import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PublicComponent } from './public.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { PasswordResetRequestComponent } from './password-reset-request/password-reset-request.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LinkAccountComponent } from './link-account/link-account.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { VerifyInvitationComponent } from './verify-invitation/verify-invitation.component';
import { SurveyComponent } from './survey/survey.component';
import { PublicEventsComponent } from './events/events.component';
import { PublicClassesComponent } from './classes/classes.component';
import { NgOptimizedImage } from '@angular/common';
import { MemberVerificationComponent } from './member-verification/member-verification.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: 'events/:id',
        component: PublicEventsComponent,
      },
      {
        path: 'classes',
        component: PublicClassesComponent,
      },
      {
        path: 'classes/:id',
        component: PublicClassesComponent,
      },
      {
        path: 'user-registration',
        component: UserRegistrationComponent,
      },
      {
        path: 'password-reset-request',
        component: PasswordResetRequestComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'link-account',
        component: LinkAccountComponent,
      },
      {
        path: 'unsubscribe',
        component: UnsubscribeComponent,
      },
      {
        path: 'survey',
        component: SurveyComponent,
      },
      {
        path: 'verify-account',
        component: VerifyAccountComponent,
      },
      {
        path: 'verify-invitation',
        component: VerifyInvitationComponent,
      },
      {
        path: 'member-verification',
        component: MemberVerificationComponent,
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes),NgOptimizedImage],
  exports: [RouterModule]
})
export class PublicRoutingModule {
  static components = [
    UserRegistrationComponent,
    ResetPasswordComponent,
    PasswordResetRequestComponent,
    LinkAccountComponent,
    UnsubscribeComponent,
    VerifyAccountComponent,
    VerifyInvitationComponent,
    SurveyComponent,
    PublicEventsComponent,
    PublicClassesComponent,
    MemberVerificationComponent,
    ];
}
