import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {RosterProfileTransactionsGridModel, RosterProfileTxnTransactionsGridModel} from '../models/roster-profile-transactions.model';
import {AccountingInvoiceDetailsGridModel, AccountingReceiptDetailsGridModel, CreateInvoiceAdjustmentModel, InvoiceDetailsGridModel, InvoicesGridModel, WindowPaymentPrefillModel, WindowPaymentSplitModel} from '../models/invoices.model';


@Injectable({
  providedIn: 'root',
})
export class RosterProfileTransactionsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getRosterTransactionsPrimeNG(dataMode: string, personId: number, filterData, orgId?: number): Observable<RosterProfileTransactionsGridModel> {
    const url: string = orgId ? `&routeorganizationid=${orgId}` : '';
    return this.http.post<RosterProfileTransactionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/transactionhistorygrid/${personId}?mode=${dataMode}${url}`, filterData);
  }

  getRosterTransactionsGroupedPrimeNG(dataMode: string, personId: number, filterData, orgId?: number): Observable<RosterProfileTransactionsGridModel> {
    return this.http.post<RosterProfileTransactionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/transactionhistorygroupedgrid/${personId}?mode=${dataMode}`, filterData);
  }

  getRosterTxnTransactionsPrimeNG(dataMode: string, personId: number, filterData, organizationId?: number): Observable<RosterProfileTxnTransactionsGridModel> {
    const url: string = (organizationId) ? `&routeOrganizationId=${organizationId}` : '';
    return this.http.post<RosterProfileTxnTransactionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/membertransaction/grid?mode=${dataMode}${url}`, filterData);
  }

  getRosterInvoiceDetailPrimeNG(dataMode: string, invoiceId: number, filterData): Observable<AccountingInvoiceDetailsGridModel> {
    return this.http.post<AccountingInvoiceDetailsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/memberinvoicetransactionsgrid/?invoiceId=${invoiceId}&mode=${dataMode}`, filterData);
  }

  getRosterUpdatedPaymentReceipt(id: number): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer',
      observe: 'response'
    };
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/${id}/updatedreceipt`, requestOptions);
  }

  getRosterReceiptDetailPrimeNG(dataMode: string, paymentId: number, filterData): Observable<AccountingReceiptDetailsGridModel> {
    return this.http.post<AccountingReceiptDetailsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/memberreceiptdetailsgrid/?paymentId=${paymentId}&mode=${dataMode}`, filterData);
  }

  getRosterWindowPaymentPrefill(orgId: number, personId: number,): Observable<WindowPaymentPrefillModel[]> {
    return this.http.get<WindowPaymentPrefillModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${orgId}/person/${personId}/windowPaymentPrefill`);
  }

  createRosterWindowPaymentSplit(dataModel: WindowPaymentSplitModel): Observable<WindowPaymentSplitModel> {
    return this.http.post<WindowPaymentSplitModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/createdirectpayment`, dataModel);
  }

  getRosterInvoicePrimeNG(dataMode: string, personId: number, filterData): Observable<InvoicesGridModel> {
    return this.http.post<InvoicesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/person/${personId}/invoicegrid?mode=${dataMode}`, filterData);
  }

  getRosterInvoiceDetailsPrimeNG(dataMode: string, personId: number, invoiceId: number, organizationId: number, filterData): Observable<InvoiceDetailsGridModel> {
    return this.http.post<InvoiceDetailsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/person/${personId}/invoice/${invoiceId}/detailgrid?organizationid=${organizationId}?mode=${dataMode}`, filterData);
  }

  createInvoiceRoster(invoiceId: number, modelData: CreateInvoiceAdjustmentModel): Observable<CreateInvoiceAdjustmentModel> {
    return this.http.post<CreateInvoiceAdjustmentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/invoice/${invoiceId}/adjust`, modelData);
  }

  // createInvoiceAdjustment(personId: number, modelData: CreateInvoiceAdjustmentModel): Observable<CreateInvoiceAdjustmentModel>{
  //   return this.http.post<CreateInvoiceAdjustmentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/membertransaction/addadjustment`, modelData);
  // }
}
