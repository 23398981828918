<div class="p-grid p-fluid">
  <form [formGroup]="mainForm">
    <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
      <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc" [parentObjectEnum]="parentObjectEnum"></app-learn-more>
    </div>
    @if (configSENDBILLPOSTEDEMAIL) {
      <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
        <p-checkbox formControlName="configureCheckBox" binary="true" tabindex="0"></p-checkbox> Send invoice Email to Member (Only Applies Feature is Activated)
        <p>If this box is checked, the system will send an email to each member when a new invoice is placed on their account. Invoices are created as part of the billing process, in particular, when billing runs are posted.</p>
      </div>
    }
    @if (configSENDBILLPOSTEDEMAIL) {
      <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
    @if (canProvisionSMSNumber) {
      <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
        <p-checkbox formControlName="isProvisionSMSNumber" (onChange)="CanProvisionSMSNumber($event)" binary="true" tabindex="0"></p-checkbox> Request Designated Texting (SMS) Number
        <p>The system has the ability to send mass text messages to members. When those messages are sent the system will utilize one of several numbers as the 'from' number. This is the number that the recipient will see as the originator of the text message. If you would like to request a unique number for your organization’s text communications, please check the box above and provide the area code and number prefix (first three digits) that you would prefer. We will make all attempts to get as close to the requested area code and number prefix as possible. Please note, provisioning these numbers takes approximately 24 hours, you will be notified when the number has been successfully provisioned via email.</p>
      </div>
    }
    @if (canProvisionSMSNumber) {
      <div class="col-md-12 col-sm-12 col-xs-12 p-mb-12 input-container">
        <div class="col-md-4 col-sm-4 col-xs-4 p-mb-2">
          <label>Ideal (SMS) Number Prefix (Area Code and First Three)</label>
          <p-inputMask [className]="'inline-button'" formControlName="ProvisionSMSNumber" mask="(999)999" placeholder="( )" tabindex="0"></p-inputMask>
        </div>
      </div>
    }
    @if (canProvisionSMSNumber) {
      <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
    @if (configALLOWUNLAYER) {
      <div class="col-md-12 col-sm-12 col-xs-12 checkbox-container p-mb-2">
        <p-checkbox formControlName="allowUnlayer" binary="true" label="Enable Drag and Drop Communication Interface" tabindex="0"></p-checkbox>
        <p>Enable drag and drop user interface in the creation of email communication templates and email communications.</p>
        <p>For more information, go to <a href="https://unlayer.com/embed" target="_blank" rel="noopener noreferrer" title="Unlayer.com">Unlayer.com</a></p>
      </div>
    }
    @if (configALLOWUNLAYER) {
      <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
    @if (configCERTEXPWARNING) {
      <div class="col-md-12 col-sm-12 col-xs-12 checkbox-container p-mb-2">
        <p-checkbox formControlName="certExpWarning" binary="true" label="Send Certification Expiration Warning" tabindex="0"></p-checkbox>
        <p>Send an automated notification (text first, email if no mobile # or opted out) to the member when certifications are 90 days away from expiring.</p>
      </div>
    }
    @if (configCERTEXPWARNING) {
      <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
    @if (configCLASSNOTIFICATION) {
      <div class="col-md-12 col-sm-12 col-xs-12 checkbox-container p-mb-2">
        <p-checkbox formControlName="classNotification" binary="true" label="Send Class Reminder 24 Hours Prior to Start of Class" tabindex="0"></p-checkbox>
        <p>Send an automated reminder (text first, email if no mobile # or opted out) to the member 24 hours before the class is scheduled to begin.</p>
      </div>
    }
    @if (configCLASSNOTIFICATION) {
      <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
    <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-xs-12 p-mb-2">
            <p-card header="Logo">
              <div class="text-align-middle upload-buttons p-button-auto-width">
                <label>The logo should be 400 px wide by 400 px high saved as a transparent .png file. File size is limited to 500KB</label>
                @if (isLogo) {
                  <figure>
                    <img src="{{ organization.LogoUrl }}" alt="" />
                  </figure>
                }
                @if (canTabWrite && !isLogo) {
                  <p-fileUpload accept="image/*" class="p-button-download export-menu image-button" mode="basic" name="myfile[]" customUpload="true"
                  (onSelect)="uploadLogo($event)" chooseIcon="pi pi-upload" maxFileSize="4e+6" tabindex="0"></p-fileUpload>
                }
                @if (canTabWrite && isLogo) {
                  <button (click)="removeLogo()" pButton icon="pi pi-trash" class="p-button-download export-menu image-button" pTooltip="Delete" tooltipPosition="bottom"></button>
                }
              </div>
            </p-card>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 p-mb-2">
            <p-card header="Banner">
              <div class="text-align-middle upload-buttons p-button-auto-width">
                <label>The banner should be 2000 px wide by 300 px high saved as a .jpg file. File size is limited to 2MB.  It should (preferably) not include your logo or any text for best results.</label>
                @if (isBanner) {
                  <figure>
                    <img src="{{ organization.BannerUrl }}" alt="" />
                  </figure>
                }
                @if (canTabWrite && !isBanner) {
                  <p-fileUpload accept="image/*" class="p-button-download export-menu image-button" mode="basic" name="myfile[]" customUpload="true"
                  (onSelect)="uploadBanner($event)" chooseIcon="pi pi-upload" maxFileSize="4e+6" tabindex="0"></p-fileUpload>
                }
                @if (canTabWrite && isBanner) {
                  <button (click)="removeBanner()" pButton icon="pi pi-trash" class="p-button-download export-menu image-button" pTooltip="Delete" tooltipPosition="bottom"></button>
                }
              </div>
            </p-card>
          </div>
        </div>
      </div>
    </div>
    @if (canTabWrite) {
      <div class="col-md-12 col-sm-12 col-xs-12 float-right tab-form-buttons p-mb-2">
        <button [disabled]="this.mainForm.invalid || !mainForm.dirty" pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
        (click)="refresh()"></button>
        @if (!isSaving) {
          <button [disabled]="this.mainForm.invalid || !mainForm.dirty" pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
          (click)="updateData()" [className]="'inline-button'"></button>
        } @else {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    }
  </form>
</div>
