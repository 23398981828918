<div class="form-control relative">
  @if (inputObj) {
      <div>
        <label>{{ inputObj.labelText }} @if (inputObj?.requiredField) {
          <sup class="req">*</sup>
        }
        </label>
      </div>
      <div>
          <p-multiSelect [options]="selectData" [optionValue]="inputObj?.optionValue"
                         [optionLabel]="inputObj?.optionLabel" appendTo="body" (onPanelHide)="sendOnBlur($event)"
                         [filter]="inputObj?.filter" [filterBy]="inputObj?.filterBy" placeholder="--"
                         [virtualScroll]="virtScroll" virtualScrollItemSize="30"
                         [(ngModel)]="inputObj.initSelected" [required]="inputObj?.requiredField"
                         class="select-dropdown no-click"
                         [styleClass]="inputObj.styleClass" (onChange)="emitDataToParent(true)"
                          display="chip" tabindex="0">
                <ng-template let-label pTemplate="item" let-i="index">
                  <div class="description-label" [title]="label.Description">
                    <div>{{ label.Description }}</div>

                  </div>
                  @if(inputObj.initSelected?.includes(label.ID)) {
                    @if(preferredId === label.ID) {
                    <div class="primary-container"> Preferred <i (click)="setPreferred(label.ID, i)" style="font-size:13px" class="fas fa-circle"></i></div>
                    } @else {
                      <div class="primary-select-container"> <i (click)="setPreferred(label.ID, i)" class="fas fa-circle-dashed" pTooltip="Set Preferred"></i></div>
                    }

                  }
                </ng-template>

          </p-multiSelect>
      </div>
  }
</div>
