@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
<form [formGroup]="mainForm" class="margin-top-10 ">
  <p-divider class="dark-divider"></p-divider>
  @if (!isEdit) {
    <div class="p-grid p-fluid parent-form">
      <p-table #dt1
               [rows]="pageRowCountUtility.pageRowCount()"
               [columns]="selectedColumns"
               dataKey="PersonID"
               [value]="staffPickerList"
               [lazy]="true"
               (onLazyLoad)="loadTable($event)"
               [paginator]="true"
               [showCurrentPageReport]="true"
               [metaKeySelection]="false"
               [totalRecords]="dataTotal"
               [rowsPerPageOptions]="[25,50,100,250,500]"
               [scrollable]="true"
               [(selection)]="selStaff"
               selectionMode="multiple"
               scrollHeight="400px"
               paginatorDropdownAppendTo="body"
               [loading]="loading"
               styleClass="p-datatable-responsive-demo p-datatable-striped inner-table"
               class="full-width"
               currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>
              <p-tableHeaderCheckbox class="p-pl-2" tabindex="0"></p-tableHeaderCheckbox>
            </th>
            @for (col of columns; track col) {
              <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
                @if (col.visible) {
                  <div class="header-text">
                    {{ col.header }}
                  </div>
                }
                @if (col.visible) {
                  <div class="header-actions">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    @if (col.columnType !== 'picker') {
                      <p-columnFilter type="{{col.columnType}}" matchMode="{{col.matchMode}}" [field]="col.field" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    }
                  </div>
                }
              </th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td>
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            @for (col of columns; track col) {
              <td>
                @if (col.visible && col.displayType === 'text') {
                  {{ rowData[col.field] }}
                }
                @if (col.visible && col.displayType === 'boolean') {
                  <p-checkbox [(ngModel)]="rowData[col.field]" [binary]="true" (onChange)="primarySelected($event, rowData)" [ngModelOptions]="{standalone: true}" [disabled]="!selStaff.includes(rowData)" tabindex="0"></p-checkbox>
                }
              </td>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [colSpan]="cols?.length" style="width: 100%;">No staff found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }
  @if (isEdit) {
    <div class="p-grid p-fluid parent-form">
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
        <p-checkbox formControlName="primary" [binary]="true" [disabled]="primaryInstructor" tabindex="0"></p-checkbox>
        Primary Instructor
      </div>
    </div>
  }
  <div class="p-grid p-fluid margin-top-10">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="p-grid p-fluid">
        <div class="col-md-6 col-sm-6 col-xs-12"></div>
        <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
          @if (isEdit && !primaryInstructor) {
            <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                    (click)="cancel()" [disabled]="!mainForm.dirty"></button>
          }
          @if (!isEdit) {
            <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                    (click)="cancel()"></button>
          }
          @if (!isSaving && isEdit && !primaryInstructor) {
            <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                    (click)="processData()" [className]="'inline-button'"
                    [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
          }
          @if (isSaving && isEdit && !primaryInstructor) {
            <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
          @if (!isSaving && !isEdit) {
            <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                    (click)="processData()" [className]="'inline-button'"
                    [disabled]="selStaff.length<=0"></button>
          }
          @if (isSaving && !isEdit) {
            <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</form>
