<form [formGroup]="mainForm">
  <div class="p-fluid p-grid">
    @if (!noOJLFacetsFound) {
      <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Facet Type</label>
        <input class="full-width filter" pInputText type="text" formControlName="OJLFacetType" disabled="true"/>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Facet</label>
        <input class="full-width filter" pInputText type="text" formControlName="OJLFacet" disabled="true"/>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
        <label class="margin-bottom-5 add-display-flex">Incoming Hours</label>
        <p-inputNumber [className]="'form-input no-margin-button'" formControlName="OJLHours" [showButtons]="true"
                       buttonLayout="horizontal" min=".25" step=".25"
                       decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                       incrementButtonIcon="pi pi-plus"
                       decrementButtonIcon="pi pi-minus" mode="decimal" [minFractionDigits]="2"></p-inputNumber>
      </div>
    }
    @if (!noOJLFacetsFound && !noRIFacetsFound) {
      <div class="col-md-12 col-sm-6 col-xs-12 margin-top-20"></div>
    }
    @if (noOJLFacetsFound) {
      <div class="col-md-12 col-sm-6 col-xs-12 margin-top-20">
        <span class="error">Sorry Incoming Hours Facet was not found for OJL.</span>
      </div>
    }
    @if (noRIFacetsFound) {
      <div class="col-md-12 col-sm-6 col-xs-12 margin-top-20">
        <span class="error">Sorry Incoming Hours Facet was not found for RI.</span>
      </div>
    } @else {
      <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Facet Type</label>
        <input class="full-width filter" pInputText type="text" formControlName="RIFacetType" disabled="true"/>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Facet</label>
        <input class="full-width filter" pInputText type="text" formControlName="RIFacet" disabled="true"/>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
        <label class="margin-bottom-5 add-display-flex">Incoming Hours</label>
        <p-inputNumber [className]="'form-input no-margin-button'" formControlName="RIHours" [showButtons]="true"
                       buttonLayout="horizontal" min=".25" step=".25"
                       decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                       incrementButtonIcon="pi pi-plus"
                       decrementButtonIcon="pi pi-minus" mode="decimal" [minFractionDigits]="2"></p-inputNumber>
      </div>
    }
    @if (!noOJLFacetsFound || !noRIFacetsFound) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15 text-align-right">
        <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times"
                pTooltip="Cancel" tooltipPosition="bottom"
                (click)="cancel()"></button>
        <button pButton label="Add" class="p-button-info p-mr-2" icon="pi pi-plus" pTooltip="Add"
                tooltipPosition="bottom"
                (click)="processData()" [className]="'inline-button'"
                [disabled]="!mainForm.get('OJLHours').value && !mainForm.get('RIHours').value"></button>
      </div>
    }
  </div>
</form>
