<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    @switch (classStatus) {
      @case ('completed') {
        <p>Please Note &mdash; Below is a list of classes this student has '<b>Completed</b>'. Be sure to keep the
          student's status up-to-date in each class the student has attended or will attend.</p>
      }
      @case ('enrolled') {
        <p>Please Note — Below is a list of classes this student is currently <b>active in.</b> Be sure to keep the
          student's status up-to-date in each class the student has attended or will attend. <b>This list is filtered by
            "Enrolled" by default.</b>
        </p>
      }
    }
  </div>
  <div class="col-md-2 col-sm-12 col-xs-12"
       [ngClass]="{'col-md-2 col-sm-12 col-xs-12' : showFilter, 'hide' : !showFilter}">
    <p-accordion expandIcon="fas fa-question-circle" collapseIcon="fas fa-question-circle">
      <p-accordionTab header="Learn More" [selected]="false">
        @switch (classStatus) {
          @case ('enrolled') {
            <p><b>See student’s active classes.</b></p>
          }
          @case ('completed') {
            <p><b>See student past class history.</b></p>
          }
        }
      </p-accordionTab>
    </p-accordion>
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <div class="col-md-12 no-padding">
      <span class="p-input-icon-left full-width">
        <i class="pi pi-search"></i>
        <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter"
               (keyup.enter)="filterItems()" placeholder="Course" tabindex="0"/>
      </span>
      </div>
      @if (classStatus === 'enrolled') {
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 no-padding">
          <app-multi-select class="full-width" [inputObj]="inputObjStatus" (emitData)="setStatusData($event)">
          </app-multi-select>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <div class="list-filter-buttons text-align-right">
          <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clearFilters()">
          </button>
          <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  (click)="filterItems()">
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header" [ngClass]="{'col-md-12' : !showFilter}">
    <div class="p-grid p-fluid">
      @if (!dataLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">@if (studentClasses) {
          <span>{{ dataTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                     (onPageChange)="paginate($event)"></p-paginator>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        @if ((currentFilter.filters | json) !== '{}') {
          <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                  pTooltip="Clear Filter" tooltipPosition="bottom"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                  (click)="clearFilters()">
          </button>
        }
        <button pButton class="p-button-download export-menu p-mr-2" icon="pi pi-filter"
                pTooltip="Filter Toggle" tooltipPosition="bottom" (click)="toggleFilter()">
        </button>
        <button pButton (click)="refresh()" icon="pi pi-refresh"
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canTabWrite) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (studentClasses) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Student to a Class" [styleClass]="'list-card'">
          <app-student-class-form [PersonID]="PersonID" [isEdit]="false" [canTabWrite]="canTabWrite"
                                  (closeAddEdit)="updateList()"></app-student-class-form>
        </p-card>
      </div>
    }
    @if (dataLoaded && dataTotal <= 0) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (studentClass of studentClassesList; track studentClass; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'"
                    (click)="showClass(studentClass.ClassID, studentClass.CourseDescription)">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i, studentClass.ClassID)">
                  <div class="list-header">
                    {{ studentClass.CourseDescription }}
                  </div>
                  <div class="list-subheader">
                    @if (studentClass.StartDate_D && studentClass.EndDate_D | date) {
                      <span> {{ studentClass.StartDate_D | date }} - {{ studentClass.EndDate_D | date }} </span>
                    }
                    <span>| {{ studentClass.TrainingStatusDescription }}</span>
                    @if (studentClass.ResultDescription) {
                      <span> | {{ studentClass.ResultDescription }}</span>
                    }
                    @if (studentClass.Grade && !isMemberRoster) {
                      <span> | <b>Grade:</b> {{ studentClass.Grade }}</span>
                    }
                  </div>
                  <!--              <div class="list-subheader">-->
                  <!--                {{studentClass.TrainingStatusDescription}}<span *ngIf="studentClass.StatusDate_D"> | <b>Date:</b> {{studentClass.StatusDate_D | date: 'shortDate'}}</span><span *ngIf="studentClass.ResultDescription"> | <b>Result:</b> {{studentClass.ResultDescription}}</span><span *ngIf="studentClass.CertificationNumber"> | <b>Certification #:</b> {{studentClass.CertificationNumber}}</span>-->
                  <!--              </div>-->
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                </div>
              </div>
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
