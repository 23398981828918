import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ClassModel, ClassStudentModel} from '../../../models/classes.model';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {ClassesModulesService} from '../../../services/classes.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {LookupModel} from '../../../models/lookups.model';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {LookupsService} from '../../../services/lookups.service';
import {ClassAttendancesService} from '../../../services/class-attendance.service';
import {ClassAttendanceGridModel, ClassAttendanceModel, ModuleAttendanceModel, ModuleDailyAttendanceModel, UpdateClassAttendanceModel} from '../../../models/class-attendance.model';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {DatePipe} from '@angular/common';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {ContextService} from '../../../services/context.service';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';
import {customAttendanceSheet} from '../../../shared/utilities/component-manager-utility';

@Component({
  selector: 'app-class-tab-attendance',
  templateUrl: './class-tab-attendance.component.html',
  styleUrls: ['./class-tab-attendance.component.scss']
})

export class ClassTabAttendanceComponent implements OnInit, AfterViewInit, OnChanges {
  showTable: boolean = false;
  ClassAttendance: ClassAttendanceGridModel;
  ClassAttendanceList: ClassAttendanceModel[];
  ModuleAttendanceList: ModuleAttendanceModel[];
  OriginalModuleAttendanceList: ModuleAttendanceModel[];
  ModuleSignInList = {} as ModuleDailyAttendanceModel;
  cols: any[];
  exportColumns: any[];
  loading: boolean;
  isExporting: boolean;
  selStudent: ClassStudentModel[];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  selectedItem: number;
  showAddEdit: boolean;
  inputObjClassPeriod: GenericSelectModel;
  inputObjClassAttendanceStatus: GenericSelectModel;
  inputObjStudents: GenericSelectModel;
  setStudentId: number;
  setStatus: number;
  setClassPeriodDesc;
  setClassPeriodDescModule;
  attendanceStatusOptions: LookupModel[];
  dataTotal: number = 0;
  updateAttendance = {} as UpdateClassAttendanceModel;
  isSaving: boolean;
  makeUpId: number;
  lateId: number;
  formValid: boolean;
  existingMessage: boolean = false;
  @Input() ClassID: number;
  @Input() canTabWrite: boolean;
  @Input() ClassInfo: ClassModel;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() isModule: boolean;
  getListRequest;
  getTotalRequest;
  presentID: number;
  absentID: number;
  unknownID: number;
  inputObjAttendanceUploadFormat: GenericSelectModel;
  setAttendanceUploadFormat: string;
  STRINGREPLACELOCAL: string;
  fd: FormData = new FormData();
  @ViewChild('canvas') canvas: ElementRef;
  private ngUnsubscribe = new Subject();

  public _customAttendanceSheet = customAttendanceSheet;

  constructor(private classService: ClassesModulesService, private tenantConfigDataService: TenantConfigDataService,
              private messageService: MessageService, private changeDetection: ChangeDetectorRef,
              public dialogService: DialogService, private lookupsDataService: LookupsDataService,
              private classAttendancesService: ClassAttendancesService, private lookupsService: LookupsService,
              private confirmationService: ConfirmationService, private datePipe: DatePipe,
              public pageRowCountUtility: PageRowCountUtility, private contextService: ContextService,
              private dialogUtility: DialogUtility) {
  }

  ngOnInit(): void {
    // this.initClassPeriod(false, this.ClassID);
    this.getAttendanceStatus();
    this.initClassAttendanceStatus(false);
    this.initStudents(false);
    this.initAttendanceUploadFormat();
    this.STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');

    this.cols = [
      {
        field: 'FullName',
        header: 'Student Name',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'Address',
        header: 'Address',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {field: 'Phone', header: 'Phone', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {
        field: 'Signature',
        header: 'Signature',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {field: 'Other', header: 'Other', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
    ];
  }

  ngAfterViewInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    if (this.ClassID) {
      this.initClassPeriod(false, this.ClassID);
    }
  }

  getClassAttendance(classId: number, classPeriod: string, currentFilter): void {
    this.loading = true;
    this.ClassAttendance = null;
    this.ClassAttendanceList = null;
    if (!this.isModule) {
      if (classPeriod !== '--') {
        if (this.getListRequest) {
          this.getListRequest.unsubscribe();
        }
        this.getListRequest = this.classAttendancesService.getClassAttendancePrimeNG('ALL', classId, classPeriod, currentFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.ClassAttendance = res;
              this.ClassAttendanceList = res.Data;
              this.ClassAttendanceList.forEach(student => {
                student.Signature = '      ';
                student.Other = '        ';
              });
              if (this.canTabWrite && this.ClassAttendanceList.length === 0) {
                this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
              } else {
                this.showAddEdit = false;
              }
              this.ClassAttendanceList.forEach(y => {
                y.AttendanceStatusDescription = this.attendanceStatusOptions.filter(x => x.ID === y.AttendanceStatusID)[0].Description;
              });
              this.dataTotal = res.Total;
              this.loading = false;
              console.log(this.ClassAttendanceList);
            }, error: () => {
              this.loading = false;
            }
          });
      } else {
        this.loading = false;
      }
    } else {
      this.classAttendancesService.getModuleAttendance(this.setClassPeriodDesc)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.ModuleAttendanceList = res;
            this.dataTotal = this.ModuleAttendanceList ? this.ModuleAttendanceList.length : 0;
            this.loading = false;
            this.classAttendancesService.getClassSignInSheet(this.setClassPeriodDesc)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (resClass) => {
                  this.ModuleSignInList = resClass;
                }
              });
          }
        });
    }
  }

  initClassPeriod(disable: boolean, classId: number): void {
    this.inputObjClassPeriod = {
      labelText: 'Class Periods',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: true,
      initSelected: null,
      disabled: disable,
      emitChangeOnLoad: true
    };
    if (classId) {
      this.lookupsDataService.getClassAttendancePeriodsLookupData(classId, false).then((lookupData) => {
        this.inputObjClassPeriod.data = lookupData;
        this.inputObjClassPeriod = Object.assign({}, this.inputObjClassPeriod);
      });
    }
  }

  getClassPeriod(event: any): void {
    if (!this.isModule) {
      if (event && event[0] && event[0].Description && event[0].Description !== '--') {
        this.setClassPeriodDesc = event[0].Description;
      } else {
        this.setClassPeriodDesc = '--';
      }
    } else {
      if (event && event[0] && event[0].ID) {
        this.setClassPeriodDesc = event[0].ID;
        this.setClassPeriodDescModule = event[0].Description;
      } else {
        this.setClassPeriodDesc = '--';
      }
    }

    this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
  }

  initClassAttendanceStatus(disable: boolean): void {
    this.inputObjClassAttendanceStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getClassAttendanceStatusesLookupData().then((lookupData) => {
      this.inputObjClassAttendanceStatus.data = lookupData;
      this.inputObjClassAttendanceStatus = Object.assign({}, this.inputObjClassAttendanceStatus);
      if (this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Makeup')) {
        this.makeUpId = this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Makeup').ID;
      }
      if (this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Late')) {
        this.lateId = this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Late').ID;
      }
      if (this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Present')) {
        this.presentID = this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Present').ID;
      }
      if (this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Absent')) {
        this.absentID = this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Absent').ID;
      }
      if (this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Unknown')) {
        this.unknownID = this.inputObjClassAttendanceStatus.data.find(x => x.Description === 'Unknown').ID;
      }

    });
  }

  getClassAttendanceStatus(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setStatus = event[0].ID;
    } else {
      this.setStatus = null;
    }
  }

  initStudents(disable: boolean) {
    this.inputObjStudents = {
      labelText: 'Student',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable
    };
    this.lookupsDataService.getStudentsByClassId(this.ClassID).then((lookupData) => {
      this.inputObjStudents.data = lookupData;
      this.inputObjStudents = Object.assign({}, this.inputObjStudents);
    });
  }

  getStudentData(event: any) {
    if (event && event[0]) {
      this.setStudentId = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setStudentId = 0;
    }
  }

  filterItems() {
    this.loading = true;

    if (!this.isModule) {

      this.initFilters('FullName', this.activeGlobalFilter, 'contains', 'and');
      if (this.setStatus && this.setStatus !== 0) {
        this.initFilters('AttendanceStatusID', this.setStatus, 'equals', 'and');
      } else {
        this.initFilters('AttendanceStatusID', null, 'equals', 'and');
      }
      this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
    } else {
      if (this.setStudentId !== 0) {
        this.classAttendancesService.getModuleAttendance(this.setClassPeriodDesc, this.setStudentId, this.setStatus)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.ModuleAttendanceList = res;
              this.dataTotal = this.ModuleAttendanceList ? this.ModuleAttendanceList.length : 0;
              this.loading = false;
            }
          });
      }
    }

  }

  checkForFormChange() {
    if (this.ModuleAttendanceList !== this.OriginalModuleAttendanceList) {
      if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
        this.existingMessage = true;
        this.openDialog();
      }
    }
  }


  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Attendance has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.saveData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  clearFilters() {
    this.loading = true;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = '';
    this.setStudentId = null;
    this.setStatus = null;
    this.initStudents(false);
    this.initClassAttendanceStatus(false);
    this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
  }

  getAttendanceStatus(): void {
    this.lookupsService.getClassAttendanceStatusesLookup()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.attendanceStatusOptions = res;
          this.attendanceStatusOptions.unshift({
            ID: null,
            Description: '--',
            Abbreviation: '--'
          });
        }, error:
          (e) => {
            console.warn(e);
          }
      });
  }

  updateList(): void {
    this.showAddEdit = false;
    this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
    this.selectedItem = -1;
  }

  // updateStatus(PersonID) {
  //   this.updateAttendance.AttendancePeriod = this.setClassPeriodDesc;
  //   this.updateAttendance.Attendees = [];
  //     this.updateAttendance.Attendees.push({
  //       AttendanceStatusID: this.ClassAttendanceList.find(x => x.PersonID === PersonID).AttendanceStatusID,
  //       PersonID,
  //       Notes:
  //     });
  //
  //   this.classAttendancesService.updateClassAttendance(this.ClassID, this.updateAttendance)
  //     .pipe(takeUntil(this.ngUnsubscribe)).subscribe({next: (res) => {
  //     this.messageService.add({
  //       severity: 'success', summary: 'Success',
  //       detail: 'Student Attendance has been successfully updated'
  //     }});
  //     this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
  //   });
  // }

  setAllPresent() {
    const presentID: number = this.attendanceStatusOptions.find(x => x.Description === 'Present').ID;
    if (!this.isModule) {
      this.updateAttendance.AttendancePeriod = this.setClassPeriodDesc;
      this.updateAttendance.Attendees = [];
      this.ClassAttendanceList.forEach(student => {
        this.updateAttendance.Attendees.push({
          AttendanceStatusID: presentID,
          PersonID: student.PersonID,
          Notes: student.Notes
        });
      });

      this.classAttendancesService.updateClassAttendance(this.ClassID, this.updateAttendance)
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Student Attendance has been successfully updated'
          });
          this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
        }
      });
    } else {
      this.ModuleAttendanceList.forEach(student => {
        student.CourseAttendance.forEach(course => {
          course.AttendanceStatusID = presentID;
        });
      });
      this.saveData();
    }
  }

  isMakeupLate(i, j) {
    return this.ModuleAttendanceList[i].CourseAttendance[j].AttendanceStatusID === this.makeUpId || this.ModuleAttendanceList[i].CourseAttendance[j].AttendanceStatusID === this.lateId;
  }

  checkRequiredFields() {
    this.formValid = true;
    this.ModuleAttendanceList.forEach(module => {
      module.CourseAttendance.forEach(course => {
        if ((course.AttendanceStatusID === this.makeUpId || course.AttendanceStatusID === this.lateId) && !course.Notes) {
          console.log(course.AttendanceStatusID + ' ' + course.Notes);
          this.formValid = false;
        }
      });
    });
  }

  paginate(event: any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
  }

  refresh(): void {
    // this.loadTable(this.currentFilter);
  }

  expandData(index, id): void {
    this.showAddEdit = !this.showAddEdit;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  resetComponent(): void {
    this.ClassAttendanceList = undefined;
    this.ClassAttendance = undefined;
    this.cols = [];
  }

  saveData() {
    if (this.isModule) {
      this.checkRequiredFields();
      if (this.formValid) {
        this.classAttendancesService.updateModuleAttendance(this.ModuleAttendanceList)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'Attendance Statuses updated'
              });
            }, error: (e) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'All Courses marked Makeup or Late require notes!'
        });
      }
    } else {
      this.updateAttendance.AttendancePeriod = this.setClassPeriodDesc;
      this.updateAttendance.Attendees = [];
      this.ClassAttendanceList.forEach(student => {
        this.updateAttendance.Attendees.push({
          AttendanceStatusID: student.AttendanceStatusID,
          PersonID: student.PersonID,
          Notes: student.Notes
        });
      });

      this.classAttendancesService.updateClassAttendance(this.ClassID, this.updateAttendance)
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Student Attendance has been successfully updated'
          });
          this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
        }
      });
    }
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        if (!this.isModule) {
          const arr = JSON.parse(JSON.stringify(this.ClassAttendanceList));
          const dataToExport = removeKeysFromArray(arr, ['ClassID', 'ClassTypeID', 'CourseID', 'FullName', 'CourseTypeID', 'SubjectAreaID', 'ClassStatusID', 'OrganizationID', 'TimezoneID', 'OnlineMeetingTypeID', 'CanEdit', 'CanDelete']);
          exportData('classes', dataToExport);
        } else {
          const arr = JSON.parse(JSON.stringify(this.ModuleAttendanceList));
          const dataToExport = removeKeysFromArray(arr, ['ClassID', 'ClassTypeID', 'CourseID', 'FullName', 'CourseTypeID', 'SubjectAreaID', 'ClassStatusID', 'OrganizationID', 'TimezoneID', 'OnlineMeetingTypeID', 'CanEdit', 'CanDelete']);
          exportData('classes', dataToExport);
        }
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.classAttendancesService.getClassAttendancePrimeNG('DATA', this.ClassID, this.setClassPeriodDesc, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              const arr = JSON.parse(JSON.stringify(this.ClassAttendanceList));
              const dataToExport = removeKeysFromArray(arr, []);
              exportData('class-attendance', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }

  exportClassPdf(): void {

    const dataToExport = [];
    this.classService.getClass(this.ClassID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (resClass) => {
          let filename: string;
          let classStaffArray;
          if(resClass.ClassStaff) {
            classStaffArray = resClass.ClassStaff?.includes(',') ? resClass.ClassStaff.split(',') : [resClass.ClassStaff];
          } else {
            classStaffArray = [''];
          }
          if (this.setClassPeriodDesc !== null && this.setClassPeriodDesc !== undefined && this.setClassPeriodDesc !== '--') {
            filename = 'class-sign-in-sheet-' + this.setClassPeriodDesc.replaceAll('/', '-') + '.pdf';
          } else {
            filename = 'class-sign-in-sheet-.pdf';
          }
          if (this._customAttendanceSheet()) {
            this.exportColumns = [['Name', 'Tardy', 'Absent', 'Signature']];
            const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
            exportFilter.first = 0;
            exportFilter.rows = null;
            this.classAttendancesService.getClassAttendancePrimeNG('DATA', this.ClassID, this.setClassPeriodDesc, exportFilter)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: () => {
                  this.ClassAttendanceList.forEach((student, index) => {
                    dataToExport.push([
                      index + 1 + ' ' + student.FullName,
                      null,
                      null,
                      student.Signature,
                      student.Other
                    ]);
                  });
                  const doc = new jsPDF('l', 'in');
                  doc.text('Sign-In Sheet', .5, .50).setFontSize(10);
                  doc.text('Date  _____________________________', 7.6, .50).setFontSize(10);
                  console.log(this.setClassPeriodDesc);
                  const tempStartTime = resClass.StartTime_T ? this.datePipe.transform(resClass.StartTime_T, 'h:mm a') : '';
                  const tempEndTime = resClass.EndTime_T ? this.datePipe.transform(resClass.EndTime_T, 'h:mm a') : '';
                  const tempLocation: string = resClass.Location ? resClass.Location : '';
                  doc.text('Class: ' + resClass.CourseDescription, .5, 1).setFontSize(10);
                  doc.text('Class Code: ' + resClass.ClassIdentifier, .5, 1.25).setFontSize(10);
                  doc.text('Location: ' + tempLocation, .5, 1.50).setFontSize(10);
                  //doc.text('Date: ' + this.setClassPeriodDesc , .5, 1.25).setFontSize(10);
                  doc.text('Start Date & Time: ' + this.datePipe.transform(resClass.StartDate_D, 'MM/dd/YYYY') + ' | ' + tempStartTime, .5, 1.75).setFontSize(10);
                  doc.text('End Date & Time: ' + this.datePipe.transform(resClass.EndDate_D, 'MM/dd/YYYY') + ' | ' + tempEndTime, .5, 2).setFontSize(10);
                  doc.text('Instructor 1: ' + classStaffArray[0], 6.5, 1).setFontSize(10);
                  doc.text('Instructor 1 Signature: _____________________________', 6.5, 1.25).setFontSize(10);
                  if (classStaffArray.length > 1) {
                    doc.text('Instructor 2: ' + classStaffArray[1], 6.5, 1.5).setFontSize(10);
                    doc.text('Instructor 2 Signature: _____________________________', 6.5, 1.75).setFontSize(10);
                  }
                  autoTable(doc,
                    {
                      head: this.exportColumns,
                      headStyles: {fillColor: '#4A4A4A', lineColor: [0, 0, 0], lineWidth: .01},
                      body: dataToExport,
                      columnStyles: {
                        0: {cellWidth: 3},
                        1: {cellWidth: 1},
                        2: {cellWidth: 1},
                        // etc
                      },
                      startY: 2.25,
                      theme: 'grid',
                      bodyStyles: {lineColor: [0, 0, 0], lineWidth: .01},
                      didDrawCell: (data) => {
                      },

                    });
                  doc.save(filename);
                }
              });
          } else {
            this.exportColumns = [['Member ID', 'Name', 'Email', this.STRINGREPLACELOCAL, 'Employer', 'Signature', 'Notes']];
            const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
            exportFilter.first = 0;
            exportFilter.rows = null;
            this.classAttendancesService.getClassAttendancePrimeNG('DATA', this.ClassID, this.setClassPeriodDesc, exportFilter)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: () => {
                  this.ClassAttendanceList.forEach(student => {
                    dataToExport.push([
                      student.MemberNumber,
                      student.FullName,
                      student.Email,
                      student.OrganizationAbbreviation,
                      student.Employer,
                      student.Signature,
                      student.Other
                    ]);
                  });
                  const doc = new jsPDF('l', 'in');
                  doc.text('Sign-In Sheet', 5, .50).setFontSize(10);
                  console.log(this.setClassPeriodDesc);
                  const tempStartTime = resClass.StartTime_T ? this.datePipe.transform(resClass.StartTime_T, 'h:mm a') : '';
                  const tempEndTime = resClass.EndTime_T ? this.datePipe.transform(resClass.EndTime_T, 'h:mm a') : '';
                  doc.text('Class: ' + resClass.CourseDescription, .5, 1).setFontSize(10);
                  //doc.text('Date: ' + this.setClassPeriodDesc , .5, 1.25).setFontSize(10);
                  doc.text('Start Date & Time: ' + this.datePipe.transform(resClass.StartDate_D, 'MM/dd/YYYY') + ' | ' + tempStartTime, .5, 1.25).setFontSize(10);
                  doc.text('End Date & Time: ' + this.datePipe.transform(resClass.EndDate_D, 'MM/dd/YYYY') + ' | ' + tempEndTime, .5, 1.50).setFontSize(10);
                  if(resClass.ClassStaff) {
                    doc.text('Instructor: ' + resClass.ClassStaff, 6.5, 1).setFontSize(10);
                  } else {
                    doc.text('Instructor: ', 6.5, 1).setFontSize(10);
                  }

                  doc.text('Instructor Signature(s): _____________________________', 6.5, 1.25).setFontSize(10);
                  autoTable(doc,
                    {
                      head: this.exportColumns,
                      headStyles: {fillColor: '#4A4A4A'},
                      body: dataToExport,
                      startY: 1.6,
                      didDrawCell: (data) => {
                      },
                    });
                  doc.save(filename);
                }
              });
          }
        }
      });
  }

  exportModulePDF() {

    const dataParentToExport = [];
    const dataToExport = [];
    if (this._customAttendanceSheet()) {
      let classStaffArray;
      if(this.ModuleSignInList.Instructor) {
        classStaffArray = this.ModuleSignInList.Instructor?.includes(',') ? this.ModuleSignInList.Instructor.split(',') : [this.ModuleSignInList.Instructor];
      } else {
        classStaffArray = [''];
      }
      this.exportColumns = [['Name', 'Tardy', 'Absent', 'Signature']];
      this.ModuleSignInList.Courses.forEach(course => {
        dataParentToExport.push([
          course.CourseDescription
        ]);
      });
      console.log(this.ModuleSignInList);
      this.ModuleSignInList.Courses[0].Students.forEach((student, i) => {
        dataToExport.push([
          i + 1 + ' ' + student.FullName,
          null,
          null,
          student.Signature,
        ]);
      });
      const index = 0;


      const doc = new jsPDF('l', 'in');
      doc.text('Sign-In Sheet', .5, .50).setFontSize(10);
      doc.text('Date  _____________________________', 7.6, .50).setFontSize(10);
      const tempStartTime = this.ModuleSignInList.StartTime ? this.datePipe.transform(this.ModuleSignInList.StartTime, 'h:mm a') : '';
      const tempEndTime = this.ModuleSignInList.EndTime ? this.datePipe.transform(this.ModuleSignInList.EndTime, 'h:mm a') : '';
      const tempLocation: string = this.ModuleSignInList.ClassLocation ? this.ModuleSignInList.ClassLocation : '';
      doc.text('Module: ' + this.ModuleSignInList.ClassDescription, .5, 1).setFontSize(10);
      doc.text('Class Code: ' + this.ModuleSignInList.ClassIdentifier, .5, 1.25).setFontSize(10);
      doc.text('Location: ' + tempLocation, .5, 1.50).setFontSize(10);
      //doc.text('Date: ' + this.setClassPeriodDesc , .5, 1.25).setFontSize(10);
      doc.text('Date & Time: ' + this.datePipe.transform(this.ModuleSignInList.Date_D, 'MM/dd/YYYY') + ' | ' + tempStartTime + tempEndTime, .5, 1.75).setFontSize(10);
      doc.text('Instructor 1: ' + classStaffArray[0], 6.5, 1).setFontSize(10);
      doc.text('Instructor 1 Signature: _____________________________', 6.5, 1.25).setFontSize(10);
      if (classStaffArray.length > 1) {
        doc.text('Instructor 2: ' + classStaffArray[1], 6.5, 1.5).setFontSize(10);
        doc.text('Instructor 2 Signature: _____________________________', 6.5, 1.75).setFontSize(10);
      }
      autoTable(doc,
        {
          head: this.exportColumns,
          headStyles: {fillColor: '#4A4A4A', lineColor: [0, 0, 0], lineWidth: .01},
          body: dataToExport,
          columnStyles: {
            0: {cellWidth: 3},
            1: {cellWidth: 1},
            2: {cellWidth: 1},
            // etc
          },
          startY: 2.25,
          theme: 'grid',
          bodyStyles: {lineColor: [0, 0, 0], lineWidth: .01},
          didDrawCell: (data) => {
          },
        });
      doc.addPage();
      const pageCount = doc.internal.pages.length;
      doc.deletePage(pageCount - 1);
      doc.save('module-sign-in-sheet.pdf');
    } else {
      this.exportColumns = [['Member ID', 'Student Name', 'Email', this.STRINGREPLACELOCAL, 'Employer', 'Signature', 'Notes']];
      this.ModuleSignInList.Courses.forEach(course => {
        dataParentToExport.push([
          course.CourseDescription
        ]);
      });
      console.log(this.ModuleSignInList);
      this.ModuleSignInList.Courses[0].Students.forEach(student => {
        dataToExport.push([
          student.MemberNumber,
          student.FullName,
          student.Email,
          student.OrganizationAbbreviation,
          student.Employer,
          student.Signature,
          student.Note
        ]);
      });
      const index = 0;


      const doc = new jsPDF('l', 'in');
      const width = doc.internal.pageSize.getWidth();
      const tempStartTime = this.ModuleSignInList.StartTime ? this.datePipe.transform(this.ModuleSignInList.StartTime, 'h:mm a') : '';
      const tempEndTime = this.ModuleSignInList.EndTime ? this.datePipe.transform(this.ModuleSignInList.EndTime, 'h:mm a') : '';
      doc.text('Sign-In Sheet', width / 2, .50, {align: 'center'}).setFontSize(10);
      doc.text('Module: ' + this.ModuleSignInList.ClassDescription, .5, 1).setFontSize(10);
      doc.text('Date: ' + this.datePipe.transform(this.ModuleSignInList.Date_D, 'MM/dd/YYYY').toString() + ' ' + tempStartTime + ' - ' + tempEndTime, .5, 1.25).setFontSize(10);
      if(this.ModuleSignInList.Instructor) {
        doc.text('Instructor: ' + this.ModuleSignInList.Instructor ? this.ModuleSignInList.Instructor : '', 6.5, 1).setFontSize(10);
      } else {
        doc.text('Instructor: ', 6.5, 1).setFontSize(10);
      }

      doc.text('Instructor Signature(s): _____________________________', 6.5, 1.25).setFontSize(10);
      this.ModuleSignInList.Courses.forEach(course => {
        doc.text(course.CourseDescription, width / 2, doc.getCurrentPageInfo().pageNumber === 1 ? 1.95 : .5, {align: 'center'}).setFontSize(10);
        autoTable(doc,
          {
            head: this.exportColumns,
            body: dataToExport,
            headStyles: {fillColor: '#4A4A4A'},
            margin: {top: doc.getCurrentPageInfo().pageNumber === 1 ? 2 : .65}, // Setting top margin for First Page.
          });
        doc.addPage();
      });
      const pageCount = doc.internal.pages.length;
      doc.deletePage(pageCount - 1);
      doc.save('module-sign-in-sheet.pdf');
    }


  }

  phoneMask(phoneNum: number | string): string {
    const phoneNumString = (phoneNum !== null && phoneNum.toString() !== 'null') ? phoneNum.toString() : '';
    if (phoneNumString.length === 10) {
      const areaCode = phoneNumString.slice(0, 3);
      const firstThree = phoneNumString.slice(3, 6);
      const lastFour = phoneNumString.slice(6, 10);
      return `(${areaCode}) ${firstThree}-${lastFour}`;
    } else {
      return (phoneNum !== null && phoneNum.toString() !== 'null') ? phoneNum.toString() : '';
    }
  }

  initAttendanceUploadFormat() {
    this.inputObjAttendanceUploadFormat = {
      labelText: 'File Format',
      optionValue: 'value',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: false
    };
    this.lookupsDataService.getAttendanceUploadFormatLookupData().then((lookupData) => {
      this.inputObjAttendanceUploadFormat.data = lookupData;
      this.inputObjAttendanceUploadFormat = Object.assign({}, this.inputObjAttendanceUploadFormat);
    });
  }

  getAttendanceUploadFormatData(event: any) {
    if (event && event[0] && event[0].value) {
      this.setAttendanceUploadFormat = event[0].value;
    } else {
      this.setAttendanceUploadFormat = null;
    }
  }

  onUpload(event, fileAttendanceUploadFormat): any {
    if (event.files.length > 0) {
      for (const file of event.files) {
        this.fd.append('file', file);
      }
      if (this.fd) {
        this.classAttendancesService.updateClassAttendanceScanned(this.fd, this.ClassID, this.setAttendanceUploadFormat, this.setClassPeriodDesc)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Attendance Updated',
                detail: 'The attendance has been updated.'
              });
              fileAttendanceUploadFormat.clear();
            }, error: (e) => {
              if (e?.error?.Message) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: e.error.Message.replace('UM: ', ''),
                  life: 5000
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
              }
              fileAttendanceUploadFormat.clear();
            }
          });
      }
    }
  }
}
