import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ContextService} from '../../../../services/context.service';
import {RosterQueryService} from '../../../../services/roster-queries.service';
import {MessageService} from 'primeng/api';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CreateRosterQueryModel, MatchTypeModel} from '../../../../models/roster-queries.model';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {TenantConfigDataService} from '../../../../services/tenant-config-data.service';
import {RouteContextUtility} from '../../../utilities/route-context.utility';


@Component({
  selector: 'app-distribution-list-new-edit-dialog',
  templateUrl: './distribution-list-new-edit-dialog.component.html',
  styleUrls: ['./distribution-list-new-edit-dialog.component.scss']
})

export class DistributionListNewEditDialogComponent implements OnInit, OnDestroy {
  isSaving: boolean;
  mainForm: FormGroup;
  matchType = {} as MatchTypeModel[];
  selectedMatch = {} as MatchTypeModel;
  setOrganizationId: number;
  inputObjOrganization: GenericSelectModel;
  InterfaceObjectEnum = 'DISTRIBUTIONLIST';
  rosterQuery = {} as CreateRosterQueryModel;
  routeContext: string;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, private contextService: ContextService, public ref: DynamicDialogRef,
              private rosterQueryService: RosterQueryService, private routeContextUtility: RouteContextUtility,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private lookupsDataService: LookupsDataService, private tenantConfigDataService: TenantConfigDataService) {
    this.mainForm = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.routeContext = this.routeContextUtility.getRouteContext();
    this.initOrganization(false, this.InterfaceObjectEnum, false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    const optionLabelToUse: string = (this.routeContext === 'membership' || this.routeContext === 'organize') ? this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY') : 'Description';
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: optionLabelToUse,
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: this.setOrganizationId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  setOrganizationData(event:any): void {
    if (event && event[0]) {
      this.setOrganizationId = event[0].ID;
    } else {
      this.setOrganizationId = 0;
    }
  }


  closeDialog() {
    this.ref.destroy();
  }


  cancel() {
    this.closeDialog();
  }

  processData() {
    this.rosterQuery.OrganizationID = this.setOrganizationId;
    this.rosterQuery.Description = this.mainForm.get('name').value;
    this.saveData();
  }

  saveData() {
    this.rosterQueryService.createRosterQueryNoFilters(this.rosterQuery)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Distribution List was created.'
          });
          this.cancel();
        }, error:
          (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Something went wrong'
            });
            console.warn(e);
          }
      });
  }
}
