<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                    [parentObjectEnum]="parentObjectEnum"></app-learn-more>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <app-select class="full-width" [inputObj]="inputObjClassPeriod" (emitData)="getClassPeriod($event)"></app-select>
    </div>
    @if (!isModule) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <label>Name</label>
        <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter"
               (keyup.enter)="filterItems()" tabindex="0"/>
      </div>
    } @else {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <app-select class="full-width" [inputObj]="inputObjStudents" (emitData)="getStudentData($event)"></app-select>
      </div>
    }
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <app-select class="full-width" [inputObj]="inputObjClassAttendanceStatus"
                  (emitData)="getClassAttendanceStatus($event)"></app-select>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
      <div class="list-filter-buttons text-align-right">
        <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"
                [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clearFilters()"
                tabindex="0">
        </button>
        <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"
                (click)="filterItems()">
        </button>
      </div>
    </div>
    @if ((setClassPeriodDesc || setClassPeriodDescModule) && ClassAttendance && ClassAttendanceList?.length > 0) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
    @if ((setClassPeriodDesc || setClassPeriodDescModule) && ClassAttendance && ClassAttendanceList?.length > 0) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <p>Update attendance based on file upload:</p>
        <app-select class="full-width" [inputObj]="inputObjAttendanceUploadFormat"
                    (emitData)="getAttendanceUploadFormatData($event)"></app-select>
      </div>
    }
    @if ((setClassPeriodDesc || setClassPeriodDescModule) && ClassAttendance && ClassAttendanceList?.length > 0) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 p-button-auto-width">
        <p-fileUpload #fileAttendanceUploadFormat mode="basic" accept=".xls,.xlsx,.csv" name="uploadedFile[]" url=""
                      (onSelect)="onUpload($event, fileAttendanceUploadFormat)" [auto]="true"
                      chooseLabel="Process Attendance List" maxFileSize="31457280"
                      [disabled]="!setAttendanceUploadFormat" tabindex="0"></p-fileUpload>
      </div>
    }
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      <h2
        class="col-md-3 col-sm-4 col-xs-12 results-total">{{ dataTotal | number:'1.0':'en-US' }} @if (dataTotal === 1) {
        Result
      } @else {
        Results
      }</h2>
      <div class="col-md-6 col-sm-6 col-xs-12 align-center list-paginator">
        <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal"
                     (onPageChange)="paginate($event)"></p-paginator>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-6 action-buttons">
        <button pButton (click)="refresh()" icon="pi pi-refresh"
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
        @if (!isModule) {
          <button pButton class="p-button-download export-menu export-pdf" icon="fa fa-file-pdf"
                  pTooltip="Export Sign-In Sheet" tooltipPosition="left" (click)="exportClassPdf()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-pdf" icon="fa fa-file-pdf"
                  pTooltip="Export Sign-In Sheet" tooltipPosition="left" (click)="exportModulePDF()">
          </button>
        }
      </div>
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
        <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal"
                     (onPageChange)="paginate($event)"></p-paginator>
      </div>
    </div>
    <div class="list-container p-grid p-fluid">
      @if (loading) {
        <span class="fa fa-spinner list-spinner"></span>
      }
      <div class="p-grid p-fluid full-width">
        <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10 filter-item-padding">
          <button pButton class="p-button-download export-menu setAllBtn p-ml-5" icon="pi pi-check" label="Set all to Present"
                  pTooltip="Set all to Present" tooltipPosition="bottom" (click)="setAllPresent()">
          </button>
        </div>
        @if (!isModule) {
          <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10 filter-item-padding text-align-right">
            @if (!isSaving) {
              <button pButton label="Save All" class="p-button-download export-menu setAllBtn p-mr-4" icon="pi pi-save"
                      pTooltip="Save " tooltipPosition="bottom"
                      (click)="saveData()"></button>
            }
          </div>
        }
        @if (isModule) {
          <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10 filter-item-padding">
            <div class="list-filter-buttons text-align-right">
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                      (click)="this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);"></button>
              @if (!isSaving) {
                <button pButton label="Save All" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save "
                        tooltipPosition="bottom"
                        (click)="saveData()" [className]="'inline-button'"></button>
              } @else {
                <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'"
                        [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          </div>
        }
      </div>
      @for (attendee of ClassAttendanceList; track attendee; let i = $index) {
        <div class="col-md-12">
          <!-- for class -->
          <!--        <p-card [styleClass]="'list-card'" *ngIf="!isModule">-->
          <!--          <div class="p-grid p-fluid">-->
          <!--            <div class="left-column col-md-12 col-sm-12 col-xs-12" (click)="expandData(i, attendee.PersonID)">-->
          <!--              <div class="list-header">-->
          <!--                {{attendee.FirstName}} {{attendee.LastName}}-->
          <!--              </div>-->
          <!--              <div class="list-subheader">-->
          <!--                <p-radioButton value="{{presentID}}" name="status" [styleClass]="'radio-input'" class="p-mr-2" label="Present"  [(ngModel)]="attendee.AttendanceStatusID" (onClick)="updateStatus(attendee.PersonID)" tabindex="0"></p-radioButton>-->
          <!--                <p-radioButton value="{{absentID}}" name="status" [styleClass]="'radio-input'" class="p-mr-2" label="Absent" [(ngModel)]="attendee.AttendanceStatusID" (onClick)="updateStatus(attendee.PersonID)" tabindex="0"></p-radioButton>-->
          <!--                <p-radioButton value="{{unknownID}}" name="status" [styleClass]="'radio-input'" class="p-mr-2"  label="Unknown" [(ngModel)]="attendee.AttendanceStatusID"  (onClick)="updateStatus(attendee.PersonID)" tabindex="0"></p-radioButton>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </p-card>-->
          @if (!isModule) {
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-12 col-sm-12 col-xs-12" (click)="expandData(i, attendee.PersonID)">
                  <div class="list-header">
                    {{ attendee.FullName }}
                  </div>
                  <div class="list-subheader">
                    <div class="p-fluid p-grid">
                      <div class="col-md-4 col-sm-4 col-xs-12 truncate-container">
                        <label>Attendance Status</label>
                        <p-dropdown class="truncate-input" [options]="attendanceStatusOptions" filter="true"
                                    optionLabel="Description" optionValue="ID"
                                    [(ngModel)]="attendee.AttendanceStatusID" tabindex="0"></p-dropdown>
                      </div>
                      <!--                <ng-container *ngFor="let course of attendee.CourseAttendance; let j = index">-->
                      <!--                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10" *ngIf="isMakeupLate(i, j)">-->
                      <!--                    <label>{{course.CourseDescription}} Note<sup class="req">*</sup> <span *ngIf="course.NotesModifiedPerson">| {{course.NotesModifiedPerson}}</span>-->
                      <!--                      <span *ngIf="course.NotesModifiedDate"> ({{course.NotesModifiedDate | date: 'shortDate'}})</span></label>-->
                      <!--                    <textarea pInputTextarea [(ngModel)]="course.Notes" [maxLength]="400" name="note" #note [ngClass]="{'note-error' : !course.Notes}" (blur)="checkForFormChange()" tabindex="0"></textarea>-->
                      <!--                    <div *ngIf="!course.Notes" class="error">Notes for Makeup/Late Courses are required.</div>-->
                      <!--                  </div>-->
                      <!--                </ng-container>-->
                      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                        <label>Notes  @if (attendee.NotesModifiedPerson) {
                          <span>| {{ attendee.NotesModifiedPerson }}</span>
                        }
                          @if (attendee.NotesModifiedDate) {
                            <span> ({{ attendee.NotesModifiedDate | date: 'shortDate' }})</span>
                          }</label>
                        <textarea pInputTextarea [(ngModel)]="attendee.Notes" [maxLength]="400" tabindex="0"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          }
        </div>
      }
      @for (attendee of ModuleAttendanceList; track attendee; let i = $index) {
        <div class="col-md-12">
          <!-- for module -->
          @if (isModule) {
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-12 col-sm-12 col-xs-12" (click)="expandData(i, attendee.ClassStudentID)">
                  <div class="list-header">
                    {{ attendee.FullName }}
                  </div>
                  <div class="list-subheader">
                    <div class="p-fluid p-grid">
                      @for (course of attendee.CourseAttendance; track course; let i = $index) {
                        <div class="col-md-4 col-sm-4 col-xs-12 truncate-container">
                          <!--                    <div class="p-fluid p-grid">-->
                          <!--                      <div class="col-md-12 col-sm-12 col-xs-12">-->
                          <label [pTooltip]="course.CourseDescription" tooltipPosition="bottom"
                                 class="truncate">{{ course.CourseDescription }} </label>
                          <!--                      </div>-->
                          <!--                      <div class="col-md-12 col-sm-12 col-xs-12">-->
                          <p-dropdown class="truncate-input" [options]="attendanceStatusOptions" filter="true"
                                      optionLabel="Description" optionValue="ID"
                                      [(ngModel)]="course.AttendanceStatusID" (onChange)="checkForFormChange()"
                                      tabindex="0" appendTo="body"></p-dropdown>
                          <!--                      </div>-->
                          <!--                    </div>-->
                        </div>
                      }
                      @for (course of attendee.CourseAttendance; track course; let j = $index) {
                        @if (isMakeupLate(i, j)) {
                          <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                            <label>{{ course.CourseDescription }} Note<sup
                              class="req">*</sup> @if (course.NotesModifiedPerson) {
                              <span>| {{ course.NotesModifiedPerson }}</span>
                            }
                              @if (course.NotesModifiedDate) {
                                <span> ({{ course.NotesModifiedDate | date: 'shortDate' }})</span>
                              }</label>
                            <textarea pInputTextarea [(ngModel)]="course.Notes" [maxLength]="400" name="note" #note
                                      [ngClass]="{'note-error' : !course.Notes}" (blur)="checkForFormChange()"
                                      tabindex="0"></textarea>
                            @if (!course.Notes) {
                              <div class="error">Notes for Makeup/Late Courses are required.</div>
                            }
                          </div>
                        }
                      }
                      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                        <label>Notes  @if (attendee.NotesModifiedPerson) {
                          <span>| {{ attendee.NotesModifiedPerson }}</span>
                        }
                          @if (attendee.NotesModifiedDate) {
                            <span> ({{ attendee.NotesModifiedDate | date: 'shortDate' }})</span>
                          }</label>
                        <textarea pInputTextarea [(ngModel)]="attendee.Notes" [maxLength]="400" tabindex="0"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          }
        </div>
      }
    </div>
  </div>
</div>
<div class="sign-in-container" id="canvas">
  <div class="p-fluid p-grid">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h2 class="text-align-middle">Sign-In Sheet</h2>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <b>Module:</b> {{ ModuleSignInList.ClassDescription }}
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <b>Date:</b> {{ ModuleSignInList.Date_D | date : 'shortDate' }}
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <b>Instructor:</b> {{ ModuleSignInList.Instructor }}
    </div>
    @for (course of ModuleSignInList.Courses; track course) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-left-10">
        <h3>{{ course.CourseDescription }}</h3>
        <div class="p-grid p-fluid">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p-table [value]="course.Students">
              <ng-template pTemplate="header">
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Member #</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th>Signature</th>
                  <th>Other</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-student>
                <tr>
                  <td>{{ student.FirstName }}</td>
                  <td>{{ student.LastName }}</td>
                  <td>{{ student.MemberNumber }}</td>
                  <td>{{ student.Address }}</td>
                  <td>{{ student.Phone }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    }
  </div>
</div>
