import { BaseDataGridModel } from './data-grid.model';

export interface StudentWageRatesModel extends BaseDataGridModel {
  Data: WageRateModel[];
}

export interface WageRateModel {
  ProgramStudentProgramWageRateID: number;
  ProgramWageRateID: number;
  ProgramWageRateDescription: string;
  CalculatedWageRate: number;
  StartDate_D: string;
  EndDate_D: string;
  CanDelete: boolean
}

export interface UpdateWageRateModel {
  ProgramWageRateID: number;
  StartDate_D: string;
  WageAdjustmentID: number;
}


export interface CreateWageRateModel {
  ProgramWageRateID: number;
  StartDate_D: string;
  WageID: number;
}
