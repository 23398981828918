import {Component, Input, OnInit} from '@angular/core';
import {UpdateWageRateModel, WageRateModel} from '../../../../models/wage-rates.model';
import {WageRatesService} from '../../../../services/wage-rates.services';
import {StudentProgramsService} from '../../../../services/student-programs.service';
import {takeUntil} from 'rxjs/operators';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {MessageService} from 'primeng/api';
import {transformDateTimeToDateOnly} from '../../../../shared/utilities/form.utility';
import {PageRowCountUtility} from '../../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-global-student-programs-wage-rates',
  templateUrl: './global-roster-student-wage-rates-subtab.component.html',
})

export class GlobalRosterStudentWageRatesSubtabComponent implements OnInit {
  currentFilter = new PrimeTableFilterModel();
  studentWageRates: WageRateModel[];
  currentWageRate: WageRateModel;
  updateWageRateData = {} as UpdateWageRateModel;
  dataTotal: number;
  cols: any[];
  inputObjWageRate: GenericSelectModel;
  selectedWageRateLevel: number;
  inputObjWageZone: GenericSelectModel;
  selectedWageRateZone: number;
  startDate: string;
  studentProgramId: number;
  programId: number;
  @Input() PersonID: number;
  @Input() isEdit: boolean;
  getListRequestPrograms;
  private ngUnsubscribe = new Subject();

  constructor(private wageRatesService: WageRatesService, private studentProgramsService: StudentProgramsService,
              private lookupsDataService: LookupsDataService, private messageService: MessageService,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.cols = [
      {field: 'ProgramWageRateDescription', header: 'Description', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'CalculatedWageRate', header: 'Wage Rate', columnType: 'numeric', matchMode: 'contains', displayType: 'currency', visible: true},
      {field: 'StartDate_D', header: 'Start Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: true},
      {field: 'EndDate_D', header: 'End Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: true},
    ];

    this.getPrograms();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initWageRateLevels(disable: boolean): void {
    this.inputObjWageRate = {
      labelText: 'Wage Rate Level',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getStudentWageRatesLookupData(this.studentProgramId).then((lookupData) => {
      this.inputObjWageRate.data = lookupData;
      this.inputObjWageRate = Object.assign({}, this.inputObjWageRate);
    });
  }

  setWageRateLevelData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.selectedWageRateLevel = event[0].ID;
    } else {
      this.selectedWageRateLevel = 0;
    }
  }

  initWageRateZones(disable: boolean): void {
    this.inputObjWageZone = {
      labelText: 'Wage Rate',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getStudentWageRatesZonesLookupData(this.programId).then((lookupData) => {
      this.inputObjWageZone.data = lookupData;
      this.inputObjWageZone = Object.assign({}, this.inputObjWageZone);
    });
  }

  setWageRateZoneData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.selectedWageRateZone = event[0].ID;
    } else {
      this.selectedWageRateZone = 0;
    }
  }

  getPrograms(): void {
    if (this.getListRequestPrograms) {
      this.getListRequestPrograms.unsubscribe();
    }
    this.getListRequestPrograms = this.studentProgramsService.getStudentProgramsPrimeNG('DATA', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          if (res && res.Data.length > 0) {
            this.studentProgramId = res.Data[0].ProgramStudentID;
            this.programId = res.Data[0].ProgramID;
            this.getStudentWageRates();
            this.initWageRateLevels(false);
            this.initWageRateZones(false);
          }
        }
      });
  }

  getStudentWageRates(): void {
    this.wageRatesService.getStudentWageRatesPrime(this.studentProgramId, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.studentWageRates = res.Data;
          this.dataTotal = res.Total;
          this.currentWageRate = this.studentWageRates.find(x => x.EndDate_D === null);
        }
      });
  }

  updateWageRate(): void {
    this.updateWageRateData.ProgramWageRateID = this.selectedWageRateLevel;
    this.updateWageRateData.StartDate_D = transformDateTimeToDateOnly(this.startDate);
    this.updateWageRateData.WageAdjustmentID = this.selectedWageRateZone;
    this.wageRatesService.createStudentWageRates(this.studentProgramId, this.updateWageRateData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Wage Rate was Added.'});
          this.getStudentWageRates();
          this.selectedWageRateLevel = null;
          this.startDate = null;
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        }
      });
  }
}
