import {Component, OnInit} from '@angular/core';

import {ConfirmationService} from 'primeng/api';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-record-not-found',
  templateUrl: './record-not-found.component.html',
  styleUrls: ['./record-not-found.component.scss']
})
export class RecordNotFoundComponent implements OnInit {
  constructor(private router: Router, private location: Location, private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {}

  back(): void {
    this.confirmationService.close();
    this.location.back();
  }

  list(): void {
    const edit: number = this.router.url.indexOf('/edit/');
    const url: string = this.router.url.substring(0, edit) + '/list';
    this.router.navigateByUrl(url);
  }
}
