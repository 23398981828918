<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12 text-align-middle">
    @if (!webcamImage) {
      <webcam [height]="300" [width]="300" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
        [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions"
        [imageQuality]="1"
        (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)"
      ></webcam>
    }
  </div>
  <div class="col-md-4 col-sm-3 col-xs-12 text-align-middle"></div>
  <div class="col-md-4 col-sm-6 col-xs-12 text-align-middle">
    @if (!webcamImage) {
      <button pButton label="Take A Snapshot" class="p-button-info p-mr-2 margin-top-10" (click)="triggerSnapshot();"></button>
    }
  </div>
  <div class="col-md-4 col-sm-3 col-xs-12 text-align-middle"></div>
  <div class="col-md-12 col-sm-12 col-xs-12 text-align-middle">
    @if (webcamImage) {
      <div class="snapshot">
        <img src="{{webcamImage.imageAsDataUrl}}" alt="" />
      </div>
    }
  </div>
  @if (webcamImage) {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="p-grid p-fluid">
        <div class="col-md-3 col-sm-3 col-xs-12 text-align-middle"></div>
        <div class="col-md-3 col-sm-3 col-xs-12 text-align-middle">
          <button pButton label="Retake Picture" class="p-button-info p-mr-2 margin-top-10" (click)="webcamImage = null"></button>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12 text-align-middle">
          <button pButton label="Save Picture" class="p-button-info p-mr-2 margin-top-10" (click)="savePicture();"></button>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12 text-align-middle"></div>
      </div>
    </div>
  }
</div>
