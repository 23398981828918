import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  ConvertProspectApplicantModel,
  ProspectProfileModel, UpdateProspectProfileModel,
} from '../models/prospect-profile.model';
import {RosterProfileModel} from '../models/roster-profile.model';
import {ProfileModel} from '../models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProspectProfileService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getProspectProfile(personId: number): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/${personId}`);
  }

  updateProspectProfile(personId: number, modelData: ProfileModel): Observable<any> {
    return this.http.put<ProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/${personId}?ignoreaddressupdates=true`, modelData);
  }

  createProspectProfile(modelData: ProfileModel): Observable<ProfileModel> {
    return this.http.post<ProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/create`, modelData);
  }
  convertProspectToApplicant(personProfileID: number, modelData: ConvertProspectApplicantModel): Observable<ConvertProspectApplicantModel> {
    return this.http.put<ConvertProspectApplicantModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/${personProfileID}/memberconvert`, modelData);
  }

  // createProspectProfileExistingMember(modelData: CreateProspectProfileExistingMemberModel): Observable<any> {
  //   return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/create`, modelData);
  // }

  // getProspectProfilePointsKGrid(personId: number): Observable<PointsGridModel> {
  //   return this.http.get<PointsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/pointsgrid`);
  // }

  // getProspectProfilePointsTotal(personId: number): Observable<TotalPointsModel> {
  //   return this.http.get<TotalPointsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/pointstotal`);
  // }
}
