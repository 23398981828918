import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { RosterProfilePoliticsModel } from '../models/roster-profile-politics.model';

@Injectable({
  providedIn: 'root',
})
export class RosterProfilePoliticsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getRosterVoterInfo(personId: number): Observable<RosterProfilePoliticsModel> {
    return this.http.get<RosterProfilePoliticsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/voterinfo`);
  }
}
