<form [formGroup]="mainForm" class="margin-top-10">
  <p-divider class="dark-divider"></p-divider>
  <div class="p-grid p-fluid">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <p-checkbox binary="true" formControlName="primaryDues" label="Primary Employer" tabindex="0"></p-checkbox>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select (emitData)="getEmployerData($event)" [inputObj]="inputObjEmployer" class="full-width"></app-select>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select (emitData)="getJobTitleData($event)" [inputObj]="inputObjJobTitle" class="full-width"></app-select>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select (emitData)="getWorkSiteData($event)" [inputObj]="inputObjWorkSite" class="full-width"></app-select>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select (emitData)="getContractData($event)" [inputObj]="inputObjContract" class="full-width"></app-select>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select (emitData)="getWorkLevelData($event)" [inputObj]="inputObjWorkLevel" class="full-width"></app-select>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select (emitData)="getWageLevelData($event)" [inputObj]="inputObjWageLevel" class="full-width"></app-select>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <label>Employee Identification Number (EIN)</label>
      <input formControlName="employmentEIN" pInputText tabindex="0" type="text">
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select (emitData)="getShiftData($event)" [inputObj]="inputObjShift" class="full-width"></app-select>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 input-container">
      <label>Start Date <sup class="req">*</sup></label>
      <p-calendar [maxDate]="mainForm.controls.endDate.value" [showIcon]="true" [yearNavigator]="true" appendTo="body" dateFormat="mm/dd/yy" dateMask formControlName="startDate" placeholder="mm/dd/yyyy" tabindex="0" yearRange="1950:2025"></p-calendar>
      @if ((mainForm.controls.startDate.dirty || mainForm.controls.startDate.touched) &&
      mainForm.controls.startDate.errors?.required) {
        <span class="error">'Start Date' must be provided</span>
      }
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 input-container">
      <label>End Date</label>
      <p-calendar [minDate]="mainForm.controls.startDate.value" [showIcon]="true" [yearNavigator]="true" appendTo="body" dateFormat="mm/dd/yy" dateMask formControlName="endDate" placeholder="mm/dd/yyyy" tabindex="0" yearRange="1950:2025"></p-calendar>
    </div>
    @if (canTabWrite) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12"></div>
          <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
            @if (isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                      (click)="cancel()" [disabled]="!mainForm.dirty"></button>
            } @else {
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                      (click)="cancel()"></button>
            }
            @if (!isSaving) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                      (click)="processData()" [className]="'inline-button'"
                      [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
            } @else {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </div>
      </div>
    }
  </div>
</form>
