<div class="parent-p-card">
  <div class="p-grid p-fluid">
    <div class="col-md-3 col-sm-3 col-xs-0"></div>
    <div class="col-md-6 col-sm-6 col-xs-12">
      <p-card>
        <h2><i class="fas fa-egg-fried"></i> Record Not Found</h2>
        <p>The record you are trying to access is either secured beyond your permissions or does not exist. Please return to where you came from or review the list for more information. Thank you!</p>
        <div style="display: inline-block;">
          <button pButton label="Back" (click)="back()" class="back-button p-mr-2" icon="pi pi-angle-left" pTooltip="Back" tooltipPosition="bottom"></button>
          <button pButton label="List" (click)="list()" class="back-button" icon="fas fa-list" pTooltip="List" tooltipPosition="bottom"></button>
        </div>
      </p-card>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-0"></div>
  </div>
</div>
