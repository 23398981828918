import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {DialogService} from 'primeng/dynamicdialog';
import {MediaManagerDialogComponent} from './media-manager/media-manager-dialog.component';

declare let unlayer: any;

@Component({
  selector: 'app-unlayer-editor',
  templateUrl: './unlayer-editor.component.html',
  styleUrls: ['./unlayer-editor.component.scss'],
})
export class UnlayerEditorComponent implements OnInit, OnChanges {
  public showBox: boolean = false;
  @Input() clear: boolean;
  @Input() reload: boolean;
  @Input() designContent: string;
  @Input() htmlContent: string;
  @Input() isEdit: boolean;
  @Output() saveToParent = new EventEmitter<any>();
  dialogLoading: boolean = false;

  constructor(private dialogService: DialogService, private changeDetectorRef: ChangeDetectorRef,
              private _renderer2: Renderer2, @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    const scriptElement = this.loadJsScript(this._renderer2, '//editor.unlayer.com/embed.js');
    scriptElement.onload = () => {
      unlayer.init({
        id: 'editorContainer',
        projectId: 43653,
        displayMode: 'email',
        safeHtml: true,
        version: '1.5.75',
        features: {
          ai: false
        }
      });
      this.editorReady();
    };
    scriptElement.onerror = () => {
      console.log('Could not load the Unlayer Embed API Script!');
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.designContent) {
      //  this.loadEditor = true;
    }
    if (changes.clear) {
      if (this.clear && unlayer) {
        unlayer.loadBlank({
          backgroundColor: '#e7e7e7'
        });
      }
    }
    if (changes.reload) {
      if (this.reload && unlayer) {
        unlayer.loadDesign(JSON.parse(this.designContent));
      }
    }
  }

  loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }

  // called when the editor has finished loading
  editorReady() {
    if (this.isEdit) {
      unlayer.loadDesign(JSON.parse(this.designContent));
    }
    unlayer.addEventListener('selectImage', (image, done) => {
      this.showBox = true;
      this.dialogLoading = true;
      this.changeDetectorRef.detectChanges();
      const ref = this.dialogService.open(MediaManagerDialogComponent, {
        header: 'Media Manager',
        width: '70%'
      });
      ref.onClose.subscribe({
        next: (res) => {
          this.dialogLoading = false;
          if (res !== undefined) {
            done({url: res});
          }
        }
      });
    });

    unlayer.addEventListener('design:updated', updates => {
      // Design is updated by the user
      unlayer.exportHtml(data => {
        this.designContent = JSON.stringify(data.design);
        this.htmlContent = data.html;
        this.saveToParent.emit(data);
      });
    });
  }
}
