<!--<input type="file" (change)="fileChangeHandler($event)">-->
<!--<img [src]="output?.base64" alt="">-->
<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12 align-center">
    @if (incomingImageUrl != '' || incomingImageUrl != null) {
      <img class="header-image" [src]="incomingImageUrl" alt="" width="100%"/>
    }
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
    <div class="text-align-middle upload-buttons">
      <label pButton icon="pi pi-upload" class="p-button-download export-menu image-button upload-icon" pTooltip="Upload" tooltipPosition="bottom">
        <input type="file" (change)="fileChangeHandler($event)">
      </label>
      @if (showWebCam) {
        <button  pButton class="p-button-download export-menu image-button p-ml-2 p-mr-2" icon="pi pi-camera"
          pTooltip="Take Picture" tooltipPosition="bottom" (click)="openWebCam()">
        </button>
      }
      <!--  <p-fileUpload *ngIf="!incomingImageUrl" class="p-button-download export-menu image-button" mode="basic" name="myfile[]" (onSelect)="fileChangeHandler($event)" accept="image/*" customUpload="true" chooseIcon="pi pi-upload" maxFileSize="4e+6"></p-fileUpload>-->
      @if (incomingImageUrl) {
        <button (click)="removeImage()" pButton icon="pi pi-trash" class="p-button-download export-menu image-button" pTooltip="Delete" tooltipPosition="bottom"></button>
      }
    </div>
  </div>
</div>
