import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './shared/guards/can-activate.guard';
import {CanDeactivateGuard} from './services/can-deactivate-guard.service';
import {PreloadModulesStrategy} from './core/strategies/preload-modules.strategy';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/dashboard'},

  {path: 'login', data: {preload: true}, loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},

  {path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)},

  {
    path: 'dashboard', canActivate: [AuthGuardService],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  {
    path: 'widget-dashboard', canActivate: [AuthGuardService],
    loadChildren: () => import('./dashboard-widgets/dashboard-widgets.module').then(m => m.DashboardWidgetsModule)
  },

  {
    path: 'snapshots', canActivate: [AuthGuardService],
    loadChildren: () => import('./snapshots/snapshots.module').then(m => m.SnapshotsModule)
  },

  {
    path: 'security-questions', canActivate: [AuthGuardService],
    loadChildren: () => import('./security-questions/security-questions.module').then(m => m.SecurityQuestionsModule)
  },

  {
    path: 'reset-password', canActivate: [AuthGuardService],
    loadChildren: () => import('./reset-password-authenticated/reset-password-authenticated.module').then(m => m.ResetPasswordAuthenticatedModule)
  },

  {
    path: 'admin/restore-person', canActivate: [AuthGuardService],
    loadChildren: () => import('./person-restore/person-restore.module').then(m => m.PersonRestoreModule)
  },

  {
    path: 'membership/account-adjustments', canActivate: [AuthGuardService],
    loadChildren: () => import('./account-adjustments/account-adjustments.module').then(m => m.AccountAdjustmentsModule)
  },

  {
    path: 'membership/accounts', canActivate: [AuthGuardService],
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)
  },

  {
    path: 'alerts', canActivate: [AuthGuardService],
    loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule)
  },

  {
    path: 'my-time', canActivate: [AuthGuardService],
    loadChildren: () => import('./my-time/my-time.module').then(m => m.MyTimeModule)
  },

  {
    path: 'membership/billing', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule)
  },

  {
    path: 'membership/billing-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing-templates/billing-templates.module').then(m => m.BillingTemplatesModule)
  },

  {
    path: 'membership/billing-configuration', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing-configuration/billing-configuration.module').then(m => m.BillingConfigurationModule)
  },

  {
    path: 'membership/disperse', canActivate: [AuthGuardService],
    loadChildren: () => import('./disperse/disperse.module').then(m => m.DisperseModule)
  },

  {
    path: 'training/bulk-time', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-time/bulk-time.module').then(m => m.BulkTimeModule)
  },

  {
    path: 'membership/bulk-updates', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-updates/bulk-updates.module').then(m => m.BulkUpdatesModule)
  },

  {
    path: 'training/bulk-updates', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-updates/bulk-updates.module').then(m => m.BulkUpdatesModule)
  },

  {
    path: 'training/bulk-custom-fields', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-custom-fields/bulk-custom-fields.module').then(m => m.BulkCustomFieldsModule)
  },

  {
    path: 'membership/bulk-suspension', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-suspension/bulk-suspension.module').then(m => m.BulkSuspensionModule)
  },

  {
    path: 'membership/bulk-receipts', canActivate: [AuthGuardService],
    loadChildren: () => import('./bulk-receipts/bulk-receipts.module').then(m => m.BulkReceiptsModule)
  },

  {
    path: 'membership/balance-report', canActivate: [AuthGuardService],
    loadChildren: () => import('./balance-report/balance-report.module').then(m => m.BalanceReportModule)
  },

  {
    path: 'membership/cases', canActivate: [AuthGuardService],
    loadChildren: () => import('./cases/cases.module').then(m => m.CasesModule)
  },

  {
    path: 'membership/case-workflows', canActivate: [AuthGuardService],
    loadChildren: () => import('./case-workflows/case-workflows.module').then(m => m.CaseWorkflowsModule)
  },

  {
    path: 'membership/contracts', canActivate: [AuthGuardService],
    loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule)
  },

  {
    path: 'membership/contributions', canActivate: [AuthGuardService],
    loadChildren: () => import('./contributions/contributions.module').then(m => m.ContributionsModule)
  },

  {
    path: 'membership/organizations', canActivate: [AuthGuardService],
    loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule)
  },

  {
    path: 'membership/missing-data', canActivate: [AuthGuardService],
    loadChildren: () => import('./missing-data/missing-data.module').then(m => m.MissingDataModule)
  },

  {
    path: 'membership/officer-directory', canActivate: [AuthGuardService],
    loadChildren: () => import('./officer-directory/officer-directory.module').then(m => m.OfficerDirectoryModule)
  },

  {
    path: 'membership/membership-cards', canActivate: [AuthGuardService],
    loadChildren: () => import('./batch-membership-cards/batch-membership-cards.module').then(m => m.BatchMembershipCardsModule)
  },

  {
    path: 'reports', canActivate: [AuthGuardService],
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
  },

  {
    path: 'reports-landing', canActivate: [AuthGuardService],
    loadChildren: () => import('./reports-landing-page/reports-landing-page.module').then(m => m.ReportsLandingPageModule)
  },

  {
    path: 'report-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./report-builder/report-builder.module').then(m => m.ReportBuilderModule)
  },

  {
    path: 'training/classes', canActivate: [AuthGuardService],
    loadChildren: () => import('./classes-modules/classes.module').then(m => m.ClassesModule)
  },

  {
    path: 'training/training-modules', canActivate: [AuthGuardService],
    loadChildren: () => import('./training-modules/training-modules.module').then(m => m.TrainingModulesModule)
  },

  {
    path: 'membership/communications', canActivate: [AuthGuardService],
    loadChildren: () => import('./communications/communications.module').then(m => m.CommunicationsModule)
  },

  {
    path: 'training/communications', canActivate: [AuthGuardService],
    loadChildren: () => import('./communications/communications.module').then(m => m.CommunicationsModule)
  },

  {
    path: 'organize/communications', canActivate: [AuthGuardService],
    loadChildren: () => import('./communications/communications.module').then(m => m.CommunicationsModule)
  },

  {
    path: 'organize/project-reports', canActivate: [AuthGuardService],
    loadChildren: () => import('./project-reports/project-reports.module').then(m => m.ProjectReportsModule)
  },


  {
    path: 'training/courses', canActivate: [AuthGuardService],
    loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
  },

  {
    path: 'membership/email-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./email-templates/email-templates.module').then(m => m.EmailTemplatesModule)
  },

  {
    path: 'membership/report-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./report-builder/report-builder.module').then(m => m.ReportBuilderModule)
  },

  {
    path: 'training/email-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./email-templates/email-templates.module').then(m => m.EmailTemplatesModule)
  },

  {
    path: 'organize/email-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./email-templates/email-templates.module').then(m => m.EmailTemplatesModule)
  },

  {
    path: 'membership/employers', canActivate: [AuthGuardService],
    loadChildren: () => import('./employers/employers.module').then(m => m.EmployersModule)
  },

  {
    path: 'membership/events', canActivate: [AuthGuardService],
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
  },

  {
    path: 'membership/event-instances', canActivate: [AuthGuardService],
    loadChildren: () => import('./event-instances/event-instances.module').then(m => m.EventInstancesModule)
  },

  {
    path: 'membership/event-locations', canActivate: [AuthGuardService],
    loadChildren: () => import('./event-locations/event-locations.module').then(m => m.EventLocationsModule)
  },

  {
    path: 'training/facets', canActivate: [AuthGuardService],
    loadChildren: () => import('./facets/facets.module').then(m => m.FacetsModule)
  },

  {
    path: 'membership/form-tracker', canActivate: [AuthGuardService],
    loadChildren: () => import('./form-tracker/form-tracker.module').then(m => m.FormTrackerModule)
  },

  {
    path: 'training/students-certifications', canActivate: [AuthGuardService],
    loadChildren: () => import('./students-certifications/students-certifications.module').then(m => m.StudentsCertificationsModule)
  },

  {
    path: 'membership/mail-merge-batches', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-batches/mail-merge-batches.module').then(m => m.MailMergeBatchesModule)
  },

  {
    path: 'training/mail-merge-batches', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-batches/mail-merge-batches.module').then(m => m.MailMergeBatchesModule)
  },

  {
    path: 'organize/mail-merge-batches', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-batches/mail-merge-batches.module').then(m => m.MailMergeBatchesModule)
  },

  {
    path: 'employment', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-employment/user-employment.module').then(m => m.UserEmploymentModule)
  },

  {
    path: 'education', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-education/user-education.module').then(m => m.UserEducationModule)
  },

  {
    path: 'communications', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-communications/user-communications.module').then(m => m.UserCommunicationsModule)
  },

  {
    path: 'points', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-points/user-points.module').then(m => m.UserPointsModule)
  },

  {
    path: 'membership/mail-merge-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-templates/mail-merge-templates.module').then(m => m.MailMergeTemplatesModule)
  },

  {
    path: 'training/mail-merge-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-templates/mail-merge-templates.module').then(m => m.MailMergeTemplatesModule)
  },

  {
    path: 'organize/mail-merge-templates', canActivate: [AuthGuardService],
    loadChildren: () => import('./mail-merge-templates/mail-merge-templates.module').then(m => m.MailMergeTemplatesModule)
  },

  {
    path: 'organize/merge-duplicates', canActivate: [AuthGuardService],
    loadChildren: () => import('./merge-duplicates/merge-duplicates.module').then(m => m.MergeDuplicatesModule)
  },

  {
    path: 'membership/member-counts', canActivate: [AuthGuardService],
    loadChildren: () => import('./member-counts/member-counts.module').then(m => m.MemberCountsModule)
  },

  {
    path: 'membership/merge-duplicates', canActivate: [AuthGuardService],
    loadChildren: () => import('./merge-duplicates/merge-duplicates.module').then(m => m.MergeDuplicatesModule)
  },

  {
    path: 'membership/personnel-files', canActivate: [AuthGuardService],
    loadChildren: () => import('./personnel-files/personnel-files.module').then(m => m.PersonnelFilesModule)
  },

  {
    path: 'membership/roster', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster/roster.module').then(m => m.RosterModule)
  },

  // { path: 'membership/roster-add', canActivate: [AuthGuardService],
  //   loadChildren: () => import('./roster-add/roster-add.module').then(m => m.RosterAddModule) },

  // { path: 'membership/roster-add-non', canActivate: [AuthGuardService],
  //   loadChildren: () => import('./roster-add-non/roster-add-non.module').then(m => m.RosterAddNonModule) },

  {
    path: 'add-profile', canActivate: [AuthGuardService],
    loadChildren: () => import('./add-profile-with-dup-check/add-profile-with-dup-check.module').then(m => m.AddProfileWithDupCheckModule)
  },

  {
    path: 'profiles', canActivate: [AuthGuardService],
    loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule)
  },

  {
    path: 'membership/global-roster', canActivate: [AuthGuardService],
    loadChildren: () => import('./global-roster/global-roster.module').then(m => m.GlobalRosterModule)
  },

  {
    path: 'membership/survey-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./survey-builder/survey-builder.module').then(m => m.SurveyBuilderModule)
  },

  {
    path: 'training/survey-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./survey-builder/survey-builder.module').then(m => m.SurveyBuilderModule)
  },

  {
    path: 'organize/survey-builder', canActivate: [AuthGuardService],
    loadChildren: () => import('./survey-builder/survey-builder.module').then(m => m.SurveyBuilderModule)
  },

  {
    path: 'training/training-centers', canActivate: [AuthGuardService],
    loadChildren: () => import('./training-centers/training-centers.module').then(m => m.TrainingCentersModule)
  },

  {
    path: 'training/apprentice-hours-import', canActivate: [AuthGuardService],
    loadChildren: () => import('./apprentice-hours-import/apprentice-hours-import.module').then(m => m.ApprenticeHoursImportModule)
  },

  {
    path: 'civic', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-civic/civic.module').then(m => m.CivicModule)
  },

  {
    path: 'user-agreement', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-agreement/user-agreement.module').then(m => m.UserAgreementModule)
  },

  {
    path: 'user-help', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-help/user-help.module').then(m => m.UserHelpModule)
  },

  {
    path: 'about', canActivate: [AuthGuardService],
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
  },

  {
    path: 'release-notes', canActivate: [AuthGuardService],
    loadChildren: () => import('./release-notes/release-notes.module').then(m => m.ReleaseNotesModule)
  },

  {
    path: 'privacy-policy', canActivate: [AuthGuardService],
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },

  {
    path: 'training/programs', canActivate: [AuthGuardService],
    loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule)
  },

  {
    path: 'organize/prospects', canActivate: [AuthGuardService],
    loadChildren: () => import('./prospects/prospects.module').then(m => m.ProspectsModule)
  },

  {
    path: 'organize/campaigns', canActivate: [AuthGuardService],
    loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule)
  },

  {
    path: 'wall', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-wall/user-wall.module').then(m => m.UserWallModule)
  },

  {
    path: 'select-options', canActivate: [AuthGuardService],
    loadChildren: () => import('./select-options/select-options.module').then(m => m.SelectOptionsModule)
  },

  {
    path: 'select-options-landing', canActivate: [AuthGuardService],
    loadChildren: () => import('./select-options-landing-page/select-options-landing-page.module').then(m => m.SelectOptionsLandingPageModule)
  },

  {
    path: 'settings', canActivate: [AuthGuardService],
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },

  {
    path: 'training/students', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-student/roster-student.module').then(m => m.RosterStudentModule)
  },

  {
    path: 'training/global-students', canActivate: [AuthGuardService],
    loadChildren: () => import('./global-roster-student/global-roster-student.module').then(m => m.GlobalRosterStudentModule)
  },

  {
    path: 'training/roster-student-add', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-student-add/roster-student-add.module').then(m => m.RosterStudentAddModule)
  },

  {
    path: 'account', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-account/user-account.module').then(m => m.UserAccountModule)
  },

  {
    path: 'documents', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-documents/user-documents.module').then(m => m.UserDocumentsModule)
  },

  {
    path: 'google-drive', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-google-drive/user-google-drive.module').then(m => m.GoogleDriveModule)
  },

  {
    path: 'profile', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)
  },

  {
    path: 'account', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-account/user-account.module').then(m => m.UserAccountModule)
  },

  {
    path: 'surveys', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-surveys/user-surveys.module').then(m => m.UserSurveysModule)
  },

  {
    path: 'time', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-time/user-time.module').then(m => m.UserTimeModule)
  },

  {
    path: 'users', canActivate: [AuthGuardService],
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },

  {
    path: 'admin/duplicate-data-tool', canActivate: [AuthGuardService],
    loadChildren: () => import('./duplicate-data-tool/duplicate-data-tool.module').then(m => m.DuplicateDataToolModule)
  },

  {
    path: 'unlinked-users', canActivate: [AuthGuardService],
    loadChildren: () => import('./unlinked-users/unlinked-users.module').then(m => m.UnlinkedUsersModule)
  },

  {
    path: 'admin/help-setup', canActivate: [AuthGuardService],
    loadChildren: () => import('./help-setup/help-setup.module').then(m => m.HelpSetupModule)
  },

  {
    path: 'tenant-admin-landing', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-admin-landing-page/tenant-admin-landing-page.module').then(m => m.TenantAdminLandingPageModule)
  },

  {
    path: 'tenant-admin-options', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-admin-options/tenant-admin-options.module').then(m => m.TenantAdminOptionsModule)
  },

  {
    path: 'admin/pathways', canActivate: [AuthGuardService],
    loadChildren: () => import('./pathways/pathways.module').then(m => m.PathwaysModule)
  },

  {
    path: 'admin/roles', canActivate: [AuthGuardService],
    loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule)
  },

  {
    path: 'membership/roster-queries', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-queries/roster-queries.module').then(m => m.RosterQueriesModule)
  },

  {
    path: 'training/roster-queries', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-queries/roster-queries.module').then(m => m.RosterQueriesModule)
  },

  {
    path: 'organize/roster-queries', canActivate: [AuthGuardService],
    loadChildren: () => import('./roster-queries/roster-queries.module').then(m => m.RosterQueriesModule)
  },

  {
    path: 'admin/type-positions', canActivate: [AuthGuardService],
    loadChildren: () => import('./type-positions/type-positions.module').then(m => m.TypePositionsModule)
  },

  {
    path: 'membership/vitals', canActivate: [AuthGuardService],
    loadChildren: () => import('./vitals/vitals.module').then(m => m.VitalsModule)
  },

  {
    path: 'membership/worksites', canActivate: [AuthGuardService],
    loadChildren: () => import('./worksites/worksites.module').then(m => m.WorksitesModule)
  },

  {
    path: 'membership/billing-distribution', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing-distribution/billing-distribution.module').then(m => m.BillingDistributionModule)
  },

  {
    path: 'membership/stipend-setup', canActivate: [AuthGuardService],
    loadChildren: () => import('./stipend-setup/stipend-setup.module').then(m => m.StipendSetupModule)
  },

  {
    path: 'membership/payments-received', canActivate: [AuthGuardService],
    loadChildren: () => import('./payments-received/payments-received.module').then(m => m.PaymentsReceivedModule)
  },

  {
    path: 'membership/payment-files-export', canActivate: [AuthGuardService],
    loadChildren: () => import('./payment-files-export/payment-files-export.module').then(m => m.PaymentFilesExportModule)
  },

  {
    path: 'membership/cope', canActivate: [AuthGuardService],
    loadChildren: () => import('./cope/cope.module').then(m => m.CopeModule)
  },

  {
    path: 'page-not-found', canActivate: [AuthGuardService],
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },

  {
    path: 'membership/billing-adjustments', canActivate: [AuthGuardService],
    loadChildren: () => import('./billing-adjustments/billing-adjustments.module').then(m => m.BillingAdjustmentsModule)
  },

  {
    path: 'membership/payment-files', canActivate: [AuthGuardService],
    loadChildren: () => import('./payment-files/payment-files.module').then(m => m.PaymentFilesModule)
  },

  {
    path: 'membership/pct-billing', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-billing/pct-billing.module').then(m => m.PctBillingModule)
  },

  {
    path: 'membership/pct-adjustments', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-adjustments/pct-adjustments.module').then(m => m.PctAdjustmentsModule)
  },

  {
    path: 'membership/pct-configuration', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-configuration/pct-configuration.module').then(m => m.PctConfigurationModule)
  },

  {
    path: 'membership/pct-disperse', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-disperse/pct-disperse.module').then(m => m.PctDisperseModule)
  },

  {
    path: 'membership/pct-payment-details', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-payment-details/pct-payment-details.module').then(m => m.PctPaymentDetailsModule)
  },

  {
    path: 'membership/pct-payment-received', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-payment-received/pct-payment-received.module').then(m => m.PctPaymentReceivedModule)
  },

  {
    path: 'membership/pct-payments', canActivate: [AuthGuardService],
    loadChildren: () => import('./pct-payments/pct-payments.module').then(m => m.PctPaymentsModule)
  },

  {
    path: 'membership/stipends', canActivate: [AuthGuardService],
    loadChildren: () => import('./stipends/stipends.module').then(m => m.StipendsModule)
  },

  {
    path: 'membership/retiree-service', canActivate: [AuthGuardService],
    loadChildren: () => import('./retiree-service/retiree-service.module').then(m => m.RetireeServiceModule)
  },

  {
    path: 'membership/years-of-service', canActivate: [AuthGuardService],
    loadChildren: () => import('./years-of-service/years-of-service.module').then(m => m.YearsOfServiceModule)
  },

  {
    path: 'membership/convention-registration-receipts', canActivate: [AuthGuardService],
    loadChildren: () => import('./convention-registration-receipts/convention-registration-receipts.module').then(m => m.ConventionRegistrationReceiptsModule)
  },

  {
    path: 'membership/new-members', canActivate: [AuthGuardService],
    loadChildren: () => import('./new-members/new-members.module').then(m => m.NewMembersModule)
  },

  {
    path: 'membership/ballots', canActivate: [AuthGuardService],
    loadChildren: () => import('./ballots/ballots.module').then(m => m.BallotsModule)
  },

  {
    path: 'officer-directory', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-officer-directory/user-officer-directory.module').then(m => m.UserOfficerDirectoryModule)
  },

  {
    path: 'training/class-payments', canActivate: [AuthGuardService],
    loadChildren: () => import('./class-payments/class-payments.module').then(m => m.ClassPaymentsModule)
  },

  {
    path: 'training/employers', canActivate: [AuthGuardService],
    loadChildren: () => import('./employers/employers.module').then(m => m.EmployersModule)
  },

  {
    path: 'training/training-stipends', canActivate: [AuthGuardService],
    loadChildren: () => import('./training-stipends/training-stipend-batches.module').then(m => m.TrainingStipendBatchesModule)
  },

  {path: '**', pathMatch: 'full', redirectTo: '/page-not-found'} // catch any unfound routes and redirect to home page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadModulesStrategy})],
  exports: [RouterModule],
  providers: [PreloadModulesStrategy, CanDeactivateGuard]
})
export class AppRoutingModule {
}
