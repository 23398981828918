@if (invalidRecord) {
  <app-record-not-found></app-record-not-found>
}
@if (!invalidRecord) {
  <div class="content p-input-filled margin-top-10">
    <div class="p-grid p-fluid">
      @if (isPage && !isDialog) {
        <div class="col-md-12 col-sm-12 col-xs-12 add-edit-header">
          @if (!invalidRecord && isPage && !isDialog) {
            <div class="inline-back-button" (click)="back()" pTooltip="Back">
              <i class="pi pi-angle-left"></i>
            </div>
          }
          @if (isStatusInActive(roster.StatusAlias)) {
            <div class="inactive-flag-hard-corner"></div>
          }
          @if (roster.StatusAlias === 'Suspended') {
            <div class="suspended-flag-hard-corner"></div>
          }
          <div class="p-grid p-fluid p-ml-2">
            <div class="col-md-1 col-sm-3 col-xs-12 relative tab-container-profile">
              @if (roster.ThumbnailUrl) {
                <div>
                  <div class="avatar-with-image" [ngClass]="getAvatarClass(true, roster.StatusAlias)"><img
                    ngSrc="{{roster.ThumbnailUrl + blobToken}}" (error)="setDefaultPic()" alt="" width="71" height="71"
                    [priority]="true"/></div>
                </div>
              }
              @if (!roster.ThumbnailUrl) {
                <div>
                  <div class="avatar-without-image" [ngClass]="getAvatarClass(false, roster.StatusAlias)"><img
                    ngSrc="assets/images/profile-generic.png" alt="" width="71" height="71" [priority]="true"/></div>
                </div>
              }
            </div>
            @if (roster) {
              <div class="col-md-6 col-sm-6 col-xs-12">
                @if (isEdit && roster) {
                  <h2>{{ roster.FirstName }} {{ roster.MiddleName }} {{ roster.LastName }}@if (roster.Suffix) {
                    <span> {{ roster.Suffix }}</span>
                  } - {{ roster.OrganizationAbbreviation }}</h2>
                  <h4>
                    @if (roster.StatusAlias) {
                      <span
                        class="p-m-0"> <b>{{ roster.StatusAlias }} {{ roster.TypeAlias }}@if (roster.MemberSubclass) {
                        <span> - {{ roster.MemberSubclass | firstLetter }}</span>
                      }</b></span>
                    }
                    @if (roster.MemberNumber) {
                      <span class="p-m-0"> | <b>Member #:</b> {{ roster.MemberNumber }}</span>
                    }
                    @if (roster.EmployerName) {
                      <span class="p-m-0"> | <b>Employer:</b> {{ roster.EmployerName }}</span>
                    }
                    @if (roster.MemberStartDate) {
                      <span class="p-m-0"> | <b>Initiated:</b> {{ roster.MemberStartDate | date: 'shortDate' }}</span>
                    }
                    @if (roster.YearsOfServiceCumulative) {
                      <span class="p-m-0"> | <b>Years of Service:</b> {{ roster.YearsOfServiceCumulative }}
                        @if (roster.YearsOfServiceConsecutive !== roster.YearsOfServiceCumulative) {
                          <span class="p-m-0">({{ roster.YearsOfServiceConsecutive }} Consecutive)</span>
                        }
                    </span>
                    }
                    @if (roster.PaidThroughDate) {
                      <span
                        class="p-m-0"> | <b>Paid Through:</b> {{ roster.PaidThroughDate | date : 'MM/dd/yyy' }}</span>
                    }
                    @if (roster.SecondaryPhoneNumber) {
                      <span class="p-m-0"> | <b>Mobile:</b> {{ roster.SecondaryPhoneNumber | phone }}</span>
                    }
                    @if (roster.PrimaryEmailAddress) {
                      <span class="p-m-0"> | <b>Primary:</b> {{ roster.PrimaryEmailAddress }}</span>
                    }
                    @if (roster.HasAccount === 'Yes') {
                      <span class="p-m-0"> | <i class="fas fa-user"></i></span>
                    }
                    @if (roster.LastLoginDate_D) {
                      <span class="p-m-0"> | <b>Last Login:</b> {{ roster.LastLoginDate_D | localizedatetime:true:true }}</span>
                    }
                  </h4>
                } @else {
                  <h2>Add Roster</h2>
                }
              </div>
              <div class="col-md-5 col-sm-5 col-xs-12 list-container list-container-header">
                <div class="list-action-buttons p-mr-2">
                  <app-roster-action-buttons [canPageWrite]="canPageWrite" [roster]="roster"
                                             [i]="0" [permissions]="permissions"
                                             [blobToken]="blobToken"></app-roster-action-buttons>
                </div>
              </div>
            }
          </div>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12">
        @if (!isPage || isDialog) {
          <p-divider></p-divider>
        }
        @if (showTabs) {
          <p-tabView (onChange)="resetWarning()">
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDemographics')) {
              <p-tabPanel [cache]="false" header="Contact"
                          [selected]="_tabsPermissionsUtility.checkTabRead(permissions,'tabDemographics')">
                <ng-template pTemplate="content">
                  <app-roster-contact-form [personID]="ContentID" [isEdit]="isEdit"
                                           [canEdit]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDemographics')"></app-roster-contact-form>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabMembership') && _activityDrivenMembers()) {
              <p-tabPanel [cache]="false" header="Membership">
                <ng-template pTemplate="content">
                  <app-roster-tab-membership-activities [PersonID]="ContentID"
                                                        [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabMembership')"
                                                        [isEdit]="isEdit"
                                                        (closeAddEdit)="closeAddEdit.emit($event)"></app-roster-tab-membership-activities>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabMembership') && !_activityDrivenMembers()) {
              <p-tabPanel [cache]="false" header="Membership">
                <ng-template pTemplate="content">
                  <app-roster-tab-membership [PersonID]="ContentID"
                                             [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabMembership')"
                                             [isEdit]="isEdit"
                                             (closeAddEdit)="closeAddEdit.emit($event)"></app-roster-tab-membership>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPii')) {
              <p-tabPanel [cache]="false" header="Secure Data">
                <ng-template pTemplate="content">
                  <app-roster-tab-secure-data [PersonID]="ContentID"
                                              [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabPii')"
                                              [isEdit]="isEdit"></app-roster-tab-secure-data>
                </ng-template>
              </p-tabPanel>
            }
            <!--        <p-tabPanel header="Employment" *ngIf="_tabsPermissionsUtility.checkTabRead(permissions,'tabEmployment')">-->
            <!--          <ng-template pTemplate="content">-->
            <!--            <app-employment-trade-tab [PersonID]="ContentID" [OrganizationID]="roster.OrganizationID" [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployment')" [isEdit]="isEdit" [interfaceObjectDesc]="'tabEmployment'" [parentObjectEnum]="InterfaceObjectEnum"></app-employment-trade-tab>-->
            <!--          </ng-template>-->
            <!--        </p-tabPanel>-->
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEmployment') && _employmentTradeTab()) {
              <p-tabPanel header="Employment">
                <ng-template pTemplate="content">
                  <app-employment-trade-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabEmployment')"
                    [PersonID]="ContentID" [OrganizationID]="roster.OrganizationID" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployment')"
                    [interfaceObjectDesc]="'tabEmployment'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-employment-trade-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEmployment') && !_employmentTradeTab()) {
              <p-tabPanel header="Employment">
                <ng-template pTemplate="content">
                  <app-employment-default-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabEmployment')"
                    [PersonID]="ContentID" [OrganizationID]="roster.OrganizationID" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployment')"
                    [interfaceObjectDesc]="'tabEmployment'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-employment-default-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPrograms')) {
              <p-tabPanel header="Programs">
                <ng-template pTemplate="content">
                  <app-roster-student-programs-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabPrograms')"
                    [PersonID]="ContentID" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabPrograms')"></app-roster-student-programs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabTrain')) {
              <p-tabPanel header="Training & Certification">
                <ng-template pTemplate="content">
                  <app-roster-student-training-certification [DBEntity]="'PERSON'" [DBEntityID]="ContentID"
                                                             [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabClassHistory')"
                                                             [interfaceObjectDesc]="'tabClassHistory'"
                                                             [parentObjectEnum]="InterfaceObjectEnum"></app-roster-student-training-certification>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabAccount')) {
              <p-tabPanel [cache]="false" header="Account">
                <ng-template pTemplate="content">
                  <span
                    class="p-mb-2"><b>Account Balance:</b>&nbsp;{{ roster.AccountBalance | currency:'USD':'symbol':'1.2-2' }}
                    | <b>Automatic Payments:</b> {{ roster.AutoPay }}</span>
                  <app-account-transactions-tab [PersonID]="ContentID" [interfaceObjectDesc]="'tabAccount'"
                                                [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabAccount')"
                                                (updateProfileAccount)="getDataToEdit(ContentID)"
                                                [parentObjectEnum]="InterfaceObjectEnum"
                                                [organizationId]="organizationId"></app-account-transactions-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabTradesSkills')) {
              <p-tabPanel header="Trades/Skills" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-trade-skills
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabTradesSkills')"
                    [ContentID]="ContentID" DBEntity="Profile" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabTradesSkills')"></app-trade-skills>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCommunications')) {
              <p-tabPanel header="Communications">
                <ng-template pTemplate="content">
                  <app-communications-user-profile-tab [DBEntityID]="ContentID"
                                                       [interfaceObjectDesc]="'tabCommunications'"
                                                       [parentObjectEnum]="InterfaceObjectEnum"></app-communications-user-profile-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCases')) {
              <p-tabPanel header="Cases">
                <ng-template pTemplate="content">
                  <app-roster-tab-cases [PersonID]="ContentID" [interfaceObjectDesc]="'tabCases'"
                                        [parentObjectEnum]="InterfaceObjectEnum"></app-roster-tab-cases>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEvents')) {
              <p-tabPanel header="Events">
                <ng-template pTemplate="content">
                  <app-roster-tab-events [PersonID]="ContentID" [interfaceObjectDesc]="'tabEvents'"
                                         [parentObjectEnum]="InterfaceObjectEnum"></app-roster-tab-events>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabVoter')) {
              <p-tabPanel header="Voter">
                <ng-template pTemplate="content">
                  <app-politics-tab [PersonID]="ContentID" [interfaceObjectDesc]="'tabVoter'"
                                    [parentObjectEnum]="InterfaceObjectEnum"></app-politics-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPreferences')) {
              <p-tabPanel header="Preferences">
                <ng-template pTemplate="content">
                  <app-roster-tab-preferences [PersonID]="ContentID" [interfaceObjectDesc]="'tabPreferences'"
                                              [parentObjectEnum]="InterfaceObjectEnum"></app-roster-tab-preferences>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabChangeLog')) {
              <p-tabPanel header="Change Log">
                <ng-template pTemplate="content">
                  <app-change-logs-tab [PersonID]="ContentID" [interfaceObjectDesc]="'tabChangeLog'"
                                       [parentObjectEnum]="InterfaceObjectEnum"
                                       [context]="'membership'"></app-change-logs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDocs') && roster) {
              <p-tabPanel header="Docs">
                <ng-template pTemplate="content">
                  <app-docs-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabDocs')"
                                [DBEntity]="'PERSONPROFILE'" [DBEntityID]="roster.PersonProfileID"
                                [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDocs')"
                                [showAccess]="true" [InterfaceObjectEnum]="InterfaceObjectEnum"
                                [interfaceObjectDesc]="'tabDocs'" [parentObjectEnum]="InterfaceObjectEnum"
                                [PersonID]="roster.PersonID"></app-docs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabNotes')) {
              <p-tabPanel header="Notes">
                <ng-template pTemplate="content">
                  <app-notes-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                                 [DBEntity]="'PERSONPROFILE'" [DBEntityID]="roster.PersonProfileID"
                                 [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [canEdit]="_tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [interfaceObjectDesc]="'tabNotes'" [parentObjectEnum]="InterfaceObjectEnum"
                                 [showDisclaimer]="true"></app-notes-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCustomFields')) {
              <p-tabPanel header="Custom Fields">
                <ng-template pTemplate="content">
                  <app-custom-fields-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabCustomFields')"
                    [PersonID]="ContentID"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabCustomFields')"
                    [category]="'union'" [isEdit]="isEdit" [interfaceObjectDesc]="'tabCustomFields'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-custom-fields-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabUserAccount')) {
              <p-tabPanel header="User Account">
                <ng-template pTemplate="content">
                  <app-roster-tab-user-account [PersonID]="ContentID"
                                               [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabUserAccount')"
                                               [isEdit]="isEdit"
                                               (updateImage)="getDataToEdit(ContentID)"></app-roster-tab-user-account>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPoints')) {
              <p-tabPanel header="Points">
                <ng-template pTemplate="content">
                  <app-roster-tab-points [PersonID]="ContentID" [interfaceObjectDesc]="'tabFacets'"
                                         [parentObjectEnum]="InterfaceObjectEnum"></app-roster-tab-points>
                </ng-template>
              </p-tabPanel>
            }
          </p-tabView>
        }
      </div>
    </div>
  </div>
}
