import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  ClassStaffGridModel, ClassStaffModel, ClassStaffPickerGridModel,
  CreateClassStaffModel, UpdateClassStaffModel
} from '../models/class-staff.model';

@Injectable({
  providedIn: 'root',
})
export class ClassStaffService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getClassStaffPrimeNG(dataMode: string, classId: number, filterData): Observable<ClassStaffGridModel> {
    return this.http.post<ClassStaffGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/staff/grid?mode=${dataMode}`, filterData);
  }

  getClassStaffPickerPrimeNG(dataMode: string, classId: number, filterData): Observable<ClassStaffPickerGridModel> {
    return this.http.post<ClassStaffPickerGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/staff/picker?mode=${dataMode}`, filterData);
  }

  getClassStaff(classId: number, classStaffId: number): Observable<ClassStaffModel> {
    return this.http.get<ClassStaffModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/staff/${classStaffId}`);
  }

  createClassStaff(classId: number, modelData: CreateClassStaffModel[]): Observable<ClassStaffModel> {
    return this.http.post<ClassStaffModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/staff/add`, modelData);
  }

  updateClassStaff(classId: number, classStaffId: number,
    modelData: UpdateClassStaffModel): Observable<ClassStaffModel> {
    return this.http.put<ClassStaffModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/staff/${classStaffId}`, modelData);
  }

  deleteClassStaff(classId: number, classStaffId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/staff/remove/${classStaffId}`);
  }
}
