<h4 class="margin-top-5 margin-bottom-5">Please Add your signature here</h4>
<canvas
  #canvas
  (touchstart)="startDrawing($event)"
  (touchmove)="moved($event)"
  [width]="sigWidth"
  height="200"
  class="signature-canvas"
></canvas>
<!--<p class="error-message" *ngIf="signatureNeeded">Signature is required</p>-->

<div class="buttons flex justify-between text-align-right p-mb-3">
  <button pButton class="p-button-info p-mr-2" (click)="clearPad()">Clear</button>
  <button pButton class="p-button-info p-mr-2" (click)="savePad()">Submit Signature</button>
</div>

<div *ngIf="signatureNeeded === false">
  <p>Signature in Base64Encoded Format</p>
  <img src="{{ signatureImg }}" alt="" srcset="" />
</div>
