import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {RosterProfileTransactionModel, RosterProfileTransactionsGridModel} from '../../../../../models/roster-profile-transactions.model';
import {RosterProfileTransactionsService} from '../../../../../services/roster-profile-transactions.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {removeKeysFromArray} from '../../../../utilities/data.utility';
import {exportData} from '../../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../../utilities/list-filter.utility';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';
import {TenantConfigDataService} from '../../../../../services/tenant-config-data.service';
import {DialogUtility} from '../../../../utilities/dialog.utility';
import {usePaidThroughBillingPeriod} from '../../../../utilities/component-manager-utility';

@Component({
  selector: 'app-transaction-history-tab',
  templateUrl: './transaction-history-tab.component.html',
  styleUrls: ['./transaction-history-tab.component.scss']
})

export class TransactionHistoryTabComponent implements OnInit, OnDestroy {
  transactionHistorys: RosterProfileTransactionsGridModel;
  transactionHistorysList: RosterProfileTransactionModel[];
  isExporting: boolean;
  dataLoaded: boolean;
  @Input() PersonID: number;
  @Input() isEdit: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() TypeFilter: string;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() canTabWrite: boolean;
  cols = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  dataTotal: number = 0;
  readyToLoadTable: boolean = false;
  getListRequest;
  getTotalRequest;
  UNGROUPEDTRANSACTIONHISTORYGRID: boolean;
  selectedFilter: number = 0;
  public _usePaidThroughBillingPeriod = usePaidThroughBillingPeriod;

  private ngUnsubscribe = new Subject();

  constructor(private rosterProfileTransactionsService: RosterProfileTransactionsService, private dialogUtility: DialogUtility,
              public pageRowCountUtility: PageRowCountUtility, private tenantConfigDataService: TenantConfigDataService) {
  }

  ngOnInit(): void {
    this.UNGROUPEDTRANSACTIONHISTORYGRID = this.tenantConfigDataService.getBooleanValue('UNGROUPEDTRANSACTIONHISTORYGRID');
    this.cols = [
      {
        field: 'TransactionDate_D',
        header: ' Date',
        columnType: 'date',
        matchMode: 'contains',
        visible: true,
        displayType: 'date'
      },
      {
        field: 'Description',
        header: 'Description',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {field: 'Type', header: 'Type', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'icon'},
      // { field: 'Status', header: 'Status', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {
        field: 'StatusDetails',
        header: 'Status Details',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'Amount_N',
        header: 'Amount',
        columnType: 'numeric',
        matchMode: 'gte',
        visible: true,
        displayType: 'currency'
      }
    ];

    if(this._usePaidThroughBillingPeriod()) {
      this.cols.splice(1,0,
        {
          field: 'PaidThroughBillingPeriod',
          header: ' Paid Through Period',
          columnType: 'date',
          matchMode: 'contains',
          visible: true,
          displayType: 'date'
        },
      );
    }
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    if (this.TypeFilter) {
      this.currentFilter.filters = {
        Type: [
          {
            value: this.TypeFilter,
            matchMode: 'equals',
            operator: 'or'
          }
        ]
      };
      this.selectedFilter = 1;
    } else {
      this.currentFilter.filters = {};
      this.selectedFilter = 0;
    }

    this.loadTable();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  loadTable(event?): void {
    if (event) {
      this.currentFilter.sortOrder = event.sortOrder;
      this.currentFilter.sortField = event.sortField;
      this.currentFilter.first = event.first;
      this.currentFilter.rows = event.rows;
    }
    this.getTransactionHistory();
  }

  getTransactionHistory(): void {
    this.dataLoaded = false;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    if (this.UNGROUPEDTRANSACTIONHISTORYGRID === true) {
      this.getListRequest = this.rosterProfileTransactionsService.getRosterTransactionsPrimeNG('DATA', this.PersonID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.transactionHistorys = res;
            this.transactionHistorysList = res.Data;
            this.dataLoaded = true;
            this.readyToLoadTable = true;
          }
        });
      this.getTotalRequest = this.rosterProfileTransactionsService.getRosterTransactionsPrimeNG('TOTAL', this.PersonID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.dataTotal = res.Total;
          }
        });
    } else {
      this.getListRequest = this.rosterProfileTransactionsService.getRosterTransactionsGroupedPrimeNG('DATA', this.PersonID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.transactionHistorys = res;
            this.transactionHistorysList = res.Data;
            this.dataLoaded = true;
            this.readyToLoadTable = true;
          }
        });
      this.getTotalRequest = this.rosterProfileTransactionsService.getRosterTransactionsGroupedPrimeNG('TOTAL', this.PersonID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.dataTotal = res.Total;
          }
        });
    }
  }

  selectRadio() {
    return new Promise((resolve, reject) => {
      this.currentFilter.filters = {};
      this.activeGlobalFilter = null;
      switch (this.selectedFilter) {
        case 0:
          delete this.currentFilter.filters.Type;
          break;
        case 1:
          this.initFilters('Type', 'Payment', 'contains', 'and');
          break;
        default:
          break;
      }
      this.loadTable();
      resolve(null);
    });
  }

  refresh(): void {
    this.loadTable();
  }

  filterItems(): void {
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('Description', this.activeGlobalFilter, 'contains', 'and');
    } else {
      this.initFilters('Description', null, 'contains', 'and');
    }
    this.loadTable();
  }

  clear(): void {
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.activeGlobalFilter = null;
      delete this.currentFilter.filters.Description;
    }
    this.loadTable();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string): void {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.transactionHistorysList));
        const dataToExport = removeKeysFromArray(arr, []);
        exportData('account-transactions', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        if (this.UNGROUPEDTRANSACTIONHISTORYGRID === true) {
          this.getListRequest = this.rosterProfileTransactionsService.getRosterTransactionsPrimeNG('DATA', this.PersonID, exportFilter)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, []);
                exportData('account-transactions', dataToExport);
                this.isExporting = false;
              }
            });
        } else {
          this.getListRequest = this.rosterProfileTransactionsService.getRosterTransactionsGroupedPrimeNG('DATA', this.PersonID, exportFilter)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, []);
                exportData('account-transactions', dataToExport);
                this.isExporting = false;
              }
            });
        }
      }
    }, () => {
    });
  }
}
