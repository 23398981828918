import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  ClassesGridModel,
  ClassModel,
  ClassStudentsCompletionModel,
  ClassUnauthModel,
  ClassUnauthFlatModel,
  CreateUpdateClassModel
} from '../models/classes.model';

@Injectable({
  providedIn: 'root',
})
export class ClassesModulesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getClassesPrimeNG(dataMode: string, filterData, startDate: string, endDate: string, orgId: number, courseId: number): Observable<ClassesGridModel> {
    let url = (orgId) ? `&trainingcenterid=${orgId}`: '';
    url += (courseId) ? `&courseid=${courseId}`: '';
    url += (startDate) ? `&start_dt=${startDate}`: '';
    url += (endDate) ? `&end_dt=${endDate}`: '';
    return this.http.post<ClassesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/grid/?mode=${dataMode}${url}`, filterData);
  }

  getClass(classId: number): Observable<ClassModel> {
    return this.http.get<ClassModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}`);
  }

  createClass(modelData: CreateUpdateClassModel, preview?: boolean): Observable<ClassModel> {
      return this.http.post<ClassModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/create`, modelData);
  }

  createMultipleClass(modelData: CreateUpdateClassModel, preview?: boolean): Observable<ClassModel[]> {
    if(preview) {
      return this.http.post<ClassModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/createrecurring?preview=${preview}`, modelData);
    } else {
      return this.http.post<ClassModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/createrecurring`, modelData);
    }
  }

  copyClass(classId: number): Observable<ClassModel> {
    return this.http.post<ClassModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/copy`, null);
  }

  updateClass(classId: number, modelData: CreateUpdateClassModel): Observable<ClassModel> {
    return this.http.put<ClassModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}`, modelData);
  }

  deleteClass(classId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/remove/${classId}`);
  }

  getAvailableClasses(tenantEnum: string): Observable<ClassUnauthModel[]> {
    return this.http.get<ClassUnauthModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/availableclasses/bytenant/${tenantEnum}`);
  }

  getAvailableClassesOrgUnauth(tenantEnum: string, organizationId: number): Observable<ClassUnauthModel[]> {
    return this.http.get<ClassUnauthModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/availableclasses/bytenant/${tenantEnum}/byOrganizationId/${organizationId}`);
  }

  getAvailableClassesOrgUnauthFlat(tenantEnum: string, organizationId: number): Observable<ClassUnauthFlatModel[]> {
    return this.http.get<ClassUnauthFlatModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/availableclasses/bytenant/${tenantEnum}/byOrganizationId/${organizationId}/flat`);
  }

  updateClassStudentsStatus(classId: number, modelData: ClassStudentsCompletionModel): Observable<ClassStudentsCompletionModel> {
    return this.http.put<ClassStudentsCompletionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/completion`, modelData);
  }
}
