import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {Subject} from 'rxjs';
import {ContextService} from '../../services/context.service';
import {DialogService} from 'primeng/dynamicdialog';
import {AdhocCommDialogComponent} from '../../shared/components/adhoc-comm-dialog/adhoc-comm-dialog.component';
import {PageService} from '../../services/page.services';
import {tabsPermissionsUtility} from '../../shared/utilities/tabs-permissions.utility';
import {InterfaceObjectAppsModel, InterfaceObjectItemPermissions} from '../../models/interface-objects.model';
import {StudentTranscriptComponent} from '../../reports/student-transcripts/student-transcripts.component';
import {DymoLabelUtility} from '../../shared/utilities/dymo-label.utility';
import {AdhocMailMergeFormComponent} from '../../shared/components/adhoc-mail-merge/adhoc-mail-merge-form.component';
import {MemberProfileDialogComponent} from '../../shared/components/member-profile-dialog/member-profile-dialog.component';

@Component({
  selector: 'app-roster-student-action-buttons',
  templateUrl: './roster-student-action-buttons.component.html',
  styleUrls: ['./roster-student-action-buttons.component.scss'],
})
export class RosterStudentActionButtonsComponent implements OnInit, OnDestroy {
  InterfaceObjectEnum: string = 'STUDENTS';
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  selRosterId: number;
  transcript: boolean = false;
  isDymo: boolean = false;
  showTranscriptOptions: boolean = false;
  selectedStudent: number;
  @Input() canPageWrite: boolean;
  @Input() roster: any;
  @Input() i: number;
  @Input() permissions: InterfaceObjectAppsModel[] = [];
  @Input() blobToken: string;
  private ngUnsubscribe = new Subject();

  constructor(private confirmationService: ConfirmationService,
              private router: Router, private contextService: ContextService,
              private dymoLabelUtility: DymoLabelUtility,
              private dialogService: DialogService, private pageService: PageService) {
  }

  ngOnInit(): void {
    this.pageService.getPagePermissions('STUDENTTRANSCRIPT', true).then((resT: InterfaceObjectItemPermissions) => {
      this.transcript = resT.Write;
    });
    if (sessionStorage.getItem('isDymoInstalled') === null && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value === 'Yes') {
      this.dymoLabelUtility.isDymoInstalled().then(() => {
        this.dymoLabelUtility.isDymoPrinterInstalled().then((rtn) => {
          this.isDymo = rtn;
        }).catch(() => {
          this.isDymo = false;
        });
      }).catch(() => {
        this.isDymo = false;
      });
    } else {
      this.isDymo = (sessionStorage.getItem('isDymoInstalled') === 'Yes' && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value === 'Yes');
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  goToEdit(id?: number): void {
    sessionStorage.setItem('sessionViewType', 'List');
    this.selRosterId = id;
    if (this.selRosterId) {
      this.router.navigate(['training/students/edit/', +this.selRosterId], {
        queryParams: {selRosterId: this.selRosterId}
      });
    }
  }

  openTranscript(PersonID, type): void {
    this.showTranscriptOptions = false;
    const selectedStudent = this.roster;
    this.dialogService.open(StudentTranscriptComponent, {
      data: {
        id: PersonID,
        isEdit: true,
        isDialog: true,
        type
      },
      header: selectedStudent.FirstName + ' ' + selectedStudent.LastName,
      width: '90%',
      height: '70%'
    });
  }

  goToSnapshot(PersonID) {
    const tempPersonIds = [PersonID];
    sessionStorage.setItem('snapshotPersonIDs', JSON.stringify(tempPersonIds));
    this.router.navigateByUrl('/snapshots?snapshotContext=studentProfile');
  }

  downloadProfile(PersonID): void {
    const selectedStudent = this.roster;
    this.dialogService.open(MemberProfileDialogComponent, {
      data: {
        id: PersonID,
        blobToken: this.blobToken,
        isEdit: true,
        isDialog: true
      },
      header: 'Download Profile - ' + selectedStudent.FirstName + ' ' + selectedStudent.LastName,
      width: '550px',
      height: '180px'
    });
  }

  openAdhocComm(recipients): void {
    this.dialogService.open(AdhocCommDialogComponent, {
      data: {
        recipients: [recipients],
        isEdit: true,
        isDialog: true,
        organizationId: recipients.OrganizationID,
        context: 'training'
      },
      header: 'Email or Text Student',
      width: '70%',
      height: '70%'
    });
  }

  openAdhocMailMerge(recipients): void {
    this.dialogService.open(AdhocMailMergeFormComponent, {
      data: {
        recipients: [recipients],
        isEdit: true,
        isDialog: true
      },
      header: 'Letter',
      width: '95%',
      height: '75%'
    });
  }

  printLabel(personID: number): void {
    this.confirmationService.confirm({
      message: 'You are about to print a DYMO label. Are you sure this is what you want to do?',
      header: 'Print Confirmation',
      icon: 'pi pi-print',
      accept: () => {
        this.dymoLabelUtility.rosterDymoLabel(personID);
      }
    });
  }
}
