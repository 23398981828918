import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {takeUntil} from 'rxjs/operators';
import {RosterProfileService} from '../../../../services/roster-profile.service';
import {UserProfileCommunicationModel, UserProfileCommunicationsGridModel} from '../../../../models/user-profile.model';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-communications-user-profile-tab',
  templateUrl: './communications-user-profile-tab.component.html',
  styleUrls: ['./communications-user-profile-tab.component.scss']
})
export class CommunicationsUserProfileTabComponent implements OnInit, OnChanges, OnDestroy {
  communications: UserProfileCommunicationsGridModel;
  communicationList: UserProfileCommunicationModel[] = [];
  dataLoaded: boolean;
  isExporting: boolean;
  totalLoaded: boolean;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  statusFilter: string = '';
  senderFilter: string = '';
  selectedItem: number;
  commTotal: number = 0;
  channel: string = '';
  @Input() DBEntityID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  inputObjSelectCommunicationData: GenericSelectModel;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(public dialogService: DialogService, private rosterService: RosterProfileService,
              private dialogUtility: DialogUtility, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 0;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.inputObjSelectCommunication(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 0;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
  }

  getData() {
    this.getList();
    this.getTotal();
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.communicationList.length = 0;
    this.dataLoaded = false;
    if (this.DBEntityID) {
      this.getListRequest = this.rosterService.getRosterProfileCommunicationsPrimeNG('DATA', this.DBEntityID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.communications = res;
            this.communicationList = res.Data;
            this.dataLoaded = true;
          }
        });
    } else {
      this.dataLoaded = true;
    }
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.commTotal = 0;
    this.totalLoaded = false;
    if (this.DBEntityID) {
      this.getTotalRequest = this.rosterService.getRosterProfileCommunicationsPrimeNG('TOTAL', this.DBEntityID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.commTotal = res.Total;
            this.totalLoaded = true;
          }
        });
    } else {
      this.totalLoaded = true;
    }
  }

  updateList(dataChanged: boolean): void {
    if (dataChanged) {
      this.selectedItem = -1;
      this.getData();
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterStatus() {
    if (this.statusFilter && this.statusFilter !== '') {
      this.initFilters('SendPublishStatus', this.statusFilter, 'contains', 'and');
    } else {
      this.currentFilter.filters.SendPublishStatus = [];
    }
  }

  filterSender() {
    if (this.senderFilter && this.senderFilter !== '') {
      this.initFilters('SenderDisplayName', this.senderFilter, 'contains', 'and');
    } else {
      this.currentFilter.filters.SenderDisplayName = [];
    }
  }

  filterChannel() {
    if (this.channel) {
      this.initFilters('Channel', this.channel, 'equals', 'and');
    } else {
      this.currentFilter.filters.Channel = [];
    }
  }

  filterItems(): void {
    this.dataLoaded = false;
    this.filterStatus();
    this.filterSender();
    this.filterChannel();
    this.initFilters('Title', this.activeGlobalFilter, 'contains', 'and');
    this.getData();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.statusFilter = '';
    this.senderFilter = '';
    this.channel = null;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.inputObjSelectCommunication(false);
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  refresh(): void {
    this.getData();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  resetComponent(): void {
    this.communicationList = undefined;
    this.communications = undefined;
  }

  inputObjSelectCommunication(disable: boolean): void {
    this.inputObjSelectCommunicationData = {
      labelText: 'Communication Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: [
        {
          ID: null,
          Description: '--',
          Abbreviation: null
        },
        {
          ID: 2,
          Description: 'Email',
          Abbreviation: 'Email'
        },
        {
          ID: 3,
          Description: 'Text Message',
          Abbreviation: 'Text Message'
        }
      ],
      disabled: disable,
      emitChangeOnLoad: true
    };
  }

  setCommunicationData(event:any) {
    delete this.currentFilter.filters.Channel;
    if (event && event[0]) {
      this.channel = event[0].Description;
    } else {
      this.channel = null;
    }
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.communicationList.length <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.communicationList));
        const dataToExport = removeKeysFromArray(arr, []);
        exportData('communications', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.rosterService.getRosterProfileCommunicationsPrimeNG('DATA', this.DBEntityID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, []);
              exportData('communications', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
