<div class="content p-input-filled margin-top-10">
  <div class="p-grid">
    <div class="col-md-12 col-sm-12 col-xs-12">
      @if (isPage && !isDialog) {
        <div class="add-edit-header">
          @if (isPage && !isDialog) {
            <div class="inline-back-button" (click)="back()" pTooltip="Back">
              <i class="pi pi-angle-left"></i>
            </div>
          }
          <div class="profile-details p-pl-5">
            @if (isEdit && class) {
              <h2>{{ class.CourseDescription }}</h2>
            }
            @if (isEdit && class) {
              <h4><b>Training Center:</b> {{ class.OrganizationName }} |
                <b>Start:</b> {{ class.StartDate_D | date:'shortDate' }} {{ class.StartTime_T | date:'shortTime' }} |
                <b>End:</b> {{ class.EndDate_D | date:'shortDate' }} {{ class.EndTime_T | date:'shortTime' }}</h4>
            }
            @if (!isEdit) {
              <h2>Add Class</h2>
            }
          </div>
        </div>
      }
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      @if (!isPage || isDialog) {
        <p-divider></p-divider>
      }
      @if (showTabs) {
        <p-tabView (onChange)="resetWarning()">
          @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDetails')) {
            <p-tabPanel header="Details"
                        [selected]="_tabsPermissionsUtility.checkTabRead(permissions,'tabDemographics')">
              <ng-template pTemplate="content">
                <app-class-tab-details [ClassID]="ContentID" [isEdit]="isEdit"
                                       [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDetails')"
                                       (closeAddEdit)="detailsSaved($event)"></app-class-tab-details>
              </ng-template>
            </p-tabPanel>
          }
          @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabStaff')) {
            <p-tabPanel header="Staff" [disabled]="!isEdit">
              <ng-template pTemplate="content">
                <app-class-tab-staff
                  [canAdd]="class.CanEdit && canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabStaff')"
                  [ClassID]="ContentID"
                  [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabStaff')"
                  [interfaceObjectDesc]="'tabStaff'" [parentObjectEnum]="InterfaceObjectEnum"></app-class-tab-staff>
              </ng-template>
            </p-tabPanel>
          }
          @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabStudents')) {
            <p-tabPanel header="Students" [disabled]="!isEdit">
              <ng-template pTemplate="content">
                <app-class-tab-students
                  [canAdd]="class.CanEdit && canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabStudents')"
                  [ClassID]="ContentID" [courseId]="origClass.CourseID" [OrganizationID]="origClass.OrganizationID"
                  [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabStudents')"
                  [interfaceObjectDesc]="'tabStudents'" [parentObjectEnum]="InterfaceObjectEnum"
                  (closeAddEdit)="getRecipients()" [isModule]="class.IsModule"></app-class-tab-students>
              </ng-template>
            </p-tabPanel>
          }
          @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabGrades') && class.IsModule) {
            <p-tabPanel header="Grades" [disabled]="!isEdit">
              <ng-template pTemplate="content">
                <app-class-tab-grades [ClassInfo]="class" [ClassID]="ContentID"
                                      [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabGrades')"
                                      [interfaceObjectDesc]="'tabAttendance'"
                                      [parentObjectEnum]="InterfaceObjectEnum"></app-class-tab-grades>
              </ng-template>
            </p-tabPanel>
          }
          @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabAttendance') && this.class.StartDate_D) {
            <p-tabPanel header="Attendance" [disabled]="!isEdit">
              <ng-template pTemplate="content">
                <app-class-tab-attendance [ClassInfo]="class" [ClassID]="ContentID"
                                          [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabAttendance')"
                                          [interfaceObjectDesc]="'tabAttendance'"
                                          [parentObjectEnum]="InterfaceObjectEnum"
                                          [isModule]="class.IsModule"></app-class-tab-attendance>
              </ng-template>
            </p-tabPanel>
          }
          @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCommunications')) {
            <p-tabPanel header="Communications" [disabled]="!isEdit">
              <ng-template pTemplate="content">
                <app-communications-tab [DBEntity]="'class'" [DBEntityID]="ContentID"
                                        [interfaceObjectDesc]="'tabCommunications'"
                                        [parentObjectEnum]="InterfaceObjectEnum" [permissions]="permissions"
                                        [recipients]="recipients"></app-communications-tab>
              </ng-template>
            </p-tabPanel>
          }
          @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDocs')) {
            <p-tabPanel header="Docs" [disabled]="!isEdit">
              <ng-template pTemplate="content">
                <app-docs-tab
                  [canAdd]="class.CanEdit && canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabDocs')"
                  [DBEntity]="'CLASS'" [DBEntityID]="ContentID"
                  [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDocs')"
                  [InterfaceObjectEnum]="InterfaceObjectEnum" [interfaceObjectDesc]="'tabDocs'"
                  [parentObjectEnum]="InterfaceObjectEnum"></app-docs-tab>
              </ng-template>
            </p-tabPanel>
          }
          @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabNotes')) {
            <p-tabPanel header="Notes" [disabled]="!isEdit">
              <ng-template pTemplate="content">
                <app-notes-tab
                  [canAdd]="class.CanEdit && canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                  [DBEntity]="'CLASS'" [DBEntityID]="ContentID"
                  [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                  [canEdit]="_tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                  [interfaceObjectDesc]="'tabNotes'" [parentObjectEnum]="InterfaceObjectEnum"></app-notes-tab>
              </ng-template>
            </p-tabPanel>
          }
          @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabOnline')) {
            <p-tabPanel header="Virtual" [disabled]="!isEdit">
              <ng-template pTemplate="content">
                <app-class-tab-virtual [ClassID]="ContentID" [isEdit]="isEdit"
                                       [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabOnline')"></app-class-tab-virtual>
              </ng-template>
            </p-tabPanel>
          }
        </p-tabView>
      }
    </div>
  </div>
</div>
