import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

@Injectable({
  providedIn: 'root',
})
export class TelerikReportingService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getReport(reportUrl: string, params): Observable<ArrayBuffer>  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/report/reportpdf?`, params, requestOptions);
  }

  getProfileReport(params): Observable<ArrayBuffer>  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/report/studentmemberprofile/pdf`, params, requestOptions);
  }
}
