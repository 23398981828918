import {Component, OnDestroy, OnInit} from '@angular/core';
import {ResourcesService} from '../../../../services/resources.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-media-manager-dialog',
  templateUrl: './media-manager-dialog.component.html',
  styleUrls: ['./media-manager-dialog.component.scss'],
})
export class MediaManagerDialogComponent implements OnInit, OnDestroy {
  fd: FormData = new FormData();
  isSaving: boolean;
  imageUrl: string;
  private ngUnsubscribe = new Subject();

  constructor(private resourceService: ResourcesService, private ref: DynamicDialogRef) {
  }

  ngOnInit(): void {
  }

  uploadImage(event:any) {
    this.fd = new FormData();
    if (event.files.length > 0) {
      for (const file of event.files) {
        this.fd.append('image', file);
      }
      if (this.fd) {
        this.resourceService.uploadResource(this.fd, null)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.imageUrl = res[0].Url;
            }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  returnUrl() {
    this.ref.close(this.imageUrl);
  }
}
