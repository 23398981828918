import {Component, OnInit} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-maps',
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss']
})

export class ChartDialogComponent implements OnInit {
  title: string;
  data: TreeNode[];
  origData: TreeNode[] = [];

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {}

  ngOnInit(): void {
    this.title = this.config.data.title;
    this.data = JSON.parse(JSON.stringify(this.config.data.orgChart));
    this.origData = JSON.parse(JSON.stringify(this.config.data.orgChart));
  }

  closeDialog(): void {
    this.ref.destroy();
    this.ref.destroy();
  }

  cancel(): void {
    this.closeDialog();
  }

  // toggle(expand): void {
  //   let d: string;
  //   if (expand === true) {
  //     d = JSON.stringify(this.data).replaceAll('"expanded":false,', '"expanded":true,');
  //   } else {
  //     d = JSON.stringify(this.data).replaceAll('"expanded":true,', '"expanded":false,');
  //   }
  //   this.data.length = 0;
  //   this.data = JSON.parse(d);
  // }

  // reset(): void {
  //   this.data = JSON.parse(JSON.stringify(this.origData));
  // }
}
