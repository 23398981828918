import {Component, OnDestroy, OnInit} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import {StudentProfileModel} from '../../../models/student-profile.model';
import {StudentCertificationsModel} from '../../../models/certifications.model';
import {ClassStudentModel} from '../../../models/class-students.model';

@Component({
  selector: 'app-adhoc-comm-dialog',
  templateUrl: './adhoc-comm-dialog.component.html',
  styleUrls: ['./adhoc-comm-dialog.component.scss']
})

export class AdhocCommDialogComponent implements OnInit, OnDestroy {
  isEmail: boolean = false;
  emailAllowed: boolean;
  textAllowed: boolean;
  recipients: StudentProfileModel[];
  recipientsStudentCerts: StudentCertificationsModel[];
  recipientsClassStudent: ClassStudentModel[];
  senderName: string;
  subject: string;
  DBEntity: string;
  DBEntityID: number;
  superuser: boolean;
  organizationId: number;
  context: string;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
  }

  ngOnInit(): void {
    this.isEmail = true;
    this.recipients = this.config.data.recipients;
    this.recipientsStudentCerts = this.config.data.recipientsStudentCerts;
    this.recipientsClassStudent = this.config.data.recipientsClassStudent;
    this.senderName = this.config.data.senderName;
    this.subject = this.config.data.subject;
    this.DBEntity = this.config.data.DBEntity;
    this.DBEntityID = this.config.data.DBEntityID;
    this.organizationId = this.config.data.organizationId;
    this.context = this.config.data.context;
    this.isEmail = false;
    this.emailAllowed = (this.config?.data?.emailAllowed !== undefined) ? this.config.data.emailAllowed : true;
    this.textAllowed = (this.config?.data?.textAllowed !== undefined) ? this.config.data.textAllowed : true;
    this.superuser = (this.config?.data?.superuser !== undefined) ? this.config.data.superuser : true;
    this.isEmail = this.emailAllowed;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  closeDialog(res?) {
    this.ref.close(res);
    this.ref.destroy();
  }
}
