import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ReportedModel, TotalHoursReportedModel} from '../../../../../models/hours-reported.model';
import {UserEmploymentService} from '../../../../../services/user-employment.service';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {takeUntil} from 'rxjs/operators';
import {removeKeysFromArray} from '../../../../utilities/data.utility';
import {exportData} from '../../../../utilities/list-table.utility';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';
import {DialogUtility} from '../../../../utilities/dialog.utility';

@Component({
  selector: 'app-hours-reported',
  templateUrl: './hours-reported.component.html',
  styleUrls: ['./hours-reported.component.scss']
})

export class HoursReportedComponent implements OnInit, OnDestroy {
  reportedList: ReportedModel[] = [];
  cols: any[];
  @Input() isEdit: boolean;
  @Input() isPage: boolean;
  @Input() PersonID: number;
  currentFilter = new PrimeTableFilterModel();
  dataTotal: number = 0;
  isExporting: boolean;
  reportedHours = {} as TotalHoursReportedModel;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private userEmploymentService: UserEmploymentService, private dialogUtility: DialogUtility,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.cols = [
      {
        field: 'OrganizationName',
        header: 'Employer',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'ReportedDate_D',
        header: 'Date Reported',
        columnType: 'date',
        matchMode: 'contains',
        displayType: 'date',
        visible: true
      },
      {
        field: 'ReportedHours',
        header: 'Hours Worked',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'numeric'
      }
    ];

    this.currentFilter.rows = null;
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    this.reportedHours.TotalReportedHours = 0;
    this.getData();
    this.getTotalHoursReported();

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getData() {
    this.getList();
    this.getTotal();
  }

  getList(): void {
    if (this.PersonID) {
      if (this.getListRequest) {
        this.getListRequest.unsubscribe();
      }
      this.getListRequest = this.userEmploymentService.getsuperuserRosterEmploymentExternalHoursPrimeNG('DATA', this.PersonID, this.currentFilter)
        .pipe()
        .subscribe({
          next: (res) => {
            this.reportedList = res.Data;
          }, error: (e) => {
            console.warn(e);
          }
        });
    }
  }

  getTotal(): void {
    if (this.PersonID) {
      if (this.getTotalRequest) {
        this.getTotalRequest.unsubscribe();
      }
      this.getTotalRequest = this.userEmploymentService.getsuperuserRosterEmploymentExternalHoursPrimeNG('TOTAL', this.PersonID, this.currentFilter)
        .pipe()
        .subscribe({
          next: (res) => {
            this.dataTotal = res.Total;
          }, error: (e) => {
            console.warn(e);
          }
        });
    }
  }

  getTotalHoursReported() {
    this.userEmploymentService.getSuperUserEmploymentExternalHours(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.reportedHours = res;
        }
      });
  }

  refresh(): void {
    this.getData();
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.reportedList));
        const dataToExport = removeKeysFromArray(arr, []);
        exportData('hours-reported', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.userEmploymentService.getsuperuserRosterEmploymentExternalHoursPrimeNG('DATA', this.PersonID, exportFilter)
          .pipe()
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, []);
              exportData('hours-reported', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
