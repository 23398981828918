import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RosterService} from '../../../services/roster.service';
import {StudentProfileService} from '../../../services/student-profile.service';
import {StudentProfileModel} from '../../../models/student-profile.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NotZeroValidation} from '../../../shared/utilities/form.utility';
import {ContextService} from '../../../services/context.service';
import {CustomAttributesModel} from '../../../models/attributes.model';

@Component({
  selector: 'app-student-roster-form-student',
  templateUrl: './roster-student-form-student.component.html',
  styleUrls: ['./roster-student-form-student.component.scss']
})

export class RosterStudentFormStudentComponent implements OnInit, OnChanges {
  context = this.contextService.contextObject;
  mainForm: FormGroup;
  studentInfo: StudentProfileModel;
  inputObjType: GenericSelectModel;
  inputObjStatus: GenericSelectModel;
  inputObjClass: GenericSelectModel;
  inputObjOrganization: GenericSelectModel;
  inputObjPathway: GenericSelectModel;
  inputObjEducation: GenericSelectModel;
  setTypeId: number;
  setStatusId: number;
  setClassId: number;
  setOrganizationId: number;
  setPathwayId: number;
  setEducationId: number;
  isDirty: boolean;
  isSaving: boolean = false;
  existingMessage: boolean = false;
  originalCustomAttributes: string;
  isPaperworkMissing: boolean = false;
  missingPaperwork: CustomAttributesModel[] = [];
  originalMissingPaperwork: string;
  @Input() isPage: boolean;
  updatedStudentInfo = {} as StudentProfileModel;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() PersonID: number;
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private rosterService: RosterService,
              private studentProfileService: StudentProfileService, private ref: DynamicDialogRef,
              private config: DynamicDialogConfig, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private contextService: ContextService,
              private confirmationService: ConfirmationService) {
    this.mainForm = this.formBuilder.group({
      type: new FormControl(null, [Validators.required, NotZeroValidation()]),
      status: new FormControl(null, [Validators.required, NotZeroValidation()]),
      // class: new FormControl(null, ),
      // organization: new FormControl(null, [Validators.required, NotZeroValidation()]),
      // pathway: new FormControl(null, [Validators.required, NotZeroValidation()]),
      studentNumber: new FormControl(''),
      // memberNumber: new FormControl('')
      education: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Student data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.PersonID && this.PersonID) {
      this.getStudentInfo();
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  getStudentInfo(): void {
    this.studentProfileService.getStudentProfile(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.missingPaperwork = res.CustomAttributes?.filter(x => x.FieldName.indexOf('Missing Paperwork') > -1 || x.FieldName.indexOf('Paperwork Missing') > -1);
          if (this.missingPaperwork !== undefined) {
            this.missingPaperwork = JSON.parse(JSON.stringify(this.missingPaperwork)?.replaceAll('Missing Paperwork - ', ''));
            this.originalMissingPaperwork = JSON.stringify(this.missingPaperwork);
          }
          res.CustomAttributes = res.CustomAttributes?.filter(x => x.FieldName.indexOf('Missing Paperwork') === -1 && x.FieldName.indexOf('Paperwork Missing') === -1);
          this.studentInfo = res;
          this.updatedStudentInfo = JSON.parse(JSON.stringify(res));
          this.originalCustomAttributes = JSON.stringify(res?.CustomAttributes);
          this.isPaperworkMissing = this.missingPaperwork?.find(x => x.FieldName === 'Is Any Paperwork Missing?')?.Value === 'Yes';
          this.initForm();
        }
      });
  }

  initForm(): void {
    this.mainForm.get('studentNumber').setValue(this.studentInfo.StudentNumber);
    // this.mainForm.get('memberNumber').setValue(this.studentInfo.MemberNumber);
    this.mainForm.get('type').setValue(this.studentInfo.TypeID);
    // this.mainForm.get('pathway').setValue(this.studentInfo.PathwayID);
    this.mainForm.get('status').setValue(this.studentInfo.StatusID);
    this.mainForm.get('education').setValue(this.studentInfo.EducationID);

    this.setTypeId = this.studentInfo.TypeID;
    this.setStatusId = this.studentInfo.StatusID;
    // this.setClassId = this.studentInfo.ClassificationID;
    this.setOrganizationId = this.studentInfo.OrganizationID;
    this.setPathwayId = this.studentInfo.PathwayID;
    this.setEducationId = this.studentInfo.EducationID;

    this.initType(!this.canTabWrite);
    this.initStatus(!this.canTabWrite);
    // this.initClass(!this.canTabWrite);
    // this.initOrganization(!this.canTabWrite);
    this.initEducation(!this.canTabWrite);
    this.mainForm.markAsPristine();
  }

  initType(disable: boolean): void {
    this.inputObjType = {
      labelText: 'Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setTypeId,
      data: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getPersonProfileTypesLookupData('TRAINING').then((lookupData) => {
      this.inputObjType.data = lookupData;
      this.inputObjType = Object.assign({}, this.inputObjType);
    });
  }

  initStatus(disable: boolean): void {
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStatusId,
      data: null,
      disabled: disable,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getPersonProfileStatusesLookupData('TRAINING').then((lookupData) => {
      this.inputObjStatus.data = lookupData;
      this.inputObjStatus = Object.assign({}, this.inputObjStatus);
    });
  }

  initClass(disable: boolean): void {
    this.inputObjClass = {
      labelText: 'Class',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setClassId,
      data: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getPersonProfileClassificationsLookupData().then((lookupData) => {
      this.inputObjClass.data = lookupData;
      this.inputObjClass = Object.assign({}, this.inputObjClass);
    });
  }

  initOrganization(disable: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setOrganizationId,
      data: null,
      disabled: disable,
      canWrite: this.canTabWrite,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByAccessLookupData().then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  initPathway(disable: boolean): void {
    this.inputObjPathway = {
      labelText: 'Pathway',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setPathwayId,
      data: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getPathwaysLookupData(this.setOrganizationId).then((lookupData) => {
      this.inputObjPathway.data = lookupData;
      this.inputObjPathway = Object.assign({}, this.inputObjPathway);
    });
  }

  initEducation(disable: boolean): void {
    this.inputObjEducation = {
      labelText: 'Education Level',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEducationId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getEducationLookupData().then((lookupData) => {
      this.inputObjEducation.data = lookupData;
      this.inputObjEducation = Object.assign({}, this.inputObjEducation);
    });
  }

  getTypeData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setTypeId = event[0].ID;
    } else {
      this.setTypeId = 0;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('type').setValue(this.setTypeId);
  }

  getStatusData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setStatusId = event[0].ID;
    } else {
      this.setStatusId = 0;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('status').setValue(this.setStatusId);
  }

  getEducationData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setEducationId = event[0].ID;
    } else {
      this.setEducationId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('education').setValue(this.setEducationId);
  }

  // getClassData(event:any) {
  //   if (event && event[0] && event[0].ID) {
  //     this.setClassId = event[0].ID;
  //   } else {
  //     this.setClassId = 0;
  //   }
  //   this.mainForm.markAsDirty();
  //   this.mainForm.get('class').setValue(this.setClassId);
  // }

  // getOrganizationData(event:any) {
  //   if (event && event[0] && event[0].ID) {
  //     this.setOrganizationId = event[0].ID;
  //   } else {
  //     this.setOrganizationId = 0;
  //   }
  //   this.mainForm.markAsDirty();
  //   this.mainForm.get('organization').setValue(this.setOrganizationId);
  //   if(this.setOrganizationId !== 0 && this.setOrganizationId) {
  //     this.initPathway(false);
  //   } else {
  //     this.initPathway(true);
  //   }
  // }

  // getPathwayData(event:any) {
  //   if (event && event[0] && event[0].ID) {
  //     this.setPathwayId = event[0].ID;
  //   } else {
  //     this.setPathwayId = 0;
  //   }
  //   this.mainForm.markAsDirty();
  //   this.mainForm.get('pathway').setValue(this.setPathwayId);
  // }

  processData(): void {
    this.isSaving = true;
    this.updatedStudentInfo.StudentNumber = this.mainForm.get('studentNumber').value;
    // this.updatedStudentInfo.MemberNumber = this.mainForm.get('memberNumber').value;
    this.updatedStudentInfo.TypeID = this.setTypeId;
    this.updatedStudentInfo.StatusID = this.setStatusId;
    // this.updatedStudentInfo.ClassificationID = this.setClassId;
    // this.updatedStudentInfo.OrganizationID = this.setOrganizationId;
    // this.updatedStudentInfo.PathwayID = this.setPathwayId;
    this.updatedStudentInfo.EducationID = this.setEducationId;

    const stringReplace = '{"ID":null,"Description":"--","Abbreviation":""},';
    const stringNew = JSON.stringify(this.studentInfo.CustomAttributes)?.replaceAll(stringReplace, '');
    if (stringNew !== undefined) {
      this.updatedStudentInfo.CustomAttributes = JSON.parse(stringNew);
    }
    if (this.missingPaperwork !== undefined) {
      this.missingPaperwork.forEach((x => {
        this.updatedStudentInfo.CustomAttributes.push(x);
      }));
    }
    this.saveForm();
  }

  updateCustomAttribute(index: number, event): void {
    this.mainForm.markAsDirty();
    this.studentInfo.CustomAttributes[index] = event;
    if (event?.FieldName === 'Contract ID' || event?.FieldName === 'Toss ID') {
      let contractid: string = '';
      let tossid: string = '';
      switch (event.FieldName) {
        case 'Contract ID':
          contractid = event.Value;
          tossid = this.studentInfo.CustomAttributes.find(x => x.FieldName === 'Toss ID')?.Value;
          break;
        case 'Toss ID':
          tossid = event.Value;
          contractid = this.studentInfo.CustomAttributes.find(x => x.FieldName === 'Contract ID')?.Value;
          break;
      }
      switch (true) {
        case contractid !== '' && tossid !== '' && contractid !== null && tossid !== null:
          this.studentInfo.CustomAttributes.find(x => x.FieldName === 'App Contract').Value = `${tossid} - ${contractid}`;
          break;
        case (contractid === '' || contractid === null) && tossid !== '' && tossid !== null:
          this.studentInfo.CustomAttributes.find(x => x.FieldName === 'App Contract').Value = `${tossid}`;
          break;
        case contractid !== '' && contractid !== null && (tossid === '' || tossid === null):
          this.studentInfo.CustomAttributes.find(x => x.FieldName === 'App Contract').Value = `${contractid}`;
          break;
      }
      // reassign to update fields
      const stringReplace = '{"ID":null,"Description":"--","Abbreviation":""},';
      const newCusApp: string = JSON.stringify(this.studentInfo.CustomAttributes).replaceAll(stringReplace, '');
      this.studentInfo.CustomAttributes = [];
      this.studentInfo.CustomAttributes = JSON.parse(newCusApp);
    }
    if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
      this.existingMessage = true;
      this.openDialog();
    }
  }

  updateMissingPaperwork(index: number, event): void {
    this.mainForm.markAsDirty();
    this.missingPaperwork[index] = event;
    if (event?.FieldName === 'Is Any Paperwork Missing?') {
      this.isPaperworkMissing = event.Value === 'Yes';
      if (!this.isPaperworkMissing) {
        this.missingPaperwork.forEach(x => {
          if (x.FieldName !== 'Is Any Paperwork Missing?') {
            x.Value = null;
          }
        });
      }
    }
    // reassign to update fields
    const newCusApp: string = JSON.stringify(this.missingPaperwork);
    this.missingPaperwork = [];
    this.missingPaperwork = JSON.parse(newCusApp);
    if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
      this.existingMessage = true;
      this.openDialog();
    }
  }

  saveForm(): void {
    if (!this.isEdit) {
      this.studentProfileService.createStudentProfile(this.updatedStudentInfo).pipe(takeUntil(this.ngUnsubscribe))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            //TODO take care of new student
          }
        });
    } else {
      this.studentProfileService.updateStudentProfile(this.PersonID, this.updatedStudentInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.ref.destroy();
            this.confirmationService.close();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Student has been updated'
            });
            this.mainForm.markAsUntouched();
            this.mainForm.markAsPristine();
            this.isSaving = false;
            this.existingMessage = false;
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      // reassign to update fields
      this.studentInfo.CustomAttributes = [];
      if (this.originalCustomAttributes !== undefined) {
        this.studentInfo.CustomAttributes = JSON.parse(this.originalCustomAttributes);
      }
      this.missingPaperwork = [];
      if (this.originalMissingPaperwork !== undefined) {
        this.missingPaperwork = JSON.parse(this.originalMissingPaperwork);
      }
      this.isPaperworkMissing = this.missingPaperwork?.find(x => x.FieldName === 'Is Any Paperwork Missing?')?.Value === 'Yes';
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }
}
