import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {CreateSharableResourceModel, SharableNoteModel, UpdateSharableModel} from '../../../../../models/sharables.model';
import {SharablesService} from '../../../../../services/sharables.service';
import {ResourcesService} from '../../../../../services/resources.service';
import {NotZeroValidation, transformDateTimeToDateOnly} from '../../../../utilities/form.utility';
import {LookupModel} from '../../../../../models/lookups.model';
import {ContextService} from '../../../../../services/context.service';

@Component({
  selector: 'app-docs-form',
  templateUrl: './docs-form.component.html',
  styleUrls: ['./docs-form.component.scss']
})
export class DocsFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  sharable = {} as SharableNoteModel;
  isDirty: boolean;
  isSaving: boolean = false;
  loading: boolean = false;
  sharableDate: Date;
  createSharable = {} as CreateSharableResourceModel;
  updateSharable = {} as UpdateSharableModel;
  setCategoryId: number;
  inputObjCategory: GenericSelectModel;
  setAccessId: number;
  inputObjAccess: GenericSelectModel;
  showUploader: boolean;
  showProgress: boolean;
  fd: FormData = new FormData();
  uploadedFiles: any[] = [];
  existingMessage: boolean = false;
  categoryAlias: string;
  today = new Date();
  @Input() InterfaceObjectEnum: string;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() sharableID: number;
  @Input() showAccess: boolean;
  @Input() isMyDoc: boolean;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService,
              private sharablesService: SharablesService,
              private lookupsDataService: LookupsDataService,
              private resourcesService: ResourcesService,
              private confirmationService: ConfirmationService,
              private contextService: ContextService
  ) {
    this.mainForm = this.formBuilder.group({
      title: new FormControl(null, [Validators.required, Validators.maxLength(250)]),
      category: new FormControl(null, [Validators.required, NotZeroValidation()]),
      access: new FormControl(null),
      fcnSharableBody: new FormControl(null),
      fcnSharableDate: new FormControl(null, Validators.required),
      files: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    this.initForm();
    if (!this.canTabWrite) {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Document data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  setDefaults(): any {
    return new Promise((resolve, reject) => {
      // set General as default category
      this.lookupsDataService.getSharableCategoriesLookupData('document', this.InterfaceObjectEnum).then((lookupData: LookupModel[]) => {
        lookupData.forEach(x => {
          if (x.Description === 'General') {
            this.setCategoryId = x.ID;
            this.mainForm.get('category').setValue(this.setCategoryId);
          }
        });
        if (this.DBEntity && this.DBEntity.toUpperCase() === 'PERSONPROFILE') {
          this.lookupsDataService.getSharablePersonProfileAccessOptionsLookupData(this.DBEntityID).then((lookupData2: LookupModel[]) => {
            lookupData2.forEach(x => {
              if (x.Description.includes('All ')) {
                this.setAccessId = x.ID;
                this.mainForm.get('access').setValue(this.setAccessId);
              }
            });
            resolve(null);
          });
        } else {
          this.lookupsDataService.getSharableAccessOptionsLookupData(this.DBEntityID).then((lookupData3: LookupModel[]) => {
            lookupData3.forEach(x => {
              if (x.Description.includes('All ')) {
                this.setAccessId = x.ID;
                this.mainForm.get('access').setValue(this.setAccessId);
              }
            });
            resolve(null);
          });
        }
      });
    });
  }

  initForm(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.loading = true;
    if (!this.isEdit) {
      this.setDefaults().then(() => {
        this.showUploader = true;
        this.mainForm.get('title').setValue(null);
        this.mainForm.get('fcnSharableBody').setValue(null);
        this.mainForm.get('fcnSharableDate').setValue(this.today);
        this.uploadedFiles.length = 0;
        this.fd = new FormData();
        this.mainForm.get('files').setValue(null);
        this.initCategory(false);
        if (this.showAccess === true) {
          this.initAccess(false);
        }
        this.loading = false;
      });
    } else {
      this.sharablesService.getSharable(this.sharableID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.sharable = res;
            this.mainForm.get('title').setValue(this.sharable.Title);
            this.categoryAlias = this.sharable.Category;
            this.setCategoryId = this.sharable.CategoryID;
            this.mainForm.get('category').setValue(this.setCategoryId);
            this.initCategory(true);
            if (this.showAccess === true) {
              this.setAccessId = this.sharable.AccessLevelID;
              this.mainForm.get('access').setValue(this.setAccessId);
              this.initAccess(!this.canTabWrite);
            }
            this.mainForm.get('fcnSharableBody').setValue(this.sharable.Body);
            if (this.sharable.PubDate) {
              this.sharableDate = new Date(this.sharable.PubDate);
              this.mainForm.get('fcnSharableDate').setValue(this.sharableDate);
            }
            this.mainForm.get('files').setValue(1);
            this.loading = false;
          }
        });
    }
  }

  isFormValid(): any {
    if (!this.isEdit) {
      return this.mainForm.valid && this.mainForm.dirty && this.uploadedFiles.length > 0;
    } else {
      return this.mainForm.valid && this.mainForm.dirty;
    }
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.saveForm();
    } else {
      this.messageService.add({severity: 'warn', summary: 'Missing Data', detail: 'No data was saved, the form is currently incomplete.'});
      this.existingMessage = false;
    }
  }

  initCategory(disable: boolean) {
    this.inputObjCategory = {
      labelText: 'Category',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setCategoryId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: false//!this.isEdit
    };
    this.lookupsDataService.getSharableCategoriesLookupData('document', this.InterfaceObjectEnum).then((lookupData) => {
      this.inputObjCategory.data = lookupData;
      this.inputObjCategory = Object.assign({}, this.inputObjCategory);
    });
  }

  getCategoryData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setCategoryId = event[0].ID;
    } else {
      this.setCategoryId = 0;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('category').setValue(this.setCategoryId);
  }

  initAccess(disable: boolean) {
    this.inputObjAccess = {
      labelText: 'Access',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setAccessId,
      disabled: disable
    };
    if (this.DBEntity && this.DBEntity.toUpperCase() === 'PERSONPROFILE') {
      this.lookupsDataService.getSharablePersonProfileAccessOptionsLookupData(this.DBEntityID).then((lookupData) => {
        this.inputObjAccess.data = lookupData;
        this.inputObjAccess = Object.assign({}, this.inputObjAccess);
      });
    } else {
      this.lookupsDataService.getSharableAccessOptionsLookupData(this.DBEntityID).then((lookupData) => {
        this.inputObjAccess.data = lookupData;
        this.inputObjAccess = Object.assign({}, this.inputObjAccess);
      });
    }
  }

  getAccessData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setAccessId = event[0].ID;
    } else {
      this.setAccessId = 0;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('access').setValue(this.setAccessId);
  }

  saveForm(): void {
    this.saveNewFiles().then((fileData) => {
      if (!this.isEdit) {
        this.createSharable.Type = 'DOCUMENT';
        this.createSharable.DBEntity = this.DBEntity;
        this.createSharable.DBEntityID = this.DBEntityID;
        this.createSharable.CategoryID = this.setCategoryId;
        if (this.showAccess === true) {
          this.createSharable.AccessLevelID = this.setAccessId;
        } else {
          this.createSharable.AccessLevelID = 0;
        }
        this.createSharable.PubDate = this.mainForm.get('fcnSharableDate').value;
        this.createSharable.Title = this.mainForm.get('title').value;//fileData[0].FileName;
        this.createSharable.Body = this.mainForm.get('fcnSharableBody').value;
        this.createSharable.BodyIsHtml = false;
        this.createSharable.Resources = [
          {
            ResourceReferenceType: 'SHARABLEDOCUMENT',
            resourceID: fileData[0].ResourceId,
            Description: fileData[0].FileName
          }
        ];
        if (this.isMyDoc) {
          this.sharablesService.createUserSharableDocument(this.createSharable)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'The document has been added.'
                });
                this.closeAddEdit.emit(true);
                this.isSaving = false;
              }, error: (e) => {
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
                this.isSaving = false;
                console.debug(e);
              }
            });
        } else {
          this.sharablesService.createSharableDocument(this.createSharable)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'The document has been added.'
                });
                this.closeAddEdit.emit(true);
                this.isSaving = false;
              }, error: (e) => {
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
                this.isSaving = false;
                console.debug(e);
              }
            });
        }
      } else {
        this.updateSharable.ID = this.sharable.ID;
        this.updateSharable.Type = this.sharable.Type;
        this.updateSharable.DBEntity = this.sharable.DBEntity;
        this.updateSharable.DBEntityID = this.sharable.DBEntityID;
        this.updateSharable.Title = this.mainForm.get('title').value;
        this.updateSharable.CategoryID = this.setCategoryId;
        if (this.showAccess === true) {
          this.updateSharable.AccessLevelID = this.mainForm.get('access').value;
        } else {
          this.updateSharable.AccessLevelID = 0;
        }
        this.updateSharable.PubDate = transformDateTimeToDateOnly(this.mainForm.get('fcnSharableDate').value);
        this.updateSharable.Body = this.mainForm.get('fcnSharableBody').value;
        this.updateSharable.BodyIsHtml = false;
        this.updateSharable.Resources = this.sharable.Resources;

        this.sharablesService.updateSharable(this.sharableID, this.updateSharable)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.confirmationService.close();
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The document has been updated.'
              });
              this.isSaving = false;
              this.existingMessage = false;
              this.closeAddEdit.emit(true);
            }
          });
      }
    });
  }

  saveNewFiles(): any {
    this.showProgress = true;
    if (this.isMyDoc) {
      return new Promise((resolve, reject) => {
        if (this.uploadedFiles.length > 0) {
          this.resourcesService.uploadResourceUser(this.fd, 'secure')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (fileData) => {
                resolve(fileData);
                this.showProgress = false;
              }
            });
        } else {
          this.showProgress = false;
          resolve(null);
        }
        console.debug(reject);
      });
    } else {
      return new Promise((resolve, reject) => {
        if (this.uploadedFiles.length > 0) {
          this.resourcesService.uploadResource(this.fd, 'secure')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (fileData) => {
                resolve(fileData);
                this.showProgress = false;
              }
            });
        } else {
          this.showProgress = false;
          resolve(null);
        }
        console.debug(reject);
      });
    }

  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  onUpload(event: any): any {
    if (event.files.length > 0) {
      this.mainForm.get('files').setValue(event.files.length);
      for (const file of event.files) {
        this.uploadedFiles.push(file);
        this.fd.append('file', file);
        if (this.mainForm.get('title').value === null || this.mainForm.get('title').value === undefined || this.mainForm.get('title').value === '') {
          this.mainForm.get('title').setValue(file.name);
        }
      }
      //this.messageService.add({severity: 'info', summary: 'File(s) Uploaded', detail: ''});
      this.mainForm.markAsDirty();
    }
  }

  clearUpload(): void {
    this.uploadedFiles.length = 0;
    this.fd = new FormData();
    this.mainForm.markAsDirty();
    this.mainForm.get('files').setValue(null);
  }
}
