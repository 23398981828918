<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <app-learn-more [interfaceObjectDesc]="'tabPaymentMethods'" [interfaceObjectTypeEnum]="'APPSECTION'"
                    [parentObjectEnum]="parentObjectEnum"></app-learn-more>
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3>Filter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 no-padding">
        <label>Name/Description</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="activeGlobalFilter" class="full-width filter" pInputText
               tabindex="0" type="text"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <app-select (emitData)="manageFilterCheckboxes($event)" [inputObj]="inputObjSelectPaymentMethodData"
                    class="full-width"></app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <div class="list-filter-buttons text-align-right">
          <button (click)="clearFilters()" [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" class="p-button-download export-menu" icon="pi pi-times"
                  label="Clear" pButton>
          </button>
          <button (click)="filterItems()" class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  pButton>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">@if (paymentMethodList) {
          <span>{{ dataTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (dataTotal > currentFilter.rows) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        <button (click)="refresh()" icon="pi pi-refresh" pButton
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        <button (click)="showAddEdit = true" icon="pi pi-plus"
                pButton pTooltip="Add"
                tooltipPosition="bottom"></button>
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (dataTotal > currentFilter.rows) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (showAddEdit && canTabWrite) {
      <div>
        <p-card header="Add Payment Method" [styleClass]="'list-card'">
          <app-payment-methods-form [userObject]="userObject" [DBEntity]="DBEntity" [DBEntityID]="DBEntityID"
                                    [canTabWrite]="true" [isEdit]="false" [allowCC]="allowCC"
                                    (closeAddEdit)="updateList($event)"></app-payment-methods-form>
        </p-card>
      </div>
    }
    @if (totalLoaded && dataTotal <= 0 && !showAddEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (paymentMethod of paymentMethodList; track paymentMethod; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              @if (paymentMethod.Expired) {
                <div class="inactive-flag"></div>
              }
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i)"
                     [ngClass]="{'' : !paymentMethod.Expired, 'inactive' : paymentMethod.Expired}">
                  <div class="list-header cc-list">
                    @switch (paymentMethod.MethodType) {
                      @case ('CC') {
                        @if (paymentMethod.CCCardType === 'Visa Card' || paymentMethod.CCCardType === 'Visa') {
                          <img width="36" height="24" class="cc-image" ngSrc="assets/images/credit-cards/visa.svg"
                               pTooltip="Visa" tooltipPosition="bottom" alt="" [priority]="true"/>
                        }
                        @if (paymentMethod.CCCardType === 'MasterCard') {
                          <img width="36" height="24" class="cc-image" ngSrc="assets/images/credit-cards/mastercard.svg"
                               pTooltip="MasterCard" tooltipPosition="bottom" alt="" [priority]="true"/>
                        }
                        @if (paymentMethod.CCCardType === 'Discover Card' || paymentMethod.CCCardType === 'Discover') {
                          <img width="36" height="24" class="cc-image" ngSrc="assets/images/credit-cards/discover.svg"
                               pTooltip="Discover" tooltipPosition="bottom" alt="" [priority]="true"/>
                        }
                        @if (paymentMethod.CCCardType === 'American Express' || paymentMethod.CCCardType === 'Amex') {
                          <img width="36" height="24" class="cc-image" ngSrc="assets/images/credit-cards/amex.svg"
                               pTooltip="American Express" tooltipPosition="bottom" alt="" [priority]="true"/>
                        }
                      }
                      @case ('ACH') {
                        @if (paymentMethod.ACHAccountType === 'CHECKING') {
                          <i class="fa fa-money-check" pTooltip="Checking Account" tooltipPosition="bottom"></i>
                        }
                        @if (paymentMethod.ACHAccountType === 'SAVING') {
                          <i class="fa fa-money-bill" pTooltip="Savings Account" tooltipPosition="bottom"></i>
                        }
                      }
                    }
                    <div class="payment-title">&nbsp;{{ paymentMethod.Display | titlecase }}</div>
                  </div>
                  @switch (paymentMethod.MethodType) {
                    @case ('CC') {
                      <div class="list-subheader">
                        Ending in
                        **** {{ paymentMethod.AccountNumber.substring(paymentMethod.AccountNumber.length - 4, paymentMethod.AccountNumber.length) }}
                        |
                        @if (!paymentMethod.Expired) {
                          <span><b>Expires:</b></span>
                        } @else {
                          <span><b>EXPIRED:</b></span>
                        } {{ paymentMethod.CCExpirationMonth }}/{{ paymentMethod.CCExpirationYear }}
                      </div>
                    }
                    @case ('ACH') {
                      <div class="list-subheader">
                        Ending in
                        **** {{ paymentMethod.AccountNumber.substring(paymentMethod.AccountNumber.length - 4, paymentMethod.AccountNumber.length) }}
                        | {{ paymentMethod.ACHBankName }}
                      </div>
                    }
                  }
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                         (click)="deletePaymentMethod(paymentMethod.PaymentMethodId)"><i class="fas fa-trash-alt"></i>
                    </div>
                    <div class="icon" (click)="expandData(i)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                  </div>
                </div>
                @if (selectedItem === i) {
                  <div class="full-width margin-top-10">
                    <app-payment-methods-form [userObject]="userObject" [DBEntity]="DBEntity"
                                              [DBEntityID]="paymentMethod.PaymentMethodId" [isEdit]="true"
                                              [canTabWrite]="canTabWrite" [allowCC]="allowCC"
                                              (closeAddEdit)="updateList($event)"></app-payment-methods-form>
                  </div>
                }
              </div>
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
