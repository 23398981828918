import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {EventInstancesGridModel, EventInstanceModel, UnauthEventInstanceModel, UnauthEventInstanceLocationModel} from '../models/event-instances.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class EventInstancesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getEventInstancesPrimeNG(dataMode: string, filterData): Observable<EventInstancesGridModel> {
    Userpilot.track('Event Instances List');
    return this.http.post<EventInstancesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/eventinstance/grid?mode=${dataMode}`, filterData);
  }

  getEventInstance(eventInstanceId: number): Observable<EventInstanceModel> {
    return this.http.get<EventInstanceModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/eventinstance/${eventInstanceId}`);
  }

  createEventInstance(modelData: EventInstanceModel, preview?: boolean): Observable<any> {
      return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/eventInstance/create`, modelData);
  }

  createMultipleEventInstance(modelData: EventInstanceModel, preview?: boolean): Observable<any> {
    if (preview) {
      return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/eventInstance/createrecurring?preview=${preview}`, modelData);
    } else {
      return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/eventInstance/createrecurring`, modelData);
    }
  }

  updateEventInstance(eventInstanceId: number, modelData: EventInstanceModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/eventinstance/${eventInstanceId}`, modelData);
  }

  deleteEventInstance(eventInstanceId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/eventinstance/remove/${eventInstanceId}`);
  }

  getEventInstancesUnauth(tenantEnum: string): Observable<UnauthEventInstanceModel[]> {
    return this.http.get<UnauthEventInstanceModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/availableevents/bytenant/${tenantEnum}`);
  }

  getEventInstancesOrgUnauth(tenantEnum: string, organizationId: number): Observable<UnauthEventInstanceModel[]> {
    return this.http.get<UnauthEventInstanceModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/availableevents/bytenant/${tenantEnum}/byOrganizationId/${organizationId}`);
  }

  getEventInstanceLocationsUnauth(tenantEnum: string, eventInstanceId: number): Observable<UnauthEventInstanceLocationModel[]> {
    return this.http.get<UnauthEventInstanceLocationModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/availableevents/bytenant/${tenantEnum}/eventinstance/${eventInstanceId}/locations`);
  }
}
