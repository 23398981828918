@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (isEmptyData) {
  <span>No invoice exists to be paid at the moment.</span>
} @else {
  <form [formGroup]="mainForm">
    @if (!showDuesHeader && !showOneTimeHeader) {
      <span>No payment opportunities exist at this time. Thank you!</span>
    } @else {
      <span>+ Indicates a Fee may apply</span>
      <p-divider class="dark-divider"></p-divider>
      @if (!confirm) {
        <div class="p-grid p-fluid">
          @if (showDuesHeader) {
            <div
              [ngClass]="{'col-md-4 col-sm-12 col-xs-12' : showOneTimeHeader, 'col-md-6 col-sm-12 col-xs-12' : !showOneTimeHeader}">
              <p-card header="DUES, AND RECURRING CONTRIBUTIONS" [styleClass]="'list-card'">
                <ng-container formArrayName="duePayments">
                  @for (duePayment of duePayments.controls; track duePayment; let i = $index) {
                    <div [formGroupName]="i">
                      <div class="p-grid p-fluid">
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <span>{{ duePayment.get('Description').value }}</span>@if (duePayment.get('UserFees').value === true) {
                          <span> +</span>
                        }
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 margin-top-10">
                          <span>{{ duePayment.get('Period').value }}</span>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 margin-top-10">
                          @if (duePayment.get('Amount').value < 0) {
                            <span class="negative"> {{
                                duePayment.get('Amount').value |
                                  currency:'USD':'symbol':'1.2-2'
                              }}</span>
                          } @else if (duePayment.get('Amount').value === 0) {
                            <span> {{ duePayment.get('Amount').value | currency:'USD':'symbol':'1.2-2' }}</span>
                          } @else if (duePayment.get('Amount').value > 0) {
                            <span
                              class="positive">{{ duePayment.get('Amount').value | currency:'USD':'symbol':'1.2-2' }}</span>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </ng-container>
                <p-divider class="dark-divider"></p-divider>
                <div class="p-grid p-fluid">
                  @if (tcSTATEMENTBILLING) {
                    <div class="col-md-8 col-sm-10 col-xs-12 margin-top-10">
                      <span>*Total</span>
                    </div>
                    <div class="col-md-4 col-sm-2 col-xs-12 margin-top-10">
                      <!--            <p-inputNumber formControlName="duePaymentValueTotal" (onBlur)="updateDuesBillingTotal()" (onFocus)="updateDuesBillingTotal()" (onInput)="updateDuesBillingTotal()" mode="currency" currency="USD" locale="en-US" [min]="0" [showButtons]="true"></p-inputNumber>-->
                      <app-currency-input [initValue]="mainForm.get('duePaymentValueTotal').value"
                                          (emitData)="updateDuesBillingTotal($event)"></app-currency-input>
                    </div>
                  } @else {
                    <div class="col-md-10 col-sm-10 col-xs-12 margin-top-10">
                      <span>*Total</span>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12 margin-top-10">
                      <strong>{{ this.mainForm.get('duePaymentValueTotal').value | currency }}</strong>
                    </div>
                  }
                  <!--              @if (tcSTATEMENTBILLING) {-->
                  <!--                <div class="col-md-4 col-sm-2 col-xs-12 margin-top-10">-->
                  <!--                  &lt;!&ndash;            <p-inputNumber formControlName="duePaymentValueTotal" (onBlur)="updateDuesBillingTotal()" (onFocus)="updateDuesBillingTotal()" (onInput)="updateDuesBillingTotal()" mode="currency" currency="USD" locale="en-US" [min]="0" [showButtons]="true"></p-inputNumber>&ndash;&gt;-->
                  <!--                  <app-currency-input [initValue]="mainForm.get('duePaymentValueTotal').value" (emitData)="updateDuesBillingTotal($event)" ></app-currency-input>-->
                  <!--                </div>-->
                  <!--              } @else {-->
                  <!--                <div class="col-md-2 col-sm-2 col-xs-12 margin-top-10">-->
                  <!--                  <strong>{{ this.mainForm.get('duePaymentValueTotal').value | currency }}</strong>-->
                  <!--                </div>-->
                  <!--              }-->
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <p>*You may make a partial payment or pre-pay for future dues by changing the total amount</p>
                  </div>
                </div>
              </p-card>
            </div>
          }
          @if (showOneTimeHeader) {
            <div
              [ngClass]="{'col-md-4 col-sm-12 col-xs-12' : showDuesHeader, 'col-md-6 col-sm-12 col-xs-12' : !showDuesHeader}">
              <p-card header="ONE-TIME CONTRIBUTIONS" [styleClass]="'list-card'">
                @if (_oneTimeContributionExtraTextCustom()) {
                  <div>
                    <p>Education decisions are political decisions - and we need friends of public education making
                      those
                      decisions at the local, state and national level. That's why your voluntary contributions to
                      IMPACT
                      are
                      critical.</p>
                    <p>Since dues dollars cannot be contributed to political candidates, your contributions to IMPACT go
                      to
                      supporting pro-public education candidates.</p>
                    <p>I.M.P.A.C.T – Independent Michigan Political Action Council of Teachers<br>
                      The political action arm of the Warren and Fitzgerald Education Associations.<br>
                      Make a contribution today - help elect friends of public education!</p>
                  </div>
                }
                <ng-container formArrayName="oneTimeContributions">
                  @for (oneTimeContribution of oneTimeContributions.controls; track oneTimeContribution; let i = $index) {
                    <div [formGroupName]="i">
                      <div class="p-grid p-fluid">
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                <span>{{ oneTimeContribution.get('Description').value }} - {{
                    oneTimeContribution.get('OrganizationName').value
                  }}</span>
                          @if (oneTimeContribution.get('UserFees').value === true) {
                            <span> +</span>
                          }
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          <app-currency-input [initValue]="0"
                                              (emitData)="updateBillingTotal($event, i);"></app-currency-input>
                          <!--                    <p-inputNumber formControlName="oneTimeContributionValue" (onBlur)="updateBillingTotal()" (onFocus)="updateBillingTotal()" (onInput)="updateBillingTotal()" mode="currency" currency="USD" locale="en-US" [min]="0" [showButtons]="true"></p-inputNumber>-->
                        </div>
                      </div>
                    </div>
                  }
                </ng-container>
                <p-divider class="dark-divider"></p-divider>
                <div class="p-grid p-fluid">
                  <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                    <span>Total</span>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10 text-align-right">
                    <strong>{{ this.mainForm.get('billingTypeValueTotal').value | currency }}</strong>
                  </div>
                </div>
              </p-card>
            </div>
          }
          <div
            [ngClass]="{'col-md-4 col-sm-12 col-xs-12' : showDuesHeader && showOneTimeHeader, 'col-md-6 col-sm-12 col-xs-12' : (showDuesHeader && !showOneTimeHeader) || (!showDuesHeader && showOneTimeHeader)}">
            <p-card header="PAYMENT SUMMARY" [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                  <span>Total Dues and Recurring Contributions</span>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                  @if (this.mainForm.get('duePaymentValueTotal').value < 0) {
                    <span class="negative">
              {{ this.mainForm.get('duePaymentValueTotal').value | currency:'USD':'symbol':'1.2-2' }}</span>
                  } @else if (this.mainForm.get('duePaymentValueTotal').value === 0) {
                    <span> {{ this.mainForm.get('duePaymentValueTotal').value | currency:'USD':'symbol':'1.2-2' }}</span>
                  } @else if (this.mainForm.get('duePaymentValueTotal').value > 0) {
                    <span class="positive">{{
                        this.mainForm.get('duePaymentValueTotal').value |
                          currency:'USD':'symbol':'1.2-2'
                      }}</span>
                  }
                </div>
              </div>
              <div class="p-grid p-fluid">
                <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                  <span>Total One-Time Contributions</span>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                  @if (this.mainForm.get('billingTypeValueTotal').value < 0) {
                    <span class="negative">
              {{ this.mainForm.get('billingTypeValueTotal').value | currency:'USD':'symbol':'1.2-2' }}</span>
                  } @else if (this.mainForm.get('billingTypeValueTotal').value === 0) {
                    <span> {{ this.mainForm.get('billingTypeValueTotal').value | currency:'USD':'symbol':'1.2-2' }}</span>
                  } @else if (this.mainForm.get('billingTypeValueTotal').value > 0) {
                    <span class="positive">{{
                        this.mainForm.get('billingTypeValueTotal').value |
                          currency:'USD':'symbol':'1.2-2'
                      }}</span>
                  }
                </div>
              </div>
              <p-divider class="dark-divider"></p-divider>
              <div class="p-grid p-fluid">
                <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                  <span>Total Amount*</span>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                  <strong>{{ +this.mainForm.get('updatePaymentTotal')?.value | currency }}</strong>
                </div>
              </div>
              <div class="p-grid p-fluid">
                <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                  <p>*Fees may apply and can be reviewed upon confirmation.</p>
                </div>
              </div>
            </p-card>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p-card header="Payment Method" [styleClass]="'list-card'">
              <div class="p-grid p-fluid parent-form">
                <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                  @if (paymentMethodList && paymentMethodList.length > 0) {
                    @for (paymentMethod of paymentMethodList; track paymentMethod) {
                      @switch (paymentMethod.MethodType) {
                        @case ('CC') {
                          <div class="p-field-radiobutton">
                            <p-radioButton value="{{ paymentMethod.PaymentMethodId }}" formControlName="paymentMethodID"
                                           [styleClass]="'radio-input'" class="p-mr-2"
                                           (onClick)="toggleCardPaymentMethod(paymentMethod)"
                                           tabindex="0"></p-radioButton>
                            @switch (true) {
                              @case (paymentMethod.CCCardType === 'Visa Card' || paymentMethod.CCCardType === 'Visa') {
                                <img width="36" height="23" class="cc-image p-mr-1"
                                     ngSrc="assets/images/credit-cards/visa.svg"
                                     pTooltip="Visa" tooltipPosition="bottom" alt="" [priority]="true"/>
                              }
                              @case (paymentMethod.CCCardType === 'MasterCard') {
                                <img width="36" height="23" class="cc-image p-mr-1"
                                     ngSrc="assets/images/credit-cards/mastercard.svg"
                                     pTooltip="MasterCard" tooltipPosition="bottom" alt="" [priority]="true"/>
                              }
                              @case (paymentMethod.CCCardType === 'Discover Card' || paymentMethod.CCCardType === 'Discover') {
                                <img width="36" height="23" class="cc-image p-mr-1"
                                     ngSrc="assets/images/credit-cards/discover.svg"
                                     pTooltip="Discover" tooltipPosition="bottom" alt="" [priority]="true"/>
                              }
                              @case (paymentMethod.CCCardType === 'American Express' || paymentMethod.CCCardType === 'Amex') {
                                <img width="36" height="23" class="cc-image p-mr-1"
                                     ngSrc="assets/images/credit-cards/amex.svg"
                                     pTooltip="American Express" tooltipPosition="bottom" alt="" [priority]="true"/>
                              }
                            }
                            @if (paymentMethod.PaymentMethodId) {
                              <label>{{ paymentMethod.MethodNickname }} &ndash; Credit card ending in ****
                                {{ paymentMethod.AccountNumber }}</label>
                            }
                            @if (!paymentMethod.PaymentMethodId) {
                              <label>{{ paymentMethod.CCCardType }}</label>
                            }
                          </div>
                        }
                        @case ('ACH') {
                          <div class="p-field-radiobutton">
                            <p-radioButton value="{{ paymentMethod.PaymentMethodId }}" formControlName="paymentMethodID"
                                           [styleClass]="'radio-input'" class="p-mr-2"
                                           label="{{ paymentMethod.ACHBankName }} *{{ paymentMethod.AccountNumber }} ({{ paymentMethod.MethodNickname}})"
                                           (onClick)="toggleCardPaymentMethod(paymentMethod)"
                                           tabindex="0"></p-radioButton>
                          </div>
                        }
                      }
                    }
                  }
                  <p-divider class="dark-divider"></p-divider>
                  <div class="input-container">
                    <label>Name/Description <sup class="req">*</sup></label>
                    <input type="text" pInputText formControlName="MethodNickname" tabindex="0">
                    @if ((mainForm.controls.MethodNickname.dirty || mainForm.controls.MethodNickname.touched) &&
                    mainForm.controls.MethodNickname.errors?.required) {
                      <span class="error">'Name/Description' must be provided</span>
                    }
                  </div>
                  @if (isNew) {
                    <div class="input-container p-mt-2">
                      <div class="radio-container">
                        <p-radioButton value="CC" formControlName="paymentMethodType" [styleClass]="'radio-input'"
                                       class="p-mr-2" label="Credit Card" tabindex="0"></p-radioButton>
                        <p-radioButton value="ACH" formControlName="paymentMethodType" [styleClass]="'radio-input'"
                                       label="Electronic Check (Direct Debit)" tabindex="0"></p-radioButton>
                      </div>
                    </div>
                  }
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                  <p-card header="Auto Pay*">
                    <div class="p-grid p-fluid">
                      <div class="col-md-3 col-sm-5 col-xs-12">
                        <app-select class="full-width" [inputObj]="inputObjAutoPay"
                                    (emitData)="getAutoPayData($event)"></app-select>
                      </div>
                      <div class="col-md-9 col-sm-7 col-xs-12">
                        <p class="p-text-bold p-ml-2 p-mr-2">*By filling out and submitting this form, you are giving us
                          your
                          explicit permission to use the specified payment method to pay off your entire dues, fees, and
                          recurring contribution balance each month on the day of the month indicated.</p>
                      </div>
                    </div>
                  </p-card>
                </div>
              </div>
              <div class="p-grid p-fluid parent-form">
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <div class="p-grid p-fluid">
                    @switch (methodType) {
                      @case ('CC') {
                        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                          <h3>Credit Card Details</h3>
                        </div>
                        <div class="col-md-12 col-sm-6 col-xs-12 input-container">
                          <label>Name as it appears on card <sup class="req">*</sup></label>
                          <input type="text" pInputText formControlName="CCNameOnAccount" tabindex="0">
                          @if ((mainForm.controls.CCNameOnAccount.dirty || mainForm.controls.CCNameOnAccount.touched) &&
                          mainForm.controls.CCNameOnAccount.errors?.required) {
                            <span class="error">'Name as it appears on card' must be provided</span>
                          }
                        </div>
                        <div class="col-md-8 col-sm-6 col-xs-12 input-container">
                          @if (isNew === true) {
                            <label>Credit Card # (No spaces/dashes) <sup class="req">*</sup></label>
                          } @else {
                            <label>Credit Card # (Ending In) <sup class="req">*</sup></label>
                          }
                          <input type="text" pInputText formControlName="CCAccountNumber"
                                 (blur)="getCCDetails(this.mainForm.controls.CCAccountNumber.value)" tabindex="0">
                          @if ((mainForm.controls.CCAccountNumber.dirty || mainForm.controls.CCAccountNumber.touched) &&
                          mainForm.controls.CCAccountNumber.errors?.required) {
                            <span class="error">'Credit Card #' must be provided</span>
                          }
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12 input-container">
                          <label>Credit Card Type</label>
                          <input type="text" pInputText formControlName="CCCardType">
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12 input-container">
                          <label>Exp. Month <sup class="req">*</sup></label>
                          <input type="text" pInputText formControlName="CCExpirationMonth" maxlength="2" tabindex="0">
                          @if ((mainForm.controls.CCExpirationMonth.dirty || mainForm.controls.CCExpirationMonth.touched) &&
                          mainForm.controls.CCExpirationMonth.errors?.required) {
                            <span class="error">'Exp. Month' must be provided</span>
                          }
                          @if ((mainForm.controls.CCExpirationMonth.dirty || mainForm.controls.CCExpirationMonth.touched) &&
                          mainForm.controls.CCExpirationMonth.errors?.min) {
                            <span class="error">Must be between 1 and 12</span>
                          }
                          @if ((mainForm.controls.CCExpirationMonth.dirty || mainForm.controls.CCExpirationMonth.touched) &&
                          mainForm.controls.CCExpirationMonth.errors?.max) {
                            <span class="error">Must be between 1 and 12</span>
                          }
                          @if (mainForm.controls.CCExpirationMonth.hasError('number')) {
                            <div class="error">Must be a number</div>
                          }
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12 input-container">
                          <label>Exp. Year <sup class="req">*</sup></label>
                          <input type="text" pInputText formControlName="CCExpirationYear" maxlength="2" tabindex="0">
                          @if ((mainForm.controls.CCExpirationYear.dirty || mainForm.controls.CCExpirationYear.touched) &&
                          mainForm.controls.CCExpirationYear.errors?.required) {
                            <span class="error">'Exp. Year' must be provided</span>
                          }
                          @if ((mainForm.controls.CCExpirationYear.dirty || mainForm.controls.CCExpirationYear.touched) &&
                          mainForm.controls.CCExpirationYear.errors?.min) {
                            <span class="error">Must be between {{ minYear }} and {{ maxYear }}</span>
                          }
                          @if ((mainForm.controls.CCExpirationYear.dirty || mainForm.controls.CCExpirationYear.touched) &&
                          mainForm.controls.CCExpirationYear.errors?.max) {
                            <span class="error">Must be between {{ minYear }} and {{ maxYear }}</span>
                          }
                          @if (mainForm.controls.CCExpirationYear.hasError('number')) {
                            <div class="error">Must be a number</div>
                          }
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12 input-container">
                          @if (showCVV === true) {
                            <label>Verification # <sup class="req">*</sup></label>
                            <input type="text" pInputText formControlName="CVV" tabindex="0">
                            @if ((mainForm.controls.CVV.dirty || mainForm.controls.CVV.touched) &&
                            mainForm.controls.CVV.errors?.required) {
                              <span class="error">'Verification #' must be provided</span>
                            }
                            @if (mainForm.controls.CVV.errors?.minlength || mainForm.controls.CVV.errors?.maxlength) {
                              <div>Must be 4 or fewer</div>
                            }
                          }
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                          <img class="float-right" ngSrc="./assets/images/cvv.png" width="350" height="136" alt=""
                               [priority]="true"/>
                          <img ngSrc="./assets/images/cc_logos.png" width="80" height="25" alt="" [priority]="true"/>
                        </div>
                      }
                      @case ('ACH') {
                        <div class="col-md-12 col-sm-6 col-xs-12 input-container">
                          <h3>Electronic Check Payment Terms &amp; Conditions</h3>
                          <p>When you provide your check information, you authorize the <span
                            class="ut-shortname">UnionTrack</span> to use information from your check to make an
                            electronic fund
                            transfer from your account.</p>
                          <p>When payments are made from your account, funds may be withdrawn as soon as the same day we
                            receive
                            your payment, and you will not receive your check back from your financial institution.</p>
                        </div>
                        <div class="col-md-12 col-sm-6 col-xs-12 input-container">
                          <label>Name on Account <sup class="req">*</sup></label>
                          <input type="text" pInputText formControlName="ACHNameOnAccount" tabindex="0">
                          @if ((mainForm.controls.ACHNameOnAccount.dirty || mainForm.controls.ACHNameOnAccount.touched) &&
                          mainForm.controls.ACHNameOnAccount.errors?.required) {
                            <span class="error">'Name on Account' must be provided</span>
                          }
                        </div>
                        <div class="col-md-12 col-sm-6 col-xs-12 input-container">
                          <div class="radio-container">
                            <p-radioButton value="CHECKING" formControlName="ACHAccountType" id="CHECKING"
                                           [styleClass]="'radio-input'" class="p-mr-2" label="Checking"
                                           tabindex="0"></p-radioButton>
                            <p-radioButton value="SAVING" formControlName="ACHAccountType" id="SAVING"
                                           [styleClass]="'radio-input'" label="Savings" tabindex="0"></p-radioButton>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                          <label>Routing # <sup class="req">*</sup></label>
                          <input type="text" pInputText formControlName="ACHRoutingNumber" maxlength="9"
                                 (blur)="getBankName(this.mainForm.controls.ACHRoutingNumber.value)" tabindex="0">
                          @if ((mainForm.controls.ACHRoutingNumber.dirty || mainForm.controls.ACHRoutingNumber.touched) &&
                          mainForm.controls.ACHRoutingNumber.errors?.required) {
                            <span class="error">'Routing # ' must be provided</span>
                          }
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                          <label>Bank</label>
                          <input type="text" pInputText formControlName="ACHBankName" tabindex="0">
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                          <label>Account # <sup class="req">*</sup></label>
                          <input type="text" pInputText formControlName="ACHAccountNumber" maxlength="20" tabindex="0">
                          @if ((mainForm.controls.ACHAccountNumber.dirty || mainForm.controls.ACHAccountNumber.touched) &&
                          mainForm.controls.ACHAccountNumber.errors?.required) {
                            <span class="error">'Account # ' must be provided</span>
                          }
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                          @if (showACHAccountNumberConfirm === true) {
                            <label>Confirm Account # <sup class="req">*</sup></label>
                            <input type="text" pInputText formControlName="ACHAccountNumberConfirm" maxlength="20"
                                   tabindex="0">
                            @if (mainForm.controls.ACHAccountNumberConfirm.invalid &&
                            mainForm.controls.ACHAccountNumberConfirm.touched) {
                              <div class="p-error">
                                @if (mainForm.controls.ACHAccountNumberConfirm.errors?.required) {
                                  <div>Confirm Account # is required</div>
                                }
                                @if (mainForm.controls.ACHAccountNumberConfirm.errors?.confirmedValidator) {
                                  <div>Account # & Confirm Account # do not match</div>
                                }
                              </div>
                            }
                          }
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                          <img ngSrc="./assets/images/ach.gif" width="428" height="275" alt="" [priority]="true"/>
                        </div>
                      }
                    }
                    @if (isNew) {
                      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                        <div class="p-mt-1 switch-container">
                          <p-inputSwitch class="p-ml-4" formControlName="oneTimeOnly" tabindex="0"></p-inputSwitch>
                          <div class="input-switch-label p-ml-2">Save this Payment Method for future use</div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <div class="p-grid p-fluid">
                    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                      <h3>Billing Address</h3>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                      <div class="p-mt-1 switch-container">
                        <p-inputSwitch class="p-ml-4" formControlName="useAddress"
                                       (click)="applyAddress(this.mainForm.controls.useAddress.value)"
                                       tabindex="0"></p-inputSwitch>
                        <div class="input-switch-label p-ml-2">Use my primary address</div>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                      @if (addressData.Ready) {
                        <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite"
                                          [showCounty]="false"
                                          [required]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
                      } @else {
                        <app-address-form [incomingAddress]="addressData" [canTabWrite]="false" [showCounty]="false"
                                          [required]="true"></app-address-form>
                      }
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <p-divider class="dark-divider"></p-divider>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 input-container mask-container">
                      <label>Phone #</label>
                      <p-inputMask type="text" pInputText formControlName="primaryPhoneNumber"
                                   [className]="'form-input no-border'" mask="(999) 999-9999" unmask="true"
                                   tabindex="0"></p-inputMask>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 input-container">
                      <label>E-mail</label>
                      <input type="text" pInputText formControlName="primaryEmail" tabindex="0">
                      @if (mainForm.controls.primaryEmail.invalid || mainForm.controls.primaryEmail.touched) {
                        <div class="p-error">
                          @if (mainForm.controls.primaryEmail?.errors) {
                            <div>E-mail must be a valid e-mail address</div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-grid p-fluid margin-top-10">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="p-grid p-fluid">
                    <div class="col-md-6 col-sm-6 col-xs-12"></div>
                    <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
                      <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                              icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom" (click)="cancel()"
                              [disabled]="!mainForm.dirty"></button>
                      <button pButton label="Confirm" class="p-button-info p-mr-2" icon="pi pi-check" pTooltip="Confirm"
                              tooltipPosition="bottom" [className]="'inline-button'" (click)="confirmPayment()"
                              [disabled]="this.mainForm.invalid || !mainForm.dirty || +this.mainForm.get('updatePaymentTotal')?.value <= 0"></button>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        </div>
      }
      @if (confirm) {
        <div>
          <p-card header="PAYMENT SUMMARY CONFIRMATION" [styleClass]="'list-card'">
            <div class="p-grid p-fluid">
              @if (checkFeeData && checkFeeData.NonFeeDetails && checkFeeData.NonFeeDetails.length > 0) {
                <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                  <div class="p-grid p-fluid">
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <label>Total Non-Fee based Charges</label>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      <strong>{{ checkFeeNonFeeTotal | currency }}</strong>
                    </div>
                  </div>
                  @for (nonFeeDataRow of checkFeeData.NonFeeDetails; track nonFeeDataRow; let i = $index) {
                    <div class="col-md-12">
                      <div class="p-grid p-fluid">
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <span>{{ nonFeeDataRow.Description }}</span>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          @if (nonFeeDataRow.Amount < 0) {
                            <span class="negative"> {{
                                nonFeeDataRow.Amount |
                                  currency:'USD':'symbol':'1.2-2'
                              }}</span>
                          } @else if (nonFeeDataRow.Amount === 0) {
                            <span> {{ nonFeeDataRow.Amount | currency:'USD':'symbol':'1.2-2' }}</span>
                          } @else if (nonFeeDataRow.Amount > 0) {
                            <span class="positive">{{ nonFeeDataRow.Amount | currency:'USD':'symbol':'1.2-2' }}</span>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
              @if (checkFeeData && checkFeeData.FeeDetails && checkFeeData.FeeDetails.length > 0) {
                <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                  <div class="p-grid p-fluid">
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <label>Total Fee based Charges +</label>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      <strong>{{ checkFeeFeeTotal | currency }}</strong>
                    </div>
                  </div>
                  @for (feeDataRow of checkFeeData.FeeDetails; track feeDataRow; let i = $index) {
                    <div class="col-md-12">
                      <div class="p-grid p-fluid">
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <span>{{ feeDataRow.Description }}</span>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          @if (feeDataRow.Amount < 0) {
                            <span class="negative"> {{
                                feeDataRow.Amount |
                                  currency:'USD':'symbol':'1.2-2'
                              }}</span>
                          } @else if (feeDataRow.Amount === 0) {
                            <span> {{ feeDataRow.Amount | currency:'USD':'symbol':'1.2-2' }}</span>
                          } @else if (feeDataRow.Amount > 0) {
                            <span class="positive">{{ feeDataRow.Amount | currency:'USD':'symbol':'1.2-2' }}</span>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="p-grid p-fluid">
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                    <p-divider class="dark-divider"></p-divider>
                  </div>
                  <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                    <label>Total Charges</label>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                    <strong>{{ checkFeeTotal | currency }}</strong>
                  </div>
                </div>
              </div>
              <!--        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">-->
              <!--          <div id="divDisclaimer" style="">-->
              <!--            <h4>**ATTENTION**</h4>-->
              <!--            <p>Please note that to ensure your entire contribution counts, this Platform utilizes a Service Fee approach and our payment processor charges the following fees for processing the transactions:</p>-->
              <!--            <p><b>Credit Cards</b></p>-->
              <!--            <ul>-->
              <!--              <li><b>$0 - $50&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$2.00 per item</b></li>-->
              <!--              <li><b>$51 - $100&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$2.75 per item</b></li>-->
              <!--              <li><b>Over $100.01&nbsp;&nbsp;&nbsp;3% per item</b></li>-->
              <!--            </ul>-->
              <!--            <p><b>Electronic Check/ACH</b></p>-->
              <!--            <ul>-->
              <!--              <li><b>$1.50 per transaction</b></li>-->
              <!--            </ul>-->
              <!--            <p>They use these fees to pay for the interchange rates, all processing fees, and customer support.</p>-->
              <!--            <p>If you have any questions or would like to talk to someone in customer support, please call (301) 685-6199 during standard business hours: Monday through Friday 8am - 8pm Eastern Standard Time.</p>-->
              <!--          </div>-->
              <!--        </div>-->
              <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10">
                <button pButton label="Adjust" class="p-button-info p-mr-2" [className]="'inline-button'"
                        icon="pi pi-angle-left" pTooltip="Adjust" tooltipPosition="bottom"
                        (click)="confirm = false;"></button>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons margin-top-10">
                <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'"
                        icon="pi pi-times"
                        pTooltip="Cancel" tooltipPosition="bottom" (click)="cancel()"
                        [disabled]="!mainForm.dirty"></button>
                @if (!isSaving) {
                  <button pButton label="Make Payment" class="p-button-info p-mr-2" icon="pi pi-save"
                          pTooltip="Make Payment"
                          tooltipPosition="bottom" [className]="'inline-button'" (click)="makePayment()"
                          [disabled]="this.mainForm.invalid || !mainForm.dirty || +this.mainForm.get('updatePaymentTotal')?.value <= 0"></button>
                } @else {
                  <button pButton label="Make Payment" class="p-button-info p-mr-2" [className]="'inline-button'"
                          [disabled]="true">
                    <i class="pi pi-spin pi-spinner save-spinner"></i>
                  </button>
                }
              </div>
            </div>
          </p-card>
        </div>
      }
    }
  </form>
}
