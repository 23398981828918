import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {RosterQueryExistingModel, RosterQueryFilterModel} from '../../../../models/roster-query-primeng.model';
import {RosterQueryService} from '../../../../services/roster-queries.service';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {CamelcaseSpacePipe} from '../../../pipes/camelcase-space.pipe';

@Component({
  selector: 'app-distribution-existing-filter-form',
  templateUrl: './distribution-filter-existing-form.component.html',
  styleUrls: ['./distribution-filter-existing-form.component.scss']
})

export class DistributionFilterExistingFormComponent implements OnInit, OnChanges {
  mainForm: FormGroup;
  @Input() isEdit: boolean;
  @Input() canEdit: boolean = true;
  ContextEnumerator: string;
  isAdmin: boolean;
  currentRosterQuery = {} as RosterQueryExistingModel;
  @Input() selectedRosterQuery = {} as RosterQueryExistingModel;
  @Output() updateFilter = new EventEmitter<number>();
  @Output() deleteFilterFromParent = new EventEmitter<any>();
  @Output() updateLiveFilter = new EventEmitter<any>();
  @Input() selectedFilter = {} as RosterQueryFilterModel;
  @Input() selectedTable: string;
  @Input() isGridPage: string;
  @Input() selectedColumn: string;
  unFormattedTable: string;
  unFormattedColumn: string;
  // isPicker: boolean = false;
  InterfaceObjectEnum = 'DISTRIBUTIONLIST';
  category: string = 'union';
  // isDate: boolean;
  startDate: Date;
  isBoolean: boolean;
  // isYesNo: boolean;
  // public attributesOptions: AttributeSelectModel [] = [];
  // setAttributeID: number;
  currentFilter = {} as PrimeTableFilterModel;
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private messageService: MessageService,
              private router: Router, private rosterQueryService: RosterQueryService,
              private confirmationService: ConfirmationService, private camelcaseSpacePipe: CamelcaseSpacePipe) {
    this.mainForm = this.formBuilder.group({
      table: new FormControl(false, Validators.required),
      column: new FormControl(null, Validators.required),
      matchType: new FormControl(null, Validators.required),
      value: new FormControl(null, Validators.required),
      startValue: new FormControl(null),
      endValue: new FormControl(null),
    });
  }

  ngOnInit(): void {
    //set context
    if (this.router.url.includes('membership')) {
      this.ContextEnumerator = 'MEMBERSHIP';
      this.category = 'union';

    }
    if (this.router.url.includes('training')) {
      this.ContextEnumerator = 'TRAINING';
      this.category = 'education';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //pull in parent org and description for saving purposes of new query
    //populate existing form once data is available
    if (changes.selectedFilter) {
      this.unFormattedTable = this.selectedTable;
      this.unFormattedColumn = this.selectedColumn;
      switch (this.selectedTable) {
        case 'ATTRIBUTE' :
        case 'STUDENTATTRIBUTE' : {
          this.selectedTable = 'Custom Fields';
          break;
        }
        case 'UNIONPOSITION' :
        case 'EDUCATIONPOSITION' : {
          this.selectedTable = 'Positions';
          break;
        }
        case 'VOTER' : {
          this.selectedTable = 'Voter';
          break;
        }
        case 'STUDENTCLASS' : {
          this.selectedTable = 'Class';
          break;
        }
        case 'STUDENTPROGRAM' : {
          this.selectedTable = 'Program';
          break;
        }
        case 'SURVEYRESPONSE' : {
          this.selectedTable = 'Survey';
          break;
        }
        case 'COMMUNICATIONSTATUS' : {
          this.selectedTable = 'Communication';
          break;
        }
        case 'CERTIFICATION' : {
          this.selectedTable = 'Certifications';
          break;
        }
        case 'ROSTER' :
        case 'STUDENTROSTER' : {
          this.selectedTable = 'Roster';
          break;
        }
      }
      this.mainForm.get('table').setValue(this.selectedTable);
      if (this.selectedFilter.HeaderName) {
        this.mainForm.get('column').setValue(this.selectedFilter.HeaderName);
      } else {
        this.mainForm.get('column').setValue(this.camelcaseSpacePipe.transform(this.selectedColumn));
      }
      if (this.selectedFilter.matchMode === 'isnullorempty') {
        this.mainForm.get('matchType').setValue('Empty');
      } else if (this.selectedFilter.matchMode === 'isnotnullorempty') {
        this.mainForm.get('matchType').setValue('Is Not Empty');
      } else {
        this.mainForm.get('matchType').setValue(this.camelcaseSpacePipe.transform(this.selectedFilter.matchMode));
      }
      if (this.selectedFilter.matchMode === 'daysfrompresent') {
        this.mainForm.get('matchType').setValue('Days From Present');
      }
      if (this.selectedFilter.matchMode === 'daysfrompresentbetween') {
        this.mainForm.get('matchType').setValue('Days From Present Between');
      }
      if (this.selectedFilter.matchMode === 'monthsfrompresentbetween') {
        this.mainForm.get('matchType').setValue('Months From Present Between');
      }
      if (this.selectedFilter.matchMode === 'monthsfrompresent') {
        this.mainForm.get('matchType').setValue('Months From Present');
      }
      if (this.selectedFilter.ValueDescription) {
        this.mainForm.get('value').setValue(this.selectedFilter.ValueDescription);
      } else {
        this.mainForm.get('value').setValue(this.selectedFilter.value);
      }
      this.mainForm.get('startValue').setValue(this.selectedFilter.value);
      this.mainForm.get('endValue').setValue(this.selectedFilter.endValue);
    }
    //Disable form for existing filter
    this.mainForm.disable();
    //Load incoming selected roster query in to a local roster query
    //for some reason it was clearing out the selected one so I could not use directly
    if (changes.selectedRosterQuery) {
      this.currentRosterQuery = this.selectedRosterQuery;
    }
  }

  deleteFilter() {
    const workingArray: any[] = JSON.parse(JSON.stringify(this.currentRosterQuery.Filters));
    let deleteFilterIndex: number;
    if (this.unFormattedTable === 'SURVEYRESPONSE') {
      deleteFilterIndex = workingArray.find(table => table.Enumerator === this.unFormattedTable)
        .FilterPrimeNG.filters[this.unFormattedColumn].findIndex(filter => filter.ValueDescription === this.mainForm.get('value').value);
    } else {
      deleteFilterIndex = workingArray.find(table => table.Enumerator === this.unFormattedTable)
        .FilterPrimeNG.filters[this.unFormattedColumn].findIndex(filter => filter.value === this.mainForm.get('value').value);
    }

    workingArray.find(table => table.Enumerator === this.unFormattedTable)
      .FilterPrimeNG.filters[this.unFormattedColumn].splice(deleteFilterIndex, 1);

    //if column has empty filters after removal need to remove the column from filter
    if (workingArray.find(table => table.Enumerator === this.unFormattedTable)
      .FilterPrimeNG.filters[this.unFormattedColumn].length === 0) {
      delete workingArray.find(table => table.Enumerator === this.unFormattedTable)
        .FilterPrimeNG.filters[this.unFormattedColumn];
    }

    //if table no longer has filters remove from list
    const isEmptyFilter = Object.keys(workingArray.find(table => table.Enumerator === this.unFormattedTable).FilterPrimeNG.filters).length;
    if (isEmptyFilter === 0) {
      const deleteTableIndex = workingArray.findIndex(table => table.Enumerator === this.unFormattedTable);
      workingArray.splice(deleteTableIndex, 1);
    }

    this.saveData(workingArray);
  }

  saveData(filters?: any[]) {
    const saveArray: RosterQueryExistingModel = JSON.parse(JSON.stringify(this.currentRosterQuery));
    if (filters) {
      saveArray.Filters = filters;
    }
    this.rosterQueryService.updateRosterQueryPrimeNg(this.selectedRosterQuery.RosterQueryID, saveArray)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Distribution List has been updated'
          });
          this.updateFilter.emit(this.currentRosterQuery.RosterQueryID);
          this.isEdit = true;
        }, error: () => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Something went wrong.'});
        }
      });
  }

  promptToDelete() {
    this.confirmationService.confirm({
      message: 'You are about to delete a filter. Are you sure this is what you want to do?',
      header: 'Warning',
      icon: 'pi pi-times',
      accept: () => {
        this.deleteFilter();
      }
    });
  }

  // showValue() {
  //   if (!this.isYesNo) {
  //     return this.mainForm.controls.matchType.value !== 'between' && !this.isPicker && !this.isBoolean && this.mainForm.controls.matchType.value !== 'isnullorempty';
  //   } else {
  //     return true;
  //   }
  // }
}
