import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../models/generic-select.model';
import {LookupsDataService} from '../../services/lookups-data.service';
import {ClassesModulesService} from '../../services/classes.service';
import {CreateUpdateClassModel} from '../../models/classes.model';
import {MessageService} from 'primeng/api';
import {NotZeroValidation, transformDateTimeToDateOnly, transformDateTimeToTimeOnly} from '../../shared/utilities/form.utility';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {PageModel} from '../../models/page.model';
import {PageService} from '../../services/page.services';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ClassConfirmMultipleDialogComponent} from './class-confirm-multiple-dialog/class-confirm-multiple-dialog.component';

@Component({
  selector: 'app-classes-add-multiple',
  templateUrl: './classes-add-multiple.component.html',
  styleUrls: ['./classes-add-multiple.component.scss']
})
export class ClassesAddMultipleComponent implements OnInit {
  mainForm: FormGroup;
  loading: boolean = false;
  InterfaceObjectEnum: string = 'CLASSES';
  class = {} as CreateUpdateClassModel;
  inputObjOrganization: GenericSelectModel;
  inputObjCourse: GenericSelectModel;
  inputObjStatus: GenericSelectModel;
  inputObjTimezone: GenericSelectModel;
  inputObjLanguage: GenericSelectModel;
  setOrganizationId: number;
  setCourseId: number;
  setStatusId: number;
  startDate: Date;
  startTime: Date;
  endDate: Date;
  endTime: Date;
  setTimezoneId: number;
  setLanguageId: number;
  inputObjRecurrenceType: GenericSelectModel;
  setRecurrenceType: string;
  inputObjRecurrenceEndType: GenericSelectModel;
  setRecurrenceEndType: string;
  isDirty: boolean;
  isSaving: boolean = false;
  isDialog: boolean = false;
  isPage: boolean = false;
  weekly: boolean = false;
  weeklyData: string[];
  weeklySelected: string[] = [];
  monthly: boolean = false;
  selectedMonthlyType = 'datesOfMonth';
  monthlyTypes = [
    {
      label: 'By Dates',
      option: 'datesOfMonth'
    }, {
      label: 'By Days of the Week',
      option: 'daysOfMonth'
    }
  ];
  nthTypes = [
    {
      label: '1st',
      option: 'first'
    }, {
      label: '2nd',
      option: 'second'
    }, {
      label: '3rd',
      option: 'third'
    }, {
      label: '4th',
      option: 'fourth'
    }, {
      label: 'Last',
      option: 'last'
    }
  ];
  selectedNthType = 'first';
  monthlyData: string[];
  monthlySelected: string[] = [];
  endTypeDate: boolean = false;
  endTypeCount: boolean = false;
  organizationName: string;
  courseName: string;
  statusName: string;
  timeZoneName: string;
  @Input() ContentID: number;
  @Input() isEdit: boolean;
  @Input() canPageWrite: boolean;
  @Input() ClassID: number;
  @Output() closeAddEdit = new EventEmitter<any>();
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private classesService: ClassesModulesService, private messageService: MessageService,
              private location: Location, private dialogService: DialogService,
              private route: ActivatedRoute, private pageService: PageService,
              public config: DynamicDialogConfig) {
    this.mainForm = this.formBuilder.group({
      organization: new FormControl(null, [Validators.required, NotZeroValidation()]),
      course: new FormControl(null, [Validators.required, NotZeroValidation()]),
      classidentifier: new FormControl(null),
      status: new FormControl(null, [Validators.required, NotZeroValidation()]),
      seats: new FormControl(0, [Validators.required, Validators.min(1)]),
      startDate: new FormControl(null, Validators.required),
      startTime: new FormControl(null, Validators.required),
      endDate: new FormControl(null),
      endTime: new FormControl(null, Validators.required),
      timezone: new FormControl(null),
      language: new FormControl(null),
      recurrenceType: new FormControl(null, Validators.required),
      recurrenceEndType: new FormControl(null, Validators.required),
      endTypeDate: new FormControl(null),
      endTypeCount: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);

        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isEdit = this.pageInfo.isEdit;
          this.isPage = this.pageInfo.isPage;
          this.isDialog = this.pageInfo.isDialog;
          this.ContentID = this.pageInfo.ContentID;
        }
        if (this.canPageWrite === undefined) {
          this.pageService.getPagePermissions(this.InterfaceObjectEnum).then((permission: boolean) => {
            this.canPageWrite = permission;
            this.initSelects();
            if (this.canPageWrite) {
              this.mainForm.enable();
            } else {
              this.mainForm.disable();
            }
          });
        } else {
          this.initSelects();
          if (this.canPageWrite) {
            this.mainForm.enable();
          } else {
            this.mainForm.disable();
          }
        }
      }
    });
    // this.mainForm.valueChanges.subscribe({next:() => {
    //   console.log(this.mainForm);
    // }});
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    this.weeklyData = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    this.monthlyData = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
      '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
      '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  cancel(): void {
    this.mainForm.reset();
    this.closeAddEdit.emit();
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  initSelects(): void {
    this.initOrganization(!this.canPageWrite, this.InterfaceObjectEnum, false, 'training');
    this.initCourseSelect(true);
    this.initStatusSelect(!this.canPageWrite);
    this.initTimezoneSelect(!this.canPageWrite);
    this.initLanguageSelect(!this.canPageWrite);
    this.initRecurrenceTypeSelect(!this.canPageWrite);
    this.initRecurrenceEndTypeSelect(!this.canPageWrite);
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean, paramContext: string): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setOrganizationId,
      disabled: disable,
      canWrite: this.canPageWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly, paramContext).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  initCourseSelect(disable: boolean): void {
    this.inputObjCourse = {
      labelText: 'Course',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setCourseId,
      disabled: disable,
      canWrite: this.canPageWrite
    };
    if (this.setOrganizationId) {
      this.lookupsDataService.getCoursesByDownstreamOrganizationIdLookupData(this.setOrganizationId).then((lookupData) => {
        this.inputObjCourse.data = lookupData;
        this.inputObjCourse = Object.assign({}, this.inputObjCourse);
      });
    }

  }

  initStatusSelect(disable: boolean): void {
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStatusId,
      disabled: disable,
      canWrite: this.canPageWrite
    };
    this.lookupsDataService.getClassStatusesLookupData().then((lookupData) => {
      this.inputObjStatus.data = lookupData;
      this.inputObjStatus = Object.assign({}, this.inputObjStatus);
    });
  }

  loadTimezoneSelect(): void {
    this.initTimezoneSelect(!this.canPageWrite);
  }

  initTimezoneSelect(disable: boolean): void {
    this.inputObjTimezone = {
      labelText: 'Timezone',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setTimezoneId,
      disabled: disable,
      canWrite: this.canPageWrite
    };
    if (this.mainForm.get('startDate').value) {
      const param = transformDateTimeToDateOnly(this.mainForm.get('startDate').value);
      this.lookupsDataService.getTimezonesLookupData(param).then((lookupData) => {
        this.inputObjTimezone.data = lookupData;
        this.inputObjTimezone = Object.assign({}, this.inputObjTimezone);
      });
    } else {
      this.setTimezoneId = null;
      this.startDate = null;
    }
  }

  getOrganizationData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setOrganizationId = event[0].ID;
      this.organizationName = event[0].Description;
    } else {
      this.setOrganizationId = 0;
    }
    this.initCourseSelect(!this.canPageWrite);
    this.mainForm.markAsDirty();
    this.mainForm.get('organization').setValue(this.setOrganizationId);

  }

  getCourseData(event: any): void {
    if (event && event[0]) {
      this.setCourseId = event[0].ID;
      this.courseName = event[0].Description;
    } else {
      this.setCourseId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('course').setValue(this.setCourseId);
  }

  getStatusData(event: any): void {
    if (event && event[0]) {
      this.setStatusId = event[0].ID;
      this.statusName = event[0].Description;
    } else {
      this.setStatusId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('status').setValue(this.setStatusId);
  }

  getTimezoneData(event: any): void {
    if (event && event[0]) {
      this.setTimezoneId = event[0].ID;
      this.timeZoneName = event[0].Description;
    } else {
      this.setTimezoneId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('timezone').setValue(this.setTimezoneId);
  }

  initLanguageSelect(disable: boolean): void {
    this.inputObjLanguage = {
      labelText: 'Language',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setLanguageId,
      disabled: disable,
      canWrite: this.canPageWrite
    };
    this.lookupsDataService.getLanguagesLookupData().then((lookupData) => {
      this.inputObjLanguage.data = lookupData;
      this.inputObjLanguage = Object.assign({}, this.inputObjLanguage);
    });
  }

  getLanguageData(event: any) {
    if (event && event[0]) {
      this.setLanguageId = event[0].ID;
    } else {
      this.setLanguageId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('language').setValue(this.setLanguageId);
  }

  initRecurrenceTypeSelect(disable: boolean): void {
    this.inputObjRecurrenceType = {
      labelText: 'Frequency',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setRecurrenceType,
      data: [
        {
          ID: null,
          Description: '--'
        },
        {
          ID: 1,
          Description: 'Daily'
        },
        {
          ID: 2,
          Description: 'Weekly'
        },
        {
          ID: 3,
          Description: 'Monthly'
        }
      ],
      disabled: disable,
      canWrite: this.canPageWrite
    };
  }

  getRecurrenceTypeData(event: any): void {
    this.weeklySelected = [];
    this.monthlySelected = [];
    if (event && event[0]) {
      this.setRecurrenceType = event[0].Description;
      this.weekly = this.setRecurrenceType.toLowerCase() === 'weekly';
      this.monthly = this.setRecurrenceType.toLowerCase() === 'monthly';
    } else {
      this.setRecurrenceType = null;
      this.weekly = false;
      this.monthly = false;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('recurrenceType').setValue(this.setRecurrenceType);
  }

  initRecurrenceEndTypeSelect(disable: boolean): void {
    this.inputObjRecurrenceEndType = {
      labelText: 'End Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setRecurrenceEndType,
      data: [
        {
          ID: null,
          Description: '--'
        },
        {
          ID: 1,
          Description: 'End Date'
        },
        {
          ID: 2,
          Description: 'Recurrence Count'
        }
      ],
      disabled: disable,
      canWrite: this.canPageWrite
    };
  }

  getRecurrenceEndTypeData(event: any): void {
    if (event && event[0]) {
      this.setRecurrenceEndType = event[0].Description;
    } else {
      this.setRecurrenceEndType = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('endTypeDate').setValue(null);
    this.mainForm.get('endTypeCount').setValue(null);
    this.mainForm.get('recurrenceEndType').setValue(this.setRecurrenceEndType.replace(' ', ''));
    this.endTypeDate = this.setRecurrenceEndType.toLowerCase() === 'end date';
    if (this.endTypeDate === true) {
      this.mainForm.get('endTypeDate').setValidators(Validators.required);
    } else {
      this.mainForm.get('endTypeDate').clearValidators();
    }
    this.mainForm.get('endTypeDate').updateValueAndValidity();
    this.endTypeCount = this.setRecurrenceEndType.toLowerCase() === 'recurrence count';
    if (this.endTypeCount === true) {
      this.mainForm.get('endTypeCount').setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.mainForm.get('endTypeCount').clearValidators();
    }
    this.mainForm.get('endTypeCount').updateValueAndValidity();
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.isSaving = true;
      this.class.OrganizationID = this.setOrganizationId;
      this.class.CourseID = this.setCourseId;
      this.class.ClassStatusID = this.setStatusId;
      this.class.ClassIdentifier = this.mainForm.get('classidentifier').value;
      this.class.MaxSeats_N = this.mainForm.get('seats').value;
      this.class.StartDate_D = transformDateTimeToDateOnly(this.mainForm.get('startDate').value);
      this.class.StartTime_T = transformDateTimeToTimeOnly(this.mainForm.get('startTime').value);
      this.class.EndDate_D = transformDateTimeToDateOnly(this.mainForm.get('startDate').value);
      this.class.EndTime_T = transformDateTimeToTimeOnly(this.mainForm.get('endTime').value);
      this.class.TimezoneID = this.setTimezoneId;
      this.class.LanguageID = this.setLanguageId;
      // recurrence details
      this.class.RecurrenceInfo = {
        RecurrenceType: this.mainForm.get('recurrenceType').value,
        RecurrenceDays: this.weeklySelected,
        RecurrenceDaysOfMonth: this.monthly && this.selectedMonthlyType !== 'daysOfMonth' ? this.monthlySelected : null,
        RecurrenceNthDayOfMonth: this.monthly && this.selectedMonthlyType === 'daysOfMonth' ? this.selectedNthType : null,
        RecurrenceEndType: this.mainForm.get('recurrenceEndType').value,
        RecurrenceEndDate: this.mainForm.get('endTypeDate').value,
        RecurrenceCount: this.mainForm.get('endTypeCount').value
      };
      this.confirmData();
    }
  }

  confirmData(): void {
    const ref = this.dialogService.open(ClassConfirmMultipleDialogComponent, {
      data: {
        class: this.class,
        organizationName: this.organizationName,
        courseName: this.courseName,
        statusName: this.statusName
      },
      header: 'Confirm Adding Multiple Classes',
      width: '90%',
      height: '50%'
    });

    ref.onClose.subscribe({
      next: (res) => {
        if (res === undefined || res === 'cancel') {
          //do nothing
        } else {
          this.closeAddEdit.emit(res);
        }
      }
    });
  }

  saveData(): void {
    this.classesService.createMultipleClass(this.class).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res) => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your classes have been created'});
        this.mainForm.markAsPristine();
        this.closeAddEdit.emit(res);
        this.isSaving = false;
      }, error: (e) => {
        if (e?.error?.Message) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: e.error.Message.replace('UM: ', ''),
            life: 5000
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
        }
        this.isSaving = false;
        console.debug(e);
      }
    });
  }

  back(): void {
    this.location.back();
  }

  updateSelectedWeekly(event, index, id): void {
    if (event) {
      let data: string[];
      if (event.checked.length > 0) {
        this.weeklySelected.push(id);
      } else {
        data = this.weeklySelected.filter(x => x !== id);
        this.weeklySelected = [];
        this.weeklySelected = data;
      }
    }
  }

  updateSelectedMonthly(event, index, id): void {
    if (event) {
      let data: string[];
      if (event.checked.length > 0) {
        this.monthlySelected.push(id);
      } else {
        data = this.monthlySelected.filter(x => x !== id);
        this.monthlySelected = [];
        this.monthlySelected = data;
      }
    }
  }
}
