<app-learn-more [interfaceObjectDesc]="interfaceObjectDesc" [interfaceObjectTypeEnum]="'APPSECTION'"
                [parentObjectEnum]="parentObjectEnum"></app-learn-more>
@if (!TypeFilter) {
  <div class="p-grid p-fluid margin-top-5 margin-bottom-5">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="global-filter">
        <p-radioButton [value]="0" [(ngModel)]="selectedFilter" label="All" class="p-mr-3 margin-top-5"
                       (onClick)="selectRadio()" tabindex="0">
        </p-radioButton>
        <p-radioButton [value]="1" [(ngModel)]="selectedFilter" label="Payments" class="p-mr-3 margin-top-5"
                       (onClick)="selectRadio()" tabindex="0">
        </p-radioButton>
      </div>
    </div>
  </div>
}
@if (readyToLoadTable) {
  <p-table
    [rows]="currentFilter.rows"
    dataKey="ID"
    [columns]="cols"
    [value]="transactionHistorysList"
    [lazy]="true"
    (onLazyLoad)="loadTable($event)"
    [paginator]="true"
    [showCurrentPageReport]="true"
    [metaKeySelection]="false"
    [totalRecords]="dataTotal"
    [rowsPerPageOptions]="[25,50,100,250,500]"
    [scrollable]="true"
    rowExpandMode="single"
    paginatorDropdownAppendTo="body"
    selectionMode="single"
    scrollHeight="400px"
    [loading]="!dataLoaded"
    styleClass="p-datatable-responsive-demo p-datatable-striped table-border p-mt-5"
    currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
  >
    <ng-template pTemplate="caption">
      <div class="p-grid p-fluid">
        <div class="col-md-4">
          <div class="global-filter">
            <input pInputText type="text" [(ngModel)]="activeGlobalFilter" [placeholder]="'Description'"
                   (keyup.enter)="filterItems()" tabindex="0"/>
            <div class="grid-filter-buttons">
              <button pButton class="p-button-download export-menu" icon="pi pi-search"
                      pTooltip="Search" tooltipPosition="bottom" (click)="filterItems()">
              </button>
              <button pButton class="p-button-download export-menu p-ml-1" icon="pi pi-times"
                      [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clear()">
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-8 grid-container-buttons-right">
          <div class="action-buttons">
            <button pButton (click)="refresh()" icon="pi pi-refresh" pTooltip="Refresh"
                    tooltipPosition="bottom"></button>
            @if (!isExporting) {
              <button pButton class="p-button-download export-menu export-excel" icon="fa fa-file-excel"
                      pTooltip="Export" tooltipPosition="bottom" (click)="export()">
              </button>
            } @else {
              <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                      icon="pi pi-spin pi-spinner save-spinner">
              </button>
            }
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col) {
          <th pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" [pSortableColumn]="col.field">
            <div class="header-text">
              {{ col.header }}
            </div>
            <div class="header-actions">
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </div>
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
      <tr>
        @for (col of columns; track col) {
          <td [ngStyle]="{'width': col.width}">
            @if (col.visible && col.displayType === 'date') {
              {{ rowData[col.field] | date: 'shortDate' }}
            }
            @if (col.visible && col.displayType === 'text') {
              {{ rowData[col.field] }}
            }
            @if (col.visible && col.displayType === 'currency') {
              @if (rowData[col.field] < 0) {
                <span class="negative"> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
              }
              @if (rowData[col.field] === 0) {
                <span> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
              }
              @if (rowData[col.field] > 0) {
                <span class="positive">{{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
              }
            }
            @if (col.visible && col.displayType === 'icon') {
              @if (rowData[col.field] === 'Payment') {
                <span><i class="fas fa-credit-card" pTooltip="Payment"></i></span>
              }
              @if (rowData[col.field] === 'Refund') {
                <span><i class="fas fa-rotate" pTooltip="Refund"></i></span>
              }
              @if (rowData[col.field] !== 'Payment' && rowData[col.field] !== 'Refund') {
                <span><i class="fas fa-file-invoice" pTooltip="Invoice"></i></span>
              }
            }
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="cols.length" style="width: 100%;">No account transactions found.</td>
      </tr>
    </ng-template>
  </p-table>
}
