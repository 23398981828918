import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import {StudentClassesCoreGridModel} from '../models/student-classes.model';

@Injectable({
  providedIn: 'root',
})
export class StudentClassService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getStudentClassesPrimeNG(dataMode: string, personId: number, filterData, completedOnly?: boolean, activeOnly?: boolean): Observable<StudentClassesCoreGridModel> {
    if(completedOnly === undefined) { completedOnly = false; }
    if(activeOnly === undefined) { activeOnly = false; }
    return this.http.post<StudentClassesCoreGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/classgrid?mode=${dataMode}&completedOnly=${completedOnly}&activeOnly=${activeOnly}`, filterData);
  }

  getUserClassesPrimeNG(dataMode: string, filterData, completedOnly?: boolean, activeOnly?: boolean): Observable<StudentClassesCoreGridModel> {
    return this.http.post<StudentClassesCoreGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/classgrid?mode=${dataMode}&completedOnly=${completedOnly}&activeOnly=${activeOnly}`, filterData);
  }
}
