import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-roster-student-training-certification',
  templateUrl: './student-training-certification.component.html'
})

export class StudentTrainingCertificationComponent implements OnInit {
  activeSubTab: string;
  @Input() DBEntity;
  @Input() DBEntityID;
  @Input() canTabWrite;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;

  constructor() {
  }

  ngOnInit(): void {
    this.activeSubTab = 'enrolled';
  }
}
