import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  OrganizationAutoPayModel, OrganizationPaymentDetailsGridModel, OrganizationPaymentPayeesGridModel, OrganizationPaymentSplitModel,
  OrganizationPaymentTransactionsGridModel
} from '../models/organization-payments.model';
import {PaymentMethodModel} from '../models/payment-methods.model';

@Injectable({
  providedIn: 'root',
})
export class PctPaymentsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getPaymentDetailsPrimeNG(dataMode: string, organizationId: number, startDate: string, endDate: string, filterData): Observable<OrganizationPaymentDetailsGridModel> {
    return this.http.post<OrganizationPaymentDetailsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/organizationpaymentdetails/grid?startdate=${startDate}&endDate=${endDate}&mode=${dataMode}`, filterData);
  }

  createReceivePayment(modelData): Observable<OrganizationPaymentSplitModel> {
    return this.http.post<OrganizationPaymentSplitModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/organizationpayment`, modelData);
  }

  getPaymentsPayeePrimeNG(dataMode: string, organizationId: number, filterData): Observable<OrganizationPaymentPayeesGridModel> {
    return this.http.post<OrganizationPaymentPayeesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/payee/grid?mode=${dataMode}`, filterData);
  }

  getPaymentsTransactionHistoryGridData(organizationId: number, payeeorganizationid: number): Observable<OrganizationPaymentTransactionsGridModel> {
    return this.http.get<OrganizationPaymentTransactionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/transactionhistory/grid?payeeorganizationid=${payeeorganizationid}`);
  }

  getAutoPaymentByOrganizationData(organizationid: number, payeeId: number): Observable<OrganizationAutoPayModel> {
    return this.http.get<OrganizationAutoPayModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationid}/payee/${payeeId}/autopay`);
  }

  updatePaymentByOrganizationData(organizationid: number, payeeId: number, modelData): Observable<PaymentMethodModel> {
    return this.http.put<PaymentMethodModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationid}/payee/${payeeId}/autopay`, modelData);
  }

  deletePaymentByOrganizationData(organizationid: number, payeeId: number): Observable<PaymentMethodModel> {
    return this.http.delete<PaymentMethodModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationid}/payee/${payeeId}/autopay`);
  }
}
