@if (invalidRecord) {
  <app-record-not-found></app-record-not-found>
}
@if (!invalidRecord) {
  <div class="content p-input-filled margin-top-10">
    <div class="p-grid">
      <div class="col-md-12 col-sm-12 col-xs-12">
        @if (isPage && !isDialog) {
          <div class="col-md-12 col-sm-12 col-xs-12 add-edit-header">
            @if (!invalidRecord && isPage && !isDialog) {
              <div class="inline-back-button" (click)="back()" pTooltip="Back">
                <i class="pi pi-angle-left"></i>
              </div>
            }
            @if (isStatusInActive(roster.StatusAlias)) {
              <div class="inactive-flag-hard-corner"></div>
            }
            <div class="p-grid p-fluid p-ml-2">
              <div class="col-md-1 col-sm-3 col-xs-12 relative tab-container-profile">
                @if (roster.ThumbnailUrl) {
                  <div>
                    <div class="avatar-with-image" [ngClass]="getAvatarClass(true, roster.StatusAlias)"><img
                      ngSrc="{{roster.ThumbnailUrl + blobToken}}" (error)="setDefaultPic()" alt="" width="71" height="71" [priority]="true"/></div>
                  </div>
                }
                @if (!roster.ThumbnailUrl) {
                  <div>
                    <div class="avatar-without-image"
                         [ngClass]="getAvatarClass(false, roster.StatusAlias)"><img ngSrc="assets/images/profile-generic.png" alt="" width="71" height="71" [priority]="true"/></div>
                    @if (roster.Positions) {
                      <div class="has-position-no-image"><i class="fas fa-id-card"></i></div>
                    }
                  </div>
                }
              </div>
              @if (roster) {
                <div class="col-md-6 col-sm-12 col-xs-12">
                  @if (isEdit && student) {
                    <h2>{{ student.FirstName }} {{ student.MiddleName }} {{ student.LastName }}@if (roster.Suffix) {
                      <span> {{ roster.Suffix }}</span>
                    }
                      @if (roster.OrganizationAbbreviation) {
                        <span> - {{ roster.OrganizationAbbreviation }}</span>
                      }</h2>
                    <h4>
                      @if (student.StatusAlias) {
                        <span
                          class="p-m-0"><b>{{ roster.StatusAlias }} {{ roster.TypeAlias }} @if (roster.MemberSubclass) {
                          <span> - {{ roster.MemberSubclass | firstLetter }}</span>
                        }</b></span>
                      }
                      @if (student.MemberNumber) {
                        <span class="p-m-0"> | <b>Member #:</b> {{ roster.MemberNumber }}</span>
                      }
                      <!--                <span *ngIf="student.MemberStartDate" class="p-m-0"> | <b>Initiated:</b> {{roster.MemberStartDate | date: 'shortDate'}}</span>-->
                      <!--                <span *ngIf="student.PaidThroughDate" class="p-m-0"> | <b>Paid Through:</b> {{roster.PaidThroughDate | date : 'MM/dd/yyy'}}</span>-->
                      @if (student.LastLoginDate_D) {
                        <span class="p-m-0"> | <b>Last Login:</b> {{ roster.LastLoginDate_D | localizedatetime:true:true }}</span>
                      }
                    </h4>
                  } @else {
                    <h2>Add Student</h2>
                  }
                </div>
                <div class="col-md-5 col-sm-12 col-xs-12 list-container list-container-header">
                  <div class="list-action-buttons p-mr-2">
                    <app-roster-student-action-buttons [canPageWrite]="canPageWrite" [roster]="roster"
                                                       [i]="0" [permissions]="permissions"
                                                       [blobToken]="blobToken"></app-roster-student-action-buttons>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        @if (!isPage || isDialog) {
          <p-divider></p-divider>
        }
        @if (showTabs) {
          <p-tabView (onChange)="resetWarning()">
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDemographics')) {
              <p-tabPanel [cache]="false" header="Contact">
                <ng-template pTemplate="content">
                  <app-student-roster-contact-form [PersonID]="ContentID" [isEdit]="isEdit"
                                                   [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDemographics')"></app-student-roster-contact-form>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabMembership')) {
              <p-tabPanel [cache]="false" header="Membership">
                <ng-template pTemplate="content">
                  <app-roster-student-membership [PersonID]="ContentID"
                                                 [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabMembership')"
                                                 [isEdit]="isEdit"></app-roster-student-membership>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPii')) {
              <p-tabPanel [cache]="false" header="Secure Data">
                <ng-template pTemplate="content">
                  <app-roster-tab-secure-data [PersonID]="ContentID"
                                              [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabPii')"
                                              [isEdit]="isEdit"></app-roster-tab-secure-data>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabStudent')) {
              <p-tabPanel [cache]="false" header="Student">
                <ng-template pTemplate="content">
                  <app-student-roster-form-student [PersonID]="ContentID" [isEdit]="isEdit"
                                                   [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabStudent')"></app-student-roster-form-student>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPrograms')) {
              <p-tabPanel header="Programs" [cache]="false">
                <ng-template pTemplate="content">
                  <app-roster-student-programs-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabPrograms')"
                    [PersonID]="ContentID" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabPrograms')"></app-roster-student-programs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabTimeEntry') && !isUserRecord()) {
              <p-tabPanel header="Time" [cache]="false">
                <ng-template pTemplate="content">
                  <app-student-time [PersonID]="ContentID" [isEdit]="isEdit"
                                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabTimeEntry')"
                                    [studentFacing]="null"></app-student-time>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabClassHistory')) {
              <p-tabPanel header="Training & Certifications">
                <ng-template pTemplate="content">
                  <app-roster-student-training-certification [DBEntity]="'PERSON'" [DBEntityID]="ContentID"
                                                             [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabClassHistory')"
                                                             [interfaceObjectDesc]="'tabClassHistory'"
                                                             [parentObjectEnum]="InterfaceObjectEnum"></app-roster-student-training-certification>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCommunications')) {
              <p-tabPanel header="Communications">
                <ng-template pTemplate="content">
                  <app-communications-user-profile-tab [DBEntityID]="ContentID"
                                                       [interfaceObjectDesc]="'tabCommunications'"
                                                       [parentObjectEnum]="InterfaceObjectEnum"></app-communications-user-profile-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEmployment') && _employmentTradeTab()) {
              <p-tabPanel header="Employment">
                <ng-template pTemplate="content">
                  <app-employment-trade-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabEmployment')"
                    [PersonID]="ContentID" [OrganizationID]="organizationId" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployment')"
                    [interfaceObjectDesc]="'tabEmployment'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-employment-trade-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEmployment') && !_employmentTradeTab()) {
              <p-tabPanel header="Employment">
                <ng-template pTemplate="content">
                  <app-employment-default-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabEmployment')"
                    [PersonID]="ContentID" [OrganizationID]="organizationId" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployment')"
                    [interfaceObjectDesc]="'tabEmployment'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-employment-default-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabTradesSkills')) {
              <p-tabPanel header="Trades/Skills" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-trade-skills
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabTradesSkills')"
                    [ContentID]="ContentID" DBEntity="Profile" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabTradesSkills')"></app-trade-skills>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabChangeLog')) {
              <p-tabPanel header="Change Log">
                <ng-template pTemplate="content">
                  <app-change-logs-tab [PersonID]="ContentID" [interfaceObjectDesc]="'tabChangeLog'"
                                       [parentObjectEnum]="InterfaceObjectEnum"
                                       [context]="'training'"></app-change-logs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDocs')) {
              <p-tabPanel header="Docs">
                <ng-template pTemplate="content">
                  <app-docs-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                                [DBEntity]="'PERSONPROFILE'" [DBEntityID]="PersonProfileID"
                                [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDocs')"
                                [showAccess]="true" [InterfaceObjectEnum]="InterfaceObjectEnum"
                                [interfaceObjectDesc]="'tabDocs'"
                                [parentObjectEnum]="InterfaceObjectEnum"></app-docs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabNotes')) {
              <p-tabPanel header="Notes">
                <ng-template pTemplate="content">
                  <app-notes-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                                 [DBEntity]="'PERSONPROFILE'" [DBEntityID]="PersonProfileID"
                                 [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [canEdit]="_tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [interfaceObjectDesc]="'tabNotes'" [parentObjectEnum]="InterfaceObjectEnum"
                                 [showDisclaimer]="true"></app-notes-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCustomFields')) {
              <p-tabPanel header="Custom Fields">
                <ng-template pTemplate="content">
                  <app-custom-fields-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabCustomFields')"
                    [PersonID]="ContentID" [category]="'education'" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabCustomFields')"
                    [interfaceObjectDesc]="'tabCustomFields'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-custom-fields-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabApprenticeHours') && student.OrganizationCountry === 'Canada') {
              <p-tabPanel header="Apprentice Hours" [cache]="false">
                <ng-template pTemplate="content">
                  <app-student-apprentice-hours [student]="student" [PersonID]="ContentID" [OrgId]="organizationId" [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabUserAccount')"></app-student-apprentice-hours>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabUserAccount')) {
              <p-tabPanel header="User Account">
                <ng-template pTemplate="content">
                  <app-roster-student-tab-user-account [PersonID]="ContentID" [isEdit]="isEdit"
                                                       [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabUserAccount')"
                                                       (updateImage)="getDataToEdit(ContentID)"></app-roster-student-tab-user-account>
                </ng-template>
              </p-tabPanel>
            }
          </p-tabView>
        }
      </div>
    </div>
  </div>
}
