import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {SharableTrainingCertificationModel, SharableTrainingCertificationsGridModel} from '../../../../models/sharables.model';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SharablesService} from '../../../../services/sharables.service';
import {MessageService} from 'primeng/api';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-training-certifications-tab',
  templateUrl: './training-certifications-tab.component.html',
  styleUrls: ['./training-certifications-tab.component.scss']
})
export class TrainingCertificationsTabComponent implements OnInit, OnChanges, OnDestroy {
  trainingCertifications: SharableTrainingCertificationsGridModel;
  trainingCertificationList: SharableTrainingCertificationModel[] = [];
  dataLoaded: boolean;
  totalLoaded: boolean;
  isExporting: boolean;
  selSharableId: number;
  isButtonDisabled: boolean = true;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  selectedItem: number;
  showAddEdit: boolean;
  isCheckboxActive: boolean = false;
  isCheckboxExpired: boolean = false;
  dataTotal: number = 0;
  today = new Date();
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() canTabWrite: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private sharablesService: SharablesService, private messageService: MessageService,
              private dialogUtility: DialogUtility, public dialogService: DialogService,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.getData();
  }

  getData(): void {
    this.getTotal();
    this.getList();
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.sharablesService.getTrainingCertificationsPrimeNG('TOTAL', this.DBEntityID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.trainingCertificationList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.sharablesService.getTrainingCertificationsPrimeNG('DATA', this.DBEntityID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.trainingCertifications = res;
          this.trainingCertificationList = res.Data;
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
  }

  deleteTrainingCertification(sharableId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.sharablesService.deleteSharable(sharableId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected training/certification has been removed.'
            });
            this.updateList(true);
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList(dataChanged: boolean): void {
    this.showAddEdit = false;
    if (dataChanged) {
      this.selectedItem = -1;
      this.getData();
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterItems(): void {
    this.dataLoaded = false;
    if (this.isCheckboxActive) {
      //this.initFilters('CompletionDate_D', new Date().toLocaleString().split(',')[0], 'gte', 'or');
      this.currentFilter.filters = {
        CompletionDate_D: [
          {
            value: new Date().toLocaleDateString().split('T')[0],
            matchMode: 'lte',
            operator: 'or'
          },
          {
            value: null,
            matchMode: 'isNullOrEmpty',
            operator: 'or'
          }
        ],
        ExpirationDate_D: [
          {
            value: new Date().toLocaleDateString().split('T')[0],
            matchMode: 'gt',
            operator: 'or'
          },
          {
            value: null,
            matchMode: 'isNullOrEmpty',
            operator: 'or'
          }
        ]
      };
    }
    if (this.isCheckboxExpired) {
      this.initFilters('ExpirationDate_D', new Date().toLocaleString().split(',')[0], 'lt', 'or');
    }
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('Title', this.activeGlobalFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.Title;
    }
    this.getData();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.isCheckboxActive = false;
    this.isCheckboxExpired = false;
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  goToEdit(event:any): void {
    this.selSharableId = event.SharableId;
  }

  refresh(): void {
    this.getData();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  resetComponent(): void {
    this.trainingCertificationList = undefined;
    this.trainingCertifications = undefined;
  }

  manageEnableDisable(type: string): void {
    if (type === 'enable') {
      this.isCheckboxActive = false;
    } else {
      this.isCheckboxExpired = false;
    }
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.trainingCertificationList));
        const dataToExport = removeKeysFromArray(arr, ['SharableId', 'CanEdit', 'Context', 'ResourceReferenceID', 'ExternalUrl', 'CanDelete']);
        exportData('training-certifications', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.sharablesService.getTrainingCertificationsPrimeNG('DATA', this.DBEntityID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['SharableId', 'CanEdit', 'Context', 'ResourceReferenceID', 'ExternalUrl', 'CanDelete']);
              exportData('training-certifications', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }

  getDate(dt: string) {
    if (dt) {
      return new Date(dt);
    } else {
      return this.today;
    }
  }
}
