import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {RosterProfileTransactionModel, RosterProfileTransactionsGridModel, RosterProfileTxnTransactionModel} from '../../../../../models/roster-profile-transactions.model';
import {RosterProfileTransactionsService} from '../../../../../services/roster-profile-transactions.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {removeKeysFromArray} from '../../../../utilities/data.utility';
import {exportData} from '../../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../../utilities/list-filter.utility';
import {ResourcesService} from '../../../../../services/resources.service';
import {DialogService} from 'primeng/dynamicdialog';
import {AdminBalanceCorrectionToolComponent} from '../../../admin-balance-correction-tool/admin-balance-correction-tool.component';
import {AccountingInvoiceDetailsGridModel, AccountingReceiptDetailsGridModel} from '../../../../../models/invoices.model';
import {ContextService} from '../../../../../services/context.service';
import {MessageService} from 'primeng/api';
import {AccountAdjustmentsService} from '../../../../../services/account-adjustments.service';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {TenantConfigDataService} from '../../../../../services/tenant-config-data.service';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import * as FileSaver from 'file-saver';
import {DialogUtility} from '../../../../utilities/dialog.utility';

@Component({
  selector: 'app-account-statement-transactions-tab',
  templateUrl: './account-statement-transactions-tab.component.html',
  styleUrls: ['./account-statement-transactions-tab.component.scss']
})

export class AccountStatementTransactionsTabComponent implements OnInit, OnDestroy {
  accountTransactions: RosterProfileTransactionsGridModel;
  accountTransactionsList: RosterProfileTransactionModel[];
  accountTxnTransactionsList: RosterProfileTxnTransactionModel[];
  isExporting: boolean;
  loading: boolean;
  inputObjOrganization: GenericSelectModel;
  @Input() PersonID: number;
  @Input() organizationId: number;
  @Input() isEdit: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() TypeFilter: string;
  @Input() canTabWrite: boolean;
  @Input() pdfType = 'PDF Receipts';
  @Output() updateData = new EventEmitter<any>();
  @Output() updateProfileAccount: EventEmitter<number> = new EventEmitter<number>();
  @Output() updateGlobalOrgId = new EventEmitter<any>();
  // tempCurrentDate;
  // tempAdjustedDate;
  setOrganizationId: number;
  cols = [];
  colsTxn = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  currentInvoiceFilter = new PrimeTableFilterModel();
  currentReceiptFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  typeAll: number = 0;
  dataTotal: number = 0;
  readyToLoadTable: boolean = false;
  firstLoad: boolean;
  showTxnTable: boolean = false;
  getListRequest;
  getTotalRequest;
  @Input() selectedTable = 'Invoices';
  invoiceReceiptData: AccountingReceiptDetailsGridModel;
  invoiceInvoiceData: AccountingInvoiceDetailsGridModel;
  getListRosterTxnTransactionsRequest;
  getTotalRosterTxnTransactionsRequest;
  canEdit: boolean;
  // accountAdjustments = {} as AccountAdjustmentListModel;
  isSaving: boolean = false;
  loadingExpand: boolean = false;
  hasTransactions: boolean;
  STATEMENTBILLING: boolean;

  private ngUnsubscribe = new Subject();

  constructor(private rosterProfileTransactionsService: RosterProfileTransactionsService, private dialogUtility: DialogUtility,
              private resourcesService: ResourcesService, private dialogService: DialogService, private contextService: ContextService,
              private messageService: MessageService, private accountAdjustmentsService: AccountAdjustmentsService,
              public pageRowCountUtility: PageRowCountUtility, private tenantConfigDataService: TenantConfigDataService,
              private lookupsDataService: LookupsDataService) {
  }

  ngOnInit(): void {
    this.STATEMENTBILLING = this.tenantConfigDataService.getBooleanValue('STATEMENTBILLING');
    if (!this.STATEMENTBILLING) {
      this.showTxnTable = true;
    }

    this.cols = [
      {
        field: 'TransactionDate_D',
        header: ' Invoice Date',
        columnType: 'date',
        matchMode: 'contains',
        visible: true,
        displayType: 'date'
      },
      {
        field: 'Description',
        header: 'Description',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'Status',
        header: 'Status',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'StatusDetails',
        header: 'Status Details',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'Amount_N',
        header: 'Amount',
        columnType: 'numeric',
        matchMode: 'gte',
        visible: true,
        displayType: 'currency'
      },
      {
        field: 'PaidThroughDate_D',
        header: 'Dues PTD',
        columnType: 'date',
        matchMode: 'equals',
        visible: true,
        displayType: 'date'
      },

      // { field: 'ResourceReferenceID', header: this.pdfType, columnType: 'numeric', matchMode: 'gte', visible: true}
    ];
    this.colsTxn = [
      {
        field: 'OrganizationName',
        header: 'Organization',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'TransactionDate_D',
        header: 'Date',
        columnType: 'date',
        matchMode: 'contains',
        visible: true,
        displayType: 'date'
      },
      {
        field: 'Type',
        header: 'PDF Invoices',
        columnType: 'pdfInvoice',
        matchMode: 'equals',
        visible: true,
        displayType: 'pdfInvoice'
      },
      {
        field: 'Description',
        header: 'Description',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {field: 'Memo', header: 'Memo', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {
        field: 'Amount_N',
        header: 'Amount',
        columnType: 'numeric',
        matchMode: 'gte',
        visible: true,
        displayType: 'currency'
      },
      {
        field: 'Type',
        header: 'Receipt PDF',
        columnType: 'pdfPayment',
        matchMode: 'equals',
        visible: true,
        displayType: 'pdfPayment'
      },
    ];

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    if (this.TypeFilter) {
      this.currentFilter.filters = {
        Type: [
          {
            value: this.TypeFilter,
            matchMode: 'equals',
            operator: 'or'
          }
        ]
      };
      this.currentInvoiceFilter.rows = this.pageRowCountUtility.pageRowCount();
      this.currentInvoiceFilter.sortOrder = 1;
      this.currentInvoiceFilter.first = 0;
      this.currentInvoiceFilter.filters = {};

      this.currentReceiptFilter.rows = this.pageRowCountUtility.pageRowCount();
      this.currentReceiptFilter.sortOrder = 1;
      this.currentReceiptFilter.first = 0;
      this.currentReceiptFilter.filters = {};
      this.readyToLoadTable = true;

    } else {
      this.initFilters('Type', 'Invoice', 'equals', 'and');
      this.firstLoad = true;
      this.readyToLoadTable = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.PersonID) {
      if (this.PersonID !== this.contextService.contextObject.PersonId) {
        this.canEdit = true;
      }
    }
    if (this.PersonID && this.parentObjectEnum) {
      this.initOrganization(false, this.parentObjectEnum, false);
    }
    if (changes.organizationId && this.organizationId) {
      this.initOrganization(false, this.parentObjectEnum, false);
      this.setOrganizationId = this.organizationId;
      this.loadTable();
    }
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: false,
      selectFirstValue: (!this.organizationId),
      initSelected: this.organizationId,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByPersonByEnumTransactionHistoryLookupData(this.parentObjectEnum, this.PersonID).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
      if (this.inputObjOrganization.data?.length > 0) {
        this.hasTransactions = true;
      }
    });
  }

  setOrganizationData(event:any): void {
    if (event && event[0]) {
      this.setOrganizationId = (event[0].ID !== null) ? event[0].ID : null;
    } else {
      this.setOrganizationId = 0;
    }
    if (this.selectedTable === 'Transactions') {
      this.loadTxnTable();
    } else {
      this.loadTable();
    }

    this.updateGlobalOrgId.emit(this.setOrganizationId);
  }

  selectRadio() {
    if (this.selectedTable === 'Payments') {
      this.cols.push({
        field: 'ReceiptNumber',
        header: 'Receipt #',
        columnType: 'pdfPayment',
        matchMode: 'equals',
        visible: true,
        displayType: 'text'
      });
      this.cols.push({
        field: 'Employer',
        header: 'Employer',
        columnType: 'text',
        matchMode: 'equals',
        visible: true,
        displayType: 'text'
      });

    } else if (this.cols.find(x => x.field === 'ReceiptNumber')) {
      const index = this.cols.findIndex(x => x.field === 'ReceiptNumber');
      this.cols.splice(index, 1);
      const index2 = this.cols.findIndex(x => x.field === 'Employer');
      this.cols.splice(index2, 1);
    }
    return new Promise((resolve, reject) => {
      this.currentFilter.filters = {};
      this.activeGlobalFilter = null;
      switch (this.typeAll) {
        case 0:
          this.cols[0].header = 'Invoice Date';
          this.cols[4].header = 'Invoice Amount';
          this.accountTransactionsList = [];
          if (this.showTxnTable === false) {
            this.filterItems();
          }
          this.showTxnTable = false;
          break;
        case 1:
          this.cols[0].header = 'Payment Date';
          this.cols[4].header = 'Payment Amount';
          this.accountTransactionsList = [];
          if (this.showTxnTable === false) {
            this.filterItems();
          }
          this.showTxnTable = false;
          break;
        case 2:
          this.accountTxnTransactionsList = [];
          this.showTxnTable = true;
          break;
        default:
          this.showTxnTable = false;
          break;
      }
      resolve(null);
    });
  }

  loadRadio() {
    switch (this.typeAll) {
      case 0:
        //timing issue on this one on load was throwing errors. had to do this way instead BK
        this.currentFilter.filters = {
          Type: [
            {
              value: 'Invoice',
              matchMode: 'equals',
              operator: 'or'
            }
          ]
        };
        //this.initFilters('Type', 'Invoice', 'equals', 'and');
        break;
      case 1:
        this.initFilters('Type', 'Payment', 'equals', 'and');
        break;
      default:
        if (this.currentFilter.filters?.Type) {
          delete this.currentFilter.filters.Type;
        }
        break;
    }
  }

  loadTable(event?): void {
    if (event) {
      this.currentFilter.sortOrder = event.sortOrder;
      this.currentFilter.sortField = event.sortField;
      this.currentFilter.first = event.first;
      this.currentFilter.rows = event.rows;
    }
    if (this.TypeFilter) {
      this.currentFilter.filters = {
        Type: [
          {
            value: this.TypeFilter,
            matchMode: 'equals',
            operator: 'or'
          }
        ]
      };
    } else {

      this.loadRadio();
    }
    this.getAccountTransactions();
  }


  getAccountTransactions(): void {

    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    if (this.PersonID) {
      this.getListRequest = this.rosterProfileTransactionsService.getRosterTransactionsPrimeNG('DATA', this.PersonID, this.currentFilter, this.setOrganizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.accountTransactions = res;
            this.accountTransactionsList = res.Data;
            this.loading = false;
            this.readyToLoadTable = true;
          }
        });
      this.getTotalRequest = this.rosterProfileTransactionsService.getRosterTransactionsPrimeNG('TOTAL', this.PersonID, this.currentFilter, this.setOrganizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.dataTotal = res.Total;
          }
        });
    } else {
      this.loading = false;
    }
  }

  loadTxnTable(event?): void {
    if (event) {
      this.currentFilter = event;
    } else {
      this.currentFilter.filters = {};
      this.currentFilter.sortOrder = 1;
    }
    this.getAccountTxnTransactions();
  }

  getAccountTxnTransactions(): void {
    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    if (this.PersonID) {
      if (this.getListRosterTxnTransactionsRequest) {
        this.getListRosterTxnTransactionsRequest.unsubscribe();
      }
      this.getListRosterTxnTransactionsRequest = this.getListRequest = this.rosterProfileTransactionsService.getRosterTxnTransactionsPrimeNG('DATA', this.PersonID, this.currentFilter, this.setOrganizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.accountTxnTransactionsList = res.Data;
            this.loading = false;
          }
        });
      if (this.getTotalRosterTxnTransactionsRequest) {
        this.getTotalRosterTxnTransactionsRequest.unsubscribe();
      }
      this.getTotalRosterTxnTransactionsRequest = this.getTotalRequest = this.rosterProfileTransactionsService.getRosterTxnTransactionsPrimeNG('TOTAL', this.PersonID, this.currentFilter, this.setOrganizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.dataTotal = res.Total;
          }
        });
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  refresh(): void {
    if (this.showTxnTable) {
      this.loadTxnTable(this.currentFilter);
    } else {
      this.loadTable();
    }
    this.updateProfileAccount.emit(this.PersonID);
  }

  filterItems(): void {

    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      if (this.showTxnTable) {
        this.initFilters('Description', this.activeGlobalFilter, 'contains', 'and');
      } else {
        this.initFilters('StatusDetails', this.activeGlobalFilter, 'contains', 'and');
      }
    } else {
      this.initFilters('StatusDetails', null, 'contains', 'and');
    }
    if (!this.showTxnTable) {
      if (this.TypeFilter) {
        this.initFilters('Type', this.TypeFilter, 'equals', 'and');
      } else {
        this.loadRadio();
      }
    }
    if (this.showTxnTable) {
      this.loadTxnTable(this.currentFilter);
    } else {
      this.loadTable();
    }
  }

  clear(): void {
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.activeGlobalFilter = null;
      if (this.showTxnTable) {
        delete this.currentFilter.filters.Description;
      } else {
        delete this.currentFilter.filters.StatusDetails;
      }
    }

    if (this.showTxnTable) {
      this.loadTxnTable(this.currentFilter);
    } else {
      if (this.TypeFilter) {
        this.initFilters('Type', this.TypeFilter, 'equals', 'and');
      } else {
        this.loadRadio();
      }
      this.loadTable();
    }
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string): void {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.showTxnTable) {
        // this was not firing properly. I figured with the way this one was set up probably best to get from api every time. BK

        // if (this.accountTransactionsList.length <= this.pageRowCountUtility.pageRowCount()) {
        //   const arr = JSON.parse(JSON.stringify(this.accountTransactionsList));
        //   const dataToExport = removeKeysFromArray(arr, ['Type','ResourceReferenceID']);
        //   exportData('account-transactions', dataToExport);
        //   this.isExporting = false;
        // } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.rosterProfileTransactionsService.getRosterTxnTransactionsPrimeNG('DATA', this.PersonID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['Type', 'ResourceReferenceID']);
              exportData('account-transactions', dataToExport);
              this.isExporting = false;
            }
          });
        //}
      } else {
        // if (this.accountTxnTransactionsList.length <= this.pageRowCountUtility.pageRowCount()) {
        //   const arr = JSON.parse(JSON.stringify(this.accountTxnTransactionsList));
        //   const dataToExport = removeKeysFromArray(arr, []);
        //   exportData('account-transactions', dataToExport);
        //   this.isExporting = false;
        // } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.rosterProfileTransactionsService.getRosterTransactionsPrimeNG('DATA', this.PersonID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['ID', 'CanEdit', 'InvoicePDF', 'ReceiptPDF',]);
              exportData('account-transactions', dataToExport);
              this.isExporting = false;
            }
          });
      }
      //}
    }, () => {
    });
  }

  openResource(resourceReferenceId: number): void {
    this.resourcesService.getResourceById(resourceReferenceId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (resource) => {
          if (resource) {
            window.open(resource.Url, '_blank');
          }
        }
      });
  }

  getAdjustReceipt(paymentId: number, description: string) {
    this.rosterProfileTransactionsService.getRosterUpdatedPaymentReceipt(paymentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          let fileName = res.headers.get('Content-Disposition');
          fileName = fileName.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, '');
          this.downloadFile(res.body, fileName, 'application/pdf');
        }
      });
  }

  downloadFile(buffer, fileName, fileType) {
    import('file-saver').then(() => {
      const data: Blob = new Blob([buffer], {
        type: fileType
      });
      console.log(fileName);
      FileSaver.saveAs(data, fileName);
    });
  }

  getPaymentInvoiceDetail(id) {
    this.invoiceInvoiceData = null;
    this.invoiceReceiptData = null;
    this.loadingExpand = true;
    if (this.selectedTable === 'Invoices') {
      this.rosterProfileTransactionsService.getRosterInvoiceDetailPrimeNG('DATA', id, this.currentInvoiceFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.invoiceInvoiceData = res;
            this.loadingExpand = false;
          }
        });
    } else {
      this.rosterProfileTransactionsService.getRosterReceiptDetailPrimeNG('DATA', id, this.currentReceiptFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.invoiceReceiptData = res;
            this.loadingExpand = false;
          }
        });
    }
  }

  refreshLatestInvoicePayment(event:any) {
    if (this.selectedTable === 'Invoices') {
      const data = {
        InvoiceIDList: [event.ID]
      };
      this.accountAdjustmentsService.recreateInvoice(0, data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: this.selectedTable === 'Invoice' ? 'Invoice has been regenerated.' : 'Receipt has been regenerated.'
            },);
          }, error: (e) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: e.error.Message.replace('UM: ', ''),
              life: 5000
            });
          }
        });
    }
    if (this.selectedTable === 'Payments') {
      const data = {
        InvoiceIDList: [event.ID]
      };
      this.accountAdjustmentsService.recreateReceipt(0, data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Receipt has been regenerated.'
            });
          }, error: (e) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: e.error.Message.replace('UM: ', ''),
              life: 5000
            });
          }
        });
    }
  }

  openAccountAdjustmentTool(event:any) {
    const ref = this.dialogService.open(AdminBalanceCorrectionToolComponent, {
      data: {
        isDialog: true,
        organizationId: this.organizationId,
        personId: this.PersonID,
        selectedTable: event.Type,
        ID: event.ID
      },
      width: '95%',
      height: '80%'
    });
    ref.onDestroy.subscribe({
      next: () => {
        this.getAccountTransactions();
        this.updateData.emit();
      }
    });
  }
}
