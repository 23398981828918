<div class="parent-p-card">
  <div>
    <div class="p-grid p-fluid margin-top-15">
      <div [ngClass]="{'col-md-2 col-sm-12 col-xs-12 list-filter-container' : showFilter, 'hide' : !showFilter}">
        <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
          <div class="margin-top-10 filter-item-padding">
            <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'"
                            [interfaceObjectDesc]="InterfaceObjectEnum"></app-learn-more>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <h3>Filter</h3>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
            <label>Description</label>
            <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter"
                   (keyup.enter)="filterItems()" tabindex="0"/>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
            <div class="list-filter-buttons text-align-right">
              <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"
                      [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clearFilters()">
              </button>
              <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"
                      (click)="filterItems()">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-10 col-sm-12 col-xs-12 list-container-header" [ngClass]="{'col-md-12' : !showFilter}">
        <div class="p-grid p-fluid">
          @if (!totalLoaded) {
            <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
          } @else {
            <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">{{ dataTotal | number:'1.0':'en-US' }}
              @if ((currentFilter.filters | json) !== '{}') {
                <span>Filtered </span>
              }
              @if (dataTotal === 1) {
                Result
              } @else {
                Results
              }</h2>
          }
          <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
            <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                         (onPageChange)="paginate($event)"></p-paginator>
          </div>
          <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
            @if ((currentFilter.filters | json) !== '{}') {
              <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                      pTooltip="Clear Filter" tooltipPosition="bottom"
                      [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                      (click)="clearFilters()">
              </button>
            }
            <button pButton class="p-button-download export-menu p-mr-2" icon="pi pi-filter"
                    pTooltip="Filter Toggle" tooltipPosition="bottom" (click)="toggleFilter()">
            </button>
            <button pButton (click)="refresh()" icon="pi pi-refresh"
                    pTooltip="Refresh" tooltipPosition="bottom"></button>
            @if (canAdd) {
              <button pButton (click)="addWorkflowStep()"
                      icon="pi pi-plus" pTooltip="Add"
                      tooltipPosition="bottom"></button>
            }
            @if (!isExporting) {
              <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                      icon="fa fa-file-excel"
                      pTooltip="Export" tooltipPosition="bottom" (click)="export()">
              </button>
            } @else {
              <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                      icon="pi pi-spin pi-spinner save-spinner">
              </button>
            }
          </div>
          <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
            <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                         (onPageChange)="paginate($event)"></p-paginator>
          </div>
        </div>
        @if (showAddEdit) {
          <div>
            <p-card header="Add Step" [styleClass]="'list-card'">
              <ng-template pTemplate="content">
                <app-workflow-steps-form [workflowID]="workflowId" [category]="'case'" [organizationId]="organizationId"
                                         (closeAddEdit)="getData()" [isEdit]="false"
                                         [canTabWrite]="canTabWrite"></app-workflow-steps-form>
              </ng-template>
            </p-card>
          </div>
        }
        <div class="list-container p-grid p-fluid">
          @if (totalLoaded && dataTotal <= 0 && !showAddEdit) {
            <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
              @if ((currentFilter.filters | json) === '{}') {
                <span><b>No results were found.</b></span>
              } @else {
                <span><i class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
              }
            </div>
          }
          @if (!dataLoaded) {
            <div class="col-md-12 col-sm-12 col-xs-12">
              <app-data-indicator></app-data-indicator>
            </div>
          }
          @for (listCardData of workflowStepList; track listCardData; let i = $index) {
            <div class="col-md-12">
              <p-card [styleClass]="'list-card'">
                <div class="p-grid p-fluid">
                  <div class="left-column col-md-8 col-sm-12 col-xs-12"
                       (click)="expandData(i, listCardData.WorkflowStepID)">
                    <div class="list-header">
                      {{ listCardData.StepDescription }}
                    </div>
                    @if (listCardData.Rank) {
                      <div class="list-subheader">
                        <b>Order in List:</b> {{ listCardData.Rank }}
                      </div>
                    }
                  </div>
                  <div class="right-column col-md-4 col-sm-12 col-xs-12">
                    <div class="list-action-buttons">
                      @if (canTabWrite && i !== 0) {
                        <div class="icon" pTooltip="Reorder Up" tooltipPosition="bottom"
                             (click)="reorderStep(listCardData.WorkflowStepID, workflowStepList[i-1].WorkflowStepID)"><i
                          class="fas fa-sort-up"></i></div>
                      }
                      @if (canTabWrite && i !== workflowStepList.length - 1) {
                        <div class="icon" pTooltip="Reorder Down" tooltipPosition="bottom"
                             (click)="reorderStep(listCardData.WorkflowStepID, workflowStepList[i+1].WorkflowStepID)"><i
                          class="fas fa-sort-down"></i></div>
                      }
                      <div class="icon hide-on-mobile" (click)="expandData(i, listCardData.WorkflowStepID)">
                        @if (this.selectedItem === i) {
                          <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                        } @else {
                          <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                        }
                      </div>
                      @if (canTabWrite && listCardData.CanDelete) {
                        <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                             (click)="delete(listCardData.WorkflowStepID)"><i class="fas fa-trash-alt"></i></div>
                      }
                    </div>
                  </div>
                  @if (selectedItem === i) {
                    <div class="full-width margin-top-10">
                      <app-workflow-steps-form [workflowStepData]="listCardData" [category]="'case'"
                                               [organizationId]="organizationId" [canTabWrite]="canTabWrite"
                                               (closeAddEdit)="getData()" [isEdit]="true"
                                               class="no-padding-container"></app-workflow-steps-form>
                    </div>
                  }
                </div>
              </p-card>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
