<div class="form-control relative">
  <!--  @if (inputObj) {-->
  <form [formGroup]="selectForm">
    <div>
      @if (!inputObj?.hideLabel) {
        <label [ngClass]="{'loading-total':!inputObj}">{{ inputObj?.labelText }}
          @if (inputObj?.requiredField) {
            <sup class="req">*</sup>
          }
        </label>
      }
    </div>
    <div>
      <p-dropdown (onChange)="emitDataToParent(true)" (onClear)="emitDataToParent(true)" [filterBy]="(inputObj?.filterBy) ? inputObj?.filterBy : inputObj?.optionLabel" [filter]="inputObj?.filter" [optionLabel]="inputObj?.optionLabel"
                  [optionValue]="inputObj?.optionValue" [options]="selectData" [showClear]="true" [styleClass]="'select-dropdown no-click dist-list-select'" [virtualScroll]="virtScroll" appendTo="body"
                  formControlName="fcnSelect" placeholder="--" tabindex="0" virtualScrollItemSize="30">
        <ng-template let-selectDataItem pTemplate="item">
          <div [ngClass]="{'' : selectDataItem.Active, 'inactive' : !selectDataItem.Active}" class="description-label">{{ selectDataItem.Description }}</div>
          <div [ngClass]="{'' : selectDataItem.Active, 'inactive' : !selectDataItem.Active}" class="icon-button">
            @if (selectDataItem.Description !== '--') {
              <i class="fas fa-square-info" [pTooltip]="tooltipContent" tooltipPosition="right"
                 (mouseenter)="getRosterQueryDetails(selectDataItem.ID)" tooltipStyleClass="filter-tool-tip"
                 [ngStyle]="{'color': (!selectDataItem.Active) ? '#da5e60' : ''}"></i>
            }
            <ng-template #tooltipContent>
              <div class="flex align-items-center">
                @if (!filters) {
                  <span class="fa fa-spinner"></span>
                }
                @for (filter of filters; track filter; let i = $index) {
                  <div class="table-name p-text-bold p-mb-3" [ngClass]="{'p-mt-3': i > 0}">{{ filter.Description }}:</div>
                  <div class="p-fluid p-grid">
                    @for (filterForm of filter?.FilterPrimeNG?.filters | keyvalue; track filterForm) {
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        @for (filterDetails of filterForm.value; track filterDetails) {
                          <!--                            <div class="p-fluid p-grid">-->
                            <!--                              <div class="col-md-12 col-sm-12 col-xs-12">-->
                          <div class="tool-tip-line p-mb-1">&#45;&nbsp;{{ filterDetails.HeaderName }} @if (showIs(filterDetails)) {
                            <span>is</span>
                          } {{ (filterDetails.matchMode | matchType).toLowerCase() }}
                            @if (filterDetails.ValueDescription !== 'Is Empty') {
                              <span>'{{ filterDetails.ValueDescription }}'</span>
                            }</div>
                          <!--                              </div>-->
                            <!--                            </div>-->
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            </ng-template>
          </div>
        </ng-template>
      </p-dropdown>
      @if (registerFormControl.fcnSelect.dirty && registerFormControl.fcnSelect.touched &&
      registerFormControl.fcnSelect.errors?.required) {
        <span class="p-error">'{{ inputObj?.labelText }}' must be selected</span>
      }
    </div>
  </form>
  <!--  }-->
</div>
