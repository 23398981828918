import {Component, OnInit} from '@angular/core';
import {ContextService} from '../../../services/context.service';

@Component({
  selector: 'app-data-indicator',
  templateUrl: './data-indicator.component.html',
  styleUrls: ['./data-indicator.component.scss']
})

export class DataIndicatorComponent implements OnInit {
  indicator: string;
  messageOptions: string[] = [];
  message: string;

  constructor(private contextService: ContextService) {
  }

  ngOnInit(): void {
    this.indicator = this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_LoadingIndicator')?.Value;
    if (this.indicator === undefined || this.indicator === null) {
      this.indicator = 'Spinner';
    }
    if (this.indicator === 'Progress Bar') {
      // build custom messages
      this.messageOptions.push('We know it\'s in here somewhere...');
      this.messageOptions.push('Looking... Looking... Looking...');
      this.messageOptions.push('Hey, thanks for your patience, we appreciate you!');
      this.messageOptions.push('No, we\'re not stalling...');
      this.messageOptions.push('Sheesh, this is heavy...');
      this.messageOptions.push('Well, this was unexpected. Time to create a diversion...');
      this.messageOptions.push('This could take all day... (but we hope not!)');
      this.messageOptions.push('No, no, turn the wrench to the right...');
      this.messageOptions.push('We\'ll be right back...');
      this.messageOptions.push('Run away! Run away!');
      this.messageOptions.push('Dusting off the cobwebs...');
      this.messageOptions.push('Mmm, that\'s good. Needs a little salt...');
      this.messageOptions.push('Duck, duck, duck, goose!');
      this.messageOptions.push('Tag, you\'re it!');
      this.messageOptions.push('One second, there\'s someone at the door...');
      this.messageOptions.push('Shoot, where\'d I put my flashlight?');
      this.messageOptions.push('Hey, hey, let\'s have a great day!');
      this.messageOptions.push('This thing needs new batteries...');
      this.messageOptions.push('Looking for the needle in the haystack...');
      // below will ensure there is a better chance to get the base message
      const totalSteps: number = this.messageOptions.length * 20;
      for (let step = 0; step < totalSteps; step++) {
        this.messageOptions.push('Retrieving data...');
      }
      const random = Math.floor(Math.random() * this.messageOptions.length);
      // set the message randomly
      this.message = this.messageOptions[random];
    }
  }
}
