@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form>
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      @if (emailBody) {
        <div class="col-md-12 col-sm-6 col-xs-12 input-container">
          <div [innerHTML]="emailBody"></div>
        </div>
      }
      @if (textMessage) {
        <div class="col-md-12 col-sm-6 col-xs-12 input-container">
          <div [innerHTML]="textMessage"></div>
        </div>
      }
      @if (surveyComm) {
        <div class="col-md-12 col-sm-6 col-xs-12 input-container">
          <h3>Survey</h3>
          @for (question of survey.Questions; track question) {
            <div class="col-md-12 col-sm-12 col-xs-12">
              <p-divider class="dark-divider"></p-divider>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
              <label><strong>{{question.Description}}</strong></label>
              @if (question.DataTypeDescription==='Range') {
                <span class="p-ml-2">Range from {{question.Minimum}} to {{question.Maximum}}</span>
              }
              @for (opt of question['Options']; let i = $index; track opt) {
                @if (i === 0) {
                  <span class="p-ml-2">Options: </span>
                  }{{opt.Description}}@if (i < question['Options'].length - 1) {
                  <span>, </span>
                }
              }
            </div>
          }
        </div>
      }
    </div>
  </form>
}
