<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                    [parentObjectEnum]="parentObjectEnum"></app-learn-more>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <app-select class="full-width" [inputObj]="inputObjCourse" (emitData)="getCourseData($event)"></app-select>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 or-background text-align-middle">
      <div class="or-text">OR</div>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <app-select class="full-width" [inputObj]="inputObjStudents" (emitData)="getStudentData($event)"></app-select>
    </div>
    <!--    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">-->
    <!--      <div class="list-filter-buttons text-align-right">-->
    <!--        <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"-->
    <!--                pTooltip="Clear" tooltipPosition="bottom" (click)="clearFilters()">-->
    <!--        </button>-->
    <!--        <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"-->
    <!--                pTooltip="Search" tooltipPosition="bottom" (click)="filterItems()">-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  @if (setStudentId !== 0 && setCourseId !== 0) {
    <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
      <div class="p-grid p-fluid remove-list-container-header-margin">
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 ">
          @if (selectedActiveName) {
            <h2>Grades for {{ selectedActiveName }}</h2>
          }
          @if (!selectedActiveName) {
            <h2>Please select Grade by Course or Grade by Student to begin.</h2>
          }
        </div>
        @if (selectedActiveName) {
          <h2
            class="col-md-3 col-sm-4 col-xs-12 results-total">{{ dataTotal | number:'1.0':'en-US' }} @if (dataTotal === 1) {
            Result
          } @else {
            Results
          }</h2>
        }
        @if (selectedActiveName) {
          <div class="col-md-6 col-sm-6 col-xs-12 align-center list-paginator">
            <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal"
                         (onPageChange)="paginate($event)"></p-paginator>
          </div>
        }
        @if (selectedActiveName) {
          <div class="col-md-3 col-sm-4 col-xs-6 action-buttons">
            <button pButton (click)="refresh()" icon="pi pi-refresh"
                    pTooltip="Refresh" tooltipPosition="bottom"></button>
            @if (!isExporting) {
              <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                      icon="fa fa-file-excel"
                      pTooltip="Export" tooltipPosition="bottom" (click)="export()">
              </button>
            } @else {
              <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                      icon="pi pi-spin pi-spinner save-spinner">
              </button>
            }
          </div>
        }
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      </div>
      @if (selectedActiveName) {
        <div class="col-md-12 col-sm-12 col-xs-12 text-align-right">
          <button pButton label="Set All" class="p-button-info p-mr-2" icon="pi pi-check"
                  pTooltip="Set All" tooltipPosition="bottom"
                  (click)="setAllToPass()" [className]="'inline-button'"></button>
          @if (!isSaving) {
            <button pButton label="Save All" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                    tooltipPosition="bottom"
                    (click)="saveData()" [className]="'inline-button'"></button>
          } @else {
            <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      }
      <div class="list-container p-grid p-fluid">
        @if (loading) {
          <span class="fa fa-spinner list-spinner"></span>
        }
        @for (grade of grades; track grade; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-4 col-sm-4 col-xs-8 margin-top-5">
                  <div class="list-header">
                    @if (byCourse) {
                      <span>{{ grade.FullName }}</span>
                    }
                    @if (!byCourse) {
                      <span style="font-size:14px;">{{ grade.CourseName }}</span>
                    }
                  </div>
                </div>
                <div class="right-column col-md-8 col-sm-4 col-xs-8 margin-top-5 remove-list-container-header-margin">
                  <div class="p-grid p-fluid">
                    <div class="col-md-3 col-sm-3 col-xs-6 input-container margin-top-5">
                      <label>Date</label>
                      <p-calendar dateMask dateFormat="mm/dd/yy" [(ngModel)]="grade.DateConverted" appendTo="body"
                                  placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true"
                                  yearRange="2010:2027" tabindex="0"></p-calendar>
                    </div>
                    <div class="col-md-3 col-sm-2 col-xs-6 input-container margin-top-5">
                      <label>Type</label>
                      <p-dropdown [styleClass]="'full-width'" [options]="CognitiveLevels" appendTo="body"
                                  [(ngModel)]="grade.CognitiveLevelDescription" optionLabel="Description"
                                  optionValue="Description" [disabled]="true" tabindex="0"></p-dropdown>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-6 input-container margin-top-5">
                      <label>Grade</label>
                      <p-inputNumber [(ngModel)]="grade.Grade" [showButtons]="true" suffix="%" mode="decimal" [minFractionDigits]="2"
                                     [maxFractionDigits]="2"
                                     tabindex="0"></p-inputNumber>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-6 input-container margin-top-5">
                      <label>Pass/Fail</label>
                      <p-dropdown [styleClass]="'full-width'" [options]="passFail" appendTo="body"
                                  [(ngModel)]="grade.ResultID" optionLabel="Description" optionValue="ID"
                                  tabindex="0"></p-dropdown>
                    </div>
                    @if (grade.HasCertification && grade.Passed) {
                      <div class="col-md-1 col-sm-2 col-xs-6 input-container margin-top-20 text-align-right">
                        <i class="fa fa-file-certificate cert-icon pointer" (click)="openCertificate(i)"
                           [ngClass]="{'disableIcon' : inBatchEdit}"></i>
                      </div>
                    }
                    @if (grade.CertificationSharableID ) {
                      <div class="col-md-1 col-sm-1 col-xs-12 margin-top-20">
                        <i class="pi pi-trash cert-icon pointer" pTooltip="Remove Certification" tooltipPosition="bottom"
                                  (click)="removeCert(grade.CertificationSharableID)"></i>
                      </div>
                    }
                    @if (grade.CertificationIssueDate && inBatchEdit) {
                      <div class="col-md-4 col-sm-3 col-xs-6 input-container margin-top-5">
                        <label>Cert. Issue Date</label>
                        <p-calendar dateMask dateFormat="mm/dd/yy" [(ngModel)]="grade.CertificationIssueDate"
                                    appendTo="body" placeholder="mm/dd/yyyy"
                                    [disabled]="true" [showIcon]="true" [yearNavigator]="true" yearRange="2010:2027"
                                    tabindex="0"></p-calendar>
                      </div>
                    }
                    @if (grade.CertificationExpirationDate && inBatchEdit) {
                      <div class="col-md-4 col-sm-3 col-xs-6 input-container margin-top-5">
                        <label>Cert. Expiration Date</label>
                        <p-calendar dateMask dateFormat="mm/dd/yy" [(ngModel)]="grade.CertificationExpirationDate"
                                    appendTo="body" placeholder="mm/dd/yyyy"
                                    [disabled]="true" [showIcon]="true" [yearNavigator]="true" yearRange="2010:2027"
                                    tabindex="0"></p-calendar>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        }
      </div>
    </div>
  }
</div>
