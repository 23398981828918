import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RosterService} from '../../../services/roster.service';
import {StudentProfileService} from '../../../services/student-profile.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ContextService} from '../../../services/context.service';
import {NotZeroValidation, transformDateTimeToDateOnly} from '../../../shared/utilities/form.utility';
import {RosterProfileService} from '../../../services/roster-profile.service';
import {RosterProfileModel} from '../../../models/roster-profile.model';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';

@Component({
  selector: 'app-roster-tab-membership-activities',
  templateUrl: './roster-tab-membership-activities.component.html',
  styleUrls: ['./roster-tab-membership-activities.component.scss']
})

export class RosterTabMembershipActivitiesComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  rosterProfile: RosterProfileModel;
  inputObjType: GenericSelectModel;
  inputObjStatus: GenericSelectModel;
  inputObjClass: GenericSelectModel;
  inputObjOrganization: GenericSelectModel;
  inputObjPathway: GenericSelectModel;
  inputObjBilling: GenericSelectModel;
  inputObjDues: GenericSelectModel;
  inputObjBargainingUnit: GenericSelectModel;
  inputObjDuesGroup: GenericSelectModel;
  isEnabled: boolean = false;
  isRetireDateRequired: boolean = false;
  setTypeId: number;
  setStatusId: number;
  setClassId: number;
  setOrganizationId: number;
  setPathwayId: number;
  setBillingId: number;
  setDuesPaymentId: number;
  setBargainingUnitId: number;
  setDuesGroupId: number;
  membershipType: string;
  membershipStatus: string;
  isSaving: boolean = false;
  isRequiredEffectiveDate: boolean = false;
  isInitiationDateRequired: boolean = false;
  disabled: boolean = false;
  typeLocked: boolean = false;
  statusLocked: boolean = false;
  STRINGREPLACECLASSIFICATION: string;
  STRINGREPLACELOCAL: string;
  STRINGREPLACETYPE: string;
  existingMessage: boolean = false;
  @Input() isPage: boolean;
  // updatedMemberInfo = {} as UpdateRosterProfileModel;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() PersonID: number;
  selectedBargaining;
  selectedActivity;
  selectedClassification;

  selectedDues;
  loading: boolean;
  showActivities: boolean = true;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private rosterService: RosterService,
              private studentProfileService: StudentProfileService, private ref: DynamicDialogRef,
              private config: DynamicDialogConfig, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private contextService: ContextService,
              private rosterProfileService: RosterProfileService, private tenantConfigDataService: TenantConfigDataService,
              private confirmationService: ConfirmationService) {
    this.mainForm = this.formBuilder.group({
      statusId: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      memberNumber: new FormControl({value: null, disabled: true}),
      class: new FormControl({value: null, disabled: true}),
      organization: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      pathway: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      type: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      effectiveDate: new FormControl({value: null, disabled: true}),
      applicationDate: new FormControl({value: null, disabled: true}),
      initiationDate: new FormControl({value: null, disabled: true}),
      retirementDate: new FormControl({value: null, disabled: false}),
      billingStatusID: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      duesPaymentID: new FormControl({value: null, disabled: true}),
      bargainingUnit: new FormControl({value: null, disabled: true}, Validators.maxLength(100)),
      duesGroup: new FormControl({value: null, disabled: true}),
      activityId: new FormControl(null),
      activityDate: new FormControl(null),
      activityOrg: new FormControl(null),
      activityPathway: new FormControl(null),
      activityBargainingUnit: new FormControl(null),
      activityType: new FormControl(null),
      activityClass: new FormControl(null),
      cumulativeYears: new FormControl(''),
      consecutiveYears: new FormControl(null),
      paidThroughDate: new FormControl(null),
      cardIssueDate: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    this.STRINGREPLACECLASSIFICATION = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
    this.STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');

    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.mainForm.get('cardIssueDate').disable();
    // this.mainForm.disable();

    //if (localStorage.getItem('saveReminder') !== 'No') {
    // this.mainForm.valueChanges.subscribe({next:() => {
    //   if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
    //     this.existingMessage = true;
    //     this.openDialog();
    //   }
    // }});
    // }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: `${this.rosterProfile.FirstName} ${this.rosterProfile.LastName} has been modified. Please save, or all changes will be lost.`,
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    if (this.PersonID) {
      this.getRosterProfile();
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  getRosterProfile(): void {
    this.rosterProfileService.getRosterProfile(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.isRetireDateRequired = res.RetiredDateRequired;
          this.rosterProfile = res;
          this.initForm();
        }
      });
  }

  initForm(): void {
    this.showActivities = true;
    this.mainForm.get('memberNumber').setValue(this.rosterProfile.MemberNumber);
    if (this.rosterProfile.EffectiveDate) {
      this.mainForm.get('effectiveDate').setValue(new Date(this.rosterProfile.EffectiveDate));
    } else {
      this.mainForm.get('effectiveDate').setValue(null);
    }
    if (this.rosterProfile.MemberApplicationDate) {
      this.mainForm.get('applicationDate').setValue(new Date(this.rosterProfile.MemberApplicationDate));
    } else {
      this.mainForm.get('applicationDate').setValue(null);
    }
    if (this.rosterProfile.PaidThroughDate) {
      this.mainForm.get('paidThroughDate').setValue(new Date(this.rosterProfile.PaidThroughDate));
    } else {
      this.mainForm.get('paidThroughDate').setValue(null);
    }
    this.mainForm.get('paidThroughDate').disable();
    if (this.rosterProfile.MemberStartDate) {
      this.mainForm.get('initiationDate').setValue(new Date(this.rosterProfile.MemberStartDate));
    } else {
      this.mainForm.get('initiationDate').setValue(null);
    }
    if (this.rosterProfile.RetirementDate) {
      this.mainForm.get('retirementDate').setValue(new Date(this.rosterProfile.RetirementDate));
    } else {
      this.mainForm.get('retirementDate').setValue(null);
    }
    if (this.rosterProfile.CardIssueDate) {
      this.mainForm.get('cardIssueDate').setValue(new Date(this.rosterProfile.CardIssueDate));
    } else {
      this.mainForm.get('cardIssueDate').setValue(null);
    }
    this.setTypeId = this.rosterProfile.TypeID;
    this.mainForm.get('type').setValue(this.rosterProfile.TypeAlias);
    this.setStatusId = this.rosterProfile.StatusID;
    this.mainForm.get('statusId').setValue(this.rosterProfile.StatusID);
    this.setClassId = this.rosterProfile.ClassificationID;
    this.mainForm.get('class').setValue(this.rosterProfile.ClassificationDescription);
    this.setOrganizationId = this.rosterProfile.OrganizationID;
    this.mainForm.get('organization').setValue(this.rosterProfile.OrganizationID);
    this.setBillingId = this.rosterProfile.BillingStatusID;
    this.mainForm.get('billingStatusID').setValue(this.rosterProfile.BillingStatusID);
    this.setDuesPaymentId = this.rosterProfile.PaymentTypeID;
    this.mainForm.get('duesPaymentID').setValue(this.rosterProfile.PaymentTypeID);
    this.setPathwayId = this.rosterProfile.PathwayID;
    this.mainForm.get('pathway').setValue(this.rosterProfile.PathwayID);
    this.setBargainingUnitId = this.rosterProfile.BargainingUnitID;
    this.mainForm.get('bargainingUnit').setValue(this.rosterProfile.BargainingUnitDescription);
    //this.setDuesGroupId = this.rosterProfile.DuesGroupDescription;
    this.mainForm.get('duesGroup').setValue(this.rosterProfile.DuesGroupDescription);
    this.mainForm.get('cumulativeYears').setValue(this.rosterProfile.YearsOfServiceCumulative);
    this.mainForm.get('consecutiveYears').setValue(this.rosterProfile.YearsOfServiceConsecutive);
    this.mainForm.get('cumulativeYears').disable();
    this.mainForm.get('consecutiveYears').disable();
    // this.initType(true);
    this.initStatus(true);
    // this.initClass(true);
    this.initOrganization(true);
    this.initPathway(true);
    this.initBillingProcess(true);
    this.initDuesPayment(true);
    this.initBargainingUnit(false);
    // this.initDuesGroup(true);
    // this.mainForm.get('bargainingUnit').setValue(this.rosterProfile.BargainingUnit);
    if (this.isRetireDateRequired) {
      this.mainForm.get('retirementDate').setValue(new Date());
      this.mainForm.get('retirementDate').setValidators([Validators.required]);
    }
  }

  // initType(disable: boolean): void {
  //   if (this.typeLocked && this.statusLocked) {
  //     disable = true;
  //   }
  //   this.inputObjType = {
  //     labelText: this.STRINGREPLACETYPE,
  //     optionValue: 'ID',
  //     optionLabel: 'Description',
  //     filter: true,
  //     requiredField: true,
  //     selectFirstValue: false,
  //     initSelected: this.setTypeId,
  //     disabled: true,
  //     canWrite: this.canTabWrite
  //   };
  //   this.lookupsDataService.getPersonProfileTypesUpdateLookupData(this.setOrganizationId, 'membership', this.setTypeId).then((lookupData) => {
  //     this.inputObjType.data = lookupData;
  //     this.inputObjType = Object.assign({}, this.inputObjType);
  //   });
  // }

  initStatus(disable: boolean): void {
    if (this.typeLocked && this.statusLocked) {
      disable = true;
    }
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStatusId,
      disabled: true,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getPersonProfileStatusesUpdateLookupData(this.setTypeId, this.setStatusId).then((lookupData) => {
      this.inputObjStatus.data = lookupData;
      this.inputObjStatus = Object.assign({}, this.inputObjStatus);
    });
  }

  // initClass(disable: boolean): void {
  //   this.inputObjClass = {
  //     labelText: this.STRINGREPLACECLASSIFICATION,
  //     optionValue: 'ID',
  //     optionLabel: 'Description',
  //     filter: true,
  //     requiredField: false,
  //     selectFirstValue: false,
  //     initSelected: this.setClassId,
  //     disabled: true,
  //     canWrite: this.canTabWrite
  //   };
  //   this.lookupsDataService.getPersonProfileClassificationsLookupData().then((lookupData) => {
  //     this.inputObjClass.data = lookupData;
  //     this.inputObjClass = Object.assign({}, this.inputObjClass);
  //   });
  // }

  initOrganization(disable: boolean): void {
    this.inputObjOrganization = {
      labelText: this.STRINGREPLACELOCAL,
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setOrganizationId,
      disabled: true,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByOrganizationGroupLookupData('ROSTER', this.setOrganizationId, true).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  initPathway(disable: boolean): void {
    this.inputObjPathway = {
      labelText: 'Pathway',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setPathwayId,
      disabled: true,
      canWrite: this.canTabWrite
    };
    if (this.setOrganizationId) {
      this.lookupsDataService.getPathwaysLookupData(this.setOrganizationId).then((lookupData) => {
        this.inputObjPathway.data = lookupData;
        this.inputObjPathway = Object.assign({}, this.inputObjPathway);
      });
    }

  }

  initBillingProcess(disable: boolean): void {
    this.inputObjBilling = {
      labelText: 'Billing Process',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setBillingId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getMoneyManagerStatusesByOrganizationIdLookupData(this.setOrganizationId).then((lookupData) => {
      this.inputObjBilling.data = lookupData;
      this.inputObjBilling = Object.assign({}, this.inputObjBilling);
    });
  }

  initDuesPayment(disable: boolean): void {
    this.inputObjDues = {
      labelText: 'Dues Payment Election',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setDuesPaymentId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getMoneyManagerPaymentTypesLookupData().then((lookupData) => {
      this.inputObjDues.data = lookupData;
      this.inputObjDues = Object.assign({}, this.inputObjDues);
    });
  }

  initBargainingUnit(disable: boolean): void {
    this.inputObjBargainingUnit = {
      labelText: 'Bargaining Unit',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setBargainingUnitId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    console.log(this.setBargainingUnitId);
    this.lookupsDataService.getBargainingUnitByOrgLookupData(this.setOrganizationId, this.setBargainingUnitId).then((lookupData) => {
      this.inputObjBargainingUnit.data = lookupData;
      this.inputObjBargainingUnit = Object.assign({}, this.inputObjBargainingUnit);
    });
  }

  initDuesGroup(disable: boolean): void {
    this.inputObjDuesGroup = {
      labelText: 'Dues Group',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setDuesPaymentId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getDuesGroupsLookupData().then((lookupData) => {
      this.inputObjDuesGroup.data = lookupData;
      this.inputObjDuesGroup = Object.assign({}, this.inputObjDuesGroup);
    });
  }

  getBargainingUnitData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setBargainingUnitId = event[0].ID;
    } else {
      this.setBargainingUnitId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('bargainingUnit').setValue(this.setBargainingUnitId);
  }

  // getTypeData(event:any) {
  //   if (event && event[0] && event[0].ID) {
  //     this.setTypeId = event[0].ID;
  //     this.membershipType = event[0].Description;
  //     if (this.setTypeId !== this.rosterProfile.TypeID) {
  //       this.mainForm.get('effectiveDate').setValue(new Date());
  //       this.mainForm.get('effectiveDate').setValidators([Validators.required]);
  //       this.isRequiredEffectiveDate = true;
  //     } else {
  //       this.mainForm.get('effectiveDate').setValue(new Date(this.rosterProfile.EffectiveDate));
  //       this.mainForm.get('effectiveDate').clearValidators();
  //       this.isRequiredEffectiveDate = false;
  //     }
  //     this.disabled = false;
  //   } else {
  //     this.setTypeId = null;
  //     this.membershipType = '';
  //     this.disabled = true;
  //   }
  //   this.setStatusId = null;
  //   this.initStatus(this.disabled);
  //   this.getStatusData(null);
  //   this.mainForm.markAsDirty();
  //   this.mainForm.get('type').setValue(this.setTypeId);
  // }
  //
  getStatusData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setStatusId = event[0].ID;
      this.membershipStatus = event[0].Description;
      if (this.setStatusId !== this.rosterProfile.StatusID) {
        this.mainForm.get('effectiveDate').setValue(new Date());
        this.mainForm.get('effectiveDate').setValidators([Validators.required]);
        this.isRequiredEffectiveDate = true;
      } else {
        this.mainForm.get('effectiveDate').setValue(new Date(this.rosterProfile.EffectiveDate));
        this.mainForm.get('effectiveDate').clearValidators();
        this.isRequiredEffectiveDate = false;
      }
      if (this.membershipStatus === 'Active' && this.membershipType === 'Member' || this.membershipStatus === 'Active' && this.membershipType === 'Retired') {
        this.mainForm.get('initiationDate').setValidators([Validators.required]);
        this.isInitiationDateRequired = true;
      } else {
        this.mainForm.get('initiationDate').clearValidators();
        this.isInitiationDateRequired = false;
      }
    } else {
      this.setStatusId = null;
      this.membershipStatus = '';
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('statusId').setValue(this.setStatusId);
  }

  //
  // getClassData(event:any) {
  //   if (event && event[0] && event[0].ID) {
  //     this.setClassId = event[0].ID;
  //   } else {
  //     this.setClassId = null;
  //   }
  //   this.mainForm.markAsDirty();
  //   this.mainForm.get('class').setValue(this.setClassId);
  // }

  getOrganizationData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setOrganizationId = event[0].ID;
      this.disabled = false;
    } else {
      this.setOrganizationId = null;
      this.disabled = true;
    }
    this.setPathwayId = null;
    this.initPathway(this.disabled);
    this.getPathwayData(null);
    this.setTypeId = null;
    // this.initType(this.disabled);
    // this.getTypeData(null);
    this.mainForm.markAsDirty();
    this.mainForm.get('organization').setValue(this.setOrganizationId);
  }

  getPathwayData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setPathwayId = event[0].ID;
    } else {
      this.setPathwayId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('pathway').setValue(this.setPathwayId);
  }

  getBillingProcessData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setBillingId = event[0].ID;
    } else {
      this.setBillingId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('billingStatusID').setValue(this.setBillingId);
  }

  getDuesPaymentData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setDuesPaymentId = event[0].ID;
    } else {
      this.setDuesPaymentId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('duesPaymentID').setValue(this.setDuesPaymentId);
  }

  getDuesGroupData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setDuesGroupId = event[0].ID;
    } else {
      this.setDuesGroupId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('duesGroupID').setValue(this.setDuesGroupId);
  }

  processData() {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      //this.updatedMemberInfo.MemberNumber = this.mainForm.get('memberNumber').value;
      this.rosterProfile.TypeID = this.setTypeId;
      this.rosterProfile.StatusID = this.setStatusId;
      this.rosterProfile.ClassificationID = this.setClassId;
      this.rosterProfile.OrganizationID = this.setOrganizationId;
      this.rosterProfile.PathwayID = this.setPathwayId;
      this.rosterProfile.EffectiveDate = transformDateTimeToDateOnly(this.mainForm.get('effectiveDate').value);
      this.rosterProfile.MemberApplicationDate = transformDateTimeToDateOnly(this.mainForm.get('applicationDate').value);
      this.rosterProfile.MemberStartDate = transformDateTimeToDateOnly(this.mainForm.get('initiationDate').value);
      this.rosterProfile.BillingStatusID = this.mainForm.get('billingStatusID').value;
      this.rosterProfile.RetirementDate = transformDateTimeToDateOnly(this.mainForm.get('retirementDate').value);
      this.rosterProfile.PaymentTypeID = this.mainForm.get('duesPaymentID').value;
      this.rosterProfile.BargainingUnitID = this.setBargainingUnitId;
      // this.rosterProfile.DuesGroupID = this.setDuesGroupId;
      this.saveForm();
    }
  }

  saveForm() {
    this.rosterProfileService.updateRosterProfile(this.PersonID, this.rosterProfile)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.ref.destroy();
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Your membership has been successfully updated'
          });
          this.confirmationService.close();
          this.mainForm.markAsPristine();
          this.mainForm.markAsUntouched();
          this.isSaving = false;
          this.existingMessage = false;
        }, error: () => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          this.isSaving = false;
        }
      });
  }

  refresh() {
    this.getRosterProfile();
    this.cancel();
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }
}
