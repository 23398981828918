import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ClassesModulesService} from '../../../services/classes.service';
import {ClassModel} from '../../../models/classes.model';
import {EventOnlineMeetingsService} from '../../../services/event-online-meetings.service';
import {CreateEventOnlineMeetingModel, EventOnlineMeetingModel} from '../../../models/event-online-meetings.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ContextService} from '../../../services/context.service';

@Component({
  selector: 'app-class-tab-virtual',
  templateUrl: './class-tab-virtual.component.html',
  styleUrls: ['./class-tab-virtual.component.scss']
})
export class ClassTabVirtualComponent implements OnInit, OnChanges {
  mainForm: FormGroup;
  loading: boolean = false;
  class = {} as ClassModel;
  online = {} as EventOnlineMeetingModel;
  createOnline = {} as CreateEventOnlineMeetingModel;
  inputObjOnlineMeetingType: GenericSelectModel;
  setOnlineMeetingTypeId: number;
  isDirty: boolean;
  isSaving: boolean = false;
  currentClass: ClassModel;
  showDetails: boolean = false;
  start_time: string;
  topic: string;
  audio: string;
  start_url: string;
  join_url: string;
  existingMessage: boolean = false;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() ClassID: number;
  @Output() closeAddEdit = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private classesService: ClassesModulesService, private messageService: MessageService,
              private eventOnlineMeetingsService: EventOnlineMeetingsService,
              private confirmationService: ConfirmationService, private contextService: ContextService) {
    this.mainForm = this.formBuilder.group({
      OnlineMeetingType: new FormControl(null),
      agenda: new FormControl(null),
      audio: new FormControl('voip')
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges() {
    if (this.isEdit) {
      if (this.ClassID) {
        this.getClassDetails(this.ClassID);
      }
    } else {
      this.initSelects();
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Virtual data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  getClassDetails(classId: number): any {
    this.loading = true;
    this.classesService.getClass(classId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: res => {
          this.currentClass = res;
          this.class = res;
          this.showDetails = false;
          this.getOnlineMeetingDetails(res.OnlineMeetingTypeID, res.OnlineMeetingIdentifier).then(() => {
            this.initForm();
            this.loading = false;
          });
        }, error: (e) => {
          this.loading = false;
          console.debug(e);
        }
      });
  }

  getOnlineMeetingDetails(typeId: number, onlineMeetingId: string): any {
    return new Promise((resolve, reject) => {
      if (typeId && onlineMeetingId) {
        this.eventOnlineMeetingsService.getEventOnlineMeeting(typeId, onlineMeetingId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: res => {
              this.showDetails = true;
              this.online = res;
              resolve(null);
            }, error: (e) => {
              console.debug(e);
              resolve(null);
            }
          });
      } else {
        resolve(null);
      }
    });
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.getClassDetails(this.ClassID);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  initForm() {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.setOnlineMeetingTypeId = this.currentClass.OnlineMeetingTypeID;
    this.mainForm.get('OnlineMeetingType').setValue(this.setOnlineMeetingTypeId);
    if (this.online) {
      this.start_time = this.online.start_time;
      this.topic = this.online.topic;
      if (this.online.settings) {
        this.audio = this.online.settings.audio;
      }
      this.start_url = this.online.start_url;
      this.join_url = this.online.join_url;
    }
    this.initSelects();
  }

  initSelects() {
    this.initOnlineMeetingType(!this.canTabWrite);
  }

  initOnlineMeetingType(disable: boolean) {
    this.inputObjOnlineMeetingType = {
      labelText: 'Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setOnlineMeetingTypeId,
      data: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getEventOnlineMeetingTypesLookupData().then((lookupData) => {
      this.inputObjOnlineMeetingType.data = lookupData;
      this.inputObjOnlineMeetingType = Object.assign({}, this.inputObjOnlineMeetingType);
    });
  }

  getOnlineMeetingTypeData(event: any) {
    this.showDetails = false;
    this.mainForm.get('agenda').setValue(null);
    if (event && event[0] && event[0].ID) {
      this.setOnlineMeetingTypeId = event[0].ID;
    } else {
      this.setOnlineMeetingTypeId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('OnlineMeetingType').setValue(this.setOnlineMeetingTypeId);
  }

  processData() {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.class.OnlineMeetingTypeID = this.mainForm.get('OnlineMeetingType').value;
      if (!this.setOnlineMeetingTypeId) {
        this.class.OnlineMeetingIdentifier = null;
      }
      this.saveData();
    }
  }

  createOnlineClass() {
    if (this.isEdit) {
      const sDate = new Date(this.class.StartDate_D);
      const sTime = new Date(this.class.StartTime_T);
      const s = new Date(sDate + ' ' + sTime);
      const eDate = new Date(this.class.EndDate_D);
      const eTime = new Date(this.class.EndTime_T);
      const e = new Date(eDate + ' ' + eTime);
      const diff = Math.abs(new Date(e).getTime() - new Date(s).getTime());
      const duration = Math.floor((diff / 1000) / 60);
      this.createOnline.topic = this.class.CourseDescription;
      this.createOnline.type = this.setOnlineMeetingTypeId;
      this.createOnline.start_time = s.toString();
      this.createOnline.duration = duration;
      this.createOnline.agenda = this.mainForm.get('agenda').value;
      this.createOnline.settings = {
        audio: this.mainForm.get('audio').value
      };
      this.eventOnlineMeetingsService.createEventOnlineMeeting(this.setOnlineMeetingTypeId, this.createOnline)
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (res) => {
          this.online = res;
          this.class.OnlineMeetingIdentifier = this.online.id.toString();
          this.start_time = this.online.start_time;
          this.topic = this.online.topic;
          this.audio = this.online.settings.audio;
          this.start_url = this.online.start_url;
          this.join_url = this.online.join_url;
          this.processData();
          this.showDetails = true;
        }, error: (er) => {
          this.showDetails = false;
          console.debug(er);
        }
      });
    }
  }

  saveData() {
    if (this.isEdit) {
      this.classesService.updateClass(this.ClassID, this.class).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.confirmationService.close();
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
          this.mainForm.markAsPristine();
          this.mainForm.markAsUntouched();
          this.isSaving = false;
          this.existingMessage = false;
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
          this.isSaving = false;
          console.debug(e);
        }
      });
    } else {
      this.classesService.createClass(this.class).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (res) => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your class saved successfully'});
          this.mainForm.markAsPristine();
          this.closeAddEdit.emit(res);
          this.isSaving = false;
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
          this.isSaving = false;
          console.debug(e);
        }
      });
    }
  }
}
