<div class="form-control">
  @if (inputObj) {
    <form [formGroup]="selectForm">
      <div>
        <label>{{ inputObj.labelText }} @if (inputObj?.requiredField) {
          <sup class="req">*</sup>
        }
        </label>
      </div>
      <div>
        @if (inputObj?.customSelect) {
          <p-multiSelect [options]="selectData" [optionLabel]="inputObj?.optionLabel" appendTo="body"
                         [filter]="inputObj?.filter" [filterBy]="inputObj?.filterBy" placeholder="--"
                         [virtualScroll]="virtScroll" virtualScrollItemSize="30"
                         formControlName="fcnSelect" [required]="inputObj?.requiredField"
                         class="select-dropdown no-click"
                         (onChange)="emitDataToParent (true, $event)">
            @switch (inputObj.customSelect) {
              @case ('Period') {
                <ng-template let-label pTemplate="item">
                  <div class="description-label" [title]="label.Description">
                    <div [ngClass]="{'highlight-option' : label.StatusDescription === 'Draft'}">{{ label.Description }}
                    </div>
                  </div>
                </ng-template>
              }
              @case ('ShowInactiveIcon') {
                <ng-template let-label pTemplate="item">
                  <div class="description-label" [ngClass]="{'' : label.Active, 'inactive' : !label.Active}">
                    @switch (inputObj?.optionLabel) {
                      @case ('Description') {
                        {{ label.Description }}
                      }
                      @case ('Abbreviation') {
                        {{ label.Abbreviation }}
                      }
                    }
                  </div>
                  @if (!label?.Active) {
                    <div class="icon-button" [ngClass]="{'' : label.Status, 'inactive' : !label.Active}">
                      @if (label?.Description !== '--') {
                        @if (label?.StatusDescription) {
                          <i class="fas fa-square-info" [pTooltip]="label?.StatusDescription" tooltipPosition="right"
                             tooltipStyleClass="filter-tool-tip" style="color: #da5e60"></i>
                        } @else {
                          <i class="fas fa-square-info" style="color: #da5e60"></i>
                        }
                      }
                    </div>
                  }
                </ng-template>
              }
            }
          </p-multiSelect>
        } @else {
          <p-multiSelect [options]="selectData" [optionLabel]="inputObj?.optionLabel" appendTo="body"
                         [filter]="inputObj?.filter" [filterBy]="inputObj?.filterBy" placeholder="--"
                         [virtualScroll]="virtScroll" virtualScrollItemSize="30"
                         formControlName="fcnSelect" [required]="inputObj?.requiredField"
                         class="select-dropdown no-click"
                         (onChange)="emitDataToParent (true, $event)">
          </p-multiSelect>
        }
        @if (registerFormControl.fcnSelect.dirty &&
        registerFormControl.fcnSelect.errors?.required) {
          <span class="p-error">'{{ inputObj.labelText }}' must be selected</span>
        }
      </div>
    </form>
  }
</div>
