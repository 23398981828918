import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-global-roster-student-programs-tab',
  templateUrl: './global-roster-student-programs.component.html'
})

export class GlobalRosterStudentProgramsComponent implements OnInit {

  @Input() DBEntity;
  @Input() PersonID;
  @Input() canTabWrite;
  @Input() isEdit: boolean;
  activeSubTab: string = 'programs';

  constructor() {
  }

  ngOnInit(): void {
  }
}
