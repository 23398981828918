// https://developers.dymo.com/2010/06/02/dymo-label-framework-javascript-library-samples-print-a-label/
// http://labelwriter.com/software/dls/sdk/docs/DYMOLabelFrameworkJavaScriptHelp/symbols/dymo.label.framework.html
// https://www.npmjs.com/package/dymojs
import {Injectable} from '@angular/core';
import * as Dymo from 'dymojs';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {RosterDymoLabelModel} from '../../models/roster-profile.model';
import {RosterProfileService} from '../../services/roster-profile.service';
import {TenantConfigDataService} from '../../services/tenant-config-data.service';
import {ContextService} from '../../services/context.service';

@Injectable()
export class DymoLabelUtility {
  private ngUnsubscribe = new Subject();

  constructor(private messageService: MessageService, private tenantConfigDataService: TenantConfigDataService,
              private rosterProfileService: RosterProfileService, private contextService: ContextService) {
  }

  public isDymoInstalled(): any {
    return new Promise((resolve, reject) => {
      const dymo = new Dymo();
      if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value !== null && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value === 'Yes') {
        dymo.getStatus().then(() => {
          resolve(null);
        }, () => {
          reject(null);
        });
      } else {
        reject(null);
      }
    });
  }

  public isDymoPrinterInstalled(): any {
    return new Promise((resolve, reject) => {
      const dymo = new Dymo();
      if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value !== null && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value === 'Yes') {
        dymo.getPrinters().then(() => {
          sessionStorage.setItem('isDymoInstalled', 'Yes');
          resolve(true);
        }, () => {
          sessionStorage.setItem('isDymoInstalled', 'No');
          reject(false);
        });
      } else {
        reject(false);
      }
    });
  }

  public printDymoLabel(labelText: string): any {
    return new Promise((resolve, reject) => {
      const dymo = new Dymo();
      this.isDymoInstalled().then(() => {
        if (labelText && labelText !== '') {
          const labelXml: string = `<?xml version="1.0" encoding="utf-8"?>\
            <DieCutLabel Version="8.0" Units="twips">\
                <PaperOrientation>Landscape</PaperOrientation>\
                <Id>Address</Id>\
                <PaperName>30252 Address</PaperName>\
                <DrawCommands/>\
                <ObjectInfo>\
                    <TextObject>\
                        <Name>Text</Name>\
                        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
                        <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
                        <LinkedObjectName></LinkedObjectName>\
                        <Rotation>Rotation0</Rotation>\
                        <IsMirrored>False</IsMirrored>\
                        <IsVariable>True</IsVariable>\
                        <HorizontalAlignment>Left</HorizontalAlignment>\
                        <VerticalAlignment>Middle</VerticalAlignment>\
                        <TextFitMode>ShrinkToFit</TextFitMode>\
                        <UseFullFontHeight>True</UseFullFontHeight>\
                        <Verticalized>False</Verticalized>\
                        <StyledText>\
                            <Element>\
                                <String>${labelText}</String>\
                                <Attributes>\
                                  <Font Family="Helvetica" Size="13"\
                                  Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
                                  <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
                                </Attributes>\
                            </Element>\
                        </StyledText>\
                    </TextObject>\
                    <Bounds X="332" Y="150" Width="4455" Height="1260" />\
                </ObjectInfo>\
            </DieCutLabel>`;
          dymo.getPrinters().then((printers) => {
            const start: number = printers.indexOf('<Name>');
            const end: number = printers.indexOf('<\\/Name>');
            if (start > 0 && end > 0) {
              const printer: string = printers.substring(start + 6, end);
              if (printer && printer !== '') {
                dymo.print(printer, labelXml);
                this.messageService.add({severity: 'success', summary: 'DYMO Success', detail: 'Your label is printing...'});
                resolve(null);
              } else {
                this.messageService.add({severity: 'error', summary: 'DYMO Printer Not Found', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
                resolve(null);
              }
            } else {
              this.messageService.add({severity: 'error', summary: 'DYMO Printer Not Found', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
              resolve(null);
            }
          }, () => {
            reject(null);
          });
        } else {
          this.messageService.add({severity: 'error', summary: 'Missing Label Data', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          resolve(null);
        }
      }).catch(() => {
        this.messageService.add({severity: 'error', summary: 'DYMO Not Installed or Found', detail: 'Our apologies... Something went sideways. Please start your DYMO webservice and try again, and let us know if it continues. Thank you!'});
      });
    });
  }

  public rosterDymoLabel(personID: number): any {
    return new Promise((resolve, reject) => {
      const MAILINGLABELSHOWMEMNUM = this.tenantConfigDataService.getBooleanValue('MAILINGLABELSHOWMEMNUM');
      const MAILINGLABELSHOWORG = this.tenantConfigDataService.getBooleanValue('MAILINGLABELSHOWORG');
      let labelText: string = '';
      this.rosterProfileService
        .getDymoLabelDetails(personID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: RosterDymoLabelModel) => {
            if (MAILINGLABELSHOWMEMNUM === true && res.MemberNumber) {
              labelText += res.MemberNumber;
            }
            if (MAILINGLABELSHOWORG === true && res.OrganizationAbbreviation) {
              if (labelText !== '') {
                labelText += '     ' + res.OrganizationAbbreviation;
              } else {
                labelText += res.OrganizationAbbreviation;
              }
            }
            if (MAILINGLABELSHOWMEMNUM === true || MAILINGLABELSHOWORG === true) {
              labelText += '\n';
            }
            if (res.FirstName) {
              labelText += res.FirstName;
            }
            if (res.LastName) {
              labelText += ' ' + res.LastName;
            }
            if (res.PrimaryAddress1) {
              labelText += '\n' + res.PrimaryAddress1;
            }
            if (res.PrimaryAddress2) {
              labelText += '\n' + res.PrimaryAddress2;
            }
            if (res.PrimaryCity) {
              labelText += '\n' + res.PrimaryCity;
            }
            if (res.PrimaryStateProvince) {
              labelText += ', ' + res.PrimaryStateProvince;
            }
            if (res.PrimaryZip) {
              labelText += ' ' + res.PrimaryZip;
            }
            if (labelText && labelText !== '') {
              this.printDymoLabel(labelText);
              resolve(null);
            } else {
              resolve(null);
            }
          }
        });
    });
  }
}
