import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {ContextService} from '../../../../services/context.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Accordion} from 'primeng/accordion';
import {Router} from '@angular/router';
import {PrimeRosterQueryTableFilterModel, RosterQueryExistingModel} from '../../../../models/roster-query-primeng.model';
import {RosterQueryService} from '../../../../services/roster-queries.service';
import {DistributionListDefinitions} from '../../../definitions/distribution-list.definitions';
import {RosterService} from '../../../../services/roster.service';
import {RouteContextUtility} from '../../../utilities/route-context.utility';
import {StudentsService} from '../../../../services/students.service';
import {ProspectsService} from '../../../../services/prospects.service';
import moment from 'moment';
import {AuxTablePreviewDialogComponent} from '../../auxiliary-table-filters/auxiliary-filter-existing-form/aux-table-preview/aux-table-preview-dialog.component';
import {RosterTabContainerComponent} from '../../../../roster/roster-tab-container/roster-tab-container.component';
import {RosterStudentTabContainerComponent} from '../../../../roster-student/roster-student-tab-container/roster-student-tab-container.component';
import {ProspectsTabContainerComponent} from '../../../../prospects/prospects-tab-container/prospects-tab-container.component';
import {TenantConfigDataService} from '../../../../services/tenant-config-data.service';
import {InactiveStatusUtility} from '../../../utilities/inactive-status.utility';
import {InterfaceObjectAppsModel} from '../../../../models/interface-objects.model';
import {tabsPermissionsUtility} from '../../../utilities/tabs-permissions.utility';
import {PageService} from '../../../../services/page.services';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-distribution-list',
  templateUrl: './distribution-list.component.html',
  styleUrls: ['./distribution-list.component.scss']
})

export class DistributionListComponent implements OnInit, OnDestroy {
  InterfaceObjectEnum = 'DISTRIBUTIONLIST';
  ContextEnumerator: string;
  context = this.contextService.contextObject;
  permissions: InterfaceObjectAppsModel[] = [];
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  mainForm: FormGroup;
  formReady: boolean;
  @Input() personId: number;
  canEdit: boolean = true;
  @ViewChild('accordion') accordion: Accordion;
  @ViewChild('fileUploader') fileUpload: any;
  existingName: string;
  existingOperator: string;
  accordionIndex: any[] = [0];
  isAdmin: boolean;
  blobToken: string;
  inactiveStatuses: string[] = [];
  inputValueChanged: boolean = false;
  isSaving: boolean = false;
  inputObjRosterQuery: GenericSelectModel;
  currentFilter = new PrimeRosterQueryTableFilterModel();
  setRosterQueryId: number;
  setOrganizationId: number;
  inputObjOrganization: GenericSelectModel;
  isNewDist: boolean = false;
  selectedRosterQuery = {} as RosterQueryExistingModel;
  importFromCommsRosterQuery = {} as RosterQueryExistingModel;
  dataTotal: number = 0;
  cols = [];
  tableData: any[];
  formattedTableName: string;
  loadingTable: boolean = false;
  viewTable: boolean = false;
  loading: boolean = false;
  routeContext: string;
  isDialog: boolean = false;
  setTableFromDialog: string;
  setColumnFromDialog: string;
  setValueFromDialog: string;
  setColumnIDFromDialog: number;
  selectedPerson;
  hasStaticList: boolean;
  isStatic: boolean = false;
  showProgress: boolean;
  fd: FormData = new FormData();
  uploadedFiles: any[] = [];
  inputObjFileFormat: GenericSelectModel;
  setFileFormat: string;
  getRosterListRequest;
  getRosterTotalRequest;
  getProspectListRequest;
  getProspectTotalRequest;
  getListStudentRequest;
  getTotalStudentRequest;


  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService, private messageService: MessageService,
              private contextService: ContextService, private dialogService: DialogService, private router: Router,
              private rosterQueryService: RosterQueryService, private distributionListDefinitions: DistributionListDefinitions,
              private confirmationService: ConfirmationService, private changeDetector: ChangeDetectorRef,
              private rosterService: RosterService, private routeContextUtility: RouteContextUtility,
              private studentsService: StudentsService, private prospectsService: ProspectsService,
              public config: DynamicDialogConfig, public ref: DynamicDialogRef,
              private tenantConfigDataService: TenantConfigDataService, private inactiveStatusUtility: InactiveStatusUtility,
              private pageService: PageService, public pageRowCountUtility: PageRowCountUtility) {
    this.mainForm = this.formBuilder.group({
      organization: new FormControl(false, Validators.required),
      distributionList: new FormControl(null, Validators.required),
      name: new FormControl(null),
      fileFormat: new FormControl(null),
      files: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.isAdmin = !this.router.url.includes('my-time');
    //isDialog used for saving from an existing communication or survey
    if (this.config.data && this.config.data.isDialog) {
      this.isNewDist = true;
      this.isDialog = true;
      const date = new Date();
      this.mainForm.get('name').setValue(this.config.data.communication.Title + ' ' + moment(date).format('MM-DD-yyyy'));
      this.setOrganizationId = this.config.data.communication.OrganizationID;
      this.loadingTable = false;
      this.setTableFromDialog = 'COMMUNICATIONSTATUS';
      this.setColumnFromDialog = 'CommunicationID';
      this.setValueFromDialog = this.config.data.communication.ID;
      // this.importFromCommsRosterQuery.Filters = [];
      // this.importFromCommsRosterQuery.Filters.push({Enumerator : 'COMMUNICATIONSTATUS', FilterJson : null, FilterPrimeNG : {} as RosterQueryFilterGroup, FilterQueryString : null});
      // this.importFromCommsRosterQuery.Filters[0].FilterPrimeNG.filters = {} as Record<string, RosterQueryFilterModel[]>;
      // this.importFromCommsRosterQuery.Filters[0].FilterPrimeNG.filters.Title = this.config.data.communication.Title;
      // this.importFromCommsRosterQuery.Filters[0].FilterPrimeNG.filters.Title = [{FilterDataTypeEnumerator: 'STRING', HeaderName : 'Title',
      //   value : this.config.data.communication.Title, operator : 'or', matchMode : 'equals'}];
    }
    this.blobToken = this.contextService.contextObject.blobToken;
    this.routeContext = this.routeContextUtility.getRouteContext();
    this.ContextEnumerator = this.routeContext.toUpperCase();
    this.inactiveStatusUtility.getPersonProfileInactiveStatuses(this.routeContext).then(x => {
      this.inactiveStatuses = x;
    }).catch(y => {
      this.inactiveStatuses = y;
    });
    this.pageService.getTabPermissions(this.InterfaceObjectEnum).then((tabPermissions: InterfaceObjectAppsModel[]) => {
      this.permissions = tabPermissions;
    });
    switch (this.routeContext) {
      case 'membership':
        this.cols = this.distributionListDefinitions.rosterCols.filter(x => x.visible === true);
        break;
      case 'training':
        this.cols = this.distributionListDefinitions.rosterStudentCols.filter(x => x.visible === true);
        break;
      case 'organize':
        this.cols = this.distributionListDefinitions.prospectCols.filter(x => x.visible === true);
        break;
    }
    this.initOrganization(false, this.InterfaceObjectEnum, false);
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    const optionLabelToUse: string = (this.routeContext === 'membership' || this.routeContext === 'organize') ? this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY') : 'Description';
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: optionLabelToUse,
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: this.setOrganizationId,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly, this.ContextEnumerator).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  setOrganizationData(event: any): void {
    if (event && event[0]) {
      this.setOrganizationId = event[0].ID;
    } else {
      this.setOrganizationId = 0;
    }
    this.initRosterQuery(false);
    this.initFileFormat(false);
  }

  initRosterQuery(disable: boolean): void {
    this.inputObjRosterQuery = {
      labelText: 'Distribution List',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setRosterQueryId,
      disabled: disable,
      canWrite: true,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getRosterQueriesLookupData(this.setOrganizationId, this.routeContext).then((lookupData) => {
      this.inputObjRosterQuery.data = lookupData;
      this.inputObjRosterQuery = Object.assign({}, this.inputObjRosterQuery);
    });
  }

  setRosterQueryData(event: any): void {
    if (event && event[0]) {
      this.setRosterQueryId = event[0].ID;
    } else {
      this.setRosterQueryId = 0;
    }
    this.mainForm.get('distributionList').setValue(this.setRosterQueryId);
    if (this.setRosterQueryId && this.setRosterQueryId !== 0) {
      this.getExistingRosterQuery(this.setRosterQueryId);
    }
  }

  getExistingRosterQuery(setRosterQueryId: number): void {
    this.loadingTable = false;
    this.rosterQueryService.getRosterQueryPrimeNg(setRosterQueryId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.hasStaticList = res.HasStaticList;
          this.mainForm.get('name').setValue(res.Description);
          this.currentFilter.AuxFilters = res.Filters;
          this.selectedRosterQuery = res;
          this.changeDetector.detectChanges();
          setTimeout(() => {
            //turn off for now to speed up development time
            this.loadingTable = true;
            this.changeDetector.detectChanges();
          }, 1000);
        }
      });

  }

  findTableName(table): string {
    let rtn: string;
    switch (table) {
      case 'UNIONPOSITION':
        this.formattedTableName = 'POSITIONS';
        rtn = 'POSITIONS';
        break;
      case 'STUDENT':
        this.formattedTableName = 'STUDENT ROSTER';
        rtn = 'STUDENT ROSTER';
        break;
      case 'EDUCATIONPOSITION':
        this.formattedTableName = 'POSITIONS';
        rtn = 'POSITIONS';
        break;
      case 'COMMUNICATIONSTATUS':
        this.formattedTableName = 'COMMUNICATION';
        rtn = 'COMMUNICATION';
        break;
      case 'VOTER':
        this.formattedTableName = 'VOTER';
        rtn = 'VOTER';
        break;
      case 'STUDENTCLASS':
        this.formattedTableName = 'CLASSES';
        rtn = 'CLASSES';
        break;
      case 'STUDENTPROGRAM':
        this.formattedTableName = 'PROGRAMS';
        rtn = 'PROGRAMS';
        break;
      case 'SURVEYRESPONSE':
        this.formattedTableName = 'SURVEYS';
        rtn = 'SURVEYS';
        break;
      case 'ATTRIBUTE':
        this.formattedTableName = 'CUSTOM FIELDS';
        rtn = 'CUSTOM FIELDS';
        break;
      case 'STUDENTATTRIBUTE':
        this.formattedTableName = 'CUSTOM FIELDS';
        rtn = 'CUSTOM FIELDS';
        break;
      case 'EMPATTRIBUTE':
        this.formattedTableName = 'CUSTOM FIELDS';
        rtn = 'CUSTOM FIELDS';
        break;
      default:
        rtn = table;
        break;
    }
    return rtn;
  }

  getTotal(): void {
    switch (this.routeContext) {
      case 'membership':
        if (this.getRosterTotalRequest) {
          this.getRosterTotalRequest.unsubscribe();
        }
        this.getRosterTotalRequest = this.rosterService.getRosterPrimeNG('TOTAL', this.currentFilter, this.setRosterQueryId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              if (res) {
                this.dataTotal = res.Total;
              }
            }
          });
        break;
      case 'training':
        if (this.getTotalStudentRequest) {
          this.getTotalStudentRequest.unsubscribe();
        }
        this.getTotalStudentRequest = this.studentsService.getStudentsPrimeNG('TOTAL', this.currentFilter, this.setRosterQueryId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              if (res) {
                this.dataTotal = res.Total;
              }
            }
          });
        break;
      case 'organize':
        if (this.getProspectTotalRequest) {
          this.getProspectTotalRequest.unsubscribe();
        }
        this.getProspectTotalRequest = this.prospectsService.getProspectsPrimeNG('TOTAL', this.currentFilter, this.setRosterQueryId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              if (res) {
                this.dataTotal = res.Total;
              }
            }
          });
        break;
    }
  }

  getList(): void {
    if (this.getRosterListRequest) {
      this.getRosterListRequest.unsubscribe();
    }
    this.loading = true;
    switch (this.routeContext) {
      case 'membership':
        this.getRosterListRequest = this.rosterService.getRosterPrimeNG('DATA', this.currentFilter, this.setRosterQueryId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              if (res) {
                this.tableData = res.Data;
              }
              this.loading = false;
            }, error: () => {
              this.loading = false;
            }
          });
        break;
      case 'training':
        if (this.getListStudentRequest) {
          this.getListStudentRequest.unsubscribe();
        }
        this.getListStudentRequest = this.studentsService.getStudentsPrimeNG('DATA', this.currentFilter, this.setRosterQueryId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              if (res) {
                this.tableData = res.Data;
              }
              this.loading = false;
            }, error: () => {
              this.loading = false;
            }
          });
        break;
      case 'organize':
        if (this.getProspectListRequest) {
          this.getProspectListRequest.unsubscribe();
        }
        this.getProspectListRequest = this.prospectsService.getProspectsPrimeNG('DATA', this.currentFilter, this.setRosterQueryId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              if (res) {
                this.tableData = res.Data;
              }
              this.loading = false;
            }, error: () => {
              this.loading = false;
            }
          });
        break;
    }
  }

  loadTable(event?): void {
    this.loading = true;
    if (event) {
      this.currentFilter.rows = event.rows;
      this.currentFilter.first = event.first;
      this.currentFilter.filters = event.filters;
      this.currentFilter.AuxFilters = event.AuxFilters;
    }
    this.getTotal();
    this.getList();
  }

  addDistList(): void {
    this.isStatic = false;
    this.isNewDist = !this.isNewDist;
    this.changeDetector.detectChanges();
    if (this.isNewDist) {
      this.mainForm.get('name').setValidators(Validators.required);
      this.mainForm.get('distributionList').clearValidators();
    } else {
      this.mainForm.get('name').clearValidators();
      this.mainForm.get('distributionList').setValidators(Validators.required);
    }
    this.mainForm.get('distributionList').updateValueAndValidity();
    this.mainForm.get('name').updateValueAndValidity();
    this.mainForm.get('name').setValue(null);
  }

  addStaticDistList(): void {
    this.isStatic = true;
    this.isNewDist = true;
    this.changeDetector.detectChanges();
    this.mainForm.get('name').setValidators(Validators.required);
    this.mainForm.get('name').updateValueAndValidity();
    this.mainForm.get('name').setValue(null);
    this.mainForm.get('distributionList').clearValidators();
    this.mainForm.get('distributionList').updateValueAndValidity();
    this.mainForm.get('fileFormat').setValidators(Validators.required);
    this.mainForm.get('fileFormat').updateValueAndValidity();
    this.mainForm.get('files').setValidators(Validators.required);
    this.mainForm.get('files').updateValueAndValidity();
  }

  canAddNew(): boolean {
    if (this.isNewDist) {
      return !!(this.setOrganizationId);
    } else {
      return !!(this.setOrganizationId && this.setRosterQueryId);
    }
    // return (this.setOrganizationId && this.setRosterQueryId) || (this.setOrganizationId && this.mainForm.get('name').value);
  }

  processNewDistList(newQueryID): void {
    this.rosterQueryService.getRosterQueryPrimeNg(newQueryID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.selectedRosterQuery = res;
          this.changeDetector.detectChanges();
          this.isNewDist = false;
          this.changeDetector.detectChanges();
          this.setOrganizationId = this.selectedRosterQuery.OrganizationID;
          this.setRosterQueryId = newQueryID;
          this.initRosterQuery(false);
          this.mainForm.get('name').setValue(this.selectedRosterQuery.Description);
        }
      });
  }

  closeAllAccordionTabs(): void {
    if (this.accordion) {
      this.accordionIndex = [];
    }
  }

  openAllAccordionTabs(): void {
    this.accordionIndex = [];
    this.accordion.tabs.forEach(tab => {
      this.accordionIndex.push(tab.findTabIndex());
    });
  }

  updateName(): void {
    this.selectedRosterQuery.Description = this.mainForm.get('name').value;
    this.rosterQueryService.updateRosterQueryPrimeNg(this.setRosterQueryId, this.selectedRosterQuery)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.initRosterQuery(false);
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Distribution List Name has been updated'});
        }, error: () => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Something went wrong.'});
        }
      });
  }

  goToEdit(selectedPerson) {
    sessionStorage.setItem('sessionViewType', 'Grid');
    let component;
    if (this.routeContext === 'membership') {
      component = RosterTabContainerComponent;
    }
    if (this.routeContext === 'training') {
      component = RosterStudentTabContainerComponent;
    }
    if (this.routeContext === 'organize') {
      component = ProspectsTabContainerComponent;
    }
    this.dialogService.open(component, {
      data: {
        id: selectedPerson.PersonID,
        isEdit: true,
        isDialog: true
      },
      header: selectedPerson.FirstName + ' ' + selectedPerson.LastName,
      width: '90%',
      height: '90%'
    });
  }

  promptToDeleteQuery(): void {
    this.confirmationService.confirm({
      message: 'You are about to delete a filter. Are you sure this is what you want to do?',
      header: 'Warning',
      icon: 'pi pi-times',
      accept: () => {
        this.deleteQuery();
      }
    });
  }

  deleteQuery(): void {
    this.rosterQueryService.deleteRosterQuery(this.setRosterQueryId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Distribution List was deleted'});
          location.reload();
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        }
      });
  }

  openAuxPreview(filter): void {
    this.findTableName(filter.Enumerator);
    this.dialogService.open(AuxTablePreviewDialogComponent, {
      data: {
        relatedTable: filter.Enumerator,
        selectedRosterQuery: filter,
        tableName: this.formattedTableName
      },
      header: 'Filter Preview',
      width: '90%',
    });
  }

  showPreview(tableName) {
    return tableName === 'UNIONPOSITION' || tableName === 'EDUCATIONPOSITION' || tableName === 'VOTER' || tableName === 'STUDENTCLASS' || tableName === 'STUDENTPROGRAM' || tableName === 'ATTRIBUTE' || tableName === 'STUDENTATTRIBUTE' || tableName === 'EMPATTRIBUTE';
  }

  initFileFormat(disable: boolean): void {
    this.inputObjFileFormat = {
      labelText: 'File Format',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: true
    };
    this.lookupsDataService.getRosterQueryImportFileFormatsLookupData(this.setOrganizationId).then((lookupData) => {
      this.inputObjFileFormat.data = lookupData;
      this.inputObjFileFormat = Object.assign({}, this.inputObjFileFormat);
    });
  }

  setFileFormatData(event: any): void {
    if (event && event[0]) {
      this.setFileFormat = event[0].Description;
    } else {
      this.setFileFormat = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('fileFormat').setValue(this.setFileFormat);
  }

  staticCancel(): void {
    this.isStatic = false;
    this.mainForm.get('fileFormat').clearValidators();
    this.mainForm.get('fileFormat').updateValueAndValidity();
    this.mainForm.get('files').clearValidators();
    this.mainForm.get('files').updateValueAndValidity();
    this.setFileFormat = null;
    this.fileUpload.clear();
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  staticReset(): void {
    this.mainForm.get('name').setValue(null);
    this.initFileFormat(false);
    this.setFileFormat = null;
    this.mainForm.get('fileFormat').setValue(this.setFileFormat);
    this.fileUpload.clear();
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  staticAdd(): void {
    this.isSaving = true;
    const staticData: RosterQueryExistingModel = {
      ContextEnumerator: this.routeContext,
      Description: this.mainForm.get('name').value,
      Active: true,
      Filters: null,
      OrganizationID: this.setOrganizationId,
      RosterQueryID: 0
    };
    this.rosterQueryService.createRosterQueryPrimeNg(staticData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.showProgress = true;
          this.rosterQueryService.uploadRosterQueryImportFile(res.RosterQueryID, this.setFileFormat, true, this.fd)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (resUpload) => {
                if (resUpload && resUpload !== '') {
                  this.messageService.add({severity: 'success', summary: 'Success', detail: resUpload.replace('UM: ', ''), life: 4000});
                }
                this.staticCancel();
                this.selectedRosterQuery = res;
                this.changeDetector.detectChanges();
                this.isNewDist = false;
                this.changeDetector.detectChanges();
                this.setRosterQueryId = 0;
                this.initRosterQuery(false);
                this.mainForm.get('name').setValue(null);
                this.showProgress = false;
                this.isSaving = false;
              }, error: (e) => {
                if (e?.error?.Message) {
                  this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 4000});
                } else {
                  this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
                }
                this.showProgress = false;
                this.isSaving = false;
              }
            });
        }, error: () => {
          this.isSaving = false;
        }
      });
  }

  onUpload(event: any): any {
    if (event.files.length > 0) {
      this.mainForm.get('files').setValue(event.files.length);
      for (const file of event.files) {
        this.uploadedFiles.push(file);
        this.fd.append('file', file);
      }
      this.mainForm.markAsDirty();
    }
  }

  clearUpload(): void {
    this.uploadedFiles.length = 0;
    this.fd = new FormData();
    this.mainForm.get('files').setValue(null);
    this.mainForm.markAsDirty();
  }

  isStatusInActive(status): any {
    return this.inactiveStatuses.indexOf(status) > -1;
  }

  // getAvatarClass(img: boolean, status: string): any {
  //   let str: string = '';
  //   if (img === false) {
  //     str = '-no-image';
  //   }
  //   switch (true) {
  //     case status === 'Deceased':
  //       return 'deceased-status' + str;
  //     case status === 'Suspended':
  //       return 'suspended-status' + str;
  //     case this.inactiveStatuses.indexOf(status) > -1:
  //       return 'inactive-status' + str;
  //     case this.inactiveStatuses.indexOf(status) === -1:
  //       return 'active-status' + str;
  //   }
  // }
}
