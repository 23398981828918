<form [formGroup]="mainForm">
  <div class="p-fluid p-grid margin-top-10">
    <div class="col-md-2 col-sm-6 col-xs-12">
      <label>Related Table<sup class="req">*</sup></label>
      <input type="text" pInputText formControlName="table">
    </div>
    <div class="col-md-2 col-sm-12 col-xs-12">
      <label>Columns<sup class="req">*</sup></label>
      <input type="text" pInputText formControlName="column">
    </div>
    <div class="col-md-2 col-sm-12 col-xs-12">
      <label>Match Type<sup class="req">*</sup></label>
      <input type="text" pInputText formControlName="matchType">
    </div>
    @if (mainForm.controls.matchType.value !== 'Between' && mainForm.controls.matchType.value !== 'Empty'
    && mainForm.controls.matchType.value !== 'Is Not Empty' && mainForm.controls.matchType.value !== 'Months From Present Between'
    && mainForm.controls.matchType.value !== 'Days From Present Between') {
      <div class="col-md-2 col-sm-12 col-xs-12">
        <label>Value <sup class="req">*</sup></label>
        <input type="text" pInputText formControlName="value">
      </div>
    }
    @if (mainForm.controls.matchType.value === 'Between' || mainForm.controls.matchType.value === 'Months From Present Between'
    || mainForm.controls.matchType.value === 'Days From Present Between') {
      <div class="col-md-2 col-sm-12 col-xs-12">
        <label>Start Value <sup class="req">*</sup></label>
        <input type="text" pInputText formControlName="startValue">
      </div>
    }
    @if (mainForm.controls.matchType.value === 'Between' || mainForm.controls.matchType.value === 'Months From Present Between'
    || mainForm.controls.matchType.value === 'Days From Present Between') {
      <div class="col-md-2 col-sm-12 col-xs-12 ">
        <label>End Value <sup class="req">*</sup></label>
        <input type="text" pInputText formControlName="endValue">
      </div>
    }
    <div class="col-md-2 col-sm-12 col-xs-12 add-entry">
      <!--      <button *ngIf="selectedTable !== 'Roster' && selectedTable !== 'Student Roster'" pButton (click)="openAuxPreview()" icon="pi pi-search" pTooltip="Preview" tooltipPosition="bottom"-->
      <!--              class="margin-top-20 margin-right-10"></button>-->
      <button pButton (click)="promptToDelete()" icon="pi pi-trash" pTooltip="Remove" tooltipPosition="bottom"
              class="margin-top-20"></button>
    </div>
  </div>
</form>
