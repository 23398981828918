<!--<p-card>-->
<div class="p-fluid p-grid">
  <!--    <div class="col-md-12 col-sm-12 col-xs-12">-->
  <!--      <div class="p-card-title">-->
  <!--        <i class="fas fa-filter"></i> Distribution List-->
  <!--      </div>-->
  <!--    </div>-->
  @if (!isDialog) {
    <div class="col-md-12 col-sm-12 col-xs-12">
      @if (!isStatic) {
        <button pButton class="p-button-info p-mr-2" [className]="'inline-button margin-top-8'" pTooltip="Dynamic"
                tooltipPosition="bottom"
                (click)="addDistList()"> @if (!isNewDist) {
          <span>New</span>
        } @else {
          <span>Edit</span>
        }&nbsp;Distribution List
        </button>
      } @else {
        <button pButton label="Back" (click)="isStatic = false;" class="p-button-info"
                [className]="'inline-button margin-top-8'"
                icon="pi pi-angle-left" pTooltip="Back" tooltipPosition="bottom"></button>
      }
      @if (!isStatic && _tabsPermissionsUtility.checkTabRead(permissions, 'staticlist')) {
        <button pButton class="p-button-info p-mr-2" [className]="'inline-button margin-top-8'" pTooltip="Static"
                tooltipPosition="bottom"
                (click)="addStaticDistList()"> New Static Distribution List
        </button>
      }
    </div>
  }
  <div class="col-md-12 col-sm-12 col-xs-12">
    <form [formGroup]="mainForm" class="full-width">
      <div class="p-fluid p-grid margin-top-10">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <h3>@if (isNewDist) {
            <span>New </span>
          } @else {
            <span>Existing </span>
          }
            @if (isStatic) {
              <span>Static </span>
            } Distribution List</h3>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <app-select (emitData)="setOrganizationData($event)" [inputObj]="inputObjOrganization"
                      class="full-width"></app-select>
        </div>
        @if (!isNewDist) {
          <div class="col-md-3 col-sm-3 col-xs-12">
            <app-dist-list-select class="full-width" [inputObj]="inputObjRosterQuery"
                                  (emitData)="setRosterQueryData($event)"></app-dist-list-select>
          </div>
        }
        @if (isNewDist || (selectedRosterQuery | json) !== '{}') {
          <div class="col-md-3 col-sm-4 col-xs-12">
            <label>Name <sup class="req">*</sup></label>
            <input class="full-width" type="text" pInputText formControlName="name" (change)="canAddNew()">
            @if ((mainForm.controls.name.dirty || mainForm.controls.name.touched) &&
            mainForm.controls.name.errors?.required) {
              <span class="error">'Name' must be provided</span>
            }
          </div>
        }
        @if ((!isNewDist && (selectedRosterQuery | json) !== '{}')) {
          <div class="col-md-2 col-sm-4 col-xs-12 margin-top-20">
            <button pButton (click)="updateName()" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                    label="Update Name" [style]="{width:'auto', 'margin-right':'3px'}"></button>
            <!--            <button pButton (click)="promptToDeleteQuery()" icon="pi pi-trash" pTooltip="Remove"-->
            <!--                    tooltipPosition="bottom" [style]="{'padding':'7px'}"></button>-->
          </div>
        }
      </div>
    </form>
  </div>
  @if (isStatic && _tabsPermissionsUtility.checkTabRead(permissions, 'staticlist')) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <div class="p-fluid p-grid">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <app-select class="full-width" [inputObj]="inputObjFileFormat"
                      (emitData)="setFileFormatData($event)"></app-select>
        </div>
        <!--        <div class="col-md-6 col-sm-12 col-xs-12 radio-container">-->
        <!--          <p-radioButton value="F" formControlName="addStatic" [styleClass]="'radio-input'" class="p-mr-2 p-mt-5" label="Add to existing static data logic"></p-radioButton>-->
        <!--          <p-radioButton value="M" formControlName="clearStatic" [styleClass]="'radio-input'" label="Clear existing static data logic" class="p-mr-2" ></p-radioButton>-->
        <!--        </div>-->
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 col-sm-12 col-xs-12 input-container p-button-auto-width">
            <p>*The file must be 30 MB or less in size.</p>
            <p-fileUpload #fileUploader name="uploadedFiles[]" (onSelect)="onUpload($event)" (onClear)="clearUpload()"
                          accept=".xls,.xlsx,.csv" [showUploadButton]="false" maxFileSize="31457280">
              <ng-template let-file pTemplate='file'>
                <div>
                  {{ file.name }} - ({{ file.size }} bytes)
                </div>
              </ng-template>
            </p-fileUpload>
          </div>
          @if (showProgress) {
            <div class="col-md-12">
              <p-progressBar mode="indeterminate"></p-progressBar>
            </div>
          }
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <h4>File Assumptions:</h4>
          <ul class="ut-sm-txt">
            <li>
              <b>No Header Necessary</b>
            </li>
            <li>
              <b>Single Column</b> (Unless non-standard format is selected)
            </li>
          </ul>
          <ul class="ut-sm-txt">
            <li>
              <b>CSV File Format</b> = Comma-separated values.
            </li>
            <li>
              <b>XLS/XLSX File Format</b> = Microsoft Excel.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12"></div>
          <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
            <button pButton (click)="staticCancel()" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                    class="margin-right-10 inline-button margin-top-10" label="Cancel"></button>
            <button pButton (click)="staticReset()" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                    class="margin-right-10 inline-button margin-top-10" label="Reset"
                    [disabled]="!mainForm.dirty"></button>
            @if (!isSaving) {
              <button pButton (click)="staticAdd()" icon="pi pi-plus" pTooltip="Add" tooltipPosition="bottom"
                      class="margin-right-10 inline-button margin-top-10" label="Add"
                      [disabled]="mainForm.invalid || !mainForm.dirty || uploadedFiles.length <= 0"></button>
            } @else {
              <button pButton label="Add" class="p-button-info p-mr-2" [className]="'inline-button'"
                      [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i></button>
            }
          </div>
        </div>
      </div>
    </div>
  }
  @if (!isStatic && !hasStaticList && (canAddNew() || (selectedRosterQuery && selectedRosterQuery.Filters && (selectedRosterQuery.Filters | json) !== '{}'))) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      @if (canAddNew()) {
        <h4>New Filter</h4>
      }
      @if (canAddNew()) {
        <app-distribution-list-filter-form [isDialog]="isDialog" [isEdit]="false" [isNewFilter]="true"
                                           [isNewDist]="isNewDist" [organizationId]="setOrganizationId"
                                           [selectedRosterQuery]="selectedRosterQuery"
                                           [description]="mainForm.get('name').value"
                                           [setTableFromDialog]="this.setTableFromDialog"
                                           [setColumnFromDialog]="setColumnFromDialog"
                                           [setValueFromDialog]="setValueFromDialog"
                                           (updateFilter)="processNewDistList($event)"
                                           (updateLiveFilter)="loadTable($event)"></app-distribution-list-filter-form>
      }
      @if (!isNewDist) {
        <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding time-accordion">
          @for (filter of selectedRosterQuery.Filters; track filter) {
            <p-card [styleClass]="'facet-container margin-top-10'">
              <div class="p-grid p-fluid">
                <div class="col-md-8 col-sm-8 col-xs-12">
                  @if (filter && filter.FilterPrimeNG && filter.FilterPrimeNG.filters && (filter.FilterPrimeNG.filters | json) !== '{}') {
                    <h3>{{ findTableName(filter.Enumerator) }}</h3>
                  }
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 text-align-right">
                  @if (showPreview(filter.Enumerator)) {
                    <button pButton (click)="openAuxPreview(filter)" icon="pi pi-search" pTooltip="Preview"
                            tooltipPosition="bottom"
                            class="margin-right-10 inline-button margin-top-10" label="Preview Results"></button>
                  }
                </div>
              </div>
              <h2 class="category-header"></h2>
              @for (filterForm of filter?.FilterPrimeNG?.filters | keyvalue; track filterForm) {
                <p-card [styleClass]="'sub-facet-container margin-top-10'">
                  @for (filterDetails of filterForm.value; track filterDetails) {
                    <app-distribution-existing-filter-form [selectedFilter]="filterDetails"
                                                           [selectedTable]="filter.Enumerator"
                                                           [selectedRosterQuery]="selectedRosterQuery"
                                                           [selectedColumn]="filterForm.key"
                                                           (updateFilter)="getExistingRosterQuery($event)"
                                                           (updateLiveFilter)="loadTable($event)"></app-distribution-existing-filter-form>
                  }
                </p-card>
              }
            </p-card>
          }
        </div>
      }
    </div>
  }

  @if (setRosterQueryId) {
    <div class="col-md-12 margin-top-5 margin-bottom-5 text-align-right">
      <button pButton (click)="viewTable = !viewTable" pTooltip="Show/Hide Results"
              tooltipPosition="bottom"
              class="inline-button margin-top-10 p-mr-3" label="Show/Hide Results"></button>
    </div>
  }

  @if (loadingTable && !isNewDist && viewTable) {
    <div class="col-md-12 margin-top-10">
      <p-table
        #dt1
        [rows]="pageRowCountUtility.pageRowCount()"
        [columns]="cols"
        dataKey="PersonID"
        [value]="tableData"
        [lazy]="true"
        (onLazyLoad)="loadTable($event)"
        [paginator]="true"
        [showCurrentPageReport]="true"
        [metaKeySelection]="false"
        [totalRecords]="dataTotal"
        [rowsPerPageOptions]="[25,50,100,250,500]"
        [scrollable]="true"
        [loading]="loading"
        [(selection)]="selectedPerson"
        scrollDirection="both"
        selectionMode="single"
        rowExpandMode="single"
        scrollHeight="52vh"
        styleClass="p-datatable-responsive-demo p-datatable-gridlines p-datatable-striped frozen-column-table"
        currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <!--            <th *ngIf="routeContext!=='membership'" style="width:75px" pFrozenColumn [frozen]="true">-->
            <!--              Profile-->
            <!--            </th>-->
            @for (col of columns; track col) {
              @if (col.frozen) {
                <th pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" style="min-width:150px;" pFrozenColumn>
                  <ng-container>
                    <div class="header-text">
                      {{ col.header }}
                    </div>
                  </ng-container>
                </th>
              }
              @if (!col.frozen) {
                <th pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" style="min-width:150px;">
                  <ng-container>
                    <div class="header-text">
                      {{ col.header }}
                    </div>
                  </ng-container>
                </th>
              }
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData" (click)="goToEdit(rowData)"
              [ngClass]="{'selectedRow' : selectedPerson === rowData}">
            <!--            <td *ngIf="routeContext!=='membership'" style="width:75px" pFrozenColumn [frozen]="true">-->
            <!--              <div *ngIf="isStatusInActive(rowData.StatusAlias)" class="inactive-flag"></div>-->
            <!--              <div class="avatar">-->
            <!--                <div class="avatar-without-image" [ngClass]="getAvatarClass(false, rowData.StatusAlias)"><img src="assets/images/profile-generic.png" alt=""></div>-->
            <!--              </div>-->
            <!--            </td>-->
            @for (col of columns; track col) {
              @if (col.frozen) {
                <td pFrozenColumn style="min-width:150px;">
                  <!--                <ng-container *ngIf="col.header === 'Profile'">-->
                  <!--                  <div *ngIf="isStatusInActive(rowData.StatusAlias)" class="inactive-flag"></div>-->
                  <!--                  <ng-container *ngIf="rowData[col.field]">-->
                  <!--                    <div class="avatar">-->
                  <!--                      <div class="avatar-with-image" [ngClass]="getAvatarClass(true, rowData.StatusAlias)"><img [src]="rowData[col.field] + blobToken" alt=""></div>-->
                  <!--                    </div>-->
                  <!--                  </ng-container>-->
                  <!--                  <ng-container *ngIf="!rowData[col.field]">-->
                  <!--                    <div class="avatar">-->
                  <!--                      <div class="avatar-without-image" [ngClass]="getAvatarClass(false, rowData.StatusAlias)"><img src="assets/images/profile-generic.png" alt=""></div>-->
                  <!--                    </div>-->
                  <!--                  </ng-container>-->
                  <!--                </ng-container>-->
                  @if (col.field === 'LastName' && isStatusInActive(rowData.StatusAlias)) {
                    <div class="inactive-flag"></div>
                  }
                  @if (col.header !== 'Profile') {
                    {{ rowData[col.field] }}
                  }
                </td>
              }
              @if (!col.frozen) {
                <td style="min-width:150px;">
                  @if (col.visible && col.displayType === 'currency') {
                    @if (rowData[col.field] < 0) {
                      <span class="positive"> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                    }
                    @if (rowData[col.field] === 0) {
                      <span> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                    }
                    @if (rowData[col.field] > 0) {
                      <span class="negative">{{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                    }
                  }
                  @if (col.visible && col.displayType === 'date') {
                    {{ rowData[col.field] | date: 'shortDate' }}
                  }
                  @if (col.visible && col.displayType === 'numeric') {
                    {{ rowData[col.field] | number:'1.0':'en-US' }}
                  }
                  @if (col.visible && col.displayType === 'phone') {
                    {{ rowData[col.field] | phone }}
                  }
                  @if (col.visible && col.displayType === 'text') {
                    {{ rowData[col.field] }}
                  }
                  @if (col.visible && !col.displayType) {
                    {{ rowData[col.field] }}
                  }
                </td>
              }
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [colSpan]="cols.length">No records found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }
</div>
<!--</p-card>-->
