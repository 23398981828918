import {InterfaceConfigListModel} from './interface-config.model';

export class ContextModel {
  apiBaseUrlV1: string;
  apiBaseUrlV2: string;
  tenantName: string;
  tenantLogo: string;
  supportEmail: string;
  accessToken: string;
  refreshToken: string;
  clientId: string;
  isLoggedIn: boolean;
  loggedIn: string;
  blobToken: string;
  multiTenant: boolean;
  UserId: string;
  UserName: string;
  TenantId: number;
  PersonId: number;
  OrganizationId: number;
  FacebookAppId: string;
  EmailVerified: boolean;
  LinkLogExists: boolean;
  UserNameEncoded: string;
  TenantIdEncoded: string;
  Administrator: boolean;
  Active: boolean;
  TemporaryPassword: boolean;
  HasSecurityQuestionResponses: boolean;
  Contexts: string[];
  pageContexts: string[];
  SuperUser: boolean;
  PersonName: string;
  selContext: string;
  selOrganizationId: number;
  selectedMOrgId: number;
  selectedTOrgId: number;
  selectedOOrgId: number;
  selOrganizationDesc: string;
  selectedMOrgDesc: string;
  selectedTOrgDesc: string;
  selectedOOrgDesc: string;
  isMobile: boolean;
  RootMemberOrgID: number;
  RootTrainingOrgID: number;
  RootOrganizeOrgID: number;
  tenantConfigs: tenantConfigsModel[];
  okta_cookie_redirect: string;
  UserAgreement: boolean;
  UserPreferences: InterfaceConfigListModel[];
  visibility: string;
  MembershipActiveStatus: string[];
  TrainingActiveStatus: string[];
}


export class tenantConfigsModel {
  Enumerator: string;
  StringValue: string;
  BooleanValue: boolean;
}

export class UserMetaDataModel {
  UserId: string;
  UserName: string;
  TenantId: number;
  PersonId: number;
  OrganizationId: number;
  FacebookAppId: string;
  EmailVerified: boolean;
  LinkLogExists: boolean;
  UserNameEncoded: string;
  TenantIdEncoded: string;
  Administrator: boolean;
  Active: boolean;
  TemporaryPassword: boolean;
  HasSecurityQuestionResponses: boolean;
  Contexts: string[];
  SuperUser: boolean;
  PersonName: string;
}
