import {AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProspectProfileService} from '../../services/prospect-profile.service';
import {Location} from '@angular/common';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ContextService} from '../../services/context.service';
import {InterfaceObjectAppsModel, InterfaceObjectItemPermissions} from '../../models/interface-objects.model';
import {tabsPermissionsUtility} from '../../shared/utilities/tabs-permissions.utility';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PageService} from '../../services/page.services';
import {PageModel} from '../../models/page.model';
import {employmentTradeTab} from '../../shared/utilities/component-manager-utility';
import {InactiveStatusUtility} from '../../shared/utilities/inactive-status.utility';
import {ProfileModel} from '../../models/profile.model';

@Component({
  selector: 'app-prospects-tab-container',
  templateUrl: './prospects-tab-container.component.html',
  styleUrls: ['./prospects-tab-container.component.scss']
})
export class ProspectsTabContainerComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  InterfaceObjectEnum: string = 'PROSPECTS';
  permissions: InterfaceObjectAppsModel[] = [];
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  dataId: number;
  prospects = {} as ProfileModel;
  origProspects = {} as ProfileModel;
  setProspectsQueryId: number = 0;
  setProspectsId: number;
  reload: boolean = true;
  isSaving: boolean = false;
  isDialog: boolean = false;
  blobToken: string;
  inactiveStatuses: string[] = [];
  showTabs: boolean;
  invalidRecord: boolean = false;
  isArchived: boolean;
  @Input() organizationId: number;
  @Input() ContentID: number;
  @Input() isEdit: boolean;
  @Input() canPageWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  isPage: boolean = false;
  public _employmentTradeTab = employmentTradeTab;
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(private prospectProfileService: ProspectProfileService,
              private router: Router, private route: ActivatedRoute,
              public config: DynamicDialogConfig, private pageService: PageService,
              private location: Location, private contextService: ContextService,
              private messageService: MessageService, private confirmationService: ConfirmationService,
              private changeDetection: ChangeDetectorRef, private inactiveStatusUtility: InactiveStatusUtility) {
  }

  ngOnInit(): void {
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isEdit = this.pageInfo.isEdit;
          this.isPage = this.pageInfo.isPage;
          this.isDialog = this.pageInfo.isDialog;
          this.ContentID = this.pageInfo.ContentID;
        }
      }
    });
    this.getDataToEdit(this.ContentID);
    if (this.canPageWrite === undefined) {
      this.pageService.getPagePermissions(this.InterfaceObjectEnum).then((permission: InterfaceObjectItemPermissions) => {
        this.canPageWrite = permission.Write;
      });
    }
    this.pageService.getTabPermissions(this.InterfaceObjectEnum).then((tabPermissions: InterfaceObjectAppsModel[]) => {
      this.permissions = tabPermissions;
      this.showTabs = true;
    });
    this.inactiveStatusUtility.getPersonProfileInactiveStatuses('organize').then(x => {
      this.inactiveStatuses = x;
    }).catch(y => {
      this.inactiveStatuses = y;
    });
    this.blobToken = this.contextService.contextObject.blobToken;
    this.changeStatus();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterContentInit(): void {
    this.changeStatus();
  }

  changeStatus(): void {
    this.changeDetection.detectChanges();
  }

  getDataToEdit(id: number): void {
    this.reload = false;
    if (id) {
      this.prospectProfileService
        .getProspectProfile(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.reload = true;
            this.origProspects = res;
            this.prospects = res;
            this.isArchived = res.Archive;
            if (this.prospects.Archive) {
              this.canPageWrite = false;
            }
          }, error: () => {
            this.invalidRecord = true;
          }
        });
    } else {
      this.reload = true;
    }
  }

  getProspectsData(event:any): void {
    if (event && event[0]) {
      this.setProspectsId = event[0].ID;
    } else {
      this.setProspectsId = null;
    }
    this.prospects.PersonID = this.setProspectsId;
  }

  reset(): void {
    this.route.params.subscribe({
      next: (params) => {
        this.dataId = Number(params.id);
        if (this.dataId) {
          this.getDataToEdit(this.dataId);
        }
      }
    });
  }

  ngOnChanges(): void {
    this.getDataToEdit(this.ContentID);
  }

  detailsSaved(event:any): void {
    if (!this.isEdit && this.isPage && event && event.ProspectID && this.router.url.includes('/add')) {
      this.router.navigateByUrl(`organize/prospects/edit/${event.ProspectID}`);
    } else if (!this.isEdit && this.isPage && this.router.url.includes('/add')) {
      this.back();
    } else {
      this.isEdit = true;
      this.prospects = event;
      this.origProspects = event;
      this.closeAddEdit.emit();
    }
  }

  back(): void {
    this.confirmationService.close();
    this.location.back();
  }

  setDefaultPic() {
    this.prospects.ThumbnailUrl = 'assets/images/profile-generic.png';
  }

  resetWarning() {
    this.confirmationService.close();
  }

  isStatusInActive(status): any {
    return this.inactiveStatuses.indexOf(status) > -1;
  }

  getAvatarClass(img: boolean, status: string): any {
    let str: string = '';
    if (img === false) {
      str = '-no-image';
    }
    switch (true) {
      case status === 'Deceased':
        return 'deceased-status' + str;
      case status === 'Suspended':
        return 'suspended-status' + str;
      case this.inactiveStatuses.indexOf(status) > -1:
        return 'inactive-status' + str;
      case this.inactiveStatuses.indexOf(status) === -1:
        return 'active-status' + str;
    }
  }
}
