import {Injectable} from '@angular/core';
import {TenantConfigDataService} from '../../services/tenant-config-data.service';

@Injectable()
export class ProspectDefinitions {
  prospectCols: any[];

  constructor(private tenantConfigDataService: TenantConfigDataService) {
    const SECUREDOB = this.tenantConfigDataService.getBooleanValue('SECUREDOB');
    const SECUREDOBField = (SECUREDOB === true) ? 'DOBSecure_D' : 'DOB_D';
    this.prospectCols = [
      {field: 'ThumbnailUrl', header: 'Profile', fullHeader: 'Profile', columnType: 'text', matchMode: 'contains', visible: true},
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: SECUREDOBField, header: 'Birth Date', fullHeader: 'Birth Date', columnType: 'date', matchMode: 'equals', visible: true},
      {field: 'OrganizationName', header: 'Organizations', fullHeader: 'Organization', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'StatusAlias', header: 'Status', fullHeader: 'Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'TypeAlias', header: 'Type', fullHeader: 'Type', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'EmployerName', header: 'Employer', fullHeader: 'Employer', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'Has Account', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'numeric', matchMode: 'equals', visible: false},
      {field: 'MiddleName', header: 'Middle Name', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'text', matchMode: 'contains', visible: false}
    ];
  }
}
