import {BaseDataGridModel} from './data-grid.model';

export interface ClassStudentsGridModel extends BaseDataGridModel {
  Data: ClassStudentModel[];
}

export interface ClassStudentModel {
  ClassStudentID: number;
  PersonID: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  TrainingStatusID: number;
  TrainingStatusDescription: string;
  ModuleGradeAverage: number;
  Classification: string;
  StatusDate_D: string;
  ExpirationDate_D: string;
  Grade: number;
  ResultID: number;
  ResultDescription: string;
  TrainingIdentifier: string;
  ShowCertificationFields: boolean;
  CertificationNumber: string;
  CertificationResourceID: number;
  CertificationResourceReferenceID: number;
  CertificationExternalUrl: string;
  CertificationIssueDate: string;
  CertificationExpirationDate: string;
  CertificationSharableID?: number;
  PrimaryEmailAddress: string;
  PrimaryEmailFailedCommunication: boolean;
  SecondaryPhoneNumber: string;
  SecondaryPhoneFailedCommunication: boolean;
  CourseName: string;
  CanEdit: boolean;
  CanDelete: boolean;
  TrainingStatusActive: boolean;
  MemberTypeAlias: string;
  MemberStatusAlias: string;
  StudentNumber: string;
  DOB_D: string;
  DOBSecure_D: string;
  PrimaryAddress1: string;
  PrimaryAddress2: string;
  PrimaryAddress3: string;
  PrimaryAddress4: string;
  PrimaryAddress5: string;
  PrimaryAddress6: string;
  PrimaryCity: string;
  PrimaryStateProvince: string;
  PrimaryZip: string;
  PrimaryCountry: string;
  FullName: string;
  CanEditRoster: boolean;
  Trade: string;
  ThumbnailUrl?: string;
}

export interface CreateClassStudentModel {
  PersonIDList: number[];
  TrainingStatusID: number;
  SendRegistrationEmail: boolean
}

export interface UpdateClassStudentModel {
  TrainingStatusID: number;
  StatusDate_D: string;
  ExpirationDate_D: string;
  Grade: number;
  ResultID: number;
  TrainingIdentifier: string;
  CertificationNumber: string;
  CertificationResourceID: number;
  CertificationExternalUrl: string;
  CertificationIssueDate: string;
  CertificationExpirationDate: string;
}

export interface ClassStudentPickerGridModel extends BaseDataGridModel {
  Data: ClassStudentPickerModel[];
}

export interface ClassStudentPickerModel {
  PersonID: number;
  UserID: string;
  UserName: string;
  UserEmail: string;
  TypeAlias: string;
  StatusAlias: string;
  OrganizationID: number;
  UTStatusDescription: string;
  OrganizationName: string;
  MemberNumber: string;
  Prefix: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Suffix: string;
  Gender: string;
  DOB_D: string;
  DOBSecure_D: string;
  SSN: string;
  Race: string;
  Ethnicity: string;
  Language: string;
  Education: string;
  Military: string;
  PrimaryAddress1: string;
  PrimaryAddress2: string;
  PrimaryAddress3: string;
  PrimaryAddress4: string;
  PrimaryAddress5: string;
  PrimaryAddress6: string;
  PrimaryCity: string;
  PrimaryStateProvince: string;
  PrimaryZip: string;
  PrimaryCountry: string;
  PrimaryVerified: boolean;
  PrimaryFailedCommunication: boolean;
  PrimaryPhonePhoneType: string;
  PrimaryPhoneNumber: string;
  PrimaryPhoneFormatted: string;
  PrimaryPhoneExtension: string;
  PrimaryPhoneVerified: boolean;
  PrimaryPhoneFailedCommunication: boolean;
  SecondaryPhonePhoneType: string;
  SecondaryPhoneNumber: string;
  SecondaryPhoneExtension: string;
  SecondaryPhoneVerified: boolean;
  SecondaryPhoneFailedCommunication: boolean;
  PrimaryEmailAddress: string;
  PrimaryEmailVerified: boolean;
  PrimaryEmailFailedCommunication: boolean;
  SecondaryEmailAddress: string;
  SecondaryEmailVerified: boolean;
  SecondaryEmailFailedCommunication: boolean;
  PublicPhonePhoneType: string;
  PublicPhoneNumber: string;
  PublicPhoneExtension: string;
  PublicPhoneVerified: boolean;
  PublicPhoneFailedCommunication: boolean;
  PublicEmailAddress: string;
  PublicEmailVerified: boolean;
  PublicEmailFailedCommunication: boolean;
  Positions: string;
  Instructor: string;
  IsStaff: boolean;
  HasAccount: string;
  ThumbnailUrl: string;
  Selected: boolean;
  HideContactInfo: boolean;
  MemberTypeAlias: string;
  MemberStatusAlias: string;
  StudentNumber: string;
  PersonProfileID: number;
  Classification: string;
  FullName?: string;
}

export interface StudentGradesGridModel extends BaseDataGridModel {
  Data: StudentGradesByCourseModel[];
}

export interface StudentGradesByCourseModel {
  ClassStudentID: number;
  CourseID: number;
  ClassID: number;
  PersonID: number;
  MemberNumber: number;
  FullName: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  CourseName: string;
  CognitiveLevelDescription: string;
  ResultDate_D: string;
  DateConverted?: Date;
  DateConvertedString?: string;
  ResultID: number;
  Grade: number;
  Passed: boolean;
  HasCertification: boolean;
  CertificationIssueDate?: string;
  CertificationExpirationDate?: string;
  CertificationSharableID?: number;
}

export interface StudentGradeCertificateModel {
  CertificationNumber: number;
  CertificationResourceID: number;
  CertificationResourceReferenceID: number;
  CertificationExternalUrl: string;
  CertificationIssueDate: string;
  CertificationExpirationDate: string;
}

export interface StudentAttendanceReportModel {
  FirstName: string;
  LastName: string;
  MiddleName: string;
  MemberNumber: number;
  StudentGroup: string;
  ClassDescription: string;
  CourseAttendance: StudentAttendanceCourseModel[];
}

export interface StudentAttendanceCourseModel {
  CourseDescription: string;
  Date_D: Date;
  AttendanceStatusDescription: string;
  Notes: string;
}


export interface StudentProgressReportModel {
  Instructor: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  MemberNumber: number;
  StudentGroup: string;
  ClassDescription: string;
  CourseProgress: CourseProgress[];
}

export interface CourseProgress {
  CourseDescription: string;
  Date_D: Date;
  CognitiveLevelDescription: string;
  ResultDescription: string;
  Grade: number;
}
