import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';
import {take} from 'rxjs/operators';

import {GoogleRecaptchaVerifyModel} from '../models/google-recaptcha.model';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {MessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class GoogleRecaptchaService {
  public googleRecaptchaVerifyModel: GoogleRecaptchaVerifyModel = {} as GoogleRecaptchaVerifyModel;

  constructor(private http: HttpClient, private recaptchaV3Service: ReCaptchaV3Service,
              private messageService: MessageService, private contextService: ContextService) {
  }

  public recaptchaChallenge(): any {
    return new Promise((resolve, reject) => {
      this.recaptchaV3Service.execute('validate_captcha')
        .pipe(take(1))
        .subscribe({
          next: (token) => {
            this.verifyRecaptchaToken(token)
              .pipe(take(1))
              .subscribe({
                next: (res) => {
                  // console.log('Google recaptcha v3 response:', res);
                  if (res && res.success === true) {
                    resolve(true);
                  } else {
                    reject(this.verificationFailed(res));
                  }
                }, error: (e) => {
                  reject(this.verificationFailed(e));
                }
              });
          }, error: (e) => {
            reject(this.verificationFailed(e));
          }
        });
    });
  }

  public verifyRecaptchaToken(responseToken: string): Observable<any> {
    if (this.contextService.contextObject) {
      return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/google/recaptcha/verify?responseToken=${responseToken}`, null);
    } else {
      return null;
    }
  }

  public verificationFailed(e: any): boolean {
    this.messageService.add({severity: 'error', summary: 'reCaptcha Failed', detail: 'reCaptcha verification has failed. Please refresh the page and try again.'});
    console.debug(e);
    return false;
  }
}
