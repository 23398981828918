<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-6 col-sm-6 col-xs-12 picture-container">
    <p-card header="Signature">
      <div class="text-align-middle image-area">

        @if (this.signatureImage) {
          <img class="profile-image" [src]="signatureImage"  />
        }
        @if (!this.signatureImage) {
          <img class="profile-image" src="assets/images/sign-here.png" width="100%"   (click)="addSignature()"/>
        }
        <p>Edit or Create your Signature</p>

        @if (personInfo) {
          <div class="text-align-middle upload-buttons">
            <div class="image-button p-ml-2">
              <button pButton label="Remove" class="p-button-info p-mr-2" icon="pi pi-trash" pTooltip="Save" tooltipPosition="bottom"
                      (click)="deleteImage('signature')" [className]="'inline-button'"></button>
              <button pButton label="Add/Edit" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                      (click)="addSignature()" [className]="'inline-button'"></button>
            </div>
          </div>
        }
      </div>
    </p-card>
  </div>
  <div class="col-md-6 col-sm-6 col-xs-12 picture-container">
    <p-card header="Profile Image">
      <div class="text-align-middle">
        @if (personInfo) {
          @if (personInfo.ThumbnailUrl) {
            <img class="profile-image" [ngSrc]="personInfo.ThumbnailUrl + blobToken" width="100" height="100" alt="" [priority]="true" />
          }
          @if (!personInfo.ThumbnailUrl) {
            <img class="profile-image" ngSrc="assets/images/user@2x.png" width="128" height="128" alt="" [priority]="true" />
          }
          <p>Upload or Capture the profile picture here as a .jpg, .png or .gif. File size is limited to 4MB.</p>
        }
        @if (personInfo) {
          <div class="text-align-middle upload-buttons">
            <div class="image-button p-ml-2">
              <app-image-editor [incomingImageUrl]="personInfo.ThumbnailUrl" (deleteImage)="deleteImage('profilephoto')" (updateImage)="uploadImage($event, 'profilephoto')"
                (updateFilename)="headerImageName = $event" [showWebCam]="true" [roundCropper]="true" [ratio]="1 / 1"
              (updatePage)="updateUserData()" [isProfile]="true" [PersonID]="this.personInfo.PersonID"></app-image-editor>
            </div>
          </div>
        }
      </div>

    </p-card>

  </div>
  <div class="col-md-6 col-sm-6 col-xs-12">
    <form [formGroup]="mainForm">
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <label>Invitation Recipient</label>
        <input type="text" pInputText formControlName="email" tabindex="0">
      </div>
      @if (canTabWrite) {
        <div class="col-md-12 col-sm-12 col-xs-12 input-container text-align-right">
          <button pButton label=" {{buttonLabel}}" class="p-button-info p-mr-2 margin-top-10" [className]="'inline-button'" icon="pi pi-envelope" (click)="sendEmail()"></button>
        </div>
      }
    </form>
  </div>
</div>
