import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Event, NavigationEnd, Router} from '@angular/router';
import {InterfaceObjectsService} from '../../../services/interface-objects.service';
import {takeUntil} from 'rxjs/operators';
import {ContextService} from 'src/app/services/context.service';
import {TenantConfigurations} from '../../../shared/tenant-configurations/configurations';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit, OnDestroy {

  activeItems: any[];
  itemMenuParent: any[] = [];
  hideMenu: boolean = false;
  // itemsUserMega: any[];
  // itemsSuperUserMega: any[];
  // itemsSuperMembershipMega: any[];
  // itemsSuperTrainingMega: any[];
  //
  // itemsUserGroup: any[];
  // itemsSuperUserGroup: any[];
  // itemsSuperMembershipGroup: any[];
  // itemsSuperTrainingGroup: any[];
  optionalStyling: boolean = true;
  centeredStyling: boolean = false;
  headerMenu: string = '';
  styleType: string = 'mega';
  headerColor: string;
  items2: any[] = [];
  @Input() selectedMenuView: string;
  tenantConfigurations = TenantConfigurations;
  worksitesLabel = 'Worksites';
  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private interfaceObjectService: InterfaceObjectsService,
              private contextService: ContextService, private confirmationService: ConfirmationService) {

    // if(contextService.contextObject.tenantName === 'IUPAT') {
    //   this.headerColor = '#000000';
    // } else {
    //   this.headerColor = '#AD172B';
    // }
  }

  goToUrl(link: string): void {
    sessionStorage.removeItem('sessionViewType');
    this.confirmationService.close();
    this.hideMenu = true;
    if (link) {
      if (!link.includes('http')) {
        this.router.navigateByUrl(link);
      } else {
        window.open(link, '_blank');
      }
    }
    // this.getURL(link.split('/')[0]);
  }

  getURL(link): void {
    let cleanLink: string;
    if (link.includes('-landing')) {
      cleanLink = '/' + link.split('/')[1];
    } else {
      cleanLink = link;
    }
    if (this.itemMenuParent.find(x => '/' + x.Route === cleanLink) !== undefined) {
      this.headerMenu = this.itemMenuParent.find(x => '/' + x.Route === cleanLink)?.Parent.toString().toLowerCase();
    } else {
      this.headerMenu = '';
    }
    // switch(link) {
    //   case 'membership':
    //     this.headerMenu = link;
    //     break;
    //   case 'training':
    //     this.headerMenu = link;
    //     break;
    //   case 'organize':
    //     this.headerMenu = link;
    //     break;
    //   case 'users':
    //   case 'unlinked-users':
    //   case 'user-help':
    //   case 'privacy-policy':
    //   case 'about':
    //   case 'admin':
    //     this.headerMenu = 'support';
    //     break;
    //   case 'alerts':
    //   case 'dashboard':
    //   case 'page-not-found':
    //     this.headerMenu = '';
    //     break;
    //   case 'report-builder' :
    //     this.headerMenu = 'reporting';
    //     break;
    //   default: this.headerMenu = 'my account';
    // }
  }

  getAppList(): void {
    if (sessionStorage.getItem('menuData') === null || sessionStorage.getItem('menuData') === undefined) {
      this.interfaceObjectService.getMenuItems().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (res) => {
          this.formatMenu(res);
        }
      });
    } else {
      // console.log(JSON.parse(sessionStorage.getItem('menuData')));
      this.formatMenu(JSON.parse(sessionStorage.getItem('menuData')));
    }

  }

  formatMenu(res) {
    let worksitesLabel: string = 'Worksites';
    if (this.contextService.contextObject.tenantName.toLowerCase() === 'iupat') {
      worksitesLabel = this.tenantConfigurations.IUPATWORKSITESLABEL;
    }
    const statisActiveItems: any[] = JSON.parse(JSON.stringify(res).replaceAll('Employer Locations', worksitesLabel));
    statisActiveItems.forEach(x => {
      let i: number = 0;
      x.Items?.forEach(y => {
        if (y.Label === 'Options') {
          x.Items?.splice(i, 1);
        }
        i += 1;
      });
      i = 0;
      x.Items?.forEach(y => {
        if (y.Label === 'Tenant Admin Options') {
          x.Items?.splice(i, 1);
        }
        i += 1;
      });
      i = 0;
      x.Items?.forEach(y => {
        if (y.Label === 'Printables' || y.Label === 'Predefined Reports') {
          x.Items?.splice(i, 1);
        }
        i += 1;
      });
      x.Items?.forEach(a => {
        a.Items?.forEach(b => {
          this.itemMenuParent.push({
            Parent: x.Label,
            Route: b.Route
          });
        });
      });
    });
    sessionStorage.setItem('menuData', JSON.stringify(res).replaceAll('Employer Locations', worksitesLabel));
    this.activeItems = statisActiveItems;
  }

  ngOnInit(): void {
    if (this.contextService.contextObject.tenantName.toLowerCase() === 'iupat') {
      this.worksitesLabel = this.tenantConfigurations.IUPATWORKSITESLABEL;
    }
    this.router.events.subscribe({
      next: (event: Event) => {
        sessionStorage.removeItem('savedReport'); //clear a report builder that might be abandoned
        if (event instanceof NavigationEnd) {
          switch (event.url.split('/')[1]) {
            case 'reports':
            case 'select-options':
            case 'tenant-admin':
              // this.getURL(event.url.split('/')[2]);
              this.getURL(event.url.split('/')[1]);
              break;
            default:
              //this.getURL(event.url.split('/')[1]);
              this.getURL(event.url);
              break;
          }
        }
      }
    });
    //this.setSuperUserMegaItems();
    this.getAppList();
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  // setSuperUserMegaItems() {
  //   this.itemsSuperUserMega = [
  //     {
  //       Label: 'My Account', Icon: 'dashboard', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Tools', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'My Wall', Icon: 'dashboard', external: false, link: 'wall'
  //             },
  //             {
  //               Label: 'My Profile', Icon: 'person', external: false, link: 'profile'
  //             },
  //             {
  //               Label: 'My Education', Icon: 'school', external: false, link: 'education/list'
  //             },
  //             {
  //               Label: 'My Employment', Icon: 'badge', external: false, link: 'employment'
  //             },
  //             {
  //               Label: 'My Time', Icon: 'timer', external: false, link: 'my-time'
  //             },
  //             {
  //               Label: 'My Documents', Icon: 'content_copy', external: false, link: 'documents'
  //             },
  //             {
  //               Label: 'My Communications', Icon: 'forum', external: false, link: 'communications'
  //             },
  //             // {
  //             //   Label: 'My Voice', Icon: 'card_giftcard', external: false, link: 'civic'
  //             // },
  //             {
  //               Label: 'My Surveys', Icon: 'assignment', external: false, link: 'surveys/list'
  //             },
  //             // {
  //             //   Label: 'My Account/Pay', Icon: 'monetization_on', external: false, link: 'account'
  //             // },
  //             // {
  //             //   Label: 'My Auto PAT', Icon: 'card_giftcard', external: false, link: 'account'
  //             // },
  //             // {
  //             //   Label: 'Google Drive', Icon: 'add_to_drive', external: false, link: 'google-drive'
  //             // },
  //             // {
  //             //   Label: 'My Points', Icon: 'timeline', external: false, link: 'points'
  //             // },
  //           ]
  //         },
  //         {
  //           Label: 'Apps', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'IMSe',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/home/oidc_client/0oaa6qlqg3s3dl8Fw5d6/aln177a159h7Zf52X0g8'
  //             },
  //             {
  //               Label: 'KLMS',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/app/dev-91555004_ftiklms_1/exk63si25vR803Kuz5d6/sso/saml'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Membership', Icon: 'dashboard', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'My Union', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             // {
  //             //   Label: 'Actions', Icon: 'touch_app', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Vitals', Icon: 'show_chart', external: false, link: 'membership/vitals'
  //             // },
  //             {
  //               Label: 'Organizations', Icon: 'business_center', external: false, link: 'membership/organizations/list'
  //             },
  //             {
  //               Label: 'Employers', Icon: 'store', external: false, link: 'membership/employers/list'
  //             },
  //             {
  //               Label: this.worksitesLabel, Icon: 'place', external: false, link: 'membership/worksites/list'
  //             },
  //             // {
  //             //   Label: 'Contracts', Icon: 'create', external: false, link: 'membership/contracts/list'
  //             // },
  //             // {
  //             //   Label: 'Form Tracker', Icon: 'assignment_turned_in', external: false, link: 'membership/form-tracker'
  //             // }
  //           ]
  //         },
  //         {
  //           Label: 'Communicate', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Distribution Lists', Icon: 'view_list', external: false, link: 'membership/roster-queries'
  //             },
  //             {
  //               Label: 'Communications', Icon: 'textsms', external: false, link: 'membership/communications/list'
  //             },
  //             {
  //               Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'membership/survey-builder/list'
  //             },
  //             {
  //               Label: 'Templates', Icon: 'layers', external: false, link: 'membership/email-templates/list'
  //             },
  //             {
  //               Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'reports/membership/mailing-labels'
  //             },
  //             {
  //               Label: 'Letters', Icon: 'mail_outline', external: false, link: 'membership/mail-merge-batches/list'
  //             },
  //             {
  //               Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'membership/mail-merge-templates/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Connect', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Roster', Icon: 'view_list', external: false, link: 'membership/roster/list'
  //             },
  //             // {
  //             //   Label: 'Champions', Icon: 'emoji_events', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Merge Duplicates', Icon: 'compress', external: false, link: 'membership/merge-duplicates'
  //             // },
  //             // {
  //             //   Label: 'Bulk Updates', Icon: 'update', external: false, link: 'membership/bulk-updates'
  //             // },
  //             // {
  //             //   Label: 'Missing Data', Icon: 'report_problem', external: false, link: 'membership/missing-data'
  //             // },
  //             // {
  //             //   Label: 'Personnel File Uploads', Icon: 'format_line_spacing', external: false, link: 'membership/personnel-files/list'
  //             // },
  //             {
  //               Label: 'Roster Directory', Icon: 'text_snippet', external: false, link: 'reports/membership/roster-directory'
  //             },
  //             {
  //               Label: 'Officer Directory', Icon: 'text_snippet', external: false, link: 'membership/officer-directory'
  //             },
  //             // {
  //             //   Label: 'Member Counts', Icon: 'timeline', external: false, link: 'membership/members-counts'
  //             // },
  //             // {
  //             //   Label: 'Address Change Report', Icon: 'text_snippet', external: false, link: 'reports/membership/address-change'
  //             // }
  //           ]
  //         },
  //         // {
  //         //   Label: 'Collect', Icon: 'folder', disabled: 'false',
  //         //   Items: [
  //         //     {
  //         //       Label: 'Accounts', Icon: 'monetization_on', external: false, link: 'membership/accounts/list'
  //         //     },
  //         //     {
  //         //       Label: 'Account Adjustments', Icon: 'leaderboard', external: false, link: 'membership/account-adjustments/list'
  //         //     },
  //         //     {
  //         //       Label: 'Billing Types', Icon: 'auto_awesome_mosaic', external: false, link: 'select-options/membership/billing-types/list'
  //         //     },
  //         //     {
  //         //       Label: 'Contributions', Icon: 'card_giftcard', external: false, link: 'membership/contributions'
  //         //     },
  //         //     {
  //         //       Label: 'IUPAT PAT', Icon: 'card_giftcard', external: false, link: 'wall'
  //         //     },
  //         //     {
  //         //       Label: 'Automatic Payments', Icon: 'sync', external: false, link: 'wall'
  //         //     },
  //         //     {
  //         //       Label: 'Daily Receipts Report', Icon: 'text_snippet', external: false, link: 'reports/membership/daily-receipts'
  //         //     },
  //         //     {
  //         //       Label: 'Annual Contributions', Icon: 'event_available', external: false, link: 'reports/membership/annual-contributions'
  //         //     }
  //         //   ]
  //         // },
  //         {
  //           Label: 'Events', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Events', Icon: 'event', external: false, link: 'membership/events/list'
  //             },
  //             {
  //               Label: 'Instances', Icon: 'local_activity', external: false, link: 'membership/event-instances/list'
  //             },
  //             {
  //               Label: 'Locations', Icon: 'place', external: false, link: 'membership/event-locations/list'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Training', Icon: 'folder', disabled: 'false', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Programs', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Training Centers', Icon: 'business', external: false, link: 'training/training-centers/list'
  //             },
  //             {
  //               Label: 'Students', Icon: 'school', external: false, link: 'training/students/list'
  //             },
  //             {
  //               Label: 'Programs', Icon: 'account_balance', external: false, link: 'training/programs/list'
  //             },
  //             {
  //               Label: 'Facets', Icon: 'device_hub', external: false, link: 'training/facets/list'
  //             },
  //             {
  //               Label: 'Courses', Icon: 'explore', external: false, link: 'training/courses/list'
  //             },
  //             {
  //               Label: 'Classes', Icon: 'class', external: false, link: 'training/classes-modules/list'
  //             },
  //             {
  //               Label: 'Bulk Time Entry', Icon: 'update', external: false, link: 'training/bulk-time'
  //             },
  //             {
  //               Label: 'Transcripts', Icon: 'mail_outline', external: false, link: 'reports/training/student-transcript'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Communicate', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Distribution Lists', Icon: 'view_list', external: false, link: 'training/roster-queries'
  //             },
  //             {
  //               Label: 'Communications', Icon: 'textsms', external: false, link: 'training/communications/list'
  //             },
  //             {
  //               Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'training/survey-builder/list'
  //             },
  //             {
  //               Label: 'Templates', Icon: 'layers', external: false, link: 'training/email-templates/list'
  //             },
  //             {
  //               Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'reports/training/mailing-labels'
  //             },
  //             {
  //               Label: 'Letters', Icon: 'mail_outline', external: false, link: 'training/mail-merge-batches/list'
  //             },
  //             {
  //               Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'training/mail-merge-templates/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Options', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Course Types', Icon: 'list', external: false, link: 'select-options/training/course-types/list'
  //             },
  //             {
  //               Label: 'Facet Types', Icon: 'list', external: false, link: 'select-options/training/facet-types/list'
  //             },
  //             {
  //               Label: 'Facet Categories', Icon: 'list', external: false, link: 'select-options/training/facet-categories/list'
  //             },
  //             {
  //               Label: 'Program Types', Icon: 'list', external: false, link: 'select-options/training/program-types/list'
  //             },
  //             {
  //               Label: 'Result Types', Icon: 'list', external: false, link: 'select-options/training/result-types/list'
  //             },
  //             {
  //               Label: 'Tracking Types', Icon: 'list', external: false, link: 'select-options/training/tracking-types/list'
  //             },
  //             {
  //               Label: 'Results', Icon: 'playlist_add_check', external: false, link: 'select-options/training/results/list'
  //             },
  //             {
  //               Label: 'Student Groups', Icon: 'people', external: false, link: 'select-options/training/student-groups/list'
  //             },
  //             {
  //               Label: 'Training Statuses', Icon: 'assignment_turned_in', external: false, link: 'select-options/training/training-statuses/list'
  //             },
  //             {
  //               Label: 'Subject Areas', Icon: 'list', external: false, link: 'select-options/training/subject-areas/list'
  //             },
  //             {
  //               Label: 'Certifications', Icon: 'text_snippet', external: false, link: 'select-options/training/certifications/list'
  //             },
  //             {
  //               Label: 'Wage Types', Icon: 'list', external: false, link: 'select-options/training/wage-adjustments/list'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Organize', Icon: 'folder', disabled: 'false', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Strategies', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             // {
  //             //   Label: 'Action Map', Icon: 'add_location', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Campaigns', Icon: 'campaign', external: false, link: 'organize/campaigns/list'
  //             },
  //             {
  //               Label: 'Prospects', Icon: 'person_pin', external: false, link: 'organize/prospects/list'
  //             },
  //             // {
  //             //   Label: 'Non-Signed Employers', Icon: 'person_outline', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Jobs', Icon: 'engineering', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Contract Awards', Icon: 'verified', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Contract Data Uploads', Icon: 'upload_file', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Prospect Uploads', Icon: 'person_add_alt', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Prospect Score', Icon: 'sports_score', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Employer Score', Icon: 'sports_score', external: false, link: 'wall'
  //             // }
  //           ]
  //         },
  //         {
  //           Label: 'Communicate', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Distribution Lists', Icon: 'view_list', external: false, link: 'organize/roster-queries'
  //             },
  //             {
  //               Label: 'Communications', Icon: 'textsms', external: false, link: 'organize/communications/list'
  //             },
  //             {
  //               Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'organize/survey-builder/list'
  //             },
  //             {
  //               Label: 'Templates', Icon: 'layers', external: false, link: 'organize/email-templates/list'
  //             },
  //             {
  //               Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'reports/organize/mailing-labels'
  //             },
  //             {
  //               Label: 'Letters', Icon: 'mail_outline', external: false, link: 'organize/mail-merge-batches/list'
  //             },
  //             {
  //               Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'organize/mail-merge-templates/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Options', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             // {
  //             //   Label: 'Action Types', Icon: 'list', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Activity Types', Icon: 'list', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Campaign Types', Icon: 'list', external: false, link: 'select-options/organize/campaign-types/list'
  //             },
  //             {
  //               Label: 'Campaign Statuses', Icon: 'list', external: false, link: 'select-options/organize/campaign-statuses/list'
  //             },
  //             {
  //               Label: 'Campaign Prospect Statuses', Icon: 'list', external: false, link: 'select-options/organize/campaign-prospect-statuses/list'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Support', Icon: 'folder', disabled: true, Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'User Management', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Users', Icon: 'info', external: false, link: 'users'
  //             },
  //             {
  //               Label: 'Unlinked Users', Icon: 'info', external: false, link: 'unlinked-users'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Admin Tools', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             // {
  //             //   Label: 'Help Setup', Icon: 'settings_suggest', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Add Page Help', Icon: 'queue', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Create Release Notes', Icon: 'verified', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Pathway Builder', Icon: 'settings', external: false, link: 'admin/pathways/list'
  //             },
  //             {
  //               Label: 'Roles', Icon: 'settings', external: false, link: 'admin/roles/list'
  //             },
  //             {
  //               Label: 'Type Positions', Icon: 'settings', external: false, link: 'admin/type-positions/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Help', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Get Support', Icon: 'video_library', external: true, link: 'https://engagesupport.uniontrack.com/support/home'
  //             },
  //             // {
  //             //   Label: 'Page Help', Icon: 'help', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Privacy Policy', Icon: 'attach_file', external: false, link: 'privacy-policy'
  //             },
  //             {
  //               Label: 'About', Icon: 'info', external: false, link: 'about'
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ];
  //   this.activeItems = this.itemsSuperUserMega;
  // }
  //
  // setSuperMembershipMegaItems() {
  //   this.itemsSuperMembershipMega = [
  //     {
  //       Label: 'My Account', Icon: 'dashboard', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Tools', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'My Wall', Icon: 'dashboard', external: false, link: 'wall'
  //             },
  //             // {
  //             //   Label: 'My Account/Pay', Icon: 'monetization_on', external: false, link: 'account'
  //             // },
  //             // {
  //             //   Label: 'My Auto PAT', Icon: 'card_giftcard', external: false, link: 'account'
  //             // },
  //             {
  //               Label: 'My Documents', Icon: 'content_copy', external: false, link: 'documents'
  //             },
  //             {
  //              Label: 'My Time', Icon: 'timer', external: false, link: 'my-time'
  //             },
  //             // {
  //             //   Label: 'My Voice', Icon: 'card_giftcard', external: false, link: 'civic'
  //             // },
  //             {
  //               Label: 'My Surveys', Icon: 'assignment', external: false, link: 'surveys/list'
  //             },
  //             {
  //               Label: 'My Profile', Icon: 'person', external: false, link: 'profile'
  //             },
  //             // {
  //             //   Label: 'Google Drive', Icon: 'add_to_drive', external: false, link: 'google-drive'
  //             // },
  //           ]
  //         },
  //         {
  //           Label: 'Apps', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'IMSe',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/home/oidc_client/0oaa6qlqg3s3dl8Fw5d6/aln177a159h7Zf52X0g8'
  //             },
  //             {
  //               Label: 'KLMS',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/app/dev-91555004_ftiklms_1/exk63si25vR803Kuz5d6/sso/saml'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Membership', Icon: 'dashboard', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'My Union', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             // {
  //             //   Label: 'Actions', Icon: 'touch_app', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Vitals', Icon: 'show_chart', external: false, link: 'membership/vitals'
  //             // },
  //             {
  //               Label: 'Organizations', Icon: 'business_center', external: false, link: 'membership/organizations/list'
  //             },
  //             {
  //               Label: 'Employers', Icon: 'store', external: false, link: 'membership/employers/list'
  //             },
  //             {
  //               Label: this.worksitesLabel, Icon: 'place', external: false, link: 'membership/worksites/list'
  //             },
  //             // {
  //             //   Label: 'Contracts', Icon: 'create', external: false, link: 'membership/contracts/list'
  //             // },
  //             // {
  //             //   Label: 'Form Tracker', Icon: 'assignment_turned_in', external: false, link: 'membership/form-tracker'
  //             // }
  //           ]
  //         },
  //         {
  //           Label: 'Communicate', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Distribution Lists', Icon: 'view_list', external: false, link: 'membership/roster-queries'
  //             },
  //             {
  //               Label: 'Communications', Icon: 'textsms', external: false, link: 'membership/communications/list'
  //             },
  //             {
  //               Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'membership/survey-builder/list'
  //             },
  //             {
  //               Label: 'Templates', Icon: 'layers', external: false, link: 'membership/email-templates/list'
  //             },
  //             {
  //               Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'membership/reports/mailing-labels'
  //             },
  //             {
  //               Label: 'Letters', Icon: 'mail_outline', external: false, link: 'membership/mail-merge-batches/list'
  //             },
  //             {
  //               Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'membership/mail-merge-templates/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Connect', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Roster', Icon: 'view_list', external: false, link: 'membership/roster/list'
  //             },
  //             // {
  //             //   Label: 'Champions', Icon: 'emoji_events', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Merge Duplicates', Icon: 'compress', external: false, link: 'membership/merge-duplicates'
  //             // },
  //             // {
  //             //   Label: 'Bulk Updates', Icon: 'update', external: false, link: 'membership/bulk-updates'
  //             // },
  //             // {
  //             //   Label: 'Missing Data', Icon: 'report_problem', external: false, link: 'membership/missing-data'
  //             // },
  //             // {
  //             //   Label: 'Personnel File Uploads', Icon: 'format_line_spacing', external: false, link: 'membership/personnel-files/list'
  //             // },
  //             {
  //               Label: 'Roster Directory', Icon: 'text_snippet', external: false, link: 'reports/membership/roster-directory'
  //             },
  //             {
  //               Label: 'Officer Directory', Icon: 'text_snippet', external: false, link: 'membership/officer-directory'
  //             },
  //             // {
  //             //   Label: 'Member Counts', Icon: 'timeline', external: false, link: 'membership/member-counts'
  //             // },
  //             // {
  //             //   Label: 'Address Change Report', Icon: 'text_snippet', external: false, link: 'reports/membership/address-change'
  //             // }
  //           ]
  //         },
  //         // {
  //         //   Label: 'Collect', Icon: 'folder', disabled: 'false',
  //         //   Items: [
  //         //     {
  //         //       Label: 'Accounts', Icon: 'monetization_on', external: false, link: 'membership/accounts/list'
  //         //     },
  //         //     {
  //         //       Label: 'Account Adjustments', Icon: 'leaderboard', external: false, link: 'membership/account-adjustments/list'
  //         //     },
  //         //     {
  //         //       Label: 'Billing Types', Icon: 'auto_awesome_mosaic', external: false, link: 'select-options/membership/billing-types/list'
  //         //     },
  //         //     {
  //         //       Label: 'Contributions', Icon: 'card_giftcard', external: false, link: 'membership/contributions'
  //         //     },
  //         //     {
  //         //       Label: 'IUPAT PAT', Icon: 'card_giftcard', external: false, link: 'wall'
  //         //     },
  //         //     {
  //         //       Label: 'Automatic Payments', Icon: 'sync', external: false, link: 'wall'
  //         //     },
  //         //     {
  //         //       Label: 'Daily Receipts Report', Icon: 'text_snippet', external: false, link: 'reports/membership/daily-receipts'
  //         //     },
  //         //     {
  //         //       Label: 'Annual Contributions', Icon: 'event_available', external: false, link: 'reports/membership/annual-contributions'
  //         //     }
  //         //   ]
  //         // },
  //         {
  //           Label: 'Events', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Events', Icon: 'event', external: false, link: 'membership/events/list'
  //             },
  //             {
  //               Label: 'Instances', Icon: 'local_activity', external: false, link: 'membership/event-instances/list'
  //             },
  //             {
  //               Label: 'Locations', Icon: 'place', external: false, link: 'membership/event-locations/list'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Support', Icon: 'folder', disabled: true, Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'User Management', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Users', Icon: 'info', external: false, link: 'users'
  //             },
  //             {
  //               Label: 'Unlinked Users', Icon: 'info', external: false, link: 'unlinked-users'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Admin Tools', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             // {
  //             //   Label: 'Help Setup', Icon: 'settings_suggest', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Add Page Help', Icon: 'queue', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Create Release Notes', Icon: 'verified', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Pathway Builder', Icon: 'settings', external: false, link: 'admin/pathways/list'
  //             },
  //             {
  //               Label: 'Roles', Icon: 'settings', external: false, link: 'admin/roles/list'
  //             },
  //             {
  //               Label: 'Type Positions', Icon: 'settings', external: false, link: 'admin/type-positions/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Help', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Get Support', Icon: 'video_library', external: true, link: 'https://engagesupport.uniontrack.com/support/home'
  //             },
  //             // {
  //             //   Label: 'Page Help', Icon: 'help', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Privacy Policy', Icon: 'attach_file', external: false, link: 'privacy-policy'
  //             },
  //             {
  //               Label: 'About', Icon: 'info', external: false, link: 'about'
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ];
  //   this.activeItems = this.itemsSuperMembershipMega;
  // }
  //
  // setSuperTrainingMegaItems() {
  //   this.itemsSuperTrainingMega = [
  //     {
  //       Label: 'My Account', Icon: 'dashboard', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Tools', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'My Wall', Icon: 'dashboard', external: false, link: 'wall'
  //             },
  //             // {
  //             //   Label: 'My Account/Pay', Icon: 'monetization_on', external: false, link: 'account'
  //             // },
  //             // {
  //             //   Label: 'My Auto PAT', Icon: 'card_giftcard', external: false, link: 'account'
  //             // },
  //             {
  //               Label: 'My Documents', Icon: 'content_copy', external: false, link: 'documents'
  //             },
  //             {
  //              Label: 'My Time', Icon: 'timer', external: false, link: 'my-time'
  //             },
  //             // {
  //             //   Label: 'My Voice', Icon: 'card_giftcard', external: false, link: 'civic'
  //             // },
  //             {
  //               Label: 'My Surveys', Icon: 'assignment', external: false, link: 'surveys/list'
  //             },
  //             {
  //               Label: 'My Profile', Icon: 'person', external: false, link: 'profile'
  //             },
  //             // {
  //             //   Label: 'Google Drive', Icon: 'add_to_drive', external: false, link: 'google-drive'
  //             // },
  //           ]
  //         },
  //         {
  //           Label: 'Apps', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'IMSe',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/home/oidc_client/0oaa6qlqg3s3dl8Fw5d6/aln177a159h7Zf52X0g8'
  //             },
  //             {
  //               Label: 'KLMS',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/app/dev-91555004_ftiklms_1/exk63si25vR803Kuz5d6/sso/saml'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Training', Icon: 'folder', disabled: 'false', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Programs', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Training Centers', Icon: 'business', external: false, link: 'training/training-centers/list'
  //             },
  //             {
  //               Label: 'Students', Icon: 'school', external: false, link: 'training/students/list'
  //             },
  //             {
  //               Label: 'Programs', Icon: 'account_balance', external: false, link: 'training/programs/list'
  //             },
  //             {
  //               Label: 'Facets', Icon: 'device_hub', external: false, link: 'training/facets/list'
  //             },
  //             {
  //               Label: 'Courses', Icon: 'explore', external: false, link: 'training/courses/list'
  //             },
  //             {
  //               Label: 'Classes', Icon: 'class', external: false, link: 'training/classes-modules/list'
  //             },
  //             {
  //               Label: 'Bulk Time Entry', Icon: 'update', external: false, link: 'training/bulk-time'
  //             },
  //             {
  //               Label: 'Transcripts', Icon: 'mail_outline', external: false, link: 'reports/training/student-transcript'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Communicate', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Distribution Lists', Icon: 'view_list', external: false, link: 'training/roster-queries'
  //             },
  //             {
  //               Label: 'Communications', Icon: 'textsms', external: false, link: 'training/communications/list'
  //             },
  //             {
  //               Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'training/survey-builder/list'
  //             },
  //             {
  //               Label: 'Templates', Icon: 'layers', external: false, link: 'training/email-templates/list'
  //             },
  //             {
  //               Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'reports/training/mailing-labels'
  //             },
  //             {
  //               Label: 'Letters', Icon: 'mail_outline', external: false, link: 'training/mail-merge-batches/list'
  //             },
  //             {
  //               Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'training/mail-merge-templates/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Options', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Course Types', Icon: 'list', external: false, link: 'select-options/training/course-types/list'
  //             },
  //             {
  //               Label: 'Facet Types', Icon: 'list', external: false, link: 'select-options/training/facet-types/list'
  //             },
  //             {
  //               Label: 'Facet Categories', Icon: 'list', external: false, link: 'select-options/training/facet-categories/list'
  //             },
  //             {
  //               Label: 'Program Types', Icon: 'list', external: false, link: 'select-options/training/program-types/list'
  //             },
  //             {
  //               Label: 'Result Types', Icon: 'list', external: false, link: 'select-options/training/result-types/list'
  //             },
  //             {
  //               Label: 'Tracking Types', Icon: 'list', external: false, link: 'select-options/training/tracking-types/list'
  //             },
  //             {
  //               Label: 'Results', Icon: 'playlist_add_check', external: false, link: 'select-options/training/results/list'
  //             },
  //             {
  //               Label: 'Student Groups', Icon: 'people', external: false, link: 'select-options/training/student-groups/list'
  //             },
  //             {
  //               Label: 'Training Statuses', Icon: 'assignment_turned_in', external: false, link: 'select-options/training/training-statuses/list'
  //             },
  //             {
  //               Label: 'Subject Areas', Icon: 'list', external: false, link: 'select-options/training/subject-areas/list'
  //             },
  //             {
  //               Label: 'Certifications', Icon: 'text_snippet', external: false, link: 'select-options/training/certifications/list'
  //             },
  //             {
  //               Label: 'Wage Types', Icon: 'list', external: false, link: 'select-options/training/wage-adjustments/list'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Support', Icon: 'folder', disabled: true, Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'User Management', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Users', Icon: 'info', external: false, link: 'users'
  //             },
  //             {
  //               Label: 'Unlinked Users', Icon: 'info', external: false, link: 'unlinked-users'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Admin Tools', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             // {
  //             //   Label: 'Help Setup', Icon: 'settings_suggest', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Add Page Help', Icon: 'queue', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Create Release Notes', Icon: 'verified', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Pathway Builder', Icon: 'settings', external: false, link: 'admin/pathways/list'
  //             },
  //             {
  //               Label: 'Roles', Icon: 'settings', external: false, link: 'admin/roles/list'
  //             },
  //             {
  //               Label: 'Type Positions', Icon: 'settings', external: false, link: 'admin/type-positions/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Help', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Get Support', Icon: 'video_library', external: true, link: 'https://engagesupport.uniontrack.com/support/home'
  //             },
  //             // {
  //             //   Label: 'Page Help', Icon: 'help', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Privacy Policy', Icon: 'attach_file', external: false, link: 'privacy-policy'
  //             },
  //             {
  //               Label: 'About', Icon: 'info', external: false, link: 'about'
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ];
  //   this.activeItems = this.itemsSuperTrainingMega;
  // }
  //
  // setUserMegaItems() {
  //   this.itemsUserMega = [
  //     {
  //       Label: 'My Account', Icon: 'dashboard', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Tools', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'My Wall', Icon: 'dashboard', external: false, link: 'wall'
  //             },
  //             // {
  //             //   Label: 'My Account/Pay', Icon: 'monetization_on', external: false, link: 'account'
  //             // },
  //             // {
  //             //   Label: 'My Auto PAT', Icon: 'card_giftcard', external: false, link: 'account'
  //             // },
  //             {
  //               Label: 'My Documents', Icon: 'content_copy', external: false, link: 'documents'
  //             },
  //             {
  //              Label: 'My Time', Icon: 'timer', external: false, link: 'my-time'
  //             },
  //             // {
  //             //   Label: 'My Voice', Icon: 'card_giftcard', external: false, link: 'civic'
  //             // },
  //             {
  //               Label: 'My Surveys', Icon: 'assignment', external: false, link: 'surveys/list'
  //             },
  //             {
  //               Label: 'My Profile', Icon: 'person', external: false, link: 'profile'
  //             },
  //             // {
  //             //   Label: 'Google Drive', Icon: 'add_to_drive', external: false, link: 'google-drive'
  //             // },
  //           ]
  //         },
  //         {
  //           Label: 'Apps', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'IMSe',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/home/oidc_client/0oaa6qlqg3s3dl8Fw5d6/aln177a159h7Zf52X0g8'
  //             },
  //             {
  //               Label: 'KLMS',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/app/dev-91555004_ftiklms_1/exk63si25vR803Kuz5d6/sso/saml'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Support', Icon: 'folder', disabled: true, Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'User Management', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Users', Icon: 'info', external: false, link: 'users'
  //             },
  //             {
  //               Label: 'Unlinked Users', Icon: 'info', external: false, link: 'unlinked-users'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Admin Tools', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             // {
  //             //   Label: 'Help Setup', Icon: 'settings_suggest', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Add Page Help', Icon: 'queue', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Create Release Notes', Icon: 'verified', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Pathway Builder', Icon: 'settings', external: false, link: 'admin/pathways/list'
  //             },
  //             {
  //               Label: 'Roles', Icon: 'settings', external: false, link: 'admin/roles/list'
  //             },
  //             {
  //               Label: 'Type Positions', Icon: 'settings', external: false, link: 'admin/type-positions/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Help', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Get Support', Icon: 'video_library', external: true, link: 'https://engagesupport.uniontrack.com/support/home'
  //             },
  //             // {
  //             //   Label: 'Page Help', Icon: 'help', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Privacy Policy', Icon: 'attach_file', external: false, link: 'privacy-policy'
  //             },
  //             {
  //               Label: 'About', Icon: 'info', external: false, link: 'about'
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ];
  //   this.activeItems = this.itemsUserMega;
  // }
  //
  // setSuperUserGroupItems() {
  //   this.itemsSuperUserGroup = [
  //     {
  //       Label: 'My Apps', Icon: 'dashboard',
  //       Items: [
  //         {
  //           Label: 'My Wall', Icon: 'dashboard', external: false, link: 'wall'
  //         },
  //         // {
  //         //   Label: 'My Account/Pay', Icon: 'monetization_on', external: false, link: 'account'
  //         // },
  //         // {
  //         //   Label: 'My Auto PAT', Icon: 'card_giftcard', external: false, link: 'account'
  //         // },
  //         {
  //           Label: 'My Documents', Icon: 'content_copy', external: false, link: 'documents'
  //         },
  //         {
  //          Label: 'My Time', Icon: 'timer', external: false, link: 'my-time'
  //         },
  //         // {
  //         //   Label: 'My Voice', Icon: 'card_giftcard', external: false, link: 'civic'
  //         // },
  //         {
  //           Label: 'My Surveys', Icon: 'assignment', external: false, link: 'surveys/list'
  //         },
  //         {
  //           Label: 'My Profile', Icon: 'person', external: false, link: 'profile'
  //         },
  //         // {
  //         //   Label: 'Google Drive', Icon: 'add_to_drive', external: false, link: 'google-drive'
  //         // },
  //         {
  //           Label: 'IMSe', Icon: 'link', external: true, link: 'https://unite.okta.com/home/oidc_client/0oaa6qlqg3s3dl8Fw5d6/aln177a159h7Zf52X0g8'
  //         },
  //         {
  //           Label: 'KLMS', Icon: 'link', external: true, link: 'https://unite.okta.com/app/dev-91555004_ftiklms_1/exk63si25vR803Kuz5d6/sso/saml'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'My Union', Icon: 'folder', disabled:'false',
  //       Items: [
  //         // {
  //         //   Label: 'Actions', Icon: 'touch_app', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Vitals', Icon: 'show_chart', external: false, link: 'membership/vitals'
  //         // },
  //         {
  //           Label: 'Organizations', Icon: 'business_center', external: false, link: 'membership/organizations/list'
  //         },
  //         {
  //           Label: 'Employers', Icon: 'store', external: false, link: 'membership/employers/list'
  //         },
  //         {
  //           Label: this.worksitesLabel, Icon: 'place', external: false, link: 'membership/worksites/list'
  //         },
  //         // {
  //         //   Label: 'Contracts', Icon: 'create', external: false, link: 'membership/contracts/list'
  //         // },
  //         // {
  //         //   Label: 'Form Tracker', Icon: 'assignment_turned_in', external: false, link: 'membership/form-tracker'
  //         // }
  //       ]
  //     },
  //     {
  //       Label: 'Communicate', Icon: 'folder', disabled:'false',
  //       Items: [
  //         {
  //           Label: 'Communications', Icon: 'textsms', external: false,
  //           Items: [
  //             {
  //               Label: 'Membership Communications', Icon: 'assignment', external: false
  //             },
  //             {
  //               Label: 'Student Communications', Icon: 'assignment', external: false
  //             }
  //             ]
  //         },
  //         {
  //           Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'membership/survey-builder/list'
  //         },
  //         {
  //           Label: 'Templates', Icon: 'layers', external: false, link: 'membership/email-templates/list'
  //         },
  //         {
  //           Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'reports/membership/mailing-labels'
  //         },
  //         {
  //           Label: 'Letters', Icon: 'mail_outline', external: false, link: 'membership/mail-merge-batches/list'
  //         },
  //         {
  //           Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'membership/mail-merge-templates/list'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Connect', Icon: 'folder', disabled:'false',
  //       Items: [
  //         {
  //           Label: 'Roster', Icon: 'view_list', external: false, link: 'membership/roster/list'
  //         },
  //         // {
  //         //   Label: 'Champions', Icon: 'emoji_events', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Merge Duplicates', Icon: 'compress', external: false, link: 'membership/merge-duplicates'
  //         // },
  //         // {
  //         //   Label: 'Bulk Updates', Icon: 'update', external: false, link: 'membership/bulk-updates'
  //         // },
  //         // {
  //         //   Label: 'Missing Data', Icon: 'report_problem', external: false, link: 'membership/missing-data'
  //         // },
  //         // {
  //         //   Label: 'Personnel File Uploads', Icon: 'format_line_spacing', external: false, link: 'membership/personnel-files/list'
  //         // },
  //         {
  //           Label: 'Roster Directory', Icon: 'text_snippet', external: false, link: 'reports/membership/roster-directory'
  //         },
  //         {
  //           Label: 'Officer Directory', Icon: 'text_snippet', external: false, link: 'membership/officer-directory'
  //         },
  //         // {
  //         //   Label: 'Member Counts', Icon: 'timeline', external: false, link: 'membership/member-counts'
  //         // },
  //         // {
  //         //   Label: 'Address Change Report', Icon: 'text_snippet', external: false, link: 'reports/membership/address-change'
  //         // }
  //       ]
  //     },
  //     // {
  //     //   Label: 'Collect', Icon: 'folder', disabled:'false',
  //     //   Items: [
  //     //     {
  //     //       Label: 'Accounts', Icon: 'monetization_on', external: false, link: 'membership/accounts/list'
  //     //     },
  //     //     {
  //     //       Label: 'Account Adjustments', Icon: 'leaderboard', external: false, link: 'membership/account-adjustments/list'
  //     //     },
  //     //     {
  //     //       Label: 'Billing Types', Icon: 'auto_awesome_mosaic', external: false, link: 'select-options/membership/billing-types/list'
  //     //     },
  //     //     {
  //     //       Label: 'Contributions', Icon: 'card_giftcard', external: false, link: 'membership/contributions'
  //     //     },
  //     //     {
  //     //       Label: 'IUPAT PAT', Icon: 'card_giftcard', external: false, link: 'wall'
  //     //     },
  //     //     {
  //     //       Label: 'Automatic Payments', Icon: 'sync', external: false, link: 'wall'
  //     //     },
  //     //     {
  //     //       Label: 'Daily Receipts Report', Icon: 'text_snippet', external: false, link: 'reports/membership/daily-receipts'
  //     //     },
  //     //     {
  //     //       Label: 'Annual Contributions', Icon: 'event_available', external: false, link: 'reports/membership/annual-contributions'
  //     //     }
  //     //   ]
  //     // },
  //     {
  //       Label: 'Events', Icon: 'folder', disabled:'false',
  //       Items: [
  //         {
  //           Label: 'Events', Icon: 'event', external: false, link: 'membership/events/list'
  //         },
  //         {
  //           Label: 'Instances', Icon: 'local_activity', external: false, link: 'membership/event-instances/list'
  //         },
  //         {
  //           Label: 'Locations', Icon: 'place', external: false, link: 'membership/event-locations/list'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Training', Icon: 'folder', disabled:'false',
  //       Items: [
  //         {
  //           Label: 'Training Centers', Icon: 'business', external: false, link: 'training/training-centers/list'
  //         },
  //         {
  //           Label: 'Students', Icon: 'school', external: false, link: 'training/students/list'
  //         },
  //         {
  //           Label: 'Programs', Icon: 'account_balance', external: false, link: 'training/programs/list'
  //         },
  //         {
  //           Label: 'Facets', Icon: 'device_hub', external: false, link: 'training/facets/list'
  //         },
  //         {
  //           Label: 'Courses', Icon: 'explore', external: false, link: 'training/courses/list'
  //         },
  //         {
  //           Label: 'Classes', Icon: 'class', external: false, link: 'training/classes-modules/list'
  //         },
  //         {
  //           Label: 'Bulk Time Entry', Icon: 'update', external: false, link: 'training/bulk-time'
  //         },
  //         {
  //           Label: 'Transcripts', Icon: 'mail_outline', external: false, link: 'reports/training/student-transcript'
  //         },
  //         {
  //           Label: 'Subject Areas', Icon: 'list', external: false, link: 'select-options/training/subject-areas/list'
  //         },
  //         {
  //           Label: 'Certifications', Icon: 'text_snippet', external: false, link: 'select-options/training/certifications/list'
  //         },
  //         {
  //           Label: 'Course Types', Icon: 'list', external: false, link: 'select-options/training/course-types/list'
  //         },
  //         {
  //           Label: 'Facet Types', Icon: 'list', external: false, link: 'select-options/training/facet-types/list'
  //         },
  //         {
  //           Label: 'Facet Categories', Icon: 'list', external: false, link: 'select-options/training/facet-categories/list'
  //         },
  //         {
  //           Label: 'Program Types', Icon: 'list', external: false, link: 'select-options/training/program-types/list'
  //         },
  //         {
  //           Label: 'Result Types', Icon: 'list', external: false, link: 'select-options/training/result-types/list'
  //         },
  //         {
  //           Label: 'Tracking Types', Icon: 'list', external: false, link: 'select-options/training/tracking-types/list'
  //         },
  //         {
  //           Label: 'Results', Icon: 'playlist_add_check', external: false, link: 'select-options/training/results/list'
  //         },
  //         {
  //           Label: 'Student Groups', Icon: 'people', external: false, link: 'select-options/training/student-groups/list'
  //         },
  //         {
  //           Label: 'Training Statuses', Icon: 'assignment_turned_in', external: false, link: 'select-options/training/training-statuses/list'
  //         },
  //         {
  //           Label: 'Wage Types', Icon: 'list', external: false, link: 'select-options/training/wage-adjustments/list'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Organize', Icon: 'folder', disabled:'false',
  //       Items: [
  //         // {
  //         //   Label: 'Action Map', Icon: 'add_location', external: false, link: 'wall'
  //         // },
  //         {
  //           Label: 'Campaigns', Icon: 'campaign', external: false, link: 'organize/campaigns/list'
  //         },
  //         {
  //           Label: 'Prospects', Icon: 'person_pin', external: false, link: 'organize/prospects'
  //         },
  //         // {
  //         //   Label: 'Non-Signed Employers', Icon: 'person_outline', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Jobs', Icon: 'engineering', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Contract Awards', Icon: 'verified', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Contract Data Uploads', Icon: 'upload_file', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Prospect Uploads', Icon: 'person_add_alt', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Action Types', Icon: 'list', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Activity Types', Icon: 'list', external: false, link: 'wall'
  //         // },
  //         {
  //           Label: 'Campaign Types', Icon: 'list', external: false, link: 'select-options/organize/campaign-types/list'
  //         },
  //         {
  //           Label: 'Campaign Statuses', Icon: 'list', external: false, link: 'select-options/organize/campaign-statuses/list'
  //         },
  //         {
  //           Label: 'Campaign Prospect Statuses', Icon: 'list', external: false, link: 'select-options/organize/campaign-prospect-statuses/list'
  //         },
  //         // {
  //         //   Label: 'Prospect Score', Icon: 'sports_score', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Employer Score', Icon: 'sports_score', external: false, link: 'wall'
  //         // }
  //       ]
  //     },
  //     {
  //       Label: 'Support', Icon: 'folder', disabled: true,
  //       Items: [
  //         {
  //           Label: 'Get Support', Icon: 'video_library', external: true, link: 'https://engagesupport.uniontrack.com/support/home'
  //         },
  //         // {
  //         //   Label: 'Page Help', Icon: 'help', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Help Setup', Icon: 'settings_suggest', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Add Page Help', Icon: 'queue', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Create Release Notes', Icon: 'verified', external: false, link: 'wall'
  //         // },
  //         {
  //           Label: 'Pathway Builder', Icon: 'settings', external: false, link: 'admin/pathways/list'
  //         },
  //         {
  //           Label: 'Roles', Icon: 'settings', external: false, link: 'admin/roles/list'
  //         },
  //         {
  //           Label: 'Type Positions', Icon: 'settings', external: false, link: 'admin/type-positions/list'
  //         },
  //         {
  //           Label: 'Privacy Policy', Icon: 'attach_file', external: false, link: 'privacy-policy'
  //         },
  //         {
  //           Label: 'About', Icon: 'info', external: false, link: 'about'
  //         },
  //         {
  //           Label: 'Users', Icon: 'info', external: false, link: 'users'
  //         }
  //       ]
  //     }
  //   ];
  //   this.activeItems = this.itemsSuperUserGroup;
  // }
  //
  // setSuperMembershipGroupItems() {
  //   this.itemsSuperMembershipGroup = [
  //     {
  //       Label: 'My Apps', Icon: 'dashboard',
  //       Items: [
  //         {
  //           Label: 'My Wall', Icon: 'dashboard', external: false, link: 'wall'
  //         },
  //         // {
  //         //   Label: 'My Account/Pay', Icon: 'monetization_on', external: false, link: 'account'
  //         // },
  //         // {
  //         //   Label: 'My Auto PAT', Icon: 'card_giftcard', external: false, link: 'account'
  //         // },
  //         {
  //           Label: 'My Documents', Icon: 'content_copy', external: false, link: 'documents'
  //         },
  //         {
  //          Label: 'My Time', Icon: 'timer', external: false, link: 'my-time'
  //         },
  //         // {
  //         //   Label: 'My Voice', Icon: 'card_giftcard', external: false, link: 'civic'
  //         // },
  //         {
  //           Label: 'My Surveys', Icon: 'assignment', external: false, link: 'surveys/list'
  //         },
  //         {
  //           Label: 'My Profile', Icon: 'person', external: false, link: 'profile'
  //         },
  //         // {
  //         //   Label: 'Google Drive', Icon: 'add_to_drive', external: false, link: 'google-drive'
  //         // },
  //         {
  //           Label: 'IMSe', Icon: 'link', external: true, link: 'https://unite.okta.com/home/oidc_client/0oaa6qlqg3s3dl8Fw5d6/aln177a159h7Zf52X0g8'
  //         },
  //         {
  //           Label: 'KLMS', Icon: 'link', external: true, link: 'https://unite.okta.com/app/dev-91555004_ftiklms_1/exk63si25vR803Kuz5d6/sso/saml'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'My Union', Icon: 'folder', disabled:'false',
  //       Items: [
  //         // {
  //         //   Label: 'Actions', Icon: 'touch_app', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Vitals', Icon: 'show_chart', external: false, link: 'membership/vitals'
  //         // },
  //         {
  //           Label: 'Organizations', Icon: 'business_center', external: false, link: 'membership/organizations/list'
  //         },
  //         {
  //           Label: 'Employers', Icon: 'store', external: false, link: 'membership/employers/list'
  //         },
  //         {
  //           Label: this.worksitesLabel, Icon: 'place', external: false, link: 'membership/worksites/list'
  //         },
  //         // {
  //         //   Label: 'Contracts', Icon: 'create', external: false, link: 'membership/contracts/list'
  //         // },
  //         // {
  //         //   Label: 'Form Tracker', Icon: 'assignment_turned_in', external: false, link: 'membership/form-tracker'
  //         // }
  //       ]
  //     },
  //     {
  //       Label: 'Communicate', Icon: 'folder', disabled:'false',
  //       Items: [
  //         {
  //           Label: 'Distribution Lists', Icon: 'view_list', external: false, link: 'membership/roster-queries'
  //         },
  //         {
  //           Label: 'Communications', Icon: 'textsms', link: 'membership/communications/list', external: false,
  //         },
  //         {
  //           Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'membership/survey-builder/list'
  //         },
  //         {
  //           Label: 'Templates', Icon: 'layers', external: false, link: 'membership/email-templates/list'
  //         },
  //         {
  //           Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'reports/membership/mailing-labels'
  //         },
  //         {
  //           Label: 'Letters', Icon: 'mail_outline', external: false, link: 'membership/mail-merge-batches/list'
  //         },
  //         {
  //           Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'membership/mail-merge-templates/list'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Connect', Icon: 'folder', disabled:'false',
  //       Items: [
  //         {
  //           Label: 'Roster', Icon: 'view_list', external: false, link: 'membership/roster/list'
  //         },
  //         // {
  //         //   Label: 'Champions', Icon: 'emoji_events', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Merge Duplicates', Icon: 'compress', external: false, link: 'membership/merge-duplicates'
  //         // },
  //         // {
  //         //   Label: 'Bulk Updates', Icon: 'update', external: false, link: 'membership/bulk-updates'
  //         // },
  //         // {
  //         //   Label: 'Missing Data', Icon: 'report_problem', external: false, link: 'membership/missing-data'
  //         // },
  //         // {
  //         //   Label: 'Personnel File Uploads', Icon: 'format_line_spacing', external: false, link: 'membership/personnel-files/list'
  //         // },
  //         {
  //           Label: 'Roster Directory', Icon: 'text_snippet', external: false, link: 'reports/membership/roster-directory'
  //         },
  //         {
  //           Label: 'Officer Directory', Icon: 'text_snippet', external: false, link: 'membership/officer-directory'
  //         },
  //         // {
  //         //   Label: 'Member Counts', Icon: 'timeline', external: false, link: 'membership/member-counts'
  //         // },
  //         // {
  //         //   Label: 'Address Change Report', Icon: 'text_snippet', external: false, link: 'reports/membership/address-change'
  //         // }
  //       ]
  //     },
  //     // {
  //     //   Label: 'Collect', Icon: 'folder', disabled:'false',
  //     //   Items: [
  //     //     {
  //     //       Label: 'Accounts', Icon: 'monetization_on', external: false, link: 'membership/accounts/list'
  //     //     },
  //     //     {
  //     //       Label: 'Account Adjustments', Icon: 'leaderboard', external: false, link: 'membership/account-adjustments/list'
  //     //     },
  //     //     {
  //     //       Label: 'Billing Types', Icon: 'auto_awesome_mosaic', external: false, link: 'select-options/membership/billing-types/list'
  //     //     },
  //     //     {
  //     //       Label: 'Contributions', Icon: 'card_giftcard', external: false, link: 'membership/contributions'
  //     //     },
  //     //     {
  //     //       Label: 'IUPAT PAT', Icon: 'card_giftcard', external: false, link: 'wall'
  //     //     },
  //     //     {
  //     //       Label: 'Automatic Payments', Icon: 'sync', external: false, link: 'wall'
  //     //     },
  //     //     {
  //     //       Label: 'Daily Receipts Report', Icon: 'text_snippet', external: false, link: 'reports/membership/daily-receipts'
  //     //     },
  //     //     {
  //     //       Label: 'Annual Contributions', Icon: 'event_available', external: false, link: 'reports/membership/annual-contributions'
  //     //     }
  //     //   ]
  //     // },
  //     {
  //       Label: 'Events', Icon: 'folder', disabled:'false',
  //       Items: [
  //         {
  //           Label: 'Events', Icon: 'event', external: false, link: 'membership/events/list'
  //         },
  //         {
  //           Label: 'Instances', Icon: 'local_activity', external: false, link: 'membership/event-instances/list'
  //         },
  //         {
  //           Label: 'Locations', Icon: 'place', external: false, link: 'membership/event-locations/list'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Support', Icon: 'folder', disabled: true,
  //       Items: [
  //         {
  //           Label: 'Get Support', Icon: 'video_library', external: true, link: 'https://engagesupport.uniontrack.com/support/home'
  //         },
  //         // {
  //         //   Label: 'Page Help', Icon: 'help', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Help Setup', Icon: 'settings_suggest', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Add Page Help', Icon: 'queue', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Create Release Notes', Icon: 'verified', external: false, link: 'wall'
  //         // },
  //         {
  //           Label: 'Pathway Builder', Icon: 'settings', external: false, link: 'admin/pathways/list'
  //         },
  //         {
  //           Label: 'Roles', Icon: 'settings', external: false, link: 'admin/roles/list'
  //         },
  //         {
  //           Label: 'Type Positions', Icon: 'settings', external: false, link: 'admin/type-positions/list'
  //         },
  //         {
  //           Label: 'Privacy Policy', Icon: 'attach_file', external: false, link: 'privacy-policy'
  //         },
  //         {
  //           Label: 'About', Icon: 'info', external: false, link: 'about'
  //         },
  //         {
  //           Label: 'Users', Icon: 'info', external: false, link: 'users'
  //         }
  //       ]
  //     }
  //   ];
  //   this.activeItems = this.itemsSuperMembershipGroup;
  // }
  //
  // setSuperTrainingGroupItems() {
  //   this.itemsSuperTrainingGroup = [
  //     {
  //       Label: 'My Apps', Icon: 'dashboard',
  //       Items: [
  //         {
  //           Label: 'My Wall', Icon: 'dashboard', external: false, link: 'wall'
  //         },
  //         // {
  //         //   Label: 'My Account/Pay', Icon: 'monetization_on', external: false, link: 'account'
  //         // },
  //         // {
  //         //   Label: 'My Auto PAT', Icon: 'card_giftcard', external: false, link: 'account'
  //         // },
  //         {
  //           Label: 'My Documents', Icon: 'content_copy', external: false, link: 'documents'
  //         },
  //         {
  //          Label: 'My Time', Icon: 'timer', external: false, link: 'my-time'
  //         },
  //         // {
  //         //   Label: 'My Voice', Icon: 'card_giftcard', external: false, link: 'civic'
  //         // },
  //         {
  //           Label: 'My Surveys', Icon: 'assignment', external: false, link: 'surveys/list'
  //         },
  //         {
  //           Label: 'My Profile', Icon: 'person', external: false, link: 'profile'
  //         },
  //         // {
  //         //   Label: 'Google Drive', Icon: 'add_to_drive', external: false, link: 'google-drive'
  //         // },
  //         {
  //           Label: 'IMSe', Icon: 'link', external: true, link: 'https://unite.okta.com/home/oidc_client/0oaa6qlqg3s3dl8Fw5d6/aln177a159h7Zf52X0g8'
  //         },
  //         {
  //           Label: 'KLMS', Icon: 'link', external: true, link: 'https://unite.okta.com/app/dev-91555004_ftiklms_1/exk63si25vR803Kuz5d6/sso/saml'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Training', Icon: 'folder', disabled:'false',
  //       Items: [
  //         {
  //           Label: 'Training Centers', Icon: 'business', external: false, link: 'training/training-centers/list'
  //         },
  //         {
  //           Label: 'Students', Icon: 'school', external: false, link: 'training/students/list'
  //         },
  //         {
  //           Label: 'Programs', Icon: 'account_balance', external: false, link: 'training/programs/list'
  //         },
  //         {
  //           Label: 'Facets', Icon: 'device_hub', external: false, link: 'training/facets/list'
  //         },
  //         {
  //           Label: 'Courses', Icon: 'explore', external: false, link: 'training/courses/list'
  //         },
  //         {
  //           Label: 'Classes', Icon: 'class', external: false, link: 'training/classes-modules/list'
  //         },
  //         {
  //           Label: 'Bulk Time Entry', Icon: 'update', external: false, link: 'training/bulk-time'
  //         },
  //         {
  //           Label: 'Transcripts', Icon: 'mail_outline', external: false, link: 'reports/training/student-transcript'
  //         },
  //         {
  //           Label: 'Subject Areas', Icon: 'list', external: false, link: 'select-options/training/subject-areas/list'
  //         },
  //         {
  //           Label: 'Certifications', Icon: 'text_snippet', external: false, link: 'select-options/training/certifications/list'
  //         },
  //         {
  //           Label: 'Course Types', Icon: 'list', external: false, link: 'select-options/training/course-types/list'
  //         },
  //         {
  //           Label: 'Facet Types', Icon: 'list', external: false, link: 'select-options/training/facet-types/list'
  //         },
  //         {
  //           Label: 'Facet Categories', Icon: 'list', external: false, link: 'select-options/training/facet-categories/list'
  //         },
  //         {
  //           Label: 'Program Types', Icon: 'list', external: false, link: 'select-options/training/program-types/list'
  //         },
  //         {
  //           Label: 'Result Types', Icon: 'list', external: false, link: 'select-options/training/result-types/list'
  //         },
  //         {
  //           Label: 'Tracking Types', Icon: 'list', external: false, link: 'select-options/training/tracking-types/list'
  //         },
  //         {
  //           Label: 'Results', Icon: 'playlist_add_check', external: false, link: 'select-options/training/results/list'
  //         },
  //         {
  //           Label: 'Student Groups', Icon: 'people', external: false, link: 'select-options/training/student-groups/list'
  //         },
  //         {
  //           Label: 'Training Statuses', Icon: 'assignment_turned_in', external: false, link: 'select-options/training/training-statuses/list'
  //         },
  //         {
  //           Label: 'Wage Types', Icon: 'list', external: false, link: 'select-options/training/wage-adjustments/list'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Support', Icon: 'folder', disabled: true,
  //       Items: [
  //         {
  //           Label: 'Get Support', Icon: 'video_library', external: true, link: 'https://engagesupport.uniontrack.com/support/home'
  //         },
  //         // {
  //         //   Label: 'Page Help', Icon: 'help', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Help Setup', Icon: 'settings_suggest', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Add Page Help', Icon: 'queue', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Create Release Notes', Icon: 'verified', external: false, link: 'wall'
  //         // },
  //         {
  //           Label: 'Pathway Builder', Icon: 'settings', external: false, link: 'admin/pathways/list'
  //         },
  //         {
  //           Label: 'Roles', Icon: 'settings', external: false, link: 'admin/roles/list'
  //         },
  //         {
  //           Label: 'Type Positions', Icon: 'settings', external: false, link: 'admin/type-positions/list'
  //         },
  //         {
  //           Label: 'Privacy Policy', Icon: 'attach_file', external: false, link: 'privacy-policy'
  //         },
  //         {
  //           Label: 'About', Icon: 'info', external: false, link: 'about'
  //         },
  //         {
  //           Label: 'Users', Icon: 'info', external: false, link: 'users'
  //         }
  //       ]
  //     }
  //   ];
  //   this.activeItems = this.itemsSuperTrainingGroup;
  // }
  //
  // setUserGroupItems() {
  //   this.itemsUserGroup = [
  //     {
  //       Label: 'My Apps', Icon: 'dashboard',
  //       Items: [
  //         {
  //           Label: 'My Wall', Icon: 'dashboard', external: false, link: 'wall'
  //         },
  //         // {
  //         //   Label: 'My Account/Pay', Icon: 'monetization_on', external: false, link: 'account'
  //         // },
  //         // {
  //         //   Label: 'My Auto PAT', Icon: 'card_giftcard', external: false, link: 'account'
  //         // },
  //         {
  //           Label: 'My Documents', Icon: 'content_copy', external: false, link: 'documents'
  //         },
  //         {
  //          Label: 'My Time', Icon: 'timer', external: false, link: 'my-time'
  //         },
  //         // {
  //         //   Label: 'My Voice', Icon: 'card_giftcard', external: false, link: 'civic'
  //         // },
  //         {
  //           Label: 'My Surveys', Icon: 'assignment', external: false, link: 'surveys/list'
  //         },
  //         {
  //           Label: 'My Profile', Icon: 'person', external: false, link: 'profile'
  //         },
  //         // {
  //         //   Label: 'Google Drive', Icon: 'add_to_drive', external: false, link: 'google-drive'
  //         // },
  //         {
  //           Label: 'IMSe', Icon: 'link', external: true, link: 'https://unite.okta.com/home/oidc_client/0oaa6qlqg3s3dl8Fw5d6/aln177a159h7Zf52X0g8'
  //         },
  //         {
  //           Label: 'KLMS', Icon: 'link', external: true, link: 'https://unite.okta.com/app/dev-91555004_ftiklms_1/exk63si25vR803Kuz5d6/sso/saml'
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Support', Icon: 'folder', disabled: true,
  //       Items: [
  //         {
  //           Label: 'Get Support', Icon: 'video_library', external: true, link: 'https://engagesupport.uniontrack.com/support/home'
  //         },
  //         // {
  //         //   Label: 'Page Help', Icon: 'help', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Help Setup', Icon: 'settings_suggest', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Add Page Help', Icon: 'queue', external: false, link: 'wall'
  //         // },
  //         // {
  //         //   Label: 'Create Release Notes', Icon: 'verified', external: false, link: 'wall'
  //         // },
  //         {
  //           Label: 'Pathway Builder', Icon: 'settings', external: false, link: 'admin/pathways/list'
  //         },
  //         {
  //           Label: 'Roles', Icon: 'settings', external: false, link: 'admin/roles/list'
  //         },
  //         {
  //           Label: 'Type Positions', Icon: 'settings', external: false, link: 'admin/type-positions/list'
  //         },
  //         {
  //           Label: 'Privacy Policy', Icon: 'attach_file', external: false, link: 'privacy-policy'
  //         },
  //         {
  //           Label: 'About', Icon: 'info', external: false, link: 'about'
  //         },
  //         {
  //           Label: 'Users', Icon: 'info', external: false, link: 'users'
  //         }
  //       ]
  //     }
  //   ];
  //   this.activeItems = this.itemsUserGroup;
  // }
  //
  // setSuperIupatMegaItems() {
  //   this.itemsSuperUserMega = [
  //     {
  //       Label: 'My Account', Icon: 'dashboard', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Tools', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'My Wall', Icon: 'dashboard', external: false, link: 'wall'
  //             },
  //             {
  //               Label: 'My Documents', Icon: 'content_copy', external: false, link: 'documents'
  //             },
  //             {
  //              Label: 'My Time', Icon: 'timer', external: false, link: 'my-time'
  //             },
  //             // {
  //             //   Label: 'My Voice', Icon: 'card_giftcard', external: false, link: 'civic'
  //             // },
  //             {
  //               Label: 'My Surveys', Icon: 'assignment', external: false, link: 'surveys/list'
  //             },
  //             {
  //               Label: 'My Profile', Icon: 'person', external: false, link: 'profile'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Apps', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'IMSe',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/home/oidc_client/0oaa6qlqg3s3dl8Fw5d6/aln177a159h7Zf52X0g8'
  //             },
  //             {
  //               Label: 'KLMS',
  //               Icon: 'link',
  //               external: true,
  //               link: 'https://unite.okta.com/app/dev-91555004_ftiklms_1/exk63si25vR803Kuz5d6/sso/saml'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Membership', Icon: 'dashboard', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'My Union', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Organizations', Icon: 'business_center', external: false, link: 'membership/organizations/list'
  //             },
  //             {
  //               Label: 'Employers', Icon: 'store', external: false, link: 'membership/employers/list'
  //             },
  //             {
  //               Label: this.worksitesLabel, Icon: 'place', external: false, link: 'membership/worksites/list'
  //             },
  //             {
  //               Label: 'Contracts', Icon: 'create', external: false, link: 'membership/contracts/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Communicate', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Distribution Lists', Icon: 'view_list', external: false, link: 'membership/roster-queries'
  //             },
  //             {
  //               Label: 'Communications', Icon: 'textsms', external: false, link: 'membership/communications/list'
  //             },
  //             {
  //               Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'membership/survey-builder/list'
  //             },
  //             {
  //               Label: 'Templates', Icon: 'layers', external: false, link: 'membership/email-templates/list'
  //             },
  //             {
  //               Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'reports/membership/mailing-labels'
  //             },
  //             {
  //               Label: 'Letters', Icon: 'mail_outline', external: false, link: 'membership/mail-merge-batches/list'
  //             },
  //             {
  //               Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'membership/mail-merge-templates/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Connect', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Roster', Icon: 'view_list', external: false, link: 'membership/roster/list'
  //             },
  //             {
  //               Label: 'Roster Directory', Icon: 'text_snippet', external: false, link: 'reports/membership/roster-directory'
  //             },
  //             {
  //               Label: 'Officer Directory', Icon: 'text_snippet', external: false, link: 'membership/officer-directory'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Events', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Events', Icon: 'event', external: false, link: 'membership/events/list'
  //             },
  //             {
  //               Label: 'Instances', Icon: 'local_activity', external: false, link: 'membership/event-instances/list'
  //             },
  //             {
  //               Label: 'Locations', Icon: 'place', external: false, link: 'membership/event-locations/list'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Training', Icon: 'folder', disabled: 'false', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Programs', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Training Centers', Icon: 'business', external: false, link: 'training/training-centers/list'
  //             },
  //             {
  //               Label: 'Students', Icon: 'school', external: false, link: 'training/students/list'
  //             },
  //             {
  //               Label: 'Programs', Icon: 'account_balance', external: false, link: 'training/programs/list'
  //             },
  //             {
  //               Label: 'Facets', Icon: 'device_hub', external: false, link: 'training/facets/list'
  //             },
  //             {
  //               Label: 'Courses', Icon: 'explore', external: false, link: 'training/courses/list'
  //             },
  //             {
  //               Label: 'Classes', Icon: 'class', external: false, link: 'training/classes-modules/list'
  //             },
  //             {
  //               Label: 'Bulk Time Entry', Icon: 'update', external: false, link: 'training/bulk-time'
  //             },
  //             {
  //               Label: 'Transcripts', Icon: 'mail_outline', external: false, link: 'reports/training/student-transcript'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Communicate', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Distribution Lists', Icon: 'view_list', external: false, link: 'training/roster-queries'
  //             },
  //             {
  //               Label: 'Communications', Icon: 'textsms', external: false, link: 'training/communications/list'
  //             },
  //             {
  //               Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'training/survey-builder/list'
  //             },
  //             {
  //               Label: 'Templates', Icon: 'layers', external: false, link: 'training/email-templates/list'
  //             },
  //             {
  //               Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'reports/training/mailing-labels'
  //             },
  //             {
  //               Label: 'Letters', Icon: 'mail_outline', external: false, link: 'training/mail-merge-batches/list'
  //             },
  //             {
  //               Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'training/mail-merge-templates/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Options', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             {
  //               Label: 'Course Types', Icon: 'list', external: false, link: 'select-options/training/course-types/list'
  //             },
  //             {
  //               Label: 'Facet Types', Icon: 'list', external: false, link: 'select-options/training/facet-types/list'
  //             },
  //             {
  //               Label: 'Facet Categories', Icon: 'list', external: false, link: 'select-options/training/facet-categories/list'
  //             },
  //             {
  //               Label: 'Program Types', Icon: 'list', external: false, link: 'select-options/training/program-types/list'
  //             },
  //             {
  //               Label: 'Result Types', Icon: 'list', external: false, link: 'select-options/training/result-types/list'
  //             },
  //             {
  //               Label: 'Tracking Types', Icon: 'list', external: false, link: 'select-options/training/tracking-types/list'
  //             },
  //             {
  //               Label: 'Results', Icon: 'playlist_add_check', external: false, link: 'select-options/training/results/list'
  //             },
  //             {
  //               Label: 'Student Groups', Icon: 'people', external: false, link: 'select-options/training/student-groups/list'
  //             },
  //             {
  //               Label: 'Training Statuses', Icon: 'assignment_turned_in', external: false, link: 'select-options/training/training-statuses/list'
  //             },
  //             {
  //               Label: 'Subject Areas', Icon: 'list', external: false, link: 'select-options/training/subject-areas/list'
  //             },
  //             {
  //               Label: 'Certifications', Icon: 'text_snippet', external: false, link: 'select-options/training/certifications/list'
  //             },
  //             {
  //               Label: 'Wage Types', Icon: 'list', external: false, link: 'select-options/training/wage-adjustments/list'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Organize', Icon: 'folder', disabled: 'false', Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'Strategies', Icon: 'folder', disabled: 'false',
  //           Items: [
  //             //{
  //             //  Label: 'Action Map', Icon: 'add_location', external: false, link: 'wall'
  //             //},
  //             {
  //               Label: 'Campaigns', Icon: 'campaign', external: false, link: 'organize/campaigns/list'
  //             },
  //             {
  //               Label: 'Prospects', Icon: 'person_pin', external: false, link: 'organize/prospects/list'
  //             },
  //             //{
  //             //  Label: 'Non-Signed Employers', Icon: 'person_outline', external: false, link: 'wall'
  //             //},
  //             //{
  //             //  Label: 'Jobs', Icon: 'engineering', external: false, link: 'wall'
  //             //},
  //             //{
  //             //  Label: 'Contract Awards', Icon: 'verified', external: false, link: 'wall'
  //             //},
  //             //{
  //             //  Label: 'Contract Data Uploads', Icon: 'upload_file', external: false, link: 'wall'
  //             //},
  //             // {
  //             //   Label: 'Prospect Uploads', Icon: 'person_add_alt', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Prospect Score', Icon: 'sports_score', external: false, link: 'wall'
  //             // },
  //             //{
  //             //  Label: 'Employer Score', Icon: 'sports_score', external: false, link: 'wall'
  //             //}
  //           ]
  //         },
  //         {
  //           Label: 'Communicate', Icon: 'folder', disabled: 'false',
  //           Items: [
  //     {
  //       Label: 'Distribution Lists', Icon: 'view_list', external: false, link: 'organize/roster-queries'
  //     },
  //             {
  //               Label: 'Communications', Icon: 'textsms', external: false, link: 'organize/communications/list'
  //             },
  //             {
  //               Label: 'Survey Builder', Icon: 'assignment', external: false, link: 'organize/survey-builder/list'
  //             },
  //             {
  //               Label: 'Templates', Icon: 'layers', external: false, link: 'organize/email-templates/list'
  //             },
  //             {
  //               Label: 'Mailing Labels', Icon: 'dynamic_feed', external: false, link: 'reports/organize/mailing-labels'
  //             },
  //             {
  //               Label: 'Letters', Icon: 'mail_outline', external: false, link: 'organize/mail-merge-batches/list'
  //             },
  //             {
  //               Label: 'Letter Templates', Icon: 'mark_as_unread', external: false, link: 'organize/mail-merge-templates/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Options', Icon: 'folder', disabled: 'false',
  //           Items: [
  //            // {
  //            //   Label: 'Action Types', Icon: 'list', external: false, link: 'wall'
  //            // },
  //            // {
  //            //   Label: 'Activity Types', Icon: 'list', external: false, link: 'wall'
  //            // },
  //            {
  //              Label: 'Campaign Types', Icon: 'list', external: false, link: 'select-options/organize/campaign-types/list'
  //            },
  //            {
  //              Label: 'Campaign Statuses', Icon: 'list', external: false, link: 'select-options/organize/campaign-statuses/list'
  //            },
  //            {
  //              Label: 'Campaign Prospect Statuses', Icon: 'list', external: false, link: 'select-options/organize/campaign-prospect-statuses/list'
  //            }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       Label: 'Support', Icon: 'folder', disabled: true, Type: this.styleType,
  //       Items: [
  //         {
  //           Label: 'User Management', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Users', Icon: 'info', external: false, link: 'users'
  //             },
  //             {
  //               Label: 'Unlinked Users', Icon: 'info', external: false, link: 'unlinked-users'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Admin Tools', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             // {
  //             //   Label: 'Help Setup', Icon: 'settings_suggest', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Add Page Help', Icon: 'queue', external: false, link: 'wall'
  //             // },
  //             // {
  //             //   Label: 'Create Release Notes', Icon: 'verified', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Pathway Builder', Icon: 'settings', external: false, link: 'admin/pathways/list'
  //             },
  //             {
  //               Label: 'Roles', Icon: 'settings', external: false, link: 'admin/roles/list'
  //             },
  //             {
  //               Label: 'Type Positions', Icon: 'settings', external: false, link: 'admin/type-positions/list'
  //             }
  //           ]
  //         },
  //         {
  //           Label: 'Help', Icon: 'dashboard', Type: this.styleType,
  //           Items: [
  //             {
  //               Label: 'Get Support', Icon: 'video_library', external: true, link: 'https://engagesupport.uniontrack.com/support/home'
  //             },
  //             // {
  //             //   Label: 'Page Help', Icon: 'help', external: false, link: 'wall'
  //             // },
  //             {
  //               Label: 'Privacy Policy', Icon: 'attach_file', external: false, link: 'privacy-policy'
  //             },
  //             {
  //               Label: 'About', Icon: 'info', external: false, link: 'about'
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ];
  //   this.activeItems = this.itemsSuperUserMega;
  // }
}
