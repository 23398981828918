
@if (accountAdjustments) {
  <div class="p-grid p-fluid">
    <!--  <div class="col-md-12 col-sm-12 col-xs-12">-->
    <!--    <p-radioButton value="balance" name="viewType" [styleClass]="'radio-input'" class="p-mr-2" label="View Balance Adjustments" [(ngModel)]="viewType" ></p-radioButton>-->
    <!--    <p-radioButton value="paidThrough" name="viewType" [styleClass]="'radio-input'" class="p-mr-2" label="View Paid Through"  [(ngModel)]="viewType" ></p-radioButton>-->
  <!--  </div>-->
  <!--    <div class="col-md-3 col-sm-12 col-xs-12 filter-item-padding">-->
  <!--      <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="setOrganizationData($event)">-->
<!--      </app-select>-->
<!--    </div>-->
@if (viewType === 'paidThrough') {
  <div class="col-md-12 col-sm-12 col-xs-12" ><h3>Step 1: Update Paid Through Date</h3></div>
}
<div class="col-md-12 col-sm-12 col-xs-12">
  <div class="p-grid p-fluid">
    <div class="col-md-9 col-sm-12 col-xs-12 margin-top-15">
      <label>Memo<sup class="req">*</sup></label>
      <textarea pInputTextarea [(ngModel)]="accountAdjustments.Memo" maxlength="300"></textarea>
    </div>
  </div>
</div>
@if (viewType === 'paidThrough') {
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">
    <div class="p-fluid p-grid">
      <div class="col-md-3 col-sm-2 col-xs-2 margin-top-10">
        <div class="p-grid p-fluid">
          <div class="col-md-12 col-sm-12 col-xs-12"><b>Current Paid Through Date</b></div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p-calendar [(ngModel)]="tempCurrentDate" appendTo="body" placeholder="mm/yyyy" view="month" dateFormat="mm/yy" [showIcon]="true" yearRange="1950:2025" disabled="true" [defaultDate]="todaysDate" ></p-calendar>
          </div>
        </div>
      </div>
      @if (accountAdjustments.UpdatePTD) {
        <div class="col-md-3 col-sm-2 col-xs-2 margin-top-10">
          <div class="p-grid p-fluid">
            <div class="col-md-12 col-sm-12 col-xs-12"><b>Adjusted Paid Through Date</b></div>
            <div class="col-md-12 col-sm-12 col-xs-12">
              <p-calendar [(ngModel)]="tempAdjustedDate" appendTo="body" placeholder="mm/yyyy" view="month" dateFormat="mm/yy" [showIcon]="true" yearRange="1950:2030" [defaultDate]="todaysDate"></p-calendar>
            </div>
          </div>
        </div>
      }
      @if (!accountAdjustments.UpdatePTD) {
        <div class="col-md-3 col-sm-2 col-xs-2 margin-top-10">
          <div class="p-grid p-fluid">
            <div class="col-md-12 col-sm-12 col-xs-12"><b>Adjusted Paid Through Date</b></div>
            <div class="col-md-12 col-sm-12 col-xs-12">
              <input pInputText [disabled]="true" value="System Calculated">
            </div>
          </div>
        </div>
      }
      <div class="col-md-3 col-sm-2 col-xs-2 margin-top-25">
        <p-checkbox binary="true" label="Force Paid Through Date" [(ngModel)]="accountAdjustments.UpdatePTD"></p-checkbox>
      </div>
    </div>
  </div>
}
@if (viewType === 'balance') {
  @if(!this._simplifiedPaymentAdjustment()) {
    <div class="col-md-12 col-sm-12 col-xs-12" ><h3>Step 2: Update Account Balance
      @if (currentMemberOrganizationId === organizationId) {
        <span (click)="viewType='paidThrough'; clearBalance()" class="pointer" style="color: #607d8b">(Back to Step 1)</span>
      }</h3></div>
    }
  }
  @if (viewType === 'balance') {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">*Negative Amount indicates unapplied credit on Member's Account</div>
  }
  @if (viewType === 'balance') {
    <div class="col-md-9 col-sm-12 col-xs-12">
      @if (accountAdjustments) {
        <p-table
          #dt1
          [rows]="pageRowCountUtility.pageRowCount()"
          [columns]="cols"
          dataKey="BillingAdjustmentID"
          [value]="accountAdjustments.BalanceAdjustmentList"
          [lazy]="true"
          [paginator]="false"
          [showCurrentPageReport]="true"
          [metaKeySelection]="false"
          [rowsPerPageOptions]="[25,50,100,250,500]"
          rowExpandMode="single"
          paginatorDropdownAppendTo="body"
          [loading]="!accountAdjustments"
          styleClass="p-datatable-responsive-demo p-datatable-striped margin-top-10"
          >
          <ng-template pTemplate="header" let-columns>
            <tr>
              @for (col of columns; track col) {
                <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
                  @if (col.visible) {
                    <div class="header-text">
                      {{col.header}}
                    </div>
                  }
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
            <tr [pSelectableRow]="rowData">
              @for (col of columns; track col) {
                <td class="text-align-right">
                  @if (col.field === 'BillingAdjustmentDescription') {
                    {{rowData[col.field]}}
                  }
                  @if (col.field === 'CurrentAmountOwed') {
                    <!--              $ <input type="number"  class="text-align-right" [style]="'width:75px;'" [(ngModel)]="rowData.CurrentAmountOwed" disabled="true" />-->
                    <app-currency-input [initValue]="rowData.CurrentAmountOwed" [disabled]="true"></app-currency-input>
                  }
                  @if (col.field === 'AdjustedAmountOwed') {
                    <div class="p-grid p-fluid full-width text-align-left">
                      <div class="col-md-8 col-sm-6 col-xs-12 margin-top-8 ">
                        <p-checkbox  binary="true" label="Update Amount Owed " [(ngModel)]="rowData.UpdateAmountOwed"></p-checkbox>
                      </div>
                      <div class="col-md-4 col-sm-6 col-xs-12 text-align-right">
                        <!--                  $<input type="number" [style]="'width:75px;'" class="text-align-right" [(ngModel)]="rowData.AdjustedAmountOwed" [disabled]="!rowData.UpdateAmountOwed" />-->
                        <app-currency-input [initValue]="rowData.AdjustedAmountOwed" [disabled]="!rowData.UpdateAmountOwed" (emitData)="rowData.AdjustedAmountOwed = $event"></app-currency-input>
                      </div>
                    </div>
                  }
                </td>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td><b>Totals</b></td>
              <!--          <td><input class="full-width text-align-right" [value]="getTotalCurrent()" disabled="true" /></td>-->
              <!--          <td><input class="full-width text-align-right" [value]="getTotalAdjustment()" disabled="true" /></td>-->
              <td> <app-currency-input [initValue]="getTotalCurrent()" [disabled]="true"></app-currency-input></td>
              <td> <app-currency-input [initValue]="getTotalAdjustment()" [disabled]="true"></app-currency-input></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="cols.length">No events found.</td>
            </tr>
          </ng-template>
        </p-table>
      }
    </div>
  }
  <div class="p-grid p-fluid full-width">
    <div class="col-md-9 col-sm-12 col-xs-12 text-align-right margin-top-10" >
      @if (!isSaving) {
        <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
          (click)="saveData()" [className]="'inline-button'"
        [disabled]="!isValid()"></button>
      } @else {
        <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
          <i class="pi pi-spin pi-spinner save-spinner"></i>
        </button>
      }
    </div>
  </div>
</div>
}
