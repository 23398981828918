import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';
import {PiiValidateReturnModel} from '../../../models/pii-validate.model';
import {Router} from '@angular/router';


@Component({
  selector: 'app-pii-duplicate',
  templateUrl: './pii-duplicate-dialog.component.html',
  styleUrls: ['./pii-duplicate-dialog.component.scss']
})

export class PiiDuplicateDialogComponent implements OnInit, OnDestroy {
  piiData: PiiValidateReturnModel;
  value: string;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private router: Router) {
  }

  ngOnInit(): void {
    this.piiData = this.config.data.piiData;
    this.value = this.config.data.value;
    console.log(this.piiData);
    this.piiData.MatchList.forEach(match => {
      match.ContextList.forEach(context => {
        if (context.HasAccess) {
          match.HasAccess = true;
        }
      });
    });
    switch (this.piiData.MatchType) {
      case 'SSN' : {
        this.value = this.value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
        break;
      }
      case 'SIN' : {
        this.value = this.value.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  goToProfile(id) {
    this.ref.close();
    this.router.navigateByUrl('/profiles/edit/' + id);
  }

  closeDialog(res?) {
    this.ref.close(res);
    this.ref.destroy();
  }
}
