import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ContextService} from '../../../../../services/context.service';
import {ProgramStudentTimeCollectionReject} from '../../../../../models/program-student-time-collection.model';
import {ProgramStudentTimeCollectionsService} from '../../../../../services/program-student-time-collection.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-time-reject-dialog',
  templateUrl: './time-reject-dialog.component.html',
  styleUrls: ['./time-reject-dialog.component.scss']
})

export class TimeRejectDialogComponent implements OnInit, OnDestroy {
  isSaving: boolean;
  rejectionReason: string;
  rejectedData = {} as ProgramStudentTimeCollectionReject;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, private contextService: ContextService, public ref: DynamicDialogRef,
              private programStudentTimeCollectionsService: ProgramStudentTimeCollectionsService,
              private messageService: MessageService) {
  }

  get messageCount() {
    if (this.rejectionReason) {
      return 265 - this.rejectionReason.length;
    } else {
      return 265;
    }
  }

  ngOnInit(): void {
    this.rejectedData = this.config.data.rejectedData;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  closeDialog() {
    this.ref.destroy();
    this.ref.destroy();
  }

  cancel() {
    this.closeDialog();
  }

  processData() {
    this.rejectedData.RejectionReason = this.rejectionReason;
    this.saveData();
  }

  saveData() {
    this.programStudentTimeCollectionsService.rejectProgramStudentTimeCollection(this.rejectedData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Rejection was sent'
          });
          this.cancel();
        }, error:
          (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            console.warn(e);
          }
      });
  }
}
