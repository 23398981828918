@if (invalidRecord) {
  <app-record-not-found></app-record-not-found>
}
@if (reload && !invalidRecord) {
  <div class="content p-input-filled margin-top-10">
    <div class="p-grid p-fluid">
      @if (isPage && !isDialog) {
        <div class="col-md-12 col-sm-12 col-xs-12 add-edit-header add-display-flex">
          @if (!invalidRecord && isPage && !isDialog) {
            <div class="inline-back-button" (click)="back()" pTooltip="Back">
              <i class="pi pi-angle-left"></i>
            </div>
          }
          @if (organization.Status !== 'Active') {
            <div class="inactive-flag-hard-corner"></div>
          }
          @if (organization.LogoUrl) {
            <div class="p-pl-5 tab-container-profile">
              <div class="org-avatar-with-image"
                   [ngClass]="organization.Status === 'Active' ? 'active-status-org' :'inactive-status-org'"><img
                ngSrc="{{organization.LogoUrl}}" (error)="setDefaultPic()" alt="" width="86" height="56"
                [priority]="true"/>
              </div>
            </div>
          }
          <div class="p-grid p-fluid">
            <div class="col-md-9 col-sm-12 col-xs-12">
              <div class="list-header p-pl-5">
                @if (isEdit && organization) {
                  <h2 class="p-mt-2">@if (organization.Abbreviation && organization.Abbreviation !== '') {
                    <span>{{ organization.Abbreviation }} - </span>
                  }
                    @if (organization.Name) {
                      <span>{{ organization.Name }}</span>
                    }</h2>
                }
                @if (isEdit && organization) {
                  <h4>
                    @if (organization.City && organization.StateProvince) {
                      <span>{{ organization.City }}, {{ organization.StateProvince }} | </span>
                    }<b>Status:</b> {{ organization.Status }} | <b>Active
                    Members:</b> {{ organization.ActiveRosterCount_N | number:'1.0':'en-US' }}
                    @if (organization.RegisteredCount_N && organization.RegisteredCount_N > 0) {
                      <span> | <b>Registered Users:</b> {{ organization.RegisteredCount_N | number:'1.0':'en-US' }}</span>
                    }
                    @if (organization.PhoneNumber) {
                      <span> | <b>Phone:</b> {{ organization.PhoneNumber | phone }}</span>
                    }
                    @if (organization.CharterDate) {
                      <span> | <b>Chartered:</b> {{ organization.CharterDate | date: 'shortDate' }}</span>
                    }
                  </h4>
                }
                @if (!isEdit) {
                  <h2>Add Organization</h2>
                }
              </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 text-align-right">
              <div class="list-container list-container-header">
                <div class="list-action-buttons p-mr-2">
                  <app-organization-action-buttons [canPageWrite]="canPageWrite" [organization]="organization"></app-organization-action-buttons>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12">
        @if (!isPage || isDialog) {
          <p-divider></p-divider>
        }
        @if (showTabs) {
          <p-tabView (onChange)="resetWarning()">
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabContact')) {
              <p-tabPanel header="Contact" [selected]="_tabsPermissionsUtility.checkTabRead(permissions,'tabContact')">
                <ng-template pTemplate="content">
                  <app-organizations-tab-details [ID]="ContentID" [isEdit]="isEdit"
                                                 [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabContact')"></app-organizations-tab-details>
                </ng-template>
              </p-tabPanel>
            }
            <!--        <p-tabPanel header="Social Media" *ngIf="_tabsPermissionsUtility.checkTabRead(permissions,'tabSocial')" [disabled]="!isEdit">-->
            <!--          TODO-->
            <!--        </p-tabPanel>-->
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabRSSFeeds')) {
              <p-tabPanel header="RSS Feeds" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-organizations-tab-rss-feeds
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabRSSFeeds')"
                    [ID]="ContentID" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabRSSFeeds')"
                    [interfaceObjectDesc]="'tabRSSFeeds'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-organizations-tab-rss-feeds>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabIRSForms')) {
              <p-tabPanel header="Remit Forms" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-organizations-tab-tax-forms
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabIRSForms')"
                    [ID]="ContentID" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabIRSForms')"
                    [interfaceObjectDesc]="'tabIRSForms'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-organizations-tab-tax-forms>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPositions')) {
              <p-tabPanel header="Positions" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-organization-positions-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabPositions')"
                    [organizationId]="ContentID" [organizationCategory]="'union'"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabPositions')"
                    [showFinancialContact]="true" [interfaceObjectDesc]="'tabPositions'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-organization-positions-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDocs')) {
              <p-tabPanel header="Docs" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-docs-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                                [DBEntity]="'organization'" [DBEntityID]="ContentID"
                                [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDocs')"
                                [showAccess]="true" [InterfaceObjectEnum]="InterfaceObjectEnum"
                                [interfaceObjectDesc]="'tabDocs'"
                                [parentObjectEnum]="InterfaceObjectEnum"></app-docs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabNotes')) {
              <p-tabPanel header="Notes" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-notes-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                                 [DBEntity]="'organization'" [DBEntityID]="ContentID"
                                 [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [canEdit]="_tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [interfaceObjectDesc]="'tabNotes'"
                                 [parentObjectEnum]="InterfaceObjectEnum"></app-notes-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabConfig')) {
              <p-tabPanel header="Configure" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-organization-configure-tab [OrganizationID]="ContentID" [orgCategory]="'union'" [isEdit]="isEdit"
                                                  [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabConfig')"
                                                  [interfaceObjectDesc]="'tabConfig'"
                                                  [parentObjectEnum]="InterfaceObjectEnum"></app-organization-configure-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabOrgVis')) {
              <p-tabPanel header="Visibility" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-organizations-tab-visibility [ID]="ContentID" [isEdit]="isEdit"
                                                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabOrgVis')"
                                                    [interfaceObjectDesc]="'tabOrgVis'"
                                                    [parentObjectEnum]="InterfaceObjectEnum"></app-organizations-tab-visibility>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabRosterCustomFields')) {
              <p-tabPanel header="Manage Custom Fields" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-organization-attributes-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabRosterCustomFields')"
                    [ResponseDBEntityEnumeratorOptions]="true" [OrgRefID]="OrgRefID"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabRosterCustomFields')"
                    [interfaceObjectDesc]="'tabRosterCustomFields'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-organization-attributes-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCustomFields')) {
              <p-tabPanel header="Custom Fields" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-custom-fields-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabCustomFields')"
                    [OrgRefID]="OrgRefID"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabCustomFields')"
                    [category]="'union'" [isEdit]="isEdit" [interfaceObjectDesc]="'tabCustomFields'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-custom-fields-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabRosterChangeLog')) {
              <p-tabPanel header="Roster Change Log" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-organizations-tab-roster-change-log [ID]="ContentID" [isEdit]="isEdit"
                                                           [interfaceObjectDesc]="'tabRosterChangeLog'"
                                                           [parentObjectEnum]="InterfaceObjectEnum"></app-organizations-tab-roster-change-log>
                </ng-template>
              </p-tabPanel>
            }
          </p-tabView>
        }
      </div>
    </div>
  </div>
}
