@if (!loading) {
  <div>
    <div>
      <div class="p-grid p-fluid margin-top-10">
        <div class="col-md-2 col-sm-12 col-xs-12">
          <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                          [parentObjectEnum]="parentObjectEnum"></app-learn-more>
          <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
            <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <h3>Filter</h3>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
              <label>Name</label>
              <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter"
                     (keyup.enter)="filterItems()" tabindex="0"/>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
              <div class="list-filter-buttons text-align-right">
                <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"
                        [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clearFilters()">
                </button>
                <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"
                        (click)="filterItems()">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
          <div class="p-grid p-fluid">
            <h2 class="col-md-3 col-sm-4 col-xs-6">{{ dataTotal | number:'1.0':'en-US' }} @if (dataTotal === 1) {
              Result
            } @else {
              Results
            }</h2>
            <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
              <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal"
                           (onPageChange)="paginate($event)"></p-paginator>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 action-buttons">
              @if ((currentFilter.filters | json) !== '{}') {
                <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                        pTooltip="Clear Filter" tooltipPosition="bottom"
                        [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                        (click)="clearFilters()">
                </button>
              }
              <button pButton (click)="refresh()" icon="pi pi-refresh"
                      pTooltip="Refresh" tooltipPosition="bottom"></button>
              @if (canAdd) {
                <button pButton (click)="showAddEdit = true"
                        icon="pi pi-plus" pTooltip="Add"
                        tooltipPosition="bottom"></button>
              }
              @if (!isExporting) {
                <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                        icon="fa fa-file-excel"
                        pTooltip="Export" tooltipPosition="bottom" (click)="export()">
                </button>
              } @else {
                <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                        icon="pi pi-spin pi-spinner save-spinner">
                </button>
              }
            </div>
            <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
              <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal"
                           (onPageChange)="paginate($event)"></p-paginator>
            </div>
          </div>
          <div class="list-container p-grid p-fluid">
            @if (showAddEdit) {
              <div class="col-md-12 col-sm-12 col-xs-12">
                <p-card header="Add Staff to Class" [styleClass]="'list-card'">
                  <app-class-staff-form [ClassID]="ClassID" [isEdit]="false"
                                        (closeAddEdit)="updateList()"></app-class-staff-form>
                </p-card>
              </div>
            }
            @if (loading) {
              <span class="fa fa-spinner list-spinner"></span>
            }
            @for (staff of StaffList; track staff; let i = $index) {
              <div class="col-md-12">
                <p-card [styleClass]="'list-card'">
                  <div class="p-grid p-fluid">
                    <div class="col-md-1 col-sm-2 col-xs-12 relative pointer center-profile profile-container"
                         (click)="expandData(i, staff.ClassStaffID)">
                      <div class="avatar-without-image active-status-no-image"><img
                        ngSrc="assets/images/profile-generic.png" alt="" height="71" width="71" [priority]="true"/>
                      </div>
                    </div>
                    <div class="col-md-7 col-sm-8 col-xs-8 pointer" (click)="expandData(i, staff.ClassStaffID)">
                      <div class="list-header">
                        {{ staff.FirstName }} {{ staff.LastName }}
                      </div>
                      <div class="list-subheader">
                        @if (staff.PrimaryInstructor === true) {
                          <span>Primary</span>
                        }
                      </div>
                    </div>
                    <div class="right-column col-md-4 col-sm-12 col-xs-12">
                      <div class="list-action-buttons">
                        <div class="icon hide-on-mobile" pTooltip="Edit" tooltipPosition="bottom"
                             (click)="openStaff(staff.PersonID)"><i class="fas fa-user-edit"></i></div>
                        @if (canTabWrite && staff.CanDelete) {
                          <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                               (click)="delete(ClassID, staff.ClassStaffID)"><i class="fas fa-trash-alt"></i></div>
                        }
                        <div class="icon" (click)="expandData(i, staff.ClassStaffID)">
                          @if (this.selectedItem === i) {
                            <i class="fas fa-arrow-circle-up" pTooltip="Hide"></i>
                          } @else {
                            <i class="fas fa-arrow-circle-down" pTooltip="Show"></i>
                          }
                        </div>
                      </div>
                    </div>
                    @if (selectedItem === i) {
                      <div class="full-width margin-top-10">
                        <app-class-staff-form [ClassID]="ClassID" [ClassStaffID]="staff.ClassStaffID" [isEdit]="true"
                                              [canTabWrite]="staff.CanEdit && canTabWrite" (closeAddEdit)="updateList()"
                                              class="no-padding-container"></app-class-staff-form>
                      </div>
                    }
                  </div>
                </p-card>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
