<app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                [parentObjectEnum]="parentObjectEnum"></app-learn-more>
<div class="p-grid p-fluid p-mb-2">
  <div class="col-md-7 col-sm-6 col-xs-12">
    <p>*Due to the volume of data, logs are updated nightly. To include changes from today, manually synchronize.</p>
  </div>
  <div class="col-md-5 col-sm-6 col-xs-12 action-buttons">
    <button pButton (click)="synchronize()" icon="fas fa-recycle" pTooltip="Synchronize"
            tooltipPosition="bottom"></button>
    <button pButton (click)="refresh()" icon="pi pi-refresh" pTooltip="Refresh" tooltipPosition="bottom"></button>
    @if (!isExporting) {
      <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
              icon="fa fa-file-excel"
              pTooltip="Export" tooltipPosition="bottom" (click)="export()">
      </button>
    } @else {
      <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
              icon="pi pi-spin pi-spinner save-spinner">
      </button>
    }
  </div>
</div>
<!--<span class="fa fa-spinner list-spinner" *ngIf="loading"></span>-->
<p-table
  #dt1
  [rows]="currentFilter.rows"
  [columns]="cols"
  [value]="changeLogsList"
  [lazy]="true"
  (onLazyLoad)="loadTable($event)"
  [paginator]="true"
  [showCurrentPageReport]="true"
  [metaKeySelection]="false"
  [totalRecords]="this.dataTotal"
  [rowsPerPageOptions]="[25,50,100,250,500]"
  [scrollable]="true"
  selectionMode="single"
  scrollHeight="400px"
  paginatorDropdownAppendTo="body"
  [loading]="!dataLoaded"
  styleClass="p-datatable-responsive-demo p-datatable-striped table-border"
  currentPageReportTemplate="Showing {first} to {last} of {{this.dataTotal | number}} entries"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      @for (col of columns; track col) {
        <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom"
            style="min-width: 14.28%;">
          <div class="header-text">
            {{ col.header }}
          </div>
          <div class="header-actions">
            <p-sortIcon [field]="col.field"></p-sortIcon>
            @if (col.columnType !== 'picker') {
              <p-columnFilter type="{{col.columnType}}" matchMode="{{col.matchMode}}" [field]="col.field" display="menu"
                              [showOperator]="false" [showAddButton]="false"></p-columnFilter>
            }
          </div>
        </th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      @for (col of columns; track col) {
        <td style="min-width: 14.28%;">
          @if (col.visible) {
            @switch (col.displayType) {
              @case ('date') {
                {{ rowData[col.field] | date: 'shortDate' }}
              }
              @case ('text') {
                {{ rowData[col.field] }}
              }
            }
          }
        </td>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="cols?.length" style="width: 100%;">No change logs found.</td>
    </tr>
  </ng-template>
</p-table>
