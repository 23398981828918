<div class="p-grid p-fluid">
  @if (currentWageRate) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <p-card header="Current Wage Rate">
        {{currentWageRate.ProgramWageRateDescription}} | {{currentWageRate.CalculatedWageRate | currency}}
      </p-card>
    </div>
  }
  @if (studentWageRates) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-20">
      <p-table #dt1
        [rows]="pageRowCountUtility.pageRowCount()"
        [columns]="cols"
        dataKey="PersonID"
        [value]="studentWageRates"
        [lazy]="true"
        [paginator]="true"
        [showCurrentPageReport]="true"
        [metaKeySelection]="false"
        [totalRecords]="dataTotal"
        [rowsPerPageOptions]="[25,50,100,250,500]"
        [scrollable]="true"
        selectionMode="multiple"
        scrollHeight="400px"
        paginatorDropdownAppendTo="body"
        styleClass="p-datatable-gridlines p-datatable-striped"
        currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
        >
        <ng-template pTemplate="header" let-columns>
          <tr>
            @for (col of columns; track col) {
              <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
                @if (col.visible) {
                  <div class="header-text">
                    {{col.header}}
                  </div>
                }
              </th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            @for (col of columns; track col) {
              <td>
                @if (col.visible && col.displayType === 'date') {
                  {{rowData[col.field] | date: 'shortDate'}}
                }
                @if (col.visible && col.displayType === 'text') {
                  {{rowData[col.field]}}
                }
                @if (col.visible && col.displayType === 'currency') {
                  @if (rowData[col.field] < 0) {
                    <span class="negative"> {{rowData[col.field] | currency:'USD':'symbol':'1.2-2'}}</span>
                  }
                  @if (rowData[col.field] === 0) {
                    <span> {{rowData[col.field] | currency:'USD':'symbol':'1.2-2'}}</span>
                  }
                  @if (rowData[col.field] > 0) {
                    <span class="positive">{{rowData[col.field] | currency:'USD':'symbol':'1.2-2'}}</span>
                  }
                }
              </td>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [colSpan]="cols?.length" style="width: 100%;">No Wage Rates found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }
</div>
