import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {PointModel, PointsGridModel} from '../../../models/points.model';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {RosterProfileService} from '../../../services/roster-profile.service';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-roster-tab-points',
  templateUrl: './roster-tab-points.component.html',
  styleUrls: ['./roster-tab-points.component.scss']
})
export class RosterTabPointsComponent implements OnInit, OnChanges, OnDestroy {
  myPointTotal: number = 0;
  points: PointsGridModel;
  pointsList: PointModel[] = [];
  dataLoaded: boolean;
  totalLoaded: boolean;
  isExporting: boolean;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  dataTotal: number = 0;
  @Input() PersonID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private dialogUtility: DialogUtility, public dialogService: DialogService,
              private rosterProfileService: RosterProfileService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.getData();
  }

  getData(): void {
    this.getTotal();
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.pointsList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.rosterProfileService.getRosterProfilePointsPrimeNg('DATA', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.points = res;
          this.pointsList = res.Data;
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.rosterProfileService.getRosterProfilePointsPrimeNg('TOTAL', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          this.totalLoaded = true;
        }
      });
  }

  getTotal(): void {
    this.myPointTotal = 0;
    this.rosterProfileService.getRosterProfilePointsTotal(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.myPointTotal = res.LifetimePoints;
        }
      });
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.loadList();
  }

  filterItems(): void {
    this.dataLoaded = false;
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('Description', this.activeGlobalFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.Description;
    }
    // if (this.startDateFilter && this.startDateFilter !== '') {
    //   this.initFilters('PointsDate_D', this.startDateFilter, 'contains', 'and');
    // } else {
    //   delete this.currentFilter.filters.PointsDate_D;
    // }
    // if (this.endDateFilter && this.endDateFilter !== '') {
    //   this.initFilters('PointsDate_D', this.endDateFilter, 'contains', 'and');
    // } else {
    //   delete this.currentFilter.filters.PointsDate_D;
    // }
    this.loadList();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    // this.startDateFilter = null;
    // this.endDateFilter = null;
    this.loadList();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  loadList(): void {
    this.getData();
  }

  refresh(): void {
    this.getData();
  }

  resetComponent(): void {
    this.pointsList = undefined;
    this.points = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.pointsList));
        const dataToExport = removeKeysFromArray(arr, []);
        exportData('points', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.rosterProfileService.getRosterProfilePointsPrimeNg('DATA', this.PersonID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, []);
              exportData('points', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
