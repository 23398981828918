<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    @if (canTabWrite) {
      <div class="col-md-12 col-sm-12 col-xs-12 nopadding">
        <div class="list-filter-buttons">
          <button pButton class="p-button-download export-menu" icon="pi pi-refresh" label="Reset"
                  (click)="resetWorkflow()">
          </button>
        </div>
      </div>
    }
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3>Filter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <label>Step</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="activeGlobalFilter" class="full-width filter" pInputText
               tabindex="0" type="text"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <label>Outcome</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="outcome" class="full-width filter" pInputText tabindex="0"
               type="text"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <label>Key Outcome</label>
        <app-yes-no-select (emitData)="keyOutcome = $event" [isBoolean]="true" [nullable]="true"
                           [selectedValue]="keyOutcome"></app-yes-no-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <label>Close Branch</label>
        <app-yes-no-select (emitData)="closeBranch = $event" [isBoolean]="true" [nullable]="true"
                           [selectedValue]="closeBranch"></app-yes-no-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <label>Next Step(s)</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="nextSteps" class="full-width filter" pInputText tabindex="0"
               type="text"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 nopadding">
        <div class="list-filter-buttons text-align-right">
          <button (click)="clearFilters()" [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" class="p-button-download export-menu" icon="pi pi-times"
                  label="Clear" pButton>
          </button>
          <button (click)="filterItems()" class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  pButton>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12">@if (workflows) {
          <span>{{ dataTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (workflows) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        @if ((currentFilter.filters | json) !== '{}') {
          <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                  pTooltip="Clear Filter" tooltipPosition="bottom"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                  (click)="clearFilters()">
          </button>
        }
        <button (click)="refresh()" icon="pi pi-refresh" pButton
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canAdd) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (workflowList) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Outcome" [styleClass]="'list-card'">
          <app-workflow-step-outcomes-form [workflowID]="WorkflowID" [isEdit]="false" [canTabWrite]="canTabWrite"
                                           (closeAddEdit)="updateList($event)"></app-workflow-step-outcomes-form>
        </p-card>
      </div>
    }
    @if (totalLoaded && dataTotal <= 0 && !showAddEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (workflow of workflowList; track workflow; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i)">
                  <div class="list-header">
                    {{ workflow.WorkflowStepDescription }} - {{ workflow.Description }}
                  </div>
                  <div class="list-subheader">
                    @if (workflow.KeyOutcome === true) {
                      <span><i class="fas fa-exclamation"></i> Key Outcome</span>
                    }
                    @if (workflow.KeyOutcome === true) {
                      <span> | </span>
                    }
                    @if (workflow.CloseBranch === true) {
                      <span><i class="fas fa-times-octagon"></i> Close Branch</span>
                    }
                    @if (workflow.KeyOutcome === true || workflow.CloseBranch === true) {
                      <span> | </span>
                    }
                    @if (workflow.NextStepDescription) {
                      <span><b>Next Step(s):</b> </span>
                    }
                    @if (workflow.NextStepDescription) {
                      <span [innerHTML]="workflow.NextStepDescription"></span>
                    }
                  </div>
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    @if (canTabWrite && workflow.ShowUpArrow) {
                      <div class="icon" pTooltip="Reorder Up" tooltipPosition="bottom"
                           (click)="reorderAttribute(workflowList[i-1].WorkflowStepOutcomeID, workflow.WorkflowStepOutcomeID)">
                        <i class="fas fa-sort-up"></i></div>
                    }
                    @if (canTabWrite && workflow.ShowDownArrow) {
                      <div class="icon" pTooltip="Reorder Down" tooltipPosition="bottom"
                           (click)="reorderAttribute(workflow.WorkflowStepOutcomeID, workflowList[i+1].WorkflowStepOutcomeID)">
                        <i class="fas fa-sort-down"></i></div>
                    }
                    @if (canTabWrite && workflow.CanDelete) {
                      <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                           (click)="deleteAttribute(WorkflowID, workflow.WorkflowStepOutcomeID)"><i
                        class="fas fa-trash-alt"></i></div>
                    }
                    <div class="icon" (click)="expandData(i)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                  </div>
                </div>
                @if (selectedItem === i) {
                  <div class="full-width margin-top-10">
                    <app-workflow-step-outcomes-form [workflowID]="WorkflowID"
                                                     [WorkflowStepOutcomeId]="workflow.WorkflowStepOutcomeID"
                                                     [isEdit]="true" [canTabWrite]="canTabWrite"
                                                     (closeAddEdit)="updateList($event)"></app-workflow-step-outcomes-form>
                  </div>
                }
              </div>
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
