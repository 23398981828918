<div class="page-container">
  <div class="parent-p-card">
    <p-card>
      <div class="p-card-title">
        <i class="fas fa-file-alt"></i> Membership Cards
      </div>
      <div class="margin-top-10">
        <app-learn-more [interfaceObjectTypeEnum]="'APP'" [interfaceObjectDesc]="InterfaceObjectEnum"
                        [report]="true"></app-learn-more>
      </div>
      <div class="p-grid p-fluid">
        @if (!isDialog) {
          <div class="p-col-12 p-sm-12 p-md-6">
            <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="setOrganizationData($event)">
            </app-select>
          </div>
        }
        @if (!isDialog) {
          <div class="p-col-12 p-sm-12 p-md-6">
            <app-dist-list-select class="full-width" [inputObj]="inputObjRosterQuery"
                                  (emitData)="setRosterQueryData($event)">
            </app-dist-list-select>
          </div>
        }
        @if (!isDialog && showTable) {
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p-table
              #dt1
              [rows]="pageRowCountUtility.pageRowCount()"
              [columns]="cols"
              dataKey="PersonID"
              [value]="membershipCardsList"
              [lazy]="true"
              (onLazyLoad)="loadTable($event)"
              [paginator]="true"
              [showCurrentPageReport]="true"
              [metaKeySelection]="false"
              [totalRecords]="dataTotal"
              [rowsPerPageOptions]="[25,50,100,250,500]"
              [scrollable]="true"
              [(selection)]="selMembershipCardUser"
              selectionMode="multiple"
              scrollHeight="400px"
              paginatorDropdownAppendTo="body"
              [loading]="loading"
              styleClass="p-datatable-responsive-demo p-datatable-striped inner-table"
              currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
              (selectionChange)="setInputData()"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th [style]="{width: '50px'}">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  @for (col of columns; track col) {
                    <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom"
                        style="width: 150px">
                      <div class="header-text">
                        {{ col.header }}
                      </div>
                      <div class="header-actions">
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                        @if (col.columnType !== 'picker') {
                          <p-columnFilter type="{{col.columnType}}" matchMode="{{col.matchMode}}" [field]="col.field"
                                          display="menu" [showOperator]="false"
                                          [showAddButton]="false"></p-columnFilter>
                        }
                      </div>
                    </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
                <tr>
                  <td [style]="{width: '50px'}">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  @for (col of columns; track col) {
                    <td style="min-width: 150px">
                      @if (col.columnType !== 'date' && !col.field.includes('Phone')) {
                        {{ rowData[col.field] }}
                      }
                      @if (col.columnType === 'date') {
                        {{ rowData[col.field] | date: 'shortDate' }}
                      }
                      @if (col.field.includes('Phone')) {
                        {{ rowData[col.field] | phone }}
                      }
                    </td>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [colSpan]="cols?.length" style="width: 100%;">No people found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }
        @if ((setOrganizationId && personIDList?.length > 0) || isDialog) {
          <div class="p-col-12">
            <app-telerik-report-viewer [isDownload]="!isDialog" [inputData]="inputData"
                                       [autoRun]="isDialog"></app-telerik-report-viewer>
          </div>
        }
      </div>
    </p-card>
  </div>
</div>
