<form class="margin-top-10" [formGroup]="mainForm">
  <p-divider class="dark-divider"></p-divider>
  <div class="p-grid p-fluid">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <p-checkbox binary="true" label="Primary Employer" formControlName="primaryDues" tabindex="0"></p-checkbox>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjEmployer" (emitData)="getEmployerData($event)" ></app-select>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjWorkSite" (emitData)="getWorkSiteData($event)" ></app-select>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <label>Start Date <sup class="req">*</sup></label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="startDate" appendTo="body" placeholder="mm/dd/yyyy" [maxDate]="mainForm.controls.endDate.value" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
      @if ((mainForm.controls.startDate.dirty || mainForm.controls.startDate.touched) &&
        mainForm.controls.startDate.errors?.required) {
        <span class="error">'Start Date' must be provided</span>
      }
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <label>End Date</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="endDate" appendTo="body" placeholder="mm/dd/yyyy" [minDate]="mainForm.controls.startDate.value" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <label>Job Title</label>
      <input type="text" pInputText  formControlName="jobTitle" tabindex="0">
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjWorkLevel" (emitData)="getWorkLevelData($event)" ></app-select>
    </div>
    <!--    <div class="col-md-6 col-sm-6 col-xs-12 input-container">-->
    <!--      <app-select class="full-width" [inputObj]="inputObjJobTitle" (emitData)="getJobTitleData($event)" ></app-select>-->
  <!--    </div>-->
  <div class="col-md-6 col-sm-6 col-xs-12 input-container">
    <app-select class="full-width" [inputObj]="inputObjTrade" (emitData)="getTradeData($event)" ></app-select>
  </div>
  <!--    <div class="col-md-6 col-sm-6 col-xs-12 input-container">-->
  <!--      <app-select class="full-width" [inputObj]="inputObjContract" (emitData)="getContractData($event)" ></app-select>-->
<!--    </div>-->
<!--    <div class="col-md-6 col-sm-6 col-xs-12 input-container">-->
<!--      <label>Employee Identification Number (EIN)</label>-->
<!--      <input type="text" pInputText  formControlName="employmentEIN">-->
<!--    </div>-->
<div class="col-md-6 col-sm-6 col-xs-12 input-container">
  <app-select class="full-width" [inputObj]="inputObjShift" (emitData)="getShiftData($event)" ></app-select>
</div>
<div class="col-md-6 col-sm-6 col-xs-12 p-mb-2">
  <label>Dispatch Rate</label>
  <!--      <p-inputNumber formControlName="dispatchRate" class="full-width"  [showButtons]="true" mode="currency" currency="USD" locale="en-US"></p-inputNumber>-->
  <app-currency-input [initValue]="mainForm.get('dispatchRate').value" (emitData)="mainForm.get('dispatchRate').setValue($event)" ></app-currency-input>
</div>
<div class="col-md-12 col-sm-12 col-xs-12 input-container">
  <label>Comments</label>
  <textarea pInputTextarea formControlName="comments" rows="5" autoResize="autoResize" [readonly]="!canTabWrite" tabindex="0"></textarea>
</div>
@if (canTabWrite) {
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="p-grid p-fluid">
      <div class="col-md-6 col-sm-6 col-xs-12"></div>
      <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
        @if (isEdit) {
          <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
          (click)="cancel()" [disabled]="!mainForm.dirty"></button>
        } @else {
          <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
          (click)="cancel()"></button>
        }
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
            (click)="processData()" [className]="'inline-button'"
          [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
        } @else {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  </div>
}
</div>
</form>
