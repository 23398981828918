import {Component, OnDestroy, OnInit} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import { ResourcesService } from '../../../../services/resources.service';
import {StudentGradeCertificateModel, StudentGradesByCourseModel} from '../../../../models/class-students.model';
import { ClassStudentsService } from '../../../../services/class-students.service';
import {transformDateTimeToDateOnly} from '../../../../shared/utilities/form.utility';
import { LookupsDataService } from '../../../../services/lookups-data.service';
import { MessageService } from 'primeng/api';

@Component({
  templateUrl: './set-all-to-pass-dialog.component.html',
  styleUrls: ['./set-all-to-pass-dialog.component.scss']
})

export class SetAllToPassDialogComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  incomingGrade = {} as StudentGradesByCourseModel;
  outgoingGrade = {} as StudentGradesByCourseModel;
  isSaving: boolean = false;
  CognitiveLevels;
  passFail;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private resourcesService: ResourcesService,
              private messageService: MessageService, private lookupsDataService: LookupsDataService) {
  }

  ngOnInit(): void {
    this.incomingGrade = this.config.data.grade;

    this.outgoingGrade.CognitiveLevelDescription = this.incomingGrade.CognitiveLevelDescription;
    this.outgoingGrade.HasCertification = this.incomingGrade.HasCertification;
    this.outgoingGrade.Passed = true;
    this.getCognitiveLevels();
    this.getPassFail();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getCognitiveLevels() {
    this.lookupsDataService.getCognitiveLevelsLookupData().then((lookupData) => {
      this.CognitiveLevels = lookupData;
      console.log(this.CognitiveLevels);
    });
  }

  getPassFail() {
    this.lookupsDataService.getPassFailLookupData().then((lookupData) => {
      this.passFail = lookupData;
    });
  }

  updatePassFail() {
    this.outgoingGrade.Passed = this.outgoingGrade.ResultID === 1 ? true : false;
  }

  processData() {
    this.ref.close(this.outgoingGrade);
  }

  closeDialog() {
    this.ref.close();
  }

  cancel() {
    this.ref.close();
  }

}
