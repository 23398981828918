import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {
  BulkUpdateProgramStudentTimeCollectionModel,
  PeriodStatusCount,
  ProgramStatusTimeCollectionApprove,
  ProgramStudentTimeCollectionByCategory,
  ProgramStudentTimeCollectionByFacetModel,
  ProgramStudentTimeCollectionModel,
  ProgramStudentTimeCollectionReject,
  ProgramStudentTimeCollectionStudentPeriodsGridModel,
  ProgramStudentTimeCollectionUpdateStatus,
  UpdateCreateProgramStudentTimeCollectionModelByCategory,
  UpdateProgramStudentTimeCollectionModel
} from '../models/program-student-time-collection.model';
import {PrimeTableFilterModel} from '../models/table-filter.model';

@Injectable({
  providedIn: 'root',
})
export class ProgramStudentTimeCollectionsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getProgramStudentTimeCollectionStudentPeriodsPrimeNG(dataMode: string, personId: number, filterData): Observable<ProgramStudentTimeCollectionStudentPeriodsGridModel> {
    return this.http.post<ProgramStudentTimeCollectionStudentPeriodsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/studentperiodgrid/byperson/${personId}?mode=${dataMode}`, filterData);
  }

  getProgramStudentTimeCollectionStudentPeriod(programStudentId: number, facetTypeId: number, periodStartDate: string): Observable<ProgramStudentTimeCollectionModel> {
    return this.http.get<ProgramStudentTimeCollectionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/period?facettypeid=${facetTypeId}&periodstart=${periodStartDate}`);
  }

  getProgramStudentTimeCollectionByFacet(programStudentId: number, facetTypeId: number, periodStartDate: string): Observable<ProgramStudentTimeCollectionByCategory[]> {
    return this.http.get<ProgramStudentTimeCollectionByCategory[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/programstudentfacet/list/byfacettype/${facetTypeId}?periodStartDate=${periodStartDate}`);
  }

  getProgramStudentTimeCollectionByFacetType(programStudentId: number): Observable<ProgramStudentTimeCollectionByFacetModel[]> {
    return this.http.get<ProgramStudentTimeCollectionByFacetModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programstudent/${programStudentId}/facettypes`);
  }

  updateProgramStudentTimeCollectionStudentPeriod(programStudentId: number, modelData: UpdateProgramStudentTimeCollectionModel): Observable<ProgramStudentTimeCollectionModel> {
    return this.http.put<ProgramStudentTimeCollectionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/period`, modelData);
  }

  createProgramStudentTimeCollectionStudentFacet(modelData: UpdateCreateProgramStudentTimeCollectionModelByCategory): Observable<UpdateCreateProgramStudentTimeCollectionModelByCategory> {
    return this.http.post<UpdateCreateProgramStudentTimeCollectionModelByCategory>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudentfacet`, modelData);
  }

  deleteProgramStudentTimeCollectionStudentFacet(programStudentFacetId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudentfacet/${programStudentFacetId}`);
  }

  updateProgramStudentTimeCollectionStudentFacet(programStudentFacetID: number, modelData: UpdateCreateProgramStudentTimeCollectionModelByCategory): Observable<UpdateCreateProgramStudentTimeCollectionModelByCategory> {
    return this.http.put<UpdateCreateProgramStudentTimeCollectionModelByCategory>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudentfacet/${programStudentFacetID}`, modelData);
  }

  updateProgramStudentTimeCollectionStatus(modelData: ProgramStudentTimeCollectionUpdateStatus): Observable<ProgramStudentTimeCollectionUpdateStatus> {
    return this.http.put<ProgramStudentTimeCollectionUpdateStatus>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/studentperiod/updatestatus`, modelData);
  }

  rejectProgramStudentTimeCollection(modelData: ProgramStudentTimeCollectionReject): Observable<ProgramStudentTimeCollectionReject> {
    return this.http.put<ProgramStudentTimeCollectionReject>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/studentperiod/updatestatus`, modelData);
  }

  approveProgramStudentTimeCollection(modelData: ProgramStatusTimeCollectionApprove): Observable<ProgramStatusTimeCollectionApprove> {
    return this.http.put<ProgramStatusTimeCollectionApprove>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/studentperiod/updatestatus`, modelData);
  }

  getPeriodStatusCounts(programStudentId: number, cached?: boolean): Observable<PeriodStatusCount[]> {
    return this.http.get<PeriodStatusCount[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/periodstatuscounts?cached=${cached}`);
  }

  getProgramStudentListByPeriodPrimeNG(dataMode: string, programFacetTypeId: number, periodStart: string, intervalStart: string, dataFilter: PrimeTableFilterModel): Observable<any> {
    return this.http.post<ProgramStudentTimeCollectionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programfacettype/${programFacetTypeId}/periodintervalstudentgrid?mode=${dataMode}&periodstart=${periodStart}&intervalStart=${periodStart}`, dataFilter);
  }

  // getProgramStudentTimeCollectionStudentsKGrid(programFacetTypeId: number, periodStartDate: string, intervalStart?: string, studentGroupId?: number):Observable<ProgramStudentTimeCollectionStudentGridModel> {
  //   let url: string = '';
  //   if (intervalStart) {
  //     url = `&intervalstart=${intervalStart}`;
  //   }
  //   if (studentGroupId) {
  //     url += `&studentgroupid=${studentGroupId}`;
  //   }
  //   return this.http.get<ProgramStudentTimeCollectionStudentGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programfacettype/${programFacetTypeId}/periodintervalstudentgrid?periodstart=${periodStartDate}${url}`);
  // }

  updateBulkProgramStudentTimeCollection(modelData: BulkUpdateProgramStudentTimeCollectionModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/bulkcollectionupdate`, modelData);
  }

  updateBulkProgramStudentTimeCollectionSingleFacet(modelData: BulkUpdateProgramStudentTimeCollectionModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/bulkcollectionupdate/singlefacet`, modelData);
  }

  updateBulkProgramStudentTimeCollectionMultipleFacet(modelData: BulkUpdateProgramStudentTimeCollectionModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/bulkcollectionupdate/multifacet`, modelData);
  }

  resetProgramStudentPeriod(programStudentId: number, periodId: number, status: string): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/period/resetstatus?programstudentid=${programStudentId}&periodid=${periodId}&status=${status}`, null);
  }
}
