import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { WorkflowStepBankGridModel, WorkflowStepBankModel, CreateUpdateWorkflowStepBankModel } from '../models/workflow-step-bank.model';

@Injectable({
  providedIn: 'root',
})
export class WorkflowStepBankService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getWorkflowStepBankPrimeNG(dataMode: string, category: string, organizationId: number, filterData): Observable<WorkflowStepBankGridModel> {
    return this.http.post<WorkflowStepBankGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/bycategory/${category}/byorganization/${organizationId}/stepbankgrid?mode=${dataMode}`, filterData);
  }

  getWorkflowStepBank(category: string, organizationId: number, workflowStepId: number): Observable<WorkflowStepBankModel> {
    return this.http.get<WorkflowStepBankModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/bycategory/${category}/byorganization/${organizationId}/step/${workflowStepId}`);
  }

  createWorkflowStepBank(category: string, organizationId: number, modelData: CreateUpdateWorkflowStepBankModel): Observable<WorkflowStepBankModel> {
    return this.http.post<WorkflowStepBankModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/bycategory/${category}/byorganization/${organizationId}/step/create`, modelData);
  }

  updateWorkflowStepBank(category: string, organizationId: number, workflowStepId: number, modelData: CreateUpdateWorkflowStepBankModel): Observable<WorkflowStepBankModel> {
    return this.http.put<WorkflowStepBankModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/bycategory/${category}/byorganization/${organizationId}/step/${workflowStepId}`, modelData);
  }

  deleteWorkflowStepBank(category: string, organizationId: number, workflowStepId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/bycategory/${category}/byorganization/${organizationId}/step/remove/${workflowStepId}`);
  }

  reorderWorkflowStepBankSteps(category: string, organizationId: number, workflowStepId1: number, workflowStepId2: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/bycategory/${category}/byorganization/${organizationId}/step/reorder?id1=${workflowStepId1}&id2=${workflowStepId2}`, null);
  }
}
