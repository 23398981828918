<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
    <h3 class="no-margin">{{ tableName }}</h3>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 margin-bottom-5">
    <p-table
      (onLazyLoad)="loadTable($event)"
      [columns]="tables"
      [lazy]="true"
      [loading]="loading"
      [metaKeySelection]="false"
      [paginator]="true"
      [rowsPerPageOptions]="[25,50,100,250,500]"
      [rows]="pageRowCountUtility.pageRowCount()"
      [scrollable]="true"
      [showCurrentPageReport]="true"
      [totalRecords]="dataTotal"
      [value]="tableData"
      currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
      dataKey="PersonID"
      rowExpandMode="single"
      scrollHeight="52vh"
      selectionMode="single"
      styleClass="p-datatable-responsive-demo p-datatable-gridlines p-datatable-striped dialog-table">
      <ng-template let-columns pTemplate="header">
        <tr>
          @for (col of columns; track col) {
            <th [style]="{width: this.tableWidth}">
              <ng-container>
                <div class="header-text">
                  {{ col.header }}
                </div>
              </ng-container>
            </th>
          }
        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-rowData pTemplate="body">
        <tr>
          @for (col of columns; track col) {
            <td [style]="{width: this.tableWidth}">
              @if (col.columnType === 'date') {
                {{ rowData[col.field] | date:"shortDate" }}
              } @else {
                {{ rowData[col.field] }}
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="tables?.length" style="width: 100%;">No records found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
