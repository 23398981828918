import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {RosterProfileService} from '../../../../../services/roster-profile.service';
import {take} from 'rxjs/operators';
import {RosterProfileApplicantModel} from '../../../../../models/roster-profile.model';
import {ContextService} from '../../../../../services/context.service';

@Component({
  templateUrl: './show-applicant-link-dialog.component.html',
})

export class ShowApplicantLinkDialogComponent implements OnInit {
  applicantData: RosterProfileApplicantModel;
  communicationType = 'QR';
  personData: any[] = [];
  presetBody: string;
  presetText: string;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private rosterProfileService: RosterProfileService,
              public contextService: ContextService) {
  }

  ngOnInit(): void {
    this.personData.push(this.config.data.person);
    this.getApplicantData();

  }

  getApplicantData() {
    this.rosterProfileService.getRosterApplicantData(this.config.data.person.PersonID)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.applicantData = res;
          this.presetBody = `Thank you for your interest in joining our organization.  Below you will find a link to continue the application process. <br/> <br><a href="${this.applicantData.ExternalUrl}" target="_blank">Complete Your Application</a>
        <br /><br />If you have questions or need assistance, please contact your business representative.`;
          this.presetText = `Thank you for your interest in joining our organization. Use this link to start your application. ${this.applicantData.ExternalUrl}`;
        }
      });
  }

  emailLink() {

  }

  textLink() {

  }
}
