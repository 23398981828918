import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-text-editor-edit-html',
  templateUrl: './text-editor-edit-html.component.html'
})

export class TextEditorEditHtmlComponent implements OnInit {
  html: string;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
  }

  ngOnInit(): void {
    this.html = this.config.data.rawHtml;
  }

  cancel() {
    this.ref.destroy();
  }

  processData() {
    this.ref.close(this.html);
  }
}
