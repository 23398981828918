import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';
import {ProfileRestoreModel, ProfileRestoreModelGrid} from '../models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class PersonService {

  constructor(private http: HttpClient, private contextService: ContextService) {}

  deletePerson(personId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/person/remove/${personId}`);
  }

  getAllDeletedPersonsPrimeNg(dataMode: string, filterData): Observable<ProfileRestoreModelGrid> {
    return this.http.post<ProfileRestoreModelGrid>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/deletedmembers/grid/0?mode=${dataMode}`, filterData);
  }

  restorePerson(personId: number): Observable<ProfileRestoreModel> {
    return this.http.post<ProfileRestoreModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/person/recover/${personId}`, null);
  }



}
