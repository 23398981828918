<div class="page-container">
  <div class="parent-p-card">
    <p-card>
      <div class="p-card-title">
        <i class="fas fa-file-alt"></i> Transcripts
      </div>
      <form [formGroup]="mainForm">
        <div class="margin-top-10">
          <app-learn-more [interfaceObjectTypeEnum]="'APP'" [interfaceObjectDesc]="InterfaceObjectEnum"
                          [report]="true"></app-learn-more>
        </div>

        <div class="p-grid p-fluid margin-top-10">
          @if (!isDialog) {
            <div class="p-col-12 p-sm-12 p-md-6">
              <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="setOrganizationData($event)">
              </app-select>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6">
              <app-select class="full-width" [inputObj]="inputObjTrainingCenter"
                          (emitData)="setTrainingCenterData($event)">
              </app-select>
            </div>
            <div class="p-col-12 p-sm-12 p-md-4">
              <app-select class="full-width" [inputObj]="inputObjStudentGroup" (emitData)="setStudentGroupData($event)">
              </app-select>
            </div>
            <div class="p-col-12 p-sm-12 p-md-4">
              <app-select class="full-width" [inputObj]="inputObjEmployer" (emitData)="setEmployerData($event)">
              </app-select>
            </div>
            <div class="p-col-12 p-sm-12 p-md-4">
              <app-select class="full-width" [inputObj]="inputObjProgram" (emitData)="setProgramData($event)">
              </app-select>
            </div>
            <div class="p-col-12 p-sm-12 p-md-4">
              <app-dist-list-select class="full-width" [inputObj]="inputObjRosterQuery"
                                    (emitData)="setRosterQueryData($event)">
              </app-dist-list-select>
            </div>
            <div class="p-col-12 p-sm-12 p-md-4">
              <app-select class="full-width" [inputObj]="inputObjPrimaryTrade" (emitData)="setPrimaryTradeData($event)">
              </app-select>
            </div>
            <div class="p-col-12 p-sm-12 p-md-4">
              <app-select class="full-width" [inputObj]="inputObjPosition" (emitData)="setPositionData($event)">
              </app-select>
            </div>
            <div class="p-col-12 p-sm-12 p-md-6">
              <app-select class="full-width" [inputObj]="inputObjCourseType" (emitData)="setCourseTypeData($event)">
              </app-select>
            </div>
            <div class="p-col-12 p-sm-6 p-md-3">
              <label>Start Date</label>
              <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="fcnStartDate" appendTo="body"
                          placeholder="mm/dd/yyyy" [maxDate]="mainForm.controls.fcnEndDate.value" [showIcon]="true"
                          inputId="icon" (onSelect)="setInputData()"
                          (onInput)="setInputData()"></p-calendar>
            </div>
            <div class="p-col-12 p-sm-6 p-md-3">
              <label>End Date</label>
              <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="fcnEndDate" appendTo="body"
                          placeholder="mm/dd/yyyy" [minDate]="mainForm.controls.fcnStartDate.value" [showIcon]="true"
                          inputId="icon" (onSelect)="setInputData()"
                          (onInput)="setInputData()"></p-calendar>
            </div>
          }
          @if(showCheckboxes){
            <div class="p-col-12">
              <div class="transcript-options">
                <div class="p-grid p-fluid">
                  <div class="col-md-2 col-sm-2 col-xs-12 checkbox-container">
                    <p-checkbox binary="true" label="Certifications" [(ngModel)]="includeCerts"
                                [ngModelOptions]="{standalone: true}"
                                (onChange)="setInputData()"></p-checkbox>
                  </div>

                  <div class="col-md-2 col-sm-2 col-xs-12 checkbox-container">
                    <p-checkbox binary="true" label="Modules Completed" [(ngModel)]="includeModules"
                                [ngModelOptions]="{standalone: true}"
                                (onChange)="setInputData()"></p-checkbox>
                  </div>

                  <div class="col-md-2 col-sm-2 col-xs-12 checkbox-container">
                    <p-checkbox binary="true" label="Courses Completed" [(ngModel)]="includeCourses"
                                [ngModelOptions]="{standalone: true}"
                                (onChange)="setInputData()"></p-checkbox>
                  </div>
                </div>
              </div>
            </div>
          }


          @if (setOrganizationId || isDialog) {
            <div class="p-col-12">
              <app-telerik-report-viewer [isDownload]="!isDialog" [reportUrl]="'studentdetailtranscript'"
                                         [inputData]="inputData"
                                         [autoRun]="isDialog && config.data.type === 'academic'"></app-telerik-report-viewer>
            </div>
          }
        </div>

      </form>
    </p-card>
  </div>
</div>
