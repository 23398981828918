import {Injectable} from '@angular/core';
import {TenantConfigDataService} from '../../services/tenant-config-data.service';

@Injectable()
export class ReportListDefinitions {
  public allTables: any[];
  public rosterStudentCols: any[];
  public rosterCols: any[];
  public prospectCols: any[];
  public positionCols: any[];
  public projectReportsCols: any[];
  public employersCols: any[];
  // public studentPositionCols: any[];
  public organizationEmployerCols: any[];
  public voterCols: any[];
  public surveyCols: any[];
  public communicationCols: any[];
  public certificationCols: any[];
  public memberTableOptions: any[];
  public studentTableOptions: any[];
  public communicationTableOptions: any[];
  public organizeTableOptions: any[];
  public projectReportsTableOptions: any[];
  public prospectsTableOptions: any[];
  public matchTextTypes: any[];
  public matchNumberTypes: any[];
  public matchDateTypes: any[];
  public booleanValues: any[];
  public yesNoValues: any[];
  public sendPublishStatusValues: any[];

  // public attributesOptions: AttributeSelectModel [] = [];

  constructor(private tenantConfigDataService: TenantConfigDataService) {

    const STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    // const STRINGREPLACEOCCUPATION = this.tenantConfigDataService.getStringValue('STRINGREPLACEOCCUPATION');
    const STRINGREPLACECLASSIFICATION = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
    const STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');

    this.memberTableOptions = [
      {option: 'Roster', value: 'ROSTER'},
      {option: 'Position', value: 'UNIONPOSITION'},
      {option: 'Voter', value: 'VOTER'},
      {option: 'Custom Fields', value: 'ATTRIBUTE'},
      {option: 'Communication', value: 'COMMUNICATIONSTATUS'}
    ];

    this.studentTableOptions = [
      {option: 'Student Roster', value: 'STUDENT'},
      {option: 'Position', value: 'EDUCATIONPOSITION'},
      // {option : 'Certification', value: 'CERTIFICATION'},
      {option: 'Voter', value: 'VOTER'},
      {option: 'Custom Fields', value: 'STUDENTATTRIBUTE'},
      // {option : 'Survey Response', value: 'SURVEYRESPONSE'},
      {option: 'Communication', value: 'COMMUNICATIONSTATUS'},
      {option: 'Certifications', value: 'CERTIFICATION'}
    ];

    this.organizeTableOptions = [
      {option: 'Prospects', value: 'PROSPECT'}
      //{option : 'Position', value: 'EDUCATIONPOSITION'},
      // {option : 'Custom Fields', value: 'STUDENTATTRIBUTE'},
      // {option : 'Communication', value: 'COMMUNICATIONSTATUS'}
    ];

    this.allTables = [
      {option: 'Roster', value: 'ROSTER'},
      {option: 'Membership Position', value: 'UNIONPOSITION'},
      {option: 'Membership Voter', value: 'VOTER'},
      {option: 'Membership Custom Fields', value: 'ATTRIBUTE'},
      {option: 'Membership Communication', value: 'COMMUNICATIONSTATUS'},
      {option: 'Student Roster', value: 'STUDENT'},
      {option: 'Training Position', value: 'EDUCATIONPOSITION'},
      {option: 'Training Voter', value: 'VOTER'},
      {option: 'Training Custom Fields', value: 'STUDENTATTRIBUTE'},
      {option: 'Training Communication', value: 'COMMUNICATIONSTATUS'},
      {option: 'Prospects', value: 'PROSPECT'}
    ];

    this.projectReportsTableOptions = [
      {option: 'Project Reports', value: 'PROJECT'},
      {option: 'Employers', value: 'PROJECTEMPLOYER'},
    ];

    this.prospectsTableOptions = [
      {option: 'Project Reports', value: 'PROSPECT'},
      {option: 'Employers', value: 'EDUCATIONPOSITION'},
    ];

    this.communicationTableOptions = [
      {option: 'Communication', value: 'COMMUNICATIONSTATUS'}
    ];

    this.matchTextTypes = [
      {option: 'Contains', value: 'contains'},
      {option: 'Equals', value: 'equals'},
      {option: 'Starts With', value: 'startsWith'},
      {option: 'Ends With', value: 'endsWith'},
      {option: 'Does not Equal', value: 'notequals'},
      {option: 'Is not empty', value: 'isnotnullorempty'},
      {option: 'Is Empty', value: 'isnullorempty'},
    ];

    this.matchNumberTypes = [
      {option: 'Greater Than', value: 'gt'},
      {option: 'Less Than', value: 'lt'},
      {option: 'Less Than or Equal', value: 'lte'},
      {option: 'Greater Than or Equal', value: 'gte'},
      {option: 'Does not Equal', value: 'notequals'},
      {option: 'Is not empty', value: 'isnotnullorempty'},
      {option: 'Is Empty', value: 'isnullorempty'},
      {option: 'Between', value: 'between'}
    ];

    this.matchDateTypes = [
      {option: 'Date is', value: 'dateis'},
      {option: 'Date is Not', value: 'dateisnot'},
      {option: 'Date is After', value: 'dateafter'},
      {option: 'Date is Before', value: 'datebefore'},
      {option: 'Between', value: 'between'},
      {option: 'Is not empty', value: 'isnotnullorempty'},
      {option: 'Is Empty', value: 'isnullorempty'},
      {option: 'Days from Present Between', value: 'daysfrompresentbetween'},
      {option: 'Months from Present Between', value: 'monthsfrompresentbetween'},
      {option: 'Month from Present', value: 'monthsfrompresent'}
    ];

    this.rosterStudentCols = [
      // {field: 'ThumbnailUrl', header: 'Profile', fullHeader: 'Profile', columnType: 'text', matchMode: 'contains', visible: true},
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false},
      {field: 'MiddleName', header: 'Middle Name', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', visible: false, frozen: true, isID: false},
      {field: 'Prefix', header: 'Prefix', fullHeader: 'Prefix', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'Gender', header: 'Gender', fullHeader: 'Gender', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      // {field: 'DOB_D', header: 'Birth Date', fullHeader: 'Birth Date', columnType: 'date', matchMode: 'equals', visible: true},
      {field: 'StudentNumber', header: 'Student #', fullHeader: 'Student #', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'MemberStatusAlias', header: 'Membership Status', fullHeader: 'Membership Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'ProgramStatus', header: 'Program Status', fullHeader: 'Program Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'MemberTypeAlias', header: 'Membership ' + STRINGREPLACETYPE, fullHeader: 'Membership ' + STRINGREPLACETYPE, columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: true},
      // {field: 'UTStatusDescription', header: 'Launch Status', fullHeader: 'Launch Status', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryTrade', header: 'Primary Trade', fullHeader: 'Primary Trade', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimarySkill', header: 'Primary Skill', fullHeader: 'Primary Skill', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'StatusAlias', header: 'Student Status', fullHeader: 'Student Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'BargainingUnitDescription', header: 'Bargaining Unit', fullHeader: 'Bargaining Unit', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'TypeAlias', header: 'Student Type', fullHeader: 'Training Type', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Classification', header: STRINGREPLACECLASSIFICATION, fullHeader: STRINGREPLACECLASSIFICATION, columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Instructor', header: 'Instructor', fullHeader: 'Instructor', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Ethnicity', header: 'Ethnicity', fullHeader: 'Ethnicity', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Race', header: 'Race', fullHeader: 'Race', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'MilitaryStatus', header: 'Military Status', fullHeader: 'Military Status', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'StudentGroupDescription', header: 'Student Group', fullHeader: 'Student Group', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'StudentGroupAlias', header: 'Student Group Alias', fullHeader: 'Student Group Alias', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryAddress1', header: 'Street Address', fullHeader: 'Street Address', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryAddress2', header: 'Ste, Bldg, Flr, etc.', fullHeader: 'Ste, Bldg, Flr, etc.', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCity', header: 'City/Town', fullHeader: 'City/Town', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryStateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryZip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCounty', header: 'County', fullHeader: 'County', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCountry', header: 'Country', fullHeader: 'Country', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryPhoneNumber', header: 'Home Phone #', fullHeader: 'Home Phone #', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'SecondaryPhoneNumber', header: 'Mobile Phone #', fullHeader: 'Mobile Phone #', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'ThirdPhoneNumber', header: 'Other Phone #', fullHeader: 'Other Phone #', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PublicPhoneNumber', header: 'Public Phone #', fullHeader: 'Public Phone #', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailAddress', header: 'Primary E-mail', fullHeader: 'Primary E-mail', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailFailedCommunication', header: 'Primary E-mail Undeliverable', fullHeader: 'Primary E-mail Undeliverable', columnType: 'ctYESNO', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailAddress', header: 'Business E-mail', fullHeader: 'Business E-mail', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailFailedCommunication', header: 'Secondary E-mail Undeliverable', fullHeader: 'Secondary E-mail Undeliverable', columnType: 'ctYESNO', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'PublicEmailAddress', header: 'Public E-mail', fullHeader: 'Public E-mail', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PublicEmailFailedCommunication', header: 'Public E-mail Undeliverable', fullHeader: 'Public E-mail Undeliverable', columnType: 'ctYESNO', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'SMSOptOut', header: 'SMS Opt Out', fullHeader: 'SMS Opt Out', columnType: 'ctYESNO', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailOptOut', header: 'Primary E-mail Opt Out', fullHeader: 'Primary E-mail Opt Out', columnType: 'YESNO', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailOptOut', header: 'Secondary E-mail Opt Out', fullHeader: 'Secondary E-mail Opt Out', columnType: 'YESNO', matchMode: 'equals', visible: false, frozen: false},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'User Account', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false}
    ];

    this.rosterCols = [
      {field: 'LastName', header: 'Last', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false},
      {field: 'FirstName', header: 'First', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false},
      {field: 'MiddleName', header: 'Middle', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Prefix', header: 'Prefix', fullHeader: 'Prefix', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'Suffix', header: 'Suffix', fullHeader: 'Suffix', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'Nickname', header: 'Nickname', fullHeader: 'Nickname', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: true},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'StatusAlias', header: 'Membership Status', fullHeader: 'Membership Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'TypeAlias', header: 'Membership ' + STRINGREPLACETYPE, fullHeader: 'Membership ' + STRINGREPLACETYPE, columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimaryTrade', header: 'Primary Trade', fullHeader: 'Primary Trade', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimarySkill', header: 'Primary Skill', fullHeader: 'Primary Skill', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'EffectiveDate_D', header: 'Effective Date', fullHeader: 'Effective Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false},
      {field: 'Classification', header: STRINGREPLACECLASSIFICATION, fullHeader: STRINGREPLACECLASSIFICATION, columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'BargainingUnitDescription', header: 'Bargaining Unit', fullHeader: 'Bargaining Unit', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'MemberStartDate_D', header: 'Initiation Date', fullHeader: 'Initiation Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false},
      {field: 'MemberRetirementDate_D', header: 'Retirement Date', fullHeader: 'Retirement Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: false, frozen: false, isID: false},
      {field: 'PrimaryOfficer', header: 'Primary Officer', fullHeader: 'Primary Officer', columnType: 'ctYESNO', matchMode: 'equals', visible: true, frozen: false, isID: false},
      {field: 'EmployerName', header: 'Employer', fullHeader: 'Employer', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'EmploymentHireDate_D', header: 'Hire Date', fullHeader: 'Hire Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: false, frozen: false, isID: false},
      {field: 'EmploymentNumber', header: 'EIN', fullHeader: 'EIN', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'WorkSiteName', header: 'Worksite', fullHeader: 'Worksite', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'ContractDescription', header: 'Contract', fullHeader: 'Contract', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'Gender', header: 'Gender', fullHeader: 'Gender', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Ethnicity', header: 'Ethnicity', fullHeader: 'Ethnicity', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Race', header: 'Race', fullHeader: 'Race', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'MilitaryStatus', header: 'Military Status', fullHeader: 'Military Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Shift', header: 'Shift', fullHeader: 'Shift', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'BillingStatusAlias', header: 'Billing Process', fullHeader: 'Billing Process', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'AccountBalance_N', header: 'Balance', fullHeader: 'Balance', columnType: 'numeric', matchMode: 'equals', displayType: 'currency', visible: true, frozen: false, isID: false},
      {field: 'LastPayment_D', header: 'Dues Last Paid', fullHeader: 'Dues Last Paid', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: false, frozen: false, isID: false},
      {field: 'PaymentType', header: 'Dues Payment Election', fullHeader: 'Dues Payment Election', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'AutoPay', header: 'Auto Pay', fullHeader: 'Auto Pay', columnType: 'ctYESNO', matchMode: 'equals', visible: true, frozen: false, isID: false},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'User Account', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimaryAddress1', header: 'Street Address', fullHeader: 'Street Address', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryAddress2', header: 'Ste, Bldg, Flr, etc.', fullHeader: 'Ste, Bldg, Flr, etc.', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCity', header: 'City/Town', fullHeader: 'City/Town', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryStateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryZip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCounty', header: 'County', fullHeader: 'County', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCountry', header: 'Country', fullHeader: 'Country', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryFailedCommunication', header: 'Undeliverable', fullHeader: 'Undeliverable', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'PrimaryPhoneNumber', header: 'Home Phone #', fullHeader: 'Home Phone #', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'SecondaryPhoneNumber', header: 'Mobile Phone #', fullHeader: 'Mobile Phone #', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'ThirdPhoneNumber', header: 'Other Phone #', fullHeader: 'Other Phone #', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'SMSOptOut', header: 'SMS Opt Out', fullHeader: 'SMS Opt Out', columnType: 'ctYESNO', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailAddress', header: 'Primary E-mail', fullHeader: 'Primary E-mail', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailOptOut', header: 'Primary E-mail Opt Out', fullHeader: 'Primary E-mail Opt Out', columnType: 'ctYESNO', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailFailedCommunication', header: 'Primary E-mail Undeliverable', fullHeader: 'Primary E-mail Undeliverable', columnType: 'ctYESNO', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailAddress', header: 'Secondary E-mail', fullHeader: 'Secondary E-mail', columnType: 'text', matchMode: 'contains', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailOptOut', header: 'Secondary E-mail Opt Out', fullHeader: 'Secondary E-mail Opt Out', columnType: 'ctYESNO', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailFailedCommunication', header: 'Secondary E-mail Undeliverable', fullHeader: 'Secondary E-mail Undeliverable', columnType: 'ctYESNO', matchMode: 'equals', visible: false, frozen: false, isID: false}
    ];

    this.prospectCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizationName', header: 'Organizations', fullHeader: 'Organization', columnType: 'text', matchMode: 'contains', visible: true},
      {field: 'StatusAlias', header: 'Status', fullHeader: 'Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'TypeAlias', header: 'Type', fullHeader: 'Type', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'Has Account', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'numeric', matchMode: 'equals', visible: false},
      {field: 'MiddleName', header: 'Middle Name', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'text', matchMode: 'contains', visible: false}
    ];

    this.projectReportsCols = [
      {field: 'ProjectName', header: 'Name of Project', fullHeader: 'Name of Project', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizationName', header: 'Organization', fullHeader: 'Organization', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizerList', header: 'Organizers', fullHeader: 'Organizers', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'ProjectPhaseDescription', header: 'Phase', fullHeader: 'Date of Visit', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProjectTypeDescription', header: 'Type', fullHeader: 'Type', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProjectValueDescription', header: 'Value', fullHeader: 'Estimated Value', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProjectIndustryDescription', header: 'Industry', fullHeader: 'Industry', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProjectFundingDescription', header: 'Funding', fullHeader: 'Funding', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'County', header: 'County', fullHeader: 'County', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'StateProvinceAbbreviation', header: 'State', fullHeader: 'State', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'VisitDate_D', header: 'Date of Visit', fullHeader: 'Date of Visit', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'FollowupVisitDate_D', header: 'Date of Follow-Up Visit', fullHeader: 'Date of Follow-Up Visit', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'ActiveProject', header: 'Active', fullHeader: 'Active', columnType: 'boolean', matchMode: 'equals', displayType: 'boolean', visible: true}
    ];

    this.employersCols = [
      {field: 'OrganizationID', header: 'Employer', fullHeader: 'Employer', columnType: 'picker', matchMode: 'contains', visible: true},
      {field: 'OrganizeProjectID', header: 'Trade', fullHeader: 'Trade', columnType: 'picker', matchMode: 'contains', visible: true},
      {field: 'OrganizeProjectOrganizationTypeID', header: 'Type', fullHeader: 'Type', columnType: 'picker', matchMode: 'equals', visible: false},
      {field: 'UnionOrganization', header: 'Is Union', fullHeader: 'Is Union', columnType: 'boolean', matchMode: 'equals', displayType: 'boolean', visible: true},
    ];

    this.organizationEmployerCols = [
      {field: 'OrganizeProjectName', header: 'Type', fullHeader: 'Type', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'OrganizationName', header: 'Type', fullHeader: 'Type', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'TradeDescription', header: 'Trade', fullHeader: 'Trade', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizeProjectOrganizationTypeDescription', header: 'Type', fullHeader: 'Type', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'UnionOrganization', header: 'Is Union', fullHeader: 'Is Union', columnType: 'boolean', matchMode: 'equals', displayType: 'boolean', visible: true},
    ];

    this.positionCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'CurrentPosition', header: 'Current', fullHeader: 'Current', columnType: 'boolean', matchMode: 'equals', displayType: 'boolean', visible: false},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'OrganizationType', header: 'Organization Type', fullHeader: 'Type', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Position', header: 'Position', fullHeader: 'Position', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PositionTypeDescription', header: 'Position Type', fullHeader: 'Type', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PositionStartDate_D', header: 'Start Date', fullHeader: 'Start Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'PositionEndDate_D', header: 'End Date', fullHeader: 'End Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true}
    ];

    this.voterCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'VoterRegistrationStatus', header: 'Voter Registration Status', fullHeader: 'Voter Registration Status', columnType: 'picker', matchMode: 'equals', visible: false},
      {field: 'VoterStatus', header: 'Voter Status', fullHeader: 'Voter Status', columnType: 'picker', matchMode: 'equals', visible: false},
      {field: 'PartyRegistration', header: 'Party Registration', fullHeader: 'Party Registration', columnType: 'picker', matchMode: 'equals', visible: false},
      {field: 'CountyName', header: 'County Name', fullHeader: 'County Name', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'CongressionalDistrict', header: 'Congressional District', fullHeader: 'Congressional District', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'StateSenateDistrict', header: 'State Senate District', fullHeader: 'State Senate District', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'StateHouseDistrict', header: 'State House District', fullHeader: 'State House District', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'TownshipName', header: 'Township Name', fullHeader: 'Township Name', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'WardName', header: 'Ward Name', fullHeader: 'Ward Name', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrecinctCode', header: 'Precinct Code', fullHeader: 'Precinct Code', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrecinctName', header: 'Precinct Name', fullHeader: 'Precinct Name', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'NationalPrecinctCode', header: 'National Precinct Code', fullHeader: 'National Precinct Code', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'CountyCouncilDistrict', header: 'County Council District', fullHeader: 'County Council District', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'CityCouncilDistrict', header: 'City Council District', fullHeader: 'City Council District', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'MunicipalDistrict', header: 'Municipal District', fullHeader: 'Municipal District', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'SchoolDistrict', header: 'School District', fullHeader: 'School District', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'JudicialDistrict', header: 'Judicial District', fullHeader: 'Judicial District', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'AbsenteeVoterIndicator', header: 'Absentee Voter Indicator', fullHeader: 'Absentee Voter Indicator', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'GeneralVotesSummary', header: 'General Votes Summary', fullHeader: 'General Votes Summary', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryVotesSummary', header: 'Primary Votes Summary', fullHeader: 'Primary Votes Summary', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'VoterScore', header: 'Voter Score', fullHeader: 'Voter Score', columnType: 'text', matchMode: 'contains', visible: false}
    ];

    this.surveyCols = [
      {field: 'Title', header: 'Title', fullHeader: 'Title', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Question', header: 'Question', fullHeader: 'Question', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Response', header: 'Response', fullHeader: 'Response', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true}
    ];

    this.communicationCols = [
      {field: 'CommunicationID', header: 'Communication', fullHeader: 'Description', columnType: 'select', matchMode: 'contains', visible: true},
      {field: 'SendpublishStatusDescription', header: 'Status', fullHeader: 'Status', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true}
    ];

    this.certificationCols = [
      {field: 'CertificationDescription', header: 'Certification', fullHeader: 'Description', columnType: 'picker', matchMode: 'contains', visible: true}
    ];

    this.sendPublishStatusValues = [
      {Description: 'Failed', ID: 5},
      {Description: 'Delivered', ID: 6},
      {Description: 'Opened', ID: 7},
      {Description: 'Engaged', ID: 8},
    ];

    this.booleanValues = [
      {Description: 'Yes', ID: true},
      {Description: 'No', ID: false},
    ];

    this.yesNoValues = [
      {Description: 'Yes', ID: 'Yes'},
      {Description: 'No', ID: 'No'},
    ];
  }
}
