<div>
  <i class="pi pi-download p-confirm-dialog-icon ng-star-inserted"></i>
  <span class="p-confirm-dialog-message"> Please be aware that exporting times may vary (even up to several minutes). Thank you for your patience.</span>
</div>
<div class="p-dialog-footer margin-top-10">
  <button type="button" pButton icon="fa fa-file-excel" label="Download Advanced Profile" class="p-element p-button p-component " (click)="processProfile(true)">
    <span class="p-button-icon p-button-icon-left pi pi-file-pdf"></span><span class="p-button-label">Download Advanced Profile</span>
  </button>
  <button type="button" pButton icon="fa fa-file-excel" label="Download Basic Profile" class="p-element p-button p-component " (click)="processProfile(false)">
    <span class="p-button-icon p-button-icon-left pi pi-file-pdf"></span><span class="p-button-label">Download Basic Profile</span>
  </button>
</div>
