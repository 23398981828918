import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import {PiiValidateModel, PiiValidateReturnModel} from '../models/pii-validate.model';


@Injectable({
  providedIn: 'root',
})
export class PiiValidateService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  validatePii(dataModel: PiiValidateModel): Observable<PiiValidateReturnModel> {
    return this.http.post<PiiValidateReturnModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/person/duplicatecheck`, dataModel);
  }
}
