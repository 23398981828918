<div class="dialog-form">
  <form [formGroup]="mainForm">
    <div class="p-grid p-fluid">
      <div class="col-md-4 col-sm-3 col-xs-12 margin-top-10">
        <app-select class="full-width" [inputObj]="inputObjTrainingStatus" (emitData)="getTrainingStatus($event)"></app-select>
      </div>
      <div class="col-md-4 col-sm-3 col-xs-12 margin-top-10">
        <label>Date@if (this.mainForm.get('certification').status === 'VALID') {
          <sup class="req">*</sup>
        }</label>
        <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="date" [maxDate]="mainForm.controls.certExpirationDate.value" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="2010:2027"></p-calendar>
        @if ((mainForm.controls.date.dirty || mainForm.controls.date.touched) &&
          mainForm.controls.date.errors?.required) {
          <span class="error">'Date' must be provided</span>
        }
      </div>
      <div class="col-md-4 col-sm-3 col-xs-12 margin-top-10">
        <label>Certificate Issue Date<sup class="req">*</sup></label>
        <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="certIssueDate" [minDate]="mainForm.controls.date.value" [maxDate]="mainForm.controls.certExpirationDate.value" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1925:2050"></p-calendar>
        @if ((mainForm.controls.certIssueDate.dirty || mainForm.controls.certIssueDate.touched) &&
          mainForm.controls.certIssueDate.errors?.required) {
          <span class="error">'Certification Issue Date' must be provided</span>
        }
      </div>
      @if (showExpirationData) {
        <div class="col-md-4 col-sm-3 col-xs-12 margin-top-10">
          <label>Certification Expires Date</label>
          <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="certExpirationDate" [minDate]="mainForm.controls.date.value" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="2010:2027"></p-calendar>
        </div>
      }
      @if (showExpirationData) {
        <div class="col-md-4 col-sm-3 col-xs-12 margin-top-25 no-padding p-ml-2">
          <p-checkbox class="margin-top-10"  binary="true" label="No Expiration" formControlName="noExpirationDate" (onChange)="noExpClick($event)"></p-checkbox>
        </div>
      }
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-20">
      <span class="error"><sup>*</sup>Individual student statuses can be edited in the student list after saving.</span>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 text-align-right p-mb-3">
      <button pButton label="Cancel" [class]="'p-button-info p-mr-2 '" [className]="'inline-button margin-right-5'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
      (click)="cancel()"></button>
      @if (!isSaving) {
        <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
        (click)="processData()" [className]="'inline-button'" [disabled]="this.mainForm.invalid || !this.mainForm.dirty"></button>
      }
    </div>
  </form>
</div>

