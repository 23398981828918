@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form [formGroup]="mainForm">
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      <div class="col-md-3 col-sm-4 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjContactCategory" (emitData)="getContactCategoryData($event)"></app-select>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 input-container">
        <h3 class="p-mt-5">
          <div class="p-mt-1 switch-container">
            <p-inputSwitch class="p-ml-4" formControlName="primary" tabindex="0"></p-inputSwitch>
            <div class="input-switch-label p-ml-2">Primary</div>
            @if (isEdit) {
<!--              <div class="p-mt-1 switch-container">-->
                <p-inputSwitch class="p-ml-4" formControlName="undeliverable" tabindex="0"></p-inputSwitch>
                <div class="input-switch-label p-ml-2">Undeliverable</div>
<!--              </div>-->
            }
            @if (!addressData.Address1 || !addressData.City || !addressData.StateProvinceID || !addressData.Zip) {
              <div class="p-mt-1 p-ml-4 red">
                *Incomplete
              </div>
            }
          </div>
<!--          @if (isEdit) {-->
<!--            <div class="p-mt-1 switch-container">-->
<!--              <p-inputSwitch class="p-ml-4" formControlName="undeliverable" tabindex="0"></p-inputSwitch>-->
<!--              <div class="input-switch-label p-ml-2">Undeliverable</div>-->
<!--            </div>-->
<!--          }-->
<!--          <p-checkbox [disabled]="primary" class="p-mr-2" binary="true" label="Primary" formControlName="primary" tabindex="0"></p-checkbox>-->
<!--          @if (isEdit) {-->
<!--            <p-checkbox binary="true" label="Undeliverable" formControlName="undeliverable" tabindex="0"></p-checkbox>-->
<!--          }-->
        </h3>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p-card header="Address" class="parent-p-card">
          <div class="p-grid p-fluid">
            <div class="col-md-12 col-sm-12 col-xs-12">
              @if (currentAddress && addressData.Ready) {
                <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [mapDialogDataTitle]="locationHeader" [mapDialogHeader]="locationHeader" [showCounty]="true" [showZipPlus4]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
              } @else if (!isEdit && addressData.Ready) {
                <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [mapDialogDataTitle]="locationHeader" [mapDialogHeader]="locationHeader" [showCounty]="true" [showZipPlus4]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
              }
            </div>
          </div>
        </p-card>
      </div>
    </div>
    @if (canTabWrite) {
      <div class="p-grid p-fluid margin-top-10">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="p-grid p-fluid">
            <div class="col-md-12 col-sm-6 col-xs-12 tab-form-buttons">
              @if (isEdit) {
                <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                (click)="cancel()" [disabled]="!mainForm.dirty"></button>
              } @else {
                <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                (click)="cancel()"></button>
              }
              @if (!isSaving) {
                <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                  (click)="processData()" [className]="'inline-button'"
                [disabled]="mainForm.invalid || !mainForm.dirty"></button>
              } @else {
                <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </form>
}
