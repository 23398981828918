import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RosterProfileChangeLogModel, RosterProfileChangeLogsGridModel} from '../../../../models/roster-profile-change-logs.model';
import {RosterProfileChangeLogsService} from '../../../../services/roster-profile-change-logs.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-change-logs-tab',
  templateUrl: './change-logs-tab.component.html',
  styleUrls: ['./change-logs-tab.component.scss']
})

export class ChangeLogsTabComponent implements OnInit, OnDestroy {
  changeLogs: RosterProfileChangeLogsGridModel;
  changeLogsList: RosterProfileChangeLogModel[];
  currentFilter = new PrimeTableFilterModel();
  dataLoaded: boolean;
  isExporting: boolean;
  dataTotal: number = 0;
  @Input() PersonID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() context: string;
  cols = [];
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private rosterProfileChangeLogsService: RosterProfileChangeLogsService, private dialogUtility: DialogUtility,
              public pageRowCountUtility: PageRowCountUtility, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.cols = [
      {
        field: 'LogDate_D',
        header: 'Log Date',
        columnType: 'date',
        matchMode: 'equals',
        visible: true,
        displayType: 'date'
      },
      {
        field: 'EffectiveDate_D',
        header: 'Effective Date',
        columnType: 'date',
        matchMode: 'equals',
        visible: true,
        displayType: 'date'
      },
      {
        field: 'Category',
        header: 'Category',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {field: 'Type', header: 'Type', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {
        field: 'ChangedFrom',
        header: 'From',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {field: 'ChangedTo', header: 'To', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'User', header: 'User', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'}
    ];

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  loadTable(event:any): void {
    this.getChangeLogs();
  }

  getChangeLogs(): void {
    this.dataLoaded = false;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.rosterProfileChangeLogsService.getRosterChangeLogsPrimeNG('DATA', this.PersonID, this.context, null, null, null)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.changeLogs = res;
          this.changeLogsList = res.Data;
          this.dataLoaded = true;
        }
      });
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.rosterProfileChangeLogsService.getRosterChangeLogsPrimeNG('TOTAL', this.PersonID, this.context, null, null, null)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  refresh(): void {
    this.getChangeLogs();
  }

  synchronize(): void {
    this.syncChangeLogs();
  }

  syncChangeLogs(): void {
    this.rosterProfileChangeLogsService.syncChangeLog('person', this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: res
          });
        }
      });
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.changeLogsList.length <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.changeLogsList));
        const dataToExport = removeKeysFromArray(arr, []);
        exportData('change-log', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.rosterProfileChangeLogsService.getRosterChangeLogsPrimeNG('DATA', this.PersonID, this.context)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, []);
              exportData('change-log', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
