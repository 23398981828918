import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from '../../services/context.service';

import { AlertsModel, AcknowledgeAlert, SharableAlertsGridModel, SharableOutgoingAlertsGridModel } from '../models/alerts.model';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getAlerts(context?): Observable<AlertsModel> {
    return this.http.get<AlertsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/alerts`
    );
  }

  // clearAlert(alertData: AcknowledgeAlert[]): Observable<AcknowledgeAlert[]> {
  //   return this.http.put<AcknowledgeAlert[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/acknowledgealerttoggle`, alertData
  //   );
  // }

  getAlertsPrimeNG(dataMode: string, filterData): Observable<SharableAlertsGridModel> {
    return this.http.post<SharableAlertsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/alertgrid?mode=${dataMode}`, filterData);
  }

  // getOutgoingAlertsKGrid(): Observable<SharableOutgoingAlertsGridModel> {
  //   return this.http.get<SharableOutgoingAlertsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/outgoingalertgrid`);
  // }

  getOutgoingAlertsPrimeNG(dataMode: string, filterData): Observable<SharableOutgoingAlertsGridModel> {
    return this.http.post<SharableOutgoingAlertsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/outgoingalertgrid?mode=${dataMode}`, filterData);
  }

  clearAlert(data: number[]): Observable<any> {
    const modelData: any[] = [];
    let modelDataSingle: AcknowledgeAlert;
    data.forEach(id => {
      modelDataSingle = {
        SharableId: id,
        Promoted: false
      };
      modelData.push(modelDataSingle);
    });
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/acknowledgealerttoggle`, modelData);
  }
}
