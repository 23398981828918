import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {AddEditApprenticeHoursDialogComponent} from './add-edit-apprentice-hours-dialog/add-edit-apprentice-hours-dialog.component';
import {ApprenticeHoursService} from '../../../services/apprentice-hours.service';
import {ApprenticeHoursGridModel, ApprenticeHoursListModel, ApprenticeHoursSummary} from '../../../models/apprenticeHours.model';
import * as FileSaver from 'file-saver';
import {StudentProfileModel} from '../../../models/student-profile.model';

@Component({
  selector: 'app-student-apprentice-hours',
  templateUrl: './roster-student-apprentice-hours.component.html',
  styleUrls: ['./roster-student-apprentice-hours.component.scss']
})

export class RosterStudentApprenticeHoursComponent implements OnInit, OnChanges, OnDestroy {
  currentFilter = new PrimeTableFilterModel();
  isExporting: boolean = false;
  dataTotal: number;
  showAddEdit: boolean = false;
  cols: any[] = [];
  apprenticeHours: ApprenticeHoursListModel[];
  apprenticeHoursSummary = {} as ApprenticeHoursSummary;
  dataLoaded: boolean;
  isEdit: boolean = false;
  getListRequest;
  getTotalRequest;
  @Input() PersonID: number;
  @Input() canTabWrite: boolean;
  @Input() OrgId: number;
  @Input() student: StudentProfileModel;
  private ngUnsubscribe = new Subject();

  constructor(public pageRowCountUtility: PageRowCountUtility, public dialogService: DialogService, private confirmationService: ConfirmationService,
              private messageService: MessageService, private apprenticeHoursService: ApprenticeHoursService) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.cols = [
      {field: 'EmployerOrganizationName', header: 'Contractor Name', fullHeader: 'Contractor Name', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'WorkMonth', header: 'Work Month', fullHeader: 'Work Month', columnType: 'date', matchMode: 'contains', visible: true, displayType: 'dateMonth'},
      {field: 'ReceivedDate', header: 'Received Day', fullHeader: 'Received Month', columnType: 'date', matchMode: 'contains', visible: true, displayType: 'date'},
      {field: 'Aef', header: 'AEF', fullHeader: 'AEF', columnType: 'currency', matchMode: 'gte', visible: true, displayType: 'currency'},
      {field: 'Hours', header: 'Hours', fullHeader: 'Hours', columnType: 'numeric', matchMode: 'gte', visible: true, displayType: 'numeric'},
      {field: 'Notes', header: 'Notes', fullHeader: 'Notes', columnType: 'text', matchMode: 'gte', visible: true, displayType: 'text'},
      {field: 'Source', header: 'Source', fullHeader: 'Source', columnType: 'text', matchMode: 'gte', visible: true, displayType: 'text'}
    ];
  }

  openDialog(): void {

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  loadTable(event?): void {
    if (event) {
      this.currentFilter.rows = event.rows;
      this.currentFilter.first = event.first;
      this.currentFilter.sortOrder = event.sortOrder;
      this.currentFilter.sortField = event.sortField;
    }
    //this.getList();
    this.getData();
    this.getTotal();
    this.getSummary();
  }

  getSummary() {
    this.apprenticeHoursService.getApprenticeHoursSummary(this.PersonID, null)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.apprenticeHoursSummary = res;
        }
      });
  }

  getData() {
    this.getListRequest = this.apprenticeHoursService.getApprenticeHoursPrimeNG('DATA', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: ApprenticeHoursGridModel) => {
          this.apprenticeHours = res.Data;
          this.dataLoaded = true;
        }
      });
  }

  getTotal() {
    this.getTotalRequest = this.apprenticeHoursService.getApprenticeHoursPrimeNG('TOTAL', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: ApprenticeHoursGridModel) => {
          this.dataTotal = res.Total;
        }
      });
  }

  goToEdit(data, isEdit) {
    const ref = this.dialogService.open(AddEditApprenticeHoursDialogComponent, {
      data: {
        data,
        isEdit,
        personId: this.PersonID,
        orgId: this.OrgId,
      },
      header: 'Add/Edit Hours',
      width: '70%',
    });
    ref.onClose.subscribe(() => {
      this.getData();
      this.getTotal();
      this.getSummary();
    });
  }

  confirmDelete(data) {
    const ref = this.confirmationService.confirm({
      header: 'Warning',
      icon: 'fas fa-triangle-exclamation',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      message: 'Are you sure you want to delete the selected row?',
      accept: () => {
        this.delete(data);
      },
      reject: () => {
      }
    });
  }

  delete(data) {
    this.apprenticeHoursService.deleteApprenticeHoursDetail(data.PersonID, data.PersonHoursID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Hours were deleted successfully'
          });
          this.loadTable();
        }, error: (e) => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          console.debug(e);
        }
      });
  }

  refresh() {
    this.getTotal();
    this.getData();
  }


  export() {
    this.apprenticeHoursService.exportApprenticeHours(this.PersonID, null)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.downloadFile(res, `${this.apprenticeHoursSummary.StudentName} Wage ${this.apprenticeHoursSummary.WageLevel} Student Level ${this.apprenticeHoursSummary.StudentLevel}.xlsx`, 'text/plain;charset=utf-8');
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Exported Successfully'
          });

        }, error: (e) => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          console.debug(e);
        }
      });
  }

  downloadFile(buffer, fileName, fileType) {
    import('file-saver').then(() => {
      const data: Blob = new Blob([buffer], {
        type: fileType
      });
      FileSaver.saveAs(data, fileName);
    });
  }


}
