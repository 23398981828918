import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {PaymentMethodModel, PaymentMethodsGridModel} from '../../../../models/payment-methods.model';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PaymentMethodsService} from '../../../../services/payment-methods.service';
import {MessageService} from 'primeng/api';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-payment-methods-tab',
  templateUrl: './payment-methods-tab.component.html',
  styleUrls: ['./payment-methods-tab.component.scss']
})
export class PaymentMethodsTabComponent implements OnInit, OnChanges, OnDestroy {
  paymentMethods: PaymentMethodsGridModel;
  paymentMethodList: PaymentMethodModel[] = [];
  dataLoaded: boolean;
  totalLoaded: boolean;
  isExporting: boolean;
  isButtonDisabled: boolean = true;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  ccFilter: boolean = false;
  checkingFilter: boolean = false;
  savingFilter: boolean = false;
  canDelete: boolean = true;
  selectedItem: number;
  showAddEdit: boolean;
  dataTotal: number = 0;
  inputObjSelectPaymentMethodData: GenericSelectModel;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() userObject: boolean;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() allowCC: boolean = true;
  @Input() parentObjectEnum: string;
  @Output() resetList = new EventEmitter<boolean>();
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private paymentMethodsService: PaymentMethodsService,
              private messageService: MessageService, private dialogUtility: DialogUtility,
              public dialogService: DialogService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    this.inputObjSelectPaymentMethod(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.getData();
  }

  getData(): void {
    this.getList();
    this.getTotal();
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.paymentMethodList.length = 0;
    this.dataLoaded = false;
    if (this.DBEntity === 'PERSON') {
      this.getListRequest = this.paymentMethodsService.getPaymentMethodsPrimeNG('DATA', this.DBEntity, this.DBEntityID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const dt = new Date();
            this.paymentMethodList = res.Data;
            if (this.canTabWrite && this.paymentMethodList.length === 0) {
              this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
            } else {
              this.showAddEdit = false;
            }
            this.paymentMethodList.forEach(x => {
              if (x.MethodType === 'CC') {
                const exp = new Date(2000 + x.CCExpirationYear, x.CCExpirationMonth - 1);
                x.Expired = (dt > exp);
              } else {
                x.Expired = false;
              }
              if (x.MethodNickname) {
                x.Display = x.MethodNickname;
              } else {
                switch (x.MethodType) {
                  case 'CC':
                    x.Display = x.CCCardType;
                    break;
                  case 'ACH':
                    if (x.ACHAccountType === 'CHECKING') {
                      x.Display = 'Checking Account';
                    } else {
                      x.Display = 'Savings Account';
                    }
                    break;
                  default:
                    x.Display = '';
                    break;
                }
              }
            });
            this.dataLoaded = true;
          }, error: (e) => {
            console.debug(e);
            this.dataLoaded = true;
          }
        });
    } else {
      this.getListRequest = this.paymentMethodsService.getPaymentMethodsPrimeNG('DATA', this.DBEntity, this.DBEntityID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const dt = new Date();
            this.paymentMethodList = res.Data;
            if (this.canTabWrite && this.paymentMethodList.length === 0) {
              this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
            } else {
              this.showAddEdit = false;
            }
            this.paymentMethodList.forEach(x => {
              if (x.MethodType === 'CC') {
                const exp = new Date(2000 + x.CCExpirationYear, x.CCExpirationMonth - 1);
                x.Expired = (dt > exp);
              } else {
                x.Expired = false;
              }
              if (x.MethodNickname) {
                x.Display = x.MethodNickname;
              } else {
                switch (x.MethodType) {
                  case 'CC':
                    x.Display = x.CCCardType;
                    break;
                  case 'ACH':
                    if (x.ACHAccountType === 'CHECKING') {
                      x.Display = 'Checking Account';
                    } else {
                      x.Display = 'Savings Account';
                    }
                    break;
                  default:
                    x.Display = '';
                    break;
                }
              }
            });
            this.dataLoaded = true;
          }, error: (e) => {
            this.dataLoaded = true;
            console.debug(e);
          }
        });
    }
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.paymentMethodsService.getPaymentMethodsPrimeNG('TOTAL', this.DBEntity, this.DBEntityID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  deletePaymentMethod(paymentMethodId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.paymentMethodsService.deletePaymentMethod(paymentMethodId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected payment method has been removed.'
            });
            this.updateList(true);
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList(dataChanged: boolean): void {
    this.showAddEdit = false;
    if (dataChanged) {
      this.selectedItem = -1;
      this.getData();
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterItems(): void {
    this.dataLoaded = false;
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('MethodNickname', this.activeGlobalFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.UserName;
    }
    delete this.currentFilter.filters.MethodType;
    delete this.currentFilter.filters.ACHAccountType;
    if (this.ccFilter === true || this.checkingFilter === true || this.savingFilter === true) {
      if (this.ccFilter === true) {
        this.initFilters('MethodType', 'CC', 'equals', 'or');
      } else if (this.checkingFilter === true) {
        this.initFilters('MethodType', 'ACH', 'equals', 'and');
        this.initFilters('ACHAccountType', 'CHECKING', 'equals', 'and');
      } else if (this.savingFilter === true) {
        this.initFilters('MethodType', 'ACH', 'equals', 'and');
        this.initFilters('ACHAccountType', 'SAVING', 'equals', 'and');
      }
    }
    this.getData();
  }


  manageFilterCheckboxes(event:any): void {
    const type = event[0].Description;
    switch (type) {
      case 'Credit Card':
        this.checkingFilter = false;
        this.savingFilter = false;
        this.ccFilter = true;
        break;
      case 'Checking':
        this.ccFilter = false;
        this.savingFilter = false;
        this.checkingFilter = true;
        break;
      case 'Savings':
        this.ccFilter = false;
        this.checkingFilter = false;
        this.savingFilter = true;
        break;
      default:
        this.ccFilter = false;
        this.checkingFilter = false;
        this.savingFilter = false;
        delete this.currentFilter.filters.MethodType;
        break;
    }
  }

  inputObjSelectPaymentMethod(disable: boolean): void {
    this.inputObjSelectPaymentMethodData = {
      labelText: 'Payment Method Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: [
        {
          ID: null,
          Description: '--',
          Abbreviation: null
        },
        {
          ID: 1,
          Description: 'Credit Card',
          Abbreviation: 'Credit Card'
        },
        {
          ID: 2,
          Description: 'Checking',
          Abbreviation: 'Checking'
        },
        {
          ID: 3,
          Description: 'Savings',
          Abbreviation: 'Savings'
        }
      ],
      disabled: disable,
      emitChangeOnLoad: true
    };
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.activeGlobalFilter = null;
    this.ccFilter = false;
    this.checkingFilter = false;
    this.savingFilter = false;
    this.currentFilter.filters = {};
    this.inputObjSelectPaymentMethod(false);
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string): void {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  refresh(): void {
    this.getData();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  resetComponent(): void {
    this.paymentMethodList = undefined;
    this.paymentMethods = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      const arr = JSON.parse(JSON.stringify(this.paymentMethodList));
      const dataToExport = removeKeysFromArray(arr, ['PaymentMethodId', 'DBEntity', 'DBEntityKeyID', 'CountryID', 'CVV', 'Display']);
      exportData('payment-methods', dataToExport);
      this.isExporting = false;
    }, () => {
    });
  }
}
