<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12 list-container-header">
    <p>View the program(s) the student is enrolled in and add additional programs here.</p>
    <div class="p-grid p-fluid">
      <h2 class="col-md-3 col-sm-4 col-xs-12">@if (studentPrograms) {
        <span>{{ dataTotal }} Results</span>
      }</h2>
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (studentPrograms) {
          <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal" (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        <button (click)="refresh()" icon="pi pi-refresh" pButton
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canTabWrite) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0" icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true" icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (studentPrograms) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal" (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    <!--    <div *ngIf="showAddEdit">-->
    <!--      <p-card header="Add Program" [styleClass]="'list-card'">-->
    <!--        <app-global-roster-student-programs-form [PersonID]="PersonID" [isEdit]="false" [canTabWrite]="canTabWrite"-->
    <!--                                          (closeAddEdit)="updateList()"></app-global-roster-student-programs-form>-->
    <!--      </p-card>-->
    <!--    </div>-->
    @if (loading) {
      <span class="fa fa-spinner list-spinner"></span>
    }
    @if (!loading) {
      <div class="list-container">
        @for (program of studentProgramsList; track program; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i, program.ProgramID)">
                  <div class="list-header">
                    {{ program.ProgramDescription }}
                  </div>
                  <div class="list-subheader">
                    <b>Status:</b> {{ program.TrainingStatusDescription }} | {{ program.StartDate_D | date }} - {{ program.EndDate_D | date }} | {{ program.Identifier }}
                  </div>
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    @if (canTabWrite && program.CanDelete) {
                      <div class="icon" pTooltip="Delete" tooltipPosition="bottom" (click)="deleteRecord(program.ProgramStudentID)"><i class="fas fa-trash-alt"></i></div>
                    }
                    <div class="icon" (click)="expandData(i, program.ProgramID)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                    <!--                <div class="icon hide-on-mobile" (click)="openProgram(program.ProgramID)"><i class="fas fa-pen"></i></div>-->
                  </div>
                </div>
              </div>
              @if (selectedItem === i) {
                <div class="full-width margin-top-10">
                  <app-global-roster-student-programs-form [PersonID]="PersonID" [programId]="program.ProgramStudentID" [isEdit]="true" [canTabWrite]="canTabWrite"
                                                           (closeAddEdit)="updateList()"></app-global-roster-student-programs-form>
                </div>
              }
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
