@if (learnMore || report) {
  <p-accordion expandIcon="fas fa-question-circle" collapseIcon="fas fa-question-circle">
    <p-accordionTab header="Learn More" [selected]="showHelp">
      @if (learnMore?.What) {
        <div>
          <b><div [innerHTML]="learnMore.What"></div></b>
        </div>
      }
      @if (learnMore?.Who) {
        <div>
          <p-divider class="dark-divider"></p-divider>
          <h4>Who</h4>
          <div [innerHTML]="learnMore.Who"></div>
        </div>
      }
      @if (learnMore?.When) {
        <div>
          <p-divider class="dark-divider"></p-divider>
          <h4>When</h4>
          <div [innerHTML]="learnMore.When"></div>
        </div>
      }
      @if (learnMore?.Why) {
        <div>
          <p-divider class="dark-divider"></p-divider>
          <h4>Why</h4>
          <div [innerHTML]="learnMore.Why"></div>
        </div>
      }
      @if (learnMore?.How) {
        <div>
          <p-divider class="dark-divider"></p-divider>
          <h4>How</h4>
          <div [innerHTML]="learnMore.How"></div>
        </div>
      }
      @if (report) {
        <div>
          <p-divider class="dark-divider"></p-divider>
          <h3>How to Run a Report</h3>
          <ol>
            <li>Select the appropriate criteria</li>
            <li>Click the 'Run Report' button</li>
            <li>Print, e-mail, or export the report as necessary</li>
          </ol>
        </div>
      }
    </p-accordionTab>
  </p-accordion>
<!--} @else {-->
<!--  <p-accordion expandIcon="fas fa-question-circle" collapseIcon="fas fa-question-circle">-->
<!--    <p-accordionTab header="Learn More" [selected]="false"></p-accordionTab>-->
<!--  </p-accordion>-->
}
