import { BaseDataGridModel } from './data-grid.model';

export interface OrganizationsGridModel extends BaseDataGridModel {
  Data: OrganizationModel[];
}

export interface OrganizationModel {
  ID: number;
  OrganizationReferenceID: number;
  CharterLocation: string;
  CharterStateProvinceID: number;
  ByLawApprovalDate: string;
  NextElectionDate: string;
  MeetingDetails: string;
  StatusReason: string;
  StatusEffectiveDate: string;
  StatusID: number;
  Status: string;
  TypeID: number;
  Type: string;
  Name: string;
  CanEditName: boolean;
  Abbreviation: string;
  Number: string;
  CharterDate: string;
  CharterDescription: string;
  LM: string;
  FEIN: string;
  Rank: number;
  LogoUrl: string;
  BannerUrl: string;
  FiscalYearEndMonth: number;
  FiscalYearEndDay: number;
  BankRoutingNumber: string;
  BankAccountNumber: string;
  PACRoutingNumber: string;
  PACAccountNumber: string;
  ActiveRosterCount_N: number;
  RegisteredCount_N: number;
  WorkSiteCount_N: number;
  IsEmployer: boolean;
  IsReferenced: boolean;
  Address1: string;
  Address2: string;
  Address3: string;
  Address4: string;
  Address5: string;
  Address6: string;
  City: string;
  StateProvinceID: number;
  StateProvince: string;
  Country: string,
  Zip: string;
  ZipPlus4: string;
  CountryID: number;
  County: string;
  Latitude: number;
  Longitude: number;
  Verified: boolean;
  FailedCommunication: boolean;
  PhonePhoneTypeID: number;
  PhoneNumber: string;
  PhoneExtension: string;
  PhoneVerified: boolean;
  PhoneFailedCommunication: boolean;
  EmailAddress: string;
  EmailVerified: boolean;
  EmailFailedCommunication: boolean;
  ExternalAccounts: OrganizationExternalAccountsModel[];
  DirectAccess: boolean;
  UTStatusID: number;
  UTStatusDescription: string;
  SortString: string;
  Level: number;
  OrganizationType: string;
  CanProvisionSMSNumber: boolean;
  ProvisionSMSNumber: boolean;
  SMSNumber: string;
  ProvisionSMSNumberPrefix: string;
  ParentName: string;
  OrganizationTypeRank: number;
  organizationCategoryEnum: string;
  UsingDuesCollection: boolean;
  ConfigurationList: OrganizationConfigurationList[];
  HasIndependentChildren: boolean;
  PathwayDescriptionList: string[];
  BondAmount_N: number;
  TypeSort: number;
  SendBillPostedEmail: boolean;
  PrimaryTrade: string;
  HomeOrganization: string;
  HomeOrganizationAbbreviation: string;
  AllowWaitListOption?: boolean;
}

export interface EmployerModel extends OrganizationModel {
  BusinessName: string;
  WebsiteUri: string;
  PrimaryContact: string;
  HomeOrganizationID: number;
  Identifier: string;
  EmployerTypeID: number;
  EnterpriseTypeID: number;
  MinorityStatusID: number;
  Subdivision: boolean;
  Subsidiary: boolean;
  Language9a: boolean;
  PensionParticipating: boolean;
  JoinDate: string;
  DoNotSendJournal: boolean;
  FaxNumber: string;
  ClassificationID?: number;
}

export interface EmployerMemberGridModel extends BaseDataGridModel{
  Data: EmployerMemberModel[];
}

export interface EmployerMemberModel {
  OrganizationID: number;
  StartDate_D: string;
  EndDate_D: string;
  PersonID: number;
  CurrentSort: number;
  CanEdit: boolean;
  CanDelete: boolean;
  FirstName: string;
  LastName: string;
  MemberNumber: number,
  TypeAlias: string;
  StatusAlias: string;
  ActivePosition: boolean;
  OrganizationName: string;
}

export interface OrganizationExternalAccountsModel {
  ExternalAccountId: number;
  ExternalAccountType: string;
  ExternalAccountIdentifier: string;
  ExternalAccountName: string;
  Code: string;
  OtherData1: string;
  OtherData2: string;
  OtherData3: string;
}

export interface OrganizationConfigurationList {
  Enumerator: string;
  ValueString: string;
  ValueBoolean: boolean;
  ModifiedDate_D: string;
}

export interface OrganizationAttributesGridModel extends BaseDataGridModel {
  Data: OrganizationAttributeModel[];
}

export interface OrganizationAttributeModel {
  OrganizationID: number;
  OrganizationName: string;
  OrganizationNumber: string;
  DataTypeEnumerator: string;
  Attribute: string;
  AttributeOrganizationID: number;
  AttributeOrganizationName: string;
  Value: string;
  ValueNumber: number;
  ValueDateTime: string;
  ValueFull: string;
}

export interface EducationalOrganizationsGridModel extends BaseDataGridModel {
  Data: OrganizationModel[];
}

export interface EmployerOrganizationsGridModel extends BaseDataGridModel {
  Data: OrganizationModel[];
}

export interface WorksiteOrganizationsGridModel extends BaseDataGridModel {
  Data: OrganizationModel[];
}

export interface CreateOrganizationModel {
  Name: string;
  Abbreviation: string;
  Number: string;
  CharterDate: string;
  CharterDescription: string;
  LM: string;
  FEIN: string;
  Rank: number;
  activeRosterCount: number;
  registeredCount: number;
  workSiteCount: number;
  Address1: string;
  Address2: string;
  Address3: string;
  Address4: string;
  Address5: string;
  Address6: string;
  City: string;
  StateProvinceID: number;
  Zip: string;
  CountryID: number;
  Verified: boolean;
  FailedCommunication: boolean;
  PhonePhoneTypeID: number;
  PhoneNumber: string;
  PhoneExtension: string;
  PhoneVerified: boolean;
  PhoneFailedCommunication: boolean;
  EmailAddress: string;
  EmailVerified: boolean;
  EmailFailedCommunication: boolean;
}

export interface UpdateOrganizationModel {
  ID: number;
  OrganizationReferenceID: number;
  CharterLocation: string;
  CharterStateProvinceID: number;
  ByLawApprovalDate: string;
  NextElectionDate: string;
  MeetingDetails: string;
  StatusReason: string;
  StatusEffectiveDate: string;
  StatusID: number;
  Status: string;
  TypeID: number;
  Type: string;
  Name: string;
  Abbreviation: string;
  Number: string;
  CharterDate: string;
  CharterDescription: string;
  LM: string;
  FEIN: string;
  Rank: number;
  LogoUrl: string;
  BannerUrl: string;
  FiscalYearEndMonth: number;
  FiscalYearEndDay: number;
  BankRoutingNumber: string;
  BankAccountNumber: string;
  PACRoutingNumber: string;
  PACAccountNumber: string;
  ActiveRosterCount_N: number;
  RegisteredCount_N: number;
  WorkSiteCount_N: number;
  IsEmployer: boolean;
  IsReferenced: boolean;
  Address1: string;
  Address2: string;
  Address3: string;
  Address4: string;
  Address5: string;
  Address6: string;
  City: string;
  StateProvinceID: number;
  StateProvince: string;
  Zip: string;
  ZipPlus4: string;
  CountryID: number;
  County: string;
  Latitude: number;
  Longitude: number;
  Verified: boolean;
  FailedCommunication: boolean;
  PhonePhoneTypeID: number;
  PhoneNumber: string;
  PhoneExtension: string;
  PhoneVerified: boolean;
  PhoneFailedCommunication: boolean;
  EmailAddress: string;
  EmailVerified: boolean;
  EmailFailedCommunication: boolean;
  ExternalAccounts: OrganizationExternalAccountsModel[];
  DirectAccess: boolean;
  UTStatusID: number;
  UTStatusDescription: string;
  TypeSort: number;
  SendBillPostedEmail: boolean;
  BondAmount_N: number;
}

export interface CreateChildOrganizationModel {
  Name: string;
  Number: string;
  Rank: number;
  StatusID: number;
  TypeID: number;
  Address1: string;
  Address2: string;
  City: string;
  StateProvinceID: number;
  Zip: string;
  CountryID: number;
  PhonePhoneTypeID: number;
  PhoneNumber: string;
  PhoneExtension: string;
  EmailAddress: string;
}

export interface OrganizationStatisticsModel {
  NewMembers30day: number;
  MembersWithEmail: number;
  MembersWithOutEmail: number;
  ActiveMembers: number;
  RegisteredMembers: number;
  UnlinkedMembers: number;
  AutopayMembers: number;
  MemberCountDetails: OrganizationStatisticMemberCountDetailsModel[];
  MembersWithMobileNumber: number;
  MembersWithOutMobileNumber: number;
}

export interface OrganizationStatisticMemberCountDetailsModel {
  PersonProfileType: string;
  PersonProfileStatus: string;
  Active: boolean;
  MemberCount: number;
}

export interface UpdateDistributionAccountModel {
  ID: number;
  OrganizationId: number;
  Description: string;
  Name: string;
}

export interface UpstreamOrganizationsGridModel extends BaseDataGridModel {
  Data: UpstreamOrganizationModel[];
}

export interface UpstreamOrganizationModel {
  OrganizationID: number;
  OrganizationName: string;
  OrganizationType: string;
  OrganizationTypeRank: number;
  Blockable: boolean;
  AllowContact: boolean;
}

export interface OrganizationAttributeListModel {
  CustomFieldID: number;
  Description: string;
  Rank: number;
  DataTypeEnumerator: string;
  FilterDataTypeEnumerator: string;
  Shared: boolean;
  ResponseDBEntityDescription: string;
}

export interface OrganizationSharableAssigneesModel {
  PersonID: number;
  FirstName: string;
  LastName: string;
  Positions: string;
}

export interface OrganizationVisibilityGridModel extends BaseDataGridModel {
  Data: OrganizationVisibilityModel[];
}

export interface OrganizationVisibilityModel {
  AllowContact: boolean;
  Blockable: boolean;
  OrganizationID: number;
  OrganizationName: string;
  OrganizationType: string;
  OrganizationTypeRank: number;
}

export interface OrganizationBillingConfigurationModel {
  RemittancePhone: string;
  RemittancePhoneExtension: string;
  InquiryPhone: string;
  InquiryPhoneExtension: string;
  DueDateOffset: number;
  DueDateDayOfMonth: number;
  ChecksPayableName: string;
  RemittanceUsePrimaryAddress: boolean;
  RemittanceAddress1: string;
  RemittanceAddress2: string;
  RemittanceAddress3: string;
  RemittanceAddress4: string;
  RemittanceAddress5: string;
  RemittanceAddress6: string;
  RemittanceCity: string;
  RemittanceStateProvinceID: number;
  RemittanceZip: string;
  RemittanceZipPlus4: string;
  RemittanceCountryID: number;
  PrimaryAddress1: string;
  PrimaryAddress2: string;
  PrimaryAddress3: string;
  PrimaryAddress4: string;
  PrimaryAddress5: string;
  PrimaryAddress6: string;
  PrimaryCity: string;
  PrimaryStateProvince: string;
  PrimaryZip: string;
  PrimaryZipPlus4: string;
  PrimaryCountry: string;
  InvoiceNotes: string;
  SenderDisplayName: string;
  SenderEmailAddress: string;
  EmailSignature: string
}

export interface OrganizationPctConfigurationModel {
  AllowACH: boolean;
  AllowCC: boolean;
  BillingFee: number;
  BillingFeeDescription: string;
  BillingFeeExcludeList: OrganizationPctConfigurationFeeExcludeListModel[];
  DueDateOffset?: number;
  ChecksPayableName: string;
  RemittanceUsePrimaryAddress: boolean;
  RemittanceAddress1: string;
  RemittanceAddress2: string;
  RemittanceAddress3: string;
  RemittanceAddress4: string;
  RemittanceAddress5: string;
  RemittanceAddress6: string;
  RemittanceCity: string;
  RemittanceStateProvinceID: number;
  RemittanceZip: string;
  RemittanceZipPlus4: string;
  RemittanceCountryID: number;
  PrimaryAddress1: string;
  PrimaryAddress2: string;
  PrimaryAddress3: string;
  PrimaryAddress4: string;
  PrimaryAddress5: string;
  PrimaryAddress6: string;
  PrimaryCity: string;
  PrimaryStateProvince: string;
  PrimaryZip: string;
  PrimaryZipPlus4: string;
  PrimaryCountry: string;
  TemplateCapList: OrganizationPctConfigurationTemplateCapListModel[];
  AdjustmentCapList: OrganizationPctConfigurationAdjustmentCapListModel[];
  InvoiceNotes: string;
  MerchantAccountID: number;
  SenderDisplayName: string;
  SenderEmailAddress: string;
  EmailSignature: string
}

export interface OrganizationPctConfigurationFeeExcludeListModel {
  OrganizationID: number;
  OrganizationName: string;
}

export interface OrganizationPctConfigurationTemplateCapListModel {
  MoneyAgentID: number;
  MoneyAgentDescription?: string;
  Cap: number;
  OverrideList?: OrganizationPctConfigurationTemplateCapOverrideListModel[];
}

export interface OrganizationPctConfigurationTemplateCapOverrideListModel {
  OrganizationID: number;
  OrganizationName?: string;
  CapOverride: number;
}

export interface OrganizationPctConfigurationAdjustmentCapListModel {
  BillingAdjustmentID: number;
  BillingAdjustmentDescription?: string;
  Cap: number;
}

export interface OrganizationProjectModel {
  OrganizeProjectOrganizationID?: number;
  OrganizeProjectID: number;
  OrganizeProjectName?: string;
  OrganizationID: number;
  OrganizationName?: string;
  UnionOrganization?: boolean,
  Trades?: OrganizationProjectTypeTradesModel[];
  OrganizationTypes?: OrganizationProjectTypeTradesModel[];
  CanEdit?: false,
  CanDelete?: false
}

export interface OrganizationProjectTradesModel {
  TradeID: number;
  TradeDescription: string;
}

export interface OrganizationProjectTypeModel {
  OrganizeProjectOrganizationTypeID: number;
  OrganizeProjectOrganizationTypeDescription: string;
}

export interface OrganizationProjectTypeTradesModel {
  ID: number;
  Description: string;
}
