import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, NgOptimizedImage, PercentPipe} from '@angular/common';
import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputNumberModule} from 'primeng/inputnumber';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccordionModule} from 'primeng/accordion';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DividerModule} from 'primeng/divider';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MenubarModule} from 'primeng/menubar';
import {ListboxModule} from 'primeng/listbox';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {BadgeModule} from 'primeng/badge';
import {PanelModule} from 'primeng/panel';
import {ToolbarModule} from 'primeng/toolbar';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputMaskModule} from 'primeng/inputmask';
import {SliderModule} from 'primeng/slider';
import {PaginatorModule} from 'primeng/paginator';
import {CalendarModule} from 'primeng/calendar';
import {ToastModule} from 'primeng/toast';
import {TabViewModule} from 'primeng/tabview';
import {EditorModule} from '@tinymce/tinymce-angular';
import {SignaturePadComponent} from './components/signature-pad/signature-pad.component';
// Services
import {DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {PickListModule} from 'primeng/picklist';
import {WebcamModule} from 'ngx-webcam';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {HighchartsChartModule} from 'highcharts-angular';
import {InputSwitchModule} from 'primeng/inputswitch';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {DistListLookupService} from '../services/dist-list-lookups.service';
import {FilterListLookupService} from '../services/filter-list-lookups.service';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {GoogleMapsModule} from '@angular/google-maps';
import {TimelineModule} from 'primeng/timeline';
import {HttpClientJsonpModule} from '@angular/common/http';
import {NgxPhotoEditorModule} from 'ngx-photo-editor';
// import { OrganizationChartModule } from 'primeng/organizationchart';
import {TreeModule} from 'primeng/tree';
import {FocusTrapModule} from 'primeng/focustrap';
import {SelectButtonModule} from 'primeng/selectbutton';

// Components
import {SelectComponent} from './components/select/select.component';
import {FilterMyDataComponent} from './components/select/filter-my-data-select/filter-my-data.component';
import {DistListSelectComponent} from './components/select/dist-list-select/dist-list-select.component';
import {MultiSelectComponent} from './components/multi-select/multi-select.component';
import {MultiSelectUniversalComponent} from './components/multi-select-universal/multi-select-universal.component';
import {CommunicationsTabComponent} from './components/tabs/communications-tab/communications-tab.component';
import {CommunicationsFormComponent} from './components/tabs/communications-tab/add-edit-communications/communications-form.component';
import {CommunicationsUserProfileTabComponent} from './components/tabs/communications-user-profile-tab/communications-user-profile-tab.component';
import {CommunicationsUserProfileFormComponent} from './components/tabs/communications-user-profile-tab/add-edit-communications-user-profile/communications-user-profile-form.component';
import {DocsTabComponent} from './components/tabs/docs-tab/docs-tab.component';
import {DocsFormComponent} from './components/tabs/docs-tab/add-edit-docs/docs-form.component';
import {NotesTabComponent} from './components/tabs/notes-tab/notes-tab.component';
import {NotesFormComponent} from './components/tabs/notes-tab/add-edit-notes/notes-form.component';
import {WebcamComponent} from './components/webcam/webcam.component';
import {ChangeLogsTabComponent} from './components/tabs/change-logs-tab/change-logs-tab.component';
import {CustomFieldsTabComponent} from './components/tabs/custom-fields-tab/custom-fields-tab.component';
import {CustomFieldsFormComponent} from './components/tabs/custom-fields-tab/add-edit-custom-fields/custom-fields-form.component';
import {UserAccountTabComponent} from './components/tabs/user-account-tab/user-account-tab.component';
import {EmploymentTradeTabComponent} from './components/tabs/employment-trade-tab/employment-trade-tab.component';
import {EmploymentDefaultTabComponent} from './components/tabs/employment-default-tab/employment-tab.component';
import {EmploymentDefaultFormComponent} from './components/tabs/employment-default-tab/add-edit-employment-default/add-edit-employment-default.component';
import {EmploymentTradeFormComponent} from './components/tabs/employment-trade-tab/add-edit-employment-trade/add-edit-employment-trade.component';
import {HoursReportedComponent} from './components/tabs/employment-trade-tab/hours-reported/hours-reported.component';
import {AccountTransactionsTabComponent} from './components/tabs/account-transactions-tab/account-transactions-tab.component';
import {AccountStatementTransactionsTabComponent} from './components/tabs/account-transactions-tab/account-statement-transactions-tab/account-statement-transactions-tab.component';
import {TransactionHistoryTabComponent} from './components/tabs/account-transactions-tab/transaction-history-tab/transaction-history-tab.component';
import {PaymentMethodsTabComponent} from './components/tabs/payment-methods-tab/payment-methods-tab.component';
import {PaymentMethodsFormComponent} from './components/tabs/payment-methods-tab/add-edit-payment-methods/payment-methods-form.component';
import {AddressesTabComponent} from './components/tabs/addresses-tab/addresses-tab.component';
import {AddressesFormComponent} from './components/tabs/addresses-tab/add-edit-addresses/addresses-form.component';
import {TrainingCertificationsTabComponent} from './components/tabs/training-certifications-tab/training-certifications-tab.component';
import {TrainingCertificationsFormComponent} from './components/tabs/training-certifications-tab/add-edit-training-certifications/training-certifications-form.component';
import {PositionsListComponent} from './components/tabs/organization-positions-tab/organization-positions-list/organization-positions-list.component';
import {PositionsTabComponent} from './components/tabs/organization-positions-tab/organization-positions-tab.component';
import {PositionsFormComponent} from './components/tabs/organization-positions-tab/organization-positions-list/add-edit-organization-positions/organization-positions-form.component';
import {PoliticsTabComponent} from './components/tabs/politics-tab/politics-tab.component';
import {OrganizationAttributesTabComponent} from './components/tabs/organization-attributes-tab/organization-attributes-tab.component';
import {OrganizationAttributesFormComponent} from './components/tabs/organization-attributes-tab/add-edit-organization-attributes/organization-attributes-form.component';
import {RosterStudentProgramsSubTabComponent} from '../roster-student/roster-student-tabs/roster-student-programs/roster-student-programs-subtab/roster-student-programs.component';
import {RosterStudentProgramsFormComponent} from '../roster-student/roster-student-tabs/roster-student-programs/roster-student-programs-subtab/add-edit-student-program/roster-student-programs-form.component';
import {EmployersTabDetailsComponent} from '../employers/employers-tabs/employers-tab-details/employers-tab-details.component';
import {RosterStudentProgramsComponent} from '../roster-student/roster-student-tabs/roster-student-programs/roster-student-programs.component';
import {StudentStipendHistoryComponent} from '../roster-student/roster-student-tabs/roster-student-programs/roster-student-programs-subtab/add-edit-student-program/student-stipend-history/student-stipend-history.component';
import {RosterStudentWageRatesSubTabComponent} from '../roster-student/roster-student-tabs/roster-student-programs/roster-student-wage-rates-subtab/roster-student-wage-rates-subtab.component';
import {RosterTabSecureDataComponent} from './components/tabs/roster-tab-secure-data/roster-tab-secure-data.component';
import {AdhocCommDialogComponent} from './components/adhoc-comm-dialog/adhoc-comm-dialog.component';
import {AdhocEmailFormComponent} from './components/adhoc-comm-dialog/adhoc-email-form/adhoc-email-form.component';
import {AdhocPhoneFormComponent} from './components/adhoc-comm-dialog/adhoc-phone-form/adhoc-phone-form.component';
import {SettingsComponent} from '../settings/settings.component';
import {TextEditorComponent} from './components/text-editor/text-editor.component';
import {TextEditorEditHtmlComponent} from './components/text-editor/text-editor-edit-html/text-editor-edit-html.component';
import {TimeNewEntryComponent} from './components/time-entry/time-new-entry/time-new-entry.component';
import {TimeEntryFormComponent} from './components/time-entry/time-new-entry/time-entry-form/time-entry-form.component';
import {TimeRejectDialogComponent} from './components/time-entry/time-new-entry/time-reject-dialog/time-reject-dialog.component';
import {TradeSkillsComponent} from './components/tabs/trade-skills-tab/trade-skills.component';
import {EditTradesSkillsDialogComponent} from './components/tabs/trade-skills-tab/edit-trades-skills-dialog/edit-trades-skills-dialog.component';
import {DistributionListComponent} from './components/distribution-list/distribution-list/distribution-list.component';
import {DistributionFilterFormComponent} from './components/distribution-list/distribution-filter-form/distribution-filter-form.component';
import {DistributionListNewEditDialogComponent} from './components/distribution-list/distribution-list-new-edit-dialog/distribution-list-new-edit-dialog.component';
import {DistributionFilterExistingFormComponent} from './components/distribution-list/distribution-filter-existing-form/distribution-filter-existing-form.component';
import {AuxiliaryGridComponent} from './components/auxiliary-table-filters/auxilary-list/auxiliary-grid.component';
import {AuxiliaryFilterFormComponent} from './components/auxiliary-table-filters/auxiliary-filter-form/auxiliary-filter-form.component';
import {AuxiliaryListNewEditDialogComponent} from './components/auxiliary-table-filters/auxiliary-list-new-edit-dialog/auxiliary-list-new-edit-dialog.component';
import {AuxiliaryFilterExistingFormComponent} from './components/auxiliary-table-filters/auxiliary-filter-existing-form/auxiliary-filter-existing-form.component';
import {LearnMoreComponent} from './components/learn-more/learn-more.component';
import {AdhocMailMergeFormComponent} from './components/adhoc-mail-merge/adhoc-mail-merge-form.component';
import {InfoTooltipComponent} from './components/info-tooltip/info-tooltip.component';
import {PositionOptionsComponent} from './components/tabs/organization-positions-tab/organization-position-options/organization-position-options.component';
import {AddNewOrganizationPositionComponent} from './components/tabs/organization-positions-tab/add-new-organization-position/add-new-organization-position.component';
import {AuxTablePreviewDialogComponent} from './components/auxiliary-table-filters/auxiliary-filter-existing-form/aux-table-preview/aux-table-preview-dialog.component';
import {MapsDialogComponent} from './components/maps-dialog/maps-dialog.component';
import {ChartDialogComponent} from './components/chart-dialog/chart-dialog.component';
import {MakePaymentTabComponent} from './components/tabs/make-payment-tab/make-payment-tab.component';
import {ProgramStudentIncomingHoursDialogComponent} from './components/program-student-incoming-hours-dialog/program-student-incoming-hours-dialog.component';
import {MediaManagerDialogComponent} from './components/unlayer-editor/media-manager/media-manager-dialog.component';
import {UnlayerEditorComponent} from './components/unlayer-editor/unlayer-editor.component';
import {EmergencyContactComponent} from './components/emergency-contacts/emergency-contact.component';
import {SmsDialogComponent} from './components/sms-dialog/sms-dialog.component';
import {AutopayTabComponent} from './components/tabs/autopay-tab/autopay-tab.component';
import {OrganizationConfigureTabComponent} from './components/tabs/organization-configure-tab/organization-configure-tab.component';
import {AddEmployerFormComponent} from './components/add-employer/add-employer-form.component';
import {YesNoSelectComponent} from './components/select/yes-no-select/yes-no-select.component';
import {PctRecordPaymentComponent} from './components/pct-record-payment/pct-record-payment.component';
import {DynamicCustomFieldsComponent} from './components/dynamic-custom-fields/dynamic-custom-fields.component';
import {StudentTrainingCertificationComponent} from './components/tabs/student-training-certification/student-training-certification.component';
import {StudentClassHistoryComponent} from './components/tabs/student-training-certification/student-class-history/student-class-history.component';
import {StudentClassFormComponent} from './components/tabs/student-training-certification/student-class-history/add-edit-student-class/student-class-form.component';
import {ClassTabDialogContainerComponent} from './components/tabs/student-training-certification/class-modules-dialog-tab-container/class-tab-container.component';
import {WorkflowStepOutcomesTabComponent} from './components/tabs/workflow-step-outcomes-tab/workflow-step-outcomes-tab.component';
import {WorkflowStepOutcomesFormComponent} from './components/tabs/workflow-step-outcomes-tab/add-edit-workflow-step-outcomes/workflow-step-outcomes-form.component';
import {WorkflowStepsTabComponent} from './components/tabs/workflow-steps-tab/workflow-steps-tab.component';
import {WorkflowStepsListComponent} from './components/tabs/workflow-steps-tab/workflow-steps-list/workflow-steps-list.component';
import {WorkflowStepsFormComponent} from './components/tabs/workflow-steps-tab/workflow-steps-list/add-edit-workflow-steps/workflow-steps-form.component';
import {WorkflowStepOptionsComponent} from './components/tabs/workflow-steps-tab/workflow-step-options/workflow-step-options.component';
import {AddNewWorkflowStepComponent} from './components/tabs/workflow-steps-tab/add-new-workflow-step/add-new-workflow-step.component';
import {MakePCTPaymentTabComponent} from './components/tabs/make-pct-payment-tab/make-pct-payment-tab.component';
import {RosterTabActivitiesComponent} from '../roster/roster-tabs/roster-tab-activities/roster-tab-activities.component';
import {RosterTabActivitiesTabsContainerComponent} from '../roster/roster-tabs/roster-tab-activities/roster-tab-activities-tabs-container/roster-tab-activities-tabs-container.component';
import {MemberActivitiesComponent} from './components/member-activities/member-activities.component';
import {AdminBalanceCorrectionToolComponent} from './components/admin-balance-correction-tool/admin-balance-correction-tool.component';
import {ListFilterComponent} from './components/list-filter/list-filter.component';
import {AlertDialogComponent} from './components/alert/alert-dialog.component';
import {RecordNotFoundComponent} from './components/record-not-found/record-not-found.component';
import {UniversalDatePickerComponent} from './components/universal-date-picker/universal-date-picker.component';
import {DataIndicatorComponent} from './components/data-indicator/data-indicator.component';
import {ImageEditorComponent} from './components/image-editor/image-editor.component';
import {StudentProgramHoursDialogComponent} from './components/student-program-hours-dialog/student-program-hours-dialog.component';
import {MemberProfileDialogComponent} from './components/member-profile-dialog/member-profile-dialog.component';
import {MembershipCardDialogComponent} from './components/membership-card-dialog/membership-card-dialog.component';
import {DateMaskDirective} from './directives/calendar-mask.component';
import {AddressFormComponent} from './components/address-form/address-form.component';
import {DeletePersonComponent} from './components/delete-person/delete-person.component';
import {ConfirmDeleteDialogComponent} from './components/delete-person/confirm-delete/confirm-delete-dialog.component';
import {ShowApplicantLinkDialogComponent} from './components/tabs/docs-tab/show-applicant-link-dialog/show-applicant-link-dialog.component';
import {ConfirmExportDialogComponent} from './components/auxiliary-table-filters/auxilary-list/confirm-exort-dialog/confirm-export-dialog.component';
import {ActionButtonsComponent} from './components/action-buttons/action-buttons.component';

// Definitions
import {RosterDefinitions} from './definitions/roster.definition';
import {RosterStudentDefinitions} from './definitions/roster-student.definition';
import {ProspectDefinitions} from './definitions/prospects.definition';
import {DistributionListDefinitions} from './definitions/distribution-list.definitions';
import {DateRangePickerDefinitions} from './definitions/date-range-picker.definitions';
import {ExemptUsersDefinitions} from './definitions/exempt-users.definitions';
import {TenantFilterDefinitions} from './definitions/tenant-filter.definitions';

// Pipes
import {PhonePipe} from './pipes/phone.pipe';
import {ToFixedPipe} from './pipes/tofixed.pipe';
import {ToFixedCurrencyPipe} from './pipes/tofixed-currency.pipe';
import {CamelcaseSpacePipe} from './pipes/camelcase-space.pipe';
import {StringReplacePipe} from './pipes/stringreplace.pipe';
import {LocalizeDateTimePipe, ShortLocalizeDateTimePipe} from './pipes/localize-datetime.pipe';
import {StatePipe} from './pipes/state.pipe';
import {MatchTypeReplace} from './pipes/filter.pipes';
import {FirstLetter} from './pipes/first-letter.pipe';
import {YesNoPipe} from './pipes/boolean.pipe';
import {SafeHtmlPipe} from './pipes/html-sanitizer.pipte';

// Utilities
import {DialogUtility} from './utilities/dialog.utility';
import {PhoneValidationUtility} from './utilities/phone-validation.utility';
import {RouteContextUtility} from './utilities/route-context.utility';
import {ConfirmValidationUtility} from './utilities/confirm-validation.utility';
import {DymoLabelUtility} from './utilities/dymo-label.utility';
import {PageRowCountUtility} from './utilities/page-row-count.utility';
import {PDFExportUtility} from './utilities/pdf-export.utility';
import {DialogModule} from 'primeng/dialog';
import {PageService} from '../services/page.services';
import {ConvertAuxFiltersUtility} from './utilities/convert-aux-filters.utility';
import {ClassTabDetailsComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-details/class-tab-details.component';
import {ClassTabStudentsComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-students/class-tab-students.component';
import {ClassStudentFormComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-students/add-edit-class-students/class-student-form.component';
import {ClassGridComponent} from '../classes-modules/class-modules-grid/classes-grid.component';
import {ClassConfirmMultipleDialogComponent} from '../classes-modules/classes-add-multiple/class-confirm-multiple-dialog/class-confirm-multiple-dialog.component';
import {ClassTabVirtualComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-virtual/class-tab-virtual.component';
import {ClassTabAttendanceComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-attendance/class-tab-attendance.component';
import {ClassAttendanceFormComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-attendance/add-edit-class-attendance/class-attendance-form.component';
import {ClassTabGradesComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-grades/class-tab-grades.component';
import {ClassTabStaffComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-staff/class-tab-staff.component';
import {ClassStaffFormComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-staff/add-edit-class-staff/class-staff-form.component';
import {ClassesAddMultipleComponent} from '../classes-modules/classes-add-multiple/classes-add-multiple.component';
import {StudentsCompleteDialogComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-students/students-complete-dialog/students-complete-dialog.component';
import {AddCertificateDialogComponent} from '../classes-modules/class-modules-tabs/class-modules-tab-grades/add-certificate-dialog/add-certificate-dialog.component';
import {ReportListDefinitions} from './definitions/reports-list.definitions';
import {CurrencyInputComponent} from './components/currency-input/currency-input.component';
import {BooleanCheckboxComponent} from './components/boolean-checkbox/boolean-checkbox.component';
import {MultiSelectFilterListComponent} from './components/multi-select-filter-list/multi-select-filter-list.component';
import {LastInLoopComponent} from './components/list-filter/last-in-loop.component';
import {InactiveStatusUtility} from './utilities/inactive-status.utility';
import {NgxCurrencyDirective} from 'ngx-currency';
import {SnapshotDefinitions} from './definitions/snapshot.definitions';
import {MultiSelectLanguagesComponent} from './components/multi-select-languages/multi-select-languages.component';

@NgModule({
  imports: [
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CheckboxModule,
    ConfirmDialogModule,
    DividerModule,
    ButtonModule,
    SplitButtonModule,
    RadioButtonModule,
    MenubarModule,
    ListboxModule,
    TableModule,
    DropdownModule,
    ProgressSpinnerModule,
    BadgeModule,
    PanelModule,
    ToolbarModule,
    CardModule,
    CommonModule,
    MultiSelectModule,
    SliderModule,
    InputMaskModule,
    CalendarModule,
    ToastModule,
    TabViewModule,
    DynamicDialogModule,
    FileUploadModule,
    PickListModule,
    PaginatorModule,
    WebcamModule,
    OverlayPanelModule,
    HighchartsChartModule,
    InputSwitchModule,
    AutoCompleteModule,
    DialogModule,
    VirtualScrollerModule,
    NgScrollbarModule,
    GoogleMapsModule,
    TimelineModule,
    HttpClientJsonpModule,
    // OrganizationChartModule,
    TreeModule,
    FocusTrapModule,
    NgxPhotoEditorModule,
    NgOptimizedImage,
    SelectButtonModule,
    NgxCurrencyDirective,
    EditorModule
  ],
  exports: [
    CommonModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CheckboxModule,
    ConfirmDialogModule,
    DividerModule,
    ButtonModule,
    SplitButtonModule,
    RadioButtonModule,
    MenubarModule,
    ListboxModule,
    TableModule,
    DropdownModule,
    ProgressSpinnerModule,
    BadgeModule,
    PanelModule,
    ToolbarModule,
    CardModule,
    MultiSelectModule,
    SliderModule,
    InputMaskModule,
    PaginatorModule,
    CalendarModule,
    SelectComponent,
    FilterMyDataComponent,
    MultiSelectComponent,
    MultiSelectLanguagesComponent,
    DistListSelectComponent,
    MultiSelectUniversalComponent,
    ToastModule,
    PhonePipe,
    MatchTypeReplace,
    ToFixedPipe,
    StatePipe,
    FirstLetter,
    ToFixedCurrencyPipe,
    CamelcaseSpacePipe,
    StringReplacePipe,
    YesNoPipe,
    SafeHtmlPipe,
    TabViewModule,
    VirtualScrollerModule,
    DocsTabComponent,
    DocsFormComponent,
    NotesTabComponent,
    NotesFormComponent,
    CommunicationsTabComponent,
    CommunicationsFormComponent,
    CommunicationsUserProfileTabComponent,
    CommunicationsUserProfileFormComponent,
    RosterStudentProgramsComponent,
    StudentStipendHistoryComponent,
    DynamicDialogModule,
    FileUploadModule,
    PickListModule,
    WebcamModule,
    WebcamComponent,
    OverlayPanelModule,
    HighchartsChartModule,
    InputSwitchModule,
    ChangeLogsTabComponent,
    CustomFieldsTabComponent,
    CustomFieldsFormComponent,
    UserAccountTabComponent,
    EmploymentTradeTabComponent,
    EmploymentDefaultTabComponent,
    EmploymentDefaultFormComponent,
    EmploymentTradeFormComponent,
    RosterStudentWageRatesSubTabComponent,
    HoursReportedComponent,
    AccountTransactionsTabComponent,
    AccountStatementTransactionsTabComponent,
    TransactionHistoryTabComponent,
    PaymentMethodsTabComponent,
    PaymentMethodsFormComponent,
    AddressesTabComponent,
    AddressesFormComponent,
    TrainingCertificationsTabComponent,
    TrainingCertificationsFormComponent,
    PositionsListComponent,
    PositionsTabComponent,
    PositionOptionsComponent,
    PositionsFormComponent,
    AddNewOrganizationPositionComponent,
    PoliticsTabComponent,
    OrganizationAttributesTabComponent,
    OrganizationAttributesFormComponent,
    AutoCompleteModule,
    RosterStudentProgramsSubTabComponent,
    RosterStudentProgramsFormComponent,
    StudentClassFormComponent,
    AdhocCommDialogComponent,
    AdhocPhoneFormComponent,
    AdhocEmailFormComponent,
    SettingsComponent,
    TextEditorComponent,
    TextEditorEditHtmlComponent,
    TimeNewEntryComponent,
    TimeEntryFormComponent,
    TimeRejectDialogComponent,
    TradeSkillsComponent,
    EditTradesSkillsDialogComponent,
    DistributionFilterFormComponent,
    DistributionListNewEditDialogComponent,
    DistributionListComponent,
    DistributionFilterExistingFormComponent,
    AuxiliaryGridComponent,
    AuxiliaryListNewEditDialogComponent,
    AuxiliaryFilterFormComponent,
    AuxiliaryFilterExistingFormComponent,
    LearnMoreComponent,
    AdhocMailMergeFormComponent,
    LocalizeDateTimePipe,
    ShowApplicantLinkDialogComponent,
    SafeHtmlPipe,
    InfoTooltipComponent,
    AuxTablePreviewDialogComponent,
    MapsDialogComponent,
    ChartDialogComponent,
    MakePaymentTabComponent,
    MakePCTPaymentTabComponent,
    ProgramStudentIncomingHoursDialogComponent,
    NgScrollbarModule,
    UnlayerEditorComponent,
    MediaManagerDialogComponent,
    EmergencyContactComponent,
    SmsDialogComponent,
    AutopayTabComponent,
    OrganizationConfigureTabComponent,
    AddEmployerFormComponent,
    YesNoSelectComponent,
    PctRecordPaymentComponent,
    DynamicCustomFieldsComponent,
    StudentTrainingCertificationComponent,
    StudentClassHistoryComponent,
    RosterTabSecureDataComponent,
    ClassTabDialogContainerComponent,
    ClassTabDetailsComponent,
    ClassTabStudentsComponent,
    ClassStudentFormComponent,
    ClassGridComponent,
    ClassConfirmMultipleDialogComponent,
    ClassTabVirtualComponent,
    ClassTabAttendanceComponent,
    ClassAttendanceFormComponent,
    ClassTabGradesComponent,
    ClassTabStaffComponent,
    ClassStaffFormComponent,
    ClassesAddMultipleComponent,
    StudentsCompleteDialogComponent,
    AddCertificateDialogComponent,
    WorkflowStepOutcomesTabComponent,
    WorkflowStepOutcomesFormComponent,
    WorkflowStepsTabComponent,
    WorkflowStepsListComponent,
    WorkflowStepsFormComponent,
    WorkflowStepOptionsComponent,
    AddNewWorkflowStepComponent,
    EmployersTabDetailsComponent,
    RosterTabActivitiesComponent,
    RosterTabActivitiesTabsContainerComponent,
    MemberActivitiesComponent,
    AdminBalanceCorrectionToolComponent,
    GoogleMapsModule,
    CurrencyInputComponent,
    BooleanCheckboxComponent,
    TimelineModule,
    ListFilterComponent,
    HttpClientJsonpModule,
    // OrganizationChartModule,
    TreeModule,
    AlertDialogComponent,
    RecordNotFoundComponent,
    FocusTrapModule,
    UniversalDatePickerComponent,
    DataIndicatorComponent,
    NgxPhotoEditorModule,
    ImageEditorComponent,
    StudentProgramHoursDialogComponent,
    NgOptimizedImage,
    ImageEditorComponent,
    MemberProfileDialogComponent,
    MembershipCardDialogComponent,
    DateMaskDirective,
    AddressFormComponent,
    SelectButtonModule,
    MultiSelectFilterListComponent,
    DeletePersonComponent,
    ConfirmDeleteDialogComponent,
    ConfirmExportDialogComponent,
    EditorModule,
    SignaturePadComponent,
    ActionButtonsComponent
  ],
  declarations: [
    SelectComponent,
    MultiSelectComponent,
    MultiSelectLanguagesComponent,
    DistListSelectComponent,
    MultiSelectUniversalComponent,
    PhonePipe,
    MatchTypeReplace,
    ToFixedPipe,
    ToFixedCurrencyPipe,
    CamelcaseSpacePipe,
    StringReplacePipe,
    YesNoPipe,
    StatePipe,
    SafeHtmlPipe,
    FirstLetter,
    DocsTabComponent,
    DocsFormComponent,
    NotesTabComponent,
    NotesFormComponent,
    CommunicationsTabComponent,
    CommunicationsFormComponent,
    CommunicationsUserProfileTabComponent,
    CommunicationsUserProfileFormComponent,
    WebcamComponent,
    ChangeLogsTabComponent,
    CustomFieldsTabComponent,
    CustomFieldsFormComponent,
    UserAccountTabComponent,
    EmploymentTradeTabComponent,
    EmploymentDefaultTabComponent,
    EmploymentDefaultFormComponent,
    RosterStudentWageRatesSubTabComponent,
    EmploymentTradeFormComponent,
    RosterStudentProgramsComponent,
    StudentStipendHistoryComponent,
    HoursReportedComponent,
    AccountTransactionsTabComponent,
    AccountStatementTransactionsTabComponent,
    TransactionHistoryTabComponent,
    PaymentMethodsTabComponent,
    PaymentMethodsFormComponent,
    AddressesTabComponent,
    AddressesFormComponent,
    TrainingCertificationsTabComponent,
    TrainingCertificationsFormComponent,
    PositionsListComponent,
    PositionOptionsComponent,
    PositionsTabComponent,
    AddNewOrganizationPositionComponent,
    PositionsFormComponent,
    PoliticsTabComponent,
    ShowApplicantLinkDialogComponent,
    OrganizationAttributesTabComponent,
    OrganizationAttributesFormComponent,
    RosterStudentProgramsSubTabComponent,
    RosterStudentProgramsFormComponent,
    StudentClassFormComponent,
    AdhocCommDialogComponent,
    AdhocPhoneFormComponent,
    AdhocEmailFormComponent,
    SettingsComponent,
    TextEditorComponent,
    TextEditorEditHtmlComponent,
    TimeNewEntryComponent,
    TimeEntryFormComponent,
    TimeRejectDialogComponent,
    TradeSkillsComponent,
    EditTradesSkillsDialogComponent,
    DistributionFilterFormComponent,
    DistributionListNewEditDialogComponent,
    DistributionListComponent,
    DistributionFilterExistingFormComponent,
    AuxiliaryGridComponent,
    AuxiliaryListNewEditDialogComponent,
    AuxiliaryFilterFormComponent,
    AuxiliaryFilterExistingFormComponent,
    LearnMoreComponent,
    AdhocMailMergeFormComponent,
    LocalizeDateTimePipe,
    ShortLocalizeDateTimePipe,
    InfoTooltipComponent,
    AuxTablePreviewDialogComponent,
    MapsDialogComponent,
    ChartDialogComponent,
    MakePaymentTabComponent,
    MakePCTPaymentTabComponent,
    ProgramStudentIncomingHoursDialogComponent,
    UnlayerEditorComponent,
    MediaManagerDialogComponent,
    EmergencyContactComponent,
    SmsDialogComponent,
    AutopayTabComponent,
    OrganizationConfigureTabComponent,
    AddEmployerFormComponent,
    YesNoSelectComponent,
    FilterMyDataComponent,
    PctRecordPaymentComponent,
    DynamicCustomFieldsComponent,
    StudentTrainingCertificationComponent,
    StudentClassHistoryComponent,
    RosterTabSecureDataComponent,
    ClassTabDialogContainerComponent,
    ClassTabDetailsComponent,
    ClassTabStudentsComponent,
    ClassStudentFormComponent,
    ClassGridComponent,
    ClassConfirmMultipleDialogComponent,
    ClassTabVirtualComponent,
    ClassTabAttendanceComponent,
    ClassAttendanceFormComponent,
    ClassTabGradesComponent,
    ClassTabStaffComponent,
    ClassStaffFormComponent,
    ClassesAddMultipleComponent,
    StudentsCompleteDialogComponent,
    AddCertificateDialogComponent,
    WorkflowStepOutcomesTabComponent,
    WorkflowStepOutcomesFormComponent,
    WorkflowStepsTabComponent,
    WorkflowStepsListComponent,
    WorkflowStepsFormComponent,
    WorkflowStepOptionsComponent,
    AddNewWorkflowStepComponent,
    EmployersTabDetailsComponent,
    RosterTabActivitiesComponent,
    RosterTabActivitiesTabsContainerComponent,
    MemberActivitiesComponent,
    AdminBalanceCorrectionToolComponent,
    CurrencyInputComponent,
    BooleanCheckboxComponent,
    ListFilterComponent,
    MultiSelectFilterListComponent,
    LastInLoopComponent,
    AlertDialogComponent,
    RecordNotFoundComponent,
    UniversalDatePickerComponent,
    DataIndicatorComponent,
    ImageEditorComponent,
    StudentProgramHoursDialogComponent,
    MemberProfileDialogComponent,
    MembershipCardDialogComponent,
    DateMaskDirective,
    AddressFormComponent,
    DeletePersonComponent,
    ConfirmDeleteDialogComponent,
    ConfirmExportDialogComponent,
    SignaturePadComponent,
    ActionButtonsComponent
  ],
  providers: [
    DialogUtility,
    DialogService,
    DynamicDialogConfig,
    ConvertAuxFiltersUtility,
    DynamicDialogRef,
    RosterDefinitions,
    RosterStudentDefinitions,
    ProspectDefinitions,
    PhoneValidationUtility,
    InactiveStatusUtility,
    PhonePipe,
    DatePipe,
    CurrencyPipe,
    RouteContextUtility,
    PageService,
    DistributionListDefinitions,
    ConfirmValidationUtility,
    DateRangePickerDefinitions,
    ExemptUsersDefinitions,
    ToFixedCurrencyPipe,
    CamelcaseSpacePipe,
    StringReplacePipe,
    DymoLabelUtility,
    PageRowCountUtility,
    LocalizeDateTimePipe,
    ShortLocalizeDateTimePipe,
    DistListLookupService,
    FilterListLookupService,
    ReportListDefinitions,
    TenantFilterDefinitions,
    DecimalPipe,
    PercentPipe,
    PDFExportUtility,
    SnapshotDefinitions
  ]
})
export class SharedModule {
}
