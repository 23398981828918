import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RosterService} from '../../../../services/roster.service';
import {TradeSkillsService} from '../../../../services/trade-skills.service';
import {TradeSkillCollectionModel, TradeSkillModel, UpdatePrimaryTradeModel} from '../../../../models/trade-skills.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ContextService} from '../../../../services/context.service';
import {NotZeroValidation} from '../../../utilities/form.utility';
import {LookupModel} from '../../../../models/lookups.model';
import {EditTradesSkillsDialogComponent} from './edit-trades-skills-dialog/edit-trades-skills-dialog.component';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-trade-skills',
  templateUrl: './trade-skills.component.html',
  styleUrls: ['./trade-skills.component.scss']
})

export class TradeSkillsComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  isSaving: boolean;
  tradeSkills: TradeSkillModel[];
  userTrades: LookupModel[] = [];
  userSkills: LookupModel[] = [];
  selectedPrimarySkill;
  selectedPrimaryTrade;
  tradeSkillsCollection: TradeSkillCollectionModel[] = [];
  cols: any[] = [];
  existingMessage: boolean = false;
  @Input() isPage: boolean;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() DBEntity: string;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() ContentID: number;
  @Input() canAdd: boolean;
  primaryReady: boolean = true;
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private rosterService: RosterService,
              private tradeSkillsService: TradeSkillsService, private ref: DynamicDialogRef,
              private config: DynamicDialogConfig, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private contextService: ContextService,
              private dialogService: DialogService, private confirmationService: ConfirmationService,
              public pageRowCountUtility: PageRowCountUtility) {
    this.mainForm = this.formBuilder.group({
      type: new FormControl(null, [Validators.required, NotZeroValidation()]),
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.getTradeSkillInfo();

    this.cols = [
      // { field: 'TradeDescription', header: 'Trade',  columnType: 'text', matchMode: 'contains', visible: true },
      {field: 'SkillDescription', header: 'Trade/Skill', columnType: 'text', matchMode: 'contains', visible: true},
      {field: 'Primary', header: 'Type', columnType: 'text', matchMode: 'contains', visible: true}
    ];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  dataChange(): void {
    if (this.isEdit && !this.existingMessage) {
      this.existingMessage = true;
      this.openDialog();
    }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Trades/Skills data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  getTradeSkillInfo(): void {
    this.primaryReady = false;
    this.userTrades = [];
    if (this.DBEntity === 'Profile') {
      this.tradeSkillsService.getTradeSkillsListByPersonId(this.ContentID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.tradeSkills = res;
            this.tradeSkills.forEach(tradeSkill => {
              this.userTrades.push({Description: tradeSkill.TradeDescription, ID: tradeSkill.TradeID});
              if (tradeSkill.Primary === true) {
                this.selectedPrimaryTrade = tradeSkill.TradeID;
              }
              if (tradeSkill.SkillList && tradeSkill.SkillList.length > 0) {
                tradeSkill.SkillList.forEach(skill => {
                  this.tradeSkillsCollection.push({
                    TradeID: tradeSkill.TradeID,
                    TradeDescription: tradeSkill.TradeDescription,
                    Primary: tradeSkill.Primary && skill.Primary,
                    SkillID: skill.SkillID,
                    PersonTradeID: tradeSkill.PersonTradeID,
                    SkillDescription: skill.SkillDescription,
                    CanDelete: tradeSkill.CanDelete
                  });
                  if (skill.Primary === true) {
                    this.selectedPrimarySkill = skill.SkillID;
                    console.log(this.selectedPrimarySkill);
                  }
                });
              }
              if (tradeSkill.SkillList && tradeSkill.SkillList.length === 0) {
                this.tradeSkillsCollection.push({
                  TradeID: tradeSkill.TradeID,
                  TradeDescription: tradeSkill.TradeDescription,
                  Primary: tradeSkill.Primary && null,
                  SkillID: null,
                  PersonTradeID: tradeSkill.PersonTradeID,
                  SkillDescription: 'No skills selected for this trade',
                  CanDelete: tradeSkill.CanDelete
                });
              }
              this.primaryReady = true;
              this.tradeSelected(this.selectedPrimaryTrade, true);
              this.initForm();
            });
          }
        });
    } else {
      this.tradeSkillsService.getTradeSkillsListByOrganizationId(this.ContentID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.tradeSkills = res;
            // console.log(this.tradeSkills);
            this.tradeSkills.forEach(tradeSkill => {
              this.userTrades.push({Description: tradeSkill.TradeDescription, ID: tradeSkill.TradeID});
              if (tradeSkill.Primary === true) {
                this.selectedPrimaryTrade = tradeSkill.TradeID;
              }
              if (tradeSkill.SkillList && tradeSkill.SkillList.length > 0) {
                tradeSkill.SkillList.forEach(skill => {
                  this.tradeSkillsCollection.push({
                    TradeID: tradeSkill.TradeID,
                    TradeDescription: tradeSkill.TradeDescription,
                    Primary: tradeSkill.Primary && skill.Primary,
                    SkillID: skill.SkillID,
                    PersonTradeID: tradeSkill.PersonTradeID,
                    SkillDescription: skill.SkillDescription
                  });
                  if (skill.Primary === true) {
                    this.selectedPrimarySkill = skill.SkillID;
                  }
                });
              }
              if (tradeSkill.SkillList && tradeSkill.SkillList.length === 0) {
                this.tradeSkillsCollection.push({
                  TradeID: tradeSkill.TradeID,
                  TradeDescription: tradeSkill.TradeDescription,
                  PersonTradeID: tradeSkill.PersonTradeID,
                  Primary: tradeSkill.Primary && null,
                  SkillID: null,
                  SkillDescription: 'No skills selected for this trade'
                });
              }
              this.primaryReady = true;
              this.tradeSelected(this.selectedPrimaryTrade, true);
              this.initForm();
            });
          }
        });
    }
  }

  tradeSelected(tradeId, load: boolean): void {
    this.userSkills = [];
    const tradeSkills: TradeSkillModel[] = this.tradeSkills.filter(x => x.TradeID === tradeId);
    tradeSkills.forEach(trade => {
      this.userSkills.push({Description: '--', ID: null});
      trade.SkillList.forEach(skill => {
        this.userSkills.push(
          {Description: skill.SkillDescription, ID: skill.SkillID,}
        );
      });
    });
    if (this.selectedPrimaryTrade !== this.selectedPrimaryTrade) {
      this.selectedPrimarySkill = null;
    }
    if (!load) {
      //turning off here as it is over kill for this form
      // this.dataChange();
    }
  }

  initForm(): void {
    this.mainForm.markAsPristine();
  }

  processData(): void {
    this.isSaving = true;
  }

  getSkill(event:any): void {

    //this.dataChange();
  }

  updatePrimary(): void {
    const selectedTrade = this.tradeSkills.filter(x => x.TradeID === this.selectedPrimaryTrade);
    this.tradeSkills.forEach(trade => {
      trade.SkillList.forEach(skill => {
        skill.Primary = skill.SkillID === this.selectedPrimarySkill;
        console.log(skill.Primary);
      });
    });
    const formatedTrade: UpdatePrimaryTradeModel = {
      Primary: true,
      SkillList: selectedTrade[0].SkillList,
    };
    // formatedTrade.SkillList.forEach(skill => {
    //   skill.Primary = skill.SkillID === this.selectedPrimarySkill;
    // });
    if (this.DBEntity === 'Profile') {
      this.tradeSkillsService.updatePersonTrade(this.ContentID, selectedTrade[0].PersonTradeID, formatedTrade, true)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your Primary Trade/Skill was updated'
            });
            this.confirmationService.close();
            this.tradeSkillsCollection = [];
            this.getTradeSkillInfo();
            this.isSaving = false;
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
            this.isSaving = false;
          }
        });
    } else {
      this.tradeSkillsService.updateOrganizationIdTrade(this.ContentID, selectedTrade[0].OrganizationTradeID, formatedTrade)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your Primary Trade/Skill was updated'
            });
            this.confirmationService.close();
            this.tradeSkillsCollection = [];
            this.getTradeSkillInfo();
            this.isSaving = false;
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
            this.isSaving = false;
          }
        });
    }
  }

  addTradesSkills(): void {
    const ref = this.dialogService.open(EditTradesSkillsDialogComponent, {
      data: {
        id: this.ContentID,
        isEdit: true,
        isDialog: true,
        DBEntity: this.DBEntity
      },
      header: 'Add New Trades/Skills',
      width: '60%',
      height: '70%'
    });

    ref.onDestroy.subscribe({
      next: () => {
        this.tradeSkillsCollection = [];
        this.getTradeSkillInfo();
      }
    });
  }

  removeTrade(personTradeID) {
    this.tradeSkillsService.deletePersonTrade(this.ContentID, personTradeID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success', detail: 'Trade was deleted '
          });
          this.tradeSkillsCollection = [];
          this.getTradeSkillInfo();
          this.isSaving = false;
        }, error: () => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          this.isSaving = false;
        }
      });
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }
}
