import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ContextService} from '../../../../../services/context.service';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {DialogUtility} from '../../../../utilities/dialog.utility';
import {Location} from '@angular/common';
import {PageModel} from '../../../../../models/page.model';
import {PageService} from '../../../../../services/page.services';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {WorkflowStepBankService} from 'src/app/services/workflow-step-bank.service';
import {WorkflowStepBankModel} from '../../../../../models/workflow-step-bank.model';
import {InterfaceObjectItemPermissions} from '../../../../../models/interface-objects.model';

@Component({
  selector: 'app-add-new-workflow-step',
  templateUrl: './add-new-workflow-step.component.html',
  styleUrls: ['./add-new-workflow-step.component.scss']
})
export class AddNewWorkflowStepComponent implements OnInit, OnChanges, OnDestroy {
  InterfaceObjectEnum: string;
  mainForm: FormGroup;
  routeParams: any;
  dataId: number;
  workflowStep: WorkflowStepBankModel = {} as WorkflowStepBankModel;
  isDirty: boolean;
  isSaving: boolean = false;
  currentFilter = new PrimeTableFilterModel();
  currentRecord: WorkflowStepBankModel;
  existingMessage: boolean = false;
  loading: boolean;
  isPage: boolean = false;
  @Input() isEdit: boolean;
  @Input() ContentID: number;
  @Input() category: string;
  @Input() organizationId: number;
  @Input() canPageWrite: boolean;
  @Input() parentObjectEnum: string;
  @Output() closeAddEdit = new EventEmitter<boolean>();
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(private workflowStepBankService: WorkflowStepBankService,
              private router: Router, private route: ActivatedRoute,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private dialogUtility: DialogUtility, private location: Location,
              private pageService: PageService, public config: DynamicDialogConfig,
              private confirmationService: ConfirmationService,
              private contextService: ContextService
  ) {
    this.mainForm = this.formBuilder.group({
      step: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.InterfaceObjectEnum = this.parentObjectEnum;
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isEdit = this.pageInfo.isEdit;
          this.isPage = this.pageInfo.isPage;
          this.ContentID = this.pageInfo.ContentID;
          this.getDataToEdit(this.ContentID);
        }
      }
    });
    if (this.canPageWrite === undefined) {
      this.pageService.getPagePermissions(this.InterfaceObjectEnum).then((permission: InterfaceObjectItemPermissions) => {
        this.canPageWrite = permission.Write;
      });
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    this.confirmationService.close();
    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    if (this.isEdit) {
      if (this.ContentID) {
        this.workflowStepBankService.getWorkflowStepBank(this.category, this.organizationId, this.ContentID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.currentRecord = res;
              this.workflowStep = res;
              this.initForm();
            }
          });
      }
    } else {
      this.initForm();
    }
    if (this.canPageWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: `${this.workflowStep.StepDescription} has been modified. Please save, or all changes will be lost.`,
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  back(): void {
    this.confirmationService.close();
    this.location.back();
  }

  cancel(): void {
    this.confirmationService.close();
    this.mainForm.markAsPristine();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  initForm(): void {
    this.loading = true;
    if (this.isEdit) {
      this.mainForm.get('step').setValue(this.currentRecord.StepDescription);
    } else {
      this.mainForm.get('step').setValue(null);
    }
    this.loading = false;
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }

  getDataToEdit(id: number): void {
    this.loading = true;
    if (id) {
      this.workflowStepBankService.getWorkflowStepBank(this.category, this.organizationId, id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.currentRecord = res;
            this.workflowStep = res;
            this.initForm();
            this.loading = false;
          }
        });
    } else {
      this.initForm();
      this.loading = false;
    }
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.workflowStep.StepDescription = this.mainForm.get('step').value;
      this.saveData();
    } else {
      this.messageService.add({severity: 'warn', summary: 'Missing Data', detail: 'No data was saved, the form is currently incomplete.'});
      this.existingMessage = false;
    }
  }

  reset(): void {
    this.route.params.subscribe({
      next: (params) => {
        this.dataId = Number(params.id);
        if (this.dataId) {
          this.getDataToEdit(this.dataId);
        }
      }
    });
  }

  saveData(): void {
    if (this.isEdit) {
      this.workflowStepBankService.updateWorkflowStepBank(this.category, this.organizationId, this.workflowStep.StepID, this.workflowStep).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
          this.mainForm.markAsUntouched();
          this.mainForm.markAsPristine();
          this.handleSave();
        }, error: (e) => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          this.isSaving = false;
          console.debug(e);
        }
      });
    } else {
      this.workflowStepBankService.createWorkflowStepBank(this.category, this.organizationId, this.workflowStep).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your step saved successfully'});
          this.handleSave();
        }, error: (e) => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          this.isSaving = false;
          console.debug(e);
        }
      });
    }
  }

  handleSave(): void {
    this.confirmationService.close();
    this.isSaving = false;
    if (this.isPage) {
      this.back();
    } else {
      this.existingMessage = false;
      this.closeAddEdit.emit(true);
    }
  }
}
