@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
<form [formGroup]="mainForm" class="margin-top-10 ">
  <p-divider class="dark-divider"></p-divider>
  <div class="p-grid p-fluid parent-form">
    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6">
      <app-select class="full-width" [inputObj]="inputObjClassAttendanceStatus" (emitData)="getClassAttendanceStatus($event)"></app-select>
    </div>
  </div>
  @if (canTabWrite) {
    <div class="p-grid p-fluid margin-top-10">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12"></div>
          <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
            @if (isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
              (click)="cancel()" [disabled]="!mainForm.dirty"></button>
            } @else {
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
              (click)="cancel()"></button>
            }
            @if (!isSaving) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                (click)="processData()" [className]="'inline-button'"
              [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
            } @else {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  }
</form>
