@if (hasEmail) {
  <form [formGroup]="mainForm">
    <div class="p-fluid p-grid">
      @if (recipients) {
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
          @if (recipients.length < 3) {
            <div>
              To: @for (data of recipients; track data; let i = $index) {
              <b>
                {{data.FirstName}} {{data.LastName}}@if (data.Suffix) {
                <span> {{data.Suffix}}</span>
                } - {{data.PrimaryEmailAddress}}@if (i === 0 && recipients.length > 1) {
                <span>,</span>
              }
            </b>
          }
        </div>
      }
      @if (recipients.length >= 3) {
        <div>
          To: <b>
          {{recipients.length}} Recipients
        </b>
      </div>
    }
  </div>
}
@if (recipientsStudentCerts) {
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
    @if (recipientsStudentCerts.length < 3) {
      <div>
        To: @for (data of recipientsStudentCerts; track data; let i = $index) {
        <b>
          {{data.FirstName}} {{data.LastName}} - {{data.PrimaryEmail}}@if (i === 0 && recipients.length > 1) {
          <span>,</span>
        }
      </b>
    }
  </div>
}
@if (recipientsStudentCerts.length >= 3) {
  <div>
    To: <b>
    {{recipientsStudentCerts.length}} Recipients
  </b>
</div>
}
</div>
}
@if (recipientsClassStudent) {
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
    @if (recipientsClassStudent.length < 3) {
      <div>
        To: @for (data of recipientsClassStudent; track data; let i = $index) {
        <b>
          {{data.FirstName}} {{data.LastName}} - {{data.PrimaryEmailAddress}}@if (i === 0 && recipients.length > 1) {
          <span>,</span>
        }
      </b>
    }
  </div>
}
@if (recipientsClassStudent.length >= 3) {
  <div>
    To: <b>
    {{recipientsClassStudent.length}} Recipients
  </b>
</div>
}
</div>
}
<div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
  From: <b>{{fromEmail}}</b>
</div>
<div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
  <label>Sender's Name <sup class="req">*</sup></label>
  <input type="text" pInputText formControlName="senderName">
  @if ((mainForm.controls.senderName.dirty || mainForm.controls.senderName.touched) &&
    mainForm.controls.senderName.errors?.required) {
    <span class="error">'Sender's Name' must be provided</span>
  }
</div>
<div class="col-md-6 col-sm-6 col-xs-12 margin-top-15">
  <label>Subject <sup class="req">*</sup></label>
  <input type="text" pInputText formControlName="subject">
  @if ((mainForm.controls.subject.dirty || mainForm.controls.subject.touched) &&
    mainForm.controls.subject.errors?.required) {
    <span class="error">'Subject' must be provided</span>
  }
</div>
@if (organizationId) {
  <div class="col-md-6 col-sm-6 col-xs-12 margin-top-15">
    <app-select class="full-width" [inputObj]="inputObjTemplate" (emitData)="getTemplateData($event)"></app-select>
  </div>
}
<div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
  @if (adhocData) {
    <app-text-editor (updateContent)="updateContent($event)" [settings]="settings" [height]="250" [html]="adhocData.Body" [showPreview]=false></app-text-editor>
  }
  @if (!adhocData) {
    <app-text-editor (updateContent)="updateContent($event)" [settings]="settings" [height]="250" [html]=null [showPreview]="false"></app-text-editor>
  }
</div>
<div class="col-md-12 col-sm-12 col-xs-12 margin-top-15 text-align-right p-mb-2">
  <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
  (click)="cancel()"></button>
  @if (!isSaving) {
    <button pButton label="Send" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Send" tooltipPosition="bottom"
      (click)="processData()" [className]="'inline-button'"
    [disabled]="mainForm.invalid || !mainForm.dirty && !content"></button>
  } @else {
    <button pButton label="Send" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
      <i class="pi pi-spin pi-spinner save-spinner"></i>
    </button>
  }
</div>
</div>
</form>
}
@if (!hasEmail) {
  <div class="error col-md-12 col-sm-12 col-xs-12 margin-top-15">Sorry there are not any valid email addresses to receive email</div>
}
<p-dialog [(visible)]="showImageDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
  Content
</p-dialog>
