import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {UserProfileCommunicationPreferencesGridModel, UserProfileCommunicationPreferencesModel} from '../../../models/user-profile.model';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {UserProfileService} from '../../../services/user-profile.service';
import {MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {RosterProfileService} from '../../../services/roster-profile.service';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';

@Component({
  selector: 'app-roster-tab-preferences',
  templateUrl: './roster-tab-preferences.component.html',
  styleUrls: ['./roster-tab-preferences.component.scss']
})
export class RosterTabPreferencesComponent implements OnInit, OnChanges, OnDestroy {
  preferences: UserProfileCommunicationPreferencesGridModel;
  preferencesList: UserProfileCommunicationPreferencesModel[] = [];
  dataLoaded: boolean;
  totalLoaded: boolean;
  isExporting: boolean;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  @Input() PersonID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;

  private ngUnsubscribe = new Subject();

  constructor(private userProfileService: UserProfileService,
              private messageService: MessageService, public dialogService: DialogService,
              private rosterProfileService: RosterProfileService, private dialogUtility: DialogUtility,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.getData();
  }

  getData(): void {
    this.preferencesList.length = 0;
    this.dataLoaded = false;
    this.totalLoaded = false;
    this.rosterProfileService.getOptInRosterPreferences(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.preferences = res;
          this.preferencesList = res.Data;
          this.dataLoaded = true;
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
          this.totalLoaded = true;
        }
      });
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterItems(): void {
    this.loadList();
  }

  clearFilters(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.loadList();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  enableDisablePreference(preference: UserProfileCommunicationPreferencesModel, event: boolean): void {
    let data: any;
    if (preference) {
      this.dataLoaded = false;
      data = {
        ID: preference.ID,
        Description: preference.Description,
        Checked: event,
        ChannelType: preference.ChannelType,
        ContactID: null
      };
      if (event === true) {
        this.userProfileService.updateUserPreference(data)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'This preference is now enabled'
              });
              this.dataLoaded = true;
            }
          });
      } else {
        this.userProfileService.updateUserPreference(data)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'This preference is now disabled'
              });
              this.dataLoaded = true;
            }
          });
      }
      preference.Checked = event;
    }
  }

  loadList(): void {
    this.getData();
  }

  refresh(): void {
    this.getData();
  }

  resetComponent(): void {
    this.preferencesList = undefined;
    this.preferences = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.preferencesList.length <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.preferencesList));
        const dataToExport = removeKeysFromArray(arr, ['ID']);
        exportData('preferences', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.rosterProfileService.getOptInRosterPreferences(this.PersonID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['ID']);
              exportData('preferences', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
