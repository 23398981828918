
  <div class="p-grid p-fluid margin-top-15">
    <div class="col-md-3 col-sm-3 col-xs-6 input-container">
      <label>Date</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" [(ngModel)]="outgoingGrade.DateConverted" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="2010:2027" tabindex="0"></p-calendar>
    </div>
<!--    <div class="col-md-3 col-sm-2 col-xs-6 input-container">-->
<!--      <label>Type</label>-->
<!--      <p-dropdown [styleClass]="'full-width'" [options]="CognitiveLevels" appendTo="body"-->
<!--                  [(ngModel)]="outgoingGrade.CognitiveLevelDescription" optionLabel="Description"  optionValue="Description" [disabled]="true" tabindex="0"></p-dropdown>-->
<!--    </div>-->
    <div class="col-md-2 col-sm-2 col-xs-6 input-container">
      <label>Grade</label>
      <p-inputNumber [(ngModel)]="outgoingGrade.Grade" [showButtons]="true" suffix="%"  tabindex="0" mode="decimal" [minFractionDigits]="2"
                     [maxFractionDigits]="2"></p-inputNumber>
    </div>
    <div class="col-md-2 col-sm-2 col-xs-6 input-container">
      <label>Pass/Fail</label>
      <p-dropdown [styleClass]="'full-width'" [options]="passFail" appendTo="body" (onBlur)="updatePassFail()"
                  [(ngModel)]="outgoingGrade.ResultID" optionLabel="Description" optionValue="ID"  tabindex="0"></p-dropdown>
    </div>
    @if(outgoingGrade.HasCertification) {
      <div class="col-md-3 col-sm-3 col-xs-6 input-container">
        <label>Cert. Issue Date</label>
        <p-calendar dateMask  dateFormat="mm/dd/yy" [(ngModel)]="outgoingGrade.CertificationIssueDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="2010:2027" tabindex="0"></p-calendar>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-6 input-container">
        <label>Cert. Expiration Date</label>
        <p-calendar dateMask  dateFormat="mm/dd/yy" [(ngModel)]="outgoingGrade.CertificationExpirationDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="2010:2027" tabindex="0"></p-calendar>
      </div>

    }

  </div>
    <div  class="col-md-12 col-sm-12 col-xs-12 text-align-right margin-top-10">
      <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button p-mr-2'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
      (click)="closeDialog()"></button>
      @if (!isSaving) {
        <button pButton label="Continue" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Continue" tooltipPosition="bottom"
          (click)="processData()" [className]="'inline-button'" [disabled]="!outgoingGrade.ResultID"></button>
      } @else {
        <button pButton label="Continue" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
          <i class="pi pi-spin pi-spinner save-spinner"></i>
        </button>
      }
    </div>

