import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {OrganizationPositionsGridModel, OrganizationPositionModel, CreatePositionModel} from '../models/organization-positions.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class OrganizationPositionsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getOrganizationPositionsPrimeNG(dataMode: string, organizationId: number, filterData, organizationCategory?: string): Observable<OrganizationPositionsGridModel> {
    Userpilot.track('Officer Directory');
    const paramCategory = (organizationCategory) ? organizationCategory : 'union';
    return this.http.post<OrganizationPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/position/grid/${organizationId}?organizationcategory=${paramCategory}&mode=${dataMode}`, filterData);
  }

  getOrganizationPosition(organizationPositionId: number): Observable<OrganizationPositionModel> {
    return this.http.get<OrganizationPositionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/position/${organizationPositionId}`);
  }

  createOrganizationPosition(modelData: CreatePositionModel): Observable<OrganizationPositionModel> {
    return this.http.post<OrganizationPositionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/position/create`, modelData);
  }

  updateOrganizationPosition(organizationPositionId: number, modelData: CreatePositionModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/position/${organizationPositionId}`, modelData);
  }

  deleteOrganizationPosition(organizationPositionId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/position/remove/${organizationPositionId}`);
  }

  getUserPositionGrid(filterData): Observable<OrganizationPositionsGridModel>{
    return this.http.post<OrganizationPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/positiongrid?mode=ALL`, filterData);
  }
}
