import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {OrganizationModel, OrganizationsGridModel} from '../../../models/organizations.model';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {OrganizationService} from '../../../services/organizations.service';
import {MessageService} from 'primeng/api';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {ContextService} from '../../../services/context.service';

@Component({
  selector: 'app-employers-tab-assignments',
  templateUrl: './employers-tab-assignments.component.html',
  styleUrls: ['./employers-tab-assignments.component.scss']
})
export class EmployerTabAssignmentsComponent implements OnInit, OnChanges, OnDestroy {
  context = this.contextService.contextObject;
  assignments: OrganizationsGridModel;
  assignmentsList: OrganizationModel[] = [];
  dataLoaded: boolean;
  isExporting: boolean;
  exportItems: any[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  showData: boolean = false;
  selectedItem: number;
  showAddEdit: boolean;
  dataTotal: number = 0;
  @Input() EmployerID: number;
  @Input() OrganizationID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() canTabWrite: boolean;
  @Input() canAdd: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private organizationService: OrganizationService,
              private messageService: MessageService,
              private dialogUtility: DialogUtility, public dialogService: DialogService,
              public pageRowCountUtility: PageRowCountUtility, private contextService: ContextService) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges() {
    if (this.EmployerID) {
      this.getData();
    }
  }

  getData() {
    this.getList();
    this.getTotal();
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.assignmentsList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.organizationService.getEmployerAssignmentsPrimeNG('DATA', this.EmployerID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.assignments = res;
          this.assignmentsList = res.Data;
          if (this.canTabWrite && this.assignmentsList.length === 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.dataLoaded = true;
        }, error: () => {
          this.dataLoaded = true;
        }
      });
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.getTotalRequest = this.organizationService.getEmployerAssignmentsPrimeNG('TOTAL', this.EmployerID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  deleteAssignment(employerId: number, organizationId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.organizationService.removeEmployerAssignment(employerId, [organizationId])
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected assignment has been removed.'
            });
            this.updateList();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList() {
    this.showAddEdit = false;
    this.selectedItem = -1;
    this.getData();
  }

  paginate(event:any) {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterItems(): void {
    this.initFilters('TaxFormTypeDescription', this.activeGlobalFilter, 'contains', 'and');
    this.updateList();
  }

  clearFilters(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.updateList();
  }

  resetFilter(): void {
    this.activeGlobalFilter = '';
    this.updateList();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  refresh(): void {
    this.updateList();
  }

  expandData(index, id): void {
    this.showData = !this.showData;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  resetComponent(): void {
    this.assignmentsList = undefined;
    this.assignments = undefined;
  }

  goToEdit(id: number): void {
    console.log('TODO', id);
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.assignmentsList));
        const dataToExport = removeKeysFromArray(arr, ['ID', 'EmployerID']);
        exportData('employer-assignments', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.organizationService.getEmployerAssignmentsPrimeNG('DATA', this.EmployerID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['ID', 'EmployerID']);
              exportData('employer-assignments', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
