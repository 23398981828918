@if (STATEMENTBILLING) {
  <app-account-statement-transactions-tab [PersonID]="PersonID" [organizationId]="organizationId" [isEdit]="isEdit"
                                          [interfaceObjectDesc]="interfaceObjectDesc"
                                          [parentObjectEnum]="parentObjectEnum" [TypeFilter]="TypeFilter"
                                          [canTabWrite]="canTabWrite" [pdfType]="pdfType" (updateData)="updateData"
                                          (updateProfileAccount)="updateProfileAccount"
                                          (updateGlobalOrgId)="updateGlobalOrgId"></app-account-statement-transactions-tab>
} @else {
  <app-transaction-history-tab [PersonID]="PersonID" [canTabWrite]="canTabWrite" [TypeFilter]="TypeFilter"></app-transaction-history-tab>
}
