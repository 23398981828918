<app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc" [parentObjectEnum]="parentObjectEnum"></app-learn-more>
@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
<div class="p-grid p-fluid parent-form">
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Voter Registration Status</label>
    <input type="text" pInputText  [(ngModel)]="VoterRegistrationStatus" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Voter Status</label>
    <input type="text" pInputText  [(ngModel)]="VoterStatus" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Party Registration</label>
    <input type="text" pInputText  [(ngModel)]="PartyRegistration" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>County Name</label>
    <input type="text" pInputText  [(ngModel)]="CountyName" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Congressional District</label>
    <input type="text" pInputText  [(ngModel)]="CongressionalDistrict" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>State Senate District</label>
    <input type="text" pInputText  [(ngModel)]="StateSenateDistrict" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>State House District</label>
    <input type="text" pInputText  [(ngModel)]="StateHouseDistrict" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-6 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Township Name</label>
    <input type="text" pInputText  [(ngModel)]="TownshipName" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-6 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Ward Name</label>
    <input type="text" pInputText  [(ngModel)]="WardName" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Precinct Code</label>
    <input type="text" pInputText  [(ngModel)]="PrecinctCode" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Precinct Name</label>
    <input type="text" pInputText  [(ngModel)]="PrecinctName" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>National Precinct Code</label>
    <input type="text" pInputText  [(ngModel)]="NationalPrecinctCode" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-6 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>County Council District</label>
    <input type="text" pInputText  [(ngModel)]="CountyCouncilDistrict" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-6 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>City Council District</label>
    <input type="text" pInputText  [(ngModel)]="CityCouncilDistrict" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Municipal District</label>
    <input type="text" pInputText  [(ngModel)]="MunicipalDistrict" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>School District</label>
    <input type="text" pInputText  [(ngModel)]="SchoolDistrict" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-4 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Judicial District</label>
    <input type="text" pInputText  [(ngModel)]="JudicialDistrict" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-3 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Absentee Voter Indicator</label>
    <input type="text" pInputText  [(ngModel)]="AbsenteeVoterIndicator" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-3 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>General Votes Summary</label>
    <input type="text" pInputText  [(ngModel)]="GeneralVotesSummary" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-3 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Primary Votes Summary</label>
    <input type="text" pInputText  [(ngModel)]="PrimaryVotesSummary" [disabled]="true" tabindex="0">
  </div>
  <div class="col-md-3 col-sm-12 col-xs-12 input-container  p-mb-2">
    <label>Voter Score</label>
    <input type="text" pInputText  [(ngModel)]="VoterScore" [disabled]="true" tabindex="0">
  </div>
</div>
