import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {OrganizationTaxFormsGridModel, OrganizationTaxFormModel} from '../models/organization-tax-forms.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationTaxFormsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getOrganizationTaxFormsPrimeNG(dataMode: string, organizationId: number, filterData): Observable<OrganizationTaxFormsGridModel> {
    return this.http.post<OrganizationTaxFormsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/taxform/grid/${organizationId}?mode=${dataMode}`, filterData);
  }

  updateOrganizationTaxForm(dataModel: FormData): Observable<any> {
    return this.http.put<OrganizationTaxFormModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/taxform/`, dataModel);
  }

  deleteOrganizationTaxForm(organizationPositionId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/taxform/${organizationPositionId}`);
  }

  remittedOrganizationTaxForm(organizationFormTypeId: number, organizationTaxFormYear: number, date: string): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/taxform/received/grid/${organizationFormTypeId}/${organizationTaxFormYear}/${date}`);
  }

  remittedOrganizationTaxFormStats(organizationFormTypeId: number, organizationTaxFormYear: number, date: string): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/taxform/received/stats/${organizationFormTypeId}/${organizationTaxFormYear}/${date}`);
  }

  remittedOrganizationTaxFormDelinquent(organizationFormTypeId: number, organizationTaxFormYear: number, date: string): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/taxform/delinquent/grid/${organizationFormTypeId}/${organizationTaxFormYear}/${date}`);
  }
}
