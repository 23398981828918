<form [formGroup]="mainForm" class="margin-top-10 padding-bottom-50">
  <div class="p-grid p-fluid">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <div class="p-grid p-fluid">
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="getOrganizationData($event)"></app-select>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjPathway" (emitData)="getPathwayData($event)"></app-select>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <label>Member #</label>
          @if (ENABLEMEMBERNUMBER) {
            <input type="text" pInputText formControlName="memberNumber" tabindex="0">
          }
          @if (!ENABLEMEMBERNUMBER) {
            <input type="text" pInputText readonly formControlName="memberNumber" tabindex="0">
          }
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjClass" (emitData)="getClassData($event)"></app-select>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <label>Bargaining Unit</label>
          <input class="full-width" type="text" pInputText  formControlName="bargainingUnit" tabindex="0">
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
      <div class="p-grid p-fluid">
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjType" (emitData)="getTypeData($event)"></app-select>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjStatus" (emitData)="getStatusData($event)"></app-select>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>Effective Date@if (isRequiredEffectiveDate) {
            <sup class="req">*</sup>
          }</label>
          <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="effectiveDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
        </div>
        <div class="col-md-6 col-sm-0 col-xs-0"></div>
        <!--        <div class="col-md-4 col-sm-12 col-xs-12 input-container">-->
        <!--          <label>Application Date</label>-->
        <!--          <p-calendar dateMask formControlName="applicationDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1925:2023"></p-calendar>-->
      <!--        </div>-->
      <div class="col-md-6 col-sm-12 col-xs-12 input-container">
        <label>Initiation Date@if (isInitiationDateRequired) {
          <sup class="req">*</sup>
        }</label>
        <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="initiationDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1925:2023" tabindex="0"></p-calendar>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 input-container">
        <label>Retirement Date@if (isRetireDateRequired) {
          <sup class="req">*</sup>
        }</label>
        <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="retirementDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjBilling" (emitData)="getBillingProcessData($event)"></app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjDues" (emitData)="getDuesPaymentData($event)"></app-select>
      </div>
    </div>
  </div>
  @if (canTabWrite) {
    <div class="col-md-12 text-align-right">
      <div class="action-buttons float-right margin-top-10">
        @if (isEdit) {
          <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
          (click)="cancel()" [disabled]="!mainForm.dirty"></button>
        } @else {
          <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
          (click)="cancel()"></button>
        }
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
            (click)="processData()" [className]="'inline-button'"
          [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
        } @else {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  }
</div>
</form>
