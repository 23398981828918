<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-20">
    <app-select class="full-width" [inputObj]="inputObjTrades" (emitData)="getTradesData($event)"></app-select>
  </div>
  @if (setTradeId !== 0 || setTradeId !== null) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
      <p-pickList [source]="availableSkills" [target]="selectedSkills" [showSourceControls]="false" [showTargetControls]="false"
        sourceHeader="Available Skills" targetHeader="Selected Skills" filterBy="Description">
        <ng-template let-skill pTemplate="item">
          <div>
            {{skill.Description}}
          </div>
        </ng-template>
      </p-pickList>
    </div>
  }
  <div class="col-md-12 col-sm-3 col-xs-12 margin-top-15 text-align-right">
    <button pButton label="Update Trade/Skills" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-upload" pTooltip="Update" tooltipPosition="bottom"
    (click)="processData()"></button>
  </div>
</div>
