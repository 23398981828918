<div class="public-card">
  <p-card class="card">
    @if (logo) {
      <div class="align-center">
        <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true"/>
      </div>
    }
    @if (!noClasses) {
      <div class="margin-top-10">
        <h2>Connect With Your Union!</h2>
        <p>We offer a wide variety of classes to ensure you get the most out of your membership.</p>
      </div>
    }
    @if (!isEdit && !noClasses) {
      <div class="margin-top-10">
        <h3>Upcoming Classes</h3>
        @for (c of publicClasses; track c) {
          @for (ei of c.Classes; track ei) {
            <div class="margin-top-15">
              <p-card class="card parent-p-card">
                <div class="p-grid p-fluid">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <h3>{{ c.CourseName }}</h3>
                    <!--                    @if (c.LanguageDescription) {-->
                    <!--                      <label>Language: {{ c.LanguageDescription }}</label>-->
                    <!--                    }-->
                    <div [innerHTML]="c.CourseDescription"></div>
                    @if (ei.FilledSeats_N < ei.MaxSeats_N) {
                      <button pButton label="Sign Up" class="p-mt-4 inline-button" (click)="classSelected(c, ei)"
                      ></button>
                    }
                    @if (ei.FilledSeats_N >= ei.MaxSeats_N && ei.AllowWaitListOption) {
                      <button pButton label="Sign Up for Waiting List" class="p-mt-4 inline-button" (click)="classSelected(c, ei)"
                      ></button>
                    }
                    @if (ei.FilledSeats_N >= ei.MaxSeats_N && !ei.AllowWaitListOption) {
                      <div class="error margin-top-10">
                        Sorry but this class is currently full. Please contact your training center for more assistance.
                      </div>
                    }
                  </div>
                  <div class="col-md-1 col-sm-0 col-xs-0">
                    <p-divider layout="vertical"></p-divider>
                  </div>
                  @if (!ei.ComplexSchedule) {
                    <div class="col-md-5 col-sm-12 col-xs-12">
                      <h3>Schedule
                        @if (ei.LanguageDescription) {
                          <span> ({{ ei.LanguageDescription }})</span>
                        }</h3>
                      @for (sch of ei.ScheduleArray; track sch) {
                        <div class="p-grid p-fluid">
                          @if (sch.Date_D) {
                            <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                              <div>Date</div>
                              <label>{{ sch.Date_D | date: 'longDate' }}</label>
                            </div>
                          }
                          @if (sch.StartTime_T) {
                            <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                              <div>Time</div>
                              <label>{{ sch.StartTime_T | date: 'shortTime' }}@if (sch.EndTime_T && sch.StartTime_T !== sch.EndTime_T) {
                                <span> - {{ sch.EndTime_T | date: 'shortTime' }}</span>
                              }</label>
                            </div>
                          }
                          @if (ei.TimezoneDescription) {
                            <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                              <div>&nbsp;</div>
                              <label>{{ ei.TimezoneDescription }}</label>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }
                  @if (ei.ComplexSchedule) {
                    <div class="col-md-5 col-sm-12 col-xs-12">
                      <h3>Schedule</h3>
                      @for (sch of ei.Schedule; track sch) {
                        <div class="p-grid p-fluid">
                          @if (sch.Date_D) {
                            <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                              <div>Date</div>
                              <label>{{ sch.Date_D | date: 'longDate' }}</label>
                            </div>
                          }
                          @if (sch.StartTime_T) {
                            <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                              <div>Time</div>
                              <label>{{ sch.StartTime_T | date: 'shortTime' }}@if (sch.EndTime_T && sch.StartTime_T !== sch.EndTime_T) {
                                <span> - {{ sch.EndTime_T | date: 'shortTime' }}</span>
                              }</label>
                            </div>
                          }
                          @if (ei.TimezoneDescription) {
                            <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                              <div>&nbsp;</div>
                              <label>{{ ei.TimezoneDescription }}</label>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }
                </div>
              </p-card>
            </div>
          }
        }
      </div>
    }
    @if (isEdit && !noClasses) {
      <div>
        <h3>Class Registration</h3>
        <div class="parent-p-card margin-top-15">
          <p-card class="card">
            <form [formGroup]="mainForm">
              <div class="p-grid p-fluid">
                <div class="col-md-6 col-sm-12 col-xs-12">
                  <h3>{{ selectedClass.CourseName }}</h3>
                  <div [innerHTML]="selectedClass.CourseDescription"></div>
                  @if (!selectedClassDetail.ComplexSchedule) {
                    <div class="p-grid p-fluid p-mt-4">
                      @if (selectedClassDetail.StartDate_D) {
                        <div class="col-md-3 col-sm-3 col-xs-12">
                          <div>Date</div>
                          <label>{{ selectedClassDetail.StartDate_D | date: 'longDate' }} - {{ selectedClassDetail.EndDate_D | date: 'longDate' }}</label>
                        </div>
                      }
                      @if (selectedClassDetail.StartTime_T) {
                        <div class="col-md-3 col-sm-3 col-xs-12">
                          <div>Time</div>
                          <label>{{ selectedClassDetail.StartTime_T | date: 'shortTime' }}@if (selectedClassDetail.EndTime_T && selectedClassDetail.StartTime_T !== selectedClassDetail.EndTime_T) {
                            <span> - {{ selectedClassDetail.EndTime_T | date: 'shortTime' }}</span>
                          }</label>
                        </div>
                      }
                      @if (selectedClassDetail.TimezoneDescription) {
                        <div class="col-md-3 col-sm-3 col-xs-12">
                          <div>&nbsp;</div>
                          <label>{{ selectedClassDetail.TimezoneDescription }}</label>
                        </div>
                      }
                      @if (selectedClassDetail.LanguageDescription) {
                        <div class="col-md-3 col-sm-3 col-xs-12">
                          <div>Language</div>
                          <label>{{ selectedClassDetail.LanguageDescription }}</label>
                        </div>
                      }
                    </div>
                  }
                  @if (selectedClassDetail.ComplexSchedule) {
                    <div class="p-grid p-fluid p-mt-4">
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <h3>Schedule</h3>
                        @for (sch of selectedClassDetail.Schedule; track sch) {
                          <div class="p-grid p-fluid">
                            @if (sch.Date_D) {
                              <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                                <div>Date</div>
                                <label>{{ sch.Date_D | date: 'longDate' }}</label>
                              </div>
                            }
                            @if (sch.StartTime_T) {
                              <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                                <div>Time</div>
                                <label>{{ sch.StartTime_T | date: 'shortTime' }}@if (sch.EndTime_T && sch.StartTime_T !== sch.EndTime_T) {
                                  <span> - {{ sch.EndTime_T | date: 'shortTime' }}</span>
                                }</label>
                              </div>
                            }
                            @if (selectedClassDetail.TimezoneDescription) {
                              <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                                <div>&nbsp;</div>
                                <label>{{ selectedClassDetail.TimezoneDescription }}</label>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  }
                  @if (!selectedClassDetail.ComplexSchedule) {
                    <div class="p-grid p-fluid p-mt-4">
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <h3>Schedule</h3>
                        @for (sch of selectedClassDetail.ScheduleArray; track sch) {
                          <div class="p-grid p-fluid">
                            @if (sch.Date_D) {
                              <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                                <div>Date</div>
                                <label>{{ sch.Date_D | date: 'longDate' }}</label>
                              </div>
                            }
                            @if (sch.StartTime_T) {
                              <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                                <div>Time</div>
                                <label>{{ sch.StartTime_T | date: 'shortTime' }}@if (sch.EndTime_T && sch.StartTime_T !== sch.EndTime_T) {
                                  <span> - {{ sch.EndTime_T | date: 'shortTime' }}</span>
                                }</label>
                              </div>
                            }
                            @if (selectedClassDetail.TimezoneDescription) {
                              <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">
                                <div>&nbsp;</div>
                                <label>{{ selectedClassDetail.TimezoneDescription }}</label>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  }
                  <br>
                  <!--                  <div *ngIf="locationDisplay">-->
                  <!--                    <label [innerHTML]="locationDisplay"></label>-->
                  <!--                  </div>-->
                  @if (selectedClassDetail.SignupNotes) {
                    <div>
                      <label>Notes</label>
                      <!--                      <p class="wrap">{{selectedClassDetail.SignupNotes}}</p>-->
                      <div [innerHTML]="selectedClassDetail.SignupNotes.replaceAll('\n','<br>')"></div>
                    </div>
                  }
                </div>
                <div class="col-md-1 col-sm-0 col-xs-0">
                  <p-divider layout="vertical"></p-divider>
                </div>
                <div class="col-md-5 col-sm-12 col-xs-12">
                  @if (locationDisplayWithName) {
                    <div>
                      <br>
                      <div>Location</div>
                      <label [innerHTML]="locationDisplayWithName"></label>
                    </div>
                  }
                  <br>
                  <div class="input-container">
                    <label>First Name <sup class="req">*</sup></label>
                    <input class="full-width" type="text" pInputText formControlName="firstName">
                    @if ((mainForm.controls.firstName.dirty || mainForm.controls.firstName.touched) &&
                    mainForm.controls.firstName.errors?.required) {
                      <span class="error">'First Name' must be provided</span>
                    }
                  </div>
                  <div class="input-container">
                    <label>Last Name <sup class="req">*</sup></label>
                    <input class="full-width" type="text" pInputText formControlName="lastName">
                    @if ((mainForm.controls.lastName.dirty || mainForm.controls.lastName.touched) &&
                    mainForm.controls.lastName.errors?.required) {
                      <span class="error">'Last Name' must be provided</span>
                    }
                  </div>
                  <div class="input-container mask-container">
                    <label>Mobile Phone #</label>
                    <p-inputMask type="text" pInputText formControlName="phone" [className]="'form-input no-border'" mask="(999) 999-9999" unmask="true"></p-inputMask>
                  </div>
                  <div class="input-container">
                    <label>E-mail <sup class="req">*</sup></label>
                    <input class="full-width" type="text" pInputText formControlName="email">
                    @if ((mainForm.controls.email.dirty || mainForm.controls.email.touched) &&
                    mainForm.controls.email.errors?.required) {
                      <span class="error">'E-mail' must be provided</span>
                    }
                    @if ((mainForm.controls.email.dirty || mainForm.controls.email.touched) &&
                    mainForm.controls.email?.errors) {
                      <span class="error">'E-mail' must be valid</span>
                    }
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 input-container no-padding">
                    <app-select class="full-width" [inputObj]="inputObjCountry" (emitData)="getCountryData($event)"></app-select>
                  </div>
                  @if (suggestAddress) {
                    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                      <label>Address Lookup</label>
                      @if (!international) {
                        <p-autoComplete #autocomplete class="p-text-bold" [suggestions]="autocompleteData.items" (completeMethod)="search($event)" (onSelect)="applySuggestion($event)" placeholder="Search for an address" tabindex="0" [disabled]="mainForm.get('Address1').disabled">
                          <ng-template let-address pTemplate="addressDisplay">
                            <div>
                              {{ address.street_line }}  {{ address.city }}, {{ address.state }} {{ address.zipcode }}
                            </div>
                          </ng-template>
                        </p-autoComplete>
                      }
                      @if (international) {
                        <p-autoComplete #autocomplete class="p-text-bold" [suggestions]="intAutocompleteEntryData.items" (completeMethod)="searchIntCandidates($event)" (onSelect)="searchIntCandidates($event)" (onFocus)="internationalEntries === true && autocomplete.suggestions?.length > 0 && autocomplete.show()" (onHide)="internationalEntries === true && autocomplete.suggestions?.length > 0 && autocomplete.show()" placeholder="Search for an address" tabindex="0"
                                        [disabled]="mainForm.get('Address1').disabled">
                          <ng-template let-address pTemplate="addressDisplay">
                            <div>
                              {{ address.address_text }}
                            </div>
                          </ng-template>
                        </p-autoComplete>
                      }
                    </div>
                  }
                  <div class="col-md-12 col-sm-12 col-xs-12 input-container no-padding">
                    <label>Street Address <sup class="req">*</sup></label>
                    <input type="text" pInputText formControlName="Address1" maxlength="100" tabindex="0">
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 input-container no-padding">
                    <label>City/Town <sup class="req">*</sup></label>
                    <input type="text" pInputText formControlName="City" maxlength="100" tabindex="0">
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 input-container no-padding">
                    <app-select class="full-width" [inputObj]="inputObjState" (emitData)="getStateData($event)"></app-select>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 input-container no-padding">
                    <label>Postal Code <sup class="req">*</sup></label>
                    <input type="text" pInputText formControlName="Zip" maxlength="10" tabindex="0">
                  </div>
                  <!--                    <div class="col-md-12 col-sm-12 col-xs-12 input-container no-padding">-->
                  <!--                      <label>Street Address <sup class="req">*</sup></label>-->
                  <!--                      <input *ngIf="!suggestAddress" type="text" pInputText  formControlName="Address1" maxlength="100">-->
                  <!--                      <p-autoComplete *ngIf="suggestAddress" formControlName="Address1" [suggestions]="autocompleteData.items" (completeMethod)="search($event)" (onSelect)="applySuggestion($event)">-->
                  <!--                        <ng-template let-address pTemplate="addressDisplay">-->
                  <!--                          <div>-->
                  <!--                            {{address.street_line}}  {{address.city}}, {{address.state}} {{address.zipcode}}-->
                  <!--                          </div>-->
                  <!--                        </ng-template>-->
                  <!--                      </p-autoComplete>-->
                  <!--                    </div>-->
                  <!--                    <div class="col-md-12 col-sm-12 col-xs-12 input-container no-padding">-->
                  <!--                      <label>City/Town <sup class="req">*</sup></label>-->
                  <!--                      <input type="text" pInputText  formControlName="City" maxlength="100">-->
                  <!--                    </div>-->
                  <!--                    <div class="col-md-12 col-sm-12 col-xs-12 input-container no-padding">-->
                  <!--                      <app-select class="full-width" [inputObj]="inputObjState" (emitData)="getStateData($event)"></app-select>-->
                  <!--                    </div>-->
                  <!--                    <div class="col-md-12 col-sm-12 col-xs-12 input-container no-padding">-->
                  <!--                      <label>Zip <sup class="req">*</sup></label>-->
                  <!--                      <input type="text" pInputText  formControlName="Zip" maxlength="10">-->
                  <!--                    </div>-->
                  <div class="input-container">
                    <p>By registering for this class, you are opting-in to occasional text message updates about this class and other classes we offer.</p>
                    @if (!isRegistering) {
                      <button pButton label="Register" class="p-button-info" pTooltip="Register" tooltipPosition="bottom"
                              (click)="register()" [disabled]="mainForm.invalid || !mainForm.touched"></button>
                    }
                    @if (isRegistering) {
                      <button pButton label="Register" class="p-button-info" [disabled]="true">
                        <i class="pi pi-spin pi-spinner save-spinner"></i>
                      </button>
                    }
                  </div>
                </div>
              </div>
            </form>
          </p-card>
        </div>
        <div class="p-mt-4">
          <button pButton label="Back to Classes" (click)="isEdit=false;" class="p-button-info" [className]="'back-button'"
                  icon="pi pi-angle-left" pTooltip="Back" tooltipPosition="bottom"></button>
        </div>
      </div>
    }
    @if (noClasses) {
      <div class="margin-top-10">
        <h2>We're busy planning our next classes, however...</h2>
        <p>No classes are currently scheduled. Please come back to check again at a later time, thank you!</p>
      </div>
    }
  </p-card>
</div>
<p-toast [baseZIndex]="16000"></p-toast>
