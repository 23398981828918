import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {ContextService} from '../../../../../services/context.service';
import {CreatePaymentMethodACHModel, CreatePaymentMethodCCModel, PaymentMethodBankNameModel, PaymentMethodCardTypeModel, PaymentMethodCardValidModel, PaymentMethodModel, UpdatePaymentMethodACHModel, UpdatePaymentMethodCCModel} from '../../../../../models/payment-methods.model';
import {PaymentMethodsService} from '../../../../../services/payment-methods.service';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {UserProfileService} from '../../../../../services/user-profile.service';
import {RosterProfileService} from '../../../../../services/roster-profile.service';
import {OrganizationService} from '../../../../../services/organizations.service';
import {AddressModel} from '../../../../../models/addresses.model';

@Component({
  selector: 'app-payment-methods-form',
  templateUrl: './payment-methods-form.component.html',
  styleUrls: ['./payment-methods-form.component.scss']
})
export class PaymentMethodsFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  currentFilter = new PrimeTableFilterModel();
  currentPaymentMethod: PaymentMethodModel;
  paymentMethod = {} as PaymentMethodModel;
  createPaymentMethodACHModel = {} as CreatePaymentMethodACHModel;
  createPaymentMethodCCModel = {} as CreatePaymentMethodCCModel;
  updatePaymentMethodACHModel = {} as UpdatePaymentMethodACHModel;
  updatePaymentMethodCCModel = {} as UpdatePaymentMethodCCModel;
  loading: boolean = false;
  inputObjAutoPay: GenericSelectModel;
  setAutoPayId: number;
  isSaving: boolean;
  methodType: string = 'CC';
  minYear: number;
  maxYear: number;
  paymentMethodId: number;
  existingMessage: boolean = false;
  addressData: AddressModel = {} as AddressModel;
  @Input() userObject: boolean;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() allowCC: boolean = true;
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private paymentMethodsService: PaymentMethodsService,
              private confirmationService: ConfirmationService, private userProfileService: UserProfileService,
              private rosterProfileService: RosterProfileService, private organizationService: OrganizationService,
              private contextService: ContextService
  ) {
    this.mainForm = this.formBuilder.group({
      MethodNickname: new FormControl(null, Validators.required),
      AutoPaymentDay: new FormControl(null),
      paymentMethodType: new FormControl(null, Validators.required),
      ACHNameOnAccount: new FormControl(null),
      ACHAccountNumber: new FormControl(null),
      ACHAccountType: new FormControl(null),
      ACHBankName: new FormControl({value: null, disabled: true}),
      ACHRoutingNumber: new FormControl(null),
      CCNameOnAccount: new FormControl(null),
      CCAccountNumber: new FormControl(null),
      CCCardType: new FormControl({value: null, disabled: true}),
      CVV: new FormControl(null, Validators.maxLength(4)),
      CCExpirationMonth: new FormControl(null),
      CCExpirationYear: new FormControl(null),
      useAddress: new FormControl(false),
      primaryPhoneNumber: new FormControl(null),
      primaryEmail: new FormControl(null, [Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')])
    });
  }

  ngOnInit(): void {
    this.mainForm.get('paymentMethodType').valueChanges.subscribe({
      next: (value) => {
        this.methodType = value;
        this.setFormState(value);
      }
    });
    if (!this.isEdit) {
      if (this.allowCC) {
        this.mainForm.get('paymentMethodType').setValue('CC');
      } else {
        this.mainForm.get('paymentMethodType').setValue('ACH');
      }
      this.mainForm.get('ACHAccountType').setValue('CHECKING');
    }
    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    if (this.isEdit) {
      this.initForm(this.isEdit);
    } else {
      this.initForm(this.isEdit);
      this.addressData.CountryID = null;
      this.addressData.Address1 = null;
      this.addressData.Address2 = null;
      this.addressData.City = null;
      this.addressData.StateProvinceID = null;
      this.addressData.Zip = null;
      this.addressData.Ready = true;
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Payment Method data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  getCCDetails(value: string): void {
    this.getCreditCardType(value);
    this.isCreditCardValid(value);
  }

  getCreditCardType(value: string): void {
    if (value) {
      const data = {} as PaymentMethodCardTypeModel;
      data.AccountNumber = value;
      this.paymentMethodsService.getCreditCardType(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.mainForm.get('CCCardType').setValue(res);
          }, error: () => {
            this.mainForm.get('CCCardType').setValue(null);
          }
        });
    }
  }

  isCreditCardValid(value: string): void {
    if (value) {
      const data = {} as PaymentMethodCardValidModel;
      data.AccountNumber = value;
      this.paymentMethodsService.isCreditCardValid(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (res === false) {
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'The Credit Card # provided is not valid. Please try again.'});
            }
          }
        });
    }
  }

  getBankName(value: string): void {
    if (value) {
      const data = {} as PaymentMethodBankNameModel;
      data.ACHRoutingNumber = value;
      this.paymentMethodsService.getBankName(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.mainForm.get('ACHBankName').setValue(res);
          }, error: () => {
            this.mainForm.get('ACHBankName').setValue(null);
          }
        });
    }
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  initSelects() {
    this.initAutoPay(false);
  }

  initForm(isEdit: boolean): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.loading = true;
    this.mainForm.get('useAddress').setValue(false);
    if (isEdit) {
      this.paymentMethodsService.getPaymentMethod(this.DBEntityID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.currentPaymentMethod = res;
            this.paymentMethodId = this.currentPaymentMethod.PaymentMethodId;

            if (this.currentPaymentMethod.CCExpirationYear) {
              this.minYear = this.currentPaymentMethod.CCExpirationYear;
              this.maxYear = this.currentPaymentMethod.CCExpirationYear + 20;
            } else {
              const dt = new Date();
              const str: string = dt.getFullYear().toString().substring(3, 4);
              this.minYear = Number(str);
              this.maxYear = Number(str) + 20;
            }

            this.methodType = this.currentPaymentMethod.MethodType;
            this.mainForm.get('MethodNickname').setValue(this.currentPaymentMethod.MethodNickname);
            this.mainForm.get('AutoPaymentDay').setValue(this.currentPaymentMethod.AutoPaymentScheduleTypeID);

            this.mainForm.get('paymentMethodType').setValue(this.currentPaymentMethod.MethodType);
            this.mainForm.get('paymentMethodType').disable();
            this.mainForm.get('CCNameOnAccount').setValue(this.currentPaymentMethod.NameOnAccount);
            this.mainForm.get('CCAccountNumber').setValue(`####${this.currentPaymentMethod.AccountNumber}`);
            this.mainForm.get('CCAccountNumber').disable();
            this.mainForm.get('CCCardType').setValue(this.currentPaymentMethod.CCCardType);
            this.mainForm.get('CCExpirationMonth').setValue(this.currentPaymentMethod.CCExpirationMonth);
            this.mainForm.get('CCExpirationYear').setValue(this.currentPaymentMethod.CCExpirationYear);
            this.mainForm.get('CVV').setValue(null);

            this.mainForm.get('ACHNameOnAccount').setValue(this.currentPaymentMethod.NameOnAccount);
            this.mainForm.get('ACHAccountType').setValue(this.currentPaymentMethod.ACHAccountType);
            this.mainForm.get('ACHAccountType').disable();
            this.mainForm.get('ACHBankName').setValue(this.currentPaymentMethod.ACHBankName);
            this.mainForm.get('ACHRoutingNumber').setValue(this.currentPaymentMethod.ACHRoutingNumber);
            this.mainForm.get('ACHRoutingNumber').disable();
            this.mainForm.get('ACHAccountNumber').setValue(`####${this.currentPaymentMethod.AccountNumber}`);
            this.mainForm.get('ACHAccountNumber').disable();

            this.mainForm.get('primaryPhoneNumber').setValue(this.currentPaymentMethod.PhoneNumber);
            this.mainForm.get('primaryEmail').setValue(this.currentPaymentMethod.EmailAddress);
            this.setAutoPayId = this.currentPaymentMethod.AutoPaymentScheduleTypeID;
            this.mainForm.get('AutoPaymentDay').setValue(this.currentPaymentMethod.AutoPaymentScheduleTypeID);

            this.addressData.CountryID = this.currentPaymentMethod.CountryID;
            this.addressData.Address1 = this.currentPaymentMethod.Address1;
            this.addressData.Address2 = this.currentPaymentMethod.Address2;
            this.addressData.City = this.currentPaymentMethod.City;
            this.addressData.StateProvinceID = this.currentPaymentMethod.StateProvinceID;
            this.addressData.Zip = this.currentPaymentMethod.Zip;
            this.addressData.Ready = true;

            this.initSelects();
            this.setFormState(this.methodType);
            this.loading = false;
          }
        });
    } else {
      this.mainForm.reset();
      if (this.allowCC) {
        this.mainForm.get('paymentMethodType').setValue('CC');
      } else {
        this.mainForm.get('paymentMethodType').setValue('ACH');
      }
      this.mainForm.get('ACHAccountType').setValue('CHECKING');
      this.initAutoPay(false);
      this.loading = false;
    }
  }

  setFormState(value: string): void {
    if (value === 'CC') {
      const dt = new Date();
      const yr: string = dt.getFullYear().toString().substring(2);
      this.minYear = Number(yr);
      this.maxYear = Number(yr) + 20;
      this.mainForm.get('CCNameOnAccount').setValidators([Validators.required]);
      this.mainForm.get('CCNameOnAccount').updateValueAndValidity();
      this.mainForm.get('CCAccountNumber').setValidators([Validators.required]);
      this.mainForm.get('CCAccountNumber').updateValueAndValidity();
      if (!this.isEdit) {
        this.mainForm.get('CVV').setValidators([Validators.required, Validators.maxLength(4)]);
        this.mainForm.get('CVV').updateValueAndValidity();
      } else {
        this.mainForm.get('CVV').setValidators(Validators.maxLength(4));
        this.mainForm.get('CVV').updateValueAndValidity();
      }
      this.mainForm.get('CCExpirationMonth').setValidators([Validators.required,
        Validators.min(1),
        Validators.max(12),
        this.regexValidator(new RegExp('^[0-9]+$'), {number: true})]);
      this.mainForm.get('CCExpirationMonth').updateValueAndValidity();
      this.mainForm.get('CCExpirationYear').setValidators([Validators.required,
        Validators.min(this.minYear),
        Validators.max(this.maxYear),
        this.regexValidator(new RegExp('^[0-9]+$'), {number: true})]);
      this.mainForm.get('CCExpirationYear').updateValueAndValidity();
      this.mainForm.get('ACHNameOnAccount').setValidators(null);
      this.mainForm.get('ACHNameOnAccount').updateValueAndValidity();
      this.mainForm.get('ACHAccountType').setValidators(null);
      this.mainForm.get('ACHAccountType').updateValueAndValidity();
      this.mainForm.get('ACHBankName').setValidators(null);
      this.mainForm.get('ACHBankName').updateValueAndValidity();
      this.mainForm.get('ACHRoutingNumber').setValidators(null);
      this.mainForm.get('ACHRoutingNumber').updateValueAndValidity();
      this.mainForm.get('ACHAccountNumber').setValidators(null);
      this.mainForm.get('ACHAccountNumber').updateValueAndValidity();

      this.mainForm.get('ACHNameOnAccount').setValue(null);
      this.mainForm.get('ACHAccountType').setValue(null);
      this.mainForm.get('ACHBankName').setValue(null);
      this.mainForm.get('ACHRoutingNumber').setValue(null);
      this.mainForm.get('ACHAccountNumber').setValue(null);
    } else {
      this.mainForm.get('ACHNameOnAccount').setValidators([Validators.required]);
      this.mainForm.get('ACHNameOnAccount').updateValueAndValidity();
      this.mainForm.get('ACHAccountType').setValidators([Validators.required]);
      this.mainForm.get('ACHAccountType').updateValueAndValidity();
      this.mainForm.get('ACHRoutingNumber').setValidators([Validators.required]);
      this.mainForm.get('ACHRoutingNumber').updateValueAndValidity();
      this.mainForm.get('ACHAccountNumber').setValidators([Validators.required]);
      this.mainForm.get('ACHAccountNumber').updateValueAndValidity();

      this.mainForm.get('CCNameOnAccount').setValidators(null);
      this.mainForm.get('CCNameOnAccount').updateValueAndValidity();
      this.mainForm.get('CCAccountNumber').setValidators(null);
      this.mainForm.get('CCAccountNumber').updateValueAndValidity();
      this.mainForm.get('CVV').setValidators(Validators.maxLength(4));
      this.mainForm.get('CVV').updateValueAndValidity();
      this.mainForm.get('CCExpirationMonth').setValidators(null);
      this.mainForm.get('CCExpirationMonth').updateValueAndValidity();
      this.mainForm.get('CCExpirationYear').setValidators(null);
      this.mainForm.get('CCExpirationYear').updateValueAndValidity();

      this.mainForm.get('ACHAccountType').setValue('CHECKING');
      this.mainForm.get('CCAccountNumber').setValue(null);
      this.mainForm.get('CCCardType').setValue(null);
      this.mainForm.get('CCExpirationMonth').setValue(null);
      this.mainForm.get('CCExpirationYear').setValue(null);
      this.mainForm.get('CVV').setValue(null);
    }
  }

  initAutoPay(disable: boolean): void {
    this.inputObjAutoPay = {
      labelText: 'Pay each month on this day',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setAutoPayId,
      disabled: disable
    };
    this.lookupsDataService.getAutoPayLookupData('member').then((lookupData) => {
      this.inputObjAutoPay.data = lookupData;
      this.inputObjAutoPay = Object.assign({}, this.inputObjAutoPay);
    });
  }

  getAutoPayData(event: any): void {
    if (event && event[0] && event[0].ID) {
      if (this.setAutoPayId !== event[0].ID) {
        this.mainForm.markAsDirty();
      }
      this.setAutoPayId = event[0].ID;
    } else {
      if (this.setAutoPayId) {
        this.mainForm.markAsDirty();
      }
      this.setAutoPayId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('AutoPaymentDay').setValue(this.setAutoPayId);
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      if (this.isEdit) {
        if (this.methodType === 'CC') {
          this.updatePaymentMethodCCModel.MethodType = this.mainForm.get('paymentMethodType').value;
          this.updatePaymentMethodCCModel.MethodNickname = this.mainForm.get('MethodNickname').value;
          this.updatePaymentMethodCCModel.CountryID = this.addressData.CountryID;
          this.updatePaymentMethodCCModel.Address1 = this.addressData.Address1;
          this.updatePaymentMethodCCModel.Address2 = this.addressData.Address2;
          this.updatePaymentMethodCCModel.City = this.addressData.City;
          this.updatePaymentMethodCCModel.StateProvinceID = this.addressData.StateProvinceID;
          this.updatePaymentMethodCCModel.Zip = this.addressData.Zip;
          this.updatePaymentMethodCCModel.PhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
          this.updatePaymentMethodCCModel.EmailAddress = this.mainForm.get('primaryEmail').value;
          this.updatePaymentMethodCCModel.NameOnAccount = this.mainForm.get('CCNameOnAccount').value;
          this.updatePaymentMethodCCModel.AutoPaymentScheduleTypeID = this.mainForm.get('AutoPaymentDay').value;
          this.updatePaymentMethodCCModel.CCExpirationMonth = this.mainForm.get('CCExpirationMonth').value;
          this.updatePaymentMethodCCModel.CCExpirationYear = this.mainForm.get('CCExpirationYear').value;
        } else {
          this.updatePaymentMethodACHModel.MethodType = this.mainForm.get('paymentMethodType').value;
          this.updatePaymentMethodACHModel.MethodNickname = this.mainForm.get('MethodNickname').value;
          this.updatePaymentMethodACHModel.CountryID = this.addressData.CountryID;
          this.updatePaymentMethodACHModel.Address1 = this.addressData.Address1;
          this.updatePaymentMethodACHModel.Address2 = this.addressData.Address2;
          this.updatePaymentMethodACHModel.City = this.addressData.City;
          this.updatePaymentMethodACHModel.StateProvinceID = this.addressData.StateProvinceID;
          this.updatePaymentMethodACHModel.Zip = this.addressData.Zip;
          this.updatePaymentMethodACHModel.PhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
          this.updatePaymentMethodACHModel.EmailAddress = this.mainForm.get('primaryEmail').value;
          this.updatePaymentMethodACHModel.NameOnAccount = this.mainForm.get('ACHNameOnAccount').value;
          this.updatePaymentMethodACHModel.AutoPaymentScheduleTypeID = this.mainForm.get('AutoPaymentDay').value;
        }
      } else {
        if (this.methodType === 'CC') {
          this.createPaymentMethodCCModel.DBEntity = this.DBEntity;
          this.createPaymentMethodCCModel.DBEntityKeyID = this.DBEntityID;
          this.createPaymentMethodCCModel.MethodType = this.mainForm.get('paymentMethodType').value;
          this.createPaymentMethodCCModel.MethodNickname = this.mainForm.get('MethodNickname').value;
          this.createPaymentMethodCCModel.CountryID = this.addressData.CountryID;
          this.createPaymentMethodCCModel.Address1 = this.addressData.Address1;
          this.createPaymentMethodCCModel.Address2 = this.addressData.Address2;
          this.createPaymentMethodCCModel.City = this.addressData.City;
          this.createPaymentMethodCCModel.StateProvinceID = this.addressData.StateProvinceID;
          this.createPaymentMethodCCModel.Zip = this.addressData.Zip;
          this.createPaymentMethodCCModel.PhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
          this.createPaymentMethodCCModel.EmailAddress = this.mainForm.get('primaryEmail').value;
          this.createPaymentMethodCCModel.AccountNumber = this.mainForm.get('CCAccountNumber').value;
          this.createPaymentMethodCCModel.NameOnAccount = this.mainForm.get('CCNameOnAccount').value;
          this.createPaymentMethodCCModel.OneTimeOnly = false;
          this.createPaymentMethodCCModel.AutoPaymentScheduleTypeID = this.mainForm.get('AutoPaymentDay').value;
          this.createPaymentMethodCCModel.CCExpirationMonth = this.mainForm.get('CCExpirationMonth').value;
          this.createPaymentMethodCCModel.CCExpirationYear = this.mainForm.get('CCExpirationYear').value;
          this.createPaymentMethodCCModel.CCCardType = this.mainForm.get('CCCardType').value;
          this.createPaymentMethodCCModel.CVV = this.mainForm.get('CVV').value;
        } else {
          this.createPaymentMethodACHModel.DBEntity = this.DBEntity;
          this.createPaymentMethodACHModel.DBEntityKeyID = this.DBEntityID;
          this.createPaymentMethodACHModel.MethodType = this.mainForm.get('paymentMethodType').value;
          this.createPaymentMethodACHModel.MethodNickname = this.mainForm.get('MethodNickname').value;
          this.createPaymentMethodACHModel.CountryID = this.addressData.CountryID;
          this.createPaymentMethodACHModel.Address1 = this.addressData.Address1;
          this.createPaymentMethodACHModel.Address2 = this.addressData.Address2;
          this.createPaymentMethodACHModel.City = this.addressData.City;
          this.createPaymentMethodACHModel.StateProvinceID = this.addressData.StateProvinceID;
          this.createPaymentMethodACHModel.Zip = this.addressData.Zip;
          this.createPaymentMethodACHModel.PhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
          this.createPaymentMethodACHModel.EmailAddress = this.mainForm.get('primaryEmail').value;
          this.createPaymentMethodACHModel.AccountNumber = this.mainForm.get('ACHAccountNumber').value;
          this.createPaymentMethodACHModel.NameOnAccount = this.mainForm.get('ACHNameOnAccount').value;
          this.createPaymentMethodACHModel.OneTimeOnly = false;
          this.createPaymentMethodACHModel.AutoPaymentScheduleTypeID = this.mainForm.get('AutoPaymentDay').value;
          this.createPaymentMethodACHModel.ACHAccountType = this.mainForm.get('ACHAccountType').value;
          this.createPaymentMethodACHModel.ACHRoutingNumber = this.mainForm.get('ACHRoutingNumber').value;
          this.createPaymentMethodACHModel.ACHBankName = this.mainForm.get('ACHBankName').value;
        }
      }
      this.saveForm();
    } else {
      this.messageService.add({severity: 'warn', summary: 'Missing Data', detail: 'No data was saved, the form is currently incomplete.'});
      this.existingMessage = false;
    }
  }

  saveForm(): void {
    if (!this.isEdit) {
      if (this.methodType === 'CC') {
        this.paymentMethodsService.createCCPaymentMethod(this.createPaymentMethodCCModel)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The payment method has been added.'
              });
              this.isSaving = false;
              this.closeAddEdit.emit(true);
            }, error: (e) => {
              if (e.error.Message) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', '')});
              }
              this.isSaving = false;
            }
          });
      } else {
        this.paymentMethodsService.createACHPaymentMethod(this.createPaymentMethodACHModel)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The payment method has been added.'
              });
              this.isSaving = false;
              this.closeAddEdit.emit(true);
            }, error: (e) => {
              if (e.error.Message) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', '')});
              }
              this.isSaving = false;
            }
          });
      }
    } else {
      if (this.methodType === 'CC') {
        this.paymentMethodsService.updateCCPaymentMethod(this.paymentMethodId, this.updatePaymentMethodCCModel)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.confirmationService.close();
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The payment method has been updated.'
              });
              this.isSaving = false;
              this.existingMessage = false;
              this.closeAddEdit.emit(true);
            }, error: (e) => {
              this.confirmationService.close();
              if (e.error.Message) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', '')});
              }
              this.isSaving = false;
              this.existingMessage = false;
            }
          });
      } else {
        this.paymentMethodsService.updateACHPaymentMethod(this.paymentMethodId, this.updatePaymentMethodACHModel)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.confirmationService.close();
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The payment method has been updated.'
              });
              this.isSaving = false;
              this.existingMessage = false;
              this.closeAddEdit.emit(true);
            }, error: (e) => {
              this.confirmationService.close();
              if (e.error.Message) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', '')});
              }
              this.isSaving = false;
              this.existingMessage = false;
            }
          });
      }
    }
  }

  cancel(): void {
    this.confirmationService.close();
    this.addressData = {} as AddressModel;
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm(this.isEdit);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  applyAddress(event: any): void {
    if (event && event === true) {
      this.addressData.Ready = false;
      switch (true) {
        case this.userObject === true && this.DBEntity === 'PERSON':
          this.userProfileService.getUserProfile()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.addressData.CountryID = res.PrimaryCountryID;
                this.addressData.Address1 = res.PrimaryAddress1;
                this.addressData.Address2 = res.PrimaryAddress2;
                this.addressData.City = res.PrimaryCity;
                this.addressData.StateProvinceID = res.PrimaryStateProvinceID;
                this.addressData.Zip = res.PrimaryZip;
                this.addressData.Ready = true;
                this.mainForm.get('primaryPhoneNumber').setValue(res.PrimaryPhoneNumber);
                this.mainForm.get('primaryEmail').setValue(res.PrimaryEmailAddress);
              }
            });
          break;
        case this.userObject === false && this.DBEntity === 'PERSON':
          this.rosterProfileService.getRosterProfile(this.DBEntityID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.addressData.CountryID = res.PrimaryCountryID;
                this.addressData.Address1 = res.PrimaryAddress1;
                this.addressData.Address2 = res.PrimaryAddress2;
                this.addressData.City = res.PrimaryCity;
                this.addressData.StateProvinceID = res.PrimaryStateProvinceID;
                this.addressData.Zip = res.PrimaryZip;
                this.addressData.Ready = true;
                this.mainForm.get('primaryPhoneNumber').setValue(res.PrimaryPhoneNumber);
                this.mainForm.get('primaryEmail').setValue(res.PrimaryEmailAddress);
              }
            });
          break;
        case this.DBEntity === 'ORGANIZATION':
          this.organizationService.getOrganization(this.DBEntityID, 'union', 0)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.addressData.CountryID = res.CountryID;
                this.addressData.Address1 = res.Address1;
                this.addressData.Address2 = res.Address2;
                this.addressData.City = res.City;
                this.addressData.StateProvinceID = res.StateProvinceID;
                this.addressData.Zip = res.Zip;
                this.addressData.Ready = true;
                this.mainForm.get('primaryPhoneNumber').setValue(res.PhoneNumber);
                this.mainForm.get('primaryEmail').setValue(res.EmailAddress);
              }
            });
          break;
      }
    }
  }

  setAddress(addressChanges: AddressModel): void {
    if (addressChanges) {
      this.mainForm.markAsDirty();
      this.addressData.CountryID = addressChanges.CountryID;
      this.addressData.Address1 = addressChanges.Address1;
      this.addressData.Address2 = addressChanges.Address2;
      this.addressData.City = addressChanges.City;
      this.addressData.StateProvinceID = addressChanges.StateProvinceID;
      this.addressData.Zip = addressChanges.Zip;
    }
  }
}
