import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterModel, PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {RosterStudentTabContainerComponent} from '../../../roster-student/roster-student-tab-container/roster-student-tab-container.component';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {ClassStudentsService} from '../../../services/class-students.service';
import {ClassStudentModel, ClassStudentsGridModel, StudentAttendanceReportModel, StudentProgressReportModel} from '../../../models/class-students.model';
import {AdhocCommDialogComponent} from '../../../shared/components/adhoc-comm-dialog/adhoc-comm-dialog.component';
import {tabsPermissionsUtility} from '../../../shared/utilities/tabs-permissions.utility';
import {InterfaceObjectAppsModel} from '../../../models/interface-objects.model';
import {PageService} from '../../../services/page.services';
import {StudentsCompleteDialogComponent} from './students-complete-dialog/students-complete-dialog.component';
import {GlobalRosterStudentTabContainerComponent} from '../../../global-roster-student/global-roster-student-tab-container/global-roster-student-tab-container.component';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {DatePipe} from '@angular/common';
import * as FileSaver from 'file-saver';
import {InactiveStatusUtility} from '../../../shared/utilities/inactive-status.utility';
import {ContextService} from '../../../services/context.service';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-class-tab-students',
  templateUrl: './class-tab-students.component.html',
  styleUrls: ['./class-tab-students.component.scss']
})

export class ClassTabStudentsComponent implements OnInit, AfterViewInit, OnChanges {
  InterfaceObjectEnum: string = 'CLASSES';
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  permissions: InterfaceObjectAppsModel[] = [];
  showTable: boolean = false;
  Students: ClassStudentsGridModel;
  StudentList: ClassStudentModel[];
  cols: any[];
  exportColumns: any[];
  loading: boolean;
  isExporting: boolean;
  selStudent: ClassStudentModel[];
  inputObjStatus: GenericSelectModel;
  setStatus: any[];
  inputObjGrades: GenericSelectModel;
  setGrades: any[];
  isButtonDisabled: boolean = true;
  exportItems: any[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  showData: boolean = false;
  showGrid: boolean = false;
  selectedItem: number;
  showAddEdit: boolean;
  dataTotal: number = 0;
  showAdd: boolean = false;
  studentAttendanceReport = {} as StudentAttendanceReportModel;
  studentProgressReport = {} as StudentProgressReportModel;
  inactiveStatuses: string[] = [];
  inactiveFlagStatuses: string[] = [];
  blobToken: string;
  @Input() ClassID: number;
  @Input() canTabWrite: boolean;
  @Input() courseId: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() OrganizationID: number;
  @Input() isModule: boolean;
  @Input() canAdd: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();

  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private classStudentsService: ClassStudentsService, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private router: Router,
              private dialogUtility: DialogUtility, private changeDetection: ChangeDetectorRef,
              public dialogService: DialogService, private pageService: PageService,
              private confirmationService: ConfirmationService,
              private datePipe: DatePipe, private inactiveStatusUtility: InactiveStatusUtility,
              private contextService: ContextService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.blobToken = this.contextService.contextObject.blobToken;
    this.pageService.getTabPermissions(this.InterfaceObjectEnum).then((tabPermissions: InterfaceObjectAppsModel[]) => {
      this.permissions = tabPermissions;
      this.inactiveStatusUtility.getTrainingStatuses('class').then(x => {
        this.inactiveFlagStatuses = x;
      }).catch(y => {
        this.inactiveFlagStatuses = y;
      });
      this.inactiveStatusUtility.getPersonProfileInactiveStatuses('membership').then(x => {
        this.inactiveStatuses = x;
      }).catch(y => {
        this.inactiveStatuses = y;
      });
    });
    this.cols = [
      {
        field: 'StudentID',
        header: 'ID',
        columnType: 'numeric',
        matchMode: 'equals',
        displayType: 'numeric',
        visible: true
      },
      {
        field: 'FirstName',
        header: 'First Name',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'LastName',
        header: 'Last Name',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'MemberNumber',
        header: 'Member #',
        columnType: 'text',
        matchMode: 'equals',
        displayType: 'text',
        visible: true
      },
      {
        field: 'TrainingStatusDescription',
        header: 'Training Center',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'StartDate_D',
        header: 'Start Date',
        columnType: 'date',
        matchMode: 'equals',
        displayType: 'date',
        visible: true
      },
      {
        field: 'EndDate_D',
        header: 'End Date',
        columnType: 'date',
        matchMode: 'equals',
        displayType: 'date',
        visible: true
      },
      {
        field: 'ClassStudentID',
        header: 'Student #',
        columnType: 'numeric',
        matchMode: 'equals',
        displayType: 'numeric',
        visible: true
      }
    ];
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    this.initStatus(false);
  }

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showGrid = true;
    if (changes.ClassID) {
      this.getData();
    }
    if (changes.isModule) {
      if (this.isModule) {
        this.initGrades(false);
      }
    }
    // if(changes.canAdd) {
    //   console.log(this.canAdd);
    // }
  }

  initStatus(disable: boolean) {
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getTrainingStatusesLookupData('class', false, false).then((lookupData) => {
      this.inputObjStatus.data = lookupData;
      this.inputObjStatus = Object.assign({}, this.inputObjStatus);
    });
  }

  getStatusData(event:any) {
    if (event) {
      this.setStatus = event;
    } else {
      this.setStatus = null;
    }
  }

  filterStatus(): void {
    this.currentFilter.filters.TrainingStatusDescription = [];
    if (this.setStatus && this.setStatus.length > 0) {
      const selected: FilterModel[] = [];
      this.setStatus.forEach(x => {
        selected.push({value: x, matchMode: 'equals', operator: 'or'});
      });
      this.currentFilter.filters.TrainingStatusDescription = selected;
    }
  }

  initGrades(disable: boolean) {
    this.inputObjGrades = {
      labelText: 'Grade',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getModuleGradeLookupData(false).then((lookupData) => {
      this.inputObjGrades.data = lookupData;
      this.inputObjGrades = Object.assign({}, this.inputObjGrades);
    });
  }

  getGradeData(event:any) {
    if (event) {
      this.setGrades = event;
    } else {
      this.setGrades = null;
    }
  }

  filterGrade(): void {
    this.currentFilter.filters.ResultID = [];
    if (this.setGrades && this.setGrades.length > 0) {
      const selected: FilterModel[] = [];
      this.setGrades.forEach(x => {
        selected.push({value: x, matchMode: 'equals', operator: 'or'});
      });
      this.currentFilter.filters.ResultID = selected;
    }
  }

  getData(): void {
    this.getTotal();
    this.getList();
    this.showAdd = false;
  }

  getList() {
    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.classStudentsService.getClassStudentsPrimeNG('DATA', this.ClassID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.Students = res;
          this.StudentList = res.Data;
          if (this.canTabWrite && this.StudentList.length === 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.loading = false;
        }
      });
  }

  getTotal() {
    this.loading = true;
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.classStudentsService.getClassStudentsPrimeNG('TOTAL', this.ClassID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  openStudent(PersonID) {
    const selectedStudent = this.StudentList.find(x => x.PersonID === PersonID);
    const ref = this.dialogService.open(RosterStudentTabContainerComponent, {
      data: {
        id: PersonID,
        isEdit: true,
        isDialog: true
      },
      header: selectedStudent.FirstName + ' ' + selectedStudent.LastName,
      width: '90%'
    });
  }

  openGlobalStudent(PersonID) {
    const selectedStudent = this.StudentList.find(x => x.PersonID === PersonID);
    const ref = this.dialogService.open(GlobalRosterStudentTabContainerComponent, {
      data: {
        id: PersonID,
        isEdit: true,
        isDialog: true
      },
      header: selectedStudent.FirstName + ' ' + selectedStudent.LastName,
      width: '90%'
    });
  }

  delete(classId: number, classStudentId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.classStudentsService.deleteClassStudent(classId, classStudentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected period has been removed.'
            });
            this.updateList();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList() {
    this.showAddEdit = false;
    this.getData();
    this.selectedItem = -1;
    this.closeAddEdit.emit();
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterItems() {
    this.loading = true;
    // this.initFilters('FirstName', this.activeGlobalFilter, 'contains', 'and');
    this.initFilters('FullName', this.activeGlobalFilter, 'contains', 'and');
    this.filterStatus();
    this.filterGrade();
    this.getData();
  }

  clearFilters() {
    this.loading = true;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.setStatus = [];
    this.setGrades = [];
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  goToEdit(id) {
    sessionStorage.setItem('sessionViewType', 'Grid');
    this.router.navigate(['training/students/edit/', +id]);
  }

  setDefaultPic(i) {
    this.StudentList[i].ThumbnailUrl = 'assets/images/profile-generic.png';
  }

  setAllComplete() {
    const ref = this.dialogService.open(StudentsCompleteDialogComponent, {
      data: {
        classId: this.ClassID,
        courseId: this.courseId,
        students: this.StudentList
      },
      header: 'Mark all Students as Complete',
      width: '80%',
      styleClass: 'dialog-form'
    });
    ref.onClose.subscribe({
      next: () => {
        this.getList();
      }
    });
  }

  loadList() {
    /*this.classWageRateService.getClassWageRateKGrid().then(() => {
      this.loading = false;
      this.showAddEdit = false;
    });*/
  }

  refresh() {
    this.getData();
  }

  expandData(index, id) {
    this.showData = !this.showData;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  resetComponent() {
    this.StudentList = undefined;
    this.Students = undefined;
    this.cols = [];
  }

  openAdhocComm(recipients) {
    const ref = this.dialogService.open(AdhocCommDialogComponent, {
      data: {
        recipients,
        isEdit: true,
        isDialog: true,
        DBEntity: 'CLASS',
        DBEntityID: this.ClassID,
        organizationId: this.OrganizationID
      },
      header: 'Email or Text Student',
      width: '70%',
      height: '70%'
    });
  }

  exportAttendance(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
      exportFilter.first = 0;
      exportFilter.rows = null;
      this.classStudentsService.exportClassStudents(this.ClassID, exportFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Report download successful'});
            this.downloadFile(res, 'student-report.xlsx', 'text/plain;charset=utf-8');
            this.isExporting = false;
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Something went wrong. Please try again.'
              });
            }
          }
        });
    }, () => {
    });
  }

  export
  (): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.StudentList));
        const dataToExport = removeKeysFromArray(arr, ['FullName', 'ClassStudentID', 'PersonID', 'TrainingStatusID', 'ResultID', 'CanEdit', 'CanDelete',
          'PrimaryAddress3', 'PrimaryAddress4', 'PrimaryAddress5', 'PrimaryAddress6', 'ShowCertificationFields', 'CertificationResourceID', 'CertificationResourceReferenceID', 'DOB_D', 'DOBSecure_D']);
        exportData('class-students', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.classStudentsService.getClassStudentsPrimeNG('DATA', this.ClassID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['FullName', 'ClassStudentID', 'PersonID', 'TrainingStatusID', 'ResultID', 'CanEdit', 'CanDelete',
                'PrimaryAddress3', 'PrimaryAddress4', 'PrimaryAddress5', 'PrimaryAddress6', 'ShowCertificationFields', 'CertificationResourceID', 'CertificationResourceReferenceID', 'DOB_D', 'DOBSecure_D']);
              exportData('class-students', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }

  downloadFile(buffer, fileName, fileType) {
    import('file-saver').then(() => {
      const data: Blob = new Blob([buffer], {
        type: fileType
      });
      FileSaver.saveAs(data, fileName);
    });
  }

  getStudentAttendance(classStudentId) {
    this.classStudentsService.getStudentAttendanceReport(classStudentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.studentAttendanceReport = res;
          this.createAttendancePdf();
        }
      });
  }

  createAttendancePdf() {
    this.exportColumns = [['Block', 'Course/Topic', 'Date', 'Attendance Status', 'Notes']];
    const dataToExport = [];
    this.studentAttendanceReport.CourseAttendance.forEach(course => {
      dataToExport.push([
        this.studentAttendanceReport.ClassDescription,
        course.CourseDescription,
        this.datePipe.transform(course.Date_D, 'MM-dd-YYYY'),
        course.AttendanceStatusDescription,
        course.Notes
      ]);
    });
    const doc = new jsPDF('l', 'in');
    doc.text('Attendance Report', 5, .50).setFontSize(10);
    doc.text(this.studentAttendanceReport.FirstName + ' ' + this.studentAttendanceReport.LastName, .5, 1).setFontSize(10);
    doc.text('Member #: ' + this.studentAttendanceReport.MemberNumber, .5, 1.25).setFontSize(10);
    doc.text('Student Group: ' + this.studentAttendanceReport.StudentGroup, .5, 1.50).setFontSize(10);
    autoTable(doc,
      {
        head: this.exportColumns,
        body: dataToExport,
        startY: 1.75,
        didDrawCell: (data) => {

        },
      });
    doc.save('attendance-sheet.pdf');
  }

  getStudentProgress(classStudentId) {
    this.classStudentsService.getStudentProgressReport(classStudentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.studentProgressReport = res;
          this.createProgressPdf();
        }
      });
  }

  createProgressPdf() {
    this.exportColumns = [['Class', 'Date', 'Type', 'Pass/Fail', 'Grade']];
    const dataToExport = [];
    this.studentProgressReport.CourseProgress.forEach(course => {
      dataToExport.push([
        course.CourseDescription,
        this.datePipe.transform(course.Date_D, 'MM-dd-YYYY'),
        course.CognitiveLevelDescription,
        course.ResultDescription,
        course.Grade
      ]);
    });
    const doc = new jsPDF('l', 'in');
    doc.text('Progress Report', 5, .50).setFontSize(10);
    doc.text(this.studentProgressReport.FirstName + ' ' + this.studentProgressReport.LastName, .5, 1).setFontSize(10);
    doc.text('Member #: ' + this.studentProgressReport.MemberNumber, .5, 1.25).setFontSize(10);
    doc.text('Student Group: ' + this.studentProgressReport.StudentGroup, .5, 1.50).setFontSize(10);
    autoTable(doc,
      {
        head: this.exportColumns,
        body: dataToExport,
        startY: 1.75,
        didDrawCell: (data) => {

        },
      });
    doc.save('progress-report.pdf');
  }

  isStatusInActive(status
                     :
                     string
  ):
    any {
    return this.inactiveFlagStatuses.indexOf(status) > -1 && status !== 'Completed';
  }

  getAvatarClass(img
                   :
                   boolean, status
                   :
                   string
  ):
    any {
    let str: string = '';
    if (img === false) {
      str = '-no-image';
    }
    switch (true) {
      case status === 'Deceased':
        return 'deceased-status' + str;
      case status === 'Suspended':
        return 'suspended-status' + str;
      case this.inactiveStatuses.indexOf(status) > -1:
        return 'inactive-status' + str;
      case this.inactiveStatuses.indexOf(status) === -1:
        return 'active-status' + str;
    }
  }
}
