import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RosterProfileChangeLogsService} from '../../../../services/roster-profile-change-logs.service';
import {RosterProfileChangeLogModel, RosterProfileChangeLogsGridModel} from '../../../../models/roster-profile-change-logs.model';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {PageRowCountUtility} from '../../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-organizations-tab-roster-change-log-details',
  templateUrl: './organizations-tab-roster-change-log-details.component.html',
  styleUrls: ['./organizations-tab-roster-change-log-details.component.scss']
})
export class OrganizationsTabRosterChangeLogDetailsComponent implements OnInit, OnChanges, OnDestroy {
  personLogs: RosterProfileChangeLogsGridModel;
  personLogsList: RosterProfileChangeLogModel[];
  currentFilter = new PrimeTableFilterModel();
  personLogsCols = [];
  dataLoaded: boolean;
  showGrid: boolean;
  selectedDataTotal: number = 0;
  @Input() PersonID: number;
  @Input() startDate: string;
  @Input() endDate: string;

  private ngUnsubscribe = new Subject();

  constructor(private rosterProfileChangeLogsService: RosterProfileChangeLogsService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.personLogsCols = [
      {
        field: 'LogDate_D',
        header: 'Log Date',
        columnType: 'date',
        matchMode: 'equals',
        visible: true,
        displayType: 'date'
      },
      {
        field: 'EffectiveDate_D',
        header: 'Effective Date',
        columnType: 'date',
        matchMode: 'equals',
        visible: true,
        displayType: 'date'
      },
      {
        field: 'Category',
        header: 'Category',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {field: 'Type', header: 'Type', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {
        field: 'ChangedFrom',
        header: 'From',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {field: 'ChangedTo', header: 'To', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'User', header: 'User', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'}
    ];
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnChanges(): void {
    if (!this.personLogsList) {
      if (this.PersonID && this.startDate && this.endDate) {
        this.selectData();
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  selectData(): void {
    this.showGrid = true;
    this.rosterProfileChangeLogsService.getRosterChangeLogsPrimeNG('ALL', this.PersonID, 'membership', this.startDate, this.endDate, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.personLogsList = res.Data;
          this.personLogs = res;
          this.selectedDataTotal = res.Total;
          this.dataLoaded = true;
        }, error: () => {
          this.dataLoaded = true;
        }
      });
  }
}
