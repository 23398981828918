import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {CreateMailMergeBatchModel, MailMergeBatchDetailActionModel, MailMergeBatchDetailModel, MailMergeBatchModel, MailMergeBatchOfficerCCModel} from '../../../models/mail-merge-batches.model';
import {MailMergeBatchesService} from '../../../services/mail-merge-batches.service';
import {Location} from '@angular/common';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PageService} from '../../../services/page.services';
import {InterfaceObjectItemPermissions} from 'src/app/models/interface-objects.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {RouteContextUtility} from '../../utilities/route-context.utility';
import {ContextService} from '../../../services/context.service';
import {MessageService} from 'primeng/api';
import {RosterModel} from '../../../models/roster.model';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {StudentModel} from '../../../models/students.model';
import {DymoLabelUtility} from '../../utilities/dymo-label.utility';
import {ResourcesService} from '../../../services/resources.service';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {PageRowCountUtility} from '../../utilities/page-row-count.utility';

@Component({
  selector: 'app-adhoc-mail-merge-form',
  templateUrl: './adhoc-mail-merge-form.component.html',
  styleUrls: ['./adhoc-mail-merge-form.component.scss']
})
export class AdhocMailMergeFormComponent implements OnInit, OnDestroy {
  InterfaceObjectEnum: string = 'LETTERBATCHES';
  mainForm: FormGroup;
  inputObjTemplateType: GenericSelectModel;
  @Input() setTemplateTypeId: number;
  inputObjRosterQuery: GenericSelectModel;
  setRosterQueryId: number;
  inputObjOrganization: GenericSelectModel;
  @Input() setOrganizationId: number;
  @Input() mailMergeBatch: MailMergeBatchModel;
  inputObjTemplate: GenericSelectModel;
  inputObjAttachmentType: GenericSelectModel;
  selectedAttachmentType: number;
  setTemplateId: number;
  @Input() ContentID: number;
  @Input() isEdit: boolean;
  @Input() canPageWrite: boolean;
  isDialog: boolean = false;
  dataId: number;
  // mailMergeBatch: MailMergeBatchModel = {} as MailMergeBatchModel;
  origMailMergeBatch: MailMergeBatchModel = {} as MailMergeBatchModel;

  blobToken: string;
  //currentDetailFilter = new PrimeTableFilterModel();
  //mailMergeBatchDetailList: MailMergeBatchDetailModel[];
  //colsDetails: any[] = [];
  //selMailMergeBatchDetail: MailMergeBatchDetailModel[] = [];
  //mailMergeBatchDetailTotal: number = 0;
  //loadingDetailsTable: boolean = true;

  showLabels: boolean = false;
  inputData: any;
  currentOrg: number;
  maxStart: number = 30;

  pickList: boolean = false;

  currentMemberFilter = new PrimeTableFilterModel();
  pickListMember: boolean = false;
  selMember: RosterModel[] = [];

  currentStudentFilter = new PrimeTableFilterModel();
  pickListStudent: boolean = false;
  selStudent: StudentModel[] = [];

  currentCCFilter = new PrimeTableFilterModel();
  ccList: MailMergeBatchOfficerCCModel[];
  colsCC: any[] = [];
  selCC: MailMergeBatchOfficerCCModel[] = [];
  ccTotal: number = 0;
  loadingCCTable: boolean = true;

  currentCCEmpFilter = new PrimeTableFilterModel();
  ccEmpList: MailMergeBatchOfficerCCModel[];
  colsCCEmp: any[] = [];
  selCCEmp: MailMergeBatchOfficerCCModel[] = [];
  ccEmpTotal: number = 0;
  loadingCCEmpTable: boolean = true;

  loading: boolean = false;
  isSaving: boolean = false;
  isPage: boolean = false;
  routeContext: string;
  recipients: any[];
  personID: number = 0;
  isDymo: boolean = false;
  showHide: boolean = false;
  showHideCC: boolean = false;
  showHideCCEmp: boolean = false;
  pickListCC: boolean = false;
  pickListCCEmp: boolean = false;
  getListRequest;
  getTotalRequest;
  getListBatchEmployerRequest;
  getTotalBatchEmployerRequest;

  @Output() closeAddEdit = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private mailMergeBatchesService: MailMergeBatchesService,
              private router: Router, private route: ActivatedRoute,
              public config: DynamicDialogConfig, private formBuilder: FormBuilder,
              private location: Location, private pageService: PageService,
              private lookupsDataService: LookupsDataService, private routeContextUtility: RouteContextUtility,
              private contextService: ContextService, private messageService: MessageService,
              public ref: DynamicDialogRef, private dymoLabelUtility: DymoLabelUtility,
              private resourcesService: ResourcesService, private tenantConfigDataService: TenantConfigDataService,
              public pageRowCountUtility: PageRowCountUtility
  ) {
    this.mainForm = this.formBuilder.group({
      description: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      organization: new FormControl(null, Validators.required),
      template: new FormControl(null, Validators.required),
      recipients: new FormControl('A', Validators.required),
      // attach: new FormControl('Y', Validators.required),
      includeRec: new FormControl('Y', Validators.required),
      includeCC: new FormControl('Y', Validators.required),
      email: new FormControl(false, Validators.required),
      label: new FormControl(false, Validators.required),
      print: new FormControl(false, Validators.required),
      ccShowHide: new FormControl(false),
      ccEmpShowHide: new FormControl(false),
      attachmentType: new FormControl(false)
    });
  }

  ngOnInit(): void {
    this.blobToken = this.contextService.contextObject.blobToken;
    this.colsCC.push(
      {field: 'ContactType', header: 'Contact Type', fullHeader: 'Contact Type', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizationName', header: 'Organization', fullHeader: 'Organization', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Position', header: 'Position', fullHeader: 'Position', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryAddress1', header: 'Street Address', fullHeader: 'Street Address', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryAddress2', header: 'Ste, Bldg, Flr, etc', fullHeader: 'Ste, Bldg, Flr, etc', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryCity', header: 'City/Town', fullHeader: 'City/Town', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryStateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryZip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryEmailAddress', header: 'E-mail', fullHeader: 'E-mail', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true}
    );
    this.colsCCEmp.push(
      {field: 'ContactType', header: 'Contact Type', fullHeader: 'Contact Type', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizationName', header: 'Organization', fullHeader: 'Organization', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Position', header: 'Position', fullHeader: 'Position', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryAddress1', header: 'Street Address', fullHeader: 'Street Address', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryAddress2', header: 'Ste, Bldg, Flr, etc', fullHeader: 'Ste, Bldg, Flr, etc', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryCity', header: 'City/Town', fullHeader: 'City/Town', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryStateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryZip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryEmailAddress', header: 'E-mail', fullHeader: 'E-mail', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true}
    );
    // this.route.params.pipe().subscribe({next:(res) => {
    //   this.pageInfo = this.pageService.getPageType(res, this.config);
    //   if (this.pageInfo.isPage || this.pageInfo.isDialog) {
    //     this.isEdit = this.pageInfo.isEdit;
    //     this.isPage = this.pageInfo.isPage;
    //     this.ContentID = this.pageInfo.ContentID;
    //     this.setOrganizationId = this.pageInfo.OrganizationID;
    //     this.getDataToEdit(this.ContentID);
    //   }
    // }});
    if (this.canPageWrite === undefined) {
      this.pageService.getPagePermissions(this.InterfaceObjectEnum).then((permission: InterfaceObjectItemPermissions) => {
        this.canPageWrite = permission.Write;
      });
    }
    this.recipients = this.config.data.recipients;
    if (this.recipients && this.recipients[0]) {
      this.personID = this.recipients[0].PersonID;
      this.mainForm.get('description').setValue('Adhoc letter for ' + this.recipients[0].FirstName + ' ' + this.recipients[0].LastName);
    }
    this.selMember = [];
    this.selStudent = [];
    this.routeContext = this.routeContextUtility.getRouteContext();
    this.initSelects();
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    if (sessionStorage.getItem('isDymoInstalled') === null && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value === 'Yes') {
      this.dymoLabelUtility.isDymoInstalled().then(() => {
        this.dymoLabelUtility.isDymoPrinterInstalled().then((rtn) => {
          this.isDymo = rtn;
        }).catch(() => {
          this.isDymo = false;
        });
      }).catch(() => {
        this.isDymo = false;
      });
    } else {
      this.isDymo = (sessionStorage.getItem('isDymoInstalled') === 'Yes' && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value === 'Yes');
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initSelects(): void {
    this.initTemplateType(false);
    this.initOrganization(false, this.InterfaceObjectEnum, false);
  }

  initTemplateType(disable: boolean): void {
    this.inputObjTemplateType = {
      labelText: 'Template Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: (!this.setTemplateTypeId),
      initSelected: this.setTemplateTypeId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true,
      canWrite: this.canPageWrite
    };
    this.lookupsDataService.getMailMergeTypesLookupData(this.routeContext).then((lookupData) => {
      this.inputObjTemplateType.data = lookupData;
      this.inputObjTemplateType = Object.assign({}, this.inputObjTemplateType);
    });
  }

  setTemplateTypeData(event: any): void {
    let desc: string;
    this.pickListMember = false;
    this.pickListStudent = false;
    if (event && event[0]) {
      this.setTemplateTypeId = event[0].ID;
      desc = event[0].Description;
    } else {
      this.setTemplateTypeId = null;
      desc = null;
    }
    switch (desc) {
      case 'Member Roster':
        this.pickListMember = true;
        break;
      case 'Student Roster':
        this.pickListStudent = true;
        break;
    }
    if (this.pickListMember) {
      this.selMember = this.recipients;
    } else if (this.pickListStudent) {
      this.selStudent = this.recipients;
    }
    this.mainForm.get('type').setValue(this.setTemplateTypeId);
    this.mainForm.markAsDirty();
    if (this.showHideCC) {
      this.loadCCTable();
    }
    if (this.showHideCCEmp) {
      this.loadCCEmpTable();
    }
    this.initTemplate(false);
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: (!this.setOrganizationId),
      initSelected: this.setOrganizationId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true,
      canWrite: this.canPageWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly, this.routeContext).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  setOrganizationData(event: any): void {
    this.showHide = false;
    this.ccClearHide();
    this.ccEmpClearHide();
    delete this.currentCCFilter.filters?.OrganizationName;
    delete this.currentCCEmpFilter.filters?.OrganizationName;
    if (event && event[0] && event[0]?.ID) {
      this.setOrganizationId = (event[0].ID !== null) ? event[0].ID : null;
      if (event[0].Description) {
        this.currentCCFilter.filters = {
          OrganizationName: [
            {
              value: event[0].Description.replace(/--/g, ''),
              matchMode: 'equals',
              operator: 'and'
            }
          ]
        };
        this.currentCCEmpFilter.filters = {
          OrganizationName: [
            {
              value: event[0].Description.replace(/--/g, ''),
              matchMode: 'equals',
              operator: 'and'
            }
          ]
        };
      }
      this.initAttachmentType(false);
    } else {
      this.setOrganizationId = null;
    }
    this.mainForm.get('organization').setValue(this.setOrganizationId);
    this.mainForm.markAsDirty();
    this.initTemplate(false);
  }

  initTemplate(disable: boolean): void {
    this.inputObjTemplate = {
      labelText: 'Template',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: this.canPageWrite
    };
    if (this.setTemplateTypeId && this.setOrganizationId) {
      this.lookupsDataService.getMailMergeTemplateTypesLookupData(this.setTemplateTypeId, this.setOrganizationId).then((lookupData) => {
        this.inputObjTemplate.data = lookupData;
        this.inputObjTemplate = Object.assign({}, this.inputObjTemplate);
      });
    }
  }

  setTemplateData(event: any): void {
    this.ccClearHide();
    this.ccEmpClearHide();
    if (event && event[0] && event[0]?.ID) {
      this.setTemplateId = event[0].ID;
      this.showHide = true;
    } else {
      this.setTemplateId = null;
      this.showHide = false;
    }
    this.mainForm.get('template').setValue(this.setTemplateId);
    this.mainForm.markAsDirty();
  }

  initAttachmentType(disable: boolean): void {
    this.inputObjAttachmentType = {
      labelText: 'Attachments',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: this.canPageWrite
    };
    this.lookupsDataService.getAttachmentTypesLookupData().then((lookupData) => {
      this.inputObjAttachmentType.data = lookupData;
      this.inputObjAttachmentType = Object.assign({}, this.inputObjAttachmentType);
    });
  }

  setAttachmentType(event: any): void {
    if (event && event[0] && event[0]?.ID) {
      this.selectedAttachmentType = event[0].ID;
    } else {
      this.selectedAttachmentType = null;
    }
    this.mainForm.get('attachmentType').setValue(this.selectedAttachmentType);
    this.mainForm.markAsDirty();
  }

  clearFilters() {
    this.currentCCFilter.filters = {};
    this.loadCCTable(this.currentCCFilter);
  }

  loadCCTable(event?): void {
    if (event) {
      this.currentCCFilter = event;
    } else {
      this.currentCCFilter.filters = {};
      this.currentCCFilter.sortOrder = 1;
    }
    this.getCCTotal();
    this.getCCList();
  }

  getCCTotal(): void {
    const recipientIDArray: number[] = [this.personID];
    if (this.setOrganizationId && this.setTemplateTypeId) {
      if (this.getTotalRequest) {
        this.getTotalRequest.unsubscribe();
      }
      this.getTotalRequest = this.mailMergeBatchesService.getMailMergeBatchOfficerCCPrimeNG('TOTAL', this.setOrganizationId, this.setTemplateTypeId, this.currentCCFilter, 0, recipientIDArray)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.ccTotal = res.Total;
          }, error: () => {
            this.ccTotal = 0;
          }
        });
    } else {
      this.ccTotal = 0;
    }
  }

  getCCList(): void {
    const recipientIDArray: number[] = [this.personID];
    this.loadingCCTable = true;
    this.selCC = [];
    if (this.setOrganizationId && this.setTemplateTypeId) {
      if (this.getListRequest) {
        this.getListRequest.unsubscribe();
      }
      this.getListRequest = this.mailMergeBatchesService.getMailMergeBatchOfficerCCPrimeNG('DATA', this.setOrganizationId, this.setTemplateTypeId, this.currentCCFilter, 0, recipientIDArray)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.ccList = res.Data;
            this.loadingCCTable = false;
          }, error: () => {
            this.ccList = [];
            this.loadingCCTable = false;
          }
        });
    } else {
      this.ccList = [];
      this.loadingCCTable = false;
    }
  }

  clearCCEmpFilters() {
    this.currentCCEmpFilter.filters = {};
    this.loadCCEmpTable(this.currentCCEmpFilter);
  }

  loadCCEmpTable(event?): void {
    if (event) {
      this.currentCCEmpFilter = event;
    } else {
      this.currentCCEmpFilter.filters = {};
      this.currentCCEmpFilter.sortOrder = 1;
    }
    this.getCCEmpTotal();
    this.getCCEmpList();
  }

  getCCEmpTotal(): void {
    const recipientIDArray: number[] = [this.personID];
    if (this.setOrganizationId && this.setTemplateTypeId) {
      if (this.getTotalBatchEmployerRequest) {
        this.getTotalBatchEmployerRequest.unsubscribe();
      }
      this.getTotalBatchEmployerRequest = this.mailMergeBatchesService.getMailMergeBatchEmployerCCPrimeNG('TOTAL', this.setOrganizationId, this.setTemplateTypeId, this.currentCCEmpFilter, 0, recipientIDArray)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.ccEmpTotal = res.Total;
          }, error: () => {
            this.ccEmpTotal = 0;
          }
        });
    } else {
      this.ccEmpTotal = 0;
    }
  }

  getCCEmpList(): void {
    const recipientIDArray: number[] = [this.personID];
    this.loadingCCEmpTable = true;
    this.selCCEmp = [];
    if (this.setOrganizationId && this.setTemplateTypeId) {
      if (this.getListBatchEmployerRequest) {
        this.getListBatchEmployerRequest.unsubscribe();
      }
      this.getListBatchEmployerRequest = this.mailMergeBatchesService.getMailMergeBatchEmployerCCPrimeNG('DATA', this.setOrganizationId, this.setTemplateTypeId, this.currentCCEmpFilter, 0, recipientIDArray)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.ccEmpList = res.Data;
            this.loadingCCEmpTable = false;
          }, error: () => {
            this.ccEmpList = [];
            this.loadingCCEmpTable = false;
          }
        });
    } else {
      this.ccEmpList = [];
      this.loadingCCEmpTable = false;
    }
  }

  detailsSaved(mailMergeBatch): void {
    this.isEdit = true;
    this.mailMergeBatch = mailMergeBatch;
    this.origMailMergeBatch = mailMergeBatch;
    this.closeAddEdit.emit();
  }

  back(): void {
    this.location.back();
  }

  processData(): void {
    this.isSaving = true;
    const data: CreateMailMergeBatchModel = {
      BatchDescription: this.mainForm.get('description').value,
      OrganizationID: this.setOrganizationId,
      MailMergeTemplateID: this.setTemplateId,
      FileType: 'PDF',
      // eslint-disable-next-line
      Parameters: JSON.stringify({'RosterQueryID': 0}),
      CreateSharables: true, //this.mainForm.get('attach').value === 'Y'
      LetterAttachmentTypeId: this.selectedAttachmentType
    };
    data.IncludeList = [this.personID];

    const ccIds: number[] = [];
    this.selCC.forEach(x => {
      ccIds.push(x.PersonID);
    });
    this.selCCEmp.forEach(x => {
      ccIds.push(x.PersonID);
    });
    data.CCPersonIDList = ccIds;
    this.mailMergeBatchesService.createAdhocMailMergeBatch(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: MailMergeBatchDetailModel[]) => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your letter has been created'});
          this.emailLetters(res).then(() => {
            this.printLabels(res).then(() => {
              this.openLetters(res).then(() => {
                setTimeout(() => {
                  this.isSaving = false;
                  this.mainForm.reset();
                  this.ref.destroy();
                }, 1500);
              });
            });
          });
        }, error: () => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          this.isSaving = false;
        }
      });
  }

  emailLetters(newData: MailMergeBatchDetailModel[]) {
    return new Promise((resolve, reject) => {
      if (this.mainForm.get('email').value === true && newData && newData[0] && newData[0].MailMergeBatchID) {
        const checkedDeliverIds: number[] = [];
        newData.forEach(x => {
          checkedDeliverIds.push(x.MailMergeBatchRowID);
        });
        const data: MailMergeBatchDetailActionModel = {
          MailMergeBatchRowIDList: checkedDeliverIds,
          IncludeRecipientList: true,
          IncludeCCList: this.mainForm.get('includeCC').value === 'Y'
        };
        this.mailMergeBatchesService.sendMailMergeBatchEmails(this.setOrganizationId, newData[0].MailMergeBatchID, data)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              resolve(null);
              this.messageService.add({severity: 'success', summary: 'Success', detail: 'E-mailing of this letter is underway. You will receive confirmation when it is complete.'});
            }, error: () => {
              resolve(null);
            }
          });
      } else {
        resolve(null);
      }
    });
  }

  printLabels(newData: MailMergeBatchDetailModel[]) {
    return new Promise((resolve, reject) => {
      if (this.mainForm.get('label').value === true) {
        newData.forEach(x => {
          if (x.PersonID) {
            this.dymoLabelUtility.rosterDymoLabel(x.PersonID);
          }
        });
        resolve(null);
      } else {
        resolve(null);
      }
    });
  }

  openLetters(newData: MailMergeBatchDetailModel[]) {
    return new Promise((resolve, reject) => {
      if (this.mainForm.get('print').value === true) {
        newData.forEach(x => {
          if (x.ResourceReferenceID) {
            this.resourcesService.getResourceById(x.ResourceReferenceID)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (resource) => {
                  window.open(resource.Url, '_blank');
                }
              });
          }
        });
        resolve(null);
      } else {
        resolve(null);
      }
    });
  }

  cancel(): void {
    this.ref.destroy();
  }

  isFormValid(): any {
    if (this.pickListMember) {
      return this.mainForm.valid && this.mainForm.dirty && this.selMember.length > 0;
    } else if (this.pickListStudent) {
      return this.mainForm.valid && this.mainForm.dirty && this.selStudent.length > 0;
    } else {
      return false;
    }
  }

  ccShowHide(): void {
    this.showHideCC = !this.showHideCC;
    if (!this.showHideCC) {
      this.ccList = [];
    }
  }

  ccEmpShowHide(): void {
    this.showHideCCEmp = !this.showHideCCEmp;
    if (!this.showHideCCEmp) {
      this.ccEmpList = [];
    }
  }

  ccClearHide(): void {
    this.pickListCC = false;
    this.showHideCC = false;
    this.mainForm.get('ccShowHide').setValue(false);
    this.ccList = [];
  }

  ccEmpClearHide(): void {
    this.pickListCCEmp = false;
    this.showHideCCEmp = false;
    this.mainForm.get('ccEmpShowHide').setValue(false);
    this.ccEmpList = [];
  }
}
