import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {MessageService} from 'primeng/api';
import {ContextService} from '../../../services/context.service';
import {UserRosterPiiAttestationService} from '../../../services/user-roster-pii-attestation.service';
import {UserRosterPiiAttestationModel} from '../../../models/user-roster-pii-attestation.model';
import {RosterProfileService} from '../../../services/roster-profile.service';

@Component({
  selector: 'app-prospects-tab-secure-data',
  templateUrl: './prospects-tab-secure-data.component.html',
  styleUrls: ['./prospects-tab-secure-data.component.scss']
})

export class ProspectsTabSecureDataComponent implements OnInit, OnDestroy, OnChanges {
  tenantName: string;
  supportEmail: string;
  membershipType: string;
  membershipStatus: string;
  isSaving: boolean = false;
  isViewSSN: boolean = false;
  newSSN: string;
  showSSN: string;
  hiddenSSN: string;
  agreementInfo: UserRosterPiiAttestationModel;
  isPrivacyChecked: boolean = false;
  @Input() isPage: boolean;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() Archived: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() PersonID: number;
  private ngUnsubscribe = new Subject();


  constructor(private ref: DynamicDialogRef,
              private config: DynamicDialogConfig, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private contextService: ContextService, private userRosterPiiAttestationService: UserRosterPiiAttestationService, private rosterProfileService: RosterProfileService) {
  }

  ngOnInit(): void {
    this.tenantName = this.contextService.contextObject.tenantName;
    this.supportEmail = this.contextService.contextObject.supportEmail;
    if (this.PersonID) {
      this.getRosterProfile();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges() {
    if (this.PersonID) {
      this.getRosterProfile();
    }
  }

  getRosterProfile() {
    const userProfileData = JSON.parse(sessionStorage.getItem('contextObject'));
    this.userRosterPiiAttestationService.getUserRosterPiiAttestation(userProfileData.PersonId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.agreementInfo = res;
          this.isPrivacyChecked = res.PiiAttestation;
          if (this.isPrivacyChecked) {
            this.getRosterProfilePii();
          }
        }
      });
  }

  updateRosterProfile() {
    const userProfileData = JSON.parse(sessionStorage.getItem('contextObject'));
    const date = new Date().toISOString().split('.')[0];
    const attestationObject = {
      PersonID: userProfileData.PersonId,
      PiiAttestation: true,
      PiiAttestationDate: date
    };

    this.userRosterPiiAttestationService.updateUserRosterPiiAttestation(userProfileData.PersonId, attestationObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Your profile has been successfully updated'
          });
          this.getRosterProfile();
        }, error: () => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
        }
      });
  }

  getRosterProfilePii() {
    this.rosterProfileService.getRosterProfilePii(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          if (res.SSN) {
            if (res.SSN !== null || res.SSN !== undefined) {
              this.newSSN = res.SSN;
            } else {
              this.newSSN = '';
            }
            this.hiddenSSN = this.newSSN.substring(this.newSSN.length - 4);
            this.hiddenSSN = '***-**-' + this.hiddenSSN;
            this.showSSN = this.hiddenSSN;
            this.canTabWrite = false;
          }
        }
      });
  }

  updateRosterProfilePii() {
    const ssnObject = {
      PersonID: this.PersonID,
      SSN: this.showSSN
    };

    this.rosterProfileService.updateRosterProfilePii(this.PersonID, ssnObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Your profile has been successfully updated'
          });
          this.getRosterProfile();
        }, error: () => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
        }
      });
  }

  toggleSSN() {
    if (this.isViewSSN && this.canTabWrite) {
      this.showSSN = this.newSSN;
    } else {
      this.showSSN = this.hiddenSSN;
    }
  }
}
