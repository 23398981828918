@if (!dataLoaded) {
  <span class="fa fa-spinner list-spinner"></span>
}
<form [formGroup]="mainForm" class="margin-top-10">
  <div class="p-grid p-fluid">
    <div class="col-md-6 col-sm-12 col-xs-12">
      <div class="p-grid p-fluid">
        <div class="col-md-9 col-sm-12 col-xs-12 input-container">
          <label>Name <sup class="req">*</sup></label>
          <input class="full-width" type="text" pInputText formControlName="name" tabindex="0">
          @if ((mainForm.controls.name.dirty || mainForm.controls.name.touched) &&
          mainForm.controls.name.errors?.required) {
            <span class="error">'Name' must be provided</span>
          }
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 input-container">
          <label>Number</label>
          <input class="full-width" type="text" pInputText formControlName="number" tabindex="0">
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>Abbreviation</label>
          <input class="full-width" type="text" pInputText formControlName="abbreviation" tabindex="0">
        </div>
        <!--        <div class="col-md-6 col-sm-12 col-xs-12 input-container">-->
        <!--          <label>Dues Bank Routing #</label>-->
        <!--          <div class="p-d-flex">-->
        <!--            <input class="full-width" type="text" pInputText  formControlName="bankRoutingNumber">-->
        <!--            <button pButton label="" class="p-button-info p-mr-2" [className]="'inline-button p-mt-0 p-ml-0 p-p-2'" icon="pi pi-times"  tooltipPosition="bottom"-->
        <!--                    (click)="clear('bankRoutingNumber')"></button>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-md-6 col-sm-12 col-xs-12 input-container">-->
        <!--          <label>Dues Bank Account #</label>-->
        <!--          <div class="p-d-flex">-->
        <!--            <input class="full-width" type="text" pInputText  formControlName="bankAccountNumber">-->
        <!--            <button pButton label="" class="p-button-info p-mr-2" [className]="'inline-button p-mt-0 p-ml-0 p-p-2'" icon="pi pi-times"  tooltipPosition="bottom"-->
        <!--                    (click)="clear('bankAccountNumber')"></button>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-md-6 col-sm-12 col-xs-12 input-container">-->
        <!--          <label>PAC Bank Routing #</label>-->
        <!--          <div class="p-d-flex">-->
        <!--            <input class="full-width" type="text" pInputText  formControlName="PACRoutingNumber">-->
        <!--            <button pButton label="" class="p-button-info p-mr-2" [className]="'inline-button p-mt-0 p-ml-0 p-p-2'" icon="pi pi-times"  tooltipPosition="bottom"-->
        <!--            (click)="clear('PACRoutingNumber')"></button>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-md-6 col-sm-12 col-xs-12 input-container">-->
        <!--          <label>PAC Bank Account #</label>-->
        <!--          <div class="p-d-flex">-->
        <!--            <input class="full-width" type="text" pInputText  formControlName="PACAccountNumber">-->
        <!--            <button pButton label="" class="p-button-info p-mr-2" [className]="'inline-button p-mt-0 p-ml-0 p-p-2'" icon="pi pi-times"  tooltipPosition="bottom"-->
        <!--                    (click)="clear('PACAccountNumber')"></button>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <p-checkbox class="checkbox" [binary]="true" formControlName="IsEmployer" label="Also an Employer?"
                        [disabled]="IsReferenced" tabindex="0"></p-checkbox>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <p-divider class="dark-divider"></p-divider>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 input-container mask-container">
          <label>Phone #</label>
          <p-inputMask type="text" pInputText formControlName="primaryPhoneNumber" [className]="'form-input no-border'"
                       mask="(999) 999-9999"
                       unmask="true" tabindex="0"></p-inputMask>
        </div>
        <div class="col-md-2 col-sm-12 col-xs-12 input-container">
          <label>Ext.</label>
          <input type="text" pInputText formControlName="primaryPhoneExtension" tabindex="0">
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>E-mail</label>
          <input type="text" pInputText formControlName="primaryEmail" tabindex="0">
          @if ((mainForm.controls.primaryEmail.dirty || mainForm.controls.primaryEmail.touched) &&
          mainForm.controls.primaryEmail?.errors) {
            <span class="error">'E-mail' must be valid</span>
          }
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 input-container">
      <p-card header="Address" class="parent-p-card">
        <div class="p-grid p-fluid">
          <div class="col-md-12 col-sm-12 col-xs-12">
            @if (currentOrganizations && addressData.Ready) {
              <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite"
                                [mapDialogDataTitle]="currentOrganizations?.Name"
                                [mapDialogHeader]="'Organization Location - ' + currentOrganizations?.Name"
                                [showCounty]="true" [showZipPlus4]="true"
                                (outgoingAddress)="setAddress($event)"></app-address-form>
            }
          </div>
        </div>
      </p-card>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <p-accordion>
        <p-accordionTab header="Details">
          <div class="p-fluid p-grid">
            @if (isAdmin) {
              <div class="col-md-6 col-sm-12 col-xs-12 input-container">
                <app-select class="full-width" [inputObj]="inputObjUTLaunchStatus"
                            (emitData)="getUTLaunchStatusData($event)"></app-select>
              </div>
              <div class="col-md-6 col-sm-0 col-xs-0 input-container"></div>
            }
            <div class="col-md-6 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjStatus"
                          (emitData)="getStatusData($event)"></app-select>
            </div>
            @if (!statusChanged) {
              <div class="col-md-6 col-sm-12 col-xs-12 input-container"></div>
            } @else {
              <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                <label>Status Reason <sup class="req">*</sup></label>
                <input class="full-width" type="text" pInputText formControlName="statusReason" tabindex="0">
              </div>
              <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                <label>Status Effective Date <sup class="req">*</sup></label>
                <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="statusEffectiveDate" appendTo="body"
                            placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025"
                            tabindex="0"></p-calendar>
              </div>
            }
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label>FEIN</label>
              <input class="full-width" type="text" pInputText formControlName="FEIN" tabindex="0">
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label>LM #</label>
              <input class="full-width" type="text" pInputText formControlName="LM" tabindex="0">
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label class="justify-right">Bond Amount</label>
              <!--              <p-inputNumber class="full-width"  mode="currency" currency="USD" locale="en-US" formControlName="bondAmount"></p-inputNumber>-->
              <app-currency-input [initValue]="mainForm.get('bondAmount').value"
                                  (emitData)="mainForm.get('bondAmount').setValue($event)"></app-currency-input>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label>Chartered</label>
              <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="chartered" appendTo="body"
                          placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025"
                          tabindex="0"></p-calendar>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label>Fiscal Year End Month</label>
              <p-inputNumber [showButtons]="true" [min]="1" [max]="12" formControlName="fiscalYearEndMonth"
                             tabindex="0"></p-inputNumber>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label>Fiscal Year End Day</label>
              <p-inputNumber [showButtons]="true" [min]="1" [max]="31" formControlName="fiscalYearEndDay"
                             tabindex="0"></p-inputNumber>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label>Charter Location</label>
              <input class="full-width" type="text" pInputText formControlName="charterLocation" tabindex="0">
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjProvidence"
                          (emitData)="getCharterStateData($event)"></app-select>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label>By Law Approval Date</label>
              <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="byLawApprovalDate" appendTo="body"
                          placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025"
                          tabindex="0"></p-calendar>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label>Next Election Date</label>
              <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="nextElectionDate" appendTo="body"
                          placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025"
                          tabindex="0"></p-calendar>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 input-container">
              <label>Meeting Place</label>
              <input class="full-width" type="text" pInputText formControlName="meetingPlace" tabindex="0">
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 text-align-right">
      <div class="action-buttons float-right margin-top-10">
        @if (isEdit) {
          <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh"
                  pTooltip="Reset" tooltipPosition="bottom"
                  (click)="cancel()" [disabled]="!mainForm.dirty"></button>
        } @else {
          <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times"
                  pTooltip="Cancel" tooltipPosition="bottom"
                  (click)="cancel()"></button>
        }
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                  tooltipPosition="bottom"
                  (click)="processData()" [className]="'inline-button'"
                  [disabled]="mainForm.invalid || !mainForm.dirty"></button>
        } @else {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  </div>
</form>
