@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
<form [formGroup]="mainForm" class="margin-top-10">
  <div class="p-grid p-fluid">
    @if (!isEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <!--        <shared-find-duplicate-users class="full-width" (duplicateFound)="handleDuplicate($event)" [requireDOB]="false"></shared-find-duplicate-users>-->
      </div>
    }
    @if (!isEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
    @if (!hideMemberData) {
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-2 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjHonorific"
                        (emitData)="getHonorificData($event)"></app-select>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 input-container">
            <label>First Name<sup class="req">*</sup></label>
            <input class="full-width" type="text" pInputText formControlName="firstName" tabindex="0">
            @if ((mainForm.controls.firstName.dirty || mainForm.controls.firstName.touched) &&
            mainForm.controls.firstName.errors?.required) {
              <span class="error">'firstName' must be provided</span>
            }
          </div>
          <div class="col-md-1 col-sm-12 col-xs-12 input-container">
            <label>Middle</label>
            <input class="full-width" type="text" pInputText formControlName="middleName" tabindex="0">
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 input-container">
            <label>Last Name<sup class="req">*</sup></label>
            <input class="full-width" type="text" pInputText formControlName="lastName" tabindex="0">
            @if ((mainForm.controls.lastName.dirty || mainForm.controls.lastName.touched) &&
            mainForm.controls.lastName.errors?.required) {
              <span class="error">'Last Name' must be provided</span>
            }
          </div>
          <div class="col-md-1 col-sm-12 col-xs-12 input-container">
            <label>Suffix</label>
            <input class="full-width" type="text" pInputText formControlName="suffix" tabindex="0">
          </div>
          <!--          <div class="col-md-6 col-sm-12 col-xs-12 input-container">-->
          <!--            <label>Date Of Birth</label>-->
          <!--            <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="dateOfBirth" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025"></p-calendar>-->
          <!--          </div>-->
          <div class="col-md-4 col-sm-12 col-xs-12 radio-container">
            <label class="p-mt-2">Gender</label>
            <p-radioButton value="F" formControlName="gender" [styleClass]="'radio-input'" class="p-mr-2" label="Female"
                           (onClick)="toggleGender()" tabindex="0"></p-radioButton>
            <p-radioButton value="M" formControlName="gender" [styleClass]="'radio-input'" label="Male"
                           (onClick)="toggleGender()" tabindex="0"></p-radioButton>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 input-container">
            <app-multi-select class="full-width" [inputObj]="inputObjRace"
                              (emitData)="getRaceData($event)"></app-multi-select>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 input-container">
            <label>Spoken Languages</label>
            <p-multiSelect [options]="languages" [(ngModel)]="selectedLanguages" [filter]="false"
                           [showToggleAll]="false" optionLabel="Description"
                           [ngModelOptions]="{standalone: true}" optionValue="ID" [styleClass]="'multi-select'"
                           [disabled]="!showSave" display="chip"
                           (onChange)="mainForm.markAsDirty()" appendTo="body"></p-multiSelect>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjEducation"
                        (emitData)="getEducationData($event)"></app-select>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjMilitary"
                        (emitData)="getMilitaryData($event)"></app-select>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p-divider class="dark-divider"></p-divider>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 input-container mask-container">
            <label>Home Phone #</label>
            <p-inputMask type="text" pInputText formControlName="primaryPhoneNumber"
                         [className]="'form-input no-border'" mask="(999) 999-9999"
                         unmask="true" tabindex="0"></p-inputMask>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 input-container mask-container"></div>
          <div class="col-md-6 col-sm-12 col-xs-12 input-container mask-container">
            <label>Mobile Phone #</label>
            <p-inputMask type="text" pInputText formControlName="secondaryPhoneNumber"
                         (change)="mobileCheckValidation(this.mainForm.controls.secondaryPhoneNumber.value)"
                         [className]="'form-input no-border'" mask="(999) 999-9999"
                         unmask="true" tabindex="0"></p-inputMask>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 radio-container">
            <label>SMS Text Messaging</label>
            <p-radioButton [value]="true" formControlName="SMSOptIn" [styleClass]="'radio-input'" class="p-mr-2"
                           label="Opt-In"></p-radioButton>
            <p-radioButton [value]="false" formControlName="SMSOptIn" [styleClass]="'radio-input'"
                           label="Opt-Out"></p-radioButton>
          </div>
          <div class="col-md-8 col-sm-12 col-xs-12">
            @if (mainForm.get('SMSOptIn').value === true) {
              <span>By choosing to opt into text messaging you may, from time to time, receive text message communication and surveys from {{ contextService.contextObject.tenantName }}
                . You may return to this page at any time to opt out of text messages.</span>
            }
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <label>E-mail
              @if (requirePrimaryEmail) {
                <sup class="req">*</sup>
              }

            </label>
            <div class="p-inputgroup w-full md:w-30rem">
              <input type="text" pInputText formControlName="primaryEmail" tabindex="0"
                     (focus)="isEditing = true"
                     (blur)="validatePii('PrimaryEmailAddress', mainForm.get('primaryEmail').value)">
              @if ((mainForm.controls.primaryEmail.dirty || mainForm.controls.primaryEmail.touched) &&
              mainForm.controls.primaryEmail?.errors) {
                <span class="error">'E-mail' must be valid</span>
              }
              @if ((mainForm.controls.primaryEmail.dirty || mainForm.controls.primaryEmail.touched) &&
              mainForm.controls.primaryEmail.errors?.required) {
                <span class="error">'E-mail' is required</span>
              }
              @if (isSearching) {
                <span class="p-inputgroup-addon pointer"><span class="fa fa-spinner search-spinner"></span></span>
              }
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 radio-container">
            <label>Email Messaging</label>
            <p-radioButton value="false" formControlName="EmailMsgOptIn" [styleClass]="'radio-input'" class="p-mr-2"
                           label="Opt-In" (onClick)="toggleEmailMsg()" tabindex="0"></p-radioButton>
            <p-radioButton value="true" formControlName="EmailMsgOptIn" [styleClass]="'radio-input'" label="Opt-Out"
                           (onClick)="toggleEmailMsg()" tabindex="0"></p-radioButton>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <label>Alt. E-mail</label>
            <input type="text" pInputText formControlName="secondaryEmail" tabindex="0">
            @if ((mainForm.controls.secondaryEmail.dirty || mainForm.controls.secondaryEmail.touched) &&
            mainForm.controls.secondaryEmail?.errors) {
              <span class="error">'E-mail' must be valid</span>
            }
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 radio-container">
            <label>Email Messaging</label>
            <p-radioButton value="false" formControlName="AltEmailMsgOptIn" [styleClass]="'radio-input'" class="p-mr-2"
                           label="Opt-In" (onClick)="toggleAltEmailMsgAlt()" tabindex="0"></p-radioButton>
            <p-radioButton value="true" formControlName="AltEmailMsgOptIn" [styleClass]="'radio-input'" label="Opt-Out"
                           (onClick)="toggleAltEmailMsgAlt()" tabindex="0"></p-radioButton>
          </div>
        </div>
      </div>
      @if (isEdit) {
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="p-grid p-fluid">
            <div class="col-md-12 col-sm-12 col-xs-12 input-container address-container">
              <app-addresses-tab [DBEntity]="'person'" [DBEntityID]="personID"
                                 [canTabWrite]="canTabWrite"></app-addresses-tab>
            </div>
          </div>
        </div>
      }
      @if (!isEdit) {
        <div class="col-md-6 col-sm-12 col-xs-12">
          <!--        <p-card header="Address" class="parent-p-card">-->
          <!--          <div class="p-grid p-fluid">-->
          <!--            <div class="col-md-12 col-sm-12 col-xs-12">-->
          <!--              <div class="p-grid p-fluid parent-form">-->
          <!--                <div class="col-md-4 col-sm-4 col-xs-12 input-container">-->
          <!--                  <app-select class="full-width" [inputObj]="inputObjCountry" (emitData)="getCountryData($event)"></app-select>-->
          <!--                </div>-->
          <!--                @if (suggestAddress) {-->
          <!--                  <div class="col-md-4 col-sm-4 col-xs-12 input-container">-->
          <!--                    <label>Address Lookup</label>-->
          <!--                    @if (!international) {-->
          <!--                      <p-autoComplete #autocomplete class="p-text-bold" [suggestions]="autocompleteData.items" (completeMethod)="search($event)" (onSelect)="applySuggestion($event)" placeholder="Search for an address" tabindex="0" [disabled]="mainForm.get('streetAddress').disabled">-->
          <!--                        <ng-template let-address pTemplate="addressDisplay">-->
          <!--                          <div>-->
          <!--                            {{address.street_line}}  {{address.city}}, {{address.state}} {{address.zipcode}}-->
          <!--                          </div>-->
          <!--                        </ng-template>-->
          <!--                      </p-autoComplete>-->
          <!--                    }-->
          <!--                    @if (international) {-->
          <!--                      <p-autoComplete #autocomplete class="p-text-bold" [suggestions]="intAutocompleteEntryData.items" (completeMethod)="searchIntCandidates($event)" (onSelect)="searchIntCandidates($event)" (onFocus)="internationalEntries === true && autocomplete.suggestions?.length > 0 && autocomplete.show()" (onHide)="internationalEntries === true && autocomplete.suggestions?.length > 0 && autocomplete.show()" placeholder="Search for an address" tabindex="0" [disabled]="mainForm.get('streetAddress').disabled">-->
          <!--                        <ng-template let-address pTemplate="addressDisplay">-->
          <!--                          <div>-->
          <!--                            {{address.address_text}}-->
          <!--                          </div>-->
          <!--                        </ng-template>-->
          <!--                      </p-autoComplete>-->
          <!--                    }-->
          <!--                  </div>-->
          <!--                }-->
          <!--                @if (this.longitude && this.latitude) {-->
          <!--                  <div class="col-md-4 col-sm-4 col-xs-12 p-mt-2">-->
          <!--                    &lt;!&ndash;                  <button pButton label="Map" (click)="showMap()" class="p-button-info margin-top-20"&ndash;&gt;-->
          <!--                  &lt;!&ndash;                          icon="pi pi-map-marker" pTooltip="Show on Map" tooltipPosition="bottom"></button>&ndash;&gt;-->
          <!--                </div>-->
          <!--              }-->
          <!--              <div class="col-md-6 col-sm-6 col-xs-12 input-container">-->
          <!--                <label>Street Address</label>-->
          <!--                <input type="text" pInputText formControlName="streetAddress" maxlength="100" (change)="clearLatLong('streetAddress')" tabindex="0">-->
          <!--              </div>-->
          <!--              <div class="col-md-6 col-sm-6 col-xs-12 input-container">-->
          <!--                <label>Suite, Building, Floor, etc.</label>-->
          <!--                <input type="text" pInputText formControlName="streetAddress2" maxlength="100" (change)="clearLatLong('streetAddress2')" tabindex="0">-->
          <!--              </div>-->
          <!--              <div class="col-xs-12 input-container" [ngClass]="setCountryId === 1 ? 'col-md-3 col-sm-3' : 'col-md-4 col-sm-4'">-->
          <!--                <label>City/Town</label>-->
          <!--                <input type="text" pInputText formControlName="city" maxlength="100" (change)="clearLatLong('city')" tabindex="0">-->
          <!--              </div>-->
          <!--              <div class="col-xs-12 input-container" [ngClass]="setCountryId === 1 ? 'col-md-3 col-sm-3' : 'col-md-4 col-sm-4'">-->
          <!--                <app-select class="full-width" [inputObj]="inputObjState" (emitData)="getStateData($event)"></app-select>-->
          <!--              </div>-->
          <!--              <div class="col-xs-12 input-container" [ngClass]="setCountryId === 1 ? 'col-md-3 col-sm-3' : 'col-md-4 col-sm-4'">-->
          <!--                <label>Postal Code</label>-->
          <!--                <input type="text" pInputText formControlName="postalCode" maxlength="10" (change)="clearLatLong('postalCode')" tabindex="0">-->
          <!--              </div>-->
          <!--              @if (setCountryId===1) {-->
          <!--                <div class="col-md-3 col-sm-3 col-xs-12 input-container">-->
          <!--                  <label>ZIP+4</label>-->
          <!--                  <input type="text" pInputText formControlName="zip4" maxlength="4" (change)="clearLatLong('zip4')" tabindex="0">-->
          <!--                </div>-->
          <!--              }-->
          <!--              <div class="col-md-12 col-sm-12 col-xs-12 input-container">-->
          <!--                <label>County</label>-->
          <!--                <input type="text" pInputText formControlName="county" maxlength="100" tabindex="0">-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </p-card>-->
          <div class="p-grid p-fluid p-mt-2">
            <div class="col-md-12 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjOrganization"
                          (emitData)="getOrganizationData($event)"></app-select>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjProspectType"
                          (emitData)="getProspectTypeData($event)"></app-select>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjProspectStatus"
                          (emitData)="getProspectStatusData($event)"></app-select>
            </div>
          </div>
        </div>
      }
    }
    @if (canTabWrite && !isEdit) {
      <div class="col-md-12 text-align-right margin-top-10">
        <div class="tab-form-buttons">
          <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times"
                  pTooltip="Cancel" tooltipPosition="bottom"
                  (click)="cancel()"></button>
          @if (!isSaving && showSave) {
            <button pButton label="Save" class="p-button-info" icon="pi pi-save" pTooltip="Save"
                    tooltipPosition="bottom"
                    (click)="processData()" [className]="'inline-button'"
                    [disabled]="mainForm.invalid || !mainForm.dirty || isSearching || isEditing"></button>
          }
          @if (isSaving && showSave) {
            <button pButton label="Save" class="p-button-info" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    }
    @if (canTabWrite && isEdit) {
      <div class="col-md-12 text-align-right margin-top-10">
        <div class="tab-form-buttons">
          <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh"
                  pTooltip="Reset" tooltipPosition="bottom"
                  (click)="cancel()" [disabled]="!mainForm.dirty"></button>
          @if (!isSaving) {
            <button pButton label="Save" class="p-button-info" icon="pi pi-save" pTooltip="Save"
                    tooltipPosition="bottom"
                    (click)="processData()" [className]="'inline-button'"
                    [disabled]="mainForm.invalid || !mainForm.dirty || isSearching || isEditing"></button>
          } @else {
            <button pButton label="Save" class="p-button-info" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    }
  </div>
</form>
