import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  WorkflowStepModel, WorkflowDetailedStepModel,
  CreateWorkflowStepModel, UpdateWorkflowStepModel, WorkflowStepGridModel, WorkflowStepTableGridModel, WorkflowStepFieldModel
} from '../models/workflow-steps.model';

@Injectable({
  providedIn: 'root',
})
export class WorkflowStepsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getWorkflowStepListPrimeNG(dataMode: string, workflowId: number, filterData): Observable<WorkflowStepGridModel> {
    return this.http.post<WorkflowStepGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/steplist?mode=${dataMode}`, filterData);
  }

  getDetailedWorkflowStepList(workflowId: number): Observable<WorkflowDetailedStepModel[]> {
    return this.http.get<WorkflowDetailedStepModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/detailedsteplist`);
  }

  createWorkflowStepExists(modelData: CreateWorkflowStepModel): Observable<WorkflowStepModel> {
    // provide StepDescription
    return this.http.post<WorkflowStepModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflowstep/create`, modelData);
  }

  createWorkflowStepNew(modelData: CreateWorkflowStepModel): Observable<WorkflowStepModel> {
    return this.http.post<WorkflowStepModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflowstep/create`, modelData);
  }

  getWorkflowProcessStep(workflowStepId: number, modelData: any): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/processstep/${workflowStepId}`, modelData);
  }

  getWorkflowProcessNextStep(workflowStepId: number, outcomeId: number, effDate: string): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/processstep/${workflowStepId}/nextsteps?outcomeid=${outcomeId}&effdate=${effDate}`);
  }

  updateWorkflowProcessStep(workflowStepId: number, modelData: any): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/processstep/${workflowStepId}`, modelData);
  }

  updateWorkflowStep(workflowStepId: number, modelData: UpdateWorkflowStepModel): Observable<WorkflowStepModel> {
    return this.http.put<WorkflowStepModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflowstep/${workflowStepId}`, modelData);
  }

  deleteWorkflowStep(workflowStepId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflowstep/remove/${workflowStepId}`);
  }

  reorderWorkflowSteps(workflowStepId1: number, workflowStepId2: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflowstep/reorder?id1=${workflowStepId1}&id2=${workflowStepId2}`, null);
  }

  getWorkflowStepPrimeNG(dataMode: string, caseId: number, filterData): Observable<WorkflowStepTableGridModel>{
    return this.http.post<WorkflowStepTableGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/process/${caseId}/stepgrid?mode=${dataMode}`, filterData);
  }

  getWorkflowInitialStep(workflowId: number): Observable<WorkflowStepFieldModel[]> {
    return this.http.get<WorkflowStepFieldModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/initialstepfields`);
  }
}
