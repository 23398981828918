<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <button pButton type="button" label="Active Classes" class="sub-tab"
            [ngClass]="{'active-tab' : activeSubTab === 'enrolled'}" (click)="activeSubTab = 'enrolled';"></button>
    <button pButton type="button" label="Completed Classes" class="sub-tab"
            [ngClass]="{'active-tab' : activeSubTab === 'completed'}" (click)="activeSubTab = 'completed';"></button>
    <button pButton type="button" label="Certifications" class="sub-tab"
            [ngClass]="{'active-tab' : activeSubTab === 'certifications'}"
            (click)="activeSubTab = 'certifications'"></button>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12">
    <p-divider class="dark-divider"></p-divider>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
    @switch (activeSubTab) {
      @case ('enrolled') {
        <app-student-classes [PersonID]="DBEntityID" [isEdit]="false" [canTabWrite]="canTabWrite"
                             [classStatus]="activeSubTab"></app-student-classes>
      }
      @case ('completed') {
        <app-student-classes [PersonID]="DBEntityID" [isEdit]="false" [canTabWrite]="canTabWrite"
                             [classStatus]="activeSubTab"></app-student-classes>
      }
      @case ('certifications') {
        <app-training-certifications-tab [DBEntity]="'PERSON'" [DBEntityID]="DBEntityID"
                                         [canTabWrite]="canTabWrite"></app-training-certifications-tab>
      }
    }
  </div>
</div>
