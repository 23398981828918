import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {PositionConfigsService} from '../../../../../services/position-config.service';
import {takeUntil} from 'rxjs/operators';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {PositionConfigModel} from '../../../../../models/position-config.model';
import {DialogService} from 'primeng/dynamicdialog';
import {AddNewOrganizationPositionComponent} from '../add-new-organization-position/add-new-organization-position.component';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-organization-position-options',
  templateUrl: './organization-position-options.component.html',
  styleUrls: ['./organization-position-options.component.scss']
})
export class PositionOptionsComponent implements OnInit, OnDestroy {
  currentFilter = new PrimeTableFilterModel();
  cols: any[] = [];
  positions: PositionConfigModel[];
  dataTotal = 0;
  selPosition: PositionConfigModel;
  loading: boolean = false;
  @Input() organizationId: number;
  @Input() organizationCategory: string;
  @Input() canTabWrite: boolean;
  @Input() canAdd: boolean;
  getListRequest;
  getTotalRequest;


  private ngUnsubscribe = new Subject();

  constructor(private positionConfigsService: PositionConfigsService, private dialogService: DialogService,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.cols = [
      {field: 'Current', header: 'Active', columnType: 'text', matchMode: 'contains', displayType: 'avatar', visible: true},
      {field: 'Description', header: 'Description', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Abbreviation', header: 'Abbreviation', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true}
    ];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getData() {
    this.getPositionsData();
    this.getPositionsTotal();
  }

  getPositionsData() {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.positionConfigsService.getPositionConfigsPrimeNG('DATA', this.organizationId, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.positions = res.Data;
        }
      });
  }

  getPositionsTotal() {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.positionConfigsService.getPositionConfigsPrimeNG('TOTAL', this.organizationId, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  loadTable(event:any): void {
    this.currentFilter = event;
    this.getData();
  }

  onRowSelect(event:any) {
    this.addEditPosition(true, event.data.PositionID);
  }

  onRowUnselect() {

  }

  goToEdit(event:any) {

  }

  addEditPosition(isEdit, selectedPositionId?) {
    const ref = this.dialogService.open(AddNewOrganizationPositionComponent, {
      data: {
        organizationId: this.organizationId,
        isEdit,
        selectedPositionId
      },
      header: isEdit ? 'Edit Organization Position' : 'Add New Organization Position',
      width: '80%',
      height: '70%'
    });
    ref.onDestroy.subscribe({
      next: () => {
        this.getData();
      }
    });
  }
}
