import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {ContextService} from '../../../../../services/context.service';
import {transformDateTimeToDateOnly} from '../../../../utilities/form.utility';
import {CreateSharableNoteModel, SharableNoteModel, SharableNotesGridModel, UpdateSharableModel} from '../../../../../models/sharables.model';
import {SharablesService} from '../../../../../services/sharables.service';
import {ResourcesService} from '../../../../../services/resources.service';

@Component({
  selector: 'app-notes-form',
  templateUrl: './notes-form.component.html',
  styleUrls: ['./notes-form.component.scss']
})
export class NotesFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  sharable = {} as SharableNoteModel;
  notes: SharableNotesGridModel;
  notesList: SharableNoteModel[];
  loading: boolean = false;
  sharableDate: Date;
  showProgress: boolean;
  createSharable = {} as CreateSharableNoteModel;
  updateSharable = {} as UpdateSharableModel;
  showUploader: boolean;
  fd: FormData = new FormData();
  uploadedFiles: any[] = [];
  isSaving: boolean;
  existingMessage: boolean = false;
  htmlBody: string;
  bodyIsHtml: boolean;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() isEdit: boolean;
  @Input() sharableID: number;
  @Input() canEdit: boolean;
  @Input() canTabWrite: boolean;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService,
              private sharablesService: SharablesService,
              private resourcesService: ResourcesService,
              private confirmationService: ConfirmationService,
              private contextService: ContextService
  ) {
    this.mainForm = this.formBuilder.group({
      fcnSharableBody: new FormControl(null, Validators.required),
      fcnSharableDate: new FormControl(null)
    });
  }

  ngOnInit(): void {
    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    this.initForm(this.isEdit);
    if (!this.canTabWrite || !this.canEdit) {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Note data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initForm(isEdit: boolean): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.loading = true;
    this.showUploader = false;
    this.uploadedFiles.length = 0;
    this.fd = new FormData();
    if (isEdit) {
      this.sharablesService.getSharable(this.sharableID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.sharable = res;
            this.bodyIsHtml = this.sharable.BodyIsHtml;
            // if (this.sharable.BodyIsHtml === true) {
            //   // strip html
            //   // Create a new div element
            //   const temporalDivElement = document.createElement('div');
            //   // Set the HTML content with the provided
            //   temporalDivElement.innerHTML = this.sharable.Body;
            //   // Retrieve the text property of the element (cross-browser support)
            //   this.htmlBody = temporalDivElement.textContent || temporalDivElement.innerText || '';
            // } else {
            this.htmlBody = this.sharable.Body;
            // }
            this.mainForm.get('fcnSharableBody').setValue(this.sharable.Body);
            if (this.sharable.PubDate) {
              this.sharableDate = new Date(this.sharable.PubDate);
              this.mainForm.get('fcnSharableDate').setValue(this.sharableDate);
            }
            this.loading = false;
          }
        });
    } else {
      this.bodyIsHtml = false;
      this.mainForm.get('fcnSharableDate').setValue(null);
      this.loading = false;
    }
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.saveForm();
    } else {
      this.messageService.add({severity: 'warn', summary: 'Missing Data', detail: 'No data was saved, the form is currently incomplete.'});
      this.existingMessage = false;
    }
  }

  saveForm(): void {
    const resources: any[] = [];
    let i: number;
    let ilen: number;
    this.saveNewFiles().then((fileData) => {
      if (!this.isEdit) {
        this.createSharable.Type = 'NOTE';
        this.createSharable.DBEntity = this.DBEntity;
        this.createSharable.DBEntityID = this.DBEntityID;
        this.createSharable.Body = this.mainForm.get('fcnSharableBody').value;
        this.createSharable.BodyIsHtml = false;
        this.createSharable.PubDate = transformDateTimeToDateOnly(this.mainForm.get('fcnSharableDate').value);
        // add new attachments
        if (fileData) {
          for (i = 0, ilen = fileData.length; i < ilen; i++) {
            resources.push({
              ResourceReferenceType: 'SHARABLEDOCUMENT',
              resourceID: fileData[i].ResourceId,
              Description: fileData[i].FileName
            });
          }
        }
        this.createSharable.Resources = resources;

        this.sharablesService.createSharableNote(this.createSharable)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.confirmationService.close();
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The note has been added.'
              });
              this.isSaving = false;
              this.existingMessage = false;
              this.closeAddEdit.emit(true);
            }
          });
      } else {
        this.updateSharable.ID = this.sharable.ID;
        this.updateSharable.Type = this.sharable.Type;
        this.updateSharable.DBEntity = this.sharable.DBEntity;
        this.updateSharable.DBEntityID = this.sharable.DBEntityID;
        this.updateSharable.Body = this.mainForm.get('fcnSharableBody').value;
        this.updateSharable.BodyIsHtml = false;
        this.updateSharable.PubDate = transformDateTimeToDateOnly(this.mainForm.get('fcnSharableDate').value);
        // add new attachments
        if (fileData) {
          for (i = 0, ilen = fileData.length; i < ilen; i++) {
            resources.push({
              ResourceID: fileData[i].ResourceId,
              ResourceReferenceType: 'SHARABLEDOCUMENT',
              ResourceReferenceID: fileData[i].ResourceReferenceID,
              MimeType: fileData[i].MimeType,
              Description: fileData[i].FileName
            });
          }
        }
        // include existing/remaining attachments
        if (this.sharable.Resources) {
          for (i = 0, ilen = this.sharable.Resources.length; i < ilen; i++) {
            resources.push(this.sharable.Resources[i]);
          }
        }
        this.updateSharable.Resources = resources;

        this.sharablesService.updateSharable(this.sharableID, this.updateSharable)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.confirmationService.close();
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The note has been updated.'
              });
              this.isSaving = false;
              this.existingMessage = false;
              this.closeAddEdit.emit(true);
            }
          });
      }
    });
  }

  saveNewFiles(): any {
    this.showProgress = true;
    return new Promise((resolve, reject) => {
      if (this.uploadedFiles.length > 0) {
        this.resourcesService.uploadResource(this.fd, 'secure')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (fileData) => {
              this.showProgress = false;
              resolve(fileData);
            }
          });
      } else {
        this.showProgress = false;
        resolve(null);
      }
      console.debug(reject);
    });
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm(this.isEdit);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  onUpload(event: any): any {
    if (event.files.length > 0) {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
        this.fd.append('file', file);
      }
      //this.messageService.add({severity: 'info', summary: 'File(s) Uploaded', detail: ''});
      this.mainForm.markAsDirty();
    }
  }

  removeResource(resourceReferenceId: number): void {
    this.sharable.Resources = this.sharable.Resources.filter(function (obj) {
      return obj.ResourceReferenceID !== resourceReferenceId;
    });
    this.mainForm.markAsDirty();
  }

  openResource(resourceReferenceId: number): void {
    this.resourcesService.getResourceById(resourceReferenceId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (resource) => {
          if (resource) {
            window.open(resource.Url, '_blank');
          }
        }
      });
  }
}
