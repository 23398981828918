import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SharableResourceModel, SharableResourcesGridModel} from '../../../../models/sharables.model';
import {FilterModel, PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SharablesService} from '../../../../services/sharables.service';
import {ResourcesService} from '../../../../services/resources.service';
import {MessageService} from 'primeng/api';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';
import {RosterProfileService} from '../../../../services/roster-profile.service';
import {RosterProfileModel} from '../../../../models/roster-profile.model';
import {ShowApplicantLinkDialogComponent} from './show-applicant-link-dialog/show-applicant-link-dialog.component';

@Component({
  selector: 'app-docs-tab',
  templateUrl: './docs-tab.component.html',
  styleUrls: ['./docs-tab.component.scss']
})
export class DocsTabComponent implements OnInit, OnChanges, OnDestroy {
  docs: SharableResourcesGridModel;
  docList: SharableResourceModel[] = [];
  personInfo: RosterProfileModel;
  dataLoaded: boolean;
  isExporting: boolean;
  totalLoaded: boolean;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  showAddEdit: boolean;
  docsTotal: number = 0;
  title: string = '';
  createdBy: string = '';
  mimeType: string = '';
  selectedItem: number;
  inputObjCategory: GenericSelectModel;
  selCategory: string[];
  showQRCodeButton: boolean;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() PersonID: number;
  @Input() canAdd: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() docData: any;
  @Input() canTabWrite: boolean;
  @Input() InterfaceObjectEnum: string;
  @Input() showAccess: boolean;
  @Output() resetList = new EventEmitter<boolean>();
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  getListRequest;
  getTotalRequest;
  first: number = 0;

  private ngUnsubscribe = new Subject();

  constructor(private sharablesService: SharablesService, private rosterProfileService: RosterProfileService,
              private messageService: MessageService, private dialogUtility: DialogUtility,
              private resourcesService: ResourcesService, private lookupsDataService: LookupsDataService,
              public dialogService: DialogService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {


    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.initCategory(false);

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
    if (changes.PersonID && this.PersonID) {
      console.log(this.PersonID);
      this.getRosterDetails(this.PersonID);
    }
  }

  getRosterDetails(personId: number): void {
    if (personId) {
      this.rosterProfileService.getRosterProfile(personId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.personInfo = res;
            this.showQRCodeButton = this.personInfo.StatusAlias === 'Pre-Applicant';
          }
        });
    }
  }

  getData(): void {
    if (this.DBEntity && this.DBEntityID) {
      if (this.DBEntity.toLowerCase() === 'organization') {
        if (this.getListRequest) {
          this.getListRequest.unsubscribe();
        }
        this.selectedItem = -1;
        this.docList.length = 0;
        this.dataLoaded = false;
        this.getListRequest = this.sharablesService.getSharableResourceGridByOrganizationPrimeNG('DATA', this.DBEntityID, this.currentFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.docs = res;
              this.docList = res.Data;
              this.dataLoaded = true;
            }
          });
        if (this.getTotalRequest) {
          this.getTotalRequest.unsubscribe();
        }
        this.docsTotal = 0;
        this.totalLoaded = false;
        this.getTotalRequest = this.sharablesService.getSharableResourceGridByOrganizationPrimeNG('TOTAL', this.DBEntityID, this.currentFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.docsTotal = res.Total;
              if (this.canAdd && this.docsTotal <= 0) {
                this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
              } else {
                this.showAddEdit = false;
              }
              this.totalLoaded = true;
            }, error: (e) => {
              console.debug(e);
              this.totalLoaded = true;
            }
          });
      } else {
        if (this.getListRequest) {
          this.getListRequest.unsubscribe();
        }
        this.selectedItem = -1;
        this.docList.length = 0;
        this.dataLoaded = false;
        this.getListRequest = this.sharablesService.getDocumentsPrimeNG('DATA', this.DBEntity, this.DBEntityID, this.currentFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.docs = res;
              this.docList = res.Data;
              this.dataLoaded = true;
            }, error: (e) => {
              console.debug(e);
            }
          });
        if (this.getTotalRequest) {
          this.getTotalRequest.unsubscribe();
        }
        this.docsTotal = 0;
        this.totalLoaded = false;
        this.getTotalRequest = this.sharablesService.getDocumentsPrimeNG('TOTAL', this.DBEntity, this.DBEntityID, this.currentFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.docsTotal = res.Total;
              if (this.canAdd && this.docsTotal <= 0) {
                this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
              } else {
                this.showAddEdit = false;
              }
              this.totalLoaded = true;
            }, error: (e) => {
              console.debug(e);
              this.totalLoaded = true;
            }
          });
      }
    } else {
      this.dataLoaded = true;
    }
  }

  deleteDoc(sharableId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.sharablesService.deleteSharable(sharableId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected document has been removed.'
            });
            this.updateList(true);
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList(dataChanged: boolean): void {
    this.showAddEdit = false;
    if (dataChanged) {
      this.getData();
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.first = event.first;
    this.getData();
  }

  filterCategory(): void {
    delete this.currentFilter.filters.CategoryAlias;
    if (this.selCategory && this.selCategory.length > 0) {
      const selected: FilterModel[] = [];
      this.selCategory.forEach(x => {
        selected.push({value: x, matchMode: 'contains', operator: 'or'});
      });
      this.currentFilter.filters.CategoryAlias = selected;
    }
  }

  filterItems(): void {
    this.dataLoaded = false;
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('Description', this.activeGlobalFilter, 'contains', 'or');
    } else {
      delete this.currentFilter.filters.Description;
    }
    if (this.title && this.title !== '') {
      this.initFilters('Title', this.title, 'contains', 'or');
    } else {
      delete this.currentFilter.filters.Title;
    }
    if (this.createdBy && this.createdBy !== '') {
      this.initFilters('CreatedBy', this.createdBy, 'contains', 'or');
    } else {
      delete this.currentFilter.filters.CreatedBy;
    }
    if (this.mimeType && this.mimeType !== '') {
      this.initFilters('MimeType', this.mimeType, 'contains', 'or');
    } else {
      delete this.currentFilter.filters.MimeType;
    }
    this.filterCategory();
    this.currentFilter.first = 0;
    this.first = 0;
    this.getData();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.title = '';
    this.createdBy = '';
    this.mimeType = '';
    this.initCategory(false);
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  refresh(): void {
    this.getData();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  initCategory(disable: boolean): void {
    this.inputObjCategory = {
      labelText: 'Category',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: false
    };
    this.lookupsDataService.getSharableCategoriesLookupData('document', this.InterfaceObjectEnum, false).then((lookupData) => {
      this.inputObjCategory.data = lookupData;
      this.inputObjCategory = Object.assign({}, this.inputObjCategory);
    });
  }

  setCategoryData(event:any): void {
    if (event) {
      this.selCategory = event;
    } else {
      this.selCategory = null;
    }
  }

  resetComponent(): void {
    this.docList = undefined;
    this.docs = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.docList.length <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.docList));
        const dataToExport = removeKeysFromArray(arr, ['ResourceReferenceId', 'SharableId', 'CanEdit', 'RosterCanSee']);
        exportData('documents', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        if (this.showAccess === true) {
          this.sharablesService.getSharableResourceGridByOrganizationPrimeNG('DATA', this.DBEntityID, exportFilter)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, ['ResourceReferenceId', 'SharableId', 'CanEdit', 'RosterCanSee']);
                exportData('documents', dataToExport);
                this.isExporting = false;
              }
            });
        } else {
          this.sharablesService.getDocumentsPrimeNG('DATA', this.DBEntity, this.DBEntityID, this.currentFilter)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, ['ResourceReferenceId', 'SharableId', 'CanEdit', 'RosterCanSee']);
                exportData('documents', dataToExport);
                this.isExporting = false;
              }
            });
        }
      }
    }, () => {
    });
  }

  openResource(resourceReferenceId: number): void {
    this.resourcesService.getResourceById(resourceReferenceId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (resource) => {
          if (resource) {
            window.open(resource.Url, '_blank');
          }
        }
      });
  }

  showQRCode() {
    const ref = this.dialogService.open(ShowApplicantLinkDialogComponent, {
      data: {
        person: this.personInfo,
      },
      header: 'Scan QR Code',
      width: '60%',
      height: '70%'
    });
  }
}
