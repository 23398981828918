@if (!isEdit) {
  <div class="p-grid p-fluid">
    <div class="col-md-3 col-sm-3 col-sm-3">
      <button pButton label="Back" (click)="back()" class="p-button-info" [className]="'back-button'"
              icon="pi pi-angle-left" pTooltip="Back" tooltipPosition="bottom"></button>
    </div>
  </div>
}
<p-card [header]="headerTitle" class="margin-left-10">
  <form [formGroup]="mainForm">
    <div class="p-fluid p-grid margin-top-15">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="p-fluid p-grid">
          <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
            <app-select class="full-width" [inputObj]="inputObjOrganization"
                        (emitData)="getOrganizationData($event)"></app-select>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-sm-7 col-xs-12">
        <div class="p-fluid p-grid">
          <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
            <label>Name of Project <sup class="req">*</sup></label>
            <input class="full-width" type="text" pInputText formControlName="projectName" tabindex="0">
            @if ((mainForm.controls.projectName.dirty || mainForm.controls.projectName.touched) &&
            mainForm.controls.projectName.errors?.required) {
              <span class="error">Project Name must be provided</span>
            }
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
            <app-multi-select class="full-width" [inputObj]="inputObjProjectOrganizers"
                              (emitData)="setProjectOrganizersData($event)"></app-multi-select>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
            <app-select class="full-width" [inputObj]="inputObjProjectType"
                        (emitData)="setProjectTypeData($event)"></app-select>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
            <app-select class="full-width" [inputObj]="inputObjProjectValue"
                        (emitData)="setProjectValueData($event)"></app-select>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
            <app-select class="full-width" [inputObj]="inputObjProjectPhase"
                        (emitData)="setProjectPhaseData($event)"></app-select>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 input-container margin-top-10">
            <app-select class="full-width" [inputObj]="inputObjProjectIndustry"
                        (emitData)="setProjectIndustryData($event)"></app-select>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 input-container">
            <label>Date of Visit<sup class="req" tabindex="0">*</sup></label>
            <p-calendar dateMask dateFormat="mm/dd/yy" [className]="'full-width'"
                        formControlName="dateOfVisit" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true"
                        tabindex="0"></p-calendar>
            @if ((mainForm.controls.dateOfVisit.dirty || mainForm.controls.dateOfVisit.touched) &&
            mainForm.controls.dateOfVisit.errors?.required) {
              <span class="error">'Date of Visit' must be provided</span>
            }
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 input-container">
            <label>Date of Follow Up Visit</label>
            <p-calendar dateMask dateFormat="mm/dd/yy" [className]="'full-width'"
                        formControlName="dateFollowUp" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true"
                        tabindex="0"></p-calendar>
          </div>
          <div class="col-md-2 col-sm-6 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjProjectFunding"
                        (emitData)="setProjectFundingData($event)"></app-select>
          </div>
          <div class="col-md-2 col-sm-6 col-xs-12 input-container">
            <label>Active Project<sup class="req">*</sup></label>
            <app-yes-no-select [isBoolean]="true" [nullable]="false"
                               [selectedValue]="projectReportData.ActiveProject"
                               (emitData)="projectReportData.ActiveProject = $event"></app-yes-no-select>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-5 col-xs-12">
        <p-card header="Address" class="address-card">
          <div class="p-grid p-fluid">
            <div class="col-md-12 col-sm-12 col-xs-12 input-container">
              @if (projectReportData && addressData.Ready) {
                <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [mapDialogDataTitle]="projectReportData?.ProjectName" [mapDialogHeader]="'Location - ' + projectReportData?.ProjectName" [required]="true" [requiredCounty]="true" [showCounty]="true" [showZipPlus4]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
              } @else if (!isEdit && addressData.Ready) {
                <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [mapDialogDataTitle]="mainForm?.controls?.projectName?.value" [mapDialogHeader]="'Location - ' + mainForm?.controls?.projectName?.value" [required]="true" [requiredCounty]="true" [showCounty]="true" [showZipPlus4]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
              }
            </div>
          </div>
        </p-card>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12"></div>
          <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
            @if (isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom" (click)="cancel()"
                      [disabled]="!mainForm.dirty"></button>
            } @else {
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom" (click)="cancel()"></button>
            }
            @if (!isSaving) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                      tooltipPosition="bottom" (click)="processData()" [className]="'inline-button'"
                      [disabled]="mainForm.invalid || addressData.Invalid || !mainForm.dirty"></button>
            } @else {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'"
                      [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  </form>
</p-card>
