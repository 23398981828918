import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  ClassStudentsGridModel,
  ClassStudentModel,
  CreateClassStudentModel,
  UpdateClassStudentModel,
  ClassStudentPickerGridModel,
  StudentGradesByCourseModel,
  StudentGradesGridModel,
  StudentGradeCertificateModel, StudentAttendanceReportModel, StudentProgressReportModel
} from '../models/class-students.model';
import { ExportDefinitionModel } from '../models/export-definitions.model';

@Injectable({
  providedIn: 'root',
})
export class ClassStudentsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getClassStudentsPrimeNG(dataMode: string, classId: number, filterData): Observable<ClassStudentsGridModel> {
    return this.http.post<ClassStudentsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/student/grid?mode=${dataMode}`, filterData);
  }

  exportClassStudentKGrid(classId: number, modelData: ExportDefinitionModel[]): Observable<any> {
    // export file types include xlsx and csv
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/student/export?filetype=xlsx`, modelData);
  }

  getClassStudentPickerPrimeNG(dataMode: string, classId: number, filterData, globalView?: boolean): Observable<ClassStudentPickerGridModel> {
    const global = (globalView === true) ? '&showall=true' : '';
    return this.http.post<ClassStudentPickerGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/student/picker?mode=${dataMode}${global}`, filterData);
  }

  getClassStudent(classId: number, classStudentId: number): Observable<ClassStudentModel> {
    return this.http.get<ClassStudentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/student/${classStudentId}`);
  }

  createClassStudent(classId: number, modelData: CreateClassStudentModel): Observable<ClassStudentModel> {
    return this.http.post<ClassStudentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/student/add`, modelData);
  }

  updateClassStudent(classId: number, classStudentId: number, modelData: UpdateClassStudentModel): Observable<ClassStudentModel> {
    return this.http.put<ClassStudentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/student/${classStudentId}`, modelData);
  }

  deleteClassStudent(classId: number, classStudentId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/student/remove/${classStudentId}`);
  }

  getStudentGradesByCourseId(classId: number, courseId:number, filterData ):  Observable<StudentGradesGridModel> {
    return this.http.post<StudentGradesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/studentResult/grid/byCourseId/${courseId}?mode=ALL`, filterData);
  }

  getStudentGradesByStudentId(classId: number, classStudentId:number, filterData ):  Observable<StudentGradesGridModel> {
    return this.http.post<StudentGradesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/studentResult/grid/byClassStudentId/${classStudentId}?mode=ALL`, filterData);
  }

  updateStudentGrades(modelData):  Observable<StudentGradesGridModel> {
    return this.http.put<StudentGradesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/studentResult`, modelData);
  }

  getStudentGradeCertification(courseId: number, classStudentId: number): Observable<StudentGradeCertificateModel> {
    return this.http.get<StudentGradeCertificateModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/studentResult/certification?courseId=${courseId}&classStudentId=${classStudentId}`);
  }

  updateStudentGradeCertification(courseId: number, classStudentId: number, modelData): Observable<StudentGradeCertificateModel> {
    return this.http.put<StudentGradeCertificateModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/studentResult/certification?courseId=${courseId}&classStudentId=${classStudentId}`, modelData);
  }

  getStudentAttendanceReport(classStudentId: number): Observable<StudentAttendanceReportModel> {
    return this.http.get<StudentAttendanceReportModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/classStudent/${classStudentId}/attendanceReport`);
  }

  exportClassStudents(classId: number, filterData): Observable<ArrayBuffer> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/student/attendanceExport`, filterData, requestOptions);
  }

  getStudentProgressReport(classStudentId: number): Observable<StudentProgressReportModel> {
    return this.http.get<StudentProgressReportModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/classStudent/${classStudentId}/progressReport`);
  }
}
