import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  SharableDashboardGridModel, CreateSingleSharableModel, CopySharableModel,
  SharableResourcesGridModel, CreateSharableResourceModel, SharableNotesGridModel,
  CreateSharableNoteModel, SharableTrainingCertificationsGridModel, CreateSharableTrainingCertificationModel, CreateMultipleSharableSendEmailModel, UpdateSharableModel
} from '../models/sharables.model';

@Injectable({
  providedIn: 'root',
})
export class SharablesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getSharable(sharableId: number): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/${sharableId}`);
  }

  getOrganizationSharable(sharableId: number, organizationId: number): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/${sharableId}?organizationId=${organizationId}`);
  }

  createSharable(modelData: CreateSingleSharableModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable`, modelData);
  }


  updateSharable(sharableId: number, modelData: UpdateSharableModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/${sharableId}`, modelData);
  }

  deleteSharable(sharableId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/${sharableId}`);
  }

  copySharable(modelData: CopySharableModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/copy`, modelData);
  }

  createMultipleSharableSendEmail(modelData: CreateMultipleSharableSendEmailModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable`, modelData);
  }

  getSharableResourceGridByOrganizationPrimeNG(dataMode: string, organizationId: number, filterData): Observable<SharableResourcesGridModel> {
    return this.http.post<SharableResourcesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/organizationresourcegrid/${organizationId}?mode=${dataMode}`, filterData);
  }

  getSharableResourceGridByPersonPrimeNG(dataMode: string, personId: number, filterData): Observable<SharableResourcesGridModel> {
    return this.http.post<SharableResourcesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/personresourcegrid/${personId}?mode=${dataMode}`, filterData);
  }

  getUserWall(type: string, take: number, dashboard: boolean, maxDate?: string): Observable<SharableDashboardGridModel> {
    const url = (maxDate) ? `&maxdate=${maxDate}` : '';
    return this.http.get<SharableDashboardGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/userwall?type=${type}&take=${take}&dashboard=${dashboard}${url}`
    );
  }

  getNotesPrimeNG(dataMode: string, entity: string, entityId: number, filterData): Observable<SharableNotesGridModel> {
    return this.http.post<SharableNotesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/notegrid/${entity}/${entityId}?mode=${dataMode}`, filterData);
  }

  createSharableNote(modelData: CreateSharableNoteModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable`, modelData);
  }

  getDocumentsPrimeNG(dataMode: string, entity: string, entityId: number, filterData): Observable<SharableResourcesGridModel> {
    return this.http.post<SharableResourcesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/documentgrid/${entity}/${entityId}?mode=${dataMode}`, filterData);
  }

  createSharableDocument(modelData: CreateSharableResourceModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable`, modelData);
  }

  createUserSharableDocument(modelData: CreateSharableResourceModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/sharable`, modelData);
  }

  //---------------------------------------person sharables---------------------------//
  getPersonNotesKGrid(personId: number): Observable<SharableNotesGridModel> {
    return this.http.get<SharableNotesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/notegrid/person/${personId}`);
  }

  createPersonNote(modelData: CreateSharableNoteModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable`, modelData);
  }

  getPersonDocumentKGrid(personId: number): Observable<SharableResourcesGridModel> {
    return this.http.get<SharableResourcesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/documentgrid/person/${personId}`);
  }
  //---------------------------------------end person sharables---------------------------//

  //---------------------------------------contract sharables---------------------------//
  createContractDocument(modelData: CreateSharableResourceModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable`, modelData);
  }
  //---------------------------------------end contract sharables---------------------------//

  //---------------------------------------organization sharables---------------------------//
  createOrganizationNote(modelData: CreateSharableNoteModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable`, modelData);
  }
  //---------------------------------------end organization sharables---------------------------//

  //---------------------------------------training certification sharables---------------------------//
  getTrainingCertificationsPrimeNG(dataMode: string, personId: number, filterData): Observable<SharableTrainingCertificationsGridModel> {
    return this.http.post<SharableTrainingCertificationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/trainingcertgrid/${personId}?mode=${dataMode}`, filterData);
  }

  createTrainingCertification(modelData: CreateSharableTrainingCertificationModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable`, modelData);
  }
  //---------------------------------------end training certification sharables---------------------------//
}
