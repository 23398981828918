import {Component, Input, OnInit} from '@angular/core';

import {UserProfileCommunicationModel} from '../../../../../models/user-profile.model';
import {take} from 'rxjs/operators';
import {UserSurveysService} from '../../../../../services/user-surveys.service';
import {UserSurveyModel} from '../../../../../models/user-surveys.model';

@Component({
  selector: 'app-communications-user-profile-form',
  templateUrl: './communications-user-profile-form.component.html',
  styleUrls: ['./communications-user-profile-form.component.scss']
})
export class CommunicationsUserProfileFormComponent implements OnInit {
  loading: boolean = false;
  emailBody: string;
  textMessage: string;
  surveyComm: boolean = false;
  survey: UserSurveyModel;
  @Input() communication: UserProfileCommunicationModel;

  constructor(private userSurveysService: UserSurveysService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.loading = true;
    if (this.communication) {
      switch (true) {
        case this.communication.Channel.toLowerCase() === 'email':
          // force a tags to open new window
          if (this.communication.Body) {
            this.emailBody = this.communication.Body.replaceAll('<a ', '<a target="_blank"');
          }
          break;
        case this.communication.Channel.toLowerCase() === 'text message':
          this.textMessage = this.communication.Body;
          break;
      }
      if (this.communication.SurveyID) {
        // load the survey questions
        this.userSurveysService.getUserSurvey(this.communication.SendPublishID)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.survey = res;
              this.surveyComm = true;
            }
          });
      }
      this.loading = false;
    }
  }
}
