import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../../models/table-filter.model';

import {OrganizationPositionsService} from '../../../../../../services/organization-positions.service';
import {CreatePositionModel, OrganizationPositionModel} from '../../../../../../models/organization-positions.model';
import {transformDateTimeToDateOnly} from '../../../../../utilities/form.utility';
import {GenericSelectModel} from '../../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../../services/lookups-data.service';
import {RosterLiteModel} from '../../../../../../models/roster.model';
import {RosterService} from '../../../../../../services/roster.service';
import {StudentModel} from '../../../../../../models/students.model';
import {StudentsService} from '../../../../../../services/students.service';
import {listFilterUtility} from '../../../../../utilities/list-filter.utility';
import {RouteContextUtility} from '../../../../../utilities/route-context.utility';
import {TenantConfigDataService} from '../../../../../../services/tenant-config-data.service';
import {PageRowCountUtility} from '../../../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-organization-positions-form',
  templateUrl: './organization-positions-form.component.html',
  styleUrls: ['./organization-positions-form.component.scss']
})
export class PositionsFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  position = {} as OrganizationPositionModel;
  loading: boolean = false;
  loadingTable: boolean = false;
  createUpdatePosition = {} as CreatePositionModel;
  isSaving: boolean;
  endDateReq: boolean = false;
  inputObjPosition: GenericSelectModel;
  setPositionId: number;
  inputObjPositionType: GenericSelectModel;
  setPositionTypeId: number;
  cols: any[];
  rosterTotal: number = 0;
  rosterList: RosterLiteModel[];
  selectedRosterList: RosterLiteModel;
  studentRosterTotal: number = 0;
  studentRosterList: StudentModel[];
  selectedStudentRosterList: StudentModel;
  showTable: boolean = false;
  activeGlobalFilter: string;
  endDateRequired: boolean;
  routeContext: string;
  existingMessage: boolean = false;
  showToPresent: boolean = true;
  STRINGREPLACELOCAL: string;
  STRINGREPLACETYPE: string;
  getListRequest;
  getTotalRequest;
  getListStudentRequest;
  getTotalStudentRequest;
  @Input() isEdit: boolean;
  @Input() canEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() organizationID: number;
  @Input() organizationPositionID: number;
  @Input() showFinancialContact: boolean = false;
  @Input() organizationCategory: string;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();


  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private rosterService: RosterService, private studentsService: StudentsService,
              private messageService: MessageService, private lookupsDataService: LookupsDataService, private confirmationService: ConfirmationService,
              private organizationPositionsService: OrganizationPositionsService, private routeContextUtility: RouteContextUtility,
              private tenantConfigDataService: TenantConfigDataService, public pageRowCountUtility: PageRowCountUtility
  ) {
    this.mainForm = this.formBuilder.group({
      financialContact: new FormControl(false),
      position: new FormControl(null, Validators.required),
      positionType: new FormControl(null),
      startDate: new FormControl(null, Validators.required),
      toPresent: new FormControl(true),
      endDate: new FormControl({value: null, disabled: true}),
      personId: new FormControl(null, Validators.required)
    });

    // this.mainForm.get('endDate').valueChanges.subscribe({next:(value) => {
    //   if (value === '' || !value) {
    //     this.mainForm.get('toPresent').setValue(true);
    //   } else {
    //     this.mainForm.get('toPresent').setValue(false);
    //   }
    // }});
  }

  ngOnInit(): void {
    this.STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    this.STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');
    this.routeContext = this.routeContextUtility.getRouteContext().toUpperCase();
    this.initForm(this.isEdit);

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  ngOnChanges(): void {
    this.routeContext = this.routeContextUtility.getRouteContext();
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }

    if (!this.isEdit) {
      this.mainForm.get('toPresent').setValue(true);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  valueChanged(): void {
    if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
      this.existingMessage = true;
      this.openDialog();
    }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Position data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initForm(isEdit: boolean): any {
    return new Promise((resolve, reject) => {
      this.mainForm.markAsPristine();
      this.mainForm.markAsUntouched();
      this.loading = true;
      if (isEdit) {
        this.organizationPositionsService.getOrganizationPosition(this.organizationPositionID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.position = res;
              this.mainForm.get('financialContact').setValue(this.position.PrimaryFinancialContact);
              this.setPositionTypeId = this.position.PositionTypeID;
              this.mainForm.get('position').setValue(this.position.PositionID);
              this.mainForm.get('positionType').setValue(this.position.PositionTypeID);
              if (this.position.StartDate_D) {
                const sDate = new Date(this.position.StartDate_D);
                this.mainForm.get('startDate').setValue(sDate);
              } else {
                this.mainForm.get('startDate').setValue(null);
              }
              this.mainForm.get('toPresent').setValue(this.position.ToPresent);
              this.endDateReq = !this.position.ToPresent;
              if (this.position.EndDate_D) {
                const eDate = new Date(this.position.EndDate_D);
                this.mainForm.get('endDate').setValue(eDate);
                this.mainForm.get('endDate').setValidators(Validators.required);
                this.mainForm.get('endDate').enable();
              } else {
                this.mainForm.get('endDate').setValue(null);
                this.mainForm.get('endDate').clearValidators();
                // this.mainForm.get('endDate').disable();
              }
              this.mainForm.get('personId').setValue(this.position.PersonID);
              this.initPositionTypeSelect(!this.canTabWrite);
              this.loading = false;
              resolve(null);
            }
          });
      } else {
        this.initPositionSelect(false);
        this.initPositionTypeSelect(false);
        if (this.organizationCategory === 'education') {
          this.cols = [
            {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'OrganizationName', header: 'Organization', fullHeader: 'Organization', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'StatusAlias', header: 'Status', fullHeader: 'Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'TypeAlias', header: 'Type', fullHeader: 'Type', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true}
          ];
        } else {
          this.cols = [
            {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'OrganizationName', header: this.STRINGREPLACELOCAL, fullHeader: this.STRINGREPLACELOCAL, columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member Number', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'StatusAlias', header: 'Status', fullHeader: 'Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'TypeAlias', header: this.STRINGREPLACETYPE, fullHeader: this.STRINGREPLACETYPE, columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
            {field: 'Positions', header: 'Positions', fullHeader: 'Positions', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true}
          ];
        }
        this.loading = false;
        resolve(null);
      }
    });
  }

  initPositionSelect(disable: boolean): void {
    this.inputObjPosition = {
      labelText: 'Position',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setPositionId,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getPositionsByOrganizationIdLookup(this.organizationID, this.organizationCategory).then((lookupData) => {
      this.inputObjPosition.data = lookupData;
      this.inputObjPosition = Object.assign({}, this.inputObjPosition);
    });
  }

  getPositionData(event:any): void {
    if (event && event[0]) {
      this.setPositionId = event[0].ID;
    } else {
      this.setPositionId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('position').setValue(this.setPositionId);
  }

  initPositionTypeSelect(disable: boolean): void {
    this.inputObjPositionType = {
      labelText: 'Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setPositionTypeId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getPositionTypesLookupData().then((lookupData) => {
      this.inputObjPositionType.data = lookupData;
      this.inputObjPositionType = Object.assign({}, this.inputObjPositionType);
    });
  }

  getPositionTypeData(event:any): void {
    if (event && event[0]) {
      this.setPositionTypeId = event[0].ID;
      this.endDateRequired = event[0].EndDateRequired;
    } else {
      this.setPositionTypeId = null;
      this.endDateRequired = false;
    }
    if (this.endDateRequired) {
      this.mainForm.get('toPresent').setValue(false);
      this.mainForm.get('toPresent').disable();
      this.showToPresent = false;
      this.mainForm.get('endDate').enable();
      this.mainForm.get('endDate').setValidators(Validators.required);
      this.mainForm.get('endDate').updateValueAndValidity();
      this.mainForm.get('endDate').enable();
    } else {
      this.mainForm.get('toPresent').enable();
      this.showToPresent = true;
      this.mainForm.get('endDate').clearValidators();
      this.mainForm.get('endDate').updateValueAndValidity();
    }
    this.mainForm.get('toPresent').updateValueAndValidity();
    if (this.mainForm.get('positionType').value !== this.setPositionTypeId) {
      this.mainForm.markAsDirty();
      this.mainForm.get('positionType').setValue(this.setPositionTypeId);
      this.valueChanged();
    }
  }

  manageEndDate(): void {
    // this.mainForm.markAsDirty();
    // if (this.mainForm.get('toPresent').value === false) {
    //   this.endDateRequired = true;
    //   this.mainForm.get('endDate').enable();
    //   this.mainForm.get('endDate').setValidators(Validators.required);
    // } else {
    //   this.endDateRequired = false;
    //   this.mainForm.get('endDate').disable();
    //   this.mainForm.get('endDate').setValidators(null);
    // }
    // this.mainForm.get('endDate').updateValueAndValidity();
    // this.mainForm.get('endDate').setValue(null);
    // this.valueChanged();
  }

  toggleDate() {
    if (this.mainForm.get('toPresent').value === true) {
      this.mainForm.get('endDate').setValue(null);
      this.endDateRequired = false;
      this.mainForm.get('endDate').disable();
      this.mainForm.get('endDate').setValidators(null);
    } else {
      this.endDateRequired = true;
      this.mainForm.get('endDate').enable();
      this.mainForm.get('endDate').setValidators(Validators.required);
    }
    this.mainForm.get('endDate').updateValueAndValidity();
  }

  togglePresent() {
    if (this.mainForm.get('endDate').value !== null) {
      this.mainForm.get('toPresent').setValue(false);
      this.endDateRequired = true;
      this.mainForm.get('endDate').enable();
      this.mainForm.get('endDate').setValidators(Validators.required);
    } else {
      this.mainForm.get('toPresent').setValue(true);
      this.endDateRequired = false;
      this.mainForm.get('endDate').disable();
      this.mainForm.get('endDate').setValidators(null);
    }
    this.mainForm.get('endDate').updateValueAndValidity();
  }

  loadTable(event?): void {
    if (event) {
      this.currentFilter = event;
      this.currentFilter.filters.FilterName = [];
      this.currentFilter.filters.FilterName.push({value: this.activeGlobalFilter, matchMode: 'contains', operator: 'or'});
    } else {
      this.currentFilter.filters = {};
      this.currentFilter.sortOrder = 0;
    }
    if (this.routeContext === 'MEMBERSHIP') {
      this.getRosterData();
    } else {
      this.getStudentRosterData();
    }
  }

  getStudentRosterData(): void {
    this.getStudentRosterList();
    this.getStudentRosterTotal();
  }

  getStudentRosterList(): void {
    this.loadingTable = true;
    if (this.getListStudentRequest) {
      this.getListStudentRequest.unsubscribe();
    }
    this.getListStudentRequest = this.studentsService
      .getStudentsPrimeNG('DATA', this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.studentRosterList = res.Data;
          this.loadingTable = false;
        }, error:
          () => {
            this.loadingTable = false;
          }
      });
  }

  getStudentRosterTotal(): void {
    this.loadingTable = true;
    if (this.getTotalStudentRequest) {
      this.getTotalStudentRequest.unsubscribe();
    }
    this.getTotalStudentRequest = this.studentsService
      .getStudentsPrimeNG('TOTAL', this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.studentRosterTotal = res.Total;
          this.loadingTable = false;
        }, error:
          () => {
            this.loadingTable = false;
          }
      });
  }

  getRosterData(): void {
    this.getRosterList();
    this.getRosterTotal();
  }

  getRosterList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.loadingTable = true;
    this.getListRequest = this.rosterService
      .getRosterLitePrimeNG('DATA', this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.rosterList = res.Data;
          this.loadingTable = false;
        }, error:
          () => {
            this.loadingTable = false;
          }
      });
  }

  getRosterTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.loadingTable = true;
    this.getTotalRequest = this.rosterService
      .getRosterLitePrimeNG('TOTAL', this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.rosterTotal = res.Total;
          this.loadingTable = false;
        }, error:
          () => {
            this.loadingTable = false;
          }
      });
  }

  filterItems(): void {
    this.initFilters('FilterName', this.activeGlobalFilter, 'contains', 'and');
    if (this.routeContext === 'MEMBERSHIP') {
      this.getRosterData();
    } else {
      this.getStudentRosterData();
    }
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string): void {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      if (!this.isEdit) {
        this.createUpdatePosition.OrganizationID = this.organizationID;
        this.createUpdatePosition.PersonID = this.mainForm.get('personId').value;
        this.createUpdatePosition.PositionID = this.mainForm.get('position').value;
      } else {
        this.createUpdatePosition.OrganizationID = this.position.OrganizationID;
      }
      this.createUpdatePosition.PositionTypeID = this.mainForm.get('positionType').value;
      this.createUpdatePosition.PrimaryFinancialContact = this.mainForm.get('financialContact').value;
      this.createUpdatePosition.StartDate_D = transformDateTimeToDateOnly(this.mainForm.get('startDate').value);
      this.createUpdatePosition.ToPresent = this.mainForm.get('toPresent').value;
      this.createUpdatePosition.EndDate_D = transformDateTimeToDateOnly(this.mainForm.get('endDate').value);
      this.saveForm();
    } else {
      this.messageService.add({severity: 'warn', summary: 'Missing Data', detail: 'No data was saved, the form is currently incomplete.'});
      this.existingMessage = false;
    }
  }

  saveForm(): void {
    if (!this.isEdit) {
      this.organizationPositionsService.createOrganizationPosition(this.createUpdatePosition)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'The position has been added.'
            });
            this.isSaving = false;
            this.closeAddEdit.emit(true);
            // location.reload();
          }, error: () => {
            this.isSaving = false;
          }
        });
    } else {
      this.organizationPositionsService.updateOrganizationPosition(this.organizationPositionID, this.createUpdatePosition)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.confirmationService.close();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'The position has been updated.'
            });
            this.mainForm.markAsUntouched();
            this.mainForm.markAsPristine();
            this.isSaving = false;
            this.existingMessage = false;
            this.closeAddEdit.emit(true);
            // location.reload();
            //this.initForm(this.isEdit);
            //this.mainForm.markAsPristine();
          }, error: () => {
            this.isSaving = false;
          }
        });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm(this.isEdit);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  setNewPerson(event:any): void {
    if (event) {
      this.mainForm.get('personId').setValue(event.PersonID);
    }
  }
}
