<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                      [parentObjectEnum]="parentObjectEnum"></app-learn-more>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3>Filter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <label>Title</label>
        <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter"
               (keyup.enter)="filterItems()" tabindex="0"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <app-select class="full-width" [inputObj]="inputObjSelectCommunicationData"
                    (emitData)="setCommunicationData($event)"></app-select>
      </div>
      <!--      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">-->
      <!--        <p-checkbox binary="true" label="Survey"></p-checkbox>-->
      <!--      </div>-->
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <div class="list-filter-buttons text-align-right">
          <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clearFilters()">
          </button>
          <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  (click)="filterItems()">
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">@if (communications) {
          <span>{{ commTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (commTotal > currentFilter.rows) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="commTotal" pageLinkSize="4"
                       [first]="first" (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        @if ((currentFilter.filters | json) !== '{}') {
          <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                  pTooltip="Clear Filter" tooltipPosition="bottom"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                  (click)="clearFilters()">
          </button>
        }
        @if (recipients && _tabsPermissionsUtility.checkTabWrite(permissions, 'tabCommunications')) {
          <button pButton (click)="openAdhocComm(recipients)" icon="fas fa-comments"
                  pTooltip="Message" tooltipPosition="bottom"></button>
        }
        <button pButton (click)="refresh()" icon="pi pi-refresh"
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="commTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
        @if (commTotal > currentFilter.rows) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="commTotal" pageLinkSize="3"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
    </div>
    @if (totalLoaded && commTotal <= 0) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (communication of communicationList; track communication; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i)">
                  <div class="list-header">
                    @if (communication.Channel === 'EMAIL') {
                      <i class="fa fa-envelope" style="color: #FACB43;" pTooltip="E-mail" tooltipPosition="bottom"></i>
                    }
                    @if (communication.Channel === 'TEXT') {
                      <i class="fa fa-comment-alt-lines" style="color: #018AFC;" pTooltip="Text Message"
                         tooltipPosition="bottom"></i>
                    }
                    {{ communication.Title }}
                  </div>
                  <div class="list-subheader">
                    {{communication.DateCreated_D | shortlocalizedatetime}} | {{ communication.SenderDisplayName }}
                    @if (communication.CombinedDistribution) {
                      <span> | {{ communication.CombinedDistribution }}</span>
                    }
                    | <b>Total Sent:</b> {{ communication.TotalSent_N | number:'1.0':'en-US' }} | <b>Success
                    Rate:</b> {{ communication.SuccessRate_N | percent }}
                  </div>
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    <div class="icon" (click)="expandData(i)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                  </div>
                </div>
                @if (selectedItem === i) {
                  <div class="full-width margin-top-10">
                    <app-communications-form [communicationId]="communication.ID"></app-communications-form>
                  </div>
                }
              </div>
            </p-card>
          </div>
        }
      </div>
    }
    @if (dataLoaded && commTotal > currentFilter.rows) {
      <div class="p-grid p-fluid p-mb-5">
        <div class="col-md-12 col-sm-12 col-xs-12 align-center list-paginator">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="commTotal" pageLinkSize="4"
                       [first]="first" (onPageChange)="paginate($event)"></p-paginator>
        </div>
      </div>
    }
  </div>
</div>
