<p-card>
  <div class="p-grid p-fluid margin-top-10">
    <div class="col-md-2 col-sm-12 col-xs-12">
      <!--      <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc" [parentObjectEnum]="parentObjectEnum"></app-learn-more>-->
      <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
        <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <h3>Filter</h3>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
          <label>Description</label>
          <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter"
                 (keyup.enter)="filterItems()"/>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
          <div class="list-filter-buttons text-align-right">
            <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"
                    [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clearFilters()">
            </button>
            <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"
                    (click)="filterItems()">
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
      <div class="p-grid p-fluid">
        @if (!totalLoaded) {
          <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
        } @else {
          <h2 class="col-md-3 col-sm-4 col-xs-12">@if (activitiesList) {
            <span>{{ dataTotal | number:'1.0':'en-US' }}
              @if ((currentFilter.filters | json) !== '{}') {
                <span>Filtered </span>
              }Results</span>
          }</h2>
        }
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
          @if (activitiesList) {
            <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                         (onPageChange)="paginate($event)"></p-paginator>
          }
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
          <button pButton (click)="refresh()" icon="pi pi-refresh"
                  pTooltip="Refresh" tooltipPosition="bottom"></button>
          @if (!isExporting) {
            <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                    icon="fa fa-file-excel"
                    pTooltip="Export" tooltipPosition="bottom" (click)="export()">
            </button>
          } @else {
            <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                    icon="pi pi-spin pi-spinner save-spinner">
            </button>
          }
        </div>
        @if (activitiesList) {
          <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
            <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                         (onPageChange)="paginate($event)"></p-paginator>
          </div>
        }
      </div>
      @if (totalLoaded && dataTotal <= 0) {
        <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
          @if ((currentFilter.filters | json) === '{}') {
            <span><b>No results were found.</b></span>
          } @else {
            <span><i
              class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
          }
        </div>
      }
      @if (!dataLoaded) {
        <div class="col-md-12 col-sm-12 col-xs-12">
          <app-data-indicator></app-data-indicator>
        </div>
      }
      @if (dataLoaded && isEdit) {
        <div class="list-container">
          @for (activity of activitiesList; track activity; let i = $index) {
            <div class="col-md-12">
              <p-card [styleClass]="'list-card'">
                <div class="p-grid p-fluid">
                  <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i, activity.ActivityID)">
                    <div class="list-header">
                      {{ activity.ActivityDescription }} @if (activity.MergedFromIdentifier) {
                      <span> (Merged from ID# {{ activity.MergedFromIdentifier }})</span>
                    }
                    </div>
                    <div class="list-subheader">
                      <b>Activity Date:</b> {{ activity.ActivityDate | date:'shortDate' }} | <b>Date
                      Created:</b> {{ activity.CreatedDate | date:'short' }} | {{ activity.CreatedBy }}
                    </div>
                    <div class="list-subheader">
                      <div>{{ activity.Description }}</div>
                    </div>
                  </div>
                  <div class="right-column col-md-4 col-sm-4 col-xs-12">
                    <div class="list-action-buttons">
                      @if (activity.CanRecover && _tabsPermissionsUtility.checkTabRead(permissions, 'recover-activity')) {
                        <div class="icon" pTooltip="Recover Activity" tooltipPosition="bottom"
                             (click)="recoverActivity(activity.ActivityHistoryID)">
                          @if (!isRecovering) {
                            <i class="fas fa-history"></i>
                          }
                          @if (isRecovering) {
                            <i class="pi pi-spin pi-spinner save-spinner"></i>
                          }
                        </div>
                      }
                      <div class="icon hide-on-mobile" (click)="expandData(i, activity.ActivityID)">
                        @if (this.selectedItem === i) {
                          <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                        } @else {
                          <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                @if (selectedItem === i) {
                  <div class="full-width margin-top-10">
                    <app-roster-tab-activities-tabs-container [ContentID]="activity.ActivityHistoryID"
                                                              [canTabWrite]="canTabWrite" [isEdit]="true"
                                                              (closeAddEdit)="getData()"></app-roster-tab-activities-tabs-container>
                  </div>
                }
              </p-card>
            </div>
          }
        </div>
      }
    </div>
  </div>
</p-card>
