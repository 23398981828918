<div class="p-grid p-fluid">
  <div class="col-md-6 col-sm-6 col-xs-12 margin-top-15">
    Manage your position list here.
  </div>
  @if (canAdd) {
    <div class="col-md-6 col-sm-6 col-xs-12 margin-top-15 text-align-right">
      <button pButton label="Add New Position" (click)="addEditPosition(false)" class="p-button-info add-new-btn" pTooltip="Add" tooltipPosition="bottom"></button>
    </div>
  }
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
    <p-table
      #dt1
      [rows]="pageRowCountUtility.pageRowCount()"
      [columns]="cols"
      dataKey="Description"
      [value]="positions"
      [lazy]="true"
      (onLazyLoad)="loadTable($event)"
      [paginator]="true"
      [showCurrentPageReport]="true"
      [metaKeySelection]="false"
      [totalRecords]="dataTotal"
      [rowsPerPageOptions]="[25,50,100,250,500]"
      [scrollable]="true"
      [(selection)]="selPosition"
      selectionMode="single"
      scrollHeight="400px"
      paginatorDropdownAppendTo="body"
      [loading]="loading"
      (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect()"
      styleClass="p-datatable-gridlines p-datatable-striped"
      currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
      >
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
              <div class="header-text">
              {{col.header}}</div>
              <div class="header-actions">
                <p-sortIcon [field]="col.field"></p-sortIcon>
                @if (col.columnType !== 'picker') {
                  <p-columnFilter type="{{col.columnType}}" matchMode="{{col.matchMode}}" [field]="col.field" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                }
              </div>
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData" (dblclick)="goToEdit(rowData)" [ngClass]="{'selectedRow' : selPosition === rowData}">
          @for (col of columns; track col) {
            <td>
              @if (col.visible && col.displayType === 'avatar') {
                <div class="avatar">
                  <div class="circle" [ngClass]="rowData[col.field] === true  ? 'circle-green' : 'circle-red'"></div>
                </div>
              }
              @if (col.visible && col.displayType === 'text') {
                {{rowData[col.field]}}
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="cols?.length" style="width: 100%;">No positions found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
