<div class="p-fluid p-grid">
  <div class="col-md-12 col-sm-12 col-xs-12 p-pt-2">
    <h2>Hours Reported</h2>
  </div>
  <div class="col-md-3 col-sm-2 col-xs-12 p-pt-2">
    Total Apprentice Hours: {{apprenticeHoursSummary.TotalPersonHours}}
  </div>
  <div class="col-md-2 col-sm-2 col-xs-12 p-pt-2">
    Wage Level: {{apprenticeHoursSummary.WageLevel}}
  </div>
  <div class="col-md-2 col-sm-2 col-xs-12 p-pt-2">
    Student Level:  {{apprenticeHoursSummary.StudentLevel}}
  </div>
  <div class="col-md-2 col-sm-2 col-xs-12 p-pt-2">
    AEF Total: {{apprenticeHoursSummary.TotalAef | currency}}
  </div>
  <div class="col-md-3 col-sm-2 col-xs-12 text-align-right">
    <button pButton (click)="refresh()" icon="pi pi-refresh"
            pTooltip="Refresh" tooltipPosition="bottom" class="p-mr-1"></button>

      <button pButton (click)="goToEdit(null, false)" type="submit"
              icon="pi pi-plus" pTooltip="Add"
              tooltipPosition="bottom" class="p-mr-1"></button>
    @if (!isExporting) {
      <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
              icon="fa fa-file-excel"
              pTooltip="Export" tooltipPosition="bottom" (click)="export()" class="p-mr-1">
      </button>
    } @else {
      <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
              icon="pi pi-spin pi-spinner save-spinner" class="p-mr-1">
      </button>
    }
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
    <p-table
      #dt1
      [rows]="pageRowCountUtility.pageRowCount()"
      [columns]="cols"
      dataKey="ID"
      [value]="apprenticeHours"
      [lazy]="true"
      (onLazyLoad)="loadTable($event)"
      [paginator]="true"
      [showCurrentPageReport]="true"
      [metaKeySelection]="false"
      [totalRecords]="dataTotal"
      [rowsPerPageOptions]="[25,50,100,250,500]"
      [scrollable]="true"
      selectionMode="single"
      scrollHeight="400px"
      paginatorDropdownAppendTo="body"
      [loading]="!dataLoaded"
      styleClass="p-datatable-responsive-demo p-datatable-striped"
      currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
              <div class="header-text">
                {{ col.header }}
              </div>
              <div class="header-actions">
                <p-sortIcon [field]="col.field"></p-sortIcon>
                <p-columnFilter [field]="col.field" display="menu" [matchMode]="col.matchMode"
                                [showOperator]="true" [showAddButton]="false"
                                [type]="col.columnType"></p-columnFilter>
              </div>
            </th>

          }
<!--          <th>-->
<!--            Actions-->
<!--          </th>-->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
        <tr>
          @for (col of columns; track col) {
            <td>
              @if (col.visible) {
                @switch (col.displayType) {
                  @case ('text') {
                    {{ rowData[col.field] }}
                  }
                  @case ('dateMonth') {
                    {{ rowData[col.field] | date: 'MM/YYYY' }}
                  }
                  @case ('date') {
                    {{ rowData[col.field] | date: 'MM/dd/YYYY' }}
                  }
                  @case ('numeric') {
                    {{ rowData[col.field] | number:'1.0':'en-US' }}
                  }
                  @case ('currency') {
                    @switch (true) {
                      @case (rowData[col.field] < 0) {
                        <span
                          class="negative"> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                      }
                      @case (rowData[col.field] === 0) {
                        <span> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                      }
                      @case (rowData[col.field] > 0) {
                        <span
                          class="positive">{{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                      }
                    }
                  }
                }
              }
            </td>

          }
<!--          <td>-->
<!--            @if(rowData.CanEdit) {-->
<!--              <div class="icon edit-buttons" pTooltip="Edit" tooltipPosition="bottom"-->
<!--                   (click)="goToEdit(rowData, true)"><i class="fas fa-file-edit p-mr-1"></i></div>-->
<!--            }-->
<!--            @if(rowData.CanDelete) {-->
<!--            <div class="icon edit-buttons" pTooltip="Delete" tooltipPosition="bottom"-->
<!--                 (click)="confirmDelete(rowData)"><i class="fas fa-trash-alt"></i></div>-->
<!--            }-->
<!--          </td>-->
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="cols?.length + 1" style="width: 100%;">No data found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
