import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ContextService} from '../../../services/context.service';
import {TelerikReportingService} from '../../../services/telerik-reporting.service';
import {take} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-telerik-report-viewer',
  templateUrl: './telerik-report-viewer.component.html'
})
export class TelerikeReportViewerComponent implements OnInit, OnChanges {
  viewerContainerStyle: any;
  serviceUrl: string = `${this.contextService.contextObject.apiBaseUrlV1}/reportingapi/reports/`;
  templateUrl: string = '../assets/telerik-report-viewer/telerikReportViewerTemplate.html';
  reportSource: any;
  viewMode: string = 'PRINT_PREVIEW';
  scaleMode: string = 'SPECIFIC'; // Option - FIT_PAGE, FIT_PAGE_WIDTH, SPECIFIC;
  scale: number = 1;
  enableAccessibility: boolean = false;
  runClicked: boolean = false;
  isValid: boolean;
  showReport: boolean = false;
  isProcessing: boolean = false;
  showNoResults: boolean = false;
  @Input() isDownload: boolean = false;
  @Input() reportUrl: string;
  @Input() inputData: any;
  @Input() autoRun: boolean = false;
  @Input() forceRefresh: string;

  constructor(public contextService: ContextService, private reportingService: TelerikReportingService,
              private messageService: MessageService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.viewerContainerStyle = {
      position: 'relative',
      width: '100%',
      height: '750px',
      left: '5px',
      right: '5px',
      ['font-family']: 'ms sans serif'
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputData) {
      if (this.inputData) {
        this.showReport = false;
        this.isValid = (this.inputData.isValid) ? this.inputData.isValid : false;
        this.reportSource = {
          report: `Application.Reporting.TelerikReports.${this.inputData.reportName}, Application`,
          parameters: this.inputData.parameters
        };
        this.changeDetectorRef.detectChanges();
        if (this.isDownload) {
          this.showReport = false;
        } else if (this.autoRun) {
          this.showReport = true;
        }
      }
    }
  }

  runReport(): void {
    this.showReport = true;
  }

  downloadReport() {
    this.showNoResults = false;
    this.isProcessing = true;
    this.inputData.parameters.report_name = this.inputData.reportName;
    this.reportingService.getReport(this.reportUrl, this.inputData.parameters)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res) {  // if results is not empty
            this.isProcessing = false;
            this.downloadFile(res, 'report.pdf', 'text/plain;charset=utf-8');
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Your PDF has been processed and is now downloading.'
            });
          } else {
            this.showNoResults = true;
            this.isProcessing = false;
          }
        }, error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was a problem processing your report'
          });
        }
      });
  }

  downloadFile(buffer, fileName, fileType) {
    import('file-saver').then(() => {
      const data: Blob = new Blob([buffer], {
        type: fileType
      });
      FileSaver.saveAs(data, fileName);
    });
  }

  ready(): void {
    //console.log('ready');
  }

  viewerToolTipOpening(e: any, args: any): void {
    //console.log('viewerToolTipOpening ' + args.toolTip.text);
    console.debug(e, args);
  }
}
