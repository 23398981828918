import { CustomAttributesModel } from './attributes.model';
import {GenericOptionsModel, GenericSelectModel} from './generic-select.model';

export interface ProspectProfileModel {
  ProspectProfileID: number;
  TypeID: number;
  TypeAlias: string;
  StatusID: number;
  StatusAlias: string;
  OrganizationID: number;
  OrganizationName: string;
  ProspectNumber: number;
  PersonID: number;
  UserID: string;
  UserName: string;
  UserEmail: string;
  PrefixID: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Suffix: string;
  Gender: string;
  DOB: string;
  SSN: string;
  SIN: string;
  RaceID: number;
  RaceIDList: number[];
  EthnicityID: number;
  LanguageID: number;
  LanguageIDList: number[];
  EducationID: number;
  MilitaryID: number;
  MilitaryStatusID: number;
  CitizenshipCountryID: number;
  ShirtSizeID: number;
  MarriageStatusID: number;
  RegisteredVoter: boolean;
  SchoolAttended: string;
  PrimaryAddress1: string;
  PrimaryAddress2: string;
  PrimaryAddress3: string;
  PrimaryAddress4: string;
  PrimaryAddress5: string;
  PrimaryAddress6: string;
  PrimaryCity: string;
  PrimaryStateProvinceID: number;
  PrimaryZip: string;
  PrimaryZipPlus4: string;
  PrimaryCountryID: number;
  PrimaryCounty: string;
  PrimaryLatitude: number;
  PrimaryLongitude: number;
  PrimaryVerified: boolean;
  PrimaryFailedCommunication: boolean;
  PrimaryPhonePhoneTypeID: number;
  PrimaryPhonePhoneTypeEnum: string;
  PrimaryPhoneNumber: string;
  PrimaryPhoneExtension: string;
  PrimaryPhoneVerified: boolean;
  PrimaryPhoneFailedCommunication: boolean;
  SecondaryPhonePhoneTypeID: number;
  SecondaryPhonePhoneTypeEnum: string;
  SecondaryPhoneNumber: string;
  SecondaryPhoneExtension: string;
  SecondaryPhoneVerified: boolean;
  SecondaryPhoneFailedCommunication: boolean;
  ThirdPhonePhoneTypeID: number;
  ThirdPhonePhoneTypeEnum: string;
  ThirdPhoneNumber: string;
  ThirdPhoneExtension: string;
  ThirdPhoneVerified: boolean;
  ThirdPhoneFailedCommunication: boolean;
  PrimaryEmailAddress: string;
  PrimaryEmailVerified: boolean;
  PrimaryEmailFailedCommunication: boolean;
  PrimaryEmailOptOut: boolean;
  SecondaryEmailAddress: string;
  SecondaryEmailVerified: boolean;
  SecondaryEmailFailedCommunication: boolean;
  SecondaryEmailOptOut: boolean;
  HasAccount: string;
  ThumbnailUrl: string;
  HideContactInfo: boolean;
  SMSOptIn: boolean;
  CustomAttributes: CustomAttributesModel[];
  LastLoginDate_D: string;
  Nickname: string;
  FullName?: string;
  ExperienceID: number;
  Trades: GenericOptionsModel[];
  WebReferral: string;
  OrganizeMyWorkspace: boolean;
}

export interface UpdateProspectProfileModel {
  ProspectProfileID: number;
  TypeID: number;
  TypeAlias: string;
  StatusID: number;
  StatusAlias: string;
  OrganizationID: number;
  OrganizationName: string;
  ProspectNumber: number;
  PersonID: number;
  UserID: string;
  UserName: string;
  UserEmail: string;
  PrefixID: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Suffix: string;
  Gender: string;
  DOB: string;
  SSN: string;
  SIN: string;
  RaceID: number;
  EthnicityID: number;
  LanguageID: number;
  EducationID: number;
  MilitaryID: number;
  PrimaryAddress1: string;
  PrimaryAddress2: string;
  PrimaryAddress3: string;
  PrimaryAddress4: string;
  PrimaryAddress5: string;
  PrimaryAddress6: string;
  PrimaryCity: string;
  PrimaryStateProvinceID: number;
  PrimaryZip: string;
  PrimaryZipPlus4: string;
  PrimaryCountryID: number;
  PrimaryVerified: boolean;
  PrimaryFailedCommunication: boolean;
  PrimaryPhonePhoneTypeID: number;
  PrimaryPhonePhoneTypeEnum: string;
  PrimaryPhoneNumber: string;
  PrimaryPhoneExtension: string;
  PrimaryPhoneVerified: boolean;
  PrimaryPhoneFailedCommunication: boolean;
  SecondaryPhonePhoneTypeID: number;
  SecondaryPhonePhoneTypeEnum: string;
  SecondaryPhoneNumber: string;
  SecondaryPhoneExtension: string;
  SecondaryPhoneVerified: boolean;
  SecondaryPhoneFailedCommunication: boolean;
  PrimaryEmailAddress: string;
  PrimaryEmailVerified: boolean;
  PrimaryEmailFailedCommunication: boolean;
  PrimaryEmailOptOut: boolean;
  SecondaryEmailAddress: string;
  SecondaryEmailVerified: boolean;
  SecondaryEmailFailedCommunication: boolean;
  SecondaryEmailOptOut: boolean;
  HasAccount: string;
  ThumbnailUrl: string;
  HideContactInfo: boolean;
  SMSOptIn: boolean;
  CustomAttributes: CustomAttributesModel[];
  LastLoginDate_D: string;
  ExperienceID: number;
  Trades: GenericOptionsModel[];
  WebReferral: string;
  OrganizeMyWorkspace: boolean;
}

export interface CreateProspectProfileModel {
  TypeID: number;
  StatusID: number;
  OrganizationID: number;
  ProspectNumber: string;
  PrefixID: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Suffix: string;
  Gender: string;
  DOB: string;
  SSN: string;
  SIN: string;
  RaceID: number;
  EthnicityID: number;
  LanguageID: number;
  EducationID: number;
  MilitaryID: number;
  PrimaryAddress1: string;
  PrimaryAddress2: string;
  PrimaryAddress3: string;
  PrimaryAddress4: string;
  PrimaryAddress5: string;
  PrimaryAddress6: string;
  PrimaryCity: string;
  PrimaryStateProvinceID: number;
  PrimaryZip: string;
  PrimaryZipPlus4: string;
  PrimaryCountryID: number;
  PrimaryVerified: boolean;
  PrimaryFailedCommunication: boolean;
  PrimaryPhonePhoneTypeID: number;
  PrimaryPhonePhoneTypeEnum: string;
  PrimaryPhoneNumber: string;
  PrimaryPhoneExtension: string;
  PrimaryPhoneVerified: boolean;
  PrimaryPhoneFailedCommunication: boolean;
  SecondaryPhonePhoneTypeID: number;
  SecondaryPhonePhoneTypeEnum: string;
  SecondaryPhoneNumber: string;
  SecondaryPhoneExtension: string;
  SecondaryPhoneVerified: boolean;
  SecondaryPhoneFailedCommunication: boolean;
  PrimaryEmailAddress: string;
  PrimaryEmailVerified: boolean;
  PrimaryEmailFailedCommunication: boolean;
  PrimaryEmailOptOut: boolean;
  SecondaryEmailAddress: string;
  SecondaryEmailVerified: boolean;
  SecondaryEmailFailedCommunication: boolean;
  SecondaryEmailOptOut: boolean;
  HasAccount: string;
  HideContactInfo: boolean;
  SMSOptIn: boolean;
  CustomAttributes: CustomAttributesModel[];
}

export interface CreateProspectProfileExistingMemberModel {
  TypeID: number;
  TypeAlias: string;
  StatusID: number;
  StatusAlias: string;
  OrganizationID: number;
  OrganizationName: string;
  PathwayID: number;
  MemberNumber: string;
  MemberNumberLabel: string;
  EffectiveDate: string;
  MembershipProfileID: number;
  ProspectProfileID: number;
  ProspectNumber: string;
  PersonID: number;
}

export interface ConvertProspectApplicantModel {
  PersonProfileID: number;
  StatusID: number;
  TypeID?: number;
  OrganizationID: number;
  PathwayID: number;
  Identifier: number;
  EffectiveDate: string;
  CustomAttributes: CustomAttributesModel[];
}
