import {Component, OnDestroy, OnInit} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ContextService } from '../../../../services/context.service';
import { LookupModel } from '../../../../models/lookups.model';
import { LookupsDataService} from '../../../../services/lookups-data.service';
import { LookupsService } from '../../../../services/lookups.service';
import { GenericSelectModel } from '../../../../models/generic-select.model';
import { MessageService } from 'primeng/api';
import {EmployerOrganizationsGridModel, OrganizationModel} from '../../../../models/organizations.model';

@Component({
  selector: 'app-project-report-add-contractor-dialog',
  templateUrl: './project-report-add-new-contractor-dialog.component.html',
  styleUrls: ['./project-report-add-new-contractor-dialog.component.scss']
})

export class ProjectReportAddNewContractorDialogComponent implements OnInit, OnDestroy {
  employer: OrganizationModel;
  constructor(private ref: DynamicDialogRef, public config:DynamicDialogConfig) {
  }

  ngOnInit(): void {
    console.log(this.config.data);
  }

  ngOnDestroy(): void {
  }

  processData(event:any) {
  //  this.employer = event.employer;
    this.ref.close(event);
  }
}
