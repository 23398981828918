import {Injectable} from '@angular/core';
import {TenantConfigService} from './tenant-config.service';
import {take, takeUntil} from 'rxjs/operators';
import {ContextService} from './context.service';
import {ContextModel, tenantConfigsModel} from '../models/context.model';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantConfigDataService {
  private ngUnsubscribe = new Subject();

  constructor(private tenantConfigService: TenantConfigService, private contextService: ContextService) {
  }

  getBooleanValue(enumerator: string): boolean {
    let rtn: boolean = null;
    if (enumerator) {
      if (this.contextService.contextObject && this.contextService.contextObject.tenantConfigs) {
        if (this.contextService.contextObject.tenantConfigs.filter(x => x.Enumerator === enumerator).length > 0) {
          rtn = this.contextService.contextObject.tenantConfigs.filter(x => x.Enumerator === enumerator)[0].BooleanValue;
          return rtn;
        } else {
          return this.getBooleanValueDefaults(enumerator);
        }
      } else {
        this.tenantConfigService.getTenantConfigAll()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res: tenantConfigsModel[]) => {
              const context1: ContextModel = this.contextService.contextObject;
              context1.tenantConfigs = res;
              this.contextService.contextObject = context1;
              if (res.filter(x => x.Enumerator === enumerator).length > 0) {
                rtn = res.filter(x => x.Enumerator === enumerator)[0].BooleanValue;
                return rtn;
              } else {
                return this.getBooleanValueDefaults(enumerator);
              }
            }
          });
        return rtn;
      }
    } else {
      return rtn;
    }
  }

  getBooleanValueDefaults(enumerator: string): boolean {
    let rtn: boolean;
    // set default return values
    switch (enumerator) {
      case 'ADDTOROSTER':
        rtn = false;
        break;
      case 'ADDTOSTUDENTROSTER':
        rtn = false;
        break;
      case 'ALLOWUNLAYER':
        rtn = false;
        break;
      case 'APPLYSMSSEGMENTCALC':
        rtn = false;
        break;
      case 'DIRECTPAYMENTBILLADJUSTTYPE':
        rtn = false;
        break;
      case 'ENABLEMEMBERNUMBER':
        rtn = false;
        break;
      case 'JOTFORMSURVEY':
        rtn = false;
        break;
      case 'LOCKORGNAME':
        rtn = false;
        break;
      case 'MAILINGLABELSHOWORG':
        rtn = false;
        break;
      case 'MAILINGLABELUSELOCALADDRESSFOROFFICERS':
        rtn = false;
        break;
      case 'PCTMEMBERRECORDS':
        rtn = false;
        break;
      case 'PCTPRESEED':
        rtn = false;
        break;
      case 'REQUIRESHARABLECERTID':
        rtn = false;
        break;
      case 'SHOWSMSSEGMENTCALC':
        rtn = false;
        break;
      case 'STATEMENTBILLING':
        rtn = false;
        break;
      case 'TRAININGSTIPENDS':
        rtn = false;
        break;
      case 'AUTOMERGEEMPLOYMENT':
        rtn = false;
        break;
      case 'UNGROUPEDTRANSACTIONHISTORYGRID':
        rtn = false;
        break;
      default:
        rtn = false;
        break;
    }
    return rtn;
  }

  getStringValue(enumerator: string): string {
    let rtn: string = '';
    if (enumerator) {
      if (this.contextService.contextObject && this.contextService.contextObject.tenantConfigs) {
        if (this.contextService.contextObject.tenantConfigs.filter(x => x.Enumerator === enumerator).length > 0) {
          rtn = this.contextService.contextObject.tenantConfigs.filter(x => x.Enumerator === enumerator)[0].StringValue;
          return rtn;
        } else {
          return this.getStringValueDefaults(enumerator);
        }
      } else {
        this.tenantConfigService.getTenantConfigAll()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res: tenantConfigsModel[]) => {
              const context1: ContextModel = this.contextService.contextObject;
              context1.tenantConfigs = res;
              this.contextService.contextObject = context1;
              if (res.filter(x => x.Enumerator === enumerator).length > 0) {
                rtn = res.filter(x => x.Enumerator === enumerator)[0].StringValue;
                return rtn;
              } else {
                return this.getStringValueDefaults(enumerator);
              }
            }
          });
      }
    } else {
      return rtn;
    }
  }

  getStringValueDefaults(enumerator: string): string {
    let rtn: string;
    // set default return values
    switch (enumerator) {
      case 'DEFAULTPAGEROWCOUNT':
        rtn = '25';
        break;
      case 'MAXSMSMESSAGECHARS':
        rtn = '160';
        break;
      case 'ORGDROPDOWNDISPLAY':
        rtn = 'Description';
        break;
      case 'ROSTERQUERYDEFAULT':
        rtn = 'List';
        break;
      case 'STRINGREPLACEEMPLOYERIDENTIFIER':
        rtn = 'Employer Identifier';
        break;
      case 'STRINGREPLACECLASSIFICATION':
        rtn = 'Classification';
        break;
      case 'STRINGREPLACEFILEDDATE':
        rtn = 'File Date';
        break;
      case 'STRINGREPLACEGROUP':
        rtn = 'Group';
        break;
      case 'STRINGREPLACELOCAL':
        rtn = 'Organization';
        break;
      case 'STRINGREPLACEOCCUPATION':
        rtn = 'Occupation';
        break;
      case 'STRINGREPLACEUNIONIDENTIFIER':
        rtn = 'Union Identifier';
        break;
      case 'STRINGREPLACETYPE':
        rtn = 'Type';
        break;
      case 'STRINGREPLACEUNION':
        rtn = 'Union';
        break;
      default:
        rtn = '';
        break;
    }
    return rtn;
  }

  getALLOWREADONLYDATAEXPORTData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('ALLOWREADONLYDATAEXPORT')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getALLOWUNLAYERData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('ALLOWUNLAYER')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getAUXFILTERTRAINCERTData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('AUXFILTERTRAINCERT')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getAUXFILTERVOTERData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('AUXFILTERVOTER')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getCASEGRIDCUSTOMSTRING1Data(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('CASEGRIDCUSTOMSTRING1')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getCASEGRIDCUSTOMSTRING2Data(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('CASEGRIDCUSTOMSTRING2')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getCASEGRIDCUSTOMSTRING3Data(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('CASEGRIDCUSTOMSTRING3')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getCASEGRIDCUSTOMSTRING4Data(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('CASEGRIDCUSTOMSTRING4')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getCASEGRIDCUSTOMSTRING5Data(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('CASEGRIDCUSTOMSTRING5')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getJOTFORMSURVEYData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('JOTFORMSURVEY')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getMAILINGLABELSHOWMEMNUMData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('MAILINGLABELSHOWMEMNUM')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getMAILINGLABELSHOWORGData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('MAILINGLABELSHOWORG')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getMEMBERSHIPCARDREPORTNAMEData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('MEMBERSHIPCARDREPORTNAME')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getPCTBILLINGCAPSData(organizationId?: number): any {
    return new Promise((resolve, reject) => {
      if (organizationId) {
        this.tenantConfigService.getTenantConfigBooleanByOrganization('PCTBILLINGCAPS', organizationId)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              resolve(res);
            }, error:
              () => {
                reject(false);
              }
          });
      } else {
        this.tenantConfigService.getTenantConfigBoolean('PCTBILLINGCAPS')
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              resolve(res);
            }, error:
              () => {
                reject(false);
              }
          });
      }
    });
  }

  getPICKMEMBERSHIPFROMSTUDENTData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('PICKMEMBERSHIPFROMSTUDENT')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getPICKSTUDENTFROMMEMBERSHIPData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('PICKSTUDENTFROMMEMBERSHIP')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getSHOWCONTACTPOSITIONDETAILData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('SHOWCONTACTPOSITIONDETAIL')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getSHOWSTUDENTMEMBERSHIPData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('SHOWSTUDENTMEMBERSHIP')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getSHOWRETIREEADJUSTMENTSData(organizationId: number): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBooleanByOrganizationGrouped('SHOWRETIREEADJUSTMENTS', organizationId)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getSTRINGREPLACECLASSIFICATIONData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('STRINGREPLACECLASSIFICATION')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getSTRINGREPLACEEMPLOYERIDENTIFIERData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('STRINGREPLACEEMPLOYERIDENTIFIER')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getSTRINGREPLACEFILEDDATEData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('STRINGREPLACEFILEDDATE')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getSTRINGREPLACELOCALData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('STRINGREPLACELOCAL')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getSTRINGREPLACEOCCUPATIONData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('STRINGREPLACEOCCUPATION')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getSTRINGREPLACETYPEData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('STRINGREPLACETYPE')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getSTRINGREPLACEUNIONData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('STRINGREPLACEUNION')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getSTRINGREPLACEUNIONIDENTIFIERData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigValue('STRINGREPLACEUNIONIDENTIFIER')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject('');
            }
        });
    });
  }

  getSUGGESTADDRESSData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('SUGGESTADDRESS')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getTENANTISUNIONData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('TENANTISUNION')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }

  getUNGROUPEDTRANSACTIONHISTORYGRIDData(): any {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigBoolean('UNGROUPEDTRANSACTIONHISTORYGRID')
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error:
            () => {
              reject(false);
            }
        });
    });
  }
}
