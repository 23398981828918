@if (projectReportData.OrganizeProjectID) {
  <div class="col-md-12 col-xs-12 col-sm-12 margin-bottom-5 mobile-center">
    <button pButton (click)="addExistingContractor()" icon="pi pi-plus" pTooltip="Add" tooltipPosition="bottom"
    class="inline-button p-mr-1" label="Add Existing Employer"></button>
<!--    <button pButton (click)="createContractor()" icon="pi pi-plus" pTooltip="Create" tooltipPosition="bottom"-->
<!--    class="inline-button" label="Create New Employer"></button>-->
  </div>
}
@if (projectContractors) {
  <div class="col-md-12 col-sm-12 col-xs-12">
    @for (contractor of projectContractors; track contractor; let i = $index) {
      <div class="p-fluid p-grid contractor-container">
        <div class="col-md-3 col-sm-3 col-xs-12 input-container margin-top-10" >
          <label>Employer</label>
          <input class="full-width" type="text" pInputText  [value]="contractor.OrganizationName" [disabled]="true" tabindex="0" >
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12 input-container margin-top-10">
          <label>Types</label>
          <p-multiSelect [options]="organizationTypes" [(ngModel)]="contractor.selectedTypes" optionLabel="Description" optionValue="ID" appendTo="body" [ngModelOptions]="{standalone: true}"
          ></p-multiSelect>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12 input-container margin-top-10">
          <label>Trades</label>
          <p-multiSelect [options]="trades" [(ngModel)]="contractor.selectedTrades" optionLabel="Description" optionValue="ID" appendTo="body" [ngModelOptions]="{standalone: true}"
                         ></p-multiSelect>
        </div>
<!--        <div class="col-md-1 col-sm-3 col-xs-12 input-container margin-top-5">-->
<!--          <label>Is Union?</label>-->
<!--          <app-yes-no-select [isBoolean]="true" [selectedValue]="contractor.UnionOrganization" (emitData)="contractor.UnionOrganization = $event"></app-yes-no-select>-->
<!--        </div>-->
        <div class="col-md-1 col-sm-3 col-xs-12 mobile-center margin-top-5">
          <button pButton (click)="promptToDelete(contractor.OrganizeProjectOrganizationID)" icon="pi pi-trash" pTooltip="Remove {{contractor.OrganizationName}}" tooltipPosition="bottom"
          class="margin-top-20 margin-right-10 p-button-icon-only"></button>
        </div>
      </div>
    }
    @if (projectContractors && projectContractors.length > 0) {
      <div class="col-md-12 col-sm-12 col-xs-12 text-align-right">
        <button pButton (click)="saveContractors()" icon="pi pi-save" pTooltip="Update" tooltipPosition="bottom" label="Save Employers"
        class="p-button-info p-mr-2" [className]="'inline-button'"></button>
      </div>
    }
  </div>
}
