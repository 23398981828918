import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormArray, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {WorkflowStepOutcomeGridModel, WorkflowStepOutcomeModel, WorkflowStepOutcomeNextStepModel, WorkflowStepOutcomesGridModel} from '../../../../../models/workflow-step-outcomes.model';
import {WorkflowStepOutcomesService} from '../../../../../services/workflow-step-outcomes.service';
import {DialogUtility} from '../../../../utilities/dialog.utility';
import {ContextService} from '../../../../../services/context.service';

@Component({
  selector: 'app-workflow-step-outcomes-form',
  templateUrl: './workflow-step-outcomes-form.component.html',
  styleUrls: ['./workflow-step-outcomes-form.component.scss']
})
export class WorkflowStepOutcomesFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  workflowStepOutcome = {} as WorkflowStepOutcomeModel;
  workflowStepOutcomes: WorkflowStepOutcomesGridModel;
  workflowStepOutcomesList: WorkflowStepOutcomeGridModel[];
  loading: boolean = false;
  saveWorkflowStepOutcome = {} as WorkflowStepOutcomeModel;
  inputObjStep: GenericSelectModel;
  setStepId: number;
  isSaving: boolean;
  selectedType: string;
  existingMessage: boolean = false;
  showNextSteps: boolean = false;
  inputObjDestinationWorkflowStep: GenericSelectModel[] = [];
  setDestinationWorkflowStepId: number[] = [];
  inputObjDeadlineType: GenericSelectModel[] = [];
  setDeadlineTypeId: number[] = [];
  inputObjIntervalType: GenericSelectModel[] = [];
  setIntervalTypeId: number[] = [];
  outcomeCanDelete: boolean[] = [];
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() workflowID: number;
  @Input() WorkflowStepOutcomeId: number;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService, private contextService: ContextService,
              private workflowStepOutcomesService: WorkflowStepOutcomesService,
              private lookupsDataService: LookupsDataService,
              private confirmationService: ConfirmationService, private dialogUtility: DialogUtility
  ) {
    this.mainForm = this.formBuilder.group({
      step: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      keyOutcome: new FormControl(false),
      closeBranch: new FormControl(false),
      nextSteps: this.formBuilder.array([])
    });
  }

  get nextSteps(): UntypedFormArray {
    return this.mainForm.get('nextSteps') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    this.initForm(this.isEdit);
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Workflow step outcome data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  createNextSteps(): FormGroup {
    return this.formBuilder.group({
      outcomeNextStepID: 0,
      outcomeDestinationWorkflowStepID: [null, Validators.required],
      outcomeDeadlineTypeID: [null, Validators.required],
      outcomeIntervalTypeID: null,
      outcomeDueInterval: 0,
      outcomeCanDelete: true
    });
  }

  addNextStep() {
    this.nextSteps.push(this.createNextSteps());
    const cnt = this.nextSteps.controls.length - 1;
    this.setDestinationWorkflowStepId[cnt] = null;
    this.initDestinationWorkflowStep(cnt, !this.canTabWrite);
    this.setDeadlineTypeId[cnt] = null;
    this.initDeadlineType(cnt, !this.canTabWrite);
    this.setIntervalTypeId[cnt] = null;
    this.initIntervalType(cnt, !this.canTabWrite);
    this.outcomeCanDelete[cnt] = true;
    this.mainForm.markAsDirty();
    this.mainForm.updateValueAndValidity();
  }

  removeNextStep(i: number) {
    if (i >= 0) {
      this.dialogUtility.promptToDelete(() => {
        this.nextSteps.removeAt(i);
        this.setDestinationWorkflowStepId = [];
        this.setDeadlineTypeId = [];
        this.setIntervalTypeId = [];
        this.outcomeCanDelete = [];
        let cnt: number = 0;
        this.nextSteps.controls.forEach(x => {
          this.setDestinationWorkflowStepId[cnt] = Number(x.get('outcomeDestinationWorkflowStepID').value);
          this.initDestinationWorkflowStep(cnt, !this.canTabWrite);
          this.setDeadlineTypeId[cnt] = Number(x.get('outcomeDeadlineTypeID').value);
          this.initDeadlineType(cnt, !this.canTabWrite);
          this.setIntervalTypeId[cnt] = Number(x.get('outcomeIntervalTypeID').value);
          this.initIntervalType(cnt, !this.canTabWrite);
          this.outcomeCanDelete[cnt] = x.get('outcomeCanDelete').value;
          cnt++;
        });
        this.mainForm.markAsDirty();
        this.mainForm.updateValueAndValidity();
        if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
          this.existingMessage = true;
          this.openDialog();
        }
      }, () => {
      });
    }
  }

  initForm(isEdit: boolean): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.loading = true;
    if (isEdit) {
      this.selectedType = null;
      this.mainForm.controls.nextSteps = new UntypedFormArray([]);
      this.workflowStepOutcomesService.getWorkflowStepOutcome(this.workflowID, this.WorkflowStepOutcomeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.workflowStepOutcome = res;
            this.setStepId = this.workflowStepOutcome.WorkflowStepID;
            this.mainForm.get('step').setValue(this.workflowStepOutcome.WorkflowStepID);
            this.mainForm.get('description').setValue(this.workflowStepOutcome.Description);
            this.mainForm.get('keyOutcome').setValue(this.workflowStepOutcome.KeyOutcome);
            this.mainForm.get('closeBranch').setValue(this.workflowStepOutcome.CloseBranch);
            this.showNextSteps = !this.workflowStepOutcome.CloseBranch;
            this.initNextSteps(this.workflowStepOutcome.NextSteps);
            // if (this.workflowStepOutcome.NextSteps && this.workflowStepOutcome.NextSteps.length > 0) {
            //   let cnt: number = 0;
            //   this.workflowStepOutcome.NextSteps?.forEach(x => {
            //     this.addNextStep(cnt, x);
            //     cnt++;
            //   });
            //   this.mainForm.markAsPristine();
            //   this.mainForm.markAsUntouched();
            // }
            this.initStep(this.isEdit);
            this.loading = false;
          }
        });
    } else {
      this.initStep(this.isEdit);
      this.showNextSteps = true;
      this.loading = false;
    }
  }

  initNextSteps(data: WorkflowStepOutcomeNextStepModel[]): void {
    let cnt: number = 0;
    this.nextSteps.clear();
    data.forEach(x => {
      this.nextSteps.push(
        this.formBuilder.group({
          outcomeNextStepID: [x.NextStepID, Validators.required],
          outcomeDestinationWorkflowStepID: [x.DestinationWorkflowStepID, Validators.required],
          outcomeDeadlineTypeID: [x.DeadlineTypeID, Validators.required],
          outcomeIntervalTypeID: x.IntervalTypeID,
          outcomeDueInterval: x.DueInterval,
          outcomeCanDelete: x.CanDelete
        })
      );
      this.setDestinationWorkflowStepId[cnt] = x.DestinationWorkflowStepID;
      this.initDestinationWorkflowStep(cnt, !this.canTabWrite);
      this.setDeadlineTypeId[cnt] = x.DeadlineTypeID;
      this.initDeadlineType(cnt, !this.canTabWrite);
      this.setIntervalTypeId[cnt] = x.IntervalTypeID;
      this.initIntervalType(cnt, !this.canTabWrite);
      this.outcomeCanDelete[cnt] = x.CanDelete;
      cnt++;
    });
  }

  initStep(disable: boolean): void {
    if (!this.isEdit) {
      this.inputObjStep = {
        labelText: 'Step',
        optionValue: 'WorkflowStepID',
        optionLabel: 'WorkflowStepAlias',
        filter: true,
        requiredField: true,
        selectFirstValue: false,
        initSelected: this.setStepId,
        disabled: disable
      };
      this.lookupsDataService.getWorkflowStepListLookupData(this.workflowID).then((lookupData) => {
        this.inputObjStep.data = lookupData;
        this.inputObjStep = Object.assign({}, this.inputObjStep);
      });
    } else {
      this.inputObjStep = {
        labelText: 'Step',
        optionValue: 'ID',
        optionLabel: 'Description',
        filter: true,
        requiredField: true,
        selectFirstValue: false,
        initSelected: this.setStepId,
        disabled: disable
      };
      this.lookupsDataService.getWorkflowStepsLookupData(this.workflowID).then((lookupData) => {
        this.inputObjStep.data = lookupData;
        this.inputObjStep = Object.assign({}, this.inputObjStep);
      });
    }
  }

  getStepData(event: any): void {
    if (!this.isEdit) {
      if (event && event[0] && event[0].WorkflowStepID) {
        this.setStepId = event[0].WorkflowStepID;
      } else {
        this.setStepId = null;
      }
    } else {
      if (event && event[0] && event[0].ID) {
        this.setStepId = event[0].ID;
      } else {
        this.setStepId = null;
      }
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('step').setValue(this.setStepId);
  }

  initDestinationWorkflowStep(i: number, disable: boolean): void {
    this.inputObjDestinationWorkflowStep[i] = {
      labelText: 'Next Step',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: (this.setDestinationWorkflowStepId[i]) ? this.setDestinationWorkflowStepId[i] : null,
      disabled: disable
    };
    this.lookupsDataService.getWorkflowAvailableNextStepsLookupData(this.setStepId).then((lookupData) => {
      this.inputObjDestinationWorkflowStep[i].data = lookupData;
      this.inputObjDestinationWorkflowStep[i] = Object.assign({}, this.inputObjDestinationWorkflowStep[i]);
    });
  }

  getDestinationWorkflowStepData(i: number, event) {
    if (event && event[0] && event[0].ID) {
      this.setDestinationWorkflowStepId[i] = event[0].ID;
    } else {
      this.setDestinationWorkflowStepId[i] = null;
    }
    this.mainForm.markAsDirty();
    this.nextSteps.at(i).get('outcomeDestinationWorkflowStepID').setValue(this.setDestinationWorkflowStepId[i]);
    this.mainForm.updateValueAndValidity();
  }

  initDeadlineType(i: number, disable: boolean): void {
    this.inputObjDeadlineType[i] = {
      labelText: 'Deadline Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: (this.setDeadlineTypeId[i]) ? this.setDeadlineTypeId[i] : null,
      disabled: disable
    };
    this.lookupsDataService.getWorkflowDeadlineTypesLookupData().then((lookupData) => {
      this.inputObjDeadlineType[i].data = lookupData;
      this.inputObjDeadlineType[i] = Object.assign({}, this.inputObjDeadlineType[i]);
    });
  }

  getDeadlineTypeData(i: number, event) {
    if (event && event[0] && event[0].ID) {
      this.setDeadlineTypeId[i] = event[0].ID;
    } else {
      this.setDeadlineTypeId[i] = null;
    }
    this.mainForm.markAsDirty();
    this.nextSteps.at(i).get('outcomeDeadlineTypeID').setValue(this.setDeadlineTypeId[i]);
    this.mainForm.updateValueAndValidity();
  }

  initIntervalType(i: number, disable: boolean): void {
    this.inputObjIntervalType[i] = {
      labelText: 'Interval Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: (this.setIntervalTypeId[i]) ? this.setIntervalTypeId[i] : null,
      disabled: disable
    };
    this.lookupsDataService.getWorkflowIntervalTypesLookupData().then((lookupData) => {
      this.inputObjIntervalType[i].data = lookupData;
      this.inputObjIntervalType[i] = Object.assign({}, this.inputObjIntervalType[i]);
    });
  }

  getIntervalTypeData(i: number, event) {
    if (event && event[0] && event[0].ID) {
      this.setIntervalTypeId[i] = event[0].ID;
    } else {
      this.setIntervalTypeId[i] = null;
    }
    this.mainForm.markAsDirty();
    this.nextSteps.at(i).get('outcomeIntervalTypeID').setValue(this.setIntervalTypeId[i]);
  }

  setDirty(): void {
    this.mainForm.markAsDirty();
  }

  updateCloseBranch(closeBranch: string): void {
    this.showNextSteps = (closeBranch === 'false');
    this.nextSteps.clear();
    this.setDestinationWorkflowStepId = [];
    this.setDeadlineTypeId = [];
    this.setIntervalTypeId = [];
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      if (!this.isEdit) {
        this.saveWorkflowStepOutcome.WorkflowStepID = this.setStepId;
        this.saveWorkflowStepOutcome.WorkflowStepOutcomeID = this.WorkflowStepOutcomeId;
      } else {
        this.saveWorkflowStepOutcome.WorkflowStepID = this.workflowStepOutcome.WorkflowStepID;
        this.saveWorkflowStepOutcome.WorkflowStepOutcomeID = this.workflowStepOutcome.WorkflowStepOutcomeID;
      }
      this.saveWorkflowStepOutcome.KeyOutcome = this.mainForm.get('keyOutcome').value;
      this.saveWorkflowStepOutcome.EnforceRequirements = false;
      this.saveWorkflowStepOutcome.CloseBranch = this.mainForm.get('closeBranch').value;
      this.saveWorkflowStepOutcome.Description = this.mainForm.get('description').value;
      if (this.mainForm.get('closeBranch').value === false && this.nextSteps.length <= 0) {
        this.confirmationService.close();
        this.messageService.add({
          severity: 'warn', summary: 'Next Step Needed',
          detail: 'Please provide next step details to continue. Thanks!'
        });
        this.isSaving = false;
        this.existingMessage = false;
      } else {
        this.saveWorkflowStepOutcome.NextSteps = [];
        this.nextSteps.controls.forEach(x => {
          this.saveWorkflowStepOutcome.NextSteps.push(
            {
              NextStepID: Number(x.get('outcomeNextStepID').value),
              WorkflowStepOutcomeID: this.WorkflowStepOutcomeId,
              DestinationWorkflowStepID: Number(x.get('outcomeDestinationWorkflowStepID').value),
              DeadlineTypeID: Number(x.get('outcomeDeadlineTypeID').value),
              DueInterval: Number(x.get('outcomeDueInterval').value),
              IntervalTypeID: Number(x.get('outcomeIntervalTypeID').value)
            }
          );
        });
        this.isSaving = false;
        this.saveForm();
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Missing Data',
        detail: 'No data was saved, the form is currently incomplete.'
      });
      this.existingMessage = false;
    }
  }

  saveForm(): void {
    if (!this.isEdit) {
      this.workflowStepOutcomesService.createWorkflowStepOutcome(this.workflowID, this.saveWorkflowStepOutcome)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'The workflow step outcome has been added.'
            });
            this.isSaving = false;
            this.closeAddEdit.emit(true);
          }
        });
    } else {
      this.workflowStepOutcomesService.updateWorkflowStepOutcome(this.workflowID, this.WorkflowStepOutcomeId, this.saveWorkflowStepOutcome)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.confirmationService.close();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'The workflow step outcome has been updated.'
            });
            this.mainForm.markAsPristine();
            this.closeAddEdit.emit(true);
            this.isSaving = false;
            this.existingMessage = false;
          }
        });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm(this.isEdit);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }
}
