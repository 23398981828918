import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SharableNoteGridModel, SharableNotesGridModel} from '../../../../models/sharables.model';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SharablesService} from '../../../../services/sharables.service';
import {MessageService} from 'primeng/api';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {ListFilterComponent} from '../../list-filter/list-filter.component';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-notes-tab',
  templateUrl: './notes-tab.component.html',
  styleUrls: ['./notes-tab.component.scss']
})
export class NotesTabComponent implements OnInit, OnChanges, OnDestroy {
  notes: SharableNotesGridModel;
  noteList: SharableNoteGridModel[] = [];
  dataLoaded: boolean;
  isExporting: boolean;
  totalLoaded: boolean;
  selSharableId: number;
  isButtonDisabled: boolean = true;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  selectedItem: number;
  showAddEdit: boolean;
  notesTotal: number = 0;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() canEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() showDisclaimer: boolean;
  @Input() canAdd: boolean;
  getListRequest;
  getTotalRequest;
  first: number = 0;

  @ViewChild(ListFilterComponent) listFilter;

  private ngUnsubscribe = new Subject();

  constructor(private sharablesService: SharablesService, private messageService: MessageService,
              private dialogUtility: DialogUtility, public dialogService: DialogService,
              private changeDetectorRef: ChangeDetectorRef, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 0;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.getData();
  }

  getData(): void {
    if (this.DBEntity && this.DBEntityID) {
      if (this.getListRequest) {
        this.getListRequest.unsubscribe();
      }
      this.selectedItem = -1;
      this.noteList.length = 0;
      this.dataLoaded = false;
      this.getListRequest = this.sharablesService.getNotesPrimeNG('DATA', this.DBEntity, this.DBEntityID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.notes = res;
            this.noteList = res.Data;
            this.dataLoaded = true;
          }, error: (e) => {
            console.debug(e);
            this.dataLoaded = true;
          }
        });
      if (this.getTotalRequest) {
        this.getTotalRequest.unsubscribe();
      }
      this.notesTotal = 0;
      this.totalLoaded = false;
      this.getTotalRequest = this.sharablesService.getNotesPrimeNG('TOTAL', this.DBEntity, this.DBEntityID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.notesTotal = res.Total;
            if (this.canAdd && this.notesTotal <= 0) {
              this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
            } else {
              this.showAddEdit = false;
            }
            this.totalLoaded = true;
          }, error: (e) => {
            console.debug(e);
            this.totalLoaded = true;
          }
        });
    } else {
      this.dataLoaded = true;
      this.totalLoaded = true;
    }
  }

  deleteNote(sharableId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.sharablesService.deleteSharable(sharableId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected note has been removed.'
            });
            this.updateList(true);
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList(dataChanged: boolean): void {
    this.showAddEdit = false;
    if (dataChanged) {
      this.selectedItem = -1;
      this.getData();
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.first = event.first;
    this.getData();
  }

  filterItems(): void {
    this.dataLoaded = false;
    this.initFilters('Body', this.activeGlobalFilter, 'contains', 'and');
    this.getData();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  goToEdit(event:any): void {
    this.selSharableId = event.SharableId;
  }

  refresh(): void {
    this.getData();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  processFilter(filter): void {
    this.currentFilter = filter;
    this.currentFilter.first = 0;
    this.first = 0;
    this.changeDetectorRef.detectChanges();
    this.getData();
  }

  resetComponent(): void {
    this.noteList = undefined;
    this.notes = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.notesTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.noteList));
        const dataToExport = removeKeysFromArray(arr, ['SharableId', 'CanEdit', 'Context']);
        exportData('notes', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.sharablesService.getNotesPrimeNG('DATA', this.DBEntity, this.DBEntityID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['SharableId', 'CanEdit', 'Context']);
              exportData('notes', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
