import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {FileUploadModel, ResourceDataUrlModel, ResourceModel} from '../models/resources.model';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getResource(resourceReferenceType: string, Id: number): Observable<ResourceModel[]> {
    return this.http.get<ResourceModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/listbytype/${resourceReferenceType}/${Id}`);
  }

  deleteResource(resourceReferenceType: string, Id: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/removebytype/${resourceReferenceType}/${Id}`);
  }

  getContainerSAS(): Observable<string> {
    return this.http.get<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/containersas/thumbnail`);
  }

  uploadResource(formData: FormData, directory?: string): Observable<FileUploadModel[]> {
    const dir: string = (directory) ? directory : 'public';
    return this.http.post<FileUploadModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/upload/${dir}`, formData);
  }

  uploadResourceUser(formData: FormData, directory?: string, expHours?: string, expDays?: string): Observable<FileUploadModel[]> {
    const dir: string = (directory) ? directory : 'public';
    return this.http.post<FileUploadModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/resource/upload/${dir}`, formData);
  }

  uploadResourceDialog(formData: FormData, url): Observable<string> {
    return this.http.post<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/upload/${url}`, formData);
  }

  uploadResourceDataUrl(modelData: ResourceDataUrlModel): Observable<string> {
    return this.http.post<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/uploadfromdataurl/secure`, modelData);
  }

  getResourceById(Id: number): Observable<ResourceModel> {
    return this.http.get<ResourceModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/singlebyreferenceid/${Id}`);
  }

  getResourceStreamById(Id: number): Observable<ResourceModel[]> {
    return this.http.get<ResourceModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/streambyreferenceid/${Id}`);
  }
}
