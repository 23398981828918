<form [formGroup]="mainForm">
  <div class="p-fluid p-grid margin-top-10">
    <div class="col-md-2 col-sm-6 col-xs-12">
      <label>Related Table<sup class="req">*</sup></label>
      <p-dropdown [options]="tables" filter="true" optionLabel="option" optionValue="value"
                  formControlName="table" (onChange)="getColumns()"></p-dropdown>
      @if ((mainForm.controls.table.dirty || mainForm.controls.table.touched) &&
      mainForm.controls.table.errors?.required) {
        <span class="error">'Related Table' must be provided</span>
      }
    </div>
    <div class="col-md-2 col-sm-12 col-xs-12">
      <label>Columns<sup class="req">*</sup></label>
      <p-dropdown [options]="columns" filter="true" optionLabel="header" optionValue="field"
                  formControlName="column" (onChange)="getMatchTypes($event)"></p-dropdown>
      @if ((mainForm.controls.column.dirty || mainForm.controls.column.touched) &&
      mainForm.controls.column.errors?.required) {
        <span class="error">'Column' must be provided</span>
      }
    </div>
    @if (!isSelect && !isBoolean && !isYesNo && !isctYesNo) {
      <div class="col-md-2 col-sm-12 col-xs-12 ">
        <label>Match Type<sup class="req">*</sup></label>
        <p-dropdown [options]="matchType" filter="true" optionLabel="option" optionValue="value"
                    formControlName="matchType" (onChange)="setValueValidators($event)"></p-dropdown>
        @if ((mainForm.controls.matchType.dirty || mainForm.controls.matchType.touched) &&
        mainForm.controls.matchType.errors?.required) {
          <span class="error">'Match Type' must be provided</span>
        }
      </div>
    }
    @if (showValue() && selectedColumnField !== null) {
      <div class="col-md-2 col-sm-12 col-xs-12 ">
        <label>Value <sup
          class="req">*</sup>@if (isDate && (mainForm.get('matchType').value === 'daysfrompresent' || mainForm.get('matchType').value === 'monthsfrompresent')) {
          <span>(Use negative for past dates)</span>
        }</label>
        @if (!isDate) {
          <input type="text" pInputText formControlName="value"
                 (change)="valueDescription = mainForm.get('value').value">
        }
        @if (isDate && mainForm.get('matchType').value !== 'daysfrompresent' && mainForm.get('matchType').value !== 'monthsfrompresent') {
          <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="value" appendTo="body" placeholder="mm/dd/yyyy"
                      [showIcon]="true" [yearNavigator]="true" yearRange="1970:2025"></p-calendar>
        }
        @if (isDate && (mainForm.get('matchType').value === 'daysfrompresent' || mainForm.get('matchType').value === 'monthsfrompresent')) {
          <input type="number" pInputText formControlName="value"
                 (change)="valueDescription = mainForm.get('value').value">
        }
        @if ((mainForm.controls.value.dirty || mainForm.controls.value.touched) &&
        mainForm.controls.value.errors?.required) {
          <span class="error">'Value' must be provided</span>
        }
      </div>
    }
    @if (isBoolean && isNewFilter) {
      <div class="col-md-2 col-sm-12 col-xs-12">
        <app-select class="full-width" [inputObj]="booleanInputObj" (emitData)="getBooleanData($event)">
        </app-select>
      </div>
    }
    @if (isYesNo && isNewFilter) {
      <div class="col-md-2 col-sm-2 col-xs-12">
        <app-select class="full-width" [inputObj]="yesNoInputObj" (emitData)="getYesNoData($event)">
        </app-select>
      </div>
    }
    @if (isctYesNo && isNewFilter) {
      <div class="col-md-2 col-sm-2 col-xs-12">
        <app-select class="full-width" [inputObj]="yesNoInputObj" (emitData)="getctYesNoData($event)">
        </app-select>
      </div>
    }
    @if (isSelect) {
      <div class="col-md-2 col-sm-2 col-xs-12">
        <app-select class="full-width" [inputObj]="genericSelectObj" (emitData)="getGenericSelectData($event)">
        </app-select>
      </div>
    }
    @if (mainForm.get('matchType').value === 'equals' && isPicker  && selectedColumnField !== null ) {
      <div class="col-md-2 col-sm-2 col-xs-12">
        <app-multi-select class="full-width" [inputObj]="genericInputObj" [newValue]="selectedGenericItems"
                          (emitData)="getGenericMultiData($event)">
        </app-multi-select>
      </div>
    }
    @if (mainForm.controls.matchType.value === 'between' && !isDate) {
      <div class="col-md-2 col-sm-12 col-xs-12">
        <label>Start Value <sup class="req">*</sup></label>
        <input type="text" pInputText formControlName="startValue">
        @if ((mainForm.controls.startValue.dirty || mainForm.controls.startValue.touched) &&
        mainForm.controls.startValue.errors?.required) {
          <span class="error">'Start Value' must be provided</span>
        }
      </div>
    }
    @if (mainForm.controls.matchType.value === 'between' && !isDate) {
      <div class="col-md-2 col-sm-12 col-xs-12 ">
        <label>End Value <sup class="req">*</sup></label>
        <input type="text" pInputText formControlName="endValue">
        @if ((mainForm.controls.endValue.dirty || mainForm.controls.endValue.touched) &&
        mainForm.controls.endValue.errors?.required) {
          <span class="error">'End Value' must be provided</span>
        }
      </div>
    }
    @if ((mainForm.controls.matchType.value === 'daysfrompresentbetween' || mainForm.controls.matchType.value === 'monthsfrompresentbetween') && isDate && selectedColumnField !== null) {
      <div class="col-md-2 col-sm-12 col-xs-12">
        <label>Start Value <sup class="req">*</sup>(Use negative for past dates)</label>
        <input type="text" pInputText formControlName="startValue">
        @if ((mainForm.controls.startValue.dirty || mainForm.controls.startValue.touched) &&
        mainForm.controls.startValue.errors?.required) {
          <span class="error">'Start Value' must be provided</span>
        }
      </div>
    }
    @if ((mainForm.controls.matchType.value === 'daysfrompresentbetween' || mainForm.controls.matchType.value === 'monthsfrompresentbetween') && isDate && selectedColumnField !== null) {
      <div class="col-md-2 col-sm-12 col-xs-12 ">
        <label>End Value <sup class="req">*</sup>(Use negative for past dates)</label>
        <input type="text" pInputText formControlName="endValue">
        @if ((mainForm.controls.endValue.dirty || mainForm.controls.endValue.touched) &&
        mainForm.controls.endValue.errors?.required) {
          <span class="error">'End Value' must be provided</span>
        }
      </div>
    }
    @if (mainForm.controls.matchType.value === 'between' && isDate) {
      <div class="col-md-2 col-sm-12 col-xs-12">
        <label>Start Value <sup class="req">*</sup></label>
        <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="startValue" appendTo="body" placeholder="mm/dd/yyyy"
                    [maxDate]="mainForm.controls.endValue.value" [showIcon]="true" [yearNavigator]="true"
                    yearRange="1970:2025"></p-calendar>
        @if ((mainForm.controls.startValue.dirty || mainForm.controls.startValue.touched) &&
        mainForm.controls.startValue.errors?.required) {
          <span class="error">'Start Value' must be provided</span>
        }
      </div>
    }
    @if (mainForm.controls.matchType.value === 'between' && isDate) {
      <div class="col-md-2 col-sm-12 col-xs-12 ">
        <label>End Value <sup class="req">*</sup></label>
        <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="endValue" appendTo="body" placeholder="mm/dd/yyyy"
                    [minDate]="mainForm.controls.startValue.value" [showIcon]="true" [yearNavigator]="true"
                    yearRange="1970:2025"></p-calendar>
        @if ((mainForm.controls.endValue.dirty || mainForm.controls.endValue.touched) &&
        mainForm.controls.endValue.errors?.required) {
          <span class="error">'End Value' must be provided</span>
        }
      </div>
    }
    <div class="col-md-2 col-sm-12 col-xs-12 add-entry">
      @if (isNewFilter && !isNewDist) {
        <button pButton label="Add New Filter" [disabled]="!mainForm.valid && !isPicker && !isBoolean"
                (click)="processData()" icon="pi pi-plus" pTooltip="Add" tooltipPosition="bottom"
                class="margin-top-20"></button>
      }
      @if (isNewFilter && isNewDist) {
        <button pButton label="Save Distribution List" [disabled]="!mainForm.valid" (click)="processData()"
                icon="pi pi-plus" pTooltip="Save" tooltipPosition="bottom" class="margin-top-20"></button>
      }
      <!--      <button *ngIf="isEdit && canEdit && this.existingTimeEntry" pButton class="margin-right-5" (click)="processData()" icon="pi pi-save"-->
      <!--              [disabled]="!mainForm.valid" pTooltip="Save" tooltipPosition="bottom" ></button>-->
      <!--      <button *ngIf="isEdit && canEdit" pButton (click)="deleteEntry(existingTimeEntry.ProgramStudentFacetID)"-->
      <!--              icon="pi pi-trash" pTooltip="Remove" tooltipPosition="bottom" ></button>-->
    </div>
  </div>
</form>
