import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, UntypedFormArray, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MakePaymentService} from '../../../../services/make-payment.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PaymentMethodsService} from '../../../../services/payment-methods.service';
import {RosterProfileService} from '../../../../services/roster-profile.service';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {PaymentMethodBankNameModel, PaymentMethodCardTypeModel, PaymentMethodCardValidModel, PaymentMethodModel} from '../../../../models/payment-methods.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {MessageService} from 'primeng/api';
import {CheckFeeModel, UserPaymentAdjustmentModel, UserPaymentModel} from '../../../../models/user-payments.model';
import {BillingTypeModel} from '../../../../models/billing-types.model';
import {ConfirmValidationUtility} from '../../../utilities/confirm-validation.utility';
import {ContextService} from '../../../../services/context.service';
import {UserProfileService} from '../../../../services/user-profile.service';
import {AddressModel} from '../../../../models/addresses.model';
import {TenantConfigDataService} from '../../../../services/tenant-config-data.service';
import {oneTimeContributionExtraTextCustom} from '../../../utilities/component-manager-utility';

@Component({
  selector: 'app-make-payment-tab',
  templateUrl: './make-payment-tab.component.html',
  styleUrls: ['./make-payment-tab.component.scss']
})

export class MakePaymentTabComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  loading: boolean;
  isEmptyData: boolean = false;
  inputObjAutoPay: GenericSelectModel;
  setAutoPayId: number;
  isSaving: boolean;
  methodType: string = 'CC';
  minYear: number;
  maxYear: number;
  contributions: BillingTypeModel[];
  paymentMethodList: PaymentMethodModel[] = [];
  isNew: boolean = true;
  showDuesHeader: boolean = true;
  showOneTimeHeader: boolean = false;
  confirm: boolean = false;
  saveData: UserPaymentModel;
  checkFeeData: CheckFeeModel;
  checkFeePaymentMethodId: number;
  checkFeeFeeTotal: number = 0;
  checkFeeNonFeeTotal: number = 0;
  checkFeeTotal: number = 0;
  addressData: AddressModel = {} as AddressModel;
  tcSTATEMENTBILLING: boolean;
  showCVV: boolean;
  showACHAccountNumberConfirm: boolean;
  public _oneTimeContributionExtraTextCustom = oneTimeContributionExtraTextCustom;
  @Input() isEdit: boolean;
  @Input() userObject: boolean;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private makePaymentService: MakePaymentService,
              private paymentMethodsService: PaymentMethodsService, private rosterProfileService: RosterProfileService,
              private lookupsDataService: LookupsDataService, private messageService: MessageService,
              private confirmValidationUtility: ConfirmValidationUtility, private contextService: ContextService,
              private userProfileService: UserProfileService, private tenantConfigDataService: TenantConfigDataService) {
    this.mainForm = this.formBuilder.group({
      oneTimeContributions: new UntypedFormArray([]),
      duePayments: new UntypedFormArray([]),
      duePaymentValueTotal: new FormControl(0),
      billingTypeValueTotal: new FormControl(0),
      updatePaymentTotal: new FormControl(0),
      isAutoPaymentMethod: new FormControl(false),
      paymentMethod: new FormControl(['credit']),
      savePaymentMethod: new FormControl(true),
      // isCreditActive: new FormControl(true),
      paymentMethodID: new FormControl(null, [Validators.required]),
      MethodNickname: new FormControl(null, Validators.required),
      AutoPaymentScheduleTypeID: new FormControl(null),
      paymentMethodType: new FormControl('CC', Validators.required),
      ACHNameOnAccount: new FormControl(null),
      ACHAccountNumber: new FormControl(null),
      ACHAccountNumberConfirm: new FormControl(null),
      ACHAccountType: new FormControl(null),
      ACHBankName: new FormControl({value: null, disabled: true}),
      ACHRoutingNumber: new FormControl(null),
      CCNameOnAccount: new FormControl(null),
      CCAccountNumber: new FormControl(null),
      CCCardType: new FormControl({value: null, disabled: true}),
      CVV: new FormControl(null, Validators.maxLength(4)),
      CCExpirationMonth: new FormControl(null),
      CCExpirationYear: new FormControl(null),
      useAddress: new FormControl(null),
      country: new FormControl(null, Validators.required),
      streetAddress: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      primaryPhoneNumber: new FormControl(null),
      primaryEmail: new FormControl(null, [Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      oneTimeOnly: new FormControl(true)
    }, {
      validator: [this.confirmValidationUtility.ConfirmedValidator('ACHAccountNumber', 'ACHAccountNumberConfirm'),
        this.customValidator('ACHAccountNumber')]
    });
  }

  get oneTimeContributions() {
    return this.mainForm.get('oneTimeContributions') as UntypedFormArray;
  }

  get duePayments() {
    return this.mainForm.get('duePayments') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.tcSTATEMENTBILLING = this.tenantConfigDataService.getBooleanValue('STATEMENTBILLING');
    this.mainForm.get('paymentMethodType').valueChanges.subscribe({
      next: (value) => {
        this.methodType = value;
        this.setFormState(value);
      }
    });
    this.initForm();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  updateBillingTotal(value?, index?): void {
    this.mainForm.get('billingTypeValueTotal').setValue(0);
    const oneTimeContributionValueList = this.mainForm.get('oneTimeContributions').value;
    if (value && index >= 0) {
      console.log(index);
      oneTimeContributionValueList[index].oneTimeContributionValue = value;
    }
    for (const oneTimeContributionValue of oneTimeContributionValueList) {
      this.mainForm.get('billingTypeValueTotal').setValue(this.mainForm.get('billingTypeValueTotal').value + oneTimeContributionValue.oneTimeContributionValue);
    }
    this.mainForm.get('updatePaymentTotal').setValue(this.mainForm.get('duePaymentValueTotal').value + this.mainForm.get('billingTypeValueTotal').value);
  }

  updateDuesBillingTotal(value?): void {
    this.mainForm.get('duePaymentValueTotal').setValue(value);
    this.mainForm.get('updatePaymentTotal').setValue(this.mainForm.get('duePaymentValueTotal').value + this.mainForm.get('billingTypeValueTotal').value);
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  initSelects(): void {
    this.initAutoPay(false);
  }

  initForm(): void {
    this.getMemberSimpleBill();
    this.getOneTimePayment();
    this.getPaymentMethodsKGrid();
    this.isNew = true;
    this.mainForm.get('paymentMethodType').setValue('CC');
    this.mainForm.get('oneTimeOnly').setValue(true);
    this.setFormState('CC');
    this.setAutoPayId = null;

    this.addressData.CountryID = null;
    this.addressData.Address1 = null;
    this.addressData.Address2 = null;
    this.addressData.City = null;
    this.addressData.StateProvinceID = null;
    this.addressData.Zip = null;
    this.addressData.ZipPlus4 = null;
    this.addressData.Latitude = null;
    this.addressData.Longitude = null;
    this.addressData.Ready = true;

    this.initSelects();
  }

  setFormState(value: string): void {
    if (value === 'CC') {
      const dt = new Date();
      const yr: string = dt.getFullYear().toString().substring(2);
      this.minYear = Number(yr);
      this.maxYear = Number(yr) + 20;
      this.mainForm.get('CCNameOnAccount').setValidators([Validators.required]);
      this.mainForm.get('CCNameOnAccount').updateValueAndValidity();
      this.mainForm.get('CCAccountNumber').setValidators([Validators.required]);
      this.mainForm.get('CCAccountNumber').updateValueAndValidity();
      if (this.isNew) {
        this.mainForm.get('CCAccountNumber').enable();
        this.mainForm.get('CVV').enable();
        this.mainForm.get('CVV').setValidators([Validators.required, Validators.maxLength(4)]);
        this.mainForm.get('CVV').updateValueAndValidity();
        this.showCVV = true;
        this.mainForm.get('CCExpirationMonth').enable();
        this.mainForm.get('CCExpirationYear').enable();
      } else {
        this.mainForm.get('CCAccountNumber').disable();
        this.mainForm.get('CVV').disable();
        this.mainForm.get('CVV').setValidators(Validators.maxLength(4));
        this.mainForm.get('CVV').updateValueAndValidity();
        this.showCVV = false;
        this.mainForm.get('CCExpirationMonth').disable();
        this.mainForm.get('CCExpirationYear').disable();
      }
      this.mainForm.get('CCExpirationMonth').setValidators([Validators.required,
        Validators.min(1),
        Validators.max(12),
        this.regexValidator(new RegExp('^[0-9]+$'), {number: true})]);
      this.mainForm.get('CCExpirationMonth').updateValueAndValidity();
      this.mainForm.get('CCExpirationYear').setValidators([Validators.required,
        Validators.min(this.minYear),
        Validators.max(this.maxYear),
        this.regexValidator(new RegExp('^[0-9]+$'), {number: true})]);
      this.mainForm.get('CCExpirationYear').updateValueAndValidity();
      this.mainForm.get('ACHNameOnAccount').setValidators(null);
      this.mainForm.get('ACHNameOnAccount').updateValueAndValidity();
      this.mainForm.get('ACHAccountType').setValidators(null);
      this.mainForm.get('ACHAccountType').updateValueAndValidity();
      this.mainForm.get('ACHBankName').setValidators(null);
      this.mainForm.get('ACHBankName').updateValueAndValidity();
      this.mainForm.get('ACHRoutingNumber').setValidators(null);
      this.mainForm.get('ACHRoutingNumber').updateValueAndValidity();
      this.mainForm.get('ACHAccountNumber').setValidators(null);
      this.mainForm.get('ACHAccountNumber').updateValueAndValidity();

      this.mainForm.get('ACHNameOnAccount').setValue(null);
      this.mainForm.get('ACHAccountType').setValue(null);
      this.mainForm.get('ACHBankName').setValue(null);
      this.mainForm.get('ACHRoutingNumber').setValue(null);
      this.mainForm.get('ACHAccountNumber').setValue(null);
    } else {
      this.mainForm.get('ACHNameOnAccount').setValidators([Validators.required]);
      this.mainForm.get('ACHNameOnAccount').updateValueAndValidity();
      this.mainForm.get('ACHAccountType').setValidators([Validators.required]);
      this.mainForm.get('ACHAccountType').updateValueAndValidity();
      if (this.isNew) {
        this.mainForm.get('ACHRoutingNumber').enable();
        this.mainForm.get('ACHRoutingNumber').setValidators([Validators.required]);
        this.mainForm.get('ACHRoutingNumber').updateValueAndValidity();
        this.mainForm.get('ACHAccountNumber').enable();
        this.mainForm.get('ACHAccountNumber').setValidators([Validators.required]);
        this.mainForm.get('ACHAccountNumber').updateValueAndValidity();
        this.mainForm.get('ACHAccountNumberConfirm').enable();
        this.mainForm.get('ACHAccountNumberConfirm').setValidators([Validators.required]);
        this.mainForm.get('ACHAccountNumberConfirm').updateValueAndValidity();
        this.showACHAccountNumberConfirm = true;
      } else {
        this.mainForm.get('ACHRoutingNumber').disable();
        this.mainForm.get('ACHRoutingNumber').setValidators(null);
        this.mainForm.get('ACHRoutingNumber').updateValueAndValidity();
        this.mainForm.get('ACHAccountNumber').disable();
        this.mainForm.get('ACHAccountNumber').setValidators(null);
        this.mainForm.get('ACHAccountNumber').updateValueAndValidity();
        this.mainForm.get('ACHAccountNumberConfirm').disable();
        this.mainForm.get('ACHAccountNumberConfirm').setValidators(null);
        this.mainForm.get('ACHAccountNumberConfirm').updateValueAndValidity();
        this.showACHAccountNumberConfirm = false;
      }

      this.mainForm.get('CCNameOnAccount').setValidators(null);
      this.mainForm.get('CCNameOnAccount').updateValueAndValidity();
      this.mainForm.get('CCAccountNumber').setValidators(null);
      this.mainForm.get('CCAccountNumber').updateValueAndValidity();
      this.mainForm.get('CVV').setValidators(Validators.maxLength(4));
      this.mainForm.get('CVV').updateValueAndValidity();
      this.mainForm.get('CCExpirationMonth').setValidators(null);
      this.mainForm.get('CCExpirationMonth').updateValueAndValidity();
      this.mainForm.get('CCExpirationYear').setValidators(null);
      this.mainForm.get('CCExpirationYear').updateValueAndValidity();

      this.mainForm.get('ACHAccountType').setValue('CHECKING');
      this.mainForm.get('CCAccountNumber').setValue(null);
      this.mainForm.get('CCCardType').setValue(null);
      this.mainForm.get('CCExpirationMonth').setValue(null);
      this.mainForm.get('CCExpirationYear').setValue(null);
      this.mainForm.get('CVV').setValue(null);
    }
  }

  initAutoPay(disable: boolean): void {
    this.inputObjAutoPay = {
      labelText: 'Pay each month on this day',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setAutoPayId,
      disabled: disable
    };
    this.lookupsDataService.getAutoPayLookupData('member').then((lookupData) => {
      this.inputObjAutoPay.data = lookupData;
      this.inputObjAutoPay = Object.assign({}, this.inputObjAutoPay);
    });
  }

  getAutoPayData(event:any): void {
    if (event && event[0] && event[0].ID) {
      if (this.setAutoPayId !== event[0].ID) {
        this.mainForm.markAsDirty();
      }
      this.setAutoPayId = event[0].ID;
    } else {
      if (this.setAutoPayId) {
        this.mainForm.markAsDirty();
      }
      this.setAutoPayId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('AutoPaymentScheduleTypeID').setValue(this.setAutoPayId);
  }

  getOneTimePayment(): void {
    this.loading = true;
    this.contributions = [];
    this.oneTimeContributions.clear();
    this.makePaymentService.getOneTimeAdjustmentTypes(this.DBEntityID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.contributions = res;
          res.forEach(x => {
            this.addOneTimeContribution(x);
          });
          this.showOneTimeHeader = res.length > 0;
          this.loading = false;
        }, error: (e) => {
          this.showOneTimeHeader = false;
          this.loading = false;
          console.debug(e);
        }
      });
  }

  getMemberSimpleBill(): void {
    this.loading = true;
    this.duePayments.clear();
    this.makePaymentService.getMemberSimpleBill(this.DBEntityID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: any) => {
          if (res.AutoPaymentMethod !== null) {
            this.mainForm.get('isAutoPaymentMethod').setValue(true);
          }
          res.Details.forEach(x => {
            this.addDuePayments(x);
          });
          this.mainForm.get('duePaymentValueTotal').setValue(res.Amount);
          this.showDuesHeader = res.Details.length > 0;
          this.updateBillingTotal();
          this.loading = false;
        }, error: (e) => {
          this.showDuesHeader = false;
          this.loading = false;
          console.debug(e);
        }
      });
  }

  getPaymentMethodsKGrid(setPaymentMethodId?: number): void {
    this.paymentMethodList = [];
    this.paymentMethodsService.getPaymentMethodsPrimeNG('DATA', this.DBEntity, this.DBEntityID, null)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: any) => {
          res.Data.push({
            PaymentMethodId: 0,
            ProviderIdentifier: null,
            DBEntity: null,
            DBEntityKeyID: null,
            MethodType: 'CC',
            MethodNickname: null,
            Address1: null,
            Address2: null,
            City: null,
            StateProvinceID: null,
            Zip: null,
            CountryID: null,
            PhoneNumber: null,
            EmailAddress: null,
            AccountNumber: null,
            NameOnAccount: null,
            ACHAccountType: null,
            ACHRoutingNumber: null,
            ACHBankName: null,
            CCExpirationMonth: null,
            CCExpirationYear: null,
            CCCardType: 'Use Details Provided Below',
            CVV: null,
            OneTimeOnly: null,
            AutoPaymentScheduleTypeID: null,
            AutoPaymentScheduleTypeDescription: null
          });
          this.paymentMethodList = res.Data;
          if (res.Data.length === 0) {
            this.loading = false;
            this.isEmptyData = true;
          }
          if (setPaymentMethodId) {
            this.mainForm.get('paymentMethodID').setValue(setPaymentMethodId);
          } else {
            this.mainForm.get('paymentMethodID').setValue(0);
          }
        }, error: (e) => {
          console.debug(e);
        }
      });
  }

  toggleCardPaymentMethod(ccardInfo): void {
    this.addressData.Ready = false;
    this.isNew = ccardInfo.PaymentMethodId === 0;
    if (ccardInfo.CCCardType != null) {
      this.mainForm.get('paymentMethod').enable();
      this.mainForm.get('ACHAccountType').enable();
      //Credit Card Info
      this.mainForm.get('CCNameOnAccount').setValue(ccardInfo.NameOnAccount);
      this.mainForm.get('CCAccountNumber').setValue(ccardInfo.AccountNumber);
      this.mainForm.get('CCCardType').setValue(ccardInfo.CCCardType);
      this.mainForm.get('CCExpirationMonth').setValue(ccardInfo.CCExpirationMonth);
      this.mainForm.get('CCExpirationYear').setValue(ccardInfo.CCExpirationYear);
      this.mainForm.get('CVV').setValue(ccardInfo.CVV);
      if (ccardInfo.CCExpirationYear) {
        this.minYear = ccardInfo.CCExpirationYear;
        this.maxYear = ccardInfo.CCExpirationYear + 20;
      } else {
        const dt = new Date();
        const str: string = dt.getFullYear().toString().substring(3, 4);
        this.minYear = Number(str);
        this.maxYear = Number(str) + 20;
      }
    } else {
      this.mainForm.get('paymentMethod').disable();
      this.mainForm.get('ACHAccountType').disable();
      //Checking Account Info
      this.mainForm.get('ACHNameOnAccount').setValue(ccardInfo.NameOnAccount);
      this.mainForm.get('ACHAccountType').setValue(ccardInfo.ACHAccountType);
      this.mainForm.get('ACHRoutingNumber').setValue(ccardInfo.ACHRoutingNumber);
      this.mainForm.get('ACHBankName').setValue(ccardInfo.ACHBankName);
      this.mainForm.get('ACHAccountNumber').setValue(ccardInfo.AccountNumber);
      this.mainForm.get('ACHAccountNumberConfirm').setValue(null);
    }
    if (ccardInfo.MethodType !== null) {
      this.mainForm.get('paymentMethodType').setValue(ccardInfo.MethodType);
    } else {
      this.mainForm.get('paymentMethodType').setValue('CC');
    }
    this.mainForm.get('MethodNickname').setValue(ccardInfo.MethodNickname);
    this.mainForm.get('AutoPaymentScheduleTypeID').setValue(ccardInfo.AutoPaymentScheduleTypeID);
    this.mainForm.get('useAddress').setValue(false);
    this.mainForm.get('primaryPhoneNumber').setValue(ccardInfo.PhoneNumber);
    this.mainForm.get('primaryEmail').setValue(ccardInfo.EmailAddress);

    setTimeout(() => {
      // delay to load form on change of payment method
      this.mainForm.get('country').setValue(ccardInfo.CountryID);
      this.mainForm.get('streetAddress').setValue(ccardInfo.Address1);
      this.mainForm.get('city').setValue(ccardInfo.City);
      this.mainForm.get('state').setValue(ccardInfo.StateProvinceID);
      this.mainForm.get('postalCode').setValue(ccardInfo.Zip);
      this.addressData.CountryID = ccardInfo.CountryID;
      this.addressData.Address1 = ccardInfo.Address1;
      this.addressData.Address2 = ccardInfo.Address2;
      this.addressData.City = ccardInfo.City;
      this.addressData.StateProvinceID = ccardInfo.StateProvinceID;
      this.addressData.Zip = ccardInfo.Zip;
      this.addressData.ZipPlus4 = ccardInfo.ZipPlus4;
      this.addressData.Latitude = ccardInfo.Latitude;
      this.addressData.Longitude = ccardInfo.Longitude;
      this.addressData.Ready = true;
    }, 350);
  }

  addOneTimeContribution(res): void {
    const oneTimeContributionForm = this.formBuilder.group({
      Active: new FormControl(res.Active),
      AllowOneTimeContribution: new FormControl(res.AllowOneTimeContribution),
      BillingAdjustmentTypeID: new FormControl(res.BillingAdjustmentTypeID),
      Description: new FormControl(res.Description),
      ID: new FormControl(res.ID),
      IsDues: new FormControl(res.IsDues),
      MemberAdjustable: new FormControl(res.MemberAdjustable),
      MerchantAccountDescription: new FormControl(res.MerchantAccountDescription),
      MerchantAccountID: new FormControl(res.MerchantAccountID),
      OrganizationID: new FormControl(res.OrganizationID),
      OrganizationName: new FormControl(res.OrganizationName),
      ShowAllowOneTimeContribution: new FormControl(res.ShowAllowOneTimeContribution),
      Type: new FormControl(res.Type),
      UserFees: new FormControl(res.UserFees),
      oneTimeContributionValue: new FormControl(0)
    });
    this.oneTimeContributions.push(oneTimeContributionForm);
  }

  addDuePayments(res): void {
    const duePaymentListGroup = this.formBuilder.group({
      Amount: new FormControl(res.Amount),
      Description: new FormControl(res.Description),
      Period: new FormControl(res.Period),
      UserFees: new FormControl(res.UserFees)
    });
    this.duePayments.push(duePaymentListGroup);
  }

  getCCDetails(value: string): void {
    this.getCreditCardType(value);
    this.isCreditCardValid(value);
  }

  getCreditCardType(value: string): void {
    if (value) {
      const data = {} as PaymentMethodCardTypeModel;
      data.AccountNumber = value;
      this.paymentMethodsService.getCreditCardType(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.mainForm.get('CCCardType').setValue(res);
          }, error: () => {
            this.mainForm.get('CCCardType').setValue(null);
          }
        });
    }
  }

  isCreditCardValid(value: string): void {
    if (value) {
      const data = {} as PaymentMethodCardValidModel;
      data.AccountNumber = value;
      this.paymentMethodsService.isCreditCardValid(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (res === false) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'The Credit Card # provided is not valid. Please try again.'
              });
            }
          }
        });
    }
  }

  getBankName(value: string): void {
    if (value) {
      const data = {} as PaymentMethodBankNameModel;
      data.ACHRoutingNumber = value;
      this.paymentMethodsService.getBankName(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.mainForm.get('ACHBankName').setValue(res);
          }, error: () => {
            this.mainForm.get('ACHBankName').setValue(null);
          }
        });
    }
  }

  cancel(): void {
    this.addressData = {} as AddressModel;
    this.confirm = false;
    this.saveData = null;
    this.mainForm.reset();
    this.initForm();
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  confirmPayment(): void {
    // call on the checkfee API first. this will check for payment fees, create a payment method if new, and return data to be confirmed by the user
    const contributionsData: UserPaymentAdjustmentModel[] = [];
    const oneTimeContributionValueList = this.mainForm.get('oneTimeContributions').value;
    // let address1: string;
    for (const oneTimeContributionValue of oneTimeContributionValueList) {
      if (oneTimeContributionValue.oneTimeContributionValue > 0) {
        contributionsData.push({
          BillingAdjustmentTypeId: oneTimeContributionValue.ID,
          Amount: oneTimeContributionValue.oneTimeContributionValue
        });
      }
    }
    // if (this.suggestAddress) {
    //   if (this.mainForm.get('streetAddress').value && typeof this.mainForm.get('streetAddress').value === 'object') {
    //     if (this.mainForm.get('streetAddress').value.street_line) {
    //       address1 = this.mainForm.get('streetAddress').value.street_line;
    //     }
    //   } else {
    //     address1 = this.mainForm.get('streetAddress').value;
    //   }
    // } else {
    const address1: string = this.addressData.Address1;
    // }
    if (this.mainForm.get('paymentMethodType').value === 'CC') {
      this.saveData = {
        PaymentMethod: {
          PaymentMethodId: (this.checkFeePaymentMethodId) ? this.checkFeePaymentMethodId : this.mainForm.get('paymentMethodID').value,
          DBEntity: this.DBEntity,
          DBEntityKeyID: this.DBEntityID,
          MethodType: this.mainForm.get('paymentMethodType').value,
          MethodNickname: this.mainForm.get('MethodNickname').value,
          Address1: address1,
          Address2: this.addressData.Address2,
          City: this.addressData.City,
          StateProvinceID: this.addressData.StateProvinceID,
          Zip: this.addressData.Zip,
          CountryID: this.addressData.CountryID,
          PhoneNumber: this.mainForm.get('primaryPhoneNumber').value,
          EmailAddress: this.mainForm.get('primaryEmail').value,
          AccountNumber: this.mainForm.get('CCAccountNumber').value,
          NameOnAccount: this.mainForm.get('CCNameOnAccount').value,
          CCExpirationMonth: this.mainForm.get('CCExpirationMonth').value,
          CCExpirationYear: this.mainForm.get('CCExpirationYear').value,
          CCCardType: this.mainForm.get('CCCardType').value,
          CVV: this.mainForm.get('CVV').value,
          AutoPaymentScheduleTypeID: this.setAutoPayId,
          OneTimeOnly: !this.mainForm.get('oneTimeOnly').value
        },
        Amount: this.mainForm.get('duePaymentValueTotal').value,
        Adjustments: contributionsData
      };
      this.makePaymentService.checkFee(this.saveData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: CheckFeeModel) => {
            this.checkFeeData = res;
            this.checkFeeNonFeeTotal = 0;
            this.checkFeeData.NonFeeDetails.forEach(x => {
              this.checkFeeNonFeeTotal += x.Amount;
            });
            this.checkFeeFeeTotal = 0;
            this.checkFeeData.FeeDetails.forEach(x => {
              this.checkFeeFeeTotal += x.Amount;
            });
            this.checkFeeTotal = this.checkFeeNonFeeTotal + this.checkFeeFeeTotal;
            this.checkFeePaymentMethodId = res.PaymentMethodID;
            this.saveData.PaymentMethod.PaymentMethodId = this.checkFeePaymentMethodId;
            if (this.mainForm.get('oneTimeOnly').value === true) {
              this.getPaymentMethodsKGrid(this.checkFeePaymentMethodId);
            }
            this.confirm = true;
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 4000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
            this.isSaving = false;
            console.debug(e);
          }
        });
    } else {
      this.saveData = {
        PaymentMethod: {
          PaymentMethodId: (this.checkFeePaymentMethodId) ? this.checkFeePaymentMethodId : this.mainForm.get('paymentMethodID').value,
          DBEntity: this.DBEntity,
          DBEntityKeyID: this.DBEntityID,
          MethodType: this.mainForm.get('paymentMethodType').value,
          MethodNickname: this.mainForm.get('MethodNickname').value,
          Address1: address1,
          Address2: this.addressData.Address2,
          City: this.addressData.City,
          StateProvinceID: this.addressData.StateProvinceID,
          Zip: this.addressData.Zip,
          CountryID: this.addressData.CountryID,
          PhoneNumber: this.mainForm.get('primaryPhoneNumber').value,
          EmailAddress: this.mainForm.get('primaryEmail').value,
          AccountNumber: this.mainForm.get('ACHAccountNumber').value,
          NameOnAccount: this.mainForm.get('ACHNameOnAccount').value,
          ACHAccountType: this.mainForm.get('ACHAccountType').value,
          ACHRoutingNumber: this.mainForm.get('ACHRoutingNumber').value,
          ACHBankName: this.mainForm.get('ACHBankName').value,
          AutoPaymentScheduleTypeID: this.setAutoPayId,
          OneTimeOnly: !this.mainForm.get('oneTimeOnly').value
        },
        Amount: this.mainForm.get('duePaymentValueTotal').value,
        Adjustments: contributionsData
      };
      this.makePaymentService.checkFee(this.saveData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: CheckFeeModel) => {
            this.checkFeeData = res;
            this.checkFeeNonFeeTotal = 0;
            this.checkFeeData.NonFeeDetails.forEach(x => {
              this.checkFeeNonFeeTotal += x.Amount;
            });
            this.checkFeeFeeTotal = 0;
            this.checkFeeData.FeeDetails.forEach(x => {
              this.checkFeeFeeTotal += x.Amount;
            });
            this.checkFeeTotal = this.checkFeeNonFeeTotal + this.checkFeeFeeTotal;
            this.checkFeePaymentMethodId = res.PaymentMethodID;
            this.saveData.PaymentMethod.PaymentMethodId = this.checkFeePaymentMethodId;
            if (this.mainForm.get('oneTimeOnly').value === true) {
              this.getPaymentMethodsKGrid(this.checkFeePaymentMethodId);
            }
            this.confirm = true;
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 4000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
            this.isSaving = false;
            console.debug(e);
          }
        });
    }
  }

  makePayment(): void {
    this.isSaving = true;
    this.makePaymentService.makePayment(this.saveData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Payment Made',
            detail: 'The payment has been made. Thank you so much!'
          });
          this.cancel();
          this.isSaving = false;
          this.closeAddEdit.emit();
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: e.error.Message.replace('UM: ', ''),
              life: 4000
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
          }
          this.isSaving = false;
          console.debug(e);
        }
      });
  }

  applyAddress(event:any): void {
    if (event && event === true) {
      this.addressData.Ready = false;
      switch (true) {
        case this.userObject === true && this.DBEntity === 'PERSON':
          this.userProfileService.getUserProfile()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.addressData.CountryID = res.PrimaryCountryID;
                this.addressData.Address1 = res.PrimaryAddress1;
                this.addressData.Address2 = res.PrimaryAddress2;
                this.addressData.City = res.PrimaryCity;
                this.addressData.StateProvinceID = res.PrimaryStateProvinceID;
                this.addressData.Zip = res.PrimaryZip;
                this.addressData.Ready = true;
                this.mainForm.get('country').setValue(res.PrimaryCountryID);
                this.mainForm.get('streetAddress').setValue(res.PrimaryAddress1);
                this.mainForm.get('city').setValue(res.PrimaryCity);
                this.mainForm.get('state').setValue(res.PrimaryStateProvinceID);
                this.mainForm.get('postalCode').setValue(res.PrimaryZip);
                this.mainForm.get('primaryPhoneNumber').setValue(res.PrimaryPhoneNumber);
                this.mainForm.get('primaryEmail').setValue(res.PrimaryEmailAddress);
              }
            });
          break;
        case this.userObject === false && this.DBEntity === 'PERSON':
          this.rosterProfileService.getRosterProfile(this.DBEntityID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.addressData.CountryID = res.PrimaryCountryID;
                this.addressData.Address1 = res.PrimaryAddress1;
                this.addressData.Address2 = res.PrimaryAddress2;
                this.addressData.City = res.PrimaryCity;
                this.addressData.StateProvinceID = res.PrimaryStateProvinceID;
                this.addressData.Zip = res.PrimaryZip;
                this.addressData.Ready = true;
                this.mainForm.get('country').setValue(res.PrimaryCountryID);
                this.mainForm.get('streetAddress').setValue(res.PrimaryAddress1);
                this.mainForm.get('city').setValue(res.PrimaryCity);
                this.mainForm.get('state').setValue(res.PrimaryStateProvinceID);
                this.mainForm.get('postalCode').setValue(res.PrimaryZip);
                this.mainForm.get('primaryPhoneNumber').setValue(res.PrimaryPhoneNumber);
                this.mainForm.get('primaryEmail').setValue(res.PrimaryEmailAddress);
              }
            });
          break;
      }
    }
  }

  customValidator(controlName: string) {
    return (formGroup: FormGroup) => {
      const matchingControl = formGroup.controls[controlName];
      if (matchingControl.errors && !matchingControl.errors?.customValidator) {
        return;
      }
      const control: string = this.contextService.contextObject.UserName;
      if (control) {
        const am: number = control.indexOf('@');
        const username: string = control.substring(0, am);
        const domain: string = control.substring(am + 1, control.length);
        if (matchingControl?.value?.indexOf(username) > -1 || matchingControl?.value?.indexOf(domain) > -1) {
          matchingControl.setErrors({customValidator: true});
        } else {
          matchingControl.setErrors(null);
        }
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  setAddress(addressChanges: AddressModel): void {
    if (addressChanges) {
      this.mainForm.markAsDirty();
      this.addressData.CountryID = addressChanges.CountryID;
      this.addressData.Address1 = addressChanges.Address1;
      this.addressData.Address2 = addressChanges.Address2;
      this.addressData.City = addressChanges.City;
      this.addressData.StateProvinceID = addressChanges.StateProvinceID;
      this.addressData.Zip = addressChanges.Zip;
      this.mainForm.get('country').setValue(addressChanges.CountryID);
      this.mainForm.get('streetAddress').setValue(addressChanges.Address1);
      this.mainForm.get('city').setValue(addressChanges.City);
      this.mainForm.get('state').setValue(addressChanges.StateProvinceID);
      this.mainForm.get('postalCode').setValue(addressChanges.Zip);
    }
  }
}
