import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProgramStudentFacetsModel, ProgramStudentFacetTypesListModel, ProgramStudentTimeCollectionFacetModel, UpdateCreateProgramStudentTimeCollectionModelByCategory, UpdateProgramStudentTimeCollectionModel} from '../../../../../models/program-student-time-collection.model';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {Subject} from 'rxjs';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {ContextService} from '../../../../../services/context.service';
import {ProgramStudentTimeCollectionsService} from '../../../../../services/program-student-time-collection.service';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {transformDateTimeToDateOnly} from '../../../../utilities/form.utility';

@Component({
  selector: 'app-time-entry-form',
  templateUrl: './time-entry-form.component.html',
  styleUrls: ['./time-entry-form.component.scss']
})

export class TimeEntryFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  entry = {} as UpdateProgramStudentTimeCollectionModel;
  programId: number;
  timeEntry = {} as UpdateCreateProgramStudentTimeCollectionModelByCategory;
  inputObjPrograms: GenericSelectModel;
  inputObjPeriodInterval: GenericSelectModel;
  setPeriodInterval: string;
  setProgramId: number;
  inputObjFacet: GenericSelectModel;
  setFacetId: number;
  setFacets: number[];
  totalNewFacets: number;
  inputObjEmployer: GenericSelectModel;
  setEmployerId: number;
  @Input() isEdit: boolean;
  @Input() selectedTab: string;
  @Input() canTabWrite: boolean;
  @Input() facetData: ProgramStudentTimeCollectionFacetModel;
  @Input() programID: number;
  @Input() selFacetType: ProgramStudentFacetTypesListModel;
  @Input() startDate: string;
  @Input() programStudentId: number;
  @Input() existingTimeEntry: ProgramStudentFacetsModel;
  @Output() updateData = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<any>();
  @Input() canEdit: boolean = true;
  @Input() MembershipOrganizationID: number;
  @Input() personID: number;
  isAdmin: boolean;
  inputChanged: boolean;
  RejectionReason: boolean = false;
  showEditHours: boolean;
  showSave: boolean;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private contextService: ContextService, private programStudentTimeCollectionsService: ProgramStudentTimeCollectionsService,
              private messageService: MessageService, private router: Router) {
    this.mainForm = this.formBuilder.group({
      program: new FormControl(null),
      employer: new FormControl(null, Validators.required),
      manager: new FormControl(null),
      facet: new FormControl(null, Validators.required),
      period: new FormControl(null, Validators.required),
      hours: new FormControl(null, Validators.required),
      rejectionReason: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.isAdmin = !this.router.url.includes('my-time');
  }

  ngOnChanges() {
    if (!this.isEdit) {
      this.initSelects();
      this.setEmployerId = this.selFacetType.LastEmployerID;
      this.mainForm.get('manager').setValue(this.selFacetType.LastManager);
    }
    if (this.isEdit && this.existingTimeEntry) {
      this.initForm();
    }
    if (!this.canEdit || !this.canTabWrite) {
      this.mainForm.disable();
    } else {
      this.mainForm.enable();
    }
    //remove after api fix
    if (this.contextService.contextObject.PersonId !== this.personID) {
      if (this.selectedTab === 'APPROVED') {
        this.showEditHours = true;
        this.setFacetId = this.existingTimeEntry.FacetID;
        this.initFacets(true);
      }
    }
    //end removal
  }

  //remove after api fix
  enableHours() {
    this.mainForm.enable();
    this.initPrograms(false);
    this.initPeriodInterval(false);
    this.initEmployer(false);
    this.initFacets(false);
    this.showSave = true;
  }

  cancel(): void {
    this.mainForm.disable();
    this.initForm();
    this.showSave = false;
  }

  // end removal

  initForm() {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.mainForm.get('hours').setValue(this.existingTimeEntry.Hours);
    this.mainForm.get('manager').setValue(this.existingTimeEntry.ManagerName);
    this.mainForm.get('period').setValue(this.existingTimeEntry.IntervalStartDate_D);
    this.mainForm.get('program').setValue(this.programStudentId);
    this.mainForm.get('facet').setValue(this.existingTimeEntry.FacetID);
    this.setPeriodInterval = this.existingTimeEntry.IntervalStartDate_D;
    this.setProgramId = this.programStudentId;
    this.setEmployerId = this.existingTimeEntry.OrganizationID;
    this.setFacetId = this.existingTimeEntry.FacetID;
    this.mainForm.get('manager').valueChanges.subscribe({
      next: (val) => {
        if (val !== this.existingTimeEntry.ManagerName) {
          this.inputChanged = true;
          this.valueChanged.emit(this.inputChanged);
        } else {
          this.inputChanged = false;
          this.valueChanged.emit(this.inputChanged);
        }
      }
    });
    this.mainForm.get('hours').valueChanges.subscribe({
      next: (val) => {
        if (val !== this.existingTimeEntry.Hours) {
          this.inputChanged = true;
          this.valueChanged.emit(this.inputChanged);
        } else {
          this.inputChanged = false;
          this.valueChanged.emit(this.inputChanged);
        }
      }
    });
    this.mainForm.get('employer').valueChanges.subscribe({
      next: (val) => {
        if (val !== this.existingTimeEntry.OrganizationID) {
          this.inputChanged = true;
          this.valueChanged.emit(this.inputChanged);
        } else {
          this.inputChanged = false;
          this.valueChanged.emit(this.inputChanged);
        }
      }
    });
    this.mainForm.get('period').valueChanges.subscribe({
      next: (val) => {
        if (val !== this.existingTimeEntry.IntervalStartDate_D) {
          this.inputChanged = true;
          this.valueChanged.emit(this.inputChanged);
        } else {
          this.inputChanged = false;
          this.valueChanged.emit(this.inputChanged);
        }
      }
    });
    if (this.existingTimeEntry.RejectionReason && this.existingTimeEntry.RejectionReason !== '') {
      this.mainForm.get('rejectionReason').setValue(this.existingTimeEntry.RejectionReason);
      this.RejectionReason = true;
    } else {
      this.mainForm.get('rejectionReason').setValue(this.existingTimeEntry.RejectionReason);
      this.RejectionReason = false;
    }
    this.initSelects();
    this.initPrograms(true);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initSelects() {
    this.initEmployer(!this.canEdit || !this.canTabWrite);
    this.initFacets(!this.canEdit || !this.canTabWrite);
    this.initPeriodInterval(!this.canEdit || !this.canTabWrite);
  }

  initPrograms(disable: boolean) {
    this.inputObjPrograms = {
      labelText: 'Program',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: this.setProgramId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getProgramsByPersonLookupData(this.personID, true).then((lookupData) => {
      this.inputObjPrograms.data = lookupData;
      this.inputObjPrograms = Object.assign({}, this.inputObjPrograms);
    });
  }

  getProgramData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setProgramId = event[0].ID;
    } else {
      this.setProgramId = 0;
    }
    this.mainForm.get('program').setValue(this.setProgramId);
  }

  initEmployer(disable: boolean): void {
    this.inputObjEmployer = {
      labelText: 'Employer',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setEmployerId,
      disabled: disable,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getOrganizationsByParentLookupData('UnionEmployer', this.MembershipOrganizationID, null, true, true).then((lookupData) => {
      this.inputObjEmployer.data = lookupData;
      this.inputObjEmployer = Object.assign({}, this.inputObjEmployer);
    });
  }

  setEmployerData(event: any): void {
    if (event && event[0]) {
      this.setEmployerId = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setEmployerId = 0;
    }
    if (this.isEdit) {
      this.inputChanged = true;
    }
    if (this.setEmployerId === 0) {
      this.mainForm.get('employer').setValue(null);
    } else {
      this.mainForm.get('employer').setValue(this.setEmployerId);
    }
  }

  initFacets(disable: boolean): void {
    this.inputObjFacet = {
      labelText: 'Skill',
      optionValue: 'FacetID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: (this.isEdit) ? this.setFacetId : null,
      disabled: disable,
      emitChangeOnLoad: true
    };
    if (this.selFacetType) {
      this.lookupsDataService.getProgramFacetTypeFacetsLookupData(this.selFacetType.ProgramFacetTypeID, true, false).then((lookupData) => {
        this.inputObjFacet.data = lookupData;
        //temp for now
        this.inputObjFacet.data = this.inputObjFacet.data.filter(x => x.Description !== 'Incoming Credit' && !x.Description.includes('Imported'));
        this.inputObjFacet = Object.assign({}, this.inputObjFacet);
      });
    }
  }

  getFacetData(event: any) {
    if (event) {
      this.setFacets = event;
      this.mainForm.get('facet').setValue(event[0]);
    } else {
      this.setFacets = null;
      this.mainForm.get('facet').setValue(null);
    }
  }

  initPeriodInterval(disable: boolean) {
    this.inputObjPeriodInterval = {
      labelText: 'Period Interval',
      optionValue: 'StartDate_D',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setPeriodInterval,
      disabled: disable,
      emitChangeOnLoad: true
    };
    if (this.programStudentId && this.selFacetType.ID && this.startDate) {
      this.lookupsDataService.getPeriodIntervalListLookupData(this.programStudentId, this.selFacetType.ID, this.startDate, true).then((lookupData) => {
        this.inputObjPeriodInterval.data = lookupData;
        this.inputObjPeriodInterval = Object.assign({}, this.inputObjPeriodInterval);
      });
    }
  }

  getPeriodIntervalData(event: any) {
    if (event && event[0]) {
      this.setPeriodInterval = (event[0].StartDate_D !== null) ? event[0].StartDate_D : 0;
    } else {
      this.setPeriodInterval = '';
    }
    this.mainForm.get('period').setValue(this.setPeriodInterval);
    if (this.isEdit) {
      this.inputChanged = true;
    }
  }

  processData() {
    this.timeEntry.FacetID = this.setFacetId;
    this.timeEntry.FacetCategoryID = this.selFacetType.ProgramFacetTypeID;
    this.timeEntry.FacetTypeID = this.selFacetType.ID;
    const hours = (Math.round(this.mainForm.get('hours').value * 4) / 4).toFixed(2);
    this.timeEntry.Hours = +hours;
    this.timeEntry.ManagerName = this.mainForm.get('manager').value;
    this.timeEntry.FacetDescription = this.selFacetType.Description;
    this.timeEntry.IntervalStartDate_D = transformDateTimeToDateOnly(this.setPeriodInterval);
    this.timeEntry.PeriodStartDate_D = transformDateTimeToDateOnly(this.startDate);
    this.timeEntry.ProgramStudentID = this.programStudentId;
    this.timeEntry.OrganizationID = this.setEmployerId;
    if (this.isEdit) {
      this.timeEntry.ProgramStudentFacetID = this.existingTimeEntry.ProgramStudentFacetID;
      this.timeEntry.FacetID = this.existingTimeEntry.FacetID;
    }
    //remove after api fix
    if (this.showEditHours) {
      this.timeEntry.FacetID = this.setFacetId;
    }
    //end removal
    this.addEntry();
  }

  processNewData() {
    if (this.setFacets && this.setFacets.length > 0) {
      this.timeEntry.FacetID = this.setFacets[0];
      this.timeEntry.FacetCategoryID = this.selFacetType.ProgramFacetTypeID;
      this.timeEntry.FacetTypeID = this.selFacetType.ID;
      const hours = (Math.round(this.mainForm.get('hours').value * 4) / 4).toFixed(2);
      this.timeEntry.Hours = +hours;
      this.timeEntry.ManagerName = this.mainForm.get('manager').value;
      this.timeEntry.FacetDescription = this.selFacetType.Description;
      this.timeEntry.IntervalStartDate_D = transformDateTimeToDateOnly(this.setPeriodInterval);
      this.timeEntry.PeriodStartDate_D = transformDateTimeToDateOnly(this.startDate);
      this.timeEntry.ProgramStudentID = this.programStudentId;
      this.timeEntry.OrganizationID = this.setEmployerId;
      if (this.isEdit) {
        this.timeEntry.ProgramStudentFacetID = this.existingTimeEntry.ProgramStudentFacetID;
        this.timeEntry.FacetID = this.existingTimeEntry.FacetID;
      }
      //remove after api fix
      if (this.showEditHours) {
        this.timeEntry.FacetID = this.setFacets[0];
      }
      this.programStudentTimeCollectionsService.createProgramStudentTimeCollectionStudentFacet(this.timeEntry)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Entry has been added'
            });
            // remove the first item
            this.setFacets.shift();
            if (this.setFacets && this.setFacets.length > 0) {
              // recall this function to add entry
              this.processNewData();
            } else {
              this.updateData.emit();
              this.messageService.add({
                severity: 'warn', summary: 'Review/Confirm Hours',
                detail: 'Please review and confirm the hours associated with each facet prior to submitting time for approval.',
                life: 10000
              });
              this.valueChanged.emit(false);
            }
          }, error:
            () => {
              this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
              //console.warn(e);
            }
        });
    }
  }

  deleteEntry(id) {
    this.programStudentTimeCollectionsService.deleteProgramStudentTimeCollectionStudentFacet(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Entry was deleted successfully'
          });
          this.updateData.emit();
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        }
      });
  }

  addEntry() {
    if (!this.isEdit) {
      this.programStudentTimeCollectionsService.createProgramStudentTimeCollectionStudentFacet(this.timeEntry)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateData.emit();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Entry has been added'
            });
            this.valueChanged.emit(false);
          }, error:
            () => {
              this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
              //console.warn(e);
            }
        });
    } else {
      this.programStudentTimeCollectionsService.updateProgramStudentTimeCollectionStudentFacet(this.existingTimeEntry.ProgramStudentFacetID, this.timeEntry)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateData.emit();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Entry has been updated'
            });
            this.updateData.emit();
            this.valueChanged.emit(false);
          }, error:
            (e) => {
              this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
              console.warn(e);
            }
        });
    }
  }
}
