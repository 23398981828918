import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { EventOnlineMeetingModel, CreateEventOnlineMeetingModel } from '../models/event-online-meetings.model';

@Injectable({
  providedIn: 'root',
})
export class EventOnlineMeetingsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getEventOnlineMeeting(meetingTypeId: number, meetingId: string): Observable<EventOnlineMeetingModel> {
    return this.http.get<EventOnlineMeetingModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/onlinemeeting/${meetingTypeId}/${meetingId}`);
  }

  createEventOnlineMeeting(meetingTypeId: number, modelData: CreateEventOnlineMeetingModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/onlinemeeting/${meetingTypeId}/create`, modelData);
  }
}
