@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
<form [formGroup]="mainForm" class="margin-top-10">
  <div class="p-grid p-fluid">
    <div class="col-md-8 col-sm-12 col-xs-12">
      <div class="p-grid p-fluid">
        <div class="col-md-2 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjHonorific"
                      (emitData)="getHonorificData($event)"></app-select>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 input-container">
          <label>First Name<sup class="req">*</sup></label>
          <input type="text" pInputText class="full-width" formControlName="firstName" tabindex="0">
          @if ((mainForm.controls.firstName.dirty || mainForm.controls.firstName.touched) &&
          mainForm.controls.firstName.errors?.required) {
            <span class="error">'firstName' must be provided</span>
          }
        </div>
        <div class="col-md-1 col-sm-12 col-xs-12 input-container">
          <label>Middle</label>
          <input class="full-width" type="text" pInputText formControlName="middleName" tabindex="0">
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 input-container">
          <label>Last Name<sup class="req">*</sup></label>
          <input class="full-width" type="text" pInputText formControlName="lastName" tabindex="0">
          @if ((mainForm.controls.lastName.dirty || mainForm.controls.lastName.touched) &&
          mainForm.controls.lastName.errors?.required) {
            <span class="error">'Last Name' must be provided</span>
          }
        </div>
        <div class="col-md-1 col-sm-12 col-xs-12 input-container">
          <label>Suffix</label>
          <input class="full-width" type="text" pInputText formControlName="suffix" tabindex="0">
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <p-divider class="dark-divider"></p-divider>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container mask-container">
          <label>Home Phone #</label>
          <p-inputMask type="text" pInputText formControlName="primaryPhoneNumber" [className]="'form-input no-border'"
                       mask="(999) 999-9999"
                       unmask="true" tabindex="0"></p-inputMask>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container mask-container"></div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container mask-container">
          <label>Mobile Phone #@if (SecondaryPhoneFailedCommunication) {
            <span class="req">&nbsp;*Undeliverable</span>
          }</label>
          <p-inputMask type="text" pInputText formControlName="secondaryPhoneNumber"
                       (change)="mobileCheckValidation(this.mainForm.controls.secondaryPhoneNumber.value)"
                       [className]="'form-input no-border'" mask="(999) 999-9999"
                       unmask="true" tabindex="0"></p-inputMask>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 radio-container">
          <label>SMS Text Messaging</label>
          <p-radioButton [value]="true" formControlName="SMSOptIn" [styleClass]="'radio-input'" class="p-mr-2 p-mt-2"
                         label="Opt-In" tabindex="0"></p-radioButton>
          <p-radioButton [value]="false" formControlName="SMSOptIn" [styleClass]="'radio-input'" class="p-mr-2 p-mt-2"
                         label="Opt-Out" tabindex="0"></p-radioButton>
        </div>
        @if (smsData?.length > 0) {
          <div class="col-md-3 col-sm-12 col-xs-12 input-container padding-top-20 pointer">
            <p-checkbox [binary]="true" formControlName="optOutCarrier" [disabled]="true" label="Opted Out with Carrier"
                        (click)="smsDialog()" pTooltip="See Details" tooltipPosition="bottom"
                        tabindex="0"></p-checkbox>
          </div>
        }
        <div class="col-md-8 col-sm-12 col-xs-12">
          @if (mainForm.get('SMSOptIn').value === true) {
            <span>By choosing to opt into text messaging you may, from time to time, receive text message communication and surveys from {{ contextService.contextObject.tenantName }}
              . You may return to this page at any time to opt out of text messages.</span>
          }
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12 input-container mask-container">
          <label>Other Phone #</label>
          <p-inputMask type="text" pInputText formControlName="thirdPhoneNumber" [className]="'form-input no-border'"
                       mask="(999) 999-9999"
                       unmask="true" tabindex="0"></p-inputMask>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container mask-container"></div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>Primary E-mail
            @if (PrimaryEmailFailedCommunication) {
              <span class="req">&nbsp;*Undeliverable</span>
            }
            @if (requirePrimaryEmail) {
              <sup class="req">*</sup>
            }
          </label>
          <div class="p-inputgroup w-full md:w-30rem">
            <input type="text" pInputText formControlName="primaryEmail" tabindex="0"
                   (focus)="isEditing = true"
                   (blur)="validatePii('PrimaryEmailAddress', mainForm.get('primaryEmail').value)">
            @if ((mainForm.controls.primaryEmail.dirty || mainForm.controls.primaryEmail.touched) &&
            mainForm.controls.primaryEmail?.errors) {
              <span class="error">'E-mail' must be valid</span>
            }
            @if ((mainForm.controls.primaryEmail.dirty || mainForm.controls.primaryEmail.touched) &&
            mainForm.controls.primaryEmail.errors?.required) {
              <span class="error">'E-mail' is required</span>
            }
            @if (isSearching) {
              <span class="p-inputgroup-addon pointer"><span class="fa fa-spinner search-spinner"></span></span>
            }
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 radio-container">
          <label>Email Messaging</label>
          <p-radioButton value="false" formControlName="EmailMsgOptIn" [styleClass]="'radio-input'" class="p-mr-2"
                         label="Opt-In" (onClick)="toggleEmailMsg()" tabindex="0"></p-radioButton>
          <p-radioButton value="true" formControlName="EmailMsgOptIn" [styleClass]="'radio-input'" label="Opt-Out"
                         (onClick)="toggleEmailMsg()" tabindex="0"></p-radioButton>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>Secondary E-mail@if (SecondaryEmailFailedCommunication) {
            <span class="req">&nbsp;*Undeliverable</span>
          }</label>
          <input type="text" pInputText formControlName="secondaryEmail" tabindex="0">
          @if ((mainForm.controls.secondaryEmail.dirty || mainForm.controls.secondaryEmail.touched) &&
          mainForm.controls.secondaryEmail?.errors) {
            <span class="error">'E-mail' must be valid</span>
          }
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 radio-container">
          <label>Email Messaging</label>
          <p-radioButton value="false" formControlName="AltEmailMsgOptIn" [styleClass]="'radio-input'" class="p-mr-2"
                         label="Opt-In" (onClick)="toggleAltEmailMsgAlt()" tabindex="0"></p-radioButton>
          <p-radioButton value="true" formControlName="AltEmailMsgOptIn" [styleClass]="'radio-input'" label="Opt-Out"
                         (onClick)="toggleAltEmailMsgAlt()" tabindex="0"></p-radioButton>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
      @if (rosterProfile) {
        <div class="p-grid p-fluid">
          <div class="col-md-12 col-sm-12 col-xs-12 input-container address-container">
            <app-addresses-tab [DBEntity]="'person'" [DBEntityID]="rosterProfile.PersonID"
                               [locationHeader]="locationHeader"
                               [canTabWrite]="canEdit"></app-addresses-tab>
          </div>
        </div>
      }
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <p-accordion>
        <p-accordionTab header="Demographics">
          <div class="p-fluid p-grid">
            <div class="col-md-3 col-sm-12 col-xs-12 radio-container">
              <label class="p-mt-2">Gender</label>
              <p-radioButton value="F" formControlName="gender" [styleClass]="'radio-input'" class="p-mr-2"
                             label="Female" tabindex="0"></p-radioButton>
              <p-radioButton value="M" formControlName="gender" [styleClass]="'radio-input'" label="Male" class="p-mr-2"
                             tabindex="0"></p-radioButton>
              <p-radioButton value="" formControlName="gender" [styleClass]="'radio-input'" label="Unspecified"
                             tabindex="0"></p-radioButton>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 input-container">
              <app-multi-select class="full-width" [inputObj]="inputObjRace"
                                (emitData)="getRaceData($event)"></app-multi-select>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 input-container">
              <app-multi-select-languages class="full-width" [inputObj]="inputObjLanguage"
                          (emitData)="setLanguageData($event)" [preferredId]="rosterProfile?.PreferredLanguageID" (emitPreferred)="setPreferredLanguageData($event)"></app-multi-select-languages>
            </div>

<!--            <div class="col-md-3 col-sm-12 col-xs-12 input-container">-->
<!--              <app-select class="full-width" [inputObj]="inputObjPreferredLanguage" (emitData)="setPreferredLanguageData($event)">-->
<!--              </app-select>-->
<!--            </div>-->
            <div class="col-md-3 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjEducation"
                          (emitData)="getEducationData($event)"></app-select>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 input-container">
              <label>Name of School</label>
              <input type="text" pInputText formControlName="schoolAttended" tabindex="0">
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjMilitary"
                          (emitData)="getMilitaryData($event)"></app-select>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjMilitaryStatus"
                          (emitData)="getMilitaryStatusData($event)"></app-select>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjMarriageStatus"
                          (emitData)="getMarriageStatusData($event)"></app-select>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjShirtSize"
                          (emitData)="getShirtSizeData($event)"></app-select>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjCitizenship"
                          (emitData)="getCitizenshipData($event)"></app-select>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 radio-container">
              <label class="p-mt-2">Registered Voter</label>
              <p-radioButton value="true" formControlName="voter" [styleClass]="'radio-input'" class="p-mr-2"
                             label="Yes" tabindex="0"></p-radioButton>
              <p-radioButton value="false" formControlName="voter" [styleClass]="'radio-input'" label="No"
                             class="p-mr-2" tabindex="0"></p-radioButton>
            </div>
            @if (rosterProfile?.CustomAttributes && rosterProfile?.CustomAttributes.length > 0) {
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="p-fluid p-grid">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <p-divider class="dark-divider"></p-divider>
                  </div>
                  @if (_separateCustomFieldsOnProfile()) {
                    @for (attribute of rosterProfile?.CustomAttributes; track attribute; let i = $index; ) {
                      @if (attribute.FieldName === 'Helmets To Hardhats') {
                        <div class="col-md-3 col-sm-12 col-xs-12">
                          <app-dynamic-custom-fields [PersonID]="rosterProfile.PersonID" [customField]="attribute"
                                                     [isEdit]="true" [canTabWrite]="canEdit"
                                                     (closeAddEdit)="updateCustomAttribute(i, $event)"></app-dynamic-custom-fields>
                        </div>
                      }
                    }
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <h3>Approved Following with Signature</h3>
                    </div>
                    @for (attribute of rosterProfile?.CustomAttributes; track attribute; let i = $index; ) {
                      @if (attribute.FieldName !== 'Helmets To Hardhats') {
                        <div class="col-md-3 col-sm-12 col-xs-12">
                          <app-dynamic-custom-fields [PersonID]="rosterProfile.PersonID" [customField]="attribute"
                                                     [isEdit]="true" [canTabWrite]="canEdit"
                                                     (closeAddEdit)="updateCustomAttribute(i, $event)"></app-dynamic-custom-fields>
                        </div>
                      }
                    }
                  } @else {
                    @for (attribute of rosterProfile?.CustomAttributes; track attribute; let i = $index; ) {
                      <div class="col-md-3 col-sm-12 col-xs-12">
                        <app-dynamic-custom-fields [PersonID]="rosterProfile.PersonID" [customField]="attribute"
                                                   [isEdit]="true" [canTabWrite]="canEdit"
                                                   (closeAddEdit)="updateCustomAttribute(i, $event)"></app-dynamic-custom-fields>
                      </div>
                    }
                  }
                </div>
              </div>
            }
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
    @if (rosterProfile) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <p-accordion [styleClass]="'relative'">
          <p-accordionTab header="Emergency Contacts" [selected]="showEmergency">
            @if (rosterProfile?.EmergencyContacts.length === 1) {
              <button pButton class="p-button-download export-menu p-mr-2 add-contact inline-button" icon="pi pi-plus"
                      pTooltip="Add Contact" tooltipPosition="bottom" label="Add Contact"
                      (click)="addEmergencyContact()">
              </button>
            }
            @for (contact of rosterProfile.EmergencyContacts; track contact; let i = $index) {
              <div>
                <div class="emergency-title-container">
                  <h3 class="emergency-title">Emergency Contact {{ i + 1 }}</h3>
                  @if (rosterProfile?.EmergencyContacts.length > 1) {
                    <button pButton class="p-button-download export-menu p-mr-2 swap-button" icon="pi pi-sort-alt"
                            pTooltip="Swap Priority" tooltipPosition="bottom" (click)="updateEmergencyPriority()">
                    </button>
                  }
                  @if (!contact.hideDelete) {
                    <button pButton class="p-button-download export-menu p-mr-2 swap-button" icon="pi pi-trash"
                            pTooltip="Delete Contact" tooltipPosition="bottom"
                            (click)="deleteEmergencyContact(contact, i)">
                    </button>
                  }
                </div>
                <app-emergency-contact [emergencyContact]="contact" [canTabWrite]="canEdit"
                                       (updateAddress)="updateEmergencyAddress($event, i)"></app-emergency-contact>
                @if (rosterProfile?.EmergencyContacts.length - 1 > i) {
                  <p-divider class="dark-divider margin-top-10"></p-divider>
                }
              </div>
            }
            @if (!rosterProfile.EmergencyContacts || rosterProfile.EmergencyContacts.length === 0) {
              <button pButton class="p-button-download export-menu p-mr-2 inline-button" icon="pi pi-plus"
                      pTooltip="Add Contact" tooltipPosition="bottom" label="Add Contact"
                      (click)="addEmergencyContact()">
              </button>
            }
          </p-accordionTab>
        </p-accordion>
      </div>
    }
    @if (canEdit) {
      <div class="col-md-12 text-align-right margin-top-10">
        <div class="action-buttons float-right">
          @if (isEdit) {
            <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                    icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                    (click)="cancel()" [disabled]="!mainForm.dirty"></button>
          } @else {
            <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times"
                    pTooltip="Cancel" tooltipPosition="bottom"
                    (click)="cancel()"></button>
          }
          @if (!isSaving) {
            <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                    tooltipPosition="bottom"
                    (click)="processData()" [className]="'inline-button'"
                    [disabled]="mainForm.invalid || !mainForm.dirty || isSearching || isEditing"></button>
          } @else {
            <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    }
  </div>
</form>
