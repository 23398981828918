@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
} @else {
  <form [formGroup]="mainForm">
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      <div class="col-md-6 col-sm-6 col-xs-12 input-container">
        <div class="input-container">
          <label>Name/Description <sup class="req">*</sup></label>
          <input type="text" pInputText  formControlName="MethodNickname" tabindex="0">
          @if ((mainForm.controls.MethodNickname.dirty || mainForm.controls.MethodNickname.touched) &&
            mainForm.controls.MethodNickname.errors?.required) {
            <span class="error">'Name/Description' must be provided</span>
          }
        </div>
        @if (!isEdit) {
          <div class="input-container p-mt-2">
            <div class="radio-container">
              @if (allowCC) {
                <p-radioButton value="CC" formControlName="paymentMethodType" id="cc" [styleClass]="'radio-input'" class="p-mr-2" label="Credit Card" tabindex="0"></p-radioButton>
              }
              <p-radioButton value="ACH" formControlName="paymentMethodType" id="ach" [styleClass]="'radio-input'" label="Electronic Check (Direct Debit)" tabindex="0"></p-radioButton>
            </div>
          </div>
        }
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12 input-container">
        <p-card header="Auto Pay*" class="address-card">
          <div class="p-grid p-fluid">
            <div class="col-md-3 col-sm-5 col-xs-12">
              <app-select class="full-width" [inputObj]="inputObjAutoPay" (emitData)="getAutoPayData($event)"></app-select>
            </div>
            <div class="col-md-9 col-sm-7 col-xs-12">
              <p class="p-text-bold p-ml-2 p-mr-2">*By filling out and submitting this form, you are giving us your explicit permission to use the specified payment method to pay off your entire dues, fees, and recurring contribution balance each month on the day of the month indicated.</p>
            </div>
          </div>
        </p-card>
      </div>
    </div>
    <div class="p-grid p-fluid parent-form">
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          @if (methodType==='CC') {
            <div class="col-md-12 col-sm-12 col-xs-12 input-container">
              <h3>Credit Card Details</h3>
            </div>
          }
          @if (methodType==='CC') {
            <div class="col-md-12 col-sm-6 col-xs-12 input-container">
              <label>Name as it appears on card <sup class="req">*</sup></label>
              <input type="text" pInputText  formControlName="CCNameOnAccount" tabindex="0">
              @if ((mainForm.controls.CCNameOnAccount.dirty || mainForm.controls.CCNameOnAccount.touched) &&
                mainForm.controls.CCNameOnAccount.errors?.required) {
                <span class="error">'Name as it appears on card' must be provided</span>
              }
            </div>
          }
          @if (methodType==='CC') {
            <div class="col-md-8 col-sm-6 col-xs-12 input-container">
              <label>Credit Card # (No spaces/dashes) <sup class="req">*</sup></label>
              <input type="text" pInputText  formControlName="CCAccountNumber" (blur)="getCCDetails(this.mainForm.controls.CCAccountNumber.value)" tabindex="0">
              @if ((mainForm.controls.CCAccountNumber.dirty || mainForm.controls.CCAccountNumber.touched) &&
                mainForm.controls.CCAccountNumber.errors?.required) {
                <span class="error">'Credit Card #' must be provided</span>
              }
            </div>
          }
          @if (methodType==='CC') {
            <div class="col-md-4 col-sm-6 col-xs-12 input-container">
              <label>Credit Card Type</label>
              <input type="text" pInputText  formControlName="CCCardType" tabindex="0">
            </div>
          }
          @if (methodType==='CC') {
            <div class="col-md-4 col-sm-6 col-xs-12 input-container">
              <label>Exp. Month <sup class="req">*</sup></label>
              <input type="text" pInputText  formControlName="CCExpirationMonth" maxlength="2" tabindex="0">
              @if ((mainForm.controls.CCExpirationMonth.dirty || mainForm.controls.CCExpirationMonth.touched) &&
                mainForm.controls.CCExpirationMonth.errors?.required) {
                <span class="error">'Exp. Month' must be provided</span>
              }
              @if ((mainForm.controls.CCExpirationMonth.dirty || mainForm.controls.CCExpirationMonth.touched) &&
                mainForm.controls.CCExpirationMonth.errors?.min) {
                <span class="error">Must be between 1 and 12</span>
              }
              @if ((mainForm.controls.CCExpirationMonth.dirty || mainForm.controls.CCExpirationMonth.touched) &&
                mainForm.controls.CCExpirationMonth.errors?.max) {
                <span class="error">Must be between 1 and 12</span>
              }
              @if (mainForm.controls.CCExpirationMonth.hasError('number')) {
                <div class="error">Must be a number</div>
              }
            </div>
          }
          @if (methodType==='CC') {
            <div class="col-md-4 col-sm-6 col-xs-12 input-container">
              <label>Exp. Year <sup class="req">*</sup></label>
              <input type="text" pInputText  formControlName="CCExpirationYear" maxlength="2" tabindex="0">
              @if ((mainForm.controls.CCExpirationYear.dirty || mainForm.controls.CCExpirationYear.touched) &&
                mainForm.controls.CCExpirationYear.errors?.required) {
                <span class="error">'Exp. Year' must be provided</span>
              }
              @if ((mainForm.controls.CCExpirationYear.dirty || mainForm.controls.CCExpirationYear.touched) &&
                mainForm.controls.CCExpirationYear.errors?.min) {
                <span class="error">Must be between {{minYear}} and {{maxYear}}</span>
              }
              @if ((mainForm.controls.CCExpirationYear.dirty || mainForm.controls.CCExpirationYear.touched) &&
                mainForm.controls.CCExpirationYear.errors?.max) {
                <span class="error">Must be between {{minYear}} and {{maxYear}}</span>
              }
              @if (mainForm.controls.CCExpirationYear.hasError('number')) {
                <div class="error">Must be a number</div>
              }
            </div>
          }
          @if (!isEdit && methodType==='CC') {
            <div class="col-md-4 col-sm-6 col-xs-12 input-container">
              <label>Verification # <sup class="req">*</sup></label>
              <input type="text" pInputText formControlName="CVV" tabindex="0">
              @if ((mainForm.controls.CVV.dirty || mainForm.controls.CVV.touched) &&
                mainForm.controls.CVV.errors?.required) {
                <span class="error">'Verification #' must be provided</span>
              }
              @if (mainForm.controls.CVV.errors?.minlength || mainForm.controls.CVV.errors?.maxlength) {
                <div>Must be 4 or fewer</div>
              }
            </div>
          }
          @if (methodType==='CC') {
            <div class="col-md-12 col-sm-12 col-xs-12 input-container">
              <img class="float-right" ngSrc="./assets/images/cvv.png" width="350" height="136" alt="" [priority]="true" />
              <img ngSrc="./assets/images/cc_logos.png" width="80" height="25" alt="" [priority]="true" />
            </div>
          }
          @if (methodType==='ACH') {
            <div class="col-md-12 col-sm-6 col-xs-12 input-container">
              <h3>Electronic Check Payment Terms &amp; Conditions</h3>
              <p>When you provide your check information, you authorize the <span class="ut-shortname">UnionTrack</span> to use information from your check to make an electronic fund transfer from your account.</p>
              <p>When payments are made from your account, funds may be withdrawn as soon as the same day we receive your payment, and you will not receive your check back from your financial institution.</p>
            </div>
          }
          @if (methodType==='ACH') {
            <div class="col-md-12 col-sm-6 col-xs-12 input-container">
              <label>Name on Account <sup class="req">*</sup></label>
              <input type="text" pInputText  formControlName="ACHNameOnAccount" tabindex="0">
              @if ((mainForm.controls.ACHNameOnAccount.dirty || mainForm.controls.ACHNameOnAccount.touched) &&
                mainForm.controls.ACHNameOnAccount.errors?.required) {
                <span class="error">'Name on Account' must be provided</span>
              }
            </div>
          }
          @if (methodType==='ACH') {
            <div class="col-md-12 col-sm-6 col-xs-12 input-container">
              <div class="radio-container">
                <p-radioButton value="CHECKING" formControlName="ACHAccountType" id="CHECKING" [styleClass]="'radio-input'" class="p-mr-2" label="Checking"></p-radioButton>
                <p-radioButton value="SAVING" formControlName="ACHAccountType" id="SAVING" [styleClass]="'radio-input'" label="Savings"></p-radioButton>
              </div>
            </div>
          }
          @if (methodType==='ACH') {
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <label>Routing # <sup class="req">*</sup></label>
              <input type="text" pInputText  formControlName="ACHRoutingNumber" maxlength="9" (blur)="getBankName(this.mainForm.controls.ACHRoutingNumber.value)" tabindex="0">
              @if ((mainForm.controls.ACHRoutingNumber.dirty || mainForm.controls.ACHRoutingNumber.touched) &&
                mainForm.controls.ACHRoutingNumber.errors?.required) {
                <span class="error">'Routing # ' must be provided</span>
              }
            </div>
          }
          @if (methodType==='ACH') {
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <label>Bank</label>
              <input type="text" pInputText  formControlName="ACHBankName" tabindex="0">
            </div>
          }
          @if (methodType==='ACH') {
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <label>Account # <sup class="req">*</sup></label>
              <input type="text" pInputText  formControlName="ACHAccountNumber" maxlength="20" tabindex="0">
              @if ((mainForm.controls.ACHAccountNumber.dirty || mainForm.controls.ACHAccountNumber.touched) &&
                mainForm.controls.ACHAccountNumber.errors?.required) {
                <span class="error">'Account # ' must be provided</span>
              }
            </div>
          }
          @if (methodType==='ACH') {
            <div class="col-md-12 col-sm-12 col-xs-12 input-container">
              <img ngSrc="./assets/images/ach.gif" width="428" height="275" alt="" [priority]="true" />
            </div>
          }
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12 input-container">
            <h3>Billing Address</h3>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 input-container">
            <div class="p-mt-3 switch-container">
              <p-inputSwitch class="p-ml-4" formControlName="useAddress" (click)="applyAddress(this.mainForm.controls.useAddress.value)" tabindex="0"></p-inputSwitch>
              <div class="input-switch-label p-ml-2">Use my primary address</div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 input-container">
            @if (addressData.Ready) {
              @if (paymentMethod) {
                <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [showCounty]="false" [required]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
              } @else if (!isEdit) {
                <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [showCounty]="false" [required]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
              }
            } @else {
              <app-address-form [incomingAddress]="addressData" [canTabWrite]="false" [showCounty]="false" [required]="true"></app-address-form>
            }
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p-divider class="dark-divider"></p-divider>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 input-container mask-container">
            <label>Phone #</label>
            <p-inputMask type="text" pInputText formControlName="primaryPhoneNumber" [className]="'form-input no-border'" mask="(999) 999-9999"
            unmask="true" tabindex="0"></p-inputMask>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <label>E-mail</label>
            <input type="text" pInputText  formControlName="primaryEmail" tabindex="0">
            @if (mainForm.controls.primaryEmail.invalid || mainForm.controls.primaryEmail.touched) {
              <div class="p-error">
                @if (mainForm.controls.primaryEmail?.errors) {
                  <div>E-mail must be a valid e-mail address</div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="p-grid p-fluid margin-top-10">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12"></div>
          <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
            @if (isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
              (click)="cancel()" [disabled]="!mainForm.dirty"></button>
            } @else {
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
              (click)="cancel()"></button>
            }
            @if (!isSaving) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                (click)="processData()" [className]="'inline-button'"
              [disabled]="mainForm.invalid || !mainForm.dirty"></button>
            } @else {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  </form>
}
