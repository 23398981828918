<div class="p-grid p-fluid margin-top-0">
  @if (emailAllowed && textAllowed) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">
      <button pButton class="p-button-download contact-button" icon="pi pi-envelope" label="Email"
              pTooltip="Clear" tooltipPosition="bottom" (click)="isEmail = true">
      </button>
      <button pButton class="p-button-download contact-button" icon="pi pi-mobile" label="Text"
              pTooltip="Search" tooltipPosition="bottom" (click)="isEmail = false">
      </button>
    </div>
  }
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">
    @if (isEmail) {
      <app-adhoc-email-form [recipients]="recipients" [recipientsStudentCerts]="recipientsStudentCerts"
                            [recipientsClassStudent]="recipientsClassStudent" [DBEntity]="DBEntity"
                            [DBEntityID]="DBEntityID" [superuser]="superuser" [senderName]="senderName"
                            [subject]="subject" [organizationId]="organizationId" [context]="context"
                            (closeDialog)="closeDialog($event)"></app-adhoc-email-form>
    } @else {
      <app-adhoc-phone-form [recipients]="recipients" [recipientsStudentCerts]="recipientsStudentCerts"
                            [recipientsClassStudent]="recipientsClassStudent" [DBEntity]="DBEntity"
                            [DBEntityID]="DBEntityID" [superuser]="superuser"
                            (closeDialog)="closeDialog()"></app-adhoc-phone-form>
    }
  </div>
</div>
