import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DialogUtility} from '../../../../utilities/dialog.utility';
import {WorkflowStepModel} from '../../../../../models/workflow-steps.model';
import {WorkflowStepsService} from '../../../../../services/workflow-steps.service';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {ContextService} from '../../../../../services/context.service';
import {removeKeysFromArray} from '../../../../utilities/data.utility';
import {exportData} from '../../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../../utilities/list-filter.utility';
import {PageService} from '../../../../../services/page.services';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-workflow-steps-list',
  templateUrl: './workflow-steps-list.component.html',
  styleUrls: ['./workflow-steps-list.component.scss'],
})
export class WorkflowStepsListComponent implements OnInit, OnDestroy {
  context = this.contextService.contextObject;
  InterfaceObjectEnum: string;
  // canPageWrite: boolean;
  showTable: boolean = false;
  workflowStepList: WorkflowStepModel[] = [];
  dataTotal: number;
  cols: any[];
  exportColumns: any[];
  dataLoaded: boolean;
  isExporting: boolean;
  totalLoaded: boolean;
  selWorkflowStepId: number;
  isButtonDisabled: boolean = true;
  exportItems: any[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  selectedTypeFilter: string = null;
  showData: boolean = false;
  showAddEdit: boolean;
  selectedItem: number;
  showFilter: boolean;
  @Input() organizationId: number;
  @Input() workflowId: number;
  @Input() canTabWrite: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() canAdd: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private workflowStepsService: WorkflowStepsService, private messageService: MessageService,
              private dialogUtility: DialogUtility, private pageService: PageService,
              private contextService: ContextService, private confirmationService: ConfirmationService,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.InterfaceObjectEnum = this.parentObjectEnum;
    this.showFilter = this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_ShowFilterDefault')?.Value === 'Show';

    // load filters
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    // load main list
    this.getData();

    this.pageService.getPagePermissions(this.InterfaceObjectEnum).then(() => {
      // this.canPageWrite = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  delete(workflowStepsId: number) {
    if (workflowStepsId) {
      this.dialogUtility.promptToDelete(() => {
        this.workflowStepsService
          .deleteWorkflowStep(workflowStepsId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.selectedItem = -1;
            this.confirmationService.close();
            this.selWorkflowStepId = undefined;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected record has been deleted.'
            });
            this.refresh();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 4000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
      }, () => {
      });
    }
  }

  expandData(index, id) {
    // if (this.context.isMobile) {
    //   this.goToEdit(id);
    // } else {
    this.showData = !this.showData;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
    // }
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.workflowStepList));
        const dataToExport = removeKeysFromArray(arr, ['WorkflowStepID', 'CanEdit', 'CanDelete']);
        exportData('facet-types', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.workflowStepsService
          .getWorkflowStepListPrimeNG('DATA', this.workflowId, exportFilter)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['WorkflowStepID', 'CanEdit', 'CanDelete']);
              exportData('facet-types', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }

  addWorkflowStep() {
    this.showAddEdit = true;
    this.selectedItem = -1;
  }

  getTotal() {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.workflowStepsService
      .getWorkflowStepListPrimeNG('TOTAL', this.workflowId, this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  getList() {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.workflowStepList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.workflowStepsService
      .getWorkflowStepListPrimeNG('DATA', this.workflowId, this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.workflowStepList = res.Data;
          if (this.canTabWrite && this.workflowStepList.length === 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
  }

  getData() {
    this.getTotal();
    this.getList();
    this.showAddEdit = false;
  }

  filterItems() {
    this.dataLoaded = false;
    this.initFilters('StepDescription', this.activeGlobalFilter, 'contains', 'and');
    this.currentFilter.first = 0;
    this.getData();
  }

  clearFilters() {
    this.dataLoaded = false;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  paginate(event: any) {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  refresh() {
    this.getData();
  }

  resetFilter() {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.getData();
  }

  reorderStep(iD1: number, iD2: number): void {
    this.workflowStepsService.reorderWorkflowSteps(iD1, iD2)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'The step has been reordered.'});
          this.getData();
        }
      });
  }
}
