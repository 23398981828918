<form [formGroup]="mainForm">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="p-grid p-fluid">
      <!-- new member -->
      @if (newMember) {
        <div class="col-md-3 col-sm-3 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjActivityOrg" (emitData)="getActivityOrgData($event)" tabindex="0"></app-select>
        </div>
      }
      @if (newMember && setOrganizationId) {
        <div class="col-md-3 col-sm-3 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjActivityPathway" (emitData)="getActivityPathwayData($event)"  tabindex="0"></app-select>
        </div>
      }
      @if (newMember && setActivityOrg) {
        <div class="col-md-3 col-sm-3 col-xs-12 input-container">
          <label>Activity Date <sup class="req">*</sup></label>
          <app-universal-date-picker [selectedValue]="mainForm.get('activityDate').value" (emitData)="mainForm.get('activityDate').setValue($event); activityDateChanged($event)"></app-universal-date-picker>
          <!--        <p-calendar dateMask  placeholder="mm/dd/yyyy" formControlName="activityDate" appendTo="body" dateFormat="mm/dd/yy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" [maxDate]="maxActivityDate"-->
        <!--                    [styleClass]="'p-mt-2'" (onSelect)="activityDateChanged($event)" (onBlur)="activityDateChanged($event)"  tabindex="0"></p-calendar>-->
      </div>
    }
    @if (newMember && mainForm.get('activityDate').value && setOrganizationId) {
      <div class="col-md-3 col-sm-3 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjActivityType" (emitData)="getActivityTypeData($event)" tabindex="0"></app-select>
      </div>
    }
    @if (newMember && setActivityType && setActivityOrg) {
      <div class="col-md-3 col-sm-3 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjActivities" (emitData)="getActivitiesData($event)" tabindex="0"></app-select>
      </div>
    }
    @if (newMember && showApplicationDate) {
      <div class="col-md-3 col-sm-3 col-xs-12 input-container">
        <label>Application Date <sup class="req">*</sup></label>
        <app-universal-date-picker [selectedValue]="mainForm.get('applicationDate').value" (emitData)="mainForm.get('applicationDate').setValue($event); activityPreview = false"></app-universal-date-picker>
        <!--        <p-calendar dateMask  placeholder="mm/dd/yyyy" formControlName="applicationDate" appendTo="body" dateFormat="mm/dd/yy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" [maxDate]="maxActivityDate"-->
      <!--                    [styleClass]="'p-mt-2'" (onBlur)="activityPreview = false" tabindex="0"></p-calendar>-->
    </div>
  }
  @if (setActivityId && showBargainingUnitFlag && newMember) {
    <div class="col-md-3 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjActivityBargainingUnit" (emitData)="getActivityBargainingUnitData($event)" tabindex="0"></app-select>
    </div>
  }
  @if (setActivityId && showPersonProfileClassificationFlag && newMember) {
    <div class="col-md-3 col-sm-3 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjActivityClass" (emitData)="getActivityClassData($event)" tabindex="0"></app-select>
    </div>
  }
  @if (setActivityId && newMember && showPersonProfileDuesGroups) {
    <div class="col-md-3 col-sm-3 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjActivityDues" (emitData)="getActivityDuesData($event)" tabindex="0"></app-select>
    </div>
  }
  @if (showWaiveFee && newMember) {
    <div class="col-md-3 col-sm-3 col-xs-12 checkbox-container">
      <p-checkbox formControlName="waiveInitiationFee" binary="true" label=" Waive APF" (onChange)="activityPreview = false" tabindex="0"></p-checkbox>
    </div>
  }
  <!-- existing member -->
  @if (!newMember) {
    <div class="col-md-3 col-sm-3 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjActivities" (emitData)="getActivitiesData($event)"></app-select>
    </div>
  }
  @if (setActivityId && !newMember) {
    <div class="col-md-3 col-sm-3 col-xs-12 input-container">
      <label>Activity Date <sup class="req">*</sup></label>
      <app-universal-date-picker  (emitData)="mainForm.get('activityDate').setValue($event); activityDateChanged($event)"></app-universal-date-picker>
      <!--        <p-calendar dateMask  placeholder="mm/dd/yyyy" formControlName="activityDate" appendTo="body" dateFormat="mm/dd/yy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" [maxDate]="maxActivityDate"-->
    <!--                    [styleClass]="'p-mt-2'" (onSelect)="activityDateChanged($event)" (onBlur)="activityDateChanged($event)"></p-calendar>-->
  </div>
}
@if (setActivityId && mainForm.get('activityDate').value && showOrganizationFlag && !newMember) {
  <div class="col-md-3 col-sm-3 col-xs-12 input-container">
    <app-select class="full-width" [inputObj]="inputObjActivityOrg" (emitData)="getActivityOrgData($event)"></app-select>
  </div>
}
@if (setActivityId && mainForm.get('activityDate').value && showPathwayFlag && !newMember) {
  <div class="col-md-3 col-sm-3 col-xs-12 input-container">
    <app-select class="full-width" [inputObj]="inputObjActivityPathway" (emitData)="getActivityPathwayData($event)"></app-select>
  </div>
}
@if (setActivityId && mainForm.get('activityDate').value && showBargainingUnitFlag && !newMember) {
  <div class="col-md-6 col-sm-6 col-xs-12 input-container">
    <app-select class="full-width" [inputObj]="inputObjActivityBargainingUnit" (emitData)="getActivityBargainingUnitData($event)"></app-select>
  </div>
}
@if (setActivityId && mainForm.get('activityDate').value && showApplicationDate && !newMember) {
  <div class="col-md-3 col-sm-3 col-xs-12 input-container">
    <label>Application Date <sup class="req">*</sup></label>
    <!--        <app-universal-date-picker [selectedValue]="mainForm.get('activityDate').value" (emitData)="mainForm.get('activityDate').setValue($event); activityPreview = false"></app-universal-date-picker>-->
    <p-calendar dateMask  placeholder="mm/dd/yyyy" formControlName="applicationDate" appendTo="body" dateFormat="mm/dd/yy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" [maxDate]="maxActivityDate"
    [styleClass]="'p-mt-2'" (onBlur)="activityPreview = false"></p-calendar>
  </div>
}
@if (setActivityId && showDOB && mainForm.get('activityDate').value && !newMember) {
  <div class="col-md-3 col-sm-3 col-xs-12 input-container">
    <label>DOB <sup class="req">*</sup></label>
    <app-universal-date-picker (emitData)="mainForm.get('dob').setValue($event);" [selectedValue]="mainForm.get('dob').value"
                               [useMinMax]="true" [minValue]="minDate" [maxValue]="maxDate"
                               ></app-universal-date-picker>
</div>
}
@if (setActivityId && mainForm.get('activityDate').value && showPersonProfileTypeFlag && !newMember) {
  <div class="col-md-3 col-sm-3 col-xs-12 input-container">
    <app-select class="full-width" [inputObj]="inputObjActivityType" (emitData)="getActivityTypeData($event)"></app-select>
  </div>
}
@if (setActivityId && mainForm.get('activityDate').value && showPersonProfileClassificationFlag && !newMember) {
  <div class="col-md-3 col-sm-3 col-xs-12 input-container">
    <app-select class="full-width" [inputObj]="inputObjActivityClass" (emitData)="getActivityClassData($event)"></app-select>
  </div>
}
@if (setActivityId && mainForm.get('activityDate').value && !newMember && showPersonProfileDuesGroups) {
  <div class="col-md-3 col-sm-3 col-xs-12 input-container">
    <app-select class="full-width" [inputObj]="inputObjActivityDues" (emitData)="getActivityDuesData($event)"></app-select>
  </div>
}
@if (showWaiveFee && mainForm.get('activityDate').value && !newMember) {
  <div class="col-md-2 col-sm-3 col-xs-12 checkbox-container margin-top-10">
    <p-checkbox formControlName="waiveInitiationFee" binary="true" label=" Waive APF" (onChange)="activityPreview = false"></p-checkbox>
  </div>
}
@if (showSSN  && mainForm.get('activityDate').value) {
  <div class="col-md-3 col-sm-12 col-xs-12 margin-top-10" >
    <div class="p-grid p-fluid">
      <div class="col-md-6 col-sm-12 col-xs-12">
        <label>SSN or SIN</label>
        <p-radioButton [value]=true [styleClass]="'radio-input'" class="p-mr-2" label="SSN" [(ngModel)]="isSSN" [ngModelOptions]="{standalone: true}" (onClick)="updateSSNSIN('ssn')"></p-radioButton>
        <p-radioButton [value]=false [styleClass]="'radio-input'" label="SIN" [(ngModel)]="isSSN" [ngModelOptions]="{standalone: true}" (onClick)="updateSSNSIN('sin')"></p-radioButton>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          @if (isSSN) {
            <div class="col-md-12 col-sm-12 col-xs-12 input-container">
              <label>SSN</label>
              <p-inputMask mask="999-99-9999" placeholder="###-##-####" formControlName="ssn" unmask="true"></p-inputMask>
            </div>
          }
          @if (!isSSN) {
            <div class="col-md-12 col-sm-12 col-xs-12 input-container">
              <label>SIN</label>
              <p-inputMask mask="999 999 999" placeholder="### ### ###" formControlName="sin" unmask="true"></p-inputMask>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}
@if (showSignature) {
  <div class="col-md-12 col-sm-12 col-xs-12" >
    <div class="p-fluid p-grid">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h3>Approved Following with Signature</h3>
      </div>
      @for (attribute of customAttributes; track attribute) {
        @if (attribute.FieldName !== 'Helmets To Hardhats') {
          <div class="col-md-3 col-sm-12 col-xs-12">
            <label>{{attribute.FieldName}}@if (attribute.FieldName === 'Representation') {
              <sup class="req">*</sup>
            }</label>
            <app-yes-no-select [isBoolean]="false" (emitData)="updateAttributes($event, attribute.FieldName); attribute.Value = $event;"
            [selectedValue]="attribute.Value" [nullable]="true"></app-yes-no-select>
          </div>
        }
      }
      <div class="col-md-3 col-sm-12 col-xs-12"></div>
      <div class="col-md-3 col-sm-12 col-xs-12"></div>
      @if (this.mainForm.get('representation').value !== 'Yes'
        && mainForm.get('representation').dirty) {
        <div class="col-md-3 col-sm-12 col-xs-12">
          <span class="error">Member must sign representation section before member can be added/updated.</span>
        </div>
      }
    </div>
  </div>
}
@if (setActivityId) {
  <div class="col-md-12 col-sm-12 col-xs-12 input-container">
    <button pButton label="Preview" class="p-button-info" [className]="'inline-button'" icon="far fa-eye" pTooltip="Preview" tooltipPosition="bottom"
    (click)="previewActivity()" [disabled]="mainForm.invalid"></button>
  </div>
}
<!-- both -->
@if (setActivityId && activityPreview) {
  <div class="col-md-12 col-sm-12 col-xs-12">
    <h3 class="p-mb-2">Per Capita</h3>
  </div>
}
@if (setActivityId && activityPreview) {
  <div class="col-md-12 col-sm-12 col-xs-12">
    <p-table [value]="preview" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th>District Council</th>
          <th>Local Union</th>
          <th>Class</th>
          <th>Activity</th>
          <th>Date</th>
          <th>DC BU</th>
          <th>DC PCT</th>
          <th>DC DBF</th>
          <th>DC APF</th>
          <th>LU BU</th>
          <th>LU PCT</th>
          <th>LU DBF</th>
          <th>LU APF</th>
          <th>RIN</th>
          <th>CARD</th>
          <th>Total</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-preview>
        <tr>
          <td>{{preview.DCOrganizationName}}</td>
          <td>{{preview.LUOrganizationName}}</td>
          <td>{{preview.PersonProfileTypeDescription}}</td>
          <td>{{preview.ActivityDescription}}</td>
          <td>{{preview.ActivityDate | date:'shortDate'}}</td>
          <td [ngClass]="{negative: preview?.DCBillableUnits < 0, blue: preview?.DCBillableUnits > 0}">{{preview.DCBillableUnits}}</td>
          <td [ngClass]="{negative: preview?.DCPCT < 0, positive: preview?.DCPCT > 0}">{{preview.DCPCT | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.DCDeathBenefitFund < 0, positive: preview?.DCDeathBenefitFund > 0}">{{preview.DCDeathBenefitFund | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.DCInitiationFee < 0, positive: preview?.DCInitiationFee > 0}">{{preview.DCInitiationFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.LUBillableUnits < 0, blue: preview?.LUBillableUnits > 0}">{{preview.LUBillableUnits}}</td>
          <td [ngClass]="{negative: preview?.LUPCT < 0, positive: preview?.LUPCT > 0}">{{preview.LUPCT | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.LUDeathBenefitFund < 0, positive: preview?.LUDeathBenefitFund > 0}">{{preview.LUDeathBenefitFund | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.LUInitiationFee < 0, positive: preview?.LUInitiationFee > 0}">{{preview.LUInitiationFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.LUReinstatementFee < 0, positive: preview?.LUReinstatementFee > 0}">{{preview.LUReinstatementFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.LUCardFee < 0, positive: preview?.LUCardFee > 0}">{{preview.LUCardFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.TotalCharged < 0, positive: preview?.TotalCharged > 0}"><strong>{{preview.TotalCharged | currency:'USD':'symbol':'1.2-2'}}</strong></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [colSpan]="5">Totals</td>
          <td>{{previewTotals.DCBillableUnits}}</td>
          <td>{{previewTotals.DCPCT | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.DCDeathBenefitFund | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.DCInitiationFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.LUBillableUnits}}</td>
          <td>{{previewTotals.LUPCT | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.LUDeathBenefitFund | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.LUInitiationFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.LUReinstatementFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.LUCardFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.TotalCharged | currency:'USD':'symbol':'1.2-2'}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
}
@if (setActivityId && activityPreview) {
  <div class="col-md-12 col-sm-12 col-xs-12">
    <h3 class="p-mb-2">Member Dues</h3>
  </div>
}
@if (setActivityId && activityPreview) {
  <div class="col-md-12 col-sm-12 col-xs-12">
    <p-table [value]="preview" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th>District Council</th>
          <th>Local Union</th>
          <th>Class</th>
          <th>Activity</th>
          <th>Date</th>
          <th>BU</th>
          <th>Dues</th>
          <th>Other Charges</th>
          <th>APF</th>
          <th>RIN</th>
          <th>CARD</th>
          <th>Total</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-preview>
        <tr>
          <td>{{preview.DCOrganizationName}}</td>
          <td>{{preview.LUOrganizationName}}</td>
          <td>{{preview.PersonProfileTypeDescription}}</td>
          <td>{{preview.ActivityDescription}}</td>
          <td>{{preview.ActivityDate | date:'shortDate'}}</td>
          <td [ngClass]="{negative: preview?.MemberBillableUnits < 0, blue: preview?.MemberBillableUnits > 0}">{{preview.MemberBillableUnits}}</td>
          <td [ngClass]="{negative: preview?.MemberDues < 0, positive: preview?.MemberDues > 0}">{{preview.MemberDues | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.MemberOtherCharges < 0, positive: preview?.MemberOtherCharges > 0}">{{preview.MemberOtherCharges | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.MemberInitiationFee < 0, positive: preview?.MemberInitiationFee > 0}">{{preview.MemberInitiationFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.MemberReinstatementFee < 0, positive: preview?.MemberReinstatementFee > 0}">{{preview.MemberReinstatementFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.MemberCardFee < 0, positive: preview?.MemberCardFee > 0}">{{preview.MemberCardFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td [ngClass]="{negative: preview?.TotalChargedMembers < 0, positive: preview?.TotalChargedMembers > 0}"><strong>{{preview.TotalChargedMembers | currency:'USD':'symbol':'1.2-2'}}</strong></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [colSpan]="5">Totals</td>
          <td>{{previewTotals.MemberBillableUnits}}</td>
          <td>{{previewTotals.MemberDues | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.MemberOtherCharges | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.MemberInitiationFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.MemberReinstatementFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.MemberCardFee | currency:'USD':'symbol':'1.2-2'}}</td>
          <td>{{previewTotals.TotalChargedMembers | currency:'USD':'symbol':'1.2-2'}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
}
</div>
</div>
@if (setActivityId && activityPreview && !newMember) {
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15 text-align-right">
    <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button p-mr-2'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom" (click)="resetActivity(true);this.ref.destroy();"></button>
    @if (!isSaving) {
      <button pButton label="Submit" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Submit" tooltipPosition="bottom" [className]="'inline-button'" [disabled]="mainForm.invalid" (click)="updateRosterCustomeAttributes()"></button>
    } @else {
      <button pButton label="Submit" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Submit" tooltipPosition="bottom" [className]="'inline-button'"  [disabled]="true">
        <i class="pi pi-spin pi-spinner save-spinner"></i>
      </button>
    }
  </div>
}
</form>
