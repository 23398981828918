<form [formGroup]="mainForm">
  <div class="p-grid p-fluid">
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <b>{{config.data.type}} Hours Summarized by Facet</b>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <p-table
        #dt1
        [rows]="25"
        [columns]="cols"
        dataKey="SubjectAreaID"
        [value]="programHours?.Facets"
        [lazy]="true"
        (onLazyLoad)="loadTable($event)"
        [paginator]="true"
        [showCurrentPageReport]="true"
        [metaKeySelection]="false"
        [totalRecords]="dataTotal"
        [rowsPerPageOptions]="[25,50,100,250,500]"
        [scrollable]="true"
        selectionMode="single"
        rowExpandMode="single"
        scrollHeight="400px"
        paginatorDropdownAppendTo="body"
        [loading]="loading"
        styleClass="p-datatable-gridlines p-datatable-striped"
        currentPageReportTemplate="Showing {first} to {last} of {{dataTotal}} entries"
        >
        <ng-template pTemplate="header" let-columns>
          <tr>
            @for (col of columns; track col) {
              <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
                <div class="header-text">
                {{col.header}}</div>
              </th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            @for (col of columns; track col) {
              <td>
                {{rowData[col.field]}}
              </td>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td [colSpan]="cols?.length -3"><b>Last Recorded Work Day:</b> {{programHours?.LastRecordedInterval | date}}</td>
            <td> Total: {{totalHours | number : '1.2-2'}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [colSpan]="cols?.length" style="width: 100%;">No hours found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</form>
