<!--<span class="fa fa-spinner list-spinner" *ngIf="loading"></span>-->
<form [formGroup]="mainForm" class="margin-top-10 ">
  <p-divider class="dark-divider"></p-divider>
  <div class="p-grid p-fluid">
    @if (!isEdit) {
      <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
        <label>Search by Student Name</label>
        <span class="p-input-icon-left full-width">
          <i class="pi pi-search"></i>
          <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter"
                 (keyup.enter)="filterItems()"
                 [ngModelOptions]="{standalone: true}" placeholder="Search Student" (focusin)="focusIn('name')"
                 tabindex="0"/>
          </span>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
        <label>Search by Member ID</label>
        <span class="p-input-icon-left full-width">
          <i class="pi pi-search"></i>
          <input class="full-width filter" pInputText type="text" [(ngModel)]="memberID" (keyup.enter)="filterItems()"
                 [ngModelOptions]="{standalone: true}" placeholder="Search Member ID" (focusin)="focusIn('id')"
                 tabindex="0"/>
        </span>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
        <app-select class="full-width" [inputObj]="inputObjStudentStatus"
                    (emitData)="getStudentStatusData($event)"></app-select>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
        <app-select class="full-width" [inputObj]="inputObjStudentGroup"
                    (emitData)="getStudentGroupData($event)"></app-select>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
        <app-select class="full-width" [inputObj]="inputObjProgram" (emitData)="getProgramData($event)"></app-select>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
        <app-select class="full-width" [inputObj]="inputObjRateLevel"
                    (emitData)="getRateLevelData($event)"></app-select>
      </div>
    }
    @if (!isEdit && (activeGlobalFilter || setStudentGroupId || memberID || setProgramId || setStudentStatusDesc)) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <div class="p-grid p-fluid">
          <div class="col-md-4 col-sm-4 col-xs-12 margin-top-15">
            <button pButton label="Get Students" class="p-button-info p-mr-2" [className]="'inline-button'"
                    icon="pi pi-search" pTooltip="Get Student Roster" tooltipPosition="bottom"
                    (click)="filterItems()"></button>
            @if (globalRoster) {
              <button pButton label="Get Global Students" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-globe" pTooltip="Get Global Student Roster" tooltipPosition="bottom"
                      (click)="filterGlobalItems()"></button>
            }
          </div>
          @if (selStudent.length > 0) {
            <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
              <app-select class="full-width" [inputObj]="inputObjTrainingStatus"
                          (emitData)="getTrainingStatus($event)"></app-select>
            </div>
          }
          @if (selStudent.length > 0) {
            <div class="col-md-4 col-sm-3 col-xs-12 checkbox-container">
              <p-checkbox binary="true" label="Send Registration Email" class="margin-top-10"
                          formControlName="sendEmail" tabindex="0"></p-checkbox>
            </div>
          }
        </div>
      </div>
    }
  </div>
  @if (showTable) {
    <div class="p-grid p-fluid parent-form margin-top-10">
      <p-table #dt1
               [rows]="pageRowCountUtility.pageRowCount()"
               [columns]="selectedColumns"
               dataKey="PersonID"
               [value]="studentPickerList"
               [lazy]="true"
               (onLazyLoad)="loadTable($event)"
               [paginator]="true"
               [showCurrentPageReport]="true"
               [metaKeySelection]="false"
               [totalRecords]="dataTotal"
               [rowsPerPageOptions]="[25,50,100,250,500]"
               [scrollable]="true"
               [(selection)]="selStudent"
               selectionMode="multiple"
               scrollHeight="400px"
               paginatorDropdownAppendTo="body"
               [loading]="loading"
               styleClass="p-datatable-responsive-demo p-datatable-striped inner-table"
               class="full-width"
               currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 50px">
              <p-tableHeaderCheckbox class="p-pl-2" tabindex="0"></p-tableHeaderCheckbox>
            </th>
            @for (col of columns; track col) {
              <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom"
                  style="width: 125px">
                @if (col.visible) {
                  <div class="header-text">
                    {{ col.header }}
                  </div>
                }
              </th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td style="width:50px">
              <p-tableCheckbox [value]="rowData" tabindex="0"></p-tableCheckbox>
            </td>
            @for (col of columns; track col) {
              <td style="width: 125px" class="relative">
                @if (col.visible) {
                  @if (col.field === 'LastName' && isStatusInActive(rowData.MemberStatusAlias)) {
                    <div class="inactive-flag"></div>
                  }
                  {{ rowData[col.field] }}
                }
              </td>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [colSpan]="cols?.length" style="width: 100%;">No students found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }
  @if (isEdit && !loading) {
    <div class="p-grid p-fluid parent-form">
      <div class="col-xs-12 margin-top-10"
           [ngClass]="{'col-md-3 col-sm-4' : isModule, 'col-md-2 col-sm-2' : !isModule}">
        <app-select class="full-width" [inputObj]="inputObjTrainingStatus"
                    (emitData)="getTrainingStatus($event)"></app-select>
      </div>
      <div class="col-xs-12 margin-top-10"
           [ngClass]="{'col-md-3 col-sm-4' : isModule, 'col-md-2 col-sm-2' : !isModule}">
        <label>Date@if (this.mainForm.get('certification').status === 'VALID') {
          <sup class="req">*</sup>
        }</label>
        <app-universal-date-picker [selectedValue]="mainForm.get('dt').value"
                                   (emitData)="mainForm.get('dt').setValue($event)"></app-universal-date-picker>
      </div>
      @if (isModule) {
        <div class="col-md-3 col-sm-2 col-xs-6 margin-top-10">
          <label>Module Grade Average</label>
          <p-inputNumber formControlName="gradeAverage" [showButtons]="true" suffix="%" tabindex="0"></p-inputNumber>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 margin-top-10">
          <app-select class="full-width" [inputObj]="inputObjGrades" (emitData)="getGradeData($event)"></app-select>
        </div>
      } @else {
        <div class="col-md-2 col-sm-2 col-xs-6 input-container margin-top-5">
          <label>Class Grade Average</label>
          <p-inputNumber formControlName="grade" [showButtons]="true" suffix="%" tabindex="0" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-6 margin-top-10">
          <app-select class="full-width" [inputObj]="inputObjGrades" (emitData)="getGradeData($event)"></app-select>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-6 margin-top-10">
          <label>Retraining Date</label>
          <app-universal-date-picker [selectedValue]="mainForm.get('expDate').value"
                                     (emitData)="mainForm.get('expDate').setValue($event);mainForm.markAsDirty();"></app-universal-date-picker>
        </div>
        <div class="col-md-2 col-sm-4 col-xs-6 margin-top-10">
          <label>Training ID</label>
          <input type="text" pInputText formControlName="trainingIdentifier">
        </div>
      }
      @if (showCertificationFields) {
        <div class="col-md-12 col-sm-12 col-xs-12">
          <h3>Certification Details</h3>
          <div class="p-grid p-fluid">
            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 input-container">
              <label>Certification #</label>
              <input type="text" pInputText formControlName="certification">
            </div>
            <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 input-container">
              <label>Certificate Issue Date <sup class="req">*</sup></label>
              <app-universal-date-picker [selectedValue]="mainForm.get('certIssueDate').value"
                                         (emitData)="mainForm.get('certIssueDate').setValue($event)"
                                         [disabled]="certIssueDateDisabled"></app-universal-date-picker>
            </div>
            <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 input-container">
              <label>Certificate Expiration Date @if (mainForm.controls.noExpirationDate.value === false) {
                <sup class="req">*</sup>
              }</label>
              <app-universal-date-picker [selectedValue]="mainForm.get('certExpirationDate').value"
                                         (emitData)="mainForm.get('certExpirationDate').setValue($event)"
                                         [disabled]="certExpirationDateDisabled"></app-universal-date-picker>
              @if ((mainForm.controls.certExpirationDate.dirty || mainForm.controls.certExpirationDate.touched) &&
              mainForm.controls.certExpirationDate.errors?.required) {
                <span class="error">'Certification Expiration Date' must be provided</span>
              }
              <p-checkbox formControlName="noExpirationDate" label="No Expiration date" [binary]="true" class="p-mt-2"
                          (onChange)="toggleExpirationDate($event)" tabindex="0"></p-checkbox>
            </div>
            @if (canTabWrite && currentRecord.CanDelete && currentRecord.CertificationIssueDate) {
              <div class="col-md-2 col-sm-2 col-xs-12 margin-top-10">
                <p-button class="p-button-info add-display-flex margin-top-25" pTooltip="Remove Certification" tooltipPosition="bottom" icon="fas fa-trash"
                          (click)="removeCert(currentRecord.CertificationSharableID)" label="Remove Certification"></p-button>
              </div>
            }
            @if (currentRecord.CertificationExternalUrl) {
              <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
                <a href="{{currentRecord.CertificationExternalUrl}}" target="_blank">Certificate</a> (TPC)
              </div>
            }
            @if (certificationResource) {
              <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
                <button pButton (click)="removeResource()" icon="pi pi-times" pTooltip="Remove" tooltipPosition="bottom"
                        class="p-button-info p-mr-2 p-mb-2"></button>
                <button pButton (click)="openResource(currentRecord.CertificationResourceReferenceID)" icon="pi pi-file"
                        pTooltip="Open" tooltipPosition="bottom" class="p-button-info p-mr-2 p-mb-2"></button>
                <div class="inline-button-text">Certificate</div>
              </div>
            } @else {
              <div class="col-md-12 col-sm-12 col-xs-12 input-container p-button-auto-width">
                <p>*Files must be 30 MB or less in size. If necessary, change the name of your files appropriately prior
                  to uploading them. Upload one file at a time.</p>
                <p-fileUpload #fileUploader name="uploadedFiles[]" (onSelect)="onUpload($event)"
                              (onClear)="clearUpload()" accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.ppt,.pptx"
                              [showUploadButton]="false" maxFileSize="31457280" tabindex="0">
                  <ng-template let-file pTemplate='file'>
                    <div>
                      {{ file.name }} - ({{ file.size }} bytes)
                    </div>
                  </ng-template>
                </p-fileUpload>
              </div>
            }
            @if (showProgress) {
              <div class="col-md-12">
                <p-progressBar mode="indeterminate"></p-progressBar>
              </div>
            }
          </div>
        </div>
      }
    </div>
  }
  @if (canTabWrite) {
    <div class="p-grid p-fluid margin-top-10">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12"></div>
          <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
            @if (isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                      (click)="cancel()" [disabled]="!this.mainForm.dirty"></button>
            } @else {
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                      (click)="cancel()"></button>
            }
            @if (!isSaving && isEdit) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                      tooltipPosition="bottom"
                      (click)="processData()" [className]="'inline-button'"
                      [disabled]="this.mainForm.invalid || !this.mainForm.dirty"></button>
            }
            @if (isSaving && isEdit) {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
            @if (!isSaving && !isEdit) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                      tooltipPosition="bottom"
                      (click)="processData()" [className]="'inline-button'"
                      [disabled]="selStudent.length<=0 || !setTrainingStatusId"></button>
            }
            @if (isSaving && !isEdit) {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  }
</form>
