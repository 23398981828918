import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ContextService} from '../../../../../services/context.service';
import {LookupModel} from '../../../../../models/lookups.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {LookupsService} from '../../../../../services/lookups.service';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {TradeSkillsService} from '../../../../../services/trade-skills.service';
import {CreatePrimaryTradeModel, TradeSkillModel, UpdatePrimaryTradeModel, UpdateSkillListModel} from '../../../../../models/trade-skills.model';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-edit-trade-skill-dialog',
  templateUrl: './edit-trades-skills-dialog.component.html',
  styleUrls: ['./edit-trades-skills-dialog.component.scss']
})

export class EditTradesSkillsDialogComponent implements OnInit, OnDestroy {
  tradesList: LookupModel;
  inputObjTrades: GenericSelectModel;
  inputObjTradeSkills: GenericSelectModel;
  availableSkills: LookupModel[] = [];
  selectedSkills: LookupModel[] = [];
  tradeSkills: TradeSkillModel[] = [];
  existingTradeSkills: TradeSkillModel[] = [];
  setTradeId: number;
  setPersonTradeId: number;
  setOrganizationTradeId: number;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, private lookupsDataService: LookupsDataService,
              private contextService: ContextService, public ref: DynamicDialogRef,
              private lookupsService: LookupsService, public tradeSkillsService: TradeSkillsService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.getExistingTradeSkills();
    this.initTrades(false);
  }

  getExistingTradeSkills(): void {
    if (this.config.data.DBEntity === 'Profile') {
      this.tradeSkillsService.getTradeSkillsListByPersonId(this.config.data.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.tradeSkills = res;
          }
        });
    } else {
      this.tradeSkillsService.getTradeSkillsListByOrganizationId(this.config.data.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.tradeSkills = res;
          }
        });
    }
  }

  initTrades(disable: boolean): void {
    this.inputObjTrades = {
      labelText: 'Available Trades',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: true,
      emitChangeOnLoad: false
    };
    this.lookupsDataService.getTradesLookupData().then((lookupData) => {
      this.inputObjTrades.data = lookupData;
      this.inputObjTrades = Object.assign({}, this.inputObjTrades);
    });
  }

  getTradesData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.setTradeId = event[0].ID;
    } else {
      this.setTradeId = 0;
    }
    if (this.setTradeId !== 0) {
      this.getTradeSkills();
    } else {
      this.availableSkills = [];
      this.selectedSkills = [];
    }
  }

  getTradeSkills(): void {
    this.lookupsService.getTradeSkills(this.setTradeId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.availableSkills = res;
          this.getExistingSkillsOnly();
        }
      });
  }

  getExistingSkillsOnly(): void {
    this.selectedSkills = [];
    if (this.tradeSkills.length > 0) {
      const trade: TradeSkillModel[] = this.tradeSkills.filter(x => x.TradeID === this.setTradeId);
      if (trade.length > 0) {
        trade[0].SkillList.forEach(skill => {
          this.selectedSkills.push({
            ID: skill.SkillID,
            Description: skill.SkillDescription,
          });
        });
        this.availableSkills = this.availableSkills.filter(ar => !this.selectedSkills.find(rm => (rm.ID === ar.ID)));
      }
    }
  }

  processData(): void {
    if (this.tradeSkills.filter(trade => trade.TradeID === this.setTradeId).length > 0) {
      this.updateSkills();
    } else {
      this.saveNewTradeSkills();
    }
  }

  saveNewTradeSkills(): void {
    const formattedSkills: UpdateSkillListModel[] = [];
    this.selectedSkills.forEach(skill => {
      formattedSkills.push({
        SkillID: skill.ID,
        Primary: !!skill[0]
      });
    });
    //For person trade
    if (this.config.data.DBEntity === 'Profile') {
      const newTradeSkill: CreatePrimaryTradeModel = {
        TradeID: this.setTradeId,
        PersonID: this.config.data.id,
        Primary: false,
        SkillList: formattedSkills
      };
      this.tradeSkillsService.createPersonTrade(this.config.data.id, newTradeSkill)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your Primary Trade/Skill was updated'
            });
            this.closeDialog();
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        });
    } else {
      //for employer/org trade
      const newTradeSkill: CreatePrimaryTradeModel = {
        TradeID: this.setTradeId,
        OrganizationID: this.config.data.id,
        Primary: false,
        SkillList: formattedSkills
      };
      this.tradeSkillsService.createOrganizationIdTrade(this.config.data.id, newTradeSkill)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your Primary Trade/Skill was updated'
            });
            this.closeDialog();
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        });
    }
  }

  updateSkills(): void {
    const selectedTrade = this.tradeSkills.filter(x => x.TradeID === this.setTradeId);
    if (this.config.data.DBEntity === 'Profile') {
      this.setPersonTradeId = selectedTrade[0].PersonTradeID;
    } else {
      this.setOrganizationTradeId = selectedTrade[0].OrganizationTradeID;
    }
    let formattedTrade: UpdatePrimaryTradeModel = null;
    const formattedSkills: UpdateSkillListModel[] = [];
    // if (this.selectedSkills.length > 0) {
    this.selectedSkills.forEach(skill => {
      formattedSkills.push({
        SkillID: skill.ID,
        Primary: false
      });
    });
    formattedTrade = {
      Primary: selectedTrade[0].Primary,
      SkillList: formattedSkills,
    };
    if (this.config.data.DBEntity === 'Profile') {
      this.tradeSkillsService.updatePersonTrade(this.config.data.id, this.setPersonTradeId, formattedTrade, true)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your Primary Trade/Skill was updated'
            });
            this.closeDialog();
          }, error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
          }
        });
    } else {
      this.tradeSkillsService.updateOrganizationIdTrade(this.config.data.id, this.setOrganizationTradeId, formattedTrade)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your Primary Trade/Skill was updated'
            });
            this.closeDialog();
          }, error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  closeDialog(): void {
    this.ref.destroy();
  }
}
