import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormArray, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {ContextService} from '../../../../../services/context.service';
import {AttributeModel, AttributesGridModel, UpdateAttributeModel} from '../../../../../models/attributes.model';
import {AttributesService} from '../../../../../services/attributes.service';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';

@Component({
  selector: 'app-organization-attributes-form',
  templateUrl: './organization-attributes-form.component.html',
  styleUrls: ['./organization-attributes-form.component.scss']
})
export class OrganizationAttributesFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  attribute = {} as AttributeModel;
  attributes: AttributesGridModel;
  attributesList: AttributeModel[];
  loading: boolean = false;
  saveAttribute = {} as UpdateAttributeModel;
  inputObjAttributeDataTypeByEntity: GenericSelectModel;
  setAttributeDataTypeByEntityId: number;
  isSaving: boolean;
  selectedType: string;
  showShare: boolean = false;
  existingMessage: boolean = false;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() attributeID: number;
  @Input() OrgRefID: number;
  @Input() ResponseDBEntityEnumeratorOptions: boolean;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService,
              private attributesService: AttributesService,
              private lookupsDataService: LookupsDataService,
              private confirmationService: ConfirmationService,
              private contextService: ContextService
  ) {
    this.mainForm = this.formBuilder.group({
      ResponseDBEntityEnumerator: new FormControl(null),
      description: new FormControl(null, Validators.required),
      AttributeDataTypeByEntity: new FormControl(null, Validators.required),
      options: new UntypedFormArray([]),
      share: new FormControl(false)
    });
  }

  get options() {
    return this.mainForm.controls.options as UntypedFormArray;
  }

  ngOnInit(): void {
    if (this.ResponseDBEntityEnumeratorOptions && !this.isEdit) {
      this.mainForm.get('ResponseDBEntityEnumerator').setValue('PERSON');
      this.showShare = true;
      this.mainForm.get('share').setValue(!this.showShare);
    }
    this.mainForm.get('AttributeDataTypeByEntity').valueChanges.subscribe({
      next: (value) => {
        if (this.inputObjAttributeDataTypeByEntity && this.inputObjAttributeDataTypeByEntity.data) {
          this.selectedType = this.inputObjAttributeDataTypeByEntity.data.find(x => x.ID === value).Description;
        }
      }
    });
    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    this.initForm(this.isEdit);
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Custom Field data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  addOption(id: number, desc: string) {
    const attributeForm = this.formBuilder.group({
      ID: new FormControl(id),
      Description: new FormControl(desc, Validators.required),
    });
    this.options.push(attributeForm);
    this.mainForm.markAsDirty();
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  removeOption(i: number) {
    this.options.removeAt(i);
    this.mainForm.markAsDirty();
  }

  initForm(isEdit: boolean): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.loading = true;
    if (isEdit) {
      this.selectedType = null;
      this.mainForm.controls.options = new UntypedFormArray([]);
      this.attributesService.getAttribute(this.attributeID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.attribute = res;
            this.showShare = this.attribute.ResponseDBEntityEnumerator === 'PERSON' && this.ResponseDBEntityEnumeratorOptions;
            this.mainForm.get('description').setValue(this.attribute.Description);
            this.setAttributeDataTypeByEntityId = this.attribute.DataTypeID;
            this.mainForm.get('AttributeDataTypeByEntity').setValue(this.setAttributeDataTypeByEntityId);
            this.initAttributeDataTypeByEntity(true);
            this.selectedType = this.attribute.DataTypeDescription;
            this.mainForm.get('share').setValue(this.attribute.Shared);
            if (this.attribute.Options && this.attribute.Options.length > 0) {
              this.attribute.Options.forEach(x => {
                this.addOption(x.ID, x.Description);
              });
              this.mainForm.markAsPristine();
              this.mainForm.markAsUntouched();
            }
            this.loading = false;
          }
        });
    } else {
      this.initAttributeDataTypeByEntity(false);
      this.loading = false;
    }
  }

  setResponseDBEntityEnumerator(): void {
    this.mainForm.get('share').setValue(false);
    this.showShare = this.mainForm.get('ResponseDBEntityEnumerator').value !== 'ORGREFERENCE';
  }

  initAttributeDataTypeByEntity(disable: boolean): void {
    this.inputObjAttributeDataTypeByEntity = {
      labelText: 'Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setAttributeDataTypeByEntityId,
      disabled: disable,
      customSelect: 'AttributeDataType'
    };
    this.lookupsDataService.getAttributeDataTypeByEntityLookupData('orgreference').then((lookupData) => {
      this.inputObjAttributeDataTypeByEntity.data = lookupData;
      this.inputObjAttributeDataTypeByEntity = Object.assign({}, this.inputObjAttributeDataTypeByEntity);
    });
  }

  getAttributeDataTypeByEntityData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setAttributeDataTypeByEntityId = event[0].ID;
    } else {
      this.setAttributeDataTypeByEntityId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('AttributeDataTypeByEntity').setValue(this.setAttributeDataTypeByEntityId);
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.saveAttribute.Description = this.mainForm.get('description').value;
      this.saveAttribute.DataTypeID = this.mainForm.get('AttributeDataTypeByEntity').value;
      this.saveAttribute.Options = this.mainForm.get('options').value;
      this.saveAttribute.Shared = this.mainForm.get('share').value;
      if (!this.isEdit && this.ResponseDBEntityEnumeratorOptions) {
        this.saveAttribute.ResponseDBEntityEnumerator = this.mainForm.get('ResponseDBEntityEnumerator').value;
      }
      this.saveForm();
    } else {
      this.messageService.add({severity: 'warn', summary: 'Missing Data', detail: 'No data was saved, the form is currently incomplete.'});
      this.existingMessage = false;
    }
  }

  saveForm(): void {
    if (!this.isEdit) {
      this.attributesService.createOrganizationReferenceAttribute(this.OrgRefID, this.saveAttribute)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'The attribute has been added.'
            });
            this.isSaving = false;
            this.closeAddEdit.emit(true);
          }
        });
    } else {
      this.attributesService.updateAttribute(this.attributeID, this.saveAttribute)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.confirmationService.close();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'The attribute has been updated.'
            });
            this.isSaving = false;
            this.existingMessage = false;
            this.closeAddEdit.emit(true);
            //this.initForm(this.isEdit);
            //this.mainForm.markAsPristine();
          }
        });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm(this.isEdit);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }
}
