@if (isPage && !isDialog) {
  <div class="p-grid">
    <div class="col-md-3 col-sm-3 col-sm-3">
      <button pButton label="Back" (click)="back()" class="p-button-info" [className]="'back-button'"
              icon="pi pi-angle-left" pTooltip="Back" tooltipPosition="bottom"></button>
    </div>
  </div>
}
<div class="content p-input-filled margin-top-10">
  @if (loading) {
    <span class="fa fa-spinner list-spinner"></span>
  }
  <form [formGroup]="mainForm">
    <div class="p-grid p-fluid">
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <p>Create multiple classes at once. Simply define the class and the frequency of recurrence, and the rest will
          take care of itself.</p>
        <h3>Recurring Class Details</h3>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 input-container">
        <app-select (emitData)="getOrganizationData($event)" [inputObj]="inputObjOrganization"
                    class="full-width"></app-select>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 input-container">
        <app-select (emitData)="getCourseData($event)" [inputObj]="inputObjCourse" class="full-width"></app-select>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 input-container">
        <label>Class #</label>
        <input formControlName="classidentifier" pInputText type="text">
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 input-container">
        <app-select (emitData)="getStatusData($event)" [inputObj]="inputObjStatus" class="full-width"></app-select>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 input-container">
        <label>Max # of Seats <sup class="req">*</sup></label>
        <p-inputNumber [showButtons]="true" formControlName="seats" inputId="stacked" min="0"></p-inputNumber>
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 input-container">
        <label>Start Date <sup class="req">*</sup></label>
        <p-calendar (onInput)="loadTimezoneSelect()" (onSelect)="loadTimezoneSelect()" [minDate]="mainForm.controls.endDate.value" [showIcon]="true" [yearNavigator]="true"
                    appendTo="body" dateFormat="mm/dd/yy" dateMask
                    formControlName="startDate" placeholder="mm/dd/yyyy"
                    yearRange="1950:2025"></p-calendar>
        @if ((mainForm.controls.startDate.dirty || mainForm.controls.startDate.touched) &&
        mainForm.controls.startDate.errors?.required) {
          <span class="error">'Start Date' must be provided</span>
        }
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 input-container">
        <label>Start Time <sup class="req">*</sup></label>
        <p-calendar [showIcon]="true" [timeOnly]="true" appendTo="body" formControlName="startTime" hourFormat="12"
                    icon="pi pi-clock" showTime="true"></p-calendar>
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 input-container">
        <label>End Time <sup class="req">*</sup></label>
        <p-calendar [showIcon]="true" [timeOnly]="true" appendTo="body" formControlName="endTime" hourFormat="12"
                    icon="pi pi-clock" showTime="true"></p-calendar>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 input-container">
        <app-select (emitData)="getTimezoneData($event)" [inputObj]="inputObjTimezone" class="full-width"></app-select>
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 input-container">
        <app-select (emitData)="getLanguageData($event)" [inputObj]="inputObjLanguage" class="full-width"></app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <p-divider class="dark-divider"></p-divider>
        <h3>Recurrence Details</h3>
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 input-container">
        <app-select (emitData)="getRecurrenceTypeData($event)" [inputObj]="inputObjRecurrenceType"
                    class="full-width"></app-select>
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 input-container">
        <app-select (emitData)="getRecurrenceEndTypeData($event)" [inputObj]="inputObjRecurrenceEndType"
                    class="full-width"></app-select>
      </div>
      @if (endTypeDate) {
        <div class="col-md-3 col-sm-12 col-xs-12 input-container">
          <label>Recurrence End Date <sup class="req">*</sup></label>
          <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="endTypeDate" appendTo="body"
                      placeholder="mm/dd/yyyy" [showIcon]="true" [minDate]="mainForm.controls.startDate.value"
                      [yearNavigator]="true" yearRange="1950:2030"></p-calendar>
          @if ((mainForm.controls.endTypeDate.dirty || mainForm.controls.endTypeDate.touched) &&
          mainForm.controls.endTypeDate.errors?.required) {
            <span class="error">'Recurrence End Date' must be provided</span>
          }
        </div>
      }
      @if (endTypeCount) {
        <div class="col-md-3 col-sm-12 col-xs-12 input-container">
          <label># of Occurrences <sup class="req">*</sup></label>
          <p-inputNumber formControlName="endTypeCount" [showButtons]="true" inputId="stacked" min="0"></p-inputNumber>
          @if ((mainForm.controls.endTypeCount.dirty || mainForm.controls.endTypeCount.touched) &&
          mainForm.controls.endTypeCount.errors?.required) {
            <span class="error">'# of Occurrences' must be provided</span>
          }
        </div>
      }
      @if (weekly) {
        <div class="col-md-9 col-sm-12 col-xs-12 input-container form-checkbox">
          <div class="p-grid p-fluid">
            @for (day of weeklyData; track day; let i = $index) {
              <div class="col-md-2 col-sm-3 col-xs-4 input-container">
                <p-checkbox [value]="day" (onChange)="updateSelectedWeekly($event, i, day)" [label]="day"></p-checkbox>
              </div>
            }
          </div>
        </div>
      }
      @if (monthly) {
        <div class="col-md-9 col-sm-12 col-xs-12 input-container">
          <div class="p-grid p-fluid">
            <div class="col-md-4 col-sm-6 col-xs-12 input-container">
              <label>Select Type<sup class="req">*</sup></label>
              <p-dropdown [styleClass]="'full-width'" [options]="monthlyTypes" appendTo="body" class="custom-dropdown"
                          [(ngModel)]="selectedMonthlyType" optionLabel="label" optionValue="option"
                          [ngModelOptions]="{standalone: true}"></p-dropdown>
            </div>
            @if (selectedMonthlyType === 'daysOfMonth') {
              <div class="col-md-4 col-sm-6 col-xs-12 input-container">
                <label>Recurring Every<sup class="req">*</sup></label>
                <p-dropdown [styleClass]="'full-width'" [options]="nthTypes" appendTo="body" class="custom-dropdown"
                            [(ngModel)]="selectedNthType" optionLabel="label" optionValue="option"
                            [ngModelOptions]="{standalone: true}"></p-dropdown>
              </div>
            }
            <div class="col-md-12 col-sm-12 col-xs-12 input-container">
              <div class="p-grid p-fluid checkbox-options">
                @if (monthly && selectedMonthlyType === 'datesOfMonth') {
                  @for (day of monthlyData; track day; let i = $index) {
                    <div class="col-md-2 col-sm-2 col-xs-3 input-container">
                      <p-checkbox [value]="day" (onChange)="updateSelectedMonthly($event, i, day)"
                                  [label]=" day"></p-checkbox>
                    </div>
                  }
                }
                @if (monthly && selectedMonthlyType === 'daysOfMonth') {
                  @for (day of weeklyData; track day; let i = $index) {
                    <div class="col-md-3 col-sm-3 col-xs-4 input-container">
                      <p-checkbox [value]="day" (onChange)="updateSelectedWeekly($event, i, day)"
                                  [label]=" day"></p-checkbox>
                    </div>
                  }
                }
              </div>
            </div>
          </div>
        </div>
      }

      @if (canPageWrite) {
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="p-grid p-fluid">
            <div class="col-md-6 col-sm-6 col-xs-12"></div>
            <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
              @if (isEdit) {
                <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                        icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                        (click)="cancel()" [disabled]="!mainForm.dirty"></button>
              } @else {
                <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'"
                        icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                        (click)="cancel()"></button>
              }
              <button pButton label="Review & Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                      tooltipPosition="bottom"
                      (click)="processData()" [className]="'inline-button'"
                      [disabled]="this.mainForm.invalid || !mainForm.dirty || (setRecurrenceType !== 'Daily' &&  weeklySelected.length === 0 && monthlySelected.length === 0)"></button>
            </div>
          </div>
        </div>
      }
    </div>
  </form>
</div>
