import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {StudentProfileService} from '../../../../services/student-profile.service';
import {StudentProfileModel} from '../../../../models/student-profile.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {StudentProgramsService} from '../../../../services/student-programs.service';
import {StudentProgramGridModel, StudentProgramsGridModel} from '../../../../models/student-programs.model';
import {DialogUtility} from '../../../../shared/utilities/dialog.utility';
import {removeKeysFromArray} from '../../../../shared/utilities/data.utility';
import {exportData} from '../../../../shared/utilities/list-table.utility';
import {PageRowCountUtility} from '../../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-roster-student-programs',
  templateUrl: './roster-student-programs.component.html',
  styleUrls: ['./roster-student-programs.component.scss']
})

export class RosterStudentProgramsSubTabComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  studentInfo: StudentProfileModel;
  isDirty: boolean;
  isSaving: boolean = false;
  updatedStudentInfo: StudentProfileModel;
  studentPrograms: StudentProgramsGridModel;
  studentProgramsList: StudentProgramGridModel[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  showAddEdit: boolean;
  dataLoaded: boolean;
  totalLoaded: boolean;
  isExporting: boolean;
  showData: boolean = false;
  selectedItem: number;
  dataTotal: number = 0;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() isPage: boolean;
  @Input() PersonID: number;
  @Output() resetList = new EventEmitter<boolean>();
  @Input() canAdd: boolean;
  ProgramStudentID: number;
  getListRequest;
  getTotalRequest;
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private studentProfileService: StudentProfileService, private ref: DynamicDialogRef,
              private messageService: MessageService, private studentProgramsService: StudentProgramsService,
              public dialogService: DialogService, private dialogUtility: DialogUtility,
              public pageRowCountUtility: PageRowCountUtility
  ) {
    this.mainForm = this.formBuilder.group({
      studentNumber: new FormControl(''),
      memberNumber: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    if (changes.PersonID && this.PersonID) {
      this.getStudentInfo();
    }
  }

  getStudentInfo() {
    this.studentProfileService.getStudentProfile(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.studentInfo = res;
          this.updatedStudentInfo = res;
          this.getPrograms();
        }
      });
  }

  getPrograms() {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.studentProgramsList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.studentProgramsService.getStudentProgramsPrimeNG('DATA', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.studentPrograms = res;
          this.studentProgramsList = res.Data;
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.studentProgramsService.getStudentProgramsPrimeNG('TOTAL', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          if (this.canAdd && this.dataTotal <= 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  openProgram(ProgramID) {
    // const selectedCourse = this.studentProgramsList.find(x => x.ProgramID === ProgramID);
    // const ref = this.dialogService.open(ProgramTabContainerComponent, {
    //   data: {
    //     id: ProgramID,
    //     isEdit: true,
    //     isDialog: true
    //   },
    //   header: selectedCourse.ProgramDescription,
    //   width: '90%',
    //   height: '70%'
    // });
    console.log(ProgramID);
  }

  expandData(index, id) {
    this.showData = !this.showData;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  paginate(event:any) {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getPrograms();
  }

  refresh() {
    this.getPrograms();
  }

  goToEdit(event:any) {
    sessionStorage.setItem('sessionViewType', 'Grid');

  }

  deleteRecord(programStudentId) {
    this.dialogUtility.promptToDelete(() => {
      this.studentProgramsService.deleteStudentProgram(programStudentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected program has been removed.'
            });
            this.updateList();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList() {
    this.showAddEdit = false;
    this.getPrograms();
    this.selectedItem = -1;
  }

  cancel() {
    this.ref.close();
    this.showAddEdit = false;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.studentProgramsList));
        const dataToExport = removeKeysFromArray(arr, []);
        exportData('student-programs', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.studentProgramsService.getStudentProgramsPrimeNG('DATA', this.PersonID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, []);
              exportData('student-programs', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
