import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

import {GenericSelectModel} from '../../models/generic-select.model';
import {LookupsDataService} from '../../services/lookups-data.service';
import {ContextService} from '../../services/context.service';
import {ActivatedRoute} from '@angular/router';
import {PageService} from '../../services/page.services';
import {PrimeTableFilterModel} from '../../models/table-filter.model';
import {PageModel} from '../../models/page.model';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {transformDateTimeToDateOnly} from '../../shared/utilities/form.utility';
import {TenantConfigDataService} from '../../services/tenant-config-data.service';
import {PageRowCountUtility} from '../../shared/utilities/page-row-count.utility';

@Component({
  templateUrl: './student-transcripts.component.html',
  styleUrls: ['./student-transcripts.component.scss'],
})

export class StudentTranscriptComponent implements OnInit, OnChanges {
  InterfaceObjectEnum: string = 'STUDENTTRANSCRIPT';
  context = this.contextService.contextObject;
  mainForm: FormGroup;
  inputData: any;
  loading: boolean = false;
  currentFilter = new PrimeTableFilterModel();
  inputObjOrganization: GenericSelectModel;
  setOrganizationId: number;
  inputObjTrainingCenter: GenericSelectModel;
  setTrainingCenterId: number;
  inputObjCourseType: GenericSelectModel;
  setCourseTypeId: number;
  inputObjStudentGroup: GenericSelectModel;
  setStudentGroupId: number;
  inputObjEmployer: GenericSelectModel;
  setEmployerId: number;
  inputObjProgram: GenericSelectModel;
  setProgramId: number;
  inputObjRosterQuery: GenericSelectModel;
  setRosterQueryId: number;
  inputObjPrimaryTrade: GenericSelectModel;
  setPrimaryTradeId: number;
  inputObjPosition: GenericSelectModel;
  setPositionId: number;
  @Input() ContentID: number;
  isDialog: boolean = false;
  includeModules: boolean = false;
  includeCourses: boolean = false;
  includeCerts: boolean = false;
  showOptions: boolean = false;
  isNewTranscript: boolean = false;
  showCheckboxes: boolean = false;
  private _clientId: string = this.contextService.contextObject.clientId;
  private pageInfo = {} as PageModel;

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private contextService: ContextService, private pageService: PageService,
              private route: ActivatedRoute, public config: DynamicDialogConfig,
              private tenantConfigDataService: TenantConfigDataService, public pageRowCountUtility: PageRowCountUtility) {
    this.mainForm = this.formBuilder.group({
      fcnStartDate: new FormControl(''),
      fcnEndDate: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.contextService.setContextPerspective(['TRAINING']);
    this.route.queryParams.subscribe({
      next: (params) => {
        if (params && params.isNew) {
          this.isNewTranscript = params.isNew === 'true';
        }
        // console.log(this.isNewTranscript);
      }
    });
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isDialog = this.pageInfo.isDialog;
          this.ContentID = this.pageInfo.ContentID;
        }
        if (this.isDialog) {
          if (this.config.data.type === 'student') {
            this.showOptions = true;
            this.includeModules = true;
            this.includeCourses = true;
            this.includeCerts = true;
          }
          this.setInputData();

        } else {
          this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
          this.currentFilter.sortOrder = 1;
          this.currentFilter.first = 0;
          this.currentFilter.filters = {};

          this.initOrganization(false);
          this.initTrainingCenter(false);
          this.initCourseType(false);
          this.initStudentGroup(false);
          this.initEmployer(false);
          this.initProgram(false);
          this.initRosterQuery(false);
          this.initPrimaryTrade(false);
          this.initPosition(true);
          this.setInputData();

          this.pageService.getPagePermissions(this.InterfaceObjectEnum).then(() => {
          });
        }
      }
    });
  }

  // reload if params change
  ngOnChanges(): void {
    this.setInputData();
  }

  initOrganization(disable: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Membership Organization',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setOrganizationId,
      disabled: disable,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData('ROSTER', false, 'membership').then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  setOrganizationData(event:any): void {
    if (event && event[0]) {
      this.setOrganizationId = event[0].ID;
      let desc: string = event[0].Description;

      for (let i = 0, ilen = event[0].Level; i < ilen; i++) {
        desc = desc.replace('--', '');
      }
      this.initPosition(false);
    } else {
      this.setOrganizationId = null;
      this.initPosition(true);
    }
    this.setInputData();
    this.loading = true;
  }

  initTrainingCenter(disable: boolean): void {
    this.inputObjTrainingCenter = {
      labelText: 'Training Center',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setTrainingCenterId,
      disabled: disable
    };
    this.lookupsDataService.getTrainingCentersLookupData().then((lookupData) => {
      this.inputObjTrainingCenter.data = lookupData;
      this.inputObjTrainingCenter = Object.assign({}, this.inputObjTrainingCenter);
    });
  }

  setTrainingCenterData(event:any): void {
    if (event && event[0]) {
      this.setTrainingCenterId = event[0].ID;
    } else {
      this.setTrainingCenterId = 0;
    }
    this.setRosterQueryId = null;
    this.initRosterQuery(false);
    this.setInputData();
    this.loading = true;
  }

  initCourseType(disable: boolean): void {
    this.inputObjCourseType = {
      labelText: 'Course Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setCourseTypeId,
      disabled: disable
    };
    this.lookupsDataService.getCourseTypesLookupData().then((lookupData) => {
      this.inputObjCourseType.data = lookupData;
      this.inputObjCourseType = Object.assign({}, this.inputObjCourseType);
    });
  }

  setCourseTypeData(event:any): void {
    if (event && event[0]) {
      this.setCourseTypeId = event[0].ID;
    } else {
      this.setCourseTypeId = 0;
    }
    this.setInputData();
    this.loading = true;
  }

  initStudentGroup(disable: boolean): void {
    this.inputObjStudentGroup = {
      labelText: 'Student Group',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setStudentGroupId,
      disabled: disable
    };
    this.lookupsDataService.getStudentGroupsByAccessLookupData(this.setTrainingCenterId, false).then((lookupData) => {
      this.inputObjStudentGroup.data = lookupData;
      this.inputObjStudentGroup = Object.assign({}, this.inputObjStudentGroup);
    });
  }

  setStudentGroupData(event:any): void {
    if (event && event[0]) {
      this.setStudentGroupId = event[0].ID;
    } else {
      this.setStudentGroupId = 0;
    }
    this.setInputData();
    this.loading = true;
  }

  initEmployer(disable: boolean): void {
    this.inputObjEmployer = {
      labelText: 'Employer',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEmployerId,
      disabled: disable
    };
    this.lookupsDataService.getEmployersLookupData(false).then((lookupData) => {
      this.inputObjEmployer.data = lookupData;
      this.inputObjEmployer = Object.assign({}, this.inputObjEmployer);
    });
  }

  setEmployerData(event:any): void {
    if (event && event[0]) {
      this.setEmployerId = event[0].ID;
    } else {
      this.setEmployerId = 0;
    }
    this.setInputData();
    this.loading = true;
  }

  initProgram(disable: boolean): void {
    this.inputObjProgram = {
      labelText: 'Program',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setProgramId,
      disabled: disable
    };
    this.lookupsDataService.getProgramsLookupData().then((lookupData) => {
      this.inputObjProgram.data = lookupData;
      this.inputObjProgram = Object.assign({}, this.inputObjProgram);
    });
  }

  setProgramData(event:any): void {
    if (event && event[0]) {
      this.setProgramId = event[0].ID;
    } else {
      this.setProgramId = 0;
    }
    this.setInputData();
    this.loading = true;
  }

  initRosterQuery(disable: boolean): void {
    this.inputObjRosterQuery = {
      labelText: 'Distribution List',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setRosterQueryId,
      disabled: disable
    };
    this.lookupsDataService.getRosterQueriesLookupData(this.setTrainingCenterId, 'training').then((lookupData) => {
      this.inputObjRosterQuery.data = lookupData;
      this.inputObjRosterQuery = Object.assign({}, this.inputObjRosterQuery);
    });
  }

  setRosterQueryData(event:any): void {
    if (event && event[0]) {
      this.setRosterQueryId = event[0].ID;
    } else {
      this.setRosterQueryId = 0;
    }
    this.setInputData();
    this.loading = true;
  }

  initPrimaryTrade(disable: boolean): void {
    this.inputObjPrimaryTrade = {
      labelText: 'Primary Trade',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setPrimaryTradeId,
      disabled: disable
    };
    this.lookupsDataService.getTradesLookupData(false).then((lookupData) => {
      this.inputObjPrimaryTrade.data = lookupData;
      this.inputObjPrimaryTrade = Object.assign({}, this.inputObjPrimaryTrade);
    });
  }

  setPrimaryTradeData(event:any): void {
    if (event && event[0]) {
      this.setPrimaryTradeId = event[0].ID;
    } else {
      this.setPrimaryTradeId = 0;
    }
    this.setInputData();
    this.loading = true;
  }

  initPosition(disable: boolean): void {
    this.inputObjPosition = {
      labelText: 'Position',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setPositionId,
      disabled: disable
    };
    if (this.setOrganizationId) {
      this.lookupsDataService.getPositionsByOrganizationIdLookup(this.setOrganizationId, null, false).then((lookupData) => {
        this.inputObjPosition.data = lookupData;
        this.inputObjPosition = Object.assign({}, this.inputObjPosition);
      });
    }
  }

  setPositionData(event:any): void {
    if (event && event[0]) {
      this.setPositionId = event[0].ID;
    } else {
      this.setPositionId = 0;
    }
    this.setInputData();
    this.loading = true;
  }

  // save for rollback
  // setInputData(): void {
  //     if (this.isDialog) {
  //       if (this.config.data.type === 'student') {
  //         this.inputData = {
  //           isValid: true,
  //           reportName: 'StudentDetailTranscript',
  //           parameters: {
  //             token: this.context.accessToken,
  //             group_id: null,
  //             person_id: this.ContentID,
  //             employer_org_id: null,
  //             program_id: null,
  //             trade_id: null,
  //             position_id: null,
  //             start_dt: null,
  //             end_dt: null,
  //             training_center_organization_id: null,
  //             course_type_id: null,
  //             membership_organization_id_list: null,
  //             roster_query_person_id_list: null,
  //             include_certifications: this.includeCerts,
  //             include_courses: this.includeCourses,
  //             include_modules: this.includeModules,
  //             mock: false
  //           }
  //         };
  //       } else {
  //         this.inputData = {
  //           isValid: true,
  //           reportName: 'AcademicDetailTranscript',
  //           parameters: {
  //             up_id: this.ContentID,
  //             token: this.context.accessToken,
  //             mock: false
  //           }
  //         };
  //       }
  //     } else {
  //       this.inputData = {
  //         isValid: true,
  //         reportName: 'StudentDetailTranscript',
  //         parameters: {
  //           token: this.context.accessToken,
  //           person_id: null,
  //           group_id: this.setStudentGroupId ? this.setStudentGroupId : null,
  //           employer_org_id: this.setEmployerId ? this.setEmployerId : null,
  //           program_id: this.setProgramId ? this.setProgramId : null,
  //           trade_id: this.setPrimaryTradeId ? this.setPrimaryTradeId : null,
  //           position_id: this.setPositionId ? this.setPositionId : null,
  //           membership_organization_id_list: this.setOrganizationId ? this.setOrganizationId : null,
  //           training_center_organization_id: this.setTrainingCenterId ? this.setTrainingCenterId : null,
  //           course_type_id: null,
  //           start_dt: this.mainForm.get('fcnStartDate').value ? transformDateTimeToDateOnly(this.mainForm.get('fcnStartDate').value) : null,
  //           end_dt: this.mainForm.get('fcnEndDate').value ? transformDateTimeToDateOnly(this.mainForm.get('fcnEndDate').value) : null,
  //           roster_query_person_id_list: null,
  //           include_certifications: this.includeCerts,
  //           include_courses: this.includeCourses,
  //           include_modules: this.includeModules,
  //           mock: false
  //         }
  //       };
  //     }
  // }

  setInputData(): void {
    // if(this.isNewTranscript) {
    if (this.isDialog) {
      if (this.config.data.type === 'student') {
        this.showCheckboxes = true;
        this.inputData = {
          isValid: true,
          reportName: 'StudentDetailTranscript',
          parameters: {
            token: this.context.accessToken,
            group_id: null,
            person_id: this.ContentID,
            employer_org_id: null,
            program_id: null,
            trade_id: null,
            position_id: null,
            start_dt: null,
            end_dt: null,
            training_center_organization_id: null,
            course_type_id: null,
            membership_organization_id: null,
            roster_query_id: null,
            include_certifications: this.includeCerts,
            include_courses: this.includeCourses,
            include_modules: this.includeModules,
            mock: false
          }
        };
      } else {
        this.showCheckboxes = false;
        this.inputData = {
          isValid: true,
          reportName: 'AcademicDetailTranscript',
          parameters: {
            person_id: this.ContentID,
            token: this.context.accessToken,
            mock: false
          }
        };
      }
    } else {
      this.showCheckboxes = true;
      this.inputData = {
        isValid: true,
        reportName: 'StudentDetailTranscript',
        parameters: {
          token: this.context.accessToken,
          person_id: null,
          group_id: this.setStudentGroupId ? this.setStudentGroupId : null,
          employer_org_id: this.setEmployerId ? this.setEmployerId : null,
          program_id: this.setProgramId ? this.setProgramId : null,
          trade_id: this.setPrimaryTradeId ? this.setPrimaryTradeId : null,
          position_id: this.setPositionId ? this.setPositionId : null,
          membership_organization_id: this.setOrganizationId ? this.setOrganizationId : null,
          training_center_organization_id: this.setTrainingCenterId ? this.setTrainingCenterId : null,
          course_type_id: this.setCourseTypeId ? this.setCourseTypeId : null,
          start_dt: this.mainForm.get('fcnStartDate').value ? transformDateTimeToDateOnly(this.mainForm.get('fcnStartDate').value) : null,
          end_dt: this.mainForm.get('fcnEndDate').value ? transformDateTimeToDateOnly(this.mainForm.get('fcnEndDate').value) : null,
          roster_query_id: this.setRosterQueryId ? this.setRosterQueryId : null,
          include_certifications: this.includeCerts,
          include_courses: this.includeCourses,
          include_modules: this.includeModules,
          mock: false
        }
      };
    }
  }
}
