import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {OrganizationPositionModel, OrganizationPositionsGridModel} from '../../../../../models/organization-positions.model';
import {FilterModel, PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OrganizationPositionsService} from '../../../../../services/organization-positions.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {DialogUtility} from '../../../../utilities/dialog.utility';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../../utilities/data.utility';
import {exportData} from '../../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../../utilities/list-filter.utility';
import {RosterTabContainerComponent} from '../../../../../roster/roster-tab-container/roster-tab-container.component';
import {RouteContextUtility} from '../../../../utilities/route-context.utility';
import {RosterStudentTabContainerComponent} from '../../../../../roster-student/roster-student-tab-container/roster-student-tab-container.component';
import {ContextService} from '../../../../../services/context.service';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-organization-positions-list',
  templateUrl: './organization-positions-list.component.html',
  styleUrls: ['./organization-positions-list.component.scss']
})
export class PositionsListComponent implements OnInit, OnChanges, OnDestroy {
  positions: OrganizationPositionsGridModel;
  positionList: OrganizationPositionModel[] = [];
  dataLoaded: boolean;
  totalLoaded: boolean;
  selSharableId: number;
  isButtonDisabled: boolean = true;
  isExporting: boolean;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  inputObjPosition: GenericSelectModel;
  setPosition: any[];
  activeGlobalFilter: string = '';
  searchLastName: string = '';
  canDelete: boolean;
  selectedItem: number;
  showAddEdit: boolean;
  displayTotal: number = 0;
  routeContext: string;
  activePosition: boolean = true;
  primaryFinancialContact: boolean = false;
  @Input() organizationId: number;
  @Input() organizationCategory: string;
  @Input() showFinancialContact: boolean = false;
  @Input() canTabWrite: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() canAdd: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private organizationPositionsService: OrganizationPositionsService,
              private messageService: MessageService, private router: Router,
              private dialogUtility: DialogUtility, public dialogService: DialogService,
              private routeContextUtility: RouteContextUtility, private contextService: ContextService,
              private lookupsDataService: LookupsDataService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.routeContext = this.routeContextUtility.getRouteContext();

    // IUPAT wants to prevent position deletion
    this.canDelete = this.contextService.contextObject.tenantName !== 'IUPAT';

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {
      ActivePosition: [
        {
          value: true,
          matchMode: 'equals',
          operator: 'and'
        }
      ]
    };

    this.activePosition = true;
    this.initPositionSelect(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    //timing issue here where the current filter is being loaded after the data call. put in both nginit and changes to correct issue
    this.routeContext = this.routeContextUtility.getRouteContext();

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {
      ActivePosition: [
        {
          value: true,
          matchMode: 'equals',
          operator: 'and'
        }
      ]
    };

    if (changes.organizationId) {
      this.getData();
    }
  }

  deletePosition(organizationPositionId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.organizationPositionsService.deleteOrganizationPosition(organizationPositionId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected position has been removed.'
            });
            this.updateList(true);
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  getData() {
    this.getList();
    this.getTotal();
  }

  initPositionSelect(disable: boolean): void {
    this.inputObjPosition = {
      labelText: 'Position',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getPositionsByOrganizationIdLookup(this.organizationId, this.organizationCategory, false).then((lookupData) => {
      this.inputObjPosition.data = lookupData;
      this.inputObjPosition = Object.assign({}, this.inputObjPosition);
    });
  }

  getPositionData(event:any): void {
    if (event) {
      this.setPosition = event;
    } else {
      this.setPosition = null;
    }
  }

  filterPosition(): void {
    this.currentFilter.filters.PositionDescription = [];
    if (this.setPosition && this.setPosition.length > 0) {
      const selected: FilterModel[] = [];
      this.setPosition.forEach(x => {
        selected.push({value: x, matchMode: 'equals', operator: 'or'});
      });
      this.currentFilter.filters.PositionDescription = selected;
    }
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.positionList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.organizationPositionsService.getOrganizationPositionsPrimeNG('DATA', this.organizationId, this.currentFilter, this.organizationCategory)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          //  this.positions = res;
          this.positionList = res.Data;
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.displayTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.organizationPositionsService.getOrganizationPositionsPrimeNG('TOTAL', this.organizationId, this.currentFilter, this.organizationCategory)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.displayTotal = res.Total;
          if (this.canAdd && this.displayTotal <= 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  updateList(dataChanged: boolean): void {
    this.showAddEdit = false;
    if (dataChanged) {
      this.selectedItem = -1;
      this.getData();
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterItems(): void {
    this.dataLoaded = false;
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('FirstName', this.activeGlobalFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.FirstName;
    }
    if (this.searchLastName && this.searchLastName !== '') {
      this.initFilters('LastName', this.searchLastName, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.LastName;
    }
    if (this.activePosition && this.activePosition === true) {
      this.initFilters('ActivePosition', true, 'equals', 'and');
    } else {
      delete this.currentFilter.filters.ActivePosition;
    }
    if (this.primaryFinancialContact && this.primaryFinancialContact === true) {
      this.initFilters('PrimaryFinancialContact', true, 'equals', 'and');
    } else {
      delete this.currentFilter.filters.PrimaryFinancialContact;
    }
    this.filterPosition();
    this.getData();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.currentFilter.filters = {};
    this.currentFilter.filters = {
      ActivePosition: [
        {
          value: true,
          matchMode: 'equals',
          operator: 'and'
        }
      ]
    };
    this.activeGlobalFilter = null;
    this.searchLastName = null;
    this.setPosition = null;
    this.activePosition = true;
    this.primaryFinancialContact = null;
    this.initPositionSelect(false);
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string): void {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  goToEdit(event:any): void {
    this.selSharableId = event.SharableId;
  }

  refresh(): void {
    this.getData();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  openPerson(PersonID: number): void {
    const selectedStudent = this.positionList.find(x => x.PersonID === PersonID);
    if (PersonID) {
      if (this.routeContext === 'training') {
        this.dialogService.open(RosterStudentTabContainerComponent, {
          data: {
            id: PersonID,
            isEdit: true,
            isDialog: true
          },
          header: selectedStudent.FirstName + ' ' + selectedStudent.LastName,
          width: '90%'
        });
      } else {
        this.dialogService.open(RosterTabContainerComponent, {
          data: {
            id: PersonID,
            isEdit: true,
            isDialog: true
          },
          header: selectedStudent.FirstName + ' ' + selectedStudent.LastName,
          width: '90%'
        });
      }
    }
  }

  resetComponent(): void {
    this.positionList = undefined;
    this.positions = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.displayTotal <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.positionList));
        const dataToExport = removeKeysFromArray(arr, ['OrganizationPositionID', 'OrganizationID', 'PositionID', 'PersonID', 'CurrentSort', 'SubjectAreaID', 'CanEdit', 'CanDelete']);
        exportData('positions', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.organizationPositionsService.getOrganizationPositionsPrimeNG('DATA', this.organizationId, exportFilter, this.organizationCategory)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['OrganizationPositionID', 'OrganizationID', 'PositionID', 'PersonID', 'CurrentSort', 'SubjectAreaID', 'CanEdit', 'CanDelete']);
              exportData('positions', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
