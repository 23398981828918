<div class="p-fluid p-grid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <button pButton type="button" label="Steps" class="sub-tab" (click)="selectedSubTab = 'list'" [disabled]="selectedSubTab === 'list'"></button>
    <button pButton type="button" label="Options" class="sub-tab" (click)="selectedSubTab = 'options'" [disabled]="selectedSubTab === 'options'"></button>
  </div>
  @if (selectedSubTab === 'list') {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <app-workflow-steps-list [parentObjectEnum]="parentObjectEnum" [organizationId]="organizationId" [workflowId]="workflowId" [canTabWrite]="canTabWrite" [canAdd]="canAdd"></app-workflow-steps-list>
    </div>
  }
  <div class="col-md-12 col-sm-12 col-xs-12">
    @if (selectedSubTab === 'options') {
      <app-workflow-step-options [parentObjectEnum]="parentObjectEnum" [category]="category" [organizationId]="organizationId" [canTabWrite]="canTabWrite" [canAdd]="canAdd"></app-workflow-step-options>
    }
  </div>
</div>
