import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {StudentProgramFacetTypeListModel} from '../../../models/student-programs.model';

@Component({
  selector: 'app-program-incoming-student-hours',
  templateUrl: './program-student-incoming-hours-dialog.component.html',
  styleUrls: ['./program-student-incoming-hours-dialog.component.scss']
})

export class ProgramStudentIncomingHoursDialogComponent implements OnInit, OnDestroy {
  isSaving: boolean;
  mainForm: FormGroup;
  existingHours: StudentProgramFacetTypeListModel[] = [];
  inputObjFacetType: GenericSelectModel;
  setFacetTypeLookupId: number;
  OJLFacetTypeID: number;
  RIFacetTypeID: number;
  OJLFacetID: number;
  noOJLFacetsFound: boolean = true;
  noRIFacetsFound: boolean = true;

  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef,
              private formBuilder: FormBuilder,
              private lookupsDataService: LookupsDataService) {
    this.mainForm = this.formBuilder.group({
      OJLFacetType: new FormControl(null, Validators.required),
      RIFacetType: new FormControl(null, Validators.required),
      OJLFacet: new FormControl(null, Validators.required),
      RIFacet: new FormControl(null, Validators.required),
      OJLHours: new FormControl(null),
      RIHours: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.getFacetTypes();
    if (sessionStorage.getItem('timeEntry') !== null) {
      const tempHours: any[] = JSON.parse(sessionStorage.getItem('timeEntry'));
      this.mainForm.get('OJLHours').setValue(tempHours.find(x => x.FacetTypeDescription.includes('(OJL)')).IncomingHours_N);
      this.mainForm.get('RIHours').setValue(tempHours.find(x => x.FacetTypeDescription.includes('(RI)')).IncomingHours_N);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getFacetTypes() {
    this.lookupsDataService.getProgramFacetTypesLookupData(this.config.data.programId, 1).then((lookupData: any[]) => {
      if (lookupData.length > 1) {
        this.setFacetTypeLookupId = lookupData[1].ID;
        this.OJLFacetTypeID = lookupData[1].FaceTypeID;
        this.mainForm.get('OJLFacetType').setValue(lookupData[1].Description);
        this.getOJLFacets();
        if (lookupData.length > 2) {
          this.RIFacetTypeID = lookupData[2].FaceTypeID;
          this.mainForm.get('RIFacetType').setValue(lookupData[2].Description);
          this.getRIFacets();
        }
      }
    });
  }

  getOJLFacets() {
    let facetData;
    this.lookupsDataService.getProgramFacetTypeFacetsLookupData(this.setFacetTypeLookupId).then((lookupData: any[]) => {
      facetData = lookupData.filter(x => x.Description === 'Incoming Credit');
      if (facetData.length > 0) {
        this.OJLFacetID = facetData[0].FacetID;
        this.mainForm.get('OJLFacet').setValue(facetData[0].Description);
        this.noOJLFacetsFound = false;
      } else {
        this.noOJLFacetsFound = true;
      }
    });
  }

  getRIFacets() {
    let facetData;
    this.lookupsDataService.getProgramFacetTypeFacetsLookupData(this.setFacetTypeLookupId).then((lookupData: any[]) => {
      facetData = lookupData.filter(x => x.Description === 'Incoming Credit');
      if (facetData.length > 0) {
        this.OJLFacetID = facetData[0].FacetID;
        this.mainForm.get('RIFacet').setValue(facetData[0].Description);
        this.noRIFacetsFound = false;
      } else {
        this.noRIFacetsFound = true;
      }
    });
  }

  processData() {
    //push ojl hours
    if ((this.mainForm.get('OJLHours').value) && (this.OJLFacetTypeID) && (this.mainForm.get('OJLFacetType').value)) {
      this.existingHours.push({
        IncomingHours_N: this.mainForm.get('OJLHours').value,
        FacetTypeID: this.OJLFacetTypeID,
        FacetTypeDescription: this.mainForm.get('OJLFacetType').value
      });
    }
    //push ri hours
    if ((this.mainForm.get('RIHours').value) && (this.RIFacetTypeID) && (this.mainForm.get('RIFacetType').value)) {
      this.existingHours.push({
        IncomingHours_N: this.mainForm.get('RIHours').value,
        FacetTypeID: this.RIFacetTypeID,
        FacetTypeDescription: this.mainForm.get('RIFacetType').value
      });
    }
    this.closeDialog();
  }

  closeDialog() {
    sessionStorage.setItem('timeEntry', JSON.stringify(this.existingHours));
    this.ref.destroy();

  }

  cancel() {
    this.ref.destroy();
  }
}
