import {BaseDataGridModel} from './data-grid.model';

export interface ClassAttendanceGridModel extends BaseDataGridModel {
  Data: ClassAttendanceModel[];
}

export interface ClassAttendanceModel {
  PersonID: number;
  FullName: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Address: string;
  Phone: string;
  AttendanceStatusID: number;
  AttendanceStatusDescription?: string;
  CanEdit: boolean;
  Signature?: string;
  Other?: string;
  PrimaryFullAddress?: string;
  MemberNumber?: string;
  Notes: string;
  NotesModifiedPerson?: string;
  NotesModifiedDate?: string;
  Email?: string;
  OrganizationAbbreviation?: string;
  Employer?: string;
}

export interface UpdateClassAttendanceModel {
  AttendancePeriod: string;
  Attendees: UpdateClassAttendanceAttendeeModel[];
}

export interface UpdateClassAttendanceAttendeeModel {
  PersonID: number;
  AttendanceStatusID: number
  Notes?: string;
}


export interface ModuleAttendanceModel {
  Address: string;
  ClassScheduleID: number;
  ClassStudentID: number;
  CourseAttendance: ModuleCourseAttendanceModel[];
  FirstName: string;
  FullName: string;
  LastName: string;
  MemberNumber: string;
  MiddleName: string;
  Notes: string;
  Phone: string;
  PrimaryFullAddress: string;
  NotesModifiedPerson: string;
  NotesModifiedDate: Date;
}

export interface ModuleCourseAttendanceModel {
  AttendanceStatusID: number;
  CourseDescription: string;
  CourseID: number;
  NotesModifiedPerson?: string;
  NotesModifiedDate?: Date;
  Notes?: string;
}

export interface ModuleDailyAttendanceModel {
  ClassDescription: string;
  Date_D: Date;
  StartTime?: string;
  EndTime?: string;
  Instructor: string;
  Courses: ModuleDailyAttendanceCourseModel[];
  ClassLocation?: string;
  ClassIdentifier?: string;
}

export interface ModuleDailyAttendanceCourseModel {
  CourseID: number;
  CourseDescription: string;
  Students: ModuleDailyAttendanceStudentModel[];
}

export interface ModuleDailyAttendanceStudentModel {
  FirstName: string;
  LastName: string;
  FullName: string;
  MiddleName: string;
  MemberNumber: number;
  Address: string;
  Phone: string;
  OrganizationAbbreviation?:string;
  Email?:string;
  Employer?: string;
  Signature?: string;
  Note?: string;
}
