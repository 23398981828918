import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { CasesGridModel, CaseModel, CreateCasesModel, UpdateCasesModel,
  CaseCommunicationRecipientsGridModel } from '../models/cases.model';
import {RosterGridModel, RosterModel} from '../models/roster.model';
import {OrganizationPositionsGridModel} from '../models/organization-positions.model';

@Injectable({
  providedIn: 'root',
})
export class CasesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getCasesPrimeNG(dataMode: string,filterData): Observable<CasesGridModel> {
    return this.http.post<CasesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/grid?mode=${dataMode}`, filterData);
  }

  getUserCasesPrimeNG(dataMode: string, filterData): Observable<CasesGridModel> {
    return this.http.post<CasesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/casegrid?mode=${dataMode}`, filterData);
  }

  getPersonCasesKGrid(personId: number): Observable<CasesGridModel> {
    return this.http.get<CasesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/casegrid`);
  }

  getCase(caseId: number): Observable<CaseModel> {
    return this.http.get<CaseModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/${caseId}`);
  }

  getCaseContactsPrimeNG(modeData: string, caseId: number, filterData): Observable<OrganizationPositionsGridModel> {
    return this.http.post<OrganizationPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/${caseId}/contacts/grid?mode=${modeData}`, filterData);
  }

  getCaseContactsByParmPrimeNG(modeData: string, organizationId: number, filterData): Observable<OrganizationPositionsGridModel> {
    return this.http.post<OrganizationPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/contactsbyparm?caseorganizationId=${organizationId}&mode=${modeData}`, filterData);
  }

  createCase(modelData: CreateCasesModel): Observable<CaseModel> {
    return this.http.post<CaseModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/create`, modelData);
  }

  updateCase(caseId: number, modelData: UpdateCasesModel): Observable<CaseModel> {
    return this.http.put<CaseModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/${caseId}`, modelData);
  }

  deleteCase(caseId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/remove/${caseId}`);
  }

  addClaimantToCase(caseId: number, personId: number): Observable<CaseModel> {
    return this.http.put<CaseModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/${caseId}/addclaimant/${personId}`, null);
  }

  deleteClaimantFromCase(caseId: number, personId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/remove/${caseId}/removeclaimant/${personId}`);
  }

  getClaimantsKGrid(caseId: number): Observable<RosterModel> {
    return this.http.get<RosterModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/${caseId}/claimants`);
  }

  getClaimantsPrimeNG(dataMode: string, caseId: number, filterData): Observable<RosterGridModel> {
    return this.http.post<RosterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/${caseId}/claimants?mode=${dataMode}`, filterData);
  }

  getClaimantsPickerPrimeNG(dataMode: string, caseOrganizationId: number, employerId: number, contractId: number, filterData): Observable<RosterGridModel> {
    return this.http.post<RosterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/claimantsbyparm?caseorganizationId=${caseOrganizationId}&employerid=${employerId}&contractid=${contractId}&mode=${dataMode}`, filterData);
  }

  getCaseCommunicationRecipientsKGrid(caseId: number): Observable<CaseCommunicationRecipientsGridModel> {
    return this.http.get<CaseCommunicationRecipientsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/case/${caseId}/claimants`);
  }
}
