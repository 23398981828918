import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {StudentProfileService} from '../../../services/student-profile.service';
import {StudentProfileModel} from '../../../models/student-profile.model';

@Component({
  selector: 'app-roster-student-tab-user-account',
  templateUrl: './roster-student-tab-user-account.component.html'
})

export class RosterStudentTabUserAccountComponent implements OnInit {
  personInfo: StudentProfileModel;
  @Input() PersonID: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() updateImage = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private studentProfileService: StudentProfileService) {
  }

  ngOnInit(): void {
    this.getStudentDetails(this.PersonID);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getStudentDetails(personId: number): void {
    if (personId) {
      this.studentProfileService.getStudentProfile(personId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.personInfo = res;
            this.updateImage.emit(this.personInfo);
          }
        });
    }
  }
}
