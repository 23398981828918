@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form [formGroup]="mainForm">
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      <div class="col-md-6 col-sm-12 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjStep" (emitData)="getStepData($event)" ></app-select>
        <span>(Shared options across all workflows.)</span>
      </div>
      @if (isEdit || setStepId === -1) {
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>Description @if (setStepId===-1) {
            <sup class="req">*</sup>
          }</label>
          <input pInputText formControlName="workflowStepAlias" maxlength="200" tabindex="0">
          @if ((mainForm.controls.workflowStepAlias.dirty || mainForm.controls.workflowStepAlias.touched) &&
            mainForm.controls.workflowStepAlias.errors?.required) {
            <span class="p-error">'Description' must be provided</span>
          }
          @if ((mainForm.controls.workflowStepAlias.dirty || mainForm.controls.workflowStepAlias.touched) &&
            mainForm.controls.workflowStepAlias.errors?.required) {
            <br>
            }
            <span>(Customize for this workflow.)</span>
          </div>
        }
      </div>
      <div class="p-grid p-fluid margin-top-10">
        @if (canTabWrite) {
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="p-grid p-fluid">
              <div class="col-md-6 col-sm-6 col-xs-12"></div>
              <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
                @if (isEdit) {
                  <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                  (click)="cancel()" [disabled]="!mainForm.dirty"></button>
                } @else {
                  <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                  (click)="cancel()"></button>
                }
                @if (!isSaving) {
                  <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                    (click)="processData()" [className]="'inline-button'"
                  [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
                } @else {
                  <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                    <i class="pi pi-spin pi-spinner save-spinner"></i>
                  </button>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </form>
  }
