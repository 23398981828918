<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">
    <h3>
      You can choose to generate a new membership card for this member or view and existing card. Generating a new card
      will create
      a "Card Issue Date" for these Member(s).</h3>
  </div>
  <div class="col-md-6 col-sm-6 col-xs-12 input-container margin-top-15">
    @if (hasCard) {
      <button pButton class="p-button-download contact-button" icon="pi pi-search" label="View Existing"
              pTooltip="Clear" tooltipPosition="bottom" (click)="closeDialog('view')">
      </button>
    }
  </div>
  <div class="col-md-6 col-sm-6 col-xs-12">
    @if (canPageWrite) {
      <div class="p-grid p-fluid">
        <div class="col-md-7 col-sm-7 col-xs-12">
          <app-select class="full-width" [inputObj]="inputObjTemplate"
                      (emitData)="getMembershipCardTemplateData($event)"></app-select>
        </div>
        <div class="col-md-5 col-sm-5 col-xs-12  align-btn">
          <button pButton class="p-button-download contact-button" icon="pi pi-search-plus" label="Generate New"
                  pTooltip="Clear" tooltipPosition="bottom" (click)="closeDialog('regenerate')"
                  [disabled]="!setMembershipCardTemplateId">
          </button>
        </div>
      </div>
    }
  </div>
</div>
