<div class="page-container">
  <div class="parent-p-card">
    @if (isPage) {
      <button pButton label="Back" (click)="back()" class="p-button-info p-mb-2"
              icon="pi pi-angle-left" pTooltip="Back" tooltipPosition="bottom"></button>
    }
    <!--    <button pButton label="Update Cache" (click)="updateCachedData()" [className]="'inline-button margin-top-5'"-->
    <!--            icon="pi pi-download" pTooltip="Update Cache" tooltipPosition="bottom"></button>-->
    <div class="p-grid p-fluid">
      <div class="col-md-12 col-sm-12 col-xs-12 p-mt-4">
        <p-tabView>
          @if (isSuper) {
            <p-tabPanel header="Settings">
              <ng-template pTemplate="content">
                <form [formGroup]="settingsForm">
                  <div class="p-grid p-fluid">
                    <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                      <p>Change settings to customize your experience.</p>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                      <p-divider class="dark-divider"></p-divider>
                    </div>
                    @if (!isMobile) {
                      <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                        <label><i class="fas fa-list"></i>&nbsp;Select your default view type for lists <sup
                          class="req">*</sup></label>
                        <p-dropdown [styleClass]="'full-width'" [options]="viewTypes" appendTo="body"
                                    formControlName="viewType" optionLabel="name" optionValue="value"></p-dropdown>
                      </div>
                    }
                    <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                      <label><i class="fas fa-hashtag"></i>&nbsp;Select your default page row count for lists <sup
                        class="req">*</sup></label>
                      <p-dropdown [styleClass]="'full-width'" [options]="pageRowCount" appendTo="body"
                                  formControlName="pageRowCount" optionLabel="name" optionValue="value"></p-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                      <label><i class="fas fa-stopwatch"></i>&nbsp;Select your default indicator for lists <sup
                        class="req">*</sup></label>
                      <p-dropdown [styleClass]="'full-width'" [options]="indicator" appendTo="body"
                                  formControlName="indicator" optionLabel="name" optionValue="value"></p-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                      <label><i class="pi pi-save"></i>&nbsp;Be reminded to save data when changed <sup
                        class="req">*</sup></label>
                      <p-dropdown [styleClass]="'full-width'" [options]="saveReminder" appendTo="body"
                                  formControlName="saveReminder" optionLabel="name" optionValue="value"></p-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                      <label><i class="pi pi-filter"></i>&nbsp;Show or hide filter on lists by default <sup
                        class="req">*</sup></label>
                      <p-dropdown [styleClass]="'full-width'" [options]="filterDefault" appendTo="body"
                                  formControlName="filterDefault" optionLabel="name" optionValue="value"></p-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                      <label>Remember current list filters <sup class="req">*</sup></label>
                      <p-dropdown [styleClass]="'full-width'" [options]="saveReminder" appendTo="body"
                                  formControlName="rememberFilters" optionLabel="name"
                                  optionValue="value"></p-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                      <label><i class="fas fa-list p-mr-1"></i> Landing Page</label>
                      <p-dropdown [styleClass]="'full-width'" [options]="landingPageOptions" appendTo="body"
                                  formControlName="landingPage" optionLabel="name" optionValue="value"></p-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 margin-top-10">
                      <app-select class="full-width" [inputObj]="inputObjMemberRosterQuery"
                                  (emitData)="setMemberRosterQueryData($event)">
                      </app-select>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 margin-top-10">
                      <app-select class="full-width" [inputObj]="inputObjTrainingRosterQuery"
                                  (emitData)="setTrainingRosterQueryData($event)">
                      </app-select>
                    </div>
                    @if (!isMobile) {
                      <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                        <label><i class="fas fa-question-circle"></i>&nbsp;Show or hide help by default <sup
                          class="req">*</sup></label>
                        <p-dropdown [styleClass]="'full-width'" [options]="helpDefault" appendTo="body"
                                    formControlName="helpDefault" optionLabel="name" optionValue="value"></p-dropdown>
                      </div>
                    }
                    <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                      <label><i class="fas fa-tag"></i>&nbsp;Do you have a DYMO label printer installed? <sup
                        class="req">*</sup></label>
                      <!--              <a href="https://www.dymo.com/" target="_blank" rel="noopener noreferrer" title="DYMO">DYMO</a>-->
                      <p-dropdown [styleClass]="'full-width'" [options]="dymoInstalled" appendTo="body"
                                  formControlName="dymoInstalled" optionLabel="name" optionValue="value"></p-dropdown>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                      <div class="align-right p-mt-2">
                        <button pButton label="Save" class="p-button-info" icon="pi pi-save" pTooltip="Save"
                                tooltipPosition="bottom" [className]="'inline-button margin-top-5'" [disabled]="!settingsForm.dirty"
                                (click)="saveSettings()"></button>
                      </div>
                      <p><span class="link" [routerLink]="['/user-agreement']">Review your User Agreement</span></p>
                    </div>
                  </div>
                </form>
              </ng-template>
            </p-tabPanel>
          }
          <p-tabPanel header="Profile Picture">
            <ng-template pTemplate="content">
              <div class="p-grid p-fluid">
                <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                  <p>Upload or capture your profile picture as a .jpg, .png or .gif. File size is limited to
                    4MB.</p>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                  <p-divider class="dark-divider"></p-divider>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                  <div class="text-align-middle">
                    @if (personInfo) {
                      @if (personInfo.ThumbnailUrl) {
                        <img class="profile-image" ngSrc="{{personInfo.ThumbnailUrl + blobToken}}" alt="" width="100"
                             height="100" [priority]="true"/>
                      }
                      @if (!personInfo.ThumbnailUrl) {
                        <img class="profile-image" ngSrc="assets/images/user@2x.png" alt="" width="100" height="100"
                             [priority]="true"/>
                      }

                    }
                    @if (personInfo) {
                      <div class="text-align-middle upload-buttons">
                        <div class="image-button p-ml-2">
                          <app-image-editor [incomingImageUrl]="personInfo.ThumbnailUrl" (deleteImage)="deleteImage('profilephoto')"
                                            (updateImage)="uploadImage($event, 'profilephoto')"
                                            (updateFilename)="headerImageName = $event" [showWebCam]="true"
                                            [roundCropper]="true" [ratio]="1 / 1"
                                            (updatePage)="getUserProfile()"></app-image-editor>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="Signature">
            <ng-template pTemplate="content">
              <div class="p-grid p-fluid">
                <div class="col-md-3 col-sm-3 col-xs-3 p-mt-2"></div>
                <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                  <p-card header="Signature">
                    <div class="text-align-middle image-area">

                      @if (this.signatureImage) {
                        <img class="profile-image" [src]="signatureImage"  width="100%" />
                      }
                      @if (!this.signatureImage) {
                        <img class="profile-image" src="assets/images/sign-here.png" width="100%"   (click)="addSignature()"/>
                      }
                      <p>Edit or Create your Signature</p>

                      @if (personInfo) {
                        <div class="text-align-middle upload-buttons">
                          <div class="image-button p-ml-2">
                            <button pButton label="Remove" class="p-button-info p-mr-2" icon="pi pi-trash" pTooltip="Save" tooltipPosition="bottom"
                                    (click)="deleteImage('signature')" [className]="'inline-button'"></button>
                            <button pButton label="Add/Edit" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                                    (click)="addSignature()" [className]="'inline-button'"></button>
                          </div>
                        </div>
                      }
                    </div>
                  </p-card>

                </div>
                <div class="col-md-3 col-sm-3 col-xs-3 p-mt-2"></div>
              </div>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="Two-Factor Authentication">
            <ng-template pTemplate="content">
              <div class="p-grid p-fluid">
                <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                  <p>For added security and peace of mind, turn on 2FA (Two-Factor Authentication). Enabling 2FA will
                    require you to provide a code sent to the mobile phone number below during login, along with your
                    username and password.</p>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                  <p-divider class="dark-divider"></p-divider>
                </div>
                <!--              <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">-->
                <!--                <label>Two Factor Authentication? <sup class="req">*</sup></label>-->
                <!--                <app-yes-no-select [isBoolean]="true" [selectedValue]="twoFactorAuth" (emitData)="twoFactorAuth = $event"></app-yes-no-select>-->
                <!--              </div>-->
                <div class="col-md-6 col-sm-12 col-xs-12 p-mt-2">
                  <label>Mobile Phone # <sup class="req">*</sup></label>
                  <p-inputMask type="text" pInputText [(ngModel)]="primaryPhoneNumber" [className]="'form-input'"
                               mask="(999) 999-9999"
                               unmask="true"></p-inputMask>
                </div>
                @if (primaryPhoneNumber) {
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">
                    <span class="error">By submitting your mobile number for verification you are agreeing to receive text messages that will be used to authenticate your account during the login process. You may return to this page at any time to disable two factor security and stop receiving authentication texts.</span>
                  </div>
                }
                <div class="col-md-12 col-sm-12 col-xs-12 text-align-right p-mt-1">
                  <div class="align-right">
                    <button pButton type="button" [label]="twoFactorLabel" (click)="openTwoFactor()"
                            [disabled]="!primaryPhoneNumber">
                      @if (isLoadingSQ) {
                        <div class="loading">
                          <i class="pi pi-spin pi-spinner"></i>
                        </div>
                      }
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="Change Password">
            <ng-template pTemplate="content">
              <form [formGroup]="changePasswordForm">
                <div class="p-grid p-fluid">
                  <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                    <p>Feel free to change your password at any time, but you must follow these rules:</p>
                    <ul>
                      <li>Must be at least 10 characters long</li>
                      <li>Must contain at least one uppercase letter (A-Z)</li>
                      <li>Must contain at least one lowercase letter (a-z)</li>
                      <li>Must contain at least one number (0-9)</li>
                      <li [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></li>
                      <li>Must have no more than 2 identical characters in a row (999 not allowed)</li>
                      <li>No parts of your username</li>
                    </ul>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                    <p-divider class="dark-divider"></p-divider>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12 p-fieldset-content p-mt-2">
                    <label>Current Password/Passphrase <sup class="req">*</sup></label>
                    <input [type]="passwordCurrentType" pInputText formControlName="passwordCurrent">
                    @if (changePasswordForm.controls.passwordCurrent.invalid && changePasswordForm.controls.passwordCurrent.touched) {
                      <div class="p-error">
                        @if (changePasswordForm.controls.passwordCurrent.errors?.required) {
                          <div>Current Password is required</div>
                        }
                      </div>
                    }
                    <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPasswordCurrent"
                                label="Show Password"></p-checkbox>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12 p-fieldset-content p-mt-2">
                    <label>New Password/Passphrase <sup class="req">*</sup></label>
                    <input [type]="passwordType" pInputText formControlName="password">
                    @if (changePasswordForm.controls.password.invalid && changePasswordForm.controls.password.touched) {
                      <div class="p-error">
                        @if (changePasswordForm.controls.password.errors?.required) {
                          <div>New Password is required</div>
                        }
                        <!--                <div *ngIf="changePasswordForm.controls.password.errors?.pattern">Must contain at least one uppercase and lowercase letter, one number, one special character, and be at least 10 characters long</div>-->
                        @if (changePasswordForm.controls.password.hasError('length')) {
                          <div>Must be at least 10 characters long</div>
                        }
                        @if (changePasswordForm.controls.password.hasError('uppercase')) {
                          <div>Must contain at least one uppercase letter (A-Z)</div>
                        }
                        @if (changePasswordForm.controls.password.hasError('lowercase')) {
                          <div>Must contain at least one lowercase letter (a-z)</div>
                        }
                        @if (changePasswordForm.controls.password.hasError('number')) {
                          <div>Must contain at least one number (0-9)</div>
                        }
                        @if (changePasswordForm.controls.password.hasError('special')) {
                          <div [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></div>
                        }
                        @if (changePasswordForm.controls.password.hasError('repeats')) {
                          <div>Must have no more than 2 identical characters in a row (999 not allowed)</div>
                        }
                        @if (changePasswordForm.controls.password.hasError('customValidator')) {
                          <div>No parts of your username</div>
                        }
                      </div>
                    }
                    <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPassword"
                                label="Show Password"></p-checkbox>
                  </div>
                  <div class="col-md-6 col-sm-0 col-xs-0"></div>
                  <div class="col-md-6 col-sm-12 col-xs-12 p-fieldset-content p-mt-2">
                    <label>Confirm New Password/Passphrase <sup class="req">*</sup></label>
                    <input [type]="passwordConfirmType" pInputText formControlName="passwordConfirm">
                    @if (changePasswordForm.controls.passwordConfirm.invalid && changePasswordForm.controls.passwordConfirm.touched) {
                      <div class="p-error">
                        @if (changePasswordForm.controls.passwordConfirm.errors?.required) {
                          <div>Confirm New Password is required</div>
                        }
                        @if (changePasswordForm.controls.passwordConfirm.errors?.confirmedValidator) {
                          <div>New Password & Confirm New Password do not match</div>
                        }
                      </div>
                    }
                    <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPasswordConfirm"
                                label="Show Password"></p-checkbox>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 p-fieldset-content p-mt-2">
                    <div class="align-right">
                      <button pButton type="button" label="Change Password" (click)="resetPassword()"
                              [disabled]="changePasswordForm.invalid || !changePasswordForm.touched">
                        @if (isLoading) {
                          <div class="loading">
                            <i class="pi pi-spin pi-spinner"></i>
                          </div>
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="Security Questions">
            <ng-template pTemplate="content">
              <div class="p-grid p-fluid">
                <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                  <p>Your current security questions and answers are not retrievable, but you may reset them below.</p>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                  <p-divider class="dark-divider"></p-divider>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
                  <form [formGroup]="securityQuestionsForm">
                    <div class="p-fieldset-content p-mt-2">
                      <label>Current Password/Passphrase <sup class="req">*</sup></label>
                      <input [type]="passwordCurrentTypeSQ" pInputText formControlName="passwordCurrent">
                      @if (securityQuestionsForm.controls.passwordCurrent.invalid && securityQuestionsForm.controls.passwordCurrent.touched) {
                        <div class="p-error">
                          @if (securityQuestionsForm.controls.passwordCurrent.errors?.required) {
                            <div>Current Password is required</div>
                          }
                        </div>
                      }
                      <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPasswordCurrent"
                                  label="Show Password"></p-checkbox>
                    </div>
                    <p-divider [styleClass]="'dark-divider'"></p-divider>
                    <div class="p-fieldset-content p-mt-2">
                      @if (showQuestion1Id) {
                        <app-select class="full-width" [inputObj]="inputObjQuestion1"
                                    (emitData)="getQuestion1Data($event)"></app-select>
                      }
                    </div>
                    <div class="p-fieldset-content p-mt-2">
                      <label>Answer <sup class="req">*</sup></label>
                      <input pInputText formControlName="answer1" maxlength="100">
                      @if (securityQuestionsForm.controls.answer1.invalid && securityQuestionsForm.controls.answer1.touched) {
                        <div class="p-error">
                          @if (securityQuestionsForm.controls.answer1.errors?.required) {
                            <div>An answer is required</div>
                          }
                          @if (securityQuestionsForm.controls.answer1.errors?.minlength) {
                            <div>Answer must be between 4 and 100 characters</div>
                          }
                        </div>
                      }
                    </div>
                    <div class="p-fieldset-content p-mt-4">
                      @if (showQuestion2Id) {
                        <app-select class="full-width" [inputObj]="inputObjQuestion2"
                                    (emitData)="getQuestion2Data($event)"></app-select>
                      }
                    </div>
                    <div class="p-fieldset-content p-mt-2">
                      <label>Answer <sup class="req">*</sup></label>
                      <input pInputText formControlName="answer2" maxlength="100">
                      @if (securityQuestionsForm.controls.answer2.invalid && securityQuestionsForm.controls.answer2.touched) {
                        <div class="p-error">
                          @if (securityQuestionsForm.controls.answer2.errors?.required) {
                            <div>An answer is required</div>
                          }
                          @if (securityQuestionsForm.controls.answer2.errors?.minlength) {
                            <div>Answer must be between 4 and 100 characters</div>
                          }
                        </div>
                      }
                    </div>
                    <div class="p-fieldset-content p-mt-4">
                      @if (showQuestion3Id) {
                        <app-select class="full-width" [inputObj]="inputObjQuestion3"
                                    (emitData)="getQuestion3Data($event)"></app-select>
                      }
                    </div>
                    <div class="p-fieldset-content p-mt-2">
                      <label>Answer <sup class="req">*</sup></label>
                      <input pInputText formControlName="answer3" maxlength="100">
                      @if (securityQuestionsForm.controls.answer3.invalid && securityQuestionsForm.controls.answer3.touched) {
                        <div class="p-error">
                          @if (securityQuestionsForm.controls.answer3.errors?.required) {
                            <div>An answer is required</div>
                          }
                          @if (securityQuestionsForm.controls.answer3.errors?.minlength) {
                            <div>Answer must be between 4 and 100 characters</div>
                          }
                        </div>
                      }
                    </div>
                    <div class="p-mt-2 p-mb-6">
                      <div class="align-right">
                        <button pButton type="button" label="Save" [className]="'inline-button margin-top-5'"
                                (click)="updateSecurityQuestions()"
                                [disabled]="securityQuestionsForm.invalid || !securityQuestionsForm.touched ">
                          @if (isLoadingSQ) {
                            <div class="loading">
                              <i class="pi pi-spin pi-spinner"></i>
                            </div>
                          }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</div>
