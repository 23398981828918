import {Component, Input, OnChanges, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {transformDateTimeToDateOnly, transformDateTimeToTimeOnly} from '../../utilities/form.utility';
import {CustomAttributesModel} from '../../../models/attributes.model';

@Component({
  selector: 'app-dynamic-custom-fields',
  templateUrl: './dynamic-custom-fields.component.html',
  styleUrls: ['./dynamic-custom-fields.component.scss']
})
export class DynamicCustomFieldsComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  fieldType: string;
  fieldDesc: string;
  @Input() customField: CustomAttributesModel;
  @Input() isEdit: boolean;
  @Input() PersonID: number;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<CustomAttributesModel>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder) {
    this.mainForm = this.formBuilder.group({
      value: new FormControl(null)
    });
  }

  ngOnInit(): void {
    if (this.canTabWrite) {
      this.mainForm.enable();
    } else {
      this.mainForm.disable();
    }
  }

  ngOnChanges(): void {
    if (this.isEdit) {
      this.initForm();
      if (this.canTabWrite) {
        this.mainForm.enable();
      } else {
        this.mainForm.disable();
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initForm(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.fieldDesc = this.customField.FieldName;
    this.fieldType = this.customField.DataTypeEnum;
    switch (this.fieldType) {
      case 'PICKLIST':
        this.customField.Options.unshift({
          ID: null,
          Description: '--',
          Abbreviation: ''
        });
        if (this.customField.Value === '' || this.customField.Value === null) {
          this.mainForm.get('value').setValue('--');
        } else {
          this.mainForm.get('value').setValue(this.customField.Value);
        }
        break;
      case 'DATE':
        if (this.customField.Value) {
          // const newDate = new Date(this.customField.Value);
          // this.mainForm.get('value').setValue(newDate);
          this.mainForm.get('value').setValue(this.customField.Value.substring(5, 7) + '/' + this.customField.Value.substring(8, 10) + '/' + this.customField.Value.substring(0, 4));
        }
        break;
      case 'TIME':
        if (this.customField.Value) {
          const attributeTime: Date = new Date(this.customField.Value);
          this.mainForm.get('value').setValue(attributeTime);
        }
        break;
      default:
        this.mainForm.get('value').setValue(this.customField.Value);
        break;
    }
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      switch (this.fieldType) {
        case 'PICKLIST':
          this.customField.Value = (this.mainForm.get('value').value === '--' || this.mainForm.get('value').value === '') ? null : this.mainForm.get('value').value;
          break;
        case 'DATE':
          this.customField.Value = transformDateTimeToDateOnly(this.mainForm.get('value').value);
          break;
        case 'TIME':
          this.customField.Value = transformDateTimeToTimeOnly(this.mainForm.get('value').value);
          break;
        default:
          this.customField.Value = this.mainForm.get('value').value;
          break;
      }
      this.saveForm();
    }
  }

  saveForm(): void {
    this.closeAddEdit.emit(this.customField);
  }
}
