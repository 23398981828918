import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {YesNoLookupModel} from '../../../../models/lookups.model';

@Component({
  selector: 'app-yes-no-select',
  templateUrl: './yes-no-select.component.html'
})
export class YesNoSelectComponent implements OnInit, OnChanges {
  optionValues: YesNoLookupModel[] = [];
  @Input() selectedValue;
  @Input() disabled: boolean;
  @Input() isBoolean: boolean = true;
  @Input() isAmount: boolean = true;
  @Input() value: any;
  @Input() nullable: boolean = false;
  @Input() tabOrder: number;
  @Output() emitData = new EventEmitter<any>();
  yesNoBoolean: YesNoLookupModel[] = [{
    ID: 1,
    Description: 'Yes',
    YesNoValue: true
  }, {
    ID: 2,
    Description: 'No',
    YesNoValue: false
  }
  ];

  yesNoAmount: YesNoLookupModel[] = [{
    ID: 1,
    Description: 'Yes',
    YesNoValue: 'HasAmount'
  }, {
    ID: 2,
    Description: 'No',
    YesNoValue: 'DoesNotHaveAmount'
  }
  ];

  yesNo: YesNoLookupModel[] = [{
    ID: 1,
    Description: 'Yes',
    YesNoValue: 'Yes'
  }, {
    ID: 2,
    Description: 'No',
    YesNoValue: 'No'
  }
  ];

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.optionValues?.length === 0) {
      if (changes.isBoolean && this.isBoolean) {
        if (this.selectedValue === null) {
          this.selectedValue = null;
        } else {
          this.selectedValue = this.selectedValue === 'true' || this.selectedValue === true;
        }
        this.optionValues = this.yesNoBoolean;
      } else if (changes.isAmount && this.isAmount) {
        this.optionValues = this.yesNoAmount;
      } else {
        this.optionValues = this.yesNo;

      }
    }

    if (changes.nullable && this.nullable) {
      this.value = null;
      this.yesNoAmount.unshift({
        ID: 0,
        Description: '--',
        YesNoValue: null
      });
      this.yesNoBoolean.unshift({
        ID: 0,
        Description: '--',
        YesNoValue: null
      });
      this.yesNo.unshift({
        ID: 0,
        Description: '--',
        YesNoValue: null
      });
      if (!this.selectedValue) {
        // this.selectedValue = null;
      }
    }
  }

  emit(event: any) {
    this.emitData.emit(event.value);
  }

}
