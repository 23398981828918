<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      @if (showDisclaimer) {
        <p>*Notes entered here are for INTERNAL USE ONLY and are otherwise not visible outside of this location.</p>
      }
      <app-learn-more [interfaceObjectDesc]="interfaceObjectDesc" [interfaceObjectTypeEnum]="'APPSECTION'"
                      [parentObjectEnum]="parentObjectEnum"></app-learn-more>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <app-list-filters (emitFilter)="processFilter($event)" [filterPage]="'Notes'"
                        [ignoreSavedFilters]="true"></app-list-filters>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">@if (notes) {
          <span>{{ notesTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (notesTotal > currentFilter.rows) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="notesTotal" pageLinkSize="4" [first]="first"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        @if ((currentFilter.filters | json) !== '{}') {
          <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                  pTooltip="Clear Filter" tooltipPosition="bottom"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                  (click)="listFilter.resetFilter()">
          </button>
        }
        <button (click)="refresh()" icon="pi pi-refresh" pButton
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canAdd) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="notesTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
        @if (notesTotal > currentFilter.rows) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="notesTotal" pageLinkSize="3" [first]="first"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Note" [styleClass]="'list-card'">
          <app-notes-form [DBEntity]="DBEntity" [DBEntityID]="DBEntityID" [isEdit]="false" [canEdit]="canTabWrite"
                          [canTabWrite]="canTabWrite" (closeAddEdit)="updateList($event)"></app-notes-form>
        </p-card>
      </div>
    }
    @if (totalLoaded && notesTotal <= 0 && !showAddEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (note of noteList; track note; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i)">
                  <div class="list-header">
                    {{ note.PublicationDate_D | date:'shortDate' }}
                  </div>
                  <div class="list-subheader">
                    <b>Created by:</b> {{ note.CreatedBy }}
                  </div>

                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    @if (canTabWrite && note.CanEdit) {
                      <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                           (click)="deleteNote(note.SharableId)"><i class="fas fa-trash-alt"></i></div>
                    }
                    <div class="icon" (click)="expandData(i)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  @if (note.Body.substring(0, 1) !== '<') {
                    <div class="list-subheader">
                      <div [innerHTML]="note.Body.replaceAll('\n', '<br>')"></div>
                    </div>
                  }
                </div>
                @if (selectedItem === i) {
                  <div class="full-width margin-top-10">
                    <app-notes-form [sharableID]="note.SharableId" [isEdit]="true" [canEdit]="note.CanEdit"
                                    [canTabWrite]="canTabWrite" (closeAddEdit)="updateList($event)"></app-notes-form>
                  </div>
                }
              </div>
            </p-card>
          </div>
        }
      </div>
    }
    @if (dataLoaded && notesTotal > currentFilter.rows) {
      <div class="p-grid p-fluid p-mb-5">
        <div class="col-md-12 col-sm-12 col-xs-12 align-center list-paginator">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="notesTotal" pageLinkSize="4"
                       [first]="first" (onPageChange)="paginate($event)"></p-paginator>
        </div>
      </div>
    }
  </div>
</div>
