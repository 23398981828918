import {Component, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {TimeNewEntryComponent} from '../../../shared/components/time-entry/time-new-entry/time-new-entry.component';

@Component({
  selector: 'app-student-time',
  templateUrl: './roster-student-time.component.html',
  styleUrls: ['./roster-student-time.component.scss'],
})

export class RosterStudentTimeComponent implements OnInit {
  selectedTab = 'DRAFT';
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() PersonID: number;
  @Input() studentFacing: boolean;
  @Input() draftProgramId: number;
  @Input() draftFacetTypeId: number;
  @Input() draftStartDate: string;
  @ViewChild(TimeNewEntryComponent) timeEntry: TimeNewEntryComponent;
  showForm = true;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.canTabWrite) {
      this.selectedTab = 'SUBMITTED';
    }
  }

  collapseAll() {
    this.timeEntry.closeAllAccordionTabs();
  }

  expandAll() {
    this.timeEntry.openAllAccordionTabs();
  }

  reloadForm() {
    this.showForm = false;
    setTimeout(() => {
      this.showForm = true;
    }, 300);
  }

  resettingToDraft(event:any): void {
    this.selectedTab = 'DRAFT';
    this.draftProgramId = event?.draftProgramId;
    this.draftFacetTypeId = event?.draftFacetTypeId;
    this.draftStartDate = event?.draftStartDate;
    this.reloadForm();
  }
}
