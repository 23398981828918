import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {OrganizeProjectContractorsModel, ProjectReportsModel} from '../../../models/project-reports.model';
import {ProjectReportAddNewContractorDialogComponent} from './project-report-add-contractor-dialog/project-report-add-new-contractor-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {DialogService} from 'primeng/dynamicdialog';
import {OrganizationService} from '../../../services/organizations.service';
import {Subject} from 'rxjs';
import {OrganizationProjectModel} from '../../../models/organizations.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ProjectReportsService} from '../../../services/project-reports.service';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {LookupModel} from '../../../models/lookups.model';
import {ProjectReportAddExistingContractorDialogComponent} from './project-report-add-existing-contractor-dialog/project-report-add-existing-contractor-dialog.component';

@Component({
  selector: 'app-project-report-tab-employer',
  templateUrl: './project-report-tab-employer.component.html',
})
export class ProjectReportTabEmployerComponent implements OnInit, OnChanges, OnDestroy {
  inputObjEmployer: GenericSelectModel;
  inputObjTrade: GenericSelectModel;
  inputObjYesNo: GenericSelectModel;
  inputObjProjectOrganizationType: GenericSelectModel;
  setOrganizationTypes;
  isContractEmployer: boolean;
  tempEmployer;
  tempIsUnion: boolean;
  setTradeId;
  setTrades;
  showAdd: boolean = false;
  addProjectOrganization = {} as OrganizationProjectModel;
  projectContractors: OrganizeProjectContractorsModel[];
  currentFilter = new PrimeTableFilterModel();
  organizationTypes: LookupModel[];
  trades: LookupModel[];
  @Input() selectedProjectReport: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() projectReportData: ProjectReportsModel;
  @Input() DBEntity;
  @Input() organizationID: number;

  private ngUnsubscribe = new Subject();

  constructor(private lookupsDataService: LookupsDataService, private dialogService: DialogService,
              private organizationService: OrganizationService, private messageService: MessageService,
              private confirmationService: ConfirmationService, private projectReportsService: ProjectReportsService) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = null;
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    this.initEmployer(false);
    this.initOrganizationTypes();
    this.initTrades();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectReportData && Object.keys(this.projectReportData).length) {
      this.getProjectContractorData();
    }
  }

  ngOnDestroy(): void {
  }

  initOrganizationTypes(): void {
    this.lookupsDataService.getOrganizeProjectOrganizationType(false).then((lookupData: any[]) => {
      this.organizationTypes = lookupData;
    });
  }

  initEmployer(disable: boolean): void {
    this.inputObjEmployer = {
      labelText: 'Employer',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.isContractEmployer ? this.isContractEmployer : null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getEmployersLookupData(true).then((lookupData) => {
      this.inputObjEmployer.data = lookupData;
      this.inputObjEmployer = Object.assign({}, this.inputObjEmployer);
    });
  }

  setEmployerData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.isContractEmployer = event[0].ID;
    } else {
      this.isContractEmployer = null;
    }
  }

  initTrades(): void {
    this.lookupsDataService.getTradesLookupData(false).then((lookupData: any[]) => {
      this.trades = lookupData;
    });
  }

  getTrades(event: any): void {
    if (event) {
      this.setTrades = event;
    } else {
      this.setTrades = null;
    }
  }

  getProjectContractorData() {
    this.projectReportsService.getOrganizeProjectContractors(this.projectReportData.OrganizeProjectID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.projectContractors = res;
          this.projectContractors.forEach(project => {
            project.selectedTrades = [];
            project.selectedTypes = [];
            project.OrganizationTypes.forEach(type => {
              project.selectedTypes.push(type.ID);
            });
            project.Trades.forEach(trade => {
              project.selectedTrades.push(trade.ID);
            });
          });
          console.log(this.projectContractors);
        }
      });
  }

  createContractor() {
    const ref = this.dialogService.open(ProjectReportAddNewContractorDialogComponent, {
      data: {
        projectData: this.projectReportData,
        canPageWrite: this.canTabWrite
      },
      header: 'Add Contractor',
      width: '90%',
      height: '80%'
    });
    ref.onClose.subscribe({
      next: (res) => {
        if (res) {
          this.tempEmployer = res.employer;
          this.isContractEmployer = res.isContractEmployer;
          this.addExistingContractor();
        }
      }
    });
  }

  addExistingContractor() {
    const ref = this.dialogService.open(ProjectReportAddExistingContractorDialogComponent, {
      data: {
        projectData: this.projectReportData,
        canPageWrite: this.canTabWrite,
        newId: this.tempEmployer ? this.tempEmployer.ID : 0,
        orgId: this.organizationID
      },
      header: 'Add Contractor',
      width: '90%',
      height: '30%'
    });
    ref.onClose.subscribe({
      next: (res) => {
        if (res === 'updates') {
          this.getProjectContractorData();
        }
        if (res === 'addNew') {
          this.createContractor();
        }
      }
    });
  }

  deleteContractor(id) {
    this.organizationService.deleteOrganizeEmployer(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Contractor was removed from this project.'
          });
          this.getProjectContractorData();
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        }
      });
  }


  saveContractors() {
    this.projectContractors.forEach(contractor => {
      contractor.Trades = [];
      contractor.OrganizationTypes = [];
      contractor.selectedTrades.forEach(trade => {
        contractor.Trades.push({ID: trade, Description: null});
      });
    });
    this.projectContractors.forEach(contractor => {
      contractor.selectedTypes.forEach(type => {
        contractor.OrganizationTypes.push({ID: type, Description: null});
      });
    });
    this.projectReportsService.updateOrganizeProjectContractors(this.projectReportData.OrganizeProjectID, this.projectContractors)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Contractors have been updated.'
          });
          this.setTrades = [];
          this.setOrganizationTypes = [];
          this.getProjectContractorData();
          this.showAdd = false;
        }, error: (e) => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          console.debug(e);
        }
      });
  }

  promptToDelete(id) {
    this.confirmationService.confirm({
      message: 'You are about to delete a contractor. Are you sure this is what you want to do?',
      header: 'Warning',
      icon: 'pi pi-times',
      accept: () => {
        this.deleteContractor(id);
      }
    });
  }


}
