<form class="margin-top-10" [formGroup]="mainForm">
  <div class="p-grid p-fluid">
    <div class="col-md-12 col-sm-6 col-xs-12 input-container">
      <label>Custom Field <sup class="req">*</sup></label>
      <p-dropdown [options]="customFields" optionLabel="Description" optionValue="CustomFieldID" [filter]="true"
                  filterBy="Description" formControlName="customField"
                  appendTo="body" [disabled]="!canTabWrite || isEdit" (onChange)="setOptions($event)"
                  tabindex="0"></p-dropdown>
    </div>
    @if (fieldType === 'YESNO') {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <div class="radio-container">
          <label>Value <sup class="req">*</sup></label>
          <p-radioButton value="Yes" formControlName="value" id="yes" [styleClass]="'radio-input'"
                         tabindex="0"></p-radioButton>
          <label class="radio-label" for="yes">Yes</label>
          <p-radioButton value="No" formControlName="value" id="no" [styleClass]="'radio-input'"
                         tabindex="0"></p-radioButton>
          <label class="radio-label" for="no">No</label>
        </div>
      </div>
    }
    @if (fieldType === 'CHECKBOX') {
      <div class="input-container margin-top-10">
        <p-checkbox binary="false" trueValue="Yes" formControlName="value" label="Click if True"
                    tabindex="0"></p-checkbox>
      </div>
    }
    @if (fieldType === 'PICKLIST' && selectedAttribute) {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Option <sup class="req">*</sup></label>
        <p-dropdown [options]="selectedAttribute.Options" optionLabel="Description" optionValue="Description"
                    formControlName="value" appendTo="body" tabindex="0"></p-dropdown>
      </div>
    }
    @if (fieldType === 'SLE') {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Text <sup class="req">*</sup></label>
        <input type="text" pInputText formControlName="value" tabindex="0">
      </div>
    }
    @if (fieldType === 'NUMBER' || fieldType === 'NUMERICID') {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Number <sup class="req">*</sup></label>
        <p-inputNumber formControlName="value" [showButtons]="true" tabindex="0"></p-inputNumber>
      </div>
    }
    @if (fieldType === 'DECIMAL2') {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Decimal (Precision 2)</label>
        <p-inputNumber formControlName="value" class="full-width" mode="decimal" [minFractionDigits]="2"
                       [maxFractionDigits]="2" [showButtons]="true"
                       tabindex="0"></p-inputNumber>
      </div>
    }
    @if (fieldType === 'DECIMAL4') {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Decimal (Precision 4)</label>
        <p-inputNumber formControlName="value" class="full-width" mode="decimal" [minFractionDigits]="4"
                       [maxFractionDigits]="4" [showButtons]="true"
                       tabindex="0"></p-inputNumber>
      </div>
    }
    @if (fieldType === 'MLE') {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Text <sup class="req">*</sup></label>
        <textarea pInputTextarea formControlName="value" rows="5" autoResize="autoResize" tabindex="0"></textarea>
      </div>
    }
    @if (fieldType === 'USD') {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Currency <sup class="req">*</sup></label>
        <!--      <p-inputNumber formControlName="value" [showButtons]="true" mode="currency" currency="USD" locale="en-US"></p-inputNumber>-->
        <app-currency-input [initValue]="mainForm.get('value').value"
                            (emitData)="mainForm.get('value').setValue($event)"></app-currency-input>
      </div>
    }
    @if (fieldType === 'DATE') {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="value" appendTo="body" placeholder="mm/dd/yyyy"
                    [showIcon]="true" tabindex="0"></p-calendar>
      </div>
    }
    @if (fieldType === 'TIME') {
      <div class="col-md-12 col-sm-6 col-xs-12 input-container margin-top-10">
        <label>Time</label>
        <p-calendar dateMask formControlName="value" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12"
                    icon="pi pi-clock" [showIcon]="true" tabindex="0"></p-calendar>
      </div>
    }
    @if (canTabWrite) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12"></div>
          <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
            @if (isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                      (click)="cancel()" [disabled]="!mainForm.dirty"></button>
            } @else {
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                      (click)="cancel()"></button>
            }
            @if (!isSaving) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                      tooltipPosition="bottom"
                      (click)="processData()" [className]="'inline-button'"
                      [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
            } @else {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </div>
      </div>
    }
  </div>
</form>
