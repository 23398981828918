import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {take} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {DistributionListDefinitions} from '../../../../definitions/distribution-list.definitions';
import {Router} from '@angular/router';
import {AttributesService} from '../../../../../services/attributes.service';
import {AttributeSelectModel} from '../../../../../models/roster-query-primeng.model';
import {RosterService} from '../../../../../services/roster.service';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {StudentsService} from '../../../../../services/students.service';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-aux-table-preview-dialog',
  templateUrl: './aux-table-preview-dialog.component.html',
  styleUrls: ['./aux-table-preview-dialog.component.scss']
})

export class AuxTablePreviewDialogComponent implements OnInit, OnDestroy {
  isSaving: boolean;
  mainForm: FormGroup;
  tables: any[];
  ContextEnumerator: string;
  category: string = 'union';
  dataTotal: number = 0;
  tableData;
  currentFilter = {} as PrimeTableFilterModel;
  loading: boolean = false;
  // columns: any[];
  attributesOptions: AttributeSelectModel [] = [];
  tableName: string;
  tableWidth: string;
  setOrganizationId: number;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig,
              public ref: DynamicDialogRef, private router: Router, private distributionListDefinitions: DistributionListDefinitions,
              private attributesService: AttributesService, private rosterService: RosterService,
              private studentsService: StudentsService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    if (this.router.url.includes('membership')) {
      this.ContextEnumerator = 'MEMBERSHIP';
      this.category = 'union';
    }
    if (this.router.url.includes('training')) {
      this.ContextEnumerator = 'TRAINING';
      this.category = 'education';
    }
    this.processTable();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  processTable() {
    this.tableName = this.config.data.tableName;
    switch (this.config.data.relatedTable) {
      case 'VOTER' : {
        this.tables = this.distributionListDefinitions.voterCols;
        break;
      }
      case 'STUDENTCLASS' : {
        this.tables = this.distributionListDefinitions.classCols;
        break;
      }
      case 'STUDENTPROGRAM' : {
        this.tables = this.distributionListDefinitions.programCols;
        break;
      }
      case 'COMMUNICATIONSTATUS' : {
        this.tables = this.distributionListDefinitions.communicationCols;
        break;
      }
      case 'EMPLOYMENT' : {
        this.tables = this.distributionListDefinitions.employmentCols;
        break;
      }
      case 'UNIONPOSITION' : {
        this.tables = this.distributionListDefinitions.positionCols;
        break;
      }
      case 'EDUCATIONPOSITION' : {
        this.tables = this.distributionListDefinitions.positionCols;
        break;
      }
      case 'ATTRIBUTE' :
      case 'STUDENTATTRIBUTE' : {
        if (this.config.data.relatedTable === 'STUDENTATTRIBUTE') {
          this.category = 'education';
        } else {
          this.category = 'union';
        }
        this.attributesService.getRosterAvailableAttributesKGrid(this.category)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              // const attributes: any[] = res;
              // this.columns = [];
              this.attributesOptions.push(
                {header: 'First Name', field: 'FirstName', columnType: 'string'},
                {header: 'Last Name', field: 'LastName', columnType: 'string'},
                {header: 'Custom Field', field: 'Attribute', columnType: 'string'},
                {header: 'Value', field: 'Value', columnType: 'string'}
              );
              // attributes.forEach(attribute => {
              //   this.attributesOptions.push(
              //     {header: attribute.Description, field: 'Value', columnType: 'string'}
              //   );
              // });
              this.tables = this.attributesOptions;
            }
          });
        break;
      }
    }
  }

  loadTable(event: any) {
    this.loading = true;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.sortField = event.sortField;
    this.currentFilter.rows = event.rows;
    this.currentFilter.filters = this.config.data.selectedRosterQuery.FilterPrimeNG.filters;
    switch (this.config.data.relatedTable) {
      case 'VOTER' : {
        this.tableWidth = '110px';
        this.rosterService.getRosterVoterGridData(this.currentFilter)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.tableData = res.Data;
              this.dataTotal = res.Total;
              this.loading = false;
            }
          });
        break;
      }
      case 'STUDENTCLASS' : {
        this.tableWidth = '110px';
        this.studentsService.getRosterClassGridData(this.currentFilter)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.tableData = res.Data;
              this.dataTotal = res.Total;
              this.loading = false;
            }
          });
        break;
      }
      case 'STUDENTPROGRAM' : {
        this.tableWidth = '110px';
        this.studentsService.getRosterProgramGridData(this.currentFilter)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.tableData = res.Data;
              this.dataTotal = res.Total;
              this.loading = false;
            }
          });
        break;
      }
      case 'UNIONPOSITION' : {
        this.tableWidth = '160px';
        this.rosterService.getRosterPositionsGridData(this.currentFilter)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.tableData = res.Data;
              this.dataTotal = res.Total;
              this.loading = false;
            }
          });
        break;
      }
      case 'EDUCATIONPOSITION' : {
        this.tableWidth = '160px';
        this.studentsService.getRosterPositionsGrid(this.currentFilter)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.tableData = res.Data;
              this.dataTotal = res.Total;
              this.loading = false;
            }
          });
        break;
      }
      case 'ATTRIBUTE' : {
        this.tableWidth = '160px';
        this.rosterService.getRosterAttributesGridData(this.currentFilter)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.tableData = res.Data;
              this.dataTotal = res.Total;
              this.loading = false;
            }
          });
        break;
      }
      // case 'STUDENTATTRIBUTE' : {
      //   this.tableWidth = '160px';
      //   this.studentsService.getStudentAttributesKGrid(this.currentFilter)
      //     .pipe(take(1))
      //     .subscribe({next:(res) => {
      //       this.tableData = res.Data;
      //       this.dataTotal = res.Total;
      //       this.loading = false;
      //     }});
      //   break;
      // }
    }
  }

  closeDialog() {
    this.ref.destroy();
  }

  cancel() {
    this.closeDialog();
  }
}
