<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                      [parentObjectEnum]="parentObjectEnum"></app-learn-more>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <h3>Filter</h3>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <label>First Name</label>
        <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter"
               (keyup.enter)="filterItems()" tabindex="0"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding margin-top-10">
        <label>Last Name</label>
        <input class="full-width filter" pInputText type="text" [(ngModel)]="searchLastName"
               (keyup.enter)="filterItems()" tabindex="0"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding margin-top-10">
        <app-multi-select class="full-width" [inputObj]="inputObjPosition"
                          (emitData)="getPositionData($event)"></app-multi-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 nopadding">
        <p-checkbox [(ngModel)]="activePosition" binary="true" label="Active" tabindex="0"></p-checkbox>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 nopadding">
        <p-checkbox [(ngModel)]="primaryFinancialContact" binary="true" label="Primary Financial Contact"
                    tabindex="0"></p-checkbox>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 nopadding">
        <div class="list-filter-buttons text-align-right">
          <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clearFilters()">
          </button>
          <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  (click)="filterItems()">
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12">@if (positionList) {
          <span>{{ displayTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (positionList) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="displayTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        @if ((currentFilter.filters | json) !== '{}') {
          <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                  pTooltip="Clear Filter" tooltipPosition="bottom"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                  (click)="clearFilters()">
          </button>
        }
        <button pButton (click)="refresh()" icon="pi pi-refresh"
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canAdd) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="displayTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (positionList) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="displayTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Position" [styleClass]="'list-card'">
          <app-organization-positions-form [organizationID]="organizationId" [isEdit]="false" [canEdit]="canTabWrite"
                                           [canTabWrite]="canTabWrite" [organizationCategory]="organizationCategory"
                                           [showFinancialContact]="showFinancialContact"
                                           (closeAddEdit)="updateList($event)"></app-organization-positions-form>
        </p-card>
      </div>
    }
    @if (totalLoaded && displayTotal <= 0) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    }
    @if (dataLoaded && positionList) {
      <div class="list-container">
        @for (data of positionList; track data; let i = $index) {
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p-card [styleClass]="'list-card'">
              <!--            <div *ngIf="data.ActivePosition" class="green-flag"></div>-->
              @if (!data.ActivePosition) {
                <div class="inactive-flag"></div>
              }
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i)">
                  <div class="list-header">
                    {{ data.FirstName }} {{ data.LastName }}
                  </div>
                  <div class="list-subheader">
                    <strong>{{ data.PositionDescription }}</strong>@if (data.PositionTypeDescription) {
                    <span> | {{ data.PositionTypeDescription }}</span>
                  }
                    @if (data.PrimaryFinancialContact) {
                      <span> | <i class="fas fa-usd-circle"></i></span>
                    }
                    @if (data.StartDate_D) {
                      <span> | <b>Start:</b> {{ data.StartDate_D | date:'shortDate' }}</span>
                    }
                    @if (data.EndDate_D) {
                      <span> | <b>End:</b> {{ data.EndDate_D | date:'shortDate' }}</span>
                    }
                    @if (data.SubjectAreaDescription) {
                      <span> | {{ data.SubjectAreaDescription }}</span>
                    }
                  </div>
                  <div class="list-subheader">
                    <b>Home:</b> {{ data.PrimaryPhoneNumber | phone }}
                    @if (data.SecondaryPhoneNumber) {
                      <span> | <b>Mobile:</b> {{ data.SecondaryPhoneNumber | phone }}</span>
                    }
                    @if (data.ThirdPhoneNumber) {
                      <span> | <b>Business:</b> {{ data.ThirdPhoneNumber | phone }}</span>
                    }
                  </div>
                  @if (data.PrimaryEmailAddress || data.SecondaryEmailAddress) {
                    <div class="list-subheader">
                      @if (data.PrimaryEmailAddress) {
                        <span><b>Primary:</b> {{ data.PrimaryEmailAddress }}</span>
                      }
                      @if (data.SecondaryEmailAddress) {
                        <span> | <b>Secondary:</b> {{ data.SecondaryEmailAddress | phone }}</span>
                      }
                    </div>
                  }
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    <div class="icon hide-on-mobile" pTooltip="Edit" tooltipPosition="bottom"
                         (click)="openPerson(data.PersonID)"><i class="fas fa-user-edit"></i></div>
                    <div class="icon" (click)="expandData(i)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                    @if (canTabWrite && canDelete && data.CanDelete) {
                      <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                           (click)="deletePosition(data.OrganizationPositionID)"><i class="fas fa-trash-alt"></i></div>
                    }
                  </div>
                </div>
                @if (selectedItem === i) {
                  <div class="full-width margin-top-10">
                    <app-organization-positions-form [organizationPositionID]="data.OrganizationPositionID"
                                                     [organizationCategory]="organizationCategory" [isEdit]="true"
                                                     [canEdit]="data.CanEdit"
                                                     [canTabWrite]="data.CanEdit && canTabWrite"
                                                     [showFinancialContact]="showFinancialContact"
                                                     (closeAddEdit)="updateList($event)"></app-organization-positions-form>
                  </div>
                }
              </div>
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
