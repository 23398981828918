import {Component, Input, OnInit} from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {AlertDialogComponent} from '../alert/alert-dialog.component';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent implements OnInit {
  @Input() OrganizatioinID: number;
  @Input() InterfaceObjectEnum: string;
  @Input() AlertNavigateTo: string;
  @Input() AlertTitle: string;
  @Input() AlertMessage: string;

  constructor(public dialogService: DialogService) {
  }

  ngOnInit(): void {
  }

  openAlert(): void {
    this.dialogService.open(AlertDialogComponent, {
      data: {
        isEdit: true,
        isDialog: true,
        organizationId: this.OrganizatioinID,
        interfaceObjectEnum: this.InterfaceObjectEnum,
        navigateTo: this.AlertNavigateTo,
        title: this.AlertTitle,
        message: this.AlertMessage
      },
      header: 'Create Alert',
      width: '70%',
      height: '70%'
    });
  }
}
