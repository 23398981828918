<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12 text-align-middle p-button-auto-width p-mb-2 margin-top-10">
    <button pButton  (click)="communicationType = 'QR'"
            pTooltip="Email" tooltipPosition="bottom" class="p-mr-2"><i class="fas fa-envelope p-mr-1"></i> QR Code</button>
    <button pButton  (click)="emailLink(); communicationType = 'Email'"
            pTooltip="Email" tooltipPosition="bottom" class="p-mr-2"><i class="fas fa-envelope p-mr-1"></i> Email</button>
    <button pButton (click)="textLink(); communicationType = 'Text'"
            pTooltip="Text" tooltipPosition="bottom" class="p-mr-2"><i class="fa-solid fa-mobile p-mr-1"></i> Text</button>
  </div>
  @if ( communicationType === 'QR') {
    @if(applicantData) {
      <div class="col-md-12 col-sm-12 col-xs-12 text-align-middle " style="max-height: 400px">
        <img height="100%" [src]="applicantData.ExternalQRUrl"  style="max-height: 400px" />
      </div>
    } @else {
      <span class="fa fa-spinner list-spinner"></span>
    }

  }
  @if (communicationType === 'Email') {
    <app-adhoc-email-form [recipients]="personData" [subject]="'Application'" [presetBody]="presetBody" [removeSubscribe]="true"
                          [senderName]="contextService.contextObject.PersonName"></app-adhoc-email-form>
  }
  @if (communicationType === 'Text') {
    <div class="col-md-12 col-sm-12 col-xs-12 text-align-middle p-button-auto-width p-mb-2 margin-top-10">
      <app-adhoc-phone-form [recipients]="personData" [presetText]="presetText"></app-adhoc-phone-form>
    </div>
  }
</div>
