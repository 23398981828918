import {Injectable} from '@angular/core';
import {ConfirmationService} from 'primeng/api';

@Injectable()
export class DialogUtility {

  constructor(private confirmationService: ConfirmationService) {
  }

  public promptReturnToList(answerOk: () => void) {
    const startDesc: string = (sessionStorage.getItem('sessionViewType')) ?
      sessionStorage.getItem('sessionViewType').toLowerCase() : 'list';
    this.confirmationService.confirm({
      acceptLabel: 'Ok',
      rejectVisible: false,
      message: `You do not have permission to retrieve this record, or it may not exist.
        Please return to the ${startDesc} and try again. Thanks so much!`,
      header: 'Retrieve Prevented/Failed',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        answerOk();
      }
    });
  }

  public promptToCopy(answerYes: () => void, answerNo: () => void) {
    this.confirmationService.confirm({
      message: 'The selected item will be copied. Are you sure this is what you want to do?',
      header: 'Copy Confirmation',
      icon: 'fas fa-copy',
      accept: () => {
        answerYes();
      },
      reject: () => {
        answerNo();
      }
    });
  }

  public promptToDelete(answerYes: () => void, answerNo: () => void) {
    this.confirmationService.confirm({
      message: 'The selected item will be deleted. Are you sure this is what you want to do?',
      header: 'Delete Confirmation',
      icon: 'fas fa-trash-alt',
      accept: () => {
        answerYes();
      },
      reject: () => {
        answerNo();
      }
    });
  }

  public promptToExportData(answerYes: () => void, answerNo: () => void) {
    this.confirmationService.confirm({
      message: 'Please be aware that exporting times may vary (even up to several minutes). Thank you for your patience.',
      acceptLabel: 'Continue',
      rejectLabel: 'Cancel',
      header: 'Data Export',
      icon: 'fa fa-file-excel',
      accept: () => {
        answerYes();
      },
      reject: () => {
        answerNo();
      }
    });
  }

  public promptToExportPDF(answerYes: () => void, answerNo: () => void) {
    this.confirmationService.confirm({
      message: 'Please be aware that PDF creation times may vary (even up to several minutes). Thank you for your patience.',
      acceptLabel: 'Continue',
      rejectLabel: 'Cancel',
      header: 'Create PDF',
      icon: 'fa fa-file-pdf',
      accept: () => {
        answerYes();
      },
      reject: () => {
        answerNo();
      }
    });
  }

  // public promptToSave(description: string, answerYes: () => void, answerNo: () => void) {
  //   const desc: string = (description) ? `'${description}'` : 'Data';
  //   this.confirmationService.confirm({
  //     key: 'formChanged',
  //     defaultFocus: 'none',
  //     header: 'Reminder to Save',
  //     acceptLabel: 'Save',
  //     rejectLabel: 'Ignore',
  //     message: `${desc} has been modified. Please save, or all changes will be lost.`,
  //     accept: () => {
  //       answerYes();
  //     },
  //     reject: () => {
  //       answerNo();
  //     }
  //   });
  // }

  public promptValidationFailed(answerOk: () => void) {
    this.confirmationService.confirm({
      key: 'validationFailed',
      acceptLabel: 'Ok',
      rejectVisible: false,
      message: 'Required fields must have a value. Please complete or reset to continue. Thanks!',
      header: 'Validation Failed',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        answerOk();
      }
    });
  }
}
