import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';

import {UserRegistrationModel} from '../../models/user-registration.model';
import {UserRegistrationService} from '../../services/user-registration.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ContextModel} from '../../models/context.model';
import {EnvironmentsService} from '../../services/environments.service';
import {ContextService} from '../../services/context.service';
import {ConfirmValidationUtility} from '../../shared/utilities/confirm-validation.utility';
import {Title} from '@angular/platform-browser';

@Component({
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit, OnDestroy {
  urForm: FormGroup;
  userRegistrationModel: UserRegistrationModel;
  passwordType = 'password';
  passwordConfirmType = 'password';
  isLoading: boolean = false;
  requirements: boolean = false;
  tenant: string;
  logo: string;
  reregister: boolean = false;

  private ngUnsubscribe = new Subject();

  constructor(private userRegistrationService: UserRegistrationService,
              private router: Router, private route: ActivatedRoute,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private environmentsService: EnvironmentsService,
              private contextService: ContextService, private confirmValidationUtility: ConfirmValidationUtility,
              private titleService: Title) {
    this.urForm = this.formBuilder.group({
      name: new FormControl(localStorage.getItem('rememberMe'), [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      password: new FormControl(null, [Validators.required,
        this.regexValidator(new RegExp('^.*[A-Za-z0-9$@#!%*?&].{9,}'), {length: true}),
        this.regexValidator(new RegExp('^.*[a-z]'), {lowercase: true}),
        this.regexValidator(new RegExp('^.*[A-Z]'), {uppercase: true}),
        this.regexValidator(new RegExp('^.*[0-9]'), {number: true}),
        this.regexValidator(new RegExp('^.*[$@#!%*?&]'), {special: true}),
        this.regexValidator(new RegExp('^(?!.*([a-zA-Z0-9$@#!%*?&])\\1{2,})'), {repeats: true})
      ]),
      // password: new FormControl('', [Validators.required,
      //   Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])(?!.*([a-zA-Z0-9])\\1{2,})[A-Za-zd$@$!%*?&].{9,}')]),
      showPassword: new FormControl(false),
      passwordConfirm: new FormControl(null, Validators.required),
      showPasswordConfirm: new FormControl(false)
    }, {
      validator: [this.confirmValidationUtility.ConfirmedValidator('password', 'passwordConfirm'),
        this.customValidator('name', 'password')]
    });
  }

  ngOnInit(): void {
    this.getBaseUrl().then(() => {
      this.route.queryParams.subscribe({
        next: (params) => {
          if (params && params.reregister) {
            this.reregister = params.reregister;
          } else {
            this.reregister = false;
          }
          this.onChanges();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onChanges(): void {
    this.urForm.controls.showPassword.valueChanges.subscribe({
      next: (val) => {
        this.passwordType = val === true ? 'text' : 'password';
      }
    });

    this.urForm.controls.showPasswordConfirm.valueChanges.subscribe({
      next: (val) => {
        this.passwordConfirmType = val === true ? 'text' : 'password';
      }
    });
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  getBaseUrl() {
    return new Promise((resolve, reject): void => {

      this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });
      // switch (true) {
      //   case environment.environmentName === 'dev':
      //   case environment.environmentName === 'staging':
      //   case environment.environmentName === 'demo':
      //     this.environmentsService.getEnvironment(environment.environmentName).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      //   case environment.local
      //   && environment.environmentName !== 'dev'
      //   && environment.environmentName !== 'demo'
      //   && environment.environmentName !== 'staging':
      //     if (this.tenant) {
      //       this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //         if (rtnTenant) {
      //           this.setUrl(rtnTenant);
      //         }
      //       });
      //     }
      //     break;
      //   case !environment.local:
      //     this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //       if (rtnTenant) {
      //         this.setUrl(rtnTenant);
      //       }
      //     });
      //     break;
      // }

      // if(environment.environmentName === 'demo') {
      //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.environmentName === 'dev') {
      //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
      //   this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //     if(rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(!environment.local) {
      //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // }
      resolve(null);
    });
  }

  setUrl(rtnTenant): void {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
  }

  toggleRequirements(): void {
    this.requirements = this.requirements !== true;
  }

  register(): void {
    this.isLoading = true;
    if (this.contextService && this.contextService.contextObject && this.contextService.contextObject.tenantName) {
      this.userRegistrationModel = {
        UserName: this.urForm.controls.name.value,
        Password: this.urForm.controls.password.value,
        ConfirmPassword: this.urForm.controls.passwordConfirm.value,
        TenantName: this.contextService.contextObject.tenantName
      };
      this.userRegistrationService.registerUser(this.userRegistrationModel)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (msg) => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: msg, life: 4500});
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 5000);
          }, error: (e) => {
            this.messageService.add({severity: 'error', summary: 'Failure', detail: e, life: 4500});
            this.isLoading = false;
          }
        });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Failure',
        detail: 'Our apologies, but we don\'t recognize the domain you are coming from at the moment. Please try again, or contact your organization for more information.',
        life: 4500
      });
      this.isLoading = false;
    }
  }

  customValidator(unControlName: string, pswdControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[unControlName];
      const matchingControl = formGroup.controls[pswdControlName];
      if (matchingControl.errors && !matchingControl.errors?.customValidator) {
        return;
      }
      const am: number = control.value.indexOf('@');
      const username: string = control.value.substring(0, am);
      const domain: string = control.value.substring(am + 1, control.value.length);
      if (matchingControl.value.indexOf(username) > -1 || matchingControl.value.indexOf(domain) > -1) {
        matchingControl.setErrors({customValidator: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
