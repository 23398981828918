import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {LookupModel, OrganizationsByObjectAccessLookupModel, PeriodsLookupModel} from '../models/lookups.model';
import {LookupsService} from './lookups.service';
import {CommunicationTemplatesService} from './communication-templates.service';
import {OrganizationService} from './organizations.service';
import {SurveyQuestionsService} from './survey-questions.service';
import {TenantConfigDataService} from './tenant-config-data.service';
import {ProgramsService} from './programs.service';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LookupsDataService {
  lookupModel: LookupModel[];
  organizationsByObjectAccessLookupModel: OrganizationsByObjectAccessLookupModel[];

  private ngUnsubscribe = new Subject();

  constructor(private lookupsService: LookupsService, private communicationTemplatesService: CommunicationTemplatesService,
              private organizationService: OrganizationService, private surveyQuestionsService: SurveyQuestionsService,
              private tenantConfigDataService: TenantConfigDataService, private programsService: ProgramsService,
              private datePipe: DatePipe) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  addPlaceholder(data: any[]) {
    if (data[0] !== undefined && data[0].ID !== null) {
      data.unshift({
        ID: null,
        Description: '--',
        Abbreviation: '--'
      });
    }
    return data;
  }

  getAgentFactorsbyOrganizationIdLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAgentFactorsbyOrganizationIdLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAgentFactorsLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAgentFactorsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAgentFactorOptionsLookupData(factorId: number, organizationId?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAgentFactorOptionssLookup(factorId, organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAttendanceUploadFormatLookupData() {
    return new Promise((resolve, reject) => {
      const rtnData: LookupModel[] = [];
      rtnData.push({
        ID: null,
        Description: '--',
        value: null
      });
      rtnData.push({
        ID: null,
        Description: 'Member Number List',
        value: 'MEMBERNUMBERLIST'
      });
      rtnData.push({
        ID: null,
        Description: 'Email Address List',
        value: 'EMAILADDRESSLIST'
      });
      rtnData.push({
        ID: null,
        Description: 'Phone Number List',
        value: 'PHONENUMBERLIST'
      });
      resolve(rtnData);
    });
  }

  getAttributeDataTypeByEntityLookupData(entity: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAttributeDataTypeByEntityLookup(entity)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAutomationScheduleTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAutomationScheduleTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAutoPayLookupData(argBillingCategory: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAutoPayLookup(argBillingCategory)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProfileActivitiesLookupData(placeholder) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProfileActivities()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAvailableActivitiesLookupData(personId: number, context?: string, applicant?: boolean, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAvailableActivities(personId, context, applicant)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res.unshift({
                ActivityID: null,
                ActivityDescription: '--',
                Active: true,
                ActivityDateFlag: 'X',
                ApplicationDateFlag: 'X',
                WaiveInitiationFeeFlag: 'X',
                BargainingUnitFlag: 'X',
                OrganizationFlag: 'X',
                PathwayFlag: 'X',
                PersonProfileTypeFlag: 'X',
                PersonProfileClassificationFlag: 'X',
                DOBFlag: 'X',
                SSNSINFlag: 'X'
              });
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAvailableActivitiesNewMemberLookupData(orgId: number, profileType: number, applicant?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAvailableActivitiesNewMember(orgId, profileType, applicant)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res.unshift({
              ActivityID: null,
              ActivityDescription: '--',
              Active: true,
              ActivityDateFlag: 'X',
              ApplicationDateFlag: 'X',
              WaiveInitiationFeeFlag: 'X',
              BargainingUnitFlag: 'X',
              OrganizationFlag: 'X',
              PathwayFlag: 'X',
              PersonProfileTypeFlag: 'X',
              PersonProfileClassificationFlag: 'X',
              DOBFlag: 'X',
              SSNSINFlag: 'X'
            });
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAvailableApprenticeHoursTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAvailableApprenticeHoursType()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAvailableBillingAdjustmentsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAvailableBillingAdjustmentsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAvailableOrganizationBillingPeriodsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAvailableOrganizationBillingPeriodsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAvailableCommunicationSendersLookupData(communicationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAvailableCommunicationSendersLookup(communicationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            let newRes = [];
            let i: number;
            const ilen: number = res.length;
            for (i = 0, ilen; i < ilen; i++) {
              newRes.push({
                ID: res[i].SenderEmailAddress,
                Description: res[i].SenderDisplayName + ' [' + res[i].SenderEmailAddress + ']',
                SenderDisplayName: res[i].SenderDisplayName
              });
            }
            newRes = this.addPlaceholder(newRes);
            resolve(newRes);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBargainingUnitLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBargainingUnitLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBargainingUnitByOrgLookupData(organizationId: number, currentId?: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBargainingUnitByOrgLookup(organizationId, currentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBillingAdjustmentAnniversaryLookupData() {
    return new Promise((resolve, reject) => {
      const rtnData: LookupModel[] = [];
      rtnData.push({
        ID: null,
        Description: '--',
        value: null
      });
      rtnData.push({
        ID: null,
        Description: 'Initiation Date',
        value: 'INITIATIONDATE'
      });
      rtnData.push({
        ID: null,
        Description: 'Retirement Date',
        value: 'RETIREMENTDATE'
      });
      rtnData.push({
        ID: null,
        Description: 'A Specific Month',
        value: 'CUSTOM'
      });
      resolve(rtnData);
    });
  }

  getBillingAdjustmentAnniversaryCustomLookupData() {
    return new Promise((resolve, reject) => {
      const rtnData: LookupModel[] = [];
      rtnData.push({
        ID: null,
        Description: '--',
        value: null
      });
      rtnData.push({
        ID: null,
        Description: 'January',
        value: '1900-01-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'February',
        value: '1900-02-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'March',
        value: '1900-03-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'April',
        value: '1900-04-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'May',
        value: '1900-05-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'June',
        value: '1900-06-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'July',
        value: '1900-07-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'August',
        value: '1900-08-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'September',
        value: '1900-09-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'October',
        value: '1900-10-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'November',
        value: '1900-11-01T00:00:00'
      });
      rtnData.push({
        ID: null,
        Description: 'December',
        value: '1900-12-01T00:00:00'
      });
      resolve(rtnData);
    });
  }

  getBillingAdjustmentFrequencyLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBillingAdjustmentsFrequencyLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBillingAdjustmentsByOrganizationIdLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBillingAdjustmentsByOrganizationIdLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBillingAdjustmentsByPersonIdLookupData(personId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBillingAdjustmentsByPersonIdLookup(personId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBillingAdjustmentTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBillingAdjustmentTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBillingAdjustmentTypesByPaymentOptionsLookupData(paymentsOnly: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBillingAdjustmentTypesByPaymentOptionsLookup(paymentsOnly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBillingPeriodByDateLookupData(date: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBillingPeriodByDateLookup(date)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBillingPeriodByOrganizationLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBillingPeriodByOrganizationLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: PeriodsLookupModel[]) => {
            res = this.addPlaceholder(res);
            res.forEach(x => {
              if (x.ID) {
                x.CodeYear = x.Code + '/' + x.Year;
              } else {
                x.CodeYear = '--';
              }
            });
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBillingPeriodsLookupData(snapshotsonly?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBillingPeriodsLookup(snapshotsonly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCampaignProspectStatusesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCampaignProspectStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCampaignStatusesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCampaignStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCampaignTypesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCampaignTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCaseLanguageTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCaseLanguageTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCaseSubjectsLookupData(casetypeid?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCaseSubjectsLookup(casetypeid)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCaseTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCaseTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProspectExperienceLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProspectExperienceLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProspectIssuesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProspectIssuesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProspectEmployersLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProspectEmployerLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCertificationsLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCertificationsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getClassAttendanceStatusesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getClassAttendanceStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getClassAttendancePeriodsLookupData(classId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getClassAttendancePeriodsLookup(classId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getClassStaffFiltersLookupData(trainingCenterId?: number, courseid?: number, start_dt?: string, end_dt?: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getClassStaffFiltersLookup(trainingCenterId, courseid, start_dt, end_dt)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getClassStaffFilterFieldLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getClassStaffFilterFieldLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getClassStatusesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getClassStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getModuleTemplatesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrainingModuleTemplatesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getModuleTemplatesDownstreamLookupData(orgId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrainingModuleTemplatesDownstreamLookup(orgId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAllClassesLookupData(filterData, startDate: string, endDate: string, orgId: number, courseId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAllClassesLookup(filterData, startDate, endDate, orgId, courseId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const data: LookupModel[] = [];
            let desc: string;
            data.push({
              ID: null,
              Description: '--',
              Abbreviation: '--'
            });
            res.Data.forEach((x) => {
              desc = x.CourseTypeDescription + ' - ' + x.CourseDescription + ' - ' + this.datePipe.transform(x.StartDate_D, 'shortDate');
              if (x.StartTime_T) {
                desc += ' ' + this.datePipe.transform(x.StartTime_T, 'shortTime');
              }
              desc += ' to ' + this.datePipe.transform(x.EndDate_D, 'shortDate');
              if (x.EndTime_T) {
                desc += ' ' + this.datePipe.transform(x.EndTime_T, 'shortTime');
              }
              data.push({
                ID: x.ClassID,
                Description: desc,
                Abbreviation: null
              });
              desc = null;
            });
            resolve(data);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getClassInstructorsLookupData(orgId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getClassInstructorsLookup(orgId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCognitiveLevelsLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCognitiveLevelsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCombinedDistributionListsLookup(organizationId: number, context: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCombinedDistributionListsLookup(organizationId, context)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCommunicationsBySurveyIdLookupData(surveyId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCommunicationsBySurveyIdLookup(surveyId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCommunicationTemplatesLookup(organizationId: number, templateType?: string) {
    return new Promise((resolve, reject) => {
      this.communicationTemplatesService.getCommunicationTemplatesLookup(organizationId, templateType)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCommunicationTemplatesByContextLookup(personId: number, context: string, templateType: string) {
    return new Promise((resolve, reject) => {
      this.communicationTemplatesService.getCommunicationTemplatesByContextLookup(personId, context, templateType)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getContactCategoriesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getContactCategoriesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getContextsLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getContextsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getContractsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getContractsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getContractTypesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getContractTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCountriesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCountriesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            let countries: LookupModel[];
            countries = res.filter(x => x.Description === 'United States' || x.Description === 'Canada');
            countries = this.addPlaceholder(countries);
            resolve(countries);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCountriesUnauthLookupData(tenantEnum: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCountriesUnauthLookup(tenantEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            let countries: LookupModel[];
            countries = res.filter(x => x.Description === 'United States' || x.Description === 'Canada');
            countries = this.addPlaceholder(countries);
            resolve(countries);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCoursesByDownstreamOrganizationIdLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCoursesByDownstreamOrganizationIdLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCoursesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCoursesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCourseTypesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCourseTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCoursesUnauthLookupData(tenantEnum: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCoursesUnauthLookup(tenantEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCoursesByClassIdLookupData(classId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCoursesByClassIdLookup(classId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStudentsByClassId(classId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStudentsByClassIdLookup(classId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPassFailLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPassFail()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCraftsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCraftsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getDocumentContextsLookupData(interfaceObjectEnum: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getDocumentContextsLookup(interfaceObjectEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getDocumentContextEntitiesLookup(parentinterfaceobject: string, childinterfaceobject: string, parentkeyid: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getDocumentContextEntitiesLookup(parentinterfaceobject, childinterfaceobject, parentkeyid)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getDownstreamOrganizationPositionsLookup(organizationId: number, category: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getDownstreamOrganizationPositionsLookup(organizationId, category)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getDuesGroupsLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getDuesGroupsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEducationalOrganizationsLookupData(interfaceObjectEnum: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEducationalOrganizationsLookup(interfaceObjectEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEducationLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEducationLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEmployersLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEmployersLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }


  getEmployersLookupByEnumData(interfaceObjectEnum: string, placeholder?: boolean, cached?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEmployersLookupByEnum(interfaceObjectEnum, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEmployersUnauthLookupData(tenantEnum: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEmployersUnauthLookup(tenantEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEthnicityLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEthnicityLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEventAttendeeStatusesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEventAttendeeStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEventInstanceStatusesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEventInstanceStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEventInstanceAttendanceStatusesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEventInstanceAttendanceStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEventInstanceAttendancePeriodsLookupData(eventInstanceId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEventInstanceAttendancePeriodsLookup(eventInstanceId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEventLocationByInstanceLookupData(EventInstanceID: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEventLocationByInstanceLookup(EventInstanceID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEventLocationsLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEventLocationsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEventOnlineMeetingTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEventOnlineMeetingTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEventsLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEventsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getFacetsLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getFacetsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getFacetCategoriesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getFacetCategoriesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getFacetTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getFacetTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getFacetsByFacetTypeLookupData(facetTypeId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getFacetsByFacetTypeLookup(facetTypeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getFacetTypesByProgramStudentLookupData(programStudentId, studentFacing?, cached?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getFacetTypesByProgramStudentLookup(programStudentId, studentFacing, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getGenericLookupData(lookupEnum: string, previousValue?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getGenericLookup(lookupEnum, previousValue)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getGoogleDriveOrganizationsLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getGoogleDriveOrganizationsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getHonorificsLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getHonorificsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getInterfaceObjectAppsLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getInterfaceObjectAppsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getInterfaceObjectElementsLookupData(appId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getInterfaceObjectElementsLookup(appId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getJotFormsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getJotFormsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getLanguagesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getLanguagesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getLanguagesUnauthLookupData(tenantEnum: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getLanguagesUnauthLookup(tenantEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getLCMSSystemsLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getLCMSSystemsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMailMergeTemplateTypesLookupData(mailMergeTypeId: number, organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMailMergeTemplateTypesLookup(mailMergeTypeId, organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAttachmentTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAttachmentTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMailMergeTypesLookupData(context: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMailMergeTypesLookup(context)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMembershipOnlyOrganizationsLookupData(data) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMembershipOnlyOrganizationsLookup(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMembershipOrganizationsByOrganizationGroupLookupData(organizationId: number, membershiponly?: boolean) {
    return new Promise((resolve, reject) => {
      this.organizationsByObjectAccessLookupModel = [];
      this.lookupsService.getMembershipOrganizationsByOrganizationGroupLookup(organizationId, membershiponly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMerchantAccountsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMerchantAccountsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMilitaryLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMilitaryLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMilitaryStatusLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMilitaryStatusLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMarriageStatusLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMarriageStatusLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getShirtSizeLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getShirtSizeLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCitizenshipLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCitizenshipLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMoneyManagerPaymentTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMoneyManagerPaymentTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMoneyManagerStatusesByOrganizationIdLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMoneyManagerStatusesByOrganizationIdLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMoneyManagerStatusesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMoneyManagerStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOccupationTiersLookupData(previousValue?: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOccupationTiersLookup(previousValue)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOccupationsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOccupationsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOccupationClassesLookupData(previousValue?: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOccupationClassesLookup(previousValue)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationBillingAdjustmentTypesLookupData(organizationId: number, pct?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationBillingAdjustmentTypesLookup(organizationId, pct)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationCategoriesLookupData(hasPositionsOnly?: boolean, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationCategoriesLookup(hasPositionsOnly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationChildTypesLookupData(organizationId: number, category: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationChildTypesLookup(organizationId, category)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationByChildLookupData(organizationId: number, placeholder: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationByChildLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsByParentLookupData(context: string, Id: number, employerOrgId?: number, placeholder?: boolean, cached?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationsByParentLookup(context, Id, employerOrgId, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationListByObjectAccessLookupData(componentEnum: string, membershipOnly?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationListByObjectAccessLookup(componentEnum, membershipOnly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res.forEach(org => {
              org.Description = org.Description.replace(/&nbsp;/g, '-');
              if (org.Abbreviation) {
                if (org.Abbreviation.replace(/&nbsp;/g, '') !== '') {
                  org.Abbreviation = org.Abbreviation.replace(/&nbsp;/g, '-');
                } else {
                  org.Abbreviation = org.Description;
                }
              }
            });
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsByAccessLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationsByAccessLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res.forEach(org => {
              org.Description = org.Description.replace(/&nbsp;/g, '-');
              if (org.Abbreviation) {
                if (org.Abbreviation.replace(/&nbsp;/g, '') !== '') {
                  org.Abbreviation = org.Abbreviation.replace(/&nbsp;/g, '-');
                } else {
                  org.Abbreviation = org.Description;
                }
              }
            });
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsByAccessTypeIdLookupData(interfaceObjectEnum: string, typeId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationsByAccessTypeIdLookup(interfaceObjectEnum, typeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            // res = this.addPlaceholder(res);
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getGeneralCommitteeLookupData(interfaceObjectEnum: string, pageEnum: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getGeneralCommitteeLookup(interfaceObjectEnum, pageEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            // res = this.addPlaceholder(res);
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getLocalLookupData(interfaceObjectEnum: string, pageEnum: string, routeorganizationid: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getLocalLookup(interfaceObjectEnum, pageEnum, routeorganizationid)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            // res = this.addPlaceholder(res);
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationBillingPeriodByOrganizationLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationBillingPeriodByOrganizationLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: PeriodsLookupModel[]) => {
            res = this.addPlaceholder(res);
            res.forEach(x => {
              if (x.ID) {
                x.CodeYear = x.Code + '/' + x.Year;
              } else {
                x.CodeYear = '--';
              }
            });
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsByMailMergeTypeIdLookupData(typeId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationsByMailMergeTypeIdLookup(typeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsByMerchantAccountIdLookupData(merchantAccountId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationsByMerchantAccountIdLookup(merchantAccountId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const data: OrganizationsByObjectAccessLookupModel[] = [];
            res = this.addPlaceholder(res);
            res.forEach(x => {
              if (x.Abbreviation) {
                data.push(x);
              }
            });
            // this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            data.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(data);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsByPersonTransactionHistoryLookupData(personId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationsByPersonTransactionHistoryLookup(personId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const data: OrganizationsByObjectAccessLookupModel[] = [];
            res = this.addPlaceholder(res);
            res.forEach(x => {
              if (x.Abbreviation) {
                data.push(x);
              }
            });
            // this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            data.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(data);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsByPersonByEnumTransactionHistoryLookupData(objectName: string, personId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationsByPersonByEnumTransactionHistoryLookup(objectName, personId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const data: OrganizationsByObjectAccessLookupModel[] = [];
            res = this.addPlaceholder(res);
            res.forEach(x => {
              if (x.Abbreviation) {
                data.push(x);
              }
            });
            // this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            data.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(data);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsByObjectAccessLookupData(componentEnum: string, membershiponly?: boolean, context?: string, placeholder?: boolean, parentsOnly?: boolean, activeOnly?: boolean, cache?: boolean) {
    return new Promise((resolve, reject) => {
      const paramOrgOnly = (membershiponly) ? membershiponly : false;
      const paramContext = (context) ? context : 'membership';
      this.organizationsByObjectAccessLookupModel = [];
      this.lookupsService.getOrganizationsByObjectAccessLookup(componentEnum, paramOrgOnly, paramContext, parentsOnly, activeOnly, cache)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsLocalsByTypeData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationsLocalsByType()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsByOrganizationGroupLookupData(interfaceObjectEnum: string, organizationId: number, membershiponly?: boolean) {
    return new Promise((resolve, reject) => {
      this.organizationsByObjectAccessLookupModel = [];
      this.lookupsService.getOrganizationsByOrganizationGroupLookup(interfaceObjectEnum, organizationId, membershiponly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsDataPerspectiveMembershipLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.organizationsByObjectAccessLookupModel = [];
      this.lookupsService.getOrganizationsDataPerspectiveMembershipLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsPerspectiveMembershipLookupData(ignorePerspective: boolean, indent: boolean) {
    return new Promise((resolve, reject) => {
      this.organizationsByObjectAccessLookupModel = [];
      this.lookupsService.getOrganizationsPerspectiveMembershipLookup(ignorePerspective, indent)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAvailableMemberStatementBillingPeriodsLookupData(routeorganizationid: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAvailableMemberStatementBillingPeriodsLookup(routeorganizationid)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            res.forEach(x => {
              if (x.ID) {
                x.CodeYear = x.Code + '/' + x.Year;
              } else {
                x.CodeYear = '--';
              }
            });
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationBillingPeriodLookupData(routeorganizationid: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationBillingPeriodLookup(routeorganizationid)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            res.forEach(x => {
              if (x.ID) {
                x.CodeYear = x.Code + '/' + x.Year;
              } else {
                x.CodeYear = '--';
              }
            });
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationByObjectAccessLookupData(componentEnum: string, routeorganizationid: number, membershipOnly: boolean, context: string) {
    return new Promise((resolve, reject) => {
      this.organizationsByObjectAccessLookupModel = [];
      this.lookupsService.getOrganizationByObjectAccessLookup(componentEnum, routeorganizationid, membershipOnly, context)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsDataPerspectiveTrainingLookupData() {
    return new Promise((resolve, reject) => {
      this.organizationsByObjectAccessLookupModel = [];
      this.lookupsService.getOrganizationsDataPerspectiveTrainingLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            this.organizationsByObjectAccessLookupModel = res;
            const regex: RegExp = /&nbsp;/g;
            this.organizationsByObjectAccessLookupModel.forEach(x => {
              x.Description = x.Description.replace(regex, '-');
              if (x.Abbreviation) {
                if (x.Abbreviation.replace(regex, '') !== '') {
                  x.Abbreviation = x.Abbreviation.replace(regex, '-');
                } else {
                  x.Abbreviation = x.Description;
                }
              }
            });
            resolve(this.organizationsByObjectAccessLookupModel);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationSharableAssigneesLookupData(organizationId: number, pageEnum: string, context?: string) {
    return new Promise((resolve, reject) => {
      this.organizationService.getOrganizationSharableAssignees(organizationId, pageEnum, context)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            let rtnData: LookupModel[] = [];
            res.forEach(x => {
              rtnData.push({
                ID: x.PersonID,
                Description: `${x.FirstName} ${x.LastName} - ${x.Positions}`
              });
            });
            rtnData = this.addPlaceholder(rtnData);
            resolve(rtnData);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationStatusesLookupData(category: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationStatusesLookup(category)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEmployerTypesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEmployerTypeLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEmployerClassificationsLookupData(cagtegory: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEmployerClassificationsLookup(cagtegory)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getEnterpriseTypesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getEnterpriseTypeLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getMinorityStatusLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getMinorityStatusLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSubdivisionLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSubdivisionLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getContractStatusesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getContractStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationMemberLookupData(organizationId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationMemberLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCharterStateProvinceLookupData(id: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCharterStateProvinceLookup(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationsUnauthLookupData(tenantEnum: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationsUnauthLookup(tenantEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPaymentFileFormatsLookupData(employerId: number, billingAdjustmentTypeId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPaymentFileFormatsLookup(employerId, billingAdjustmentTypeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const rtn: LookupModel[] = [];
            rtn.push({
              ID: null,
              Description: '--'
            });
            res.forEach(x => {
              rtn.push({
                ID: null,
                Description: x
              });
            });
            resolve(rtn);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPaymentTypesLookupData(excludeSystemOnlyTypes?: boolean, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPaymentTypesLookup(excludeSystemOnlyTypes)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getHoursFileFormatsLookupData(orgId, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getHoursFileFormatsLookup(orgId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const rtn: LookupModel[] = [];
            rtn.push({
              ID: null,
              Description: '--'
            });
            res.forEach(x => {
              rtn.push({
                ID: null,
                Description: x
              });
            });
            resolve(rtn);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPathwaysLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPathwaysLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPeriodTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPeriodTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPeriodIntervalLookupData(programStudenId: number, facetTypeId: number, cached?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPeriodInterval(programStudenId, facetTypeId, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPeriodIntervalListLookupData(programStudenId: number, facetTypeId: number, periodStartDate: string, cached?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPeriodIntervalList(programStudenId, facetTypeId, periodStartDate, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonnelFileFormatsLookupData(routeorganizationid: number, employerid: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonnelFileFormatsLookup(routeorganizationid, employerid)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const rtn: LookupModel[] = [];
            rtn.push({
              ID: null,
              Description: '--'
            });
            res.forEach(x => {
              rtn.push({
                ID: null,
                Description: x
              });
            });
            resolve(rtn);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileTypeByContextLimitLookupData(context: string, orgId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileTypesByContextLimitLookup(context, orgId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileTypeByContextLookupData(context: string, active: string, preselected: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileTypesByContextLookup(context, active, preselected)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileStatusesByContextLookupData(context: string, active: string, preselected: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileStatusesByContextLookup(context, active, preselected)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileClassificationsLookupData(activeOnly?: boolean, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileClassificationsLookup(activeOnly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileClassificationsByTypeLimitLookupData(typeId: number, orgId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileClassificationsByTypeLimitLookup(typeId, orgId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileClassificationsByTypeLookupData(typeId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileClassificationsByTypeLookup(typeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileClassificationsByTypeByOrgByDOBLookupData(typeId: number, orgId: number, dob: string, activityDate: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileClassificationsByTypeByOrgByDOBLookup(typeId, orgId, dob, activityDate)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileClassificationsByTypeOptionsLookupData(typeId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileClassificationsByType(typeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileClassificationsByTypeByOrgByPersonIdLookupData(typeId: number, orgId: number, personId: number, activityDate: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileClassificationsByTypeByOrgByPersonIdLookup(typeId, orgId, personId, activityDate)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileDuesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileDuesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileClassificationsUnauthLookupData(tenantEnum: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileClassificationsUnauthLookup(tenantEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileStatusesInsertLookupData(personProfileTypeId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileStatusesInsertLookup(personProfileTypeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileStatusesLookupData(context: string, placeholder?: boolean, cached?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileStatusesLookup(context, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileStatusesUpdateLookupData(personProfileTypeId: number, Id: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileStatusesUpdateLookup(personProfileTypeId, Id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileTypesInsertLookupData(organizationId: number, context: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileTypesInsertLookup(organizationId, context)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileTypesLookupData(context: string, placeholder?: boolean, cached?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileTypesLookup(context, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getBillingCategoryOptionsLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getBillingCategoryOptionsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileTypesByOrgIdLookupData(context: string, organizationId: number, checkAdmin?: boolean, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileTypesByOrgIdLookup(context, organizationId, checkAdmin)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileTypesByContextByOrgByDOBLookupData(context: string, organizationId: number, dob: string, activityDate: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileTypesByContextByOrgByDOBLookup(context, organizationId, dob, activityDate)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPersonProfileTypesByContextByOrgByPersonIdLookupData(context: string, organizationId: number, personId: number, dob: string, activityDate: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileTypesByContextByOrgByPersonIdLookup(context, organizationId, personId, dob, activityDate)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }


  getPersonProfileTypesUpdateLookupData(organizationId: number, context: string, Id: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPersonProfileTypesUpdateLookup(organizationId, context, Id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPhoneTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPhoneTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPositionsByOrganizationIdLookup(organizationId: number, organizationCategory?: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPositionsByOrganizationIdLookup(organizationId, organizationCategory)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPositionsByAccessLookupData(interfaceObjectEnum: string, category: string, placeholder?: boolean, cached?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPositionsByAccessLookup(interfaceObjectEnum, category, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPositionTypesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPositionTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPositionOrganizationTypes(category: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPositionOrganizationTypesLookup(category)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getVoterStatus(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getVoterStatus()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getVoterRegistrationStatus(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getVoterRegistrationStatus()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getVoterParties(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getVoterParties()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getPreferencesLookupData(preferenceType: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getPreferencesLookup(preferenceType)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramFacetTypeFacetsLookupData(programFacetTypeId: number, cached?: boolean, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramFacetTypeFacetsLookup(programFacetTypeId, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramFacetTypePeriodIntervalsLookupData(programFacetTypeId: number, periodStartDate: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramFacetTypePeriodIntervalsLookup(programFacetTypeId, periodStartDate)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramFacetTypePeriodsLookupData(programFacetTypeId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramFacetTypePeriodsLookup(programFacetTypeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramFacetTypesLookupData(programId: number, showStudentFacing?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramFacetTypesLookup(programId, showStudentFacing)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramsByPersonLookupData(personId: number, cached?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramsByPersonLookup(personId, cached)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramsLookupData(personId?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramsLookup(personId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramsDownstreamLookupData(orgId: number, hasStipends?: boolean, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramsDownstreamLookup(orgId, hasStipends)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAllProgramsLookupData(placeholder?: boolean, hasStipends?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAllProgramsLookup(hasStipends)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getAllProgramsPicklistLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getAllProgramsPicklistLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramStudentFacetTypesLookupData(programStudentId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramStudentFacetTypesLookup(programStudentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramStudentGroupsLookupData(programId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramStudentGroupsLookup(programId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramStudentPeriodsLookup(programStudentId: number, facetTypeId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramStudentPeriodsLookup(programStudentId, facetTypeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramStudentsLookup(programId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.programsService.getProgramStudentsGridData(programId, null)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            let newRes = [];
            let i: number;
            const ilen: number = res?.Data?.length;
            for (i = 0, ilen; i < ilen; i++) {
              newRes.push({
                ID: res.Data[i].ProgramStudentID,
                Description: res.Data[i].FirstName + ' ' + res.Data[i].LastName + ' (' + res.Data[i].TrainingStatusDescription + ')'
              });
            }
            if (placeholder === undefined || placeholder === true) {
              newRes = this.addPlaceholder(newRes);
            }
            resolve(newRes);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramTypesLookupData(placeholder?: boolean, hasStipends?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramTypesLookup(hasStipends)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramWageRateGroupsLookupData(programId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramWageRateGroupsLookup(programId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getRaceLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getRaceLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getReportRunEnvelopeTypesLookupData(id: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getReportRunEnvelopeTypesLookup(id).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
              resolve(res);
            }
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getReportRunTypeLookupData(str: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getReportRunTypeLookup(str).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
              resolve(res);
            }
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getReportRunPersonOutcomeByIdLookupData(id: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getReportRunPersonOutcomeByIdLookup(id).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
              resolve(res);
            }
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getResultsLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getResultsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getResultTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getResultTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getRosterQueriesLookupData(organizationId?: number, context?: string, activeOnly?: boolean, currentId?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getRosterQueriesLookup(organizationId, context, activeOnly, currentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getRosterQueriesByAccessLookupData(interfaceObjectEnum: string, activeOnly?: boolean, currentId?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getRosterQueriesByAccessLookup(interfaceObjectEnum, activeOnly, currentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getRosterQueryByObjectAccessData(componentEnum: string, activeOnly?: boolean, currentId?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getRosterQueryByObjectAccess(componentEnum, activeOnly, currentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getRosterQueryByOrganizationLookupData(organizationId?: number, activeOnly?: boolean, currentId?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getRosterQueryByOrganization(organizationId, activeOnly, currentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getRosterQueryImportFileFormatsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getRosterQueryImportFileFormatsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const rtn: LookupModel[] = [];
            rtn.push({
              ID: null,
              Description: '--'
            });
            res.forEach(x => {
              rtn.push({
                ID: null,
                Description: x
              });
            });
            resolve(rtn);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  //report builder roster query
  getRosterQueryFilterTypesLookupData(orgId: number, rosterQueryCategoryId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getRosterQueryFilterTypes(orgId, rosterQueryCategoryId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
              resolve(res);
            } else {
              resolve(res);
            }
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getRosterQueryFilterTypeFieldLookupData(orgId: number, id: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getRosterQueryFilterTypeFields(orgId, id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
              resolve(res);
            } else {
              resolve(res);
            }
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }


  getSecurityQuestionsLookupData(groupId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSecurityQuestionsLookup(groupId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSharableAccessOptionsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSharableAccessOptionsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSharablePersonProfileAccessOptionsLookupData(personProfileId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSharablePersonProfileAccessOptionsLookup(personProfileId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSharableCategoriesLookupData(sharableType: string, interfaceObjectEnum?: string, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSharableCategoriesLookup(sharableType, interfaceObjectEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSharablesLookupData(organizationId: number, sharableType: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSharablesLookup(organizationId, sharableType)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getShiftsLookupData(organizationId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getShiftsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSnapshotsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSnapshotsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStatesLookupData(countryId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStatesLookup(countryId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getCountyLookupData(stateId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getCountyLookup(stateId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStatesUnauthLookup(tenantEnum: string, countryId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStatesUnauthLookup(tenantEnum, countryId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStipendBatchStartDatesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStipendBatchStartDatesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStipendBatchEndDatesLookupData(startPeriodId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStipendBatchEndDatesLookup(startPeriodId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStipendBatchDatesLookupData(stipendBatchId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStipendBatchDatesLookup(stipendBatchId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStudentGroupsLookupData(organization?: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStudentGroupsLookup(organization)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStudentGroupsByAccessLookupData(organization?: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStudentGroupsByAccessLookup(organization)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStudentGroupsEnumLookupData(interfaceObjectEnum: string, organization?: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStudentGroupsEnumLookup(interfaceObjectEnum, organization)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSubjectAreasLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSubjectAreasLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSurveyQuestionLookupData(surveyQuestionID: number) {
    return new Promise((resolve, reject) => {
      this.surveyQuestionsService.getSurveyQuestion(surveyQuestionID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            const data: LookupModel[] = this.addPlaceholder([]);
            res.Options.forEach(x => {
              data.push({
                ID: x.ID,
                Description: x.Description,
                Abbreviation: x.Description
              });
            });
            resolve(data);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSurveysLookupData(organizationId: number, context: string, hasResponsesOnly?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSurveysLookup(organizationId, context, hasResponsesOnly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTaxFormsLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTaxFormsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTaxFormTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTaxFormTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTimezonesLookupData(date?: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTimezonesLookup(date)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTrackingTypesByPeriodTypeIdLookupData(periodTypeId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrackingTypesByPeriodTypeIdLookup(periodTypeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTrackingTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrackingTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTradesLookupData(placeholder?: boolean, contractId?: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTradesLookup(contractId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTradeSkillsLookupData(tradeId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTradeSkills(tradeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTrainingStipendFrequencyLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      const rtnData: LookupModel[] = [];
      if (placeholder === undefined || placeholder === true) {
        rtnData.push({
          ID: null,
          Description: '--',
          value: null
        });
      }
      rtnData.push({
        ID: null,
        Description: 'Per Day',
        value: 'PER_DAY'
      });
      rtnData.push({
        ID: null,
        Description: 'Per Hour',
        value: 'PER_HOUR'
      });
      rtnData.push({
        ID: null,
        Description: 'Per Meal',
        value: 'PER_MEAL'
      });
      resolve(rtnData);
    });
  }

  getTrainingStipendPaymentTypesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrainingStipendPaymentTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizationEmployerByCampaignLookupData(campaignId: number, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizationEmployersByCampaign(campaignId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getSkillsLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getSkills()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTrainingCentersLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrainingCentersLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTrainingModuleStatusesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrainingModuleStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTrainingStatusesEnrolledLookup(scope: string, completed: boolean, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrainingStatusesEnrolledLookup(scope, completed)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getTrainingStatusesLookupData(scope: string, passing?: boolean, placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getTrainingStatusesLookup(scope, passing)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getModuleGradeLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getModuleGradeLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getUTStatusesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getUTStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWageAdjustmentsLookupData(organizationId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWageAdjustmentsLookup(organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWageAdjustmentTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWageAdjustmentTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWageLevelsLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWageLevelsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStudentWageRatesLookupData(programStudentId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStudentWageRateLookup(programStudentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getStudentWageRatesZonesLookupData(programId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getStudentWageZoneLookup(programId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProgramWageRatesLookupData(programId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProgramWageRateLookup(programId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowAvailableNextStepsLookupData(currentStepId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowAvailableNextStepsLookup(currentStepId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowDeadlineTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowDeadlineTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowIntervalTypesLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowIntervalTypesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowsLookupData(contractId: number, caseType: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowsLookup(contractId, caseType)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowStatusesLookupData(placeholder?: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowStatusesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowStepsByCategoryOrganizationIdLookupData(workflowCategory: string, organizationId: number, newStep: boolean) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowStepsByCategoryOrganizationIdLookup(workflowCategory, organizationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            if (newStep === true) {
              const removeIndex = res.findIndex(x => x.ID === -1);
              res.splice(removeIndex, 1);
            }
            resolve(res);
          }, error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowStepListLookupData(workflowId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowStepListLookup(workflowId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res.unshift({
              ID: null,
              Description: '--',
              Abbreviation: '--',
              WorkflowStepID: null,
              WorkflowStepAlias: '--'
            });
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowStepsLookupData(workflowId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowStepsLookup(workflowId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowOutcomeLookupData(stepId: number) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowOutcomeLookup(stepId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkflowTypesLookupData(workflowCategory: string) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkflowTypesLookup(workflowCategory)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getWorkLevelsLookupData() {
    return new Promise((resolve, reject) => {
      this.lookupsService.getWorkLevelsLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            res = this.addPlaceholder(res);
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizeProjectOrganizers(organizationID?, placeholder?) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizeProjectOrganizersLookup(organizationID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizeProjectOrganizationType(placeholder?) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizeProjectOrganizationTypes()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizeProjectTypesListLookupData(placeholder?) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizeProjectTypesListLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizeProjectEstimatedLookupData(placeholder?) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizeProjectEstimatedLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizeProjectPhasesLookupData(placeholder?) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizeProjectPhasesLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizeProjectIndustriesListLookupData(placeholder?) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizeProjectIndustriesListLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getOrganizeProjectFundingLookupData(placeholder?) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getOrganizeProjectFundingLookup()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }

  getProjectListLookupData(orgId: number, placeholder?) {
    return new Promise((resolve, reject) => {
      this.lookupsService.getProtectListLookup(orgId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: LookupModel[]) => {
            if (placeholder === undefined || placeholder === true) {
              res = this.addPlaceholder(res);
            }
            resolve(res);
          },
          error: (e) => {
            console.warn(e);
            reject(null);
          }
        });
    });
  }
}
