import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { CoursesGridModel, CourseModel, CourseClassStudentsGridModel } from '../models/courses.model';
import { ExportDefinitionModel } from '../models/export-definitions.model';
import { UserProfileCommunicationsGridModel } from '../models/user-profile.model';
import { PrimeTableFilterModel } from '../models/table-filter.model';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getCoursesGridPrimeNG(dataMode: string, filterData: PrimeTableFilterModel): Observable<CoursesGridModel> {
    return this.http.post<CoursesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/course/grid?mode=${dataMode}`, filterData);
  }

  getCourse(courseId: number): Observable<CourseModel> {
    return this.http.get<CourseModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/course/${courseId}`);
  }

  createCourse(modelData: CourseModel): Observable<CourseModel> {
    return this.http.post<CourseModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/course/create`, modelData);
  }

  updateCourse(courseId: number, modelData: CourseModel): Observable<CourseModel> {
    return this.http.put<CourseModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/course/${courseId}`, modelData);
  }

  deleteCourse(courseId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/course/remove/${courseId}`);
  }

  getCourseClassStudentsPrimeNG(dataMode: string, courseId: number, filterData): Observable<CourseClassStudentsGridModel> {
    return this.http.post<CourseClassStudentsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/course/${courseId}/student/grid?mode=${dataMode}`, filterData);
  }

  exportCourseClassStudents(courseId: number, modelData: ExportDefinitionModel[]): Observable<any> {
    // export file types include xlsx and csv
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/course/${courseId}/student/export?filetype=xlsx`, modelData);
  }
  getCourseCommunicationsKGrid(personId: number): Observable<UserProfileCommunicationsGridModel> {
    return this.http.get<UserProfileCommunicationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/communicationgrid`);
  }

}
