import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { WorkflowStepOutcomesGridModel, WorkflowStepOutcomeModel,
  CreateWorkflowStepOutcomeModel } from '../models/workflow-step-outcomes.model';

@Injectable({
  providedIn: 'root',
})
export class WorkflowStepOutcomesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  resetWorkflowFlow(workflowId: number): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/resetflow`, null);
  }

  getWorkflowStepOutcomesPrimeNG(dataMode: string, workflowId: number, filterData, workflowStepId?: number): Observable<WorkflowStepOutcomesGridModel> {
    const url = (workflowStepId) ? `&workflowstepid=${workflowStepId}` : '';
    return this.http.post<WorkflowStepOutcomesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/stepoutcome/grid?mode=${dataMode}${url}`, filterData);
  }

  getWorkflowStepOutcome(workflowId: number, stepOutcomeId: number): Observable<WorkflowStepOutcomeModel> {
    return this.http.get<WorkflowStepOutcomeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/stepoutcome/${stepOutcomeId}`);
  }

  updateWorkflowStepOutcome(workflowId: number, stepOutcomeId: number, modelData: WorkflowStepOutcomeModel): Observable<WorkflowStepOutcomeModel> {
    return this.http.put<WorkflowStepOutcomeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/stepoutcome/${stepOutcomeId}`, modelData);
  }

  createWorkflowStepOutcome(workflowId: number, modelData: CreateWorkflowStepOutcomeModel): Observable<WorkflowStepOutcomeModel> {
    return this.http.post<WorkflowStepOutcomeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/stepoutcome/create`, modelData);
  }

  deleteWorkflowStepOutcome(workflowId: number, workflowStepOutcomeId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/stepoutcome/remove/${workflowStepOutcomeId}`);
  }

  reorderWorkflowStepOutcomes(workflowId: number, workflowStepId1: number, workflowStepId2: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/stepoutcome/reorder?id1=${workflowStepId1}&id2=${workflowStepId2}`, null);
  }
}
