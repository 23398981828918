import {Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterViewInit, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import { CurrencyInputModel } from '../../../models/currency-input.model';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent implements OnInit, OnChanges, AfterViewInit {
  showFilter: boolean = false;
  value: any = null;
  options: any;
  @Input() initValue;
  @Input() hideLabel: boolean;
  @Input() requiredField: boolean;
  @Input() labelText: string;
  @Input() disabled: boolean;
  @Output() emitData = new EventEmitter<any>();

  constructor (private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.options =
      { prefix: '$',
        allowNegative: true,
        thousands: ',',
        decimal: '.',
        nullable: true,
        inputMode: 'NATURAL'
      };
  }

  // reload if params change
  ngOnChanges(changes: SimpleChanges): void {
    if(!this.labelText) {
      this.labelText = 'Value';
    }
    this.initValue = +this.initValue;
    if(changes.initValue) {
      if(this.initValue !== null) {
        if(this.initValue !== 0) {
          this.value = +this.initValue.toFixed(2);
        } else {
          this.value = +this.initValue;
        }
      }
    }
  }


  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  emitDataToParent(): void {
   this.emitData.emit(this.value);
  }
}
