
  <div class="page-container">
    <div class="public-card">
      <p-card class="card">
        <div *ngIf="logo" class="align-center">
          <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true" />
        </div>
        <div class="page-container">
            <h2 class="two-factor-h2">Two Factor Authentication</h2>
              <div class="p-grid p-fluid">
                <div class="col-md-12 col-sm-12 col-xs-12 text-align-middle">

                  <form>
                    <div class="p-grid p-fluid ">
                      <div class="col-md-3 col-sm-3 col-xs-0"></div>
                      <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="p-grid p-fluid">
                          <fieldset class='number-code col-md-12 col-sm-12 col-xs-12 ' #fieldsetElement>
                            <legend>Security Code</legend>
                            <input
                              #inputEl

                              *ngFor="let input of inputs.controls; let i = index"
                              (focus)="handleFocus($event);"
                              (blur)="onTouched?.()"
                              (keypress)="handleKeyPress($event, i)"
                              (input)="handleInput()"
                              (keydown)="handleKeyDown($event, i)"
                              (paste)="handlePaste($event, i)"
                              [formControl]="$any(input)"
                              [attr.autocomplete]="i === 0 ? 'one-time-code' : null"
                              type="text"
                              inputmode="numeric"
                              tabindex="0"
                            />
                            <div class="hyphen">-</div>
                          </fieldset>
                          <div class="col-md-6 col-sm-6 col-xs-12 text-align-left">

                          </div>
                          <div class="col-md-6 col-sm-6 col-xs-12 text-align-right">
<!--                            <button pButton label="Resend Code" pTooltip="Link Account" tooltipPosition="bottom"-->
<!--                                    (click)="resendCode()" [className]="'inline-button p-mr-2'" ></button>-->
                            @if (!isValidating) {
                            <button pButton label="Submit"  pTooltip="Link Account" tooltipPosition="bottom" type="button"
                                    (click)="processSubmit(false)" [className]="'inline-button margin-top-5'" [disabled]="!validationCode && validationCode?.length === 5" ></button>
                            } @else {
                            <button pButton label="Submit"  pTooltip="Link Account" tooltipPosition="bottom" icon="pi pi-spin pi-spinner save-spinner"
                                   [className]="'inline-button margin-top-5'" [disabled]="validationCode?.length === 5" ></button>
                            }

                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12 text-align-middle error margin-top-10">
                            Your confirmation code will expire in 5 minutes. You may <span (click)="resendCode()" class="resend-code">request an updated code</span> at any time.</div>
                        </div>

                      </div>

                      </div>

                  </form>


              </div>
              <div class="tab-form-buttons p-mt-2">

<!--                <button *ngIf="!isVerifying" pButton label="Link Account" class="p-button-info p-mr-2" pTooltip="Link Account" tooltipPosition="bottom"-->
<!--                        (click)="verify()" [className]="'inline-button'" [disabled]="mainForm.invalid || !mainForm.touched"></button>-->
<!--                <button pButton label="Link Account" *ngIf="isVerifying" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">-->
<!--                  <i class="pi pi-spin pi-spinner save-spinner"></i>-->
<!--                </button>-->
              </div>
          </div>
        </div>
      </p-card>
    </div>
    <p-toast [baseZIndex]="16000" key="twoFA"></p-toast>
  </div>

