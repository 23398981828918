import {AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {OrganizationService} from '../../../services/organizations.service';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {ContextService} from '../../../services/context.service';
import {DialogService} from 'primeng/dynamicdialog';
import {RosterTabContainerComponent} from '../../../roster/roster-tab-container/roster-tab-container.component';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';

@Component({
  selector: 'app-employer-members-tab',
  templateUrl: './employers-tab-members.component.html',
  styleUrls: ['./employers-tab-members.component.scss']
})

export class EmployersTabMembersComponent implements OnInit, AfterContentChecked, OnDestroy {
  context = this.contextService.contextObject;
  canPageWrite: boolean;
  memberList: any[] = [];
  loading: boolean;
  isExporting: boolean;
  dataTotal: number = 0;
  @Input() selEmployerId: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  inputObjOrganization: GenericSelectModel;
  inputObjStatus: GenericSelectModel;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  memberNumber: string = '';
  employerID: string = '';
  showData: boolean = false;
  isGrid: boolean = false;
  showAddEdit: boolean;
  selectedItem: number;
  showFilter: boolean = true;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private changeDetection: ChangeDetectorRef,
              private organizationService: OrganizationService, private contextService: ContextService,
              private dialogUtility: DialogUtility, private dialogService: DialogService,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    // load main list
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    // load main filter
    this.getData();
  }

  ngAfterContentChecked(): void {
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.getTotalRequest = this.organizationService
      .getEmployerMembersPrimeNG('TOTAL', this.currentFilter, this.selEmployerId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.memberList.length = 0;
    this.loading = true;
    this.getListRequest = this.organizationService
      .getEmployerMembersPrimeNG('DATA', this.currentFilter, this.selEmployerId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.memberList = res.Data;
          this.loading = false;
        }
      });
  }

  getData(): void {
    this.showAddEdit = false;
    this.selectedItem = -1;
    this.getTotal();
    this.getList();
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.memberList));
        const dataToExport = removeKeysFromArray(arr, ['ID', 'OrganizationReferenceID', 'ContractTypeID', 'EmployerOrganizationID', 'UnionOrganizationID', 'CanDelete']);
        exportData('contracts', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.organizationService
          .getEmployerMembersPrimeNG('DATA', this.currentFilter, this.selEmployerId)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['ID', 'OrganizationReferenceID', 'ContractTypeID', 'EmployerOrganizationID', 'UnionOrganizationID', 'CanDelete']);
              exportData('contracts', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }

  handleAdd(event:any): void {
    this.showAddEdit = this.dataTotal === 0;
    if (event) {
      if (event === true) {
        this.getData();
      }
    }
  }

  filterItems(): void {
    this.loading = true;
    if (this.activeGlobalFilter) {
      this.initFilters('FullName', this.activeGlobalFilter, 'contains', 'and');
    } else {
      this.initFilters('FullName', null, 'contains', 'and');
    }

    if (this.memberNumber) {
      this.initFilters('MemberNumber', this.memberNumber, 'contains', 'and');
    } else {
      this.initFilters('MemberNumber', null, 'contains', 'and');
    }

    this.currentFilter.first = 0;
    this.getData();
  }

  refresh(): void {
    this.getData();
  }

  resetFilter(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.memberNumber = null;
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string): void {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  openMemberDetail(memberDetail) {
    const ref = this.dialogService.open(RosterTabContainerComponent, {
      data: {
        id: memberDetail.PersonID,
        isEdit: true,
        isDialog: true
      },
      header: memberDetail.FullName,
      width: '90%',
      height: '70%'
    });
  }
}
