

import {Component, OnInit, ViewChild} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-maps',
  templateUrl: './maps-dialog.component.html',
  styleUrls: ['./maps-dialog.component.scss']
})

export class MapsDialogComponent implements OnInit {
  latitude: number;
  longitude: number;
  zoom:number = 15;
  title: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  markerPositions: google.maps.LatLngLiteral[] = [];
  center: google.maps.LatLngLiteral;
  apiLoaded: boolean;

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;



  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, httpClient: HttpClient) {
    httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyABIQeWXzRptDeYqeMNUjQoq7DqzbCvjbI', 'callback')
      .subscribe(
        () => {
          this.apiLoaded = true;
        });
  }

  ngOnInit(): void {
    // this.latitude = this.config.data.latitude;
    // this.longitude = this.config.data.longitude;
    this.markerPositions.push({
      lat: this.config.data.latitude,
      lng: this.config.data.longitude
    });
    this.center = {
      lat: this.config.data.latitude,
      lng: this.config.data.longitude
    };
    this.title = this.config.data.title;
    this.address = this.config.data.street;
    this.city = this.config.data.city;
    this.state = this.config.data.state;
    this.zip = this.config.data.zip;
  }

  openInfoWindow(marker: MapMarker) {
    if (this.infoWindow !== undefined) this.infoWindow.open(marker);
  }

  closeDialog(): void {
    this.ref.destroy();
    this.ref.destroy();
  }

  cancel(): void {
    this.closeDialog();
  }
}
