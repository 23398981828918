import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {ApprenticeHoursListModel} from '../../../../models/apprenticeHours.model';
import moment from 'moment/moment';
import {transformDateTimeToDateOnly} from '../../../../shared/utilities/form.utility';
import {ApprenticeHoursService} from '../../../../services/apprentice-hours.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MessageService} from 'primeng/api';

export interface typeModel{
  EmployerRequired: boolean;
  AefRequired: boolean;
  HoursRequired: boolean;
  NotesRequired: boolean;
  ID: number;
  Rank: number;
  Description: string;
  Active: boolean;
  Enum: string;
}


@Component({
  selector: 'app-maps',
  templateUrl: './add-edit-apprentice-hours-dialog.component.html',
  styleUrls: ['./add-edit-apprentice-hours-dialog.component.scss']
})

export class AddEditApprenticeHoursDialogComponent implements OnInit {
  mainForm: FormGroup;
  inputObjApprenticeHoursType: GenericSelectModel;
  inputObjEmployer: GenericSelectModel;
  isSaving: boolean = false;
  setType: typeModel;
  setEmployerId: number;
  setApprenticeHoursTypeId: number;
  apprenticeHours = {} as  ApprenticeHoursListModel;
  tempWorkMonthDate;
  todaysDate: Date = new Date();

  private ngUnsubscribe = new Subject();
  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private formBuilder: FormBuilder,
              public lookupsDataService: LookupsDataService, private apprenticeHoursService: ApprenticeHoursService,
              private messageService: MessageService) {
    this.mainForm  = this.formBuilder.group({
      type: new FormControl(null),
      workMonth: new FormControl(null),
      hours: new FormControl(null),
      employer: new FormControl(null),
      aef: new FormControl(null),
      notes: new FormControl(null),
    });
  }
  ngOnInit(): void {

    console.log(this.config.data);
    if(this.config.data.isEdit) {
      this.getApprenticeHoursData();
    } else {
      this.initApprenticeHoursType(false);
      this.initEmployer(false);
    }


  }

  getApprenticeHoursData() {

    this.apprenticeHoursService.getApprenticeHoursDetail(this.config.data.personId, this.config.data.data.PersonHoursID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.apprenticeHours = res;
          this.setApprenticeHoursTypeId = this.apprenticeHours.PersonHoursTypeID;
          this.mainForm.get('hours').setValue(this.apprenticeHours.Hours);
          this.mainForm.get('aef').setValue(this.apprenticeHours.Aef);
          this.mainForm.get('notes').setValue(this.apprenticeHours.Notes);
          this.setEmployerId = this.apprenticeHours.EmployerOrganizationID;
          if (this.apprenticeHours.WorkMonth) {
            this.tempWorkMonthDate = new Date(this.apprenticeHours.WorkMonth);
          } else {
            this.tempWorkMonthDate = this.todaysDate;
          }
          this.initApprenticeHoursType(false);
          this.initEmployer(false);
        }, error: (e) => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          console.debug(e);
        }
      });

  }

  initApprenticeHoursType(disable: boolean) {
    console.log(this.setApprenticeHoursTypeId);
    this.inputObjApprenticeHoursType = {
      labelText: 'Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setApprenticeHoursTypeId,
      data: null,
      disabled: disable,
    };
    this.lookupsDataService.getAvailableApprenticeHoursTypesLookupData().then((lookupData) => {
      this.inputObjApprenticeHoursType.data = lookupData;
      this.inputObjApprenticeHoursType = Object.assign({}, this.inputObjApprenticeHoursType);
      if(this.config.data.isEdit) {
        this.setType = this.inputObjApprenticeHoursType.data.find(x => x.ID === this.apprenticeHours.PersonHoursTypeID);
      }
    });
  }

  getApprenticeHoursTypeData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setApprenticeHoursTypeId = event[0].ID;
      this.setType = event[0];
      if(this.setType.AefRequired) {
        this.mainForm.get('aef').setValidators(Validators.required);
        this.mainForm.updateValueAndValidity();
      } else {
        this.mainForm.get('aef').clearValidators();
        this.mainForm.updateValueAndValidity();
      }

      if(this.setType.EmployerRequired) {
        this.mainForm.get('employer').setValidators(Validators.required);
        this.mainForm.updateValueAndValidity();
        this.initEmployer(false);
      } else {
        this.mainForm.get('employer').clearValidators();
        this.mainForm.updateValueAndValidity();
      }

      if(this.setType.HoursRequired) {
        this.mainForm.get('hours').setValidators(Validators.required);
        this.mainForm.updateValueAndValidity();
      } else {
        this.mainForm.get('hours').clearValidators();
        this.mainForm.updateValueAndValidity();
      }

      if(this.setType.NotesRequired) {
        this.mainForm.get('notes').setValidators(Validators.required);
        this.mainForm.updateValueAndValidity();
      } else {
        this.mainForm.get('notes').clearValidators();
        this.mainForm.updateValueAndValidity();
      }

    } else {
      this.setApprenticeHoursTypeId = 0;
    }
    this.mainForm.get('aef').setValue(null);
    this.mainForm.get('employer').setValue(null);
    //set form rules based
    this.mainForm.get('type').setValue(this.setApprenticeHoursTypeId);
    this.mainForm.markAsDirty();
  }

  initEmployer(disable: boolean): void {
    this.inputObjEmployer = {
      labelText: 'Employer',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setEmployerId,
      disabled: disable
    };
    if (this.config.data.orgId) {
      this.lookupsDataService.getOrganizationsByParentLookupData('UnionEmployer', this.config.data.orgId).then((lookupData) => {
        this.inputObjEmployer.data = lookupData;
        this.inputObjEmployer = Object.assign({}, this.inputObjEmployer);
      });
    }
  }

  getEmployerData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setEmployerId = event[0].ID;
    } else {
      this.setEmployerId = 0;
    }
    this.mainForm.get('employer').setValue(this.setEmployerId);
    this.mainForm.markAsDirty();
    console.log(this.mainForm);
  }

  processData(){
    this.apprenticeHours.Hours = this.mainForm.get('hours').value;
    this.apprenticeHours.Aef = this.mainForm.get('aef').value;
    this.apprenticeHours.PersonHoursTypeID = this.setType.ID;
    this.apprenticeHours.PersonID = this.config.data.personId;
    this.apprenticeHours.EmployerOrganizationID = this.setEmployerId;
    this.apprenticeHours.ReceivedDate = transformDateTimeToDateOnly(this.todaysDate.toString());
    this.apprenticeHours.Notes = this.mainForm.get('notes').value;
    const lastDayWorkMonthDate = moment(this.tempWorkMonthDate).endOf('month').format('YYYY-MM-DD');
    this.apprenticeHours.WorkMonth = transformDateTimeToDateOnly(lastDayWorkMonthDate);

    this.saveData();
  }

  saveData() {
    if(!this.config.data.isEdit) {
      this.apprenticeHoursService.createApprenticeHoursDetail(this.apprenticeHours.PersonID, this.apprenticeHours)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Hours were added successfully'
            });
            this.closeDialog();
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            console.debug(e);
          }
        });
    } else {
      this.apprenticeHoursService.updateApprenticeHoursDetail(this.apprenticeHours.PersonID, this.apprenticeHours.PersonHoursID,  this.apprenticeHours)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'Hours were updated successfully'
              });
              this.closeDialog();
            }, error: (e) => {
              this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
              console.debug(e);
            }
          });
    }
  }

  closeDialog(): void {
    this.ref.close();
  }

  cancel(): void {
    this.closeDialog();
  }
}
