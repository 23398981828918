import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {StudentProfileModel} from '../../../../models/student-profile.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {SendAdHocCommunicationModel} from '../../../../models/communications.model';
import {ContextService} from '../../../../services/context.service';
import {CommunicationsService} from '../../../../services/communications.service';
import {MessageService} from 'primeng/api';
import {Subject} from 'rxjs';
import {RouteContextUtility} from '../../../utilities/route-context.utility';
import {StudentCertificationsModel} from '../../../../models/certifications.model';
import {ClassStudentModel} from '../../../../models/class-students.model';
import {SegmentedMessage} from 'sms-segments-calculator';
import {TenantConfigDataService} from '../../../../services/tenant-config-data.service';
import {UserProfileService} from '../../../../services/user-profile.service';

@Component({
  selector: 'app-adhoc-phone-form',
  templateUrl: './adhoc-phone-form.component.html',
  styleUrls: ['./adhoc-phone-form.component.scss']
})

export class AdhocPhoneFormComponent implements OnInit, OnChanges {
  mainForm: FormGroup;
  fromEmail: string;
  hasPhone: boolean;
  toUser: string;
  isSaving: boolean;
  adhocData: SendAdHocCommunicationModel = {} as SendAdHocCommunicationModel;
  personIdList: any[] = [];
  loadingRec: boolean = false;
  totalCharacters: number = 0;
  totalSegments: number = 0;
  totalRecipients: number = 0;
  totalCost: number = 0;
  onlyOneRecipient: boolean = false;
  tcMAXSMSMESSAGECHARS: number;
  tcAPPLYSMSSEGMENTCALC: boolean = false;
  tcSHOWSMSSEGMENTCALC: boolean = false;
  hasNonAllowedChars: boolean = false;
  nonAllowedChars: string = '';
  @Input() recipients: StudentProfileModel[];
  @Input() recipientsStudentCerts: StudentCertificationsModel[];
  @Input() recipientsClassStudent: ClassStudentModel[];
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() superuser: boolean;
  @Input() presetText: string;
  @Output() closeDialog = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private contextService: ContextService, private formBuilder: FormBuilder,
              private communicationsService: CommunicationsService, private messageService: MessageService,
              private routeContextUtility: RouteContextUtility, private tenantConfigDataService: TenantConfigDataService,
              private userProfileService: UserProfileService) {
    this.mainForm = this.formBuilder.group({
      content: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    console.log(this.recipients);
    this.tcMAXSMSMESSAGECHARS = Number(this.tenantConfigDataService.getStringValue('MAXSMSMESSAGECHARS'));
    this.tcAPPLYSMSSEGMENTCALC = this.tenantConfigDataService.getBooleanValue('APPLYSMSSEGMENTCALC');
    this.tcSHOWSMSSEGMENTCALC = this.tenantConfigDataService.getBooleanValue('SHOWSMSSEGMENTCALC');
    this.loadingRec = true;
    if (this.recipients) {
      this.totalRecipients = this.recipients.length;
      this.onlyOneRecipient = (this.totalRecipients === 1);
      this.loadingRec = false;
      this.recipients.forEach(person => {
        this.personIdList.push(person.PersonID);
        if (person.SecondaryPhoneNumber) {
          this.hasPhone = true;
        }
      });
      this.segment();
    } else if (this.recipientsStudentCerts) {
      this.totalRecipients = this.recipientsStudentCerts.length;
      this.onlyOneRecipient = (this.totalRecipients === 1);
      this.loadingRec = false;
      this.recipientsStudentCerts.forEach(person => {
        this.personIdList.push(person.PersonID);
        if (person.SecondaryPhoneNumber) {
          this.hasPhone = true;
        }
      });
      this.segment();
    } else if (this.recipientsClassStudent) {
      this.totalRecipients = this.recipientsClassStudent.length;
      this.onlyOneRecipient = (this.totalRecipients === 1);
      this.loadingRec = false;
      this.recipientsClassStudent.forEach(person => {
        this.personIdList.push(person.PersonID);
        if (person.SecondaryPhoneNumber) {
          this.hasPhone = true;
        }
      });
      this.segment();
    }
    this.fromEmail = this.contextService.contextObject.UserName;

    this.mainForm.controls.content.valueChanges.subscribe({
      next: () => {
        this.segment();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.presetText) {
      this.adhocData.Body = this.presetText;
      this.mainForm.get('content').setValue(this.presetText);
      this.mainForm.markAsDirty();
    }
  }

  cancel() {
    this.closeDialog.emit();
  }

  processData() {
    const title: string = (this.DBEntity !== null && this.DBEntity !== undefined) ? this.DBEntity.charAt(0).toUpperCase() + this.DBEntity.substring(1).toLowerCase() + ' Text Notification' : 'Text Notification';
    this.isSaving = true;
    this.adhocData.DBEntity = this.DBEntity;
    this.adhocData.DBEntityKeyID = this.DBEntityID;
    this.adhocData.PersonIDList = this.personIdList;
    this.adhocData.Title = title;
    this.adhocData.Body = this.mainForm.get('content').value;
    this.adhocData.SendSummaryEmailToUser = false;
    this.adhocData.Context = this.routeContextUtility.getRouteContext();
    this.adhocData.Channel = 'TEXT';
    this.adhocData.OrganizationID = null;//this.contextService.contextObject.OrganizationID;
    this.saveData();
  }

  saveData() {
    if (this.superuser === false) {
      this.userProfileService.sendUserCommunicationAdHoc(this.adhocData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your text has been sent'
            });
            this.isSaving = false;
            this.closeDialog.emit();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message, life: 4000});
            } else {
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
            }
            this.isSaving = false;
            console.debug(e);
          }
        });
    } else {
      this.communicationsService.sendCommunicationAdHoc(this.adhocData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your text has been sent'
            });
            this.isSaving = false;
            this.closeDialog.emit();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message, life: 4000});
            } else {
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
            }
            this.isSaving = false;
            console.debug(e);
          }
        });
    }
  }

  segment(): void {
    const sms: string = (this.mainForm.get('content').value) ? this.mainForm.get('content').value : '';
    const smsDetails = new SegmentedMessage(sms);
    this.hasNonAllowedChars = false;
    this.nonAllowedChars = '';
    if (this.tcAPPLYSMSSEGMENTCALC === true && smsDetails !== null) {
      this.totalCharacters = smsDetails.numberOfCharacters;
      if (this.totalCharacters > 0) {
        this.totalSegments = smsDetails.segments.length;
        this.totalCost = 0.0075 * smsDetails.segments.length * this.totalRecipients;
      } else {
        this.totalSegments = 0;
        this.totalCost = 0;
      }
      if (smsDetails.getNonGsmCharacters().length > 0) {
        smsDetails.getNonGsmCharacters().forEach(x => {
          this.nonAllowedChars += (this.nonAllowedChars === '') ? x : ' ' + x;
        });
        this.hasNonAllowedChars = true;
      }
    } else {
      this.totalCharacters = sms.length;
      this.totalSegments = 0;
      this.totalCost = 0;
    }
  }
}
