<div class="p-grid p-fluid">
  <div class="col-md-2 col-sm-12 col-xs-12 margin-top-15">
    <app-learn-more [interfaceObjectDesc]="interfaceObjectDesc" [interfaceObjectTypeEnum]="'APPSECTION'"
                    [parentObjectEnum]="parentObjectEnum"></app-learn-more>
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3>Filter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container margin-top-10 no-padding">
        <app-select (emitData)="getEmployerData($event)" [inputObj]="inputObjEmployer" class="full-width"></app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <div class="list-filter-buttons text-align-right">
          <button (click)="clearFilters()" [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" class="p-button-download export-menu" icon="pi pi-times"
                  label="Clear" pButton>
          </button>
          <button (click)="filterItems()" class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  pButton>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header margin-top-15">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">@if (employment) {
          <span>{{ dataTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (employment) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        <button (click)="refresh()" icon="pi pi-refresh" pButton
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canAdd) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (employment) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Employment" [styleClass]="'list-card'">
          <app-employment-default-form [PersonID]="PersonID" [organizationID]="OrganizationID" [isEdit]="false"
                                       [canTabWrite]="canTabWrite"
                                       (closeAddEdit)="updateList()"></app-employment-default-form>
        </p-card>
      </div>
    }
    @if (totalLoaded && dataTotal <= 0 && !showAddEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (employment of employmentList; track employment; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              @if (employment.PrimaryEmployment) {
                <div class="green-flag"></div>
              }
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i, employment.EmploymentID)">
                  <div class="list-header">
                    {{ employment.EmployerName }}
                  </div>
                  <div class="list-subheader">
                    @if (employment.StartDate_D) {
                      <span>{{ employment.StartDate_D | date: 'shortDate' }}</span>
                    }
                    @if (employment.StartDate_D && employment.EndDate_D) {
                      <span> - {{ employment.EndDate_D | date: 'shortDate' }}</span>
                    }
                    @if (employment.EmploymentNumber) {
                      <span class="p-m-0"> | <b>EIN #:</b> {{ employment.EmploymentNumber }}</span>
                    }
                    @if (employment.WorkSiteName) {
                      <span class="p-m-0"> | <b>Location:</b> {{ employment.WorkSiteName }}</span>
                    }
                    @if (employment.ContractDescription) {
                      <span class="p-m-0"> | <b>Contract:</b> {{ employment.ContractDescription }}</span>
                    }
                    @if (employment.OccupationDescription) {
                      <span class="p-m-0"> | <b>{{ STRINGREPLACEOCCUPATION }}
                        :</b> {{ employment.OccupationDescription }}</span>
                    }
                    @if (employment.WorkLevelDescription) {
                      <span class="p-m-0"> | <b>Work Level:</b> {{ employment.WorkLevelDescription }}</span>
                    }
                    @if (employment.WageLevelDescription) {
                      <span class="p-m-0"> | <b>Wage Level:</b> {{ employment.WageLevelDescription }}</span>
                    }
                    @if (employment.ShiftDescription) {
                      <span class="p-m-0"> | <b>Shift:</b> {{ employment.ShiftDescription }}</span>
                    }
                  </div>
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    <!--                <div *ngIf="user-employment.CanDelete" class="icon" pTooltip="Delete" tooltipPosition="bottom" (click)="deleteRecord(program.ProgramID)"><i class="fas fa-trash-alt"></i></div>-->
                    <div class="icon" (click)="expandData(i, employment.EmploymentID)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                  </div>
                </div>
              </div>
              @if (selectedItem === i) {
                <div class="full-width margin-top-10">
                  <app-employment-default-form [PersonID]="PersonID" [organizationID]="OrganizationID"
                                               [employmentId]="employment.EmploymentID" [isEdit]="true"
                                               [canTabWrite]="canTabWrite"
                                               (closeAddEdit)="updateList()"></app-employment-default-form>
                </div>
              }
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
