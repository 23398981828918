import {FilterModel} from './table-filter.model';

export interface RosterQueryExistingModel extends RosterQueryCreateModel {
  RosterQueryID: number;
  HasStaticList?: boolean;
}

export interface RosterQueryCreateModel {
  OrganizationID: number;
  OrganizationName?: string;
  OrganizationNumber?: string;
  Description: string;
  Active: boolean;
  ContextEnumerator: string;
  Filters: RosterQueryFiltersModel[];
}

export interface RosterQueryFiltersModel {
  Enumerator: string;
  FilterPrimeNG: RosterQueryFilterGroup;
  FilterJson?: string;
  FilterQueryString?: string;
}

export interface RosterQueryFilterGroup {
  globalOperator: string;
  filters: Record<string, RosterQueryFilterModel[]>
  AuxFilters?: RosterQueryFiltersModel[];
  first?: number;
  globalFilter?: string;
  multiSortMeta?: string;
  sortField?: string;
  rows?: number;
  sortOrder?: number;
}

export interface RosterQueryFilterModel {
  matchMode: string;
  operator: string;
  HeaderName?: string;
  value?;
  startValue?: string;
  endValue?: string;
  ValueDescription?: string;
  FilterDataTypeEnumerator?: string;
  isID?: boolean;
}

export class PrimeRosterQueryTableFilterModel {
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters: Record<string, FilterModel[]>;
  AuxFilters: RosterQueryFiltersModel[];
}

export class AttributeSelectModel {
  field: string;
  header: string;
  columnType: string;
  id?: number;
  matchMode?: string;
}


//Everything below used for applying filters to a table on the fly not for queries. Subtle differences
export class AuxiliaryFilterModel {
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters: Record<string, FilterModel[]>;
  AuxFilters: AuxiliaryAuxFiltersModel[];
  IncludePii: boolean;
}

export interface AuxiliaryAuxFiltersModel {
  Enumerator: string;
  FilterPrimeNG: AuxiliaryFilterGroup;
  FilterJson?: string;
  FilterQueryString?: string;
}

export interface AuxiliaryFilterGroup {
  globalOperator: string;
  filters: Record<string, RosterQueryFilterModel[]>
  AuxFilters?: RosterQueryFiltersModel[];
  first?: number;
  globalFilter?: string;
  multiSortMeta?: string;
  sortField?: string;
  rows?: number;
  sortOrder?: number;
}
