import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {RosterProfileEmploymentService} from '../../../../services/roster-profile-employment.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {RosterProfileEmploymentGridModel, RosterProfileEmploymentModel} from '../../../../models/roster-profile-employment.model';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-employment-trade-tab',
  templateUrl: './employment-trade-tab.component.html',
  styleUrls: ['./employment-trade-tab.component.scss']
})

export class EmploymentTradeTabComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  isDirty: boolean;
  isSaving: boolean = false;
  isExporting: boolean;
  employment: RosterProfileEmploymentGridModel;
  employmentList: RosterProfileEmploymentModel[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  showAddEdit: boolean;
  dataLoaded: boolean;
  totalLoaded: boolean;
  showData: boolean = false;
  selectedItem: number;
  setEmployerId: number;
  inputObjEmployer: GenericSelectModel;
  activeSubTab: string = 'employers';
  dataTotal: number = 0;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() canAdd: boolean;
  @Input() isPage: boolean;
  @Input() PersonID: number;
  @Input() OrganizationID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  ProgramStudentID: number;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private rosterProfileEmploymentService: RosterProfileEmploymentService, private ref: DynamicDialogRef,
              private lookupsDataService: LookupsDataService, private messageService: MessageService,
              private dialogUtility: DialogUtility, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.initEmployer(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges() {
    if (this.PersonID) {
      this.getEmployment();
    }
  }

  getEmployerData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setEmployerId = event[0].ID;
    } else {
      this.setEmployerId = 0;
    }
  }

  getEmployment() {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.employmentList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.rosterProfileEmploymentService.getRosterEmploymentPrimeNG('DATA', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.employment = res;
          this.employmentList = res.Data;
          if (this.canTabWrite && this.employmentList.length === 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.rosterProfileEmploymentService.getRosterEmploymentPrimeNG('TOTAL', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  initEmployer(disable: boolean): void {
    this.inputObjEmployer = {
      labelText: 'Employer',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEmployerId,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getOrganizationsByParentLookupData('UnionEmployer', this.OrganizationID).then((lookupData) => {
      this.inputObjEmployer.data = lookupData;
      this.inputObjEmployer = Object.assign({}, this.inputObjEmployer);
    });
  }

  expandData(index, id) {
    this.showData = !this.showData;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  paginate(event:any) {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getEmployment();
  }

  filterItems(): void {
    this.initFilters('EmployerOrganizationID', this.setEmployerId, 'equals', 'and');
    this.getEmployment();
  }

  clearFilters(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.setEmployerId = null;
    this.getEmployment();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  refresh() {
    // this.loadTable(this.currentFilter);
    this.getEmployment();
  }

  goToEdit(event:any) {
    sessionStorage.setItem('sessionViewType', 'Grid');

  }

  deleteRecord(employmentId) {
    this.dialogUtility.promptToDelete(() => {
      this.rosterProfileEmploymentService.deleteRosterEmployment(this.PersonID, employmentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected user-employment has been removed.'
            });
            this.updateList();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList() {
    this.showAddEdit = false;
    this.getEmployment();
    this.selectedItem = -1;
  }

  cancel() {
    this.ref.destroy();
    this.showAddEdit = false;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.employmentList));
        const dataToExport = removeKeysFromArray(arr, []);
        exportData('user-employment', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.rosterProfileEmploymentService.getRosterEmploymentPrimeNG('DATA', this.PersonID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, []);
              exportData('user-employment', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
