<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
    <p-table
      #dt1
      [rows]="pageRowCountUtility.pageRowCount()"
      [columns]="cols"
      dataKey="TrainingStipendID"
      [value]="programStudentStipends"
      [lazy]="true"
      (onLazyLoad)="loadTable($event)"
      [paginator]="true"
      [showCurrentPageReport]="true"
      [metaKeySelection]="false"
      [totalRecords]="dataTotal"
      [rowsPerPageOptions]="[25,50,100,250,500]"
      [scrollable]="true"
      [loading]="loading"
      selectionMode="single"
      rowExpandMode="single"
      scrollHeight="400px"
      paginatorDropdownAppendTo="body"
      styleClass="p-datatable-gridlines p-datatable-striped"
      currentPageReportTemplate="Showing {first} to {last} of {{dataTotal}} entries"
    >
      <ng-template pTemplate="caption">
        <div class="p-grid p-fluid">
          <div class="col-md-12 grid-container-buttons-right">
            <div class="action-buttons">
              <button pButton (click)="refresh()" icon="pi pi-refresh" pTooltip="Refresh"
                      tooltipPosition="bottom"></button>
              @if (!isExporting) {
                <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                        icon="fa fa-file-excel"
                        pTooltip="Export" tooltipPosition="bottom" (click)="export()">
                </button>
              } @else {
                <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                        icon="pi pi-spin pi-spinner save-spinner">
                </button>
              }
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
              <div class="header-text">
                {{ col.header }}
              </div>
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          @for (col of columns; track col) {
            <td>
              @if (col.visible && col.displayType === 'date') {
                {{ rowData[col.field] | date: 'shortDate' }}
              }
              <!--              @if (col.visible && col.displayType === 'text') {-->
              <!--                {{rowData[col.field]}}-->
              <!--              }-->
              <!--              @if (col.visible && col.displayType === 'numeric') {-->
              <!--                {{rowData[col.field] | number:'1.0':'en-US'}}-->
              <!--              }-->
              @if (col.visible && col.displayType === 'currency') {
                @if (rowData[col.field] < 0) {
                  <span class="negative"> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                }
                @if (rowData[col.field] === 0) {
                  <span> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                }
                @if (rowData[col.field] > 0) {
                  <span class="positive">{{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
                }
              }
            </td>
          }
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
