import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ContextService} from '../../../../services/context.service';
import {RosterQueryService} from '../../../../services/roster-queries.service';
import {MessageService} from 'primeng/api';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {RosterQueryExistingModel} from '../../../../models/roster-query-primeng.model';
import {TenantConfigDataService} from '../../../../services/tenant-config-data.service';

@Component({
  selector: 'app-aux-dialog',
  templateUrl: './auxiliary-list-new-edit-dialog.component.html',
  styleUrls: ['./auxiliary-list-new-edit-dialog.component.scss']
})

export class AuxiliaryListNewEditDialogComponent implements OnInit, OnDestroy {
  isSaving: boolean;
  mainForm: FormGroup;
  setOrganizationId: number;
  inputObjOrganization: GenericSelectModel;
  InterfaceObjectEnum = 'DISTRIBUTIONLIST';
  saveRosterQuery = {} as RosterQueryExistingModel;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, private contextService: ContextService, public ref: DynamicDialogRef,
              private rosterQueryService: RosterQueryService,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private lookupsDataService: LookupsDataService, private tenantConfigDataService: TenantConfigDataService) {
    this.mainForm = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.initOrganization(false, this.InterfaceObjectEnum, false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: this.setOrganizationId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  setOrganizationData(event:any): void {
    if (event && event[0]) {
      this.setOrganizationId = event[0].ID;
    } else {
      this.setOrganizationId = 0;
    }
  }

  closeDialog() {
    this.ref.destroy();
  }

  cancel() {
    this.closeDialog();
  }

  processData() {
    this.saveRosterQuery.ContextEnumerator = this.config.data.contextEnumerator;
    this.saveRosterQuery.OrganizationID = this.setOrganizationId;
    this.saveRosterQuery.Description = this.mainForm.get('name').value;
    this.saveRosterQuery.Filters = this.config.data.filter.AuxFilters;
    this.saveData();
  }

  saveData() {
    this.rosterQueryService.createRosterQueryPrimeNg(this.saveRosterQuery)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'New Distribution List has been added.'});
          this.closeDialog();
        }, error: () => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Something went wrong.'});
        }
      });
  }
}
