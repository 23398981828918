import { Injectable } from '@angular/core';
import {AuxiliaryFilterModel, RosterQueryFilterGroup, RosterQueryFiltersModel} from '../../models/roster-query-primeng.model';

@Injectable()
export class ConvertAuxFiltersUtility {
  public convertToAuxFilter(currentFilter: RosterQueryFiltersModel[]) {
    const convertedFilter = {} as AuxiliaryFilterModel;
    convertedFilter.AuxFilters = [];
    currentFilter.forEach(filter => {
      convertedFilter.AuxFilters.push( {
        Enumerator: filter.Enumerator,
        FilterPrimeNG: filter.FilterPrimeNG
      });
    });
  }
}
