import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {MessageService} from 'primeng/api';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {OrganizationTaxFormModel} from '../../../models/organization-tax-forms.model';
import {OrganizationTaxFormsService} from '../../../services/organization-tax-forms.service';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-organizations-tab-tax-forms',
  templateUrl: './organizations-tab-tax-forms.component.html',
  styleUrls: ['./organizations-tab-tax-forms.component.scss']
})
export class OrganizationsTabTaxFormsComponent implements OnInit, OnChanges, OnDestroy {
  taxforms: OrganizationTaxFormModel[] = [];
  dataLoaded: boolean;
  totalLoaded: boolean;
  isExporting: boolean;
  isButtonDisabled: boolean = true;
  exportItems: any[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  showData: boolean = false;
  selectedItem: number;
  showAddEdit: boolean;
  dataTotal: number = 0;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() ID: number;
  @Input() canAdd: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private messageService: MessageService, private dialogUtility: DialogUtility,
              public dialogService: DialogService,
              private organizationTaxFormsService: OrganizationTaxFormsService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges() {
    if (this.ID) {
      this.getData();
    }
  }

  getData() {
    this.getList();
    this.getTotal();
  }

  getList() {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.taxforms.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.organizationTaxFormsService.getOrganizationTaxFormsPrimeNG('DATA', this.ID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.taxforms = res.Data;
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
  }

  getTotal() {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.organizationTaxFormsService.getOrganizationTaxFormsPrimeNG('TOTAL', this.ID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          if (this.canAdd && this.dataTotal <= 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  deleteFeed(taxFormID) {
    this.dialogUtility.promptToDelete(() => {
      this.organizationTaxFormsService.deleteOrganizationTaxForm(taxFormID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected feed has been removed.'
            });
            this.updateList();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList() {
    this.showAddEdit = false;
    this.selectedItem = -1;
    this.getData();
  }

  paginate(event:any) {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterItems(): void {
    this.initFilters('TaxFormTypeDescription', this.activeGlobalFilter, 'contains', 'and');
    this.updateList();
  }

  clearFilters(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.updateList();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  refresh() {
    this.updateList();
  }

  expandData(index, id) {
    this.showData = !this.showData;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  resetComponent() {
    this.taxforms = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.taxforms));
        const dataToExport = removeKeysFromArray(arr, ['ID']);
        exportData('rss-taxforms', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.organizationTaxFormsService.getOrganizationTaxFormsPrimeNG('DATA', this.ID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['ID']);
              exportData('rss-taxforms', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
