@if (showGrid) {
  <p-table dataKey="User"
           #dt2
           [rows]="currentFilter.rows"
           [columns]="personLogsCols"
           [value]="personLogsList"
           [paginator]="true"
           [showCurrentPageReport]="true"
           [metaKeySelection]="false"
           [totalRecords]="selectedDataTotal"
           [rowsPerPageOptions]="[25,50,100,250,500]"
           [scrollable]="true"
           selectionMode="single"
           scrollHeight="400px"
           paginatorDropdownAppendTo="body"
           [loading]="!dataLoaded"
           styleClass="p-datatable-responsive-demo p-datatable-striped table-border"
           currentPageReportTemplate="Showing {first} to {last} of {{selectedDataTotal | number}} entries">
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col) {
          <th [pSortableColumn]="col.field" pTooltip="{{ col.fullHeader }}" tooltipPosition="bottom"
              style="min-width: 14.28%;">
            <div class="header-text">
              {{ col.header }}
            </div>
            <div class="header-actions">
              <p-sortIcon [field]="col.field"></p-sortIcon>
              <p-columnFilter [field]="col.field" display="menu" [matchMode]="col.matchMode" [showOperator]="true"
                              [showAddButton]="false" [type]="col.columnType"></p-columnFilter>
            </div>
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        @for (col of columns; track col) {
          <td style="min-width: 14.28%;">@if (col.columnType !== 'date') {
            {{ rowData[col.field] }}
          }
            @if (col.columnType === 'date') {
              {{ rowData[col.field] | date: 'shortDate' }}
            }
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      No details found.
    </ng-template>
  </p-table>
}
