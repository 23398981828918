import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Userpilot } from 'userpilot';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

//Initialize Userpilot
//Userpilot.initialize('NX-bd2bc21a'); //`appToken` should be replaced with your userpilot appToken.
