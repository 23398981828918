@if (invalidRecord) {
  <app-record-not-found></app-record-not-found>
}
@if (!loading && !invalidRecord) {
  <div class="content p-input-filled margin-top-10">
    <div class="p-grid p-fluid">
      @if (isPage && !isDialog) {
        <div class="col-md-12 col-sm-12 col-xs-12 add-edit-header">
          @if (!invalidRecord && isPage && !isDialog) {
            <div class="inline-back-button" (click)="back()" pTooltip="Back">
              <i class="pi pi-angle-left"></i>
            </div>
          }
          @if (employer.Status !== 'Active') {
            <div class="inactive-flag-hard-corner"></div>
          }
          <div class="p-grid p-fluid p-ml-2">
            <div class="col-md-8 col-sm-8 col-xs-12">
              <div class="profile-details p-pl-5">
                @if (isEdit && employer) {
                  <h2>{{ employer.Name }}</h2>
                }
                @if (isEdit && employer) {
                  <h4>
                    @if (employer.City && employer.StateProvince) {
                      <span>{{ employer.City }}, {{ employer.StateProvince }}</span>
                    }
                    @if (employer.Status) {
                      <span> | <b>Status:</b> {{ employer.Status }}</span>
                    }
                    @if (employer.ActiveRosterCount_N && employer.ActiveRosterCount_N > 0) {
                      <span> | <b># of Active Members:</b> {{ employer.ActiveRosterCount_N | number:'1.0':'en-US' }}</span>
                    }
                    @if (employer.WorkSiteCount_N && employer.WorkSiteCount_N > 0) {
                      <span> | <b># of {{ worksitesLabel }}
                        :</b> {{ employer.WorkSiteCount_N | number:'1.0':'en-US' }}</span>
                    }
                    @if (employer.HomeOrganizationAbbreviation) {
                      <span> | <b>Home Org:</b> {{ employer.HomeOrganizationAbbreviation }}</span>
                    }
                    @if (employer.PrimaryTrade) {
                      <span> | <b>Primary Trade:</b> {{ employer.PrimaryTrade }}</span>
                    }
                    @if (employer.PhoneNumber) {
                      <span> | {{ employer.PhoneNumber | phone }}</span>
                    }
                  </h4>
                }
                @if (!isEdit) {
                  <h2>Add Employer</h2>
                }
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 list-container list-container-header">
              <div class="list-action-buttons p-mr-2">
                <app-employer-action-buttons [canPageWrite]="canPageWrite" [employer]="employer"></app-employer-action-buttons>
              </div>
            </div>
          </div>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12">
        @if (!isPage || isDialog) {
          <p-divider></p-divider>
        }
        @if (showTabs) {
          <p-tabView (onChange)="resetWarning()">
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabContact')) {
              <p-tabPanel header="Contact" [selected]="_tabsPermissionsUtility.checkTabRead(permissions,'tabContact')">
                <ng-template pTemplate="content">
                  <app-employers-tab-details [ID]="ContentID" [isEdit]="isEdit"
                                             [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabContact')"></app-employers-tab-details>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabMembers')) {
              <p-tabPanel header="Members" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-employer-members-tab [selEmployerId]="ContentID"
                                            [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabMembers')"
                                            [interfaceObjectDesc]="'tabMembers'"
                                            [parentObjectEnum]="InterfaceObjectEnum"></app-employer-members-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabTradeSkills')) {
              <p-tabPanel header="Trades/Skill" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-trade-skills
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabTradeSkills')"
                    [ContentID]="ContentID" DBEntity="Employer" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabTradeSkills')"></app-trade-skills>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPositions')) {
              <p-tabPanel header="Positions" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-organization-positions-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabPositions')"
                    [organizationId]="ContentID" [organizationCategory]="'employer'"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabPositions')"
                    [showFinancialContact]="true" [interfaceObjectDesc]="'tabPositions'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-organization-positions-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDocs')) {
              <p-tabPanel header="Docs" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-docs-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                                [DBEntity]="'orgreference'" [DBEntityID]="orgRefID"
                                [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDocs')"
                                [InterfaceObjectEnum]="InterfaceObjectEnum" [interfaceObjectDesc]="'tabDocs'"
                                [parentObjectEnum]="InterfaceObjectEnum"></app-docs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabNotes')) {
              <p-tabPanel header="Notes" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-notes-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                                 [DBEntity]="'orgreference'" [DBEntityID]="orgRefID"
                                 [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [canEdit]="_tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [interfaceObjectDesc]="'tabNotes'"
                                 [parentObjectEnum]="InterfaceObjectEnum"></app-notes-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCustomFields')) {
              <p-tabPanel header="Custom Fields" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-custom-fields-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabCustomFields')"
                    [OrgRefID]="orgRefID"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabCustomFields')"
                    [category]="'union'" [responseentity]="'employer'" [isEdit]="isEdit"
                    [interfaceObjectDesc]="'tabCustomFields'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-custom-fields-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabAssignments')) {
              <p-tabPanel header="Assignments" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-employers-tab-assignments
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabAssignments')"
                    [EmployerID]="ContentID" [OrganizationID]="organizationId"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabAssignments')"
                    [interfaceObjectDesc]="'tabAssignments'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-employers-tab-assignments>
                </ng-template>
              </p-tabPanel>
            }
          </p-tabView>
        }
      </div>
    </div>
  </div>
}
