import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {WorkflowStepOutcomesService} from '../../../../services/workflow-step-outcomes.service';
import {WorkflowStepOutcomeGridModel, WorkflowStepOutcomesGridModel} from '../../../../models/workflow-step-outcomes.model';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-workflow-step-outcomes-tab',
  templateUrl: './workflow-step-outcomes-tab.component.html',
  styleUrls: ['./workflow-step-outcomes-tab.component.scss']
})
export class WorkflowStepOutcomesTabComponent implements OnInit, OnChanges, OnDestroy {
  workflows: WorkflowStepOutcomesGridModel;
  workflowList: WorkflowStepOutcomeGridModel[] = [];
  dataLoaded: boolean;
  isExporting: boolean;
  totalLoaded: boolean;
  selSharableId: number;
  isButtonDisabled: boolean = true;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  outcome: string = '';
  keyOutcome: boolean;
  closeBranch: boolean;
  nextSteps: string = '';
  canDelete: boolean = true;
  selectedItem: number;
  showAddEdit: boolean;
  dataTotal: number = 0;
  @Input() WorkflowID: number;
  @Input() canTabWrite: boolean;
  @Input() isEdit: boolean;
  @Output() resetList = new EventEmitter<boolean>();
  @Input() canAdd: boolean;
  getListRequest;
  // getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private workflowStepOutcomesService: WorkflowStepOutcomesService,
              private messageService: MessageService, private dialogUtility: DialogUtility,
              public dialogService: DialogService, private confirmationService: ConfirmationService,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
  }

  getData(): void {
    // only make a mode=ALL call, so that the outcome creation doesn't happen multiple times
    this.getList(this.WorkflowID, this.currentFilter);
  }

  getList(workflowId: number, filterData): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.workflowList.length = 0;
    this.dataLoaded = false;
    this.totalLoaded = false;
    this.getListRequest = this.workflowStepOutcomesService.getWorkflowStepOutcomesPrimeNG('ALL', workflowId, filterData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.workflows = res;
          this.workflowList = res.Data;
          this.dataTotal = res.Total;
          if (this.canTabWrite && this.workflowList.length === 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.dataLoaded = true;
          this.totalLoaded = true;
        }, error: () => {
          this.dataLoaded = true;
          this.totalLoaded = true;
        }
      });
  }

  reorderAttribute(iD1: number, iD2: number): void {
    this.workflowStepOutcomesService.reorderWorkflowStepOutcomes(this.WorkflowID, iD1, iD2)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'The workflow has been reordered.'});
          this.updateList(true);
        }
      });
  }

  deleteAttribute(workflowId: number, workflowStepOutcomeId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.workflowStepOutcomesService.deleteWorkflowStepOutcome(workflowId, workflowStepOutcomeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected workflow has been removed.'
            });
            this.updateList(true);
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList(dataChanged: boolean): void {
    this.showAddEdit = false;
    if (dataChanged) {
      this.selectedItem = -1;
      this.resetList.emit(true);
      this.getData();
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.resetList.emit();
    this.getData();
  }

  filterItems(): void {
    this.initFilters('WorkflowStepDescription', this.activeGlobalFilter, 'contains', 'and');
    this.initFilters('Description', this.outcome, 'contains', 'and');
    if (this.keyOutcome === true) {
      this.initFilters('KeyOutcome', true, 'equals', 'and');
    } else if (this.keyOutcome === false) {
      this.initFilters('KeyOutcome', false, 'equals', 'and');
    } else {
      delete this.currentFilter.filters.KeyOutcome;
    }
    if (this.closeBranch === true) {
      this.initFilters('CloseBranch', true, 'equals', 'and');
    } else if (this.closeBranch === false) {
      this.initFilters('CloseBranch', false, 'equals', 'and');
    } else {
      delete this.currentFilter.filters.CloseBranch;
    }
    this.initFilters('NextStepDescription', this.nextSteps, 'contains', 'and');
    this.resetList.emit();
    this.getData();
  }

  clearFilters(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.outcome = null;
    this.nextSteps = null;
    this.keyOutcome = null;
    this.closeBranch = null;
    this.resetList.emit();
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  goToEdit(event:any): void {
    this.selSharableId = event.SharableId;
  }

  refresh(): void {
    this.getData();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  resetComponent(): void {
    this.workflowList = undefined;
    this.workflows = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.workflowList.length <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.workflowList));
        const dataToExport = removeKeysFromArray(arr, ['WorkflowStepRank', 'ShowUpArrow', 'ShowDownArrow', 'WorkflowStepOutcomeID', 'WorkflowStepID', 'Rank', 'CanDelete', 'NextSteps']);
        exportData('workflow-outcomes', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.workflowStepOutcomesService.getWorkflowStepOutcomesPrimeNG('DATA', this.WorkflowID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['WorkflowStepRank', 'ShowUpArrow', 'ShowDownArrow', 'WorkflowStepOutcomeID', 'WorkflowStepID', 'Rank', 'CanDelete', 'NextSteps']);
              exportData('workflow-outcomes', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }

  resetWorkflow(): void {
    this.confirmationService.confirm({
      message: 'Do you want to reset the step outcomes to the default? Doing so will remove any outcome work you have done on this workflow, but will allow you to start fresh.',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      header: 'Reset Workflow',
      icon: 'pi pi-refresh',
      accept: () => {
        this.workflowStepOutcomesService.resetWorkflowFlow(this.WorkflowID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.confirmationService.close();
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The workflow step outcomes have been reset.'
              });
              this.getData();
            }
          });
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
}
