import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ContextService} from '../../../../services/context.service';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {DialogUtility} from '../../../../shared/utilities/dialog.utility';
import {Location} from '@angular/common';
import {ClassAttendanceModel, UpdateClassAttendanceModel} from '../../../../models/class-attendance.model';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {ClassesModulesService} from '../../../../services/classes.service';
import {ClassAttendancesService} from '../../../../services/class-attendance.service';

@Component({
  selector: 'app-class-attendance-form',
  templateUrl: './class-attendance-form.component.html',
  styleUrls: ['./class-attendance-form.component.scss']
})
export class ClassAttendanceFormComponent implements OnInit, OnChanges {
  mainForm: FormGroup;
  routeParams: any;
  dataId: number;
  selSubjectAreaID: number;
  classAttendance: ClassAttendanceModel = {} as ClassAttendanceModel;
  isDirty: boolean;
  isSaving: boolean = false;
  currentFilter = new PrimeTableFilterModel();
  currentRecord: ClassAttendanceModel;
  loading: boolean;
  isPage: boolean = false;
  inputObjClassAttendanceStatus: GenericSelectModel;
  setClassAttendanceStatusId: number;
  existingMessage: boolean = false;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() ClassID: number;
  @Input() ClassPeriod: string;
  @Input() classAttendanceData: ClassAttendanceModel;
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private classService: ClassesModulesService,
              private router: Router, private route: ActivatedRoute,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private dialogUtility: DialogUtility, private location: Location,
              private lookupsDataService: LookupsDataService,
              private classAttendancesService: ClassAttendancesService,
              private confirmationService: ConfirmationService,
              private contextService: ContextService
  ) {
    this.mainForm = this.formBuilder.group({
      status: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        if (this.router.url.includes('/edit/')) {
          this.isEdit = true;
          this.isPage = true;
        } else if (this.router.url.includes('/add')) {
          this.isEdit = false;
          this.isPage = true;
        } else {
          this.isPage = false;
        }
      }
    });
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    this.reset();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    if (this.isEdit) {
      if (this.classAttendanceData) {
        this.currentRecord = this.classAttendanceData;
        this.classAttendance = this.classAttendanceData;
        this.initForm();
      }
    } else {
      this.initForm();
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Attendance data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initClassAttendanceStatus(disable: boolean): void {
    this.inputObjClassAttendanceStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setClassAttendanceStatusId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getClassAttendanceStatusesLookupData().then((lookupData) => {
      this.inputObjClassAttendanceStatus.data = lookupData;
      this.inputObjClassAttendanceStatus = Object.assign({}, this.inputObjClassAttendanceStatus);
    });
  }

  getClassAttendanceStatus(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setClassAttendanceStatusId = event[0].ID;
    } else {
      this.setClassAttendanceStatusId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('status').setValue(this.setClassAttendanceStatusId);
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  initForm(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.loading = true;
    if (this.isEdit) {
      this.setClassAttendanceStatusId = this.currentRecord.AttendanceStatusID;
      this.mainForm.get('status').setValue(this.currentRecord.AttendanceStatusID);
    }
    this.initClassAttendanceStatus(!this.canTabWrite);
    this.loading = false;
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }

  getDataToEdit(id: number): void {
    this.loading = true;
    this.currentRecord = this.classAttendanceData;
    this.classAttendance = this.classAttendanceData;
    this.setClassAttendanceStatusId = this.classAttendanceData.AttendanceStatusID;
    this.mainForm.get('status').setValue(this.classAttendanceData.AttendanceStatusID);
    this.loading = false;
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.classAttendance.AttendanceStatusID = this.mainForm.get('status').value;
      this.saveData();
    } else {
      this.messageService.add({severity: 'warn', summary: 'Missing Data', detail: 'No data was saved, the form is currently incomplete.'});
      this.existingMessage = false;
    }
  }

  reset(): void {
    this.route.params.subscribe({
      next: (params) => {
        this.dataId = Number(params.id);
        if (this.dataId) {
          this.getDataToEdit(this.dataId);
        }
      }
    });
  }

  saveData(): void {
    const dataModel: UpdateClassAttendanceModel = {
      AttendancePeriod: this.ClassPeriod,
      Attendees: [{
        PersonID: this.classAttendanceData.PersonID,
        AttendanceStatusID: this.classAttendance.AttendanceStatusID,
        Notes: this.classAttendanceData.Notes
      }]
    };
    this.classAttendancesService.updateClassAttendance(this.ClassID, dataModel)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        this.confirmationService.close();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
        this.mainForm.markAsUntouched();
        this.mainForm.markAsPristine();
        this.isSaving = false;
        this.existingMessage = false;
        if (this.isPage) {
          //this.back();
        } else {
          this.closeAddEdit.emit(true);
        }
      }, error: (e) => {
        if (e?.error?.Message) {
          this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
        } else {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
        }
        this.isSaving = false;
        console.debug(e);
      }
    });
  }
}
