import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  TrainingStipendsGridModel,
  TrainingStipendModel,
  CreateUpdateTrainingStipendModel,
  ProgramStudentTrainingStipendsGridModel
} from '../models/training-stipends.model';

@Injectable({
  providedIn: 'root',
})
export class TrainingStipendsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getTrainingStipendsPrimeNG(dataMode: string, trainingStipendBatchId: number, filterData): Observable<TrainingStipendsGridModel> {
    return this.http.post<TrainingStipendsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingstipendbatch/${trainingStipendBatchId}/trainingstipend/grid?mode=${dataMode}`, filterData);
  }

  getTrainingStipend(trainingStipendId: number): Observable<TrainingStipendModel> {
    return this.http.get<TrainingStipendModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingstipend/${trainingStipendId}`);
  }

  createTrainingStipend(modelData: CreateUpdateTrainingStipendModel): Observable<TrainingStipendModel> {
    return this.http.post<TrainingStipendModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingstipend/create`, modelData);
  }

  deleteTrainingStipend(trainingStipendId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingstipend/remove/${trainingStipendId}`);
  }

  updateTrainingStipendDetail(trainingStipendId: number, modelData: CreateUpdateTrainingStipendModel): Observable<TrainingStipendModel> {
    return this.http.put<TrainingStipendModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingstipend/${trainingStipendId}`, modelData);
  }

  generateTrainingStipend(trainingStipendId: number): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingstipend/generate/${trainingStipendId}`);
  }

  getProgramStudentTrainingStipend(programStudentId: number, filterData): Observable<ProgramStudentTrainingStipendsGridModel> {
    return this.http.post<ProgramStudentTrainingStipendsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/trainingstipend/grid?mode=ALL`, filterData);
  }
}
