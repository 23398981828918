@if (!loading) {
  <div class="content p-input-filled margin-top-10">
    <div>
      <p-card>
        @if (!loading) {
          <form [formGroup]="mainForm">
            <div class="p-grid p-fluid parent-form">
              <div class="col-md-6 col-sm-12 col-xs-12 input-container">
                <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="getOrganizationData($event)">
                </app-select>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12 input-container">
                <app-multi-select class="full-width" [inputObj]="inputObjRecipient" (emitData)="getRecipientData($event)">
                </app-multi-select>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12 input-container">
                <label>Title <sup class="req">*</sup></label>
                <input type="text" pInputText  formControlName="title">
                @if ((mainForm.controls.title.dirty || mainForm.controls.title.touched) &&
                  mainForm.controls.title.errors?.required) {
                  <span class="error">'Description' must be provided</span>
                }
              </div>
              <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                <label>Date <sup class="req">*</sup></label>
                <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="alertDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025"></p-calendar>
                @if ((mainForm.controls.alertDate.dirty || mainForm.controls.alertDate.touched) &&
                  mainForm.controls.alertDate.errors?.required) {
                  <span class="error">'Date' must be provided</span>
                }
              </div>
              <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                <app-select class="full-width" [inputObj]="inputObjCategory" (emitData)="getCategoryData($event)">
                </app-select>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                <label>Message <sup class="req">*</sup></label>
                <textarea pInputTextarea formControlName="message" rows="5" autoResize="autoResize"></textarea>
                @if ((mainForm.controls.message.dirty || mainForm.controls.message.touched) &&
                  mainForm.controls.message.errors?.required) {
                  <span class="error">'Message' must be provided</span>
                }
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12 input-container">
                <p-checkbox binary="true" label="Send Alert via E-mail" formControlName="sendEmail"></p-checkbox>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12 tab-form-buttons">
                <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                (click)="cancel()"></button>
                @if (!isSaving) {
                  <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                    (click)="processData()" [className]="'inline-button'"
                  [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
                } @else {
                  <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                    <i class="pi pi-spin pi-spinner save-spinner"></i>
                  </button>
                }
              </div>
            </div>
          </form>
        }
      </p-card>
    </div>
  </div>
}
