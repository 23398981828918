import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { CommunicationTemplatesGridModel,
  CreateCommunicationTemplateModel } from '../models/communication-templates.model';
import { LookupModel } from '../models/lookups.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class CommunicationTemplatesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getCommunicationTemplatesPrimeNG(dataMode: string, organizationId: number, context: string, filterData): Observable<CommunicationTemplatesGridModel> {
    Userpilot.track('Communications Template List');
    return this.http.post<CommunicationTemplatesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable/commtemplategrid/organization/${organizationId}?context=${context}&mode=${dataMode}`, filterData);
  }

  createCommunicationTemplate(modelData: CreateCommunicationTemplateModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/sharable`, modelData);
  }

  getCommunicationTemplatesLookup(organizationId: number, templateType: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organization/${organizationId}/sharablesbytype/commtemplate?templateType=${templateType}`);
  }

  getCommunicationTemplatesByContextLookup(personId: number, context: string, templateType: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/person/${personId}/communicationTemplates/byContext/${context}/byType/${templateType}`);
  }
}
