import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {take} from 'rxjs/operators';
import {ContextService} from '../../../services/context.service';
import {TelerikReportingService} from '../../../services/telerik-reporting.service';
import * as FileSaver from 'file-saver';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-maps',
  templateUrl: './member-profile-dialog.component.html',
  styleUrls: ['./member-profile-dialog.component.scss']
})

export class MemberProfileDialogComponent implements OnInit {

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef,
              private contextService: ContextService, private reportingService: TelerikReportingService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {

  }

  processProfile(showTranscript) {
    const reportData = {
      report_name: 'StudentMemberProfile',
      client_id: this.contextService.contextObject.clientId,
      up_id: this.contextService.contextObject.PersonId,
      sp_id: this.config.data.id,
      mo_id: this.contextService.contextObject.RootMemberOrgID,
      rosterQueryId: null,
      showNotesAdvanced: showTranscript,
      sasToken: this.config.data.blobToken,
    };
    this.reportingService.getReport(null, reportData)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.downloadFile(res, 'student-member-profile.pdf', 'text/plain;charset=utf-8');
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Your PDF has been processed and is now downloading.'
          });
          this.ref.close();
        }, error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was a problem processing your report'
          });
        }
      });
  }


  downloadFile(buffer, fileName, fileType) {
    import('file-saver').then(() => {
      const data: Blob = new Blob([buffer], {
        type: fileType
      });
      FileSaver.saveAs(data, fileName);
    });
  }


  closeDialog(): void {
    this.ref.destroy();
  }

  cancel(): void {
    this.closeDialog();
  }
}
