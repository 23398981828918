<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <div class="margin-top-10 filter-item-padding">
      <app-learn-more [interfaceObjectDesc]="interfaceObjectDesc" [interfaceObjectTypeEnum]="'APPSECTION'"
                      [parentObjectEnum]="parentObjectEnum"></app-learn-more>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 list-filter-container">
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3>Filter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 filter-item-padding">
        <label>Description</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="descriptionFilter" class="full-width filter" pInputText
               tabindex="0" type="text"/>
      </div>
      <div class="col-md-12 margin-top-10 filter-item-padding">
        <label>Value</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="activeGlobalFilter" class="full-width filter" pInputText
               tabindex="0" type="text"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <div class="list-filter-buttons text-align-right">
          <button (click)="clearFilters()" [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" class="p-button-download export-menu" icon="pi pi-times"
                  label="Clear" pButton>
          </button>
          <button (click)="filterItems()" class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  pButton>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">@if (customFields) {
          <span>{{ dataTotal | number }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (customFields) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-6 action-buttons">
        @if ((currentFilter.filters | json) !== '{}') {
          <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                  pTooltip="Clear Filter" tooltipPosition="bottom"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                  (click)="clearFilters()">
          </button>
        }
        <button (click)="refresh()" icon="pi pi-refresh" pButton
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canAdd) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (customFields) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Custom Field" [styleClass]="'list-card'">
          <app-custom-fields-form [PersonID]="PersonID" [OrgRefID]="OrgRefID" [isEdit]="false" [category]="category"
                                  [responseentity]="responseentity"
                                  [canTabWrite]="canTabWrite" (closeAddEdit)="updateList()"
                                  [usedCustomFields]="usedCustomFields"></app-custom-fields-form>
        </p-card>
      </div>
    }
    @if (totalLoaded && dataTotal <= 0 && !showAddEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (field of customFieldsList; track field; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i, field.CustomFieldID)">
                  <div class="list-header">
                    {{ field.Attribute }}
                  </div>
                  <div class="sub-header">
                    @switch (field.DataTypeEnumerator) {
                      @case ('PICKLIST') {
                        <i class="fas fa-list"></i> |
                      }
                      @case ('DATE') {
                        <i class="fas fa-calendar"></i> |
                      }
                      @case ('TIME') {
                        <i class="fas fa-clock"></i> |
                      }
                      @case ('DATETIME') {
                        <i class="fas fa-calendar"></i> |
                      }
                      @case ('NUMBER') {
                        <i class="fas fa-hashtag"></i> |
                      }
                      @case ('DECIMAL2') {
                        <i class="fas fa-hashtag"></i> |
                      }
                      @case ('DECIMAL4') {
                        <i class="fas fa-hashtag"></i> |
                      }
                      @case ('USD') {
                        <i class="fas fa-money-bill"></i> |
                      }
                      @case ('CAD') {
                        <i class="fas fa-money-bill"></i> |
                      }
                      @case ('SLE') {
                        <i class="fas fa-font"></i> |
                      }
                      @case ('MLE') {
                        <i class="fas fa-font"></i> |
                      }
                      @case ('YESNO') {
                        <i class="fas fa-square-check"></i> |
                      }
                    }
                    @switch (field.DataTypeEnumerator) {
                      @case ('DATE') {
                        <span> {{ field.Value | date: 'shortDate' }}</span>
                      }
                      @case ('TIME') {
                        <span> {{ field.Value | date: 'shortTime' }}</span>
                      }
                      @case ('DECIMAL2') {
                        <span> {{ field.Value | number:'1.2-2':'en-US' }}</span>
                      }
                      @case ('DECIMAL4') {
                        <span> {{ field.Value | number:'1.4-4':'en-US' }}</span>
                      }
                      @default {
                        <span> {{ field.Value }}</span>
                      }
                    }
                  </div>
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    @if (canTabWrite) {
                      <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                           (click)="deleteRecord(field.CustomResponseID)"><i class="fas fa-trash-alt"></i></div>
                    }
                    <div class="icon" (click)="expandData(i, field.CustomFieldID)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                  </div>
                </div>
              </div>
              @if (selectedItem === i) {
                <div class="full-width margin-top-10">
                  <app-custom-fields-form [PersonID]="PersonID" [OrgRefID]="OrgRefID"
                                          [attributeId]="field.CustomFieldID" [attributeValue]="field.Value"
                                          [isEdit]="true" [category]="category" [responseentity]="responseentity"
                                          [canTabWrite]="canTabWrite"
                                          (closeAddEdit)="updateList()"></app-custom-fields-form>
                </div>
              }
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
