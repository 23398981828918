import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Modules
import { CoreModule } from './core/core.module';
import { SidebarModule } from 'primeng/sidebar';
import { SharedModule } from './shared/shared-module';
import { LoginModule } from './login/login.module';
import { PublicModule } from './public/public.module';

import { EnvironmentsService } from './services/environments.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    PublicModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    SidebarModule
  ],
  providers: [
    Title,
    EnvironmentsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
