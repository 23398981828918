import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConfirmationService} from 'primeng/api';
import {removeKeysFromArray} from '../../../../../../shared/utilities/data.utility';
import {exportData} from '../../../../../../shared/utilities/list-table.utility';
import {PrimeTableFilterModel} from '../../../../../../models/table-filter.model';
import {PageRowCountUtility} from '../../../../../../shared/utilities/page-row-count.utility';
import {TrainingStipendsService} from '../../../../../../services/training-stipends.service';
import {ProgramStudentStipendsModel} from '../../../../../../models/training-stipends.model';
import {DialogUtility} from '../../../../../../shared/utilities/dialog.utility';

@Component({
  selector: 'app-student-stipend-history',
  templateUrl: './student-stipend-history.component.html',
  styleUrls: ['./student-stipend-history.component.scss']
})

export class StudentStipendHistoryComponent implements OnInit, OnDestroy, OnChanges {
  programStudentStipends: ProgramStudentStipendsModel[];
  dataTotal: number;
  loading: boolean;
  isExporting: boolean;
  currentFilter = new PrimeTableFilterModel();
  cols: any[];
  getListRequest;
  @Input() programStudentID: number;
  private ngUnsubscribe = new Subject();

  constructor(public ref: DynamicDialogRef, private trainingStipendsService: TrainingStipendsService,
              private confirmationService: ConfirmationService, public pageRowCountUtility: PageRowCountUtility,
              private dialogUtility: DialogUtility) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.programStudentID) {
      if (this.programStudentID) {
        this.loadPage();
      }
    }
  }

  loadPage(): void {
    this.cols = [
      {
        field: 'PeriodStartDate',
        header: 'Start Date',
        columnType: 'date',
        displayType: 'date',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'PeriodEndDate',
        header: 'End Date',
        columnType: 'date',
        displayType: 'date',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'TotalAmount',
        header: 'Total Amount',
        columnType: 'number',
        displayType: 'currency',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'WithheldAmount',
        header: 'Withheld Amount',
        columnType: 'number',
        displayType: 'currency',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'PaidAmount',
        header: 'Amount Paid',
        columnType: 'number',
        displayType: 'currency',
        matchMode: 'contains',
        visible: true
      }
    ];
    // load filters
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.loading = true;
  }

  loadTable(event?) {
    if (event) {
      this.currentFilter.rows = event.rows;
      this.currentFilter.first = event.first;
      this.currentFilter.sortOrder = event.sortOrder;
      this.getData();
    } else {
      this.getData();
    }
  }

  getData() {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.loading = true;
    this.getListRequest = this.trainingStipendsService.getProgramStudentTrainingStipend(this.programStudentID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.programStudentStipends = res.Data;
          this.dataTotal = res.Total;
          this.loading = false;
        }
      });
  }

  refresh(): void {
    this.loadTable();
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.programStudentStipends));
        const dataToExport = removeKeysFromArray(arr, ['TrainingStipendID', 'TrainingStipendBatchID', 'CanEdit', 'CanDelete']);
        exportData('student-stipend-history', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.trainingStipendsService.getProgramStudentTrainingStipend(this.programStudentID, exportFilter)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['TrainingStipendID', 'TrainingStipendBatchID', 'CanEdit', 'CanDelete']);
              exportData('student-stipend-history', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
