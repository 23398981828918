import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PositionConfigsService} from '../../../../../services/position-config.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {PositionConfigModel, PositionConfigRoleListModel} from '../../../../../models/position-config.model';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-organization-add-position',
  templateUrl: './add-new-organization-position.component.html',
  styleUrls: ['./add-new-organization-position.component.scss']
})
export class AddNewOrganizationPositionComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  permissions: PositionConfigRoleListModel[];
  selectedValues: number;
  positionData = {} as PositionConfigModel;
  roleList: any[] = [];
  accessOptions = [{
    option: 'No Access',
    value: 0
  }, {
    option: 'Direct Access to only this Organization',
    value: 1
  }, {
    option: 'Access to Sub-Organizations',
    value: 2
  }
  ];
  accessBillingOptions = [{
    option: 'No Access',
    value: 0
  }, {
    option: 'Direct Access to only this Organization',
    value: 1
  }
  ];
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private positionConfigsService: PositionConfigsService,
              public config: DynamicDialogConfig, public ref: DynamicDialogRef, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.mainForm = this.formBuilder.group({
      position: new FormControl(null, [Validators.required]),
      abbreviation: new FormControl(null, [Validators.required]),
      active: new FormControl(null, Validators.required)
    });
    if (!this.config.data.isEdit) {
      this.getAvailableRoles();
    } else {
      this.getExistingPosition();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getAvailableRoles(): void {
    this.positionConfigsService.getAvailableRoleList(this.config.data.organizationId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.permissions = res;
          this.permissions.forEach(role => {
            this.roleList.push({RoleID: role.RoleID, SelectionStatus: role.SelectionStatus});
          });
        }
      });
  }

  getExistingPosition(): void {
    this.positionConfigsService.getPositionConfig(this.config.data.organizationId, this.config.data.selectedPositionId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.positionData = res;
          this.mainForm.get('position').setValue(this.positionData.Description);
          this.mainForm.get('abbreviation').setValue(this.positionData.Abbreviation);
          this.mainForm.get('active').setValue(this.positionData.Current);
          this.permissions = this.positionData.RoleList;
        }
      });
  }

  updateList(index, event): void {
    if (!this.config.data.isEdit) {
      this.roleList[index].SelectionStatus = event.value;
    }
  }

  closeDialog(): void {
    this.ref.destroy();
  }

  cancel(): void {
    this.closeDialog();
  }

  processData(): void {
    this.positionData.Description = this.mainForm.get('position').value;
    this.positionData.Abbreviation = this.mainForm.get('abbreviation').value;
    this.positionData.Current = this.mainForm.get('active').value;
    this.positionData.RoleList = this.config.data.isEdit ? this.positionData.RoleList : this.roleList;
    this.saveData();
  }

  saveData(): void {
    if (!this.config.data.isEdit) {
      this.positionConfigsService.createPositionConfig(this.config.data.organizationId, this.positionData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Position Option added successfully'});
            this.closeDialog();
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.closeDialog();
            console.debug(e);
          }
        });
    } else {
      this.positionConfigsService.updatePositionConfig(this.config.data.organizationId, this.config.data.selectedPositionId, this.positionData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Position Option updated successfully'});
            this.closeDialog();
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.closeDialog();
            console.debug(e);
          }
        });
    }
  }
}
