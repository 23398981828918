<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-12 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                        [parentObjectEnum]="parentObjectEnum"></app-learn-more>
      </div>
      <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">@if (assignmentsList) {
        <span>{{ dataTotal }} Results</span>
      }</h2>
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (assignmentsList) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        <button pButton (click)="refresh()" icon="pi pi-refresh"
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canAdd) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (assignmentsList) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Assignment to Employer" [styleClass]="'list-card'">
          <app-employer-assignment-form [employerID]="EmployerID"
                                        (closeAddEdit)="updateList()"></app-employer-assignment-form>
        </p-card>
      </div>
    }
    @if (dataLoaded && dataTotal <= 0) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (assignment of assignmentsList; track assignment; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12">
                  <div class="list-header">
                    @if (assignment.Abbreviation && assignment.Abbreviation !== '') {
                      <span>{{ assignment.Abbreviation }} - </span>
                    }
                    @if (assignment.Name) {
                      <span>{{ assignment.Name }}</span>
                    }
                  </div>
                  <div class="list-subheader">
                    @if (context.Administrator) {
                      <span><b>{{ assignment.UTStatusDescription.toUpperCase() }}</b> | </span>
                    }
                    <b>{{ assignment.OrganizationType }}</b> |
                    @if (assignment.City && assignment.StateProvince) {
                      <span>{{ assignment.City }}, {{ assignment.StateProvince }} | </span>
                    }<b>Status:</b> {{ assignment.Status }} | <b>Active
                    Members:</b> {{ assignment.ActiveRosterCount_N | number:'1.0':'en-US' }}
                    @if (assignment.RegisteredCount_N && assignment.RegisteredCount_N > 0) {
                      <span> | <b>Registered Users:</b> {{ assignment.RegisteredCount_N | number:'1.0':'en-US' }}</span>
                    }
                    @if (assignment.PhoneNumber) {
                      <span> | {{ assignment.PhoneNumber | phone }}</span>
                    }
                    @if (assignment.UsingDuesCollection) {
                      <span> | <b>Using Dues Collection</b></span>
                    }
                    @if (assignment.BondAmount_N && assignment.BondAmount_N > 0) {
                      <span> | <b>Bond Amount:</b> {{ assignment.BondAmount_N | currency:'USD':'symbol':'1.2-2' }}</span>
                    }
                  </div>
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    @if (canTabWrite && OrganizationID && assignment.ID !== OrganizationID) {
                      <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                           (click)="deleteAssignment(EmployerID, assignment.ID)"><i class="fas fa-trash-alt"></i></div>
                    }
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
