import {Inject, Injectable} from '@angular/core';
import {EnvironmentModel} from '../models/environments.model';
import {DOCUMENT} from '@angular/common';

// declare function require(name: string): any;

@Injectable({
  providedIn: 'root',
})
export class EnvironmentsService {
  public _environment: EnvironmentModel;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  getEnvironment(routeHostName: string): any {
    return new Promise((resolve) => {
      // let environmentLevel: string;
      let environmentName: string = 'ENGAGE';

      // engage
      const apiBaseUrlEngageDevelopment: string = 'https://utsmart-development.azurewebsites.net';
      const apiBaseUrlEngageStage: string = 'https://engageapp-staging.azurewebsites.net';
      const apiBaseUrlEngageSandbox: string = 'https://engageapp-sandbox.azurewebsites.net';
      const apiBaseUrlEngageProduction: string = 'https://engageapp.azurewebsites.net';
      const tenantLogoEngage = '/logo_main_black.png';

      // iaff
      const environmentNameIaffSmart: string = 'SMART';
      const apiBaseUrlIaffSmartStage: string = 'https://utsmart-staging.azurewebsites.net';
      const apiBaseUrlIaffSmartDemo: string = 'https://engagedemoapp-iaffdemo.azurewebsites.net';
      const apiBaseUrlIaffSmartProduction: string = 'https://utsmart.azurewebsites.net';
      const tenantLogoIaff = 'iaff/logo_main_black.png';

      // imtef
      const apiBaseUrlImtefEngageStage: string = 'https://engageimtefapp-staging.azurewebsites.net';
      const apiBaseUrlImtefEngageSandbox: string = 'https://engageimtefapp-sandbox.azurewebsites.net';
      const apiBaseUrlImtefEngageProduction: string = 'https://engageimtefapp.azurewebsites.net';
      const tenantLogoImtef = 'imtef/logo_main_imtef.png';

      // iupat
      const environmentNameIupatUnite: string = 'UNITE';
      const apiBaseUrlIupatUniteStage: string = 'https://engageappiupat-staging.azurewebsites.net';
      const apiBaseUrlIupatUniteSandbox: string = 'https://engageappiupat-sandbox.azurewebsites.net';
      const apiBaseUrlIupatUniteProduction: string = 'https://engageappiupat.azurewebsites.net';
      const tenantLogoIupat = 'iupat/logo_main_black.png';

      // cwa6182
      const tenantLogoCwa6182 = 'cwa6182/logo_main_black.png';

      // ua486
      const tenantLogoUA486 = 'ua486/ua-486-logo.png';

      // steamfitters
      const tenantLogoSteamfitters = '602training/steam-fitters-logo.png';

      // upw646
      const tenantLogoUPW646 = 'upw646/logo_main_black.png';

      // cmplwa
      const tenantLogoCMPLWA = tenantLogoEngage;//'cmplwa/logo_main_black.png';

      // brs
      const tenantLogoBRS = 'brs/logo_main_black.png';

      // iape
      const tenantLogoIAPE = 'iape/logo_main_black.png';

      // idg
      const tenantLogoIDG = 'idg/logo_main_black.png';

      // kgp
      const tenantLogoKGP = 'kgp/logo_main_black.png';

      // ncfo
      const tenantLogoNCFO = 'ncfo/logo_main_black.png';

      // ofnhp
      const tenantLogoOFNHP = 'ofnhp/logo_main_black.png';

      // opcmia633
      const tenantLogoOPCMIA633 = 'opcmia633/logo_main_black.png';

      // uea
      const tenantLogoUEA = 'uea/logo_main_black.png';

      // unsu
      const tenantLogoUNSU = 'unsu/logo_main_black.png';

      // wea
      const tenantLogoWEA = 'wea/logo_main_black.png';

      // AFGE1216
      const tenantLogoAFGE1216 = 'afgelocal1216/logo_main_black.png';

      // NUGW
      const tenantLogoNUGW = 'nugradworkers/logo_main_black.png';

      // atda
      const tenantLogoATDA = 'atda/logo_main_black.png';

      // blet
      const tenantLogoBLET = 'blet/logo_main_black.png';

      // SEIU503LOCAL219
      const tenantLogoSEIU503LOCAL219 = 'seiu503local219/logo_main_black.png';

      // console.log(routeHostName);
      // set default
      // this._environment = {
      //   environmentName,
      //   apiBaseUrlV1: apiBaseUrlV1EngageDevelopment,
      //   multiTenant: false,
      //   tenantEnum: 'IAFF',
      //   tenantLogo: tenantLogoEngage,
      //   supportEmail: 'engagesupport@uniontrack.com',
      //   tenantName: 'iaff_web'
      // };
      this._environment = {
        environmentName: null,
        apiBaseUrlV1: null,
        apiBaseUrlV2: null,
        multiTenant: false,
        tenantEnum: null,
        tenantLogo: tenantLogoEngage,
        supportEmail: null,
        tenantName: null
      };
      // apply hostname specifics
      // console.log('route host ===== ' + routeHostName);

      // UNIONTRACK INTERNAL **************************************************
      // DEV/LOCALHOST
      // console.log('Route Host Name: ' + routeHostName);
      switch (routeHostName) {
        case 'localhost':
        case 'dev':
        case 'utinterface-development.azurewebsites.net':
        case 'engageinterface-ang-development.azurewebsites.net':
        case 'dev.engageapp.net':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageDevelopment;
          this._environment.apiBaseUrlV2 = 'http://localhost:5055';
          this._environment.tenantEnum = 'IAFF';
          this.loadTheme('iaff');
          this._environment.tenantLogo = tenantLogoEngage;
          this._environment.pageTitle = 'Engage: Dev';
          sessionStorage.setItem('gaTag', 'G-GBM3HDG00K');
          sessionStorage.setItem('env', 'dev');
          // eslint-disable-next-line max-len
          // if (routeHostName !== 'localhost') {
          //  sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-dev.js');
          // }
          break;
        //PLAYGROUND
        case 'engageinterface-ang-playground.azurewebsites.net':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageDevelopment;
          this._environment.tenantEnum = 'IAFF';
          this.loadTheme('iaff');
          this._environment.tenantLogo = tenantLogoEngage;
          this._environment.pageTitle = 'Engage: Dev';
          sessionStorage.setItem('gaTag', 'G-GBM3HDG00K');
          sessionStorage.setItem('env', 'dev');
          break;
        //DEMO
        case 'demo.engageapp.net':
        case 'engagedemo.uniontrack.com':
        case 'engagedemo2.uniontrack.com':
        case 'demo':
          this._environment.apiBaseUrlV1 = 'https://engagedemoapp.azurewebsites.net';
          this._environment.multiTenant = true;
          this._environment.tenantEnum = 'IAFF';
          this.loadTheme('iaff');
          this._environment.tenantLogo = tenantLogoEngage;
          this._environment.pageTitle = 'Engage: Demo';
          break;
        // SANDBOX
        case 'uat.engageapp.net':
        case 'engageinterface-ang-sandbox.azurewebsites.net': //TODO: remove this once the above entry is working
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
          this._environment.multiTenant = true;
          this._environment.tenantEnum = 'BRS';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'Engage: Sandbox';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        //

        // ATU1039 **************************************************
        // STAGING
        case 'engageinterfaceatu1039-staging.azurewebsites.net':
        case 'ATU1039-staging':
        case 'atu1039.staging.engageapp.net':
        case 'ATU1039':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageStage;
          this._environment.tenantEnum = 'ATU1039';
          this._environment.pageTitle = 'Engage';
          //  sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'engageinterfaceatu1039-sandbox.azurewebsites.net':
        case 'ATU1039-sandbox':
        case 'atu1039.uat.engageapp.net':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
          this._environment.tenantEnum = 'ATU1039';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'Engage: Sandbox';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'engageinterfaceatu1039.azurewebsites.net':
        case 'engage.atulocal1039.org':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageProduction;
          this._environment.tenantEnum = 'ATU1039';
          this._environment.tenantLogo = 'logo_main_black.png';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // UPW646 **************************************************
        // STAGING
        case 'engageinterfaceupw646-staging.azurewebsites.net':
        case 'UPW646-staging':
        case 'upw646.staging.engageapp.net':
        case 'UPW646':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageStage;
          this._environment.tenantEnum = 'UPW646';
          this._environment.tenantLogo = 'upw646/logo_main_black.png';
          this.loadTheme('upw646');
          this._environment.pageTitle = 'UPW646: Staging';
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'engageinterfaceupw646-sandbox.azurewebsites.net':
        case 'UPW646-sandbox':
        case 'upw646.uat.engageapp.net':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
          this._environment.tenantEnum = 'UPW646';
          this._environment.tenantLogo = 'upw646/logo_main_black.png';
          this.loadTheme('upw646');
          this._environment.pageTitle = 'UPW646: UAT';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'engageinterfaceupw646.azurewebsites.net':
        case 'engage.upwhawaii.org':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageProduction;
          this._environment.tenantLogo = 'upw646/logo_main_black.png';
          this.loadTheme('upw646');
          this._environment.pageTitle = 'UPW646: Engage';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // CMPLWA **************************************************
        // STAGING
        case 'engageinterfacecmplwa-staging.azurewebsites.net':
        case 'cmplwa-staging':
        case 'cmplwa.staging.engageapp.net':
        case 'CMPLWA':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageStage;
          this._environment.tenantEnum = 'cmplwa';
          this._environment.tenantLogo = 'cmplwa/logo_main_black.png';
          this.loadTheme('cmplwa');
          this._environment.pageTitle = 'cmplwa: Staging';
          //  sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'engageinterfacecmplwa-sandbox.azurewebsites.net':
        case 'cmplwa-sandbox':
        case 'cmplwa.uat.engageapp.net':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
          this._environment.tenantEnum = 'cmplwa';
          this._environment.tenantLogo = 'cmplwa/logo_main_black.png';
          this.loadTheme('cmplwa');
          this._environment.pageTitle = 'CMPLWA: UAT';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'engageinterfacecmplwa.azurewebsites.net':
        case 'engage.trowelout.org':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageProduction;
          this._environment.tenantEnum = 'cmplwa';
          this._environment.tenantLogo = 'cmplwa/logo_main_black.png';
          this.loadTheme('cmplwa');
          this._environment.pageTitle = 'cmplwa: Engage';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // // BRS **************************************************
        // // STAGING
        // case 'engageinterfacebrs-staging.azurewebsites.net':
        // case 'brs-staging':
        // case 'brs.staging.engageapp.net':
        //   this._environment.apiBaseUrlV1 = apiBaseUrlEngageStage;
        //   this._environment.tenantEnum = 'BRS';
        //   this._environment.pageTitle = 'BRS';
        //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
        //   break;
        // // UAT
        // case 'engageinterfacebrs-sandbox.azurewebsites.net':
        // case 'brs-sandbox':
        // case 'brs.uat.engageapp.net':
        //   // environmentLevel = 'Sandbox';
        //   this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
        //   this._environment.tenantEnum = 'BRS';
        //   this._environment.tenantLogo = 'logo_main_black.png';
        //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
        //   break;
        // // PRODUCTION
        // case 'engageinterfacebrs.azurewebsites.net':
        //   // case 'engage.brs.org':
        //   // environmentLevel = 'Production';
        //   this._environment.apiBaseUrlV1 = apiBaseUrlEngageProduction;
        //   this._environment.tenantEnum = 'BRS';
        //   this._environment.tenantLogo = 'logo_main_black.png';
        //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
        //   break;
        // //

        // IAFF SMART **************************************************
        // STAGING
        case 'utinterface-staging.azurewebsites.net':
        case 'smart.staging.engageapp.net':
        case 'iaff-staging':
          environmentName = environmentNameIaffSmart;
          this._environment.apiBaseUrlV1 = apiBaseUrlIaffSmartStage;
          this._environment.tenantEnum = 'IAFF';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'Engage: Staging';
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-iaff-staging.js');
          break;
        // DEMO
        case 'smartdemo.uniontrack.com':
        case 'smart.uat.engageapp.net':
          // environmentLevel = 'Sandbox';
          environmentName = environmentNameIaffSmart;
          this._environment.apiBaseUrlV1 = apiBaseUrlIaffSmartDemo;
          this._environment.tenantEnum = 'IAFF';
          this._environment.tenantLogo = 'logo_main_black.png';
          break;
        // PRODUCTION
        case 'utinterface.azurewebsites.net':
        case 'smart.iaff.org':
          // environmentLevel = 'Production';
          environmentName = environmentNameIaffSmart;
          this._environment.apiBaseUrlV1 = apiBaseUrlIaffSmartProduction;
          this._environment.tenantEnum = 'IAFF';
          this._environment.tenantLogo = 'logo_main_black.png';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-iaff-production.js');
          break;
        //

        // IMTEF **************************************************
        // STAGING
        case 'engageimtefinterface-staging.azurewebsites.net':
        case 'imtef-staging':
        case 'imtef.staging.engageapp.net':
        case 'IMTEF':
          this._environment.apiBaseUrlV1 = apiBaseUrlImtefEngageStage;
          this._environment.tenantEnum = 'IMTEF';
          this._environment.tenantLogo = 'imtef/logo_main_imtef.png';
          this.loadTheme('imtef');
          this._environment.pageTitle = 'IMTEF: Staging';
          //  sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'engageimtefinterface-sandbox.azurewebsites.net':
        case 'imtef.sandbox.engageapp.net':
        case 'imtef-sandbox':
        case 'imtef.uat.engageapp.net':
          this._environment.apiBaseUrlV1 = apiBaseUrlImtefEngageSandbox;
          this._environment.tenantEnum = 'IMTEF';
          this._environment.tenantLogo = 'imtef/logo_main_imtef.png';
          this.loadTheme('imtef');
          this._environment.pageTitle = 'IMTEF: UAT';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'engageimtefinterface.azurewebsites.net':
        case 'imtefengage.uniontrack.com':
        case 'imtef.engageapp.net':
        case 'engage.imtef.org':
          this._environment.apiBaseUrlV1 = apiBaseUrlImtefEngageProduction;
          this._environment.tenantEnum = 'IMTEF';
          this._environment.tenantLogo = 'logo_main_black.png';
          this.loadTheme('imtef');
          this._environment.pageTitle = 'IMTEF';
          sessionStorage.setItem('gaTag', 'G-1TS2MS11Y8');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // IUPAT **************************************************
        // STAGING
        case 'engageinterfaceiupat-staging.azurewebsites.net':
        case 'unitestaging.uniontrack.com':
        case 'engageinterfaceiupat-ang-staging.azurewebsites.net':
        case 'iupat-staging':
        case 'iupat.staging.engageapp.net':
        case 'IUPAT':
          environmentName = environmentNameIupatUnite;
          this._environment.apiBaseUrlV1 = apiBaseUrlIupatUniteStage;
          this._environment.tenantEnum = 'IUPAT';
          this._environment.tenantLogo = 'iupat/logo_main_black.png';
          this._environment.tenantBaseColor1 = '#F0AF00';
          this.loadTheme('iupat');
          this._environment.pageTitle = 'IUPAT UNITE: Staging';
          //  sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-iupat-staging.js');
          break;
        // UAT
        case 'engageinterfaceiupat-sandbox.azurewebsites.net':
        case 'sandbox.iupat.org':
        case 'unite.uat.engageapp.net':

          environmentName = environmentNameIupatUnite;
          this._environment.apiBaseUrlV1 = apiBaseUrlIupatUniteSandbox;
          this._environment.tenantEnum = 'IUPAT';
          this._environment.tenantLogo = 'iupat/logo_main_black.png';
          this._environment.tenantBaseColor1 = '#F0AF00';
          this.loadTheme('iupat');
          this._environment.pageTitle = 'IUPAT UNITE: UAT';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'engageinterfaceiupat.azurewebsites.net':
        case 'unite.iupat.org':
          environmentName = environmentNameIupatUnite;
          this._environment.apiBaseUrlV1 = apiBaseUrlIupatUniteProduction;
          this._environment.tenantEnum = 'IUPAT';
          this._environment.tenantLogo = 'iupat/logo_main_black.png';
          this._environment.tenantBaseColor1 = '#F0AF00';
          this._environment.pageTitle = 'IUPAT UNITE';
          sessionStorage.setItem('gaTag', 'G-VPB4K5KWN2');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-iupat-production.js');
          break;
        //


        // 602TRAINING **************************************************
        // STAGING
        case '602training.staging.engageapp.net':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
          this._environment.tenantEnum = '602TRAINING';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = '602 Training: Staging';
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT-OLD
        case '602old.uat.engageapp.net':
        case '602TRAINING-OLD':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
          this._environment.tenantEnum = '602TRAINING-OLD';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = '602 Training: UAT OLD';
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // UAT
        case '602training.uat.engageapp.net':
        case '602TRAINING':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
          this._environment.tenantEnum = '602TRAINING';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = '602 Training: UAT';
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case '602training.engageapp.net':
        case 'engage.602training.org':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageProduction;
          this._environment.tenantEnum = '602TRAINING';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = '602 Training';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // UA486 **************************************************
        // STAGING
        case 'engageinterfaceua486-staging.azurewebsites.net':
        case 'ua486-staging':
        case 'ua486.staging.engageapp.net':

          // environmentLevel = 'Stage';
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageStage;
          this._environment.tenantEnum = 'UA486';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'Plumbers and Steamfitters 486: Staging';
          this.loadTheme('ua486');
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'engageinterfaceua486-sandbox.azurewebsites.net':
        case 'ua486-sandbox':
        case 'ua486.sandbox.engageapp.net':
        case 'ua486.uat.engageapp.net':
        case 'UA486':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
          this._environment.tenantEnum = 'UA486';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'Plumbers and Steamfitters 486: UAT';
          this.loadTheme('ua486');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'engageinterfaceua486.azurewebsites.net':
        case '486school.engageapp.net' :
        case 'ua486.engageapp.net':
        case 'engage.getpiping.com':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageProduction;
          this._environment.tenantEnum = 'UA486';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'Plumbers and Steamfitters 486';
          sessionStorage.setItem('gaTag', 'G-ZBR3N4FE10');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // CWA6182 **************************************************
        // STAGING
        case 'engageinterfacecwa6182-staging.azurewebsites.net':
        case 'cwa6182-staging':
        case 'cwa6182.staging.engageapp.net':
        case 'CWA6182':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageStage;
          this._environment.tenantEnum = 'CWA6182';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'CWA 6182: Staging';
          //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'engageinterfacecwa6182-sandbox.azurewebsites.net':
        case 'cwa6182.sandbox.engageapp.net':
        case 'cwa6182.uat.engageapp.net':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageSandbox;
          this._environment.tenantEnum = 'CWA6182';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'CWA 6182: UAT';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'engageinterfacecwa6182.azurewebsites.net':
        case 'cwa6182.engageapp.net':
        case 'engage.cwa6182.org':
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageProduction;
          this._environment.tenantEnum = 'CWA6182';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'CWA 6182';
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // ATDA **************************************************
        // STAGING
        case 'atda.staging.engageapp.net':
        case 'ATDA-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'ATDA Engage: Staging', 'ATDA', 'atda/logo_main_black.png', 'atda');
          //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'atda.sandbox.engageapp.net':
        case 'atda.uat.engageapp.net':
        case 'ATDA':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'ATDA Engage: UAT', 'ATDA', 'atda/logo_main_black.png', 'atda');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'atda.engageapp.net':
        case 'engage.atda.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'ATDA', 'atda/logo_main_black.png', 'atda');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // STAGING MULTIPLE **************************************************
        // this is set to allow for INTERNAL ONLY tenant selection on staging
        case 'staging.engageapp.net':
        case 'staging':
          this.setEnvDetails(apiBaseUrlEngageStage, 'Engage: Staging (Multi)', 'TEMPLATE', 'logo_main_black.png', 'iaff').then(() => {
            this._environment.multiTenant = true;
          });
          break;
        //
        //

        // BLET **************************************************
        // STAGING
        case 'blet.staging.engageapp.net':
        case 'BLET-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'BLET Engage: Staging', 'BLET', 'blet/logo_main_black.png', 'blet');
          //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'blet.sandbox.engageapp.net':
        case 'blet.uat.engageapp.net':
        case 'BLET':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'BLET Engage: UAT', 'BLET', 'blet/logo_main_black.png', 'blet');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'blet.engageapp.net':
        case 'engage.blet.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'BLET', 'blet/logo_main_black.png', 'blet');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // BRS **************************************************
        // STAGING
        case 'brs.staging.engageapp.net':
        case 'BRS-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'BRS Engage: Staging', 'BRS', 'brs/logo_main_black.png', 'brs');
          //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'brs.sandbox.engageapp.net':
        case 'brs.uat.engageapp.net':
        case 'BRS':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'BRS Engage: UAT', 'BRS', 'brs/logo_main_black.png', 'brs');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'brs.engageapp.net':
        case 'engage.brs.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'BRS', 'brs/logo_main_black.png', 'brs');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // IAPE **************************************************
        // STAGING
        case 'iape.staging.engageapp.net':
        case 'IAPE-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'IAPE Engage: Staging', 'IAPE', 'iape/logo_main_black.png', 'iape');
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'iape.sandbox.engageapp.net':
        case 'iape.uat.engageapp.net':
        case 'IAPE':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'IAPE Engage: UAT', 'IAPE', 'iape/logo_main_black.png', 'iape');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'iape.engageapp.net':
        case 'engage.iape1096.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'IAPE', 'iape/logo_main_black.png', 'iape');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // IDG **************************************************
        // STAGING
        case 'idg.staging.engageapp.net':
        case 'IDG-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'IDG Engage: Staging', 'IDG', 'idg/logo_main_black.png', 'idg');
          //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'idg.sandbox.engageapp.net':
        case 'idg.uat.engageapp.net':
        case 'IDG':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'IDG Engage: UAT', 'IDG', 'idg/logo_main_black.png', 'idg');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'idg.engageapp.net':
        case 'engage.driversguild.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'IDG', 'idg/logo_main_black.png', 'idg');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // KGP **************************************************
        // STAGING
        case 'kgp.staging.engageapp.net':
        case 'KGP-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'KGP Engage: Staging', 'KGP', 'kgp/logo_main_black.png', 'kgp');
          //    sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        //UAT
        case 'kgp.sandbox.engageapp.net':
        case 'kgp.uat.engageapp.net':
        case 'KGP':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'KGP Engage: UAT', 'KGP', 'kgp/logo_main_black.png', 'kgp');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'kgp.engageapp.net':
        case 'engage.kappagammapi.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'KGP', 'kgp/logo_main_black.png', 'kgp');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // NCFO **************************************************
        // STAGING
        case 'ncfo.staging.engageapp.net':
        case 'NCFO-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'NCFO Engage: Staging', 'NCFO', 'ncfo/logo_main_black.png', 'ncfo');
          //  sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'ncfo.sandbox.engageapp.net':
        case 'ncfo.uat.engageapp.net':
        case 'NCFO':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'NCFO Engage: UAT', 'NCFO', 'ncfo/logo_main_black.png', 'ncfo');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'ncfo.engageapp.net':
        case 'engage.ncfo.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'NCFO', 'ncfo/logo_main_black.png', 'ncfo');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // OFNHP **************************************************
        // STAGING
        case 'ofnhp.staging.engageapp.net':
        case 'OFNHP-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'OFNHP Engage: Staging', 'OFNHP', 'ofnhp/logo_main_black.png', 'ofnhp');
          //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'ofnhp.sandbox.engageapp.net':
        case 'ofnhp.uat.engageapp.net':
        case 'OFNHP':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'OFNHP Engage: UAT', 'OFNHP', 'ofnhp/logo_main_black.png', 'ofnhp');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'ofnhp.engageapp.net':
        case 'engage.ofnhp.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'OFNHP', 'ofnhp/logo_main_black.png', 'ofnhp');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // OPCMIA633 **************************************************
        // STAGING
        case 'opcmia633.staging.engageapp.net':
        case 'OPCMIA633-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'OPCMIA633 Engage: Staging', 'OPCMIA633', 'opcmia633/logo_main_black.png', 'opcmia633');
          //   sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'opcmia633.sandbox.engageapp.net':
        case 'opcmia633.uat.engageapp.net':
        case 'OPCMIA633':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'OPCMIA633 Engage: UAT', 'OPCMIA633', 'opcmia633/logo_main_black.png', 'opcmia633');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'opcmia633.engageapp.net':
        case 'engage.local633.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'OPCMIA633', 'opcmia633/logo_main_black.png', 'opcmia633');
          sessionStorage.setItem('gaTag', 'G-EFWMN8SPSQ');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // UEA **************************************************
        // STAGING
        case 'engageinterfaceuea-staging.azurewebsites.net':
        case 'uea.staging.engageapp.net':
        case 'UEA-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'UEA Engage: Staging', 'UEA', 'uea/logo_main_black.png', 'uea');
          //  sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'uea.sandbox.engageapp.net':
        case 'uea.uat.engageapp.net':
        case 'UEA':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'UEA Engage: UAT', 'UEA', 'uea/logo_main_black.png', 'uea');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'uea.engageapp.net':
        case 'uticaea.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'UEA', 'uea/logo_main_black.png', 'uea');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // UNSU **************************************************
        // STAGING
        case 'engageinterfaceunsu-staging.azurewebsites.net':
        case 'unsu.staging.engageapp.net':
        case 'UNSU-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'UNSU Engage: Staging', 'UNSU', 'unsu/logo_main_black.png', 'unsu');
          //  sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'unsu.sandbox.engageapp.net':
        case 'unsu.uat.engageapp.net':
        case 'UNSU':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'UNSU Engage: UAT', 'UNSU', 'unsu/logo_main_black.png', 'unsu');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'unsu.engageapp.net':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'UNSU', 'unsu/logo_main_black.png', 'unsu');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          break;
        //

        // WEA **************************************************
        // STAGING
        case 'wea.staging.engageapp.net':
        case 'WEA-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'WEA Engage: Staging', 'WEA', 'wea/logo_main_black.png', 'wea');
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'wea.sandbox.engageapp.net':
        case 'wea.uat.engageapp.net':
        case 'WEA':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'WEA Engage: UAT', 'WEA', 'wea/logo_main_black.png', 'wea');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'wea.engageapp.net':
        case 'engage.warrenea.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'WEA', 'wea/logo_main_black.png', 'wea');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          sessionStorage.setItem('gaTag', 'G-GX6RYVQZVJ');
          break;
        //

        // AFGE1216 **************************************************
        // STAGING
        case 'afgelocal1216.staging.engageapp.net':
        case 'AFGE1216-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'AFGE 1216 Engage: Staging', 'AFGE1216', 'afgelocal1216/logo_main_black.png', 'afgelocal1216');
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'afgelocal1216.sandbox.engageapp.net':
        case 'afgelocal1216.uat.engageapp.net':
        case 'AFGE1216':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'AFGE 1216 Engage: UAT', 'AFGE1216', 'afgelocal1216/logo_main_black.png', 'afgelocal1216');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'afgelocal1216.engageapp.net':
        case 'engage.local1216.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'AFGE1216', 'afgelocal1216/logo_main_black.png', 'afgelocal1216');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          // sessionStorage.setItem('gaTag', 'G-GX6RYVQZVJ');
          break;
        //

        // NUGW **************************************************
        // STAGING
        case 'nugradworkers.staging.engageapp.net':
        case 'nugradworkers-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'NU Grad Workers Engage: Staging', 'NUGRADWORKERS', 'nugradworkers/logo_main_black.png', 'nugradworkers');
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'nugradworkers.sandbox.engageapp.net':
        case 'nugradworkers.uat.engageapp.net':
        case 'NUGRADWORKERS':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'NU Grad Workers Engage: UAT', 'NUGRADWORKERS', 'nugradworkers/logo_main_black.png', 'nugradworkers');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'nugradworkers.engageapp.net':
        case 'engage.nugradworkers.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'NUGRADWORKERS', 'nugradworkers/logo_main_black.png', 'nugradworkers');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          // sessionStorage.setItem('gaTag', 'G-GX6RYVQZVJ');
          break;
        //

        // SEIU503LOCAL219 **************************************************
        // STAGING
        case 'seiu503local219.staging.engageapp.net':
        case 'seiu503local219-stage':
          this.setEnvDetails(apiBaseUrlEngageStage, 'SEIU 503 Local 219 Engage: Staging', 'SEIU503LOCAL219', 'seiu503local219/logo_main_black.png', 'seiu503local219');
          // sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-staging.js');
          break;
        // UAT
        case 'seiu503local219.sandbox.engageapp.net':
        case 'seiu503local219.uat.engageapp.net':
        case 'SEIU503LOCAL219':
          this.setEnvDetails(apiBaseUrlEngageSandbox, 'SEIU 503 Local 219 Engage: UAT', 'SEIU503LOCAL219', 'seiu503local219/logo_main_black.png', 'seiu503local219');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-uat.js');
          break;
        // PRODUCTION
        case 'seiu503local219.engageapp.net':
        case 'engage.seiu503local219.org':
          this.setEnvDetails(apiBaseUrlEngageProduction, 'Engage', 'SEIU503LOCAL219', 'seiu503local219/logo_main_black.png', 'seiu503local219');
          sessionStorage.setItem('newRelic', './assets/new-relic/new-relic-saas-production.js');
          // sessionStorage.setItem('gaTag', 'G-GX6RYVQZVJ');
          break;
        //

        default: {
          this._environment.apiBaseUrlV1 = null;
          this._environment.pageTitle = 'Engage';
          this._environment.tenantEnum = null;
          this._environment.tenantLogo = tenantLogoEngage;
        }
      }


      // SET THEME
      // console.log('tenantEnum',this._environment.tenantEnum);
      switch (this._environment.tenantEnum) {
        case 'IAFF':
          //for brad local testing only if (routeHostName !== 'dev' && routeHostName !== 'demo' && routeHostName !== 'localhost' && routeHostName !== '192.168.0.170') {
          if (routeHostName !== 'dev' && routeHostName !== 'demo' && routeHostName !== 'staging' && routeHostName !== 'localhost') {
            //SME 20230918: THE ENTRY BELOW IS OVERRIDING THE HOST LEVEL SETTINGS FOR DEV AND DEMO, COMMENTING OUT
            //this._environment.tenantLogo = tenantLogoIaff;
            this.loadTheme('iaff');
          }
          break;
        case 'IUPAT':
          this._environment.tenantLogo = tenantLogoIupat;
          this.loadTheme('iupat');
          break;

        case 'IMTEF':
          this._environment.tenantLogo = tenantLogoImtef;
          this.loadTheme('imtef');
          break;

        case '602TRAINING-OLD':
        case '602TRAINING':
          this._environment.tenantLogo = tenantLogoSteamfitters;
          this.loadTheme('602training');
          break;

        case 'CWA6182':
          this._environment.tenantLogo = tenantLogoCwa6182;
          this.loadTheme('cwa6182');
          break;

        case 'UA486':
          this._environment.tenantLogo = tenantLogoUA486;
          this.loadTheme('ua486');
          break;

        case 'UPW646':
          this._environment.tenantLogo = tenantLogoUPW646;
          this.loadTheme('upw646');
          break;

        case 'CMPLWA':
          this._environment.tenantLogo = tenantLogoCMPLWA;
          this.loadTheme('cmplwa');
          break;

        case 'ATDA':
          this._environment.tenantLogo = tenantLogoATDA;
          this.loadTheme('atda');
          break;

        case 'BLET':
          this._environment.tenantLogo = tenantLogoBLET;
          this.loadTheme('blet');
          break;
        //
        // Mario Migration
        //
        case 'BRS':
          this._environment.tenantLogo = tenantLogoBRS;
          this.loadTheme('brs');
          break;

        case 'IAPE':
          this._environment.tenantLogo = tenantLogoIAPE;
          this.loadTheme('iape');
          break;

        case 'IDG':
          this._environment.tenantLogo = tenantLogoIDG;
          this.loadTheme('idg');
          break;

        case 'KGP':
          this._environment.tenantLogo = tenantLogoKGP;
          this.loadTheme('kgp');
          break;

        case 'NCFO':
          this._environment.tenantLogo = tenantLogoNCFO;
          this.loadTheme('ncfo');
          break;

        case 'OFNHP':
          this._environment.tenantLogo = tenantLogoOFNHP;
          this.loadTheme('ofnhp');
          break;

        case 'OPCMIA633':
          this._environment.tenantLogo = tenantLogoOPCMIA633;
          this.loadTheme('opcmia633');
          break;

        case 'UEA':
          this._environment.tenantLogo = tenantLogoUEA;
          this.loadTheme('uea');
          break;

        case 'UNSU':
          this._environment.tenantLogo = tenantLogoUNSU;
          this.loadTheme('unsu');
          break;

        case 'WEA':
          this._environment.tenantLogo = tenantLogoWEA;
          this.loadTheme('wea');
          break;

        case 'AFGE1216':
          this._environment.tenantLogo = tenantLogoAFGE1216;
          this.loadTheme('afgelocal1216');
          break;

        case 'NUGRADWORKERS':
          this._environment.tenantLogo = tenantLogoNUGW;
          this.loadTheme('nugradworkers');
          break;

        case 'SEIU503LOCAL219':
          this._environment.tenantLogo = tenantLogoSEIU503LOCAL219;
          this.loadTheme('seiu503local219');
          break;
      }

      resolve(this._environment);
    });
  }

  setEnvDetails(apiBaseUrlV1: string, pageTitle: string, tenantEnum: string, tenantLogo: string, loadTheme: string) {
    return new Promise((resolve) => {
      this._environment.apiBaseUrlV1 = apiBaseUrlV1;
      this._environment.pageTitle = pageTitle;
      this._environment.tenantEnum = tenantEnum;
      this._environment.tenantLogo = tenantLogo;
      this.loadTheme(loadTheme);
      resolve(null);
    });
  }

  loadTheme(cssFile: string) {
    // console.log('cssFile',cssFile);
    const headEl = this.document.getElementsByTagName('head')[0];
    const newLinkEl = this.document.createElement('link');
    newLinkEl.rel = 'stylesheet';
    newLinkEl.href = cssFile + '.css';
    sessionStorage.setItem('customCSS', cssFile);
    headEl.append(newLinkEl);
  }
}
