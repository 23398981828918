import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-roster-tab-activities-tabs-container',
  templateUrl: './roster-tab-activities-tabs-container.component.html',
})
export class RosterTabActivitiesTabsContainerComponent implements OnInit {
  InterfaceObjectEnum: string = 'MEMBERSHIPTAB';
  @Input() ContentID: number;
  @Input() canTabWrite: boolean;
  @Input() DBEntity: string;
  @Input() isEdit: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();

  constructor(private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
  }

  resetWarning() {
    this.confirmationService.close();
  }
}
