import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {NotZeroValidation} from '../../../shared/utilities/form.utility';
import {RosterProfileService} from '../../../services/roster-profile.service';
import {RosterProfileModel} from '../../../models/roster-profile.model';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';

@Component({
  selector: 'app-global-roster-student-membership',
  templateUrl: './global-roster-student-membership.component.html',
  styleUrls: ['./global-roster-student-membership.component.scss']
})

export class GlobalRosterStudentMembershipComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  rosterProfile: RosterProfileModel;
  currentRosterProfile: RosterProfileModel;
  inputObjType: GenericSelectModel;
  inputObjStatus: GenericSelectModel;
  inputObjClass: GenericSelectModel;
  inputObjOrganization: GenericSelectModel;
  inputObjPathway: GenericSelectModel;
  inputObjBilling: GenericSelectModel;
  inputObjDues: GenericSelectModel;
  inputObjBargainingUnit: GenericSelectModel;
  isEnabled: boolean = false;
  isRetireDateRequired: boolean = false;
  setTypeId: number;
  setStatusId: number;
  setClassId: number;
  setOrganizationId: number;
  setPathwayId: number;
  setBillingId: number;
  setDuesPaymentId: number;
  setBargainingUnitId: number;
  membershipType: string;
  membershipStatus: string;
  isSaving: boolean = false;
  isRequiredEffectiveDate: boolean = false;
  isInitiationDateRequired: boolean = false;
  disabled: boolean = false;
  typeLocked: boolean = false;
  statusLocked: boolean = false;
  STRINGREPLACECLASSIFICATION: string;
  STRINGREPLACELOCAL: string;
  STRINGREPLACETYPE: string;
  existingMessage: boolean = false;
  @Input() isPage: boolean;
  // updatedMemberInfo = {} as UpdateRosterProfileModel;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() PersonID: number;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private rosterProfileService: RosterProfileService, private tenantConfigDataService: TenantConfigDataService) {
    this.mainForm = this.formBuilder.group({
      statusId: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      memberNumber: new FormControl({value: null, disabled: true}),
      class: new FormControl({value: null, disabled: true}),
      organization: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      pathway: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      typeId: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      effectiveDate: new FormControl({value: null, disabled: true}),
      applicationDate: new FormControl({value: null, disabled: true}),
      initiationDate: new FormControl({value: null, disabled: true}),
      retirementDate: new FormControl({value: null, disabled: true}),
      billingStatusID: new FormControl({value: null, disabled: true}, [Validators.required, NotZeroValidation()]),
      duesPaymentID: new FormControl({value: null, disabled: true}),
      bargainingUnit: new FormControl({value: null, disabled: true}, Validators.maxLength(100))
    });
  }

  ngOnInit(): void {
    this.STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    this.STRINGREPLACECLASSIFICATION = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
    this.STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    if (this.PersonID) {
      this.getRosterProfile();
    }
  }

  getRosterProfile(): void {
    this.rosterProfileService.getRosterProfile(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.isRetireDateRequired = res.RetiredDateRequired;
          this.currentRosterProfile = res;
          this.rosterProfile = res;
          this.initForm();
        }
      });
  }

  initForm(): void {
    this.mainForm.get('memberNumber').setValue(this.rosterProfile.MemberNumber);
    if (this.rosterProfile.EffectiveDate) {
      this.mainForm.get('effectiveDate').setValue(new Date(this.rosterProfile.EffectiveDate));
    } else {
      this.mainForm.get('effectiveDate').setValue(null);
    }
    if (this.rosterProfile.MemberApplicationDate) {
      this.mainForm.get('applicationDate').setValue(new Date(this.rosterProfile.MemberApplicationDate));
    } else {
      this.mainForm.get('applicationDate').setValue(null);
    }
    if (this.rosterProfile.MemberStartDate) {
      this.mainForm.get('initiationDate').setValue(new Date(this.rosterProfile.MemberStartDate));
    } else {
      this.mainForm.get('initiationDate').setValue(null);
    }
    if (this.rosterProfile.RetirementDate) {
      this.mainForm.get('retirementDate').setValue(new Date(this.rosterProfile.RetirementDate));
    } else {
      this.mainForm.get('retirementDate').setValue(null);
    }
    this.setTypeId = this.rosterProfile.TypeID;
    this.mainForm.get('typeId').setValue(this.rosterProfile.TypeID);
    this.setStatusId = this.rosterProfile.StatusID;
    this.mainForm.get('statusId').setValue(this.rosterProfile.StatusID);
    this.setClassId = this.rosterProfile.ClassificationID;
    this.mainForm.get('class').setValue(this.rosterProfile.ClassificationID);
    this.setOrganizationId = this.rosterProfile.OrganizationID;
    this.mainForm.get('organization').setValue(this.rosterProfile.OrganizationID);
    this.setBillingId = this.rosterProfile.BillingStatusID;
    this.mainForm.get('billingStatusID').setValue(this.rosterProfile.BillingStatusID);
    this.setDuesPaymentId = this.rosterProfile.PaymentTypeID;
    this.mainForm.get('duesPaymentID').setValue(this.rosterProfile.PaymentTypeID);
    this.setPathwayId = this.rosterProfile.PathwayID;
    this.mainForm.get('pathway').setValue(this.rosterProfile.PathwayID);
    this.setBargainingUnitId = this.rosterProfile.BargainingUnitID;
    this.mainForm.get('bargainingUnit').setValue(this.rosterProfile.BargainingUnitDescription);
    this.initType(true);
    this.initStatus(true);
    this.initClass(true);
    this.initOrganization(true);
    this.initPathway(true);
    this.initBillingProcess(true);
    this.initDuesPayment(true);
    this.initBargainingUnit(true);
    // this.mainForm.get('bargainingUnit').setValue(this.rosterProfile.BargainingUnit);
    if (this.isRetireDateRequired) {
      this.mainForm.get('retirementDate').setValue(new Date());
      this.mainForm.get('retirementDate').setValidators([Validators.required]);
    }
  }

  initType(disable: boolean): void {
    if (this.typeLocked && this.statusLocked) {
      disable = true;
    }
    this.inputObjType = {
      labelText: this.STRINGREPLACETYPE,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setTypeId,
      disabled: true,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getPersonProfileTypesUpdateLookupData(this.setOrganizationId, 'membership', this.setTypeId).then((lookupData) => {
      this.inputObjType.data = lookupData;
      this.inputObjType = Object.assign({}, this.inputObjType);
    });
  }

  initStatus(disable: boolean): void {
    if (this.typeLocked && this.statusLocked) {
      disable = true;
    }
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStatusId,
      disabled: true,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getPersonProfileStatusesUpdateLookupData(this.setTypeId, this.setStatusId).then((lookupData) => {
      this.inputObjStatus.data = lookupData;
      this.inputObjStatus = Object.assign({}, this.inputObjStatus);
    });
  }

  initClass(disable: boolean): void {
    this.inputObjClass = {
      labelText: this.STRINGREPLACECLASSIFICATION,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setClassId,
      disabled: true,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getPersonProfileClassificationsLookupData().then((lookupData) => {
      this.inputObjClass.data = lookupData;
      this.inputObjClass = Object.assign({}, this.inputObjClass);
    });
  }

  initOrganization(disable: boolean): void {
    this.inputObjOrganization = {
      labelText: this.STRINGREPLACELOCAL,
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setOrganizationId,
      disabled: true,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsDataPerspectiveMembershipLookupData().then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  initPathway(disable: boolean): void {
    this.inputObjPathway = {
      labelText: 'Pathway',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setPathwayId,
      disabled: true,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getPathwaysLookupData(this.setOrganizationId).then((lookupData) => {
      this.inputObjPathway.data = lookupData;
      this.inputObjPathway = Object.assign({}, this.inputObjPathway);
    });
  }

  initBillingProcess(disable: boolean): void {
    this.inputObjBilling = {
      labelText: 'Billing Process',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setBillingId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getMoneyManagerStatusesByOrganizationIdLookupData(this.setOrganizationId).then((lookupData) => {
      this.inputObjBilling.data = lookupData;
      this.inputObjBilling = Object.assign({}, this.inputObjBilling);
    });
  }

  initDuesPayment(disable: boolean): void {
    this.inputObjDues = {
      labelText: 'Dues Payment Election',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setDuesPaymentId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getMoneyManagerPaymentTypesLookupData().then((lookupData) => {
      this.inputObjDues.data = lookupData;
      this.inputObjDues = Object.assign({}, this.inputObjDues);
    });
  }

  initBargainingUnit(disable: boolean): void {
    this.inputObjBargainingUnit = {
      labelText: 'Bargaining Unit',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setBargainingUnitId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getBargainingUnitByOrgLookupData(this.setOrganizationId).then((lookupData) => {
      this.inputObjBargainingUnit.data = lookupData;
      this.inputObjBargainingUnit = Object.assign({}, this.inputObjBargainingUnit);
    });
  }

  getBargainingUnitData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setBargainingUnitId = event[0].ID;
    } else {
      this.setBargainingUnitId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('bargainingUnit').setValue(this.setBargainingUnitId);
  }

  getTypeData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setTypeId = event[0].ID;
      this.membershipType = event[0].Description;
      if (this.setTypeId !== this.rosterProfile.TypeID) {
        this.mainForm.get('effectiveDate').setValue(new Date());
        this.mainForm.get('effectiveDate').setValidators([Validators.required]);
        this.isRequiredEffectiveDate = true;
      } else {
        this.mainForm.get('effectiveDate').setValue(new Date(this.rosterProfile.EffectiveDate));
        this.mainForm.get('effectiveDate').clearValidators();
        this.isRequiredEffectiveDate = false;
      }
      this.disabled = false;
    } else {
      this.setTypeId = null;
      this.membershipType = '';
      this.disabled = true;
    }
    this.setStatusId = null;
    this.initStatus(this.disabled);
    this.getStatusData(null);
    this.mainForm.markAsDirty();
    this.mainForm.get('typeId').setValue(this.setTypeId);
  }

  getStatusData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setStatusId = event[0].ID;
      this.membershipStatus = event[0].Description;
      if (this.setStatusId !== this.rosterProfile.StatusID) {
        this.mainForm.get('effectiveDate').setValue(new Date());
        this.mainForm.get('effectiveDate').setValidators([Validators.required]);
        this.isRequiredEffectiveDate = true;
      } else {
        this.mainForm.get('effectiveDate').setValue(new Date(this.rosterProfile.EffectiveDate));
        this.mainForm.get('effectiveDate').clearValidators();
        this.isRequiredEffectiveDate = false;
      }
      if (this.membershipStatus === 'Active' && this.membershipType === 'Member' || this.membershipStatus === 'Active' && this.membershipType === 'Retired') {
        this.mainForm.get('initiationDate').setValidators([Validators.required]);
        this.isInitiationDateRequired = true;
      } else {
        this.mainForm.get('initiationDate').clearValidators();
        this.isInitiationDateRequired = false;
      }
    } else {
      this.setStatusId = null;
      this.membershipStatus = '';
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('statusId').setValue(this.setStatusId);
  }

  getClassData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setClassId = event[0].ID;
    } else {
      this.setClassId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('class').setValue(this.setClassId);
  }

  getOrganizationData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setOrganizationId = event[0].ID;
      this.disabled = false;
    } else {
      this.setOrganizationId = null;
      this.disabled = true;
    }
    this.setPathwayId = null;
    this.initPathway(this.disabled);
    this.getPathwayData(null);
    this.setTypeId = null;
    this.initType(this.disabled);
    this.getTypeData(null);
    this.mainForm.markAsDirty();
    this.mainForm.get('organization').setValue(this.setOrganizationId);
  }

  getPathwayData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setPathwayId = event[0].ID;
    } else {
      this.setPathwayId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('pathway').setValue(this.setPathwayId);
  }

  getBillingProcessData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setBillingId = event[0].ID;
    } else {
      this.setBillingId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('billingStatusID').setValue(this.setBillingId);
  }

  getDuesPaymentData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setDuesPaymentId = event[0].ID;
    } else {
      this.setDuesPaymentId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('duesPaymentID').setValue(this.setDuesPaymentId);
  }
}
