import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import SignaturePad from 'signature_pad';
import {Subject} from 'rxjs';
import {UserProfileModel} from '../../../models/user-profile.model';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ContextService} from '../../../services/context.service';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit {
  signatureNeeded!: boolean;
  signaturePad!: SignaturePad;
  signatureImg!: string;
  personInfo = {} as UserProfileModel;
  PersonID: number;
  fd: FormData = new FormData();
  sigWidth = '560px';
  private ngUnsubscribe = new Subject();

  @ViewChild('canvas') canvasEl!: ElementRef;

  constructor( private contextService: ContextService,
               public config: DynamicDialogConfig, public ref: DynamicDialogRef) {}

  ngOnInit(): void {
    this.sigWidth = this.contextService.contextObject.isMobile ? '365' : '560';
  }
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (!this.signatureNeeded) {
      this.signatureNeeded = false;
    }
    console.log(base64Data);
    this.ref.close(base64Data);
  }



}
