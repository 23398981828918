import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { RosterProfileEmploymentGridModel, RosterProfileEmploymentModel,
  CreateUpdateRosterProfileEmploymentModel } from '../models/roster-profile-employment.model';

@Injectable({
  providedIn: 'root',
})
export class RosterProfileEmploymentService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getRosterEmploymentPrimeNG(dataMode: string, personId: number, filterData): Observable<RosterProfileEmploymentGridModel> {
    return this.http.post<RosterProfileEmploymentGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/employment/grid?mode=${dataMode}`, filterData);
  }

  getRosterEmployment(personId: number, employmentId: number): Observable<RosterProfileEmploymentModel> {
    return this.http.get<RosterProfileEmploymentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/employment/${employmentId}`);
  }

  createRosterEmployment(personId: number, modelData: CreateUpdateRosterProfileEmploymentModel): Observable<RosterProfileEmploymentModel> {
    return this.http.post<RosterProfileEmploymentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/employment/create`, modelData);
  }

  updateRosterEmployment(personId: number, employmentId: number, modelData: CreateUpdateRosterProfileEmploymentModel): Observable<RosterProfileEmploymentModel> {
    return this.http.put<RosterProfileEmploymentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/employment/${employmentId}`, modelData);
  }

  deleteRosterEmployment(personId: number, employmentId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/employment/remove/${employmentId}`);
  }
}
