import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class RouteContextUtility {
  constructor(private router: Router) {}

  public getRouteContext(): string {
    if (this.router.url.includes('/membership/')) {
      return 'membership';
    } else if (this.router.url.includes('/training/')) {
      return 'training';
    } else if (this.router.url.includes('/organize/')) {
      return 'organize';
    }
  }
}
