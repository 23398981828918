export const environment = {
  environmentName: 'dev',
  local: false,
  production: false,
  multiTenant: false,
  tenantEnum: 'IAFF',
  tenantLogo: 'logo_main_black.png',
  apiBaseURL: 'https://utsmart-development.azurewebsites.net',
  cordova: false
};
