@if (designContent || !isEdit) {
  <div class="unlayer-container">
    <!--  <button (click)="exportHtml()">Export</button>-->
    <!--  <email-editor [options]="options" (loaded)="editorLoaded()" (ready)="editorReady()"></email-editor>-->
    <div id="editorContainer" style="height: 650px;"></div>
    @if (dialogLoading) {
      <div class="unlayer-loader">
        <span class="fa fa-spinner list-spinner"></span>
      </div>
    }
  </div>
}
