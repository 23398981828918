<div class="p-fluid p-grid">
  @if (hasPhone) {
    <form [formGroup]="mainForm" class="col-md-12 col-sm-12 col-xs-12">
      @if (recipients) {
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
          @if (recipients.length < 3) {
            <div>
              To: @for (data of recipients; track data) {
              <b>
                {{data.FirstName}} {{data.LastName}}@if (data.Suffix) {
                <span> {{data.Suffix}}</span>
                } - {{data.SecondaryPhoneNumber | phone}},
              </b>
            }
          </div>
        }
        @if (recipients.length >= 3) {
          <div>
            To: <b>
            {{recipients.length}} Recipients
          </b>
        </div>
      }
    </div>
  }
  @if (recipientsStudentCerts) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
      @if (recipientsStudentCerts.length < 3) {
        <div>
          To: @for (data of recipientsStudentCerts; track data) {
          <b>
            {{data.FirstName}} {{data.LastName}} - {{data.SecondaryPhoneNumber | phone}},
          </b>
        }
      </div>
    }
    @if (recipientsStudentCerts.length >= 3) {
      <div>
        To: <b>
        {{recipientsStudentCerts.length}} Recipients
      </b>
    </div>
  }
</div>
}
@if (recipientsClassStudent) {
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
    @if (recipientsClassStudent.length < 3) {
      <div>
        To: @for (data of recipientsClassStudent; track data) {
        <b>
          {{data.FirstName}} {{data.LastName}} - {{data.SecondaryPhoneNumber | phone}},
        </b>
      }
    </div>
  }
  @if (recipientsClassStudent.length >= 3) {
    <div>
      To: <b>
      {{recipientsClassStudent.length}} Recipients
    </b>
  </div>
}
</div>
}
<div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
  <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize" formControlName="content"></textarea>
  @if ((mainForm.controls.content.dirty || mainForm.controls.content.touched) &&
    mainForm.controls.content.errors?.required) {
    <span class="error">SMS Message must be provided</span>
  }
  @if ((mainForm.controls.content.dirty || mainForm.controls.content.touched) &&
    totalCharacters > tcMAXSMSMESSAGECHARS) {
    <span class="error">SMS Message must be {{tcMAXSMSMESSAGECHARS.toString()}} characters or less</span>
  }
  @if (tcAPPLYSMSSEGMENTCALC && !tcSHOWSMSSEGMENTCALC && hasNonAllowedChars) {
    <div class="margin-top-10 sms-nonallowedchars">
      <h3><i class="fas fa-times-circle red-icon"></i> Non-Allowed Characters</h3>
      <p>The following characters are not allowed in SMS messaging. Please replace or remove them to continue. If you copied and pasted this message from somewhere else, you will need to re-type characters that are problematic. Thank you.</p>
      <p-divider class="dark-divider"></p-divider>
      <div>
        {{nonAllowedChars}}
      </div>
    </div>
  }
  @if (tcAPPLYSMSSEGMENTCALC && tcSHOWSMSSEGMENTCALC && hasNonAllowedChars && !onlyOneRecipient) {
    <div class="margin-top-10 sms-highimpactchars">
      <h3><i class="fas fa-exclamation-circle yellow-icon"></i> High-Impact Characters</h3>
      <p>The following characters have a high impact on SMS messaging. They drive up the number of segments used in the message, which drives up the amount of time it takes to send the message and the total cost. If you can, please replace or remove them. If you copied and pasted this message from somewhere else, you can re-type characters that are problematic. Thank you.</p>
      <p-divider class="dark-divider"></p-divider>
      <div>
        {{nonAllowedChars}}
      </div>
    </div>
  }
  @if (tcAPPLYSMSSEGMENTCALC && tcSHOWSMSSEGMENTCALC && !onlyOneRecipient) {
    <div class="p-grid p-fluid margin-top-10 sms-stats">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <h4># of Characters<app-info-tooltip [infoTooltipEnum]="'SMSMESSAGECHARACTERS'"></app-info-tooltip></h4>
        <h2>{{totalCharacters | number:'1.0':'en-US'}}</h2>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <h4># of Segments<app-info-tooltip [infoTooltipEnum]="'SMSMESSAGESEGMENTS'"></app-info-tooltip></h4>
        <h2>{{totalSegments | number:'1.0':'en-US'}}</h2>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <h4># of Recipients</h4>
        @if (!loadingRec) {
          <h2>{{totalRecipients | number:'1.0':'en-US'}}</h2>
        }
        @if (loadingRec) {
          <h2><i class="pi pi-spin pi-spinner save-spinner"></i></h2>
        }
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <h4>Estimated Cost<app-info-tooltip [infoTooltipEnum]="'SMSMESSAGECOST'"></app-info-tooltip></h4>
        @if (!loadingRec) {
          <h2 class="cost">{{totalCost | currency:'USD':'symbol':'1.2-2'}}</h2>
        }
        @if (loadingRec) {
          <h2><i class="pi pi-spin pi-spinner save-spinner cost"></i></h2>
        }
      </div>
    </div>
  }
</div>
<div class="col-md-12 col-sm-12 col-xs-12 margin-top-15 text-align-right">
  <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
  (click)="cancel()"></button>
  @if (!isSaving) {
    <button pButton label="Send" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Send" tooltipPosition="bottom"
      (click)="processData()" [className]="'inline-button'"
    [disabled]="mainForm.invalid || !mainForm.dirty || totalCharacters > tcMAXSMSMESSAGECHARS || (tcSHOWSMSSEGMENTCALC === false && hasNonAllowedChars === true)"></button>
  } @else {
    <button pButton label="Send" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
      <i class="pi pi-spin pi-spinner save-spinner"></i>
    </button>
  }
</div>
</form>
}
@if (!hasPhone) {
  <div class="error col-md-12 col-sm-12 col-xs-12 margin-top-15">Sorry there are not any valid phone numbers to receive texts.</div>
}
</div>
