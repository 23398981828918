import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { RSSFeedsGridModel, CreateUpdateRSSFeedModel } from '../models/rss-feeds.model';

@Injectable({
  providedIn: 'root',
})
export class RSSFeedsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getRSSFeedsPrimeNG(dataMode: string, organizationId: number, filterData): Observable<RSSFeedsGridModel> {
    return this.http.post<RSSFeedsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/RSSfeed/${organizationId}?mode=${dataMode}`, filterData);
  }

  createRSSFeed(organizationId: number, modelData: CreateUpdateRSSFeedModel): Observable<CreateUpdateRSSFeedModel> {
    return this.http.post<CreateUpdateRSSFeedModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/addRSSfeed/${organizationId}`, modelData);
  }

  updateRSSFeed(rssFeedId: number, modelData: CreateUpdateRSSFeedModel): Observable<CreateUpdateRSSFeedModel> {
    return this.http.put<CreateUpdateRSSFeedModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/RSSfeed/${rssFeedId}`, modelData);
  }

  deleteRSSFeed(rssFeedId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/tenantConfig/RSSfeed/${rssFeedId}`);
  }

  toggleRSSFeed(rssFeedId: number, toggleOnOff: string): Observable<string> {
    //acceptable toggleOnOff values: ON / OFF
    return this.http.put<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/toggleRSS/${rssFeedId}/${toggleOnOff}`, null);
  }
}
