<p-card>
  <div class="row">
    <div class="col-md-6 col-sm-6 col-xs-6">
      <div class="p-card-title">
        <i class="fas fa-user"></i> Members
      </div>
    </div>
  </div>
  <div>
    <div class="p-grid p-fluid margin-top-15">
      <div [ngClass]="{'col-md-2 col-sm-12 col-xs-12 list-filter-container' : showFilter, 'hide' : !showFilter}">
        <div class="margin-top-10 filter-item-padding">
          <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                          [parentObjectEnum]="parentObjectEnum"></app-learn-more>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
          <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <h3>Filter</h3>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding p-mb-2">
            <label>Name</label>
            <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter"
                   (keyup.enter)="filterItems()" tabindex="0"/>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding p-mb-2">
            <label>Member #</label>
            <input class="full-width filter" pInputText type="text" [(ngModel)]="memberNumber"
                   (keyup.enter)="filterItems()" tabindex="0"/>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
            <div class="list-filter-buttons text-align-right">
              <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"
                      [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="resetFilter()">
              </button>
              <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"
                      (click)="filterItems()">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-10 col-sm-12 col-xs-12 list-container-header" [ngClass]="{'col-md-12' : !showFilter}">
        <div class="p-grid p-fluid">
          <h2
            class="col-md-3 col-sm-4 col-xs-12 results-total">{{ dataTotal | number:'1.0':'en-US' }} @if (dataTotal === 1) {
            Result
          } @else {
            Results
          }</h2>
          <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
            <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal" pageLinkSize="3"
                         (onPageChange)="paginate($event)"></p-paginator>
          </div>
          <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
            @if ((currentFilter.filters | json) !== '{}') {
              <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                      pTooltip="Clear Filter" tooltipPosition="bottom"
                      [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                      (click)="resetFilter()">
              </button>
            }
            <button pButton class="p-button-download export-menu p-mr-2" icon="pi pi-filter"
                    pTooltip="Filter Toggle" tooltipPosition="bottom" (click)="toggleFilter()">
            </button>
            <button pButton (click)="refresh()" icon="pi pi-refresh"
                    pTooltip="Refresh" tooltipPosition="bottom"></button>
            @if (!isExporting) {
              <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                      icon="fa fa-file-excel"
                      pTooltip="Export" tooltipPosition="bottom" (click)="export()">
              </button>
            } @else {
              <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                      icon="pi pi-spin pi-spinner save-spinner">
              </button>
            }
          </div>
          <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
            <p-paginator [rows]="pageRowCountUtility.pageRowCount()" [totalRecords]="dataTotal" pageLinkSize="3"
                         (onPageChange)="paginate($event)"></p-paginator>
          </div>
        </div>
        <!--          <div *ngIf="showAddEdit">-->
        <!--            <p-card header="Add Contract" [styleClass]="'list-card'" >-->
        <!--              <ng-template pTemplate="content">-->
        <!--                <app-contracts-tab-details-custom [setEmployerId]="setEmployerId" (closeAddEdit)="handleAdd($event)" [isEdit]="false" [canTabWrite]="canPageWrite"></app-contracts-tab-details-custom>-->
        <!--              </ng-template>-->
        <!--            </p-card>-->
        <!--          </div>-->
        <div class="list-container p-grid p-fluid">
          @if (!loading && dataTotal <= 0) {
            <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
              @if ((currentFilter.filters | json) === '{}') {
                <span><b>No results were found.</b></span>
              } @else {
                <span><i class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
              }
            </div>
          }
          @if (loading) {
            <div class="col-md-12 col-sm-12 col-xs-12">
              <app-data-indicator></app-data-indicator>
            </div>
          }
          @for (listCardData of memberList; track listCardData; let i = $index) {
            <div class="col-md-12">
              <p-card [styleClass]="'list-card'">
                <div class="p-grid p-fluid">
                  <div class="left-column col-md-8 col-sm-12 col-xs-12">
                    <div class="list-header">
                      {{ listCardData.FullName }}
                    </div>
                    <div class="list-subheader">
                      @if (listCardData.MemberNumber) {
                        <span> <b>Member #:</b> {{ listCardData.MemberNumber }}</span>
                      }
                      @if (listCardData.StatusAlias) {
                        <span> | <b>Membership Status:</b> {{ listCardData.StatusAlias }}</span>
                      }
                      @if (listCardData.StartDate_D) {
                        <span> | <b>Start Date:</b> {{ listCardData.StartDate_D | date: 'MM/dd/YYYY' }}</span>
                      }
                    </div>
                  </div>
                  <div class="right-column col-md-4 col-sm-12 col-xs-12">
                    <div class="list-action-buttons">
                      <div class="icon hide-on-mobile" pTooltip="Open Member" tooltipPosition="bottom"
                           (click)="openMemberDetail(listCardData)"><i class="pi pi-window-maximize"></i></div>
                    </div>
                  </div>
                </div>
              </p-card>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</p-card>
