import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {UpdateUserProfileCommunicationPreferenceModel, UpdateUserProfileModel, UserProfileCommunicationPreferencesGridModel, UserProfileCommunicationPreferencesModel, UserProfileCommunicationsGridModel, UserProfileModel, UserProfilePhotoDataUrlModel} from '../models/user-profile.model';
import {ResourceModel} from '../models/resources.model';
import {PointsGridModel, TotalPointsModel} from '../models/points.model';
import {LookupModel} from '../models/lookups.model';
import {RosterProfilePiiModel} from '../models/roster-profile.model';
import {SMSOptStatusModel} from '../models/sms.model';
import {OrganizationPositionsGridModel} from '../models/organization-positions.model';
import {CreateSharableNoteModel} from '../models/sharables.model';
import {SendAdHocCommunicationModel} from '../models/communications.model';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getUserProfile(): Observable<UserProfileModel> {
    return this.http.get<UserProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile`);
  }

  getUserProfileFile(type): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/image/${type}`);
  }

  getUserProfileByContext(context): Observable<UserProfileModel> {
    return this.http.get<UserProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/personProfile/byContext/${context}`);
  }

  updateUserProfile(modelData: UserProfileModel): Observable<any> {
    return this.http.put<UserProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile?ignoreaddressupdates=true`, modelData);
  }

  getUserStudentProfile(): Observable<UserProfileModel> {
    return this.http.get<UserProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/studentprofile`);
  }

  updateUserStudentProfile(modelData: UpdateUserProfileModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/studentprofile?ignoreaddressupdates=true`, modelData);
  }

  getUserPreference(): Observable<UserProfileCommunicationPreferencesModel> {
    return this.http.get<UserProfileCommunicationPreferencesModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/preferences/communicate`);
  }

  updateUserPreferences(modelData: UpdateUserProfileCommunicationPreferenceModel[]): Observable<string> {
    return this.http.put<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/preferences`, modelData);
  }

  updateUserPreference(modelData: UpdateUserProfileCommunicationPreferenceModel): Observable<string> {
    return this.http.put<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/preference`, modelData);
  }

  getUserPreferenceOptions(option: string): Observable<LookupModel> {
    return this.http.get<LookupModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/preferenceoptions/${option}`);
  }

  //TODO - handle form data file
  updateUserProfileFile(formData: FormData, type): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/image/${type}`, formData);
  }

  updateUserProfilePhotoUrl(modelData: UserProfilePhotoDataUrlModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/photo/fromdataurl`, modelData);
  }

  getPersonImage(type: string, personId: number): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/${personId}/image/${type}`);
  }

  uploadPersonImage(type: string, personId: number, formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/${personId}/image/${type}`, formData);
  }


  getAllOptInUserPreferencesPrimeNG(dataMode: string, filterData): Observable<UserProfileCommunicationPreferencesGridModel> {
    return this.http.post<UserProfileCommunicationPreferencesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/optinpreferences?mode=${dataMode}`, filterData);
  }

  mobilePhoneNumberCheck(phoneNumber: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/ismobile?phone=${phoneNumber}`);
  }

  getUserCommunicationsPrimeNG(dataMode: string, filterData): Observable<UserProfileCommunicationsGridModel> {
    return this.http.post<UserProfileCommunicationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/communicationgrid?mode=${dataMode}`, filterData);
  }

  getUserPointHistoryPrimeNG(dataMode: string, filterData): Observable<PointsGridModel> {
    return this.http.post<PointsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/pointsgrid?mode=${dataMode}`, filterData);
  }

  getUserPointsTotal(): Observable<TotalPointsModel> {
    return this.http.get<TotalPointsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/pointstotal`);
  }

  isEmailTaken(emailAddress: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/isemailtaken?email=${emailAddress}`);
  }

  getUserProfilePhoto(personId: number): Observable<ResourceModel[]> {
    return this.http.get<ResourceModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/listbytype/profilephoto/${personId}`);
  }

  getUserProfilePii(): Observable<RosterProfilePiiModel> {
    return this.http.get<RosterProfilePiiModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/pii`);
  }

  updateUserProfilePii(modelData: RosterProfilePiiModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/pii`, modelData);
  }

  getUserSmsOptOutStatus(mobileNumber: string): Observable<SMSOptStatusModel[]> {
    return this.http.get<SMSOptStatusModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/sms/optoutstatus/${mobileNumber}`);
  }

  getUserPathwayPositionByOrgTypeData(organizationType: string, filterData): Observable<OrganizationPositionsGridModel> {
    return this.http.post<OrganizationPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/pathwayposition/byorganizationtype/${organizationType}/grid?mode=DATA`, filterData);
  }

  sendUserCommunicationAdHoc(modelData: SendAdHocCommunicationModel ): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/communication/adhoc`, modelData);
  }

  createUserSharable(modelData: CreateSharableNoteModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/sharable`, modelData);
  }
}
