<form [formGroup]="mainForm">
  <div class="p-grid p-fluid">
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="setOrganizationData($event)"></app-select>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <label>Name <sup class="req">*</sup></label>
      <input class="full-width" type="text" pInputText  formControlName="name">
      @if ((mainForm.controls.name.dirty || mainForm.controls.name.touched) &&
        mainForm.controls.name.errors?.required) {
        <span class="error">'Name' must be provided</span>
      }
    </div>
    <!--  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">-->
    <!--    <label>Match Type <sup class="req">*</sup></label>-->
    <!--    <p-dropdown [options]="matchType" [(ngModel)]="selectedMatch" optionLabel="MatchTypeDescription" optionValue="MatchTypeID"></p-dropdown>-->
    <!--    <span class="error" *ngIf="(mainForm.controls.name.dirty || mainForm.controls.name.touched) &&-->
  <!--                    mainForm.controls.name.errors?.required">'Name' must be provided</span>-->
<!--  </div>-->
<div class="col-md-12 col-sm-12 col-xs-12 margin-top-15 text-align-right">
  <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
  (click)="cancel()"></button>
  @if (!isSaving) {
    <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Send" tooltipPosition="bottom"
    (click)="processData()" [className]="'inline-button'" [disabled]="this.mainForm.invalid"></button>
  } @else {
    <button pButton label="Send" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
      <i class="pi pi-spin pi-spinner save-spinner"></i>
    </button>
  }
</div>
</div>
</form>
