@if (customField) {
  <form [formGroup]="mainForm">
    @if (fieldType === 'YESNO') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <app-yes-no-select [isBoolean]="false"
                           [selectedValue]="mainForm.get('value').value"
                           (emitData)="mainForm.get('value').setValue($event);mainForm.markAsDirty();processData();"
                           [nullable]="true" [disabled]="!canTabWrite" tabindex="0"
        ></app-yes-no-select>
        <!--        <div class="radio-container">-->
        <!--          <label>{{ fieldDesc }}</label>-->
        <!--          <p-radioButton value="Yes" formControlName="value" id="yes" [styleClass]="'radio-input'"-->
        <!--                         (onClick)="processData()" tabindex="0"></p-radioButton>-->
        <!--          <label class="radio-label" for="yes">Yes</label>-->
        <!--          <p-radioButton value="No" formControlName="value" id="no" [styleClass]="'radio-input'"-->
        <!--                         (onClick)="processData()" tabindex="0"></p-radioButton>-->
        <!--          <label class="radio-label" for="no">No</label>-->
        <!--        </div>-->
        <!--        <div class="switch-container">-->
        <!--          <p-inputSwitch formControlName="value" (onChange)="mainForm.get('value').setValue($event);mainForm.markAsDirty();processData();" tabindex="0"></p-inputSwitch>-->
        <!--          <div class="input-switch-label p-ml-2">{{ fieldDesc }}</div>-->
        <!--        </div>-->
      </div>
    }
    @if (fieldType === 'CHECKBOX') {
      <div class="input-container margin-top-10">
        <p-checkbox binary="false" trueValue="Yes" formControlName="value" (onChange)="processData()"
                    [label]="fieldDesc" [disabled]="!canTabWrite" tabindex="0"></p-checkbox>
      </div>
    }
    @if (fieldType === 'PICKLIST') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <p-dropdown [options]="customField.Options" optionLabel="Description" optionValue="Description"
                    formControlName="value" (onChange)="processData()" appendTo="body" [disabled]="!canTabWrite"
                    tabindex="0"></p-dropdown>
      </div>
    }
    @if (fieldType === 'SLE') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <input type="text" pInputText formControlName="value" (change)="processData()" [disabled]="!canTabWrite"
               tabindex="0">
      </div>
    }
    @if (fieldType === 'NUMBER' || fieldType === 'NUMERICID') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <p-inputNumber formControlName="value" class="full-width" [showButtons]="true" (onBlur)="processData()"
                       [disabled]="!canTabWrite"
                       tabindex="0"></p-inputNumber>
      </div>
    }
    @if (fieldType === 'DECIMAL2') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <p-inputNumber formControlName="value" class="full-width" mode="decimal" [minFractionDigits]="2"
                       [maxFractionDigits]="2" [showButtons]="true" (onBlur)="processData()" [disabled]="!canTabWrite"
                       tabindex="0"></p-inputNumber>
      </div>
    }
    @if (fieldType === 'DECIMAL4') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <p-inputNumber formControlName="value" class="full-width" mode="decimal" [minFractionDigits]="2"
                       [maxFractionDigits]="4" [showButtons]="true" (onBlur)="processData()" [disabled]="!canTabWrite"
                       tabindex="0"></p-inputNumber>
      </div>
    }
    @if (fieldType === 'MLE') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <textarea pInputTextarea formControlName="value" rows="5" autoResize="autoResize" (change)="processData()"
                  [disabled]="!canTabWrite"
                  tabindex="0"></textarea>
      </div>
    }
    @if (fieldType === 'USD') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <!--    <p-inputNumber formControlName="value" [showButtons]="true" mode="currency" currency="USD" locale="en-US" (onBlur)="processData()"></p-inputNumber>-->
        <app-currency-input [initValue]="mainForm.get('value').value"
                            (emitData)="mainForm.get('value').setValue($event)" [disabled]="!canTabWrite"
                            tabindex="0"></app-currency-input>
      </div>
    }
    @if (fieldType === 'DATE') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="value" appendTo="body" placeholder="mm/dd/yyyy"
                    [showIcon]="true" (onInput)="processData()"
                    (onSelect)="processData()" (onBlur)="processData()" [disabled]="!canTabWrite"
                    tabindex="0"></p-calendar>
      </div>
    }
    @if (fieldType === 'TIME') {
      <div class="input-container margin-top-10">
        <label>{{ fieldDesc }}</label>
        <p-calendar dateMask formControlName="value" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12"
                    icon="pi pi-clock"
                    [showIcon]="true" (onInput)="processData()"
                    (onSelect)="processData()" (onBlur)="processData()" [disabled]="!canTabWrite"
                    tabindex="0"></p-calendar>
      </div>
    }
  </form>
}
