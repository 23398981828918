import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {OrganizationTaxFormsService} from '../../../../services/organization-tax-forms.service';
import {OrganizationTaxFormModel} from '../../../../models/organization-tax-forms.model';
import {ResourcesService} from '../../../../services/resources.service';
import {GenericSelectModel} from 'src/app/models/generic-select.model';
import {ContextService} from '../../../../services/context.service';

@Component({
  selector: 'app-organization-tax-form',
  templateUrl: './organizations-tax-form.component.html',
  styleUrls: ['./organizations-tax-form.component.scss']
})
export class OrganizationsTaxFormComponent implements OnInit, OnChanges {
  inputObjTaxForm: GenericSelectModel;
  setTaxFormId: number;
  mainForm: FormGroup;
  taxforms = {} as OrganizationTaxFormModel;
  isSaving: boolean = false;
  loading: boolean = false;
  showProgress: boolean;
  fd: FormData = new FormData();
  uploadedFiles: any[] = [];
  existingMessage: boolean = false;
  @Input() taxformData: any;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() taxFomID: number;
  @Input() ID: number;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();
  cols: any[];

  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private lookupsDataService: LookupsDataService,
              private organizationTaxFormsService: OrganizationTaxFormsService,
              private formBuilder: FormBuilder,
              private resourcesService: ResourcesService,
              private contextService: ContextService
  ) {
    this.mainForm = this.formBuilder.group({
      TaxFormTypeDescription: new FormControl(null, Validators.required),
      TaxFormYear: new FormControl(null, Validators.required),
      TaxFormFile: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    this.initForm();
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Tax form data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initForm(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    if (this.taxformData) {
      this.mainForm.get('TaxFormFile').setValue(this.taxformData.ResourceReferenceID);
      this.setTaxFormId = this.taxformData.TaxFormID;
      this.mainForm.get('TaxFormTypeDescription').setValue(this.setTaxFormId);
      this.mainForm.get('TaxFormYear').setValue(this.taxformData.Year_N);
    } else {
      this.mainForm.get('TaxFormYear').setValue(new Date().getFullYear());
      this.uploadedFiles.length = 0;
      this.fd = new FormData();
    }
    this.initTaxForm(false);
    this.loading = false;
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.taxforms.Year_N = this.mainForm.get('TaxFormYear').value;
      this.taxforms.OrganizationTaxFormID = this.setTaxFormId;
      this.saveForm();
    } else {
      this.messageService.add({severity: 'warn', summary: 'Missing Data', detail: 'No data was saved, the form is currently incomplete.'});
      this.existingMessage = false;
    }
  }

  initTaxForm(disable: boolean): void {
    this.inputObjTaxForm = {
      labelText: 'Form',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setTaxFormId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getTaxFormsLookupData().then((lookupData) => {
      this.inputObjTaxForm.data = lookupData;
      this.inputObjTaxForm = Object.assign({}, this.inputObjTaxForm);
    });
  }

  getTaxFormData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setTaxFormId = event[0].ID;
    } else {
      this.setTaxFormId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('TaxFormTypeDescription').setValue(this.setTaxFormId);
  }

  saveForm(): void {
    this.saveNewFiles().then((fileData) => {
      if (fileData) {
        let obj: any;
        if (this.taxformData === undefined) {
          obj = {
            OrganizationTaxFormID: Number(0),
            OrganizationID: Number(this.ID),
            ResourceID: fileData[0].ResourceId,
            Form: '',
            TaxFormID: Number(this.setTaxFormId),
            Year_N: Number(this.mainForm.get('TaxFormYear').value)
          };
          this.organizationTaxFormsService.updateOrganizationTaxForm(obj)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.confirmationService.close();
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'The document has been added.'
                });
                this.closeAddEdit.emit(true);
                this.isSaving = false;
                this.existingMessage = false;
              }, error: (e) => {
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
                this.isSaving = false;
                console.debug(e);
              }
            });
        } else {
          this.organizationTaxFormsService.updateOrganizationTaxForm(this.taxformData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.confirmationService.close();
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'The document has been added.'
                });
                this.closeAddEdit.emit(true);
                this.isSaving = false;
                this.existingMessage = false;
              }, error: (e) => {
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
                this.isSaving = false;
                console.debug(e);
              }
            });
        }
      }
    });
  }

  saveNewFiles(): any {
    this.showProgress = true;
    return new Promise((resolve, reject) => {
      if (this.uploadedFiles.length > 0) {
        this.resourcesService.uploadResource(this.fd)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (fileData) => {
              resolve(fileData);
              this.showProgress = false;
            }
          });
      } else {
        this.showProgress = false;
        resolve(null);
      }
      console.debug(reject);
    });
  }

  onUpload(event: any): any {
    if (event.files.length > 0) {
      this.mainForm.get('TaxFormFile').setValue(event.files.length);
      for (const file of event.files) {
        this.uploadedFiles.push(file);
        this.fd.append('file', file);
      }
      this.mainForm.markAsDirty();
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  clearUpload(): void {
    this.uploadedFiles.length = 0;
    this.fd = new FormData();
    this.mainForm.get('TaxFormFile').setValue(null);
    this.mainForm.markAsDirty();
  }

  openResource(resourceReferenceId: number): void {
    this.resourcesService.getResourceById(resourceReferenceId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (resource) => {
          if (resource) {
            window.open(resource.Url, '_blank');
          }
        }
      });
  }
}
