@if (!loading) {
  <div class="content p-input-filled margin-top-10">
    <div>
      <p-card>
        <form [formGroup]="mainForm">
          @if (!isEdit) {
            <div class="p-grid p-fluid">
              <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
                <label>Title/Description <sup class="req">*</sup></label>
                <input pInputText  formControlName="description">
                @if ((mainForm.controls.description.dirty || mainForm.controls.description.touched) &&
                  mainForm.controls.description.errors?.required) {
                  <span class="p-error">'Title/Description' must be provided</span>
                }
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12 p-mb-2">
                <app-select class="full-width" [inputObj]="inputObjTemplateType" (emitData)="setTemplateTypeData($event)"></app-select>
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12 p-mb-2">
                <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="setOrganizationData($event)"></app-select>
              </div>
              <div class="col-md-4 col-sm-12 col-xs-12 p-mb-2">
                <app-select class="full-width" [inputObj]="inputObjTemplate" (emitData)="setTemplateData($event)"></app-select>
              </div>
              @if (showHide) {
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="p-grid p-fluid p-mt-2">
                    <div class="col-md-6 col-sm-6 col-xs-6">
                      <!--            <h3>Officer CC's</h3>-->
                      <p-checkbox binary="true" label="Select Officer CC's" formControlName="ccShowHide" (onChange)="ccShowHide()"></p-checkbox>
                    </div>
                    @if (showHideCC) {
                      <div class="col-md-6 col-sm-6 col-xs-6">
                        @if ((currentCCFilter.filters | json) !== '{}') {
                          <button pButton class="p-button-download export-menu float-right p-mb-2" icon="pi pi-filter-slash"
                            pTooltip="Clear Filter" tooltipPosition="bottom" [ngClass]="{'filter-active': (currentCCFilter.filters | json) !== '{}'}"
                          (click)="clearFilters()"></button>
                        }
                      </div>
                    }
                    @if (showHideCC) {
                      <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
                        <p-table
                          #dtm
                          [rows]="pageRowCountUtility.pageRowCount()"
                          [columns]="colsCC"
                          dataKey="PersonID"
                          [value]="ccList"
                          [lazy]="true"
                          (onLazyLoad)="loadCCTable($event)"
                          [paginator]="true"
                          [showCurrentPageReport]="true"
                          [metaKeySelection]="false"
                          [totalRecords]="ccTotal"
                          [scrollable]="true"
                          [(selection)]="selCC"
                          responsiveLayout="scroll"
                          selectionMode="multiple"
                          scrollHeight="310px"
                          [loading]="loadingCCTable"
                          scrollDirection="both"
                          class="full-width"
                          styleClass="p-datatable-responsive-demo p-datatable-striped inner-table dialog-table"
                          currentPageReportTemplate="Showing {first} to {last} of {{ccTotal | number}} entries">
                          <ng-template pTemplate="header" let-columns>
                            <tr>
                              <th [style]="{width: '50px'}">
                                &nbsp;&nbsp;<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                              </th>
                              <!--                      <th [style]="{width: '75px'}">-->
                              <!--                        Profile-->
                            <!--                      </th>-->
                            @for (col of columns; track col) {
                              <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" [style]="{width: '100px'}">
                                <ng-container>
                                  <div class="header-text">
                                    {{col.header}}
                                  </div>
                                  <div class="header-actions">
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                    @if (col.field !== 'OrganizationName') {
                                      <p-columnFilter [field]="col.field" display="menu" [matchMode]="col.matchMode" [showOperator]="true" [showAddButton]="false" [type]="col.columnType"></p-columnFilter>
                                    }
                                  </div>
                                </ng-container>
                              </th>
                            }
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" >
                          <tr>
                            <td [style]="{width: '50px'}">
                              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                            </td>
                            <!--                      <td [style]="{width: '75px'}">-->
                            <!--                        <div class="avatar">-->
                            <!--                          <div class="avatar-without-image" [ngClass]="getAvatarClass(false, rowData.MemberStatusAlias)"><img src="assets/images/profile-generic.png" alt=""></div>-->
                          <!--                        </div>-->
                        <!--                      </td>-->
                        @for (col of columns; track col) {
                          <td [style]="{width: '100px'}">
                            @if (col.columnType !== 'date' && !col.field.includes('Phone')) {
                              {{rowData[col.field]}}
                            }
                            @if (col.columnType === 'date') {
                              {{rowData[col.field] | date: 'shortDate'}}
                            }
                            @if (col.field.includes('Phone')) {
                              {{rowData[col.field] | phone}}
                            }
                          </td>
                        }
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td [colSpan]="colsCC.length" [style]="{width: '100%'}">No people found.</td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <p><b># Officer CC Selected:</b> {{this.selCC.length}}</p>
                </div>
              }
            </div>
          </div>
        }
        @if (showHide) {
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="p-grid p-fluid p-mt-2">
              <div class="col-md-6 col-sm-6 col-xs-6">
                <!--            <h3>Employer CC's</h3>-->
                <p-checkbox binary="true" label="Select Employer CC's" formControlName="ccEmpShowHide" (onChange)="ccEmpShowHide()"></p-checkbox>
              </div>
              @if (showHideCCEmp) {
                <div class="col-md-6 col-sm-6 col-xs-6">
                  @if ((currentCCEmpFilter.filters | json) !== '{}') {
                    <button pButton class="p-button-download export-menu float-right p-mb-2" icon="pi pi-filter-slash"
                      pTooltip="Clear Filter" tooltipPosition="bottom" [ngClass]="{'filter-active': (currentCCEmpFilter.filters | json) !== '{}'}"
                    (click)="clearCCEmpFilters()"></button>
                  }
                </div>
              }
              @if (showHideCCEmp) {
                <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
                  <p-table
                    #dtm
                    [rows]="pageRowCountUtility.pageRowCount()"
                    [columns]="colsCCEmp"
                    dataKey="PersonID"
                    [value]="ccEmpList"
                    [lazy]="true"
                    (onLazyLoad)="loadCCEmpTable($event)"
                    [paginator]="true"
                    [showCurrentPageReport]="true"
                    [metaKeySelection]="false"
                    [totalRecords]="ccEmpTotal"
                    [scrollable]="true"
                    [(selection)]="selCCEmp"
                    responsiveLayout="scroll"
                    selectionMode="multiple"
                    scrollHeight="310px"
                    [loading]="loadingCCEmpTable"
                    scrollDirection="both"
                    class="full-width"
                    styleClass="p-datatable-responsive-demo p-datatable-striped inner-table dialog-table"
                    currentPageReportTemplate="Showing {first} to {last} of {{ccEmpTotal | number}} entries">
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th [style]="{width: '50px'}">
                          &nbsp;&nbsp;<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <!--                      <th [style]="{width: '75px'}">-->
                        <!--                        Profile-->
                      <!--                      </th>-->
                      @for (col of columns; track col) {
                        <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" [style]="{width: '100px'}">
                          <ng-container>
                            <div class="header-text">
                              {{col.header}}
                            </div>
                            <div class="header-actions">
                              <p-sortIcon [field]="col.field"></p-sortIcon>
                              @if (col.field !== 'OrganizationName') {
                                <p-columnFilter [field]="col.field" display="menu" [matchMode]="col.matchMode" [showOperator]="true" [showAddButton]="false" [type]="col.columnType"></p-columnFilter>
                              }
                            </div>
                          </ng-container>
                        </th>
                      }
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" >
                    <tr>
                      <td [style]="{width: '50px'}">
                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                      </td>
                      <!--                      <td [style]="{width: '75px'}">-->
                      <!--                        <div class="avatar">-->
                      <!--                          <div class="avatar-without-image" [ngClass]="getAvatarClass(false, rowData.MemberStatusAlias)"><img src="assets/images/profile-generic.png" alt=""></div>-->
                    <!--                        </div>-->
                  <!--                      </td>-->
                  @for (col of columns; track col) {
                    <td [style]="{width: '100px'}">
                      @if (col.header !== 'Profile' && col.columnType !== 'date' && !col.field.includes('Phone')) {
                        {{rowData[col.field]}}
                      }
                      @if (col.columnType === 'date') {
                        {{rowData[col.field] | date: 'shortDate'}}
                      }
                      @if (col.field.includes('Phone')) {
                        {{rowData[col.field] | phone}}
                      }
                    </td>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [colSpan]="colsCC.length" [style]="{width: '100%'}">No people found.</td>
                </tr>
              </ng-template>
            </p-table>
            <p><b># Employer CC Selected:</b> {{this.selCCEmp.length}}</p>
          </div>
        }
      </div>
    </div>
  }
  <!--          <div class="col-md-6 col-sm-12 col-xs-12 radio-container">-->
  <!--            <label class="p-mt-2">Attach Letter to Recipient Record<app-info-tooltip [infoTooltipEnum]="'MAILMERGEATTACHLETTER'"></app-info-tooltip></label>-->
  <!--            <p-radioButton value="Y" formControlName="attach" [styleClass]="'radio-input'" class="p-mr-2" label="Yes"></p-radioButton>-->
  <!--            <p-radioButton value="N" formControlName="attach" [styleClass]="'radio-input'" label="No"></p-radioButton>-->
<!--          </div>-->
<div class="col-md-12 col-sm-12 col-xs-12 input-container">
  <div class="p-fluid p-grid">
    <div class="col-md-2 col-sm-12 col-xs-12">
      <label class="p-mt-2">Include CC'd<app-info-tooltip [infoTooltipEnum]="'MAILMERGEINCLUDECC'"></app-info-tooltip></label>
      <p-radioButton value="Y" formControlName="includeCC" [styleClass]="'radio-input'" class="p-mr-2" label="Yes"></p-radioButton>
      <p-radioButton value="N" formControlName="includeCC" [styleClass]="'radio-input'" label="No"></p-radioButton>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjAttachmentType" (emitData)="setAttachmentType($event)"></app-select>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12 input-container">
      <label>Letter Type</label>
      <span>
        <p-checkbox binary="true" label="E-mail" formControlName="email"></p-checkbox>
      </span>
      @if (isDymo) {
        <span class="p-ml-4">
          <p-checkbox binary="true" label="Print DYMO Label" formControlName="label"></p-checkbox>
        </span>
      }
      <span class="p-ml-4">
        <p-checkbox binary="true" label="Print Letter" formControlName="print"></p-checkbox>
      </span>
    </div>
  </div>
</div>
@if (canPageWrite) {
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="p-grid p-fluid">
      <div class="col-md-6 col-sm-6 col-xs-12"></div>
      <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
        @if (isEdit) {
          <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
          (click)="cancel()" [disabled]="!mainForm.dirty"></button>
        } @else {
          <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
          (click)="cancel()"></button>
        }
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
            (click)="processData()" [className]="'inline-button'" [disabled]="!isFormValid()"
          ></button>
        } @else {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  </div>
}
</div>
}
</form>
</p-card>
</div>
</div>
}
