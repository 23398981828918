import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {ActivityLookupModel, BillingAdjustmentTypeLookupModel, CommunicationSenderLookupModel, DocumentContextLookupModel, EmailAddressLookupModel, JotFormFormListLookupModel, LookupModel, OrganizationsByObjectAccessLookupModel, PeriodsLookupModel, ProgramFacetTypeLookupModel, ProgramStudentPeriodLookupModel, StateLookupModel, UnlinkedOrganizationsModel} from '../models/lookups.model';
import {ClassesGridModel} from '../models/classes.model';

@Injectable({
  providedIn: 'root',
})
export class LookupsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getAgentFactorsbyOrganizationIdLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/agentfactorsbyorganization/${organizationId}`);
  }

  getAgentFactorsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/agentfactors`);
  }

  getAgentFactorOptionssLookup(factorId: number, organizationId?: number): Observable<LookupModel[]> {
    const url = (organizationId) ? `?routeorganizationid=${organizationId}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/agentfactoroptions/${factorId}${url}`);
  }

  getAttributeDataTypeByEntityLookup(entity: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/customdatatypesbyentity/${entity}`);
  }

  getAutomationScheduleTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/automationscheduletype`);
  }

  getAutoPayLookup(argBillingCategory: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/autopayoptions/bybillingcategory/${argBillingCategory}`);
  }

  getProfileActivities(): Observable<ActivityLookupModel[]> {
    return this.http.get<ActivityLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0//common/personprofileactivities`);
  }

  getAvailableActivities(personId: number, context?, applicant?): Observable<ActivityLookupModel[]> {
    let url = context !== null && context !== undefined ? `?context=${context}` : '';
    if (url !== '') {
      url = applicant !== null && applicant !== undefined ? `${url}&applicant=${applicant}` : url;
    } else {
      url = applicant !== null && applicant !== undefined ? `?applicant=${applicant}` : '';
    }

    return this.http.get<ActivityLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/availableactivities${url}`);
  }

  getAvailableActivitiesNewMember(orgId: number, profileType: number, applicant?: boolean): Observable<ActivityLookupModel[]> {
    const url = applicant ? `&applicant=${applicant}` : '';
    return this.http.get<ActivityLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/0/availableactivities?limitByOrganizationId=${orgId}&limitByPersonProfileTypeId=${profileType}${url}`);
  }

  getAvailableApprenticeHoursType(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personhourstypes`);

  }

  getAvailableBillingAdjustmentsLookup(organizationId: number): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/availablebillingadjustments/byorganization/${organizationId}`);
  }

  getAvailableOrganizationBillingPeriodsLookup(organizationId: number): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/availableorganizationbillingperiods/byorganization/${organizationId}`);
  }

  getAvailableCommunicationSendersLookup(communicationId: number): Observable<CommunicationSenderLookupModel[]> {
    return this.http.get<CommunicationSenderLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/${communicationId}/availablesenders`);
  }

  getBargainingUnitLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/bargainingunit`);
  }

  getBargainingUnitByOrgLookup(organizationId: number, currentId?: number): Observable<LookupModel[]> {
    const url = currentId ? `${organizationId}?currentId=${currentId}` : organizationId;
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/bargainingunit/byorganization/${url}`);
  }

  getBillingAdjustmentsByOrganizationIdLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingadjustmenttypes/${organizationId}`);
  }

  getBillingAdjustmentsFrequencyLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/moneyAgentRateFrequencies`);
  }


  getBillingAdjustmentsByPersonIdLookup(personId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/availablebillingadjustments/byperson/${personId}`);
  }

  getBillingAdjustmentTypesLookup(): Observable<BillingAdjustmentTypeLookupModel[]> {
    return this.http.get<BillingAdjustmentTypeLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingadjustmenttype`);
  }

  getBillingAdjustmentTypesByPaymentOptionsLookup(paymentOptionsOnly: boolean): Observable<BillingAdjustmentTypeLookupModel[]> {
    return this.http.get<BillingAdjustmentTypeLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingadjustmenttype?paymentOptionsOnly=${paymentOptionsOnly}`);
  }

  getBillingPeriodByDateLookup(date: string): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingperiodbydate?date=${date}`);
  }

  getBillingPeriodByOrganizationLookup(organizationId: number): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingperiod/${organizationId}`);
  }

  getTradeSkills(tradeId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/skills/bytradeid/${tradeId}`);
  }

  getSkills(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/skill`);
  }

  getPersonTrade(tradeId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/skills/bytradeid/${tradeId}`);
  }

  getBillingPeriodsLookup(snapshotsonly?: boolean): Observable<PeriodsLookupModel[]> {
    const url = (snapshotsonly) ? `?snapshotsonly=${snapshotsonly}` : '';
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingperiod${url}`);
  }

  getCampaignProspectStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/campaignprospectstatus`);
  }

  getCampaignStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/campaignstatus`);
  }

  getCampaignTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/campaigntype`);
  }

  getProspectExperienceLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/experience`);
  }

  getProspectIssuesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeissue`);
  }

  getProspectEmployerLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeprojectorganization`);
  }

  getCaseLanguageTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/caselanguagetypes`);
  }

  getCaseSubjectsLookup(casetypeid?: number): Observable<LookupModel[]> {
    const url = (casetypeid) ? `?casetypeid=${casetypeid}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/casesubjects${url}`);
  }

  getCaseTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/casetypes`);
  }

  getCertificationsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/certifications`);
  }

  getClassAttendanceStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/classattendancestatuses`);
  }

  getClassAttendancePeriodsLookup(classId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/attendanceperiods/byclass/${classId}`);
  }

  getClassStaffFiltersLookup(trainingCenterId?: number, courseid?: number, start_dt?: string, end_dt?: string): Observable<LookupModel[]> {
    let url: string = '';
    if (trainingCenterId) {
      url = `&trainingcenterid=${trainingCenterId}`;
    }
    if (courseid) {
      url += url === '' ? `?courseid=${courseid}` : `&courseid=${courseid}`;
    }
    if (start_dt) {
      url += url === '' ? `?start_dt=${start_dt}` : `&start_dt=${start_dt}`;
    }
    if (end_dt) {
      url += url === '' ? `?end_dt=${end_dt}` : `&end_dt=${end_dt}`;
    }
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/classstatuses${url}`);
  }

  getClassStaffFilterFieldLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/classStaffFilter`);
  }

  getClassStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/classstatuses`);
  }

  getTrainingModuleTemplatesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trainingmodules`);
  }

  getTrainingModuleTemplatesDownstreamLookup(orgId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trainingmodules/byDownstreamOrganizationId/${orgId}`);
  }

  getAllClassesLookup(filterData, startDate: string, endDate: string, orgId: number, courseId: number): Observable<ClassesGridModel> {
    let url = (orgId) ? `&trainingcenterid=${orgId}` : '';
    url += (courseId) ? `&courseid=${courseId}` : '';
    url += (startDate) ? `&start_dt=${startDate}` : '';
    url += (endDate) ? `&end_dt=${endDate}` : '';
    return this.http.post<ClassesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/grid/?mode=DATA${url}`, filterData);
  }

  getClassInstructorsLookup(orgId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organization/${orgId}/trainingstaff`);
  }

  getCognitiveLevelsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/cognitivelevels`);
  }

  getCombinedDistributionListsLookup(organizationId: number, context: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/combineddistribution/${organizationId}?context=${context}`);
  }

  getCommunicationsBySurveyIdLookup(surveyId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/surveycommunications/${surveyId}`);
  }

  getContactCategoriesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/contactcategories`);
  }

  getContextsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/contexts`);
  }

  getContractsLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/contracts/${organizationId}`);
  }

  getContractTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/contracttypes`);
  }

  getCountriesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/countries`);
  }

  getCountriesUnauthLookup(tenantEnum: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/countries/bytenant/${tenantEnum}`);
  }

  getCoursesByDownstreamOrganizationIdLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/courses/byDownstreamOrganizationId/${organizationId}`);
  }

  getCoursesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/courses`);
  }

  getCourseTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/coursetypes`);
  }

  getCoursesUnauthLookup(tenantEnum: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/courses/bytenant/${tenantEnum}`);
  }

  getCoursesByClassIdLookup(classId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/courses/byClassId/${classId}`);
  }

  getPassFail(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/results/bytype/PASSFAIL`);
  }

  getStudentsByClassIdLookup(classId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/students/byClassId/${classId}`);
  }

  getCraftsLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/crafts/${organizationId}`);
  }

  getDocumentContextsLookup(interfaceObjectEnum: string): Observable<DocumentContextLookupModel[]> {
    return this.http.get<DocumentContextLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/documentcontextsbyinterfaceobject/${interfaceObjectEnum}`);
  }

  getDocumentContextEntitiesLookup(parentinterfaceobject: string, childinterfaceobject: string, parentkeyid: number): Observable<DocumentContextLookupModel[]> {
    return this.http.get<DocumentContextLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/documentcontextentitylist?parentinterfaceobject=${parentinterfaceobject}&childinterfaceobject=${childinterfaceobject}&parentkeyid=${parentkeyid}`);
  }

  getDownstreamOrganizationPositionsLookup(organizationId: number, category: string): Observable<EmailAddressLookupModel[]> {
    return this.http.get<EmailAddressLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/downstreamOrganizationTypePositions/byorganization/${organizationId}/bycategory/${category}`);
  }

  getDuesGroupsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofileduesgroups`);
  }

  getEmailAddressByUsernameLookup(username: string): Observable<EmailAddressLookupModel[]> {
    return this.http.get<EmailAddressLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/useremailadresses?username=${username}`);
  }

  getEducationalOrganizationsLookup(interfaceObjectEnum: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/educationalorganizationsbyaccess/${interfaceObjectEnum}`);
  }

  getEducationLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/education`);
  }

  getEmployersLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/employers`);
  }

  getEmployersLookupByEnum(interfaceObjectEnum: string, cached?: boolean): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/employersbyaccess/${interfaceObjectEnum}?cached=${cached}`);
  }


  getEmployersUnauthLookup(tenantEnum: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/employers/bytenant//${tenantEnum}`);
  }

  getEthnicityLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/ethnicity`);
  }

  getEventAttendeeStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/eventattendeestatuses`);
  }

  getEventLocationByInstanceLookup(EventInstanceID: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/eventlocations/byinstance/${EventInstanceID}`);
  }

  getEventInstanceStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/eventinstancestatuses`);
  }

  getEventInstanceAttendanceStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/eventinstanceattendancestatuses`);
  }

  getEventInstanceAttendancePeriodsLookup(eventInstanceId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/attendanceperiods/byeventinstance/${eventInstanceId}`);
  }

  getEventLocationsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/eventlocations`);
  }

  getEventOnlineMeetingTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/onlinemeetingtypes`);
  }

  getEventsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/events`);
  }

  getFacetsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/facets`);
  }

  getFacetCategoriesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/facetcategories`);
  }

  getFacetTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/facettypes`);
  }

  getFacetsByFacetTypeLookup(facetTypeId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/facets/byfacettype/${facetTypeId}`);
  }

  getFacetTypesByProgramStudentLookup(programStudentId: number, studentFacing?: boolean, cached?: boolean): Observable<LookupModel[]> {
    if (studentFacing === null) {
      return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programstudent/${programStudentId}/facettypes?cached=${cached}`);
    } else if (studentFacing) {
      return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programstudent/${programStudentId}/facettypes?studentFacing=true&cached=${cached}`);
    } else if (!studentFacing) {
      return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programstudent/${programStudentId}/facettypes?nonStudentFacing=true&?cached=${cached}`);
    }
  }

  getGenericLookup(lookupEnum: string, previousValue?: number): Observable<LookupModel[]> {
    const url = (previousValue && previousValue > 0) ? `?previousvalue=${previousValue}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/lookup/${lookupEnum}${url}`);
  }

  getGoogleDriveOrganizationsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/googledriveorganizations`);
  }

  getHonorificsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/honorifics`);
  }

  getInterfaceObjectAppsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/interfacepages`);
  }

  getInterfaceObjectElementsLookup(appId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/interfaceelementsbypageid/${appId}`);
  }

  getJotFormsLookup(organizationId: number): Observable<JotFormFormListLookupModel[]> {
    return this.http.get<JotFormFormListLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/jotforms/byorganization/${organizationId}`);
  }

  getLanguagesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/language`);
  }

  getLanguagesUnauthLookup(tenantEnum: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/language/bytenant/${tenantEnum}`);
  }

  getLCMSSystemsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/lcmsystems`);
  }

  getAttachmentTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/letterattachmenttypes`);
  }

  getMailMergeTemplateTypesLookup(mailMergeTypeId: number, organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/mailmergetemplates/bytypeid/${mailMergeTypeId}/byorganizationid/${organizationId}`);
  }

  getMailMergeTypesLookup(context: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/mailmergetypes?context=${context}`);
  }

  getMembershipOnlyOrganizationsLookup(data: UnlinkedOrganizationsModel): Observable<LookupModel[]> {
    return this.http.post<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/membershiponlyorganizations`, data);
  }

  getMembershipOrganizationsByOrganizationGroupLookup(organizationId: number, membershiponly?: boolean): Observable<OrganizationsByObjectAccessLookupModel[]> {
    const url = (membershiponly) ? `?membershiponly=${membershiponly}` : '';
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/membershiporganizations/bygrouporganization/${organizationId}${url}`);
  }

  getMerchantAccountsLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/merchantaccounts/${organizationId}`);
  }

  getMilitaryLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/military`);
  }

  getMilitaryStatusLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/MilitaryStatuses`);
  }

  getMarriageStatusLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/MarriageStatuses`);
  }

  getShirtSizeLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/shirtsizes`);
  }

  getCitizenshipLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/countries`);
  }

  getMoneyManagerPaymentTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/moneymanagerpaymenttypes`);
  }

  getMoneyManagerStatusesByOrganizationIdLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/moneymanagerstatus/byorganization/${organizationId}`);
  }

  getMoneyManagerStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/moneymanagerstatus`);
  }

  getOccupationClassesLookup(previousValue?: number): Observable<LookupModel[]> {
    const url: string = (previousValue) ? `?previousvalue=${previousValue}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/lookup/occupationclass${url}`);
  }

  getOccupationsLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/occupations/${organizationId}`);
  }

  getOccupationTiersLookup(previousValue?: number): Observable<LookupModel[]> {
    const url: string = (previousValue) ? `?previousvalue=${previousValue}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/lookup/occupationtier${url}`);
  }

  getOrganizationBillingAdjustmentTypesLookup(organizationId: number, pct?: boolean): Observable<LookupModel[]> {
    if (pct) {
      return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingadjustmenttypes/${organizationId}?pct=${pct}`);
    } else {
      return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingadjustmenttypes/${organizationId}`);
    }
  }

  getAvailableMemberStatementBillingPeriodsLookup(routeorganizationid: number): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/AvailableMemberStatementBillingPeriods/byorganization/${routeorganizationid}`);
  }

  getOrganizationBillingPeriodLookup(routeorganizationid: number): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingPeriod/${routeorganizationid}`);
  }

  getOrganizationCategoriesLookup(hasPositionsOnly?: boolean): Observable<LookupModel[]> {
    const url = (hasPositionsOnly === true) ? '?haspositionsonly=true' : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationcategories${url}`);
  }

  getOrganizationChildTypesLookup(organizationId: number, category: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organization/${organizationId}/childtypesbycategory/${category}`);
  }

  getOrganizationByChildLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbychild/UnionEmployer/${organizationId}`);
  }

  getOrganizationsByParentLookup(context: string, Id: number, employerOrgId?: number, cached?: boolean): Observable<LookupModel[]> {
    if (employerOrgId) {
      return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyparent/${context}/${Id}?currentid=${employerOrgId}&cached=${cached}`);
    } else {
      return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyparent/${context}/${Id}?cached=${cached}`);
    }
  }

  getOrganizationsByAccessLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess`);
  }

  getOrganizationListByObjectAccessLookup(componentEnum: string, membershipOnly?: boolean): Observable<LookupModel[]> {
    const url: string = (membershipOnly !== undefined) ? `?membershiponly=${membershipOnly}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess/${componentEnum}${url}`);
  }

  getOrganizationsByAccessTypeIdLookup(interfaceObjectEnum: string, typeId: number): Observable<OrganizationsByObjectAccessLookupModel[]> {
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess/${interfaceObjectEnum}?typeid=${typeId}`);
  }

  getGeneralCommitteeLookup(interfaceObjectEnum: string, pageEnum: string): Observable<OrganizationsByObjectAccessLookupModel[]> {
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess/${interfaceObjectEnum}?typeenum=${pageEnum}`);
  }

  getLocalLookup(interfaceObjectEnum: string, pageEnum: string, routeorganizationid: number): Observable<OrganizationsByObjectAccessLookupModel[]> {
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess/${interfaceObjectEnum}?typeenum=${pageEnum}&routeorganizationid=${routeorganizationid}`);
  }

  getOrganizationsByMailMergeTypeIdLookup(typeId: number): Observable<OrganizationsByObjectAccessLookupModel[]> {
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizations/bymailmergetype/${typeId}`);
  }

  getOrganizationsByMerchantAccountIdLookup(merchantAccountId: number): Observable<OrganizationsByObjectAccessLookupModel[]> {
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/merchantAccount/${merchantAccountId}/organizations?getUnassignedOrgs=true`);
  }

  getOrganizationsByPersonTransactionHistoryLookup(personId: number): Observable<OrganizationsByObjectAccessLookupModel[]> {
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizations/bypersontransactionhistory/${personId}`);
  }

  getOrganizationsByPersonByEnumTransactionHistoryLookup(objectName: string, personId: number): Observable<OrganizationsByObjectAccessLookupModel[]> {
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizations/byaccess/${objectName}/bypersontransactionhistory/${personId}`);
  }

  getOrganizationsByObjectAccessLookup(interfaceObjectEnum: string, membershiponly?: boolean, context?: string, parentsOnly?: boolean, activeOnly?: boolean, cache?: boolean):
    Observable<OrganizationsByObjectAccessLookupModel[]> {
    const url = (membershiponly) ? `?membershiponly=${membershiponly}` : '';
    let contextUrl: string;
    if (membershiponly) {
      contextUrl = (context) ? `&context=${context}` : '';
    } else {
      contextUrl = (context) ? `?context=${context}` : '';
    }
    let parentsOnlyUrl: string;
    if (membershiponly || contextUrl !== '') {
      parentsOnlyUrl = (parentsOnly !== null && parentsOnly !== undefined) ? `&parentsOnly=${parentsOnly}` : '';
    } else {
      parentsOnlyUrl = (parentsOnly !== null && parentsOnly !== undefined) ? `?parentsOnly=${parentsOnly}` : '';
    }
    if (activeOnly === undefined || activeOnly === null) {
      activeOnly = false;
    }
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(
      `${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess/${interfaceObjectEnum}${url}${contextUrl}${parentsOnlyUrl}&activeOnly=${activeOnly}&cache=${cache}`);
  }

  getOrganizationsLocalsByType(): Observable<OrganizationsByObjectAccessLookupModel[]> {
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizations/bytype/localunion?activeonly=true`);
  }

  getOrganizationsByOrganizationGroupLookup(interfaceObjectEnum: string, organizationId: number, membershiponly?: boolean): Observable<OrganizationsByObjectAccessLookupModel[]> {
    const url = (membershiponly) ? `?membershiponly=${membershiponly}` : '';
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbygroup/${interfaceObjectEnum}/${organizationId}${url}`);
  }

  getOrganizationsDataPerspectiveMembershipLookup(activeOnly?: boolean): Observable<OrganizationsByObjectAccessLookupModel[]> {
    if (activeOnly) {
      return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess?context=membership&ignorePerspective=true&indent=false&activeOnly=true`);
    } else {
      return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess?context=membership&ignorePerspective=true&indent=false`);
    }
  }

  getOrganizationsPerspectiveMembershipLookup(ignorePerspective: boolean, indent: boolean): Observable<OrganizationsByObjectAccessLookupModel[]> {
    return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess?context=membership&ignorePerspective=${ignorePerspective}&indent=${indent}`);
  }

  getOrganizationBillingPeriodByOrganizationLookup(organizationId: number): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/availableorganizationbillingperiods/byorganization/${organizationId}`);
  }

  getOrganizationByObjectAccessLookup(componentEnum: string, routeorganizationid: number, membershipOnly: boolean, context: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess/${componentEnum}?routeorganizationid=${routeorganizationid}&membershiponly=${membershipOnly}context=${context}`);
  }

  getOrganizationsDataPerspectiveTrainingLookup(activeOnly?): Observable<OrganizationsByObjectAccessLookupModel[]> {
    if (activeOnly) {
      return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess?context=training&ignorePerspective=true&indent=false&activeOnly=true`);
    } else {
      return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess?context=training&ignorePerspective=true&indent=false`);

    }
  }

  getOrganizationsDataPerspectiveOrganizeLookup(activeOnly?): Observable<OrganizationsByObjectAccessLookupModel[]> {
    if (activeOnly) {
      return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess?context=organize&ignorePerspective=true&indent=false&activeOnly=true`);
    } else {
      return this.http.get<OrganizationsByObjectAccessLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationsbyaccess?context=organize&ignorePerspective=true&indent=false`);
    }
  }

  getOrganizationStatusesLookup(category: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationstatuses/bycategory/${category}`);
  }

  getEmployerTypeLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/employertypes`);
  }

  getEmployerClassificationsLookup(category: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationclassifications/${category}`);
  }

  getEnterpriseTypeLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/enterprisetypes`);
  }

  getMinorityStatusLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/minoritystatuses`);
  }

  getSubdivisionLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/subdivision`);
  }

  getContractStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/contractstatuses`);
  }

  getOrganizationMemberLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organization/${organizationId}/organizationstaff`);
  }

  getCharterStateProvinceLookup(countryId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/stateprovinces/${countryId}`);
  }

  getOrganizationsUnauthLookup(tenantEnum: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizations/bytenant/${tenantEnum}`);
  }

  getPaymentFileFormatsLookup(employerId: number, billingAdjustmentTypeId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/paymentfileformats?employerid=${employerId}&billingadjustmenttypeId=${billingAdjustmentTypeId}`);
  }

  getPaymentTypesLookup(excludeSystemOnlyTypes?: boolean): Observable<LookupModel[]> {
    const url = (excludeSystemOnlyTypes) ? `?excludesystemonlytypes=${excludeSystemOnlyTypes}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/paymenttypes${url}`);
  }

  getHoursFileFormatsLookup(orgId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personhoursfileformats?routeorganizationid=${orgId}`);
  }

  getPathwaysLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/pathwaysbyorganization/${organizationId}`);
  }

  getPeriodTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/periodtypes`);
  }

  getPeriodInterval(programStudentId: number, facetTypeId: number, cached?: boolean): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/periodlist/byfacettype/${facetTypeId}?cached=${cached}`);
  }

  getPeriodIntervalList(programStudentId: number, facetTypeId: number, periodStartDate: string, cached?: boolean): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/periodintervallist/byfacettype/${facetTypeId}?periodStartDate=${periodStartDate}&cached=${cached}`);
  }

  getPersonnelFileFormatsLookup(routeorganizationid: number, employerid: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personnelfileformats?routeorganizationid=${routeorganizationid}&employerid=${employerid}`);
  }

  getPersonProfileTypesByContextLimitLookup(context: string, orgId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofiletypesbycontext/${context}?limitByOrganizationId=${orgId}`);
  }

  getPersonProfileTypesByContextLookup(context: string, active: string, preselected: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofiletypesbycontext/${context}/${active}?preselected=${preselected}`);
  }

  getPersonProfileStatusesByContextLookup(context: string, active: string, preselected: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofilestatusesbycontext/${context}/${active}?preselected=${preselected}`);
  }

  getPersonProfileClassificationsLookup(activeOnly?: boolean): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofileclassifications?activeOnly=${activeOnly}`);
  }

  getPersonProfileClassificationsByTypeLimitLookup(TypeId: number, orgId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofileclassifications/bypersonprofiletype/${TypeId}?limitByOrganizationId=${orgId}`);
  }

  getPersonProfileClassificationsByTypeLookup(TypeId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofileclassifications/bypersonprofiletype/${TypeId}`);
  }

  getPersonProfileClassificationsByTypeByOrgByDOBLookup(TypeId: number, orgId: number, dob: string, activityDate: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofileclassifications/bypersonprofiletype/${TypeId}?limitByOrganizationId=${orgId}&limitByDOB=${dob}&limitByEffectiveDate=${activityDate}`);
  }

  getPersonProfileClassificationsByType(TypeId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofileclassifications/bypersonprofiletype/${TypeId}`);
  }

  getPersonProfileClassificationsByTypeByOrgByPersonIdLookup(TypeId: number, orgId: number, personId: number, activityDate: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofileclassifications/bypersonprofiletype/${TypeId}?limitByOrganizationId=${orgId}&limitByPersonId=${personId}&limitByEffectiveDate=${activityDate}`);
  }

  getPersonProfileDuesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofileduesgroups`);
  }

  getPersonProfileClassificationsUnauthLookup(tenantEnum: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofileclassifications/bytenant/${tenantEnum}`);
  }

  getPersonProfileStatusesInsertLookup(personProfileTypeId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofilestatusesbytype/${personProfileTypeId}`);
  }

  getPersonProfileStatusesLookup(context: string, cached?: boolean): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofilestatusesbycontext/${context}?cached=${cached}`);
  }

  getPersonProfileStatusesUpdateLookup(personProfileTypeId: number, Id: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofilestatusesbytype/${personProfileTypeId}?currentid=${Id}`);
  }

  getPersonProfileTypesInsertLookup(organizationId: number, context: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofiletypesbyorganization/${organizationId}/${context}`);
  }

  getPersonProfileTypesLookup(context: string, cached?: boolean): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofiletypesbycontext/${context}?cached=${cached}`);
  }

  getBillingCategoryOptionsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingcategoryoptions`);
  }

  getPersonProfileTypesByOrgIdLookup(context: string, orgId: number, checkAdmin: boolean): Observable<LookupModel[]> {
    if (checkAdmin !== null) {
      return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofiletypesbycontext/${context}?limitByOrganizationId=${orgId}&checkAdmin=${checkAdmin}`);
    } else {
      return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofiletypesbycontext/${context}?limitByOrganizationId=${orgId}`);

    }
  }

  getPersonProfileTypesByContextByOrgByDOBLookup(context: string, organizationId: number, dob: string, activityDate: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofiletypesbycontext/${context}?limitByOrganizationId=${organizationId}&limitByDOB=${dob}&limitByEffectiveDate=${activityDate}`);
  }

  getPersonProfileTypesByContextByOrgByPersonIdLookup(context: string, organizationId: number, personId: number, dob: string, activityDate: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofiletypesbycontext/${context}?limitByOrganizationId=${organizationId}&limitByPersonId=${personId}&limitByDOB=${dob}&limitByEffectiveDate=${activityDate}`);
  }

  getPersonProfileTypesUpdateLookup(organizationId: number, context: string, Id: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/personprofiletypesbyorganization/${organizationId}/${context}?currentid=${Id}`);
  }

  getPhoneTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/phonetypes`);
  }

  getPositionsByOrganizationIdLookup(organizationId: number, organizationCategory?: string): Observable<LookupModel[]> {
    const url = (organizationCategory) ? `?organizationcategory=${organizationCategory}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/positionsbyorganization/${organizationId}${url}`);
  }

  getPositionsByAccessLookup(interfaceObjectEnum: string, category: string, cached?: boolean): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/assignedpositions/byaccess/${interfaceObjectEnum}/bycategory/${category}?cached=${cached}`);
  }

  getPositionTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/positiontypes`);
  }

  getPositionOrganizationTypesLookup(category: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizationtype/bycategory/${category}`);
  }

  getVoterStatus(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/voterstatuses`);
  }

  getVoterRegistrationStatus(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/voterregistrationstatuses`);
  }

  getVoterParties(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/parties`);
  }

  getPreferencesLookup(preferenceType: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/preferences/${preferenceType}`);
  }

  getProgramFacetTypeFacetsLookup(programFacetTypeId: number, cached?: boolean): Observable<ProgramFacetTypeLookupModel[]> {
    return this.http.get<ProgramFacetTypeLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programfacettype/${programFacetTypeId}/facetlist?cached=${cached}`);
  }

  getProgramFacetTypePeriodIntervalsLookup(programFacetTypeId: number, periodStartDate: string): Observable<ProgramStudentPeriodLookupModel[]> {
    return this.http.get<ProgramStudentPeriodLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programfacettype/${programFacetTypeId}/periodintervallist?periodstart=${periodStartDate}`);
  }

  getProgramFacetTypePeriodsLookup(programFacetTypeId: number): Observable<ProgramStudentPeriodLookupModel[]> {
    return this.http.get<ProgramStudentPeriodLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programfacettype/${programFacetTypeId}/periodlist`);
  }

  getProgramFacetTypesLookup(programId: number, returnAll: number): Observable<LookupModel[]> {
    const url = `&returnAll=${returnAll}`;
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/bulkcollection/programfacettypes?programid=${programId}${url}`);
  }

  getProgramsByPersonLookup(personId: number, cached?: boolean): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programs/byperson/${personId}?cached=${cached}`);
  }

  getProgramsLookup(personId?: number): Observable<LookupModel[]> {
    const url = (personId) ? `?routepersonId=${personId}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programs${url}`);
  }

  getProgramsDownstreamLookup(orgId?: number, hasStipends?: boolean): Observable<LookupModel[]> {
    const url = (hasStipends) ? `?hasstipends=${hasStipends}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programs/bydownstreamorganizationid/${orgId}${url}`);
  }

  getAllProgramsLookup(hasStipends?: boolean): Observable<LookupModel[]> {
    const url = (hasStipends) ? `?hasstipends=${hasStipends}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programs${url}`);
  }

  getAllProgramsPicklistLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programspicklist`);
  }

  getProgramStudentFacetTypesLookup(programStudentId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programstudent/${programStudentId}/facettypes`);
  }

  getProgramStudentGroupsLookup(programId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/program/${programId}/studentgroups`);
  }

  getProgramStudentPeriodsLookup(programStudentId: number, facetTypeId: number): Observable<ProgramStudentPeriodLookupModel[]> {
    return this.http.get<ProgramStudentPeriodLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/periodlist/byfacettype/${facetTypeId}`);
  }

  getProgramTypesLookup(hasStipends?: boolean): Observable<LookupModel[]> {
    const url = (hasStipends) ? `?hasstipends=${hasStipends}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/programtypes${url}`);
  }

  getProgramWageRateGroupsLookup(programId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/program/${programId}/wagerategroups`);
  }

  getRaceLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/race`);
  }

  getReportRunEnvelopeTypesLookup(id: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/reportrunenvelopetypes/byreportruntypeid/${id}`);
  }

  getReportRunTypeLookup(str: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/reportruntypes/byreporttype/${str}`);
  }

  getReportRunPersonOutcomeByIdLookup(id: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/reportrunpersonoutcomes/byreportruntypeid/${id}`);
  }

  getResultsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/results`);
  }

  getResultTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/resulttypes`);
  }

  getRosterQueriesLookup(organizationId?: number, context?: string, activeOnly?: boolean, currentId?: number): Observable<string[]> {
    const activeOnlyUrl: string = '?activeOnly=' + ((activeOnly === false) ? 'false' : 'true');
    const contextUrl: string = (context) ? `&context=${context}` : '';
    const currentIdUrl: string = (currentId) ? `&currentId=${currentId}` : '';
    if (organizationId) {
      return this.http.get<string[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/rosterqueries/${organizationId}${activeOnlyUrl}${contextUrl}${currentIdUrl}`);
    } else {
      return this.http.get<string[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/rosterqueries${activeOnlyUrl}${contextUrl}${currentIdUrl}`);
    }
  }

  getRosterQueriesByAccessLookup(interfaceObjectEnum: string, activeOnly?: boolean, currentId?: number): Observable<string[]> {
    const activeOnlyUrl: string = '?activeOnly=' + ((activeOnly === false) ? 'false' : 'true');
    const currentIdUrl: string = (currentId) ? `&currentId=${currentId}` : '';
    return this.http.get<string[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/rosterqueries/${interfaceObjectEnum}${activeOnlyUrl}${currentIdUrl}`);
  }

  getRosterQueryByObjectAccess(componentEnum: string, activeOnly?: boolean, currentId?: number): Observable<LookupModel[]> {
    const activeOnlyUrl: string = '?activeOnly=' + ((activeOnly === false) ? 'false' : 'true');
    const currentIdUrl: string = (currentId) ? `&currentId=${currentId}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/rosterqueries/${componentEnum}${activeOnlyUrl}${currentIdUrl}`
    );
  }

  getRosterQueryFilterTypes(orgId: number, rosterQueryCategoryId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/reportbuilder/rosterqueryfiltertypes/${rosterQueryCategoryId}?organizationId=${orgId}`
    );
  }

  getRosterQueryFilterTypeFields(orgId: number, rosterQueryFilterTypeId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/reportbuilder/rosterqueryfiltertypefields/${rosterQueryFilterTypeId}?organizationId=${orgId}`
    );
  }

  getRosterQueryByOrganization(organizationId?: number, activeOnly?: boolean, currentId?: number): Observable<LookupModel[]> {
    const url = (organizationId) ? `/${organizationId}` : '';
    const activeOnlyUrl: string = '?activeOnly=' + ((activeOnly === false) ? 'false' : 'true');
    const currentIdUrl: string = (currentId) ? `&currentId=${currentId}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/rosterqueries${url}${activeOnlyUrl}${currentIdUrl}`
    );
  }

  getRosterQueryImportFileFormatsLookup(organizationId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/rosterqueryimportfileformats?routeorganizationid=${organizationId}`);
  }

  getSecurityQuestionsLookup(groupId: number): Observable<LookupModel[]> {
    // groups are only 1, 2, or 3
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/securityquestions/${groupId}`);
  }

  getSharableAccessOptionsLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/sharableaccessoptions/${organizationId}`);
  }

  getSharablePersonProfileAccessOptionsLookup(personProfileId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/sharableaccessoptions/bypersonprofileid/${personProfileId}`);
  }

  getSharableCategoriesLookup(sharableType: string, interfaceObjectEnum?: string): Observable<LookupModel[]> {
    const url = (interfaceObjectEnum) ? `&interfaceobject=${interfaceObjectEnum}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/sharablecategories?sharabletype=${sharableType}${url}`);
  }

  getSharablesLookup(organizationId: number, sharableType: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organization/${organizationId}/sharablesbytype/${sharableType}`);
  }

  getShiftsLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/shifts/${organizationId}`);
  }

  getSnapshotsLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/duessnapshotsbyorganization/${organizationId}`);
  }

  getStatesLookup(countryId: number): Observable<StateLookupModel[]> {
    return this.http.get<StateLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/stateprovinces/${countryId}`);
  }

  getCountyLookup(stateId: number): Observable<StateLookupModel[]> {
    return this.http.get<StateLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/counties/${stateId}`);
  }

  getStatesUnauthLookup(tenantEnum: string, countryId: number): Observable<StateLookupModel[]> {
    return this.http.get<StateLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/stateprovinces/bytenant/${tenantEnum}/bycountry/${countryId}`);
  }

  getStipendBatchStartDatesLookup(): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingPeriod?snapshotsonly=true`);
  }

  getStipendBatchEndDatesLookup(startPeriodId: number): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingPeriod?snapshotsonly=true&startperiodid=${startPeriodId}`);
  }

  getStipendBatchDatesLookup(stipendBatchId: number): Observable<PeriodsLookupModel[]> {
    return this.http.get<PeriodsLookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/billingperiod?stipendbatchid=${stipendBatchId}`);
  }

  getStudentGroupsLookup(organizationId?: number): Observable<LookupModel[]> {
    const url: string = (organizationId) ? `?downstreamOrganizationId=${organizationId}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/studentgroups${url}`);
  }

  getStudentGroupsByAccessLookup(organizationId?: number): Observable<LookupModel[]> {
    const url: string = (organizationId) ? `?downstreamOrganizationId=${organizationId}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/studentgroups/byaccess/programs${url}`);
  }

  getStudentGroupsEnumLookup(interfaceObjectEnum: string, organizationId?: number): Observable<LookupModel[]> {
    const url: string = (organizationId) ? `?downstreamOrganizationId=${organizationId}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/studentgroups/byaccess/${interfaceObjectEnum}${url}`);
  }

  getSubjectAreasLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/subjectareas`);
  }

  getSurveysLookup(organizationId: number, context: string, hasResponsesOnly?: boolean): Observable<LookupModel[]> {
    const url = (hasResponsesOnly) ? `&hasresponsesonly=${hasResponsesOnly}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/surveys/${organizationId}?context=${context}${url}`);
  }

  getTaxFormTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/taxFormTypes`);
  }

  getTimezonesLookup(date: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/timezones?date=${date}`);
  }

  getTrackingTypesByPeriodTypeIdLookup(periodTypeId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trackingtypes/byperiodtype/${periodTypeId}`);
  }

  getTrackingTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trackingtypes`);
  }

  getTradesLookup(contractId?: number): Observable<LookupModel[]> {
    const url = contractId ? `?contractid=${contractId}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trade${url}`);
  }

  getTradeSkillsLookup(tradeId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/skills/bytradeid/${tradeId}`);
  }

  getTrainingStipendPaymentTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trainingstipendpaymenttypes`);
  }

  getOrganizationEmployersByCampaign(campaignId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeprojectorganization/bycampaign/${campaignId}`);
  }

  getTrainingCentersLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trainingcenters`);
  }

  getTrainingModuleStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trainingmodulestatuses`);
  }

  getTrainingStatusesEnrolledLookup(scope: string, completed: boolean): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trainingstatuses?scope=${scope}&completed=${completed}`);
  }

  getTrainingStatusesLookup(scope: string, passing?: boolean): Observable<LookupModel[]> {
    const url = passing ? `?scope=${scope}&passing=${passing}` : `?scope=${scope}`;
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/trainingstatuses${url}`);
  }

  getModuleGradeLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/results/bytype/LETTERGRADE`);
  }

  getUTStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/utstatuses`);
  }

  getWageAdjustmentsLookup(organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/wageadjustment/${organizationId}`);
  }

  getWageAdjustmentTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/wageadjustmenttype`);
  }

  getWageLevelsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/wagelevels`);
  }

  getStudentWageRateLookup(programStudentId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/wagerate/unassignedoptions`);
  }

  getStudentWageZoneLookup(programId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/wageadjustments/byprogramId/${programId}`);
  }

  getProgramWageRateLookup(programId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/program/${programId}/wagerates`);
  }

  getWorkflowAvailableNextStepsLookup(currentStepId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/availablenextstepsbycurrentstepid/${currentStepId}`);
  }

  getWorkflowDeadlineTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/workflowdeadlinetypes`);
  }

  getWorkflowIntervalTypesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/workflowintervaltypes`);
  }

  getWorkflowsLookup(contractId: number, caseType: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/workflows/contract/${contractId}/${caseType}`);
  }

  getWorkflowStatusesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/workflowstatuses`);
  }

  getWorkflowStepsByCategoryOrganizationIdLookup(workflowCategory: string, organizationId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/availableworkflowstepsbycategory/${workflowCategory}?routeorganizationid=${organizationId}`);
  }

  getWorkflowStepListLookup(workflowId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/${workflowId}/steplist`);
  }

  getWorkflowStepsLookup(workflowId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/workflowsteps/${workflowId}`);
  }

  getWorkflowOutcomeLookup(stepId: number): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/workflow/processstep/${stepId}/outcomeoptions`);
  }

  getWorkflowTypesLookup(workflowCategory: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/workflowtypesbycategory/${workflowCategory}`);
  }

  getWorkLevelsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/worklevels`);
  }

  getTaxFormsLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/taxforms`);
  }

  getOrganizeProjectOrganizersLookup(organizationID?): Observable<LookupModel[]> {
    const url = organizationID ? `?directOrganizationId=${organizationID}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizers/byaccess/ORGANIZEPROJECT${url}`);
  }

  getOrganizeProjectOrganizationTypes(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeproject/organizationtypes`);
  }

  getOrganizeProjectTypesListLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeproject/types`);
  }

  getOrganizeProjectEstimatedLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeproject/estimatedvalues`);
  }

  getOrganizeProjectPhasesLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeproject/phases`);
  }

  getOrganizeProjectIndustriesListLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeproject/industries`);
  }

  getOrganizeProjectFundingLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeproject/funding`);
  }

  getProtectListLookup(orgId: number): Observable<LookupModel[]> {
    const url = orgId ? `?organizationid=${orgId}` : '';
    return this.http.get<LookupModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/organizeprojects${url}`);
  }

}
