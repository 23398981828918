@if (invalidRecord) {
  <app-record-not-found></app-record-not-found>
}
@if (!invalidRecord) {
  <div class="content p-input-filled margin-top-10">
    <div class="p-grid">
      @if (isPage && !isDialog) {
        <div class="col-md-12 col-sm-12 col-xs-12 add-edit-header">
          @if (!invalidRecord && isPage && !isDialog) {
            <div class="inline-back-button" (click)="back()" pTooltip="Back">
              <i class="pi pi-angle-left"></i>
            </div>
          }
          @if (projectReport.ActiveProject !== true) {
            <div class="inactive-flag-hard-corner"></div>
          }
          <div class="profile-details p-pl-5">
            @if (projectReport.ProjectName) {
              @if (isEdit && projectReport) {
                <h2>{{ projectReport.ProjectName }}</h2>
              }
            }
          </div>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12">
        @if (!isPage || isDialog) {
          <p-divider></p-divider>
        }
        @if (showTabs) {
          <p-tabView (onChange)="resetWarning()">
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDetails')) {
              <p-tabPanel header="Project Report Details"
                          [selected]="_tabsPermissionsUtility.checkTabRead(permissions,'tabDetails')">
                <ng-template pTemplate="content">
                  <app-project-report-tab-questions [selectedProjectReport]="ContentID" [isEdit]="isEdit"
                                                    [projectReportData]="projectReport"
                                                    [canTabWrite]="projectReport.CanEdit && canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDetails')"></app-project-report-tab-questions>
                </ng-template>
              </p-tabPanel>
            }
            <!--        <p-tabPanel header="Project Report Results" *ngIf="_tabsPermissionsUtility.checkTabRead(permissions,'tabResults')">-->
            <!--          <ng-template pTemplate="content">-->
            <!--            <app-project-report-tab-results [selectedProjectReport]="ContentID" [isEdit]="isEdit" [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabResults')"></app-project-report-tab-results>-->
            <!--          </ng-template>-->
            <!--        </p-tabPanel>-->
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEmployers')) {
              <p-tabPanel header="Employers">
                <ng-template pTemplate="content">
                  <app-project-report-tab-employer [organizationID]="projectReport.OrganizationID"
                                                   [selectedProjectReport]="ContentID" [isEdit]="isEdit"
                                                   [projectReportData]="projectReport"
                                                   [canTabWrite]="projectReport.CanEdit && canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployers')"></app-project-report-tab-employer>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDocs')) {
              <p-tabPanel header="Docs" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-docs-tab [canAdd]="true" [DBEntity]="'ORGANIZEPROJECT'" [DBEntityID]="ContentID"
                                [canTabWrite]="true" [InterfaceObjectEnum]="InterfaceObjectEnum"></app-docs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabNotes')) {
              <p-tabPanel header="Notes" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-notes-tab [canAdd]="true" [DBEntity]="'ORGANIZEPROJECT'" [DBEntityID]="ContentID"
                                 [canTabWrite]="true" [canEdit]="true"></app-notes-tab>
                </ng-template>
              </p-tabPanel>
            }
          </p-tabView>
        }
      </div>
    </div>
  </div>
}
