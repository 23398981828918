import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ContextService} from '../../../../services/context.service';
import {RosterProfileService} from '../../../../services/roster-profile.service';
import {ResourcesService} from '../../../../services/resources.service';
import {UserAccountService} from '../../../../services/user-account.service';
import {UserAccountPasswordResetEmailModel} from '../../../../models/user-account.model';
import {UserRegistrationService} from '../../../../services/user-registration.service';
import {UserRegistrationInviteUserModel} from '../../../../models/user-registration.model';
import {SignaturePadComponent} from '../../signature-pad/signature-pad.component';
import {DialogService} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-user-account-tab',
  templateUrl: './user-account-tab.component.html',
  styleUrls: ['./user-account-tab.component.scss']
})

export class UserAccountTabComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  emailModel = {} as UserAccountPasswordResetEmailModel;
  registrationModel = {} as UserRegistrationInviteUserModel;
  headerImageName: string;
  @Input() personInfo: any;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() isPage: boolean;
  @Input() PersonID: number;
  @Output() updateImage = new EventEmitter<any>();
  @Output() updateData = new EventEmitter<any>();
  blobToken: string;
  buttonLabel: string;
  signatureImage: string;
  imageType: string;
  fd: FormData;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private messageService: MessageService,
              private contextService: ContextService,
              private rosterProfileService: RosterProfileService,
              private resourcesService: ResourcesService, private confirmationService: ConfirmationService,
              private userAccountService: UserAccountService,
              private userRegistrationService: UserRegistrationService,
              private dialogService: DialogService
  ) {
    this.mainForm = this.formBuilder.group({
      email: new FormControl({value: null, disabled: true}),
    });
  }

  ngOnInit(): void {
    this.blobToken = this.contextService.contextObject.blobToken;
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges() {
    if (this.isEdit) {
      this.getStudentInfo();

    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  getStudentInfo() {
    if (this.personInfo) {
      this.initForm();
      this.emailModel.EmailID = -1;
      this.emailModel.UserName = this.personInfo.PrimaryEmailAddress;
      this.emailModel.TenantID = -1;
      this.emailModel.TenantName = this.contextService.contextObject.tenantName;
      this.registrationModel.Email = this.personInfo.PrimaryEmailAddress;
      this.registrationModel.PersonID = this.PersonID;
      if (this.personInfo.UserID) {
        this.buttonLabel = ' Send Password Reset';
      } else {
        this.buttonLabel = ' Send Registration Invitation';
      }
      this.getProfileSignature();
    }
  }

  getProfileSignature() {
    this.rosterProfileService.getRosterProfileFile(this.personInfo.PersonID, 'signature')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.length > 0) {
          this.signatureImage = res[0].Url + this.blobToken;

        } else {
          this.signatureImage = null;
        }
        console.log(this.signatureImage);
      });
  }

  initForm() {
    if (this.personInfo) {
      this.mainForm.get('email').setValue(this.personInfo.PrimaryEmailAddress);
    }
  }


  deleteImage(type) {
    this.confirmationService.confirm({
      acceptLabel: 'Ok',
      rejectVisible: false,
      message: 'The image will be removed. Are you sure this is what you want to do?',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        if (this.PersonID) {
          this.resourcesService.deleteResource(type, this.PersonID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.getStudentInfo();
                this.personInfo.ThumbnailUrl = null;
                this.updateImage.emit();
                this.getProfileSignature();
              }, error: (e) => {
                if (e?.error?.Message) {
                  this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
                } else {
                  this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
                }
              }
            });
        }
      }
    });
  }

  updateUserData() {
    this.updateData.emit(this.PersonID);
  }

  uploadImage(event, type) {

    this.fd = new FormData();
    let byteString;
    this.imageType = type;
    if (type === 'profilephoto') {
      byteString = atob(event.base64.split(',')[1]);
    } else {
      byteString = atob(event.split(',')[1]);
      this.headerImageName = 'signature';
    }

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob
    const bb = new Blob([ab]);
    if (event) {
      this.fd.append('file', bb, this.headerImageName);
      console.log(this.fd);
    }
    // } else {
    //   this.fd = event;
    // }
    if (event.file?.size < 4e+6 || type === 'signature') {
      this.rosterProfileService.updateRosterProfileFile(this.PersonID, type, this.fd)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Image was uploaded successfully'
            });
            if (type === 'profilephoto') {
              this.getStudentInfo();
              this.personInfo.ThumbnailUrl = res[1]?.Url + this.blobToken;
              this.updateImage.emit(res[1]?.Url + this.blobToken);
            } else {
              this.getProfileSignature();
            }
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            console.debug(e);
          }
        });
    } else {
      this.messageService.add({
        severity: 'error', summary: 'Error',
        detail: 'File size is too large'
      });
    }
  }


  addSignature() {
    const ref = this.dialogService.open(SignaturePadComponent, {
      data: {},
      header: 'Add/Edit Signature',
      width: this.contextService.contextObject.isMobile ? '100%' : '600px',
    });
    ref.onClose.subscribe(res => {
      if (res !== null) {
        this.uploadImage(res, 'signature');
      }
    });
  }

  sendEmail() {
    if (this.personInfo.UserID) {
      this.userAccountService.generatePasswordResetEmail(this.emailModel)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (msg: string) => {
            if (msg) {
              this.messageService.add({severity: 'success', summary: 'Success', detail: msg, life: 4500});
            } else {
              this.messageService.add({severity: 'success', summary: 'Success', detail: 'Email was sent'});
            }
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 4000});
            } else {
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
            }
          }
        });
    } else {
      this.userRegistrationService.inviteUser(this.registrationModel)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Email was sent'
            });
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 4000});
            } else {
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
            }
          }
        });
    }
  }

}
