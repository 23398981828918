<app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                [parentObjectEnum]="parentObjectEnum"></app-learn-more>
<!--<span class="fa fa-spinner list-spinner" *ngIf="loading"></span>-->
@if (STATEMENTBILLING) {
  <div class="p-grid p-fluid margin-top-5 margin-bottom-5">
    <div class="col-md-3 col-sm-12 col-xs-12">
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="setOrganizationData($event)">
        </app-select>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      @if (!TypeFilter) {
        <div class="global-filter">
          <p-radioButton [value]="0" [(ngModel)]="typeAll" label="Invoices" class="p-mr-3 margin-top-5"
                         (onClick)=" selectedTable = 'Invoices'; selectRadio(); pdfType='PDF Invoices'" tabindex="0">
          </p-radioButton>
          <p-radioButton [value]="1" [(ngModel)]="typeAll" label="Payments" class="p-mr-3 margin-top-5"
                         (onClick)="selectedTable = 'Payments'; selectRadio();  pdfType='PDF Receipts'" tabindex="0">
          </p-radioButton>
          <p-radioButton [value]="2" [(ngModel)]="typeAll" label="Transactions" class="p-mr-3 margin-top-5"
                         (onClick)="selectedTable = 'Transactions'; selectRadio(); " tabindex="0">
          </p-radioButton>
        </div>
      }
    </div>
  </div>
}
@if (readyToLoadTable && !showTxnTable) {
  <p-table
    [rows]="pageRowCountUtility.pageRowCount()"
    dataKey="ID"
    [columns]="cols"
    [value]="accountTransactionsList"
    [lazy]="true"
    (onLazyLoad)="loadTable($event)"
    [paginator]="true"
    [showCurrentPageReport]="true"
    [metaKeySelection]="false"
    [totalRecords]="dataTotal"
    [rowsPerPageOptions]="[25,50,100,250,500]"
    [scrollable]="true"
    rowExpandMode="single"
    paginatorDropdownAppendTo="body"
    selectionMode="single"
    scrollHeight="400px"
    [loading]="loading"
    styleClass="p-datatable-responsive-demo p-datatable-striped table-border p-mt-5"
    currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
  >
    <ng-template pTemplate="caption">
      <div class="p-grid p-fluid">
        <div class="col-md-4">
          <div class="global-filter">
            <input pInputText type="text" [(ngModel)]="activeGlobalFilter"
                   [placeholder]="(showTxnTable===true)?'Description':'Status Details'" (keyup.enter)="filterItems()"
                   tabindex="0"/>
            <div class="grid-filter-buttons">
              <button pButton class="p-button-download export-menu" icon="pi pi-search"
                      pTooltip="Search" tooltipPosition="bottom" (click)="filterItems()">
              </button>
              <button pButton class="p-button-download export-menu p-ml-1" icon="pi pi-times"
                      [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clear()">
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-8 grid-container-buttons-right">
          <div class="action-buttons">
            <button pButton (click)="refresh()" icon="pi pi-refresh" pTooltip="Refresh"
                    tooltipPosition="bottom"></button>
            @if (!isExporting) {
              <button pButton class="p-button-download export-menu export-excel" icon="fa fa-file-excel"
                      pTooltip="Export" tooltipPosition="bottom" (click)="export()">
              </button>
            } @else {
              <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                      icon="pi pi-spin pi-spinner save-spinner">
              </button>
            }
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th [className]="'expand-column'"></th>
        @for (col of columns; track col) {
          <th pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" [pSortableColumn]="col.field">
            <div class="header-text">
              {{ col.header }}
            </div>
            <div class="header-actions">
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </div>
          </th>
        }
        <th>
          {{ pdfType }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
      <tr>
        <td [className]="'expand-column'" [pSelectableRow]="rowData">
          <button type="button" pButton (click)="getPaymentInvoiceDetail(rowData.ID)" [pRowToggler]="rowData"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
        </td>
        @for (col of columns; track col) {
          <td [ngStyle]="{'width': col.width}">
            @if (col.visible && col.displayType === 'date') {
              {{ rowData[col.field] | date: 'shortDate' }}
            }
            @if (col.visible && col.displayType === 'text') {
              {{ rowData[col.field] }}
            }
            @if (col.visible && col.displayType === 'currency') {
              @if (rowData[col.field] < 0) {
                <span class="negative"> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
              }
              @if (rowData[col.field] === 0) {
                <span> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
              }
              @if (rowData[col.field] > 0) {
                <span class="positive">{{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
              }
            }
          </td>
        }
        <td>
          @if (selectedTable === 'Invoices') {
            @if (rowData['ResourceReferenceID']) {
              <span class="pi pi-download clickable p-ml-2" (click)="openResource(rowData['ResourceReferenceID'])"
                    pTooltip="Download PDF" tooltipPosition="bottom"></span>
            }
          }
          @if (selectedTable === 'Payments') {
            @if (rowData['ResourceReferenceID']) {
              <span class="pi pi-download clickable p-ml-2" (click)="openResource(rowData['ResourceReferenceID'])"
                    pTooltip="Original Receipt" tooltipPosition="bottom"></span>
            }
            @if (rowData['ResourceReferenceID']) {
              <span class="pi pi-download clickable p-ml-2" (click)="getAdjustReceipt(rowData.ID, rowData.Description)"
                    pTooltip="Adjusted Receipt" tooltipPosition="bottom"></span>
            }
          }
          @if (canEdit && canTabWrite) {
            <span class="pi pi-pencil clickable p-ml-2" (click)="openAccountAdjustmentTool(rowData)"
                  pTooltip="Adjust Account" tooltipPosition="bottom"></span>
          }
          @if (rowData['CanEdit']) {
            <span class="pi pi-refresh clickable p-ml-2" (click)="refreshLatestInvoicePayment(rowData)"
                  pTooltip="Refresh" tooltipPosition="bottom"></span>
          }
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData>
      @if (selectedTable === 'Invoices') {
        <tr>
          <td [colSpan]="cols.length+ 1">
            @if (loadingExpand) {
              <span class="fa fa-spinner list-spinner"></span>
            }
            @if (invoiceInvoiceData) {
              <p-table [value]="invoiceInvoiceData.Data"
                       styleClass="p-datatable-responsive-demo p-datatable-striped table-border"
                       [tableStyle]="{width: '100%'}" [loading]="loadingExpand">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="TransactionDate">
                      <div class="header-text">Transaction Date</div>
                      <div class="header-actions">
                        <p-sortIcon field="TransactionDate"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="Amount">
                      <div class="header-text">Amount</div>
                      <div class="header-actions">
                        <p-sortIcon field="Amount"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="Description">
                      <div class="header-text">Description</div>
                      <div class="header-actions">
                        <p-sortIcon field="Description"></p-sortIcon>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                  <tr>
                    <td>{{ data.TransactionDate | date: 'shortDate' }}</td>
                    <td>{{ data.Amount | currency }}</td>
                    <td>{{ data.Description }}</td>
                  </tr>
                </ng-template>
              </p-table>
            }
          </td>
        </tr>
      }
      @if (selectedTable === 'Payments') {
        <tr>
          <td [colSpan]="cols.length+ 1">
            @if (loadingExpand) {
              <span class="fa fa-spinner list-spinner"></span>
            }
            @if (invoiceReceiptData) {
              <p-table [value]="invoiceReceiptData.Data"
                       styleClass="p-datatable-responsive-demo p-datatable-striped table-border full-width"
                       [tableStyle]="{width: '100%'}" [loading]="loadingExpand">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="PaymentDate">Transaction Date</th>
                    <th pSortableColumn="Amount">Amount</th>
                    <th pSortableColumn="Description">Description</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                  <tr>
                    <td>{{ data.PaymentDate | date: 'shortDate' }}</td>
                    <td>{{ data.Amount | currency }}</td>
                    <td>{{ data.Description }}</td>
                  </tr>
                </ng-template>
              </p-table>
            }
          </td>
        </tr>
      }
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="cols.length + 3" style="width: 100%;">No account transactions found.</td>
      </tr>
    </ng-template>
  </p-table>
}
@if (readyToLoadTable && showTxnTable) {
  <p-table
    [rows]="pageRowCountUtility.pageRowCount()"
    [columns]="colsTxn"
    [value]="accountTxnTransactionsList"
    [lazy]="true"
    (onLazyLoad)="loadTxnTable($event)"
    [paginator]="true"
    [showCurrentPageReport]="true"
    [metaKeySelection]="false"
    [totalRecords]="dataTotal"
    [rowsPerPageOptions]="[25,50,100,250,500]"
    [scrollable]="true"
    selectionMode="single"
    scrollHeight="400px"
    [loading]="loading"
    styleClass="p-datatable-responsive-demo p-datatable-striped table-border p-mt-5"
    currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
  >
    <ng-template pTemplate="caption">
      <div class="p-grid p-fluid">
        <div class="col-md-4">
          <div class="global-filter">
            <input pInputText type="text" [(ngModel)]="activeGlobalFilter"
                   [placeholder]="(showTxnTable===true)?'Description':'Status Details'" (keyup.enter)="filterItems()"/>
            <div class="grid-filter-buttons">
              <button pButton class="p-button-download export-menu" icon="pi pi-search"
                      pTooltip="Search" tooltipPosition="bottom" (click)="filterItems()">
              </button>
              <button pButton class="p-button-download export-menu p-ml-1" icon="pi pi-times"
                      [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clear()">
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-8 grid-container-buttons-right">
          <div class="action-buttons">
            <button pButton (click)="refresh()" icon="pi pi-refresh" pTooltip="Refresh"
                    tooltipPosition="bottom"></button>
            @if (!isExporting) {
              <button pButton class="p-button-download export-menu export-excel" icon="fa fa-file-excel"
                      pTooltip="Export" tooltipPosition="bottom" (click)="export()">
              </button>
            } @else {
              <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                      icon="pi pi-spin pi-spinner save-spinner">
              </button>
            }
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        @for (col of columns; track col) {
          <th pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" [pSortableColumn]="col.field">
            <div class="header-text">
              {{ col.header }}
            </div>
            <div class="header-actions">
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </div>
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        @for (col of columns; track col) {
          <td [ngStyle]="{'width': col.width}">
            @if (col.visible && col.displayType === 'text' && selectedTable !== 'Payments') {
              {{ rowData[col.field] }}
            }
            @if (col.visible && col.displayType === 'date') {
              {{ rowData[col.field] | date: 'shortDate' }}
            }
            @if (col.visible && col.displayType === 'currency') {
              @if (rowData[col.field] < 0) {
                <span class="positive"> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
              }
              @if (rowData[col.field] === 0) {
                <span> {{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
              }
              @if (rowData[col.field] > 0) {
                <span class="negative">{{ rowData[col.field] | currency:'USD':'symbol':'1.2-2' }}</span>
              }
            }
            @if (col.visible && col.displayType === 'text' && selectedTable === 'Payments') {
              {{ rowData[col.field] }}
            }
            @if (col.visible && col.displayType === 'pdfInvoice') {
              @if (rowData['Type'] === 'Invoice') {
                @if (rowData['ResourceReferenceID']) {
                  <span class="pi pi-download clickable p-ml-2" (click)="openResource(rowData['ResourceReferenceID'])"
                        pTooltip="Download PDF" tooltipPosition="bottom"></span>
                }
                @if (!rowData['ResourceReferenceID']) {
                  <span>Pending</span>
                }
              }
            }
            @if (col.visible && col.displayType === 'pdfPayment') {
              @if (rowData['Type'] === 'Payment') {
                @if (rowData['ResourceReferenceID']) {
                  <span class="pi pi-download clickable p-ml-2" (click)="openResource(rowData['ResourceReferenceID'])"
                        pTooltip="Download PDF" tooltipPosition="bottom"></span>
                }
              }
            }
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="cols.length + 1" style="width: 100%;">No account transactions found.</td>
      </tr>
    </ng-template>
  </p-table>
}
