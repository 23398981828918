import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {OrganizationChangeLogsService} from '../../../services/organization-change-logs.service';
import {OrganizationChangeLogModel, OrganizationChangeLogsGridModel} from '../../../models/organization-change-logs.model';
import {RosterProfileChangeLogsService} from '../../../services/roster-profile-change-logs.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as FileSaver from 'file-saver';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-organizations-tab-roster-change-log',
  templateUrl: './organizations-tab-roster-change-log.component.html',
  styleUrls: ['./organizations-tab-roster-change-log.component.scss']
})
export class OrganizationsTabRosterChangeLogComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  changeLogs: OrganizationChangeLogsGridModel;
  changeLogsList: OrganizationChangeLogModel[];
  selChangeLog: OrganizationChangeLogModel;
  currentFilter = new PrimeTableFilterModel();
  dataLoaded: boolean;
  cols: any[];
  exportData: any;
  startDate: string;
  endDate: string;
  showGrid: boolean = false;
  dataTotal: number = 0;
  @Input() isEdit: boolean;
  @Input() ID: number;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  currentYearMonthDay = this.getLocalISOTime();
  currentDay = this.currentYearMonthDay.substring(this.currentYearMonthDay.length, this.currentYearMonthDay.length - 3);
  currentYearMonthFirstDay = this.currentYearMonthDay.replace(this.currentDay, '-01');

  private ngUnsubscribe = new Subject();

  constructor(public dialogService: DialogService,
              private organizationChangeLogsService: OrganizationChangeLogsService,
              private rosterProfileChangeLogsService: RosterProfileChangeLogsService,
              private formBuilder: FormBuilder,
              public pageRowCountUtility: PageRowCountUtility, private messageService: MessageService) {

    this.mainForm = this.formBuilder.group({
      fcnStartDate: new FormControl(null, Validators.required),
      fcnEndDate: new FormControl(null, Validators.required),
      synchronize: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.cols = [
      {
        field: 'AuditOperation',
        header: 'Change Type',
        fullHeader: 'Change Type',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'UpdateDate_D',
        header: 'Change Date',
        fullHeader: 'Change Date',
        columnType: 'date',
        matchMode: 'equals',
        displayType: 'date',
        visible: true
      },
      {
        field: 'FirstName',
        header: 'First Name',
        fullHeader: 'First Name',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'LastName',
        header: 'Last Name',
        fullHeader: 'Last Name',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'PrimaryAddress1',
        header: 'Street Address',
        fullHeader: 'Street Address',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'PrimaryAddress2',
        header: 'Address Line 2',
        fullHeader: 'Address Line 2',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'PrimaryCity',
        header: 'City/Town',
        fullHeader: 'City/Town',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'PrimaryZip',
        header: 'Postal Code',
        fullHeader: 'Postal Code',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'PrimaryPhoneNumber',
        header: 'Home Phone #',
        fullHeader: 'Home Phone #',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'SecondaryPhoneNumber',
        header: 'Mobile Phone #',
        fullHeader: 'Mobile Phone #',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'PrimaryEmailAddress',
        header: 'Primary E-mail Address',
        fullHeader: 'Primary E-mail Address',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'SecondaryEmailAddress',
        header: 'Secondary E-mail Address',
        fullHeader: 'Secondary E-mail Address',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      }
    ];

    this.exportData = [{FieldName: 'LastName', ColumnHeader: 'Last Name'}, {
      FieldName: 'FirstName',
      ColumnHeader: 'First Name'
    },
      {FieldName: 'PrimaryAddress1', ColumnHeader: 'Street Address'}, {
        FieldName: 'PrimaryAddress2',
        ColumnHeader: 'Address Line 2'
      },
      {FieldName: 'PrimaryCity', ColumnHeader: 'City/Town'}, {
        FieldName: 'PrimaryStateProvince',
        ColumnHeader: 'State/Province'
      },
      {FieldName: 'PrimaryZip', ColumnHeader: 'Postal Code'}, {
        FieldName: 'PrimaryPhoneNumber',
        ColumnHeader: 'Home Phone #'
      },
      {FieldName: 'SecondaryPhoneNumber', ColumnHeader: 'Mobile Phone #'}, {
        FieldName: 'PrimaryEmailAddress',
        ColumnHeader: 'Primary E-mail Address'
      },
      {FieldName: 'SecondaryEmailAddress', ColumnHeader: 'Secondary E-mail Address'}];

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getLocalISOTime();
    this.getChangeLogs();
  }

  getChangeLogs(): void {
    this.mainForm.get('fcnStartDate').setValue(new Date(new Date(this.currentYearMonthFirstDay).setDate(new Date(this.currentYearMonthFirstDay).getDate() + 1)));
    this.mainForm.get('fcnEndDate').setValue(new Date(new Date(this.currentYearMonthDay).setDate(new Date(this.currentYearMonthDay).getDate() + 1)));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getLocalISOTime() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10);
  }

  getSelectedLocalISOTime(date) {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(date - tzoffset)).toISOString().slice(0, 10);
  }

  getList(): void {
    const startDate = new Date(this.mainForm.get('fcnStartDate').value).toISOString().slice(0, 10);
    const endDate = new Date(this.mainForm.get('fcnEndDate').value).toISOString().slice(0, 10);
    this.dataLoaded = false;
    this.startDate = startDate.toString();
    this.endDate = endDate.toString();
    this.organizationChangeLogsService.getOrganizationChangeLogsGridAll(this.ID, startDate, endDate, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.changeLogs = res;
          this.changeLogsList = res.Data;
          this.dataTotal = res.Total;
          this.showGrid = true;
          this.dataLoaded = true;
        }, error: () => {
          this.showGrid = true;
          this.dataLoaded = true;
        }
      });
    // this.getTotal(startDate, endDate);
  }

  // getTotal(startDate, endDate): void {
  //   this.organizationChangeLogsService.getOrganizationChangeLogsGridTotal(this.ID, startDate, endDate, this.currentFilter)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe({next: (res) => {
  //       if (res) {
  //         this.dataTotal = res.Total;
  //       }
  //     }});
  // }

  loadTable(event?) {
    if (event) {
      this.currentFilter = event;
    } else {
      this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
      this.currentFilter.sortOrder = 1;
      this.currentFilter.first = 0;
      this.currentFilter.filters = {};
    }
    this.updateList();
  }

  getDatesOfWeek(): void {
    const d = new Date();
    const day = d.getDay();
    const startDate = d.getDate() - day + (day === 0 ? -6 : 0);
    const endDate = d.getDate() - day + (day === 0 ? -6 : 6);
    this.mainForm.get('fcnStartDate').setValue(new Date(d.setDate(startDate)));
    this.mainForm.get('fcnEndDate').setValue(new Date(d.setDate(endDate)));
    if (!this.showGrid) {
      this.showGrid = true;
    } else {
      //this.getList(new Date(d.setDate(startDate)).toISOString().slice(0,10), new Date(d.setDate(endDate)).toISOString().slice(0,10));
      this.getList();
    }
  }

  getDatesDuringMonth(): void {
    this.mainForm.get('fcnStartDate').setValue(new Date(new Date(this.currentYearMonthFirstDay).setDate(new Date(this.currentYearMonthFirstDay).getDate() + 1)));
    this.mainForm.get('fcnEndDate').setValue(new Date(new Date(this.currentYearMonthDay).setDate(new Date(this.currentYearMonthDay).getDate() + 1)));
    if (!this.showGrid) {
      this.showGrid = true;
    } else {
      //this.getList(this.currentYearMonthFirstDay, this.currentYearMonthDay);
      this.getList();
    }
  }

  getDatesDuringYear(): void {
    this.mainForm.get('fcnStartDate').setValue(new Date(new Date(new Date().getFullYear() + '-01-01').setDate(new Date(new Date().getFullYear() + '-01-01').getDate() + 1)));
    this.mainForm.get('fcnEndDate').setValue(new Date(new Date(new Date().getFullYear() + '-12-31').setDate(new Date(new Date().getFullYear() + '-12-31').getDate() + 1)));
    // const startDate = new Date(this.mainForm.get('fcnStartDate').value).toISOString().slice(0,10);
    // const endDate = new Date(this.mainForm.get('fcnEndDate').value).toISOString().slice(0,10);
    if (!this.showGrid) {
      this.showGrid = true;
    } else {
      //this.getList(startDate, endDate);
      this.getList();
    }
  }

  runList(): void {
    // const startDate = new Date(this.mainForm.get('fcnStartDate').value).toISOString().slice(0,10);
    // const endDate = new Date(this.mainForm.get('fcnEndDate').value).toISOString().slice(0,10);
    if (!this.showGrid) {
      this.showGrid = true;
    } else {
      this.getList();
    }
  }

  synchronize(): void {
    this.syncChangeLogs();
  }

  syncChangeLogs(): void {
    this.rosterProfileChangeLogsService.syncChangeLog('organization', this.ID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: res
          });
        }
      });
  }

  updateList(): void {
    // const startDate = new Date(this.mainForm.get('fcnStartDate').value).toISOString().slice(0,10);
    // const endDate = new Date(this.mainForm.get('fcnEndDate').value).toISOString().slice(0,10);
    this.getList();
  }

  refresh(): void {
    this.updateList();
  }

  export(): void {
    const startDate = this.getSelectedLocalISOTime(this.mainForm.get('fcnStartDate').value);
    const endDate = this.getSelectedLocalISOTime(this.mainForm.get('fcnEndDate').value);
    const obj = {
      tableFilterModel:
        {
          first: 0,
          sortField: 'FirstName',
          sortOrder: 1,
          filters: {
            OrganizationName: [
              {
                matchMode: 'contains',
                operator: 'and',
                value: null
              }
            ]
          },
          globalFilter: null
        },
      exportColumnModelList:
      // [
      //   {
      //     FieldName: 'PersonID',
      //     ColumnHeader: 'Person ID'
      //   },
      //   {
      //     FieldName: 'FirstName',
      //     ColumnHeader: 'First Name'
      //   },
      //   {
      //     FieldName: 'LastName',
      //     ColumnHeader: 'Last Name'
      //   }
      //
      // ]
        [{FieldName: 'LastName', ColumnHeader: 'Last Name'}, {FieldName: 'FirstName', ColumnHeader: 'First Name'},
          {FieldName: 'PrimaryAddress1', ColumnHeader: 'Street Address'}, {
          FieldName: 'PrimaryAddress2',
          ColumnHeader: 'Address Line 2'
        },
          {FieldName: 'PrimaryCity', ColumnHeader: 'City/Town'}, {
          FieldName: 'PrimaryStateProvince',
          ColumnHeader: 'State/Province'
        },
          {FieldName: 'PrimaryZip', ColumnHeader: 'Postal Code'}, {
          FieldName: 'PrimaryPhoneNumber',
          ColumnHeader: 'Home Phone #'
        },
          {FieldName: 'SecondaryPhoneNumber', ColumnHeader: 'Mobile Phone #'}, {
          FieldName: 'PrimaryEmailAddress',
          ColumnHeader: 'Primary E-mail Address'
        },
          {FieldName: 'SecondaryEmailAddress', ColumnHeader: 'Secondary E-mail Address'}]
    };
    //this.organizationChangeLogsService.getOrganizationChangeLogsExport(this.ID, startDate, endDate, this.exportData)
    this.organizationChangeLogsService.getOrganizationChangeLogsExport(this.ID, startDate, endDate, obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.downloadFile(res, 'change-log.xlsx', 'text/plain;charset=utf-8');
          this.dataLoaded = true;
        }, error: (e) => {
          this.dataLoaded = true;
          console.debug(e);
        }
      });
  }

  downloadFile(buffer, fileName, fileType) {
    import('file-saver').then(() => {
      const data: Blob = new Blob([buffer], {
        type: fileType
      });
      FileSaver.saveAs(data, fileName);
    });
  }
}
