import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RosterService} from '../../../services/roster.service';
import {StudentProfileService} from '../../../services/student-profile.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {MessageService} from 'primeng/api';
import {ContextService} from '../../../services/context.service';
import {NotZeroValidation} from '../../../shared/utilities/form.utility';
import {ProspectProfileService} from '../../../services/prospect-profile.service';
import {TradeSkillsService} from '../../../services/trade-skills.service';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {ProfileModel} from '../../../models/profile.model';

@Component({
  selector: 'app-prospects-tab-details',
  templateUrl: './prospects-tab-details.component.html',
  styleUrls: ['./prospects-tab-details.component.scss']
})

export class ProspectsTabDetailsComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  membershipInfo: ProfileModel;
  inputObjType: GenericSelectModel;
  inputObjStatus: GenericSelectModel;
  inputObjOrganization: GenericSelectModel;
  inputObjTrades: GenericSelectModel;
  inputObjExperience: GenericSelectModel;
  inputObjIssues: GenericSelectModel;
  inputObjEmployerUnion: GenericSelectModel;
  setTradeIds: number[] = [];
  setExperience: number;
  setIssuesIds: [] = [];
  setEmployerUnion: number;
  isEnabled: boolean = false;
  setTypeId: number;
  setStatusId: number;
  setOrganizationId: number;
  membershipType: string;
  membershipStatus: string;
  isSaving: boolean = false;
  disabled: boolean = false;


  @Input() isPage: boolean;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() Archived: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() PersonID: number;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private rosterService: RosterService, private tradeSkillService: TradeSkillsService,
              private studentProfileService: StudentProfileService, private ref: DynamicDialogRef,
              private config: DynamicDialogConfig, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private contextService: ContextService,
              private prospectProfileService: ProspectProfileService, private tenantConfigDataService: TenantConfigDataService) {
    this.mainForm = this.formBuilder.group({
      statusId: new FormControl(null, [Validators.required, NotZeroValidation()]),
      organization: new FormControl(null, [Validators.required, NotZeroValidation()]),
      typeId: new FormControl(null, [Validators.required, NotZeroValidation()]),
      experience: new FormControl(null,),
      issues: new FormControl(null,),
      trades: new FormControl(null),
      employer: new FormControl(null,),
      organizeWorkPlace: new FormControl(null),
      webReferral: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges() {
    if (this.PersonID) {
      this.getRosterProfile();
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  getRosterProfile() {
    this.prospectProfileService.getProspectProfile(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.membershipInfo = res;
          this.initForm();
        }
      });
  }


  initForm() {
    this.setTypeId = this.membershipInfo.TypeID;
    this.mainForm.get('typeId').setValue(this.membershipInfo.TypeID);
    this.setStatusId = this.membershipInfo.StatusID;
    this.mainForm.get('statusId').setValue(this.membershipInfo.StatusID);
    this.setOrganizationId = this.membershipInfo.OrganizationID;
    this.mainForm.get('organization').setValue(this.membershipInfo.OrganizationID);
    this.setExperience = this.membershipInfo.ExperienceID;
    this.mainForm.get('organizeWorkPlace').setValue(this.membershipInfo.OrganizeMyWorkspace);
    this.mainForm.get('webReferral').setValue(this.membershipInfo.WebReferral);
    this.membershipInfo.Trades.forEach(trade => {
      this.setTradeIds.push(trade.ID);
    });
    this.initSelects(!this.canTabWrite);
  }

  initSelects(disable) {
    this.initType(disable);
    this.initStatus(disable);
    this.initOrganization(disable);
    this.initExperience(disable);
    this.initIssues(disable);
    this.initTrades(disable);
  }

  initType(disable: boolean): void {
    this.inputObjType = {
      labelText: 'Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setTypeId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getPersonProfileTypesLookupData('organize').then((lookupData) => {
      this.inputObjType.data = lookupData;
      this.inputObjType = Object.assign({}, this.inputObjType);
    });
  }

  getTypeData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setTypeId = event[0].ID;
    } else {
      this.setTypeId = 0;
    }
    this.mainForm.get('typeId').setValue(this.setTypeId);
    this.mainForm.markAsDirty();
  }

  initStatus(disable: boolean): void {
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStatusId,
      disabled: disable,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getPersonProfileStatusesLookupData('organize').then((lookupData) => {
      this.inputObjStatus.data = lookupData;
      this.inputObjStatus = Object.assign({}, this.inputObjStatus);
    });
  }

  getStatusData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setStatusId = event[0].ID;
    } else {
      this.setStatusId = 0;
    }
    this.mainForm.get('statusId').setValue(this.setStatusId);
    this.mainForm.markAsDirty();
  }

  initOrganization(disable: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setOrganizationId,
      disabled: disable,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData('PROSPECTS', false, 'organize').then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }


  getOrganizationData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setOrganizationId = event[0].ID;
      this.disabled = false;
    } else {
      this.setOrganizationId = 0;
      this.disabled = true;
    }
    this.mainForm.get('organization').setValue(this.setOrganizationId);
    this.mainForm.markAsDirty();
  }

  initExperience(disable: boolean): void {
    this.inputObjExperience = {
      labelText: 'Experience',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setExperience ? this.setExperience : null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getProspectExperienceLookupData().then((lookupData) => {
      this.inputObjExperience.data = lookupData;
      this.inputObjExperience = Object.assign({}, this.inputObjExperience);
    });
  }

  getExperienceData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setExperience = event[0].ID;
    } else {
      this.setExperience = 0;
    }
    this.mainForm.get('experience').setValue(this.setExperience);
    this.mainForm.markAsDirty();
  }

  initIssues(disable: boolean): void {
    this.inputObjIssues = {
      labelText: 'Issues',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setIssuesIds,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getProspectIssuesLookupData().then((lookupData) => {
      this.inputObjIssues.data = lookupData;
      this.inputObjIssues = Object.assign({}, this.inputObjIssues);
    });
  }

  getIssuesData(event:any) {
    if (event) {
      this.setIssuesIds = event;
    } else {
      this.setIssuesIds = [];
    }
    this.mainForm.get('issues').setValue(this.setIssuesIds);
    this.mainForm.markAsDirty();
  }

  initTrades(disable: boolean): void {
    console.log(this.setTradeIds);
    this.inputObjTrades = {
      labelText: 'Trades',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setTradeIds,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getTradesLookupData().then((lookupData) => {
      this.inputObjTrades.data = lookupData;
      this.inputObjTrades = Object.assign({}, this.inputObjTrades);
    });
  }

  getTradesData(event:any) {
    if (event) {
      this.setTradeIds = event;
    } else {
      this.setTradeIds = [];
    }
    this.mainForm.get('trades').setValue(this.setTradeIds);
    this.mainForm.markAsDirty();
  }


  processData() {
    this.isSaving = true;
    this.membershipInfo.TypeID = this.setTypeId;
    this.membershipInfo.StatusID = this.setStatusId;
    this.membershipInfo.OrganizationID = this.setOrganizationId;
    this.membershipInfo.ExperienceID = this.setExperience !== 0 ? this.setExperience : null;
    this.membershipInfo.Trades = [];
    this.membershipInfo.OrganizeMyWorkspace = this.mainForm.get('organizeWorkPlace').value;
    this.membershipInfo.WebReferral = this.mainForm.get('webReferral').value;
    this.setTradeIds.forEach(trade => {
      this.membershipInfo.Trades.push({ID: trade, Description: null});
    });
    this.saveForm();
  }

  saveForm() {
    this.prospectProfileService.updateProspectProfile(this.membershipInfo.PersonID, this.membershipInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.ref.destroy();
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Your details have been successfully updated'
          });
          this.isSaving = false;
        }, error: (e) => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          this.isSaving = false;
          console.debug(e);
        }
      });
  }

  cancel(): void {
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }
}
