import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormArray, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ClassesModulesService} from '../../../services/classes.service';
import {ClassModel} from '../../../models/classes.model';
import {TrainingModuleTemplatesService} from '../../../services/training-modules.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NotZeroValidation, transformDateTimeToDateOnly, transformDateTimeToTimeOnly} from '../../../shared/utilities/form.utility';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TrainingModuleTemplateModel} from '../../../models/training-modules.model';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';
import {ContextService} from '../../../services/context.service';

class dayCourseModel {
  inputObjCourse: GenericSelectModel[];
  setCourseId: number[];
}

@Component({
  selector: 'app-class-tab-details',
  templateUrl: './class-tab-details.component.html',
  styleUrls: ['./class-tab-details.component.scss']
})

export class ClassTabDetailsComponent implements OnInit, OnChanges {
  mainForm: FormGroup;
  loading: boolean = false;
  InterfaceObjectEnum: string = 'CLASSES';
  class = {} as ClassModel;
  inputObjOrganization: GenericSelectModel;
  inputObjModuleTemplate: GenericSelectModel;
  inputObjInstructor: GenericSelectModel;
  inputObjCourse: GenericSelectModel;
  inputObjStatus: GenericSelectModel;
  inputObjTimezone: GenericSelectModel;
  inputObjLanguage: GenericSelectModel;
  inputObjType: GenericSelectModel;
  inputObjProgram: GenericSelectModel;
  setModuleID: number;
  setType: string = 'Class';
  setOrganizationId: number;
  setCourseId: number;
  setStatusId: number;
  startDate: Date;
  startTime: Date;
  endDate: Date;
  endTime: Date;
  setTimezoneId: number;
  setLanguageId: number;
  isDirty: boolean;
  isSaving: boolean = false;
  currentClass: ClassModel;
  existingMessage: boolean = false;
  moduleTemplate: TrainingModuleTemplateModel;
  total: number = 0;
  disabledStartDate: Date;
  formLoaded: boolean;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() ClassID: number;
  @Output() closeAddEdit = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private classesService: ClassesModulesService, private messageService: MessageService,
              private confirmationService: ConfirmationService, private trainingModulesService: TrainingModuleTemplatesService,
              private dialogUtility: DialogUtility,
              private router: Router, private contextService: ContextService) {
    this.mainForm = this.formBuilder.group({
      organization: new FormControl(null, [Validators.required, NotZeroValidation()]),
      course: new FormControl(null, [Validators.required, NotZeroValidation()]),
      type: new FormControl(null, [Validators.required, NotZeroValidation()]),
      moduleTemplate: new FormControl(null),
      program: new FormControl(null),
      isComplex: new FormControl('false'),
      moduleTitle: new FormControl(null),
      classidentifier: new FormControl(null),
      status: new FormControl(null, [Validators.required, NotZeroValidation()]),
      seats: new FormControl(0, Validators.required),
      startDate: new FormControl(null, Validators.required),
      startTime: new FormControl(null),
      endDate: new FormControl(null, Validators.required),
      endTime: new FormControl(null),
      timezone: new FormControl(null),
      language: new FormControl(null),
      signupNotes: new FormControl(null, Validators.maxLength(4000)),
      days: this.formBuilder.array([]),
      location: new FormControl(null, Validators.maxLength(150))
    });
  }

  get days(): UntypedFormArray {
    return this.mainForm.get('days') as UntypedFormArray;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isEdit && this.isEdit) {
      if (this.ClassID) {
        this.loading = true;
        this.getClass();
      }
    } else if (changes.isEdit && !this.isEdit) {
      this.initType(false);
      this.initSelects();
      this.mainForm.get('type').setValue(this.setType);
    }
    if (this.canTabWrite) {
      this.mainForm.enable();
    } else {
      this.mainForm.disable();
    }
  }

  getClass() {
    this.classesService.getClass(this.ClassID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.currentClass = res;
          this.class = res;
          this.initForm();
          this.loading = false;
          this.initSelects();
        }, error: (e) => {
          this.loading = false;
          console.debug(e);
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: `'${this.class.CourseDescription}' has been modified. Please save, or all changes will be lost.`,
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  checkFormChange() {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: (val) => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage && (val.isComplex === 'true' || (val.isComplex === 'false' && val.startDate !== null && val.endDate !== null))) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      // this.initForm();
      this.days.clear();
      this.getClass();
      if (this.setType !== 'Class') {
        this.getModuleDetails();
      }
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  initForm() {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.setOrganizationId = this.currentClass.OrganizationID;
    this.mainForm.get('organization').setValue(this.setOrganizationId);
    this.setCourseId = this.currentClass.CourseID;
    this.mainForm.get('course').setValue(this.setCourseId);
    this.mainForm.get('classidentifier').setValue(this.currentClass.ClassIdentifier);
    this.setStatusId = this.currentClass.ClassStatusID;
    this.mainForm.get('status').setValue(this.setStatusId);
    this.mainForm.get('location').setValue(this.currentClass.Location);
    this.mainForm.get('seats').setValue(this.currentClass.MaxSeats_N);
    //this.mainForm.get('isComplex').disable();
    if (this.currentClass.ComplexSchedule && !this.currentClass.IsModule) {
      this.initInstructors(false);
      this.mainForm.get('isComplex').setValue('true');
      this.mainForm.get('startDate').clearValidators();
      this.mainForm.get('startDate').updateValueAndValidity();
      this.mainForm.get('endDate').clearValidators();
      this.mainForm.get('endDate').updateValueAndValidity();

      this.currentClass.Schedule.forEach(day => {
        const tempInstructors: any[] = [];
        day.Instructors.forEach(instructor => {
          tempInstructors.push(instructor.PersonID);
        });
        this.days.push(
          this.formBuilder.group({
            startDate: [day.Date_D ? new Date(day.Date_D) : null],
            startTime: [day.StartTime_T ? new Date(day.StartTime_T) : null],
            endTime: [day.EndTime_T ? new Date(day.EndTime_T) : null],
            instructors: [tempInstructors]
          })
        );
      });
      if (this.days.length > 0) {
        this.disabledStartDate = this.days.controls[0].get('startDate').value;
      }
      this.checkFormChange();
    } else {
      this.mainForm.get('isComplex').setValue('false');
    }

    if (this.currentClass.StartDate_D) {
      this.startDate = new Date(this.currentClass.StartDate_D);
      this.mainForm.get('startDate').setValue(this.startDate);
    }
    if (this.currentClass.StartTime_T) {
      this.startTime = new Date(this.currentClass.StartTime_T);
      this.mainForm.get('startTime').setValue(this.startTime);
    }
    if (this.currentClass.EndDate_D) {
      this.endDate = new Date(this.currentClass.EndDate_D);
      this.mainForm.get('endDate').setValue(this.endDate);
    }
    if (this.currentClass.EndTime_T) {
      this.endTime = new Date(this.currentClass.EndTime_T);
      this.mainForm.get('endTime').setValue(this.endTime);
    }
    if (this.currentClass.IsModule) {
      this.setType = 'Module';
      this.mainForm.get('type').setValue('Module');
      this.setModuleID = this.currentClass.TrainingModuleID;
      this.mainForm.get('moduleTitle').setValue(this.currentClass.Title);
      this.mainForm.get('moduleTemplate').setValue(this.setModuleID);
      this.mainForm.get('startDate').clearValidators();
      this.mainForm.get('startDate').updateValueAndValidity();
      this.mainForm.get('endDate').clearValidators();
      this.mainForm.get('endDate').updateValueAndValidity();
      this.mainForm.get('course').clearValidators();
      this.mainForm.get('course').updateValueAndValidity();
      this.formLoaded = true;
      if (this.setModuleID) {
        this.getModuleDetails();
      }

      this.initType(true);

      this.currentClass.Schedule.forEach(day => {
        const tempInstructors: any[] = [];
        day.Instructors.forEach(instructor => {
          tempInstructors.push(instructor.PersonID);
        });
        this.days.push(
          this.formBuilder.group({
            startDate: [day.Date_D ? new Date(day.Date_D) : null],
            startTime: [day.StartTime_T ? new Date(day.StartTime_T) : null],
            endTime: [day.EndTime_T ? new Date(day.EndTime_T) : null],
            instructors: [tempInstructors],
            TrainingModuleDayID: day.TrainingModuleDayID,
            ClassScheduleID: day.ClassScheduleID
          })
        );
      });
      if (this.days.length > 0) {
        this.disabledStartDate = this.days.controls[0].get('startDate').value;
      }
      this.initInstructors(false);
      setTimeout(() => {
        this.checkFormChange();
      }, 5000);
    } else {
      this.setType = 'Class';
      this.mainForm.get('type').setValue('Class');
      this.initType(true);
    }

    this.setTimezoneId = this.currentClass.TimezoneID;
    this.mainForm.get('timezone').setValue(this.setTimezoneId);
    this.setLanguageId = this.currentClass.LanguageID;
    this.mainForm.get('language').setValue(this.setLanguageId);
    this.mainForm.get('signupNotes').setValue(this.currentClass.SignupNotes);
    this.initSelects();
  }

  initSelects() {
    if (this.setType === 'Class') {
      this.initOrganization(!this.canTabWrite, this.InterfaceObjectEnum, false, 'training');
      this.initCourseSelect(!this.canTabWrite, this.setOrganizationId);
    } else {
      this.initOrganization(!this.canTabWrite || this.isEdit, this.InterfaceObjectEnum, false, 'training');
      this.initModuleTemplates(!this.canTabWrite);
    }
    this.initStatusSelect(!this.canTabWrite);
    this.initTimezoneSelect(!this.canTabWrite);
    this.initLanguageSelect(!this.canTabWrite);
  }

  initType(disable: boolean) {
    this.inputObjType = {
      labelText: 'Type',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setType,
      disabled: disable,
      data: [{Description: 'Class'}, {Description: 'Module'}]
    };
  }

  getType(event: any) {
    if (event && event[0]) {
      this.setType = event[0].Description;
    }
    if (this.setType === 'Class') {
      this.mainForm.get('course').setValidators([Validators.required, NotZeroValidation()]);
      this.mainForm.get('status').setValidators([Validators.required, NotZeroValidation()]);
      this.mainForm.get('startDate').setValidators([Validators.required]);
      this.mainForm.get('endDate').setValidators([Validators.required]);
      this.mainForm.get('moduleTemplate').clearValidators();
      this.mainForm.get('moduleTitle').clearValidators();
    } else {
      this.mainForm.get('course').clearValidators();
      this.mainForm.get('status').clearValidators();
      this.mainForm.get('startDate').clearValidators();
      this.mainForm.get('endDate').clearValidators();
      this.mainForm.get('moduleTemplate').setValidators([Validators.required, NotZeroValidation()]);
      this.mainForm.get('moduleTitle').setValidators([Validators.required, NotZeroValidation()]);
    }
    for (const field in this.mainForm.controls) {
      if (field) {
        this.mainForm.get(field).updateValueAndValidity();
      }
    }
    this.setOrganizationId = null;
    this.setModuleID = null;
    this.moduleTemplate = null;
    this.setCourseId = null;
    this.setStatusId = null;
    this.setTimezoneId = null;
    this.setLanguageId = null;
    this.initSelects();
    this.mainForm.reset();
    this.mainForm.markAsDirty();
    this.mainForm.get('type').setValue(this.setType);
    if (this.setType === 'Class') {
      this.mainForm.get('isComplex').setValue('false');
    }
  }

  updateRequired(value) {
    if (this.isEdit && this.currentClass.ComplexSchedule && value === 'false') {
      this.confirmationService.confirm({
        header: 'Warning',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
        message: 'Changing from a complex schedule to a simple schedule will clear data entered for each day. Do you wish to continue?',
        accept: () => {
          this.mainForm.get('isComplex').setValue('false');
          this.days.clear();
        },
        reject: () => {
          // setTimeout(() => {this.mainForm.get('isComplex').setValue('true');}, 300);
          this.mainForm.get('isComplex').setValue('true');
        }
      });
    }
    if (value === 'true') {
      this.mainForm.get('startDate').clearValidators();
      this.mainForm.get('endDate').clearValidators();
      this.initInstructors(false);
    } else {
      this.mainForm.get('startDate').setValidators([Validators.required]);
      this.mainForm.get('endDate').setValidators([Validators.required]);
    }

    for (const field in this.mainForm.controls) {
      if (field) {
        this.mainForm.get(field).updateValueAndValidity();
      }
    }
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean, paramContext: string) {
    this.inputObjOrganization = {
      labelText: 'Training Center',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setOrganizationId,
      disabled: disable,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly, paramContext).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  getOrganizationData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setOrganizationId = event[0].ID;
      this.initCourseSelect(!this.canTabWrite, this.setOrganizationId);
      this.initModuleTemplates(!this.canTabWrite);
    } else {
      this.setOrganizationId = 0;
      this.initCourseSelect(true, this.setOrganizationId);
      this.initModuleTemplates(true);
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('organization').setValue(this.setOrganizationId);
    this.initInstructors(false);
  }

  initModuleTemplates(disable: boolean) {
    this.inputObjModuleTemplate = {
      labelText: 'Module Templates',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.currentClass && this.currentClass.TrainingModuleID ? this.currentClass.TrainingModuleID : null,
      disabled: disable,
      canWrite: this.canTabWrite,
      emitChangeOnLoad: true
    };
    if (this.setOrganizationId) {
      this.lookupsDataService.getModuleTemplatesDownstreamLookupData(this.setOrganizationId).then((lookupData) => {
        this.inputObjModuleTemplate.data = lookupData;
        this.inputObjModuleTemplate = Object.assign({}, this.inputObjModuleTemplate);
      });
    }

  }

  getModuleTemplates(event: any) {
    if (event && event[0]) {
      this.setModuleID = event[0].ID;
    }
    // this.mainForm.markAsDirty();
    this.mainForm.get('moduleTemplate').setValue(this.setModuleID);
    if (this.setModuleID && !this.isEdit) {
      this.getModuleDetails();
    }
  }

  getModuleDetails() {
    this.trainingModulesService.getTrainingModuleTemplate(this.setModuleID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          if (!this.isEdit) {
            this.moduleTemplate = res;
            this.moduleTemplate.Days.forEach((x, index) => {
              this.addDay();
              this.days.controls[index].get('TrainingModuleDayID').setValue(x.TrainingModuleDayID);
            });
          }
          this.mainForm.get('program').setValue(res.ProgramDescription);
          this.mainForm.get('program').disable();
        }
      });
  }

  initInstructors(disable: boolean) {
    this.inputObjInstructor = {
      labelText: 'Instructors',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (this.setOrganizationId) {
      this.lookupsDataService.getClassInstructorsLookupData(this.setOrganizationId, false).then((lookupData) => {
        this.inputObjInstructor.data = lookupData;
        this.inputObjInstructor = Object.assign({}, this.inputObjInstructor);
      });
    }

  }

  getInstructors(event, index) {
    if (event && event[0]) {
      const tempInstructors: any[] = [];
      event.forEach(instructor => {
        tempInstructors.push({personID: instructor});
      });
      this.days.controls[index].get('instructors').setValue(tempInstructors);
    }

  }

  initCourseSelect(disable: boolean, organizationId: number): void {
    this.inputObjCourse = {
      labelText: 'Course',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setCourseId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (organizationId) {
      this.lookupsDataService.getCoursesByDownstreamOrganizationIdLookupData(organizationId).then((lookupData) => {
        this.inputObjCourse.data = lookupData;
        this.inputObjCourse = Object.assign({}, this.inputObjCourse);
      });
    }
  }

  initStatusSelect(disable: boolean): void {
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStatusId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getClassStatusesLookupData().then((lookupData) => {
      this.inputObjStatus.data = lookupData;
      this.inputObjStatus = Object.assign({}, this.inputObjStatus);
    });
  }

  loadTimezoneSelect(): void {
    this.initTimezoneSelect(!this.canTabWrite);
    this.mainForm.markAsDirty();
  }

  initTimezoneSelect(disable: boolean): void {
    this.inputObjTimezone = {
      labelText: 'Timezone',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setTimezoneId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    const param = transformDateTimeToDateOnly(this.mainForm.get('startDate').value);
    this.lookupsDataService.getTimezonesLookupData().then((lookupData) => {
      this.inputObjTimezone.data = lookupData;
      this.inputObjTimezone = Object.assign({}, this.inputObjTimezone);
    });
  }

  getCourseData(event: any) {
    if (event && event[0]) {
      this.setCourseId = event[0].ID;
    } else {
      this.setCourseId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('course').setValue(this.setCourseId);
  }

  getStatusData(event: any) {
    if (event && event[0]) {
      this.setStatusId = event[0].ID;
    } else {
      this.setStatusId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('status').setValue(this.setStatusId);
  }

  getTimezoneData(event: any) {
    if (event && event[0]) {
      this.setTimezoneId = event[0].ID;
    } else {
      this.setTimezoneId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('timezone').setValue(this.setTimezoneId);
  }

  canAddComplexSchedule() {
    return this.canTabWrite && this.mainForm.get('isComplex').value === 'true' && this.setType === 'Class' && this.setOrganizationId;
  }

  initLanguageSelect(disable: boolean): void {
    this.inputObjLanguage = {
      labelText: 'Language',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setLanguageId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getLanguagesLookupData().then((lookupData) => {
      this.inputObjLanguage.data = lookupData;
      this.inputObjLanguage = Object.assign({}, this.inputObjLanguage);
    });
  }

  getLanguageData(event: any) {
    if (event && event[0]) {
      this.setLanguageId = event[0].ID;
    } else {
      this.setLanguageId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('language').setValue(this.setLanguageId);
  }

  processData() {
    if (this.mainForm.valid) {
      this.existingMessage = false;
      this.isSaving = true;
      this.class.OrganizationID = this.setOrganizationId;
      this.class.CourseID = this.setCourseId;
      this.class.Location = this.mainForm.get('location').value;
      this.class.MaxSeats_N = this.mainForm.get('seats').value;
      this.class.TimezoneID = this.setTimezoneId;
      this.class.LanguageID = this.setLanguageId;
      this.class.SignupNotes = this.mainForm.get('signupNotes').value;
      this.class.ClassIdentifier = this.mainForm.get('classidentifier').value;
      if (this.setType === 'Class') {
        this.class.ClassStatusID = this.setStatusId;
        this.class.StartDate_D = transformDateTimeToDateOnly(this.mainForm.get('startDate').value);
        this.class.StartTime_T = transformDateTimeToTimeOnly(this.mainForm.get('startTime').value);
        this.class.EndDate_D = transformDateTimeToDateOnly(this.mainForm.get('endDate').value);
        this.class.EndTime_T = transformDateTimeToTimeOnly(this.mainForm.get('endTime').value);

        if (this.mainForm.get('isComplex').value === 'true') {
          this.class.Schedule = [];
          this.class.ComplexSchedule = true;
          this.days.controls.forEach((input, index) => {
            const tempInstructors: any[] = [];
            if (input.get('instructors').value) {
              input.get('instructors').value.forEach(x => {
                tempInstructors.push({PersonID: x});
              });
            }

            this.class.Schedule.push({
              Date_D: transformDateTimeToDateOnly(input.get('startDate').value),
              StartTime_T: transformDateTimeToTimeOnly(input.get('startTime').value),
              EndTime_T: transformDateTimeToTimeOnly(input.get('endTime').value),
              ClassScheduleID: null,
              TrainingModuleDayID: null,
              TrainingModuleDayDescription: null,
              Instructors: tempInstructors,
              Courses: null
            });
          });
        } else {
          this.class.Schedule = [];
          this.class.ComplexSchedule = false;
        }
        this.saveData();
      } else {
        this.class.CourseDescription = this.mainForm.get('moduleTitle').value;
        this.class.TrainingModuleID = this.setModuleID;
        if (!this.isEdit) {
          this.class.Schedule = [];
        }
        this.class.Title = this.mainForm.get('moduleTitle').value;
        this.days.controls.forEach((input, index) => {
          const tempInstructors: any[] = [];
          if (input.get('instructors').value) {
            input.get('instructors').value.forEach(x => {
              tempInstructors.push({PersonID: x});
            });
          }
          this.class.Schedule.push({
            Date_D: transformDateTimeToDateOnly(input.get('startDate').value),
            StartTime_T: transformDateTimeToTimeOnly(input.get('startTime').value),
            EndTime_T: transformDateTimeToTimeOnly(input.get('endTime').value),
            ClassScheduleID: input.get('ClassScheduleID').value,
            TrainingModuleDayID: input.get('TrainingModuleDayID').value,
            TrainingModuleDayDescription: null,
            Instructors: tempInstructors,
            Courses: null
          });
        });

        const checkDates = [];
        let dateUsed: boolean = false;
        this.mainForm.get('days').value.forEach(day => {
            if (day.startDate) {
              const tempDate = day.startDate.toDateString();
              if (checkDates.includes(tempDate)) {
                dateUsed = true;
              } else if (day.startDate) {
                checkDates.push(tempDate);
              }
            }
          }
        );

        if (dateUsed) {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Error - conflicting dates are not allowed. Please review.'});
          this.isSaving = false;
        } else {
          this.saveData();
        }
      }
    }
  }

  saveData() {
    if (this.isEdit) {
      this.classesService.updateClass(this.class.ClassID, this.class).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.confirmationService.close();
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
          this.mainForm.markAsPristine();
          this.mainForm.markAsUntouched();
          this.isSaving = false;
          this.existingMessage = false;
          if (this.setType !== 'Class') {
            this.initModuleTemplates(!this.canTabWrite);
          }
          this.days.clear();
          this.getClass();
          if (this.setType !== 'Class') {
            this.getModuleDetails();
          }
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
          this.isSaving = false;
          console.debug(e);
        }
      });
    } else {
      this.classesService.createClass(this.class).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (res) => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your class saved successfully'});
          this.mainForm.markAsPristine();
          this.router.navigateByUrl('training/classes/edit/' + res.ClassID);
          this.isSaving = false;
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
          this.isSaving = false;
          console.debug(e);
        }
      });
    }
  }

  //Module day creation
  createDays(): FormGroup {
    return this.formBuilder.group({
      ClassScheduleID: [null],
      TrainingModuleDayID: [null],
      startDate: [null],
      startTime: [null],
      endTime: [null],
      instructors: [null]
    });
  }

  addDay() {
    this.mainForm.markAsDirty();
    this.days.push(this.createDays());
  }

  disabledDates() {
    if (this.days.length > 0) {
      this.disabledStartDate = this.days.controls[0].get('startDate').value;
    }
  }

  deleteDay(i: number): void {
    if (i >= 0) {
      this.dialogUtility.promptToDelete(() => {
        // this.deleteAllCourses(i).then(() => {
        this.days.removeAt(i);
        this.mainForm.markAsDirty();
        // });
      }, () => {
      });
    }
  }
}
