<div class="p-grid p-fluid confirm-data">
  <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10 ">
    <div class="p-grid p-fluid">
      <h3 style="margin-bottom:3px">Details</h3>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <b>Organization:</b> {{config.data.organizationName}}
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <b>Course:</b> {{config.data.courseName}}
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <b>Class #:</b> {{config.data.class.ClassIdentifier}}
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <b>Status:</b> {{config.data.statusName}}
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <b>Max # of Seats:</b> {{config.data.class.MaxSeats_N | number:'1.0':'en-US'}}
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <b>Start Date:</b> {{config.data.class.StartDate_D | date :'MM/dd/yyyy'}}
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <b>Start Time:</b> {{config.data.class.StartTime_T | date :'h:mm a'}}
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <b>End Time:</b> {{config.data.class.EndTime_T | date :'h:mm a'}}
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <b>Recurrence Summary:</b> {{recurrenceSummary}}
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-6 col-xs-12">
    <h3 style="margin-bottom:3px">Adding {{previewClass.length}} Classes</h3>
    <div class="classes-container">
      @for (pclass of previewClass; track pclass; let i = $index) {
        <div class="class-list">
          <b>{{i + 1}}.</b> {{pclass}}
        </div>
      }
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15 text-align-right">
    <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
    (click)="cancel()"></button>
    <button  pButton label="Add" class="p-button-info p-mr-2" icon="pi pi-plus" pTooltip="Add" tooltipPosition="bottom"
    (click)="saveData()" [className]="'inline-button'"></button>
  </div>
</div>
