import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { EmergencyContact } from '../../../models/user-profile.model';
import { Subject } from 'rxjs';
import {
  AddressModel
} from '../../../models/addresses.model';

@Component({
  selector: 'app-emergency-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrls: ['./emergency-contact.component.scss']
})

export class EmergencyContactComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  addressData: AddressModel = {} as AddressModel;
  @Input() emergencyContact: EmergencyContact;
  @Input() canTabWrite: boolean;
  @Output() updateAddress = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder) {
    this.mainForm = this.formBuilder.group({
      FirstName: new FormControl(null,Validators.required),
      LastName: new FormControl(null,Validators.required),
      Relationship: new FormControl(null,Validators.required),
      PhoneTypeID: new FormControl(null),
      PhoneNumber: new FormControl(null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      EmailAddress: new FormControl(null)
    });
  }
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.emergencyContact) {
      this.mainForm.get('FirstName').setValue(this.emergencyContact.FirstName);
      this.mainForm.get('LastName').setValue(this.emergencyContact.LastName);
      this.mainForm.get('Relationship').setValue(this.emergencyContact.Relationship);

      this.addressData.CountryID = this.emergencyContact.CountryID;
      this.addressData.Address1 = this.emergencyContact.Address1;
      this.addressData.Address2 = this.emergencyContact.Address2;
      this.addressData.City = this.emergencyContact.City;
      this.addressData.StateProvinceID = this.emergencyContact.StateProvinceID;
      this.addressData.Zip = this.emergencyContact.Zip;
      this.addressData.Ready = true;

      this.mainForm.get('PhoneTypeID').setValue(this.emergencyContact.PhoneTypeID);
      this.mainForm.get('PhoneNumber').setValue(this.emergencyContact.PhoneNumber);
      this.mainForm.get('EmailAddress').setValue(this.emergencyContact.EmailAddress);
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  processData() {
    this.emergencyContact.FirstName = this.mainForm.get('FirstName').value;
    this.emergencyContact.LastName = this.mainForm.get('LastName').value;
    this.emergencyContact.Relationship = this.mainForm.get('Relationship').value;
    this.emergencyContact.CountryID = this.addressData.CountryID;
    this.emergencyContact.Address1 = this.addressData.Address1;
    this.emergencyContact.Address2 = this.addressData.Address2;
    this.emergencyContact.City = this.addressData.City;
    this.emergencyContact.StateProvinceID = this.addressData.StateProvinceID;
    this.emergencyContact.Zip = this.addressData.Zip;
    this.emergencyContact.PhoneTypeID = this.mainForm.get('PhoneTypeID').value;
    this.emergencyContact.PhoneNumber = this.mainForm.get('PhoneNumber').value;
    this.emergencyContact.EmailAddress = this.mainForm.get('EmailAddress').value;
    this.updateAddress.emit(this.emergencyContact);
  }

  setAddress(addressChanges: AddressModel): void {
    if (addressChanges) {
      this.mainForm.markAsDirty();
      this.addressData.CountryID = addressChanges.CountryID;
      this.addressData.Address1 = addressChanges.Address1;
      this.addressData.Address2 = addressChanges.Address2;
      this.addressData.City = addressChanges.City;
      this.addressData.StateProvinceID = addressChanges.StateProvinceID;
      this.addressData.Zip = addressChanges.Zip;
    }
  }
}
