import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { ContextService } from './context.service';

import { OrganizationChangeLogsGridModel } from '../models/organization-change-logs.model';

@Injectable({
  providedIn: 'root',
})

export class OrganizationChangeLogsService {

  constructor(private http: HttpClient, private contextService: ContextService) {}

  getOrganizationChangeLogsGridAll(organizationId: number, startDate: string, endDate: string, filterData): Observable<OrganizationChangeLogsGridModel> {
    return this.http.post<OrganizationChangeLogsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/changelogroster/grid/${organizationId}?mode=ALL&startdate=${startDate}&enddate=${endDate}`,filterData);
  }

  // getOrganizationChangeLogsPrimeNG(dataMode: string, organizationId: number, startDate: string, endDate: string, filterData): Observable<OrganizationChangeLogsGridModel> {
  //   return this.http.post<OrganizationChangeLogsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/changelogroster/grid/${organizationId}?mode=${dataMode}&startdate=${startDate}&enddate=${endDate}`,filterData);
  // }

  // getOrganizationChangeLogsExport(organizationId: number, startDate: string, endDate, data: FormData): Observable<ArrayBuffer> {
  //   // eslint-disable-next-line @typescript-eslint/ban-types
  //   const requestOptions: Object = {
  //     responseType: 'arraybuffer'
  //   };
  //   return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/changelogroster/export/${organizationId}?filetype=xlsx&startdate=${startDate}&enddate=${endDate}`, data, requestOptions);
  // }
  getOrganizationChangeLogsExport(organizationId: number, startDate: string, endDate, filterData): Observable<ArrayBuffer> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/changelogroster/primeng/export/${organizationId}?startdate=${startDate}&enddate=${endDate}`, filterData, requestOptions);
  }
}
