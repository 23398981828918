<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <button pButton type="button" label="Programs" class="sub-tab" (click)="activeSubTab = 'programs'"></button>
    <button pButton type="button" label="Wage Rates" class="sub-tab" (click)="activeSubTab = 'wageRates'"></button>
  </div>
  @switch (activeSubTab) {
    @case ('programs') {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
        <app-roster-student-programs [PersonID]="PersonID" [isEdit]="isEdit" [canTabWrite]="canTabWrite"
                                     [canAdd]="canAdd"></app-roster-student-programs>
      </div>
    }
    @case ('wageRates') {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
        <app-student-programs-wage-rates [PersonID]="PersonID" [isEdit]="isEdit" [canAdd]="canAdd"
                                         [canReadWrite]="canTabWrite"></app-student-programs-wage-rates>
      </div>
    }
  }
</div>
