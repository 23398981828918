import { FilterModel } from '../../models/table-filter.model';

export function listFilterUtility (value, matchMode: string, operator: string): FilterModel[] {
  const filter: FilterModel[] = [];
  filter.push(
    {
      value,
      matchMode,
      operator
    }
  );
  return filter;
}
