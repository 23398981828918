import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-workflow-steps-tab',
  templateUrl: './workflow-steps-tab.component.html',
})
export class WorkflowStepsTabComponent implements OnInit{
  selectedSubTab: string = 'list';
  @Input() category: string;
  @Input() organizationId: number;
  @Input() workflowId: number;
  @Input() canTabWrite: boolean;
  @Input() isEdit: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() canAdd: boolean;

  ngOnInit(): void {
  }
}
