@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form>
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      @if (emailBody) {
        <div class="col-md-12 col-sm-6 col-xs-12 input-container">
          <div [innerHTML]="emailBody"></div>
        </div>
      }
      @if (textMessage) {
        <div class="col-md-12 col-sm-6 col-xs-12 input-container">
          <div [innerHTML]="textMessage"></div>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <p-divider class="dark-divider"></p-divider>
        <h3>Recipients</h3>
        <p-table
          #dt1
          [rows]="pageRowCountUtility.pageRowCount()"
          [columns]="cols"
          [value]="communicationRecipientsList"
          [paginator]="true"
          [showCurrentPageReport]="true"
          [metaKeySelection]="false"
          [totalRecords]="dataTotal"
          [rowsPerPageOptions]="[25,50,100,250,500]"
          [scrollable]="true"
          scrollHeight="400px"
          paginatorDropdownAppendTo="body"
          [loading]="loading"
          styleClass="p-datatable-responsive-demo p-datatable-striped inner-table"
          currentPageReportTemplate="Showing {first} to {last} of {{ dataTotal  | number}} entries"
          >
          <ng-template pTemplate="header" let-columns>
            <tr>
              @for (col of columns; track col) {
                <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom"
                  [ngStyle]="{'width': col.width}">
                  <div class="header-text">
                    {{col.header}}
                  </div>
                  <div class="header-actions">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    @if (col.columnType !== 'none') {
                      <p-columnFilter type="{{col.columnType}}" matchMode="{{col.matchMode}}" [field]="col.field" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    }
                  </div>
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" >
            <tr>
              @for (col of columns; track col) {
                <td [ngStyle]="{'width': col.width}">
                  @if (col.visible && col.field === 'Channel') {
                    @if (rowData.Channel==='Email') {
                      <i class="fa fa-envelope" style="color: #FACB43;" pTooltip="E-mail" tooltipPosition="bottom"></i>
                    }
                    @if (rowData.Channel==='Text Message') {
                      <i class="fa fa-comment-alt-lines" style="color: #018AFC;" pTooltip="Text Message" tooltipPosition="bottom"></i>
                    }
                  }
                  @if (col.visible && col.field === 'DateDistributed_D') {
                    {{rowData[col.field] | date:'shortDate'}}
                  }
                  @if (col.field !== 'Exclude' && col.field !== 'Channel' && col.field !== 'DateDistributed_D') {
                    {{rowData[col.field]}}
                  }
                </td>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [colSpan]="cols?.length" style="width: 100%;">No Recipients Found</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </form>
}
