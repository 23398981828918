<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                    [parentObjectEnum]="parentObjectEnum"></app-learn-more>
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3>Filter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <label>Description</label>
        <input class="full-width filter" pInputText type="text" [(ngModel)]="descriptionFilter"
               (keyup.enter)="filterItems()" tabindex="0"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding margin-top-10">
        <app-select class="full-width" [inputObj]="inputObjCaseType" (emitData)="setCaseTypeData($event)"></app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <div class="list-filter-buttons text-align-right">
          <button pButton class="p-button-download export-menu" icon="pi pi-times" label="Clear"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="clearFilters()">
          </button>
          <button pButton class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  (click)="filterItems()">
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12">@if (casesList) {
          <span>{{ cases.Total | number:'1.0':'en-US' }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (casesList) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="cases?.Total"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        <button pButton (click)="refresh()" icon="pi pi-refresh"
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="casesList?.length === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (casesList) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="cases?.Total"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (totalLoaded && cases.Total <= 0) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (caseRow of casesList; track caseRow; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-12 col-sm-8 col-xs-8">
                  <div class="list-header">
                    {{ caseRow.WorkflowTypeDescription }} | {{ caseRow.EmployerName }}
                  </div>
                  <div class="list-subheader">
                    <b>Filed:</b> {{ caseRow.FiledDate_D | date:'shortDate' }}@if (caseRow.UnionIdentifier) {
                    <span> | <b>Union Case #:</b> {{ caseRow.UnionIdentifier }}</span>
                  }
                    @if (caseRow.EmployerIdentifier) {
                      <span> | <b>Employer Case #:</b> {{ caseRow.EmployerIdentifier }}</span>
                    }
                  </div>
                  <div class="list-subheader">
                    <b>Next Due:</b> {{ caseRow.NextDueDate_D | date:'shortDate' }} | <b>Current
                    Step:</b> {{ caseRow.CurrentStep }}
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
