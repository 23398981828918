import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PaymentMethodsService} from '../../../../services/payment-methods.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PaymentMethodModel} from '../../../../models/payment-methods.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {TenantConfigDataService} from '../../../../services/tenant-config-data.service';
import {PctPaymentsService} from '../../../../services/pct-payment.service';
import {OrganizationAutoPayModel} from '../../../../models/organization-payments.model';
import {ContextService} from '../../../../services/context.service';

@Component({
  selector: 'app-autopay-tab',
  templateUrl: './autopay-tab.component.html',
  styleUrls: ['./autopay-tab.component.scss']
})

export class AutopayTabComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  setPaymentDay: number;
  paymentMethodList: PaymentMethodModel[] = [];
  currentPaymentMethod: PaymentMethodModel[] = [];
  currentAutoPay: OrganizationAutoPayModel;
  showAddEditNote: boolean = false;
  dataLoaded: boolean;
  isSaving: boolean = false;
  isAutoPay: boolean = false;
  inputObjPaymentDay: GenericSelectModel;
  AUTOPAYSIGNUPNOTICE: string;
  existingMessage: boolean = false;
  @Input() PersonID: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() userObject: boolean;
  @Input() organizationId: number;
  @Input() payeeOrganizationId: number;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private messageService: MessageService, private contextService: ContextService,
              private ref: DynamicDialogRef, private paymentMethodsService: PaymentMethodsService,
              private lookupsDataService: LookupsDataService, private tenantConfigDataService: TenantConfigDataService,
              private confirmationService: ConfirmationService, private pctPaymentsService: PctPaymentsService) {
    this.mainForm = this.formBuilder.group({
      paymentMethodID: new FormControl('', [Validators.required]),
      paymentDay: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.AUTOPAYSIGNUPNOTICE = this.tenantConfigDataService.getStringValue('AUTOPAYSIGNUPNOTICE');
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.isFormValid() && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    if (this.PersonID) {
      this.getAutomaticPaymentMethod(this.PersonID);
    } else {
      this.getAutomaticPaymentMethodByOrg(this.organizationId, this.payeeOrganizationId);
    }

    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Auto pay has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initPaymentDay(disable: boolean): void {
    let billingCategory: string;
    if (this.PersonID) {
      billingCategory = 'member';
    } else {
      billingCategory = 'organization';
    }
    this.inputObjPaymentDay = {
      labelText: 'Pay each month on this day',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setPaymentDay,
      disabled: disable
    };
    this.lookupsDataService.getAutoPayLookupData(billingCategory).then((lookupData) => {
      this.inputObjPaymentDay.data = lookupData;
      this.inputObjPaymentDay = Object.assign({}, this.inputObjPaymentDay);
    });
  }

  getPaymentDayData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setPaymentDay = event[0].ID;
    } else {
      this.setPaymentDay = 0;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('paymentDay').setValue(this.setPaymentDay);
  }

  initPaymentMethodData(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.mainForm.get('paymentMethodID').setValue(0);
    if (this.PersonID) {
      let counter = 0;
      for (const currentPaymentMethod of this.currentPaymentMethod) {
        if (currentPaymentMethod.AutoPaymentScheduleTypeID !== null) {
          this.isAutoPay = true;
          this.setPaymentDay = currentPaymentMethod.AutoPaymentScheduleTypeID;
          this.mainForm.get('paymentMethodID').setValue(currentPaymentMethod.PaymentMethodId);
          this.initPaymentDay(false);
        } else {
          counter++;
        }
        if (counter === this.currentPaymentMethod.length) {
          this.mainForm.get('paymentMethodID').setValue(0);
        }
      }
    } else {
      this.isAutoPay = true;
      this.setPaymentDay = this.currentAutoPay.AutoPaymentScheduleTypeID;
      if (this.currentAutoPay.PaymentMethodID) {
        this.mainForm.get('paymentMethodID').setValue(this.currentAutoPay.PaymentMethodID);
      } else {
        this.mainForm.get('paymentMethodID').setValue(0);
        this.isAutoPay = false;
      }
      this.initPaymentDay(false);
    }
    this.dataLoaded = true;
  }

  isFormValid(): any {
    if (this.mainForm.get('paymentMethodID').value !== '0') {
      return this.mainForm.valid && this.mainForm.dirty && this.setPaymentDay > 0;
    } else {
      return this.mainForm.valid && this.mainForm.dirty;
    }
  }

  getAutomaticPaymentMethod(Id: number): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.dataLoaded = false;
    this.getListRequest = this.paymentMethodsService.getPaymentMethodsPrimeNG('DATA', 'PERSON', Id, null)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.paymentMethodList = res.Data;
          this.currentPaymentMethod = res.Data;
          this.initPaymentMethodData();
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
  }

  getAutomaticPaymentMethodByOrg(organizationId: number, payeeOrganizationId: number): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.dataLoaded = false;
    this.getListRequest = this.paymentMethodsService.getPaymentMethodsPrimeNG('DATA', 'ORGANIZATION', organizationId, null)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.paymentMethodList = res.Data;
          this.currentPaymentMethod = res.Data;
          this.pctPaymentsService.getAutoPaymentByOrganizationData(organizationId, payeeOrganizationId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (response) => {
                this.currentAutoPay = response;
                this.initPaymentMethodData();
                this.dataLoaded = true;
              }, error: (e) => {
                console.debug(e);
                this.dataLoaded = true;
              }
            });
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
  }

  updateAutoPaymentByOrganizationByOrg(): void {
    const obj = {
      AutoPaymentScheduleTypeID: this.mainForm.get('paymentDay').value,
      PaymentMethodID: this.paymentMethodList[0].PaymentMethodId
    };
    this.dataLoaded = false;
    this.pctPaymentsService.updatePaymentByOrganizationData(this.organizationId, this.payeeOrganizationId, obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.dataLoaded = true;
          this.initPaymentMethodData();
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
  }

  deleteAutoPaymentByOrganizationByOrg(): void {
    this.dataLoaded = false;
    this.pctPaymentsService.deletePaymentByOrganizationData(this.organizationId, this.payeeOrganizationId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.dataLoaded = true;
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: e.error.Message.replace('UM: ', ''),
              life: 5000
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
          }
          this.dataLoaded = true;
        }
      });
  }

  getPaymentMethodData(): void {
    if (this.mainForm.get('paymentMethodID').value !== '0') {
      this.isAutoPay = true;
      this.mainForm.get('paymentDay').setValidators(Validators.required);
      this.initPaymentDay(false);
    } else {
      this.isAutoPay = false;
      this.mainForm.get('paymentDay').setValidators(null);
      this.mainForm.get('paymentDay').setValue(null);
      this.setPaymentDay = 0;
    }
    this.mainForm.get('paymentDay').updateValueAndValidity();
  }

  closeAddEdit(): void {
    this.showAddEditNote = false;
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
    } else {
      this.initPaymentMethodData();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.saveForm();
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Missing Data',
        detail: 'No data was saved, the form is currently incomplete.'
      });
      this.existingMessage = false;
    }
  }

  saveForm(): void {
    switch (true) {
      case this.userObject === true:
        if (!this.PersonID) {
          if (this.setPaymentDay) {
            this.updateAutoPaymentByOrganizationByOrg();
          } else {
            this.deleteAutoPaymentByOrganizationByOrg();
          }
        } else {
          this.paymentMethodsService.setAutoPay(Number(this.mainForm.get('paymentMethodID').value), this.setPaymentDay)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.confirmationService.close();
                this.ref.destroy();
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'Your auto pay has been successfully added'
                });
                this.mainForm.markAsPristine();
                this.mainForm.markAsUntouched();
                this.isSaving = false;
                this.existingMessage = false;
              }, error: (e) => {
                if (e?.error?.Message) {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.error.Message.replace('UM: ', ''),
                    life: 5000
                  });
                } else {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                  });
                }
                this.isSaving = false;
              }
            });
        }
        break;
      case this.userObject === false:
        if (!this.PersonID) {
          if (this.setPaymentDay) {
            this.updateAutoPaymentByOrganizationByOrg();
          } else {
            this.deleteAutoPaymentByOrganizationByOrg();
          }
        } else {
          this.paymentMethodsService.setAutoPayment(Number(this.mainForm.get('paymentMethodID').value), this.setPaymentDay, this.PersonID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.confirmationService.close();
                this.ref.destroy();
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'Your auto pay has been successfully added'
                });
                this.mainForm.markAsPristine();
                this.mainForm.markAsUntouched();
                this.isSaving = false;
                this.existingMessage = false;
              }, error: (e) => {
                console.debug(e);
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
                this.isSaving = false;
                console.debug(e);
              }
            });
        }
        break;
    }
  }
}
