<div class="p-fluid p-grid time-entry-container">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="p-fluid p-grid ">
      <div class="col-md-7 col-sm-12 col-xs-12">
        @if (canTabWrite) {
          <button pButton class="time-entry-button p-mr-2" label="New Entry"
                  pTooltip="New Entry" tooltipPosition="bottom" (click)="selectedTab = 'DRAFT'; reloadForm();"
                  [ngClass]="{'active-tab' : selectedTab === 'DRAFT'}">
          </button>
        }
        <button pButton class="time-entry-button p-mr-2" label="Waiting Approval"
                pTooltip="Waiting Approval" tooltipPosition="bottom" (click)="selectedTab = 'SUBMITTED'; reloadForm();"
                [ngClass]="{'active-tab' : selectedTab === 'SUBMITTED'}">
        </button>
        <button pButton class="time-entry-button p-mr-2" label="Rejected"
                pTooltip="Rejected Approval" tooltipPosition="bottom" (click)="selectedTab = 'REJECTED'; reloadForm();"
                [ngClass]="{'active-tab' : selectedTab === 'REJECTED'}">
        </button>
        <button pButton class="time-entry-button p-mr-2" label="Approved"
                pTooltip="Approved" tooltipPosition="bottom" (click)="selectedTab = 'APPROVED'; reloadForm();"
                [ngClass]="{'active-tab' : selectedTab === 'APPROVED'}">
        </button>
      </div>
      <div class="col-md-5 col-sm-12 col-xs-12 text-align-right">
        <button pButton class="time-entry-button p-mr-2" label="Collapse All"
                pTooltip="Collapse All" tooltipPosition="bottom" (click)="collapseAll()">
        </button>
        <button pButton class="time-entry-button" label="Expand All"
                pTooltip="Expand All" tooltipPosition="bottom" (click)="expandAll()">
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
    @if (showForm) {
      <div>
        <app-time-new-entry [personId]="PersonID" [selectedTab]="selectedTab" [canTabWrite]="canTabWrite"
                            [studentFacing]="studentFacing"
                            (resetToDraft)="resettingToDraft($event)"
                            [draftProgramId]="draftProgramId" [draftFacetTypeId]="draftFacetTypeId"
                            [draftStartDate]="draftStartDate"></app-time-new-entry>
      </div>
    }
  </div>
</div>
