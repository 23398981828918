import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {LookupModel} from '../../../models/lookups.model';
import {GenericSelectModel} from '../../../models/generic-select.model';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html'
})
export class SelectComponent implements OnInit, OnChanges, AfterViewInit {
  showFilter: boolean = false;
  selectForm: FormGroup;
  selectData: LookupModel[];
  virtScroll: boolean;
  @Input() inputObj: GenericSelectModel;
  @Input() newValue: any;
  @Input() tabOrder: number;
  @Output() emitData = new EventEmitter<any>();
  @Output() emitBlur = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef) {
    this.selectForm = this.formBuilder.group({
      fcnSelect: [null]
    });
  }

  ngOnInit(): void {
    if (this.inputObj) {
      if (typeof this.inputObj.customSelect == 'undefined') {
        this.inputObj.customSelect = null;
      }
    }
  }

  // reload if params change
  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputObj) {
      if (typeof this.inputObj.customSelect == 'undefined') {
        this.inputObj.customSelect = null;
      }
      this.selectForm.markAsPristine();
      this.selectForm.markAsUntouched();
      this.loadSelect();
      if (this.inputObj.canWrite) {
        if (this.inputObj.disabled === true) {
          this.selectForm.get('fcnSelect').disable();
        } else {
          this.selectForm.get('fcnSelect').enable();
        }
      } else {
        this.selectForm.get('fcnSelect').disable();
      }
    } else {
      this.selectForm.get('fcnSelect').disable();
    }
    if (this.inputObj?.requiredField) {
      this.selectForm.get('fcnSelect').setValidators(Validators.required);
      this.selectForm.markAsPristine();
      this.selectForm.markAsUntouched();
      this.selectForm.get('fcnSelect').updateValueAndValidity();
    } else {
      this.selectForm.get('fcnSelect').clearValidators();
      this.selectForm.markAsPristine();
      this.selectForm.markAsUntouched();
      this.selectForm.get('fcnSelect').updateValueAndValidity();
    }
    if (changes.newValue) {
      if (this.inputObj && this.newValue && this.inputObj.initSelected !== this.newValue) {
        this.selectForm.get('fcnSelect').setValue(this.newValue);
        this.emitDataToParent(true);
      }
    }
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  // required to listen to form controls
  get registerFormControl(): any {
    return this.selectForm.controls;
  }

  loadSelect(): void {
    if (this.inputObj.data) {
      this.selectData = this.inputObj.data;
      if (this.inputObj.data.length > 100) {
        this.virtScroll = true;
      }
    }
    if (this.inputObj.requiredField) {
      this.selectForm.get('fcnSelect').setValidators([Validators.required]);
    }
    if (this.inputObj.selectFirstValue) {
      let id: number;
      let desc: string;
      let startDate: string;
      let value: string;
      let enumString: string;
      if (this.selectData) {
        for (let i = 0, ilen = this.selectData.length; i < ilen; i++) {
          if (this.inputObj.optionValue === 'Description') {
            desc = this.selectData[i].Description;
            if (desc !== '--') {
              break;
            }
          } else if (this.inputObj.optionValue === 'StartDate_D') {
            startDate = this.selectData[i].StartDate_D;
            if (startDate) {
              break;
            }
          } else if (this.inputObj.optionValue === 'value') {
            value = this.selectData[i].value;
            if (value) {
              break;
            }
          } else if (this.inputObj.optionValue === 'ActivityID') {
            id = this.selectData[i].ActivityID;
            if (id) {
              break;
            }
          } else if (this.inputObj.optionValue === 'Enum') {
            enumString = this.selectData[i].Enum;
            if (id) {
              break;
            }
          } else {
            id = this.selectData[i].ID;
            if (id) {
              break;
            }
          }
        }
        switch (this.inputObj.optionValue) {
          case 'ID':
            this.selectForm.get('fcnSelect').setValue(id);
            break;
          case 'Description':
            this.selectForm.get('fcnSelect').setValue(desc);
            break;
          case 'StartDate_D':
            this.selectForm.get('fcnSelect').setValue(startDate);
            break;
          case 'value':
            this.selectForm.get('fcnSelect').setValue(value);
            break;
          case 'ActivityID':
            this.selectForm.get('fcnSelect').setValue(id);
            break;
          case 'Enum':
            this.selectForm.get('fcnSelect').setValue(enumString);
            break;
        }
      }
    } else {
      if (this.inputObj.initSelected !== null && this.inputObj.initSelected !== undefined) {
        this.selectForm.get('fcnSelect').setValue(this.inputObj.initSelected);
      } else {
        this.selectForm.get('fcnSelect').setValue(null);
      }
    }
    if (this.selectData) {
      this.showFilter = Object.keys(this.selectData).length > 10;
      if (this.inputObj.emitChangeOnLoad === undefined) {
        this.emitDataToParent(false);
      } else {
        this.emitDataToParent(this.inputObj.emitChangeOnLoad);
      }
    }
    if (this.inputObj.canWrite === undefined) {
      this.inputObj.canWrite = true;
    }
  }

  emitDataToParent(emit: boolean): void {
    if (emit && this.selectData) {
      let activeSelection;
      switch (this.inputObj.optionValue) {
        case 'ID':
          activeSelection = this.selectData.filter(x => x.ID === this.selectForm.get('fcnSelect').value);
          break;
        case 'Description':
          activeSelection = this.selectData.filter(x => x.Description === this.selectForm.get('fcnSelect').value);
          break;
        case 'ActivityID':
          activeSelection = this.selectData.filter(x => x.ActivityID === this.selectForm.get('fcnSelect').value);
          break;
        case 'FacetID':
          activeSelection = this.selectData.filter(x => x.FacetID === this.selectForm.get('fcnSelect').value);
          break;
        case 'WorkflowStepID':
          activeSelection = this.selectData.filter(x => x.WorkflowStepID === this.selectForm.get('fcnSelect').value);
          break;
        case 'StartDate_D':
          activeSelection = this.selectData.filter(x => x.StartDate_D === this.selectForm.get('fcnSelect').value);
          break;
        case 'value':
          activeSelection = this.selectData.filter(x => x.value === this.selectForm.get('fcnSelect').value);
          break;
        case 'Enum':
          activeSelection = this.selectData.filter(x => x.Enum === this.selectForm.get('fcnSelect').value);
          break;
      }
      if (activeSelection) {
        this.emitData.emit(activeSelection);
      } else {
        this.emitData.emit(null);
      }
    }
  }
}
