import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {takeUntil} from 'rxjs/operators';
import {CommunicationsService} from '../../../../services/communications.service';
import {CommunicationGridModel, CommunicationsGridModel} from '../../../../models/communications.model';
import {RouteContextUtility} from '../../../utilities/route-context.utility';
import {AdhocCommDialogComponent} from '../../adhoc-comm-dialog/adhoc-comm-dialog.component';
import {tabsPermissionsUtility} from '../../../utilities/tabs-permissions.utility';
import {InterfaceObjectAppsModel} from '../../../../models/interface-objects.model';
import {ClassStudentModel} from '../../../../models/class-students.model';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';
import {DialogUtility} from '../../../utilities/dialog.utility';

@Component({
  selector: 'app-communications-tab',
  templateUrl: './communications-tab.component.html',
  styleUrls: ['./communications-tab.component.scss']
})
export class CommunicationsTabComponent implements OnInit, OnChanges, OnDestroy {
  communications: CommunicationsGridModel;
  communicationList: CommunicationGridModel[] = [];
  dataLoaded: boolean;
  isExporting: boolean;
  totalLoaded: boolean;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  selectedItem: number;
  commTotal: number = 0;
  channel: string = '';
  routeContext: string;
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  inputObjSelectCommunicationData: GenericSelectModel;
  @Input() permissions: InterfaceObjectAppsModel[] = [];
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() recipients: ClassStudentModel[];
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  getListRequest;
  getTotalRequest;
  first: number = 0;

  private ngUnsubscribe = new Subject();

  constructor(public dialogService: DialogService, private communicationsService: CommunicationsService,
              private routeContextUtility: RouteContextUtility, private dialogUtility: DialogUtility,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.routeContext = this.routeContextUtility.getRouteContext();

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 0;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.inputObjSelectCommunication(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.routeContext = this.routeContextUtility.getRouteContext();

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 0;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
  }

  getData(): void {
    if (this.DBEntity && this.DBEntityID) {
      if (this.getListRequest) {
        this.getListRequest.unsubscribe();
      }
      this.selectedItem = -1;
      this.communicationList.length = 0;
      this.dataLoaded = false;
      this.getListRequest = this.communicationsService.getCommunicationByEntityPrimeNG('DATA', this.DBEntity, this.DBEntityID, this.routeContext, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.communications = res;
            this.communicationList = res.Data;
            this.dataLoaded = true;
          }
        });
      if (this.getTotalRequest) {
        this.getTotalRequest.unsubscribe();
      }
      this.commTotal = 0;
      this.totalLoaded = false;
      this.getTotalRequest = this.communicationsService.getCommunicationByEntityPrimeNG('TOTAL', this.DBEntity, this.DBEntityID, this.routeContext, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.commTotal = res.Total;
            this.totalLoaded = true;
          }
        });
    } else {
      this.dataLoaded = true;
      this.totalLoaded = true;
    }
  }

  updateList(dataChanged: boolean): void {
    if (dataChanged) {
      this.selectedItem = -1;
      this.getData();
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.first = event.first;
    this.getData();
  }

  filterChannel() {
    if (this.channel) {
      this.initFilters('Channel', this.channel, 'equals', 'and');
    } else {
      this.currentFilter.filters.Channel = [];
    }
  }

  filterItems(): void {
    this.dataLoaded = false;
    this.filterChannel();
    this.initFilters('Title', this.activeGlobalFilter, 'contains', 'and');
    this.currentFilter.first = 0;
    this.first = 0;
    this.getData();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.channel = null;
    this.inputObjSelectCommunication(false);
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  refresh(): void {
    this.getData();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  resetComponent(): void {
    this.communicationList = undefined;
    this.communications = undefined;
  }

  inputObjSelectCommunication(disable: boolean): void {
    this.inputObjSelectCommunicationData = {
      labelText: 'Communication Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: [
        {
          ID: null,
          Description: '--',
          Abbreviation: null
        },
        {
          ID: 2,
          Description: 'Email',
          Abbreviation: 'Email'
        },
        {
          ID: 3,
          Description: 'Text Message',
          Abbreviation: 'Text Message'
        }
      ],
      disabled: disable,
      emitChangeOnLoad: true
    };
  }

  setCommunicationData(event:any) {
    delete this.currentFilter.filters.Channel;
    if (event && event[0]) {
      this.channel = event[0].Description;
    } else {
      this.channel = null;
    }
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.communicationList.length <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.communicationList));
        const dataToExport = removeKeysFromArray(arr, ['SendPublishID', 'CommunicationID', 'SurveyID', 'AdHoc', 'ID']);
        exportData('communications', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.communicationsService.getCommunicationByEntityPrimeNG('DATA', this.DBEntity, this.DBEntityID, this.routeContext, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['SendPublishID', 'CommunicationID', 'SurveyID', 'AdHoc', 'ID']);
              exportData('communications', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }

  openAdhocComm(recipients) {
    const ref = this.dialogService.open(AdhocCommDialogComponent, {
      data: {
        recipientsClassStudent: recipients,
        isEdit: true,
        isDialog: true,
        DBEntity: this.DBEntity,
        DBEntityID: this.DBEntityID
      },
      header: 'Email or Text Recipients',
      width: '70%',
      height: '70%'
    });
  }
}
