import { NgModule } from '@angular/core';

import { ToastModule } from 'primeng/toast';
import { SharedModule} from '../shared/shared-module';
import { PublicComponent } from './public.component';
import { PublicRoutingModule } from './public-routing.module';


@NgModule({
  declarations: [PublicComponent,
    PublicRoutingModule.components],
  imports: [ToastModule,
    SharedModule,
    PublicRoutingModule],
  exports: [PublicComponent],
})
export class PublicModule { }
