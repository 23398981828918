import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';

import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {ClassStaffService} from '../../../../services/class-staff.service';
import {ClassStaffModel, ClassStaffPickerModel, CreateClassStaffModel, UpdateClassStaffModel} from '../../../../models/class-staff.model';
import {PageRowCountUtility} from '../../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-class-staff-form',
  templateUrl: './class-staff-form.component.html',
  styleUrls: ['./class-staff-form.component.scss']
})
export class ClassStaffFormComponent implements OnInit, OnChanges {
  mainForm: FormGroup;
  routeParams: any;
  isSaving: boolean = false;
  currentFilter = new PrimeTableFilterModel();
  currentRecord: ClassStaffModel;
  classStaff = {} as UpdateClassStaffModel;
  loading: boolean;
  isPage: boolean = false;
  staffPickerList: ClassStaffPickerModel[];
  selStaff: ClassStaffPickerModel[] = [];
  dataTotal: number = 0;
  cols: any[];
  primaryInstructor: boolean = false;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() ClassID: number;
  @Input() ClassStaffID: number;
  @Output() closeAddEdit = new EventEmitter<boolean>();
  getListRequest;
  getTotalRequest;
  private ngUnsubscribe = new Subject();

  constructor(private classStaffService: ClassStaffService,
              private router: Router, private route: ActivatedRoute,
              private messageService: MessageService, private formBuilder: FormBuilder,
              public pageRowCountUtility: PageRowCountUtility
  ) {
    this.mainForm = this.formBuilder.group({
      primary: new FormControl(false)
    });
  }

  _selectedColumns: any[];

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        if (this.router.url.includes('/edit/')) {
          this.isPage = true;
        } else this.isPage = this.router.url.includes('/add');
      }
    });
    if (this.isEdit) {
      this.mainForm.markAsPristine();
      this.mainForm.markAsUntouched();
      this.reset();
    } else {
      this.cols = [
        {field: 'FirstName', header: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
        {field: 'LastName', header: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
        {field: 'PrimaryInstructor', header: 'Primary', columnType: 'boolean', matchMode: 'equals', displayType: 'boolean', visible: true}
      ];
      this._selectedColumns = this.cols.filter(col => col.visible === true);
    }
  }

  ngOnChanges(): void {
    this.reset();
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  loadTable(event:any) {
    if (event) {
      this.currentFilter = event;
    } else {
      this.currentFilter.filters = {};
      this.currentFilter.sortOrder = 1;
    }
    this.getList(event);
    this.getTotal(event);
  }

  getList(event:any) {
    this.loading = true;
    this.currentFilter = event;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.classStaffService.getClassStaffPickerPrimeNG('DATA', this.ClassID, this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.staffPickerList = res.Data;
          this.loading = false;
        }, error: (e) => {
          this.loading = false;
          console.log(e);
        }
      });
  }

  getTotal(event:any) {
    this.currentFilter = event;
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.classStaffService.getClassStaffPickerPrimeNG('TOTAL', this.ClassID, this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  cancel(): void {
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  initForm(): void {
    if (this.isEdit) {
      this.mainForm.get('primary').setValue(this.currentRecord.PrimaryInstructor);
    }
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }

  getDataToEdit(): void {
    if (this.ClassID && this.ClassStaffID) {
      this.loading = true;
      this.classStaffService.getClassStaff(this.ClassID, this.ClassStaffID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.currentRecord = res;
            this.primaryInstructor = res.PrimaryInstructor;
            this.initForm();
            this.loading = false;
          }, error: () => {
            this.loading = false;
          }
        });
    } else {
      this.loading = false;
    }
  }

  processData(): void {
    const ids: CreateClassStaffModel[] = [];
    this.isSaving = true;
    if (this.isEdit) {
      this.classStaff.PrimaryInstructor = this.mainForm.get('primary').value;
    } else {
      this.selStaff.forEach(x => {
        ids.push({
          PersonID: x.PersonID,
          PrimaryInstructor: x.PrimaryInstructor
        });
      });
    }
    this.saveData(ids);
  }

  reset(): void {
    this.route.params.subscribe({
      next: () => {
        this.getDataToEdit();
      }
    });
  }

  saveData(dataModel: CreateClassStaffModel[]): void {
    if (this.isEdit) {
      const dataModelUpdate: UpdateClassStaffModel = {
        PrimaryInstructor: this.classStaff.PrimaryInstructor
      };
      this.classStaffService.updateClassStaff(this.ClassID, this.ClassStaffID, dataModelUpdate)
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
          this.mainForm.markAsUntouched();
          this.mainForm.markAsPristine();
          this.isSaving = false;
          this.closeAddEdit.emit(true);
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
          this.isSaving = false;
          console.debug(e);
        }
      });
    } else {
      this.classStaffService.createClassStaff(this.ClassID, dataModel)
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
          this.mainForm.markAsUntouched();
          this.mainForm.markAsPristine();
          this.isSaving = false;
          this.closeAddEdit.emit(true);
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
          this.isSaving = false;
          console.debug(e);
        }
      });
    }
  }

  primarySelected(event, rowData: ClassStaffPickerModel): void {
    if (event && rowData) {
      if (event.checked) {
        if (this.selStaff.length > 0) {
          this.selStaff.forEach(x => {
            if (x.PersonID !== rowData.PersonID) {
              x.PrimaryInstructor = false;
            }
          });
        }
      }
    }
  }
}
