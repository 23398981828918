import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { RosterProfileActivity, RosterProfileActivitiesGridModel } from '../models/roster-profile-activities.model';

@Injectable({
  providedIn: 'root',
})
export class RosterProfileActivitiesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getRosterActivityPreview(personId: number, modelData: RosterProfileActivity): Observable<RosterProfileActivity> {
    return this.http.put<RosterProfileActivity>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/activity/preview`, modelData);
  }

  getRosterActivitySubmit(personId: number, modelData: RosterProfileActivity): Observable<RosterProfileActivity> {
    return this.http.put<RosterProfileActivity>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/activity`, modelData);
  }

  getRosterActivitiesPrimeNG(dataMode: string, personId: number, filterData): Observable<RosterProfileActivitiesGridModel> {
    return this.http.post<RosterProfileActivitiesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/activityhistory/grid?mode=${dataMode}`, filterData);
  }

  recoverActivity(personId: number, activityHistoryId: number): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/activityrecovery/${activityHistoryId}`);
  }
}
