<div class="p-fluid p-grid">
  @if (organizationCategory==='union') {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <button pButton type="button" label="Positions" class="sub-tab" (click)="selectedSubTab = 'list'" [disabled]="selectedSubTab === 'list'"></button>
      <button pButton type="button" label="Options" class="sub-tab" (click)="selectedSubTab = 'options'" [disabled]="selectedSubTab === 'options'"></button>
    </div>
  }
  @if (selectedSubTab === 'list') {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <app-organization-positions-list [organizationId]="organizationId" [organizationCategory]="organizationCategory" [canTabWrite]="canTabWrite" [canAdd]="canAdd" [showFinancialContact]="showFinancialContact" [interfaceObjectDesc]="interfaceObjectDesc" [parentObjectEnum]="parentObjectEnum"></app-organization-positions-list>
    </div>
  }
  @if (organizationCategory==='union') {
    <div class="col-md-12 col-sm-12 col-xs-12">
      @if (selectedSubTab === 'options') {
        <app-organization-position-options [organizationId]="organizationId" [organizationCategory]="organizationCategory" [canTabWrite]="canTabWrite" [canAdd]="canAdd"></app-organization-position-options>
      }
    </div>
  }
</div>
