import {AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Location} from '@angular/common';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ContextService} from '../../services/context.service';
import {InterfaceObjectAppsModel, InterfaceObjectItemPermissions} from '../../models/interface-objects.model';
import {tabsPermissionsUtility} from '../../shared/utilities/tabs-permissions.utility';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PageService} from '../../services/page.services';
import {ProjectReportsService} from '../../services/project-reports.service';
import {ProjectReportsModel} from '../../models/project-reports.model';
import {PageModel} from '../../models/page.model';

@Component({
  selector: 'app-project-report-tab-container',
  templateUrl: './projects-report-tab-container.component.html',
  styleUrls: ['./projects-report-tab-container.component.scss']
})
export class ProjectsReportTabContainerComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  InterfaceObjectEnum: string = 'ORGANIZEPROJECT';
  isSaving: boolean = false;
  isDialog: boolean = false;
  projectReport = {} as ProjectReportsModel;
  permissions: InterfaceObjectAppsModel[] = [];
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  @Input() ContentID: number;
  @Input() isEdit: boolean;
  @Input() canPageWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  isPage: boolean = false;
  previousUrl: string;
  showTabs: boolean;
  invalidRecord: boolean = false;
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private route: ActivatedRoute,
              public config: DynamicDialogConfig, private pageService: PageService,
              private contextService: ContextService, private location: Location,
              private messageService: MessageService, private confirmationService: ConfirmationService,
              private projectReportsService: ProjectReportsService,
              private changeDetection: ChangeDetectorRef
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe({
        next: (event: NavigationEnd) => {
          this.previousUrl = event.url;
        }
      });
  }

  ngOnInit(): void {
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isEdit = this.pageInfo.isEdit;
          this.isPage = this.pageInfo.isPage;
          this.isDialog = this.pageInfo.isDialog;
          this.ContentID = this.pageInfo.ContentID;
          this.getDataToEdit(this.ContentID);
        }
      }
    });
    if (this.isDialog) {
      this.ContentID = this.config.data.id;
    }
    if (this.canPageWrite === undefined) {
      this.pageService.getPagePermissions(this.InterfaceObjectEnum).then((permission: InterfaceObjectItemPermissions) => {
        this.canPageWrite = permission.Write;
      });
    }
    this.pageService.getTabPermissions(this.InterfaceObjectEnum).then((tabPermissions: InterfaceObjectAppsModel[]) => {
      this.permissions = tabPermissions;
      this.showTabs = true;
    });
    if (this.config.data) {
      this.isDialog = this.config.data.isDialog;
    }
    this.changeStatus();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterContentInit(): void {
    this.changeStatus();
  }

  changeStatus(): void {
    this.changeDetection.detectChanges();
  }

  getDataToEdit(id: number): void {
    this.projectReportsService.getProtectReport(this.ContentID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.projectReport = res;
        }, error: () => {
          this.invalidRecord = true;
        }
      });
  }

  ngOnChanges(): void {
    this.getDataToEdit(this.ContentID);
  }


  resetWarning() {
    this.confirmationService.close();
  }

  back(): void {
    this.confirmationService.close();
    this.location.back();
    // if(this.previousUrl.includes('login')) {
    //   this.router.navigateByUrl('organize/project-reports/list');
    // } else {
    //   this.location.back();
    // }
  }
}
