import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {CreateUpdateEventModel, EventModel, EventsAttendedGridModel, EventsGridModel} from '../models/events.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getEventsPrimeNG(dataMode: string, organizationId: number, filterData): Observable<EventsGridModel> {
    Userpilot.track('Events List');
    return this.http.post<EventsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/event/grid?mode=${dataMode}`, filterData);
  }

  getEvent(eventId: number): Observable<EventModel> {
    // return this.http.get<EventModel>(`${this.contextService.contextObject.apiBaseUrlV2}/client/event/${eventId}`);
    return this.http.get<EventModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/event/${eventId}`);
  }

  createEvent(modelData: CreateUpdateEventModel): Observable<CreateUpdateEventModel> {
    return this.http.post<CreateUpdateEventModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/event/create`, modelData);
  }

  updateEvent(eventId: number, modelData: CreateUpdateEventModel): Observable<CreateUpdateEventModel> {
    return this.http.put<CreateUpdateEventModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/event/${eventId}`, modelData);
  }

  deleteEvent(eventId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/event/remove/${eventId}`);
  }

  getUserEventsPrimeNG(dataMode: string, filterData): Observable<EventsAttendedGridModel> {
    return this.http.post<EventsAttendedGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/eventsattendedgrid?mode=${dataMode}`, filterData);
  }

  getPersonEventsPrimeNG(dataMode: string, personId: number, filterData): Observable<EventsAttendedGridModel> {
    return this.http.post<EventsAttendedGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/eventsattended/grid?mode=${dataMode}`, filterData);
  }

}
