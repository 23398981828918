@if (organization.PhoneNumber) {
  <a [href]="'tel:'+organization.PhoneNumber">
    <div class="icon"><i class="fas fa-phone"></i></div>
  </a>
}
@if (organization.EmailAddress) {
  <a [href]="'mailTo:' +organization.EmailAddress" target="_top">
    <div class="icon"><i class="fas fa-envelope"></i></div>
  </a>
}
@if (organization.Latitude && organization.Longitude && organization.Name) {
  <div class="icon hide-on-mobile" pTooltip="Map" tooltipPosition="bottom"
       (click)="getLocation(organization)"><i class="fas fa-map-marker-alt"></i></div>
}
<div (click)="getOrgChart(organization)" class="icon hide-on-mobile" pTooltip="Hierarchy"
     tooltipPosition="bottom"><i class="fas fa-sitemap"></i></div>
<app-action-buttons [AlertMessage]="'Organization Alert: '" [AlertNavigateTo]="'/membership/organizations/edit/' + organization.ID" [AlertTitle]="((organization.Abbreviation && organization.Abbreviation !== '') ? organization.Abbreviation + ' - ' : '') + organization.Name" [InterfaceObjectEnum]="InterfaceObjectEnum"
                    [OrganizatioinID]="organization.ID"></app-action-buttons>
