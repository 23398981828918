<p-table
  #dt1
  [rows]="pageRowCountUtility.pageRowCount()"
  [columns]="selectedColumns"
  dataKey="ClassID"
  [value]="classesList"
  [lazy]="true"
  (onLazyLoad)="loadTable($event)"
  [paginator]="true"
  [showCurrentPageReport]="true"
  [metaKeySelection]="false"
  [totalRecords]="dataTotal"
  [rowsPerPageOptions]="[25,50,100,250,500]"
  [scrollable]="true"
  [(selection)]="selClass"
  (onFilter)="hasFilter = true"
  selectionMode="single"
  rowExpandMode="single"
  scrollHeight="54vh"
  paginatorDropdownAppendTo="body"
  [loading]="loading"
  (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
  styleClass="p-datatable-responsive-demo p-datatable-striped margin-top-15"
  currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
>
  <ng-template pTemplate="caption">
    <div class="p-grid p-fluid">
      <div class="col-md-3 col-sm-4 col-xs-12 margin-top-10">
        <app-select class="full-width" [inputObj]="inputObjTrainingCenters"
                    (emitData)="getTrainingCenterData($event)"></app-select>
      </div>
      <div class="col-md-12 col-sm-12 margin-top-10">
        <button pButton class="p-button-download search-button" icon="pi pi-search" label="Search"
                (click)="setGlobalFilter(dt1)">
        </button>
        <button pButton class="p-button-download search-button" icon="pi pi-times" label="Clear"
                [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" (click)="resetFilter()">
        </button>
      </div>
      <div class="col-md-7 col-sm-7"></div>
      <div class="col-md-5 grid-container-buttons-right">
        <div class="action-buttons">
          @if (hasFilter) {
            <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                    pTooltip="Clear Filter" tooltipPosition="bottom" [ngClass]="'filter-active margin-right-5'"
                    (click)="clear(dt1)">
            </button>
          }
          <button pButton (click)="refresh()" icon="pi pi-refresh" pTooltip="Refresh" tooltipPosition="bottom"></button>
          @if (canAdd) {
            <button pButton (click)="showAddEdit = true;" icon="pi pi-plus" pTooltip="Add"
                    tooltipPosition="bottom"></button>
          }
          <button pButton class="p-button-download export-menu export-excel" icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        </div>
      </div>
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Course" [styleClass]="'list-card'">
          <ng-template pTemplate="content">
            <app-classes-tab-container [canEdit]="true" (closeAddEdit)="loadTable(currentFilter)"
                                       [isEdit]="false"></app-classes-tab-container>
          </ng-template>
        </p-card>
      </div>
    }
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th [className]="'expand-column'"></th>
      @for (col of columns; track col) {
        <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
          @if (col.visible) {
            <div class="header-text">
              {{ col.header }}
            </div>
            @if (col.header !== "Start Time" && col.header !== "End Time") {
              <div class="header-actions">
                <p-sortIcon [field]="col.field"></p-sortIcon>
                <p-columnFilter [field]="col.field" display="menu" [matchMode]="col.matchMode" [showOperator]="false"
                                [showAddButton]="false" [type]="col.columnType">
<!--                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">-->
<!--                    <p-dropdown-->
<!--                      [ngModel]="value"-->
<!--                      (onChange)="customFilterCallback(filter, $event.value)"></p-dropdown>-->
<!--                  </ng-template>-->
                </p-columnFilter>
              </div>
            }
          }
        </th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
    <tr [pSelectableRow]="rowData" (dblclick)="goToEdit(rowData)" [ngClass]="{'selectedRow' : selClass === rowData}">
      <td [className]="'expand-column'">
        <button type="button" pButton [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      @for (col of columns; track col) {
        <td>
          @if (col.visible && col.displayType === 'date') {
            {{ rowData[col.field] | date: 'shortDate' }}
          }
          @if (col.visible && col.displayType === 'time') {
            {{ rowData[col.field] | date: 'shortTime' }}
          }
          @if (col.visible && col.displayType === 'text') {
            {{ rowData[col.field] }}
          }
          @if (col.visible && col.displayType === 'numeric') {
            {{ rowData[col.field] | number:'1.0':'en-US' }}
          }
        </td>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-rowData>
    <tr>
      <td [colSpan]="cols.length+ 1">
        <app-classes-tab-container [canEdit]="rowData.CanEdit" [ContentID]="rowData.ClassID" [isEdit]="true"
                                   [canPageWrite]="canPageWrite"
                                   class="no-padding-container full-width"></app-classes-tab-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="cols?.length + 1" style="width: 100%;">No classes found.</td>
    </tr>
  </ng-template>
</p-table>
<div class="p-grid p-fluid margin-top-15">
  <div class="col-md-6 col-sm-12 grid-container-buttons">
    <button pButton icon="pi pi-pencil" [routerLink]="['training/classes/edit/', selClassId]" pTooltip="Edit"
            [disabled]="!selClassId" tooltipPosition="bottom" class="p-button-secondary"
            style="margin-right:10px;"></button>
  </div>
  <div class="col-md-6 col-sm-12 grid-container-buttons-right">
    @if (canPageWrite) {
      <div class="action-buttons">
        <button pButton icon="pi pi-trash" (click)="deleteClass(selClassId)" class="p-button-secondary"
                [disabled]="canDelete" pTooltip="Delete" tooltipPosition="bottom"></button>
      </div>
    }
  </div>
</div>
<!--  </p-card>-->
<!--</div>-->
