import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {StudentProfileService} from '../../../services/student-profile.service';
import {StudentProfileModel} from '../../../models/student-profile.model';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PhoneValidationUtility} from '../../../shared/utilities/phone-validation.utility';
import {transformDateTimeToDateOnly} from '../../../shared/utilities/form.utility';
import {ContextService} from '../../../services/context.service';
import {PiiValidateModel} from '../../../models/pii-validate.model';
import {PiiDuplicateDialogComponent} from '../../../shared/components/pii-duplicate-dialog/pii-duplicate-dialog.component';
import {PiiValidateService} from '../../../services/pii-validate.service';
import {addHelmetsToHardhatsCustomField} from '../../../shared/utilities/component-manager-utility';

@Component({
  selector: 'app-student-roster-contact-form',
  templateUrl: './roster-student-form-contact.component.html',
  styleUrls: ['./roster-student-form-contact.component.scss']
})

export class RosterStudentFormContactComponent implements OnInit, OnChanges, OnDestroy {
  context = this.contextService.contextObject;
  mainForm: FormGroup;
  studentInfo: StudentProfileModel;
  inputObjPrefix: GenericSelectModel;
  inputObjRace: GenericSelectModel;
  inputObjEducation: GenericSelectModel;
  inputObjMilitary: GenericSelectModel;
  inputObjState: GenericSelectModel;
  inputObjCountry: GenericSelectModel;
  inputObjMilitaryStatus: GenericSelectModel;
  inputObjMarriageStatus: GenericSelectModel;
  inputObjShirtSize: GenericSelectModel;
  inputObjCitizenship: GenericSelectModel;
  setMilitaryStatusId: number;
  setMarriageStatusId: number;
  setShirtSizeId: number;
  setCitizenshipId: number;
  languages;
  selectedLanguages: number[] = [];
  selectedRaces: number[] = [];
  setPrefixId: number;
  setEthnicityId: number;
  setLanguageId: number;
  setEducationId: number;
  setMilitaryId: number;
  setStateId: number;
  setCountryId: number;
  dob: Date;
  isDirty: boolean;
  isSaving: boolean = false;
  showEmergency: boolean = false;
  isSearching: boolean;
  isEditing: boolean;
  updatedStudentInfo = {} as StudentProfileModel;
  inputObjLanguage: GenericSelectModel;
  setPreferredLanguage: number;
  preferredLanguageString: string;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() PersonID: number;
  existingMessage: boolean;
  PrimaryPhoneFailedCommunication: boolean = false;
  SecondaryPhoneFailedCommunication: boolean = false;
  PrimaryEmailFailedCommunication: boolean = false;
  SecondaryEmailFailedCommunication: boolean = false;
  public _addHelmetsToHardhatsCustomField = addHelmetsToHardhatsCustomField;
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private piiValidateService: PiiValidateService,
              private studentProfileService: StudentProfileService, private ref: DynamicDialogRef,
              private lookupsDataService: LookupsDataService, private dialogService: DialogService,
              private messageService: MessageService, private phoneValidationUtility: PhoneValidationUtility,
              private confirmationService: ConfirmationService, public contextService: ContextService) {
    this.mainForm = this.formBuilder.group({
      prefix: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      middleInitial: new FormControl(''),
      suffix: new FormControl(''),
      dob: new FormControl(''),
      gender: new FormControl(''),
      race: new FormControl(''),
      ethnicity: new FormControl(''),
      language: new FormControl(''),
      education: new FormControl(''),
      military: new FormControl(''),
      militaryStatusID: new FormControl(null),
      marriageStatusID: new FormControl(null),
      citizenshipID: new FormControl(null),
      shirtSizeID: new FormControl(null),
      schoolAttended: new FormControl(null),
      country: new FormControl(''),
      streetAddress: new FormControl(null, Validators.maxLength(100)),
      streetAddress2: new FormControl(null, Validators.maxLength(100)),
      city: new FormControl(null, Validators.maxLength(100)),
      state: new FormControl(''),
      zip: new FormControl(null, Validators.maxLength(10)),
      zip4: new FormControl(null, Validators.maxLength(4)),
      county: new FormControl(null, Validators.maxLength(100)),
      primaryPhoneNumber: new FormControl(null),
      secondaryPhoneNumber: new FormControl(null),
      thirdPhoneNumber: new FormControl(null),
      voter: new FormControl(null),
      SMSOptIn: new FormControl(''),
      primaryEmail: new FormControl(null, [Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      primaryEmailOptIn: new FormControl(''),
      secondaryEmail: new FormControl(null, [Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      secondaryEmailOptIn: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    if (this.PersonID) {
      this.getStudentInfo();
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: `${this.studentInfo.FirstName} ${this.studentInfo.LastName} has been modified. Please save, or all changes will be lost.`,
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  getStudentInfo(): void {
    this.studentProfileService.getStudentProfile(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.studentInfo = res;
          this.updatedStudentInfo.TypeID = this.studentInfo.TypeID;
          this.studentInfo.EmergencyContacts.forEach(x => {
            x.hideDelete = false;
          });
          this.initForm();
        }
      });
  }

  initForm(): void {
    this.isSearching = false;
    this.mainForm.get('prefix').setValue(this.studentInfo.PrefixID);
    this.mainForm.get('firstName').setValue(this.studentInfo.FirstName);
    this.mainForm.get('lastName').setValue(this.studentInfo.LastName);
    this.mainForm.get('middleInitial').setValue(this.studentInfo.MiddleName);
    this.mainForm.get('suffix').setValue(this.studentInfo.Suffix);
    if (this.studentInfo.DOB) {
      this.dob = new Date(this.studentInfo.DOB);
      this.mainForm.get('dob').setValue(this.dob);
    }
    if (this.studentInfo.RegisteredVoter === true) {
      this.mainForm.get('voter').setValue('true');
    } else if (this.studentInfo.RegisteredVoter === false) {
      this.mainForm.get('voter').setValue('false');
    }
    this.mainForm.get('gender').setValue(this.studentInfo.Gender);
    this.mainForm.get('streetAddress').setValue(this.studentInfo.PrimaryAddress1);
    this.mainForm.get('streetAddress2').setValue(this.studentInfo.PrimaryAddress2);
    this.mainForm.get('city').setValue(this.studentInfo.PrimaryCity);
    this.mainForm.get('zip').setValue(this.studentInfo.PrimaryZip);
    this.mainForm.get('zip4').setValue(this.studentInfo.PrimaryZipPlus4);
    this.mainForm.get('primaryPhoneNumber').setValue(this.studentInfo.PrimaryPhoneNumber);
    this.mainForm.get('secondaryPhoneNumber').setValue(this.studentInfo.SecondaryPhoneNumber);
    this.mainForm.get('thirdPhoneNumber').setValue(this.studentInfo.ThirdPhoneNumber);
    this.mainForm.get('SMSOptIn').setValue(this.studentInfo.SMSOptIn);
    this.mainForm.get('primaryEmail').setValue(this.studentInfo.PrimaryEmailAddress);
    this.mainForm.get('secondaryEmail').setValue(this.studentInfo.SecondaryEmailAddress);
    if (this.studentInfo.PrimaryEmailOptOut === false) {
      this.mainForm.get('primaryEmailOptIn').setValue('false');
    } else {
      this.mainForm.get('primaryEmailOptIn').setValue('true');
    }

    if (this.studentInfo.SecondaryEmailOptOut === false) {
      this.mainForm.get('secondaryEmailOptIn').setValue('false');
    } else {
      this.mainForm.get('secondaryEmailOptIn').setValue('true');
    }
    this.PrimaryPhoneFailedCommunication = this.studentInfo.PrimaryPhoneFailedCommunication;
    this.SecondaryPhoneFailedCommunication = this.studentInfo.SecondaryPhoneFailedCommunication;
    this.PrimaryEmailFailedCommunication = this.studentInfo.PrimaryEmailFailedCommunication;
    this.SecondaryEmailFailedCommunication = this.studentInfo.SecondaryEmailFailedCommunication;

    this.setPrefixId = this.studentInfo.PrefixID;
    this.setEducationId = this.studentInfo.EducationID;
    this.setEthnicityId = this.studentInfo.EthnicityID;
    this.setLanguageId = this.studentInfo.LanguageID;
    this.setPreferredLanguage = this.studentInfo.PreferredLanguageID;
    this.setMilitaryId = this.studentInfo.MilitaryID;
    this.setCountryId = this.studentInfo.PrimaryCountryID;
    this.setStateId = this.studentInfo.PrimaryStateProvinceID;
    this.selectedLanguages = this.studentInfo.LanguageIDList;
    this.setCitizenshipId = this.studentInfo.CitizenshipCountryID;
    this.setShirtSizeId = this.studentInfo.ShirtSizeID;
    this.setMarriageStatusId = this.studentInfo.MarriageStatusID;
    this.setMilitaryStatusId = this.studentInfo.MilitaryStatusID;
    this.mainForm.get('schoolAttended').setValue(this.studentInfo.SchoolAttended);
    this.selectedRaces = this.studentInfo.RaceIDList;
    if (this._addHelmetsToHardhatsCustomField()) {
      // add the Helmets to Hardhats attribute as necessary
      if (this.studentInfo.CustomAttributes && this.studentInfo.CustomAttributes.length > 0) {
        if (!this.studentInfo.CustomAttributes.find(x => x.FieldName === 'Helmets To Hardhats')) {
          this.studentInfo.CustomAttributes.push({
            OrganizationID: this.context.RootMemberOrgID,
            FieldName: 'Helmets To Hardhats',
            DataTypeEnum: 'YESNO',
            Value: 'No'
          });
        }
      } else {
        this.studentInfo.CustomAttributes = [{
          OrganizationID: this.context.RootMemberOrgID,
          FieldName: 'Helmets To Hardhats',
          DataTypeEnum: 'YESNO',
          Value: 'No'
        }];
      }
    }
    this.initPrefix(false);
    //  this.initEthnicity(false);
    this.initEducation(false);
    this.initMilitary(false);
    this.initCountry(false);
    this.initState(false);
    this.initMilitaryStatus(false);
    this.initMarriageStatus(false);
    this.initCitizenship(false);
    this.initShirtSize(false);
    this.initRace(false);
    this.initLanguages(false);
  }

  initPrefix(disable: boolean): void {
    this.inputObjPrefix = {
      labelText: 'Prefix',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setPrefixId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getHonorificsLookupData().then((lookupData) => {
      this.inputObjPrefix.data = lookupData;
      this.inputObjPrefix = Object.assign({}, this.inputObjPrefix);
    });
  }

  initCountry(disable: boolean): void {
    this.inputObjCountry = {
      labelText: 'Country',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setCountryId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getCountriesLookupData().then((lookupData) => {
      this.inputObjCountry.data = lookupData;
      this.inputObjCountry = Object.assign({}, this.inputObjCountry);
    });

  }

  initState(disable: boolean): void {
    this.inputObjState = {
      labelText: 'State/Province',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setStateId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (this.setCountryId) {
      this.lookupsDataService.getStatesLookupData(this.setCountryId).then((lookupData) => {
        this.inputObjState.data = lookupData;
        this.inputObjState = Object.assign({}, this.inputObjState);
      });
    }
  }

  initRace(disable: boolean): void {
    this.inputObjRace = {
      labelText: 'Race / Ethnicity',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.selectedRaces,
      disabled: disable,
      canWrite: this.canTabWrite,
      styleClass: 'multi-select'
    };
    this.lookupsDataService.getRaceLookupData().then((lookupData) => {
      this.inputObjRace.data = lookupData;
      this.inputObjRace = Object.assign({}, this.inputObjRace);
    });
  }

  getRaceData(event: any): void {
    if (event) {
      this.selectedRaces = event;
    } else {
      this.selectedRaces = [];
    }
    this.mainForm.markAsDirty();
    // this.mainForm.get('raceID').setValue(this.setRaceId);
  }

  initLanguages(disable, preferredChanged?: boolean) {
    this.lookupsDataService.getLanguagesLookupData(false).then((lookupData: any[]) => {
      if (preferredChanged) {
        //do nothing for now
      } else {
        this.preferredLanguageString = this.studentInfo.PreferredLanguageID ?
          ' (Preferred ' + lookupData.find(x => x.ID === this.studentInfo.PreferredLanguageID).Description + ')' : '';
      }

      this.inputObjLanguage = {
        labelText: 'Spoken Languages' + this.preferredLanguageString,
        optionValue: 'ID',
        optionLabel: 'Description',
        filter: true,
        requiredField: false,
        selectFirstValue: false,
        initSelected: this.selectedLanguages,
        disabled: disable,
        canWrite: this.canTabWrite,
        data: lookupData
      };
    });
  }

  setLanguageData(event) {
    if (event) {
      this.selectedLanguages = event;
    } else {
      this.selectedLanguages = [];
    }
    this.mainForm.markAsDirty();
  }

  setPreferredLanguageData(event) {
    this.preferredLanguageString = event ?
      ' (Preferred ' + this.inputObjLanguage.data.find(x => x.ID === event).Description + ')' : '';

    this.initLanguages(false, true);
    this.studentInfo.PreferredLanguageID = event;
    this.mainForm.markAsDirty();
  }

  initEducation(disable: boolean): void {
    this.inputObjEducation = {
      labelText: 'Education Level',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEducationId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getEducationLookupData().then((lookupData) => {
      this.inputObjEducation.data = lookupData;
      this.inputObjEducation = Object.assign({}, this.inputObjEducation);
    });
  }

  initMilitary(disable: boolean): void {
    this.inputObjMilitary = {
      labelText: 'Military Branch',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setMilitaryId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getMilitaryLookupData().then((lookupData) => {
      this.inputObjMilitary.data = lookupData;
      this.inputObjMilitary = Object.assign({}, this.inputObjMilitary);
    });
  }

  initMilitaryStatus(disable: boolean): void {
    this.inputObjMilitaryStatus = {
      labelText: 'Military Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setMilitaryStatusId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getMilitaryStatusLookupData().then((lookupData) => {
      this.inputObjMilitaryStatus.data = lookupData;
      this.inputObjMilitaryStatus = Object.assign({}, this.inputObjMilitaryStatus);
    });
  }

  getMilitaryStatusData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setMilitaryStatusId = event[0].ID;
    } else {
      this.setMilitaryStatusId = 0;
    }
    if (this.mainForm.get('militaryStatusID').value !== this.setMilitaryStatusId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('militaryStatusID').setValue(this.setMilitaryStatusId);
  }

  initMarriageStatus(disable: boolean): void {
    this.inputObjMarriageStatus = {
      labelText: 'Marital Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setMarriageStatusId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getMarriageStatusLookupData().then((lookupData) => {
      this.inputObjMarriageStatus.data = lookupData;
      this.inputObjMarriageStatus = Object.assign({}, this.inputObjMarriageStatus);
    });
  }

  getMarriageStatusData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setMarriageStatusId = event[0].ID;
    } else {
      this.setMarriageStatusId = 0;
    }
    if (this.mainForm.get('marriageStatusID').value !== this.setMarriageStatusId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('marriageStatusID').setValue(this.setMarriageStatusId);
  }

  initShirtSize(disable: boolean): void {
    this.inputObjShirtSize = {
      labelText: 'T-Shirt Size',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setShirtSizeId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getShirtSizeLookupData().then((lookupData) => {
      this.inputObjShirtSize.data = lookupData;
      this.inputObjShirtSize = Object.assign({}, this.inputObjShirtSize);
    });
  }

  getShirtSizeData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setShirtSizeId = event[0].ID;
    } else {
      this.setShirtSizeId = 0;
    }
    if (this.mainForm.get('shirtSizeID').value !== this.setShirtSizeId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('shirtSizeID').setValue(this.setShirtSizeId);
  }

  initCitizenship(disable: boolean): void {
    this.inputObjCitizenship = {
      labelText: 'Current Citizenship',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setCitizenshipId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getCitizenshipLookupData().then((lookupData) => {
      this.inputObjCitizenship.data = lookupData;
      this.inputObjCitizenship = Object.assign({}, this.inputObjCitizenship);
    });
  }

  getCitizenshipData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setCitizenshipId = event[0].ID;
    } else {
      this.setCitizenshipId = 0;
    }
    if (this.mainForm.get('citizenshipID').value !== this.setCitizenshipId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('citizenshipID').setValue(this.setCitizenshipId);
  }

  getPrefixData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setPrefixId = event[0].ID;
    } else {
      this.setPrefixId = 0;
    }
    if (this.setPrefixId !== 0) {
      this.initState(false);
    }
    this.mainForm.get('prefix').setValue(this.setPrefixId);
  }

  getEducationData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setEducationId = event[0].ID;
    } else {
      this.setEducationId = 0;
    }
    this.mainForm.get('education').setValue(this.setEducationId);
    this.mainForm.markAsDirty();
  }

  getMilitaryData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setMilitaryId = event[0].ID;
    } else {
      this.setMilitaryId = 0;
    }
    this.mainForm.get('military').setValue(this.setMilitaryId);
    this.mainForm.markAsDirty();
  }

  addEmergencyContact(): void {
    this.showEmergency = true;
    this.studentInfo.EmergencyContacts.push({
      FirstName: null,
      LastName: null,
      Relationship: null,
      Address1: null,
      Address2: null,
      City: null,
      StateProvinceID: null,
      Zip: null,
      CountryID: null,
      PhoneTypeID: null,
      PhoneNumber: null,
      EmailAddress: null,
      SequenceNumber: this.studentInfo.EmergencyContacts.length === 0 ? 1 : 2,
      hideDelete: true
    });
  }

  deleteEmergencyContact(contact, index): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove ' + contact.FirstName + ' ' + contact.LastName + ' as a contact?',
      acceptLabel: 'Continue',
      rejectLabel: 'Cancel',
      header: 'Delete Contact',
      icon: 'pi pi-trash',
      accept: () => {
        if (this.studentInfo.EmergencyContacts[index].LastName && this.studentInfo.EmergencyContacts[index].FirstName) {
          this.studentInfo.EmergencyContacts.splice(index, 1);
          this.mainForm.markAsDirty();
          this.processData();
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  updateEmergencyAddress(event, i): void {
    this.mainForm.markAsDirty();
    this.studentInfo.EmergencyContacts[i] = event;
    this.processData();
  }

  updateEmergencyPriority(): void {
    if (this.studentInfo.EmergencyContacts.length > 1) {
      this.mainForm.markAsDirty();
      this.studentInfo.EmergencyContacts[0].SequenceNumber = 2;
      this.studentInfo.EmergencyContacts[1].SequenceNumber = 1;
      this.studentInfo.EmergencyContacts = this.studentInfo.EmergencyContacts.reverse();
      this.processData();
    }
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.updatedStudentInfo.EmergencyContacts = this.studentInfo.EmergencyContacts;
      this.updatedStudentInfo.FirstName = this.mainForm.get('firstName').value;
      this.updatedStudentInfo.LastName = this.mainForm.get('lastName').value;
      this.updatedStudentInfo.MiddleName = this.mainForm.get('middleInitial').value;
      this.updatedStudentInfo.PrefixID = this.setPrefixId;
      this.updatedStudentInfo.Suffix = this.mainForm.get('suffix').value;
      this.updatedStudentInfo.DOB = transformDateTimeToDateOnly(this.mainForm.get('dob').value);
      this.updatedStudentInfo.Gender = this.mainForm.get('gender').value;
      if (this.setMilitaryId === 0) {
        this.updatedStudentInfo.MilitaryID = null;
      } else {
        this.updatedStudentInfo.MilitaryID = this.setMilitaryId;
      }
      this.updatedStudentInfo.EducationID = this.setEducationId;
      this.updatedStudentInfo.LanguageID = this.setLanguageId;
      this.updatedStudentInfo.EthnicityID = this.setEthnicityId;
      this.updatedStudentInfo.PrimaryCountryID = this.setCountryId;
      this.updatedStudentInfo.PrimaryStateProvinceID = this.setStateId;
      this.updatedStudentInfo.PrimaryAddress1 = this.mainForm.get('streetAddress').value;
      this.updatedStudentInfo.PrimaryAddress2 = this.mainForm.get('streetAddress2').value;
      this.updatedStudentInfo.PrimaryCity = this.mainForm.get('city').value;
      this.updatedStudentInfo.PrimaryZip = this.mainForm.get('zip').value;
      this.updatedStudentInfo.PrimaryZipPlus4 = this.mainForm.get('zip4').value;

      this.updatedStudentInfo.PrimaryPhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
      this.updatedStudentInfo.SecondaryPhoneNumber = this.mainForm.get('secondaryPhoneNumber').value;
      this.updatedStudentInfo.ThirdPhoneNumber = this.mainForm.get('thirdPhoneNumber').value;

      this.updatedStudentInfo.SMSOptIn = this.mainForm.get('SMSOptIn').value;
      if (this.mainForm.get('primaryEmail').value === '') {
        this.updatedStudentInfo.PrimaryEmailAddress = null;
      } else {
        this.updatedStudentInfo.PrimaryEmailAddress = this.mainForm.get('primaryEmail').value;
      }

      if (this.mainForm.get('secondaryEmail').value === '') {
        this.updatedStudentInfo.SecondaryEmailAddress = null;
      } else {
        this.updatedStudentInfo.SecondaryEmailAddress = this.mainForm.get('secondaryEmail').value;
      }

      this.updatedStudentInfo.PrimaryEmailOptOut = this.mainForm.get('primaryEmailOptIn').value !== 'false';

      this.updatedStudentInfo.SecondaryEmailOptOut = this.mainForm.get('secondaryEmailOptIn').value !== 'false';


      this.updatedStudentInfo.CitizenshipCountryID = this.setCitizenshipId;
      this.updatedStudentInfo.ShirtSizeID = this.setShirtSizeId;
      this.updatedStudentInfo.MarriageStatusID = this.setMarriageStatusId;
      if (this.setMilitaryStatusId === 0) {
        this.updatedStudentInfo.MilitaryStatusID = null;
      } else {
        this.updatedStudentInfo.MilitaryStatusID = this.setMilitaryStatusId;
      }
      this.updatedStudentInfo.SchoolAttended = this.mainForm.get('schoolAttended').value;
      this.updatedStudentInfo.RaceIDList = this.selectedRaces;
      if (this.mainForm.get('voter').value === 'true') {
        this.updatedStudentInfo.RegisteredVoter = true;
      } else if (this.mainForm.get('voter').value === 'false') {
        this.updatedStudentInfo.RegisteredVoter = false;
      }
      this.updatedStudentInfo.LanguageIDList = this.selectedLanguages;
      this.updatedStudentInfo.CustomAttributes = this.studentInfo.CustomAttributes;
      if (this._addHelmetsToHardhatsCustomField()) {
        // save the Helmets to Hardhats attribute, or add, as necessary
        if (this.updatedStudentInfo.CustomAttributes && this.updatedStudentInfo.CustomAttributes.length > 0) {
          if (!this.updatedStudentInfo.CustomAttributes.find(x => x.FieldName === 'Helmets To Hardhats')) {
            this.updatedStudentInfo.CustomAttributes.push({
              OrganizationID: this.context.RootTrainingOrgID,
              FieldName: 'Helmets To Hardhats',
              DataTypeEnum: 'YESNO',
              Value: 'No'
            });
          }
        } else {
          this.updatedStudentInfo.CustomAttributes = [{
            OrganizationID: this.context.RootTrainingOrgID,
            FieldName: 'Helmets To Hardhats',
            DataTypeEnum: 'YESNO',
            Value: 'No'
          }];
        }
      }
      this.saveForm();
    }
  }

  saveForm(): void {
    this.studentProfileService.updateStudentProfile(this.PersonID, this.updatedStudentInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.ref.destroy();
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Student has been updated'
          });
          this.studentInfo.EmergencyContacts.forEach(x => {
            x.hideDelete = false;
          });
          this.confirmationService.close();
          this.isSaving = false;
          this.mainForm.markAsPristine();
          this.existingMessage = false;
        }, error: () => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          this.isSaving = false;
        }
      });
  }

  updateCustomAttribute(index: number, event): void {
    this.mainForm.markAsDirty();
    this.updatedStudentInfo.CustomAttributes[index] = event;
    // reassign to update fields
    const stringReplace = '{"ID":null,"Description":"--","Abbreviation":""},';
    const newCusApp: string = JSON.stringify(this.updatedStudentInfo.CustomAttributes).replaceAll(stringReplace, '');
    this.updatedStudentInfo.CustomAttributes = [];
    this.updatedStudentInfo.CustomAttributes = JSON.parse(newCusApp);
    if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
      this.existingMessage = true;
      this.openDialog();
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  mobileCheckValidation(phone): void {
    this.phoneValidationUtility.phoneValidation(phone).then((res) => {
      this.updatedStudentInfo.SecondaryPhoneVerified = res;
      this.mainForm.markAsDirty();
    });
  }

  validatePii(key: string, value: string): void {
    this.isSearching = true;
    this.isEditing = false;
    const piiData: PiiValidateModel = {
      SSN: null,
      SIN: null,
      PrimaryEmailAddress: null,
      PersonID: this.PersonID,
      MatchType: key
    };
    piiData[key] = value;
    this.piiValidateService.validatePii(piiData)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.isSearching = false;
          if (res.MatchType) {
            const ref = this.dialogService.open(PiiDuplicateDialogComponent, {
              data: {
                piiData: res,
                value
              },
              header: 'Duplicate User(s) Found',
              width: '60%',
            });
            ref.onClose.subscribe({
              next: () => {
                console.log(key);
                switch (key) {
                  case 'SSN' : {
                    this.mainForm.get('SSN').setValue(null);
                    break;
                  }
                  case 'SIN' : {
                    this.mainForm.get('SIN').setValue(null);
                    break;
                  }
                  case 'PrimaryEmailAddress' : {
                    this.mainForm.get('primaryEmail').setValue(null);
                    break;
                  }
                }
              }
            });
          }
        }
      });
  }
}
