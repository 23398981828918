import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {ProgramStudentTimeCollectionsService} from '../../../../services/program-student-time-collection.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PeriodStatusCount, ProgramStatusTimeCollectionApprove, ProgramStudentFacetTypesListModel, ProgramStudentTimeCollectionByCategory, ProgramStudentTimeCollectionReject, ProgramStudentTimeCollectionStudentPeriodModel, ProgramStudentTimeCollectionUpdateStatus,} from '../../../../models/program-student-time-collection.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TimeRejectDialogComponent} from './time-reject-dialog/time-reject-dialog.component';
import {DialogService} from 'primeng/dynamicdialog';
import {Accordion} from 'primeng/accordion';
import {Router} from '@angular/router';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {transformDateTimeToDateOnly} from '../../../utilities/form.utility';
import {hideApprovedHoursReset} from '../../../utilities/component-manager-utility';

@Component({
  selector: 'app-time-new-entry',
  templateUrl: './time-new-entry.component.html',
  styleUrls: ['./time-new-entry.component.scss']
})

export class TimeNewEntryComponent implements OnInit, OnDestroy, OnChanges {
  mainForm: FormGroup;
  entries: ProgramStudentTimeCollectionStudentPeriodModel[];
  facetEntries: ProgramStudentTimeCollectionByCategory[];
  selFacetType: ProgramStudentFacetTypesListModel;
  programId: number;
  inputObjPrograms: GenericSelectModel;
  setProgramId: number;
  inputObjFacetType: GenericSelectModel;
  setFacetTypeId: number;
  inputObjEmployer: GenericSelectModel;
  setEmployerId: number;
  inputObjPeriod: GenericSelectModel;
  setPeriodId: number;
  setStartDate: string;
  setStartDateDescription: string;
  programStudentId: number;
  facetGroups: string[] = [];
  formReady: boolean;
  @Input() personId: number;
  setOrgId: number = 0;
  statusUpdate = {} as ProgramStudentTimeCollectionUpdateStatus;
  rejectedData = {} as ProgramStudentTimeCollectionReject;
  approvedData = {} as ProgramStatusTimeCollectionApprove;
  statusCount: PeriodStatusCount[] = [];
  hasEntries: boolean = false;
  @Input() selectedTab: string;
  @Input() canTabWrite: boolean;
  @Input() studentFacing: boolean;
  @Input() draftProgramId: number;
  @Input() draftFacetTypeId: number;
  @Input() draftStartDate: string;
  @Output() resetToDraft = new EventEmitter<any>();
  canEdit: boolean = true;
  @ViewChild('accordion') accordion: Accordion;
  accordionIndex: any[] = [0];
  isAdmin: boolean;
  totalTimeSubmitting: number = 0;
  inputValueChanged: boolean = false;
  isSaving: boolean = false;
  currentFilter = new PrimeTableFilterModel();
  getListRequest;
  getTotalRequest;
  periodTotal: number[] = [];
  public _hideApprovedHoursReset = hideApprovedHoursReset;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private programStudentTimeCollectionsService: ProgramStudentTimeCollectionsService,
              private dialogService: DialogService, private router: Router,
              private confirmationService: ConfirmationService) {
    this.mainForm = this.formBuilder.group({
      program: new FormControl(false),
      employer: new FormControl(null, Validators.required),
      manager: new FormControl(null),
      facet: new FormControl(true),
      period: new FormControl({value: null, disabled: true}),
      hours: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.isAdmin = !this.router.url.includes('my-time');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTab) {
      this.formReady = false;
      this.hasEntries = false;
      this.canEdit = !(this.selectedTab === 'APPROVED' || this.selectedTab === 'SUBMITTED');
      this.entries = [];
      this.facetEntries = [];
      this.initPrograms(false);
      this.accordionIndex = [0];
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  // getExistingPrograms(): void {
  //   this.currentFilter.rows = null;
  //   this.currentFilter.sortOrder = 1;
  //   this.currentFilter.first = 0;
  //   this.currentFilter.filters = {};
  //   if (this.getListRequest) {
  //     this.getListRequest.unsubscribe();
  //   }
  //   this.getListRequest = this.programStudentTimeCollectionsService.getProgramStudentTimeCollectionStudentPeriodsPrimeNG('DATA', this.personId, this.currentFilter)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe({next:(res) => {
  //       this.entries = res.Data;
  //     }});
  // }

  getExistingTimeEntries(): void {
    this.facetGroups = [];
    this.programStudentTimeCollectionsService.getProgramStudentTimeCollectionByFacet(this.programStudentId, this.setFacetTypeId, this.setStartDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.facetEntries = res;
          this.hasEntries = false;
          this.totalTimeSubmitting = 0;
          this.periodTotal.length = 0;
          let i: number = 0;
          let pTotal: number = 0;
          this.facetEntries.forEach(parentEntry => {
            i++;
            if (parentEntry.ProgramStudentFacets.length > 0) {
              this.hasEntries = true;
            }
            parentEntry.ProgramStudentFacets.forEach(entry => {
              this.totalTimeSubmitting = this.totalTimeSubmitting + entry.Hours;
              pTotal += entry.Hours;
              if (!this.facetGroups.includes(entry.FacetDescription)) {
                this.facetGroups.push(entry.FacetDescription);
              }
            });
            this.periodTotal.push(pTotal);
          });
        }
      });
  }

  initPrograms(disable: boolean): void {
    this.inputObjPrograms = {
      labelText: 'Program',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: (!this.draftProgramId),
      initSelected: this.draftProgramId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getProgramsByPersonLookupData(this.personId, true).then((lookupData) => {
      this.inputObjPrograms.data = lookupData;
      this.inputObjPrograms = Object.assign({}, this.inputObjPrograms);
    });
  }

  getProgramData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setProgramId = event[0].ID;
    } else {
      this.setProgramId = 0;
    }
    this.formReady = false;
    this.setFacetTypeId = 0;
    this.setPeriodId = null;
    this.setStartDate = '';
    if (event[0]) {
      this.setOrgId = event[0].MembershipOrganizationID;
    }
    this.mainForm.get('program').setValue(this.setProgramId);
    this.programStudentId = this.setProgramId;
    this.initFacetTypes(false);
    this.getPeriodStatusCount();
  }

  getPeriodStatusCount(): void {
    this.programStudentTimeCollectionsService.getPeriodStatusCounts(this.programStudentId, true)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.statusCount = res;
          this.statusCount = this.statusCount.filter(x => x.Description === this.selectedTab);
        }
      });
  }

  initFacetTypes(disable: boolean): void {
    this.inputObjFacetType = {
      labelText: 'Facet Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: (!this.draftFacetTypeId),
      initSelected: this.draftFacetTypeId,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true
    };
    if (this.programStudentId) {
      this.lookupsDataService.getFacetTypesByProgramStudentLookupData(this.programStudentId, this.studentFacing, true).then((lookupData) => {
        this.inputObjFacetType.data = lookupData;
        this.inputObjFacetType = Object.assign({}, this.inputObjFacetType);

      });
    }
  }

  getFacetTypesData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setFacetTypeId = event[0].ID;
    } else {
      this.setFacetTypeId = 0;
    }
    this.mainForm.get('facet').setValue(this.setFacetTypeId);
    this.mainForm.markAsDirty();
    this.selFacetType = event[0];
    this.initPeriod(false);
  }

  initPeriod(disable: boolean): void {
    this.inputObjPeriod = {
      labelText: 'Period',
      optionValue: 'StartDate_D',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: (!this.draftStartDate),
      initSelected: this.draftStartDate,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'Period'
    };
    if (this.programStudentId && this.setFacetTypeId) {
      this.lookupsDataService.getPeriodIntervalLookupData(this.programStudentId, this.setFacetTypeId, true).then((lookupData) => {
        this.inputObjPeriod.data = lookupData;
        if (this.selectedTab === 'DRAFT') {
          this.inputObjPeriod.data = this.inputObjPeriod.data.filter(x => x.StatusEnumerator === this.selectedTab || x.StatusEnumerator === 'EMPTY');
        } else {
          this.inputObjPeriod.data = this.inputObjPeriod.data.filter(x => x.StatusEnumerator === this.selectedTab);
        }
        this.inputObjPeriod = Object.assign({}, this.inputObjPeriod);
      });
    }
  }

  getPeriodData(event: any): void {
    this.setPeriodId = null;
    this.setStartDate = null;
    this.setStartDateDescription = '';
    if (event && event[0]) {
      this.setPeriodId = event[0].ID;
      this.setStartDate = (event[0].StartDate_D !== null) ? event[0].StartDate_D : 0;
      this.setStartDateDescription = event[0].Description;
    } else {
      this.setPeriodId = null;
      this.setStartDate = '';
      this.setStartDateDescription = 'this period';
    }
    this.formReady = true;
    this.accordionIndex = [0];
    if (this.setStartDate) {
      this.getExistingTimeEntries();
    } else {
      this.entries = [];
      this.facetEntries = [];
      this.hasEntries = false;
    }
  }

  updateData(): void {
    this.getExistingTimeEntries();
  }

  valueChanged(value): void {
    if (value !== this.inputValueChanged) {
      this.inputValueChanged = value;
    }
  }

  processData(): void {
    this.statusUpdate.PeriodStartDate_D = transformDateTimeToDateOnly(this.setStartDate);
    this.statusUpdate.StatusEnumerator = 'SUBMITTED';
    this.statusUpdate.ProgramStudentID = this.programStudentId;
    this.statusUpdate.FacetTypeID = this.setFacetTypeId;
    switch (this.selectedTab) {
      case 'DRAFT' :
      case 'EMPTY' :
      case 'REJECTED' : {
        this.statusUpdate.PeriodStartDate_D = transformDateTimeToDateOnly(this.setStartDate);
        this.statusUpdate.StatusEnumerator = 'SUBMITTED';
        this.statusUpdate.ProgramStudentID = this.programStudentId;
        this.statusUpdate.FacetTypeID = this.setFacetTypeId;
        this.submitForApproval();
      }
        break;
      case 'SUBMITTED' : {
        this.approvedData.StatusEnumerator = 'APPROVED';
        this.approvedData.FacetTypeID = this.setFacetTypeId;
        this.approvedData.ProgramStudentID = this.programStudentId;
        this.approvedData.PeriodStartDate_D = transformDateTimeToDateOnly(this.setStartDate);
        this.approveEntries();
      }
        break;
    }
  }

  reject(): void {
    this.rejectedData.PeriodStartDate_D = transformDateTimeToDateOnly(this.setStartDate);
    this.rejectedData.ProgramStudentID = this.programStudentId;
    this.rejectedData.StatusEnumerator = 'REJECTED';
    this.rejectedData.FacetTypeID = this.setFacetTypeId;
    const ref = this.dialogService.open(TimeRejectDialogComponent, {
      data: {
        rejectedData: this.rejectedData,
        isEdit: true,
        isDialog: true
      },
      header: 'Reject Submission',
      width: '70%',
      height: '400px'
    });
    ref.onDestroy.subscribe({
      next: () => {
        this.formReady = false;
        this.initPeriod(false);
      }
    });
    // this.initPeriod(false);
  }

  submitForApproval(): void {
    this.programStudentTimeCollectionsService.updateProgramStudentTimeCollectionStatus(this.statusUpdate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your program saved successfully'});
          this.facetGroups = [];
          setTimeout(() => {
            this.initPrograms(false);
          }, 500);
          this.hasEntries = false;
        }, error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          this.getExistingTimeEntries();
        }
      });
  }

  approveEntries(): void {
    this.programStudentTimeCollectionsService.approveProgramStudentTimeCollection(this.approvedData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Approval was sent'
          });
          this.getExistingTimeEntries();
          this.initPrograms(false);
          this.hasEntries = false;
        }, error:
          (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            console.warn(e);
          }
      });
  }

  closeAllAccordionTabs(): void {
    if (this.accordion) {
      this.accordionIndex = [];
    }
  }

  openAllAccordionTabs(): void {
    this.accordionIndex = [];
    this.accordion.tabs.forEach(tab => {
      this.accordionIndex.push(tab.findTabIndex());
    });
  }

  resetToEditEmit(): void {
    this.confirmationService.confirm({
      message: 'You are about to reset the entire period back to \'New Entry\' status so that hours can be added, updated, or removed. All changes will need to be re-submitted and approved. Are you sure this is what you want to do?',
      header: 'Reset Period as \'New Entry\'',
      accept: () => {
        this.programStudentTimeCollectionsService.resetProgramStudentPeriod(this.programStudentId, this.setPeriodId, 'DRAFT')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.resetToDraft.emit({
                draftProgramId: this.setProgramId,
                draftFacetTypeId: this.setFacetTypeId,
                draftStartDate: this.setStartDate
              });
            }, error:
              (e) => {
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
                console.warn(e);
              }
          });
      }
    });
  }
}
