import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(phoneNum: number | string): string {
    const phoneNumString = (phoneNum !== null && phoneNum.toString() !== 'null') ? phoneNum.toString() : '';
    if (phoneNum && phoneNumString.length === 10 ) {
      const areaCode = phoneNumString.slice(0, 3);
      const firstThree = phoneNumString.slice(3, 6);
      const lastFour = phoneNumString.slice(6, 10);
      return `(${areaCode}) ${firstThree}-${lastFour}`;
    } else {
      return (phoneNum !== null && phoneNum.toString() !== 'null') ? phoneNum.toString() : '';
    }
  }
}
