import {Injectable} from '@angular/core';
import {TenantConfigDataService} from '../../services/tenant-config-data.service';
import {activityDrivenMembers} from '../utilities/component-manager-utility';

@Injectable()
export class RosterDefinitions {
  rosterLiteCols: any[];
  rosterCols: any[];
  public _activityDrivenMembers = activityDrivenMembers;

  constructor(private tenantConfigDataService: TenantConfigDataService) {
    const SECUREDOB: boolean = this.tenantConfigDataService.getBooleanValue('SECUREDOB');
    const SECUREDOBField = (SECUREDOB === true) ? 'DOBSecure_D' : 'DOB_D';
    const STATEMENTBILLING: boolean = this.tenantConfigDataService.getBooleanValue('STATEMENTBILLING');
    const STRINGREPLACELOCAL: string = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    const STRINGREPLACEOCCUPATION: string = this.tenantConfigDataService.getStringValue('STRINGREPLACEOCCUPATION');
    const STRINGREPLACECLASSIFICATION: string = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
    const STRINGREPLACETYPE: string = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');
    this.rosterLiteCols = [
      {field: 'LastName', header: 'Last', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'FirstName', header: 'First', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'MiddleName', header: 'Middle', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', visible: false, displayType: 'text'},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'StatusAlias', header: 'Status', fullHeader: 'Status', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'TypeAlias', header: STRINGREPLACETYPE, fullHeader: STRINGREPLACETYPE, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'LastActivity', header: 'Last Activity', fullHeader: 'Last Activity', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'LastActivityDate_D', header: 'Last Activity Date', fullHeader: 'Last Activity Date', columnType: 'date', matchMode: 'gte', visible: true, displayType: 'date'},
      {field: 'PaidThroughDate', header: 'Paid Through Date', fullHeader: 'Paid Through Date', columnType: 'date', matchMode: 'gte', visible: true, displayType: 'date'},
      {field: 'Positions', header: 'Positions', fullHeader: 'Positions', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'SecondaryPhoneNumber', header: 'Mobile Phone #', fullHeader: 'Mobile Phone #', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'phone'},
      {field: 'PrimaryEmailAddress', header: 'Primary E-mail', fullHeader: 'Primary E-mail', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'}
    ];
    this.rosterCols = [
      {field: 'ThumbnailUrl', header: 'Profile', fullHeader: 'Profile', columnType: 'text', matchMode: 'contains', visible: true},
      {field: 'Prefix', header: 'Prefix', fullHeader: 'Prefix', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'LastName', header: 'Last', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'MiddleName', header: 'Middle', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Suffix', header: 'Suffix', fullHeader: 'Suffix', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Nickname', header: 'Nickname', fullHeader: 'Nickname', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Gender', header: 'Gender', fullHeader: 'Gender', columnType: 'text', matchMode: 'contains', visible: false},
      {field: SECUREDOBField, header: 'Birth Date', fullHeader: 'Birth Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: true},
      {field: 'SSN', header: 'SSN', fullHeader: 'SSN', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Ethnicity', header: 'Ethnicity', fullHeader: 'Ethnicity', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Race', header: 'Race', fullHeader: 'Race', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'MilitaryStatus', header: 'Military Status', fullHeader: 'Military Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'picker', displayType: 'text', visible: true},
      {field: 'UTStatusDescription', header: 'Launch Status', fullHeader: 'Launch Status', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'StatusAlias', header: 'Status', fullHeader: 'Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'TypeAlias', header: STRINGREPLACETYPE, fullHeader: STRINGREPLACETYPE, columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'EffectiveDate_D', header: 'Effective Date', fullHeader: 'Effective Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: false},
      {field: 'Classification', header: STRINGREPLACECLASSIFICATION, fullHeader: STRINGREPLACECLASSIFICATION, columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'BargainingUnitDescription', header: 'Bargaining Unit', fullHeader: 'Bargaining Unit', columnType: 'picker', matchMode: 'equals', visible: false},
      {field: 'MemberStartDate_D', header: 'Initiation Date', fullHeader: 'Initiation Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: false},
      {field: 'MemberRetirementDate_D', header: 'Retirement Date', fullHeader: 'Retirement Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: false}
    ];
    if (this._activityDrivenMembers() === true) {
      this.rosterCols.push(
        {field: 'LastActivity', header: 'Last Activity', fullHeader: 'Last Activity', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
        {field: 'LastActivityDate_D', header: 'Last Activity Date', fullHeader: 'Last Activity Date', columnType: 'date', matchMode: 'gte', displayType: 'date', visible: true}
      );
    }
    if (STATEMENTBILLING === true) {
      this.rosterCols.push(
        {field: 'PaidThroughDate_D', header: 'Paid Through Date', fullHeader: 'Paid Through Date', columnType: 'date', matchMode: 'gte', displayType: 'date', visible: true}
      );
    }
    this.rosterCols.push(
      {field: 'Positions', header: 'Positions', fullHeader: 'Positions', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryOfficer', header: 'Primary Officer', fullHeader: 'Primary Officer', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'EmployerName', header: 'Employer', fullHeader: 'Employer', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'EmploymentHireDate_D', header: 'Hire Date', fullHeader: 'Hire Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: false},
      {field: 'EmploymentNumber', header: 'EIN', fullHeader: 'EIN', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'WorkSiteName', header: 'Worksite', fullHeader: 'Worksite', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'ContractDescription', header: 'Contract', fullHeader: 'Contract', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Occupation', header: STRINGREPLACEOCCUPATION, fullHeader: STRINGREPLACEOCCUPATION, columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Shift', header: 'Shift', fullHeader: 'Shift', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'BillingStatusAlias', header: 'Billing Process', fullHeader: 'Billing Process', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'AccountBalance_N', header: 'Balance', fullHeader: 'Balance', columnType: 'numeric', matchMode: 'gte', displayType: 'currency', visible: true},
      {field: 'LastPayment_D', header: 'Dues Last Paid', fullHeader: 'Dues Last Paid', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: false},
      {field: 'PaymentType', header: 'Dues Payment Election', fullHeader: 'Dues Payment Election', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'AutoPay', header: 'Auto Pay', fullHeader: 'Auto Pay', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'User Account', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PrimaryAddress1', header: 'Street Address', fullHeader: 'Street Address', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryAddress2', header: 'Ste, Bldg, Flr, etc.', fullHeader: 'Ste, Bldg, Flr, etc.', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryCity', header: 'City/Town', fullHeader: 'City/Town', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryStateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryZip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryCounty', header: 'County', fullHeader: 'County', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryCountry', header: 'Country', fullHeader: 'Country', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryFailedCommunication', header: 'Undeliverable', fullHeader: 'Undeliverable', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryPhoneNumber', header: 'Home Phone #', fullHeader: 'Home Phone #', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'SecondaryPhoneNumber', header: 'Mobile Phone #', fullHeader: 'Mobile Phone #', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'SMSOptOut', header: 'SMS Opt Out', fullHeader: 'SMS Opt Out', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryEmailAddress', header: 'Primary E-mail', fullHeader: 'Primary E-mail', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryEmailOptOut', header: 'Primary E-mail Opt Out', fullHeader: 'Primary E-mail Opt Out', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryEmailFailedCommunication', header: 'Primary E-mail Undeliverable', fullHeader: 'Primary E-mail Undeliverable', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'SecondaryEmailAddress', header: 'Secondary E-mail', fullHeader: 'Secondary E-mail', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'SecondaryEmailOptOut', header: 'Secondary E-mail Opt Out', fullHeader: 'Secondary E-mail Opt Out', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'SecondaryEmailFailedCommunication', header: 'Secondary E-mail Undeliverable', fullHeader: 'Secondary E-mail Undeliverable', columnType: 'text', matchMode: 'contains', visible: false}
    );
  }
}
