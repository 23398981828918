import {Component, EventEmitter, Input, isDevMode, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TextEditorModel} from '../../../models/text-editor.model';
import {MessageService} from 'primeng/api';
import {ContextService} from '../../../services/context.service';
import {ajax} from 'rxjs/ajax';

declare const gtag;

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})

export class TextEditorComponent implements OnChanges{
  @Input() html;
  @Input() height: number;
  @Input() settings: TextEditorModel;
  @Input() editorId: string;
  @Input() showPreview: boolean;
  @Output() updateContent = new EventEmitter<any>();
  activeSubTab: string = 'preview';
  editMode: boolean = false;
  config;
  showEditor: boolean = false;
  fileReader: FileReader = new FileReader();

  constructor(public messageService: MessageService, public contextService:ContextService) {
    // set a unique editor id if not provided, otherwise the editor may not load correctly
    if (!this.editorId) {
      this.editorId = (Math.floor(Math.random() * (Math.floor(100000) - Math.ceil(1) + 1)) + Math.ceil(1)).toString();
    }
  }

  ngOnChanges(changes:SimpleChanges): void {
    console.log(this.showPreview);
    if(changes.html && (this.html === null || this.html === '')) {
      this.activeSubTab = 'edit';
      this.editMode = true;
    }
    if (this.editorId && this.height) {
      this.config = {
        height: this.height,
        menubar: this.settings.menubar,
        readonly: this.settings.readonly,
        image_title: true,
        file_picker_types: 'image file',
        browser_spellcheck: true,
        contextmenu: false,
        automatic_uploads: true,
        invalid_elements: 'iframe',
        default_link_target: '_blank',
        convert_urls: false,
        convert_unsafe_embeds: true,
        plugins: [
          'advlist','autolink','lists','link','charmap','preview','anchor','pagebreak',
          'searchreplace','wordcount','visualblocks','visualchars','code',
          'insertdatetime','nonbreaking','save','table','directionality',
          'emoticons','image','codesample','help'
        ],
        toolbar: 'undo redo | insert | styles | fontfamily fontsize | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code',
        content_style: 'body { font-family: Arial; }',
        file_picker_callback (cb, value, meta) {
          const input = document.createElement('input');
          input.setAttribute('id', 'textEditorInput');
          input.setAttribute('type', 'file');
          // if image upload, only allow images to be selected
          if (meta && meta.filetype === 'image') {
            input.setAttribute('accept', 'image/*');
          }
          input.onchange = () => {
            let fd;
            const file = input.files[0];
            let form;
            const max = 4000;
            const mb = file.size / 1000;
            const reader = new FileReader();
            const sessionVars = JSON.parse(sessionStorage.getItem('contextObject'));
            const ut_token = sessionVars.accessToken;
            const urlBase = sessionVars.apiBaseUrlV1 + '/';
            console.log(urlBase);

            let url: string;
            console.log('firing on change');
            if (reader !== null && reader !== undefined) {
              console.log('in reader if');
              reader.readAsDataURL(file);
              reader.onload = () => {
                form = document.createElement('form');
                fd = new FormData(form);
                if (file !== null && file !== undefined) {
                  console.log('in file if');
                  switch (true) {
                    case mb <= max && mb > 0:
                      if (file) {
                        url = `${urlBase}api/1.0/resource/upload/public`;
                        fd.append('textEditorInput', file, file.name);
                        ajax.post(
                          url,
                          fd, {
                            Authorization: 'bearer ' + ut_token,
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
                          }
                        ).subscribe(res => {
                          if (res && res.response[0]) {
                            switch (meta.filetype) {
                              case 'image':
                                cb(res.response[0].Url, {alt: res.response[0].FileName, title: res.response[0].FileName});
                                break;
                              default:
                                cb(res.response[0].Url, {text: res.response[0].FileName, title: res.response[0].FileName});
                                break;
                            }
                          } else {
                            cb('', {title: ''});
                          }
                        }, error => {
                          console.error(error);
                        });

                      }
                      break;
                    case mb > 0:
                      cb('', { title: 'The file you selected is too large (' + mb + ' MB). Files must be ' + max + ' MB or less. Please try again.' });
                      //UTUtilities.dom.openDialog(null, "File Too Large", "The image you selected is too large (" + mb + " MB). Images must be " + UTUtilities.get.thumbNailUploadMaxSize() + " MB or less. Please try again.", null, null, null, null, null, null);
                      break;
                    default:
                      cb('', { title: 'No file was included. Please try again.' });
                      //UTUtilities.dom.openDialog(null, "Please Try Again", "No image was included. Please try again.", null, null, null, null, null, null);
                      break;
                  }
                } else {
                  cb('', { title: 'No file was included. Please try again.' });
                  //UTUtilities.dom.openDialog(null, "Please Try Again", "No image was included. Please try again.", null, null, null, null, null, null);
                }
              };
            }
          };
          input.click();
        }
      };
      this.showEditor = true;
    }
  }

  updateText() {
    this.updateContent.emit(this.html);
  }

  trackMe() {
    if(!isDevMode() && sessionStorage.getItem('gaTag') !== 'null') {
      gtag('event', 'TinyMCE_Loaded', {
        event_category: 'BUTTON_CLICK',
        event_label: 'Load Tiny',
        value: 'TinyMCE was loaded'
      });
    }
  }

  // //angular way... not being used
  // public filePickerCallback(cb, value, meta) {
  //   const url: string = this._urlBase;
  //   const fileElement: HTMLElement = document.getElementById('file') as HTMLElement;
  //   fileElement.click();
  //   console.log('in here');
  //  }
// //angular way... not being used
//   handleFileInput(event)  {
//     let fd;
//     this.selectedFile = event.target.files[0];
//     let form;
//     const maxThumbnail = 4000;
//     const maxUpload = 30000;
//     const mb = this.selectedFile.size / 1000;
//     this.fileReader.readAsDataURL(this.selectedFile);
//     const urlField = this.elem.nativeElement.querySelectorAll('.tox-textfield');
//     const tinyForm = document.getElementById('myTiny');
//     this.fileReader.onload = (e) => {
//       form = document.createElement('form');
//       fd = new FormData(form);
//       if (this.selectedFile !== null && this.selectedFile !== undefined) {
//         fd.append(event.target, this.selectedFile);
//         switch (true) {
//           case mb <= maxThumbnail && mb > 0:
//             if (this.selectedFile) {
//               if(this.settings.dir === 'secure') {
//                 this.resourceService.uploadResource(fd, this.settings.expDays.toString(), this.settings.expHours.toString())
//                   .pipe(takeUntil(this.ngUnsubscribe))
//                   .subscribe(res => {
//                     this.messageService.add({
//                       severity: 'success', summary: 'Success',
//                       detail: 'File added'
//                     });
//                   }, error => {
//                     this.messageService.add({
//                       severity: 'error', summary: 'Error',
//                       detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
//                     });
//                   });
//               }
//             }
//         }
//       }
//     };
//   }
//
  // uploadResource() {
  //
  // }
  //
  // ngOnDestroy(): void {
  //   this.ngUnsubscribe.next();
  //   this.ngUnsubscribe.complete();
  // }
}
