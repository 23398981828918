import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import {
  OrganizeProjectContractorsGridModel,
  OrganizeProjectContractorsModel,
  ProjectReportsGridModel,
  ProjectReportsModel
} from '../models/project-reports.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectReportsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getProjectReportsPrimeNG(dataMode: string, filterData): Observable<ProjectReportsGridModel> {
    return this.http.post<ProjectReportsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/grid?mode=${dataMode}`, filterData);
  }

  exportProtectReports(filterData ): Observable<ArrayBuffer> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/export`, filterData, requestOptions);
  }

  getProtectReport(organizeProjectId: number): Observable<ProjectReportsModel> {
    return this.http.get<ProjectReportsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/${organizeProjectId}`);
  }

  createProtectReportsTotal(projectData: ProjectReportsModel): Observable<ProjectReportsModel> {
    return this.http.post<ProjectReportsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/create`, projectData);
  }

  updateProtectReportsTotal(organizationProjectId: number, projectData: ProjectReportsModel): Observable<ProjectReportsModel> {
    return this.http.put<ProjectReportsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/${organizationProjectId}`, projectData);
  }

  deleteProjectReport(projectId): Observable<ProjectReportsModel> {
    return this.http.delete<ProjectReportsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/remove/${projectId}`);
  }

  getOrganizeProjectOrganizationID(projectOrganizationId: number): Observable<ProjectReportsModel> {
    return this.http.get<ProjectReportsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/organization//${projectOrganizationId}`);
  }

  getOrganizeProjectContractorsGrid(organizeProjectId: number, filterData): Observable<OrganizeProjectContractorsGridModel> {
    return this.http.post<OrganizeProjectContractorsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/${organizeProjectId}/organization/grid?mode=ALL`, filterData);
  }

  getOrganizeProjectContractors(organizeProjectId: number): Observable<OrganizeProjectContractorsModel[]> {
    return this.http.get<OrganizeProjectContractorsModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/organization/byproject/${organizeProjectId}`);
  }

  updateOrganizeProjectContractors(organizeProjectId: number, modelData: OrganizeProjectContractorsModel[]): Observable<OrganizeProjectContractorsModel[]> {
    return this.http.put<OrganizeProjectContractorsModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organizeproject/organization/byproject/${organizeProjectId}`, modelData);
  }



}
