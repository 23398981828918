import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DialogService} from 'primeng/dynamicdialog';
import {OrganizationModel} from '../../models/organizations.model';
import {MapsDialogComponent} from '../../shared/components/maps-dialog/maps-dialog.component';
import {RouteContextUtility} from '../../shared/utilities/route-context.utility';

@Component({
  selector: 'app-employer-action-buttons',
  templateUrl: './employers-action-buttons.component.html'
})
export class EmployerActionButtonsComponent implements OnInit, OnDestroy {
  InterfaceObjectEnum: string = 'EMPPROFILE';
  routeContext: string;
  @Input() canPageWrite: boolean;
  @Input() employer: OrganizationModel;

  private ngUnsubscribe = new Subject();

  constructor(public dialogService: DialogService, private routeContextUtility: RouteContextUtility) {
  }

  ngOnInit(): void {
    this.routeContext = this.routeContextUtility.getRouteContext();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getLocation(data: OrganizationModel) {
    // const url = `https://www.google.com/maps/place/${data.Address1},+${data.City},+${data.StateProvince}+${data.Zip}`;
    // this.router.navigate([]).then(() => {  window.open(url, '_blank'); });
    const ref = this.dialogService.open(MapsDialogComponent, {
      data: {
        latitude: data.Latitude,
        longitude: data.Longitude,
        title: data.Name,
        street: data.Address1,
        city: data.City,
        state: data.StateProvince,
        zip: data.Zip
      },
      header: `Employer Location - ${data.Name}`,
      width: '90%',
      height: '70%'
    });
  }
}
