import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';
import {ApprenticeHoursFileModel, ApprenticeHoursGridModel, ApprenticeHoursImportGrid, ApprenticeHoursListModel, ApprenticeHoursSummary, HoursFileCreateModel, HoursFileDataRowModel} from '../models/apprenticeHours.model';

@Injectable({
  providedIn: 'root',
})
export class ApprenticeHoursService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getApprenticeHoursPrimeNG(dataMode: string, personId: number, filter): Observable<ApprenticeHoursGridModel> {
    return this.http.post<ApprenticeHoursGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/person/${personId}/hours/grid?mode=${dataMode}`, filter);
  }

  getApprenticeHoursDetail(personId: number, personHoursId: number): Observable<ApprenticeHoursListModel> {
    return this.http.get<ApprenticeHoursListModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/person/${personId}/hours/${personHoursId}`);
  }

  createApprenticeHoursDetail(personId: number, apprenticeHours: ApprenticeHoursListModel): Observable<ApprenticeHoursListModel> {
    return this.http.post<ApprenticeHoursListModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/person/${personId}/hours/create`, apprenticeHours);
  }

  getApprenticeHoursSummary(personId: number, personHoursId: number): Observable<ApprenticeHoursSummary> {
    return this.http.get<ApprenticeHoursSummary>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0//person/${personId}/hours/summary`);
  }

  updateApprenticeHoursDetail(personId: number, personHoursId: number, apprenticeHours: ApprenticeHoursListModel): Observable<ApprenticeHoursListModel> {
    return this.http.put<ApprenticeHoursListModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/person/${personId}/hours/${personHoursId}`, apprenticeHours);
  }

  deleteApprenticeHoursDetail(personId: number, personHoursId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/person/${personId}/hours/remove/${personHoursId}`);
  }

  exportApprenticeHours(personId: number, filter): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/person/${personId}/hours/export`, filter, requestOptions);
  }

  getApprenticeHoursImportPrimeNG(dataMode: string, routeOrganizationId: number, filter): Observable<ApprenticeHoursImportGrid> {
    return this.http.post<ApprenticeHoursImportGrid>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${routeOrganizationId}/personhoursfile/grid?mode=${dataMode}`, filter);
  }

  getApprenticeImportHours(id: number): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/billingAdjustmentType/${id}`);
  }

  uploadHoursFileData(organizationId: number, hoursFileId: number, formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/paymentfile/${hoursFileId}/upload`, formData);
  }

  deleteHoursFile(organizationId: number, personHoursFileId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/personhoursfile/${personHoursFileId}/remove`);
  }

  createHoursFile(organizationId: number, modelData: HoursFileCreateModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/personhoursfile/create`, modelData);
  }

  updateHoursFile(organizationId: number, personHoursFileId: number, modelData: HoursFileCreateModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/personhoursfile/${personHoursFileId}}`, modelData);
  }

  getHoursFileGridDetailPrimeNG(dataMode: string, organizationId: number, personHoursFileId: number, filterData): Observable<HoursFileDataRowModel> {
    return this.http.post<HoursFileDataRowModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/personhoursfile/${personHoursFileId}/grid?mode=${dataMode}`, filterData);
  }

  getHoursFile(organizationId: number, personHoursFileId: number): Observable<ApprenticeHoursFileModel> {
    return this.http.get<ApprenticeHoursFileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/personhoursfile/${personHoursFileId}`);
  }

  updateHoursFileRow(organizationId: number, personHoursFileRowId: number, modelData: HoursFileDataRowModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/personhoursfilerow/${personHoursFileRowId}`, modelData);
  }

  processHoursFile(organizationId: number, personHoursFileId: number): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/personhoursfile/${personHoursFileId}/process`, null);
  }
}
