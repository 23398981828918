import { Injectable } from '@angular/core';

@Injectable()
export class ExemptUsersDefinitions {
  public exemptUsers: any[];

  constructor() {

    this.exemptUsers = [
     // 'bradking@uniontrack.com',
      'tomgregory@uniontrack.com',
      'jamesbauer@uniontrack.com'
    ];
  }
}
