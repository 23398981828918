<form [formGroup]="mainForm">
  <div class="p-grid p-fluid">
    <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
      <p>*Please Note - The amount of data returned is directly related to the number of days included in the date range
        below. Thank you for your patience as we retrieve your change log.</p>
      <app-learn-more [interfaceObjectTypeEnum]="'APPSECTION'" [interfaceObjectDesc]="interfaceObjectDesc"
                      [parentObjectEnum]="parentObjectEnum"></app-learn-more>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
      <div class="p-grid p-fluid">
        <div class="col-md-2 col-sm-4 col-xs-12 p-mb-2">
          <label>Start Date <sup class="req">*</sup></label>
          <p-calendar dateMask placeholder="mm/dd/yyyy" formControlName="fcnStartDate" appendTo="body"
                      dateFormat="mm/dd/yy" [showIcon]="true" [maxDate]="mainForm.controls.fcnEndDate.value"
                      tabindex="0"></p-calendar>
          @if ((mainForm.controls.fcnStartDate.dirty || mainForm.controls.fcnStartDate.touched) &&
          mainForm.controls.fcnStartDate.errors?.required) {
            <span class="error">'Start Date' must be provided</span>
          }
        </div>
        <div class="col-md-2 col-sm-4 col-xs-12 p-mb-2">
          <label>End Date <sup class="req">*</sup></label>
          <p-calendar dateMask placeholder="mm/dd/yyyy" formControlName="fcnEndDate" appendTo="body"
                      dateFormat="mm/dd/yy" [showIcon]="true" [minDate]="mainForm.controls.fcnStartDate.value"
                      tabindex="0"></p-calendar>
          @if ((mainForm.controls.fcnEndDate.dirty || mainForm.controls.fcnEndDate.touched) &&
          mainForm.controls.fcnEndDate.errors?.required) {
            <span class="error">'End Date' must be provided</span>
          }
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
          <button pButton label="Synchronize" class="p-button-info" [className]="'inline-button'" pTooltip="Synchronize"
                  tooltipPosition="bottom"
                  (click)="synchronize()"></button>&nbsp;
          <button pButton label="This Week" class="p-button-info" [className]="'inline-button'" pTooltip="This Week"
                  tooltipPosition="bottom"
                  (click)="getDatesOfWeek()"></button>&nbsp;
          <button pButton label="This Month" class="p-button-info" [className]="'inline-button'" pTooltip="This Month"
                  tooltipPosition="bottom"
                  (click)="getDatesDuringMonth()"></button>&nbsp;
          <button pButton label="This Year" class="p-button-info" [className]="'inline-button'" pTooltip="This Year"
                  tooltipPosition="bottom"
                  (click)="getDatesDuringYear()"></button>&nbsp;&nbsp;
          <button pButton label="Run" class="p-button-info" [className]="'inline-button'" pTooltip="Run"
                  tooltipPosition="bottom"
                  (click)="runList()"
                  [disabled]="this.mainForm.invalid"></button>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      @if (showGrid) {
        <p-table
          #dt1
          [rows]="currentFilter.rows"
          [columns]="cols"
          dataKey="PersonID"
          [value]="changeLogsList"
          [lazy]="true"
          (onLazyLoad)="loadTable($event)"
          [paginator]="true"
          [showCurrentPageReport]="true"
          [metaKeySelection]="false"
          [totalRecords]="dataTotal"
          [rowsPerPageOptions]="[25,50,100,250,500]"
          [scrollable]="true"
          [(selection)]="selChangeLog"
          selectionMode="single"
          rowExpandMode="single"
          scrollHeight="400px"
          paginatorDropdownAppendTo="body"
          [loading]="!dataLoaded"
          styleClass="p-datatable-responsive-demo p-datatable-striped table-border"
          currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
        >
          <ng-template pTemplate="caption">
            <div class="p-grid p-fluid">
              <div class="col-md-12 grid-container-buttons-right">
                <div class="action-buttons">
                  <!--                <button *ngIf="hasFilter" pButton class="p-button-download export-menu" icon="pi pi-filter-slash"-->
                  <!--                        pTooltip="Clear Filter" tooltipPosition="bottom" [ngClass]="'filter-active margin-right-5'"-->
                  <!--                        (click)="clear(dt1)">-->
                  <!--                </button>-->
                  <!--                <button pButton (click)="refresh()" icon="pi pi-refresh" pTooltip="Refresh" tooltipPosition="bottom"></button>-->
                  <!--                &lt;!&ndash;          <button pButton *ngIf="canPageWrite" (click)="showAddEdit = true;" icon="pi pi-plus" pTooltip="Add" tooltipPosition="bottom">&ndash;&gt;-->
                  <!--                &lt;!&ndash;          </button>&ndash;&gt;-->
                  <!--                <button *ngIf="showExport && !isExporting" pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0" icon="fa fa-file-excel"-->
                  <!--                        pTooltip="Export" tooltipPosition="bottom" (click)="export()">-->
                  <!--                </button>-->
                  <!--                <button *ngIf="isExporting" pButton class="p-button-download export-menu p-pl-2" [disabled]="true" icon="pi pi-spin pi-spinner save-spinner">-->
                  <!--                </button>-->
                  <button pButton class="p-button-info p-mr-2" icon="pi pi-refresh" pTooltip="Refresh"
                          tooltipPosition="bottom"
                          (click)="refresh()"></button>
                  <button pButton icon="fa fa-file-excel"
                          class="p-button-info p-mr-2 export-excel" pTooltip="Export" tooltipPosition="bottom"
                          (click)="export()"></button>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th [className]="'expand-column'"></th>
              @for (col of columns; track col) {
                <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
                  @if (col.visible) {
                    <div class="header-text">
                      {{ col.header }}
                    </div>
                    <div class="header-actions">
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                      <p-columnFilter [field]="col.field" display="menu" [matchMode]="col.matchMode"
                                      [showOperator]="true" [showAddButton]="false"
                                      [type]="col.columnType"></p-columnFilter>
                    </div>
                  }
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
            <tr [pSelectableRow]="rowData" [ngClass]="{'selectedRow' : selChangeLog === rowData}">
              <td [className]="'expand-column'">
                <button type="button" pButton [pRowToggler]="rowData"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              </td>
              @for (col of columns; track col) {
                <td>
                  @if (col.visible && col.columnType !== 'date' && !col.field.includes('Phone')) {
                    {{ rowData[col.field] }}
                  }
                  @if (col.visible && col.columnType === 'date') {
                    {{ rowData[col.field] | date: 'shortDate' }}
                  }
                  @if (col.visible && col.field.includes('Phone')) {
                    {{ rowData[col.field] | phone }}
                  }
                </td>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-rowData>
            <tr>
              <td [colSpan]="cols.length + 1">
                <app-organizations-tab-roster-change-log-details [PersonID]="rowData.PersonID" [startDate]="startDate"
                                                                 [endDate]="endDate"
                                                                 class="no-padding-container"></app-organizations-tab-roster-change-log-details>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [colSpan]="cols?.length" style="width: 100%;">No change logs found.</td>
            </tr>
          </ng-template>
        </p-table>
      }
    </div>
  </div>
</form>
