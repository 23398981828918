
<div class="page-container">
  <div class="public-card">
    <p-card class="card">
      @if (logo) {
        <div class="align-center">
          <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true" />
        </div>
      }
      <div class="page-container">
        <form [formGroup]="prrForm" class="password-reset-request-form">
          <h2>Reset Password/Passphrase</h2>
          <p>Please provide your username and select an e-mail address. You will be sent an e-mail with a link to reset your password.</p>
          <div class="p-fieldset-content">
            <label>Username (E-mail)<span class="ut-requireddenotation"> *</span></label>
            <input type="email" id="email" pInputText formControlName="username">
            @if (prrForm.controls.username.invalid && prrForm.controls.username.touched) {
              <div class="p-error">
                @if (prrForm.controls.username.errors?.required) {
                  <div>Username is required</div>
                }
                @if (prrForm.controls.username?.errors) {
                  <div>Username must be a valid e-mail address</div>
                }
              </div>
            }
          </div>
          <div class="p-fieldset-content">
            <label>Send Reset Details To<span class="ut-requireddenotation"> *</span></label>
            <p-dropdown [options]="emails" formControlName="emailTarget" optionValue="EmailID" optionLabel="MaskedAddress" [showClear]="true" placeholder="--" class="select-dropdown"></p-dropdown>
            @if (prrForm.controls.emailTarget.invalid && prrForm.controls.emailTarget.touched) {
              <div class="p-error">
                @if (prrForm.controls.emailTarget.errors?.required) {
                  <div>A selection is required</div>
                }
              </div>
            }
          </div>
          <div class="align-center">
            @if (!isLoading) {
              <button pButton label="Request Reset" class="p-button-info p-mr-2" pTooltip="Request Reset" tooltipPosition="bottom"
              (click)="resetPassword()" [className]="'inline-button'" [disabled]="prrForm.invalid || !prrForm.touched"></button>
            } @else {
              <button pButton label="Request Reset" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
          <p><span class="link" [routerLink]="['/login']">&lt; Back to Login</span></p>
        </form>
      </div>
    </p-card>
    <div class="align-center">
      <button pButton type="button" class="signup-button" label="New User? - Sign Up" [routerLink]="['/public/user-registration']"></button>
    </div>
  </div>
  <p-toast [baseZIndex]="16000"></p-toast>
</div>
