@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form [formGroup]="mainForm">
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      @if (!isEdit) {
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjPosition" (emitData)="getPositionData($event)" ></app-select>
        </div>
      }
      @if (showFinancialContact) {
        <div class="col-md-9 col-sm-6 col-xs-12 input-container">
          <p-checkbox binary="true" label="Primary Financial Contact" formControlName="financialContact" class="p-mt-2" (onChange)="valueChanged()" tabindex="0"></p-checkbox><i class="fas fa-usd-circle"></i>
        </div>
      }
      @if (organizationCategory==='union') {
        <div class="col-md-4 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjPositionType" (emitData)="getPositionTypeData($event)" ></app-select>
        </div>
      }
      <div class="col-md-4 col-sm-6 col-xs-12 input-container">
        <label>Start Date <sup class="req">*</sup></label>
        <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="startDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [maxDate]="mainForm.controls.endDate.value" (onSelect)="valueChanged(); manageEndDate()" (onInput)="valueChanged()" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
        @if (endDateReq && (mainForm.controls.startDate.dirty || mainForm.controls.startDate.touched) &&
          mainForm.controls.startDate.errors?.required) {
          <span class="error">'Start Date' must be provided</span>
        }
        @if (showToPresent) {
          <p-checkbox binary="true" label="To Present?" formControlName="toPresent" class="p-mt-2" (onChange)="toggleDate()" tabindex="0"></p-checkbox>
        }
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 input-container">
        <label>End Date @if (this.endDateRequired) {
          <sup class="req">*</sup>
        }</label>
        <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="endDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [minDate]="mainForm.controls.startDate.value" (onSelect)="valueChanged(); togglePresent()" (onInput)="valueChanged()" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
        @if (endDateReq && (mainForm.controls.endDate.dirty || mainForm.controls.endDate.touched) &&
          mainForm.controls.endDate.errors?.required) {
          <span class="error">'End Date' must be provided</span>
        }
      </div>
      @if (!isEdit) {
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
          <span class="p-input-icon-left full-width">
            <i class="pi pi-search"></i>
            <input class="full-width filter" pInputText type="text" [(ngModel)]="activeGlobalFilter" (keyup.enter)="filterItems()"
              [ngModelOptions]="{standalone: true}" placeholder="Search Roster" />
            </span>
          </div>
        }
        @if (!isEdit && activeGlobalFilter) {
          <div class="col-md-12 col-sm-12 col-xs-12 input-container">
            <button pButton label="Get Roster" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-search" pTooltip="Get Roster" tooltipPosition="bottom"
            (click)="showTable=true; filterItems()"></button>
          </div>
        }
        @if (showTable && routeContext === 'MEMBERSHIP') {
          <div class="col-md-12 col-sm-12 col-xs-12 input-container">
            <p-table
              #dt1
              [rows]="pageRowCountUtility.pageRowCount()"
              [columns]="cols"
              dataKey="PersonID"
              [value]="rosterList"
              [lazy]="true"
              (onLazyLoad)="loadTable($event)"
              [paginator]="true"
              [showCurrentPageReport]="true"
              [metaKeySelection]="false"
              [totalRecords]="this.rosterTotal"
              [rowsPerPageOptions]="[25,50,100,250,500]"
              [scrollable]="true"
              [(selection)]="selectedRosterList"
              selectionMode="single"
              scrollHeight="400px"
              paginatorDropdownAppendTo="body"
              [loading]="loadingTable"
              styleClass="p-datatable-responsive-demo p-datatable-striped inner-table"
              currentPageReportTemplate="Showing {first} to {last} of {{this.rosterTotal | number}} entries"
              (selectionChange)="setNewPerson($event)"
              >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th style="width:3rem"></th>
                  @for (col of columns; track col) {
                    <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" style="width:50px">
                      <div class="header-text">
                      {{col.header}}</div>
                      <div class="header-actions">
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                        @if (col.columnType !== 'none') {
                          <p-columnFilter type="{{col.columnType}}" matchMode="{{col.matchMode}}" [field]="col.field" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        }
                      </div>
                    </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" >
                <tr>
                  <td style="width:3rem">
                    <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                  </td>
                  @for (col of columns; track col) {
                    <td style="width:50px">
                      <ng-container>
                        {{rowData[col.field]}}
                      </ng-container>
                    </td>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [colSpan]="cols?.length" style="width: 100%;">No people found</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }
        @if (showTable && routeContext === 'TRAINING') {
          <div class="col-md-12 col-sm-12 col-xs-12 input-container">
            <p-table
              #dt1
              [rows]="pageRowCountUtility.pageRowCount()"
              [columns]="cols"
              dataKey="PersonID"
              [value]="studentRosterList"
              [lazy]="true"
              (onLazyLoad)="loadTable($event)"
              [paginator]="true"
              [showCurrentPageReport]="true"
              [metaKeySelection]="false"
              [totalRecords]="this.studentRosterTotal"
              [rowsPerPageOptions]="[25,50,100,250,500]"
              [scrollable]="true"
              [(selection)]="selectedStudentRosterList"
              selectionMode="single"
              scrollHeight="400px"
              paginatorDropdownAppendTo="body"
              [loading]="loadingTable"
              styleClass="p-datatable-responsive-demo p-datatable-striped inner-table"
              currentPageReportTemplate="Showing {first} to {last} of {{this.studentRosterTotal | number}} entries"
              (selectionChange)="setNewPerson($event)"
              >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th style="width:3rem"></th>
                  @for (col of columns; track col) {
                    <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom"
                      style="width:50px">
                      <div class="header-text">
                      {{col.header}}</div>
                      <div class="header-actions">
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                        @if (col.columnType !== 'none') {
                          <p-columnFilter type="{{col.columnType}}" matchMode="{{col.matchMode}}" [field]="col.field" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        }
                      </div>
                    </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" >
                <tr>
                  <td style="width:3rem">
                    <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                  </td>
                  @for (col of columns; track col) {
                    <td style="width:50px">
                      <ng-container>
                        {{rowData[col.field]}}
                      </ng-container>
                    </td>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [colSpan]="cols?.length" style="width: 100%;">No people found</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        }
      </div>
      <div class="p-grid p-fluid margin-top-10">
        @if (canTabWrite) {
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="p-grid p-fluid">
              <div class="col-md-6 col-sm-6 col-xs-12"></div>
              <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
                @if (isEdit) {
                  <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                  (click)="cancel()" [disabled]="!mainForm.dirty"></button>
                } @else {
                  <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                  (click)="cancel()"></button>
                }
                @if (!isSaving) {
                  <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                    (click)="processData()" [className]="'inline-button'"
                  [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
                } @else {
                  <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                    <i class="pi pi-spin pi-spinner save-spinner"></i>
                  </button>
                }
              </div>
            </div>
          </div>
        }
        <!--    <div *ngIf="isEdit" class="col-md-12 col-sm-12 col-xs-12 input-container">-->
        <!--      <p-divider class="dark-divider"></p-divider>-->
        <!--      <div>{{position.FirstName}} {{position.LastName}}</div>-->
        <!--      <div><strong>{{position.PositionDescription}}</strong></div>-->
        <!--      <div *ngIf="position.PrimaryPhoneNumber"><b>Home:</b> {{position.PrimaryPhoneNumber | phone}}</div>-->
        <!--      <div *ngIf="position.SecondaryPhoneNumber"><b>Mobile:</b> {{position.SecondaryPhoneNumber | phone}}</div>-->
        <!--      <div *ngIf="position.PrimaryEmailAddress"><a href="mailto:{{position.EmailAddress}}">{{position.PrimaryEmailAddress}}</a></div>-->
      <!--    </div>-->
    </div>
  </form>
}
