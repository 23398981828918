<div class="p-grid p-fluid margin-top-0">
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
    <textarea [(ngModel)]="html" autoResize="autoResize" pInputTextarea rows="45" style="width: 100%;" tabindex="0"></textarea>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15 text-align-right">
    <button (click)="cancel()" [className]="'inline-button'" class="p-button-info p-mr-2" label="Cancel" pButton pTooltip="Cancel"
            tooltipPosition="bottom"></button>
    <button (click)="processData()" [className]="'inline-button'" class="p-button-info p-mr-2" label="Ok" pButton pTooltip="Ok"
            tooltipPosition="bottom"></button>
  </div>
</div>
