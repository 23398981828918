@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form [formGroup]="mainForm">
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      @if (!isEdit) {
        <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
          <div class="radio-container margin-top-10">
            <p-radioButton value="TRAINING" formControlName="type" id="Training" [styleClass]="'radio-input'"
                           class="p-mr-2" label="Training" (onClick)="toggle()"></p-radioButton>
            <p-radioButton value="CERTIFICATION" formControlName="type" id="Certification" [styleClass]="'radio-input'"
                           label="Certification" (onClick)="toggle()"></p-radioButton>
          </div>
        </div>
      }
      <div class="col-md-9 col-sm-12 col-xs-12 input-container">
        <label>Name <sup class="req">*</sup></label>
        <input type="text" pInputText formControlName="name">
        @if ((mainForm.controls.name.dirty || mainForm.controls.name.touched) &&
        mainForm.controls.name.errors?.required) {
          <span class="error">'Name' must be provided</span>
        }
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjCategory" (emitData)="getCategoryData($event)"></app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <label>Description</label>
        <textarea pInputTextarea formControlName="description" rows="5" autoResize="autoResize"></textarea>
      </div>
      @if (sharableType.toUpperCase() === 'CERTIFICATION') {
        <div class="col-md-3 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjCertification"
                      (emitData)="getCertificationData($event)"></app-select>
        </div>
      }
      <div class="col-md-3 col-sm-6 col-xs-12 input-container">
        @if (sharableType.toUpperCase() === 'TRAINING') {
          <label>Completion Date</label>
        }
        @if (sharableType.toUpperCase() === 'CERTIFICATION') {
          <label>Certificate Issue Date <sup class="req">*</sup></label>
        }
        <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="completionDate" appendTo="body"
                    placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true"
                    yearRange="1950:2025"></p-calendar>
      </div>
      @if (sharableType.toUpperCase() === 'CERTIFICATION') {
        <div class="col-md-3 col-sm-6 col-xs-12 input-container">
          <label>Expiration Date</label>
          <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="expirationDate" appendTo="body"
                      placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true"
                      yearRange="1950:2025"></p-calendar>
          @if ((mainForm.controls.expirationDate.dirty || mainForm.controls.expirationDate.touched) &&
          mainForm.controls.expirationDate.errors?.required) {
            <span class="error">'Expiration Date' must be provided</span>
          }
          <p-checkbox formControlName="noExpirationDate" label="No Expiration date" [binary]="true" class="p-mt-2"
                      (onChange)="toggleExpirationDate($event)"></p-checkbox>
        </div>
      }
      @if (sharable.GoURL) {
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
          <a href="{{sharable.GoURL}}" target="_blank">Certificate</a> (TPC)
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12 input-container p-button-auto-width">
        <label>Attachment</label>
        @for (res of sharable.Resources; track res) {
          <div class="upload-file-container">
            @if (canTabWrite) {
              <button pButton (click)="removeResource(res.ResourceReferenceID)" icon="pi pi-times" pTooltip="Remove"
                      tooltipPosition="bottom" class="p-button-info p-mr-2 p-mb-2"></button>
            }
            <button pButton (click)="openResource(res.ResourceReferenceID)" icon="pi pi-file" pTooltip="Open"
                    tooltipPosition="bottom" class="p-button-info p-mr-2 p-mb-2"></button>
            <div class="inline-button-text">{{ res.Description }} ({{ res.MIMEType }})</div>
          </div>
        }
        @if (canTabWrite && addAttachment && showUploader === false) {
          <button pButton (click)="showUploader=!showUploader" icon="pi pi-plus" pTooltip="Add Attachments"
                  tooltipPosition="bottom" class="p-button-info p-mr-2 p-mb-2"></button>
        }
        @if (showUploader === true) {
          <button pButton (click)="showUploader=!showUploader" icon="pi pi-minus" pTooltip="Hide"
                  tooltipPosition="bottom" class="p-button-info p-mr-2 p-mb-2"></button>
        }
        @if (showUploader === true) {
          <p>*Files must be 30 MB or less in size. If necessary, change the name of your files appropriately prior to
            uploading them.</p>
        }
        @if (canTabWrite && showUploader === true) {
          <p-fileUpload accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.ppt,.pptx" #fileUploader
                        name="uploadedFiles[]" (onSelect)="onUpload($event)"
                        [showUploadButton]="false" maxFileSize="31457280">
            <ng-template let-file pTemplate='file'>
              <div>
                {{ file.name }} - ({{ file.size }} bytes)
              </div>
            </ng-template>
          </p-fileUpload>
        }
      </div>
      @if (showProgress) {
        <div class="col-md-12">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      }
    </div>
    @if (canTabWrite) {
      <div class="p-grid p-fluid margin-top-10">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="p-grid p-fluid">
            <div class="col-md-6 col-sm-6 col-xs-12"></div>
            <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
              @if (isEdit) {
                <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                        icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                        (click)="cancel()" [disabled]="!mainForm.dirty"></button>
              } @else {
                <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'"
                        icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                        (click)="cancel()"></button>
              }
              @if (!isSaving) {
                <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                        tooltipPosition="bottom"
                        (click)="processData()" [className]="'inline-button'"
                        [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
              } @else {
                <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'"
                        [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </form>
}
