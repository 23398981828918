<form [formGroup]="mainForm" class="">
  <div class="p-fluid p-grid ">
    @if (isEdit) {
      <div class="col-md-2 col-sm-12 col-xs-12">
        <app-select class="full-width" [inputObj]="inputObjPrograms" (emitData)="getProgramData($event)"></app-select>
      </div>
    }
    <div class="col-md-2 col-sm-12 col-xs-12 p-mt-2">
      <app-select (emitData)="getPeriodIntervalData($event)" [inputObj]="inputObjPeriodInterval" class="full-width"></app-select>
    </div>
    <div class="col-md-2 col-sm-12 col-xs-12 p-mt-2">
      <app-select (emitData)="setEmployerData($event)" [inputObj]="inputObjEmployer" class="full-width"></app-select>
    </div>
    <div class="col-md-2 col-sm-12 col-xs-12 p-mt-2">
      <label>Manager</label>
      <input formControlName="manager" pInputText tabindex="0" type="text">
    </div>
    <div class="col-md-1 col-sm-12 col-xs-12 p-mt-2">
      <label>Hours <sup class="req">*</sup></label>
      <p-inputNumber [maxFractionDigits]="2" [minFractionDigits]="2" [showButtons]="true" [step]="0.25" class="full-width"
                     formControlName="hours" mode="decimal"
                     tabindex="0"></p-inputNumber>
      @if ((mainForm.controls.hours.dirty || mainForm.controls.hours.touched) &&
      mainForm.controls.hours.errors?.required) {
        <span class="error">'Hours' must be provided</span>
      }
    </div>
    @if (!isEdit || showEditHours) {
      <div class="col-md-1 col-sm-12 col-xs-12 p-mt-2">
        @if (!isEdit) {
          <app-multi-select class="full-width" [inputObj]="inputObjFacet" (emitData)="getFacetData($event)"></app-multi-select>
        } @else {
          <app-select class="full-width" [inputObj]="inputObjFacet" (emitData)="getFacetData($event)"></app-select>
        }
      </div>
    }
    @if (canTabWrite) {
      <div class="col-md-2 col-sm-12 col-xs-12 add-entry">
        <!-- remove after api fix -->
        @if (showEditHours && !showSave) {
          <button pButton label="Edit" (click)="enableHours()" pTooltip="Edit" tooltipPosition="bottom" class="margin-top-20"></button>
        }
        @if (showSave) {
          <button pButton icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom" (click)="cancel()" class="margin-top-20"></button>
        }
        @if (showSave) {
          <button pButton [disabled]="!mainForm.valid" (click)="processData()" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom" class="margin-top-20" [className]="'p-ml-1'"></button>
        }
        <!-- end removal -->
        @if (!isEdit) {
          <button pButton label="Add Entry" [disabled]="!mainForm.valid" (click)="processNewData()" icon="pi pi-plus" pTooltip="Add" tooltipPosition="bottom" class="margin-top-25"></button>
        }
        @if (isEdit && canEdit && this.existingTimeEntry) {
          <button pButton class="margin-right-5" (click)="processData()" icon="pi pi-save"
                  [disabled]="!mainForm.valid && mainForm.dirty" pTooltip="Save" tooltipPosition="bottom"></button>
        }
        @if (isEdit && canEdit) {
          <button pButton (click)="deleteEntry(existingTimeEntry.ProgramStudentFacetID)"
                  icon="pi pi-trash" pTooltip="Remove" tooltipPosition="bottom"></button>
        }
      </div>
    }
    @if (RejectionReason) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <label>Reason for Rejection</label>
        <textarea type="text" pInputTextarea formControlName="rejectionReason" rows="5" autoResize="autoResize" tabindex="0"></textarea>
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
  </div>
</form>
