<form [formGroup]="mainForm" class="padding-bottom-50">
  <div class="p-grid p-fluid">
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>Member #</label>
      <input type="text" pInputText  readonly formControlName="memberNumber">
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjStatus" (emitData)="getStatusData($event)"></app-select>
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>{{STRINGREPLACETYPE}}</label>
      <input class="full-width" type="text" pInputText  formControlName="type" readonly>
      <!--      <app-select class="full-width" [inputObj]="inputObjType" (emitData)="getTypeData($event)"></app-select>-->
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>{{STRINGREPLACECLASSIFICATION}}</label>
      <input class="full-width" type="text" pInputText  formControlName="class" readonly>
      <!--      <app-select class="full-width" [inputObj]="inputObjClass" (emitData)="getClassData($event)"></app-select>-->
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjDues" (emitData)="getDuesPaymentData($event)"></app-select>
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>Years of Service</label>
      <input pInputText formControlName="cumulativeYears" [disabled]="true" tabindex="0"/>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-12 input-container">
      <app-select  class="full-width" [inputObj]="inputObjOrganization" (emitData)="getOrganizationData($event)"></app-select>
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>Effective Date@if (isRequiredEffectiveDate) {
        <sup class="req">*</sup>
      }</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="effectiveDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [disabled]="true" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>Application Date</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="applicationDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1925:2023" [disabled]="true" tabindex="0"></p-calendar>
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>Dues Group</label>
      <input class="full-width" type="text" pInputText  formControlName="duesGroup" readonly>
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjBilling" (emitData)="getBillingProcessData($event)"></app-select>
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>Consecutive Years</label>
      <input pInputText formControlName="consecutiveYears" [disabled]="true" tabindex="0" />
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjPathway" (emitData)="getPathwayData($event)"></app-select>
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>Paid Through Date</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="paidThroughDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [disabled]="true" [yearNavigator]="true" tabindex="0"></p-calendar>
    </div>
    <div class="col-md-2 col-sm-4  col-xs-12 input-container">
      <label>Initiation Date@if (isInitiationDateRequired) {
        <sup class="req">*</sup>
      }</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="initiationDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1925:2023" [disabled]="true" tabindex="0"></p-calendar>
    </div>
    <div class="col-md-4 col-sm-4  col-xs-12 input-container">
      <!--          <input class="full-width" type="text" pInputText  formControlName="bargainingUnit" readonly>-->
      <app-select class="full-width" [inputObj]="inputObjBargainingUnit" (emitData)="getBargainingUnitData($event)"></app-select>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-12 input-container">
      <label>Retirement Date@if (isRetireDateRequired) {
        <sup class="req">*</sup>
      }</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="retirementDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-12 input-container">
      <label>Card Issue Date</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="cardIssueDate" appendTo="body" placeholder="mm/dd/yyyy"
      [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
    </div>
    @if (canTabWrite) {
      <div class="col-md-12 text-align-right">
        <div class="action-buttons float-right margin-top-10">
          @if (isEdit) {
            <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
            (click)="cancel()" [disabled]="!mainForm.dirty"></button>
          } @else {
            <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
            (click)="cancel()"></button>
          }
          @if (!isSaving) {
            <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
              (click)="processData()" [className]="'inline-button'"
            [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
          } @else {
            <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    }
    @if (this.rosterProfile) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p-accordion [styleClass]="'margin-top-10 activities-accordion'">
          <p-accordionTab header="Activities" [selected]="true">
            @if (canTabWrite) {
              <div class="p-grid p-fluid">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <app-member-activities [setOrganizationId]="rosterProfile.OrganizationID" [PersonID]="rosterProfile.PersonID" [canTabWrite]="canTabWrite" [StatusAlias]="rosterProfile.StatusAlias"
                    [applicationDate]="rosterProfile.MemberApplicationDate" [initiationDate]="rosterProfile.MemberStartDate" [isEdit]="isEdit" (closeAddEdit)="closeAddEdit.emit(); getRosterProfile()" [newMember]="false"
                  [effectiveDate]="rosterProfile.EffectiveDate" [memberType]="rosterProfile.TypeAlias"></app-member-activities>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <p-divider class="dark-divider"></p-divider>
                </div>
              </div>
            }
            @if (showActivities) {
              <app-roster-tab-activities [PersonID]="PersonID" [canTabWrite]="canTabWrite" [isEdit]="true" (updateData)="refresh()"></app-roster-tab-activities>
            }
          </p-accordionTab>
        </p-accordion>
      </div>
    }
  </div>
</form>
