import {Component, OnInit, Input, Output, EventEmitter, OnChanges,  SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-last-in-loop',
  template: ''
})

export class LastInLoopComponent implements OnInit, OnChanges {
  @Output() emitData = new EventEmitter<any>();
  @Input() dataSent: boolean;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.dataSent) {
      if(this.dataSent) {
        this.emitData.emit();
      }

    }
  }
}
