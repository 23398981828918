<form [formGroup]="mainForm">
  <div class="p-grid p-fluid">
    <div class="col-md-6 col-sm-12 col-xs-12 margin-top-15">
      <label>Certification #</label>
      <input type="text" pInputText  formControlName="certification">
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 margin-top-15">
      <label>Certification Issue Date <sup class="req">*</sup></label>
      <app-universal-date-picker [selectedValue]="mainForm.get('certIssueDate').value" (emitData)="mainForm.get('certIssueDate').setValue($event); mainForm.markAsDirty()"></app-universal-date-picker>
      <!--      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="certIssueDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="2010:2027"></p-calendar>-->
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 margin-top-15">
      <label>Certification Expire Date </label>
      <app-universal-date-picker [selectedValue]="mainForm.get('certExpirationDate').value" (emitData)="mainForm.get('certExpirationDate').setValue($event)"></app-universal-date-picker>
      <!--      <p-calendar dateMask formControlName="certExpirationDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="2010:2027" ></p-calendar>-->
      <p-checkbox formControlName="noExpirationDate" label="No Expiration date" [binary]="true" class="p-mt-2" (onChange)="toggleExpirationDate($event)"></p-checkbox>
    </div>
    @if (certificateData.CertificationResourceID) {
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
        <a href="{{certificateData.CertificationExternalUrl}}" target="_blank">Certificate</a> (TPC)
      </div>
    }
    @if (certificateData.CertificationResourceID) {
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
        <button pButton (click)="removeResource()" icon="pi pi-times" pTooltip="Remove" tooltipPosition="bottom" class="p-button-info p-mr-2 p-mb-2"></button>
        <button pButton (click)="openResource(certificateData.CertificationResourceReferenceID)" icon="pi pi-file" pTooltip="Open" tooltipPosition="bottom" class="p-button-info p-mr-2 p-mb-2"></button>
        <div class="inline-button-text">Certificate</div>
      </div>
    }
    @if (!certificateData.CertificationResourceID) {
      <div  class="col-md-12 col-sm-12 col-xs-12 input-container p-button-auto-width">
        <p>*Files must be 30 MB or less in size. If necessary, change the name of your files appropriately prior to uploading them. Upload one file at a time.</p>
        <p-fileUpload #fileUploader name="uploadedFiles[]" (onSelect)="onUpload($event)" (onClear)="clearUpload()" accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.ppt,.pptx" [showUploadButton]="false" maxFileSize="31457280">
          <ng-template let-file pTemplate='file'>
            <div>
              {{file.name}} - ({{file.size}} bytes)
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
    }
    <div  class="col-md-12 col-sm-12 col-xs-12 text-align-right p-mb-3">
      <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button p-mr-2'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
      (click)="closeDialog()"></button>
      @if (!isSaving) {
        <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
          (click)="processData()" [className]="'inline-button'"
        [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
      } @else {
        <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
          <i class="pi pi-spin pi-spinner save-spinner"></i>
        </button>
      }
    </div>
  </div>
</form>
