import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {ResourcesService} from '../../../../services/resources.service';
import {StudentGradeCertificateModel} from '../../../../models/class-students.model';
import {ClassStudentsService} from '../../../../services/class-students.service';
import {transformDateTimeToDateOnly} from '../../../../shared/utilities/form.utility';
import {MessageService} from 'primeng/api';

@Component({
  templateUrl: './add-certificate-dialog.component.html',
  styleUrls: ['./add-certificate-dialog.component.scss']
})

export class AddCertificateDialogComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  showProgress: boolean;
  fd: FormData = new FormData();
  uploadedFiles: any[] = [];
  isSaving: boolean = false;
  certificateData = {} as StudentGradeCertificateModel;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private formBuilder: FormBuilder,
              private resourcesService: ResourcesService, private classStudentsService: ClassStudentsService,
              private messageService: MessageService) {
    this.mainForm = this.formBuilder.group({
      certification: new FormControl(null),
      certIssueDate: new FormControl(null, Validators.required),
      certExpirationDate: new FormControl(null),
      noExpirationDate: new FormControl(null),
      files: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.getExistingCertification();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getExistingCertification() {
    this.classStudentsService.getStudentGradeCertification(this.config.data.selectedStudent.CourseID, this.config.data.selectedStudent.ClassStudentID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.certificateData = res;
          this.mainForm.get('certification').setValue(this.certificateData.CertificationNumber);
          if (this.certificateData.CertificationIssueDate) {
            this.mainForm.get('certIssueDate').setValue(new Date(this.certificateData.CertificationIssueDate));
          }
          if (this.certificateData.CertificationExpirationDate) {
            this.mainForm.get('certExpirationDate').setValue(new Date(this.certificateData.CertificationExpirationDate));
          } else {
            this.mainForm.get('noExpirationDate').setValue(true);
          }
          if (!this.certificateData.CertificationExpirationDate) {
            this.mainForm.get('certExpirationDate').setValue(null);
            this.mainForm.get('certExpirationDate').disable();
            this.mainForm.get('certExpirationDate').setValidators(null);
            this.mainForm.get('certExpirationDate').updateValueAndValidity();
          }
        }
      });
  }

  toggleExpirationDate(event:any): void {
    if (event && event.checked) {
      this.mainForm.get('certExpirationDate').setValue(null);
      this.mainForm.get('certExpirationDate').disable();
      this.mainForm.get('certExpirationDate').setValidators(null);
      this.mainForm.get('certExpirationDate').updateValueAndValidity();
    } else {
      this.mainForm.get('certExpirationDate').enable();
      this.mainForm.get('certExpirationDate').setValidators(Validators.required);
      this.mainForm.get('certExpirationDate').updateValueAndValidity();
    }
  }

  saveNewFiles(): any {
    this.showProgress = true;
    return new Promise((resolve, reject) => {
      if (this.uploadedFiles.length > 0) {
        this.resourcesService.uploadResource(this.fd, 'public')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (fileData) => {
              resolve(fileData);
              this.showProgress = false;
            }
          });
      } else {
        this.showProgress = false;
        resolve(null);
      }
      console.debug(reject);
    });
  }

  onUpload(event:any): any {
    if (event.files.length > 0) {
      this.mainForm.get('files').setValue(event.files.length);
      for (const file of event.files) {
        this.uploadedFiles.push(file);
        this.fd.append('file', file);
      }
      //this.messageService.add({severity: 'info', summary: 'File(s) Uploaded', detail: ''});
      this.mainForm.markAsDirty();
      // if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
      //   this.existingMessage = true;
      //   this.openDialog();
      // }
    }
  }

  clearUpload(): void {
    this.uploadedFiles.length = 0;
    this.fd = new FormData();
    this.mainForm.get('files').setValue(null);
    this.mainForm.markAsDirty();
  }

  processData() {
    this.saveNewFiles().then((fileData) => {
      if (fileData && fileData.length > 0) {
        this.certificateData.CertificationResourceID = fileData[0].ResourceId;
        this.certificateData.CertificationExternalUrl = fileData[0].Url;
      }

      this.certificateData.CertificationNumber = this.mainForm.get('certification').value;
      this.certificateData.CertificationIssueDate = transformDateTimeToDateOnly(this.mainForm.get('certIssueDate').value);
      this.certificateData.CertificationExpirationDate = transformDateTimeToDateOnly(this.mainForm.get('certExpirationDate').value);
      this.classStudentsService.updateStudentGradeCertification(this.config.data.selectedStudent.CourseID, this.config.data.selectedStudent.ClassStudentID,
        this.certificateData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Grades have been updated.'
            });
            this.ref.close();
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
          }
        });
    });

  }

  removeResource(): void {
    this.certificateData.CertificationResourceID = null;
    this.mainForm.markAsDirty();
    this.clearUpload();
  }

  openResource(resourceReferenceId: number): void {
    this.resourcesService.getResourceById(resourceReferenceId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (resource) => {
          if (resource) {
            window.open(resource.Url, '_blank');
          }
        }
      });
  }

  closeDialog() {
    this.ref.destroy();
  }

  cancel() {
    this.ref.close();
  }

}
