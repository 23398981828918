import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgxCroppedEvent, NgxPhotoEditorService} from 'ngx-photo-editor';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {WebcamComponent} from '../webcam/webcam.component';
import {UserProfilePhotoDataUrlModel} from '../../../models/user-profile.model';
import {ContextService} from '../../../services/context.service';
import {DialogService} from 'primeng/dynamicdialog';
import {UserProfileService} from '../../../services/user-profile.service';
import {MessageService} from 'primeng/api';
import {RosterProfileService} from '../../../services/roster-profile.service';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})

export class ImageEditorComponent implements OnInit, OnChanges {
  output?: NgxCroppedEvent;
  fileName: string;
  @Input() incomingImageUrl: string;
  @Input() ratio: number = 16 / 9;
  @Input() roundCropper: boolean = false;
  @Input() PersonID: number;
  @Input() isProfile: boolean;
  @Input() showWebCam: boolean;
  @Output() deleteImage = new EventEmitter<any>();
  @Output() updateImage = new EventEmitter<any>();
  @Output() updateFilename = new EventEmitter<any>();
  @Output() updatePage = new EventEmitter<any>();
  private ngUnsubscribe = new Subject();

  constructor(private service: NgxPhotoEditorService, private contextService: ContextService,
              private dialogService: DialogService, private userProfileService: UserProfileService, private messageService: MessageService,
              private rosterProfileService: RosterProfileService,) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.incomingImageUrl) {
      console.log(this.incomingImageUrl);
    }
  }

  fileChangeHandler($event: any) {
    console.log($event);
    this.fileName = $event.target.files[0].name;
    console.log(this.roundCropper);
    this.service.open($event, {
      aspectRatio: this.ratio,
      roundCropper: this.roundCropper,
      format: 'png'
    }).subscribe({
      next: (data) => {
        this.output = data;
        console.log(this.fileName);
        this.updateFilename.emit(this.fileName);
        this.updateImage.emit(this.output);
      }
    });
  }

  removeImage() {
    this.deleteImage.emit();
  }

  openWebCam() {
    const imageWidth = this.contextService.contextObject.isMobile ? '90%' : '75%';
    const ref = this.dialogService.open(WebcamComponent, {
      data: {},
      header: 'Capture your profile picture',
      width: imageWidth,
      height: '70%'
    });
    if (!this.isProfile) {
      ref.onClose.subscribe({
        next: (image: UserProfilePhotoDataUrlModel) => {
          if (image) {
            this.userProfileService.updateUserProfilePhotoUrl(image)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: () => {
                  this.messageService.add({
                    severity: 'success', summary: 'Success',
                    detail: 'Profile image has been updated'
                  });
                  this.updatePage.emit();
                }
              });
          } else {
            console.log('no image');
          }
        }
      });
    } else {
      // console.log(this.PersonID);
      ref.onClose.subscribe({
        next: (image: UserProfilePhotoDataUrlModel) => {
          if (image) {
            this.rosterProfileService.updateRosterProfilePhotoUrl(this.PersonID, image)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: () => {
                  this.messageService.add({
                    severity: 'success', summary: 'Success',
                    detail: 'Profile Image has been updated'
                  });
                  this.updatePage.emit();
                }
              });
          }
        }
      });
    }
  }
}
