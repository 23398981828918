<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <app-learn-more [interfaceObjectDesc]="interfaceObjectDesc" [interfaceObjectTypeEnum]="'APPSECTION'"
                      [parentObjectEnum]="parentObjectEnum"></app-learn-more>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3>Filter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 no-padding">
        <label>File Name</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="activeGlobalFilter" class="full-width filter" pInputText
               tabindex="0" type="text"/>
      </div>
      <div class="col-md-12 no-padding margin-top-10">
        <label>Title</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="title" class="full-width filter" pInputText tabindex="0"
               type="text"/>
      </div>
      <div class="col-md-12 no-padding margin-top-10">
        <label>Created By</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="createdBy" class="full-width filter" pInputText tabindex="0"
               type="text"/>
      </div>
      <div class="col-md-12 no-padding margin-top-10">
        <label>File Type</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="mimeType" class="full-width filter" pInputText tabindex="0"
               type="text"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <app-multi-select (emitData)="setCategoryData($event)" [inputObj]="inputObjCategory" class="full-width">
        </app-multi-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <div class="list-filter-buttons text-align-right">
          <button (click)="clearFilters()" [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" class="p-button-download export-menu" icon="pi pi-times"
                  label="Clear" pButton>
          </button>
          <button (click)="filterItems()" class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  pButton>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">@if (docs) {
          <span>{{ docsTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (docsTotal > currentFilter.rows) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="docsTotal" pageLinkSize="4" [first]="first"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        @if (showQRCodeButton) {
          <button pButton (click)="showQRCode()"
                  icon="fa-light fa-qrcode" pTooltip="Show Link"
                  tooltipPosition="bottom"></button>
        }
        @if ((currentFilter.filters | json) !== '{}') {
          <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                  pTooltip="Clear Filter" tooltipPosition="bottom"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                  (click)="clearFilters()">
          </button>
        }
        <button (click)="refresh()" icon="pi pi-refresh" pButton
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canTabWrite && canAdd) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="docsTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
        @if (docsTotal > currentFilter.rows) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="docsTotal" pageLinkSize="3"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Document" [styleClass]="'list-card'">
          <app-docs-form [DBEntity]="DBEntity" [DBEntityID]="DBEntityID" [isEdit]="false" [showAccess]="showAccess"
                         [canTabWrite]="canTabWrite" [InterfaceObjectEnum]="InterfaceObjectEnum"
                         (closeAddEdit)="updateList($event)"></app-docs-form>
        </p-card>
      </div>
    }
    @if (totalLoaded && docsTotal <= 0 && !showAddEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (doc of docList; track doc; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i)">
                  <div class="list-header">
                    <!--                <i class="fa fa-file"></i>-->
                    @switch (true) {
                      @case (doc.MimeType === 'text/plain') {
                        <i class="fa fa-file-alt"></i>
                      }
                      @case (doc.MimeType === 'application/vnd.ms-excel' || doc.MimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        <i class="fa fa-file-excel export-excel"></i>
                      }
                      @case (doc.MimeType === 'application/pdf') {
                        <i class="fa fa-file-pdf export-pdf"></i>
                      }
                      @case (doc.MimeType === 'application/vnd.ms-powerpoint' || doc.MimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                        <i class="fa fa-file-powerpoint" style="color: #D04423;"></i>
                      }
                      @case (doc.MimeType === 'application/msword' || doc.MimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                        <i class="fa fa-file-word" style="color: #1B5EBE;"></i>
                      }
                      @case (doc.MimeType === 'image/gif' || doc.MimeType === 'image/jpeg' || doc.MimeType === 'image/png') {
                        <i class="fa fa-image" style="color: #98c6e0;"></i>
                      }
                    }
                    {{ doc.Title }} - {{ doc.PublicationDate_D | date:'shortDate' }}
                  </div>
                  <div class="list-subheader">
                    @if (doc.CategoryAlias) {
                      <span>{{ doc.CategoryAlias }} | </span>
                    }<b>Uploaded on:</b> {{ doc.CreatedDate_D | date:'shortDate' }} | <b>Uploaded
                    by:</b> {{ doc.CreatedBy }}@if (doc.RosterCanSee === true) {
                    <span> | Roster Access</span>
                  }
                  </div>
                  <!--              <div class="list-subheader">-->
                  <!--                {{doc.Description}}-->
                  <!--              </div>-->
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    <div class="icon" (click)="openResource(doc.ResourceReferenceId)" pTooltip="Download"><i
                      class="pi pi-download"></i></div>
                    @if (canTabWrite && doc.CanEdit && canDelete) {
                      <div class="icon" pTooltip="Delete" tooltipPosition="bottom" (click)="deleteDoc(doc.SharableId)">
                        <i class="fas fa-trash-alt"></i></div>
                    }
                    <div class="icon" (click)="expandData(i)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                  </div>
                </div>
                @if (selectedItem === i) {
                  <div class="full-width margin-top-10">
                    <app-docs-form [sharableID]="doc.SharableId" [DBEntity]="DBEntity" [DBEntityID]="DBEntityID"
                                   [isEdit]="true" [showAccess]="showAccess" [canTabWrite]="canTabWrite"
                                   [InterfaceObjectEnum]="InterfaceObjectEnum"
                                   (closeAddEdit)="updateList($event)"></app-docs-form>
                  </div>
                }
              </div>
            </p-card>
          </div>
        }
      </div>
    }
    @if (dataLoaded && docsTotal > currentFilter.rows) {
      <div class="p-grid p-fluid p-mb-5">
        <div class="col-md-12 col-sm-12 col-xs-12 align-center list-paginator">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="docsTotal" pageLinkSize="4" [first]="first"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      </div>
    }
  </div>
</div>
