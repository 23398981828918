import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';

import {UserRegistrationService} from '../../services/user-registration.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {ContextModel} from '../../models/context.model';
import {EnvironmentsService} from '../../services/environments.service';
import {ContextService} from '../../services/context.service';
import {UserAccountPasswordResetModel, UserAccountRequestSecurityQuestionModel, UserAccountVerifyTokenModel} from '../../models/user-account.model';
import {UserAccountService} from '../../services/user-account.service';
import {ConfirmValidationUtility} from '../../shared/utilities/confirm-validation.utility';
import {Title} from '@angular/platform-browser';

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  rpForm: FormGroup;
  tenant: string;
  logo: string;
  qpusername: string;
  qptoken: string;
  passwordType = 'password';
  passwordConfirmType = 'password';
  isLoading: boolean = false;
  requirements: boolean = false;
  securityQuestionID: number;
  securityQuestion: string;
  tries: number = 0;

  private ngUnsubscribe = new Subject();

  constructor(private userRegistrationService: UserRegistrationService,
              private router: Router, private route: ActivatedRoute,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private environmentsService: EnvironmentsService, private userAccountService: UserAccountService,
              private contextService: ContextService, private confirmValidationUtility: ConfirmValidationUtility,
              private titleService: Title) {
    this.rpForm = this.formBuilder.group({
      answer: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(100)]),
      password: new FormControl(null, [Validators.required,
        this.regexValidator(new RegExp('^.*[A-Za-z0-9$@#!%*?&].{9,}'), {length: true}),
        this.regexValidator(new RegExp('^.*[a-z]'), {lowercase: true}),
        this.regexValidator(new RegExp('^.*[A-Z]'), {uppercase: true}),
        this.regexValidator(new RegExp('^.*[0-9]'), {number: true}),
        this.regexValidator(new RegExp('^.*[$@#!%*?&]'), {special: true}),
        this.regexValidator(new RegExp('^(?!.*([a-zA-Z0-9$@#!%*?&])\\1{2,})'), {repeats: true})
      ]),
      // password: new FormControl(null, [Validators.required, Validators.maxLength(100),
      //   Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])(?!.*([a-zA-Z0-9])\\1{2,})[A-Za-zd$@$!%*?&].{9,}')]),
      showPassword: new FormControl(false),
      passwordConfirm: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      showPasswordConfirm: new FormControl(false)
    }, {
      validator: [this.confirmValidationUtility.ConfirmedValidator('password', 'passwordConfirm'),
        this.customValidator('password')]
    });
  }

  ngOnInit(): void {
    this.getBaseUrl().then(() => {
      this.route.queryParams.subscribe({
        next: (params) => {
          // get the query parameters
          if (params && params.username) {
            this.qpusername = params.username;
          } else {
            this.qpusername = null;
          }
          if (params && params.token) {
            this.qptoken = params.token;
          } else {
            this.qptoken = null;
          }
          // check the validity of the token, it can expire
          this.verifyToken().then((res) => {
            if (res === 'Valid') {
              // if valid, show verify
              this.loadQuestion();
              this.onChanges();
            } else {
              // if invalid, redirect
              this.messageService.add({severity: 'error', summary: 'Failure', detail: 'We apologize, but this link has expired...', life: 2500});
              setTimeout(() => {
                this.router.navigate(['login']);
              }, 3000);
            }
          }, () => {
            // if invalid, redirect
            this.messageService.add({severity: 'error', summary: 'Failure', detail: 'We apologize, but this link has expired...', life: 2500});
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 3000);
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onChanges(): void {
    this.rpForm.controls.showPassword.valueChanges.subscribe({
      next: (val) => {
        this.passwordType = val === true ? 'text' : 'password';
      }
    });

    this.rpForm.controls.showPasswordConfirm.valueChanges.subscribe({
      next: (val) => {
        this.passwordConfirmType = val === true ? 'text' : 'password';
      }
    });
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {
      switch (true) {
        case environment.environmentName === 'dev':
        case environment.environmentName === 'staging':
        case environment.environmentName === 'demo':
          this.environmentsService.getEnvironment(environment.environmentName).then((rtnTenant) => {
            if (rtnTenant) {
              this.setUrl(rtnTenant);
            }
          });
          break;
        case environment.local
        && environment.environmentName !== 'dev'
        && environment.environmentName !== 'demo'
        && environment.environmentName !== 'staging':
          if (this.tenant) {
            this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
              if (rtnTenant) {
                this.setUrl(rtnTenant);
              }
            });
          }
          break;
        case !environment.local:
          this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
            if (rtnTenant) {
              this.setUrl(rtnTenant);
            }
          });
          break;
      }
      // if(environment.environmentName === 'demo') {
      //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.environmentName === 'dev') {
      //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
      //   this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //     if(rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(!environment.local) {
      //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // }
      resolve(null);
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
  }

  toggleRequirements() {
    this.requirements = this.requirements !== true;
  }

  verifyToken() {
    // check the status of the token
    return new Promise((resolve, reject) => {
      const data: UserAccountVerifyTokenModel = {
        UserNameEncoded: this.qpusername,
        Token: this.qptoken,
        TokenTypeID: 3
      };
      this.userAccountService.verifyToken(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error: (e) => {
            console.log(e);
            reject(e);
          }
        });
    });
  }

  loadQuestion(): void {
    const data: UserAccountRequestSecurityQuestionModel = {
      UserName: this.qpusername,
      Iteration: Math.floor(Math.random() * 3)
    };
    this.userAccountService.requestSecurityQuestion(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          if (res) {
            this.securityQuestionID = res.ID;
            this.securityQuestion = res.Description;
          } else {
            this.securityQuestionID = null;
            this.securityQuestion = 'No Question Found';
          }
        }, error: () => {
          this.securityQuestionID = null;
          this.securityQuestion = 'No Question Found';
        }
      });
  }

  resetPassword() {
    this.isLoading = true;
    const data: UserAccountPasswordResetModel = {
      UserName: this.qpusername,
      Password: this.rpForm.get('password').value,
      ConfirmPassword: this.rpForm.get('passwordConfirm').value,
      SecurityQuestionID: this.securityQuestionID,
      SecurityQuestionResponse: this.rpForm.get('answer').value
    };
    this.userAccountService.resetPassword(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (msg) => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: msg, life: 4500});
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 5000);
        }, error: (e) => {
          this.rpForm.get('answer').setValue(null);
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Failure', detail: e.error.Message, life: 2500});
          }
          this.tries++;
          if (this.tries >= 3) {
            this.messageService.add({severity: 'error', summary: 'Failure', detail: 'You may only try 3 times unsuccessfully...', life: 2500});
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 3000);
          } else {
            this.isLoading = false;
          }
        }
      });
  }

  customValidator(pswdControlName: string) {
    return (formGroup: FormGroup) => {
      const matchingControl = formGroup.controls[pswdControlName];
      if (matchingControl.errors && !matchingControl.errors?.customValidator) {
        return;
      }
      const control: string = this.contextService.contextObject.UserName;
      if (control) {
        const am: number = control.indexOf('@');
        const username: string = control.substring(0, am);
        const domain: string = control.substring(am + 1, control.length);
        if (matchingControl.value.indexOf(username) > -1 || matchingControl.value.indexOf(domain) > -1) {
          matchingControl.setErrors({customValidator: true});
        } else {
          matchingControl.setErrors(null);
        }
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
