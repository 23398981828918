import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-roster-student-programs-tab',
  templateUrl: './roster-student-programs.component.html'
})

export class RosterStudentProgramsComponent implements OnInit {

  @Input() DBEntity;
  @Input() PersonID;
  @Input() canTabWrite;
  @Input() isEdit: boolean;
  @Input() canAdd: boolean;
  activeSubTab: string = 'programs';

  constructor() {
  }

  ngOnInit(): void {
  }
}
