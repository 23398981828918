import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DialogUtility} from '../../shared/utilities/dialog.utility';
import {ClassModel} from '../../models/classes.model';
import {ClassesModulesService} from '../../services/classes.service';
import {PrimeTableFilterModel} from '../../models/table-filter.model';
import {removeKeysFromArray} from '../../shared/utilities/data.utility';
import {exportData} from '../../shared/utilities/list-table.utility';
import {GenericSelectModel} from '../../models/generic-select.model';
import {DatePipe} from '@angular/common';
import {LookupsDataService} from '../../services/lookups-data.service';
import {Table} from 'primeng/table';
import {listFilterUtility} from '../../shared/utilities/list-filter.utility';
import {PageRowCountUtility} from '../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-classes-grid',
  templateUrl: './classes-grid.component.html',
  styleUrls: ['./classes-grid.component.scss'],
})
export class ClassGridComponent implements OnInit, AfterViewInit, OnDestroy {
  showTable: boolean = false;
  classesList: ClassModel[];
  dataTotal: number = 0;
  cols: any[];
  exportColumns: any[];
  loading: boolean;
  isExporting: boolean;
  selClassId: number;
  selClass: ClassModel[];
  isButtonDisabled: boolean = true;
  exportItems: any[] = [];
  setTrainingCenterId: number;
  setCourseId: number;
  currentFilter = new PrimeTableFilterModel();
  inputObjCourse: GenericSelectModel;
  inputObjTrainingCenters: GenericSelectModel;
  filter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  showAddEdit: boolean;
  startDate: Date;
  startDateString: string;
  endDate: Date;
  endDateString: string;
  hasFilter: boolean;
  @Input() canPageWrite: boolean;
  @Input() canAdd: boolean;
  getListRequest;
  getTotalRequest;
  stopListening: boolean = false;
  private ngUnsubscribe = new Subject();

  constructor(private classesService: ClassesModulesService, private datePipe: DatePipe,
              private messageService: MessageService, private router: Router,
              private dialogUtility: DialogUtility, private changeDetection: ChangeDetectorRef,
              private lookupsDataService: LookupsDataService, private confirmationService: ConfirmationService,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  _selectedColumns: any[];

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  ngOnInit(): void {
    this.cols = [
      {
        field: 'CourseTypeDescription',
        header: 'Course Type',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'CourseDescription',
        header: 'Course',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'ClassIdentifier',
        header: 'Class #',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'ClassStatusDescription',
        header: 'Status',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'OrganizationName',
        header: 'Organization',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'Location',
        header: 'Location',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'ClassStaff',
        header: 'Staff',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'StartDate_D',
        header: 'Start Date',
        columnType: 'date',
        matchMode: 'equals',
        visible: true,
        displayType: 'date'
      },
      {
        field: 'StartTime_T',
        header: 'Start Time',
        columnType: 'date',
        matchMode: 'equals',
        visible: true,
        displayType: 'time'
      },
      {
        field: 'EndDate_D',
        header: 'End Date',
        columnType: 'date',
        matchMode: 'equals',
        visible: true,
        displayType: 'date'
      },
      {
        field: 'EndTime_T',
        header: 'End Time',
        columnType: 'date',
        matchMode: 'equals',
        visible: true,
        displayType: 'time'
      },
      {
        field: 'TimezoneDescription',
        header: 'Timezone',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        displayType: 'text'
      },
      {
        field: 'Students_N',
        header: '# Enrolled',
        columnType: 'numeric',
        matchMode: 'gte',
        visible: true,
        displayType: 'numeric'
      },
      {
        field: 'MaxSeats_N',
        header: 'Max # of Seats',
        columnType: 'numeric',
        matchMode: 'gte',
        visible: true,
        displayType: 'numeric'
      }
    ];

    this._selectedColumns = this.cols;
    // load filters
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 0;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    this.initSelects();
  }

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initSelects(): void {
    this.initCourses(false);
    this.initTrainingCenters(false);
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.classesService
      .getClassesPrimeNG('TOTAL', this.currentFilter, this.startDateString, this.endDateString, this.setTrainingCenterId, this.setCourseId)
      .pipe(take(1))
      .subscribe({
        next: res => {
          this.dataTotal = res.Total;
        }
      });
  }

  getList(): void {
    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.classesService
      .getClassesPrimeNG('DATA', this.currentFilter, this.startDateString, this.endDateString, this.setTrainingCenterId, this.setCourseId)
      .pipe(take(1))
      .subscribe({
        next: res => {
          this.classesList = res.Data;
          this.loading = false;
        }, error:
          (e) => {
            console.warn(e);
          }
      });
  }

  getData(): void {
    this.showAddEdit = false;
    this.convertDates();
    this.getTotal();
    this.getList();
  }

  convertDates(): void {
    if (this.startDate) {
      this.startDateString = this.datePipe.transform(this.startDate, 'yyyy-MM-dd').toString();
    }
    if (this.endDate) {
      this.endDateString = this.datePipe.transform(this.endDate, 'yyyy-MM-dd').toString();
    }

  }


  initCourses(disable: boolean): void {
    this.inputObjCourse = {
      labelText: 'Courses',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getCoursesLookupData().then((lookupData) => {
      this.inputObjCourse.data = lookupData;
      this.inputObjCourse = Object.assign({}, this.inputObjCourse);
    });
  }

  getCourseData(event:any): void {
    if (event && event[0]) {
      this.setCourseId = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setCourseId = 0;
    }
  }

  initTrainingCenters(disable: boolean): void {
    this.inputObjTrainingCenters = {
      labelText: 'Training Center',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getTrainingCentersLookupData().then((lookupData) => {
      this.inputObjTrainingCenters.data = lookupData;
      this.inputObjTrainingCenters = Object.assign({}, this.inputObjTrainingCenters);
    });
  }

  getTrainingCenterData(event:any): void {
    if (event && event[0]) {
      this.setTrainingCenterId = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setTrainingCenterId = 0;
    }
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string): void {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  setGlobalFilter(table: Table): any {
    let key: keyof typeof table.filters;
    for (key in table.filters) {
      if (table.filters) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        table.filters[key].forEach(filter => {
          filter.value = null;
        });
      }
    }
    this.getData();
  }

  loadTable(event?): void {
    if (event) {
      this.currentFilter = event;
      this.currentFilter.sortField = event.sortField;
      this.currentFilter.sortOrder = event.sortOrder;
    }
        this.getData();
      console.log(event);

  }

  customFilterCallback(filter: (a) => void, value: any): void {
    this.stopListening = true;
    filter(value);
    this.stopListening = false;
  }


  onRowSelect(event: any): void {
    this.canDelete = event.data.CanDelete;
    this.selClassId = event.data.ClassID;
  }

  onRowUnselect(event: any): void {
    this.selClassId = undefined;
  }

  goToEdit(event:any): void {
    sessionStorage.setItem('sessionViewType', 'Grid');
    this.selClassId = event.ClassID;
    this.router.navigate(['training/classes/edit/', +this.selClassId]);
  }

  deleteClass(classId: number): void {
    if (classId) {
      this.dialogUtility.promptToDelete(() => {
        this.classesService
          .deleteClass(classId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.selClassId = undefined;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected class has been deleted.'
            });
            this.refresh();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
      }, () => {
      });
    }
  }

  refresh(): void {
    this.getData();
  }

  resetFilter(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.setCourseId = 0;
    this.setTrainingCenterId = 0;
    this.startDate = null;
    this.startDateString = null;
    this.endDate = null;
    this.endDateString = null;
    this.getData();
    this.initSelects();
    this.hasFilter = false;
  }

  clear(table: Table): void {
    table.clear();
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.setCourseId = 0;
    this.setTrainingCenterId = 0;
    this.startDate = null;
    this.startDateString = null;
    this.endDate = null;
    this.endDateString = null;
    this.loadTable();
    this.hasFilter = false;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.classesList));
        const dataToExport = removeKeysFromArray(arr, ['ClassID', 'ClassTypeID', 'CourseID', 'CourseTypeID', 'SubjectAreaID', 'ClassStatusID', 'OrganizationID', 'TimezoneID', 'OnlineMeetingTypeID', 'CanEdit', 'CanDelete']);
        exportData('classes', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.classesService
          .getClassesPrimeNG('DATA', exportFilter, this.startDateString, this.endDateString, this.setTrainingCenterId, this.setCourseId)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['ClassID', 'ClassTypeID', 'CourseID', 'CourseTypeID', 'SubjectAreaID', 'ClassStatusID', 'OrganizationID', 'TimezoneID', 'OnlineMeetingTypeID', 'CanEdit', 'CanDelete']);
              exportData('classes', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
