import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {ConfirmationService, MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {RosterProfileActivitiesService} from '../../../services/roster-profile-activities.service';
import {RosterProfileActivitiesGridModel, RosterProfileActivityModel} from '../../../models/roster-profile-activities.model';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {tabsPermissionsUtility} from '../../../shared/utilities/tabs-permissions.utility';
import {InterfaceObjectAppsModel} from '../../../models/interface-objects.model';
import {PageService} from '../../../services/page.services';
import {Router} from '@angular/router';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';

@Component({
  selector: 'app-roster-tab-activities',
  templateUrl: './roster-tab-activities.component.html',
  styleUrls: ['./roster-tab-activities.component.scss']
})
export class RosterTabActivitiesComponent implements OnInit, OnChanges, OnDestroy {
  activities: RosterProfileActivitiesGridModel;
  activitiesList: RosterProfileActivityModel[] = [];
  dataLoaded: boolean;
  totalLoaded: boolean;
  isExporting: boolean;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  dataTotal: number = 0;
  STRINGREPLACECLASSIFICATION: string;
  STRINGREPLACELOCAL: string;
  STRINGREPLACETYPE: string;
  selectedItem: number;
  @Input() PersonID: number;
  @Input() canTabWrite: boolean;
  @Input() isEdit: boolean;
  @Output() updateData = new EventEmitter<any>();
  // @Input() interfaceObjectDesc: string;
  // @Input() parentObjectEnum: string;
  getListRequest;
  getTotalRequest;
  isRecovering: boolean;
  permissions: InterfaceObjectAppsModel[] = [];
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  protected readonly tabsPermissionsUtility = tabsPermissionsUtility;
  private ngUnsubscribe = new Subject();

  constructor(private tenantConfigDataService: TenantConfigDataService, private router: Router,
              private messageService: MessageService, public dialogService: DialogService,
              private rosterProfileActivitiesService: RosterProfileActivitiesService, private confirmationService: ConfirmationService,
              public pageRowCountUtility: PageRowCountUtility, private pageService: PageService,
              private dialogUtility: DialogUtility) {
  }

  ngOnInit(): void {
    this.STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    this.STRINGREPLACECLASSIFICATION = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
    this.STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();

    this.pageService.getTabPermissions('ROSTER').then((tabPermissions: InterfaceObjectAppsModel[]) => {
      this.permissions = tabPermissions;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.getData();
  }

  getData(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.activitiesList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.rosterProfileActivitiesService.getRosterActivitiesPrimeNG('DATA', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.activities = res;
          this.activitiesList = res.Data;
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.rosterProfileActivitiesService.getRosterActivitiesPrimeNG('TOTAL', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.loadList();
  }

  filterItems(): void {
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('ActivityDescription', this.activeGlobalFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.ActivityDescription;
    }
    this.loadList();
  }

  clearFilters(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.loadList();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  loadList(): void {
    this.getData();
  }

  refresh(): void {
    this.getData();
  }

  resetComponent(): void {
    this.activitiesList = undefined;
    this.activities = undefined;
  }

  recoverActivity(id) {
    this.confirmationService.confirm({
      header: 'Activity Recovery Confirmation',
      icon: 'fas fa-history',
      message: 'You are about to recover this activity. Are you sure this is what you want to do?',
      accept: () => {
        this.isRecovering = true;
        this.rosterProfileActivitiesService.recoverActivity(this.PersonID, id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Activities have been recovered successfully'
              });
              this.isRecovering = false;
              // this.getData();
              // this.updateData.emit();
              this.router.navigateByUrl('/membership/roster/edit/' + this.PersonID);
            }, error: (e) => {
              if (e?.error?.Message) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: e.error.Message.replace('UM: ', ''),
                  life: 5000
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
              }
              this.isRecovering = false;
            }
          });
      }
    });
  }

  expandData(index, id) {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
    this.confirmationService.close();
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.activitiesList));
        const dataToExport = removeKeysFromArray(arr, ['ActivityHistoryID', 'PersonID', 'PersonProfileID', 'ActivityID', 'OldPersonProfileStatusID', 'PersonProfileStatusID', 'OldPersonProfileTypeID', 'PersonProfileTypeID', 'OldPersonProfileClassificationID', 'PersonProfileClassificationID', 'OldOrganizationID', 'OrganizationID', 'OldBargainingUnitID', 'BargainingUnitID']);
        exportData('roster-profile-activities', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.rosterProfileActivitiesService.getRosterActivitiesPrimeNG('DATA', this.PersonID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['ActivityHistoryID', 'PersonID', 'PersonProfileID', 'ActivityID', 'OldPersonProfileStatusID', 'PersonProfileStatusID', 'OldPersonProfileTypeID', 'PersonProfileTypeID', 'OldPersonProfileClassificationID', 'PersonProfileClassificationID', 'OldOrganizationID', 'OrganizationID', 'OldBargainingUnitID', 'BargainingUnitID']);
              exportData('roster-profile-activities', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
