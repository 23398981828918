<div class="page-container">
  <div class="public-card">
    <p-card class="card">
      @if (logo) {
        <div class="align-center">
          <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76"
               [priority]="true"/>
        </div>
      }
      <div class="page-container">
        <form [formGroup]="urForm" class="user-registration-form">
          <h2>Create an Account</h2>
          @if (reregister) {
            <div class="p-fieldset-content">
              <p>Your account must first be verified to continue. You should have received an e-mail from us with
                instructions on how to do so. If that cannot be located, please re-register below.</p>
              <p>You may use the same username and password you have already provided. Doing so will re-generate the
                verification email and allow you to continue. Many thanks!</p>
            </div>
          }
          <div class="p-fieldset-content">
            <label>Username (E-mail)<span class="ut-requireddenotation"> *</span></label>
            <input type="email" id="username" pInputText formControlName="name">
            @if (urForm.controls.name.invalid && urForm.controls.name.touched) {
              <div class="p-error">
                @if (urForm.controls.name.errors?.required) {
                  <div>Username is required</div>
                }
                @if (urForm.controls.name?.errors) {
                  <div>Username must be a valid e-mail address</div>
                }
              </div>
            }
          </div>
          <div class="p-fieldset-content">
            <label>Password/Passphrase<span class="ut-requireddenotation"> *</span></label>
            <input [type]="passwordType" pInputText formControlName="password">
            @if (urForm.controls.password.invalid && urForm.controls.password.touched) {
              <div class="p-error">
                @if (urForm.controls.password.errors?.required) {
                  <div>Password is required</div>
                }
                <!--                <div *ngIf="changePasswordForm.controls.password.errors?.pattern">Must contain at least one uppercase and lowercase letter, one number, one special character, and be at least 10 characters long</div>-->
                @if (urForm.controls.password.hasError('length')) {
                  <div>Must be at least 10 characters long</div>
                }
                @if (urForm.controls.password.hasError('uppercase')) {
                  <div>Must contain at least one uppercase letter (A-Z)</div>
                }
                @if (urForm.controls.password.hasError('lowercase')) {
                  <div>Must contain at least one lowercase letter (a-z)</div>
                }
                @if (urForm.controls.password.hasError('number')) {
                  <div>Must contain at least one number (0-9)</div>
                }
                @if (urForm.controls.password.hasError('special')) {
                  <div [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></div>
                }
                @if (urForm.controls.password.hasError('repeats')) {
                  <div>Must have no more than 2 identical characters in a row (999 not allowed)</div>
                }
                @if (urForm.controls.password.hasError('customValidator')) {
                  <div>No parts of your username</div>
                }
              </div>
            }
            <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPassword"
                        label="Show Password"></p-checkbox>
          </div>
          <div class="p-fieldset-content">
            <label>Confirm Password/Passphrase<span class="ut-requireddenotation"> *</span></label>
            <input [type]="passwordConfirmType" pInputText formControlName="passwordConfirm">
            @if (urForm.controls.passwordConfirm.invalid && urForm.controls.passwordConfirm.touched) {
              <div class="p-error">
                @if (urForm.controls.passwordConfirm.errors?.required) {
                  <div>Confirm Password is required</div>
                }
                @if (urForm.controls.passwordConfirm.errors?.confirmedValidator) {
                  <div>Password & Confirm Password do not match</div>
                }
              </div>
            }
            <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPasswordConfirm"
                        label="Show Password"></p-checkbox>
          </div>
          <div class="p-fieldset-content">
            <!--      <span class="align-right"><a href="https://www.useapassphrase.com/" target="_blank" rel="noopener noreferrer" title="Why use a passphrase?">Why use a passphrase?</a></span>-->
            <span class="link" (click)="toggleRequirements()">Requirements</span>
            @if (requirements) {
              <ul>
                <li>Must be at least 10 characters long</li>
                <li>Must contain at least one uppercase letter (A-Z)</li>
                <li>Must contain at least one lowercase letter (a-z)</li>
                <li>Must contain at least one number (0-9)</li>
                <li [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></li>
                <li>Must have no more than 2 identical characters in a row (999 not allowed)</li>
                <li>No parts of your username</li>
              </ul>
            }
          </div>
          @if (!reregister) {
            <div class="align-center">
              @if (!isLoading) {
                <button pButton label="Register" class="p-button-info p-mr-2" pTooltip="Register"
                        tooltipPosition="bottom"
                        (click)="register()" [className]="'inline-button'"
                        [disabled]="urForm.invalid || !urForm.touched"></button>
              } @else {
                <button pButton label="Register" class="p-button-info p-mr-2" [className]="'inline-button'"
                        [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          } @else {
            <div class="align-center">
              @if (!isLoading) {
                <button pButton label="Re-Register" class="p-button-info p-mr-2" pTooltip="Re-Register"
                        tooltipPosition="bottom"
                        (click)="register()" [className]="'inline-button'"
                        [disabled]="urForm.invalid || !urForm.touched"></button>
              } @else {
                <button pButton label="Re-Register" class="p-button-info p-mr-2" [className]="'inline-button'"
                        [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          }
          <p><span class="link" [routerLink]="['/login']">&lt; Back to Login</span></p>
        </form>
      </div>
    </p-card>
  </div>
  <p-toast [baseZIndex]="16000"></p-toast>
</div>
