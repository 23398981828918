import {AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {StudentProfileService} from '../../services/student-profile.service';
import {Location} from '@angular/common';
import {StudentProfileModel} from '../../models/student-profile.model';
import {ContextService} from '../../services/context.service';
import {InterfaceObjectAppsModel} from '../../models/interface-objects.model';
import {tabsPermissionsUtility} from '../../shared/utilities/tabs-permissions.utility';
import {InterfaceObjectsService} from '../../services/interface-objects.service';
import {PageService} from '../../services/page.services';
import {PageModel} from '../../models/page.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {RosterProfileModel} from '../../models/roster-profile.model';
import {RosterProfileService} from '../../services/roster-profile.service';
import {dateDiffInYearsMonths} from '../../shared/utilities/form.utility';
import {employmentTradeTab} from '../../shared/utilities/component-manager-utility';
import {InactiveStatusUtility} from '../../shared/utilities/inactive-status.utility';

@Component({
  selector: 'app-global-roster-student-tab-container',
  templateUrl: './global-roster-student-tab-container.component.html',
  styleUrls: ['./global-roster-student-tab-container.component.scss']
})
export class GlobalRosterStudentTabContainerComponent implements OnInit, OnDestroy, AfterContentInit {
  InterfaceObjectEnum: string = 'STUDENTSALL';
  invalidRecord: boolean = false;
  permissions: InterfaceObjectAppsModel[] = [];
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  @Input() ContentID: number;
  @Input() PersonProfileID: number;
  @Input() isEdit: boolean;
  @Input() canPageWrite;
  isDialog: boolean = false;
  dataId: number;
  student: StudentProfileModel = {} as StudentProfileModel;
  origStudent: StudentProfileModel = {} as StudentProfileModel;
  roster: RosterProfileModel = {} as RosterProfileModel;
  reload: boolean = true;
  isPage: boolean = false;
  blobToken: string;
  inactiveStatuses: string[] = [];
  yearsService: string;
  showTabs: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  public _employmentTradeTab = employmentTradeTab;
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
              private route: ActivatedRoute, private router: Router,
              private studentProfileService: StudentProfileService,
              private location: Location, private contextService: ContextService,
              private interfaceObjectsService: InterfaceObjectsService,
              private pageService: PageService, private confirmationService: ConfirmationService,
              private rosterProfileService: RosterProfileService, private changeDetection: ChangeDetectorRef,
              private inactiveStatusUtility: InactiveStatusUtility, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isEdit = this.pageInfo.isEdit;
          this.isPage = this.pageInfo.isPage;
          this.isDialog = this.pageInfo.isDialog;
          this.ContentID = this.pageInfo.ContentID;
          this.getDataToEdit(this.ContentID);
        }
      }
    });
    if (this.canPageWrite === undefined) {
      this.pageService.getPagePermissions(this.InterfaceObjectEnum).then(() => {
        this.canPageWrite = false;
      });
    }
    this.pageService.getTabPermissions(this.InterfaceObjectEnum).then((tabPermissions: InterfaceObjectAppsModel[]) => {
      this.permissions = tabPermissions;
      this.showTabs = true;
    });
    this.inactiveStatusUtility.getPersonProfileInactiveStatuses('training').then(x => {
      this.inactiveStatuses = x;
    }).catch(y => {
      this.inactiveStatuses = y;
    });
    this.blobToken = this.contextService.contextObject.blobToken;
    this.changeStatus();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterContentInit(): void {
    this.changeStatus();
  }

  changeStatus(): void {
    this.changeDetection.detectChanges();
  }

  back(): void {
    this.confirmationService.close();
    this.location.back();
  }

  getDataToEdit(id: number): void {
    this.reload = false;
    if (id) {
      this.studentProfileService.getStudentProfile(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: StudentProfileModel) => {
            this.reload = true;
            this.origStudent = res;
            this.student = res;
            this.PersonProfileID = this.student.PersonProfileID;
          }, error: (e) => {
            this.reload = true;
            console.debug(e);
            if (e) {
              if (e.Message) {
                this.messageService.add({severity: 'warn', summary: 'Record Not Found', detail: e.Message});
              }
              if (!e.ok) {
                this.invalidRecord = true;
              }
            }
          }
        });
      this.rosterProfileService
        .getRosterProfile(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: RosterProfileModel) => {
            this.roster = res;
            if (this.roster.MemberStartDate) {
              const dtStart: Date = new Date(this.roster.MemberStartDate);
              const dtToday: Date = new Date();
              this.yearsService = dateDiffInYearsMonths(dtStart, dtToday);
            } else {
              this.yearsService = null;
            }
          }
        });
    } else {
      this.reload = false;
    }
  }

  reset(): void {
    this.route.params.subscribe({
      next: (params) => {
        this.dataId = Number(params.id);
        if (this.dataId) {
          this.getDataToEdit(this.dataId);
        }
      }
    });
  }

  resetWarning() {
    this.confirmationService.close();
  }

  setDefaultPic() {
    this.roster.ThumbnailUrl = 'assets/images/profile-generic.png';
  }

  isStatusInActive(status): any {
    return this.inactiveStatuses.indexOf(status) > -1;
  }

  getAvatarClass(img: boolean, status: string): any {
    let str: string = '';
    if (img === false) {
      str = '-no-image';
    }
    switch (true) {
      case status === 'Deceased':
        return 'deceased-status' + str;
      case status === 'Suspended':
        return 'suspended-status' + str;
      case this.inactiveStatuses.indexOf(status) > -1:
        return 'inactive-status' + str;
      case this.inactiveStatuses.indexOf(status) === -1:
        return 'active-status' + str;
    }
  }
}
