
<div class="page-container">
  <div class="public-card">
    <p-card class="card">
      @if (logo) {
        <div class="align-center">
          <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true" />
        </div>
      }
      <div class="page-container">
        @if (landing && survey) {
          <div class="p-grid p-fluid">
            @if (!landing.Submitted) {
              <div class="col-md-12 col-sm-12 col-xs-12">
                <p>As part of our ongoing commitment to provide the best membership experience possible, we ask that you complete the survey below. Your opinion is important to us and a timely response is very much appreciated.</p>
                @if (survey && survey.Anonymous) {
                  <p>*This survey is Anonymous &mdash; your responses are made accessible for statistical purposes, but they are not directly attributable to you by survey administrators.</p>
                }
                <p class="red">Please be sure to submit your responses when you are finished &mdash; otherwise they will not be counted. Many thanks.</p>
              </div>
            }
            @for (question of survey.Questions; track question; let i = $index) {
              @if (!landing.Submitted) {
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <p-divider class="dark-divider"></p-divider>
                </div>
              }
              @if (!landing.Submitted) {
                <div class="col-md-12 col-sm-12 col-xs-12">
                  @if (question.DataTypeDescription === 'Yes/No') {
                    <div class="col-md-12 col-sm-12 col-xs-12 input-container margin-top-10 p-mb-2">
                      <div class="radio-container">
                        <label><strong>{{question.Description}}</strong> @if (question.RequiredCount>0) {
                        <sup class="req">*</sup>
                      }</label><br><br>
                      <p-radioButton [(ngModel)]="responses[i]" value="Yes" name="value" id="yes" [styleClass]="'radio-input'" (onClick)="saveResponse(question.ID, i)"></p-radioButton>
                      <label class="radio-label p-mr-2" for="yes">Yes</label>
                      <p-radioButton [(ngModel)]="responses[i]" value="No" name="value" id="no" [styleClass]="'radio-input'" (onClick)="saveResponse(question.ID, i)"></p-radioButton>
                      <label class="radio-label" for="no">No</label>
                      @if (reqCheck && !responses[i] && question.RequiredCount===1) {
                        <p class="p-error">A response is required to submit</p>
                      }
                    </div>
                  </div>
                }
                @if (question.DataTypeDescription === 'Custom Pick List') {
                  <div class="col-md-12 col-sm-12 col-xs-12 input-container margin-top-10 p-mb-2">
                    <label><strong>{{question.Description}}</strong> @if (question.RequiredCount>0) {
                    <sup class="req">*</sup>
                  }</label>
                  <p-dropdown [(ngModel)]="responses[i]" [options]="question.Options" optionLabel="Description" optionValue="Description" (onChange)="saveResponse(question.ID, i)"></p-dropdown>
                  @if (reqCheck && !responses[i] && question.RequiredCount===1) {
                    <p class="p-error">A response is required to submit</p>
                  }
                </div>
              }
              @if (question.DataTypeDescription === 'Range') {
                <div class="col-md-12 col-sm-12 col-xs-12 input-container margin-top-10 p-mb-2">
                  <label><strong>{{question.Description}}</strong> @if (question.RequiredCount>0) {
                  <sup class="req">*</sup>
                }</label>
                <p><p-slider [(ngModel)]="responses[i]" [min]="question.Minimum" [max]="question.Maximum" (onSlideEnd)="saveResponse(question.ID, i)"></p-slider></p>
                <p>(Drag slider to respond.)</p>
                <p><b>Your Response:</b> {{responses[i]}}</p>
                @if (reqCheck && !responses[i] && question.RequiredCount===1) {
                  <p class="p-error">A response is required to submit</p>
                }
              </div>
            }
            @if (question.DataTypeDescription === 'Open Text - Single Line') {
              <div class="col-md-12 col-sm-12 col-xs-12 input-container margin-top-10 p-mb-2">
                <label><strong>{{question.Description}}</strong> @if (question.RequiredCount>0) {
                <sup class="req">*</sup>
              }</label>
              <input [(ngModel)]="responses[i]" type="text" pInputText  (change)="saveResponse(question.ID, i)">
              @if (reqCheck && !responses[i] && question.RequiredCount===1) {
                <p class="p-error">A response is required to submit</p>
              }
            </div>
          }
          @if (question.DataTypeDescription === 'Open Text - Multi Line') {
            <div class="col-md-12 col-sm-12 col-xs-12 input-container margin-top-10 p-mb-2">
              <label><strong>{{question.Description}}</strong> @if (question.RequiredCount>0) {
              <sup class="req">*</sup>
            }</label>
            <textarea [(ngModel)]="responses[i]" pInputText  (change)="saveResponse(question.ID, i)"></textarea>
            @if (reqCheck && !responses[i] && question.RequiredCount===1) {
              <p class="p-error">A response is required to submit</p>
            }
          </div>
        }
      </div>
    }
  }
  @if (!survey.Submitted) {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="p-grid p-fluid">
        <div class="col-md-6 col-sm-6 col-xs-12"></div>
        <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
          @if (!isSaving) {
            <button pButton label="Submit" class="p-button-info p-mr-2" pTooltip="Submit" tooltipPosition="bottom"
            (click)="submitSurvey()" [className]="'inline-button'"></button>
          } @else {
            <button pButton label="Submit" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    </div>
  }
</div>
}
@if (showErrorWarning) {
  <div class="col-md-12 col-sm-12 col-xs-12 text-align-middle">
    <h3>Sorry we are not able to find this survey. Please try again or <a [routerLink]="['/login']">login.</a></h3>
  </div>
}
</div>
</p-card>
</div>
<p-toast [baseZIndex]="16000"></p-toast>
</div>
