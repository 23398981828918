import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import { CustomAttributesModel } from '../models/attributes.model';

@Injectable({
  providedIn: 'root',
})
export class CustomAttributesService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getCustomAttributesByOrgID(orgId: number): Observable<CustomAttributesModel[]> {
    return this.http.get<CustomAttributesModel[]>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/organization/${orgId}/profileAttributes/byContext/membership`);
  }

  updateCustomAttributesByPersonID(personId: number, modelData): Observable<CustomAttributesModel[]> {
    return this.http.put<CustomAttributesModel[]>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/attributes`, modelData);
  }
}
