@switch (selectedTab) {
  @case ('DRAFT') {
    <h2>Select Entry Period to edit</h2>
  }
  @case ('SUBMITTED') {
    <h2>Waiting Approval</h2>
  }
  @case ('REJECTED') {
    <h2>Rejected</h2>
  }
  @case ('APPROVED') {
    <h2>Approved</h2>
  }
}
<div class="p-fluid p-grid margin-top-10">
  <div class="col-md-3 col-sm-12 col-xs-12">
    <app-select (emitData)="getProgramData($event)" [inputObj]="inputObjPrograms" class="full-width"></app-select>
  </div>
  <div class="col-md-3 col-sm-12 col-xs-12">
    <app-select (emitData)="getFacetTypesData($event)" [inputObj]="inputObjFacetType" class="full-width"></app-select>
  </div>
  <div class="col-md-2 col-sm-12 col-xs-12">
    <app-select (emitData)="getPeriodData($event)" [inputObj]="inputObjPeriod" class="full-width"></app-select>
  </div>
  @if (statusCount.length > 0) {
    <div class="col-md-4 col-sm-12 col-xs-12">
      <h4 class="margin-top-25">There @if (statusCount[0].Count > 1) {
        <span>are</span>
      }
        @if (statusCount[0].Count === 1) {
          <span>is</span>
        } {{ statusCount[0].Count }} period@if (statusCount[0].Count > 1) {
          <span>s</span>
        } in {{ selectedTab | titlecase }} status.</h4>
    </div>
  }
</div>
<!--<span class="fa fa-spinner list-spinner" *ngIf="!formReady"></span>-->
@if (formReady) {
  <div>
    @if (selectedTab === 'DRAFT' && canTabWrite) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <h2>Add New Entry</h2>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="margin-top-5">All time is recorded in hours, down to quarter hour increments. For example, 1 hour and
          15 minutes is recorded as 1.25 hours. All time entered will be rounded accordingly.
        </p>
      </div>
    }
    <div class="col-md-12 col-sm-12 col-xs-12margin-top-10">
      @if (selectedTab === 'DRAFT' && canTabWrite) {
        <app-time-entry-form [selFacetType]="selFacetType" [startDate]="setStartDate"
                             [programStudentId]="programStudentId" [canTabWrite]="canTabWrite"
                             (updateData)="updateData()" [isEdit]="false"
                             [MembershipOrganizationID]="setOrgId"></app-time-entry-form>
      }
      @if (selectedTab === 'DRAFT') {
        <p-divider class="dark-divider"></p-divider>
      }
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding time-accordion">
        <p-accordion [(activeIndex)]="accordionIndex" [multiple]="true" #accordion>
          @for (entry of facetEntries; track entry; let h = $index) {
            @if (entry.ProgramStudentFacets.length > 0) {
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <div>
                    {{ entry.FacetCategoryDescription }} - {{ entry.TotalRecordedHours | number:'1.0':'en-US' }}
                    Recorded Hours and
                    {{ entry.ApprovedRecordedHours | number:'1.0':'en-US' }}  Approved Hours of
                    {{ entry.RequiredHours | number:'1.0':'en-US' }} Hours (Recommended)
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <h2 class="category-header"></h2>
                  @if (entry.ProgramStudentFacets.length > 0) {
                    <p-card [styleClass]="'facet-container margin-top-10'">
                      <div class="p-fluid p-grid">
                        @for (facetCategory of facetGroups; track facetCategory; let i = $index) {
                          @for (hours of entry.ProgramStudentFacets; track hours) {
                            <div class="col-md-12 col-sm-12 col-xs-12">
                              @if (facetCategory === hours.FacetDescription) {
                                <h3>{{ facetCategory }}</h3>
                              }
                              @if (hours.FacetDescription === facetGroups[i]) {
                                <p-card [styleClass]="'sub-facet-container'">
                                  <div class="p-fluid p-grid margin-top-10">
                                    <app-time-entry-form [selFacetType]="selFacetType" [startDate]="setStartDate"
                                                         [existingTimeEntry]="hours"
                                                         [MembershipOrganizationID]="setOrgId"
                                                         [selectedTab]="selectedTab"
                                                         [programStudentId]="programStudentId" [isEdit]="true"
                                                         [canEdit]="canEdit" [canTabWrite]="canTabWrite"
                                                         [personID]="personId" (updateData)="updateData()"
                                                         (valueChanged)="valueChanged($event)"></app-time-entry-form>
                                  </div>
                                </p-card>
                              }
                            </div>
                          }
                        }
                        <!--                    <div *ngIf="selectedTab === 'APPROVED' && entry.ProgramStudentFacets?.length > 1" class="col-md-12"><h4>Total '{{entry?.FacetCategoryDescription}}' Hours for '{{setStartDateDescription}}': {{periodTotal[h] | number:'1.0':'en-US'}}</h4></div>-->
                        @if (selectedTab === 'APPROVED' && entry.ProgramStudentFacets?.length > 1) {
                          <div class="col-md-12"><h4>{{ entry?.FacetCategoryDescription }}
                            - {{ periodTotal[h] | number:'1.0':'en-US' }} Approved Hours for
                            '{{ setStartDateDescription }}'</h4></div>
                        }
                      </div>
                    </p-card>
                  }
                </ng-template>
              </p-accordionTab>
            }
          }
        </p-accordion>
      </div>
    </div>
    @if (hasEntries && canTabWrite) {
      @switch (true) {
        @case (selectedTab === 'DRAFT' || selectedTab === 'REJECTED') {
          <div class="col-md-12 margin-top-10 text-align-right">
            @if (inputValueChanged) {
              <span class="error">You have unsaved changes you need save before submitting.</span>
            }
            <button pButton class="p-button-info p-mr-2" [className]="'inline-button'" pTooltip="Submit"
                    tooltipPosition="bottom"
                    (click)="processData()" [disabled]="inputValueChanged">Submit {{ totalTimeSubmitting }} hours for
              approval
            </button>
          </div>
        }
        @case (selectedTab === 'SUBMITTED') {
          @if (isAdmin) {
            <div class="col-md-12 margin-top-10 text-align-right">
              <button pButton label="Reject" class="p-button-info" [className]="'inline-button force-margin-right'"
                      pTooltip="Reject" tooltipPosition="bottom"
                      (click)="reject()"></button>
              <button pButton label="Approve {{totalTimeSubmitting}} hours" class="p-button-info p-ml-2"
                      [className]="'inline-button'" pTooltip="Approve" tooltipPosition="bottom"
                      (click)="processData()"></button>
            </div>
          }
        }
        @case (selectedTab === 'APPROVED') {
          @if (isAdmin && !this._hideApprovedHoursReset()) {
            <div class="col-md-12 margin-top-10 text-align-right">
              <button pButton label="Reset Period as 'New Entry'" class="p-button-info"
                      [className]="'inline-button force-margin-right'"
                      pTooltip="Reset Period as 'New Entry'" tooltipPosition="bottom"
                      (click)="resetToEditEmit()"></button>
            </div>
          }
        }
      }
    }
    <!--    @if ((selectedTab === 'DRAFT' || selectedTab === 'REJECTED') && hasEntries && canTabWrite) {-->
    <!--      <div class="col-md-12 margin-top-10 text-align-right">-->
    <!--        @if (inputValueChanged) {-->
    <!--          <span class="error">You have unsaved changes you need save before submitting.</span>-->
    <!--        }-->
    <!--        <button pButton class="p-button-info p-mr-2" [className]="'inline-button'" pTooltip="Submit"-->
    <!--                tooltipPosition="bottom"-->
    <!--                (click)="processData()" [disabled]="inputValueChanged">Submit {{ totalTimeSubmitting }} hours for-->
    <!--          approval-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    }-->
    <!--    @if ((isAdmin && selectedTab === 'SUBMITTED') && hasEntries && canTabWrite) {-->
    <!--      <div class="col-md-12 margin-top-10 text-align-right">-->
    <!--        <button pButton label="Reject" class="p-button-info" [className]="'inline-button force-margin-right'"-->
    <!--                pTooltip="Reject" tooltipPosition="bottom"-->
    <!--                (click)="reject()"></button>-->
    <!--        <button pButton label="Approve {{totalTimeSubmitting}} hours" class="p-button-info p-ml-2"-->
    <!--                [className]="'inline-button'" pTooltip="Approve" tooltipPosition="bottom"-->
    <!--                (click)="processData()"></button>-->
    <!--      </div>-->
    <!--    }-->
    <!--    @if ((isAdmin && selectedTab === 'APPROVED') && hasEntries && canTabWrite) {-->
    <!--      <div class="col-md-12 margin-top-10 text-align-right">-->
    <!--        <button pButton label="Reset to Edit" class="p-button-info" [className]="'inline-button force-margin-right'"-->
    <!--                pTooltip="Reset to Edit" tooltipPosition="bottom"-->
    <!--                (click)="reject()"></button>-->
    <!--      </div>-->
    <!--    }-->
  </div>
}
