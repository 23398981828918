import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RosterProfilePoliticsService} from '../../../../services/roster-profile-politics.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-politics-tab',
  templateUrl: './politics-tab.component.html',
  styleUrls: ['./politics-tab.component.scss']
})

export class PoliticsTabComponent implements OnInit, OnDestroy {
  loading: boolean;
  VoterRegistrationStatus: string;
  DateOfBirth: string;
  Gender: string;
  VoterStatus: string;
  PartyRegistration: string;
  CountyName: string;
  CongressionalDistrict: string;
  StateSenateDistrict: string;
  StateHouseDistrict: string;
  TownshipName: string;
  WardName: string;
  PrecinctCode: string;
  PrecinctName: string;
  NationalPrecinctCode: string;
  CountyCouncilDistrict: string;
  CityCouncilDistrict: string;
  MunicipalDistrict: string;
  SchoolDistrict: string;
  JudicialDistrict: string;
  AbsenteeVoterIndicator: string;
  GeneralVotesSummary: number;
  PrimaryVotesSummary: number;
  VoterScore: string;
  @Input() PersonID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;

  private ngUnsubscribe = new Subject();

  constructor(private rosterProfilePoliticsService: RosterProfilePoliticsService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.PersonID) {
      this.getPolitics();
    }
  }

  getPolitics() {
    this.rosterProfilePoliticsService.getRosterVoterInfo(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.VoterRegistrationStatus = res.VoterRegistrationStatus;
          this.VoterStatus = res.VoterStatus;
          this.PartyRegistration = res.PartyRegistration;
          this.CountyName = res.CountyName;
          this.CongressionalDistrict = res.CongressionalDistrict;
          this.StateSenateDistrict = res.StateSenateDistrict;
          this.StateHouseDistrict = res.StateHouseDistrict;
          this.TownshipName = res.TownshipName;
          this.WardName = res.WardName;
          this.PrecinctCode = res.PrecinctCode;
          this.PrecinctName = res.PrecinctName;
          this.NationalPrecinctCode = res.NationalPrecinctCode;
          this.CountyCouncilDistrict = res.CountyCouncilDistrict;
          this.CityCouncilDistrict = res.CityCouncilDistrict;
          this.MunicipalDistrict = res.MunicipalDistrict;
          this.SchoolDistrict = res.SchoolDistrict;
          this.JudicialDistrict = res.JudicialDistrict;
          this.AbsenteeVoterIndicator = res.AbsenteeVoterIndicator;
          this.GeneralVotesSummary = res.GeneralVotesSummary;
          this.PrimaryVotesSummary = res.PrimaryVotesSummary;
          this.VoterScore = res.VoterScore;
          this.loading = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
