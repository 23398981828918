<!--<agm-map-->
<!--  [latitude]="this.latitude"-->
<!--  [longitude]="this.longitude"-->
<!--  [zoom]="this.zoom"-->
<!--  >-->
<!--  <agm-marker-->
<!--    [latitude]="this.latitude"-->
<!--    [longitude]="this.longitude" [label]="this.title">-->
<!--    <agm-info-window>-->
<!--      {{address}} <br />-->
<!--      {{city}}, {{state}} {{zip}}-->
<!--    </agm-info-window>-->
<!--  </agm-marker>-->
<!--</agm-map>-->
@if (apiLoaded) {
  <div>
    <div>
      <p-card header="Address" class="parent-p-card">
        @if (address) {
          <span>{{address}}<br></span>
        }
        @if (city && state && zip) {
          <span>{{city}}, {{state}} {{zip}}</span>
        }
        <!--        <p *ngIf="address.County"><b>County:</b> {{address.County}}</p>-->
      </p-card>
    </div>
    <div class="p-mt-2">
      <p-card class="parent-p-card">
        <google-map
          height="400px"
          width="100%"
          [zoom]="zoom"
          [center]="center">
          @for (markerPosition of markerPositions; track markerPosition) {
            <map-marker #marker="mapMarker"
              [position]="markerPosition"
            (mapClick)="openInfoWindow(marker)"></map-marker>
          }
          <map-info-window> {{address}} <br />
            {{city}}, {{state}} {{zip}}
          </map-info-window>
        </google-map>
      </p-card>
    </div>
  </div>
}
