<form [formGroup]="mainForm" class="margin-top-10 padding-bottom-50">
  <div class="p-grid p-fluid">
    <div class="col-md-4 col-sm-4 col-xs-12 input-container">
      <app-select  class="full-width" [inputObj]="inputObjOrganization" (emitData)="getOrganizationData($event)"></app-select>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjType" (emitData)="getTypeData($event)"></app-select>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjStatus" (emitData)="getStatusData($event)"></app-select>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjExperience" (emitData)="getExperienceData($event)"></app-select>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12 input-container">
      <app-multi-select class="full-width" [inputObj]="inputObjTrades" (emitData)="getTradesData($event)"></app-multi-select>
    </div>
    <div class="col-md-4 col-sm-3 col-xs-12 input-container">
      <label>Interested in Organizing my Workplace?</label>
      <app-yes-no-select [disabled]="!canTabWrite" [isBoolean]="true" [nullable]="true" (emitData)="mainForm.get('organizeWorkPlace').setValue($event); mainForm.markAsDirty()" [selectedValue]="mainForm.get('organizeWorkPlace').value"></app-yes-no-select>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12 margin-bottom-5">
      <label>Web Referral</label>
      <input pInputText  formControlName="webReferral" tabindex="0">
    </div>
    @if (canTabWrite) {
      <div class="col-md-12 text-align-right">
        <div class="action-buttons float-right margin-top-10">
          @if (isEdit) {
            <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
            (click)="cancel()" [disabled]="!mainForm.dirty"></button>
          } @else {
            <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
            (click)="cancel()"></button>
          }
          @if (!isSaving) {
            <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
              (click)="processData()" [className]="'inline-button'"
            [disabled]="this.mainForm.invalid || !mainForm.dirty || setTradeIds?.length === 0"> </button>
          } @else {
            <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    }
  </div>
</form>
