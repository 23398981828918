<form [formGroup]="mainForm" class="margin-top-10 padding-bottom-50">
  <div class="p-grid p-fluid">
    <div class="col-md-3 col-sm-3 col-xs-12 margin-top-10">
      <app-select class="full-width" [inputObj]="inputObjApprenticeHoursType" (emitData)="getApprenticeHoursTypeData($event)">
      </app-select>
    </div>
    @if(setType) {
      <div class="col-md-2 col-sm-2 col-xs-12 margin-top-10">
        <label>Work Month</label>
        <p-calendar [(ngModel)]="tempWorkMonthDate" appendTo="body" placeholder="mm/yyyy" view="month" dateFormat="mm/yy" [showIcon]="true" yearRange="1950:2030" [ngModelOptions]="{standalone: true}"></p-calendar>

      </div>
      <div class="col-md-2 col-sm-2 col-xs-12 margin-top-10">
        <label>Hours @if(setType.HoursRequired){<sup class="req">*</sup>}</label>
        <p-inputNumber formControlName="hours" [showButtons]="true" [maxFractionDigits]="2" [minFractionDigits]="2" mode="decimal"></p-inputNumber>
      </div>
      @if (setType.Description === 'Employer' || setType.Description === 'AEF') {
        <div class="col-md-2 col-sm-2 col-xs-12 margin-top-10">
          <app-select class="full-width" [inputObj]="inputObjEmployer" (emitData)="getEmployerData($event)"></app-select>
        </div>
      }
      @if (setType.AefRequired) {
        <div class="col-md-3 col-sm-3 col-xs-12 margin-top-10">
          <label>AEF @if(setType.AefRequired){<sup class="req">*</sup>}</label>
          <app-currency-input [initValue]="mainForm.get('aef').value"
                              (emitData)="mainForm.get('aef').setValue($event);"></app-currency-input>
        </div>
      }


      <div class="col-md-12 col-sm-12 col-xs-12  margin-top-10">
        <label>Notes  @if(setType.NotesRequired){<sup class="req">*</sup>}</label>
        <input type="text" pInputText  formControlName="notes">
      </div>
    }

    <div class="col-md-12 text-align-right margin-top-10">
      <div class="action-buttons float-right">
        <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times"
                pTooltip="Cancel" tooltipPosition="bottom"
                (click)="cancel()"></button>
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                  tooltipPosition="bottom"
                  (click)="processData()" [className]="'inline-button'"
                  [disabled]="mainForm.invalid || !mainForm.dirty"></button>
        }
        @if (isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  </div>
</form>
