import {AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {MessageService} from 'primeng/api';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {RosterStudentTabContainerComponent} from '../../../roster-student/roster-student-tab-container/roster-student-tab-container.component';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {ClassStaffService} from '../../../services/class-staff.service';
import {ClassStaffGridModel, ClassStaffModel} from '../../../models/class-staff.model';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-class-tab-staff',
  templateUrl: './class-tab-staff.component.html',
  styleUrls: ['./class-tab-staff.component.scss']
})

export class ClassTabStaffComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  showTable: boolean = false;
  Staff: ClassStaffGridModel;
  StaffList: ClassStaffModel[];
  cols: any[];
  exportColumns: any[];
  loading: boolean;
  isExporting: boolean;
  isButtonDisabled: boolean = true;
  exportItems: any[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  showData: boolean = false;
  showGrid: boolean = false;
  selectedItem: number;
  showAddEdit: boolean;
  dataTotal: number = 0;
  showAdd: boolean = false;
  @Input() ClassID: number;
  @Input() canTabWrite: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() canAdd: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private classStaffService: ClassStaffService,
              private messageService: MessageService,
              private dialogUtility: DialogUtility, private changeDetection: ChangeDetectorRef,
              public dialogService: DialogService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.cols = [
      {
        field: 'StaffID',
        header: 'ID',
        columnType: 'numeric',
        matchMode: 'equals',
        displayType: 'numeric',
        visible: true
      },
      {
        field: 'FirstName',
        header: 'First Name',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'LastName',
        header: 'Last Name',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'MemberNumber',
        header: 'Member #',
        columnType: 'text',
        matchMode: 'equals',
        displayType: 'text',
        visible: true
      },
      {
        field: 'TrainingStatusDescription',
        header: 'Training Center',
        columnType: 'text',
        matchMode: 'contains',
        displayType: 'text',
        visible: true
      },
      {
        field: 'StartDate_D',
        header: 'Start Date',
        columnType: 'date',
        matchMode: 'equals',
        displayType: 'date',
        visible: true
      },
      {
        field: 'EndDate_D',
        header: 'End Date',
        columnType: 'date',
        matchMode: 'equals',
        displayType: 'date',
        visible: true
      },
      {
        field: 'ClassStaffID',
        header: 'Staff #',
        columnType: 'numeric',
        matchMode: 'equals',
        displayType: 'numeric',
        visible: true
      }
    ];
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;

    this.currentFilter.filters = {};
  }

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.showGrid = true;
    if (this.ClassID) {
      this.getData();
    }
  }

  getData(): void {
    this.getTotal();
    this.getList();
    this.showAdd = false;
  }

  getList(): void {
    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.classStaffService.getClassStaffPrimeNG('DATA', this.ClassID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.Staff = res;
          this.StaffList = res.Data;
          if (this.canTabWrite && this.StaffList.length === 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.StaffList.forEach(staff => {
            staff.FullName = staff.FirstName + ' ' + staff.LastName;
          });
          this.loading = false;
        }, error: (e) => {
          this.loading = false;
          console.debug(e);
        }
      });
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.classStaffService.getClassStaffPrimeNG('TOTAL', this.ClassID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  openStaff(PersonID) {
    const selectedStaff = this.StaffList.find(x => x.PersonID === PersonID);
    const ref = this.dialogService.open(RosterStudentTabContainerComponent, {
      data: {
        id: PersonID,
        isEdit: true,
        isDialog: true
      },
      header: selectedStaff.FirstName + ' ' + selectedStaff.LastName,
      width: '90%'
    });
    console.log(ref);
  }

  delete(classId: number, classStaffId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.classStaffService.deleteClassStaff(classId, classStaffId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected staff has been removed from this class.'
            });
            this.updateList();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList(): void {
    this.showAddEdit = false;
    this.getData();
    this.selectedItem = -1;
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterItems(): void {
    this.loading = true;
    // this.initFilters('FirstName', this.activeGlobalFilter, 'contains', 'and');
    this.initFilters('LastName', this.activeGlobalFilter, 'contains', 'and');
    this.getData();
  }

  clearFilters(): void {
    this.loading = true;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  loadList(): void {
    /*this.classWageRateService.getClassWageRateKGrid().then(() => {
      this.loading = false;
      this.showAddEdit = false;
    });*/
  }

  refresh(): void {
    this.getData();
  }

  expandData(index, id): void {
    this.showData = !this.showData;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
    console.log(id);
  }

  resetComponent(): void {
    this.StaffList = undefined;
    this.Staff = undefined;
    this.cols = [];
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.StaffList));
        const dataToExport = removeKeysFromArray(arr, ['ClassStaffID', 'PersonID', 'TrainingStatusID', 'ResultID', 'CanEdit', 'CanDelete',
          'PrimaryAddress3', 'PrimaryAddress4', 'PrimaryAddress5', 'PrimaryAddress6', 'FullName']);
        exportData('class-staff', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.classStaffService.getClassStaffPrimeNG('DATA', this.ClassID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['ClassStaffID', 'PersonID', 'TrainingStatusID', 'ResultID', 'CanEdit', 'CanDelete',
                'PrimaryAddress3', 'PrimaryAddress4', 'PrimaryAddress5', 'PrimaryAddress6', 'FullName']);
              exportData('class-staff', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
