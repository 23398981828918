
<div class="page-container">
  <div class="public-card">
    <p-card class="card">
      @if (logo) {
        <div class="align-center">
          <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true" />
        </div>
      }
      <div class="page-container">
        @if (verifyPath) {
          <div>
            <h2>Link Account</h2>
            <p>Please provide the following so your account may be appropriately linked. Many thanks.</p>
            <form [formGroup]="mainForm" class="user-registration-form">
              <div class="p-grid p-fluid">
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="p-fieldset-content p-mt-2">
                    <label>Date Of Birth <sup class="req">*</sup></label>
                    <app-universal-date-picker [selectedValue]="mainForm.get('dateOfBirth').value" (emitData)="mainForm.get('dateOfBirth').setValue($event)"></app-universal-date-picker>
                    <!--                    <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="dateOfBirth" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025"></p-calendar>-->
                    @if ((mainForm.controls.dateOfBirth.dirty || mainForm.controls.dateOfBirth.touched) &&
                      mainForm.controls.dateOfBirth.errors?.required) {
                      <span class="p-error">'Date Of Birth' must be provided</span>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Postal Code <sup class="req">*</sup></label>
                    <input type="text" pInputText  formControlName="postalCode">
                    @if ((mainForm.controls.postalCode.dirty || mainForm.controls.postalCode.touched) &&
                      mainForm.controls.postalCode.errors?.required) {
                      <span class="p-error">'Postal Code' must be provided</span>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2 mask-container">
                    <label>Mobile Phone # <sup class="req">*</sup></label>
                    <p-inputMask type="text" pInputText formControlName="primaryPhoneNumber" [className]="'form-input no-border'" mask="(999) 999-9999"
                    unmask="true"></p-inputMask>
                    @if ((mainForm.controls.primaryPhoneNumber.dirty || mainForm.controls.primaryPhoneNumber.touched) &&
                      mainForm.controls.primaryPhoneNumber.errors?.required) {
                      <span class="p-error">'Mobile Phone #' must be provided</span>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Member #</label>
                    <input type="text" pInputText  formControlName="memberNumber">
                    <p>(Not Required)</p>
                  </div>
                </div>
              </div>
              <div class="tab-form-buttons p-mt-2">
                @if (!isVerifying) {
                  <button pButton label="Link Account" class="p-button-info p-mr-2" pTooltip="Link Account" tooltipPosition="bottom"
                  (click)="verify()" [className]="'inline-button'" [disabled]="mainForm.invalid || !mainForm.touched"></button>
                }
                @if (isVerifying) {
                  <button pButton label="Link Account" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                    <i class="pi pi-spin pi-spinner save-spinner"></i>
                  </button>
                }
              </div>
            </form>
          </div>
        }
        @if (unlinkedPath) {
          <div>
            <h2>More Details Needed</h2>
            <p>We're close, but have one additional request. Please provide the following so your account may be appropriately linked.</p>
            <form [formGroup]="mainFormUnlinked" class="user-registration-form">
              <div class="p-grid p-fluid">
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="p-fieldset-content p-mt-2">
                    <label>First Name <sup class="req">*</sup></label>
                    <input type="text" pInputText  formControlName="firstName">
                    @if ((mainFormUnlinked.controls.firstName.dirty || mainFormUnlinked.controls.firstName.touched) &&
                      mainFormUnlinked.controls.firstName.errors?.required) {
                      <span class="p-error">'First Name' must be provided</span>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Last Name <sup class="req">*</sup></label>
                    <input type="text" pInputText  formControlName="lastName">
                    @if ((mainFormUnlinked.controls.lastName.dirty || mainFormUnlinked.controls.lastName.touched) &&
                      mainFormUnlinked.controls.lastName.errors?.required) {
                      <span class="p-error">'Last Name' must be provided</span>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="setOrganizationData($event)">
                    </app-select>
                  </div>
                  <div class="p-fieldset-content tab-form-buttons p-mt-2">
                    @if (!isVerifying) {
                      <button pButton label="Submit" class="p-button-info p-mr-2" pTooltip="Submit" tooltipPosition="bottom"
                      (click)="attemptToLink()" [className]="'inline-button'" [disabled]="mainFormUnlinked.invalid || !mainFormUnlinked.touched"></button>
                    }
                    @if (isVerifying) {
                      <button pButton label="Submit" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                        <i class="pi pi-spin pi-spinner save-spinner"></i>
                      </button>
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
      </div>
    </p-card>
  </div>
  <p-toast [baseZIndex]="16000"></p-toast>
</div>
