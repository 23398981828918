import {Component, Input, OnChanges, OnInit} from '@angular/core';

import {GenericSelectModel} from '../../models/generic-select.model';
import {LookupsDataService} from '../../services/lookups-data.service';
import {ContextService} from '../../services/context.service';
import {TenantConfigDataService} from '../../services/tenant-config-data.service';
import {RosterService} from '../../services/roster.service';
import {PageModel} from '../../models/page.model';
import {ActivatedRoute} from '@angular/router';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {PageService} from '../../services/page.services';
import {RosterLiteModel} from '../../models/roster.model';
import {RosterDefinitions} from '../../shared/definitions/roster.definition';
import {PrimeTableFilterModel} from '../../models/table-filter.model';
import {PageRowCountUtility} from '../../shared/utilities/page-row-count.utility';

@Component({
  templateUrl: './membership-cards.component.html',
  styleUrls: ['./membership-cards.component.scss'],
})

export class MembershipCardsComponent implements OnInit, OnChanges {
  InterfaceObjectEnum: string = 'MEMBERSHIPCARD';
  context = this.contextService.contextObject;
  membershipCardsList: RosterLiteModel[];
  dataTotal: number = 0;
  inputData: any;
  personIDList: number[] = [];
  loading: boolean = false;
  showTable: boolean;
  cols: any[];
  selMembershipCardUser: any[] = [];
  inputObjOrganization: GenericSelectModel;
  setOrganizationId: number;
  inputObjRosterQuery: GenericSelectModel;
  setRosterQueryId: number;
  currentFilter = new PrimeTableFilterModel();
  getListRequest;
  getTotalRequest;
  @Input() ContentID: number;
  isDialog: boolean = false;
  private _clientId: string = this.contextService.contextObject.clientId;
  private pageInfo = {} as PageModel;

  constructor(private lookupsDataService: LookupsDataService, private contextService: ContextService,
              private tenantConfigDataService: TenantConfigDataService, private rosterService: RosterService,
              private route: ActivatedRoute, public config: DynamicDialogConfig,
              private pageService: PageService, private rosterDefinitions: RosterDefinitions,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isDialog = this.pageInfo.isDialog;
          this.ContentID = this.pageInfo.ContentID;
          this.personIDList.push(this.ContentID);
        }
        if (this.isDialog) {
          this.setInputData();
        } else {
          this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
          this.currentFilter.sortOrder = 1;
          this.currentFilter.first = 0;
          this.currentFilter.filters = {};
          this.currentFilter.filters = {
            StatusAlias: [
              {
                value: 'Active',
                matchMode: 'equals',
                operator: 'or'
              }
            ]
          };
          this.initOrganization(false, this.InterfaceObjectEnum, true);
          this.cols = this.rosterDefinitions.rosterLiteCols.filter(col => col.visible === true);
        }
      }
    });

    this.pageService.getPagePermissions(this.InterfaceObjectEnum).then(() => {
    });
  }

  // reload if params change
  ngOnChanges(): void {
    if (this.isDialog) {
      this.setInputData();
    } else {
      this.getData();
    }
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  setOrganizationData(event:any): void {
    this.showTable = false;
    if (event && event[0]) {
      this.setOrganizationId = event[0].ID;
    } else {
      this.setOrganizationId = null;
    }
    if (this.setOrganizationId !== null) {
      this.setRosterQueryId = 0;
      this.initRosterQuery(false);
      this.currentFilter.filters = {};
      this.currentFilter.filters = {
        StatusAlias: [
          {
            value: 'Active',
            matchMode: 'equals',
            operator: 'or'
          }
        ]
      };
      this.getData();
      this.showTable = true;
    }
    this.loading = true;
  }

  initRosterQuery(disable: boolean): void {
    this.inputObjRosterQuery = {
      labelText: 'Distribution List',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setRosterQueryId,
      disabled: disable,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getRosterQueryByOrganizationLookupData(this.setOrganizationId).then((lookupData) => {
      this.inputObjRosterQuery.data = lookupData;
      this.inputObjRosterQuery = Object.assign({}, this.inputObjRosterQuery);
    });
  }

  setRosterQueryData(event:any): void {
    if (event && event[0]) {
      this.setRosterQueryId = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setRosterQueryId = 0;
    }
    this.getData();
  }

  getData(): void {
    this.setInputData();
    this.getList();
    this.getTotal();
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.personIDList = [];
    this.selMembershipCardUser = [];
    this.getListRequest = this.rosterService.getRosterLitePrimeNG('DATA', this.currentFilter, this.setRosterQueryId, this.setOrganizationId)
      .pipe()
      .subscribe({
        next: (res) => {
          this.membershipCardsList = res.Data;
          this.loading = false;
        }, error: (e) => {
          console.debug(e);
        }
      });
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.rosterService.getRosterLitePrimeNG('TOTAL', this.currentFilter, this.setRosterQueryId, this.setOrganizationId)
      .pipe()
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }, error: (e) => {
          console.debug(e);
        }
      });
  }

  setInputData(): void {
    const MEMBERSHIPCARDREPORTNAME = this.tenantConfigDataService.getStringValue('MEMBERSHIPCARDREPORTNAME');
    if (!this.isDialog) {
      this.getListIds();
    }
    this.inputData = {
      isValid: this.validateForm(),
      reportName: MEMBERSHIPCARDREPORTNAME,
      parameters: {
        client_id: this._clientId,
        // eslint-disable-next-line
        json: JSON.stringify({'PersonIDList': this.personIDList}),
        p_id: this.context.PersonId,
        // o_id: (this.setOrganizationId) ? this.setOrganizationId : 0,
        sasToken: this.context.blobToken
      }
    };
  }

  validateForm(): boolean {
    return this.setOrganizationId !== null;
  }

  loadTable(event?) {
    this.getData();
  }

  getListIds(): void {
    this.personIDList = [];
    for (const list of this.selMembershipCardUser) {
      this.personIDList.push(list.PersonID);
    }
  }
}
