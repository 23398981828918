<div class="p-fluid p-grid">
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
    <form [formGroup]="mainForm">
      <div class="p-grid p-fluid">
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>Position <sup class="req">*</sup></label>
          <input type="text" pInputText  formControlName="position">
          @if ((mainForm.controls.position.dirty || mainForm.controls.position.touched) &&
            mainForm.controls.position.errors?.required) {
            <span class="error">'Position' must be provided</span>
          }
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>Abbreviation <sup class="req">*</sup></label>
          <input type="text" pInputText  formControlName="abbreviation">
          @if ((mainForm.controls.abbreviation.dirty || mainForm.controls.abbreviation.touched) &&
            mainForm.controls.abbreviation.errors?.required) {
            <span class="error">'Abbreviation' must be provided</span>
          }
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
          <p-checkbox binary="true" label="Active" formControlName="active"></p-checkbox>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <h3>Permissions for this Position</h3>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        Application rights are given to users through their assigned position(s). Choose from the options below.
      </div>
      <div class="p-fluid p-grid margin-top-10">
        @for (permission of permissions; track permission; let i = $index) {
          <div class="col-md-5 col-sm-6 col-xs-12 margin-top-10">
            <label>Role</label>
            <input type="text" pInputText  [(ngModel)]="permissions[i].Description" [value]="permission.Description" [disabled]="true" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="col-md-5 col-sm-6 col-xs-12 margin-top-10">
            <label>Organizational Access <sup class="req">*</sup></label>
            @if (permission.Description === 'Billing') {
              <p-dropdown [options]="accessOptions" [(ngModel)]="permission.SelectionStatus" optionLabel="option" optionValue="value" (onChange)="updateList(i, $event)"
              [disabled]="!permission.CanEdit" [ngModelOptions]="{standalone: true}"></p-dropdown>
            }
            @if (permission.Description !== 'Billing') {
              <p-dropdown [options]="accessOptions" [(ngModel)]="permission.SelectionStatus" optionLabel="option" optionValue="value" (onChange)="updateList(i, $event)"
              [disabled]="!permission.CanEdit" [ngModelOptions]="{standalone: true}"></p-dropdown>
            }
          </div>
        }
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15 text-align-right">
        <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
        (click)="cancel()"></button>
        <button  pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
        (click)="processData()" [className]="'inline-button'" [disabled]="this.mainForm.invalid"></button>
      </div>
    </form>
  </div>
</div>
