<div class="list-container p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <p>The mobile number {{data[0].MobileNumber | phone}} has been opted out by the carrier due to a text response of STOP, STOPALL, UNSUBSCRIBE, CANCEL, END, or QUIT to the phone number(s) below.</p>
    <p>To opt back in to receive text messaging, please send a text message from {{data[0].MobileNumber | phone}} to the number(s) below with the word START, YES, or UNSTOP.</p>
    <p class="bold">*Until this action is taken, the carrier will continue to block all messages to {{data[0].MobileNumber | phone}} from the number(s) below.</p>
  </div>
  @for (detail of data; track detail; let i = $index) {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <p-card [styleClass]="'list-card'">
        <div class="p-grid p-fluid">
          <div class="left-column col-md-12 col-sm-12 col-xs-12">
            <div class="list-header">
              {{detail.SmsServiceAlias}}
            </div>
            <div class="list-subheader">
              {{detail.SmsSenderNumber | phone}}
            </div>
            <div class="list-subheader">
              <b>Opt Out Date:</b> {{detail.OptoutDate | date:'shortDate'}}
            </div>
          </div>
        </div>
      </p-card>
    </div>
  }
</div>
