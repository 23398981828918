import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EmployerModel} from '../../../models/organizations.model';
import {OrganizationService} from '../../../services/organizations.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AddressModel} from '../../../models/addresses.model';
import {transformDateTimeToDateOnly} from '../../../shared/utilities/form.utility';
import {employerContactCustom} from '../../../shared/utilities/component-manager-utility';
import {ContextService} from '../../../services/context.service';

@Component({
  selector: 'app-employers-tab-details',
  templateUrl: './employers-tab-details.component.html',
  styleUrls: ['./employers-tab-details.component.scss']
})

export class EmployersTabDetailsComponent implements OnInit, OnChanges, OnDestroy {
  InterfaceObjectEnum: string = 'EMPPROFILE';
  mainForm: FormGroup;
  inputObjStatus: GenericSelectModel;
  inputObjHomeOrganization: GenericSelectModel;
  setStatusId: number;
  loading: boolean = false;
  isDirty: boolean;
  isSaving: boolean = false;
  employer = {} as EmployerModel;
  setOrganizationId: number;
  setHomeOrganizationId: number;
  inputObjOrganization: GenericSelectModel;
  inputObjEmployerType: GenericSelectModel;
  inputObjEnterpriseType: GenericSelectModel;
  inputObjMinorityStatus: GenericSelectModel;
  inputObjSubdivision: GenericSelectModel;
  inputObjType: GenericSelectModel;
  inputObjClassification: GenericSelectModel;
  setEmployerType: number;
  setClassificationType: number;
  setEnterpriseType: number;
  setMinorityStatus: number;
  setSubdivision: string;
  setNineA: boolean;
  setPension: boolean;
  existingMessage: boolean = false;
  addressData: AddressModel = {} as AddressModel;
  sendJournal: boolean = false;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() ID: number;
  @Input() orgId: number;
  @Input() isOrganize: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();

  public _employerContactCustom = employerContactCustom;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private organizationService: OrganizationService,
              private ref: DynamicDialogRef, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private confirmationService: ConfirmationService,
              private contextService: ContextService) {
    this.mainForm = this.formBuilder.group({
      organization: new FormControl(null),
      status: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      FEIN: new FormControl(null),
      primaryPhoneNumber: new FormControl(null),
      primaryPhoneExtension: new FormControl(null),
      primaryEmail: new FormControl(null, [Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      doingBusinessAs: new FormControl(null),
      statusDate: new FormControl(null),
      employerId: new FormControl(null),
      faxNumber: new FormControl(null),
      website: new FormControl(null),
      primaryContact: new FormControl(null),
      homeOrganization: new FormControl(null),
      employerType: new FormControl(null),
      enterpriseType: new FormControl(null),
      minorityStatus: new FormControl(null),
      subdivision: new FormControl(null),
      nineA: new FormControl(null),
      pensionParticipation: new FormControl(null),
      joinDate: new FormControl(null),
      classification: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    if (this.isEdit) {
      if (this.ID) {
        this.loading = true;
        this.organizationService.getEmployerOrganization(this.ID, 'employer', 1)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.employer = res;
              // this.employers = res;
              this.initForm();
              this.loading = false;
            }, error: () => {
              this.loading = false;
            }
          });
      }
    } else {
      this.setOrganizationId = this.orgId;
      this.initSelects();
      this.addressData.CountryID = null;
      this.addressData.Address1 = null;
      this.addressData.Address2 = null;
      this.addressData.City = null;
      this.addressData.StateProvinceID = null;
      this.addressData.Zip = null;
      this.addressData.ZipPlus4 = null;
      this.addressData.County = null;
      this.addressData.Latitude = null;
      this.addressData.Longitude = null;
      this.addressData.Ready = true;
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: `'${this.employer.Name}' data has been modified. Please save, or all changes will be lost.`,
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initSelects(): void {
    this.initOrganization(false, this.InterfaceObjectEnum, false);
    if (this.isEdit) {
      this.initHomeOrganization(false);
    }
    this.initStatus(false);
    this.initEmployerType(false);
    this.initEnterpriseType(false);
    this.initMinorityStatus(false);
    this.initSubdivision(false);
    this.initEmployerClassification(false);
    // this.mainForm.markAsPristine();
    // this.mainForm.markAsUntouched();
    // if (localStorage.getItem('saveReminder') !== 'No') {
    //   this.mainForm.valueChanges.subscribe({next: () => {
    //     if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
    //       this.existingMessage = true;
    //       this.openDialog();
    //     }
    //   }});
    // }
  }

  initForm(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    if (this.isEdit) {
      this.mainForm.get('organization').setValue(this.employer.ID);
    }
    this.mainForm.get('doingBusinessAs').setValue(this.employer.BusinessName);
    this.mainForm.get('name').setValue(this.employer.Name);
    this.mainForm.get('FEIN').setValue(this.employer.FEIN);
    this.mainForm.get('employerId').setValue(this.employer.Identifier);
    this.setStatusId = this.employer.StatusID;
    this.mainForm.get('status').setValue(this.employer.StatusID);
    this.setHomeOrganizationId = this.employer.HomeOrganizationID;
    this.mainForm.get('primaryPhoneNumber').setValue(this.employer.PhoneNumber);
    this.mainForm.get('primaryPhoneExtension').setValue(this.employer.PhoneExtension);
    this.mainForm.get('primaryEmail').setValue(this.employer.EmailAddress);
    if (this.employer.StatusEffectiveDate) {
      const statusDate = new Date(this.employer.StatusEffectiveDate);
      this.mainForm.get('statusDate').setValue(statusDate);
    }
    this.initStatus(false);
    this.mainForm.get('faxNumber').setValue(this.employer.FaxNumber);
    this.mainForm.get('website').setValue(this.employer.WebsiteUri);
    this.mainForm.get('primaryContact').setValue(this.employer.PrimaryContact);
    this.mainForm.get('homeOrganization').setValue(this.employer.HomeOrganizationID);
    this.mainForm.get('employerType').setValue(this.employer.EmployerTypeID);
    this.setEmployerType = this.employer.EmployerTypeID;
    this.mainForm.get('enterpriseType').setValue(this.employer.EnterpriseTypeID);
    this.mainForm.get('classification').setValue(this.employer.ClassificationID);
    this.setClassificationType = this.employer.ClassificationID;
    this.setEnterpriseType = this.employer.EnterpriseTypeID;
    this.mainForm.get('minorityStatus').setValue(this.employer.MinorityStatusID);
    this.setMinorityStatus = this.employer.MinorityStatusID;
    this.mainForm.get('nineA').setValue(this.employer.Language9a);
    this.setNineA = this.employer.Language9a;
    this.mainForm.get('pensionParticipation').setValue(this.employer.PensionParticipating);
    this.setPension = this.employer.PensionParticipating;
    if (this.employer.JoinDate) {
      const joinDate = new Date(this.employer.JoinDate);
      this.mainForm.get('joinDate').setValue(joinDate);
    }
    if (this.employer.Subdivision) {
      this.mainForm.get('subdivision').setValue('Subdivision');
      this.setSubdivision = 'Subdivision';
    } else {
      this.mainForm.get('subdivision').setValue('Subsidiary');
      this.setSubdivision = 'Subsidiary';
    }

    this.addressData.CountryID = this.employer.CountryID;
    this.addressData.Address1 = this.employer.Address1;
    this.addressData.Address2 = this.employer.Address2;
    this.addressData.City = this.employer.City;
    this.addressData.StateProvinceID = this.employer.StateProvinceID;
    this.addressData.Zip = this.employer.Zip;
    this.addressData.ZipPlus4 = this.employer.ZipPlus4;
    this.addressData.County = this.employer.County;
    this.addressData.Latitude = this.employer.Latitude;
    this.addressData.Longitude = this.employer.Longitude;
    this.addressData.Ready = true;

    this.initSelects();
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: (!this.setOrganizationId),
      initSelected: this.setOrganizationId,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });

  }

  setOrganizationData(event: any): void {
    if (event && event[0]) {
      this.setHomeOrganizationId = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setHomeOrganizationId = 0;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('organization').setValue(this.setOrganizationId);
  }

  initHomeOrganization(disable: boolean): void {
    this.inputObjHomeOrganization = {
      labelText: 'Home Organization',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setHomeOrganizationId,
      disabled: disable,
    };
    this.lookupsDataService.getOrganizationByChildLookupData(this.employer.ID, true).then((lookupData) => {
      this.inputObjHomeOrganization.data = lookupData;
      this.inputObjHomeOrganization = Object.assign({}, this.inputObjHomeOrganization);
    });
  }

  setHomeOrganizationData(event: any): void {
    if (event && event[0]) {
      this.setHomeOrganizationId = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setHomeOrganizationId = 0;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('homeOrganization').setValue(this.setHomeOrganizationId);
  }

  initStatus(disable: boolean): void {
    let tempData;
    this.lookupsDataService.getOrganizationStatusesLookupData('employer').then((lookupData: any[]) => {
      tempData = lookupData;
      if (this.isOrganize && tempData.find(x => x.Description === 'Active Non-Union')) {
        this.setStatusId = tempData.find(x => x.Description === 'Active Non-Union').ID;
        this.mainForm.get('status').setValue(this.setStatusId);
      }
      this.inputObjStatus = {
        labelText: 'Status',
        optionValue: 'ID',
        optionLabel: 'Description',
        filter: false,
        requiredField: true,
        selectFirstValue: false,
        initSelected: this.setStatusId,
        disabled: this.isOrganize,
        canWrite: this.canTabWrite,
        data: tempData,
        customSelect: 'ShowInactiveIcon'
      };
    });
  }

  getStatusData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setStatusId = event[0].ID;
    } else {
      this.setStatusId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('status').setValue(this.setStatusId);
  }

  initEmployerType(disable: boolean): void {
    this.inputObjEmployerType = {
      labelText: 'Employer Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEmployerType,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getEmployerTypesLookupData(true).then((lookupData) => {
      this.inputObjEmployerType.data = lookupData;
      this.inputObjEmployerType = Object.assign({}, this.inputObjEmployerType);
    });
  }

  setEmployerTypeData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setEmployerType = event[0].ID;
    } else {
      this.setEmployerType = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('employerType').setValue(this.setEmployerType);
  }

  initEmployerClassification(disable: boolean): void {
    this.inputObjClassification = {
      labelText: 'Classification',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setClassificationType,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getEmployerClassificationsLookupData('EMPLOYER', true).then((lookupData) => {
      this.inputObjClassification.data = lookupData;
      this.inputObjClassification = Object.assign({}, this.inputObjClassification);
    });
  }

  setEmployerClassificationData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setClassificationType = event[0].ID;
    } else {
      this.setClassificationType = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('classification').setValue(this.setClassificationType);
  }

  initEnterpriseType(disable: boolean): void {
    this.inputObjEnterpriseType = {
      labelText: 'Enterprise Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEnterpriseType,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getEnterpriseTypesLookupData(true).then((lookupData) => {
      this.inputObjEnterpriseType.data = lookupData;
      this.inputObjEnterpriseType = Object.assign({}, this.inputObjEnterpriseType);
    });
  }

  setEnterpriseTypeData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setEnterpriseType = event[0].ID;
    } else {
      this.setEnterpriseType = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('enterpriseType').setValue(this.setEnterpriseType);
  }

  initMinorityStatus(disable: boolean): void {
    this.inputObjMinorityStatus = {
      labelText: 'Minority Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setMinorityStatus,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getMinorityStatusLookupData(true).then((lookupData) => {
      this.inputObjMinorityStatus.data = lookupData;
      this.inputObjMinorityStatus = Object.assign({}, this.inputObjMinorityStatus);
    });
  }

  setMinorityStatusData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setMinorityStatus = event[0].ID;
    } else {
      this.setMinorityStatus = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('minorityStatus').setValue(this.setMinorityStatus);
  }

  initSubdivision(disable: boolean): void {
    this.inputObjSubdivision = {
      labelText: 'Subdivision/Subsidiary',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setSubdivision,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.inputObjSubdivision.data = [
      {
        ID: 1,
        Description: 'Subdivision'
      },
      {
        ID: 2,
        Description: 'Subsidiary'
      },

    ];
    this.inputObjEnterpriseType = Object.assign({}, this.inputObjEnterpriseType);
  }

  setSubdivisionData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setSubdivision = event[0].ID;
    } else {
      this.setSubdivision = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('subdivision').setValue(this.setSubdivision);
  }

  processData() {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      if (this.isEdit) {
        this.employer.HomeOrganizationID = this.setHomeOrganizationId;
      }
      this.employer.Name = this.mainForm.get('name').value;
      this.employer.BusinessName = this.mainForm.get('doingBusinessAs').value;
      this.employer.FEIN = this.mainForm.get('FEIN').value;
      this.employer.Identifier = this.mainForm.get('employerId').value;
      this.employer.StatusID = this.mainForm.get('status').value;
      this.employer.CountryID = this.addressData.CountryID;
      this.employer.Address1 = this.addressData.Address1;
      this.employer.Address2 = this.addressData.Address2;
      this.employer.City = this.addressData.City;
      this.employer.StateProvinceID = this.addressData.StateProvinceID;
      this.employer.Zip = this.addressData.Zip;
      this.employer.ZipPlus4 = this.addressData.ZipPlus4;
      this.employer.County = this.addressData.County;
      this.employer.Latitude = this.addressData.Latitude;
      this.employer.Longitude = this.addressData.Longitude;
      this.employer.PhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
      this.employer.PhoneExtension = this.mainForm.get('primaryPhoneExtension').value;
      this.employer.EmailAddress = this.mainForm.get('primaryEmail').value;
      this.employer.ClassificationID = this.mainForm.get('classification').value;
      this.employer.FaxNumber = this.mainForm.get('faxNumber').value;
      this.employer.WebsiteUri = this.mainForm.get('website').value;
      this.employer.PrimaryContact = this.mainForm.get('primaryContact').value;
      this.employer.EmployerTypeID = this.setEmployerType;
      this.employer.EnterpriseTypeID = this.setEnterpriseType;
      this.employer.MinorityStatusID = this.setMinorityStatus;
      this.employer.Language9a = this.setNineA;
      this.employer.PensionParticipating = this.setPension;
      if (this.mainForm.get('statusDate').value) {
        this.employer.StatusEffectiveDate = transformDateTimeToDateOnly(this.mainForm.get('statusDate').value.toString());
      }
      if (this.mainForm.get('joinDate').value) {
        this.employer.JoinDate = transformDateTimeToDateOnly(this.mainForm.get('joinDate').value.toString());
      }
      if (this.mainForm.get('subdivision').value === 'Subdivision') {
        this.employer.Subdivision = true;
        this.employer.Subsidiary = false;
      } else {
        this.employer.Subsidiary = true;
        this.employer.Subdivision = false;
      }
      this.saveForm();
    }
  }

  saveForm() {
    if (!this.isEdit) {
      this.organizationService.createEmployer(this.setOrganizationId, this.employer)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            // this.ref.destroy();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Your employer has been successfully added'
            });
            this.isSaving = false;
            this.closeAddEdit.emit({employer: res, isContractEmployer: true});
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    } else {
      this.organizationService.updateOrganization(this.ID, this.employer)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.ref.destroy();
            this.confirmationService.close();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Employers has been updated'
            });
            // this.updateLocalData();
            this.mainForm.markAsPristine();
            this.mainForm.markAsUntouched();
            this.isSaving = false;
            this.existingMessage = false;
            this.closeAddEdit.emit({employer: null, isContractEmployer: true});
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    this.addressData = {} as AddressModel;
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  setAddress(addressChanges: AddressModel): void {
    if (addressChanges) {
      this.mainForm.markAsDirty();
      this.addressData.CountryID = addressChanges.CountryID;
      this.addressData.Address1 = addressChanges.Address1;
      this.addressData.Address2 = addressChanges.Address2;
      this.addressData.City = addressChanges.City;
      this.addressData.StateProvinceID = addressChanges.StateProvinceID;
      this.addressData.Zip = addressChanges.Zip;
      this.addressData.ZipPlus4 = addressChanges.ZipPlus4;
      this.addressData.County = addressChanges.County;
      this.addressData.Latitude = addressChanges.Latitude;
      this.addressData.Longitude = addressChanges.Longitude;
    }
  }
}
