import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RosterQueryService} from '../../../../services/roster-queries.service';
import {LookupModel} from '../../../../models/lookups.model';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {take} from 'rxjs/operators';
import {Dropdown} from 'primeng/dropdown';

@Component({
  selector: 'app-dist-list-select',
  templateUrl: './dist-list-select.component.html'
})
export class DistListSelectComponent implements OnInit, OnChanges, AfterViewInit {
  showFilter: boolean = false;
  selectForm: FormGroup;
  selectData: LookupModel[];
  virtScroll: boolean;
  selectedItem: number;
  filters;

  @Input() inputObj: GenericSelectModel;
  @Input() newValue: any;
  @Input() tabOrder: number;
  @Output() emitData = new EventEmitter<any>();
  @Output() emitBlur = new EventEmitter<any>();

  @ViewChild(Dropdown) multiSelect: Dropdown;

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef,
              private rosterQueryService: RosterQueryService) {
    this.selectForm = this.formBuilder.group({
      fcnSelect: [null]
    });
  }

  // required to listen to form controls
  get registerFormControl(): any {
    return this.selectForm.controls;
  }

  ngOnInit(): void {

  }

  // reload if params change
  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputObj) {
      this.selectForm.markAsPristine();
      this.selectForm.markAsUntouched();
      this.loadSelect();
      if (this.inputObj.canWrite) {
        if (this.inputObj.disabled === true) {
          this.selectForm.get('fcnSelect').disable();
        } else {
          this.selectForm.get('fcnSelect').enable();
        }
      } else {
        this.selectForm.get('fcnSelect').disable();
      }
    } else {
      this.selectForm.get('fcnSelect').disable();
    }
    if (this.inputObj?.requiredField) {
      this.selectForm.get('fcnSelect').setValidators(Validators.required);
      this.selectForm.markAsPristine();
      this.selectForm.markAsUntouched();
      this.selectForm.get('fcnSelect').updateValueAndValidity();
    } else {
      this.selectForm.get('fcnSelect').clearValidators();
      this.selectForm.markAsPristine();
      this.selectForm.markAsUntouched();
      this.selectForm.get('fcnSelect').updateValueAndValidity();
    }
    if (changes.newValue) {
      if (this.inputObj && this.newValue && this.inputObj.initSelected !== this.newValue) {
        this.selectForm.get('fcnSelect').setValue(this.newValue);
        this.emitDataToParent(true);
      }
    }
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  loadSelect(): void {
    if (this.inputObj.data) {
      this.selectData = this.inputObj.data;
      if (this.inputObj.data.length > 100) {
        this.virtScroll = true;
      }
    }
    if (this.inputObj.requiredField) {
      this.selectForm.get('fcnSelect').setValidators([Validators.required]);
    }
    if (this.inputObj.selectFirstValue) {
      let id: number;
      let desc: string;
      if (this.selectData) {
        for (let i = 0, ilen = this.selectData.length; i < ilen; i++) {
          if (this.inputObj.optionValue === 'Description') {
            desc = this.selectData[i].Description;
            if (desc !== '--') {
              break;
            }
          } else {
            id = this.selectData[i].ID;
            if (id) {
              break;
            }
          }
        }
        switch (this.inputObj.optionValue) {
          case 'ID':
            this.selectForm.get('fcnSelect').setValue(id);
            break;
          case 'Description':
            this.selectForm.get('fcnSelect').setValue(desc);
            break;
        }
      }
    } else {
      if (this.inputObj.initSelected !== null && this.inputObj.initSelected !== undefined) {
        this.selectForm.get('fcnSelect').setValue(this.inputObj.initSelected);
      } else {
        this.selectForm.get('fcnSelect').setValue(null);
      }
    }
    if (this.selectData) {
      this.showFilter = Object.keys(this.selectData).length > 10;
      if (this.inputObj.emitChangeOnLoad === undefined) {
        this.emitDataToParent(false);
      } else {
        this.emitDataToParent(this.inputObj.emitChangeOnLoad);
      }
    }
    if (this.inputObj.canWrite === undefined) {
      this.inputObj.canWrite = true;
    }
  }

  emitDataToParent(emit: boolean): void {
    if (emit && this.selectData) {
      let activeSelection;
      switch (this.inputObj.optionValue) {
        case 'ID':
          activeSelection = this.selectData.filter(x => x.ID === this.selectForm.get('fcnSelect').value);
          break;
        case 'Description':
          activeSelection = this.selectData.filter(x => x.Description === this.selectForm.get('fcnSelect').value);
          break;
      }
      if (activeSelection) {
        this.emitData.emit(activeSelection);
      } else {
        this.emitData.emit(null);
      }
    }
  }

  getRosterQueryDetails(id: number,) {
    if (id) {
      const i = this.selectData.findIndex(x => x.ID === id);
      this.selectedItem = i;
      this.rosterQueryService.getRosterQuery(id)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.filters = res.Filters;
          }
        });
    }
  }

  showIs(selectedFilter) {
    return selectedFilter.matchMode === 'gte' || selectedFilter.matchMode === 'lte' || selectedFilter.matchMode === 'gt' ||
      selectedFilter.matchMode === 'le';
  }
}
