import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {ConfirmationService, MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {OrganizationService} from '../../../../services/organizations.service';
import {OrganizationConfigurationList, OrganizationModel} from '../../../../models/organizations.model';
import {ResourcesService} from '../../../../services/resources.service';
import {ContextService} from '../../../../services/context.service';

@Component({
  selector: 'app-organization-configure-tab',
  templateUrl: './organization-configure-tab.component.html',
  styleUrls: ['./organization-configure-tab.component.scss']
})
export class OrganizationConfigureTabComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  organization: OrganizationModel;
  ConfigurationList: OrganizationConfigurationList;
  fd: FormData = new FormData();
  configureCheckBox: boolean;
  isProvisionSMSNumber: boolean;
  isSaving: boolean = false;
  ProvisionSMSNumber: string;
  isLogo: boolean = false;
  isBanner: boolean = false;
  logoImage: string;
  bannerImage: string;
  canProvisionSMSNumber: boolean = false;
  existingMessage: boolean = false;
  configALLOWUNLAYER: boolean = false;
  configCERTEXPWARNING: boolean = false;
  configCLASSNOTIFICATION: boolean = false;
  configSENDBILLPOSTEDEMAIL: boolean = false;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() OrganizationID: number;
  @Input() orgCategory: string;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;

  private ngUnsubscribe = new Subject();

  constructor(private messageService: MessageService,
              private changeDetection: ChangeDetectorRef,
              private lookupsDataService: LookupsDataService, private contextService: ContextService,
              private formBuilder: FormBuilder, private organizationService: OrganizationService,
              private resourceService: ResourcesService, private confirmationService: ConfirmationService) {

    this.mainForm = this.formBuilder.group({
      // configureCheckBox: new FormControl(''),
      isProvisionSMSNumber: new FormControl(''),
      ProvisionSMSNumber: new FormControl(''),
      allowUnlayer: new FormControl(false),
      certExpWarning: new FormControl(false),
      classNotification: new FormControl(false),
      sendBillPostedEmail: new FormControl(false)
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    this.getData();
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Configuration data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.updateData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  getData(): void {
    this.organizationService.getOrganization(this.OrganizationID, this.orgCategory, 1)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.mainForm.markAsPristine();
          this.mainForm.markAsUntouched();
          this.organization = res;
          if (res.LogoUrl != null) {
            this.isLogo = true;
            this.logoImage = res.LogoUrl;
          }

          if (res.BannerUrl != null) {
            this.isBanner = true;
            this.bannerImage = res.BannerUrl;
          }

          if (res.ConfigurationList.length > 0) {
            res.ConfigurationList.forEach(x => {
              switch (x.Enumerator) {
                case 'ALLOWUNLAYER':
                  this.configALLOWUNLAYER = true;
                  this.mainForm.get('allowUnlayer').setValue((x.ValueBoolean !== null) ? x.ValueBoolean : false);
                  break;
                case 'CERTEXPWARNING':
                  this.configCERTEXPWARNING = true;
                  this.mainForm.get('certExpWarning').setValue((x.ValueBoolean !== null) ? x.ValueBoolean : false);
                  break;
                case 'CLASSNOTIFICATION':
                  this.configCLASSNOTIFICATION = true;
                  this.mainForm.get('classNotification').setValue((x.ValueBoolean !== null) ? x.ValueBoolean : false);
                  break;
                case 'SENDBILLPOSTEDEMAIL':
                  this.configSENDBILLPOSTEDEMAIL = true;
                  this.mainForm.get('sendBillPostedEmail').setValue((x.ValueBoolean !== null) ? x.ValueBoolean : false);
                  break;
              }
            });
          } else {
            this.mainForm.get('certExpWarning').setValue(false);
          }
          this.canProvisionSMSNumber = res.CanProvisionSMSNumber;
          this.mainForm.get('isProvisionSMSNumber').setValue(res.ProvisionSMSNumber);
          this.mainForm.get('ProvisionSMSNumber').setValue(res.ProvisionSMSNumberPrefix);
        }
      });
  }

  CanProvisionSMSNumber(event: any): void {
    this.organization.ProvisionSMSNumber = event.checked;
    this.mainForm.get('isProvisionSMSNumber').setValue(event.checked);
    if (!this.mainForm.get('isProvisionSMSNumber').value) {
      this.mainForm.get('ProvisionSMSNumber').setValue('');
      this.mainForm.get('ProvisionSMSNumber').disable();
    } else {
      this.organization.ProvisionSMSNumberPrefix = this.mainForm.get('ProvisionSMSNumber').value;
      this.mainForm.get('ProvisionSMSNumber').enable();
    }
  }

  refresh(): void {
    this.getData();
  }

  uploadLogo(event: any): void {
    if (event.files.length > 0) {
      this.fd.append('logo', event.files[0]);
    }
    this.updateOrganizationLogoBanner(this.OrganizationID, 'Logo', this.fd);
  }

  uploadBanner(event: any): void {
    if (event.files.length > 0) {
      this.fd.append('banner', event.files[0]);
    }
    this.updateOrganizationLogoBanner(this.OrganizationID, 'Banner', this.fd);
  }

  removeLogo(): void {
    this.isLogo = false;
    this.deleteResource('ORGANIZATIONLOGO', this.OrganizationID);
  }

  removeBanner(): void {
    this.isBanner = false;
    this.deleteResource('ORGANIZATIONBANNER', this.OrganizationID);
  }

  updateData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;

      if (this.organization.ConfigurationList && this.organization.ConfigurationList.length > 0) {
        let i: number = 0;
        this.organization.ConfigurationList.forEach(x => {
          switch (x.Enumerator) {
            case 'ALLOWUNLAYER':
              this.organization.ConfigurationList[i].ValueBoolean = this.mainForm.get('allowUnlayer').value;
              break;
            case 'CERTEXPWARNING':
              this.organization.ConfigurationList[i].ValueBoolean = this.mainForm.get('certExpWarning').value;
              break;
            case 'CLASSNOTIFICATION':
              this.organization.ConfigurationList[i].ValueBoolean = this.mainForm.get('classNotification').value;
              break;
            case 'SENDBILLPOSTEDEMAIL':
              this.organization.ConfigurationList[i].ValueBoolean = this.mainForm.get('sendBillPostedEmail').value;
              break;
          }
          i++;
        });
      }
      this.organization.ProvisionSMSNumber = this.mainForm.get('isProvisionSMSNumber').value;
      if (!this.mainForm.get('isProvisionSMSNumber').value) {
        this.organization.ProvisionSMSNumberPrefix = '';
      } else {
        this.organization.ProvisionSMSNumberPrefix = this.mainForm.get('ProvisionSMSNumber').value;
      }

      this.organizationService.updateOrganization(this.OrganizationID, this.organization)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.confirmationService.close();
            this.mainForm.markAsUntouched();
            this.mainForm.markAsPristine();
            this.isSaving = false;
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your configure section has been updated.'});
            this.existingMessage = false;
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 4000});
            } else {
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
            }
            this.isSaving = false;
          }
        });
    }
  }

  updateOrganizationLogoBanner(organizationID: number, resourceType: string, formData: FormData): void {
    this.organizationService.updateOrganizationLogoBanner(organizationID, resourceType, formData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.confirmationService.close();
          this.fd = new FormData();
          this.getData();
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your image has been successfully uploaded.'});
          this.existingMessage = false;
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        }
      });
  }

  deleteResource(resourceReferenceType: string, organizationID: number): void {
    this.resourceService.deleteResource(resourceReferenceType, organizationID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.confirmationService.close();
          this.getData();
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your image has been successfully removed.'});
          this.existingMessage = false;
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 4000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        }
      });
  }
}
