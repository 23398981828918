<div class="p-grid p-fluid ">
  @if(showPreview) {
    <div class="col-md-12 col-sm-12 col-xs-12 p-mt-1 p-mb-1">
      <button pButton type="button" label="Preview" class="sub-tab"
              [ngClass]="{'active-tab' : activeSubTab === 'preview'}" (click)="activeSubTab = 'preview'; editMode = false"></button>
      <button pButton type="button" label="Edit" class="sub-tab"
              [ngClass]="{'active-tab' : activeSubTab === 'edit'}" (click)="activeSubTab = 'edit'; editMode = true;"></button>
    </div>
  }

  <div class="col-md-12 col-sm-12 col-xs-12">

    @if ((showEditor && editorId && editMode) || (showPreview === false && editorId)) {
      <editor
        apiKey="ctgbx403sfv48ldgsvyfib3wdhkssnpboktoh4ukro05c4nt"
        (onChange)="updateText()"
        (onBlur)="updateText()"
        (onFocusOut)="updateText()"
        (onInit)="trackMe()"
        [(ngModel)]="html"
        [disabled]="!settings.canEdit"
        [init]="config"
        [id]="'myTiny' + editorId"
      ></editor>
    }

    @else {
      <div class="email-preview" [innerHTML]="html"></div>
    }
  </div>
</div>
