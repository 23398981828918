import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MessageService} from 'primeng/api';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {ClassStudentsCompletionModel} from '../../../../models/classes.model';
import {CoursesService} from '../../../../services/courses.service';
import {transformDateTimeToDateOnly} from '../../../../shared/utilities/form.utility';
import {ClassesModulesService} from '../../../../services/classes.service';

@Component({
  templateUrl: './students-complete-dialog.component.html',
  styleUrls: ['./students-complete-dialog.component.scss']
})

export class StudentsCompleteDialogComponent implements OnInit {
  mainForm: FormGroup;
  inputObjTrainingStatus: GenericSelectModel;
  setTrainingStatusId: number;
  isSaving: boolean = false;
  studentList;
  showExpirationData: boolean = true;
  studentStatuses = {} as ClassStudentsCompletionModel;
  classId: number;

  private ngUnsubscribe = new Subject();

  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private lookupsDataService: LookupsDataService, private courseService: CoursesService,
              private classService: ClassesModulesService) {
    this.mainForm = this.formBuilder.group({
      status: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      certification: new FormControl(null),
      certIssueDate: new FormControl(null, Validators.required),
      certExpirationDate: new FormControl(null),
      noExpirationDate: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.initTrainingStatus(false);
    if (this.config.data.courseId) {
      this.getCourseData();
    }
    if (this.config.data.classId) {
      this.classId = this.config.data.classId;
    }
    if (this.config.data.students) {
      this.studentList = this.config.data.students;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initTrainingStatus(disable: boolean): void {
    this.inputObjTrainingStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setTrainingStatusId,
      disabled: disable,
      canWrite: true,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getTrainingStatusesLookupData('class', true, false).then((lookupData) => {
      this.inputObjTrainingStatus.data = lookupData;
      this.inputObjTrainingStatus = Object.assign({}, this.inputObjTrainingStatus);
    });
  }

  getTrainingStatus(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.setTrainingStatusId = event[0].ID;
      if (event[0].Description !== 'Completed') {
        this.mainForm.get('certification').disable();
        this.mainForm.get('certIssueDate').disable();
        this.mainForm.get('certExpirationDate').disable();
        this.mainForm.get('noExpirationDate').disable();
        this.mainForm.get('date').setValidators(null);
      } else {
        this.mainForm.get('certification').enable();
        this.mainForm.get('certIssueDate').enable();
        this.mainForm.get('certExpirationDate').enable();
        this.mainForm.get('noExpirationDate').enable();
        this.mainForm.get('date').setValidators([Validators.required]);
      }
    } else {
      this.setTrainingStatusId = null;
      this.mainForm.get('certification').disable();
      this.mainForm.get('certIssueDate').disable();
      this.mainForm.get('certExpirationDate').disable();
      this.mainForm.get('noExpirationDate').disable();
      this.mainForm.get('date').setValidators(null);
    }

    if (event[0] !== undefined) {
      if (event[0].Description === 'Completed' && this.mainForm.get('date').value === null) {
        this.mainForm.get('date').setValidators([Validators.required]);
        this.mainForm.get('date').updateValueAndValidity();
      } else {
        this.mainForm.get('date').setValidators(null);
      }
    }

    this.mainForm.markAsDirty();
    this.mainForm.get('status').setValue(this.setTrainingStatusId);
  }

  noExpClick(event:any) {
    if (event.checked) {
      this.mainForm.get('certExpirationDate').setValue(null);
      this.mainForm.get('certExpirationDate').disable();
    } else {
      this.mainForm.get('certExpirationDate').enable();
    }
  }

  getCourseData() {
    this.courseService.getCourse(this.config.data.courseId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: res => {
          if (res.CertificationDescription) {
            this.showExpirationData = true;
          }
        }
      });
  }

  cancel() {
    this.ref.destroy();
  }

  processData() {
    this.studentStatuses.Students = [];
    this.studentList.forEach(student => {
      this.studentStatuses.Students.push({
        ClassStudentID: student.ClassStudentID,
        TrainingStatusID: this.setTrainingStatusId,
        StatusDate_D: transformDateTimeToDateOnly(this.mainForm.get('date').value),
        CertificationIssueDate: transformDateTimeToDateOnly(this.mainForm.get('certIssueDate').value),
        CertificationExpirationDate: transformDateTimeToDateOnly(this.mainForm.get('certExpirationDate').value)
      });
    });
    this.saveData();
  }

  saveData() {
    this.classService.updateClassStudentsStatus(this.classId, this.studentStatuses)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Student statuses have been updated'
          });
          this.ref.close();
        }, error: (e) => {
          console.debug(e);
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
        }
      });

  }
}
