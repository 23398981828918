import {Injectable} from '@angular/core';
import {SharedFilterModel} from '../../models/filter.model';
import {TenantConfigDataService} from '../../services/tenant-config-data.service';
import {ContextService} from '../../services/context.service';
import {TenantConfigurations} from '../tenant-configurations/configurations';

@Injectable()
export class TenantFilterDefinitions {
  sharedFilterModel: SharedFilterModel[] = [];
  activeStatuses: string[];

  constructor(private tenantConfigDataService: TenantConfigDataService, private contextService: ContextService,) {
  }

  getTenantPageFilters(page) {
    // const STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    const STRINGREPLACEOCCUPATION = this.tenantConfigDataService.getStringValue('STRINGREPLACEOCCUPATION');
    // const STRINGREPLACECLASSIFICATION = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
    const STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');

    const tenantConfigurations = TenantConfigurations;
    let worksitesLabel: string = 'Worksite';
    if (this.contextService.contextObject.tenantName.toLowerCase() === 'iupat') {
      worksitesLabel = tenantConfigurations.IUPATWORKSITESLABEL.replace('s', '');
    }

    //
    // page.toUpperCase() !== interface object enumerator
    // not every tenant needs its own case statement below, only add them if requirements force us to
    //
    switch (page.toUpperCase()) {

      case 'STUDENTSALL': {
        this.sharedFilterModel = [
          {field: 'FilterName', label: 'Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'OrganizationID', label: 'Membership Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'MemberNumber', label: 'Member #', labelOption: null, valueOption: null, type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'EmployerName', label: 'Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'TypeAlias', label: 'Student Types', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'StatusAlias', label: 'Student Statuses', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: ['Active'], context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'ProgramDescription', label: 'Program', labelOption: 'Description', type: 'multi', valueOption: 'Description', default: null, context: 'training', category: 'union', connectedTo: null, auxTable: null, visible: true},
          {field: 'ProgramLevel', label: 'Program Level', labelOption: null, valueOption: null, type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'StudentGroupID', label: 'Student Group', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'HasCurrentProgram', label: 'Active Apprentices Enrolled in Programs', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'HasPeriodsNeedingApproval', label: 'Time Waiting on Approval', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'ROSTERALL': {
        this.sharedFilterModel = [
          {field: 'FilterName', label: 'Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'OrganizationID', label: 'Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'MemberNumber', label: 'Member #', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'TypeAlias', label: 'Membership ' + STRINGREPLACETYPE, labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'StatusAlias', label: 'Membership Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: ['Active'], context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Positions', label: 'Position', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: 'union', connectedTo: null, auxTable: null, visible: true},
          {field: 'EmployerName', label: 'Primary Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'WRKPROFILE': {
        this.sharedFilterModel = [
          {field: 'Name', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'City', label: 'City', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'CountryID', label: 'Country', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'UNLINKEDUSERS': {
        this.sharedFilterModel = [
          {field: 'LastName', label: 'Last Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'FirstName', label: 'First Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'MemberNumber', label: 'Member #', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'UserID', label: 'Email', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'ACCOUNTADJ': {
        switch (sessionStorage.getItem('workingAs')) {
          case 'IUPAT': {
            this.activeStatuses = ['Active', 'Suspended'];
            break;
          }
          default : {
            this.activeStatuses = JSON.parse(sessionStorage.getItem('contextObject'))?.MembershipActiveStatus;
            break;
          }
        }
        this.sharedFilterModel = [
          {field: 'FilterName', label: 'Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'MemberNumber', label: 'Member #', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'TypeAlias', label: STRINGREPLACETYPE, labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'StatusAlias', label: 'Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: this.activeStatuses, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Positions', label: 'Position', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: 'union', connectedTo: null, auxTable: null, visible: true},
          {field: 'EmployerName', label: 'Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'BUILDCASEWORKFLOWS': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Title', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'WorkflowStatusDescription', label: 'Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: 'union', connectedTo: null, auxTable: null, visible: true},
          {field: 'WorkflowTypeDescription', label: 'Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'OCCUPATIONCLASSES':
      case 'OCCUPATIONTIERS':
      case 'BUILDPATHWAY': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Active', label: 'Active', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'PCTADJUSTMENTS': {
        this.sharedFilterModel = [
          {field: 'Name', label: 'Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'OverDueAmount_N', label: 'Has a Balance', labelOption: null, valueOption: null, type: 'hasAmount', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'NewPayments_N', label: 'Has New Payments', labelOption: null, valueOption: null, type: 'hasAmount', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'NewAdjustments_N', label: 'Has New Adjustments', labelOption: null, valueOption: null, type: 'hasAmount', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'PCTPAYMENTS': {
        this.sharedFilterModel = [
          {field: 'OrganizationName', label: 'Name', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'DISTRIBUTIONLISTS': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Active', label: 'Active', labelOption: null, valueOption: null, type: 'boolean', default: true, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'USERPOINTS': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'PointsDate_D', label: 'Start Date', labelOption: null, valueOption: null, type: 'dateRange', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'EDUORGPROFILE': {
        this.sharedFilterModel = [
          {field: 'Name', label: 'Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Status', label: 'Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: 'union', connectedTo: null, auxTable: null, visible: true},
          {field: 'City', label: 'City', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'County', label: 'County', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'ALERTS': {
        this.sharedFilterModel = [
          {field: 'Title', label: 'Title', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Promoted', label: 'Active', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          //{field: 'Promoted', label: 'Sent', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'BALLOTS': {
        this.sharedFilterModel = [
          {field: 'IdentifierPrefix', label: 'Prefix', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'PaperColor', label: 'Paper Color', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Subject', label: 'Subject', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Notes', label: 'Notes', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'COMMTEMPLATES': {
        this.sharedFilterModel = [
          {field: 'Title', label: 'Title', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'CAMPAIGNS': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'CampaignTypeDescription', label: 'Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'CampaignStatusDescription', label: 'Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'CASEMANAGEMENT': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'EmployerName', label: 'Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          // {field: 'ContractName', label: 'Contract', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'UnionIdentifier', label: 'Union ID', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'EmployerIdentifier', label: 'Employer ID', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'WorkflowTypeDescription', label: 'Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'CONTRIBUTIONS': {
        this.sharedFilterModel = [
          {field: 'ContributionDate_D', label: 'Date', labelOption: null, valueOption: null, type: 'dateRange', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'FirstName', label: 'First name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastName', label: 'Last name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'EmployerName', label: 'Primary Employer', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Worksite', label: worksitesLabel, labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Occupation', label: STRINGREPLACEOCCUPATION, labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'PROGRAMS': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'ProgramTypeDescription', label: 'Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'SubjectAreaDescription', label: 'Subject Area', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'OrganizationID', label: 'Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'PROSPECTS': {
        this.sharedFilterModel = [
          {field: 'FirstName', label: 'First Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'organize', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastName', label: 'Last Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'OrganizationID', label: 'Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'organize', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'TypeAlias', label: 'Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'organize', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'StatusAlias', label: 'Statuses', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'organize', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'EmployerName', label: 'Primary Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'organize', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'organize', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'COURSES': {
        this.sharedFilterModel = [
          {field: 'Name', label: 'Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Code', label: 'Code', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'CourseTypeDescription', label: 'Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'SubjectAreaDescription', label: 'Subject Area', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'OrganizationID', label: 'Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'HasExam', label: 'Has Exam', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LCMSDescription', label: 'LCMS Description', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'USERCOMMUNICATIONS': {
        this.sharedFilterModel = [
          {field: 'Title', label: 'Title', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'SenderDisplayName', label: 'Sender', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Channel', label: 'Channel', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'DOCUMENT': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'File Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'CreatedBy', label: 'Created By', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'MimeType', label: 'File Type', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'CategoryAlias', label: 'Category', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: 'document', connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'USERPROFILE': {
        this.sharedFilterModel = [
          {field: 'UserName', label: 'Username', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'FirstName', label: 'First Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastName', label: 'Last Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'AccessLevel', label: 'Access Level', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LockedOut', label: 'Locked Out', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Enabled', label: 'Enabled', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'LETTERTEMPLATES': {
        this.sharedFilterModel = [
          {field: 'EmailSubject', label: 'Email Subject', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'MEMBERSHIPCARD': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'BUILDHELP': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'What', label: 'What', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Who', label: 'Who', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'When', label: 'When', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Why', label: 'Why', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'How', label: 'How', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'TRAININGSTATUSES': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'ActiveStatus', label: 'Active Status', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'ProgramStatus', label: 'Program Status', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'ClassStatus', label: 'Class Status', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'PassingStatus', label: 'Passing Status', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'EVENTINSTANCES': {
        this.sharedFilterModel = [
          {field: 'EventDescription', label: 'Events', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'OrganizationID', label: 'Organizations', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'StartDate_D', label: 'Start Date', labelOption: null, valueOption: null, type: 'dateRange', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'EVENTLOCATIONS': {
        this.sharedFilterModel = [
          {field: 'Name', label: 'Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Status', label: 'Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: 'union', connectedTo: null, auxTable: null, visible: true},
          {field: 'City', label: 'City', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'County', label: 'County', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'TYPEPOSITIONSETUP': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Abbreviation', label: 'Abbreviation', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'OrganizationCategoryDescription', label: 'Category', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'AllowCrossContextRoles', label: 'Allow Cross-Context Roles', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Organizer', label: 'Organizer', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'CaseRecipient', label: 'Case Recipient', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'UnlinkedUsersAlert', label: 'Unlinked Users Alert', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Current', label: 'Current', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Primary', label: 'Primary', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'ROLEMANAGEMENT': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Name/Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'ContextDescription', label: 'Context', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'AllowRecursive', label: 'Access to Sub-Organizations', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'REPORTBUILDER': {
        this.sharedFilterModel = [
          {field: 'Name', label: 'Name', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Description', label: 'Description', labelOption: null, valueOption: null, type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'PersonId', label: 'Show Only', labelOption: null, valueOption: null, type: 'filterMyData', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'ACCOUNTS': {
        switch (sessionStorage.getItem('workingAs')) {
          case 'IUPAT': {
            this.activeStatuses = ['Active', 'Suspended'];
            break;
          }
          default : {
            this.activeStatuses = JSON.parse(sessionStorage.getItem('contextObject'))?.MembershipActiveStatus;
            break;
          }
        }
        this.sharedFilterModel = [
          {field: 'FilterName', label: 'Name', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'MemberNumber', label: 'Member #', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'TypeAlias', label: 'Membership ' + STRINGREPLACETYPE, labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'StatusAlias', label: 'Membership Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: this.activeStatuses, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Positions', label: 'Position', labelOption: 'Description', type: 'multi', valueOption: 'Description', default: null, context: 'membership', category: 'union', connectedTo: null, auxTable: null, visible: true},
          {field: 'EmployerName', label: 'Primary Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'CONTRACT': {
        switch (sessionStorage.getItem('workingAs')) {
          case 'IUPAT': {
            this.sharedFilterModel = [
              {field: 'Description', label: 'Description', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Identifier', label: 'Contract ID', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'ContractTypeID', label: 'Contract Type', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'TradeID', label: 'Trades', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
            ];
            break;
          }
          default : {
            this.sharedFilterModel = [
              {field: 'Description', label: 'Description', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Identifier', label: 'Number', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Active', label: 'Active', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
            ];
            break;
          }
        }
        break;
      }
      case 'LETTERBATCHES': {
        this.sharedFilterModel = [
          {field: 'BatchDescription', label: 'Description', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'SEARCHHELP': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Title', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'What', label: 'Description', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'EVENTS': {
        this.sharedFilterModel = [
          {field: 'Name', label: 'Name', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'BILLADJ': {
        this.sharedFilterModel = [
          {field: 'LastName', label: 'Last Name', labelOption: null, valueOption: '', type: 'text', default: null, context: 'null', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'PAYMENTFILES': {
        this.sharedFilterModel = [
          {field: 'EmployerName', label: 'Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'StatusFilter', label: 'Completed', labelOption: null, valueOption: null, type: 'boolean', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
      }
        break;
      case 'APPRENTICEHOURS': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
      }
        break;
      case 'PERSONNELFILES': {
        this.sharedFilterModel = [
          {field: 'EmployerName', label: 'Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'StatusFilter', label: 'Completed', labelOption: null, valueOption: null, type: 'boolean', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
        ];
      }
        break;
      case 'TRACKINGTYPES': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: '', type: 'text', default: null, context: 'organize', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'organize', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'AUTOPAYSCHEDULETYPE':
      case 'CAMPAIGNPROSTATUSES':
      case 'CAMPAIGNSTATUSES':
      case 'COGNITIVELEVELS':
      case 'EXPERIENCETYPES':
      case 'ISSUETYPES':
      case 'ORGANIZEPROJECTVALUE':
      case 'ORGANIZEPROJECTINDST':
      case 'ORGANIZEPROJECTPHASE':
      case 'ORGANIZEPROJECTTYPE':
      case 'BILLRATES':
      case 'FACETS':
      case 'SURVEY':
      case 'SUBJECTAREAS':
      case 'SHARABLECATEGORY':
      case 'WAGEADJTYPES':
      case 'MANDATORYLANGUAGE':
      case 'MERCHANTACCOUNT':
      case 'COURSETYPES':
      case 'PROGRAMTYPES':
      case 'RESULTTYPES':
      case 'RESULTS':
      case 'POSITIONTYPE':
      case 'FACETCATEGORIES':
      case 'FACETTYPES':
      case 'TRNSTIPENDPPMTTYPES':
      case 'CERTIFICATIONS': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Description', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'PROGRAMTYPESTUDENTS': {
        this.sharedFilterModel = [
          {field: 'OrganizationID', label: 'Organization', labelOption: 'Abbreviation', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'ProgramDescription', label: 'Program', labelOption: 'Description', type: 'multi', valueOption: 'Description', default: null, context: 'training', category: 'HasStipends', connectedTo: null, auxTable: null, visible: true},
          {field: 'ProgramTypeDescription', label: 'Program Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'SubjectAreaDescription', label: 'Subject Area', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'TRNSTIPENDRATES': {
        this.sharedFilterModel = [
          {field: 'OrganizationID', label: 'Organization', labelOption: 'Abbreviation', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Account', label: 'Account', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'TrainingStipendPaymentTypeDescription', label: 'Stipend Payment Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'SubjectAreaDescription', label: 'Subject Area', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'FrequencyEnumerator', label: 'Frequency', labelOption: 'Description', valueOption: 'value', type: 'multi', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'TRNSTIPENDS': {
        this.sharedFilterModel = [
          {field: 'OrganizationID', label: 'Organization', labelOption: 'Abbreviation', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'ProgramTypeDescription', label: 'Program Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'PeriodEndDate', label: 'Week Ending', labelOption: null, valueOption: null, type: 'dateRange', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
        ];
        break;
      }
      case 'ORGANIZATIONS': {
        this.sharedFilterModel = [
          {field: 'Name', label: 'Name', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Number', label: 'Number', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Status', label: 'Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: ['Active'], context: 'membership', category: 'union', connectedTo: null, auxTable: null, visible: true},
          {field: 'City', label: 'City', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'County', label: 'County', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Country', label: 'Country', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'NOTES': {
        this.sharedFilterModel = [
          {field: 'Body', label: 'Name', labelOption: null, valueOption: '', type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'CreatedDate_D', label: 'Start Date', labelOption: null, valueOption: null, type: 'dateRange', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'ROSTER': {
        switch (sessionStorage.getItem('workingAs')) {
          case 'IUPAT': {
            this.sharedFilterModel = [
              {field: 'FilterName', label: 'Name', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'OrganizationID', label: 'Organization', labelOption: 'Abbreviation', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberNumber', label: 'Member #', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'TypeAlias', label: 'Membership ' + STRINGREPLACETYPE, labelOption: 'Description', valueOption: 'Description', type: 'multi', default: ['Affiliate', 'Apprentice', 'Industrial', 'Life', 'Regular'], context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StatusAlias', label: 'Membership Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: ['Active', 'Suspended'], context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Position', label: 'Position', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: 'union', connectedTo: null, auxTable: 'UNIONPOSITION', visible: true},
              {field: 'CurrentPosition', label: 'Current', labelOption: 'Description', valueOption: 'Description', type: 'text', default: true, context: 'membership', category: 'union', connectedTo: null, auxTable: 'UNIONPOSITION', visible: false},
              {field: 'EmployerName', label: 'Primary Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
            ];
          }
            break;
          default : {
            this.activeStatuses = JSON.parse(sessionStorage.getItem('contextObject'))?.MembershipActiveStatus;
            this.sharedFilterModel = [
              {field: 'FilterName', label: 'Name', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'OrganizationID', label: 'Organization', labelOption: 'Abbreviation', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberNumber', label: 'Member #', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'TypeAlias', label: 'Membership ' + STRINGREPLACETYPE, labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StatusAlias', label: 'Membership Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: this.activeStatuses, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Position', label: 'Position', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: 'union', connectedTo: null, auxTable: 'UNIONPOSITION', visible: true},
              {field: 'CurrentPosition', label: 'Current', labelOption: 'Description', valueOption: 'Description', type: 'text', default: true, context: 'membership', category: 'union', connectedTo: null, auxTable: 'UNIONPOSITION', visible: false},
              {field: 'EmployerName', label: 'Primary Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
            ];
          }
        }
        break;
      }
      case 'STUDENTROSTER': {
        switch (sessionStorage.getItem('workingAs')) {
          case 'IMTEF': {
            this.activeStatuses = JSON.parse(sessionStorage.getItem('contextObject'))?.TrainingActiveStatus;
            this.sharedFilterModel = [
              {field: 'FilterName', label: 'Name', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'TypeAlias', label: 'Student Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StatusAlias', label: 'Student Statuses', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: this.activeStatuses, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StudentGroupID', label: 'Student Group', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MembershipAncestorOrganizationID', label: 'Membership Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'ProgramDescription', label: 'Program', labelOption: 'Description', type: 'multi', valueOption: 'Description', default: null, context: 'training', category: 'union', connectedTo: null, auxTable: null, visible: true},
              {field: 'ProgramLevel', label: 'Program Level', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'HasCurrentProgram', label: 'Active Apprentices Enrolled in Programs', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'HasPeriodsNeedingApproval', label: 'Time Waiting on Approval', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberNumber', label: 'Member #', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberTypeAlias', label: 'Membership ' + STRINGREPLACETYPE, labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Employer', label: 'Primary Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
            ];
          }
            break;
          case 'IUPAT': {
            this.sharedFilterModel = [
              {field: 'FilterName', label: 'Name', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MembershipAncestorOrganizationID', label: 'Membership Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberNumber', label: 'Member #', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberTypeAlias', label: 'Membership ' + STRINGREPLACETYPE, labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Employer', label: 'Primary Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'TypeAlias', label: 'Student Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'ProgramDescription', label: 'Program', labelOption: 'Description', type: 'multi', valueOption: 'Description', default: null, context: 'training', category: 'union', connectedTo: null, auxTable: null, visible: true},
              {field: 'ProgramLevel', label: 'Program Level', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StatusAlias', label: 'Student Statuses', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StudentGroupID', label: 'Student Group', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'HasCurrentProgram', label: 'Active Apprentices Enrolled in Programs', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'HasPeriodsNeedingApproval', label: 'Time Waiting on Approval', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
            ];
          }
            break;
          case 'UA486': {
            this.sharedFilterModel = [
              {field: 'FilterName', label: 'Name', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'TypeAlias', label: 'Student Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StatusAlias', label: 'Student Statuses', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'ProgramDescription', label: 'Program', labelOption: 'Description', type: 'multi', valueOption: 'Description', default: null, context: 'training', category: 'union', connectedTo: null, auxTable: null, visible: true},
              {field: 'ProgramLevel', label: 'Program Level', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StudentGroupID', label: 'Student Group', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'HasCurrentProgram', label: 'Active Apprentices Enrolled in Programs', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'HasPeriodsNeedingApproval', label: 'Time Waiting on Approval', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MembershipAncestorOrganizationID', label: 'Membership Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberNumber', label: 'Member #', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberTypeAlias', label: 'Membership ' + STRINGREPLACETYPE, labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Employer', label: 'Primary Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
            ];
          }
            break;
          default : {
            this.activeStatuses = JSON.parse(sessionStorage.getItem('contextObject'))?.TrainingActiveStatus;
            this.sharedFilterModel = [
              {field: 'FilterName', label: 'Name', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'TypeAlias', label: 'Student Type', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StatusAlias', label: 'Student Statuses', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: this.activeStatuses, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'ProgramDescription', label: 'Program', labelOption: 'Description', type: 'multi', valueOption: 'Description', default: null, context: 'training', category: 'union', connectedTo: null, auxTable: null, visible: true},
              {field: 'ProgramLevel', label: 'Program Level', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StudentGroupID', label: 'Student Group', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'HasCurrentProgram', label: 'Active Apprentices Enrolled in Programs', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'HasPeriodsNeedingApproval', label: 'Time Waiting on Approval', labelOption: null, valueOption: null, type: 'boolean', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MembershipAncestorOrganizationID', label: 'Membership Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberNumber', label: 'Member #', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'MemberTypeAlias', label: 'Membership ' + STRINGREPLACETYPE, labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Employer', label: 'Primary Employer', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true}
            ];
          }
        }
        break;
      }
      case 'STIPENDS': {
        this.sharedFilterModel = [
          {field: 'PostingStatus', label: 'Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'SURVEYS': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Name/Title', labelOption: null, valueOption: null, type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Anonymous', label: 'Anonymous', labelOption: 'Description', valueOption: '', type: 'boolean', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'Secure', label: 'Secure', labelOption: 'Description', valueOption: null, type: 'boolean', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'CanEdit', label: 'Distributed', labelOption: 'Description', valueOption: '', type: 'boolean', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
      case 'EMPLOYER': {
        switch (sessionStorage.getItem('workingAs')) {
          case 'IUPAT': {
            this.sharedFilterModel = [
              {field: 'Name', label: 'Name', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Identifier', label: 'Employer ID', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'FEIN', label: 'Federal Tax ID', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Status', label: 'Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: 'employer', connectedTo: null, auxTable: null, visible: true},
              {field: 'PrimaryTrade', label: 'Primary Trades', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StateProvince', label: 'State/Province', labelOption: 'Description', valueOption: 'Abbreviation', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
            ];
          }
            break;
          default : {
            this.sharedFilterModel = [
              {field: 'Name', label: 'Name', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Identifier', label: 'Employer ID', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'FEIN', label: 'Federal Tax ID', labelOption: 'Description', valueOption: 'Description', type: 'text', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'Status', label: 'Status', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: 'employer', connectedTo: null, auxTable: null, visible: true},
              {field: 'PrimaryTrade', label: 'Primary Trades', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'StateProvince', label: 'State/Province', labelOption: 'Description', valueOption: 'Abbreviation', type: 'multi', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
              {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: 'membership', category: null, connectedTo: null, auxTable: null, visible: true},
            ];
          }
        }
        break;
      }
      case 'TRAININGMODULES': {
        this.sharedFilterModel = [
          {field: 'Description', label: 'Title', labelOption: null, valueOption: null, type: 'text', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'OrganizationID', label: 'Organization', labelOption: 'Description', valueOption: 'ID', type: 'multi', default: null, context: 'training', category: null, connectedTo: null, auxTable: null, visible: true},
          {field: 'ProgramDescription', label: 'Program', labelOption: 'Description', valueOption: 'Description', type: 'multi', default: null, context: 'training', category: 'union', connectedTo: null, auxTable: null, visible: true},
          {field: 'LastField', label: null, labelOption: null, valueOption: null, type: 'last', default: null, context: null, category: null, connectedTo: null, auxTable: null, visible: true},
        ];
        break;
      }
    }
    return this.sharedFilterModel;
  }
}
