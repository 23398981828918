import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  ProgramsGridModel,
  ProgramModel,
  CreateUpdateProgramModel,
  ProgramsStudentsGridModel,
  ProgramStudentHoursByFacetTypeModel, ProgramStudentFacetSummaryGridModel
} from '../models/programs.model';

@Injectable({
  providedIn: 'root',
})
export class ProgramsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  // getProgramsKGrid(): Observable<ProgramsGridModel> {
  //   return this.http.get<ProgramsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/grid`);
  // }

  getProgramsPrimeNG(dataMode: string, filterData): Observable<ProgramsGridModel> {
    return this.http.post<ProgramsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/grid?mode=${dataMode}`, filterData);
  }

  getProgram(programId: number): Observable<ProgramModel> {
    return this.http.get<ProgramModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/${programId}`);
  }

  createProgram(modelData: CreateUpdateProgramModel): Observable<ProgramModel> {
    return this.http.post<ProgramModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/create`, modelData);
  }

  updateProgram(programId: number, modelData: CreateUpdateProgramModel): Observable<ProgramModel> {
    return this.http.put<ProgramModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/${programId}`, modelData);
  }

  deleteProgram(programId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/remove/${programId}`);
  }

  cloneProgram(programId: number): Observable<ProgramModel> {
    return this.http.post<ProgramModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/${programId}/clone`, null);
  }

  getProgramStudentsGridData(programId: number, filterData): Observable<ProgramsStudentsGridModel>{
    return this.http.post<ProgramsStudentsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/program/${programId}/student/grid?mode=DATA`, filterData);
  }

  getProgramStudentDetailsByFacetType(programStudentId: number, facetTypeName: string): Observable<ProgramStudentHoursByFacetTypeModel> {
    return this.http.get<ProgramStudentHoursByFacetTypeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/programstudent/${programStudentId}/facettype/${facetTypeName}/summary`);
  }

  getProgramStudentsGridTotal(programId: number, filterData): Observable<ProgramsStudentsGridModel> {
    return this.http.post<ProgramsStudentsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/program/${programId}/student/grid?mode=TOTAL`, filterData);
  }

  getProgramStudentFacetSummaryPrimeNG(filterData): Observable<ProgramStudentFacetSummaryGridModel> {
    return this.http.post<ProgramStudentFacetSummaryGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/facetsummary/grid?mode=ALL`, filterData);
  }
}
