<!--<span class="fa fa-spinner list-spinner" *ngIf="loading"></span>-->
<form class="margin-top-10 ">
  <p-divider class="dark-divider"></p-divider>
  <div class="p-grid p-fluid parent-form">
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <p>Please Note &mdash; After adding this student to a class, you will need to go to the class to appropriately record the student's status.</p>
    </div>
    @if (!isEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
          <app-select class="full-width" [inputObj]="inputObjCourse" (emitData)="getCourseData($event)"></app-select>
        </div>
      </div>
    }
    @if (setCourseId !== 0) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container" >
        <button pButton label="Get Classes" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-search" pTooltip="Get Classes" tooltipPosition="bottom"
        (click)="showTable=true;getData();"></button>
      </div>
    }
    @if (showTable) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <p-table
          #dt1
          dataKey="uniqueId"
          [rows]="pageRowCountUtility.pageRowCount()"
          [columns]="cols"
          [value]="classesList"
          [lazy]="true"
          (onLazyLoad)="loadTable($event)"
          [paginator]="true"
          [showCurrentPageReport]="true"
          [metaKeySelection]="false"
          [totalRecords]="dataTotal"
          [rowsPerPageOptions]="[25,50,100,250,500]"
          [scrollable]="true"
          scrollDirection="both"
          [(selection)]="selClass"
          selectionMode="multiple"
          scrollHeight="50vh"
          [loading]="loading"
          styleClass="p-datatable-gridlines p-datatable-striped"
          currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
          >
          <ng-template pTemplate="header" let-columns>
            <tr>
              @for (col of columns; track col) {
                <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom"
                  [ngStyle]="{'width': col.width}">
                  <div class="header-text">
                  {{col.header}}</div>
                  <div class="header-actions">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </div>
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" >
            <tr>
              @for (col of columns; track col) {
                <td [ngStyle]="{'width': col.width}">
                  @if (col.field === 'select') {
                    <p-checkbox [(ngModel)]="rowData.Selected" [binary]="true" [ngModelOptions]="{standalone: true}" (onChange)="onRowSelect($event, rowData)" tabindex="0"></p-checkbox >
                    }
                    @if (col.visible && col.displayType === 'date') {
                      {{rowData[col.field] | date: 'shortDate'}}
                    }
                    @if (col.visible && col.displayType === 'time') {
                      {{rowData[col.field] | date: 'shortTime'}}
                    }
                    @if (col.visible && col.displayType === 'text') {
                      {{rowData[col.field]}}
                    }
                    @if (col.visible && col.displayType === 'numeric') {
                      {{rowData[col.field] | number:'1.0':'en-US'}}
                    }
                  </td>
                }
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [colSpan]="cols?.length" style="width: 100%;">No Classes Found</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      }
    </div>
    @if (canTabWrite) {
      <div class="p-grid p-fluid margin-top-10">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="p-grid p-fluid">
            <div class="col-md-6 col-sm-6 col-xs-12"></div>
            <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
              @if (!isEdit) {
                <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                (click)="cancel()"></button>
              }
              @if (!isSaving) {
                <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                  (click)="processData()" [className]="'inline-button'" [disabled]="selectedClasses.length <= 0"
                ></button>
              } @else {
                <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </form>
