<div class="p-grid p-fluid">
  @if (!autoRun) {
    <div class="p-col-12 p-sm-12 p-md-2">
      @if (!isDownload) {
        <button pButton label="Run Report" class="p-button-info p-mb-2" pTooltip="Run Report" tooltipPosition="bottom"
        (click)="showReport = true" [disabled]="!isValid"></button>
      }
      @if (isDownload && !isProcessing) {
        <button pButton label="Download Report" class="p-button-download export-menu export-pdf p-pl-2 inline-button" pTooltip="Download Report" tooltipPosition="bottom"
        (click)="downloadReport()" [disabled]="!isValid" icon="fa fa-file-pdf"></button>
      }
      @if (isProcessing) {
        <button label="Processing Report" pButton class="p-button-download export-menu p-pl-2 inline-button" [disabled]="true" icon="pi pi-spin pi-spinner save-spinner">
        </button>
      }
    </div>
  }
  @if (showReport) {
    <div class="p-col-12 p-md-12">
      <tr-viewer [containerStyle]="viewerContainerStyle" [serviceUrl]="serviceUrl" [templateUrl]="templateUrl"
        [reportSource]="reportSource" [viewMode]="viewMode" [scaleMode]="scaleMode" [scale]="scale" [ready]="ready"
        [viewerToolTipOpening]="viewerToolTipOpening" [enableAccessibility]="enableAccessibility">
      </tr-viewer>
    </div>
  }
  @if (showNoResults) {
    <div class="p-col-12 p-md-12 ">
      <div class="margin-top-10 sms-nonallowedchars">
        <h3><i class="fas fa-times-circle red-icon"></i> No Results Found</h3>
        <p>The above search did not have any results. Please try again and have a great day!</p>
      </div>
    </div>
  }
</div>
