import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DialogService} from 'primeng/dynamicdialog';
import {OrganizationModel} from '../../models/organizations.model';
import {MapsDialogComponent} from '../../shared/components/maps-dialog/maps-dialog.component';
import {RouteContextUtility} from '../../shared/utilities/route-context.utility';
import {take, takeUntil} from 'rxjs/operators';
import {TreeNode} from 'primeng/api';
import {ChartDialogComponent} from '../../shared/components/chart-dialog/chart-dialog.component';
import {OrganizationService} from '../../services/organizations.service';

@Component({
  selector: 'app-organization-action-buttons',
  templateUrl: './organizations-action-buttons.component.html'
})
export class OrganizationActionButtonsComponent implements OnInit, OnDestroy {
  InterfaceObjectEnum: string = 'ORGPROFILE';
  routeContext: string;
  expand: boolean = true;
  @Input() canPageWrite: boolean;
  @Input() organization: OrganizationModel;

  private ngUnsubscribe = new Subject();

  constructor(public dialogService: DialogService, private routeContextUtility: RouteContextUtility,
              private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.routeContext = this.routeContextUtility.getRouteContext();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getLocation(data: OrganizationModel): void {
    // const url = `https://www.google.com/maps/place/${data.Address1},+${data.City},+${data.StateProvince}+${data.Zip}`;
    // this.router.navigate([]).then(() => {  window.open(url, '_blank'); });
    this.organizationService
      .getOrganization(data.ID, 'union', 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dialogService.open(MapsDialogComponent, {
            data: {
              latitude: res.Latitude,
              longitude: res.Longitude,
              title: res.Name,
              street: res.Address1,
              city: res.City,
              state: data.StateProvince,
              zip: res.Zip
            },
            header: `Organization Location - ${data.Name}`,
            width: '90%',
            height: '70%'
          });
        }
      });
  }

  getOrgChart(data: OrganizationModel): void {
    this.organizationService.getOrganizationStructure(data.ID)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.expand = true;
          const treeNode: TreeNode[] = [];
          treeNode.push(this.buildTreeNode(data.ID, res));
          this.dialogService.open(ChartDialogComponent, {
            data: {
              orgChart: treeNode
            },
            header: `Hierarchy - ${data.Name}`,
            width: '90%',
            height: '70%'
          });
        }
      });
  }

  buildTreeNode(id: number, data): any {
    let rtn: TreeNode;
    if (data) {
      switch (true) {
        case this.expand === true && id !== data.ID:
          rtn = JSON.parse(JSON.stringify({
            label: (data?.Abbreviation) ? data?.Abbreviation : data?.Name,
            expanded: true,
            children: []
          }));
          break;
        case id === data.ID:
          rtn = JSON.parse(JSON.stringify({
            label: (data?.Abbreviation) ? data?.Abbreviation : data?.Name,
            expanded: true,
            children: []
          }));
          this.expand = false;
          break;
        default:
          rtn = JSON.parse(JSON.stringify({
            label: (data?.Abbreviation) ? data?.Abbreviation : data?.Name,
            expanded: false,
            children: []
          }));
          this.expand = false;
          break;
      }
      if (data?.Children?.length > 0) {
        data?.Children.forEach(x => {
          rtn.children.push(JSON.parse(JSON.stringify(this.buildTreeNode(id, x))));
        });
      }
    }
    return rtn;
  }
}
