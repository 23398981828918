import { Pipe, PipeTransform } from '@angular/core';
import { TenantConfigDataService } from '../../services/tenant-config-data.service';


@Pipe({
  name: 'stringReplace'
})


export class StringReplacePipe implements PipeTransform {
  constructor(private tenantConfigDataService: TenantConfigDataService) {
  }
  STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
  STRINGREPLACECLASSIFICATION = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
  STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');

  transform(value:string): string {
    if(value !== 'STRINGREPLACELOCAL' && value !== 'STRINGREPLACECLASSIFICATION' && value !==  'STRINGREPLACETYPE') {
      return value;
    } else if(value === 'STRINGREPLACELOCAL') {
      return this.STRINGREPLACELOCAL;
    } else if(value === 'STRINGREPLACECLASSIFICATION') {
      return this.STRINGREPLACECLASSIFICATION;
    } else if(value === 'STRINGREPLACETYPE') {
      return this.STRINGREPLACETYPE;
    } else {
      return value;
    }
  }
}
