import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Subject} from 'rxjs';
import {RosterService} from '../../services/roster.service';
import {RosterFormModel} from '../../models/roster.model';
import {ContextService} from '../../services/context.service';
import {takeUntil} from 'rxjs/operators';
import {AdhocCommDialogComponent} from '../../shared/components/adhoc-comm-dialog/adhoc-comm-dialog.component';
import {DialogService} from 'primeng/dynamicdialog';
import {PageService} from '../../services/page.services';
import {InterfaceObjectAppsModel, InterfaceObjectItemPermissions} from '../../models/interface-objects.model';
import {tabsPermissionsUtility} from '../../shared/utilities/tabs-permissions.utility';
import {StudentTranscriptComponent} from '../../reports/student-transcripts/student-transcripts.component';
import {DymoLabelUtility} from '../../shared/utilities/dymo-label.utility';
import {AdhocMailMergeFormComponent} from '../../shared/components/adhoc-mail-merge/adhoc-mail-merge-form.component';
import {MemberProfileDialogComponent} from '../../shared/components/member-profile-dialog/member-profile-dialog.component';
import {MembershipCardsComponent} from '../../reports/membership-cards/membership-cards.component';
import {showMembershipCard} from '../../shared/utilities/component-manager-utility';
import {MembershipCardDialogComponent} from '../../shared/components/membership-card-dialog/membership-card-dialog.component';

@Component({
  selector: 'app-roster-action-buttons',
  templateUrl: './roster-action-buttons.component.html',
  styleUrls: ['./roster-action-buttons.component.scss'],
})
export class RosterActionButtonsComponent implements OnInit, OnDestroy {
  InterfaceObjectEnum: string = 'ROSTER';
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  showAddOptions: boolean;
  selRosterId: number;
  selectedItem: number;
  studentsPermission: boolean = false;
  memberCardPermission: boolean = false;
  transcriptPermission: boolean = false;
  mailMergePermission: boolean = false;
  accountAdjPermission: boolean = false;
  batchMemberCardsPermission: boolean = false;
  batchMemberCardsPermissionWrite: boolean = false;
  isDymo: boolean = false;
  availableForms: RosterFormModel[];
  showFormOptions: boolean = false;
  selectedMember: number;
  showTranscriptOptions: boolean = false;
  selectedStudent: number;
  public _showMembershipCard = showMembershipCard;
  @Input() canPageWrite: boolean;
  @Input() roster: any;
  @Input() i: number;
  @Input() permissions: InterfaceObjectAppsModel[] = [];
  @Input() blobToken: string;
  private ngUnsubscribe = new Subject();

  constructor(private messageService: MessageService, private router: Router, public dialogService: DialogService,
              private rosterService: RosterService, private contextService: ContextService,
              private pageService: PageService, private confirmationService: ConfirmationService,
              private dymoLabelUtility: DymoLabelUtility) {
  }

  ngOnInit(): void {
    // show/hide icons based on user permissions
    this.pageService.getPagePermissions('STUDENTS', true).then((resS: InterfaceObjectItemPermissions) => {
      this.studentsPermission = !!(resS);
    });
    this.pageService.getPagePermissions('MEMBERSHIPCARD', true).then((resC: InterfaceObjectItemPermissions) => {
      this.memberCardPermission = !!(resC);
    });
    this.pageService.getPagePermissions('BATCHMEMBERCARDS', true).then((resC: InterfaceObjectItemPermissions) => {
      this.batchMemberCardsPermission = !!(resC);
      this.batchMemberCardsPermissionWrite = resC?.Write;
    });
    this.pageService.getPagePermissions('STUDENTTRANSCRIPT', true).then((resT: InterfaceObjectItemPermissions) => {
      this.transcriptPermission = !!(resT);
    });
    this.pageService.getPagePermissions('LETTERBATCHES', true).then((resM: InterfaceObjectItemPermissions) => {
      this.mailMergePermission = !!(resM);
    });
    this.pageService.getPagePermissions('ACCOUNTADJ', true).then((resA: InterfaceObjectItemPermissions) => {
      this.accountAdjPermission = !!(resA);
    });

    if (sessionStorage.getItem('isDymoInstalled') === null && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value === 'Yes') {
      this.dymoLabelUtility.isDymoInstalled().then(() => {
        this.dymoLabelUtility.isDymoPrinterInstalled().then((rtn) => {
          this.isDymo = rtn;
        }).catch(() => {
          this.isDymo = false;
        });
      }).catch(() => {
        this.isDymo = false;
      });
    } else {
      this.isDymo = (sessionStorage.getItem('isDymoInstalled') === 'Yes' && this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_DymoInstalled')?.Value === 'Yes');
    }

    this.availableForms = [
      {
        Enum: 'BENEFICIARYOFBURIALEXPENSE',
        Description: 'Beneficiary of Burial Expense'
      },
      {
        Enum: 'CONCENTRAFORM',
        Description: 'Concentra Form'
      },
      {
        Enum: 'MATCGRADFORM',
        Description: 'MATC Grad Form'
      },
      {
        Enum: 'APPLICATIONFORMEMBERSHIP',
        Description: 'Application for Membership'
      },
      {
        Enum: 'MDDOLREGISTRATION',
        Description: 'MD DOL Registration'
      },
      {
        Enum: 'VAAGREEMENT',
        Description: 'VA Agreement'
      },
    ];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  goToAccount(id?: number): void {
    sessionStorage.setItem('sessionViewType', 'List');
    this.selRosterId = id;
    if (this.selRosterId) {
      this.router.navigate(['membership/account-adjustments/edit', +this.selRosterId], {
        queryParams: {selRosterId: this.selRosterId}
      });
    }
  }

  openMemberCard(PersonID): void {
    this.showAddOptions = false;
    const selectedPerson = this.roster;
    this.dialogService.open(MembershipCardsComponent, {
      data: {
        id: PersonID,
        isEdit: true,
        isDialog: true
      },
      header: selectedPerson.FirstName + ' ' + selectedPerson.LastName,
      width: '90%',
      height: '70%'
    });
  }

  // openTranscript(PersonID): void {
  //   this.showAddOptions = false;
  //   const selectedStudent = this.roster;
  //   this.dialogService.open(StudentTranscriptComponent, {
  //     data: {
  //       id: PersonID,
  //       isEdit: true,
  //       isDialog: true
  //     },
  //     header: selectedStudent.FirstName + ' ' + selectedStudent.LastName,
  //     width: '90%',
  //     height: '70%'
  //   });
  // }

  openTranscript(PersonID, type): void {
    this.showTranscriptOptions = false;
    const selectedStudent = this.roster;
    this.dialogService.open(StudentTranscriptComponent, {
      data: {
        id: PersonID,
        isEdit: true,
        isDialog: true,
        type
      },
      header: selectedStudent.FirstName + ' ' + selectedStudent.LastName,
      width: '90%',
      height: '70%'
    });
  }

  openAdhocComm(recipients): void {
    this.showAddOptions = false;
    this.dialogService.open(AdhocCommDialogComponent, {
      data: {
        recipients: [recipients],
        isEdit: true,
        isDialog: true,
        organizationId: recipients.OrganizationID,
        context: 'membership'
      },
      header: 'Email or Text Member',
      width: '90%',
      height: '70%'
    });
  }

  openMembershipCard(personID, organizationID): void {
    this.showAddOptions = false;
    {
      const ref = this.dialogService.open(MembershipCardDialogComponent, {
        data: {
          id: personID,
          organizationID,
          write: this.batchMemberCardsPermissionWrite
        },
        header: 'Membership Card Confirmation',
        width: '60%',
        height: '30%'
      });

      ref.onClose.subscribe((result) => {
        if (result[0]) {
          this.rosterService.getRosterMembershipCard(personID, result[0], result[1])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
              window.open(res.Url, '_blank');
            }, error => {
              if (error && error.error && error.error.Message) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: error.error.Message.replace('UM: ', ''),
                  life: 5000
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
              }
            });
        }

      });
    }
  }

  openAdhocMailMerge(recipients): void {
    this.showAddOptions = false;
    const ref = this.dialogService.open(AdhocMailMergeFormComponent, {
      data: {
        recipients: [recipients],
        isEdit: true,
        isDialog: true
      },
      header: 'Letter',
      width: '95%',
      height: '75%'
    });
  }

  downloadProfile(PersonID): void {
    this.showAddOptions = false;
    const selectedStudent = this.roster;
    const ref = this.dialogService.open(MemberProfileDialogComponent, {
      data: {
        id: PersonID,
        blobToken: this.blobToken,
        isEdit: true,
        isDialog: true
      },
      header: 'Download Profile - ' + selectedStudent.FirstName + ' ' + selectedStudent.LastName,
      width: '550px',
      height: '180px'
    });
  }

  printLabel(personID: number): void {
    this.confirmationService.confirm({
      message: 'You are about to print a DYMO label. Are you sure this is what you want to do?',
      header: 'Print Confirmation',
      icon: 'pi pi-print',
      accept: () => {
        this.dymoLabelUtility.rosterDymoLabel(personID);
      }
    });
  }

  openFormOptions() {
    this.showFormOptions = !this.showFormOptions;
  }

  openForm(personId: number, enumerator: string) {
    this.rosterService.getRosterMemberForms(personId, enumerator).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.openFormOptions();
      window.open(res.StringRtn, '_blank');
    });
  }
}
