@if (useMinMax) {
  <p-calendar dateMask  dateFormat="mm/dd/yy" [(ngModel)]="value" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [disabled]="disabled"
              (onSelect)="emit($event, 'select')"
              (onBlur)="emit($event, 'text')"
              [minDate]="minValue"
              [maxDate]="maxValue" ></p-calendar>
  @if(value < minValue || value > maxValue) {
    <span class="error">This date cannot be before {{minValue | date : 'MM/dd/yyyy'}} or after {{maxValue | date : 'MM/dd/yyyy'}}</span>
  }
} @else {
  <p-calendar dateMask  dateFormat="mm/dd/yy" [(ngModel)]="value" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [disabled]="disabled"
   (onSelect)="emit($event, 'select')" (onBlur)="emit($event, 'text')" ></p-calendar>
}

