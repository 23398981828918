import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  ClassAttendanceGridModel, ClassAttendanceModel,
  ModuleAttendanceModel,
  ModuleDailyAttendanceModel,
  UpdateClassAttendanceModel
} from '../models/class-attendance.model';
import {FileUploadModel} from '../models/resources.model';

@Injectable({
  providedIn: 'root',
})
export class ClassAttendancesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getClassAttendancePrimeNG(dataMode: string, classId: number, classPeriod: string, filterData): Observable<ClassAttendanceGridModel> {
    return this.http.post<ClassAttendanceGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/attendance/grid?period=${classPeriod}&mode=${dataMode}`, filterData);
  }

  updateClassAttendance(classId: number, modelData: UpdateClassAttendanceModel): Observable<UpdateClassAttendanceModel> {
    return this.http.put<UpdateClassAttendanceModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${classId}/attendance`, modelData);
  }

  getModuleAttendance(classScheduleId: number, classStudentId?: number, attendanceStatusId?: number): Observable<ModuleAttendanceModel[]> {
    let url: string = '';
    if(classStudentId && !attendanceStatusId) {
      url = `?classStudentId=${classStudentId}`;
    }

    if(attendanceStatusId && !classStudentId) {
      url = `?attendanceStatusId=${attendanceStatusId}`;
    }

    if(attendanceStatusId && classStudentId) {
      url = `?classStudentId=${classStudentId}&attendanceStatusId=${attendanceStatusId}`;
    }

    return this.http.get<ModuleAttendanceModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/classSchedule/${classScheduleId}/attendance${url}`);
  }

  updateModuleAttendance(modelData: ModuleAttendanceModel[]): Observable<ModuleAttendanceModel[]> {
    return this.http.put<ModuleAttendanceModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/attendance`, modelData);
  }

  getClassSignInSheet(classScheduleId: number): Observable<ModuleDailyAttendanceModel> {
    return this.http.get<ModuleDailyAttendanceModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/classSchedule/${classScheduleId}/signInSheet`);
  }

  updateClassAttendanceScanned(formData: FormData, eventInstanceID: number, fileFormat: string, period: string): Observable<FileUploadModel[]> {
    return this.http.post<FileUploadModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/class/${eventInstanceID}/attendance/upload?format=${fileFormat}&eventperiod=${period}`, formData);
  }
}


