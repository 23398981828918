<div class="p-fluid p-grid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <h3>Warning deleting this person will delete them from all contexts. To confirm please type "<span class="error">Confirm</span>"
      in the box below to continue.</h3>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12">
    <input class="full-width" type="text" pInputText [(ngModel)]="cancelText" tabindex="0">
  </div>
  <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10"></div>
  <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10">
    <div class="p-fluid p-grid">
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5 justify-right add-display-flex">
          <button pButton class="p-button-download contact-button" icon="pi pi-times" label="Cancel"
                  pTooltip="Cancel" tooltipPosition="bottom" (click)="closeDialog()">
          </button>
        <button pButton class="p-button-download contact-button" icon="pi pi-save" label="Confirm"
                pTooltip="Confirm" tooltipPosition="bottom" [disabled]="cancelText !== 'Confirm'" (click)="delete()">
        </button>
      </div>
    </div>
  </div>
</div>
