import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {ClassStudentsService} from '../../../../services/class-students.service';
import {ClassStudentModel, ClassStudentPickerModel, CreateClassStudentModel, UpdateClassStudentModel} from '../../../../models/class-students.model';
import {transformDateTimeToDateOnly} from '../../../../shared/utilities/form.utility';
import {ContextService} from '../../../../services/context.service';
import {ResourcesService} from '../../../../services/resources.service';
import {listFilterUtility} from '../../../../shared/utilities/list-filter.utility';
import {TenantConfigDataService} from '../../../../services/tenant-config-data.service';
import {InactiveStatusUtility} from '../../../../shared/utilities/inactive-status.utility';
import {InterfaceObjectItemPermissions} from '../../../../models/interface-objects.model';
import {PageService} from '../../../../services/page.services';
import {PageRowCountUtility} from '../../../../shared/utilities/page-row-count.utility';
import {DialogUtility} from '../../../../shared/utilities/dialog.utility';
import {SharablesService} from '../../../../services/sharables.service';


@Component({
  selector: 'app-class-student-form',
  templateUrl: './class-student-form.component.html',
  styleUrls: ['./class-student-form.component.scss']
})
export class ClassStudentFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  routeParams: any;
  isSaving: boolean = false;
  currentFilter = new PrimeTableFilterModel();
  currentRecord: ClassStudentModel;
  classStudent = {} as UpdateClassStudentModel;
  loading: boolean;
  isPage: boolean = false;
  inputObjTrainingStatus: GenericSelectModel;
  setTrainingStatusId: number;
  inputObjStudentGroup: GenericSelectModel;
  setStudentGroupId: number;
  inputObjProgram: GenericSelectModel;
  setProgramId: number;
  setProgramDesc: string;
  inputObjStudentStatus: GenericSelectModel;
  setStudentStatusDesc: string;
  inputObjRateLevel: GenericSelectModel;
  setRateLevelDesc: string;
  inputObjGrades: GenericSelectModel;
  setGradeId: number;
  setResultId: number;
  activeGlobalFilter: string;
  studentPickerList: ClassStudentPickerModel[];
  selStudent: ClassStudentPickerModel[] = [];
  dataTotal: number = 0;
  cols: any[];
  showTable: boolean = false;
  blobToken: string;
  existingMessage: boolean = false;
  showCertificationFields: boolean;
  showProgress: boolean;
  fd: FormData = new FormData();
  uploadedFiles: any[] = [];
  certificationResource: boolean;
  memberID: string;
  global: boolean = false;
  STRINGREPLACELOCAL: string;
  STRINGREPLACETYPE: string;
  globalRoster: boolean = false;
  certIssueDateDisabled: boolean;
  certExpirationDateDisabled: boolean;
  detailsLoading: boolean;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() ClassID: number;
  @Input() ClassStudentID: number;
  @Input() courseId: number;
  @Input() OrganizationID: number;
  @Input() isModule: boolean;
  @Output() closeAddEdit = new EventEmitter<boolean>();
  inactiveStatuses: string[] = [];
  getListRequest;
  getTotalRequest;
  private ngUnsubscribe = new Subject();

  constructor(private classStudentsService: ClassStudentsService,
              private router: Router, private route: ActivatedRoute,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private lookupsDataService: LookupsDataService, private confirmationService: ConfirmationService,
              private contextService: ContextService, private dialogUtility: DialogUtility,
              private resourcesService: ResourcesService, private tenantConfigDataService: TenantConfigDataService,
              private inactiveStatusUtility: InactiveStatusUtility, private pageService: PageService,
              public pageRowCountUtility: PageRowCountUtility, private sharablesService: SharablesService) {
    this.mainForm = this.formBuilder.group({
      status: new FormControl(null, Validators.required),
      dt: new FormControl(null, Validators.required),
      expDate: new FormControl(null),
      grade: new FormControl(null),
      result: new FormControl(null),
      certification: new FormControl(null),
      certIssueDate: new FormControl(null, Validators.required),
      certExpirationDate: new FormControl(null),
      noExpirationDate: new FormControl(null),
      files: new FormControl(null),
      sendEmail: new FormControl(null),
      gradeAverage: new FormControl(null),
      finalGrade: new FormControl(null),
      trainingIdentifier: new FormControl(null),
    });
  }

  _selectedColumns: any[];

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  ngOnInit(): void {
    this.STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    this.STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');
    this.pageService.getPagePermissions('STUDENTSALL', true).then((res: InterfaceObjectItemPermissions) => {
      this.globalRoster = !!(res);
    });
    this.inactiveStatusUtility.getPersonProfileInactiveStatuses('membership').then(x => {
      this.inactiveStatuses = x;
    }).catch(y => {
      this.inactiveStatuses = y;
    });
    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        if (this.router.url.includes('/edit/')) {
          this.isPage = true;
        } else this.isPage = this.router.url.includes('/add');
      }
    });
    if (this.isEdit) {
      this.mainForm.markAsPristine();
      this.mainForm.markAsUntouched();
      this.getDataToEdit();
    } else {
      this.blobToken = this.contextService.contextObject.blobToken;
      this.cols = [
        {
          field: 'LastName',
          header: 'Last Name',
          fullHeader: 'Last Name',
          columnType: 'text',
          matchMode: 'contains',
          displayType: 'text',
          visible: true
        },
        {
          field: 'FirstName',
          header: 'First Name',
          fullHeader: 'First Name',
          columnType: 'text',
          matchMode: 'contains',
          displayType: 'text',
          visible: true
        },
        {
          field: 'MiddleName',
          header: 'Middle Name',
          fullHeader: 'Middle Name',
          columnType: 'text',
          matchMode: 'contains',
          displayType: 'text',
          visible: false
        },
        {
          field: 'StudentNumber',
          header: 'Student #',
          fullHeader: 'Student #',
          columnType: 'numeric',
          matchMode: 'equals',
          displayType: 'numeric',
          visible: true
        },
        {
          field: 'MemberNumber',
          header: 'Member #',
          fullHeader: 'Member #',
          columnType: 'text',
          matchMode: 'equals',
          displayType: 'text',
          visible: true
        },
        {
          field: 'MemberStatusAlias',
          header: 'Status',
          fullHeader: 'Status',
          columnType: 'text',
          matchMode: 'contains',
          displayType: 'text',
          visible: true
        },
        {
          field: 'MemberTypeAlias',
          header: this.STRINGREPLACETYPE,
          fullHeader: this.STRINGREPLACETYPE,
          columnType: 'text',
          matchMode: 'contains',
          displayType: 'text',
          visible: true
        },
        {
          field: 'OrganizationName',
          header: this.STRINGREPLACELOCAL,
          fullHeader: this.STRINGREPLACELOCAL,
          columnType: 'picker',
          displayType: 'text',
          visible: true
        },
      ];
      this._selectedColumns = this.cols.filter(col => col.visible === true);
      this.initStudentGroup(false);
      this.initProgram(false);
      this.initRateLevel(0, true);
      this.initStudentStatus(false);
    }

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
          switch (field) {
            case 'certIssueDate':
              this.certIssueDateDisabled = true;
              break;
            case 'certExpirationDate':
              this.certExpirationDateDisabled = true;
              break;
          }
        } else {
          this.mainForm.get(field).enable();
          switch (field) {
            case 'certIssueDate':
              this.certIssueDateDisabled = false;
              break;
            case 'certExpirationDate':
              this.certExpirationDateDisabled = false;
              break;
          }
        }
      }
    } else {
      this.mainForm.disable();
    }
    if (changes.isModule && !this.isEdit) {
      this.initGrades(false);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initGrades(disable: boolean) {
    this.inputObjGrades = {
      labelText: 'Final Grade',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setGradeId,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getModuleGradeLookupData(false).then((lookupData) => {
      this.inputObjGrades.data = lookupData;
      this.inputObjGrades = Object.assign({}, this.inputObjGrades);
    });
  }

  getGradeData(event: any) {
    if (event) {
      this.setGradeId = event[0].ID;
    } else {
      this.setGradeId = null;
    }
    this.mainForm.get('finalGrade').setValue(this.setGradeId);
    this.mainForm.markAsDirty();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Student data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  loadTable(event?): void {
    if (event) {
      this.currentFilter.rows = event.rows;
      this.currentFilter.sortOrder = event.sortOrder;
      this.currentFilter.first = event.first;
    }
    this.getList();
    this.getTotal();
  }

  getList(): void {
    const showAll = (this.global === true) ? true : null;
    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.classStudentsService.getClassStudentPickerPrimeNG('DATA', this.ClassID, this.currentFilter, showAll)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.studentPickerList = res.Data;
          this.studentPickerList.forEach(x => {
            x.FullName = x.FirstName + ' ' + x.LastName;
          });
          this.loading = false;
          this.global = false;
        }, error: () => {
          this.loading = false;
          this.global = false;
        }
      });
  }

  getTotal(): void {
    const showAll = (this.global === true) ? true : null;
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.classStudentsService.getClassStudentPickerPrimeNG('TOTAL', this.ClassID, this.currentFilter, showAll)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          this.global = false;
        }
      });
  }

  initTrainingStatus(disable: boolean): void {
    this.inputObjTrainingStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setTrainingStatusId,
      disabled: disable,
      canWrite: this.canTabWrite,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getTrainingStatusesLookupData('class').then((lookupData) => {
      this.inputObjTrainingStatus.data = lookupData;
      this.inputObjTrainingStatus = Object.assign({}, this.inputObjTrainingStatus);
    });
  }

  getTrainingStatus(event: any): void {
    if (event && event[0] && event[0].ID) {
      if (event[0].Description === 'Completed') {
        this.certIssueDateDisabled = false;
        this.mainForm.get('certIssueDate').enable();
        this.certExpirationDateDisabled = false;
        this.mainForm.get('certExpirationDate').enable();
        this.mainForm.get('noExpirationDate').enable();
        this.mainForm.get('certification').enable();
      } else {
        this.certIssueDateDisabled = true;
        this.mainForm.get('certIssueDate').disable();
        this.certExpirationDateDisabled = true;
        this.mainForm.get('certExpirationDate').disable();
        this.mainForm.get('noExpirationDate').disable();
        this.mainForm.get('certification').disable();
      }
      this.setTrainingStatusId = event[0].ID;
    } else {
      this.setTrainingStatusId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('status').setValue(this.setTrainingStatusId);
  }

  filterGlobalItems(): void {
    this.global = true;
    this.filterItems();
  }

  filterItems(): void {
    this.initTrainingStatus(!this.canTabWrite);
    this.showTable = false;
    delete this.currentFilter.filters.StudentGroupID;
    delete this.currentFilter.filters.setStudentStatusDesc;
    delete this.currentFilter.filters.setProgramDesc;
    delete this.currentFilter.filters.setRateLevelDesc;
    delete this.currentFilter.filters.FilterName;
    delete this.currentFilter.filters.MemberNumber;
    if (this.activeGlobalFilter) {
      this.initFilters('FilterName', this.activeGlobalFilter, 'contains', 'and');
    } else if (this.memberID) {
      this.initFilters('MemberNumber', this.memberID, 'contains', 'and');
    } else {
      if (this.setStudentGroupId) {
        this.initFilters('StudentGroupID', this.setStudentGroupId, 'equals', 'and');
      }
      if (this.setStudentStatusDesc) {
        this.initFilters('StatusAlias', this.setStudentStatusDesc, 'equals', 'and');
      }
      if (this.setProgramDesc && this.setRateLevelDesc) {
        this.initFilters('ProgramDescription', this.setProgramDesc, 'equals', 'and');
        this.initFilters('RateLevelDescription', this.setRateLevelDesc, 'equals', 'and');
      } else if (this.setProgramDesc) {
        this.initFilters('ProgramDescription', this.setProgramDesc, 'equals', 'and');
      }
    }
    //give it time to remove the table, so it will load again. Not ideal but this search is a one-off.
    setTimeout(() => {
      this.showTable = true;
    }, 300);
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  initForm(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.loading = true;
    if (this.isEdit) {
      this.setTrainingStatusId = this.currentRecord.TrainingStatusID;
      this.mainForm.get('status').setValue(this.currentRecord.TrainingStatusID);
      if (this.currentRecord.StatusDate_D) {
        const sDate = new Date(this.currentRecord.StatusDate_D);
        console.log(sDate);
        this.mainForm.get('dt').setValue(sDate);
      } else {
        this.mainForm.get('dt').setValue(null);
      }
      this.mainForm.get('dt').updateValueAndValidity();
      if (this.currentRecord.ExpirationDate_D) {
        const expDate = new Date(this.currentRecord.ExpirationDate_D);
        this.mainForm.get('expDate').setValue(expDate);
      } else {
        this.mainForm.get('expDate').setValue(null);
      }
      this.mainForm.get('grade').setValue(this.currentRecord.Grade);
      this.mainForm.get('gradeAverage').setValue(this.currentRecord.ModuleGradeAverage);
      this.mainForm.get('gradeAverage').disable();
      this.mainForm.get('finalGrade').setValue(this.currentRecord.ResultDescription);
      this.setResultId = this.currentRecord.ResultID;
      this.mainForm.get('result').setValue(this.currentRecord.ResultID);
      this.mainForm.get('certification').setValue(this.currentRecord.CertificationNumber);
      this.mainForm.get('trainingIdentifier').setValue(this.currentRecord.TrainingIdentifier);
      this.setGradeId = this.currentRecord.ResultID;
      this.initGrades(false);
      this.showCertificationFields = this.currentRecord.ShowCertificationFields;
      if (this.showCertificationFields) {
        if (this.currentRecord.CertificationIssueDate) {
          const certificationIssueDate = new Date(this.currentRecord.CertificationIssueDate);
          this.mainForm.get('certIssueDate').setValue(certificationIssueDate);
        } else {
          this.mainForm.get('certIssueDate').setValue(null);
        }
        if (this.currentRecord.CertificationExpirationDate) {
          const certificationExpirationDate = new Date(this.currentRecord.CertificationExpirationDate);
          this.mainForm.get('certExpirationDate').setValue(certificationExpirationDate);
          this.mainForm.get('certExpirationDate').setValidators(Validators.required);
          this.certExpirationDateDisabled = false;
          this.mainForm.get('certExpirationDate').enable();
          this.mainForm.get('noExpirationDate').setValue(false);
        } else {
          this.mainForm.get('certExpirationDate').setValue(null);
          this.mainForm.get('certExpirationDate').setValidators(null);
          this.certExpirationDateDisabled = true;
          this.mainForm.get('certExpirationDate').disable();
          this.mainForm.get('noExpirationDate').setValue(true);
        }
        this.mainForm.get('certExpirationDate').updateValueAndValidity();
        this.certificationResource = !!(this.currentRecord.CertificationResourceID);
      } else {
        this.mainForm.get('certIssueDate').setValidators(null);
        this.mainForm.get('certIssueDate').updateValueAndValidity();
        this.mainForm.get('certExpirationDate').setValidators(null);
        this.mainForm.get('certExpirationDate').updateValueAndValidity();
      }
      this.initTrainingStatus(!this.canTabWrite);
      // this.initResult(!this.canTabWrite);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.loading = false;
  }

  toggleExpirationDate(event: any): void {
    if (event && event.checked) {
      this.mainForm.get('certExpirationDate').setValue(null);
      this.certExpirationDateDisabled = true;
      this.mainForm.get('certExpirationDate').disable();
      this.mainForm.get('certExpirationDate').setValidators(null);
      this.mainForm.get('certExpirationDate').updateValueAndValidity();
    } else {
      this.certExpirationDateDisabled = false;
      this.mainForm.get('certExpirationDate').enable();
      this.mainForm.get('certExpirationDate').setValidators(Validators.required);
      this.mainForm.get('certExpirationDate').updateValueAndValidity();
    }
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }


  getDataToEdit(): void {
    if (this.ClassID && this.ClassStudentID) {
      this.loading = true;
      this.classStudentsService.getClassStudent(this.ClassID, this.ClassStudentID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.currentRecord = res;
            if (this.currentRecord.TrainingStatusDescription !== 'Completed') {
              this.mainForm.get('certification').disable();
              this.certIssueDateDisabled = true;
              this.mainForm.get('certIssueDate').disable();
              this.certExpirationDateDisabled = true;
              this.mainForm.get('certExpirationDate').disable();
              this.mainForm.get('noExpirationDate').disable();
              this.mainForm.get('dt').setValidators(null);
            } else {
              this.mainForm.get('certification').enable();
              this.certIssueDateDisabled = false;
              this.mainForm.get('certIssueDate').enable();
              this.certExpirationDateDisabled = false;
              this.mainForm.get('certExpirationDate').enable();
              this.mainForm.get('noExpirationDate').enable();
              this.mainForm.get('dt').setValidators([Validators.required]);
            }
            this.initForm();
            this.loading = false;
          }, error: () => {
            this.loading = false;
          }
        });
    }
  }

  setDefaultPic() {
    //this.accountAdjustment.ThumbnailUrl = 'assets/images/profile-generic.png';
  }

  processData(): void {
    if ((this.mainForm.valid && this.mainForm.dirty) || this.selStudent.length > 0) {
      this.existingMessage = false;
      const ids: number[] = [];
      this.isSaving = true;
      // console.log(this.mainForm.get('dt').value);
      if (this.isEdit) {
        this.classStudent.TrainingStatusID = this.mainForm.get('status').value;
        if (this.mainForm.get('dt').value) {
          this.classStudent.StatusDate_D = transformDateTimeToDateOnly(this.mainForm.get('dt').value);
        }
        if (this.mainForm.get('expDate').value) {
          this.classStudent.ExpirationDate_D = transformDateTimeToDateOnly(this.mainForm.get('expDate').value);
        }
        this.classStudent.Grade = this.mainForm.get('grade').value;
        this.classStudent.ResultID = this.mainForm.get('finalGrade').value;
        this.classStudent.TrainingIdentifier = this.mainForm.get('trainingIdentifier').value;
        this.classStudent.CertificationNumber = this.mainForm.get('certification').value;
        if (this.mainForm.get('certIssueDate').value) {
          this.classStudent.CertificationIssueDate = transformDateTimeToDateOnly(this.mainForm.get('certIssueDate').value);
        }
        if (this.mainForm.get('certExpirationDate').value) {
          this.classStudent.CertificationExpirationDate = transformDateTimeToDateOnly(this.mainForm.get('certExpirationDate').value);
        }
        this.classStudent.CertificationResourceID = this.currentRecord?.CertificationResourceID;
        this.currentRecord.ModuleGradeAverage = this.mainForm.get('gradeAverage').value;
      } else {
        this.selStudent.forEach(x => {
          ids.push(x.PersonID);
        });
      }
      //this.mainForm.get('sendEmail').
      this.saveData(ids);
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Missing Data',
        detail: 'No data was saved, the form is currently incomplete.'
      });
      this.existingMessage = false;
    }
  }

  reset(): void {
    this.route.params.subscribe({
      next: () => {
        this.getDataToEdit();
      }
    });
  }

  saveData(ids: number[]): void {
    if (this.isEdit) {
      this.saveNewFiles().then((fileData) => {
        if (fileData) {
          this.classStudent.CertificationResourceID = fileData[0].ResourceId;
        }
        this.classStudentsService.updateClassStudent(this.ClassID, this.ClassStudentID, this.classStudent)
          .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.confirmationService.close();
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
            this.mainForm.markAsUntouched();
            this.mainForm.markAsPristine();
            this.isSaving = false;
            this.existingMessage = false;
            this.getDataToEdit();
            this.closeAddEdit.emit(true);
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
            this.isSaving = false;
            console.debug(e);
          }
        });
      });
    } else {
      const dataModel: CreateClassStudentModel = {
        PersonIDList: ids,
        TrainingStatusID: this.setTrainingStatusId,
        SendRegistrationEmail: this.mainForm.get('sendEmail').value
      };
      this.classStudentsService.createClassStudent(this.ClassID, dataModel)
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your changes have been saved.'});
          this.selStudent = [];
          this.mainForm.markAsUntouched();
          this.mainForm.markAsPristine();
          this.isSaving = false;
          this.closeAddEdit.emit(true);
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: e.error.Message.replace('UM: ', ''),
              life: 5000
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
          }
          this.isSaving = false;
          console.debug(e);
        }
      });
    }
  }

  saveNewFiles(): any {
    this.showProgress = true;
    return new Promise((resolve, reject) => {
      if (this.uploadedFiles.length > 0) {
        this.resourcesService.uploadResource(this.fd, 'public')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (fileData) => {
              resolve(fileData);
              this.showProgress = false;
            }
          });
      } else {
        this.showProgress = false;
        resolve(null);
      }
      console.debug(reject);
    });
  }

  onUpload(event: any): any {
    if (event.files.length > 0) {
      this.mainForm.get('files').setValue(event.files.length);
      for (const file of event.files) {
        this.uploadedFiles.push(file);
        this.fd.append('file', file);
      }
      this.certificationResource = true;
      //this.messageService.add({severity: 'info', summary: 'File(s) Uploaded', detail: ''});
      this.mainForm.markAsDirty();
      if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
        this.existingMessage = true;
        this.openDialog();
      }
    }
  }

  clearUpload(): void {
    this.uploadedFiles.length = 0;
    this.fd = new FormData();
    this.mainForm.get('files').setValue(null);
    this.mainForm.markAsDirty();
    this.certificationResource = false;
  }

  removeResource(): void {
    this.classStudent.CertificationResourceID = null;
    this.mainForm.markAsDirty();
    if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
      this.existingMessage = true;
      this.openDialog();
    }
    this.clearUpload();
  }

  openResource(resourceReferenceId: number): void {
    this.resourcesService.getResourceById(resourceReferenceId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (resource) => {
          if (resource) {
            window.open(resource.Url, '_blank');
          }
        }
      });
  }

  initStudentGroup(disable: boolean): void {
    this.inputObjStudentGroup = {
      labelText: 'Search by Student Group',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getStudentGroupsLookupData(this.OrganizationID, true).then((lookupData) => {
      this.inputObjStudentGroup.data = lookupData;
      this.inputObjStudentGroup = Object.assign({}, this.inputObjStudentGroup);
    });
  }

  getStudentGroupData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setStudentGroupId = event[0].ID;
    } else {
      this.setStudentGroupId = null;
    }
    this.activeGlobalFilter = null;
    this.memberID = null;
    this.showTable = false;
  }

  initProgram(disable: boolean): void {
    this.inputObjProgram = {
      labelText: 'Search by Program',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getAllProgramsLookupData(true).then((lookupData) => {
      this.inputObjProgram.data = lookupData;
      this.inputObjProgram = Object.assign({}, this.inputObjProgram);
    });
  }

  getProgramData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setProgramId = event[0].ID;
      if (event[0].Description.indexOf(' - ') > 0) {
        this.setProgramDesc = event[0].Description.substring(event[0].Description.indexOf(' - ') + 3);
      } else {
        this.setProgramDesc = event[0].Description;
      }
    } else {
      this.setProgramId = null;
      this.setProgramDesc = null;
    }
    this.activeGlobalFilter = null;
    this.memberID = null;
    this.showTable = false;
    this.setRateLevelDesc = null;
    this.initRateLevel(this.setProgramId, this.setProgramId === null);
  }

  initRateLevel(programId: number, disable: boolean): void {
    this.inputObjRateLevel = {
      labelText: 'Search by Rate Level',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (programId > 0) {
      this.lookupsDataService.getProgramWageRatesLookupData(programId).then((lookupData) => {
        this.inputObjRateLevel.data = lookupData;
        this.inputObjRateLevel = Object.assign({}, this.inputObjRateLevel);
      });
    }
  }

  getRateLevelData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setRateLevelDesc = event[0].Description;
    } else {
      this.setRateLevelDesc = null;
    }
    this.activeGlobalFilter = null;
    this.memberID = null;
    this.showTable = false;
  }

  initStudentStatus(disable: boolean): void {
    this.inputObjStudentStatus = {
      labelText: 'Search by Student Status',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getPersonProfileStatusesLookupData('training', true).then((lookupData) => {
      this.inputObjStudentStatus.data = lookupData;
      this.inputObjStudentStatus = Object.assign({}, this.inputObjStudentStatus);
    });
  }

  getStudentStatusData(event: any): void {
    if (event && event[0] && event[0].ID) {
      this.setStudentStatusDesc = event[0].Description;
    } else {
      this.setStudentStatusDesc = null;
    }
    this.activeGlobalFilter = null;
    this.memberID = null;
    this.showTable = false;
  }

  isStatusInActive(status): any {
    return this.inactiveStatuses.indexOf(status) > -1;
  }

  removeCert(sharableId) {
    this.dialogUtility.promptToDelete(() => {
      this.sharablesService.deleteSharable(sharableId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected training/certification has been removed.'
            });
            // this.mainForm.get('certIssueDate').setValue(null);
            // this.mainForm.get('certExpirationDate').setValue(null);
            // this.mainForm.get('certification').setValue(null);
            this.closeAddEdit.emit(true);
            this.getDataToEdit();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  focusIn(input: string): void {
    switch (input) {
      case 'name':
        this.memberID = null;
        break;
      case 'id':
        this.activeGlobalFilter = null;
        break;
    }
    this.setStudentStatusDesc = null;
    this.initStudentStatus(false);
    this.setStudentGroupId = null;
    this.initStudentGroup(false);
    this.setProgramId = null;
    this.setProgramDesc = null;
    this.initProgram(false);
    this.setRateLevelDesc = null;
    this.initRateLevel(0, true);
  }
}
