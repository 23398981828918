<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3>Filter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <label>Name</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="activeGlobalFilter" class="full-width filter" pInputText
               type="text"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <p-checkbox (click)="manageEnableDisable('enable')" [(ngModel)]="isCheckboxExpired" binary="true"
                    label="Expired"></p-checkbox>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <p-checkbox (click)="manageEnableDisable('disable')" [(ngModel)]="isCheckboxActive" binary="true"
                    label="Active"></p-checkbox>
      </div>
      <!--
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <p-checkbox binary="true" label="Locked Out" [(ngModel)]="lockedOutUser"></p-checkbox>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <p-checkbox binary="true" label="Enabled" [(ngModel)]="enabledUser" (click)="manageEnableDisable('enable')"></p-checkbox>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <p-checkbox binary="true" label="Disabled" [(ngModel)]="disabledUser" (click)="manageEnableDisable('disable')"></p-checkbox>
      </div>
      -->
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
        <div class="list-filter-buttons text-align-right">
          <button (click)="clearFilters()" [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" class="p-button-download export-menu" icon="pi pi-times"
                  label="Clear" pButton>
          </button>
          <button (click)="filterItems()" class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  pButton>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total">@if (trainingCertifications) {
          <span>{{ dataTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (trainingCertifications) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        @if ((currentFilter.filters | json) !== '{}') {
          <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                  pTooltip="Clear Filter" tooltipPosition="bottom"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                  (click)="clearFilters()">
          </button>
        }
        <button (click)="refresh()" icon="pi pi-refresh" pButton
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canTabWrite) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (trainingCertificationList) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Training/Certification" [styleClass]="'list-card'">
          <app-training-certifications-form [DBEntity]="DBEntity" [DBEntityID]="DBEntityID" [isEdit]="false"
                                            [canTabWrite]="canTabWrite"
                                            (closeAddEdit)="updateList($event)"></app-training-certifications-form>
        </p-card>
      </div>
    }
    @if (totalLoaded && dataTotal <= 0) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (trainingCertification of trainingCertificationList; track trainingCertification; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              @if (trainingCertification.ExpirationDate_D && getDate(trainingCertification.ExpirationDate_D) < today) {
                <div class="inactive-flag"></div>
              }
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i)"
                     [ngClass]="{'' : !trainingCertification.ExpirationDate_D || getDate(trainingCertification.ExpirationDate_D) >= today, 'inactive' : trainingCertification.ExpirationDate_D && getDate(trainingCertification.ExpirationDate_D) < today}">
                  <div class="list-header">
                    {{ trainingCertification.Title }}
                  </div>
                  <div class="list-subheader">
                    {{ trainingCertification.Type | titlecase }}@if (trainingCertification.CompletionDate_D) {
                    <span> | @if (trainingCertification.Type === 'Training') {
                      <span>Completion</span>
                    }
                      @if (trainingCertification.Type === 'Certification') {
                        <span><b>Issue Date:</b></span>
                      } {{ trainingCertification.CompletionDate_D | date:'shortDate' }}</span>
                  }
                    @if (trainingCertification.ExpirationDate_D) {
                      <span> | @if (getDate(trainingCertification.ExpirationDate_D) >= today) {
                        <span><b>Expires:</b></span>
                      }
                        @if (getDate(trainingCertification.ExpirationDate_D) < today) {
                          <span><b>EXPIRED:</b></span>
                        } {{ trainingCertification.ExpirationDate_D | date:'shortDate' }}</span>
                    }
                  </div>
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    @if (canTabWrite && trainingCertification.CanDelete) {
                      <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                           (click)="deleteTrainingCertification(trainingCertification.SharableId)"><i
                        class="fas fa-trash-alt"></i></div>
                    }
                    <div class="icon" (click)="expandData(i)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                  </div>
                </div>
                @if (selectedItem === i) {
                  <div class="full-width margin-top-10">
                    <app-training-certifications-form [sharableID]="trainingCertification.SharableId"
                                                      [DBEntity]="DBEntity" [DBEntityID]="DBEntityID" [isEdit]="true"
                                                      [canTabWrite]="canTabWrite"
                                                      (closeAddEdit)="updateList($event)"></app-training-certifications-form>
                  </div>
                }
              </div>
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
