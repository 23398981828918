import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { PositionConfigGridModel, PositionConfigModel, CreatePositionModel,
  UpdatePositionModel, PositionConfigRoleListModel } from '../models/position-config.model';

@Injectable({
  providedIn: 'root',
})
export class PositionConfigsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getPositionConfigsPrimeNG(dataMode: string, organizationId: number, filterData): Observable<PositionConfigGridModel> {
    return this.http.post<PositionConfigGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/configureposition/grid?mode=${dataMode}`, filterData);
  }

  getPositionConfig(organizationId: number, positionId: number): Observable<PositionConfigModel> {
    return this.http.get<PositionConfigModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/configureposition/${positionId}`);
  }

  createPositionConfig(organizationId: number, modelData: CreatePositionModel): Observable<PositionConfigModel> {
    return this.http.post<PositionConfigModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/configureposition/create`, modelData);
  }

  updatePositionConfig(organizationId: number, positionId: number, modelData: UpdatePositionModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/configureposition/${positionId}`, modelData);
  }

  deletePositionConfig(organizationId: number, positionId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/configureposition/remove/${positionId}`);
  }

  getAvailableRoleList(organizationId: number): Observable<PositionConfigRoleListModel[]> {
    return this.http.get<PositionConfigRoleListModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${organizationId}/configureposition/newpositionrolelist`);
  }
}
