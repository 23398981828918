@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
} @else {
  <div class="content p-input-filled margin-top-10">
    <div>
      @if (isPage) {
        <div class="add-edit-header">
          <div class="inline-back-button" (click)="back()" pTooltip="Back">
            <i class="pi pi-angle-left"></i>
          </div>
          @if (isEdit && workflowStep) {
            <h2>{{ workflowStep.StepDescription }}</h2>
          }
          @if (!isEdit) {
            <h2>Add Step Option</h2>
          }
        </div>
      }
      <p-card>
        <form [formGroup]="mainForm">
          <div class="p-grid p-fluid">
            <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
              <label>Step/Intent <sup class="req">*</sup></label>
              <input pInputText formControlName="step" maxlength="200" tabindex="0">
              @if ((mainForm.controls.step.dirty || mainForm.controls.step.touched) &&
              mainForm.controls.step.errors?.required) {
                <span class="p-error">'Step' must be provided</span>
              }
            </div>
            @if (canPageWrite) {
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="p-grid p-fluid">
                  <div class="col-md-6 col-sm-6 col-xs-12"></div>
                  <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
                    @if (isEdit) {
                      <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                              icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                              (click)="cancel()" [disabled]="!mainForm.dirty"></button>
                    } @else {
                      <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'"
                              icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                              (click)="cancel()"></button>
                    }
                    @if (!isSaving) {
                      <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                              tooltipPosition="bottom"
                              (click)="processData()" [className]="'inline-button'" [disabled]="!isFormValid()"
                      ></button>
                    } @else {
                      <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'"
                              [disabled]="true">
                        <i class="pi pi-spin pi-spinner save-spinner"></i>
                      </button>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </form>
      </p-card>
    </div>
  </div>
}
