import {AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RosterProfileModel} from '../../models/roster-profile.model';
import {RosterProfileService} from '../../services/roster-profile.service';
import {Location} from '@angular/common';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ContextService} from '../../services/context.service';
import {InterfaceObjectAppsModel, InterfaceObjectItemPermissions} from '../../models/interface-objects.model';
import {tabsPermissionsUtility} from '../../shared/utilities/tabs-permissions.utility';
import {PageModel} from '../../models/page.model';
import {PageService} from '../../services/page.services';
import {dateDiffInYearsMonths} from '../../shared/utilities/form.utility';
import {ConfirmationService, MessageService} from 'primeng/api';
import {activityDrivenMembers, employmentTradeTab} from '../../shared/utilities/component-manager-utility';
import {InactiveStatusUtility} from '../../shared/utilities/inactive-status.utility';

@Component({
  selector: 'app-roster-tab-container',
  templateUrl: './roster-tab-container.component.html',
  styleUrls: ['./roster-tab-container.component.scss']
})
export class RosterTabContainerComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  InterfaceObjectEnum: string = 'ROSTER';
  invalidRecord: boolean = false;
  dataId: number;
  roster: RosterProfileModel = {} as RosterProfileModel;
  origRoster: RosterProfileModel = {} as RosterProfileModel;
  permissions: InterfaceObjectAppsModel[] = [];
  setRosterQueryId: number = 0;
  reload: boolean = true;
  isSaving: boolean = false;
  isDialog: boolean = false;
  blobToken: string;
  inactiveStatuses: string[] = [];
  @Input() organizationId: number;
  @Input() ContentID: number;
  @Input() isEdit: boolean;
  @Input() canPageWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Output() updateImage = new EventEmitter<any>();
  isPage: boolean = false;
  yearsService: string;
  showTabs: boolean;
  workingAs = sessionStorage.getItem('workingAs');
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  public _activityDrivenMembers = activityDrivenMembers;
  public _employmentTradeTab = employmentTradeTab;
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(private rosterProfileService: RosterProfileService, private messageService: MessageService,
              private route: ActivatedRoute, private changeDetection: ChangeDetectorRef,
              public config: DynamicDialogConfig, private pageService: PageService,
              private location: Location, public contextService: ContextService,
              private confirmationService: ConfirmationService, private inactiveStatusUtility: InactiveStatusUtility) {
  }

  ngOnInit(): void {
    this.blobToken = this.contextService.contextObject.blobToken;
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isEdit = this.pageInfo.isEdit;
          this.isPage = this.pageInfo.isPage;
          this.isDialog = this.pageInfo.isDialog;
          this.ContentID = this.pageInfo.ContentID;
          this.getDataToEdit(this.ContentID);
        }
      }
    });
    if (this.canPageWrite === undefined) {
      this.pageService.getPagePermissions(this.InterfaceObjectEnum).then((permission: InterfaceObjectItemPermissions) => {
        this.canPageWrite = permission.Write;
      });
    }
    this.pageService.getTabPermissions(this.InterfaceObjectEnum).then((tabPermissions: InterfaceObjectAppsModel[]) => {
      this.permissions = tabPermissions;
      this.showTabs = true;
    });
    this.inactiveStatusUtility.getPersonProfileInactiveStatuses('membership').then(x => {
      this.inactiveStatuses = x;
    }).catch(y => {
      this.inactiveStatuses = y;
    });

    this.changeStatus();
  }

  ngOnChanges(): void {
    if (this.ContentID && !this.isPage) {
      this.getDataToEdit(this.ContentID);
    }
  }

  changeStatus(): void {
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterContentInit(): void {
    this.changeStatus();
  }

  getDataToEdit(id): void {
    this.reload = false;
    this.rosterProfileService
      .getRosterProfile(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: RosterProfileModel) => {
          this.origRoster = res;
          this.roster = res;
          this.organizationId = res.OrganizationID;
          this.updateImage.emit(this.roster.ThumbnailUrl);
          if (this.roster.MemberStartDate) {
            const dtStart: Date = new Date(this.roster.MemberStartDate);
            const dtToday: Date = new Date();
            this.yearsService = dateDiffInYearsMonths(dtStart, dtToday);
          } else {
            this.yearsService = null;
          }
          this.reload = true;
        }, error: (e) => {
          this.reload = true;
          console.debug(e);
          if (e) {
            if (e.Message) {
              this.messageService.add({severity: 'warn', summary: 'Record Not Found', detail: e.Message});
            }
            if (!e.ok) {
              this.invalidRecord = true;
            }
          }
        }
      });
  }

  reset(): void {
    this.route.params.subscribe({
      next: (params) => {
        this.dataId = Number(params.id);
        if (this.dataId) {
          this.getDataToEdit(this.dataId);
        }
      }
    });
  }

  // detailsSaved(roster): void {
  //   this.isEdit = true;
  //   this.roster = roster;
  //   this.origRoster = roster;
  //   this.closeAddEdit.emit();
  // }

  back(): void {
    this.location.back();
  }

  setDefaultPic() {
    this.roster.ThumbnailUrl = 'assets/images/profile-generic.png';
  }

  resetWarning() {
    this.confirmationService.close();
  }

  isStatusInActive(status): any {
    return this.inactiveStatuses.indexOf(status) > -1;
  }

  getAvatarClass(img: boolean, status: string): any {
    let str: string = '';
    if (img === false) {
      str = '-no-image';
    }
    switch (true) {
      case status === 'Deceased':
        return 'deceased-status' + str;
      case status === 'Suspended':
        return 'suspended-status' + str;
      case this.inactiveStatuses.indexOf(status) > -1:
        return 'inactive-status' + str;
      case this.inactiveStatuses.indexOf(status) === -1:
        return 'active-status' + str;
    }
  }
}
