import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {FilterModel, PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {StudentClassCoreModel, StudentClassesGridModel} from '../../../../../models/student-classes.model';
import {StudentClassService} from '../../../../../services/student-classes.service';
import {listFilterUtility} from '../../../../utilities/list-filter.utility';
import {removeKeysFromArray} from '../../../../utilities/data.utility';
import {exportData} from '../../../../utilities/list-table.utility';
import {ClassTabDialogContainerComponent} from '../class-modules-dialog-tab-container/class-tab-container.component';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {ContextService} from '../../../../../services/context.service';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';
import {DialogUtility} from '../../../../utilities/dialog.utility';

@Component({
  selector: 'app-student-classes',
  templateUrl: './student-class-history.component.html',
  styleUrls: ['./student-class-history.component.scss']
})

export class StudentClassHistoryComponent implements OnInit, OnDestroy, OnChanges {
  context = this.contextService.contextObject;
  studentClasses: StudentClassesGridModel;
  isDirty: boolean;
  isSaving: boolean = false;
  isExporting: boolean;
  studentClassesList: StudentClassCoreModel[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  inputObjStatus = {} as GenericSelectModel;
  setStatus: number[] = [];
  showAddEdit: boolean;
  dataLoaded: boolean;
  showData: boolean = false;
  selectedItem: number;
  dataTotal: number = 0;
  showFilter: boolean = true;
  enrolledStatusId: number[] = [];
  statusData;
  @Input() isEdit: boolean;
  @Input() isPage: boolean;
  @Input() PersonID: number;
  @Input() canTabWrite: boolean;
  @Input() classStatus: string;
  @Output() resetList = new EventEmitter<boolean>();
  ProgramStudentID: number;
  showActiveOnly: boolean;
  showCompletedOnly: boolean;
  isMemberRoster: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private ref: DynamicDialogRef, private lookupsDataService: LookupsDataService,
              private studentClassServices: StudentClassService, private dialogUtility: DialogUtility,
              private dialogService: DialogService, private router: Router,
              private contextService: ContextService, public pageRowCountUtility: PageRowCountUtility
  ) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    if (this.router.url.includes('/membership')) {
      this.isMemberRoster = true;
    }
  }

  ngOnChanges(): void {
    this.getEnrolledStatuses();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getEnrolledStatuses(): void {
    this.statusData = null;
    this.lookupsDataService.getTrainingStatusesEnrolledLookup('class', false, false).then((lookupData) => {
      this.statusData = lookupData;
      if (this.classStatus === 'enrolled') {
        if (this.statusData.filter(x => x.Active === true)) {
          this.statusData.forEach(status => {
            if (status.Active === true) {
              this.enrolledStatusId.push(status.ID);
            }
          });
          // this.setStatus = this.statusData.filter(y => this.enrolledStatusId.includes(y.ID));
          this.setStatus = this.enrolledStatusId;
          this.initStatus(false);
          this.filterItems();
        } else if (this.classStatus === 'enrolled' && this.PersonID) {
          this.showActiveOnly = true;
          this.getStudentClasses();
        }
      } else {
        if (this.classStatus === 'completed' && this.PersonID) {
          this.showCompletedOnly = true;
          this.getStudentClasses();
        }
      }
    });
  }

  getStudentClasses(): void {
    if (this.context?.SuperUser) {
      if (this.getListRequest) {
        this.getListRequest.unsubscribe();
      }
      this.selectedItem = -1;
      this.studentClassesList.length = 0;
      this.dataLoaded = false;
      this.getListRequest = this.studentClassServices.getStudentClassesPrimeNG('DATA', this.PersonID, this.currentFilter, this.showCompletedOnly, this.showActiveOnly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.studentClassesList = res.Data;
            this.dataLoaded = true;
          }
        });
      if (this.getTotalRequest) {
        this.getTotalRequest.unsubscribe();
      }
      this.dataTotal = 0;
      this.getTotalRequest = this.studentClassServices.getStudentClassesPrimeNG('TOTAL', this.PersonID, this.currentFilter, this.showCompletedOnly, this.showActiveOnly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.dataTotal = res.Total;
          }
        });
    } else {
      if (this.getListRequest) {
        this.getListRequest.unsubscribe();
      }
      this.selectedItem = -1;
      this.studentClassesList.length = 0;
      this.dataLoaded = false;
      this.getListRequest = this.studentClassServices.getUserClassesPrimeNG('DATA', this.currentFilter, this.showCompletedOnly, this.showActiveOnly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.studentClassesList = res.Data;
            this.dataLoaded = true;
          }
        });
      if (this.getTotalRequest) {
        this.getTotalRequest.unsubscribe();
      }
      this.dataTotal = 0;
      this.getTotalRequest = this.studentClassServices.getUserClassesPrimeNG('TOTAL', this.currentFilter, this.showCompletedOnly, this.showActiveOnly)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.dataTotal = res.Total;
          }
        });
    }
  }

  initStatus(disable: boolean): void {
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setStatus,
      data: this.statusData,
      disabled: false,
      emitChangeOnLoad: true
    };
  }

  setStatusData(event:any): void {
    if (event) {
      this.setStatus = event;
    } else {
      this.setStatus = null;
    }
  }

  filterStatus(): void {
    this.currentFilter.filters.TrainingStatusID = [];
    if (this.setStatus && this.setStatus.length > 0) {
      const selected: FilterModel[] = [];
      this.setStatus.forEach(x => {
        selected.push({value: x, matchMode: 'equals', operator: 'or'});
      });
      this.currentFilter.filters.TrainingStatusID = selected;
    }
  }

  expandData(index, id): void {
    this.showData = !this.showData;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getStudentClasses();
  }

  refresh(): void {
    this.getStudentClasses();
  }

  filterItems(): void {
    this.dataLoaded = false;
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('CourseDescription', this.activeGlobalFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.CourseDescription;
    }
    this.filterStatus();
    this.getStudentClasses();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.setStatus = [];
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.getStudentClasses();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string): void {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  updateList(): void {
    this.showAddEdit = false;
    this.getStudentClasses();
    this.selectedItem = -1;
  }

  cancel(): void {
    this.ref.destroy();
    this.showAddEdit = false;
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  showClass(id, description): void {
    if (this.context?.SuperUser) {
      const ref = this.dialogService.open(ClassTabDialogContainerComponent, {
        data: {
          id,
          isEdit: true,
          isDialog: true,
          isPage: false
        },
        header: description,
        width: '90%',
        height: '70%'
      });
    }
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.studentClassesList));
        const dataToExport = removeKeysFromArray(arr, []);
        exportData('student-class-history', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        if (this.context?.SuperUser) {
          this.studentClassServices.getStudentClassesPrimeNG('DATA', this.PersonID, exportFilter, this.showCompletedOnly, this.showActiveOnly)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, []);
                exportData('student-class-history', dataToExport);
                this.isExporting = false;
              }
            });
        } else {
          this.studentClassServices.getUserClassesPrimeNG('DATA', this.currentFilter, this.showCompletedOnly, this.showActiveOnly)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                const arr = JSON.parse(JSON.stringify(res.Data));
                const dataToExport = removeKeysFromArray(arr, []);
                exportData('student-class-history', dataToExport);
                this.isExporting = false;
              }
            });
        }
      }
    }, () => {
    });
  }
}
