@if (invalidRecord) {
  <app-record-not-found></app-record-not-found>
}
@if (!invalidRecord) {
  <div class="content p-input-filled margin-top-10">
    <div class="p-grid p-fluid">
      @if (isPage && !isDialog) {
        <div class="col-md-12 col-sm-12 col-xs-12 add-edit-header">
          @if (!invalidRecord && isPage && !isDialog) {
            <div class="inline-back-button" (click)="back()" pTooltip="Back">
              <i class="pi pi-angle-left"></i>
            </div>
          }
          @if (isStatusInActive(prospects.StatusAlias)) {
            <div class="inactive-flag-hard-corner"></div>
          }
          <div class="p-grid p-fluid">
            <div class="col-md-1 col-sm-3 col-xs-12 relative tab-container-profile">
              @if (prospects.ThumbnailUrl) {
                <div>
                  <div class="avatar-with-image" [ngClass]="getAvatarClass(true, prospects.StatusAlias)"><img
                    ngSrc="{{prospects.ThumbnailUrl + blobToken}}" (error)="setDefaultPic()" alt="" width="71"
                    height="71" [priority]="true"/></div>
                </div>
              }
              @if (!prospects.ThumbnailUrl) {
                <div>
                  <div class="avatar-without-image"
                       [ngClass]="getAvatarClass(false, prospects.StatusAlias)"><img
                    ngSrc="assets/images/profile-generic.png" alt="" width="71" height="71" [priority]="true"/></div>
                </div>
              }
            </div>
            @if (prospects) {
              <div class="col-md-11 col-sm-9 col-xs-12">
                @if (isEdit && prospects) {
                  <h2>{{ prospects.FirstName }} {{ prospects.LastName }}@if (prospects.Suffix) {
                    <span> {{ prospects.Suffix }}</span>
                  }</h2>
                }
                @if (isEdit && prospects) {
                  <h4>{{ prospects.OrganizationName }} | {{ prospects.StatusAlias }} {{ prospects.TypeAlias }}</h4>
                }
                @if (!isEdit) {
                  <h2>Add Prospect</h2>
                }
              </div>
            }
          </div>
        </div>
      }
      @if (isArchived) {
        <div class="col-md-12 col-sm-12 col-xs-12">
          <h3 class="error">This Converted Prospect is Read-Only.</h3>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12">
        @if (!isPage || isDialog) {
          <p-divider></p-divider>
        }
        @if (showTabs) {
          <p-tabView (onChange)="resetWarning()">
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDemographics')) {
              <p-tabPanel header="Contact"
                          [selected]="_tabsPermissionsUtility.checkTabRead(permissions,'tabDemographics')"
                          cache="false">
                <ng-template pTemplate="content">
                  <app-prospects-contact-form [personID]="ContentID"
                                              [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDemographics')"
                                              [isEdit]="isEdit"
                                              (closeAddEdit)="detailsSaved($event)"></app-prospects-contact-form>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabProspect')) {
              <p-tabPanel header="Details" [disabled]="!isEdit" cache="false">
                <ng-template pTemplate="content">
                  <app-prospects-tab-details [PersonID]="ContentID"
                                             [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabProspect')"
                                             [isEdit]="isEdit"></app-prospects-tab-details>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPii')) {
              <p-tabPanel header="Secure Data" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-prospects-tab-secure-data [PersonID]="ContentID"
                                                 [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabPii')"
                                                 [isEdit]="isEdit"></app-prospects-tab-secure-data>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCommunications')) {
              <p-tabPanel header="Communications" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-communications-user-profile-tab [DBEntityID]="ContentID"></app-communications-user-profile-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEmployment') && _employmentTradeTab()) {
              <p-tabPanel header="Employment" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-employment-trade-tab [PersonID]="ContentID" [OrganizationID]="prospects.OrganizationID"
                                            [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployment')"
                                            [isEdit]="isEdit" [interfaceObjectDesc]="'tabEmployment'"
                                            [parentObjectEnum]="InterfaceObjectEnum"></app-employment-trade-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEmployment') && !_employmentTradeTab()) {
              <p-tabPanel header="Employment">
                <ng-template pTemplate="content">
                  <app-employment-default-tab [PersonID]="ContentID" [OrganizationID]="prospects.OrganizationID"
                                              [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployment')"
                                              [isEdit]="isEdit" [interfaceObjectDesc]="'tabEmployment'"
                                              [parentObjectEnum]="InterfaceObjectEnum"></app-employment-default-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabVoter')) {
              <p-tabPanel header="Voter" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-politics-tab [PersonID]="ContentID" [interfaceObjectDesc]="'tabVoter'"
                                    [parentObjectEnum]="InterfaceObjectEnum"></app-politics-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabChangeLog')) {
              <p-tabPanel header="Change Log" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-change-logs-tab [PersonID]="ContentID" [interfaceObjectDesc]="'tabChangeLog'"
                                       [parentObjectEnum]="InterfaceObjectEnum"
                                       [context]="'organize'"></app-change-logs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCustomFields')) {
              <p-tabPanel header="Custom Fields">
                <ng-template pTemplate="content">
                  <app-custom-fields-tab
                    [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabCustomFields')"
                    [PersonID]="ContentID" [category]="'union'" [isEdit]="isEdit"
                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabCustomFields')"
                    [interfaceObjectDesc]="'tabCustomFields'"
                    [parentObjectEnum]="InterfaceObjectEnum"></app-custom-fields-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDocs')) {
              <p-tabPanel header="Docs" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-docs-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                                [DBEntity]="'PERSONPROFILE'" [DBEntityID]="prospects.ProspectProfileID"
                                [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDocs')"
                                [showAccess]="true" [InterfaceObjectEnum]="InterfaceObjectEnum"
                                [interfaceObjectDesc]="'tabDocs'"
                                [parentObjectEnum]="InterfaceObjectEnum"></app-docs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabNotes')) {
              <p-tabPanel header="Notes" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-notes-tab [canAdd]="canPageWrite && _tabsPermissionsUtility.checkTabAdd(permissions,'tabNotes')"
                                 [DBEntity]="'PERSONPROFILE'" [DBEntityID]="prospects.ProspectProfileID"
                                 [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [canEdit]="_tabsPermissionsUtility.checkTabWrite(permissions,'tabNotes')"
                                 [interfaceObjectDesc]="'tabNotes'"
                                 [parentObjectEnum]="InterfaceObjectEnum"></app-notes-tab>
                </ng-template>
              </p-tabPanel>
            }
            <!--        <p-tabPanel header="Score" *ngIf="_tabsPermissionsUtility.checkTabRead(permissions,'tabScore')" [disabled]="!isEdit">-->
            <!--        TODO-->
            <!--        </p-tabPanel>-->
          </p-tabView>
        }
      </div>
    </div>
  </div>
}
