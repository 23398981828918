import {AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ClassModel} from '../../../../../models/classes.model';
import {ClassesModulesService} from '../../../../../services/classes.service';
import {Location} from '@angular/common';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {InterfaceObjectAppsModel, InterfaceObjectItemPermissions} from '../../../../../models/interface-objects.model';
import {tabsPermissionsUtility} from '../../../../utilities/tabs-permissions.utility';
import {PageModel} from '../../../../../models/page.model';
import {PageService} from '../../../../../services/page.services';
import {ConfirmationService} from 'primeng/api';
import {ClassStudentModel} from '../../../../../models/class-students.model';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-classes-tab-container',
  templateUrl: './class-modules-dialog-tab-container.component.html',
  styleUrls: ['./class-modules-dialog-tab-container.component.scss']
})
export class ClassTabDialogContainerComponent implements OnInit, OnDestroy, AfterContentInit {
  InterfaceObjectEnum: string = 'CLASSES';
  permissions: InterfaceObjectAppsModel[] = [];
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  @Input() ContentID: number;
  @Input() isEdit: boolean;
  @Input() canPageWrite: boolean;
  @Input() canEdit: boolean;
  isDialog: boolean = false;
  dataId: number;
  class: ClassModel = {} as ClassModel;
  origClass: ClassModel = {} as ClassModel;
  reload: boolean = true;
  isPage: boolean = false;
  recipients: ClassStudentModel[];
  currentFilter = new PrimeTableFilterModel();
  showTabs: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(private classesService: ClassesModulesService,
              private route: ActivatedRoute,
              public config: DynamicDialogConfig, private pageService: PageService,
              private location: Location, private confirmationService: ConfirmationService,
              private changeDetection: ChangeDetectorRef,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);

        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isEdit = this.pageInfo.isEdit;
          this.isPage = this.pageInfo.isPage;
          this.isDialog = this.pageInfo.isDialog;
          this.ContentID = this.pageInfo.ContentID;
        }
        this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
        this.currentFilter.sortOrder = 1;
        this.currentFilter.first = 0;
        this.currentFilter.filters = {};
        this.getDataToEdit(this.ContentID);
      }
    });
    if (this.canPageWrite === undefined) {
      this.pageService.getPagePermissions(this.InterfaceObjectEnum).then((permission: InterfaceObjectItemPermissions) => {
        this.canPageWrite = permission.Write;
      });
    }
    this.pageService.getTabPermissions(this.InterfaceObjectEnum).then((tabPermissions: InterfaceObjectAppsModel[]) => {
      this.permissions = tabPermissions;
      this.showTabs = true;
    });
    this.changeStatus();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterContentInit(): void {
    this.changeStatus();
  }

  changeStatus(): void {
    this.changeDetection.detectChanges();
  }

  detailsSaved(classDetail) {
    this.isEdit = true;
    this.class = classDetail;
    this.origClass = classDetail;
    this.closeAddEdit.emit();
  }

  back(): void {
    this.confirmationService.close();
    this.location.back();
  }

  getDataToEdit(id: number): void {
    this.reload = false;
    if (id) {
      this.classesService.getClass(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.reload = true;
            this.origClass = res;
            this.class = res;
            this.canEdit = res.CanEdit;
            //this.setClassId = this.class.ClassID;
            this.getRecipients();
          }
        });
    } else {
      this.reload = true;
    }
  }

  reset(): void {
    this.route.params.subscribe({
      next: (params) => {
        this.dataId = Number(params.id);
        //this.getIndex(this.dataId);
        if (this.dataId) {
          this.getDataToEdit(this.dataId);
        }
      }
    });
  }

  resetWarning() {
    this.confirmationService.close();
  }

  getRecipients(): void {
    // this.classStudentsService.getClassStudentsGridData(this.ContentID, this.currentFilter)
    //     .pipe(takeUntil(this.ngUnsubscribe))
    //     .subscribe({next:(res) => {
    //       this.recipients = res.Data;
    //     }});
  }
}
