import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-organization-positions-tab',
  templateUrl: './organization-positions-tab.component.html',
})
export class PositionsTabComponent implements OnInit{
  selectedSubTab: string = 'list';
  @Input() organizationId : number;
  @Input() canAdd: boolean;
  @Input() organizationCategory: string;
  @Input() showFinancialContact: boolean = false;
  @Input() canTabWrite: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;

  ngOnInit(): void {
  }
}
