import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {StudentPositionsGridModel, StudentsGridModel} from '../models/students.model';
import {RosterVoterGridModel} from '../models/roster.model';

@Injectable({
  providedIn: 'root',
})
export class StudentsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  // getStudentsKGrid(rosterQueryId?: number): Observable<StudentsGridModel> {
  //   const url = (rosterQueryId) ? rosterQueryId : 0;
  //   return this.http.get<StudentsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/grid/${url}`);
  // }

  getStudentsPrimeNG(dataMode: string, filterData, rosterQueryId?: number, routeOrganizationId?: number, globalView?: boolean): Observable<StudentsGridModel> {
    const rq = (rosterQueryId) ? rosterQueryId : 0;
    const url = (routeOrganizationId) ? `&routeorganizationid=${routeOrganizationId}` : '';
    const global = (globalView === true) ? '&showall=true' : '';
    return this.http.post<StudentsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/grid/${rq}/?mode=${dataMode}${url}${global}`, filterData);
  }

  getStudentsExpandedExport(filterData, rosterQueryId?: number): Observable<ArrayBuffer> {
    const url = (rosterQueryId) ? rosterQueryId : 0;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/export/primeng/${url}?filetype=xlsx`, filterData, requestOptions);
  }

  // getStudentAttributesKGrid(rosterQueryId?: number): Observable<StudentAttributesGridModel> {
  //   const url = (rosterQueryId) ? rosterQueryId : 0;
  //   return this.http.get<StudentAttributesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/attributegrid/${url}`);
  // }

  // getRosterPositionsKGrid(rosterQueryId?: number): Observable<StudentPositionsGridModel> {
  //   const url = (rosterQueryId) ? rosterQueryId : 0;
  //   return this.http.get<StudentPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/positiongrid/${url}`);
  // }

  // getStudentAttributeData {
  //   return this.http.post<StudentPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/positiongrid/${url}`, filterData);
  // }

  getRosterPositionsGrid(filterData, rosterQueryId?: number): Observable<StudentPositionsGridModel> {
    const url = (rosterQueryId) ? rosterQueryId : 0;
    return this.http.post<StudentPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/positiongrid/${url}`, filterData);
  }

  getRosterClassGridData(filterData, rosterQueryId?: number): Observable<RosterVoterGridModel> {
    const rq: number = (rosterQueryId) ? rosterQueryId : 0;
    return this.http.post<RosterVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/classgrid/${rq}`, filterData);
  }

  getRosterProgramGridData(filterData, rosterQueryId?: number): Observable<RosterVoterGridModel> {
    const rq: number = (rosterQueryId) ? rosterQueryId : 0;
    return this.http.post<RosterVoterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/programgrid/${rq}`, filterData);
  }
}
