import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProspectProfileService} from '../../../services/prospect-profile.service';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {MessageService} from 'primeng/api';
import {PhoneValidationUtility} from '../../../shared/utilities/phone-validation.utility';
import {AddressModel} from '../../../models/addresses.model';
import {LookupsService} from '../../../services/lookups.service';
import {AddressesService} from '../../../services/addresses.service';
import {MapsService} from '../../../services/maps.service';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {ProfileModel} from '../../../models/profile.model';
import {PiiValidateModel} from '../../../models/pii-validate.model';
import {PiiDuplicateDialogComponent} from '../../../shared/components/pii-duplicate-dialog/pii-duplicate-dialog.component';
import {PiiValidateService} from '../../../services/pii-validate.service';
import {donotRequirePrimaryEmailCustom} from '../../../shared/utilities/component-manager-utility';
import {ContextService} from '../../../services/context.service';

@Component({
  selector: 'app-prospects-contact-form',
  templateUrl: './prospects-form-contact.component.html',
  styleUrls: ['./prospects-form-contact.component.scss']
})

export class ProspectsFormContactComponent implements OnInit, OnChanges, OnDestroy {
  InterfaceObjectEnum: string = 'PROSPECTS';
  mainForm: FormGroup;
  dateOfBirth: Date;
  inputObjStatus: GenericSelectModel;
  inputObjHonorific: GenericSelectModel;
  inputObjRace: GenericSelectModel;
  inputObjEthnicity: GenericSelectModel;
  inputObjLanguage: GenericSelectModel;
  inputObjEducation: GenericSelectModel;
  inputObjMilitary: GenericSelectModel;
  inputObjProspectType: GenericSelectModel;
  inputObjProspectStatus: GenericSelectModel;
  inputObjOrganization: GenericSelectModel;
  setPrefixId: number;
  setStatusId: number;
  loading: boolean = false;
  isDirty: boolean;
  isSaving: boolean = false;
  setRaceId: number;
  setEthnicityId: number;
  setLanguageId: number;
  setEducationId: number;
  setMilitaryId: number;
  setProspectTypeId: number;
  setProspectStatusId: number;
  setOrganizationId: number;
  showSave: boolean;
  isSearching: boolean;
  isEditing: boolean;
  currentProspectsProfile = {} as ProfileModel;
  // addressData: AddressModel = {} as AddressModel;
  hideMemberData: boolean;
  selectedRaces: number[] = [];
  languages;
  selectedLanguages: number[] = [];
  requirePrimaryEmail: boolean;
  @Input() personID: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() Archived: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  public _donotRequirePrimaryEmailCustom = donotRequirePrimaryEmailCustom;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsService: LookupsService, private piiValidateService: PiiValidateService,
              private messageService: MessageService, private addressesService: AddressesService, private dialogService: DialogService,
              private prospectProfileService: ProspectProfileService, private ref: DynamicDialogRef, public contextService: ContextService,
              private lookupsDataService: LookupsDataService, private phoneValidationUtility: PhoneValidationUtility,
              private mapsService: MapsService, private tenantConfigDataService: TenantConfigDataService) {

    this.mainForm = this.formBuilder.group({
      prefixID: new FormControl(null),
      firstName: new FormControl(null, [Validators.required]),
      middleName: new FormControl(null),
      lastName: new FormControl(null, [Validators.required]),
      suffix: new FormControl(null),
      dateOfBirth: new FormControl(null),
      gender: new FormControl(null),
      primaryPhoneNumber: new FormControl(null),
      secondaryPhoneNumber: new FormControl(null),
      SMSOptIn: new FormControl(false),
      primaryEmail: new FormControl(null, [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      EmailMsgOptIn: new FormControl(false),
      secondaryEmail: new FormControl(null, [Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      AltEmailMsgOptIn: new FormControl(false),
      raceID: new FormControl(null),
      ethnicityID: new FormControl(null),
      languageID: new FormControl(null),
      educationID: new FormControl(null),
      militaryID: new FormControl(null),
      organizationID: new FormControl(null, [Validators.required]),
      typeID: new FormControl(null, [Validators.required]),
      statusID: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this._donotRequirePrimaryEmailCustom()) {
      this.requirePrimaryEmail = false;
      this.mainForm.get('primaryEmail').clearValidators();
      this.mainForm.get('primaryEmail').updateValueAndValidity();

    } else {
      this.requirePrimaryEmail = true;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isEdit) {
      this.loading = true;
      this.prospectProfileService.getProspectProfile(this.personID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.currentProspectsProfile = res;
            this.initForm();
            this.loading = false;
          }, error: (e) => {
            this.loading = false;
            console.debug(e);
          }
        });
    }

    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  initSelects(disabled) {
    this.initPrefix(disabled);
    // this.initEthnicity(disabled);
    this.initEducation(disabled);
    this.initLanguage(disabled);
    this.initRace(disabled);
    this.initMilitary(disabled);
    if (!this.isEdit) {
      this.initOrganization(disabled, this.InterfaceObjectEnum, false);
      this.initProspectStatus(disabled);
      this.initProspectType(disabled);
    }
    this.getLanguages();
  }

  initForm() {
    this.isSearching = false;
    this.mainForm.get('prefixID').setValue(this.currentProspectsProfile.PrefixID);
    this.mainForm.get('firstName').setValue(this.currentProspectsProfile.FirstName);
    this.mainForm.get('middleName').setValue(this.currentProspectsProfile.MiddleName);
    this.mainForm.get('lastName').setValue(this.currentProspectsProfile.LastName);
    this.mainForm.get('suffix').setValue(this.currentProspectsProfile.Suffix);
    if (this.currentProspectsProfile.DOB) {
      this.dateOfBirth = new Date(this.currentProspectsProfile.DOB);
      this.mainForm.get('dateOfBirth').setValue(this.dateOfBirth);
    }
    this.mainForm.get('gender').setValue(this.currentProspectsProfile.Gender);
    this.mainForm.get('primaryPhoneNumber').setValue(this.currentProspectsProfile.PrimaryPhoneNumber);
    this.mainForm.get('secondaryPhoneNumber').setValue(this.currentProspectsProfile.SecondaryPhoneNumber);
    this.mainForm.get('SMSOptIn').setValue(this.currentProspectsProfile.SMSOptIn);
    this.mainForm.get('primaryEmail').setValue(this.currentProspectsProfile.PrimaryEmailAddress);
    this.mainForm.get('EmailMsgOptIn').setValue(String(this.currentProspectsProfile.PrimaryEmailOptOut));
    this.mainForm.get('secondaryEmail').setValue(this.currentProspectsProfile.SecondaryEmailAddress);
    this.mainForm.get('AltEmailMsgOptIn').setValue(String(this.currentProspectsProfile.SecondaryEmailOptOut));
    this.setPrefixId = this.currentProspectsProfile.PrefixID;
    this.selectedRaces = this.currentProspectsProfile.RaceIDList;
    this.selectedLanguages = this.currentProspectsProfile.LanguageIDList;
    this.setEducationId = this.currentProspectsProfile.EducationID;
    this.setMilitaryId = this.currentProspectsProfile.MilitaryID;
    this.mainForm.get('typeID').setValue(String(this.currentProspectsProfile.TypeID));
    this.mainForm.get('statusID').setValue(String(this.currentProspectsProfile.StatusID));
    this.mainForm.get('organizationID').setValue(String(this.currentProspectsProfile.OrganizationID));
    this.initSelects(!this.canTabWrite);
  }

  initPrefix(disable: boolean): void {
    this.inputObjHonorific = {
      labelText: 'Prefix',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setPrefixId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getHonorificsLookupData().then((lookupData) => {
      this.inputObjHonorific.data = lookupData;
      this.inputObjHonorific = Object.assign({}, this.inputObjHonorific);
    });
  }

  initRace(disable: boolean): void {
    this.inputObjRace = {
      labelText: 'Race / Ethnicity',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.selectedRaces,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getRaceLookupData().then((lookupData) => {
      this.inputObjRace.data = lookupData;
      this.inputObjRace = Object.assign({}, this.inputObjRace);
    });
  }

  initEthnicity(disable: boolean): void {
    this.inputObjEthnicity = {
      labelText: 'Ethnicity',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEthnicityId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getEthnicityLookupData().then((lookupData) => {
      this.inputObjEthnicity.data = lookupData;
      this.inputObjEthnicity = Object.assign({}, this.inputObjEthnicity);
    });
  }

  getLanguages() {
    this.lookupsDataService.getLanguagesLookupData().then((lookupData) => {
      this.languages = lookupData;
      this.languages.shift();
    });
  }

  initLanguage(disable: boolean): void {
    this.inputObjLanguage = {
      labelText: 'Language',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.selectedLanguages,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getLanguagesLookupData().then((lookupData) => {
      this.inputObjLanguage.data = lookupData;
      this.inputObjLanguage = Object.assign({}, this.inputObjLanguage);
    });
  }

  initEducation(disable: boolean): void {
    this.inputObjEducation = {
      labelText: 'Education Level',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setEducationId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getEducationLookupData().then((lookupData) => {
      this.inputObjEducation.data = lookupData;
      this.inputObjEducation = Object.assign({}, this.inputObjEducation);
    });
  }

  initMilitary(disable: boolean): void {
    this.inputObjMilitary = {
      labelText: 'Military Branch',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setMilitaryId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getMilitaryLookupData().then((lookupData) => {
      this.inputObjMilitary.data = lookupData;
      this.inputObjMilitary = Object.assign({}, this.inputObjMilitary);
    });
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: this.setOrganizationId,
      disabled: disable,
      canWrite: this.canTabWrite,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly, 'organize').then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  initProspectType(disable: boolean): void {
    this.inputObjProspectType = {
      labelText: 'Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: this.setProspectTypeId,
      disabled: disable,
      canWrite: this.canTabWrite,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getPersonProfileTypesLookupData('organize').then((lookupData) => {
      this.inputObjProspectType.data = lookupData;
      this.inputObjProspectType = Object.assign({}, this.inputObjProspectType);
    });
  }

  initProspectStatus(disable: boolean): void {
    this.inputObjProspectStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: this.setProspectStatusId,
      disabled: disable,
      canWrite: this.canTabWrite,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getPersonProfileStatusesLookupData('organize').then((lookupData) => {
      this.inputObjProspectStatus.data = lookupData;
      this.inputObjProspectStatus = Object.assign({}, this.inputObjProspectStatus);
    });
  }

  getStatusData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setStatusId = event[0].ID;
    } else {
      this.setStatusId = 0;
    }
    this.mainForm.get('status').setValue(this.setStatusId);
    this.mainForm.markAsDirty();
  }

  getHonorificData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setPrefixId = event[0].ID;
    } else {
      this.setPrefixId = 0;
    }

    if (this.mainForm.get('prefixID').value !== this.setPrefixId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('prefixID').setValue(this.setPrefixId);
  }

  getRaceData(event: any) {
    if (event) {
      this.selectedRaces = event;
    } else {
      this.selectedRaces = [];
    }
    this.mainForm.markAsDirty();
    // this.mainForm.get('raceID').setValue(this.setRaceId);
  }

  getEthnicityData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setEthnicityId = event[0].ID;
    } else {
      this.setEthnicityId = 0;
    }
    if (this.mainForm.get('ethnicityID').value !== this.setEthnicityId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('ethnicityID').setValue(this.setEthnicityId);
  }

  getLanguageData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setLanguageId = event[0].ID;
    } else {
      this.setLanguageId = 0;
    }
    if (this.mainForm.get('languageID').value !== this.setLanguageId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('languageID').setValue(this.setLanguageId);
  }

  getEducationData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setEducationId = event[0].ID;
    } else {
      this.setEducationId = 0;
    }
    if (this.mainForm.get('educationID').value !== this.setEducationId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('educationID').setValue(this.setEducationId);
  }

  getMilitaryData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setMilitaryId = event[0].ID;
    } else {
      this.setMilitaryId = 0;
    }
    if (this.mainForm.get('militaryID').value !== this.setMilitaryId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('militaryID').setValue(this.setMilitaryId);
  }

  getOrganizationData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setOrganizationId = event[0].ID;
    } else {
      this.setOrganizationId = null;
    }
    if (this.mainForm.get('organizationID').value !== this.setOrganizationId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('organizationID').setValue(this.setOrganizationId);
  }

  getProspectStatusData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setProspectStatusId = event[0].ID;
    } else {
      this.setProspectStatusId = null;
    }
    if (this.mainForm.get('statusID').value !== this.setProspectStatusId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('statusID').setValue(this.setProspectStatusId);
  }

  getProspectTypeData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setProspectTypeId = event[0].ID;
    } else {
      this.setProspectTypeId = null;
    }
    if (this.mainForm.get('typeID').value !== this.setProspectTypeId) {
      this.mainForm.markAsDirty();
    }
    this.mainForm.get('typeID').setValue(this.setProspectTypeId);
  }

  processData() {
    this.currentProspectsProfile.PrefixID = this.mainForm.get('prefixID').value;
    this.currentProspectsProfile.FirstName = this.mainForm.get('firstName').value;
    this.currentProspectsProfile.MiddleName = this.mainForm.get('middleName').value;
    this.currentProspectsProfile.LastName = this.mainForm.get('lastName').value;
    this.currentProspectsProfile.Suffix = this.mainForm.get('suffix').value;
    this.currentProspectsProfile.DOB = this.mainForm.get('dateOfBirth').value;
    this.currentProspectsProfile.Gender = this.mainForm.get('gender').value;
    this.currentProspectsProfile.PrimaryPhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
    this.currentProspectsProfile.SecondaryPhoneNumber = this.mainForm.get('secondaryPhoneNumber').value;
    this.currentProspectsProfile.SMSOptIn = this.mainForm.get('SMSOptIn').value;
    if (this.mainForm.get('primaryEmail').value === '') {
      this.currentProspectsProfile.PrimaryEmailAddress = null;
    } else {
      this.currentProspectsProfile.PrimaryEmailAddress = this.mainForm.get('primaryEmail').value;
    }
    this.currentProspectsProfile.PrimaryEmailOptOut = (this.mainForm.get('EmailMsgOptIn').value === 'true');
    if (this.mainForm.get('secondaryEmail').value === '') {
      this.currentProspectsProfile.SecondaryEmailAddress = null;
    } else {
      this.currentProspectsProfile.SecondaryEmailAddress = this.mainForm.get('secondaryEmail').value;
    }
    this.currentProspectsProfile.SecondaryEmailOptOut = (this.mainForm.get('AltEmailMsgOptIn').value === 'true');
    this.currentProspectsProfile.RaceIDList = this.selectedRaces;
    this.currentProspectsProfile.EthnicityID = this.mainForm.get('ethnicityID').value;
    this.currentProspectsProfile.LanguageIDList = this.selectedLanguages;
    this.currentProspectsProfile.EducationID = this.mainForm.get('educationID').value;
    this.currentProspectsProfile.MilitaryID = this.mainForm.get('militaryID').value;
    this.currentProspectsProfile.TypeID = this.mainForm.get('typeID').value;
    this.currentProspectsProfile.StatusID = this.mainForm.get('statusID').value;
    this.currentProspectsProfile.OrganizationID = this.mainForm.get('organizationID').value;
    this.saveForm();
  }

  saveForm() {
    if (this.isEdit) {
      this.prospectProfileService.updateProspectProfile(this.personID, this.currentProspectsProfile)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.ref.destroy();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Your prospect profile has been successfully updated'
            });
            this.isSaving = false;
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    } else {
      this.prospectProfileService.createProspectProfile(this.currentProspectsProfile)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.ref.destroy();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Your prospect profile has been successfully added'
            });
            this.mainForm.markAsPristine();
            this.closeAddEdit.emit();
            this.isSaving = false;
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    }
  }

  clear(formControlName: string) {
    this.mainForm.get(formControlName).setValue('');
    this.mainForm.get(formControlName).enable();
  }

  cancel(): void {
    // this.addressData = {} as AddressModel;
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  toggleGender() {
    if (this.mainForm.get('gender').value === 'F') {
      this.mainForm.get('gender').setValue('F');
    } else {
      this.mainForm.get('gender').setValue('M');
    }
  }


  toggleEmailMsg() {
    if (this.mainForm.get('EmailMsgOptIn').value === 'true') {
      this.mainForm.get('EmailMsgOptIn').setValue('true');
    } else {
      this.mainForm.get('EmailMsgOptIn').setValue('false');
    }
  }

  toggleAltEmailMsgAlt() {
    if (this.mainForm.get('AltEmailMsgOptIn').value === 'true') {
      this.mainForm.get('AltEmailMsgOptIn').setValue('true');
    } else {
      this.mainForm.get('AltEmailMsgOptIn').setValue('false');
    }
  }

  mobileCheckValidation(phone) {
    this.phoneValidationUtility.phoneValidation(phone).then((res) => {
      this.currentProspectsProfile.SecondaryPhoneVerified = res;
      this.mainForm.markAsDirty();
    });
  }

  handleDuplicate(event: any) {
    if (event) {
      this.hideMemberData = event.hardStop;
      if (!event.hardStop) {
        this.showSave = true;
        this.mainForm.enable();
        this.mainForm.get('firstName').disable();
        this.mainForm.get('lastName').disable();
        this.mainForm.get('firstName').setValue(event.firstName);
        this.mainForm.get('lastName').setValue(event.lastName);
        this.mainForm.get('primaryEmail').setValue(event.email);
        this.initSelects(false);
      }
    } else {
      this.mainForm.disable();
      this.showSave = false;
      this.initSelects(true);
    }
  }

  setAddress(addressChanges: AddressModel): void {
    // if (addressChanges) {
    //   this.mainForm.markAsDirty();
    //   this.addressData.CountryID = addressChanges.CountryID;
    //   this.addressData.Address1 = addressChanges.Address1;
    //   this.addressData.Address2 = addressChanges.Address2;
    //   this.addressData.City = addressChanges.City;
    //   this.addressData.StateProvinceID = addressChanges.StateProvinceID;
    //   this.addressData.Zip = addressChanges.Zip;
    //   this.addressData.ZipPlus4 = addressChanges.ZipPlus4;
    //   this.addressData.County = addressChanges.County;
    //   this.addressData.Latitude = addressChanges.Latitude;
    //   this.addressData.Longitude = addressChanges.Longitude;
    // }
  }

  validatePii(key: string, value: string) {
    this.isSearching = true;
    this.isEditing = false;
    const piiData: PiiValidateModel = {
      SSN: null,
      SIN: null,
      PrimaryEmailAddress: null,
      PersonID: this.personID,
      MatchType: key
    };
    piiData[key] = value;
    this.piiValidateService.validatePii(piiData)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.isSearching = false;
          if (res.MatchType) {
            const ref = this.dialogService.open(PiiDuplicateDialogComponent, {
              data: {
                piiData: res,
                value
              },
              header: 'Duplicate User(s) Found',
              width: '60%',
            });
            ref.onClose.subscribe({
              next: () => {
                switch (key) {
                  case 'SSN' : {
                    this.mainForm.get('SSN').setValue(null);
                    break;
                  }
                  case 'SIN' : {
                    this.mainForm.get('SIN').setValue(null);
                    break;
                  }
                  case 'PrimaryEmailAddress' : {
                    this.mainForm.get('primaryEmail').setValue(null);
                    break;
                  }
                }
              }
            });
          }
        }
      });
  }
}
