import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';

import {transformDateTimeToDateOnly} from '../../../../utilities/form.utility';
import {CreateSharableTrainingCertificationModel, SharableCardDetailModel, UpdateSharableModel} from '../../../../../models/sharables.model';
import {SharablesService} from '../../../../../services/sharables.service';
import {ResourcesService} from '../../../../../services/resources.service';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {TenantConfigDataService} from '../../../../../services/tenant-config-data.service';

@Component({
  selector: 'app-training-certifications-form',
  templateUrl: './training-certifications-form.component.html',
  styleUrls: ['./training-certifications-form.component.scss']
})
export class TrainingCertificationsFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  sharable = {} as SharableCardDetailModel;
  loading: boolean = false;
  sharableType: string;
  completionDate: Date;
  expirationDate: Date;
  showProgress: boolean;
  inputObjCategory: GenericSelectModel;
  setCategoryId: number;
  inputObjCertification: GenericSelectModel;
  setCertificationId: number;
  createSharable = {} as CreateSharableTrainingCertificationModel;
  updateSharable = {} as UpdateSharableModel;
  showUploader: boolean;
  addAttachment: boolean = true;
  fd: FormData = new FormData();
  uploadedFiles: any[] = [];
  isSaving: boolean;
  REQUIRESHARABLECERTID: boolean;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() sharableID: number;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService,
              private sharablesService: SharablesService,
              private resourcesService: ResourcesService,
              private lookupsDataService: LookupsDataService,
              private tenantConfigDataService: TenantConfigDataService
  ) {
    this.mainForm = this.formBuilder.group({
      type: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      category: new FormControl(null),
      description: new FormControl(null),
      completionDate: new FormControl(null),
      expirationDate: new FormControl(null),
      noExpirationDate: new FormControl(null),
      certification: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.REQUIRESHARABLECERTID = this.tenantConfigDataService.getBooleanValue('REQUIRESHARABLECERTID');

    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.sharableType === 'Certification') {
      this.mainForm.get('completionDate').setValidators(Validators.required);
      this.mainForm.get('completionDate').updateValueAndValidity();
    }
  }

  ngOnChanges(): void {
    this.initForm(this.isEdit);
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initForm(isEdit: boolean): void {
    this.loading = true;
    this.showUploader = false;
    this.uploadedFiles.length = 0;
    this.fd = new FormData();
    if (isEdit) {
      this.sharablesService.getSharable(this.sharableID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.sharable = res;
            this.sharableType = this.sharable.Type;
            this.mainForm.get('type').setValue(this.sharable.Type);
            this.mainForm.get('name').setValue(this.sharable.Title);
            this.setCategoryId = this.sharable.CategoryID;
            this.mainForm.get('category').setValue(this.sharable.CategoryID);
            this.mainForm.get('description').setValue(this.sharable.Body);
            this.setCertificationId = this.sharable.CertificationID;
            this.mainForm.get('certification').setValue(this.sharable.CertificationID);
            if (this.sharable.CompletionDate) {
              this.completionDate = new Date(this.sharable.CompletionDate);
              this.mainForm.get('completionDate').setValue(this.completionDate);
            } else {
              this.mainForm.get('completionDate').setValue(null);
            }
            if (this.sharable.CertExpirationDate) {
              this.expirationDate = new Date(this.sharable.CertExpirationDate);
              this.mainForm.get('expirationDate').setValue(this.expirationDate);
              if (this.canTabWrite) {
                this.mainForm.get('expirationDate').enable();
              } else {
                this.mainForm.get('expirationDate').disable();
              }
              this.mainForm.get('noExpirationDate').setValue(false);
            } else {
              this.mainForm.get('expirationDate').setValue(null);
              this.mainForm.get('expirationDate').disable();
              this.mainForm.get('noExpirationDate').setValue(true);
            }
            this.addAttachment = this.sharable.Resources.length <= 0;
            this.initSelects();
            this.loading = false;
          }
        });
    } else {
      this.sharableType = 'TRAINING';
      this.mainForm.get('type').setValue(this.sharableType);
      this.initSelects();
      this.loading = false;
    }
  }

  toggleExpirationDate(event:any): void {
    if (event && event.checked) {
      this.mainForm.get('expirationDate').setValue(null);
      this.mainForm.get('expirationDate').disable();
      this.mainForm.get('expirationDate').setValidators(null);
      this.mainForm.get('expirationDate').updateValueAndValidity();
    } else {
      if (this.canTabWrite) {
        this.mainForm.get('expirationDate').enable();
      } else {
        this.mainForm.get('expirationDate').disable();
      }
      this.mainForm.get('expirationDate').setValidators(Validators.required);
      this.mainForm.get('expirationDate').updateValueAndValidity();
    }
  }

  initSelects() {
    this.initCategory(!this.canTabWrite, this.sharableType);
    this.initCertification(!this.canTabWrite);
  }

  initCategory(disable: boolean, sharableType: string): void {
    this.inputObjCategory = {
      labelText: 'Category',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setCategoryId,
      disabled: disable
    };
    this.lookupsDataService.getSharableCategoriesLookupData(sharableType).then((lookupData) => {
      this.inputObjCategory.data = lookupData;
      this.inputObjCategory = Object.assign({}, this.inputObjCategory);
    });
  }

  getCategoryData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setCategoryId = event[0].ID;
    } else {
      this.setCategoryId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('category').setValue(this.setCategoryId);
  }

  initCertification(disable: boolean): void {
    this.inputObjCertification = {
      labelText: 'Certification',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: this.REQUIRESHARABLECERTID,
      selectFirstValue: false,
      initSelected: this.setCertificationId,
      disabled: disable
    };
    this.lookupsDataService.getCertificationsLookupData().then((lookupData) => {
      this.inputObjCertification.data = lookupData;
      this.inputObjCertification = Object.assign({}, this.inputObjCertification);
    });
  }

  getCertificationData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setCertificationId = event[0].ID;
      if (event[0].Description) {
        this.mainForm.get('name').setValue(event[0].Description);
      }
    } else {
      this.setCertificationId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('certification').setValue(this.setCertificationId);
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }

  processData(): void {
    this.isSaving = true;
    this.saveForm();
  }

  saveForm(): void {
    const resources: any[] = [];
    let i: number;
    let ilen: number;
    this.saveNewFiles().then((fileData) => {
      if (!this.isEdit) {
        this.createSharable.Type = this.mainForm.get('type').value;
        this.createSharable.DBEntity = this.DBEntity;
        this.createSharable.DBEntityID = this.DBEntityID;
        this.createSharable.Title = this.mainForm.get('name').value;
        this.createSharable.CategoryID = this.mainForm.get('category').value;
        this.createSharable.Body = this.mainForm.get('description').value;
        this.createSharable.BodyIsHtml = false;
        this.createSharable.CompletionDate = transformDateTimeToDateOnly(this.mainForm.get('completionDate').value);
        this.createSharable.CertExpirationDate = transformDateTimeToDateOnly(this.mainForm.get('expirationDate').value);
        this.createSharable.CertificationID = this.mainForm.get('certification').value;
        // add new attachments
        if (fileData) {
          for (i = 0, ilen = fileData.length; i < ilen; i++) {
            resources.push({
              ResourceReferenceType: 'SHARABLEDOCUMENT',
              resourceID: fileData[i].ResourceId,
              Description: fileData[i].FileName
            });
          }
        }
        this.createSharable.Resources = resources;

        this.sharablesService.createTrainingCertification(this.createSharable)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The training/certification has been added.'
              });
              this.isSaving = false;
              this.closeAddEdit.emit(true);
            }, error: () => {
              this.isSaving = false;
            }
          });
      } else {
        this.updateSharable.ID = this.sharableID;
        this.updateSharable.Type = this.sharable.Type;
        this.updateSharable.DBEntity = this.DBEntity;
        this.updateSharable.DBEntityID = this.DBEntityID;
        this.updateSharable.Title = this.mainForm.get('name').value;
        this.updateSharable.CategoryID = this.mainForm.get('category').value;
        this.updateSharable.Body = this.mainForm.get('description').value;
        this.updateSharable.BodyIsHtml = false;
        this.updateSharable.CompletionDate = transformDateTimeToDateOnly(this.mainForm.get('completionDate').value);
        this.updateSharable.CertExpirationDate = transformDateTimeToDateOnly(this.mainForm.get('expirationDate').value);
        this.updateSharable.CertificationID = this.mainForm.get('certification').value;
        // add new attachments
        if (fileData) {
          for (i = 0, ilen = fileData.length; i < ilen; i++) {
            resources.push({
              ResourceID: fileData[i].ResourceId,
              ResourceReferenceType: 'SHARABLEDOCUMENT',
              ResourceReferenceID: fileData[i].ResourceReferenceID,
              MimeType: fileData[i].MimeType,
              Description: fileData[i].FileName
            });
          }
        }
        // include existing/remaining attachments
        if (this.sharable.Resources) {
          for (i = 0, ilen = this.sharable.Resources.length; i < ilen; i++) {
            resources.push(this.sharable.Resources[i]);
          }
        }
        this.updateSharable.Resources = resources;

        this.sharablesService.updateSharable(this.sharableID, this.updateSharable)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The training/certification has been updated.'
              });
              this.mainForm.markAsPristine();
              this.mainForm.markAsUntouched();
              this.isSaving = false;
              this.closeAddEdit.emit(true);
              //this.initForm(this.isEdit);
              //this.mainForm.markAsPristine();
            }, error: () => {
              this.isSaving = false;
            }
          });
      }
    });
  }

  saveNewFiles(): any {
    this.showProgress = true;
    return new Promise((resolve, reject) => {
      if (this.uploadedFiles.length > 0) {
        // set the resource directory to public only for certifications
        const dir: string = (this.mainForm.get('type').value === 'CERTIFICATION') ? 'public' : null;
        this.resourcesService.uploadResource(this.fd, dir)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (fileData) => {
              this.showProgress = false;
              resolve(fileData);
            }
          });
      } else {
        this.showProgress = false;
        resolve(null);
      }
      console.debug(reject);
    });
  }

  cancel(): void {
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm(this.isEdit);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  onUpload(event:any): any {
    if (event.files.length > 0) {
      for (const file of event.files) {
        this.uploadedFiles.push(file);
        this.fd.append('file', file);
      }
      //this.messageService.add({severity: 'info', summary: 'File(s) Uploaded', detail: ''});
      this.mainForm.markAsDirty();
    }
  }

  removeResource(resourceReferenceId: number): void {
    this.addAttachment = this.sharable.Resources.length <= 1;
    this.mainForm.markAsDirty();
    this.sharable.Resources = this.sharable.Resources.filter(function (obj) {
      return obj.ResourceReferenceID !== resourceReferenceId;
    });
  }

  openResource(resourceReferenceId: number): void {
    this.resourcesService.getResourceById(resourceReferenceId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (resource) => {
          if (resource) {
            window.open(resource.Url, '_blank');
          }
        }
      });
  }

  toggle() {
    this.sharableType = this.mainForm.get('type').value;
    this.initSelects();
  }
}
