import {AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OrganizationModel} from '../../models/organizations.model';
import {OrganizationService} from '../../services/organizations.service';
import {Location} from '@angular/common';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {InterfaceObjectAppsModel, InterfaceObjectItemPermissions} from '../../models/interface-objects.model';
import {tabsPermissionsUtility} from '../../shared/utilities/tabs-permissions.utility';
import {PageService} from '../../services/page.services';
import {PageModel} from '../../models/page.model';
import {ConfirmationService} from 'primeng/api';
import {ContextService} from '../../services/context.service';

@Component({
  selector: 'app-organization-tab-container',
  templateUrl: './organization-tab-container.component.html',
  styleUrls: ['./organization-tab-container.component.scss']
})
export class OrganizationTabContainerComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  InterfaceObjectEnum: string = 'ORGPROFILE';
  permissions: InterfaceObjectAppsModel[] = [];
  public _tabsPermissionsUtility = tabsPermissionsUtility;
  dataId: number;
  organization: OrganizationModel = {} as OrganizationModel;
  origOrganization: OrganizationModel = {} as OrganizationModel;
  setOrganizationId: number = 0;
  blobToken: string;
  reload: boolean = true;
  isSaving: boolean = false;
  isDialog: boolean = false;
  showTabs: boolean;
  invalidRecord: boolean = false;
  @Input() organizationId: number;
  @Input() ContentID: number;
  @Input() OrgRefID: number;
  @Input() isEdit: boolean;
  @Input() canPageWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  isPage: boolean = false;
  //setOrganizationId: number;
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(private organizationService: OrganizationService,
              private router: Router, private route: ActivatedRoute,
              public config: DynamicDialogConfig, private confirmationService: ConfirmationService,
              private location: Location, private pageService: PageService,
              private changeDetection: ChangeDetectorRef, private contextService: ContextService
  ) {
  }

  ngOnInit(): void {
    this.blobToken = this.contextService.contextObject.blobToken;
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        //this.setOrganizationId = res.selOrganizationId;
        if (this.pageInfo.isPage || this.pageInfo.isDialog) {
          this.isEdit = this.pageInfo.isEdit;
          this.isPage = this.pageInfo.isPage;
          this.isDialog = this.pageInfo.isDialog;
          this.ContentID = this.pageInfo.ContentID;
          this.getDataToEdit(this.ContentID);
        }
      }
    });
    if (this.canPageWrite === undefined) {
      this.pageService.getPagePermissions(this.InterfaceObjectEnum).then((permission: InterfaceObjectItemPermissions) => {
        this.canPageWrite = permission.Write;
      });
    }
    this.pageService.getTabPermissions(this.InterfaceObjectEnum).then((tabPermissions: InterfaceObjectAppsModel[]) => {
      this.permissions = tabPermissions;
      this.showTabs = true;
    });
    this.changeStatus();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterContentInit(): void {
    this.changeStatus();
  }

  changeStatus(): void {
    this.changeDetection.detectChanges();
  }

  getDataToEdit(id: number): void {
    this.reload = false;
    if (id) {
      this.organizationService
        .getOrganization(id, 'union', 0)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: OrganizationModel) => {
            this.reload = true;
            this.origOrganization = res;
            this.organization = res;
            if (!this.OrgRefID) {
              this.OrgRefID = res.OrganizationReferenceID;
            }
          }, error: () => {
            this.invalidRecord = true;
          }
        });
    } else {
      this.reload = true;
    }
  }

  getRosterData(event:any): void {
    if (event && event[0]) {
      this.setOrganizationId = event[0].ID;
    } else {
      this.setOrganizationId = null;
    }
    this.organization.ID = this.setOrganizationId;
  }

  reset(): void {
    this.route.params.subscribe({
      next: (params) => {
        this.dataId = Number(params.id);
        if (this.dataId) {
          this.getDataToEdit(this.dataId);
        }
      }
    });
  }

  ngOnChanges(): void {
    this.getDataToEdit(this.ContentID);
  }

  // detailsSaved(organization): void {
  //   this.isEdit = true;
  //   this.organization = organization;
  //   this.origOrganization = organization;
  //   this.closeAddEdit.emit();
  // }

  back(): void {
    this.confirmationService.close();
    this.location.back();
  }

  resetWarning() {
    this.confirmationService.close();
  }

  setDefaultPic() {
    this.organization.LogoUrl = 'assets/images/' + this.contextService.contextObject.tenantLogo;
  }
}
