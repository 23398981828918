@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form [formGroup]="mainForm">
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjStep" (emitData)="getStepData($event)"></app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <label>Description <sup class="req">*</sup></label>
        <input type="text" pInputText formControlName="description" tabindex="0">
        @if ((mainForm.controls.description.dirty || mainForm.controls.description.touched) &&
        mainForm.controls.description.errors?.required) {
          <span class="error">'Description' must be provided</span>
        }
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <p-checkbox binary="true" formControlName="keyOutcome" label="Key Outcome"></p-checkbox>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <p-radioButton formControlName="closeBranch" [value]="false" label="Continue to Next Step"
                       class="p-mr-3 margin-top-5" (onClick)="updateCloseBranch('false')" tabindex="0">
        </p-radioButton>
        <p-radioButton formControlName="closeBranch" [value]="true" label="Close Branch" class="p-mr-3 margin-top-5"
                       (onClick)="updateCloseBranch('true')" tabindex="0">
        </p-radioButton>
      </div>
      @if (showNextSteps) {
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <h3>Next Step(s)</h3>
          <ng-container formArrayName="nextSteps">
            @for (option of nextSteps.controls; track option; let i = $index) {
              <div formGroupName="{{i}}">
                <div class="p-grid p-fluid">
                  <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                    <app-select class="full-width" [inputObj]="inputObjDestinationWorkflowStep[i]"
                                (emitData)="getDestinationWorkflowStepData(i, $event)"></app-select>
                  </div>
                  <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                    <app-select class="full-width" [inputObj]="inputObjDeadlineType[i]"
                                (emitData)="getDeadlineTypeData(i, $event)"></app-select>
                  </div>
                  <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                    <app-select class="full-width" [inputObj]="inputObjIntervalType[i]"
                                (emitData)="getIntervalTypeData(i, $event)"></app-select>
                  </div>
                  <div class="col-md-2 col-sm-12 col-xs-12 input-container">
                    <label>Interval</label>
                    <p-inputNumber formControlName="outcomeDueInterval" [showButtons]="true" [disabled]="!canTabWrite"
                                   (onInput)="setDirty()" (onBlur)="setDirty()" tabindex="0"></p-inputNumber>
                  </div>
                  <div class="col-md-1 col-sm-12 col-xs-12 input-container">
                    @if (canTabWrite && outcomeCanDelete[i]) {
                      <div class="icon p-mt-4" pTooltip="Delete" tooltipPosition="bottom" [ngClass]="'option-delete'"
                           (click)="removeNextStep(i)"><i class="fas fa-trash-alt"></i></div>
                    }
                  </div>
                </div>
              </div>
            }
          </ng-container>
          @if (canTabWrite) {
            <button pButton label="Add Next Step" (click)="addNextStep()" class="p-button-info p-mr-2"
                    [className]="'inline-button'"></button>
          }
        </div>
      }
    </div>
    @if (canTabWrite) {
      <div class="p-grid p-fluid margin-top-10">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="p-grid p-fluid">
            <div class="col-md-6 col-sm-6 col-xs-12"></div>
            <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
              @if (isEdit) {
                <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                        icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                        (click)="cancel()" [disabled]="!mainForm.dirty"></button>
              } @else {
                <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'"
                        icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                        (click)="cancel()"></button>
              }
              @if (!isSaving) {
                <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                        tooltipPosition="bottom"
                        (click)="processData()" [className]="'inline-button'"
                        [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
              } @else {
                <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'"
                        [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </form>
}
