import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {EventsAttendedGridModel, EventsAttendedModel} from '../../../models/events.model';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {EventsService} from '../../../services/events.service';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';

@Component({
  selector: 'app-roster-tab-events',
  templateUrl: './roster-tab-events.component.html',
  styleUrls: ['./roster-tab-events.component.scss']
})
export class RosterTabEventsComponent implements OnInit, OnChanges, OnDestroy {
  myEventTotal: number = 0;
  events: EventsAttendedGridModel;
  eventsList: EventsAttendedModel[] = [];
  dataLoaded: boolean;
  totalLoaded: boolean;
  isExporting: boolean = true;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  eventsTotal: number;
  @Input() PersonID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private eventsService: EventsService, public dialogService: DialogService,
              private dialogUtility: DialogUtility, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.getData();
  }

  getData(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.eventsList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.eventsService.getPersonEventsPrimeNG('DATA', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.events = res;
          this.eventsList = res.Data;
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.eventsTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.eventsService.getPersonEventsPrimeNG('TOTAL', this.PersonID, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.eventsTotal = res.Total;
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.loadList();
  }

  filterItems(): void {
    this.initFilters('EventName', this.activeGlobalFilter, 'contains', 'and');
    this.loadList();
  }

  clearFilters(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.loadList();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  loadList(): void {
    this.getData();
  }

  refresh(): void {
    this.getData();
  }

  resetComponent(): void {
    this.eventsList = undefined;
    this.events = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.eventsList.length <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.eventsList));
        const dataToExport = removeKeysFromArray(arr, ['EventInstanceAttendeeID', 'PersonID', 'EventInstanceAttendeeStatusID', 'EventInstanceAttendeeStatusActive', 'CanEdit', 'CanDelete', 'OrganizationID']);
        exportData('events-attended', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.eventsService.getPersonEventsPrimeNG('DATA', this.PersonID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['EventInstanceAttendeeID', 'PersonID', 'EventInstanceAttendeeStatusID', 'EventInstanceAttendeeStatusActive', 'CanEdit', 'CanDelete', 'OrganizationID']);
              exportData('events-attended', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
