@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form [formGroup]="mainForm" class="margin-top-10" >
    <div class="p-grid p-fluid">
      <div class="col-md-4 col-sm-12 col-xs-12 input-container" >
        <app-select class="full-width" [inputObj]="inputObjType" (emitData)="getType($event)" ></app-select>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="getOrganizationData($event)" ></app-select>
      </div>
      @if (setType === 'Module') {
        <div class="col-md-4 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjModuleTemplate" (emitData)="getModuleTemplates($event)" ></app-select>
        </div>
      }
      <!--    <div class="col-md-4 col-sm-12 col-xs-12 input-container" *ngIf="setType === 'Module'">-->
      <!--      <app-select class="full-width" [inputObj]="inputObjProgram" (emitData)="getProgramData($event)" ></app-select>-->
      <!--    </div>-->
      @if (setType === 'Module') {
        <div class="col-md-4 col-sm-12 col-xs-12 input-container">
          <label>Title<sup class="req">*</sup></label>
          <input type="text"  pInputText  formControlName="moduleTitle" tabindex="0">
        </div>
      }
      @if (setType === 'Class') {
        <div class="col-md-4 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjCourse" (emitData)="getCourseData($event)" ></app-select>
        </div>
      }
      @if (setType === 'Module') {
        <div class="col-md-4 col-sm-12 col-xs-12 input-container">
          <label>Program</label>
          <input type="text" pInputText  formControlName="program" tabindex="0">
        </div>
      }
      <div class="col-md-3 col-sm-12 col-xs-12 input-container" >
        <label>Class #</label>
        <input type="text" pInputText  formControlName="classidentifier" tabindex="0">
      </div>
      @if (setType === 'Class') {
        <div class="col-md-3 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjStatus" (emitData)="getStatusData($event)" ></app-select>
        </div>
      }
      <div class="col-md-3 col-sm-12 col-xs-12 input-container" >
        <label>Location (Bldg, Flr, Rm, etc)</label>
        <input type="text" pInputText  formControlName="location" tabindex="0">
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 input-container">
        <label>Max # of Seats <sup class="req">*</sup></label>
        <p-inputNumber formControlName="seats" [showButtons]="true" inputId="stacked" min="0" tabindex="0"></p-inputNumber>
      </div>
      @if (setType === 'Class') {
        <div class="col-md-4 col-sm-12 col-xs-12 input-container">
          <label>Scheduling Type <sup class="req">*</sup></label>
          <p-radioButton formControlName="isComplex" value="true" label="Complex Schedule" class="p-mr-3 margin-top-5" (onClick)="updateRequired('true')" tabindex="0">
          </p-radioButton>
          <p-radioButton formControlName="isComplex" value="false" label="Simple Schedule" class="p-mr-3 margin-top-5" (onClick)="updateRequired('false')" tabindex="0">
          </p-radioButton>
        </div>
      }
      @if (setType === 'Class' && mainForm.get('isComplex').value === 'false') {
        <div class="col-md-2 col-sm-12 col-xs-12 input-container">
          <label>Start Date <sup class="req">*</sup></label>
          <!--  Do not use universal date picker here. causes issues with min date on end date -->
          <!--      <app-universal-date-picker [selectedValue]="mainForm.get('startDate').value" (emitData)="mainForm.get('startDate').setValue($event)"></app-universal-date-picker>-->
          <p-calendar  dateMask dateFormat="mm/dd/yy" formControlName="startDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [maxDate]="mainForm.controls.endDate.value"  (onInput)="loadTimezoneSelect()" (onSelect)="loadTimezoneSelect()" tabindex="0"></p-calendar>
          @if ((mainForm.controls.startDate.dirty || mainForm.controls.startDate.touched) &&
          mainForm.controls.startDate.errors?.required) {
            <span class="error">'Start Date' must be provided</span>
          }
        </div>
      }
      @if (setType === 'Class' && mainForm.get('isComplex').value === 'false') {
        <div class="col-md-2 col-sm-12 col-xs-12 input-container">
          <label>Start Time</label>
          <p-calendar  formControlName="startTime" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12" icon="pi pi-clock" [showIcon]="true" tabindex="0"></p-calendar>
        </div>
      }
      @if (setType === 'Class' && mainForm.get('isComplex').value === 'false') {
        <div class="col-md-2 col-sm-12 col-xs-12 input-container" >
          <label>End Date <sup class="req">*</sup></label>
          <!--  Do not use universal date picker here. causes issues with min date on end date -->
          <!--      <app-universal-date-picker [selectedValue]="mainForm.get('endDate').value" (emitData)="mainForm.get('endDate').setValue($event)" [minDate]="mainForm.controls.startDate.value" ></app-universal-date-picker>-->
          <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="endDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [minDate]="mainForm.controls.startDate.value" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
          @if ((mainForm.controls.endDate.dirty || mainForm.controls.endDate.touched) &&
          mainForm.controls.endDate.errors?.required) {
            <span class="error">'End Date' must be provided and after the start date.</span>
          }
        </div>
      }
      @if (setType === 'Class' && mainForm.get('isComplex').value === 'false') {
        <div class="col-md-2 col-sm-12 col-xs-12 input-container">
          <label>End Time</label>
          <p-calendar   formControlName="endTime" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12" icon="pi pi-clock" [showIcon]="true" tabindex="0"></p-calendar>
        </div>
      }
      <div class="col-md-4 col-sm-12 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjTimezone" (emitData)="getTimezoneData($event)" ></app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <label>Notes @if (setType === 'Class') {
          <span>(Viewable on the registration page)</span>
        }</label>
        <textarea pInputTextarea formControlName="signupNotes" rows="5" autoResize="autoResize" tabindex="0"></textarea>
        @if (mainForm.controls.signupNotes.errors?.maxlength) {
          <div>Only 4,000 characters are allowed</div>
        }
      </div>
      @if (canAddComplexSchedule()) {
        <div class="col-md-12 col-sm-12 col-xs-12  text-align-right tab-form-buttons">
          <button pButton label="Add Day" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-plus" pTooltip="Add Day" tooltipPosition="bottom"
                  (click)="addDay()"></button>
        </div>
      }
      <!-- new module -->
      @if (!isEdit && setType === 'Module') {
        @if (setModuleID && moduleTemplate && moduleTemplate.Days) {
          <div class="col-md-12 col-sm-12 col-xs-12 ">
            @for (t of days.controls; track t; let i = $index) {
              <p-card formArrayName="days" [styleClass]="'list-card margin-top-10'">
                <div class="p-fluid p-grid" [formGroupName]="i">
                  <div class="col-md-1 col-sm-12 col-xs-12"><div class="day-label">Day {{i + 1}}</div></div>
                  <div class="col-md-2 col-sm-12 col-xs-12 input-container" >
                    <label>Date</label>
                    <p-calendar dateMask dateFormat="mm/dd/yy" formControlName="startDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true"  [yearNavigator]="true" yearRange="2021:2030" [minDate]="this.disabledStartDate" (onSelect)="disabledDates();" tabindex="0"></p-calendar>
                  </div>
                  <div class="col-md-2 col-sm-12 col-xs-12 input-container">
                    <label>Start Time</label>
                    <p-calendar  formControlName="startTime" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12" icon="pi pi-clock" [showIcon]="true" tabindex="0"></p-calendar>
                  </div>
                  <div class="col-md-2 col-sm-12 col-xs-12 input-container">
                    <label>End Time</label>
                    <p-calendar formControlName="endTime" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12" icon="pi pi-clock" [showIcon]="true" tabindex="0"></p-calendar>
                  </div>
                  <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                    <label>Instructors</label>
                    <p-multiSelect [options]="this.inputObjInstructor.data" formControlName="instructors" optionLabel="Description" optionValue="ID" (onChange)="getInstructors($event, i)" appendTo="body"></p-multiSelect>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    @for (course of moduleTemplate.Days[i].Courses; track course) {
                      <p-card [styleClass]="'margin-top-10'">
                        <div class="p-fluid p-grid" >
                          <div class="col-md-3 col-sm-12 col-xs-12">
                            <label>Course/Topic</label>
                            <input type="text" pInputText  [(ngModel)]="course.Course.Name" [ngModelOptions]="{standalone: true}" disabled tabindex="0">
                          </div>
                          <div class="col-md-2 col-sm-12 col-xs-12">
                            <label>RI Hours</label>
                            <input type="text" pInputText  [(ngModel)]="course.Hours" [ngModelOptions]="{standalone: true}" disabled tabindex="0">
                          </div>
                          <div class="col-md-2 col-sm-12 col-xs-12">
                            <label>Total Hours</label>
                            <input type="text" pInputText  [(ngModel)]="course.Course.Hours_N" [ngModelOptions]="{standalone: true}" disabled tabindex="0">
                          </div>
                          <div class="col-md-2 col-sm-12 col-xs-12">
                            <label>Cognitive Level</label>
                            <input type="text" pInputText  [(ngModel)]="course.Course.CognitiveLevelDescription" [ngModelOptions]="{standalone: true}" disabled tabindex="0">
                          </div>
                        </div>
                      </p-card>
                    }
                  </div>
                </div>
              </p-card>
            }
          </div>
        }
      }
      <!-- existing module -->
      @if (isEdit && currentClass?.IsModule) {
        @if (this.currentClass) {
          <div class="col-md-12 col-sm-12 col-xs-12 ">
            @for (t of days.controls; track t; let i = $index) {
              <p-card formArrayName="days" [styleClass]="'list-card margin-top-10'">
                <div class="p-fluid p-grid" [formGroupName]="i">
                  <div class="col-md-1 col-sm-12 col-xs-12"><div class="day-label">Day {{i + 1}}</div></div>
                  <div class="col-md-2 col-sm-12 col-xs-12 input-container" >
                    <label>Date</label>
                    <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="startDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="2021:2030" [minDate]="this.disabledStartDate" (onSelect)="disabledDates();" tabindex="0"></p-calendar>
                  </div>
                  <div class="col-md-2 col-sm-12 col-xs-12 input-container">
                    <label>Start Time</label>
                    <p-calendar  formControlName="startTime" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12" icon="pi pi-clock" [showIcon]="true" tabindex="0"></p-calendar>
                  </div>
                  <div class="col-md-2 col-sm-12 col-xs-12 input-container">
                    <label>End Time</label>
                    <p-calendar  formControlName="endTime" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12" icon="pi pi-clock" [showIcon]="true" tabindex="0"></p-calendar>
                  </div>
                  <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                    <!--              <app-multi-select class="full-width" [inputObj]="inputObjInstructor" (emitData)="getInstructors($event, i)"></app-multi-select>-->
                    <label>Instructors</label>
                    <p-multiSelect [options]="this.inputObjInstructor.data" formControlName="instructors" optionLabel="Description" optionValue="ID" (onChange)="getInstructors($event, i)"></p-multiSelect>
                  </div>
                  @if (currentClass?.Schedule[i] && currentClass?.Schedule[i].Courses) {
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      @for (course of currentClass.Schedule[i].Courses; track course) {
                        <p-card [styleClass]="'margin-top-10'">
                          <div class="p-fluid p-grid" >
                            <div class="col-md-3 col-sm-12 col-xs-12">
                              <label>Course/Topic</label>
                              <input type="text" pInputText  [(ngModel)]="course.Name" [ngModelOptions]="{standalone: true}" disabled tabindex="0">
                            </div>
                            <div class="col-md-2 col-sm-12 col-xs-12">
                              <label>RI Hours</label>
                              <input type="text" pInputText  [(ngModel)]="course.DayHours" [ngModelOptions]="{standalone: true}" disabled tabindex="0">
                            </div>
                            <div class="col-md-2 col-sm-12 col-xs-12">
                              <label>Total Hours</label>
                              <input type="text" pInputText  [(ngModel)]="course.TotalHours" [ngModelOptions]="{standalone: true}" disabled tabindex="0">
                            </div>
                            <div class="col-md-2 col-sm-12 col-xs-12">
                              <label>Cognitive Level</label>
                              <input type="text" pInputText  [(ngModel)]="course.CognitiveLevelDescription" [ngModelOptions]="{standalone: true}" disabled tabindex="0">
                            </div>
                          </div>
                        </p-card>
                      }
                    </div>
                  }
                </div>
              </p-card>
            }
          </div>
        }
      }
      <!-- new complex class -->
      @if (this.mainForm.get('isComplex').value === 'true') {
        <div class="col-md-12 col-sm-12 col-xs-12">
          @for (t of days.controls; track t; let i = $index) {
            <p-card formArrayName="days" [styleClass]="'list-card margin-top-10'">
              <div class="p-fluid p-grid" [formGroupName]="i">
                <div class="col-md-1 col-sm-12 col-xs-12"><div class="day-label">Day {{i + 1}}</div></div>
                <div class="col-md-2 col-sm-12 col-xs-12 input-container" >
                  <label>Date</label>
                  <p-calendar  dateMask dateFormat="mm/dd/yy" formControlName="startDate" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true"  [yearNavigator]="true" yearRange="2021:2025" [minDate]="this.disabledStartDate" (onSelect)="disabledDates();" tabindex="0"></p-calendar>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12 input-container">
                  <label>Start Time</label>
                  <p-calendar formControlName="startTime" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12" icon="pi pi-clock" [showIcon]="true" tabindex="0"></p-calendar>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12 input-container">
                  <label>End Time</label>
                  <p-calendar formControlName="endTime" appendTo="body" [timeOnly]="true" showTime="true" hourFormat="12" icon="pi pi-clock" [showIcon]="true" tabindex="0"></p-calendar>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 input-container">
                  <label>Instructors</label>
                  <p-multiSelect [options]="this.inputObjInstructor.data" formControlName="instructors" optionLabel="Description" optionValue="ID" (onChange)="getInstructors($event, i)" appendTo="body"></p-multiSelect>
                </div>
                <div class="col-md-1 col-sm-12 col-xs-12 margin-top-25">
                  <button pButton icon="pi pi-trash" (click)="deleteDay(i)" class="p-button-secondary " [disabled]="!canTabWrite" pTooltip="Delete" tooltipPosition="bottom"></button>
                </div>
              </div>
            </p-card>
          }
        </div>
      }
      @if (canTabWrite) {
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="p-grid p-fluid">
            <div class="col-md-6 col-sm-6 col-xs-12"></div>
            <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
              @if (isEdit) {
                <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                        (click)="cancel()" [disabled]="!mainForm.dirty"></button>
              } @else {
                <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                        (click)="cancel()"></button>
              }
              @if (!isSaving) {
                <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                        (click)="processData()" [className]="'inline-button'"
                        [disabled]="this.mainForm.invalid"></button>
              } @else {
                <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          </div>
        </div>
      }
    </div>
  </form>
}
