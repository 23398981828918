<form [formGroup]="mainForm" class="margin-top-10 padding-bottom-50">
  <div class="p-grid p-fluid">
    <div class="col-md-3 col-sm-3 col-xs-12 input-container" >
      <app-select class="full-width" [inputObj]="inputObjType" (emitData)="getTypeData($event)" ></app-select>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-12 input-container" >
      <app-select class="full-width" [inputObj]="inputObjStatus" (emitData)="getStatusData($event)" ></app-select>
    </div>
    <!--    <div class="col-md-3 col-sm-3 col-xs-12 input-container" >-->
    <!--      <app-select class="full-width" [inputObj]="inputObjClass" (emitData)="getClassData($event)" ></app-select>-->
  <!--    </div>-->
  <div class="col-md-3 col-sm-3 col-xs-12 input-container" >
    <label>Student #</label>
    <input type="text" pInputText  formControlName="studentNumber" tabindex="0">
  </div>
  <!--    <div class="col-md-3 col-sm-6 col-xs-12 input-container" >-->
  <!--      <app-select  class="full-width" [inputObj]="inputObjOrganization" (emitData)="getOrganizationData($event)" ></app-select>-->
<!--    </div>-->
<!--    <div class="col-md-3 col-sm-6 col-xs-12 input-container" >-->
<!--      <app-select *ngIf="setOrganizationId !== 0 && setOrganizationId !== null" class="full-width" [inputObj]="inputObjPathway" (emitData)="getPathwayData($event)" ></app-select>-->
<!--    </div>-->
<!--    <div class="col-md-3 col-sm-3 col-xs-12 input-container" >-->
<!--      <label>Member #</label>-->
<!--      <input type="text" pInputText  formControlName="memberNumber">-->
<!--    </div>-->
<!--    <ng-container *ngFor="let ca of studentInfo?.CustomAttributes; let i = index;">-->
<!--      <app-dynamic-custom-fields [PersonID]="PersonID" [OrgRefID]="OrgRefID" [attributeId]="ca.CustomFieldID" [attributeValue]="ca.Value" [isEdit]="true" [category]="category"-->
<!--                            [canTabWrite]="canTabWrite" (closeAddEdit)="updateList()"></app-dynamic-custom-fields>-->
<!--    </ng-container>-->
<div class="col-md-3 col-sm-12 col-xs-12 input-container">
  <app-select class="full-width" [inputObj]="inputObjEducation" (emitData)="getEducationData($event)"></app-select>
</div>
@for (ca of studentInfo?.CustomAttributes; track ca; let i = $index) {
  @if (ca.Tab === 'tabStudent') {
    <div class="col-md-3 col-sm-3 col-xs-12">
      <app-dynamic-custom-fields [PersonID]="PersonID" [customField]="ca" [isEdit]="true" [canTabWrite]="canTabWrite" (closeAddEdit)="updateCustomAttribute(i, $event)"></app-dynamic-custom-fields>
    </div>
  }
}
@for (mp of missingPaperwork; track mp; let i = $index) {
  @if (mp.Tab === 'tabStudent' && mp.FieldName === 'Is Any Paperwork Missing?') {
    <div class="col-md-12 col-sm-12 col-xs-12 p-mb-2">
      <app-dynamic-custom-fields [PersonID]="PersonID" [customField]="mp" [isEdit]="true" [canTabWrite]="canTabWrite" (closeAddEdit)="updateMissingPaperwork(i, $event)"></app-dynamic-custom-fields>
    </div>
  }
  @if (mp.Tab === 'tabStudent' && mp.FieldName !== 'Is Any Paperwork Missing?' && isPaperworkMissing) {
    <div class="col-md-3 col-sm-3 col-xs-12">
      <app-dynamic-custom-fields [PersonID]="PersonID" [customField]="mp" [isEdit]="true" [canTabWrite]="canTabWrite" (closeAddEdit)="updateMissingPaperwork(i, $event)"></app-dynamic-custom-fields>
    </div>
  }
}
@if (canTabWrite) {
  <div class="col-md-12 text-align-right">
    <div class="action-buttons float-right margin-top-10">
      @if (isEdit) {
        <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
        (click)="cancel()" [disabled]="!mainForm.dirty"></button>
      } @else {
        <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
        (click)="cancel()"></button>
      }
      @if (!isSaving) {
        <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
          (click)="processData()" [className]="'inline-button'"
        [disabled]="mainForm.invalid || !mainForm.dirty"></button>
      } @else {
        <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
          <i class="pi pi-spin pi-spinner save-spinner"></i>
        </button>
      }
    </div>
  </div>
}
</div>
</form>
