import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {OrganizationService} from '../../../services/organizations.service';
import {OrganizationVisibilityGridModel, OrganizationVisibilityModel} from '../../../models/organizations.model';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';

@Component({
  selector: 'app-organizations-tab-visibility',
  templateUrl: './organizations-tab-visibility.component.html',
  styleUrls: ['./organizations-tab-visibility.component.scss']
})
export class OrganizationsTabVisibilityComponent implements OnInit, OnChanges, OnDestroy {
  visibilityData: OrganizationVisibilityGridModel;
  visibilityDataList: OrganizationVisibilityModel[] = [];
  dataLoaded: boolean;
  isExporting: boolean;
  totalLoaded: boolean;
  isButtonDisabled: boolean = true;
  exportItems: any[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  selectedItem: number;
  showAddEdit: boolean;
  getListRequest;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() ID: number;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;

  private ngUnsubscribe = new Subject();

  constructor(private messageService: MessageService, public dialogService: DialogService,
              private organizationService: OrganizationService, private dialogUtility: DialogUtility,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.loadData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  loadData(): void {
    this.totalLoaded = false;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.visibilityDataList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.organizationService.getOrganizationVisibility(this.ID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.totalLoaded = true;
          this.visibilityData = res;
          this.visibilityDataList = res.Data;
          if (this.canTabWrite && this.visibilityDataList.length === 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.dataLoaded = true;
        }, error: (e) => {
          this.totalLoaded = true;
          this.dataLoaded = true;
          console.debug(e);
        }
      });
  }

  ngOnChanges(): void {
    this.loadData();
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.loadData();
  }

  refresh(): void {
    this.loadData();
  }

  goToEdit(id: number): void {
    console.log('TODO', id);
  }

  enableDisableVisibility(visibility: OrganizationVisibilityModel, checked: boolean): void {
    if (visibility) {
      this.dataLoaded = false;
      visibility.AllowContact = checked;
      if (checked) {
        this.organizationService.updategetOrganizationVisibility(this.ID, visibility.OrganizationID, visibility.AllowContact)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'This visibility is now enabled'
              });
              this.dataLoaded = true;
            }
          });
      } else {
        this.organizationService.updategetOrganizationVisibility(this.ID, visibility.OrganizationID, visibility.AllowContact)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'This visibility is now disabled'
              });
              this.dataLoaded = true;
            }
          });
      }
    }
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.visibilityDataList.length <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.visibilityDataList));
        const dataToExport = removeKeysFromArray(arr, ['OrganizationID', 'OrganizationTypeRank', 'Blockable']);
        exportData('visibilityData', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.organizationService.getOrganizationVisibility(this.ID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['OrganizationID', 'OrganizationTypeRank', 'Blockable']);
              exportData('visibilityData', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
