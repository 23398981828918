<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="p-fluid p-grid contractor-container">
      <div class="col-md-4 col-sm-3 col-xs-12 input-container margin-top-10" >
        <app-select class="full-width" [inputObj]="inputObjEmployer" (emitData)="setEmployerData($event)"></app-select>
      </div>
      <div class="col-md-2 col-sm-3 col-xs-12 margin-top-30">
        <button pButton (click)="createContractor()" icon="pi pi-plus" pTooltip="Create" tooltipPosition="bottom"
                class="inline-button" label="Create New Employer"></button>
      </div>
      <div class="col-md-6 col-sm-3 col-xs-12 input-container margin-top-10">
        <label class="custom-select-label">Types</label>
        <p-multiSelect [options]="organizationTypes" [(ngModel)]="setOrganizationTypes" optionLabel="Description" optionValue="ID" appendTo="body" [ngModelOptions]="{standalone: true}"></p-multiSelect>
      </div>
      <div class="col-md-6 col-sm-3 col-xs-12 input-container margin-top-10">
        <label class="custom-select-label">Trades</label>
        <p-multiSelect [options]="trades" [(ngModel)]="setTrades" optionLabel="Description" optionValue="ID" appendTo="body" [ngModelOptions]="{standalone: true}"></p-multiSelect>
      </div>
<!--      <div class="col-md-6 col-sm-3 col-xs-12 input-container margin-top-10">-->
<!--        <label>Is Union?</label>-->
<!--        <app-yes-no-select [isBoolean]="true" (emitData)="tempIsUnion = $event" [selectedValue]="tempIsUnion"></app-yes-no-select>-->
<!--      </div>-->
      <div class="col-md-12 col-sm-12 col-xs-12 mobile-center margin-top-5 text-align-right">
        <button pButton (click)="saveNewContractor()" icon="pi pi-save" pTooltip="Add" tooltipPosition="bottom"
                class="margin-top-20 margin-right-10 inline-button" label="Add"></button>
      </div>
    </div>
  </div>
</div>
