
<div class="page-container">
  <div class="public-card">
    <p-card class="card">
      @if (logo) {
        <div class="align-center">
          <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true" />
        </div>
      }
      <div class="page-container">
        <form [formGroup]="uForm" class="unsubscribe-form">
          <h2>Unsubscribe</h2>
          <div class="p-fieldset-content">
            <div class="pad">Are you sure you want to unsubscribe from receiving communications in the future?</div>
            <p-checkbox class="checkbox" [binary]="true" formControlName="confirm"></p-checkbox>
            <label>Yes, I no longer want to receive communications.</label>
          </div>
          <div class="p-fieldset-content">
            <label>Provide Your E-mail<span class="ut-requireddenotation"> *</span></label>
            <input type="email" id="email" pInputText formControlName="email">
            @if (uForm.controls.email.invalid || uForm.controls.email.touched) {
              <div class="p-error">
                @if (uForm.controls.email.errors?.required) {
                  <div>E-mail is required</div>
                }
                @if (uForm.controls.email?.errors) {
                  <div>Must be a valid e-mail address</div>
                }
              </div>
            }
          </div>
          <div class="align-center">
            @if (!isLoading) {
              <button pButton label="Confirm" class="p-button-info p-mr-2" pTooltip="Confirm" tooltipPosition="bottom"
              (click)="unsubscribe()" [className]="'inline-button'" [disabled]="uForm.invalid || !uForm.touched || !uForm.controls.confirm.value"></button>
            } @else {
              <button pButton label="Confirm" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </form>
      </div>
    </p-card>
  </div>
  <p-toast [baseZIndex]="16000"></p-toast>
</div>
