import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {OrganizationModel, UpdateOrganizationModel} from '../../../models/organizations.model';
import {OrganizationService} from '../../../services/organizations.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AddressModel} from '../../../models/addresses.model';
import {transformDateTimeToDateOnly} from '../../../shared/utilities/form.utility';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {ContextService} from '../../../services/context.service';

@Component({
  selector: 'app-organizations-tab-details',
  templateUrl: './organizations-tab-details.component.html',
  styleUrls: ['./organizations-tab-details.component.scss']
})

export class OrganizationsTabDetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  //InterfaceObjectEnum: string = 'ORGPROFILE';
  mainForm: FormGroup;
  chartered: Date;
  organizations: OrganizationModel;
  inputObjStatus: GenericSelectModel;
  inputObjProvidence: GenericSelectModel;
  setCharterStateId: number;
  setStatusId: number;
  inputObjUTLaunchStatus: GenericSelectModel;
  setUTLaunchStatusId: number;
  dataLoaded: boolean;
  isDirty: boolean;
  isSaving: boolean = false;
  lockOrgName: boolean;
  currentOrganizations: OrganizationModel;
  updatedOrganizations = {} as UpdateOrganizationModel;
  setOrganizationId: number;
  inputObjOrganization: GenericSelectModel;
  inputObjType: GenericSelectModel;
  originalStatus: number;
  statusChanged: boolean;
  IsReferenced: boolean = true;
  isAdmin: boolean = false;
  existingMessage: boolean = false;
  addressData: AddressModel = {} as AddressModel;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() ID: number;
  @Output() closeAddEdit = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef,
              private organizationService: OrganizationService, private ref: DynamicDialogRef,
              private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private tenantConfigDataService: TenantConfigDataService,
              private contextService: ContextService, private confirmationService: ConfirmationService) {
    this.mainForm = this.formBuilder.group({
      utLaunchStatus: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      number: new FormControl(null),
      abbreviation: new FormControl(null),
      FEIN: new FormControl(null),
      LM: new FormControl(null),
      chartered: new FormControl(null),
      fiscalYearEndMonth: new FormControl(null),
      fiscalYearEndDay: new FormControl(null),
      bondAmount: new FormControl(null),
      bankRoutingNumber: new FormControl(null),
      bankAccountNumber: new FormControl(null),
      PACRoutingNumber: new FormControl(null),
      PACAccountNumber: new FormControl(null),
      IsEmployer: new FormControl({value: null, disabled: true}),
      primaryPhoneNumber: new FormControl(null),
      primaryPhoneExtension: new FormControl(null),
      primaryEmail: new FormControl(null, [Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]),
      charterLocation: new FormControl(null),
      charterStateProvinceID: new FormControl(null),
      byLawApprovalDate: new FormControl(null),
      nextElectionDate: new FormControl(null),
      meetingPlace: new FormControl(null),
      statusReason: new FormControl(null),
      statusEffectiveDate: new FormControl([Validators.required])
    });
  }

  ngOnInit(): void {
    this.isAdmin = this.contextService.contextObject.Administrator;
    // this.lockOrgName = this.tenantConfigDataService.getBooleanValue('LOCKORGNAME');
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ID) {
      if (this.ID) {
        this.dataLoaded = false;
        this.organizationService.getOrganization(this.ID, 'union', 1)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.currentOrganizations = res;
              this.organizations = res;
              this.initForm();
              this.dataLoaded = true;
            }, error: (e) => {
              this.dataLoaded = true;
              console.debug(e);
            }
          });
      }
    } else {
      //this.initSelects();
    }
    if (changes.canTabWrite) {
      this.lockOrgName = this.tenantConfigDataService.getBooleanValue('LOCKORGNAME');
      if (this.canTabWrite) {
        for (const field in this.mainForm.controls) {
          if (this.mainForm.get(field).disabled) {
            this.mainForm.get(field).disable();
          } else {
            switch (field) {
              case 'name':
              case 'number':
              case 'abbreviation':
                if (this.isEdit === true) {
                  if (this.lockOrgName === true) {
                    this.mainForm.get(field).disable();
                  } else {
                    this.mainForm.get(field).enable();
                  }
                } else {
                  this.mainForm.get(field).enable();
                }
                break;
              default:
                this.mainForm.get(field).enable();
                break;
            }
          }
        }
      } else {
        this.mainForm.disable();
      }
    }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: `'${this.currentOrganizations.Name}' data has been modified. Please save, or all changes will be lost.`,
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initSelects() {
    this.initUTLaunchStatus(false);
    this.initStatus(false);
    if (this.currentOrganizations.CountryID) {
      this.initCharterStateProvince(false);
    } else {
      this.initCharterStateProvince(true);
    }
  }

  initForm() {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.setUTLaunchStatusId = this.currentOrganizations.UTStatusID;
    this.mainForm.get('utLaunchStatus').setValue(this.currentOrganizations.UTStatusID);
    this.IsReferenced = this.currentOrganizations.IsReferenced;
    this.mainForm.get('name').setValue(this.currentOrganizations.Name);
    this.mainForm.get('number').setValue(this.currentOrganizations.Number);
    this.mainForm.get('abbreviation').setValue(this.currentOrganizations.Abbreviation);
    this.mainForm.get('FEIN').setValue(this.currentOrganizations.FEIN);
    this.mainForm.get('LM').setValue(this.currentOrganizations.LM);

    if (this.currentOrganizations.CharterDate) {
      this.chartered = new Date(this.currentOrganizations.CharterDate);
      this.mainForm.get('chartered').setValue(this.chartered);
    }
    this.mainForm.get('fiscalYearEndMonth').setValue(this.currentOrganizations.FiscalYearEndMonth);
    this.mainForm.get('fiscalYearEndDay').setValue(this.currentOrganizations.FiscalYearEndDay);
    this.mainForm.get('bondAmount').setValue(this.currentOrganizations.BondAmount_N);

    if (this.currentOrganizations.BankRoutingNumber != null) {
      this.mainForm.get('bankRoutingNumber').disable();
      this.mainForm.get('bankRoutingNumber').setValue('#####' + this.currentOrganizations.BankRoutingNumber.substring(this.currentOrganizations.BankRoutingNumber.length, this.currentOrganizations.BankRoutingNumber.length - 3));
    }

    if (this.currentOrganizations.BankAccountNumber != null) {
      this.mainForm.get('bankAccountNumber').disable();
      this.mainForm.get('bankAccountNumber').setValue('#####' + this.currentOrganizations.BankAccountNumber.substring(this.currentOrganizations.BankAccountNumber.length, this.currentOrganizations.BankAccountNumber.length - 3));
    }

    if (this.currentOrganizations.PACRoutingNumber != null) {
      this.mainForm.get('PACRoutingNumber').disable();
      this.mainForm.get('PACRoutingNumber').setValue('#####' + this.currentOrganizations.PACRoutingNumber.substring(this.currentOrganizations.PACRoutingNumber.length, this.currentOrganizations.PACRoutingNumber.length - 3));
    }

    if (this.currentOrganizations.PACAccountNumber != null) {
      this.mainForm.get('PACAccountNumber').disable();
      this.mainForm.get('PACAccountNumber').setValue('#####' + this.currentOrganizations.PACAccountNumber.substring(this.currentOrganizations.PACAccountNumber.length, this.currentOrganizations.PACAccountNumber.length - 3));
    }

    this.mainForm.get('primaryPhoneNumber').setValue(this.currentOrganizations.PhoneNumber);
    this.mainForm.get('primaryPhoneExtension').setValue(this.currentOrganizations.PhoneExtension);
    this.mainForm.get('primaryEmail').setValue(this.currentOrganizations.EmailAddress);
    this.mainForm.get('IsEmployer').setValue(this.currentOrganizations.IsEmployer);
    this.setStatusId = this.currentOrganizations.StatusID;
    this.originalStatus = this.currentOrganizations.StatusID;
    this.mainForm.get('status').setValue(this.currentOrganizations.StatusID);
    this.setCharterStateId = this.currentOrganizations.CharterStateProvinceID;
    this.mainForm.get('charterLocation').setValue(this.currentOrganizations.CharterLocation);
    if (this.currentOrganizations.ByLawApprovalDate) {
      const chartered = new Date(this.currentOrganizations.ByLawApprovalDate);
      this.mainForm.get('byLawApprovalDate').setValue(chartered);
    }
    if (this.currentOrganizations.NextElectionDate) {
      const chartered = new Date(this.currentOrganizations.NextElectionDate);
      this.mainForm.get('nextElectionDate').setValue(chartered);
    }
    if (this.currentOrganizations.StatusEffectiveDate) {
      const chartered = new Date(this.currentOrganizations.StatusEffectiveDate);
      this.mainForm.get('statusEffectiveDate').setValue(chartered);
    }
    this.mainForm.get('meetingPlace').setValue(this.currentOrganizations.MeetingDetails);
    this.mainForm.get('statusReason').setValue(this.currentOrganizations.StatusReason);
    this.setCharterStateId = this.currentOrganizations.CharterStateProvinceID;

    this.addressData.CountryID = this.currentOrganizations.CountryID;
    this.addressData.Address1 = this.currentOrganizations.Address1;
    this.addressData.Address2 = this.currentOrganizations.Address2;
    this.addressData.City = this.currentOrganizations.City;
    this.addressData.StateProvinceID = this.currentOrganizations.StateProvinceID;
    this.addressData.Zip = this.currentOrganizations.Zip;
    this.addressData.ZipPlus4 = this.currentOrganizations.ZipPlus4;
    this.addressData.County = this.currentOrganizations.County;
    this.addressData.Latitude = this.currentOrganizations.Latitude;
    this.addressData.Longitude = this.currentOrganizations.Longitude;
    this.addressData.Ready = true;

    this.initSelects();
  }

  initUTLaunchStatus(disable: boolean): void {
    this.inputObjUTLaunchStatus = {
      labelText: 'Launch Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setUTLaunchStatusId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getUTStatusesLookupData().then((lookupData) => {
      this.inputObjUTLaunchStatus.data = lookupData;
      this.inputObjUTLaunchStatus = Object.assign({}, this.inputObjUTLaunchStatus);
    });
  }

  getUTLaunchStatusData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setUTLaunchStatusId = event[0].ID;
    } else {
      this.setUTLaunchStatusId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('utLaunchStatus').setValue(this.setUTLaunchStatusId);
  }

  initStatus(disable: boolean): void {
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStatusId,
      disabled: disable,
      canWrite: this.canTabWrite,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationStatusesLookupData('union').then((lookupData) => {
      this.inputObjStatus.data = lookupData;
      this.inputObjStatus = Object.assign({}, this.inputObjStatus);
    });
  }

  initCharterStateProvince(disable: boolean): void {
    this.inputObjProvidence = {
      labelText: 'Charter State/Province',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setCharterStateId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (this.addressData.CountryID) {
      this.lookupsDataService.getCharterStateProvinceLookupData(this.addressData.CountryID).then((lookupData) => {
        this.inputObjProvidence.data = lookupData;
        this.inputObjProvidence = Object.assign({}, this.inputObjProvidence);
      });
    }
  }

  getCharterStateData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setCharterStateId = event[0].ID;
    } else {
      this.setCharterStateId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('charterStateProvinceID').setValue(this.setCharterStateId);
  }

  getStatusData(event: any) {
    if (event && event[0] && event[0].ID) {
      this.setStatusId = event[0].ID;
    } else {
      this.setStatusId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('status').setValue(this.setStatusId);
    this.statusChanged = this.originalStatus !== this.setStatusId;
  }

  processData() {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.updatedOrganizations.UTStatusID = this.setUTLaunchStatusId;
      this.updatedOrganizations.Name = this.mainForm.get('name').value;
      this.updatedOrganizations.Number = this.mainForm.get('number').value;
      this.updatedOrganizations.Abbreviation = this.mainForm.get('abbreviation').value;
      this.updatedOrganizations.FEIN = this.mainForm.get('FEIN').value;
      this.updatedOrganizations.LM = this.mainForm.get('LM').value;

      this.updatedOrganizations.CharterDate = transformDateTimeToDateOnly(this.mainForm.get('chartered').value);
      this.updatedOrganizations.FiscalYearEndMonth = this.mainForm.get('fiscalYearEndMonth').value;
      this.updatedOrganizations.FiscalYearEndDay = this.mainForm.get('fiscalYearEndDay').value;
      this.updatedOrganizations.FiscalYearEndDay = this.mainForm.get('fiscalYearEndDay').value;
      this.updatedOrganizations.BondAmount_N = this.mainForm.get('bondAmount').value;
      this.updatedOrganizations.BankRoutingNumber = this.mainForm.get('bankRoutingNumber').value;
      this.updatedOrganizations.BankAccountNumber = this.mainForm.get('bankAccountNumber').value;
      this.updatedOrganizations.PACRoutingNumber = this.mainForm.get('PACRoutingNumber').value;
      this.updatedOrganizations.PACAccountNumber = this.mainForm.get('PACAccountNumber').value;
      this.updatedOrganizations.StatusID = this.mainForm.get('status').value;
      this.updatedOrganizations.CountryID = this.addressData.CountryID;
      this.updatedOrganizations.Address1 = this.addressData.Address1;
      this.updatedOrganizations.Address2 = this.addressData.Address2;
      this.updatedOrganizations.City = this.addressData.City;
      this.updatedOrganizations.StateProvinceID = this.addressData.StateProvinceID;
      this.updatedOrganizations.Zip = this.addressData.Zip;
      this.updatedOrganizations.ZipPlus4 = this.addressData.ZipPlus4;
      this.updatedOrganizations.County = this.addressData.County;
      this.updatedOrganizations.Latitude = this.addressData.Latitude;
      this.updatedOrganizations.Longitude = this.addressData.Longitude;
      this.updatedOrganizations.PhoneNumber = this.mainForm.get('primaryPhoneNumber').value;
      this.updatedOrganizations.PhonePhoneTypeID = 2; //use per Tom's instructions
      this.updatedOrganizations.PhoneExtension = this.mainForm.get('primaryPhoneExtension').value;
      this.updatedOrganizations.EmailAddress = this.mainForm.get('primaryEmail').value;
      this.updatedOrganizations.IsEmployer = this.mainForm.get('IsEmployer').value;
      this.updatedOrganizations.CharterLocation = this.mainForm.get('charterLocation').value;
      this.updatedOrganizations.CharterStateProvinceID = this.setCharterStateId;
      this.updatedOrganizations.ByLawApprovalDate = transformDateTimeToDateOnly(this.mainForm.get('byLawApprovalDate').value);
      this.updatedOrganizations.NextElectionDate = transformDateTimeToDateOnly(this.mainForm.get('nextElectionDate').value);
      this.updatedOrganizations.MeetingDetails = this.mainForm.get('meetingPlace').value;
      if (this.statusChanged) {
        this.updatedOrganizations.StatusReason = this.mainForm.get('statusReason').value;
        this.updatedOrganizations.StatusEffectiveDate = transformDateTimeToDateOnly(this.mainForm.get('statusEffectiveDate').value);
      }
      this.updatedOrganizations.OrganizationReferenceID = this.currentOrganizations.OrganizationReferenceID;
      this.saveForm();
    }
  }

  saveForm() {
    if (!this.isEdit) {
      this.organizationService.addChildOrganizationByStructureType(this.setOrganizationId, 'union', this.updatedOrganizations)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.ref.destroy();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Your organization has been successfully added'
            });
            this.isSaving = false;
            this.closeAddEdit.emit(true);
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    } else {
      this.organizationService.updateOrganization(this.ID, this.updatedOrganizations)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.ref.destroy();
            this.confirmationService.close();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'This organization has been updated'
            });
            this.mainForm.markAsPristine();
            this.mainForm.markAsUntouched();
            this.isSaving = false;
            this.existingMessage = false;
            this.closeAddEdit.emit(true);
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    this.addressData = {} as AddressModel;
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  setAddress(addressChanges: AddressModel): void {
    if (addressChanges) {
      this.mainForm.markAsDirty();
      this.addressData.CountryID = addressChanges.CountryID;
      this.addressData.Address1 = addressChanges.Address1;
      this.addressData.Address2 = addressChanges.Address2;
      this.addressData.City = addressChanges.City;
      this.addressData.StateProvinceID = addressChanges.StateProvinceID;
      this.addressData.Zip = addressChanges.Zip;
      this.addressData.ZipPlus4 = addressChanges.ZipPlus4;
      this.addressData.County = addressChanges.County;
      this.addressData.Latitude = addressChanges.Latitude;
      this.addressData.Longitude = addressChanges.Longitude;
    }
  }
}
