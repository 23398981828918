import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import {WageRateModel, StudentWageRatesModel, UpdateWageRateModel, CreateWageRateModel} from '../models/wage-rates.model';

@Injectable({
  providedIn: 'root',
})
export class WageRatesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getStudentWageRatesPrime(programStudentId: number, filterData): Observable<StudentWageRatesModel> {
    return this.http.post<StudentWageRatesModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/wagerate/grid`, filterData);
  }

  createStudentWageRates(programStudentId: number, dataModel: UpdateWageRateModel): Observable<CreateWageRateModel> {
    return this.http.post<CreateWageRateModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/wagerate/create`, dataModel);
  }

  updateStudentWageRates(programStudentId: number, programStudentWageRate: number, dataModel: UpdateWageRateModel): Observable<UpdateWageRateModel> {
    return this.http.put<UpdateWageRateModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/wagerate/${programStudentWageRate}`, dataModel);
  }

  deleteStudentWageRates(programStudentId: number, programStudentProgramWageRateId: number): Observable<UpdateWageRateModel> {
    return this.http.delete<UpdateWageRateModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/programstudent/${programStudentId}/wagerate/${programStudentProgramWageRateId}`);
  }

}
