
<div class="page-container">
  <div class="public-card">
    <p-card class="card">
      @if (logo) {
        <div class="align-center">
          <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true" />
        </div>
      }
      <div class="page-container">
        @if (verifyPath) {
          <div>
            <h2>Verify Invitation</h2>
            <p>Please provide the following data to verify your invitation. Thank you and Welcome!</p>
            <form [formGroup]="mainForm" class="user-registration-form">
              <div class="p-grid p-fluid">
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="p-fieldset-content p-mt-2">
                    <label>Password/Passphrase <sup class="req">*</sup></label>
                    <input [type]="passwordType" pInputText formControlName="password">
                    @if (mainForm.controls.password.invalid && mainForm.controls.password.touched) {
                      <div class="p-error">
                        @if (mainForm.controls.password.errors?.required) {
                          <div>Password is required</div>
                        }
                        <!--                <div *ngIf="changePasswordForm.controls.password.errors?.pattern">Must contain at least one uppercase and lowercase letter, one number, one special character, and be at least 10 characters long</div>-->
                        @if (mainForm.controls.password.hasError('length')) {
                          <div>Must be at least 10 characters long</div>
                        }
                        @if (mainForm.controls.password.hasError('uppercase')) {
                          <div>Must contain at least one uppercase letter (A-Z)</div>
                        }
                        @if (mainForm.controls.password.hasError('lowercase')) {
                          <div>Must contain at least one lowercase letter (a-z)</div>
                        }
                        @if (mainForm.controls.password.hasError('number')) {
                          <div>Must contain at least one number (0-9)</div>
                        }
                        @if (mainForm.controls.password.hasError('special')) {
                          <div [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></div>
                        }
                        @if (mainForm.controls.password.hasError('repeats')) {
                          <div>Must have no more than 2 identical characters in a row (999 not allowed)</div>
                        }
                        @if (mainForm.controls.password.hasError('customValidator')) {
                          <div>No parts of your username</div>
                        }
                      </div>
                    }
                    <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPassword" label="Show Password"></p-checkbox>
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Confirm Password/Passphrase <sup class="req">*</sup></label>
                    <input [type]="passwordConfirmType" pInputText formControlName="passwordConfirm">
                    @if (mainForm.controls.passwordConfirm.invalid && mainForm.controls.passwordConfirm.touched) {
                      <div class="p-error">
                        @if (mainForm.controls.passwordConfirm.errors?.required) {
                          <div>Confirm Password is required</div>
                        }
                        @if (mainForm.controls.passwordConfirm.errors?.confirmedValidator) {
                          <div>Password & Confirm Password do not match</div>
                        }
                      </div>
                    }
                    <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPasswordConfirm" label="Show Password"></p-checkbox>
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <!--            <span class="align-right"><a href="https://www.useapassphrase.com/" target="_blank" rel="noopener noreferrer" title="Why use a passphrase?">Why use a passphrase?</a></span>-->
                    <span class="link" (click)="toggleRequirements()">Requirements</span>
                    @if (requirements) {
                      <ul>
                        <li>Must be at least 10 characters long</li>
                        <li>Must contain at least one uppercase letter (A-Z)</li>
                        <li>Must contain at least one lowercase letter (a-z)</li>
                        <li>Must contain at least one number (0-9)</li>
                        <li [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></li>
                        <li>Must have no more than 2 identical characters in a row (999 not allowed)</li>
                        <li>No parts of your username</li>
                      </ul>
                    }
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="p-fieldset-content p-mt-2">
                    @if (showQuestion1Id) {
                      <app-select class="full-width" [inputObj]="inputObjQuestion1" (emitData)="getQuestion1Data($event)"></app-select>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Answer <sup class="req">*</sup></label>
                    <input pInputText formControlName="answer1" maxlength="100">
                    @if (mainForm.controls.answer1.invalid && mainForm.controls.answer1.touched) {
                      <div class="p-error">
                        @if (mainForm.controls.answer1.errors?.required) {
                          <div>An answer is required</div>
                        }
                        @if (mainForm.controls.answer1.errors?.minlength) {
                          <div>Answer must be between 4 and 100 characters</div>
                        }
                      </div>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    @if (showQuestion2Id) {
                      <app-select class="full-width" [inputObj]="inputObjQuestion2" (emitData)="getQuestion2Data($event)"></app-select>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Answer <sup class="req">*</sup></label>
                    <input pInputText formControlName="answer2" maxlength="100">
                    @if (mainForm.controls.answer2.invalid && mainForm.controls.answer2.touched) {
                      <div class="p-error">
                        @if (mainForm.controls.answer2.errors?.required) {
                          <div>An answer is required</div>
                        }
                        @if (mainForm.controls.answer2.errors?.minlength) {
                          <div>Answer must be between 4 and 100 characters</div>
                        }
                      </div>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    @if (showQuestion3Id) {
                      <app-select class="full-width" [inputObj]="inputObjQuestion3" (emitData)="getQuestion3Data($event)"></app-select>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Answer <sup class="req">*</sup></label>
                    <input pInputText formControlName="answer3" maxlength="100">
                    @if (mainForm.controls.answer3.invalid && mainForm.controls.answer3.touched) {
                      <div class="p-error">
                        @if (mainForm.controls.answer3.errors?.required) {
                          <div>An answer is required</div>
                        }
                        @if (mainForm.controls.answer3.errors?.minlength) {
                          <div>Answer must be between 4 and 100 characters</div>
                        }
                      </div>
                    }
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="p-fieldset-content p-mt-2">
                    <label>Date Of Birth <sup class="req">*</sup></label>
                    <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="dateOfBirth" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025"></p-calendar>
                    @if ((mainForm.controls.dateOfBirth.dirty || mainForm.controls.dateOfBirth.touched) &&
                      mainForm.controls.dateOfBirth.errors?.required) {
                      <span class="p-error">'Date Of Birth' must be provided</span>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2 mask-container">
                    <label>Mobile Phone # <sup class="req">*</sup></label>
                    <p-inputMask type="text" pInputText formControlName="primaryPhoneNumber" [className]="'form-input no-border'" mask="(999) 999-9999"
                    unmask="true"></p-inputMask>
                    @if ((mainForm.controls.primaryPhoneNumber.dirty || mainForm.controls.primaryPhoneNumber.touched) &&
                      mainForm.controls.primaryPhoneNumber.errors?.required) {
                      <span class="p-error">'Mobile Phone #' must be provided</span>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Member #</label>
                    <input type="text" pInputText  formControlName="memberNumber">
                    <p>(Not Required)</p>
                  </div>
                </div>
              </div>
              <div class="tab-form-buttons p-mt-2">
                @if (!isVerifying) {
                  <button pButton label="Verify Invitation" class="p-button-info p-mr-2" pTooltip="Verify Invitation" tooltipPosition="bottom"
                  (click)="verify()" [className]="'inline-button'" [disabled]="mainForm.invalid || !mainForm.touched"></button>
                }
                @if (isVerifying) {
                  <button pButton label="Verify Invitation" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                    <i class="pi pi-spin pi-spinner save-spinner"></i>
                  </button>
                }
              </div>
            </form>
          </div>
        }
        @if (expiredPath) {
          <div>
            <h2>Create an Account</h2>
            <p>The window to respond to your invitation has expired. Please register to continue.</p>
            <form [formGroup]="mainFormExpired" class="user-registration-form">
              <div class="p-grid p-fluid">
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="p-fieldset-content">
                    <label>Username (E-mail)<span class="ut-requireddenotation"> *</span></label>
                    <input type="email" pInputText formControlName="username">
                    @if (mainFormExpired.controls.username.invalid && mainFormExpired.controls.username.touched) {
                      <div class="p-error">
                        @if (mainFormExpired.controls.username.errors?.required) {
                          <div>Username is required</div>
                        }
                        @if (mainFormExpired.controls.username?.errors) {
                          <div>Username must be a valid e-mail address</div>
                        }
                      </div>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Password/Passphrase <sup class="req">*</sup></label>
                    <input [type]="passwordType" pInputText formControlName="password">
                    @if (mainFormExpired.controls.password.invalid && mainFormExpired.controls.password.touched) {
                      <div class="p-error">
                        @if (mainFormExpired.controls.password.errors?.required) {
                          <div>Password is required</div>
                        }
                        <!--                <div *ngIf="changePasswordForm.controls.password.errors?.pattern">Must contain at least one uppercase and lowercase letter, one number, one special character, and be at least 10 characters long</div>-->
                        @if (mainFormExpired.controls.password.hasError('length')) {
                          <div>Must be at least 10 characters long</div>
                        }
                        @if (mainFormExpired.controls.password.hasError('uppercase')) {
                          <div>Must contain at least one uppercase letter (A-Z)</div>
                        }
                        @if (mainFormExpired.controls.password.hasError('lowercase')) {
                          <div>Must contain at least one lowercase letter (a-z)</div>
                        }
                        @if (mainFormExpired.controls.password.hasError('number')) {
                          <div>Must contain at least one number (0-9)</div>
                        }
                        @if (mainFormExpired.controls.password.hasError('special')) {
                          <div [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></div>
                        }
                        @if (mainFormExpired.controls.password.hasError('repeats')) {
                          <div>Must have no more than 2 identical characters in a row (999 not allowed)</div>
                        }
                        @if (mainFormExpired.controls.password.hasError('customValidator')) {
                          <div>No parts of your username</div>
                        }
                      </div>
                    }
                    <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPassword" label="Show Password"></p-checkbox>
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Confirm Password/Passphrase <sup class="req">*</sup></label>
                    <input [type]="passwordConfirmType" pInputText formControlName="passwordConfirm">
                    @if (mainFormExpired.controls.passwordConfirm.invalid && mainFormExpired.controls.passwordConfirm.touched) {
                      <div class="p-error">
                        @if (mainFormExpired.controls.passwordConfirm.errors?.required) {
                          <div>Confirm Password is required</div>
                        }
                        @if (mainFormExpired.controls.passwordConfirm.errors?.confirmedValidator) {
                          <div>Password & Confirm Password do not match</div>
                        }
                      </div>
                    }
                    <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPasswordConfirm" label="Show Password"></p-checkbox>
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <!--            <span class="align-right"><a href="https://www.useapassphrase.com/" target="_blank" rel="noopener noreferrer" title="Why use a passphrase?">Why use a passphrase?</a></span>-->
                    <span class="link" (click)="toggleRequirements()">Requirements</span>
                    @if (requirements) {
                      <ul>
                        <li>Must be at least 10 characters long</li>
                        <li>Must contain at least one uppercase letter (A-Z)</li>
                        <li>Must contain at least one lowercase letter (a-z)</li>
                        <li>Must contain at least one number (0-9)</li>
                        <li [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></li>
                        <li>Must have no more than 2 identical characters in a row (999 not allowed)</li>
                        <li>No parts of your username</li>
                      </ul>
                    }
                  </div>
                  <div class="p-fieldset-content tab-form-buttons p-mt-2">
                    @if (!isVerifying) {
                      <button pButton label="Register" class="p-button-info p-mr-2" pTooltip="Register" tooltipPosition="bottom"
                      (click)="signUp()" [className]="'inline-button'" [disabled]="mainFormExpired.invalid || !mainFormExpired.touched"></button>
                    }
                    @if (isVerifying) {
                      <button pButton label="Register" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                        <i class="pi pi-spin pi-spinner save-spinner"></i>
                      </button>
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
        @if (unlinkedPath) {
          <div>
            <h2>Verify Invitation</h2>
            <p>We're close, but have one additional request. Please provide the following so your account may be appropriately linked.</p>
            <form [formGroup]="mainFormUnlinked" class="user-registration-form">
              <div class="p-grid p-fluid">
                <div class="col-md-4 col-sm-12 col-xs-12">
                  <div class="p-fieldset-content p-mt-2">
                    <label>First Name <sup class="req">*</sup></label>
                    <input type="text" pInputText  formControlName="firstName">
                    @if ((mainFormUnlinked.controls.firstName.dirty || mainFormUnlinked.controls.firstName.touched) &&
                      mainFormUnlinked.controls.firstName.errors?.required) {
                      <span class="p-error">'First Name' must be provided</span>
                    }
                  </div>
                  <div class="p-fieldset-content p-mt-2">
                    <label>Last Name <sup class="req">*</sup></label>
                    <input type="text" pInputText  formControlName="lastName">
                    @if ((mainFormUnlinked.controls.lastName.dirty || mainFormUnlinked.controls.lastName.touched) &&
                      mainFormUnlinked.controls.lastName.errors?.required) {
                      <span class="p-error">'Last Name' must be provided</span>
                    }
                  </div>
                  <div class="p-fieldset-content tab-form-buttons p-mt-2">
                    @if (!isVerifying) {
                      <button pButton label="Submit" class="p-button-info p-mr-2" pTooltip="Submit" tooltipPosition="bottom"
                      (click)="attemptToLink()" [className]="'inline-button'" [disabled]="mainFormUnlinked.invalid || !mainFormUnlinked.touched"></button>
                    }
                    @if (isVerifying) {
                      <button pButton label="Submit" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                        <i class="pi pi-spin pi-spinner save-spinner"></i>
                      </button>
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
        }
      </div>
    </p-card>
  </div>
  <p-toast [baseZIndex]="16000"></p-toast>
</div>
