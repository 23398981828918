import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AccountAdjustmentListModel} from '../../../models/invoices.model';
import {AccountAdjustmentsService} from '../../../services/account-adjustments.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {transformDateTimeToDateOnly} from '../../utilities/form.utility';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import moment from 'moment';
import {PageRowCountUtility} from '../../utilities/page-row-count.utility';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {simplifiedPaymentAdjustment} from '../../utilities/component-manager-utility';


@Component({
  selector: 'app-admin-balance-correction-tool',
  templateUrl: './admin-balance-correction-tool.component.html',
  styleUrls: ['./admin-balance-correction-tool.component.scss'],
})
export class AdminBalanceCorrectionToolComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  accountAdjustments: AccountAdjustmentListModel;
  isSaving: boolean = false;
  isDialog: false;
  totalBalance: number;
  tempCurrentDate;
  tempAdjustedDate;
  todaysDate: Date = new Date();
  viewType = 'paidThrough';
  RefreshInvoiceID: number = null;
  inputObjOrganization: GenericSelectModel;
  setOrganizationId: number;
  @Input() organizationId;
  @Input() currentMemberOrganizationId;
  @Input() PersonID;
  @Output() updateData = new EventEmitter<any>();
  @Output() updateTab = new EventEmitter<any>();
  @Output() updateGlobalOrgId = new EventEmitter<any>();
  @Input() canTabWrite: boolean;
  cols = [];
  public _simplifiedPaymentAdjustment = simplifiedPaymentAdjustment;


  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private accountAdjustmentsService: AccountAdjustmentsService,
              private messageService: MessageService, private config: DynamicDialogConfig, private tenantConfigDataService: TenantConfigDataService,
              private ref: DynamicDialogRef, public pageRowCountUtility: PageRowCountUtility,
              private lookupsDataService: LookupsDataService) {

    this.cols = [
      {field: 'BillingAdjustmentDescription', header: '', fullHeader: '', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'CurrentAmountOwed', header: 'Current Amount Owed', fullHeader: '', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'field'},
      {field: 'AdjustedAmountOwed', header: 'Adjusted Amount Owed', fullHeader: 'Status', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'field'},
    ];

    this.mainForm = this.formBuilder.group({
      organization: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.isDialog = this.config.data.isDialog;
    }
    //this.todaysDate = moment();
    if (this.isDialog) {
      this.currentMemberOrganizationId = this.config.data.organizationId;
      this.PersonID = this.config.data.personId;
      this.getAccountAdjustments();
    }
    if(this._simplifiedPaymentAdjustment()) {
      this.viewType = 'balance';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.PersonID) {
      this.getAccountAdjustments();
      //this.initOrganization(false);
    }
  }

  // initOrganization(disable: boolean): void {
  //   this.inputObjOrganization = {
  //     labelText: 'Organization',
  //     optionValue: 'ID',
  //     optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
  //     filter: true,
  //     requiredField: true,
  //     selectFirstValue: false,
  //     initSelected: (this.organizationId) ? this.organizationId : null,
  //     disabled: disable,
  //     emitChangeOnLoad: true,
  //     customSelect: 'ShowInactiveIcon'
  //   };
  //   this.lookupsDataService.getOrganizationListByObjectAccessLookupData('ROSTER').then((lookupData) => {
  //     this.inputObjOrganization.data = lookupData;
  //     this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
  //   });
  // }
  //
  // setOrganizationData(event:any): void {
  //   if (event && event[0] && event[0].ID) {
  //     this.setOrganizationId = event[0].ID;
  //   } else {
  //     this.setOrganizationId = 0;
  //   }
  //   this.mainForm.markAsDirty();
  //   this.mainForm.get('organization').setValue(this.setOrganizationId);
  //   this.organizationId = this.setOrganizationId;
  //   this.updateGlobalOrgId.emit(this.setOrganizationId);
  //   console.log('org id === ' + this.organizationId + ' original id === ' + this.currentMemberOrganizationId);
  //   if (this.organizationId !== this.currentMemberOrganizationId) {
  //     this.viewType = 'balance';
  //   } else {
  //     this.viewType = 'paidThrough';
  //   }
  // }

  getTotalCurrent() {
    let totalCurrent = 0;
    if (this.accountAdjustments) {
      this.accountAdjustments.BalanceAdjustmentList.forEach(account => {
        totalCurrent = totalCurrent + account.CurrentAmountOwed;
      });
      return `${totalCurrent.toFixed(2)}`;
    }
  }

  getTotalAdjustment() {
    let totalAdjusted = 0;
    if (this.accountAdjustments) {
      this.accountAdjustments.BalanceAdjustmentList.forEach(account => {
        totalAdjusted = totalAdjusted + +account.AdjustedAmountOwed;
      });
      return `${totalAdjusted}`;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getAccountAdjustments() {
    this.accountAdjustmentsService.getAccountAdjustmentDetails(this.currentMemberOrganizationId, this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.accountAdjustments = res;

          if (this.accountAdjustments.AdjustedPaidThroughDate) {
            this.tempAdjustedDate = new Date(this.accountAdjustments.AdjustedPaidThroughDate);
          } else {
            this.tempAdjustedDate = this.todaysDate;
          }

          if (this.accountAdjustments.CurrentPaidThroughDate) {
            this.tempCurrentDate = new Date(this.accountAdjustments.CurrentPaidThroughDate);
          }
        }
      });
  }

  isValid() {
    return !!(this.accountAdjustments.Memo || this.viewType === 'paidThrough');
  }

  clearBalance() {
    this.accountAdjustments.BalanceAdjustmentList = null;
    console.log(this.accountAdjustments);
  }

  saveData() {
    const lastDayOfPaidDate = moment(this.tempAdjustedDate).endOf('month').format('YYYY-MM-DD');
    const lastDayOfCurrentDate = moment(this.tempCurrentDate).endOf('month').format('YYYY-MM-DD');
    this.accountAdjustments.AdjustedPaidThroughDate = transformDateTimeToDateOnly(lastDayOfPaidDate);
    this.accountAdjustments.CurrentPaidThroughDate = transformDateTimeToDateOnly(lastDayOfCurrentDate);
    if (this.config?.data?.selectedTable === 'Invoice') {
      this.accountAdjustments.RefreshInvoiceID = this.config.data.ID;
    }
    if (this.config?.data?.selectedTable === 'Payment') {
      this.accountAdjustments.RefreshPaymentID = this.config.data.ID;
    }
    this.isSaving = true;
    this.accountAdjustmentsService.makeAccountAdjustment(this.currentMemberOrganizationId, this.PersonID, this.accountAdjustments)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          if (this.viewType === 'paidThrough') {
            this.RefreshInvoiceID = res;
            this.accountAdjustmentsService.getAccountAdjustmentDetails(this.currentMemberOrganizationId, this.PersonID)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (result) => {
                  this.viewType = 'balance';
                  this.accountAdjustments.RefreshInvoiceID = this.RefreshInvoiceID;
                  this.accountAdjustments.BalanceAdjustmentList = result.BalanceAdjustmentList;
                }
              });
          } else {
            this.viewType = 'paidThrough';
            this.updateTab.emit(1);
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Balance Adjustment was saved successfully.'
            });
            if (this.isDialog) {
              this.ref.close();
            }
          }

          this.updateData.emit();
          this.isSaving = false;

        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
          this.isSaving = false;
        }
      });
  }
}
