@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (isEmptyData) {
  <span>No invoice exists to be paid at the moment.</span>
}
@if (!isEmptyData) {
  <form [formGroup]="mainForm">
    @if (!confirm) {
      <div class="p-grid p-fluid">
        @if (paymentData) {
          <div class="col-md-6 col-sm-12 col-xs-12">
            <p-card header="{{paymentData.OrganizationName.toUpperCase()}} - PER CAPITA TAX" [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10">
                  <div class="p-grid p-fluid">
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>Last Invoice Date</strong>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.LastInvoiceDate_D | date:'shortDate'}}
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>Due Date</strong>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.DueDate_D | date:'shortDate'}}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10">
                  <div class="p-grid p-fluid">
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>Last Invoice Amount</strong>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.LastInvoiceAmount_N | currency:'USD':'symbol':'1.2-2'}}
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>Overdue Amount</strong>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.OverDueAmount_N | currency:'USD':'symbol':'1.2-2'}}
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>*Returned Payments</strong>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.ReturnedPayments_N | currency:'USD':'symbol':'1.2-2'}}
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>New Adjustments</strong>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.NewAdjustments_N | currency:'USD':'symbol':'1.2-2'}}
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>New Payments</strong>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.NewPayments_N | currency:'USD':'symbol':'1.2-2'}}
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>Current Balance</strong>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.CurrentBalance_N | currency:'USD':'symbol':'1.2-2'}}
                    </div>
                  </div>
                </div>
              </div>
              <p-divider class="dark-divider"></p-divider>
              <div class="p-grid p-fluid">
                <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 text-align-middle">
                  <div class="radio-container">
                    <p-radioButton value="full" formControlName="amountToPay" id="full" class="radio-input" (click)="togglePayInFull('full')"></p-radioButton>
                    <label class="radio-label p-mr-5" for="full"><strong>Pay IN FULL ({{paymentData.CurrentBalance_N | currency:'USD':'symbol':'1.2-2'}})</strong></label>
                    <p-radioButton value="overdue" formControlName="amountToPay" id="overdue" class="radio-input" (click)="togglePayInFull('overdue')"></p-radioButton>
                    <label class="radio-label" for="overdue"><strong>Pay Overdue Balance ONLY ({{paymentData.RemainingOverDueAmount_N | currency:'USD':'symbol':'1.2-2'}})</strong></label>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                  <strong>*Returned Payments</strong> - Pending payment(s) that you made before your last invoice have since been returned. You are responsible for re-submitting this payment.
                </div>
              </div>
            </p-card>
          </div>
        }
        <div class="col-md-6 col-sm-12 col-xs-12">
          <p-card header="PAYMENT SUMMARY" [styleClass]="'list-card'">
            @if (payInFull) {
              <div class="p-grid p-fluid">
                <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                  <strong>Total Current Balance</strong>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                  {{paymentData.CurrentBalance_N | currency:'USD':'symbol':'1.2-2'}}
                </div>
              </div>
            }
            @if (!payInFull) {
              <div class="p-grid p-fluid">
                <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                  <strong>Total Overdue Balance</strong><br><span class="p-ml-5"><strong>(Including any Returned Payments)</strong></span>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                  {{paymentData.RemainingOverDueAmount_N | currency:'USD':'symbol':'1.2-2'}}
                </div>
              </div>
            }
            <p-divider class="dark-divider"></p-divider>
            <div class="p-grid p-fluid">
              <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                <strong>Total Amount</strong>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                <strong>{{totalToBePaid | currency:'USD':'symbol':'1.2-2'}}</strong>
              </div>
            </div>
          </p-card>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <p-card header="Payment Method" [styleClass]="'list-card'">
            @if (paymentMethodList && paymentMethodList.length > 0) {
              @for (paymentMethod of paymentMethodList; track paymentMethod) {
                <div class="p-field-radiobutton">
                  <p-radioButton value="{{ paymentMethod.PaymentMethodId }}" formControlName="paymentMethodID" [styleClass]="'radio-input'" class="p-mr-2" label="{{ paymentMethod.CCCardType }} {{ paymentMethod.AccountNumber }}" (onClick)="toggleCardPaymentMethod(paymentMethod)"></p-radioButton>
                </div>
              }
            }
            <p-divider class="dark-divider"></p-divider>
            <div class="p-grid p-fluid parent-form">
              <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                <label>Name/Description <sup class="req">*</sup></label>
                <input type="text" pInputText  formControlName="MethodNickname">
                @if ((mainForm.controls.MethodNickname.dirty || mainForm.controls.MethodNickname.touched) &&
                  mainForm.controls.MethodNickname.errors?.required) {
                  <span class="error">'Name/Description' must be provided</span>
                }
              </div>
              <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                <h3>Auto Pay</h3>
                <p>*By filling out and submitting this form, you are giving us your explicit permission to use the specified payment method to pay off your entire PCT invoice balance to "{{paymentData.OrganizationName}}" each month on the day of the month indicated.</p>
                <div class="p-grid p-fluid">
                  <div class="col-md-3 col-sm-12 col-xs-12">
                    <app-select class="full-width" [inputObj]="inputObjAutoPay" (emitData)="getAutoPayData($event)"></app-select>
                  </div>
                </div>
              </div>
              @if (isNew) {
                <div class="col-md-12 col-sm-12 col-xs-12 input-container p-mt-2">
                  <div class="radio-container">
                    @if (paymentData.AllowsCC) {
                      <p-radioButton value="CC" formControlName="paymentMethodType" [styleClass]="'radio-input'" class="p-mr-2" label="Credit Card"></p-radioButton>
                    }
                    @if (paymentData.AllowsACH) {
                      <p-radioButton value="ACH" formControlName="paymentMethodType" [styleClass]="'radio-input'" label="Electronic Check (Direct Debit)"></p-radioButton>
                    }
                  </div>
                </div>
              }
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="p-grid p-fluid">
                  @if (methodType==='CC') {
                    <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                      <h3>Credit Card Details</h3>
                    </div>
                  }
                  @if (methodType==='CC') {
                    <div class="col-md-12 col-sm-6 col-xs-12 input-container">
                      <label>Name as it appears on card <sup class="req">*</sup></label>
                      <input type="text" pInputText  formControlName="CCNameOnAccount">
                      @if ((mainForm.controls.CCNameOnAccount.dirty || mainForm.controls.CCNameOnAccount.touched) &&
                        mainForm.controls.CCNameOnAccount.errors?.required) {
                        <span class="error">'Name as it appears on card' must be provided</span>
                      }
                    </div>
                  }
                  @if (methodType==='CC') {
                    <div class="col-md-8 col-sm-6 col-xs-12 input-container">
                      <label>Credit Card # (No spaces/dashes) <sup class="req">*</sup></label>
                      <input type="text" pInputText formControlName="CCAccountNumber" (blur)="getCCDetails(this.mainForm.controls.CCAccountNumber.value)">
                      @if ((mainForm.controls.CCAccountNumber.dirty || mainForm.controls.CCAccountNumber.touched) &&
                        mainForm.controls.CCAccountNumber.errors?.required) {
                        <span class="error">'Credit Card #' must be provided</span>
                      }
                    </div>
                  }
                  @if (methodType==='CC') {
                    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
                      <label>Credit Card Type</label>
                      <input type="text" pInputText  formControlName="CCCardType">
                    </div>
                  }
                  @if (methodType==='CC') {
                    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
                      <label>Exp. Month <sup class="req">*</sup></label>
                      <input type="text" pInputText  formControlName="CCExpirationMonth" maxlength="2">
                      @if ((mainForm.controls.CCExpirationMonth.dirty || mainForm.controls.CCExpirationMonth.touched) &&
                        mainForm.controls.CCExpirationMonth.errors?.required) {
                        <span class="error">'Exp. Month' must be provided</span>
                      }
                      @if ((mainForm.controls.CCExpirationMonth.dirty || mainForm.controls.CCExpirationMonth.touched) &&
                        mainForm.controls.CCExpirationMonth.errors?.min) {
                        <span class="error">Must be between 1 and 12</span>
                      }
                      @if ((mainForm.controls.CCExpirationMonth.dirty || mainForm.controls.CCExpirationMonth.touched) &&
                        mainForm.controls.CCExpirationMonth.errors?.max) {
                        <span class="error">Must be between 1 and 12</span>
                      }
                      @if (mainForm.controls.CCExpirationMonth.hasError('number')) {
                        <div class="error">Must be a number</div>
                      }
                    </div>
                  }
                  @if (methodType==='CC') {
                    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
                      <label>Exp. Year <sup class="req">*</sup></label>
                      <input type="text" pInputText  formControlName="CCExpirationYear" maxlength="2">
                      @if ((mainForm.controls.CCExpirationYear.dirty || mainForm.controls.CCExpirationYear.touched) &&
                        mainForm.controls.CCExpirationYear.errors?.required) {
                        <span class="error">'Exp. Year' must be provided</span>
                      }
                      @if ((mainForm.controls.CCExpirationYear.dirty || mainForm.controls.CCExpirationYear.touched) &&
                        mainForm.controls.CCExpirationYear.errors?.min) {
                        <span class="error">Must be between {{minYear}} and {{maxYear}}</span>
                      }
                      @if ((mainForm.controls.CCExpirationYear.dirty || mainForm.controls.CCExpirationYear.touched) &&
                        mainForm.controls.CCExpirationYear.errors?.max) {
                        <span class="error">Must be between {{minYear}} and {{maxYear}}</span>
                      }
                      @if (mainForm.controls.CCExpirationYear.hasError('number')) {
                        <div class="error">Must be a number</div>
                      }
                    </div>
                  }
                  @if (methodType==='CC') {
                    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
                      <label>Verification # <sup class="req">*</sup></label>
                      <input type="text" pInputText formControlName="CVV">
                      @if ((mainForm.controls.CVV.dirty || mainForm.controls.CVV.touched) &&
                        mainForm.controls.CVV.errors?.required) {
                        <span class="error">'Verification #' must be provided</span>
                      }
                      @if (mainForm.controls.CVV.errors?.minlength || mainForm.controls.CVV.errors?.maxlength) {
                        <div>Must be 4 or fewer</div>
                      }
                    </div>
                  }
                  @if (methodType==='CC') {
                    <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                      <img class="float-right" ngSrc="./assets/images/cvv.png" width="350" height="136" alt="" [priority]="true" />
                      <img ngSrc="./assets/images/cc_logos.png" width="80" height="25" alt="" [priority]="true" />
                    </div>
                  }
                  @if (methodType==='ACH') {
                    <div class="col-md-12 col-sm-6 col-xs-12 input-container">
                      <h3>Electronic Check Payment Terms &amp; Conditions</h3>
                      <p>When you provide your check information, you authorize the <span class="ut-shortname">UnionTrack</span> to use information from your check to make an electronic fund transfer from your account.</p>
                      <p>When payments are made from your account, funds may be withdrawn as soon as the same day we receive your payment, and you will not receive your check back from your financial institution.</p>
                    </div>
                  }
                  @if (methodType==='ACH') {
                    <div class="col-md-12 col-sm-6 col-xs-12 input-container">
                      <label>Name on Account <sup class="req">*</sup></label>
                      <input type="text" pInputText  formControlName="ACHNameOnAccount">
                      @if ((mainForm.controls.ACHNameOnAccount.dirty || mainForm.controls.ACHNameOnAccount.touched) &&
                        mainForm.controls.ACHNameOnAccount.errors?.required) {
                        <span class="error">'Name on Account' must be provided</span>
                      }
                    </div>
                  }
                  @if (methodType==='ACH') {
                    <div class="col-md-12 col-sm-6 col-xs-12 input-container">
                      <div class="radio-container">
                        <p-radioButton value="CHECKING" formControlName="ACHAccountType" id="CHECKING" [styleClass]="'radio-input'" class="p-mr-2" label="Checking"></p-radioButton>
                        <p-radioButton value="SAVING" formControlName="ACHAccountType" id="SAVING" [styleClass]="'radio-input'" label="Savings"></p-radioButton>
                      </div>
                    </div>
                  }
                  @if (methodType==='ACH') {
                    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                      <label>Routing # <sup class="req">*</sup></label>
                      <input type="text" pInputText  formControlName="ACHRoutingNumber" maxlength="9" (blur)="getBankName(this.mainForm.controls.ACHRoutingNumber.value)">
                      @if ((mainForm.controls.ACHRoutingNumber.dirty || mainForm.controls.ACHRoutingNumber.touched) &&
                        mainForm.controls.ACHRoutingNumber.errors?.required) {
                        <span class="error">'Routing # ' must be provided</span>
                      }
                    </div>
                  }
                  @if (methodType==='ACH') {
                    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                      <label>Bank</label>
                      <input type="text" pInputText  formControlName="ACHBankName">
                    </div>
                  }
                  @if (methodType==='ACH') {
                    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                      <label>Account # <sup class="req">*</sup></label>
                      <input type="text" pInputText formControlName="ACHAccountNumber" maxlength="20">
                      @if ((mainForm.controls.ACHAccountNumber.dirty || mainForm.controls.ACHAccountNumber.touched) &&
                        mainForm.controls.ACHAccountNumber.errors?.required) {
                        <span class="error">'Account # ' must be provided</span>
                      }
                    </div>
                  }
                  @if (methodType==='ACH') {
                    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                      <label>Confirm Account # <sup class="req">*</sup></label>
                      <input type="text" pInputText formControlName="ACHAccountNumberConfirm" maxlength="20">
                      @if (mainForm.controls.ACHAccountNumberConfirm.invalid && mainForm.controls.ACHAccountNumberConfirm.touched) {
                        <div class="p-error">
                          @if (mainForm.controls.ACHAccountNumberConfirm.errors?.required) {
                            <div>Confirm Account # is required</div>
                          }
                          @if (mainForm.controls.ACHAccountNumberConfirm.errors?.confirmedValidator) {
                            <div>Account # & Confirm Account # do not match</div>
                          }
                        </div>
                      }
                    </div>
                  }
                  @if (methodType==='ACH') {
                    <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                      <img ngSrc="./assets/images/ach.gif" width="428" height="275" alt="" [priority]="true" />
                    </div>
                  }
                  @if (isNew) {
                    <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                      <p-checkbox binary="true" label="Save this Payment Method for future use" formControlName="oneTimeOnly"></p-checkbox>
                    </div>
                  }
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="p-grid p-fluid">
                  <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                    <h3>Billing Address</h3>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 input-container">
                    <div class="p-mt-1 switch-container">
                      <p-inputSwitch class="p-ml-4" formControlName="useAddress" (click)="applyAddress(this.mainForm.controls.useAddress.value)" tabindex="0"></p-inputSwitch>
                      <div class="input-switch-label p-ml-2">Use my primary address</div>
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 input-container">
                    @if (addressData.Ready) {
                      <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [showCounty]="false"
                                        [required]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
                    } @else {
                      <app-address-form [incomingAddress]="addressData" [canTabWrite]="false" [showCounty]="false"
                                        [required]="true"></app-address-form>
                    }
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <p-divider class="dark-divider"></p-divider>
                  </div>
                  <div class="col-md-6 col-sm-6 col-xs-12 input-container mask-container">
                    <label>Phone #</label>
                    <p-inputMask type="text" pInputText formControlName="primaryPhoneNumber" [className]="'form-input no-border'" mask="(999) 999-9999"
                    unmask="true"></p-inputMask>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12 input-container">
                    <label>E-mail</label>
                    <input type="text" pInputText  formControlName="primaryEmail">
                    @if (mainForm.controls.primaryEmail.invalid || mainForm.controls.primaryEmail.touched) {
                      <div class="p-error">
                        @if (mainForm.controls.primaryEmail?.errors) {
                          <div>E-mail must be a valid e-mail address</div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div class="p-grid p-fluid margin-top-10">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="p-grid p-fluid">
                  <div class="col-md-6 col-sm-6 col-xs-12"></div>
                  <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
                    <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                    (click)="cancel()"></button>
                    <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                    (click)="reset()" [disabled]="!mainForm.dirty"></button>
                    <button pButton label="Confirm" class="p-button-info p-mr-2" icon="pi pi-check" pTooltip="Confirm" tooltipPosition="bottom" [className]="'inline-button'"
                    (click)="confirmPayment()" [disabled]="this.mainForm.invalid || !mainForm.dirty || totalToBePaid <= 0"></button>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    }
    @if (confirm) {
      <div>
        <p-card header="PAYMENT SUMMARY CONFIRMATION" [styleClass]="'list-card'">
          <div class="p-grid p-fluid">
            @if (paymentData) {
              <div class="col-md-6 col-sm-12 col-xs-12">
                <p-card header="{{paymentData.OrganizationName.toUpperCase()}} - PER CAPITA TAX" [styleClass]="'list-card'">
                  <div class="p-grid p-fluid">
                    <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10">
                      <div class="p-grid p-fluid">
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <strong>Last Invoice Date</strong>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          {{paymentData.LastInvoiceDate_D | date:'shortDate'}}
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <strong>Due Date</strong>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          {{paymentData.DueDate_D | date:'shortDate'}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10">
                      <div class="p-grid p-fluid">
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <strong>Last Invoice Amount</strong>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          {{paymentData.LastInvoiceAmount_N | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <strong>Overdue Amount</strong>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          {{paymentData.OverDueAmount_N | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <strong>*Returned Payments</strong>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          {{paymentData.ReturnedPayments_N | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <strong>New Adjustments</strong>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          {{paymentData.NewAdjustments_N | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <strong>New Payments</strong>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          {{paymentData.NewPayments_N | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                          <strong>Current Balance</strong>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                          {{paymentData.CurrentBalance_N | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                      </div>
                    </div>
                  </div>
                </p-card>
              </div>
            }
            <div class="col-md-6 col-sm-12 col-xs-12">
              <p-card header="PAYMENT SUMMARY" [styleClass]="'list-card'">
                @if (payInFull) {
                  <div class="p-grid p-fluid">
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>Total Current Balance</strong>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.CurrentBalance_N | currency:'USD':'symbol':'1.2-2'}}
                    </div>
                  </div>
                }
                @if (!payInFull) {
                  <div class="p-grid p-fluid">
                    <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                      <strong>Total Overdue Balance</strong><br><span class="p-ml-5"><strong>(Including any Returned Payments)</strong></span>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                      {{paymentData.RemainingOverDueAmount_N | currency:'USD':'symbol':'1.2-2'}}
                    </div>
                  </div>
                }
                <p-divider class="dark-divider"></p-divider>
                <div class="p-grid p-fluid">
                  <div class="col-md-8 col-sm-8 col-xs-12 margin-top-10">
                    <strong>Total Amount</strong>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-12 margin-top-10">
                    <strong>{{totalToBePaid | currency:'USD':'symbol':'1.2-2'}}</strong>
                  </div>
                </div>
              </p-card>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 margin-top-10">
              <button pButton label="Adjust" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-angle-left" pTooltip="Adjust" tooltipPosition="bottom"
              (click)="confirm = false;"></button>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons margin-top-10">
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
              (click)="cancel()" [disabled]="!mainForm.dirty"></button>
              @if (!isSaving) {
                <button pButton label="Make Payment" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Make Payment" tooltipPosition="bottom" [className]="'inline-button'"
                (click)="makePayment()" [disabled]="this.mainForm.invalid || !mainForm.dirty || totalToBePaid <= 0"></button>
              } @else {
                <button pButton label="Make Payment" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          </div>
        </p-card>
      </div>
    }
  </form>
}
