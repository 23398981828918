<div class="p-grid p-fluid p-mb-4 margin-top-0">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <h3>Matches have been found with the {{ piiData.MatchType }} matching {{ value }}. Please use the options below to
      help solve this issue. </h3>
  </div>
  @for (match of piiData.MatchList; track match; let i = $index) {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="match-header"><strong>Match {{ i + 1 }}</strong></div>
      <div class="match-row p-mb-2">
        @if (match.HasAccess) {
          {{ match.FirstName }} {{ match.LastName }} (
          @for (context of match.ContextList; track match; let last = $last) {
            <span class="contexts">{{ context.Context }}@if (!last) {,} </span>
          }
          ) - {{ match.ContextList[0].OrganizationName }} <span
            class="view-now pointer"
            (click)="goToProfile(match.PersonID)"> View Now</span>
        } @else {
          (
          @for (context of match.ContextList; track match; let last = $last) {
            <span class="contexts">{{ context.Context }}@if (!last) {, } </span>
          }
          ) in {{ match.ContextList[0].OrganizationName }} | Not accessible. Please contact an Administrator for assistance.
        }
      </div>
    </div>
  }
</div>
