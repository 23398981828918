import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { UserRosterPiiAttestationModel } from '../models/user-roster-pii-attestation.model';

@Injectable({
  providedIn: 'root',
})
export class UserRosterPiiAttestationService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getUserPiiAttestation(): Observable<UserRosterPiiAttestationModel> {
    return this.http.get<UserRosterPiiAttestationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/attestation`);
  }

  updateUserPiiAttestation(modelData: UserRosterPiiAttestationModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/profile/attestation`, modelData);
  }

  getUserRosterPiiAttestation(userPersonId: number): Observable<UserRosterPiiAttestationModel> {
    return this.http.get<UserRosterPiiAttestationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${userPersonId}/attestation`);
  }

  updateUserRosterPiiAttestation(userPersonId: number, modelData: UserRosterPiiAttestationModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${userPersonId}/attestation`, modelData);
  }
}
