<form [formGroup]="mainForm">
  <div class="p-grid p-fluid margin-top-15">
    @if (!toOrganizationId) {
      <div class="col-md-3 col-sm-12 col-xs-12">
        <app-select (change)="updateTotal()" class="full-width" [inputObj]="inputObjOrganizationTo" (emitData)="setOrganizationDataTo($event)">
        </app-select>
      </div>
    }
    @if (!fromOrganizationId) {
      <div class="col-md-3 col-sm-12 col-xs-12">
        <app-select (change)="updateTotal()" class="full-width" [inputObj]="inputObjOrganizationFrom" (emitData)="setOrganizationDataFrom($event)">
        </app-select>
      </div>
    }
    <div class="col-md-3 col-sm-12 col-xs-12">
      <app-select (change)="updateTotal()" class="full-width" [inputObj]="inputObjPaymentMethod" (emitData)="setPaymentMethodData($event)">
      </app-select>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12">
      <label>Date <sup class="req">*</sup></label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="date" appendTo="body" placeholder="mm/dd/yyyy" [showIcon]="true" [yearNavigator]="true" yearRange="1990:2025" [styleClass]="'p-mt-2'" tabindex="0"></p-calendar>
      @if ((mainForm.controls.date.dirty || mainForm.controls.date.touched) &&
        mainForm.controls.date.errors?.required) {
        <span class="error">'Date' must be provided</span>
      }
    </div>
  </div>
  <div class="p-grid p-fluid">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <p>You may split this payment one or more ways as necessary.</p>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
    <ng-container formArrayName="formFields">
      @for (fieldsForm of formFields.controls; track fieldsForm; let i = $index) {
        <div [formGroupName]="i">
          <div class="p-grid p-fluid">
            <div class="col-md-3 col-sm-12 col-xs-12 p-mb-3">
              <app-select (change)="updateTotal()"  class="full-width" [inputObj]="inputBillingAdjustments[i]" (emitData)="setBillingAdjustments($event, i)">
              </app-select>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 p-mb-3">
              <label class="justify-right">Amount <sup class="req">*</sup></label>
              <!--              <p-inputNumber formControlName="amount" (focusout)="updateTotal()" (change)="updateTotal()" (onInput)="updateTotal()" (blur)="updateTotal()" (focus)="updateTotal()" (onFocus)="updateTotal()" (onBlur)="updateTotal()" (onKeyDown)="updateTotal()" (keypress)="updateTotal()" (keydown)="updateTotal()" (keyup)="updateTotal()" (input)="updateTotal()" class="full-width"  mode="currency" currency="USD" locale="en-US"></p-inputNumber>-->
              <app-currency-input [initValue]="fieldsForm.get('amount').value" (emitData)="fieldsForm.get('amount').setValue($event); updateTotal()" ></app-currency-input>
              @if ((fieldsForm.get('amount').dirty || fieldsForm.get('amount').touched) &&
                fieldsForm.get('amount').errors?.required) {
                <span class="error">'Amount' must be provided</span>
              }
            </div>
            @if (canWrite) {
              <div class="col-md-6 col-sm-12 col-xs-12 p-mb-2 grid-container-buttons-right float-right-buttons float-right">
                @if (formFields.controls.length > 1) {
                  <button pButton icon="pi pi-trash" (click)="removeFields(i)" class="p-button-secondary margin-top-20" pTooltip="Delete" tooltipPosition="bottom"></button>
                }
              </div>
            }
          </div>
        </div>
      }
    </ng-container>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
    <div class="p-grid p-fluid">
      <div class="col-md-3 col-sm-12 col-xs-12 margin-top-25 no-padding">
        @if (canWrite) {
          <button pButton label="Add" class="p-button-info p-mt-5" icon="pi pi-plus" pTooltip="Add" tooltipPosition="bottom"
          [className]="'inline-button'" (click)="addPayment()"></button>
        }
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 margin-top-10">
        <label class="justify-right">Total</label>
        <!--        <p-inputNumber formControlName="total" class="full-width"  mode="currency" currency="USD" locale="en-US"></p-inputNumber>-->
        <app-currency-input [disabled]="true" [initValue]="formTotal" ></app-currency-input>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 no-padding margin-top-10">
    <label>Memo</label>
    <input formControlName="memo" type="text" class="full-width" pInputText tabindex="0">
  </div>
  @if (canWrite) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <div class="p-grid p-fluid">
        <div class="col-md-12 col-sm-12 col-xs-12 tab-form-buttons margin-top-10 margin-bottom-5">
          @if (showCancel) {
            <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
            (click)="cancel()"></button>
          }
          <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
          (click)="reset()" [disabled]="!mainForm.dirty"></button>
          @if (!isSaving) {
            <button pButton label="Record Payment" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Record Payment" tooltipPosition="bottom"
              (click)="processData()" [className]="'inline-button'"
              [disabled]="this.mainForm.invalid || !mainForm.dirty"
            ></button>
          } @else {
            <button pButton label="Record Payment" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    </div>
  }
</form>
