<form class="margin-top-10" [formGroup]="mainForm">
  <p-divider class="dark-divider"></p-divider>
  <div class="p-grid p-fluid">
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjProgram" (emitData)="getProgramData($event)" ></app-select>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjStatus" (emitData)="getStatusData($event)" ></app-select>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjTrainingCenter" (emitData)="getTrainingCenterData($event)" ></app-select>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <label>Start Date</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="startDate" appendTo="body" placeholder="mm/dd/yyyy" [maxDate]="mainForm.controls.endDate.value" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <label>End Date @if (closedStatus) {
        <sup class="req">*</sup>
      }</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="endDate" appendTo="body" placeholder="mm/dd/yyyy" [minDate]="mainForm.controls.startDate.value" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" [disabled]="!closedStatus" tabindex="0"></p-calendar>
      @if ((mainForm.controls.endDate.dirty || mainForm.controls.endDate.touched) &&
        mainForm.controls.endDate.errors?.required) {
        <span class="error">'End Date' must be provided</span>
      }
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjStudentGroup" (emitData)="getStudentGroupData($event)" ></app-select>
    </div>
    @if (!isEdit && hasIncomingCreditFacet) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <button pButton label="Add Incoming Hours" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-plus" pTooltip="Add Hours" tooltipPosition="bottom"
        (click)="openIncomingHours()"></button>
        <button pButton label="Clear Incoming Hours" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Clear Hours" tooltipPosition="bottom"
        (click)="clearIncomingHours()"></button>
      </div>
    }
    @if (!isEdit && studentProgram.FacetTypeList && studentProgram.FacetTypeList.length > 0) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <h3>Adding
          @if (studentProgram.FacetTypeList[0].IncomingHours_N) {
            <span> {{studentProgram.FacetTypeList[0].IncomingHours_N | number:'1.0':'en-US'}} </span>
          } @else {
            <span> 0 </span>
          }
        incoming hour(s) from {{studentProgram.FacetTypeList[0].FacetTypeDescription}}</h3>
        @if (studentProgram.FacetTypeList.length > 1) {
          <h3>Adding
            @if (studentProgram.FacetTypeList[1].IncomingHours_N) {
              <span> {{studentProgram.FacetTypeList[1].IncomingHours_N | number:'1.0':'en-US'}} </span>
            } @else {
              <span> 0 </span>
            }
          incoming hour(s) from {{studentProgram.FacetTypeList[1].FacetTypeDescription}}</h3>
        }
      </div>
    }
    <!--    <div class="col-md-4 col-sm-6 col-xs-12 input-container">-->
    <!--      <label>Identifier</label>-->
    <!--      <input type="text" pInputText  formControlName="identifier">-->
  <!--    </div>-->
  <p-divider class="full-width" ></p-divider>
  @if (selectedStudent) {
    <div class="p-grid p-fluid full-width">
      <div class="col-md-12 col-sm-12 col-xs-12 summary-tabs">
        <p-tabView>
          <p-tabPanel [cache]="true" header="Hour Summaries for All Periods">
            <ng-template pTemplate="content">
              @if (studentProgram && isEdit) {
                <div class="p-grid p-fluid">
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10" >
                    <div class="p-fluid p-grid">
                      <div class="col-md-4 col-sm-4 col-xs-12">
                        <span><b>Amount: </b></span><span>{{selectedStudent?.RateAmount | currency}}</span>
                      </div>
                      <div class="col-md-4 col-sm-4 col-xs-12">
                        <!--                        <span><b>Amount: </b></span><span>Amount Here</span>-->
                      </div>
                      <div class="col-md-4 col-sm-4 col-xs-12">
                        <span><b>Rate Level: </b></span><span>{{selectedStudent?.RateLevel}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-grid p-fluid">
                  @for (facets of studentProgram.FacetTypeList; track facets) {
                    <div class="col-md-6 col-sm-6 col-xs-6 margin-top-20">
                      <div class="p-grid p-fluid">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <h3 style="margin-block-end:3px;">{{facets.FacetTypeDescription}}</h3>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <b>Required Hours:</b> {{facets.RequiredHours_N | number:'1.0':'en-US'}}
                        </div>
                        @if (facets.FacetTypeDescription.toLowerCase().includes('ojl')) {
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <b>Approved Hours:</b> {{facets.ApprovedHours_N}}
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12" >
                            <b>Waiting on Approval Hours:</b> {{facets.SubmittedHours_N}}
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12" >
                            <b>Rejected Hours:</b> {{facets.RejectedHours_N}}
                          </div>
                        }
                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <b>Recorded Hours:</b> {{facets.RecordedHours_N | number:'1.0':'en-US'}}
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <b>Completed:</b> @if (facets.Completed === true ? 'Yes' : 'No') {
                          <span></span>
                        }
                      </div>
                      <!--                      <div class="col-md-12 col-sm-12 col-xs-12 no-padding">-->
                      <!--                        <button pButton label="Hours Summary" class="p-button-info no-margin" [className]="'inline-button'" pTooltip="Summary" tooltipPosition="bottom" (click)="getHoursDialog(facets)"></button>-->
                    <!--                      </div>-->
                  </div>
                </div>
              }
            </div>
          }
          <div class="col-md-12 col-sm-12 col-xs-12 percent-container margin-top-10">
            <div class="p-fluid p-grid">
              <div class="col-md-6 col-sm-6 col-xs-12 pointer" (click)="getDetailData('OJL', selectedStudent?.ProgramStudentID, studentInfo?.FirstName + ' ' + studentInfo?.LastName)">
                <div class="p-fluid p-grid">
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                    <b>Hours Completed for OJL (Click for Details)</b>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">
                    <p-progressBar [value]="selectedStudent?.FacetType1Percentage | number : '1.2-2'"></p-progressBar>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">
                    <b>{{selectedStudent?.FacetType1RecordedHours_N | number}} Recorded of {{selectedStudent?.FacetType1RequiredHours_N | number}} Required</b>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 pointer" (click)="getDetailData('RI', selectedStudent?.ProgramStudentID, studentInfo?.FirstName + ' ' + studentInfo?.LastName)">
                <div class="p-fluid p-grid">
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
                    <b>Hours Completed for RI (Click for Details)</b>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">
                    <p-progressBar [value]="selectedStudent?.FacetType2Percentage | number : '1.2-2'"></p-progressBar>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5">
                    <b>{{selectedStudent?.FacetType2RecordedHours_N | number}} Recorded of {{selectedStudent?.FacetType2RequiredHours_N | number}} Required</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-tabPanel>
      @for (facets of studentProgram.FacetTypeList; track facets) {
        @if (showPeriodTabs) {
          <p-tabPanel [cache]="false" [header]="'Period Hours for ' + facets.FacetTypeEnumerator">
            <ng-template pTemplate="content">
              <app-program-student-hours-summary [data]="outgoingData" [isPage]="true" (updateData)="getHoursTable(facets)"></app-program-student-hours-summary>
            </ng-template>
          </p-tabPanel>
        }
      }
      @if (studentProgram.ProgramHasStipends) {
        <p-tabPanel [cache]="false" header="Stipend History">
          <ng-template pTemplate="content">
            <app-student-stipend-history [programStudentID]="studentProgram.ProgramStudentID"></app-student-stipend-history>
          </ng-template>
        </p-tabPanel>
      }
      <!--            <p-tabPanel *ngIf="studentProgram?.FacetTypeList" [header]="'Period Hours for ' + studentProgram.FacetTypeList[0].FacetTypeEnumerator" [cache]="false" >-->
      <!--              <ng-template pTemplate="content">-->
      <!--                <app-program-student-hours-summary [data]="outgoingData" [isPage]="true"  (onCreate)="getHoursTable(studentProgram.FacetTypeList[0])"></app-program-student-hours-summary>-->
    <!--              </ng-template>-->
  <!--            </p-tabPanel>-->
  <!--          <p-tabPanel *ngIf="studentProgram?.FacetTypeList && studentProgram?.FacetTypeList[1]" [header]="'Period Hours for ' + studentProgram.FacetTypeList[1].FacetTypeEnumerator" [cache]="false" >-->
  <!--            <ng-template pTemplate="content">-->
  <!--              <app-program-student-hours-summary [data]="outgoingData" [isPage]="true" (onCreate)="getHoursTable(studentProgram.FacetTypeList[1])"></app-program-student-hours-summary>-->
<!--            </ng-template>-->
<!--          </p-tabPanel>-->
</p-tabView>
</div>
</div>
}
@if (canTabWrite) {
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="p-grid p-fluid">
      <div class="col-md-6 col-sm-6 col-xs-12"></div>
      <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
        @if (isEdit) {
          <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
          (click)="cancel()" [disabled]="!mainForm.dirty"></button>
        } @else {
          <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
          (click)="cancel()"></button>
        }
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
            (click)="processData()" [className]="'inline-button'"
          [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
        } @else {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  </div>
}
</div>
</form>
