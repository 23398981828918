import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Location} from '@angular/common';
import {UserProfileModel, UserProfilePhotoDataUrlModel} from '../models/user-profile.model';
import {UserProfileService} from '../services/user-profile.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {WebcamComponent} from '../shared/components/webcam/webcam.component';
import {DialogService} from 'primeng/dynamicdialog';
import {ResourcesService} from '../services/resources.service';
import {ContextService} from '../services/context.service';
import {GenericSelectModel} from '../models/generic-select.model';
import {LookupsDataService} from '../services/lookups-data.service';
import {UpdateUserAccountSecurityQuestionsModel, UserAccountChangePasswordModel} from '../models/user-account.model';
import {UserAccountService} from '../services/user-account.service';
import {ConfirmValidationUtility} from '../shared/utilities/confirm-validation.utility';
import {TwoFactorAuthComponent} from '../public/two-factor-auth/two-factor-auth.component';
import {InterfaceConfigListModel} from '../models/interface-config.model';
import {InterfaceConfigService} from '../services/interface-config.service';
import {InterfaceObjectsService} from '../services/interface-objects.service';
import {InterfaceObjectHelpService} from '../services/interface-object-help.service';
import {tabsPermissionsUtility} from '../shared/utilities/tabs-permissions.utility';
import {SignaturePadComponent} from '../shared/components/signature-pad/signature-pad.component';

export class viewTypesModel {
  name: string;
  value: string;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit, OnDestroy {
  settingsForm: FormGroup;
  changePasswordForm: FormGroup;
  securityQuestionsForm: FormGroup;
  viewTypes: viewTypesModel[] = [];
  pageRowCount: viewTypesModel[] = [];
  indicator: viewTypesModel[] = [];
  filterDefault: viewTypesModel[] = [];
  helpDefault: viewTypesModel[] = [];
  saveReminder: viewTypesModel[] = [];
  rememberFilters: viewTypesModel[] = [];
  dymoInstalled: viewTypesModel[] = [];
  twoFactorAuth: boolean;
  inputObjMemberRosterQuery: GenericSelectModel;
  inputObjTrainingRosterQuery: GenericSelectModel;
  setMemberRosterQueryId: number;
  setTrainingRosterQueryId: number;
  blobToken: string;
  PersonID: number;
  personInfo: UserProfileModel;
  fd: FormData = new FormData();
  passwordCurrentType = 'password';
  passwordType = 'password';
  passwordConfirmType = 'password';
  isLoading: boolean = false;
  passwordCurrentTypeSQ = 'password';
  inputObjQuestion1: GenericSelectModel;
  setQuestion1Id: number = null;
  inputObjQuestion2: GenericSelectModel;
  setQuestion2Id: number = null;
  inputObjQuestion3: GenericSelectModel;
  setQuestion3Id: number = null;
  showQuestion1Id: boolean = true;
  showQuestion2Id: boolean = true;
  showQuestion3Id: boolean = true;
  isLoadingSQ: boolean = false;
  isMobile: boolean = false;
  isSuper: boolean = false;
  headerImageName: string;
  existingMessage: boolean = false;
  primaryPhoneNumber: string;
  twoFactorLabel: string = 'Verify';
  userPreferences: InterfaceConfigListModel[] = [];
  landingPageOptions: viewTypesModel[] = [];
  @Input() isPage: boolean;
  signatureImage: string;
  imageType: string;
  protected readonly _tabsPermissionsUtility = tabsPermissionsUtility;
  private ngUnsubscribe = new Subject();

  constructor(private fb: FormBuilder, private messageService: MessageService,
              private location: Location, private userProfileService: UserProfileService, private interfaceConfigService: InterfaceConfigService,
              private dialogService: DialogService, private resourcesService: ResourcesService,
              private confirmationService: ConfirmationService, private contextService: ContextService,
              private lookupsDataService: LookupsDataService, private userAccountService: UserAccountService,
              private confirmValidationUtility: ConfirmValidationUtility, private interfaceObjectService: InterfaceObjectsService,
              private interfaceObjectHelpService: InterfaceObjectHelpService) {
    this.settingsForm = this.fb.group({
      viewType: new FormControl(null),
      pageRowCount: new FormControl(null),
      indicator: new FormControl(null),
      filterDefault: new FormControl(null),
      helpDefault: new FormControl(null),
      saveReminder: new FormControl(null),
      dymoInstalled: new FormControl(null),
      rememberFilters: new FormControl('Yes'),
      landingPage: new FormControl('Dashboard'),
      memberDistributionList: new FormControl(0),
      trainingDistributionList: new FormControl(0),
    });
    this.changePasswordForm = this.fb.group({
      passwordCurrent: new FormControl(null, Validators.required),
      showPasswordCurrent: new FormControl(false),
      password: new FormControl(null, [Validators.required,
        this.regexValidator(new RegExp('^.*[A-Za-z0-9$@#!%*?&].{9,}'), {length: true}),
        this.regexValidator(new RegExp('^.*[a-z]'), {lowercase: true}),
        this.regexValidator(new RegExp('^.*[A-Z]'), {uppercase: true}),
        this.regexValidator(new RegExp('^.*[0-9]'), {number: true}),
        this.regexValidator(new RegExp('^.*[$@#!%*?&]'), {special: true}),
        this.regexValidator(new RegExp('^(?!.*([a-zA-Z0-9$@#!%*?&])\\1{2,})'), {repeats: true})
      ]),
      // password: new FormControl('', [Validators.required,
      //   Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])(?!.*([a-zA-Z0-9])\\1{2,})[A-Za-zd$@$!%*?&].{9,}')]),
      showPassword: new FormControl(false),
      passwordConfirm: new FormControl(null, Validators.required),
      showPasswordConfirm: new FormControl(false)
    }, {
      validator: [this.confirmValidationUtility.ConfirmedValidator('password', 'passwordConfirm'),
        this.customValidator('password')]
    });
    this.getProfileSignature();
    this.securityQuestionsForm = this.fb.group({
      passwordCurrent: new FormControl('', Validators.required),
      showPasswordCurrent: new FormControl(false),
      question1: new FormControl(null, Validators.required),
      answer1: new FormControl({
        value: null,
        disabled: true
      }, [Validators.required, Validators.minLength(4), Validators.maxLength(100)]),
      question2: new FormControl(null, Validators.required),
      answer2: new FormControl({
        value: null,
        disabled: true
      }, [Validators.required, Validators.minLength(4), Validators.maxLength(100)]),
      question3: new FormControl(null, Validators.required),
      answer3: new FormControl({
        value: null,
        disabled: true
      }, [Validators.required, Validators.minLength(4), Validators.maxLength(100)])
    });
  }

  ngOnInit(): void {
    this.get2FAData();
    this.initMemberRosterQuery(false, this.contextService.contextObject.RootOrganizeOrgID);
    this.initTrainingRosterQuery(false, this.contextService.contextObject.RootTrainingOrgID);
    this.isSuper = this.contextService.contextObject.SuperUser;
    this.isMobile = this.contextService.contextObject.isMobile;
    this.updateCachedData();
    this.viewTypes.push(
      {
        name: 'List',
        value: 'List'
      },
      {
        name: 'Grid',
        value: 'Grid'
      }
    );

    this.landingPageOptions.push(
      {
        name: 'Dashboard',
        value: 'Dashboard'
      },
      {
        name: 'Student Roster',
        value: 'StudentRoster'
      },
      {
        name: 'Roster',
        value: 'Roster'
      },
    );

    this.pageRowCount.push(
      {
        name: '25',
        value: '25'
      },
      {
        name: '50',
        value: '50'
      },
      {
        name: '100',
        value: '100'
      },
      {
        name: '250',
        value: '250'
      },
      {
        name: '500',
        value: '500'
      }
    );

    this.indicator.push(
      {
        name: 'Spinner',
        value: 'Spinner'
      },
      {
        name: 'Progress Bar',
        value: 'Progress Bar'
      }
    );

    this.filterDefault.push(
      {
        name: 'Show',
        value: 'Show'
      },
      {
        name: 'Hide',
        value: 'Hide'
      }
    );

    this.helpDefault.push(
      {
        name: 'Show',
        value: 'Show'
      },
      {
        name: 'Hide',
        value: 'Hide'
      }
    );

    this.saveReminder.push(
      {
        name: 'Yes',
        value: 'Yes'
      },
      {
        name: 'No',
        value: 'No'
      }
    );

    this.rememberFilters.push(
      {
        name: 'No',
        value: 'No'
      },
      {
        name: 'Yes',
        value: 'Yes'
      },
    );

    this.dymoInstalled.push(
      {
        name: 'No',
        value: 'No'
      },
      {
        name: 'Yes',
        value: 'Yes'
      }
    );
    this.getUserPreferences();
    this.blobToken = this.contextService.contextObject.blobToken;
    this.getUserProfile();
    this.initQuestion1(false);
    this.initQuestion2(false);
    this.initQuestion3(false);
    this.onChanges();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.settingsForm.valueChanges.subscribe({
        next: () => {
          if (this.settingsForm.valid && this.settingsForm.dirty && !this.existingMessage) {
            this.existingMessage = true;
            this.openSettingsDialog();
          }
        }
      });
      this.securityQuestionsForm.valueChanges.subscribe({
        next: () => {
          if (this.securityQuestionsForm.valid && this.securityQuestionsForm.dirty && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }

    if (this.contextService.contextObject.Administrator) {
      this.landingPageOptions.push({
          name: 'Widget User Dashboard',
          value: 'WidgetUserDashboard'
        },
        {
          name: 'Widget Admin Dashboard',
          value: 'WidgetAdminDashboard'
        },);
    }
  }

  getUserPreferences(): void {
    this.interfaceConfigService.getUserInterfaceConfigList().pipe(take(1)).subscribe({
      next: (res) => {
        this.userPreferences = res;
        // view type
        if (this.userPreferences.find(x => x.Key === 'Global_ViewType')) {
          this.settingsForm.get('viewType').setValue(this.userPreferences.find(x => x.Key === 'Global_ViewType')?.Value);
        } else {
          this.settingsForm.get('viewType').setValue('List');
        }

        // Roster Default Dist List
        if (this.userPreferences.find(x => x.Key === 'Roster_DefaultDistList')) {
          this.setMemberRosterQueryId = +this.userPreferences.find(x => x.Key === 'Roster_DefaultDistList')?.Value;
          this.initMemberRosterQuery(false);
        } else {
          this.initMemberRosterQuery(false);
        }

        // Student Default Dist List
        if (this.userPreferences.find(x => x.Key === 'Student_DefaultDistList')) {
          this.setTrainingRosterQueryId = +this.userPreferences.find(x => x.Key === 'Student_DefaultDistList')?.Value;
          this.initTrainingRosterQuery(false);
        } else {
          this.initTrainingRosterQuery(false);
        }

        //Row Count
        if (this.userPreferences.find(x => x.Key === 'Global_PageRowCount')) {
          this.settingsForm.get('pageRowCount').setValue(this.userPreferences.find(x => x.Key === 'Global_PageRowCount')?.Value);
        } else {
          this.settingsForm.get('pageRowCount').setValue('25');
        }

        // Loading Indicator
        if (this.userPreferences.find(x => x.Key === 'Global_LoadingIndicator')) {
          this.settingsForm.get('indicator').setValue(this.userPreferences.find(x => x.Key === 'Global_LoadingIndicator')?.Value);
        } else {
          this.settingsForm.get('indicator').setValue('Spinner');
        }

        // Show/Hide Filter
        if (this.userPreferences.find(x => x.Key === 'Global_ShowFilterDefault')) {
          this.settingsForm.get('filterDefault').setValue(this.userPreferences.find(x => x.Key === 'Global_ShowFilterDefault')?.Value);
        } else {
          this.settingsForm.get('filterDefault').setValue('Show');
        }

        // Landing Page
        if (this.userPreferences.find(x => x.Key === 'Global_LandingPage')) {
          this.settingsForm.get('landingPage').setValue(this.userPreferences.find(x => x.Key === 'Global_LandingPage')?.Value);
        } else {
          this.settingsForm.get('landingPage').setValue('Dashboard');
        }

        // Student landing page
        // if (this.userPreferences.find(x => x.Key === 'Global_StudentRosterLandingPage')) {
        //   this.settingsForm.get('landingPage').setValue(this.userPreferences.find(x => x.Key === 'Global_StudentRosterLandingPage')?.Value);
        // } else {
        //   this.settingsForm.get('landingPage').setValue('No');
        // }

        // Show Help
        if (this.userPreferences.find(x => x.Key === 'Global_ShowHelpDefault')) {
          this.settingsForm.get('helpDefault').setValue(this.userPreferences.find(x => x.Key === 'Global_ShowHelpDefault')?.Value);
        } else {
          this.settingsForm.get('helpDefault').setValue('Hide');
        }

        //save reminder
        if (this.userPreferences.find(x => x.Key === 'Global_SaveReminder')) {
          this.settingsForm.get('saveReminder').setValue(this.userPreferences.find(x => x.Key === 'Global_SaveReminder')?.Value);
        } else {
          this.settingsForm.get('saveReminder').setValue('Yes');
        }

        // Remember Filters
        if (this.userPreferences.find(x => x.Key === 'Global_RememberFilters')) {
          this.settingsForm.get('rememberFilters').setValue(this.userPreferences.find(x => x.Key === 'Global_RememberFilters')?.Value);
        } else {
          this.settingsForm.get('rememberFilters').setValue('No');
        }

        // Dymo Installed
        if (this.userPreferences.find(x => x.Key === 'Global_DymoInstalled')) {
          this.settingsForm.get('dymoInstalled').setValue(this.userPreferences.find(x => x.Key === 'Global_DymoInstalled')?.Value);
        } else {
          this.settingsForm.get('dymoInstalled').setValue('No');
        }

        //Turn on save reminders for setting page if value changes.
        if (this.userPreferences.find(x => x.Key === 'Global_SaveReminder') && this.userPreferences.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
          this.settingsForm.valueChanges.subscribe({
            next: () => {
              if (this.settingsForm.valid && this.settingsForm.dirty && !this.existingMessage) {
                this.existingMessage = true;
                this.openSettingsDialog();
              }
            }
          });
          this.securityQuestionsForm.valueChanges.subscribe({
            next: () => {
              if (this.securityQuestionsForm.valid && this.securityQuestionsForm.dirty && !this.existingMessage) {
                this.existingMessage = true;
                this.openDialog();
              }
            }
          });
        }
      }
    });
  }

  getProfileSignature() {
    this.userProfileService.getUserProfileFile('signature')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.length > 0) {
          this.signatureImage = res[0].Url + this.blobToken;

        } else {
          this.signatureImage = null;
        }
        console.log(this.signatureImage);
      });
  }

  addSignature() {
    const ref = this.dialogService.open(SignaturePadComponent, {
      data: {},
      header: 'Add/Edit Signature',
      width: this.contextService.contextObject.isMobile ? '100%' : '600px',
    });
    ref.onClose.subscribe(res => {
      if (res !== null) {
        this.uploadImage(res, 'signature');
      }
    });
  }

  initMemberRosterQuery(disable: boolean, organizationId?: number): void {
    this.inputObjMemberRosterQuery = {
      labelText: 'Default Member Distribution List',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setMemberRosterQueryId,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getRosterQueriesLookupData(organizationId, 'membership', true, this.setMemberRosterQueryId).then((lookupData) => {
      this.inputObjMemberRosterQuery.data = lookupData;
      this.inputObjMemberRosterQuery = Object.assign({}, this.inputObjMemberRosterQuery);
    });
  }

  updateCachedData(): void {
    this.interfaceObjectService.getAppList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          sessionStorage.setItem('pagePermissions', JSON.stringify(res));
        }
      });
    this.interfaceObjectHelpService.getInterfaceObjectHelpKGrid()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          sessionStorage.setItem('getHelp', JSON.stringify(res));
        }
      });
  }

  setMemberRosterQueryData(event: any): void {
    if (event && event[0]) {
      this.setMemberRosterQueryId = event[0].ID;
    } else {
      this.setMemberRosterQueryId = 0;
    }
  }

  initTrainingRosterQuery(disable: boolean, organizationId?: number): void {
    this.inputObjTrainingRosterQuery = {
      labelText: 'Default Training Distribution List',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setTrainingRosterQueryId,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getRosterQueriesLookupData(organizationId, 'training', true, this.setTrainingRosterQueryId).then((lookupData) => {
      this.inputObjTrainingRosterQuery.data = lookupData;
      this.inputObjTrainingRosterQuery = Object.assign({}, this.inputObjTrainingRosterQuery);
    });
  }

  setTrainingRosterQueryData(event: any): void {
    if (event && event[0]) {
      this.setTrainingRosterQueryId = event[0].ID;
    } else {
      this.setTrainingRosterQueryId = 0;
    }
  }

  onChanges(): void {
    this.changePasswordForm.controls.showPasswordCurrent.valueChanges.subscribe({
      next: (val) => {
        this.passwordCurrentType = val === true ? 'text' : 'password';
      }
    });
    this.changePasswordForm.controls.showPassword.valueChanges.subscribe({
      next: (val) => {
        this.passwordType = val === true ? 'text' : 'password';
      }
    });
    this.changePasswordForm.controls.showPasswordConfirm.valueChanges.subscribe({
      next: (val) => {
        this.passwordConfirmType = val === true ? 'text' : 'password';
      }
    });
    this.securityQuestionsForm.controls.showPasswordCurrent.valueChanges.subscribe({
      next: (val) => {
        this.passwordCurrentTypeSQ = val === true ? 'text' : 'password';
      }
    });
  }

  openTwoFactor(): void {
    const ref = this.dialogService.open(TwoFactorAuthComponent, {
      data: {
        isLogin: false,
        twoFactorAuth: !this.twoFactorAuth,
        phoneNumber: this.primaryPhoneNumber
      },
      header: 'Two Factor Authentication',
      width: '70%',
      height: '50%'
    });

    ref.onClose.subscribe({
      next: () => {
        this.get2FAData();
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openSettingsDialog(): void {
    this.confirmationService.confirm({
      key: 'settignsFormChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Settings have been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.saveSettings();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Security Questions have been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.updateSecurityQuestions();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  getUserProfile(): void {
    this.userProfileService.getUserProfile()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.personInfo = res;
          this.PersonID = res.PersonID;
        }
      });
  }

  saveSettings(): void {
    this.userPreferences = [];
    // view type
    this.userPreferences.push({Key: 'Global_ViewType', Value: this.settingsForm.get('viewType').value});
    // page row count default
    if (this.settingsForm.get('pageRowCount').value !== '--') {
      this.userPreferences.push({Key: 'Global_PageRowCount', Value: this.settingsForm.get('pageRowCount').value});
    }
    // indicator default
    this.userPreferences.push({Key: 'Global_LoadingIndicator', Value: this.settingsForm.get('indicator').value});
    // filter default
    this.userPreferences.push({
      Key: 'Global_ShowFilterDefault',
      Value: this.settingsForm.get('filterDefault').value
    });
    // help default
    this.userPreferences.push({Key: 'Global_ShowHelpDefault', Value: this.settingsForm.get('helpDefault').value});
    // save reminder
    this.userPreferences.push({Key: 'Global_SaveReminder', Value: this.settingsForm.get('saveReminder').value});
    //save filters
    this.userPreferences.push({
      Key: 'Global_RememberFilters',
      Value: this.settingsForm.get('rememberFilters').value
    });
    // roster landing page
    // this.userPreferences.push({
    //   Key: 'Global_StudentRosterLandingPage',
    //   Value: this.settingsForm.get('landingPage').value
    // });
    // roster landing page
    this.userPreferences.push({
      Key: 'Global_LandingPage',
      Value: this.settingsForm.get('landingPage').value
    });
    // dymo installed
    this.userPreferences.push({Key: 'Global_DymoInstalled', Value: this.settingsForm.get('dymoInstalled').value});
    //default Roster Dist List
    if (this.setMemberRosterQueryId && this.setMemberRosterQueryId !== 0) {
      this.userPreferences.push({Key: 'Roster_DefaultDistList', Value: this.setMemberRosterQueryId.toString()});
    }
    //default Student Dist List
    if (this.setTrainingRosterQueryId && this.setTrainingRosterQueryId !== 0) {
      this.userPreferences.push({
        Key: 'Student_DefaultDistList',
        Value: this.setTrainingRosterQueryId.toString()
      });
    }
    this.interfaceConfigService.updateUserInterfaceAll(this.userPreferences).pipe(take(1)).subscribe({
      next: () => {
        const context = this.contextService.contextObject;
        this.interfaceConfigService.getUserInterfaceConfigList().pipe(take(1)).subscribe({
          next: (result) => {
            context.UserPreferences = result;
            this.contextService.contextObject = context;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Settings have been updated successfully'
            });
          }
        });
      }
    });

  }

  back(): void {
    this.location.back();
  }

  openWebCam(): void {
    const imageWidth = this.contextService.contextObject.isMobile ? '90%' : '75%';
    const ref = this.dialogService.open(WebcamComponent, {
      data: {},
      header: 'Capture your profile picture',
      width: imageWidth,
      height: '70%'
    });

    ref.onClose.subscribe({
      next: (image: UserProfilePhotoDataUrlModel) => {
        if (image) {
          this.userProfileService.updateUserProfilePhotoUrl(image)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'Profile image has been updated'
                });
                this.getUserProfile();
              }
            });
        }
      }
    });
  }

  deleteImage(type): void {
    this.confirmationService.confirm({
      acceptLabel: 'Ok',
      rejectVisible: false,
      message: 'The image will be removed. Are you sure this is what you want to do?',
      icon: 'pi pi-exclamation-circle',
      accept: () => {
        this.resourcesService.deleteResource(type, this.PersonID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.getUserProfile();
              this.getProfileSignature();
            }, error: (e) => {
              if (e?.error?.Message) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: e.error.Message.replace('UM: ', ''),
                  life: 5000
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
              }
            }
          });
      }
    });
  }

  uploadImage(event, type): void {

    this.fd = new FormData();
    let byteString;
    this.imageType = type;
    if (type === 'profilephoto') {
      byteString = atob(event.base64.split(',')[1]);
    } else {
      byteString = atob(event.split(',')[1]);
      this.headerImageName = 'signature';
    }

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob
    const bb = new Blob([ab]);
    if (event) {
      this.fd.append('file', bb, this.headerImageName);
      console.log(this.fd);
    }
    // } else {
    //   this.fd = event;
    // }
    if (event.file?.size < 4e+6 || type === 'signature') {
      this.userProfileService.updateUserProfileFile(this.fd, type)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Image was uploaded successfully'
            });
            this.getUserProfile();
            this.getProfileSignature();
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            console.debug(e);
          }
        });
    } else {
      this.messageService.add({
        severity: 'error', summary: 'Error',
        detail: 'File size is too large'
      });
    }
  }


  resetPassword(): void {
    if (this.isLoading === false && !this.changePasswordForm.invalid && this.changePasswordForm.touched) {
      this.isLoading = true;

      const data: UserAccountChangePasswordModel = {
        UserName: this.contextService.contextObject.UserName,
        CurrentPassword: this.changePasswordForm.get('passwordCurrent').value,
        Password: this.changePasswordForm.get('password').value,
        ConfirmPassword: this.changePasswordForm.get('passwordConfirm').value
      };

      this.userAccountService.changePassword(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Your password has been changed.'
            });
            this.changePasswordForm.reset();
            this.isLoading = false;
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
            this.isLoading = false;
          }
        });
    }
  }

  initQuestion1(disable: boolean): void {
    this.inputObjQuestion1 = {
      labelText: 'Question #1',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getSecurityQuestionsLookupData(1).then((lookupData) => {
      this.inputObjQuestion1.data = lookupData;
      this.inputObjQuestion1 = Object.assign({}, this.inputObjQuestion1);
    });
  }

  getQuestion1Data(event: any): void {
    this.securityQuestionsForm.get('answer1').disable();
    if (event && event[0] && event[0].ID) {
      this.setQuestion1Id = event[0].ID;
      this.securityQuestionsForm.get('answer1').enable();
    } else {
      this.setQuestion1Id = null;
    }
    this.securityQuestionsForm.get('question1').setValue(this.setQuestion1Id);
  }

  initQuestion2(disable: boolean): void {
    this.inputObjQuestion2 = {
      labelText: 'Question #2',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getSecurityQuestionsLookupData(2).then((lookupData) => {
      this.inputObjQuestion2.data = lookupData;
      this.inputObjQuestion2 = Object.assign({}, this.inputObjQuestion2);
    });
  }

  get2FAData(): void {
    this.userAccountService.getAccountTfa()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.twoFactorAuth = res.TwoFactorEnabled;
          this.primaryPhoneNumber = res.PhoneNumber;
          if (this.twoFactorAuth) {
            this.twoFactorLabel = 'Disable';
          } else {
            this.twoFactorLabel = 'Verify';
          }
        }
      });
  }

  getQuestion2Data(event: any): void {
    this.securityQuestionsForm.get('answer2').disable();
    if (event && event[0] && event[0].ID) {
      this.setQuestion2Id = event[0].ID;
      this.securityQuestionsForm.get('answer2').enable();
    } else {
      this.setQuestion2Id = null;
    }
    this.securityQuestionsForm.get('question2').setValue(this.setQuestion2Id);
  }

  initQuestion3(disable: boolean): void {
    this.inputObjQuestion3 = {
      labelText: 'Question #3',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getSecurityQuestionsLookupData(3).then((lookupData) => {
      this.inputObjQuestion3.data = lookupData;
      this.inputObjQuestion3 = Object.assign({}, this.inputObjQuestion3);
    });
  }

  getQuestion3Data(event: any): void {
    this.securityQuestionsForm.get('answer3').disable();
    if (event && event[0] && event[0].ID) {
      this.setQuestion3Id = event[0].ID;
      this.securityQuestionsForm.get('answer3').enable();
    } else {
      this.setQuestion3Id = null;
    }
    this.securityQuestionsForm.get('question3').setValue(this.setQuestion3Id);
  }

  updateSecurityQuestions(): void {
    if (this.isLoadingSQ === false && !this.securityQuestionsForm.invalid && this.securityQuestionsForm.touched) {
      this.isLoadingSQ = true;
      const data: UpdateUserAccountSecurityQuestionsModel = {
        CurrentPassword: this.securityQuestionsForm.get('passwordCurrent').value,
        QuestionID1: this.securityQuestionsForm.get('question1').value,
        QuestionResponse1: this.securityQuestionsForm.get('answer1').value,
        QuestionID2: this.securityQuestionsForm.get('question2').value,
        QuestionResponse2: this.securityQuestionsForm.get('answer2').value,
        QuestionID3: this.securityQuestionsForm.get('question3').value,
        QuestionResponse3: this.securityQuestionsForm.get('answer3').value
      };
      this.userAccountService.updateSecurityQuestions(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Your security questions have been updated.'
            });
            this.existingMessage = true;
            this.securityQuestionsForm.reset();
            this.showQuestion1Id = false;
            this.showQuestion2Id = false;
            this.showQuestion3Id = false;
            setTimeout(() => {
              this.showQuestion1Id = true;
              this.showQuestion2Id = true;
              this.showQuestion3Id = true;
            }, 50);
            this.isLoadingSQ = false;
          }, error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Failure',
              detail: 'Your security questions have not been updated. Please try again.'
            });
            this.isLoadingSQ = false;
          }
        });
    }
  }

  customValidator(pswdControlName: string) {
    return (formGroup: FormGroup) => {
      const matchingControl = formGroup.controls[pswdControlName];
      if (matchingControl.errors && !matchingControl.errors?.customValidator) {
        return;
      }
      const control: string = this.contextService.contextObject.UserName;
      if (control) {
        const am: number = control.indexOf('@');
        const username: string = control.substring(0, am);
        const domain: string = control.substring(am + 1, control.length);
        if (matchingControl.value.indexOf(username) > -1 || matchingControl.value.indexOf(domain) > -1) {
          matchingControl.setErrors({customValidator: true});
        } else {
          matchingControl.setErrors(null);
        }
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
