import {Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterViewInit, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LookupModel } from '../../../models/lookups.model';
import { GenericSelectModel } from '../../../models/generic-select.model';

@Component({
  selector: 'app-multi-select-filter-list',
  templateUrl: './multi-select-filter-list.component.html'
})
export class MultiSelectFilterListComponent implements OnInit, OnChanges, AfterViewInit {
  showFilter: boolean = false;
  selectForm: FormGroup;
  selectData: LookupModel[];
  selectedData: LookupModel[] = [];
  virtScroll: boolean;
  selectAll: boolean;
  @Input() inputObj: GenericSelectModel;
  @Input() newValue: any;
  @Input() labelOption: string;
  @Input() valueOption: string;
  @Input() customSelect: string;
  @Output() emitData = new EventEmitter<any>();
  @Output() loadData = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef) {
    this.selectForm = this.formBuilder.group({
      fcnSelect: [null]
    });
  }

  ngOnInit(): void {
    this.loadData.emit();
  }

  // reload if params change
  ngOnChanges(): void {
    if (this.inputObj) {
      this.loadSelect();
      this.inputObj.initSelected?.forEach(item => {
        const tempItem = this.inputObj.data.find(data => data[this.valueOption] === item);
        this.selectedData.push(tempItem);
      });
    }
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  // required to listen to form controls
  get registerFormControl(): any {
    return this.selectForm.controls;
  }

  loadSelect(): void {
    if (this.inputObj.data) {
      this.selectData = this.inputObj.data;
      if(this.inputObj.data.length > 100) {
        this.virtScroll = true;
      }
    }

    if (this.selectData) {
      this.showFilter = Object.keys(this.selectData).length > 10;
      if (this.inputObj.emitChangeOnLoad === undefined) {
        this.emitDataToParent(false);
      } else {
        this.emitDataToParent(this.inputObj.emitChangeOnLoad);
      }
    }
    if (this.inputObj.canWrite === undefined) {
      this.inputObj.canWrite = true;
    }
  }


  onSelectAllChange(event:any) {
    //
   this.selectedData = event.checked ? [...this.selectData] : [];
   this.selectAll = event.checked;
    // console.log(this.selectAll);
  }

  emitDataToParent( emit: boolean, event?): void {
    if (emit) {
      if (this.selectedData.length > 0) {
        this.emitData.emit(this.selectedData);
      } else {
        this.emitData.emit([]);
      }
    }

  }

  protected readonly JSON = JSON;
}
