import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {SharablesService} from '../../../services/sharables.service';
import {MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {CreateMultipleSharableSendEmailModel} from '../../../models/sharables.model';
import {ContextService} from '../../../services/context.service';
import {Subject} from 'rxjs';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {RouteContextUtility} from '../../utilities/route-context.utility';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  context = this.contextService.contextObject;
  mainForm: FormGroup;
  InterfaceObjectEnum: string;
  inputObjOrganization: GenericSelectModel;
  selOrganizationId: number = null;
  inputObjRecipient: GenericSelectModel;
  selRecipientId: number[] = [];
  inputObjCategory: GenericSelectModel;
  selCategoryId: number = null;
  loading: boolean = false;
  isSaving: boolean;
  DBEntity: string;
  DBEntityID: number;
  organizationId: number;
  navigateTo: string;
  routeContext: string;
  alert = {} as CreateMultipleSharableSendEmailModel;
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private sharablesService: SharablesService, private messageService: MessageService,
              private contextService: ContextService, private tenantConfigDataService: TenantConfigDataService,
              public config: DynamicDialogConfig, public ref: DynamicDialogRef,
              private routeContextUtility: RouteContextUtility) {
    this.mainForm = this.formBuilder.group({
      organization: new FormControl(null, Validators.required),
      recipient: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      alertDate: new FormControl(new Date(), Validators.required),
      category: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
      sendEmail: new FormControl(false)
    });
  }

  ngOnInit(): void {
    this.routeContext = (this.routeContextUtility.getRouteContext() === 'organize') ? 'membership' : this.routeContextUtility.getRouteContext();
    this.DBEntity = this.config.data.DBEntity;
    this.DBEntityID = this.config.data.DBEntityID;
    this.organizationId = this.config.data.organizationId;
    this.InterfaceObjectEnum = this.config.data.interfaceObjectEnum;
    this.navigateTo = this.config.data.navigateTo;
    if (this.config.data.title) {
      this.mainForm.get('title').setValue(this.config.data.title);
    }
    if (this.config.data.message) {
      this.mainForm.get('message').setValue(this.config.data.message);
    }
    this.initOrganization(false, this.InterfaceObjectEnum, false);
    this.initCategory(false, 'alert');
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    const optionLabelToUse: string = (this.routeContext === 'membership' || this.routeContext === 'organize') ? this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY') : 'Description';
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: optionLabelToUse,
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: null,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly, this.routeContext).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  getOrganizationData(event:any): void {
    let canEdit: boolean;
    if (event && event[0]) {
      this.selOrganizationId = (event[0].ID !== null) ? event[0].ID : null;
      canEdit = true;
    } else {
      this.selOrganizationId = null;
      canEdit = false;
    }
    this.mainForm.get('organization').setValue(this.selOrganizationId);
    this.mainForm.markAsDirty();
    this.initRecipient(!canEdit);
  }

  initRecipient(disable: boolean): void {
    this.inputObjRecipient = {
      labelText: 'Recipient',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      data: null,
      disabled: disable
    };
    if (this.selOrganizationId) {
      this.lookupsDataService.getOrganizationSharableAssigneesLookupData(this.selOrganizationId, this.InterfaceObjectEnum, this.routeContext).then((lookupData) => {
        this.inputObjRecipient.data = lookupData;
        this.inputObjRecipient = Object.assign({}, this.inputObjRecipient);
      });
    }
  }

  getRecipientData(event:any): void {
    if (event) {
      this.selRecipientId = event;
    } else {
      this.selRecipientId = null;
    }
    this.mainForm.get('recipient').setValue(this.selRecipientId);
    this.mainForm.markAsDirty();
  }

  initCategory(disable: boolean, sharableType: string): void {
    this.inputObjCategory = {
      labelText: 'Severity',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: true,
      initSelected: null,
      data: null,
      disabled: disable,
      emitChangeOnLoad: true
    };
    this.lookupsDataService.getSharableCategoriesLookupData(sharableType).then((lookupData) => {
      this.inputObjCategory.data = lookupData;
      this.inputObjCategory = Object.assign({}, this.inputObjCategory);
    });
  }

  getCategoryData(event:any): void {
    if (event && event[0]) {
      this.selCategoryId = (event[0].ID !== null) ? event[0].ID : null;
    } else {
      this.selCategoryId = null;
    }
    this.mainForm.get('category').setValue(this.selCategoryId);
    this.mainForm.markAsDirty();
  }

  processData(): void {
    this.isSaving = true;
    this.alert.Type = 'alert';
    this.alert.DBEntity = 'person';
    this.alert.DBEntityIDList = this.selRecipientId;
    this.alert.CategoryID = this.mainForm.get('category').value;
    this.alert.Title = this.mainForm.get('title').value;
    this.alert.Body = this.mainForm.get('message').value;
    this.alert.BodyIsHtml = true;
    this.alert.Promoted = true;
    this.alert.GoURL = this.navigateTo;
    this.alert.SendCommunication = this.mainForm.get('sendEmail').value;
    if (this.mainForm.get('sendEmail').value === true) {
      this.alert.CommunicationChannel = 'EMAIL';
      this.alert.CommunicationContext = this.context.selContext;
    }
    this.saveForm();
  }

  saveForm(): void {
    this.sharablesService.createMultipleSharableSendEmail(this.alert).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your alert has been created and sent.'});
        this.mainForm.markAsPristine();
        this.closeAddEdit.emit(true);
        this.isSaving = false;
        this.ref.destroy();
      }, error: (e) => {
        if (e?.error?.Message) {
          this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
        } else {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
        }
        this.isSaving = false;
      }
    });
  }

  cancel(): void {
    this.mainForm.reset();
    this.closeAddEdit.emit(false);
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.closeDialog();
  }

  closeDialog(res?) {
    this.ref.close(res);
    this.ref.destroy();
  }
}
