<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <button pButton type="button" label="Programs" class="sub-tab" (click)="activeSubTab = 'programs'"></button>
    <button pButton type="button" label="Wage Rates" class="sub-tab" (click)="activeSubTab = 'wageRates'"></button>
  </div>
  @if (activeSubTab === 'programs') {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
      <app-global-roster-student-programs [PersonID]="PersonID" [isEdit]="isEdit" [canTabWrite]="false"></app-global-roster-student-programs>
    </div>
  }
  @if (activeSubTab === 'wageRates') {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
      <app-global-student-programs-wage-rates [PersonID]="PersonID" [isEdit]="isEdit"></app-global-student-programs-wage-rates>
    </div>
  }
</div>
