import { Component, Input, OnInit, OnChanges} from '@angular/core';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent implements OnInit, OnChanges {
  infoTooltip: string = '';
  tcMAXSMSMESSAGECHARS: number;
  @Input() infoTooltipEnum: string;

  constructor(private tenantConfigDataService: TenantConfigDataService) {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.tcMAXSMSMESSAGECHARS = Number(this.tenantConfigDataService.getStringValue('MAXSMSMESSAGECHARS'));
    switch (this.infoTooltipEnum) {
      case 'MAILMERGEATTACHLETTER':
        this.infoTooltip = 'This will attach this letter to the Docs tab of the recipient.';
        break;
      case 'MAILMERGEINCLUDECC':
        this.infoTooltip = 'Selecting Yes will include any selected CC individuals on the generated letter.';
        break;
      case 'MAILMERGEINCLUDERECIPIENTS':
        this.infoTooltip = 'Selecting Yes will email this letter to the recipient; selecting No will not email this letter to the recipient and will only email this letter to those selected in the CC\'d list.';
        break;
      case 'SMSMESSAGECHARACTERS':
        this.infoTooltip = `SMS messages have a maximum of ${this.tcMAXSMSMESSAGECHARS.toString()} characters.`;
        break;
      case 'SMSMESSAGESEGMENTS':
        this.infoTooltip = 'SMS segments refer to how many different messages are sent based on the number of characters used. The use of emojis, accented characters, or MMS (images, video, etc.) will increase the number of segments in a message.';
        break;
      case 'SMSMESSAGECOST':
        this.infoTooltip = 'The cost of messaging is based on the number of segments sent.';
        break;
      default:
        this.infoTooltip = '';
        break;
    }
  }
}

