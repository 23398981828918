import { InterfaceObjectAppsModel } from '../../models/interface-objects.model';
import { Injectable } from '@angular/core';

@Injectable()
export class tabsPermissionsUtility {
  static checkTabRead(permissions: InterfaceObjectAppsModel[], value: string): boolean {
    return permissions.filter(e => e.Description === value).length > 0;
  }

  static checkTabWrite(permissions: InterfaceObjectAppsModel[], value: string): boolean {
    const filterPermission: InterfaceObjectAppsModel[] = permissions.filter(e => e.Description === value);
    return filterPermission[0] && filterPermission[0].Write === true;
  }

  static checkTabAdd(permissions: InterfaceObjectAppsModel[], value: string): boolean {
    const filterPermission: InterfaceObjectAppsModel[] = permissions.filter(e => e.Description === value);
    return filterPermission[0] && filterPermission[0].HasAdd === true && filterPermission[0].CanAdd === true;
  }
}
