@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form [formGroup]="mainForm">
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      @if (ResponseDBEntityEnumeratorOptions && !isEdit) {
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <p-radioButton formControlName="ResponseDBEntityEnumerator" value="PERSON" label="Roster Custom Field" class="p-mr-3" (onClick)="setResponseDBEntityEnumerator()" tabindex="0">
          </p-radioButton>
          <p-radioButton formControlName="ResponseDBEntityEnumerator" value="EMPLOYER" label="Employer Custom Field" class="p-mr-3" (onClick)="setResponseDBEntityEnumerator()" tabindex="0">
          </p-radioButton>
          <p-radioButton formControlName="ResponseDBEntityEnumerator" value="ORGREFERENCE" label="Sub-Organization Field" class="p-mr-3" (onClick)="setResponseDBEntityEnumerator()" tabindex="0">
          </p-radioButton>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <label>Field Description <sup class="req">*</sup></label>
        <input type="text" pInputText  formControlName="description" tabindex="0">
        @if ((mainForm.controls.description.dirty || mainForm.controls.description.touched) &&
          mainForm.controls.description.errors?.required) {
          <span class="error">'Description' must be provided</span>
        }
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <app-select class="full-width" [inputObj]="inputObjAttributeDataTypeByEntity" (emitData)="getAttributeDataTypeByEntityData($event)"></app-select>
      </div>
      @if (showShare) {
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <p-checkbox binary="true" formControlName="share" tabindex="0"></p-checkbox> Share with all types of Organizations
        </div>
      }
      @if (selectedType==='Custom Pick List') {
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <label>Options <sup class="req">*</sup></label>
          <!--    <div class="p-grid p-fluid">-->
          <ng-container formArrayName="options">
            @for (option of options.controls; track option; let i = $index) {
              <ng-container formGroupName="{{i}}">
                <div class="p-grid p-fluid">
                  <div class="col-md-10 col-sm-12 col-xs-12 input-container">
                    <input type="text" formControlName="Description" pInputText  tabindex="0"/>
                    <!--          <span class="error" *ngIf="(mainForm.controls.options[i].optionDescription.dirty || mainForm.controls.options[i].optionDescription.touched) &&-->
                  <!--        mainForm.controls.options[i].optionDescription.errors?.required">'Description' must be provided</span>-->
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12 input-container">
                  @if (canTabWrite) {
                    <div class="icon" pTooltip="Delete" tooltipPosition="bottom" [ngClass]="'option-delete'" (click)="removeOption(i)"><i class="fas fa-trash-alt"></i></div>
                  }
                </div>
              </div>
            </ng-container>
          }
        </ng-container>
      <!--    </div>-->
      @if (canTabWrite) {
        <button pButton label="Add Option" (click)="addOption(0, '')" class="p-button-info p-mr-2" [className]="'inline-button'"></button>
      }
    </div>
  }
</div>
@if (canTabWrite) {
  <div class="p-grid p-fluid margin-top-10">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="p-grid p-fluid">
        <div class="col-md-6 col-sm-6 col-xs-12"></div>
        <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
          @if (isEdit) {
            <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
            (click)="cancel()" [disabled]="!mainForm.dirty"></button>
          } @else {
            <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
            (click)="cancel()"></button>
          }
          @if (!isSaving) {
            <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
              (click)="processData()" [className]="'inline-button'"
            [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
          } @else {
            <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    </div>
  </div>
}
</form>
}
