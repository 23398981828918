<div class="side-nav-menu">
  @if (loadingMenu) {
    <span class="fa fa-spinner list-spinner"></span>
  } @else {

    <!--  <div class="my-wall" routerLink="dashboard"><span class="material-icons">dashboard</span> My Wall</div>-->
    <p-accordion [multiple]="true">
      @for (item of activeItems; track item) {
        <p-accordionTab header="{{item.Label}}" [selected]="isUser">
          <!--        <ng-container *ngIf="item.Items">-->
          <!--          <div class="menu-item" *ngFor="let subItem of item.Items" >-->
          <!--            <ng-container *ngIf="item.Items && item.Type !== 'mega'">-->
          <!--              <a (click)="goToRoute(subItem.external, subItem.link, $event)">-->
          <!--                <span class="material-icons">{{subItem.Icon}} </span>-->
          <!--                <span> {{subItem.Label}}</span>-->
          <!--              </a>-->
          <!--            </ng-container>-->
          <!--          </div>-->
          <!--        </ng-container>-->
          @for (subItem of item.Items; track subItem) {
            <div>
              @if (subItem.Items) {
                <p-accordionTab header="{{subItem.Label}}">
                  <div class="menu-item">
                    @for (subSubItem of subItem.Items; track subSubItem) {
                      @if (!subSubItem.Disabled) {
                        <a (click)="goToRoute(subSubItem.Route)">
                          <span class="{{subSubItem.Icon}}"></span>
                          <span> {{ subSubItem.Label }}</span>
                        </a>
                      }
                    }
                  </div>
                </p-accordionTab>
              }
              <!--            <ng-container *ngIf="!subItem.Items" >-->
              <!--              <div class="menu-item">-->
              <!--                <ng-container *ngIf="item.Items && item.Type !== 'mega'">-->
              <!--                  <a (click)="goToRoute(subItem.link)">-->
              <!--                    <span class="material-icons">{{subItem.Icon}} </span>-->
              <!--                    <span> {{subItem.Label}}</span>-->
              <!--                  </a>-->
              <!--                </ng-container>-->
              <!--              </div>-->
              <!--            </ng-container>-->
            </div>
          }
        </p-accordionTab>
      }
    </p-accordion>
  }
</div>
