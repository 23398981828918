<div class="p-grid p-fluid">
  @if (showNoProgramsMessage) {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h3>This student does not have active programs.</h3>
    </div>
  }
  @if (!showNoProgramsMessage) {
    <div class="col-md-12 col-sm-12 col-xs-12">
      <p-card header="Update Wage Rate">
        <div class="p-fluid p-grid margin-top-5">
          <div class="col-md-3 col-sm-6 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjWageZone" (emitData)="setWageRateZoneData($event)"></app-select>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjWageRate" (emitData)="setWageRateLevelData($event)"></app-select>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12 input-container">
            <label class="margin-bottom-5 add-display-flex">Start Date <sup class="req">*</sup></label>
            <app-universal-date-picker [disabled]="!canReadWrite" [selectedValue]="startDate" (emitData)="startDate =$event"></app-universal-date-picker>
          </div>
          @if (canAdd) {
            <div class="col-md-2 col-sm-6 col-xs-12 input-container">
              <button pButton label="Add New Wage Rate" (click)="updateWageRate()" class="p-button-info margin-top-17" pTooltip="Update" tooltipPosition="bottom"
              [disabled]="!selectedWageRateLevel || !startDate || !selectedWageRateZone"></button>
            </div>
          }
        </div>
      </p-card>
    </div>
  }
  @if (currentWageRate) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
      <p-card header="Current Wage Rate">
        {{currentWageRate.ProgramWageRateDescription}} | {{currentWageRate.CalculatedWageRate | currency}}
      </p-card>
    </div>
  }
  @if (studentWageRates) {
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-20">
      <p-table #dt1
        [rows]="pageRowCountUtility.pageRowCount()"
        [columns]="cols"
        dataKey="PersonID"
        [value]="studentWageRates"
        [lazy]="true"
        [paginator]="true"
        [showCurrentPageReport]="true"
        [metaKeySelection]="false"
        [totalRecords]="dataTotal"
        [rowsPerPageOptions]="[25,50,100,250,500]"
        [scrollable]="true"
        selectionMode="multiple"
        scrollHeight="400px"
        paginatorDropdownAppendTo="body"
        styleClass="p-datatable-gridlines p-datatable-striped"
        currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
        >
        <ng-template pTemplate="header" let-columns>
          <tr>
            @for (col of columns; track col) {
              <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
                @if (col.visible) {
                  <div class="header-text">
                    {{col.header}}
                  </div>
                }
              </th>
            }
            <th>
              Actions
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            @for (col of columns; track col) {
              <td>
                @if (col.field === 'CalculatedWageRate') {
                  {{rowData[col.field] | currency}}
                }
                @if (col.field === 'StartDate_D' || col.field === 'EndDate_D') {
                  {{rowData[col.field] | date}}
                }
                @if (col.field === 'ProgramWageRateDescription') {
                  {{rowData[col.field]}}
                }
              </td>
            }
            <td>
              @if (rowData.CanDelete && this.canReadWrite) {
                <button pButton icon="pi pi-trash" (click)="deleteWageRate(rowData)"  class="p-button-secondary margin-top-20 " pTooltip="Delete" tooltipPosition="bottom"></button>
              }
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [colSpan]="cols?.length" style="width: 100%;">No Wage Rates found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }
</div>
