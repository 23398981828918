import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {CreatePaymentMethodACHModel, CreatePaymentMethodCCModel, PaymentMethodBankNameModel, PaymentMethodCardTypeModel, PaymentMethodCardValidModel, PaymentMethodModel, PaymentMethodsGridModel, UpdatePaymentMethodACHModel, UpdatePaymentMethodCCModel} from '../models/payment-methods.model';
import {CreateOrganizationBillingAdjustmentbyPersonModel, CreateOrganizationBillingAdjustmentModel} from '../models/organization-billing-adjustments.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getPaymentMethodsPrimeNG(dataMode: string, dbentity: string, dbEntityID: number, filterData): Observable<PaymentMethodsGridModel> {
    return this.http.post<PaymentMethodsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/methodgrid/${dbentity}/${dbEntityID}?mode=${dataMode}`, filterData);
  }

  getPaymentMethod(paymentMethodId: number): Observable<PaymentMethodModel> {
    return this.http.get<PaymentMethodModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/method/${paymentMethodId}`);
  }

  createCCPaymentMethod(modelData: CreatePaymentMethodCCModel): Observable<PaymentMethodModel> {
    return this.http.post<PaymentMethodModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/method/create`, modelData);
  }

  createACHPaymentMethod(modelData: CreatePaymentMethodACHModel): Observable<PaymentMethodModel> {
    return this.http.post<PaymentMethodModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/method/create`, modelData);
  }

  updateCCPaymentMethod(paymentMethodId: number, modelData: UpdatePaymentMethodCCModel): Observable<PaymentMethodModel> {
    return this.http.put<PaymentMethodModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/method/${paymentMethodId}`, modelData);
  }

  updateACHPaymentMethod(paymentMethodId: number, modelData: UpdatePaymentMethodACHModel): Observable<PaymentMethodModel> {
    return this.http.put<PaymentMethodModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/method/${paymentMethodId}`, modelData);
  }

  deletePaymentMethod(paymentMethodId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/method/remove/${paymentMethodId}`);
  }

  getCreditCardType(data: PaymentMethodCardTypeModel): Observable<string> {
    return this.http.post<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/cardtype`, data);
  }

  isCreditCardValid(data: PaymentMethodCardValidModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/cardvalid`, data);
  }

  getBankName(data: PaymentMethodBankNameModel): Observable<string> {
    return this.http.post<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/bankname`, data);
  }

  setAutoPay(paymentMethodId: number, paymentDay: number): Observable<string> {
    return this.http.put<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/setautopayment?paymentMethodId=${paymentMethodId}&scheduleTypeId=${paymentDay}`, null);
  }

  setAutoPayment(paymentMethodId: number, paymentDay: number, personID: number): Observable<string> {
    return this.http.put<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/setautopayment?paymentMethodId=${paymentMethodId}&scheduleTypeId=${paymentDay}&personid=${personID}`, null);
  }

  createDirectPayment(modelData: CreateOrganizationBillingAdjustmentModel): Observable<CreateOrganizationBillingAdjustmentModel> {
    return this.http.post<CreateOrganizationBillingAdjustmentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/createdirectpayment`, modelData);
  }

  createDirectPaymentbyPerson(modelData: CreateOrganizationBillingAdjustmentbyPersonModel): Observable<CreateOrganizationBillingAdjustmentbyPersonModel> {
    return this.http.post<CreateOrganizationBillingAdjustmentbyPersonModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/createdirectpayment`, modelData);
  }

  getOrgPaymentMethodsPrimeNG(dataMode: string, organizationId: number, filterData): Observable<PaymentMethodsGridModel> {
    return this.http.post<PaymentMethodsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/methodgrid/organization/${organizationId}?mode=${dataMode}`, filterData);
  }
}
