import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { StudentProfileModel,
  CreateStudentProfileExistingMemberModel,  } from '../models/student-profile.model';

@Injectable({
  providedIn: 'root',
})
export class StudentProfileService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getStudentProfile(personId: number): Observable<StudentProfileModel> {
    return this.http.get<StudentProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/${personId}`);
  }

  getUserStudentProfile(): Observable<StudentProfileModel> {
    return this.http.get<StudentProfileModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/studentprofile`);
  }

  updateStudentProfile(personId: number, modelData: StudentProfileModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/${personId}?ignoreaddressupdates=true`, modelData);
  }

  createStudentProfile(modelData: StudentProfileModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/create`, modelData);
  }

  createStudentProfileExistingMember(modelData: CreateStudentProfileExistingMemberModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/studentroster/create`, modelData);
  }

}
