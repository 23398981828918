<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-2 col-sm-12 col-xs-12">
    <div class="col-md-12 col-sm-12 col-xs-12 list-filter-container">
      <app-learn-more [interfaceObjectDesc]="interfaceObjectDesc" [interfaceObjectTypeEnum]="'APPSECTION'"
                      [parentObjectEnum]="parentObjectEnum"></app-learn-more>
      <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <h3>Filter</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
        <label>Description</label>
        <input (keyup.enter)="filterItems()" [(ngModel)]="activeGlobalFilter" class="full-width filter" pInputText
               tabindex="0" type="text"/>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 no-padding margin-top-10">
        <app-select (emitData)="getAttributeDataTypeByEntityData($event)" [inputObj]="inputObjAttributeDataTypeByEntity"
                    class="full-width"></app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 nopadding">
        <div class="list-filter-buttons text-align-right">
          <button (click)="clearFilters()" [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" class="p-button-download export-menu" icon="pi pi-times"
                  label="Clear" pButton>
          </button>
          <button (click)="filterItems()" class="p-button-download export-menu" icon="pi pi-search" label="Search"
                  pButton>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
    <div class="p-grid p-fluid">
      @if (!totalLoaded) {
        <h2 class="col-md-3 col-sm-4 col-xs-12 results-total loading-total">Searching</h2>
      } @else {
        <h2 class="col-md-3 col-sm-4 col-xs-12">@if (attributes) {
          <span>{{ dataTotal }}
            @if ((currentFilter.filters | json) !== '{}') {
              <span>Filtered </span>
            }Results</span>
        }</h2>
      }
      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
        @if (attributes) {
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        }
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12 action-buttons">
        @if ((currentFilter.filters | json) !== '{}') {
          <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                  pTooltip="Clear Filter" tooltipPosition="bottom"
                  [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                  (click)="clearFilters()">
          </button>
        }
        <button (click)="refresh()" icon="pi pi-refresh" pButton
                pTooltip="Refresh" tooltipPosition="bottom"></button>
        @if (canAdd) {
          <button pButton (click)="showAddEdit = true"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        @if (!isExporting) {
          <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                  icon="fa fa-file-excel"
                  pTooltip="Export" tooltipPosition="bottom" (click)="export()">
          </button>
        } @else {
          <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                  icon="pi pi-spin pi-spinner save-spinner">
          </button>
        }
      </div>
      @if (attributeList) {
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator [rows]="currentFilter.rows" [totalRecords]="dataTotal"
                       (onPageChange)="paginate($event)"></p-paginator>
        </div>
      }
    </div>
    @if (showAddEdit) {
      <div>
        <p-card header="Add Attribute" [styleClass]="'list-card'">
          <app-organization-attributes-form [OrgRefID]="OrgRefID"
                                            [ResponseDBEntityEnumeratorOptions]="ResponseDBEntityEnumeratorOptions"
                                            [isEdit]="false" [canTabWrite]="canTabWrite"
                                            (closeAddEdit)="updateList($event)"></app-organization-attributes-form>
        </p-card>
      </div>
    }
    @if (totalLoaded && dataTotal <= 0 && !showAddEdit) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="list-container">
        @for (attribute of attributeList; track attribute; let i = $index) {
          <div class="col-md-12">
            <p-card [styleClass]="'list-card'">
              <div class="p-grid p-fluid">
                <div class="left-column col-md-8 col-sm-12 col-xs-12" (click)="expandData(i)">
                  <div class="list-header">
                    {{ attribute.Description }}
                  </div>
                  <div class="list-subheader">
                    @switch (attribute.ResponseDBEntityEnumerator) {
                      @case ('PERSON') {
                        <i class="fas fa-list" pTooltip="Roster" tooltipPosition="bottom"></i>
                      }
                      @case ('EMPLOYER') {
                        <i class="fas fa-briefcase" pTooltip="Employer" tooltipPosition="bottom"></i>
                      }
                      @case ('ORGREFERENCE') {
                        <i class="fas fa-house-user" pTooltip="Organization" tooltipPosition="bottom"></i>
                      }
                    } |
                    @switch (attribute.DataTypeEnumerator) {
                      @case ('PICKLIST') {
                        <i class="fas fa-list"></i>
                      }
                      @case ('DATE') {
                        <i class="fas fa-calendar"></i>
                      }
                      @case ('TIME') {
                        <i class="fas fa-calendar"></i>
                      }
                      @case ('DATETIME') {
                        <i class="fas fa-calendar"></i>
                      }
                      @case ('NUMBER') {
                        <i class="fas fa-hashtag"></i>
                      }
                      @case ('USD') {
                        <i class="fas fa-money-bill"></i>
                      }
                      @case ('CAD') {
                        <i class="fas fa-money-bill"></i>
                      }
                      @case ('SLE') {
                        <i class="fas fa-font"></i>
                      }
                      @case ('MLE') {
                        <i class="fas fa-font"></i>
                      }
                      @case ('YESNO') {
                        <i class="fas fa-square-check"></i>
                      }
                    }
                    {{ attribute.DataTypeDescription }}
                  </div>
                </div>
                <div class="right-column col-md-4 col-sm-12 col-xs-12">
                  <div class="list-action-buttons">
                    @if (canTabWrite && i !== 0) {
                      <div class="icon" pTooltip="Reorder Up" tooltipPosition="bottom"
                           (click)="reorderAttribute(attribute.ID, attributeList[i-1].ID)"><i
                        class="fas fa-sort-up"></i></div>
                    }
                    @if (canTabWrite && i !== attributeList.length - 1) {
                      <div class="icon" pTooltip="Reorder Down" tooltipPosition="bottom"
                           (click)="reorderAttribute(attribute.ID, attributeList[i+1].ID)"><i
                        class="fas fa-sort-down"></i></div>
                    }
                    @if (canTabWrite && attribute.CanDelete) {
                      <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                           (click)="deleteAttribute(OrgRefID, attribute.ID)"><i class="fas fa-trash-alt"></i></div>
                    }
                    <div class="icon" (click)="expandData(i)">
                      @if (this.selectedItem === i) {
                        <i class="fas fa-arrow-circle-up" pTooltip="Hide" tooltipPosition="bottom"></i>
                      } @else {
                        <i class="fas fa-arrow-circle-down" pTooltip="Show" tooltipPosition="bottom"></i>
                      }
                    </div>
                  </div>
                </div>
                @if (selectedItem === i) {
                  <div class="full-width margin-top-10">
                    <app-organization-attributes-form [attributeID]="attribute.ID"
                                                      [ResponseDBEntityEnumeratorOptions]="ResponseDBEntityEnumeratorOptions"
                                                      [isEdit]="true" [canTabWrite]="canTabWrite && attribute.CanEdit"
                                                      (closeAddEdit)="updateList($event)"></app-organization-attributes-form>
                  </div>
                }
              </div>
            </p-card>
          </div>
        }
      </div>
    }
  </div>
</div>
