import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  UserEmploymentGridModel, UserEmploymentModel,
  CreateUpdateUserEmploymentModel
} from '../models/user-employment.model';
import {ReportedGridModel, TotalHoursReportedModel} from '../models/hours-reported.model';

@Injectable({
  providedIn: 'root',
})
export class UserEmploymentService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getUserEmploymentPrimeNG(dataMode: string, filterData): Observable<UserEmploymentGridModel> {
    return this.http.post<UserEmploymentGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/employment/grid?mode=${dataMode}`, filterData);
  }

  getUserEmployment(employmentId: number): Observable<UserEmploymentModel> {
    return this.http.get<UserEmploymentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/employment/${employmentId}`);
  }

  getUserEmploymentExternalHours(): Observable<TotalHoursReportedModel> {
    return this.http.get<TotalHoursReportedModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/employment/externalhours/total`);
  }

  getUserEmploymentExternalHoursPrimeNG(dataMode: string, filterData): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/employment/externalhours/grid?mode=${dataMode}`, filterData);
  }

  getsuperuserRosterEmploymentExternalHoursPrimeNG(dataMode: string, personId: number, filterData): Observable<ReportedGridModel> {
    return this.http.post<ReportedGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/employment/externalhours/grid?mode=${dataMode}`, filterData);
  }

  createUserEmployment(modelData: CreateUpdateUserEmploymentModel): Observable<UserEmploymentModel> {
    return this.http.post<UserEmploymentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/employment/create`, modelData);
  }

  getSuperUserEmploymentExternalHours(personId: number): Observable<TotalHoursReportedModel> {
    return this.http.get<TotalHoursReportedModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/employment/externalhours/total`);
  }

  updateUserEmployment(employmentId: number, modelData: CreateUpdateUserEmploymentModel): Observable<UserEmploymentModel> {
    return this.http.put<UserEmploymentModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/employment/${employmentId}`, modelData);
  }

  deleteUserEmployment(employmentId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/employment/remove/${employmentId}`);
  }
}
