import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { UserSurveysGridModel, UserSurveyModel, UpdateUserSurveyResponseModel,
  UserSurveyLandingModel } from '../models/user-surveys.model';
import {SurveyModel} from '../models/surveys.model';

@Injectable({
  providedIn: 'root',
})
export class UserSurveysService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getUserSurveysPrimeNG(dataMode: string, filterData): Observable<UserSurveysGridModel> {
    return this.http.post<UserSurveysGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/grid?mode=${dataMode}`, filterData);
  }

  getUserSurvey(sendPublishId: number, tenantID?: number): Observable<UserSurveyModel> {
    const url: string = (tenantID) ? `?tenantid=${tenantID}` : '';
    return this.http.get<UserSurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/${sendPublishId}${url}`);
  }

  getUserSurveyAnon(sendPublishId: number, tenantId: number): Observable<UserSurveyModel> {
    return this.http.get<UserSurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/${sendPublishId}?tenantid=${tenantId}`);
  }

  updateUserSurveyResponse(modelData: UpdateUserSurveyResponseModel): Observable<UpdateUserSurveyResponseModel> {
    return this.http.put<UpdateUserSurveyResponseModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/response`, modelData);
  }

  updateUserSurveyResponseAnon(tenantId: string, modelData: UpdateUserSurveyResponseModel): Observable<UpdateUserSurveyResponseModel> {
    return this.http.put<UpdateUserSurveyResponseModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/response?tenantid=${tenantId}`, modelData);
  }

  submitUserSurveyResponse(sendPublishId: number): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/submit/${sendPublishId}`, null);
  }

  submitUserSurveyResponseAnon(sendPublishId: number, tenantId: number): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/submit/${sendPublishId}?tenantid=${tenantId}`, null);
  }

  getUserSurveyLanding(sendPublishId: string, tenantId: string, personId: string): Observable<UserSurveyLandingModel> {
    if (personId && personId !== '') {
      return this.http.get<UserSurveyLandingModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/landing?plainspid=${sendPublishId}&plaintid=${tenantId}&plainpid=${personId}`);
    } else {
      return this.http.get<UserSurveyLandingModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/landing?spid=${sendPublishId}&tid=${tenantId}`);
    }
  }

  getStatisticsByStatisticsId(surveyId: number, statisticId: number): Observable<SurveyModel> {
    return this.http.get<SurveyModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/survey/${surveyId}/statistics/${statisticId}`);
  }
}
