import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';
import {RosterProfileService} from '../../../services/roster-profile.service';
import {takeUntil} from 'rxjs/operators';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';

@Component({
  templateUrl: './membership-card-dialog.component.html',
  styleUrls: ['./membership-card-dialog.component.scss']
})

export class MembershipCardDialogComponent implements OnInit, OnDestroy {
  hasCard: boolean;
  inputObjTemplate: GenericSelectModel;
  setMembershipCardTemplateId: number;
  setTemplateTypeId: number;
  setOrganizationId: number;
  canPageWrite: boolean;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private rosterProfileService: RosterProfileService,
              private lookupsDataService: LookupsDataService) {
  }

  ngOnInit(): void {
    if (this.config.data.id) {
      this.rosterProfileService.getRosterProfile(this.config.data.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.hasCard = !!res.CardIssueDate;
          }
        });
    }
    if (this.config.data.organizationID) {
      this.setOrganizationId = this.config.data.organizationID;
      this.getMailMergeTypes();
    }
    this.canPageWrite = this.config.data.write;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  closeDialog(res) {
    const result = [res, this.setMembershipCardTemplateId];
    this.ref.close(result);
  }

  getMailMergeTypes() {
    this.lookupsDataService.getMailMergeTypesLookupData('membership').then((lookupData: any[]) => {
      this.setTemplateTypeId = lookupData.find(x => x.Enum === 'MEMBERSHIPCARD').ID;
      this.initMembershipCardTemplates(false);
    });
  }

  initMembershipCardTemplates(disable: boolean): void {
    this.inputObjTemplate = {
      labelText: 'Membership Card Template',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      emitChangeOnLoad: true
    };
    if (this.setTemplateTypeId) {
      this.lookupsDataService.getMailMergeTemplateTypesLookupData(this.setTemplateTypeId, this.setOrganizationId).then((lookupData) => {
        this.inputObjTemplate.data = lookupData;
        this.inputObjTemplate = Object.assign({}, this.inputObjTemplate);
      });
    }
  }

  getMembershipCardTemplateData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setMembershipCardTemplateId = event[0].ID;
    } else {
      this.setMembershipCardTemplateId = null;
    }
  }
}
