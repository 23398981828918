import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RSSFeedsService} from '../../../../services/rss-feeds.service';
import {CreateUpdateRSSFeedModel, RSSFeedModel} from '../../../../models/rss-feeds.model';
import {ContextService} from '../../../../services/context.service';

@Component({
  selector: 'app-organization-rss-feed-form',
  templateUrl: './organizations-rss-feed-form.component.html',
  styleUrls: ['./organizations-rss-feed-form.component.scss']
})
export class OrganizationsRssFeedFormComponent implements OnInit, OnChanges {
  mainForm: FormGroup;
  feeds = {} as CreateUpdateRSSFeedModel;
  isSaving: boolean = false;
  loading: boolean = false;
  existingMessage: boolean = false;
  @Input() feedData: RSSFeedModel;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() feedID: number;
  @Input() ID: number;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();
  cols: any[];

  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private lookupsDataService: LookupsDataService,
              private rssFeedsService: RSSFeedsService,
              private formBuilder: FormBuilder,
              private contextService: ContextService
  ) {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.mainForm = this.formBuilder.group({
      feedTitle: new FormControl(null, Validators.required),
      feedURL: new FormControl(null, [Validators.required, Validators.pattern(reg)]),
      feedLookbackNumber: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.initForm();

    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
  }

  ngOnChanges(): void {
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'RSS Feed data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initForm() {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    if (this.feedData) {
      this.mainForm.get('feedTitle').setValue(this.feedData.Title);
      this.mainForm.get('feedURL').setValue(this.feedData.URL);
      this.mainForm.get('feedLookbackNumber').setValue(this.feedData.Lookback_N);
    }
    this.loading = false;
  }

  processData() {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      //if (this.isEdit) {
      this.feeds.Title = this.mainForm.get('feedTitle').value;
      this.feeds.URL = this.mainForm.get('feedURL').value;
      this.feeds.Lookback_N = Number(this.mainForm.get('feedLookbackNumber').value);
      //}
      this.saveForm();
    }
  }

  saveForm() {
    if (!this.isEdit) {
      this.rssFeedsService.createRSSFeed(this.ID, this.feeds)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Feed has been saved'
            });
            this.closeAddEdit.emit();
            this.isSaving = false;
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    } else {
      this.rssFeedsService.updateRSSFeed(this.feedID, this.feeds)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.confirmationService.close();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Feed has been updated'
            });
            // this.dynamicDialogRef.close();
            this.existingMessage = false;
            this.closeAddEdit.emit();
          }, error: (e) => {
            this.isSaving = false;
            console.debug(e);
          }
        });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }
}
