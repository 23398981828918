@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
@if (!loading) {
  <form [formGroup]="mainForm">
    <p-divider class="dark-divider"></p-divider>
    <div class="p-grid p-fluid parent-form">
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <label>Title <sup class="req">*</sup></label>
        <input type="text" pInputText  formControlName="title" tabindex="0">
        @if ((mainForm.controls.title.dirty || mainForm.controls.title.touched) &&
          mainForm.controls.title.errors?.required) {
          <span class="error">'Title' must be provided</span>
        }
      </div>
      @if (canTabWrite) {
        <div class="col-md-4 col-sm-4 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjCategory" (emitData)="getCategoryData($event)"></app-select>
        </div>
      }
      @if (!canTabWrite) {
        <div class="col-md-4 col-sm-4 col-xs-12 input-container">
          <label>Category</label>
          <input class="full-width" type="text" pInputText  [value]="categoryAlias" [disabled]="true" tabindex="0">
        </div>
      }
      @if (showAccess) {
        <div class="col-md-4 col-sm-4 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjAccess" (emitData)="getAccessData($event)"></app-select>
        </div>
      }
      <div class="col-md-4 col-sm-4 col-xs-12 input-container">
        <label>Document Issue Date <sup class="req">*</sup></label>
        <app-universal-date-picker [selectedValue]="mainForm.get('fcnSharableDate').value" (emitData)="mainForm.get('fcnSharableDate').setValue($event); mainForm.markAsDirty()"></app-universal-date-picker>
        @if ((mainForm.controls.fcnSharableDate.dirty || mainForm.controls.fcnSharableDate.touched) &&
          mainForm.controls.fcnSharableDate.errors?.required) {
          <span class="error">'Document Issue Date' must be provided</span>
        }
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <label>Note</label>
        <textarea pInputTextarea formControlName="fcnSharableBody" rows="5" autoResize="autoResize" tabindex="0"></textarea>
      </div>
      @if (!isEdit) {
        <div class="col-md-12 col-sm-12 col-xs-12 input-container p-button-auto-width">
          <p>*Files must be 30 MB or less in size. If necessary, change the name of your files appropriately prior to uploading them. Upload one file at a time.</p>
          <p-fileUpload accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.ppt,.pptx" #fileUploader name="uploadedFiles[]" (onSelect)="onUpload($event)" (onClear)="clearUpload()" [showUploadButton]="false" maxFileSize="31457280">
            <ng-template let-file pTemplate='file'>
              <div>
                {{file.name}} - ({{file.size}} bytes)
              </div>
            </ng-template>
          </p-fileUpload>
        </div>
      }
      @if (showProgress) {
        <div class="col-md-12">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      }
    </div>
    @if (canTabWrite) {
      <div class="p-grid p-fluid margin-top-10">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="p-grid p-fluid">
            <div class="col-md-6 col-sm-6 col-xs-12"></div>
            <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
              @if (isEdit) {
                <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                (click)="cancel()" [disabled]="!mainForm.dirty"></button>
              } @else {
                <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                (click)="cancel()"></button>
              }
              @if (!isSaving) {
                <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                  (click)="processData()" [className]="'inline-button'"
                [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
              } @else {
                <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                  <i class="pi pi-spin pi-spinner save-spinner"></i>
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </form>
}
