import {Component, OnInit, ViewChild} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {InterfaceObjectItemPermissions} from '../../../../../models/interface-objects.model';
import {PageService} from '../../../../../services/page.services';



@Component({
  templateUrl: './confirm-export-dialog.component.html',
  styleUrls: ['./confirm-export-dialog.component.scss']
})

export class ConfirmExportDialogComponent implements OnInit {
  canExportPii: boolean = false;
  includePii: boolean = false;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private pageService: PageService ) {

  }

  ngOnInit(): void {
    this.pageService.getPagePermissions('ROSTER').then((res: InterfaceObjectItemPermissions) => {
      this.canExportPii = res.CanExportPii;
    });
  }

  export() {
    this.ref.close(this.includePii);
  }

  closeDialog(): void {
    this.ref.close();
  }

  cancel(): void {
    this.closeDialog();
  }
}
