import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmDeleteDialogComponent} from './confirm-delete/confirm-delete-dialog.component';

@Component({
  selector: 'app-delete-person-button',
  templateUrl: './delete-person.component.html',
  styleUrls: ['./delete-person.component.scss']
})

export class DeletePersonComponent implements OnInit {
  @Input() PersonID: number;
  @Input() Person;
  @Output() updateData: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private dialogService: DialogService) {
  }

  ngOnInit(): void {

  }

  confirmDelete() {
    const ref = this.dialogService.open(ConfirmDeleteDialogComponent, {
      data: {
        id: this.PersonID,
        profile: this.Person
      },
      header: this.Person.FirstName + ' ' + this.Person.LastName,
      width: '30%',
      height: '30%'
    });
    ref.onClose.subscribe({
      next: (res) => {
        if (res === 'deleted') {
          this.updateData.emit();
        }
      }
    });
  }
}
