import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {CaseModel, CasesGridModel} from '../../../models/cases.model';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {CasesService} from '../../../services/cases.service';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';

@Component({
  selector: 'app-roster-tab-cases',
  templateUrl: './roster-tab-cases.component.html',
  styleUrls: ['./roster-tab-cases.component.scss']
})
export class RosterTabCasesComponent implements OnInit, OnChanges, OnDestroy {
  cases: CasesGridModel;
  //cases: RosterProfileModel;
  casesList: CaseModel[] = [];
  dataLoaded: boolean;
  totalLoaded: boolean;
  isExporting: boolean;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  descriptionFilter: string = '';
  inputObjCaseType: GenericSelectModel;
  getListRequest;
  // getTotalRequest;
  @Input() PersonID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;

  private ngUnsubscribe = new Subject();

  constructor(private casesService: CasesService, public dialogService: DialogService,
              private dialogUtility: DialogUtility, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.initCaseType(false);

    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.getData();
  }

  getData(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.casesList.length = 0;
    this.dataLoaded = false;
    this.totalLoaded = false;
    this.getListRequest = this.casesService.getPersonCasesKGrid(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.cases = res;
          this.casesList = res.Data;
          this.dataLoaded = true;
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
          this.totalLoaded = true;
        }
      });
  }

  initCaseType(disable: boolean): void {
    this.inputObjCaseType = {
      labelText: 'Type',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      data: [
        {
          ID: null,
          Description: '--',
          Abbreviation: '--'
        },
        {
          ID: 1,
          Description: 'Grievance',
          Abbreviation: 'Grievance'
        },
        {
          ID: 2,
          Description: 'Discipline',
          Abbreviation: 'Discipline'
        }
      ],
      disabled: disable
    };
  }

  setCaseTypeData(event:any): void {
    if (event) {
      this.activeGlobalFilter = event[0].Description;
    } else {
      this.activeGlobalFilter = null;
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.loadList();
  }

  filterItems(): void {
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('WorkflowTypeDescription', this.activeGlobalFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.WorkflowTypeDescription;
    }
    if (this.descriptionFilter && this.descriptionFilter !== '') {
      this.initFilters('OccupationDescription', this.descriptionFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.OccupationDescription;
    }
    this.loadList();
  }

  clearFilters(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.descriptionFilter = null;
    this.initCaseType(false);
    this.loadList();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  loadList(): void {
    this.getData();
  }

  refresh(): void {
    this.getData();
  }

  resetComponent(): void {
    this.casesList = undefined;
    this.cases = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.casesList.length <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.casesList));
        const dataToExport = removeKeysFromArray(arr, ['CaseID', 'WorkflowTypeEnumerator', 'OrganizationID', 'PathwayID', 'EmployerID', 'ContractID', 'WorkflowID', 'OwnerPersonID', 'WorkflowProcessID']);
        exportData('cases', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.casesService.getPersonCasesKGrid(this.PersonID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['CaseID', 'WorkflowTypeEnumerator', 'OrganizationID', 'PathwayID', 'EmployerID', 'ContractID', 'WorkflowID', 'OwnerPersonID', 'WorkflowProcessID']);
              exportData('cases', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
