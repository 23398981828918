import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContextService} from '../../../../services/context.service';
import {StudentProfileModel} from '../../../../models/student-profile.model';
import {SendAdHocCommunicationModel} from '../../../../models/communications.model';
import {CommunicationsService} from '../../../../services/communications.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MessageService} from 'primeng/api';
import {TextEditorModel} from '../../../../models/text-editor.model';
import {RouteContextUtility} from '../../../utilities/route-context.utility';
import {StudentCertificationsModel} from '../../../../models/certifications.model';
import {ClassStudentModel} from '../../../../models/class-students.model';
import {UserProfileService} from '../../../../services/user-profile.service';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {SharablesService} from '../../../../services/sharables.service';

@Component({
  selector: 'app-adhoc-email-form',
  templateUrl: './adhoc-email-form.component.html',
  styleUrls: ['./adhoc-email-form.component.scss']
})

export class AdhocEmailFormComponent implements OnInit, OnChanges {
  showImageDialog: boolean = false;
  mainForm: FormGroup;
  fromEmail: string;
  isSaving: boolean;
  adhocData: SendAdHocCommunicationModel = {} as SendAdHocCommunicationModel;
  personIdList: any[] = [];
  hasEmail: boolean = false;
  content: string;
  settings = {} as TextEditorModel;
  inputObjTemplate: GenericSelectModel;
  @Input() recipients: StudentProfileModel[];
  @Input() recipientsStudentCerts: StudentCertificationsModel[];
  @Input() recipientsClassStudent: ClassStudentModel[];
  @Input() senderName: string;
  @Input() subject: string;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() superuser: boolean;
  @Input() organizationId: number;
  @Input() context: string;
  @Input() presetBody: string;
  @Input() removeSubscribe: boolean;

  @Output() closeDialog = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private contextService: ContextService, private formBuilder: FormBuilder,
              private communicationsService: CommunicationsService, private messageService: MessageService,
              private routeContextUtility: RouteContextUtility, private userProfileService: UserProfileService,
              private lookupsDataService: LookupsDataService, private sharablesService: SharablesService) {
    this.mainForm = this.formBuilder.group({
      senderName: new FormControl(null, [Validators.required]),
      subject: new FormControl(null, [Validators.required]),
      body: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.fromEmail = this.contextService.contextObject.UserName;

    if (this.senderName !== null && this.senderName !== '') {
      this.mainForm.get('senderName').setValue(this.senderName);
    }
    if (this.subject !== null && this.subject !== '') {
      this.mainForm.get('subject').setValue(this.subject);
    }
    this.settings.height = 250;
    this.settings.menubar = false;
    this.settings.image_title = true;
    this.settings.readonly = 0;
    this.settings.canEdit = true;
    this.settings.dir = 'secure';
    this.settings.expDays = 3650;
    this.settings.expHours = 0;
    this.initTemplate(false, this.organizationId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.recipients) {
      this.recipients.forEach(person => {
        this.personIdList.push(person.PersonID);
        if (person.PrimaryEmailAddress) {
          this.hasEmail = true;
        }
      });
    } else if (this.recipientsStudentCerts) {
      this.recipientsStudentCerts.forEach(person => {
        this.personIdList.push(person.PersonID);
        if (person.PrimaryEmail) {
          this.hasEmail = true;
        }
      });
    } else if (this.recipientsClassStudent) {
      this.recipientsClassStudent.forEach(person => {
        this.personIdList.push(person.PersonID);
        if (person.PrimaryEmailAddress) {
          this.hasEmail = true;
        }
      });
    }
    if (this.presetBody) {
      this.mainForm.get('body').setValue(this.presetBody);
      this.adhocData.Body = this.presetBody;
      this.mainForm.markAsDirty();
    } else {
      this.adhocData.Body = '';
    }
  }

  linkFile() {
    this.showImageDialog = true;
  }

  linkImage() {
    this.showImageDialog = true;
  }

  cancel() {
    this.closeDialog.emit();
  }

  updateContent(event:any) {
    this.content = event;
    this.adhocData.Body = event;
    this.mainForm.get('body').setValue(event);
  }

  processData() {
    this.isSaving = true;
    this.adhocData.DBEntity = this.DBEntity;
    this.adhocData.DBEntityKeyID = this.DBEntityID;
    this.adhocData.PersonIDList = this.personIdList;
    this.adhocData.Title = this.mainForm.get('subject').value;
    this.adhocData.SenderEmailAddress = this.fromEmail;
    this.adhocData.SenderDisplayName = this.mainForm.get('senderName').value;
    this.adhocData.SendSummaryEmailToUser = false;
    this.adhocData.Context = this.routeContextUtility.getRouteContext();
    this.adhocData.Channel = 'EMAIL';
    this.adhocData.OrganizationID = null;//this.contextService.contextObject.OrganizationID;
    if (this.removeSubscribe) {
      this.adhocData.Body = this.adhocData.Body.replaceAll('<a ', '<a clicktracking=off ');
    }
    this.saveData();
  }

  saveData() {
    if (this.superuser === false) {
      this.userProfileService.sendUserCommunicationAdHoc(this.adhocData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your email has been sent'
            });
            this.isSaving = false;
            this.closeDialog.emit(this.adhocData.Body);
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
            this.isSaving = false;
          }
        });
    } else {
      this.communicationsService.sendCommunicationAdHoc(this.adhocData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: 'Your email has been sent'
            });
            this.isSaving = false;
            this.closeDialog.emit(this.adhocData.Body);
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
            this.isSaving = false;
          }
        });
    }
  }

  initTemplate(disable: boolean, organizationId: number): void {
    this.inputObjTemplate = {
      labelText: 'Template',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable,
      canWrite: true
    };
    if (this.recipients[0]?.PersonID && this.context) {
      this.lookupsDataService.getCommunicationTemplatesByContextLookup(this.recipients[0]?.PersonID, this.context, 'TINYMCE').then((lookupData) => {
        this.inputObjTemplate.data = lookupData;
        this.inputObjTemplate = Object.assign({}, this.inputObjTemplate);
      });
    } else {
      this.lookupsDataService.getCommunicationTemplatesLookup(organizationId, 'TINYMCE').then((lookupData) => {
        this.inputObjTemplate.data = lookupData;
        this.inputObjTemplate = Object.assign({}, this.inputObjTemplate);
      });
    }
  }

  getTemplateData(event:any): void {
    if (event && event[0]) {
      this.loadTemplate(event[0].ID);
    }
  }

  loadTemplate(id: number): void {
    if (id) {
      this.sharablesService.getSharable(id).pipe(take(1))
        .subscribe({
          next: (res) => {
            if (res && res.Body) {
              this.content = res.Body;
              this.adhocData.Body = res.Body;
              this.mainForm.get('body').setValue(res.Body);
            }
          }, error:
            (e) => {
              console.warn(e);
            }
        });
    }
  }
}
