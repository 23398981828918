import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { BillingTypeModel } from '../models/billing-types.model';
import {CheckFeeModel, UserPaymentModel, UserPaymentSimpleBillModel} from '../models/user-payments.model';

@Injectable({
  providedIn: 'root',
})
export class MakePaymentService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getOneTimeAdjustmentTypes(personId: number): Observable<BillingTypeModel[]> {
    return this.http.get<BillingTypeModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/onetimeadjustmenttypes?personid=${personId}`);
  }

  getMemberSimpleBill(personId: number): Observable<UserPaymentSimpleBillModel> {
    return this.http.get<UserPaymentSimpleBillModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/membersimplebill?personid=${personId}`);
  }

  checkFee(checkFee: UserPaymentModel): Observable<CheckFeeModel> {
    return this.http.put<CheckFeeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/checkfee`, checkFee);
  }

  makePayment(payment: UserPaymentModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/memberpayment`, payment);
  }
}
