import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import {TradeSkillModel, UpdatePrimaryTradeModel, CreatePrimaryTradeModel} from '../models/trade-skills.model';


@Injectable({
  providedIn: 'root',
})
export class TradeSkillsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getTradeSkillsListByPersonId(personId: number): Observable<TradeSkillModel[]> {
    return this.http.get<TradeSkillModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/trade/list`);
  }

  createPersonTrade(personId: number, modelData: CreatePrimaryTradeModel): Observable<CreatePrimaryTradeModel> {
    return this.http.post<CreatePrimaryTradeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/trade`, modelData);
  }

  updatePersonTrade(personId: number, personTradeId: number,  modelData: UpdatePrimaryTradeModel, TheQuickening:boolean): Observable<UpdatePrimaryTradeModel> {
    return this.http.put<UpdatePrimaryTradeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/trade/${personTradeId}?TheQuickening=${TheQuickening}`, modelData);
  }

  deletePersonTrade(personId: number, personTradeId: number): Observable<UpdatePrimaryTradeModel> {
    return this.http.delete<UpdatePrimaryTradeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/trade/remove/${personTradeId}`);
  }

  getTradeSkillsListByOrganizationId(employerId: number): Observable<TradeSkillModel[]> {
    return this.http.get<TradeSkillModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/trade/list?organizationId=${employerId}`);
  }

  getTradeSkillsByOrganizationId(orgId: number): Observable<TradeSkillModel[]> {
    return this.http.get<TradeSkillModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/trade/${orgId}`);
  }

  createOrganizationIdTrade(orgId: number, modelData: CreatePrimaryTradeModel): Observable<CreatePrimaryTradeModel> {
    return this.http.post<CreatePrimaryTradeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/trade/${orgId}/trade`, modelData);
  }

  updateOrganizationIdTrade(orgId: number, orgTradeId: number, modelData: UpdatePrimaryTradeModel): Observable<UpdatePrimaryTradeModel> {
    return this.http.put<UpdatePrimaryTradeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/trade/${orgId}/trade?organizationTradeId=${orgTradeId}`, modelData);
  }

  deleteOrganizationIdTrade(orgId: number, orgTradeId: number): Observable<UpdatePrimaryTradeModel> {
    return this.http.delete<UpdatePrimaryTradeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/trade/remove/${orgId}?organizationTradeId=${orgTradeId}`);
  }

}
