import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NavigationEnd, Router} from '@angular/router';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Location} from '@angular/common';
import {ProjectReportsModel} from '../../../models/project-reports.model';
import {ProjectReportsService} from '../../../services/project-reports.service';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConfirmationService, MessageService} from 'primeng/api';
import {transformDateTimeToDateOnly} from '../../../shared/utilities/form.utility';
import {AddressModel} from '../../../models/addresses.model';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';

@Component({
  selector: 'app-project-report-tab-questions',
  templateUrl: './project-report-tab-questions.component.html',
  styleUrls: ['./project-report-tab-questions.component.scss']
})
export class ProjectReportTabQuestionsComponent implements OnInit, OnChanges, OnDestroy {
  InterfaceObjectEnum = 'ORGANIZEPROJECT';
  routeContext = 'organize';
  mainForm: FormGroup;
  inputObjProjectIndustry: GenericSelectModel;
  inputObjProjectValue: GenericSelectModel;
  inputObjProjectType: GenericSelectModel;
  inputObjProjectPhase: GenericSelectModel;
  inputObjOrganization: GenericSelectModel;
  inputObjProjectOrganizers: GenericSelectModel;
  inputObjProjectFunding: GenericSelectModel;
  setProjectFunding: number;
  setActiveProject;
  selectedOrganizers: any[];
  setProjectType: number;
  setProjectValue: number;
  setProjectPhase: number;
  setProjectIndustry: number;
  setOrganizationId: number;
  currentFilter = new PrimeTableFilterModel();
  isSaving: boolean = false;
  previousUrl: string;
  headerTitle: string;
  canModifyExisting: boolean;
  isMobile: boolean;
  addressData: AddressModel = {} as AddressModel;
  isDialog: boolean = false;
  @Input() selectedProjectReport: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() projectReportData: ProjectReportsModel;
  @Input() DBEntity;

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private lookupsDataService: LookupsDataService,
              private location: Location,
              private confirmationService: ConfirmationService, private projectReportsService: ProjectReportsService,
              private messageService: MessageService, private router: Router,
              private config: DynamicDialogConfig, public ref: DynamicDialogRef,
              private tenantConfigDataService: TenantConfigDataService) {
    this.mainForm = this.formBuilder.group({
      organization: new FormControl(null, Validators.required),
      organizers: new FormControl(null),
      projectName: new FormControl(null, Validators.required),
      projectFunding: new FormControl(null, Validators.required),
      activeProject: new FormControl(null),
      projectType: new FormControl(null, Validators.required),
      estimatedValue: new FormControl(null, Validators.required),
      constructionPhase: new FormControl(null, Validators.required),
      projectIndustry: new FormControl(null, Validators.required),
      dateOfVisit: new FormControl(null, Validators.required),
      dateFollowUp: new FormControl(null),
      projectOwner: new FormControl(null),
      projectDeveloper: new FormControl(null),
      projectEngineer: new FormControl(null),
      generalContractor: new FormControl(null),
      generalContractorIsUnion: new FormControl(null),
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe({
        next: (event: NavigationEnd) => {
          this.previousUrl = event.url;
        }
      });
  }

  ngOnInit(): void {
    if (!this.isEdit) {
      this.headerTitle = 'Add New Project Report';
      this.projectReportData = {} as ProjectReportsModel;
      this.projectReportData.TradeList = [];
      this.addressData.CountryID = null;
      this.addressData.Address1 = null;
      this.addressData.Address2 = null;
      this.addressData.City = null;
      this.addressData.StateProvinceID = null;
      this.addressData.Zip = null;
      this.addressData.ZipPlus4 = null;
      this.addressData.County = null;
      this.addressData.Latitude = null;
      this.addressData.Longitude = null;
      this.addressData.Ready = true;
      this.addressData.Invalid = true;
      this.initSelects();
    }
    if (this.config.data) {
      this.isDialog = this.config.data.isDialog;
    }
    if (this.isDialog) {
      this.isEdit = true;
      this.headerTitle = 'Existing Project';
      this.getProjectData();
    }

    this.currentFilter.rows = null;
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectReportData && Object.keys(this.projectReportData).length) {
      this.initData();
    }

    if (this.isEdit && this.projectReportData.IsAdmin) {
      this.canModifyExisting = true;
    }

    if (this.isEdit && !this.projectReportData.IsAdmin) {
      this.canModifyExisting = false;
    }

    if (!this.isEdit) {
      this.canModifyExisting = true;
      this.addressData.CountryID = null;
      this.addressData.Address1 = null;
      this.addressData.Address2 = null;
      this.addressData.City = null;
      this.addressData.StateProvinceID = null;
      this.addressData.Zip = null;
      this.addressData.ZipPlus4 = null;
      this.addressData.County = null;
      this.addressData.Latitude = null;
      this.addressData.Longitude = null;
      this.addressData.Ready = true;
      this.addressData.Invalid = true;
    } else {
      this.headerTitle = 'Edit Project Report';
    }
  }


  initData() {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.setOrganizationId = this.projectReportData.OrganizationID;
    this.mainForm.get('organization').setValue(this.projectReportData.OrganizationID);
    this.mainForm.get('projectName').setValue(this.projectReportData.ProjectName);
    this.selectedOrganizers = this.projectReportData.OrganizerPersonIDList;
    this.setProjectPhase = this.projectReportData.ProjectPhaseID;
    this.mainForm.get('constructionPhase').setValue(this.projectReportData.ProjectPhaseID);
    this.setProjectType = this.projectReportData.ProjectTypeID;
    this.mainForm.get('projectType').setValue(this.projectReportData.ProjectTypeID);
    this.setProjectValue = this.projectReportData.ProjectValueID;
    this.mainForm.get('estimatedValue').setValue(this.projectReportData.ProjectValueID);
    this.setProjectIndustry = this.projectReportData.ProjectIndustryID;
    this.mainForm.get('projectIndustry').setValue(this.projectReportData.ProjectIndustryID);
    this.mainForm.get('projectOwner').setValue(this.projectReportData.ProjectOwner);
    this.mainForm.get('projectDeveloper').setValue(this.projectReportData.ProjectDeveloper);
    this.mainForm.get('projectEngineer').setValue(this.projectReportData.ProjectEngineer);
    this.mainForm.get('generalContractor').setValue(this.projectReportData.GeneralContractor);
    this.mainForm.get('projectFunding').setValue(this.projectReportData.ProjectFundingID);
    this.setProjectFunding = this.projectReportData.ProjectFundingID;
    this.setActiveProject = this.projectReportData.ActiveProject;
    this.mainForm.get('activeProject').setValue(this.projectReportData.ActiveProject);
    if (this.projectReportData.GeneralContractorIsUnion) {
      this.mainForm.get('generalContractorIsUnion').setValue('true');
    } else {
      this.mainForm.get('generalContractorIsUnion').setValue('false');
    }
    const vDate = new Date(this.projectReportData.VisitDate_D);
    this.mainForm.get('dateOfVisit').setValue(vDate);

    if (this.projectReportData.FollowupVisitDate_D) {
      const fDate = new Date(this.projectReportData.FollowupVisitDate_D);
      this.mainForm.get('dateFollowUp').setValue(fDate);
    }

    this.addressData.CountryID = this.projectReportData.CountryID;
    this.addressData.Address1 = this.projectReportData.Address1;
    this.addressData.Address2 = this.projectReportData.Address2;
    this.addressData.City = this.projectReportData.City;
    this.addressData.StateProvinceID = this.projectReportData.StateProvinceID;
    this.addressData.Zip = this.projectReportData.Zip;
    this.addressData.ZipPlus4 = this.projectReportData.ZipPlus4;
    this.addressData.County = this.projectReportData.County;
    this.addressData.Latitude = this.projectReportData.Latitude;
    this.addressData.Longitude = this.projectReportData.Longitude;
    this.addressData.Ready = true;
    this.addressData.Invalid = false;

    this.initSelects();
  }

  getProjectData() {
    this.projectReportsService.getProtectReport(this.config.data.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.projectReportData = res;
          this.initData();
        }
      });
  }

  initSelects() {
    this.initOrganization(this.canModifyExisting);
    this.initProjectType(this.canModifyExisting);
    this.initProjectValue(this.canModifyExisting);
    this.initProjectPhase(false);
    this.initProjectIndustries(this.canModifyExisting);
    this.initProjectFunding(this.canModifyExisting);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initOrganization(disable: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organizations',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: !this.isEdit,
      initSelected: this.setOrganizationId,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(this.InterfaceObjectEnum, false, this.routeContext, false).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  getOrganizationData(event:any) {
    if (event) {
      this.setOrganizationId = event[0].ID;
    } else {
      this.setOrganizationId = null;
    }
    this.initOrganizers(false);
    this.mainForm.get('organization').setValue(this.setOrganizationId);
  }

  initOrganizers(disable: boolean): void {
    this.inputObjProjectOrganizers = {
      labelText: 'Organizers',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.selectedOrganizers,
      data: null,
      disabled: disable,
      customSelect: 'ShowStatus'
    };
    this.lookupsDataService.getOrganizeProjectOrganizers(this.setOrganizationId, false).then((lookupData) => {
      this.inputObjProjectOrganizers.data = lookupData;
      this.inputObjProjectOrganizers = Object.assign({}, this.inputObjProjectOrganizers);
    });
  }

  setProjectOrganizersData(event:any) {
    if (event) {
      this.selectedOrganizers = event;
    } else {
      this.selectedOrganizers = null;
    }
  }

  initProjectPhase(disable: boolean) {
    this.inputObjProjectPhase = {
      labelText: 'Construction Phase',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: (this.setProjectPhase) ? this.setProjectPhase : null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getOrganizeProjectPhasesLookupData(true).then((lookupData) => {
      this.inputObjProjectPhase.data = lookupData;
      this.inputObjProjectPhase = Object.assign({}, this.inputObjProjectPhase);
    });
  }

  setProjectPhaseData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setProjectPhase = event[0].ID;
    } else {
      this.setProjectPhase = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('constructionPhase').setValue(this.setProjectPhase);
  }

  initProjectType(disable: boolean) {
    this.inputObjProjectType = {
      labelText: 'Project Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: (this.setProjectType) ? this.setProjectType : null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getOrganizeProjectTypesListLookupData(true).then((lookupData) => {
      this.inputObjProjectType.data = lookupData;
      this.inputObjProjectType = Object.assign({}, this.inputObjProjectType);
    });
  }

  setProjectTypeData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setProjectType = event[0].ID;
    } else {
      this.setProjectType = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('projectType').setValue(this.setProjectType);
  }

  initProjectValue(disable: boolean) {
    this.inputObjProjectValue = {
      labelText: 'Estimated Value',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: (this.setProjectValue) ? this.setProjectValue : null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getOrganizeProjectEstimatedLookupData(true).then((lookupData) => {
      this.inputObjProjectValue.data = lookupData;
      this.inputObjProjectValue = Object.assign({}, this.inputObjProjectValue);
    });
  }

  setProjectValueData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setProjectValue = event[0].ID;
    } else {
      this.setProjectValue = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('estimatedValue').setValue(this.setProjectValue);
  }

  initProjectIndustries(disable: boolean) {
    this.inputObjProjectIndustry = {
      labelText: 'Industries',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: (this.setProjectIndustry) ? this.setProjectIndustry : null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getOrganizeProjectIndustriesListLookupData(true).then((lookupData) => {
      this.inputObjProjectIndustry.data = lookupData;
      this.inputObjProjectIndustry = Object.assign({}, this.inputObjProjectIndustry);
    });
  }

  setProjectIndustryData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setProjectIndustry = event[0].ID;
    } else {
      this.setProjectIndustry = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('projectIndustry').setValue(this.setProjectIndustry);
  }

  initProjectFunding(disable: boolean) {
    this.inputObjProjectFunding = {
      labelText: 'Project Funding',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setProjectFunding ? this.setProjectFunding : null,
      data: null,
      disabled: disable
    };
    this.lookupsDataService.getOrganizeProjectFundingLookupData(true).then((lookupData) => {
      this.inputObjProjectFunding.data = lookupData;
      this.inputObjProjectFunding = Object.assign({}, this.inputObjProjectFunding);
    });
  }

  setProjectFundingData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.setProjectFunding = event[0].ID;
    } else {
      this.setProjectFunding = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('projectFunding').setValue(this.setProjectFunding);
  }

  processData() {
    this.projectReportData.OrganizationID = this.setOrganizationId;
    this.projectReportData.ProjectName = this.mainForm.get('projectName').value;
    this.projectReportData.OrganizerPersonIDList = this.selectedOrganizers;
    this.projectReportData.CountryID = this.addressData.CountryID;
    this.projectReportData.Address1 = this.addressData.Address1;
    this.projectReportData.Address2 = this.addressData.Address2;
    this.projectReportData.City = this.addressData.City;
    this.projectReportData.StateProvinceID = this.addressData.StateProvinceID;
    this.projectReportData.Zip = this.addressData.Zip;
    this.projectReportData.ZipPlus4 = this.addressData.ZipPlus4;
    this.projectReportData.County = this.addressData.County;
    this.projectReportData.Latitude = this.addressData.Latitude;
    this.projectReportData.Longitude = this.addressData.Longitude;
    this.projectReportData.ProjectPhaseID = this.mainForm.get('constructionPhase').value;
    this.projectReportData.ProjectTypeID = this.mainForm.get('projectType').value;
    this.projectReportData.ProjectValueID = this.mainForm.get('estimatedValue').value;
    this.projectReportData.ProjectIndustryID = this.mainForm.get('projectIndustry').value;
    this.projectReportData.VisitDate_D = transformDateTimeToDateOnly(this.mainForm.get('dateOfVisit').value);
    if (this.mainForm.get('dateFollowUp').value) {
      this.projectReportData.FollowupVisitDate_D = transformDateTimeToDateOnly(this.mainForm.get('dateFollowUp').value);
    } else {
      this.projectReportData.FollowupVisitDate_D = null;
    }
    this.projectReportData.ProjectOwner = this.mainForm.get('projectOwner').value;
    this.projectReportData.ProjectDeveloper = this.mainForm.get('projectDeveloper').value;
    this.projectReportData.ProjectEngineer = this.mainForm.get('projectEngineer').value;

    this.projectReportData.ProjectFundingID = this.mainForm.get('projectFunding').value;
    this.projectReportData.GeneralContractor = this.mainForm.get('generalContractor').value;

    this.projectReportData.GeneralContractorIsUnion = this.mainForm.get('generalContractorIsUnion').value === 'true';

    this.saveData();
  }

  saveData() {
    if (!this.isEdit) {
      this.projectReportsService.createProtectReportsTotal(this.projectReportData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'New project report has been added.'
            });
            this.isSaving = false;
            this.router.navigateByUrl('organize/project-reports/edit/' + res.OrganizeProjectID);
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    } else {
      this.projectReportsService.updateProtectReportsTotal(this.projectReportData.OrganizeProjectID, this.projectReportData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'New project report has been updated.'
            });
            this.isSaving = false;
            this.mainForm.markAsPristine();
            this.ref.close();
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    }
  }

  cancel() {
    this.addressData = {} as AddressModel;
    window.location.reload();
  }

  back(): void {
    this.confirmationService.close();
    this.location.back();
    // if(this.previousUrl.includes('login')) {
    //   this.router.navigateByUrl('organize/project-reports/list');
    // } else {
    //   this.location.back();
    // }
  }

  setAddress(addressChanges: AddressModel): void {
    if (addressChanges) {
      this.mainForm.markAsDirty();
      this.addressData.CountryID = addressChanges.CountryID;
      this.addressData.Address1 = addressChanges.Address1;
      this.addressData.Address2 = addressChanges.Address2;
      this.addressData.City = addressChanges.City;
      this.addressData.StateProvinceID = addressChanges.StateProvinceID;
      this.addressData.Zip = addressChanges.Zip;
      this.addressData.ZipPlus4 = addressChanges.ZipPlus4;
      this.addressData.County = addressChanges.County;
      this.addressData.Latitude = addressChanges.Latitude;
      this.addressData.Longitude = addressChanges.Longitude;
      this.addressData.Invalid = addressChanges.Invalid;
    }
  }
}
