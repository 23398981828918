<div>
  <div class="p-grid p-fluid margin-top-10">
    <div class="col-md-2 col-sm-12 col-xs-12">
      <app-learn-more [interfaceObjectDesc]="interfaceObjectDesc" [interfaceObjectTypeEnum]="'APPSECTION'"
                      [parentObjectEnum]="parentObjectEnum"></app-learn-more>
      <div class="col-md-12 col-sm-12 col-xs-12 list-container-filter-header">
        <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <h3>Filter</h3>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
          <label>Name</label>
          <input (keyup.enter)="filterItems()" [(ngModel)]="activeGlobalFilter" class="full-width filter" pInputText
                 tabindex="0" type="text"/>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
          <app-multi-select (emitData)="getStatusData($event)" [inputObj]="inputObjStatus"
                            class="full-width"></app-multi-select>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
          <app-multi-select (emitData)="getGradeData($event)" [inputObj]="inputObjGrades"
                            class="full-width"></app-multi-select>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
          <div class="list-filter-buttons text-align-right">
            <button (click)="clearFilters()" [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" class="p-button-download export-menu" icon="pi pi-times"
                    label="Clear" pButton>
            </button>
            <button (click)="filterItems()" class="p-button-download export-menu" icon="pi pi-search" label="Search"
                    pButton>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-10 col-sm-12 col-xs-12 list-container-header">
      <div class="p-grid p-fluid">
        <h2 class="col-md-3 col-sm-4 col-xs-6 results-total">{{ dataTotal | number:'1.0':'en-US' }} @if (dataTotal === 1) {
          Result
        } @else {
          Results
        }</h2>
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator">
          <p-paginator (onPageChange)="paginate($event)" [rows]="pageRowCountUtility.pageRowCount()"
                       [totalRecords]="dataTotal"></p-paginator>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 action-buttons">
          @if ((currentFilter.filters | json) !== '{}') {
            <button pButton class="p-button-download export-menu" icon="pi pi-filter-slash"
                    pTooltip="Clear Filter" tooltipPosition="bottom"
                    [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}"
                    (click)="clearFilters()">
            </button>
          }
          @if (_tabsPermissionsUtility.checkTabWrite(permissions, 'tabCommunications')) {
            <button pButton (click)="openAdhocComm(StudentList)" [disabled]="dataTotal === 0" icon="fas fa-comments"
                    pTooltip="Message" tooltipPosition="bottom"></button>
          }
          <button (click)="refresh()" icon="pi pi-refresh" pButton
                  pTooltip="Refresh" tooltipPosition="bottom"></button>
          @if (canAdd) {
            <button pButton (click)="showAddEdit = true"
                    icon="pi pi-plus" pTooltip="Add"
                    tooltipPosition="bottom"></button>
          }
          @if (!isExporting) {
            <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                    icon="fa fa-file-excel"
                    pTooltip="Attendance Export" tooltipPosition="bottom" (click)="exportAttendance()">
            </button>
            <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
                    icon="fa fa-file-excel"
                    pTooltip="Export" tooltipPosition="bottom" (click)="export()">
            </button>
          } @else {
            <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
                    icon="pi pi-spin pi-spinner save-spinner">
            </button>
          }
        </div>
        <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile">
          <p-paginator (onPageChange)="paginate($event)" [rows]="pageRowCountUtility.pageRowCount()"
                       [totalRecords]="dataTotal"></p-paginator>
        </div>
      </div>
      @if (showAddEdit) {
        <div>
          <p-card header="Add Student to Class" [styleClass]="'list-card'">
            <app-class-student-form [ClassID]="ClassID" [isEdit]="false" [canTabWrite]="canTabWrite"
                                    [OrganizationID]="OrganizationID"
                                    (closeAddEdit)="updateList()"></app-class-student-form>
          </p-card>
        </div>
      }
      @if (!loading && dataTotal <= 0) {
        <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
          @if ((currentFilter.filters | json) === '{}') {
            <span><b>No results were found.</b></span>
          } @else {
            <span><i
              class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
          }
        </div>
      }
      @if (loading) {
        <div class="col-md-12 col-sm-12 col-xs-12">
          <app-data-indicator></app-data-indicator>
        </div>
      } @else {
        <div class="list-container p-grid p-fluid">
          @if (dataTotal > 0) {
            <button pButton class="p-button-download export-menu setAllBtn" icon="pi pi-check"
                    label="Set all to Complete"
                    pTooltip="Set all to Complete" tooltipPosition="bottom" (click)="setAllComplete()">
            </button>
          }
          @for (student of StudentList; track student; let i = $index) {
            <div class="col-md-12">
              <p-card [styleClass]="'list-card'">
                @if (isStatusInActive(student.TrainingStatusDescription)) {
                  <div class="inactive-flag"></div>
                }
                <div class="p-grid p-fluid">
                  <div class="col-md-1 col-sm-2 col-xs-12 relative pointer center-profile profile-container"
                       (click)="expandData(i, student.ClassStudentID)">
                    @if (student.ThumbnailUrl) {
                      <div>
                        <div class="avatar-with-image" [ngClass]="getAvatarClass(true, student.MemberStatusAlias)"><img
                          ngSrc="{{student.ThumbnailUrl + blobToken}}" (error)="setDefaultPic(i)" alt="" height="71"
                          width="71" [priority]="true"/></div>
                      </div>
                    }
                    @if (!student.ThumbnailUrl) {
                      <div>
                        <div class="avatar-without-image" [ngClass]="getAvatarClass(false, student.MemberStatusAlias)">
                          <img
                            ngSrc="assets/images/profile-generic.png" alt="" height="71" width="71" [priority]="true"/>
                        </div>
                      </div>
                    }
                  </div>
                  <div class="col-md-7 col-sm-8 col-xs-8 pointer" (click)="expandData(i, student.ClassStudentID)">
                    <div class="list-header">
                      {{ student.FullName }}
                    </div>
                    <div class="list-subheader">
                      {{ student.TrainingStatusDescription }}
                      @if (!isModule) {
                        @if (student.Grade) {
                          <span> | <b>Class Grade Average:</b> {{ student.Grade }}%</span>
                        }
                        @if (student.ResultDescription) {
                          <span> | <b>Class Grade:</b> {{ student.ResultDescription }}</span>
                        }
                      }
                      @if (student.ExpirationDate_D) {
                        <span> | <b>Retraining Date:</b> {{ student.ExpirationDate_D | date: 'shortDate' }}</span>
                      }
                      @if (student.CertificationNumber) {
                        <span> | <b>Certification #:</b> {{ student.CertificationNumber }}</span>
                      }
                      @if (student.ResultDescription && isModule) {
                        <span> | <b>Module Grade:</b> {{ student.ResultDescription }}</span>
                      }
                      @if (student.MemberStatusAlias) {
                        <span> | <b>Member Status:</b> {{ student.MemberStatusAlias }}</span>
                      }
                      @if (student.Trade) {
                        <span> | <b>Trade:</b> {{ student.Trade }}</span>
                      }
                    </div>
                  </div>
                  <div class="right-column col-md-4 col-sm-12 col-xs-12">
                    <div class="list-action-buttons">
                      @if (student.CanEditRoster) {
                        <div class="icon hide-on-mobile" pTooltip="Edit" tooltipPosition="bottom"
                             (click)="openStudent(student.PersonID)"><i class="fas fa-user-edit"></i></div>
                      }
                      @if (!student.CanEditRoster) {
                        <div class="icon hide-on-mobile" (click)="openGlobalStudent(student.PersonID)"><i
                          class="fas fa-user-edit"></i></div>
                      }
                      @if (isModule) {
                        <div class="icon hide-on-mobile" (click)="getStudentProgress(student.ClassStudentID)"><i
                          class="fas fa-file-pdf" pTooltip="Progress Report"></i></div>
                        <div class="icon hide-on-mobile" (click)="getStudentAttendance(student.ClassStudentID)"><i
                          class="fas fa-file-pdf" pTooltip="Attendance Report"></i></div>
                      }
                      @if (canTabWrite && student.CanDelete) {
                        <div class="icon" pTooltip="Delete" tooltipPosition="bottom"
                             (click)="delete(ClassID, student.ClassStudentID)"><i class="fas fa-trash-alt"></i></div>
                      }
                      <div class="icon" (click)="expandData(i, student.ClassStudentID)">
                        @if (this.selectedItem === i) {
                          <i class="fas fa-arrow-circle-up" pTooltip="Hide"></i>
                        } @else {
                          <i class="fas fa-arrow-circle-down" pTooltip="Show"></i>
                        }
                      </div>
                    </div>
                  </div>
                  @if (selectedItem === i) {
                    <div class="full-width margin-top-10">
                      <app-class-student-form [courseId]="courseId" [ClassID]="ClassID"
                                              [ClassStudentID]="student.ClassStudentID" [isEdit]="true"
                                              [canTabWrite]="student.CanEdit && canTabWrite"
                                              class="no-padding-container"
                                              [isModule]="isModule"></app-class-student-form>
                    </div>
                  }
                </div>
              </p-card>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
