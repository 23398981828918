<div class="form-control relative">
  <form [formGroup]="selectForm">
    <div>
      @if (!inputObj?.hideLabel) {
        <label [ngClass]="{'loading-total':!inputObj}">{{ inputObj?.labelText }}
          @if (inputObj?.requiredField) {
            <sup class="req">*</sup>
          }
        </label>
      }
    </div>
    <div>
      @if (inputObj?.customSelect) {
        <p-dropdown [options]="selectData" [optionValue]="inputObj?.optionValue" [optionLabel]="inputObj?.optionLabel"
                    appendTo="body" tabindex="0"
                    [showClear]="true" [filter]="inputObj?.filter"
                    [filterBy]="(inputObj?.filterBy) ? inputObj?.filterBy : inputObj?.optionLabel" placeholder="--"
                    [virtualScroll]="virtScroll" virtualScrollItemSize="30"
                    formControlName="fcnSelect" class="select-dropdown no-click" (onChange)="emitDataToParent(true)"
                    (onClear)="emitDataToParent(true)">
          @switch (inputObj.customSelect) {
            @case ('AttributeDataType') {
              <ng-template let-label pTemplate="item">
                <div class="description-label">
                  {{ label.Description }}
                </div>
                <div class="icon-button">
                  @if (label?.Description !== '--') {
                    <!--                    @switch (label.Enum) {-->
                      <!--                      @case ('PICKLIST') {-->
                      <!--                        <i class="fas fa-list"></i>-->
                      <!--                      }-->
                      <!--                      @case ('DATE') {-->
                      <!--                        <i class="fas fa-calendar"></i>-->
                      <!--                      }-->
                      <!--                      @case ('TIME') {-->
                      <!--                        <i class="fas fa-calendar"></i>-->
                      <!--                      }-->
                      <!--                      @case ('DATETIME') {-->
                      <!--                        <i class="fas fa-calendar"></i>-->
                      <!--                      }-->
                      <!--                      @case ('NUMBER') {-->
                      <!--                        <i class="fas fa-hashtag"></i>-->
                      <!--                      }-->
                      <!--                      @case ('USD') {-->
                      <!--                        <i class="fas fa-money-bill"></i>-->
                      <!--                      }-->
                      <!--                      @case ('CAD') {-->
                      <!--                        <i class="fas fa-money-bill"></i>-->
                      <!--                      }-->
                      <!--                      @case ('SLE') {-->
                      <!--                        <i class="fas fa-font"></i>-->
                      <!--                      }-->
                      <!--                      @case ('MLE') {-->
                      <!--                        <i class="fas fa-font"></i>-->
                      <!--                      }-->
                      <!--                      @case ('YESNO') {-->
                      <!--                        <i class="fas fa-square-check"></i>-->
                      <!--                      }-->
                      <!--                    }-->
                    @switch (label.Description) {
                      @case ('Checkbox') {
                        <i class="fas fa-square-check"></i>
                      }
                      @case ('Custom Pick List') {
                        <i class="fas fa-list"></i>
                      }
                      @case ('Date') {
                        <i class="fas fa-calendar"></i>
                      }
                      @case ('Time') {
                        <i class="fas fa-calendar"></i>
                      }
                      @case ('DATETIME') {
                        <i class="fas fa-calendar"></i>
                      }
                      @case ('Number') {
                        <i class="fas fa-hashtag"></i>
                      }
                      @case ('Numeric Identifier') {
                        <i class="fas fa-hashtag"></i>
                      }
                      @case ('US Currency') {
                        <i class="fas fa-money-bill"></i>
                      }
                      @case ('Canadian Currency') {
                        <i class="fas fa-money-bill"></i>
                      }
                      @case ('Open Text - Single Line') {
                        <i class="fas fa-font"></i>
                      }
                      @case ('Open Text - Multi Line') {
                        <i class="fas fa-font"></i>
                      }
                      @case ('Yes/No') {
                        <i class="fas fa-square-check"></i>
                      }
                    }
                  }
                </div>
              </ng-template>
            }
            @case ('ShowStatus') {
              <ng-template let-label pTemplate="item">
                <div class="description-label" [title]="label.Description">
                  <div [ngClass]="{'inactive' : label.Active === false}">{{ label.Description }}
                    @if (label.Description !== '--' && label.StatusDescription) {
                      ({{ label.StatusDescription }})
                    }
                  </div>
                </div>
              </ng-template>
            }
            @case ('Period') {
              <ng-template let-label pTemplate="item">
                <div class="description-label" [title]="label.Description">
                  <div [ngClass]="{'highlight-option' : label.StatusDescription === 'Draft'}">{{ label.Description }}
                  </div>
                </div>
              </ng-template>
            }
            @case ('ShowInactiveIcon') {
              <ng-template let-label pTemplate="item">
                <div class="description-label" [ngClass]="{'' : label.Active, 'inactive' : !label.Active}">
                  @switch (inputObj?.optionLabel) {
                    @case ('Description') {
                      {{ label.Description }}
                    }
                    @case ('Abbreviation') {
                      {{ label.Abbreviation }}
                    }
                  }
                </div>
                @if (!label?.Active) {
                  <div class="icon-button" [ngClass]="{'' : label.Active, 'inactive' : !label.Active}">
                    @if (label?.Description !== '--') {
                      @if (label?.StatusDescription) {
                        <i class="fas fa-square-info" [pTooltip]="label?.StatusDescription" tooltipPosition="right"
                           tooltipStyleClass="filter-tool-tip" style="color: #da5e60"></i>
                      } @else {
                        <i class="fas fa-square-info" style="color: #da5e60"></i>
                      }
                    }
                  </div>
                }
              </ng-template>
            }
          }
        </p-dropdown>
      } @else {
        <p-dropdown [options]="selectData" [optionValue]="inputObj?.optionValue" [optionLabel]="inputObj?.optionLabel"
                    appendTo="body" tabindex="0"
                    [showClear]="true" [filter]="inputObj?.filter"
                    [filterBy]="(inputObj?.filterBy) ? inputObj?.filterBy : inputObj?.optionLabel" placeholder="--"
                    [virtualScroll]="virtScroll" virtualScrollItemSize="30"
                    formControlName="fcnSelect" class="select-dropdown no-click" (onChange)="emitDataToParent(true)"
                    (onClear)="emitDataToParent(true)">
        </p-dropdown>
      }
      @if (registerFormControl.fcnSelect.dirty && registerFormControl.fcnSelect.touched &&
      registerFormControl.fcnSelect.errors?.required) {
        <span class="p-error">'{{ inputObj?.labelText }}' must be selected</span>
      }
    </div>
  </form>
</div>
