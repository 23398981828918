import {take, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PageModel} from '../models/page.model';
import {InterfaceObjectsService} from './interface-objects.service';
import {pagePermissionsUtility} from '../shared/utilities/page-permissions.utility';
import {MessageService} from 'primeng/api';
import {Subject} from 'rxjs';
import {InterfaceObjectItemPermissions, InterfaceObjectsModel} from '../models/interface-objects.model';
import {PrimeTableFilterModel} from '../models/table-filter.model';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  currentFilter = new PrimeTableFilterModel();
  public _pagePermissionsUtility = pagePermissionsUtility;

  private ngUnsubscribe = new Subject();

  constructor(private router: Router,
              private interfaceObjectsService: InterfaceObjectsService,
              private messageService: MessageService) {
  }

  getPageType(route, config): PageModel {
    const pageInfo = {} as PageModel;
    if (this.router.url.includes('/edit/')) {
      pageInfo.isEdit = true;
      pageInfo.isPage = true;
      pageInfo.ContentID = parseInt(route.id, 10);
      if (route.orgid) {
        pageInfo.OrganizationID = parseInt(route.orgid, 10);
      }
    } else if (this.router.url.includes('/add')) {
      pageInfo.isEdit = false;
      pageInfo.isPage = true;
      pageInfo.ContentID = parseInt(route.id, 10);
      if (route.orgid) {
        pageInfo.OrganizationID = parseInt(route.orgid, 10);
      }
    } else {
      pageInfo.isPage = false;
    }
    if (config && config.data && config.data.isDialog) {
      pageInfo.ContentID = config.data.id;
      pageInfo.OrganizationID = null;
      pageInfo.DBEntityID = config.data.DBEntityID;
      pageInfo.DBEntity = config.data.DBEntity;
      pageInfo.canTabWrite = config.data.canTabWrite;
      pageInfo.isEdit = config.data.isEdit;
      pageInfo.isDialog = config.data.isDialog;
      pageInfo.header = config.data.header;
      pageInfo.userCall = config.data.userCall;
    }
    return pageInfo;
  }

  getPagePermissions(InterfaceObjectEnum: string, ignoreReroute?: boolean) {
    return new Promise((resolve, reject) => {
      let res;
      if (sessionStorage.getItem('pagePermissions') === null || sessionStorage.getItem('pagePermissions') === undefined) {
        this.interfaceObjectsService.getAppList()
          .pipe(take(1))
          .subscribe({
            next: (result) => {
              sessionStorage.setItem('pagePermissions', JSON.stringify(result));
              res = JSON.parse(sessionStorage.getItem('pagePermissions'));
              this.formatData(res, InterfaceObjectEnum, ignoreReroute).then((permissions) => {
                resolve(permissions);
              });
            }
          });
      } else {
        res = JSON.parse(sessionStorage.getItem('pagePermissions'));
        this.formatData(res, InterfaceObjectEnum, ignoreReroute).then((permissions) => {
          resolve(permissions);
        });
      }
    });

  }

  formatData(res, InterfaceObjectEnum: string, ignoreReroute?: boolean) {
    return new Promise((resolve, reject) => {
      let permissions: InterfaceObjectItemPermissions;
      if (!this._pagePermissionsUtility.checkPageRead(res, InterfaceObjectEnum)) {
        if (ignoreReroute === null || ignoreReroute === undefined || ignoreReroute === false) {
          this.messageService.add({
            severity: 'error',
            summary: 'Permission Denied',
            detail: 'You do not have permission to ' + window.location.pathname
          });
          this.router.navigateByUrl('dashboard');
        } else {
          // check for existence in permission list
          const i: InterfaceObjectsModel = res.find(x => x.ObjectEnumerator === InterfaceObjectEnum);
          if (i === null || i === undefined) {
            permissions = null;
          } else {
            permissions = {
              Write: false,
              CanAdd: this._pagePermissionsUtility.checkPageAdd(res, InterfaceObjectEnum),
              CanExportPii: this._pagePermissionsUtility.checkPageExportPii(res, InterfaceObjectEnum)
            };
          }
        }
      } else {
        // check for existence in permission list
        const i: InterfaceObjectsModel = res.find(x => x.ObjectEnumerator === InterfaceObjectEnum);
        if (i === null || i === undefined) {
          permissions = null;

        } else {
          permissions = {
            Write: this._pagePermissionsUtility.checkPageWrite(res, InterfaceObjectEnum),
            CanAdd: this._pagePermissionsUtility.checkPageAdd(res, InterfaceObjectEnum),
            CanExportPii: this._pagePermissionsUtility.checkPageExportPii(res, InterfaceObjectEnum)
          };

        }
      }
      resolve(permissions);
    });


  }


  // getPagePermissions(InterfaceObjectEnum: string, ignoreReroute?: boolean) {
  //   return new Promise((resolve, reject) => {
  //     console.log(InterfaceObjectEnum);
  //     // this.currentFilter.filters = {
  //     //   ObjectEnumerator: [
  //     //     {
  //     //       value: InterfaceObjectEnum,
  //     //       matchMode: 'equals',
  //     //       operator: 'and'
  //     //     }
  //     //   ]
  //     // };
  //     this.interfaceObjectsService.getAppFiltered(InterfaceObjectEnum)
  //       .pipe(takeUntil(this.ngUnsubscribe))
  //       .subscribe({next:(res) => {
  //         let permissions: InterfaceObjectItemPermissions;
  //         if (!this._pagePermissionsUtility.checkPageRead(res, InterfaceObjectEnum)) {
  //           if (ignoreReroute === null || ignoreReroute === undefined || ignoreReroute === false) {
  //             this.messageService.add({severity: 'error', summary: 'Permission Denied', detail: 'You do not have permission to ' + window.location.pathname});
  //             this.router.navigateByUrl('dashboard');
  //           } else {
  //             // check for existence in permission list
  //             const i: InterfaceObjectsModel = res.find(x => x.ObjectEnumerator === InterfaceObjectEnum);
  //             if (i === null || i === undefined) {
  //               permissions = null;
  //             } else {
  //               permissions = {
  //                 Write: false,
  //                 CanAdd: this._pagePermissionsUtility.checkPageAdd(res, InterfaceObjectEnum)
  //               };
  //             }
  //           }
  //         } else {
  //           // check for existence in permission list
  //           const i: InterfaceObjectsModel = res.find(x => x.ObjectEnumerator === InterfaceObjectEnum);
  //           if (i === null || i === undefined) {
  //             permissions = null;
  //           } else {
  //             permissions = {
  //               Write: this._pagePermissionsUtility.checkPageWrite(res, InterfaceObjectEnum),
  //               CanAdd: this._pagePermissionsUtility.checkPageAdd(res, InterfaceObjectEnum)
  //             };
  //           }
  //         }
  //         resolve(permissions);
  //       }, error:
  //       () => {
  //         reject(null);
  //       }});
  //   });
  // }

  getTabPermissions(InterfaceObjectEnum: string) {
    return new Promise((resolve, reject) => {
      this.interfaceObjectsService.getObjectListByApp(InterfaceObjectEnum)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            resolve(res);
          }, error: () => {
            reject(null);
          }
        });
    });
  }
}
