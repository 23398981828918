import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { ContextService } from '../../services/context.service';

@Injectable({
  providedIn: 'root'
})
export class BlobStorageService {

  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  //TODO - replace with resources.service - getContainerSAS
  getBlobToken(): Observable<string> {
    return this.http.get<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/resource/containersas/thumbnail`);
  }
}
