<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <p>Send rejection reason to student</p>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12">
    <textarea pInputTextarea autoResize="autoResize" [rows]="10" [(ngModel)]="rejectionReason" maxlength="256"></textarea>
  </div>
  <div class="col-md-12">
    <div class="margin-top-15"><b>Character limit: 256. Remaining:</b> {{messageCount}}.</div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15 text-align-right">
    <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
    (click)="cancel()"></button>
    @if (!isSaving) {
      <button pButton label="Send" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Send" tooltipPosition="bottom"
      (click)="processData()" [className]="'inline-button'"></button>
    } @else {
      <button pButton label="Send" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
        <i class="pi pi-spin pi-spinner save-spinner"></i>
      </button>
    }
  </div>
</div>
