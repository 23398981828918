import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AttributesService} from '../../../../../services/attributes.service';
import {NotZeroValidation, transformDateTimeToDateOnly, transformDateTimeToTimeOnly} from '../../../../utilities/form.utility';
import {AttributeByEntityModel, AttributeModel, AttributesValueByEntityGridModel, CreateUpdateAttributeValueModel} from '../../../../../models/attributes.model';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';
import {ContextService} from '../../../../../services/context.service';

@Component({
  selector: 'app-custom-fields-form',
  templateUrl: './custom-fields-form.component.html',
  styleUrls: ['./custom-fields-form.component.scss']
})
export class CustomFieldsFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  customFields: AttributeByEntityModel[];
  selectedAttribute: AttributeModel;
  attribute = {} as CreateUpdateAttributeValueModel;
  currentFilter = new PrimeTableFilterModel();
  isDirty: boolean;
  isSaving: boolean = false;
  dataTotal: number = 0;
  fieldType: string;
  existingMessage: boolean = false;
  @Input() usedCustomFields: number[];
  @Input() category: string;
  @Input() responseentity: string;
  @Input() isEdit: boolean;
  @Input() PersonID: number;
  @Input() OrgRefID: number;
  @Input() canTabWrite: boolean;
  @Input() attributeId: number;
  @Input() attributeValue: string;
  @Output() closeAddEdit = new EventEmitter<boolean>();
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private formBuilder: FormBuilder, private contextService: ContextService,
              private attributesService: AttributesService, public pageRowCountUtility: PageRowCountUtility
  ) {
    this.mainForm = this.formBuilder.group({
      customField: new FormControl(null, [Validators.required, NotZeroValidation()]),
      value: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.getAttributes();
    if (!this.isEdit) {
      this.getCustomFieldValue();
    }
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_SaveReminder')?.Value !== 'No') {
      this.mainForm.valueChanges.subscribe({
        next: () => {
          if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
            this.existingMessage = true;
            this.openDialog();
          }
        }
      });
    }
    if (this.canTabWrite) {
      this.mainForm.enable();
    } else {
      this.mainForm.disable();
    }
  }

  getCustomFieldValue(): void {
    this.mainForm.get('customField').valueChanges.subscribe({
      next: (value) => {
        if (value) {
          const selectedField: AttributeByEntityModel = this.customFields.find(x => x.CustomFieldID === value);
          if (selectedField) {
            this.fieldType = selectedField.DataTypeEnumerator;
            const id = selectedField.CustomFieldID;
            if (this.fieldType === 'PICKLIST') {
              this.attributesService.getAttribute(id)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                  next: (res) => {
                    this.selectedAttribute = res;
                    this.mainForm.get('value').setValue(res.Options[0].Description);
                  }
                });
            }
          }
        }
      }
    });
  }

  ngOnChanges(): void {
    if (this.isEdit) {
      this.attributesService.getAttribute(this.attributeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.selectedAttribute = res;
            this.initForm();
            this.getCustomFieldValue();
          }
        });
      if (this.canTabWrite) {
        this.mainForm.enable();
      } else {
        this.mainForm.disable();
      }
    }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Custom Field data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  setOptions(event: any) {

  }

  getAttributes(): void {
    switch (true) {
      case this.PersonID !== undefined:
        this.attributesService.getPersonAvailableAttributes(this.PersonID, this.category)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.customFields = res;
              this.customFields.unshift({
                CustomFieldID: 0,
                DataTypeEnumerator: null,
                Description: '--',
                FilterDataTypeEnumerator: null,
                Rank: 1,
                ResponseDBEntityDescription: null,
                Shared: false
              });
              if (!this.isEdit) {
                this.usedCustomFields.forEach(field => {
                  const index = this.customFields.findIndex(x => x.CustomFieldID === field);
                  this.customFields.splice(index, 1);
                });
              }
            }
          });
        break;
      case this.OrgRefID !== undefined:
        this.attributesService.getOrganizationReferenceAvailableAttributes(this.OrgRefID, this.category, this.responseentity)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.customFields = res;
              this.customFields.unshift({
                CustomFieldID: 0,
                DataTypeEnumerator: null,
                Description: '--',
                FilterDataTypeEnumerator: null,
                Rank: 1,
                ResponseDBEntityDescription: null,
                Shared: false
              });
              if (!this.isEdit) {
                this.usedCustomFields.forEach(field => {
                  const index = this.customFields.findIndex(x => x.CustomFieldID === field);
                  this.customFields.splice(index, 1);
                });
              }
            }
          });
        break;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initForm(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.mainForm.get('customField').setValue(this.selectedAttribute.ID);
    this.fieldType = this.selectedAttribute.DataTypeEnumerator;

    switch (this.fieldType) {
      case 'DATE':
        if (this.attributeValue) {
          // const newDate = new Date(this.attributeValue);
          // this.mainForm.get('value').setValue(newDate);
          this.mainForm.get('value').setValue(this.attributeValue.substring(5, 7) + '/' + this.attributeValue.substring(8, 10) + '/' + this.attributeValue.substring(0, 4));
        }
        break;
      case 'TIME':
        if (this.attributeValue) {
          const attributeTime: Date = new Date(this.attributeValue);
          this.mainForm.get('value').setValue(attributeTime);
        }
        break;
      default:
        this.mainForm.get('value').setValue(this.attributeValue);
        break;
    }
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      this.attribute.CustomFieldID = this.mainForm.get('customField').value;
      switch (this.fieldType) {
        case 'CHECKBOX':
          this.attribute.Value = (this.mainForm.get('value').value === 'Yes') ? 'Yes' : null;
          break;
        case 'DATE':
          this.attribute.Value = transformDateTimeToDateOnly(this.mainForm.get('value').value);
          break;
        case 'TIME':
          this.attribute.Value = transformDateTimeToTimeOnly(this.mainForm.get('value').value);
          break;
        default:
          this.attribute.Value = this.mainForm.get('value').value;
          break;
      }
      //this.eventInstance.EndTime_T = transformDateTimeToTimeOnly(this.mainForm.get('endTime').value);
      this.saveForm();
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Missing Data',
        detail: 'No data was saved, the form is currently incomplete.'
      });
      this.existingMessage = false;
    }
  }

  saveForm(): void {
    switch (true) {
      case this.PersonID !== undefined:
        if (!this.isEdit) {
          this.attributesService.createPersonValue(this.PersonID, this.attribute)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'Custom field has been saved'
                });
                this.closeAddEdit.emit();
                this.isSaving = false;
              }, error: () => {
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
                this.isSaving = false;
              }
            });
        } else {
          this.attributesService.getPersonValuePrimeNG('DATA', this.PersonID, this.category, this.currentFilter)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res: AttributesValueByEntityGridModel) => {
                const customResponseID: number = res.Data.filter(x => x.CustomFieldID === this.attribute.CustomFieldID)[0].CustomResponseID;
                if (customResponseID) {
                  this.attributesService.updateAttributeValue(customResponseID, this.attribute)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe({
                      next: () => {
                        this.confirmationService.close();
                        this.messageService.add({
                          severity: 'success', summary: 'Success',
                          detail: 'Custom field has been updated'
                        });
                        this.closeAddEdit.emit();
                        this.isSaving = false;
                        this.existingMessage = false;
                      }, error: () => {
                        this.confirmationService.close();
                        this.messageService.add({
                          severity: 'error', summary: 'Error',
                          detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                        });
                        this.isSaving = false;
                        this.existingMessage = false;
                      }
                    });
                }
              }
            });
        }
        break;
      case this.OrgRefID !== undefined:
        if (!this.isEdit) {
          this.attributesService.createOrganizationReferenceValue(this.OrgRefID, this.attribute)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.messageService.add({
                  severity: 'success', summary: 'Success',
                  detail: 'Custom field has been saved'
                });
                this.closeAddEdit.emit();
                this.isSaving = false;
              }, error: () => {
                this.messageService.add({
                  severity: 'error', summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
                this.isSaving = false;
              }
            });
        } else {
          this.getData();
        }
        break;
    }
  }

  getData(): void {
    this.getList();
    this.getTotal();
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.attributesService.getOrganizationReferenceValuePrimeNG('DATA', this.OrgRefID, this.category, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: AttributesValueByEntityGridModel) => {
          const customResponseID: number = res.Data.filter(x => x.CustomFieldID === this.attribute.CustomFieldID)[0].CustomResponseID;
          if (customResponseID) {
            this.attributesService.updateAttributeValue(customResponseID, this.attribute)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: () => {
                  this.messageService.add({
                    severity: 'success', summary: 'Success',
                    detail: 'Custom field has been updated'
                  });
                  this.closeAddEdit.emit();
                  this.isSaving = false;
                }, error: () => {
                  this.messageService.add({
                    severity: 'error', summary: 'Error',
                    detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                  });
                  this.isSaving = false;
                }
              });
          }
        }
      });
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.attributesService.getOrganizationReferenceValuePrimeNG('TOTAL', this.OrgRefID, this.category, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: AttributesValueByEntityGridModel) => {
          this.dataTotal = res.Total;
        }
      });
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  isValid(): boolean {
    return this.mainForm.valid;
  }
}
