import {Component, Input, OnInit} from '@angular/core';

import {CommunicationDistributionModel, CommunicationDistributionsGridModel, CommunicationModel} from '../../../../../models/communications.model';
import {take, takeUntil} from 'rxjs/operators';
import {CommunicationsService} from '../../../../../services/communications.service';
import {Subject} from 'rxjs';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {PageRowCountUtility} from '../../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-communications-form',
  templateUrl: './communications-form.component.html',
  styleUrls: ['./communications-form.component.scss']
})
export class CommunicationsFormComponent implements OnInit {
  loading: boolean = false;
  emailBody: string;
  textMessage: string;
  communication: CommunicationModel;
  cols: any[];
  communicationRecipients: CommunicationDistributionsGridModel;
  communicationRecipientsList: CommunicationDistributionModel[];
  currentFilter = new PrimeTableFilterModel();
  dataTotal: number = 0;
  @Input() communicationId: number;
  showUnlayer: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private communicationsService: CommunicationsService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.cols = [
      {field: 'Target', header: 'Target', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Channel', header: 'Channel', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'TargetIdentifier', header: 'Target Identifier', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'DateDistributed_D', header: 'Date Distributed', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'Outcome', header: 'Outcome', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true}
    ];
    this.currentFilter.rows = null;
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    this.initForm();
  }

  initForm(): void {
    this.loading = true;
    if (this.communicationId) {
      this.communicationsService.getCommunicationDetails(this.communicationId)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.communication = res;
            switch (true) {
              case this.communication.Channel.toLowerCase() === 'email':
                // force a tags to open new window
                this.emailBody = this.communication.Body.replaceAll('<a ', '<a target="_blank"');
                break;
              case this.communication.Channel.toLowerCase() === 'text':
                this.textMessage = this.communication.Body;
                break;
              case this.communication.Channel.toLowerCase() === 'text message':
                this.textMessage = this.communication.Body;
                break;
            }
            this.getData();
            this.loading = false;
          }
        });
    } else {
      this.loading = false;
    }
  }

  getData() {
    this.getList();
    this.getTotal();
  }

  getList(): void {
    this.loading = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.communicationsService.getCommunicationDistributionsPrimeNG('DATA', this.communicationId, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.communicationRecipients = res;
          this.communicationRecipientsList = res.Data;
          this.loading = false;
        }, error: (e) => {
          this.loading = false;
          console.debug(e);
        }
      });
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.communicationsService.getCommunicationDistributionsPrimeNG('TOTAL', this.communicationId, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }
}
