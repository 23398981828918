import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProgramsService} from '../../../services/programs.service';
import {ProgramStudentFacetSummary, ProgramStudentHoursDialog} from '../../../models/programs.model';
import {removeKeysFromArray} from '../../utilities/data.utility';
import {exportData} from '../../utilities/list-table.utility';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {listFilterUtility} from '../../utilities/list-filter.utility';
import {PageRowCountUtility} from '../../utilities/page-row-count.utility';
import {DialogUtility} from '../../utilities/dialog.utility';

@Component({
  selector: 'app-program-student-hours-summary',
  templateUrl: './student-program-hours-dialog.component.html',
  styleUrls: ['./student-program-hours-dialog.component.scss']
})

export class StudentProgramHoursDialogComponent implements OnInit, OnDestroy, OnChanges {
  facetSummary: ProgramStudentFacetSummary[];
  dataTotal: number;
  loading: boolean;
  isExporting: boolean;
  currentFilter = new PrimeTableFilterModel();
  cols: any[];
  recordedHours_N: number;
  getListRequest;
  configDataLocal = {} as ProgramStudentHoursDialog;
  @Input() data: ProgramStudentHoursDialog;
  @Input() index;
  @Input() isPage: boolean;
  @Output() updateData = new EventEmitter<any>();
  private ngUnsubscribe = new Subject();

  constructor(public ref: DynamicDialogRef, private programsService: ProgramsService,
              private dialogUtility: DialogUtility, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    // this.currentFilter.filters = {};
    // this.currentFilter.sortOrder = 1;
    // this.currentFilter.rows = 25;
    this.configDataLocal = null;
    this.updateData.emit();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isPage && this.data) {
      this.configDataLocal = this.data;
      this.loadPage();
      // } else {
      //this.loadPage();
    }
  }

  loadPage() {
    this.recordedHours_N = this.configDataLocal.RecordedHours_N;
    this.cols = [
      // { field: 'FacetTypeDescription', header: 'Facet Type', columnType: 'text', displayType: 'text', matchMode: 'contains', visible: true },
      {
        field: 'PeriodStartDate',
        header: 'Start Date',
        columnType: 'date',
        displayType: 'date',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'PeriodEndDate',
        header: 'End Date',
        columnType: 'date',
        displayType: 'date',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'PeriodModifiedDate',
        header: 'Last Modified',
        columnType: 'date',
        displayType: 'date',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'PeriodReceivedDate',
        header: 'Submitted',
        columnType: 'date',
        displayType: 'date',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'PeriodApprovedDate',
        header: 'Approved',
        columnType: 'date',
        displayType: 'date',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'ManagerName',
        header: 'Manager',
        columnType: 'text',
        displayType: 'text',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'AllocatedHours',
        header: 'Allocated Hours',
        columnType: 'numeric',
        displayType: 'numeric',
        matchMode: 'contains',
        visible: true
      },
      {
        field: 'UnallocatedHours',
        header: 'Unallocated Hours',
        columnType: 'numeric',
        displayType: 'numeric',
        matchMode: 'contains',
        visible: true
      }
    ];
    // load filters
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    this.initFilters('PersonID', this.configDataLocal.PersonID, 'equals', 'and');
    this.initFilters('ProgramStudentID', this.configDataLocal.ProgramStudentID, 'equals', 'and');
    this.initFilters('FacetTypeID', this.configDataLocal.FacetTypeID, 'equals', 'and');
    this.loading = true;
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  loadTable(event?) {
    if (event) {
      this.initFilters('PersonID', this.configDataLocal.PersonID, 'equals', 'and');
      this.initFilters('ProgramStudentID', this.configDataLocal.ProgramStudentID, 'equals', 'and');
      this.initFilters('FacetTypeID', this.configDataLocal.FacetTypeID, 'equals', 'and');
      this.currentFilter.rows = event.rows;
      this.currentFilter.first = event.first;
      this.currentFilter.sortOrder = event.sortOrder;
      this.getData();
    } else {
      this.initFilters('PersonID', this.configDataLocal.PersonID, 'equals', 'and');
      this.initFilters('ProgramStudentID', this.configDataLocal.ProgramStudentID, 'equals', 'and');
      this.initFilters('FacetTypeID', this.configDataLocal.FacetTypeID, 'equals', 'and');
      this.currentFilter.sortOrder = 1;
      this.getData();
    }

  }

  getData() {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.loading = true;
    this.getListRequest = this.programsService.getProgramStudentFacetSummaryPrimeNG(this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.facetSummary = res.Data;
          this.dataTotal = res.Total;
          this.loading = false;
        }
      });
  }

  closeDialog() {
    this.ref.destroy();
  }

  cancel() {
    this.closeDialog();
  }

  refresh(): void {
    this.loadTable();
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.facetSummary));
        const dataToExport = removeKeysFromArray(arr, ['PersonID', 'ProgramID', 'FacetTypeID', 'FacetTypeRank', 'PeriodID', 'ProgramStudentID', 'ProgramOrganizationID']);
        exportData('hours-summary', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.programsService.getProgramStudentFacetSummaryPrimeNG(exportFilter)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['PersonID', 'ProgramID', 'FacetTypeID', 'FacetTypeRank', 'PeriodID', 'ProgramStudentID', 'ProgramOrganizationID']);
              exportData('hours-summary', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
