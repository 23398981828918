import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { TrainingModuleTemplatesGridModel, TrainingModuleTemplateModel } from '../models/training-modules.model';

@Injectable({
  providedIn: 'root',
})
export class TrainingModuleTemplatesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getTrainingModuleTemplatesPrimeNG(dataMode: string, filterData): Observable<TrainingModuleTemplatesGridModel> {
    return this.http.post<TrainingModuleTemplatesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingmodule/grid/?mode=${dataMode}`, filterData);
  }

  getTrainingModuleTemplate(classModuleTemplateId: number): Observable<TrainingModuleTemplateModel> {
    return this.http.get<TrainingModuleTemplateModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingmodule/${classModuleTemplateId}`);
  }

  createTrainingModuleTemplate(modelData: TrainingModuleTemplateModel): Observable<TrainingModuleTemplateModel> {
    return this.http.post<TrainingModuleTemplateModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingmodule/create`, modelData);
  }

  updateTrainingModuleTemplate(classModuleTemplateId: number, modelData: TrainingModuleTemplateModel): Observable<TrainingModuleTemplateModel> {
    return this.http.put<TrainingModuleTemplateModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingmodule/${classModuleTemplateId}`, modelData);
  }

  deleteTrainingModuleTemplate(classModuleTemplateId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingmodule/remove/${classModuleTemplateId}`);
  }

  updateTrainingModuleTemplateStatus(trainingModuleId: number, trainingModuleStatusId: number ): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/trainingmodule/${trainingModuleId}/setstatus/${trainingModuleStatusId}`, null);
  }
}
