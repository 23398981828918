@if (!dataLoaded) {
  <span class="fa fa-spinner list-spinner"></span>
}
<form [formGroup]="mainForm" class="margin-top-10 padding-bottom-50">
  @if (dataLoaded) {
    <div class="p-grid p-fluid margin-top-10">
      @if (AUTOPAYSIGNUPNOTICE !== '') {
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div [innerHTML]="AUTOPAYSIGNUPNOTICE"></div>
          <p-divider class="dark-divider"></p-divider>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h2>Payment Method</h2>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
        <div class="p-grid p-fluid">
          <div class="col-md-12 col-sm-12 col-xs-12 radio-container">
            <ng-container>
              <div class="p-field-radiobutton">
                <p-radioButton value="0" formControlName="paymentMethodID" [styleClass]="'radio-input'" class="p-mr-2"
                               (click)="getPaymentMethodData()" label="I do NOT want to make automatic payments"
                               tabindex="0"></p-radioButton>
                <br/>
              </div>
            </ng-container>
            @for (paymentMethod of paymentMethodList; track paymentMethod) {
              @switch (paymentMethod.MethodType) {
                @case ('CC') {
                  <div class="p-field-radiobutton">
                    <p-radioButton value="{{ paymentMethod.PaymentMethodId }}" formControlName="paymentMethodID"
                                   [styleClass]="'radio-input'" class="p-mr-2" (click)="getPaymentMethodData()"
                                   tabindex="0"></p-radioButton>
                    @if (paymentMethod.CCCardType === 'Visa Card' || paymentMethod.CCCardType === 'Visa') {
                      <img width="36" height="23" class="cc-image p-mr-1" ngSrc="assets/images/credit-cards/visa.svg"
                           pTooltip="Visa" tooltipPosition="bottom" alt="" [priority]="true"/>
                    }
                    @if (paymentMethod.CCCardType === 'MasterCard') {
                      <img width="36" height="23" class="cc-image p-mr-1"
                           ngSrc="assets/images/credit-cards/mastercard.svg" pTooltip="MasterCard"
                           tooltipPosition="bottom" alt="" [priority]="true"/>
                    }
                    @if (paymentMethod.CCCardType === 'Discover Card' || paymentMethod.CCCardType === 'Discover') {
                      <img width="36" height="23" class="cc-image p-mr-1"
                           ngSrc="assets/images/credit-cards/discover.svg"
                           pTooltip="Discover" tooltipPosition="bottom" alt="" [priority]="true"/>
                    }
                    @if (paymentMethod.CCCardType === 'American Express' || paymentMethod.CCCardType === 'Amex') {
                      <img width="36" height="23" class="cc-image p-mr-1" ngSrc="assets/images/credit-cards/amex.svg"
                           pTooltip="American Express" tooltipPosition="bottom" alt="" [priority]="true"/>
                    }
                    <label>{{ paymentMethod.MethodNickname }} &ndash; Credit card ending in
                      **** {{ paymentMethod.AccountNumber }}</label>
                  </div>
                }
                @case ('ACH') {
                  <div class="p-field-radiobutton">
                    <p-radioButton value="{{ paymentMethod.PaymentMethodId }}" formControlName="paymentMethodID"
                                   [styleClass]="'radio-input'" class="p-mr-2" (click)="getPaymentMethodData()"
                                   label="{{ paymentMethod.ACHBankName }} *{{ paymentMethod.AccountNumber }} ({{ paymentMethod.MethodNickname}})"
                                   tabindex="0"></p-radioButton>
                  </div>
                }
              }
            }
          </div>
          @if (isAutoPay) {
            <div class="col-md-12 col-sm-12 col-xs-12">
              <h2 class="p-mt-6">Day of the month</h2>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 input-container">
              <app-select class="full-width" [inputObj]="inputObjPaymentDay"
                          (emitData)="getPaymentDayData($event)"></app-select>
            </div>
          }
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 text-align-right">
        @if (paymentMethodList.length !== 0) {
          <div class="action-buttons float-right margin-top-10">
            @if (!isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-refresh" pTooltip="Cancel" tooltipPosition="bottom"
                      (click)="cancel()"></button>
            }
            @if (!isSaving) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                      tooltipPosition="bottom"
                      (click)="processData()" [className]="'inline-button'"
                      [disabled]="!isFormValid()"></button>
            } @else {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        }
      </div>
    </div>
  }
</form>
