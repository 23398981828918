import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {OrganizationModel} from '../../../models/organizations.model';
import {OrganizationService} from '../../../services/organizations.service';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Location} from '@angular/common';
import {listFilterUtility} from '../../utilities/list-filter.utility';
import {PageModel} from '../../../models/page.model';
import {ActivatedRoute} from '@angular/router';
import {PageService} from '../../../services/page.services';
import {PageRowCountUtility} from '../../utilities/page-row-count.utility';

@Component({
  selector: 'app-add-employer-form',
  templateUrl: './add-employer-form.component.html',
  styleUrls: ['./add-employer-form.component.scss']
})

export class AddEmployerFormComponent implements OnInit, OnChanges, OnDestroy {
  InterfaceObjectEnum: string = 'EMPPROFILE';
  mainForm: FormGroup;
  inputObjState: GenericSelectModel;
  inputObjStatus: GenericSelectModel;
  inputObjCountry: GenericSelectModel;
  setStateId: number;
  setStatusId: number;
  setCountryId: number;
  loading: boolean = false;
  isDirty: boolean;
  isSaving: boolean = false;
  employer = {} as OrganizationModel;
  setOrganizationId: number;
  inputObjOrganization: GenericSelectModel;
  inputObjType: GenericSelectModel;
  existingMessage: boolean = false;
  currentFilter = new PrimeTableFilterModel();
  organizationList: OrganizationModel[];
  selorganization: OrganizationModel[] = [];
  dataTotal: number = 0;
  cols: any[];
  isPage: boolean = false;
  isDialog: boolean = false;
  employerFilter: string;
  showTable: boolean = false;
  createNew: boolean = false;
  showOtherEmployers: boolean;
  @Input() isContractEmployer: boolean = false;
  @Input() isEdit: boolean;
  @Input() canPageWrite: boolean;
  @Input() ID: number;
  @Input() organizationId: number;
  @Input() isOrganize: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  getListRequest;
  getTotalRequest;
  private pageInfo = {} as PageModel;
  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private organizationService: OrganizationService, private ref: DynamicDialogRef,
              private config: DynamicDialogConfig, private lookupsDataService: LookupsDataService,
              private messageService: MessageService, private tenantConfigDataService: TenantConfigDataService,
              private confirmationService: ConfirmationService, private location: Location,
              private route: ActivatedRoute, private pageService: PageService,
              public pageRowCountUtility: PageRowCountUtility) {
    this.mainForm = this.formBuilder.group({
      organization: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.cols = [
      {field: 'Name', header: 'Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'City', header: 'City/Town', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'StateProvince', header: 'State/Province', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Status', header: 'Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ActiveRosterCount_N', header: 'Active Members', columnType: 'numeric', matchMode: 'gte', displayType: 'numeric', visible: true},
      {field: 'WorkSiteCount_N', header: '# of Locations', columnType: 'numeric', matchMode: 'gte', displayType: 'numeric', visible: true}
    ];
    this.route.params.pipe().subscribe({
      next: (res) => {
        this.pageInfo = this.pageService.getPageType(res, this.config);
        if ((this.pageInfo.isPage || this.pageInfo.isDialog) && !this.isContractEmployer) {
          this.isEdit = this.pageInfo.isEdit;
          this.isPage = this.pageInfo.isPage;
          this.isDialog = this.pageInfo.isDialog;
          this.loadForm();
        }
      }
    });
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isContractEmployer) {
      if (this.isContractEmployer) {
        this.setOrganizationId = this.organizationId;
        this.isEdit = false;
        // this.lookupsDataService.getOrganizationStatusesLookupData('employer').then((lookupData: any[]) => {
        //   this.inputObjStatus.data = lookupData;
        //   this.inputObjStatus = Object.assign({}, this.inputObjStatus);
        //   this.setStatusId = this.inputObjStatus.data.find(x => x.Description === 'Organize Employer').ID;
        // });
      }
    }
    this.loadForm();
  }

  loadForm(): void {
    this.mainForm.reset();
    this.initOrganization(this.isContractEmployer, this.InterfaceObjectEnum, false);
    if (this.canPageWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  initForm(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  initOrganization(disable: boolean, paramComponentEnum: string, paramMembershipOnly: boolean): void {
    this.inputObjOrganization = {
      labelText: 'Organization',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: (!this.setOrganizationId),
      initSelected: this.setOrganizationId,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsByObjectAccessLookupData(paramComponentEnum, paramMembershipOnly).then((lookupData) => {
      this.inputObjOrganization.data = lookupData;
      this.inputObjOrganization = Object.assign({}, this.inputObjOrganization);
    });
  }

  setOrganizationData(event:any): void {
    if (event && event[0]) {
      this.setOrganizationId = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setOrganizationId = 0;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('organization').setValue(this.setOrganizationId);
    if (this.isContractEmployer) {
      this.setOrganizationId = this.organizationId;
    }
  }

  processAssignments() {
    if (this.selorganization && this.selorganization.length > 0) {
      this.saveAssignments(this.selorganization);
    }
  }

  saveAssignments(ids: OrganizationModel[]) {
    if (ids) {
      ids.forEach(emp => {
        this.organizationService.addEmployerAssignment(emp.ID, [this.setOrganizationId])
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.messageService.add({
                severity: 'success', summary: 'Success',
                detail: 'The selected employers have been linked to the organization'
              });
              if (!this.isContractEmployer) {
                this.closeAddEdit.emit({employer: res, isContractEmployer: true});
              } else {
                this.closeAddEdit.emit({employer: res, isContractEmployer: false});
              }
              this.isSaving = false;
            }, error: (e) => {
              this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
              this.isSaving = false;
              console.debug(e);
            }
          });
      });
    }
  }

  setFilter(): void {
    this.organizationList = [];
    this.dataTotal = 0;
    this.selorganization = [];
    if (this.employerFilter && this.employerFilter !== '') {
      this.initFilters('Name', this.employerFilter, 'contains', 'and');
    } else {
      this.showTable = false;
    }
  }


  filterItems(): void {
    this.loadTable(this.currentFilter);
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }

  loadTable(event?): void {
    this.getList();
    this.getTotal();
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.loading = true;
    this.organizationList = [];
    this.getListRequest = this.organizationService
      .getEmployerNotAssignedPickerPrimeNG('DATA', this.setOrganizationId, this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res && this.employerFilter && this.employerFilter !== '') {
            this.organizationList = res.Data;
          }
          this.loading = false;
        }, error: (e) => {
          this.loading = false;
          console.log(e);
        }
      });
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.getTotalRequest = this.organizationService
      .getEmployerNotAssignedPickerPrimeNG('TOTAL', this.setOrganizationId, this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res && this.employerFilter && this.employerFilter !== '') {
            this.dataTotal = res.Total;
          }
        }, error: (e) => {
          console.log(e);
        }
      });
  }

  // getAllEmployers() {
  //   this.organizationService.getEmployersPrimeNG('TOTAL', this.currentFilter, this.setOrganizationId)
  //     .pipe(take(1))
  //     .subscribe({next:(res) => {
  //       console.log(res);
  //       if(res.Total > 0) {
  //         this.showOtherEmployers = true;
  //       }
  //     }});
  // }

  addExistingContractor() {
    this.ref.close('addExisting');
  }

  back(): void {
    this.confirmationService.close();
    this.location.back();
  }
}
