import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { ProspectsGridModel } from '../models/prospects.model';
// import {PrimeTableFilterModel} from '../models/table-filter.model';

@Injectable({
  providedIn: 'root',
})
export class ProspectsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getProspectsPrimeNG(dataMode: string, filterData, rosterQueryId?: number): Observable<ProspectsGridModel> {
    const url = (rosterQueryId) ? rosterQueryId : 0;
    return this.http.post<ProspectsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/grid/${url}?mode=${dataMode}&perspectiveContext=ORGANIZE`, filterData);
  }

  // getProspectsPrimeNG(dataMode: string, filterData: PrimeTableFilterModel, rosterQueryId?: number): Observable<ProspectsGridModel> {
  //   const url = (rosterQueryId) ? rosterQueryId : 0;
  //   return this.http.post<ProspectsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/grid/${url}?mode=${dataMode}`, filterData);
  // }

  getProspectsExpandedExport(filterData, rosterQueryId?: number): Observable<ArrayBuffer> {
    const url = (rosterQueryId) ? rosterQueryId : 0;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http.post<ArrayBuffer>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/export/primeng/${url}?filetype=xlsx`, filterData, requestOptions);
  }

  // getProspectAttributesKGrid(rosterQueryId?: number): Observable<ProspectAttributesGridModel> {
  //   const url = (rosterQueryId) ? rosterQueryId : 0;
  //   return this.http.get<ProspectAttributesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/attributegrid/${url}`);
  // }
  //
  // getRosterPositionsKGrid(rosterQueryId?: number): Observable<ProspectPositionsGridModel> {
  //   const url = (rosterQueryId) ? rosterQueryId : 0;
  //   return this.http.get<ProspectPositionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/prospectroster/positiongrid/${url}`);
  // }
}
