import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../models/table-filter.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {StudentProgramsService} from '../../../../../services/student-programs.service';
import {StudentProgramFacetTypeListModel, StudentProgramModel} from '../../../../../models/student-programs.model';
import {GenericSelectModel} from '../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../services/lookups-data.service';
import {DialogService} from 'primeng/dynamicdialog';
import {NotZeroValidation, transformDateTimeToDateOnly} from '../../../../../shared/utilities/form.utility';
import {ProgramStudentIncomingHoursDialogComponent} from '../../../../../shared/components/program-student-incoming-hours-dialog/program-student-incoming-hours-dialog.component';
import {ProgramsService} from '../../../../../services/programs.service';
import {StudentProfileModel} from '../../../../../models/student-profile.model';
import {StudentProfileService} from '../../../../../services/student-profile.service';
import {hideFacetSummary} from '../../../../../shared/utilities/component-manager-utility';
import {ProgramStudentHoursByFacetTypeModel, ProgramStudentHoursDialog, ProgramStudentModel} from '../../../../../models/programs.model';
import {ProgramStudentHoursDialogComponent} from '../../../../../programs/programs-tabs/programs-tab-students/program-student-hours-dialog/program-student-hours-dialog.component';
import {PageRowCountUtility} from '../../../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-roster-student-programs-form',
  templateUrl: './roster-student-programs-form.component.html',
  styleUrls: ['./roster-student-programs-form.component.scss']
})
export class RosterStudentProgramsFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  studentProgram = {} as StudentProgramModel;
  isDirty: boolean;
  isSaving: boolean = false;
  amountType: string;
  startDate: Date;
  endDate: Date;
  StudentList: ProgramStudentModel[] = [];
  programHours: ProgramStudentHoursByFacetTypeModel;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() userCall: boolean;
  @Input() PersonID: number;
  @Input() programStudentId: number;
  @Input() programId: number;
  @Input() studentInfo: StudentProfileModel;
  approvedHoursTotal: number = 0;
  waitingForApprovalTotal: number = 0;
  rejectedHoursTotal: number = 0;
  currentFilter = new PrimeTableFilterModel();
  setStatusId: number;
  setTrainingCenterId: number;
  setStudentprogramStudentId: number;
  setStudentGroupId: number;
  inputObjStatus: GenericSelectModel;
  inputObjTrainingCenter: GenericSelectModel;
  inputObjProgram: GenericSelectModel;
  inputObjStudentGroup: GenericSelectModel;
  hasIncomingCreditFacet: boolean = false;
  closedStatus: boolean = true;
  startDateRequired: boolean;
  timeEntry: StudentProgramFacetTypeListModel[] = [];
  existingHours: StudentProgramFacetTypeListModel[] = [];
  outgoingData = {} as ProgramStudentHoursDialog;
  showPeriodTabs: boolean;
  @Input() selectedStudent;
  @Input() selectedItemIndex: number;
  public _hideFacetSummary = hideFacetSummary;
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private formBuilder: FormBuilder,
              private studentProgramService: StudentProgramsService,
              private lookupsDataService: LookupsDataService,
              private dialogService: DialogService,
              private programService: ProgramsService,
              private studentProfileService: StudentProfileService,
              private programsService: ProgramsService, public pageRowCountUtility: PageRowCountUtility
  ) {
    this.mainForm = this.formBuilder.group({
      program: new FormControl(null, [Validators.required, NotZeroValidation()]),
      status: new FormControl(null, [Validators.required, NotZeroValidation()]),
      trainingCenter: new FormControl(null),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      studentGroup: new FormControl(null)
      // identifier: new FormControl(null)
    });
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnInit(): void {
    sessionStorage.removeItem('timeEntry');
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    if (!this.router.url.includes('/education')) {
      this.showPeriodTabs = true;
    }
  }

  ngOnChanges(): void {
    if (this.isEdit) {
      if (!this.userCall) {
        this.studentProgramService.getStudentProgram(this.programStudentId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.studentProgram = res;
              this.initForm();
            }
          });
      } else {
        this.studentProgramService.getUserStudentProgram(this.programStudentId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.studentProgram = res;
              this.initForm();
            }
          });
      }
      if (this.PersonID) {
        console.log(this.PersonID);
        console.log(this.selectedStudent);
        //  this.getHourData();
        //  this.getFacetSummaryHours();
      }
    } else {
      this.initStatus(false);
      this.initTrainingCenter(false);
      this.initPrograms(false);
      this.initStudentGroup(true, 0);
    }
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initForm(): void {
    this.closedStatus = this.studentProgram.ClosedStatus;
    this.setStatusId = this.studentProgram.TrainingStatusID;
    this.mainForm.get('status').setValue(this.studentProgram.TrainingStatusID);
    this.setStudentprogramStudentId = this.studentProgram.ProgramStudentID;
    this.programId = this.studentProgram.ProgramID;
    this.mainForm.get('program').setValue(this.studentProgram.ProgramID);
    this.setTrainingCenterId = this.studentProgram.OrganizationID;
    this.mainForm.get('trainingCenter').setValue(this.studentProgram.OrganizationID);
    if (this.studentProgram.StartDate_D) {
      this.startDate = new Date(this.studentProgram.StartDate_D);
      this.mainForm.get('startDate').setValue(this.startDate);
    }
    if (this.studentProgram.EndDate_D) {
      this.endDate = new Date(this.studentProgram.EndDate_D);
      this.mainForm.get('endDate').setValue(this.endDate);
    }
    this.setStudentGroupId = this.studentProgram.StudentGroupID;
    this.mainForm.get('studentGroup').setValue(this.studentProgram.StudentGroupID);
    this.manageEndDateValidity(this.closedStatus);
    // this.mainForm.get('identifier').setValue(this.studentProgram.Identifier);
    this.initStatus(!this.canTabWrite);
    this.initTrainingCenter(!this.canTabWrite);
    this.initPrograms(!this.canTabWrite || this.isEdit);
    this.initStudentGroup(!this.canTabWrite, this.programId);
  }

  initStatus(disable: boolean): void {
    this.inputObjStatus = {
      labelText: 'Status',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStatusId,
      data: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getTrainingStatusesLookupData('program').then((lookupData) => {
      this.inputObjStatus.data = lookupData;
      this.inputObjStatus = Object.assign({}, this.inputObjStatus);
    });
  }

  initPrograms(disable: boolean): void {
    this.inputObjProgram = {
      labelText: 'Program',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.programId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getProgramsLookupData().then((lookupData) => {
      this.inputObjProgram.data = lookupData;
      this.inputObjProgram = Object.assign({}, this.inputObjProgram);
    });
  }

  initTrainingCenter(disable: boolean): void {
    this.inputObjTrainingCenter = {
      labelText: 'Training Center',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setTrainingCenterId,
      data: null,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    this.lookupsDataService.getTrainingCentersLookupData().then((lookupData) => {
      this.inputObjTrainingCenter.data = lookupData;
      this.inputObjTrainingCenter = Object.assign({}, this.inputObjTrainingCenter);
    });
  }

  getStatusData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.setStatusId = event[0].ID;
      this.closedStatus = event[0].ClosedStatus;
    } else {
      this.setStatusId = 0;
      if (this.studentProgram) {
        this.closedStatus = this.studentProgram.ClosedStatus;
      } else {
        this.closedStatus = true;
      }
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('status').setValue(this.setStatusId);
    this.manageEndDateValidity(this.closedStatus);
  }

  getProgramData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.programId = event[0].ID;
      this.initStudentGroup(!this.canTabWrite, this.programId);
      this.programService.getProgram(this.programId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.lookupsDataService.getFacetsLookupData().then((lookupData: any[]) => {
              this.hasIncomingCreditFacet = lookupData.find(x => x.Description.includes('Incoming Credit'));
            });
          }
        });
    } else {
      this.programId = 0;
      this.initStudentGroup(true, this.programId);
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('program').setValue(this.programId);

  }

  getTrainingCenterData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.setTrainingCenterId = event[0].ID;
    } else {
      this.setTrainingCenterId = 0;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('trainingCenter').setValue(this.setTrainingCenterId);
  }

  initStudentGroup(disable: boolean, programId: number): void {
    console.log(programId);
    this.inputObjStudentGroup = {
      labelText: 'Student Group',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setStudentGroupId,
      disabled: disable,
      canWrite: this.canTabWrite
    };
    if (programId) {
      this.lookupsDataService.getProgramStudentGroupsLookupData(programId).then((lookupData) => {
        this.inputObjStudentGroup.data = lookupData;
        this.inputObjStudentGroup = Object.assign({}, this.inputObjStudentGroup);
      });
    }
  }

  getStudentGroupData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.setStudentGroupId = event[0].ID;
    } else {
      this.setStudentGroupId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('studentGroup').setValue(this.setStudentGroupId);
  }

  manageEndDateValidity(closedStatus: boolean): void {
    if (closedStatus === true) {
      this.mainForm.get('endDate').setValidators(Validators.required);
    } else {
      this.mainForm.get('endDate').setValidators(null);
    }
    this.mainForm.get('endDate').updateValueAndValidity();
  }

  // getHourData() {
  //   console.log('in get hour data');
  //   this.currentFilter.filters.PersonID = [];
  //   this.currentFilter.filters.PersonID.push({
  //     matchMode: 'equals',
  //     operator: 'and',
  //     value: this.PersonID
  //   });
  //
  //   this.programService.getProgramStudentsGridData(this.programId, this.currentFilter)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe({next:(res) => {
  //       this.StudentList = res.Data;
  //       this.StudentList.forEach(student => {
  //         student.FullName = student.FirstName + ' ' + student.LastName;
  //       });
  //       this.selectedStudent = this.StudentList[this.selectedItemIndex];
  //     }});
  //   console.log(this.StudentList);
  //   console.log(this.selectedStudent);
  //   if(this.selectedStudent === undefined) {
  //     console.log('in here');
  //     this.selectedStudent = this.StudentList[0];
  //   }
  // }

  processData(): void {
    this.isSaving = true;
    this.studentProgram.StartDate_D = transformDateTimeToDateOnly(this.mainForm.get('startDate').value);
    this.studentProgram.EndDate_D = transformDateTimeToDateOnly(this.mainForm.get('endDate').value);
    // this.studentProgram.Identifier = this.mainForm.get('identifier').value;
    this.studentProgram.TrainingStatusID = this.setStatusId;
    this.studentProgram.StudentGroupID = this.setStudentGroupId;
    this.studentProgram.OrganizationID = this.setTrainingCenterId;
    this.studentProgram.PersonID = this.PersonID;
    this.studentProgram.ProgramID = this.programId;
    this.saveForm();
  }

  saveForm(): void {
    if (!this.isEdit) {
      this.studentProgramService.createStudentProgram(this.studentProgram)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Program has been saved'
            });
            this.closeAddEdit.emit();
            this.isSaving = false;
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    } else {
      this.studentProgramService.updateStudentProgram(this.studentProgram.ProgramStudentID, this.studentProgram)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Program has been updated'
            });
            this.closeAddEdit.emit();
            this.isSaving = false;
          }, error: (e) => {
            this.messageService.add({
              severity: 'error', summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
            this.isSaving = false;
            console.debug(e);
          }
        });
    }
  }

  cancel(): void {
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm();
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  isValid() {
    return this.mainForm.valid;
  }

  openIncomingHours(): void {
    const ref = this.dialogService.open(ProgramStudentIncomingHoursDialogComponent, {
      data: {
        programId: this.programId,
      },
      header: 'Add Incoming Hours',
      width: '70%',
    });
    ref.onDestroy.pipe(take(1)).subscribe({
      next: () => {
        //having to use session storage due to primeng close() bug
        this.existingHours = JSON.parse(sessionStorage.getItem('timeEntry'));
        if (this.existingHours) {
          this.studentProgram.FacetTypeList = [];
          this.existingHours.forEach(timeEntry => {
            this.studentProgram.FacetTypeList.push({
                IncomingHours_N: timeEntry.IncomingHours_N,
                FacetTypeID: timeEntry.FacetTypeID,
                FacetTypeDescription: timeEntry.FacetTypeDescription,
              }
            );
          });
        }
      }
    });
  }

  clearIncomingHours(): void {
    this.studentProgram.FacetTypeList = [];
    this.startDateRequired = false;
    sessionStorage.removeItem('timeEntry');
  }

  getHoursTable(facets: StudentProgramFacetTypeListModel) {
    this.studentProfileService.getStudentProfile(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: StudentProfileModel) => {
          this.outgoingData = {
            type: facets.FacetTypeDescription,
            PersonID: res.PersonID,
            ProgramStudentID: this.programStudentId,
            FacetTypeID: facets.FacetTypeID,
            RecordedHours_N: facets.RecordedHours_N
          };
        }, error: (e) => {
          console.debug(e);
          if (e) {
            if (e.Message) {
              this.messageService.add({severity: 'warn', summary: 'Record Not Found', detail: e.Message});
            }
          }
        }
      });
  }

  getFacetSummaryHours() {
    this.programsService.getProgramStudentDetailsByFacetType(this.programStudentId, 'ojl')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.programHours = res;
          //ugly code not my fault bk
          // console.log(this.programHours);
          // this.programHours.Facets.forEach(facet => {
          //   if(facet.ApprovedHours) {
          //     this.approvedHoursTotal = this.approvedHoursTotal + facet.ApprovedHours;
          //   }
          //   if(facet.WaitingForApprovalHours) {
          //     this.waitingForApprovalTotal = this.waitingForApprovalTotal + facet.WaitingForApprovalHours;
          //   }
          //   if(facet.RejectedHours) {
          //     this.rejectedHoursTotal = this.rejectedHoursTotal + facet.RejectedHours;
          //   }
          // });
        }
      });
  }


  getDetailData(type, programStudentId, studentName) {
    const ref = this.dialogService.open(ProgramStudentHoursDialogComponent, {
      data: {
        type,
        id: programStudentId,
        isEdit: true,
        isDialog: true
      },
      header: studentName,
      width: '60%'
    });
  }

}
