import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PersonService} from '../../../../services/person.service';
import {take} from 'rxjs/operators';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})

export class ConfirmDeleteDialogComponent implements OnInit {
  cancelText: string;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private personService: PersonService,
              private messageService: MessageService) {

  }

  ngOnInit(): void {
    console.log(this.config.data);
  }

  closeDialog(deleted?): void {
    this.ref.close(deleted);
  }

  cancel(): void {
    this.closeDialog();
  }

  delete() {
    this.personService.deletePerson(this.config.data.id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: this.config.data.profile.FirstName + ' ' + this.config.data.profile.LastName + ' has been deleted.'
          });
          this.closeDialog('deleted');
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
            });
          }
        }
      });
  }
}
