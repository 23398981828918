import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MakePaymentService} from '../../../../services/make-payment.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PaymentMethodsService} from '../../../../services/payment-methods.service';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {CreatePaymentMethodACHModel, CreatePaymentMethodCCModel, PaymentMethodBankNameModel, PaymentMethodCardTypeModel, PaymentMethodCardValidModel, PaymentMethodModel} from '../../../../models/payment-methods.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {MessageService} from 'primeng/api';
import {BillingTypeModel} from '../../../../models/billing-types.model';
import {ConfirmValidationUtility} from '../../../utilities/confirm-validation.utility';
import {ContextService} from '../../../../services/context.service';
import {OrganizationPaymentPayeeModel, OrganizationPaymentSplitModel} from '../../../../models/organization-payments.model';
import {OrganizationService} from '../../../../services/organizations.service';
import {PctPaymentsService} from '../../../../services/pct-payment.service';
import {AddressModel} from '../../../../models/addresses.model';

@Component({
  selector: 'app-make-pct-payment-tab',
  templateUrl: './make-pct-payment-tab.component.html',
  styleUrls: ['./make-pct-payment-tab.component.scss']
})

export class MakePCTPaymentTabComponent implements OnInit, OnDestroy {
  mainForm: FormGroup;
  loading: boolean;
  isEmptyData: boolean = false;
  inputObjAutoPay: GenericSelectModel;
  inputObjState: GenericSelectModel;
  inputObjCountry: GenericSelectModel;
  setAutoPayId: number;
  setStateId: number;
  setCountryId: number;
  isSaving: boolean;
  methodType: string;
  minYear: number;
  maxYear: number;
  contributions: BillingTypeModel[];
  paymentMethodList: PaymentMethodModel[] = [];
  isNew: boolean = true;
  confirm: boolean = false;
  saveData: OrganizationPaymentSplitModel = {} as OrganizationPaymentSplitModel;
  paymentMethodCC: CreatePaymentMethodCCModel = {} as CreatePaymentMethodCCModel;
  paymentMethodACH: CreatePaymentMethodACHModel = {} as CreatePaymentMethodACHModel;
  totalToBePaid: number;
  payInFull: boolean = true;
  addressData: AddressModel = {} as AddressModel;
  @Input() isEdit: boolean;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() paymentData: OrganizationPaymentPayeeModel;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Output() closeRefresh = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private makePaymentService: MakePaymentService,
              private paymentMethodsService: PaymentMethodsService, private pctPaymentsService: PctPaymentsService,
              private lookupsDataService: LookupsDataService, private messageService: MessageService,
              private confirmValidationUtility: ConfirmValidationUtility, private contextService: ContextService,
              private organizationService: OrganizationService) {
    this.mainForm = this.formBuilder.group({
      // oneTimeContributions: new FormArray([]),
      // duePayments: new FormArray([]),
      // duePaymentValueTotal: new FormControl(0),
      // billingTypeValueTotal: new FormControl(0),
      updatePaymentTotal: new FormControl(0),
      isAutoPaymentMethod: new FormControl(false),
      // paymentMethod: new FormControl(['credit']),
      savePaymentMethod: new FormControl(true),
      // isCreditActive: new FormControl(true),
      paymentMethodID: new FormControl(null, [Validators.required]),
      MethodNickname: new FormControl(null, Validators.required),
      AutoPaymentScheduleTypeID: new FormControl(null),
      paymentMethodType: new FormControl(null, Validators.required),
      ACHNameOnAccount: new FormControl(null),
      ACHAccountNumber: new FormControl(null),
      ACHAccountNumberConfirm: new FormControl(null),
      ACHAccountType: new FormControl(null),
      ACHBankName: new FormControl({value: null, disabled: true}),
      ACHRoutingNumber: new FormControl(null),
      CCNameOnAccount: new FormControl(null),
      CCAccountNumber: new FormControl(null),
      CCCardType: new FormControl({value: null, disabled: true}),
      CVV: new FormControl(null, Validators.maxLength(4)),
      CCExpirationMonth: new FormControl(null),
      CCExpirationYear: new FormControl(null),
      useAddress: new FormControl(null),
      primaryPhoneNumber: new FormControl(null),
      primaryEmail: new FormControl(null),
      oneTimeOnly: new FormControl(true),
      amountToPay: new FormControl(null, Validators.required)
    }, {
      validator: [this.confirmValidationUtility.ConfirmedValidator('ACHAccountNumber', 'ACHAccountNumberConfirm'),
        this.customValidator('ACHAccountNumber')]
    });
  }

  ngOnInit(): void {
    switch (true) {
      case this.paymentData.AllowsCC:
        this.methodType = 'CC';
        break;
      case this.paymentData.AllowsACH:
        this.methodType = 'ACH';
        break;
    }
    this.mainForm.get('paymentMethodType').setValue(this.methodType);
    this.mainForm.get('paymentMethodType').valueChanges.subscribe({
      next: (value) => {
        this.methodType = value;
        this.setFormState(value);
      }
    });
    this.initForm();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  initSelects(): void {
    this.initAutoPay(false);
  }

  initForm(): void {
    this.mainForm.get('amountToPay').setValue('full');
    this.totalToBePaid = this.paymentData.CurrentBalance_N;
    // this.getMemberSimpleBill();
    // this.getOneTimePayment();
    this.getPaymentMethodsKGrid();
    this.isNew = true;
    switch (true) {
      case this.paymentData.AllowsCC:
        this.mainForm.get('paymentMethodType').setValue('CC');
        this.mainForm.get('oneTimeOnly').setValue(true);
        this.setFormState('CC');
        break;
      case this.paymentData.AllowsACH:
        this.mainForm.get('paymentMethodType').setValue('ACH');
        this.mainForm.get('oneTimeOnly').setValue(true);
        this.setFormState('ACH');
        break;
    }
    this.setAutoPayId = null;

    this.addressData.CountryID = null;
    this.addressData.Address1 = null;
    this.addressData.Address2 = null;
    this.addressData.City = null;
    this.addressData.StateProvinceID = null;
    this.addressData.Zip = null;
    this.addressData.ZipPlus4 = null;
    this.addressData.Latitude = null;
    this.addressData.Longitude = null;
    this.addressData.Ready = true;

    this.initSelects();
    this.loading = false;
  }

  setFormState(value: string): void {
    if (value === 'CC') {
      const dt = new Date();
      const yr: string = dt.getFullYear().toString().substring(2);
      this.minYear = Number(yr);
      this.maxYear = Number(yr) + 20;
      this.mainForm.get('CCNameOnAccount').setValidators([Validators.required]);
      this.mainForm.get('CCNameOnAccount').updateValueAndValidity();
      this.mainForm.get('CCAccountNumber').setValidators([Validators.required]);
      this.mainForm.get('CCAccountNumber').updateValueAndValidity();
      if (this.isNew) {
        this.mainForm.get('CVV').setValidators([Validators.required, Validators.maxLength(4)]);
        this.mainForm.get('CVV').updateValueAndValidity();
      } else {
        this.mainForm.get('CVV').setValidators(Validators.maxLength(4));
        this.mainForm.get('CVV').updateValueAndValidity();
      }
      this.mainForm.get('CCExpirationMonth').setValidators([Validators.required,
        Validators.min(1),
        Validators.max(12),
        this.regexValidator(new RegExp('^[0-9]+$'), {number: true})]);
      this.mainForm.get('CCExpirationMonth').updateValueAndValidity();
      this.mainForm.get('CCExpirationYear').setValidators([Validators.required,
        Validators.min(this.minYear),
        Validators.max(this.maxYear),
        this.regexValidator(new RegExp('^[0-9]+$'), {number: true})]);
      this.mainForm.get('CCExpirationYear').updateValueAndValidity();
      this.mainForm.get('ACHNameOnAccount').setValidators(null);
      this.mainForm.get('ACHNameOnAccount').updateValueAndValidity();
      this.mainForm.get('ACHAccountType').setValidators(null);
      this.mainForm.get('ACHAccountType').updateValueAndValidity();
      this.mainForm.get('ACHBankName').setValidators(null);
      this.mainForm.get('ACHBankName').updateValueAndValidity();
      this.mainForm.get('ACHRoutingNumber').setValidators(null);
      this.mainForm.get('ACHRoutingNumber').updateValueAndValidity();
      this.mainForm.get('ACHAccountNumber').setValidators(null);
      this.mainForm.get('ACHAccountNumber').updateValueAndValidity();
      this.mainForm.get('ACHAccountNumberConfirm').setValidators(null);
      this.mainForm.get('ACHAccountNumberConfirm').updateValueAndValidity();

      this.mainForm.get('ACHNameOnAccount').setValue(null);
      this.mainForm.get('ACHAccountType').setValue(null);
      this.mainForm.get('ACHBankName').setValue(null);
      this.mainForm.get('ACHRoutingNumber').setValue(null);
      this.mainForm.get('ACHAccountNumber').setValue(null);
      this.mainForm.get('ACHAccountNumberConfirm').setValue(null);
    } else {
      this.mainForm.get('ACHNameOnAccount').setValidators([Validators.required]);
      this.mainForm.get('ACHNameOnAccount').updateValueAndValidity();
      this.mainForm.get('ACHAccountType').setValidators([Validators.required]);
      this.mainForm.get('ACHAccountType').updateValueAndValidity();
      this.mainForm.get('ACHRoutingNumber').setValidators([Validators.required]);
      this.mainForm.get('ACHRoutingNumber').updateValueAndValidity();
      this.mainForm.get('ACHAccountNumber').setValidators([Validators.required]);
      this.mainForm.get('ACHAccountNumber').updateValueAndValidity();
      this.mainForm.get('ACHAccountNumberConfirm').setValidators([Validators.required]);
      this.mainForm.get('ACHAccountNumberConfirm').updateValueAndValidity();

      this.mainForm.get('CCNameOnAccount').setValidators(null);
      this.mainForm.get('CCNameOnAccount').updateValueAndValidity();
      this.mainForm.get('CCAccountNumber').setValidators(null);
      this.mainForm.get('CCAccountNumber').updateValueAndValidity();
      this.mainForm.get('CVV').setValidators(Validators.maxLength(4));
      this.mainForm.get('CVV').updateValueAndValidity();
      this.mainForm.get('CCExpirationMonth').setValidators(null);
      this.mainForm.get('CCExpirationMonth').updateValueAndValidity();
      this.mainForm.get('CCExpirationYear').setValidators(null);
      this.mainForm.get('CCExpirationYear').updateValueAndValidity();

      this.mainForm.get('ACHAccountType').setValue('CHECKING');
      this.mainForm.get('CCAccountNumber').setValue(null);
      this.mainForm.get('CCCardType').setValue(null);
      this.mainForm.get('CCExpirationMonth').setValue(null);
      this.mainForm.get('CCExpirationYear').setValue(null);
      this.mainForm.get('CVV').setValue(null);
    }
  }

  initAutoPay(disable: boolean): void {
    this.inputObjAutoPay = {
      labelText: 'Pay each month on this day',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setAutoPayId,
      disabled: disable
    };
    this.lookupsDataService.getAutoPayLookupData('organization').then((lookupData) => {
      this.inputObjAutoPay.data = lookupData;
      this.inputObjAutoPay = Object.assign({}, this.inputObjAutoPay);
    });
  }

  getAutoPayData(event:any): void {
    if (event && event[0] && event[0].ID) {
      if (this.setAutoPayId !== event[0].ID) {
        this.mainForm.markAsDirty();
      }
      this.setAutoPayId = event[0].ID;
    } else {
      if (this.setAutoPayId) {
        this.mainForm.markAsDirty();
      }
      this.setAutoPayId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('AutoPaymentScheduleTypeID').setValue(this.setAutoPayId);
  }

  getPaymentMethodsKGrid(setPaymentMethodId?: number): void {
    this.paymentMethodList = [];
    this.paymentMethodsService.getPaymentMethodsPrimeNG('DATA', this.DBEntity, this.DBEntityID, null)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: any) => {
          let methType: string;
          switch (true) {
            case this.paymentData.AllowsCC:
              methType = 'CC';
              break;
            case this.paymentData.AllowsACH:
              methType = 'ACH';
              break;
          }
          res.Data.push({
            PaymentMethodId: 0,
            ProviderIdentifier: null,
            DBEntity: null,
            DBEntityKeyID: null,
            MethodType: methType,
            MethodNickname: null,
            Address1: null,
            Address2: null,
            City: null,
            StateProvinceID: null,
            Zip: null,
            CountryID: null,
            PhoneNumber: null,
            EmailAddress: null,
            AccountNumber: null,
            NameOnAccount: null,
            ACHAccountType: null,
            ACHRoutingNumber: null,
            ACHBankName: null,
            CCExpirationMonth: null,
            CCExpirationYear: null,
            CCCardType: 'Use Details Provided Below',
            CVV: null,
            OneTimeOnly: null,
            AutoPaymentScheduleTypeID: null,
            AutoPaymentScheduleTypeDescription: null
          });
          this.paymentMethodList = res.Data;
          if (res.Data.length === 0) {
            this.loading = false;
            this.isEmptyData = true;
          }
          if (setPaymentMethodId) {
            this.mainForm.get('paymentMethodID').setValue(setPaymentMethodId);
          } else {
            this.mainForm.get('paymentMethodID').setValue(0);
          }
        }, error: (e) => {
          console.debug(e);
        }
      });
  }

  toggleCardPaymentMethod(ccardInfo): void {
    this.isNew = ccardInfo.PaymentMethodId === 0;
    if (ccardInfo.CCCardType !== null) {
      // this.mainForm.get('paymentMethod').enable();
      this.mainForm.get('ACHAccountType').enable();
      //Credit Card Info
      this.mainForm.get('CCNameOnAccount').setValue(ccardInfo.NameOnAccount);
      this.mainForm.get('CCAccountNumber').setValue(ccardInfo.AccountNumber);
      this.mainForm.get('CCCardType').setValue(ccardInfo.CCCardType);
      this.mainForm.get('CCExpirationMonth').setValue(ccardInfo.CCExpirationMonth);
      this.mainForm.get('CCExpirationYear').setValue(ccardInfo.CCExpirationYear);
      this.mainForm.get('CVV').setValue(ccardInfo.CVV);
      if (ccardInfo.CCExpirationYear) {
        this.minYear = ccardInfo.CCExpirationYear;
        this.maxYear = ccardInfo.CCExpirationYear + 20;
      } else {
        const dt = new Date();
        const str: string = dt.getFullYear().toString().substring(3, 4);
        this.minYear = Number(str);
        this.maxYear = Number(str) + 20;
      }
    } else {
      // this.mainForm.get('paymentMethod').disable();
      this.mainForm.get('ACHAccountType').disable();
      //Checking Account Info
      this.mainForm.get('ACHNameOnAccount').setValue(ccardInfo.NameOnAccount);
      this.mainForm.get('ACHAccountType').setValue(ccardInfo.ACHAccountType);
      this.mainForm.get('ACHRoutingNumber').setValue(ccardInfo.ACHRoutingNumber);
      this.mainForm.get('ACHBankName').setValue(ccardInfo.ACHBankName);
      this.mainForm.get('ACHAccountNumber').setValue(ccardInfo.AccountNumber);
      this.mainForm.get('ACHAccountNumberConfirm').setValue(null);
    }
    if (ccardInfo.MethodType !== null) {
      this.mainForm.get('paymentMethodType').setValue(ccardInfo.MethodType);
    } else {
      switch (true) {
        case this.paymentData.AllowsCC:
          this.mainForm.get('paymentMethodType').setValue('CC');
          this.mainForm.get('oneTimeOnly').setValue(true);
          this.setFormState('CC');
          break;
        case this.paymentData.AllowsACH:
          this.mainForm.get('paymentMethodType').setValue('ACH');
          this.mainForm.get('oneTimeOnly').setValue(true);
          this.setFormState('ACH');
          break;
      }
    }
    this.mainForm.get('MethodNickname').setValue(ccardInfo.MethodNickname);
    this.mainForm.get('AutoPaymentScheduleTypeID').setValue(ccardInfo.AutoPaymentScheduleTypeID);
    this.mainForm.get('useAddress').setValue(false);
    this.mainForm.get('primaryPhoneNumber').setValue(ccardInfo.PhoneNumber);
    this.mainForm.get('primaryEmail').setValue(ccardInfo.EmailAddress);

    setTimeout(() => {
      // delay to load form on change of payment method
      this.addressData.CountryID = ccardInfo.CountryID;
      this.addressData.Address1 = ccardInfo.Address1;
      this.addressData.Address2 = ccardInfo.Address2;
      this.addressData.City = ccardInfo.City;
      this.addressData.StateProvinceID = ccardInfo.StateProvinceID;
      this.addressData.Zip = ccardInfo.Zip;
      this.addressData.ZipPlus4 = ccardInfo.ZipPlus4;
      this.addressData.Latitude = ccardInfo.Latitude;
      this.addressData.Longitude = ccardInfo.Longitude;
      this.addressData.Ready = true;
    }, 350);
  }

  getCCDetails(value: string): void {
    this.getCreditCardType(value);
    this.isCreditCardValid(value);
  }

  getCreditCardType(value: string): void {
    if (value) {
      const data = {} as PaymentMethodCardTypeModel;
      data.AccountNumber = value;
      this.paymentMethodsService.getCreditCardType(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.mainForm.get('CCCardType').setValue(res);
          }, error: () => {
            this.mainForm.get('CCCardType').setValue(null);
          }
        });
    }
  }

  isCreditCardValid(value: string): void {
    if (value) {
      const data = {} as PaymentMethodCardValidModel;
      data.AccountNumber = value;
      this.paymentMethodsService.isCreditCardValid(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            if (res === false) {
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'The Credit Card # provided is not valid. Please try again.'});
            }
          }
        });
    }
  }

  getBankName(value: string): void {
    if (value) {
      const data = {} as PaymentMethodBankNameModel;
      data.ACHRoutingNumber = value;
      this.paymentMethodsService.getBankName(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.mainForm.get('ACHBankName').setValue(res);
          }, error: () => {
            this.mainForm.get('ACHBankName').setValue(null);
          }
        });
    }
  }

  cancel(): void {
    this.closeAddEdit.emit();
  }

  reset(): void {
    this.addressData = {} as AddressModel;
    this.confirm = false;
    this.saveData = null;
    this.mainForm.reset();
    this.initForm();
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  confirmPayment(): void {
    if (this.mainForm.get('paymentMethodID').value !== 0) {
      this.saveData.PaymentMethodID = this.mainForm.get('paymentMethodID').value;
      this.confirm = true;
    } else {
      if (this.mainForm.get('paymentMethodType').value === 'CC') {
        this.paymentMethodCC = {
          DBEntity: this.DBEntity,
          DBEntityKeyID: this.DBEntityID,
          MethodType: this.mainForm.get('paymentMethodType').value,
          MethodNickname: this.mainForm.get('MethodNickname').value,
          Address1: this.addressData.Address1,
          Address2: this.addressData.Address2,
          City: this.addressData.City,
          StateProvinceID: this.addressData.StateProvinceID,
          Zip: this.addressData.Zip,
          CountryID: this.addressData.CountryID,
          PhoneNumber: this.mainForm.get('primaryPhoneNumber').value,
          EmailAddress: this.mainForm.get('primaryEmail').value,
          AccountNumber: this.mainForm.get('CCAccountNumber').value,
          NameOnAccount: this.mainForm.get('CCNameOnAccount').value,
          CCExpirationMonth: this.mainForm.get('CCExpirationMonth').value,
          CCExpirationYear: this.mainForm.get('CCExpirationYear').value,
          CCCardType: this.mainForm.get('CCCardType').value,
          CVV: this.mainForm.get('CVV').value,
          AutoPaymentScheduleTypeID: this.setAutoPayId,
          OneTimeOnly: !this.mainForm.get('oneTimeOnly').value
        };
        this.paymentMethodsService.createCCPaymentMethod(this.paymentMethodCC)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res: any) => {
              this.saveData.PaymentMethodID = res.PaymentMethodId;
              this.confirm = true;
            }, error: (e) => {
              if (e?.error?.Message) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 4000});
              } else {
                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
              }
              this.confirm = false;
              console.debug(e);
            }
          });
      } else {
        this.paymentMethodACH = {
          DBEntity: this.DBEntity,
          DBEntityKeyID: this.DBEntityID,
          MethodType: this.mainForm.get('paymentMethodType').value,
          MethodNickname: this.mainForm.get('MethodNickname').value,
          Address1: this.addressData.Address1,
          Address2: this.addressData.Address2,
          City: this.addressData.City,
          StateProvinceID: this.addressData.StateProvinceID,
          Zip: this.addressData.Zip,
          CountryID: this.addressData.CountryID,
          PhoneNumber: this.mainForm.get('primaryPhoneNumber').value,
          EmailAddress: this.mainForm.get('primaryEmail').value,
          AccountNumber: this.mainForm.get('ACHAccountNumber').value,
          NameOnAccount: this.mainForm.get('ACHNameOnAccount').value,
          ACHAccountType: this.mainForm.get('ACHAccountType').value,
          ACHRoutingNumber: this.mainForm.get('ACHRoutingNumber').value,
          ACHBankName: this.mainForm.get('ACHBankName').value,
          AutoPaymentScheduleTypeID: this.setAutoPayId,
          OneTimeOnly: !this.mainForm.get('oneTimeOnly').value
        };
        this.paymentMethodsService.createACHPaymentMethod(this.paymentMethodACH)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res: any) => {
              this.saveData.PaymentMethodID = res.PaymentMethodId;
              this.confirm = true;
            }, error: (e) => {
              if (e?.error?.Message) {
                this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 4000});
              } else {
                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
              }
              this.confirm = false;
              console.debug(e);
            }
          });
      }
    }
  }

  makePayment(): void {
    this.isSaving = true;
    this.saveData.PayeeOrganizationID = this.paymentData.OrganizationID;
    this.saveData.PayerOrganizationID = this.DBEntityID;
    this.saveData.Amount = this.totalToBePaid;
    this.pctPaymentsService.createReceivePayment(this.saveData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Payment Made', detail: 'The payment has been made. Thank you so much!'});
          this.closeRefresh.emit();
          this.isSaving = false;
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 4000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
          this.isSaving = false;
          console.debug(e);
        }
      });
  }

  applyAddress(event:any): void {
    if (event && event === true) {
      this.addressData.Ready = false;
      this.organizationService.getOrganization(this.DBEntityID, 'union', 0)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.addressData.CountryID = res.CountryID;
            this.addressData.Address1 = res.Address1;
            this.addressData.Address2 = res.Address2;
            this.addressData.City = res.City;
            this.addressData.StateProvinceID = res.StateProvinceID;
            this.addressData.Zip = res.Zip;
            this.addressData.Ready = true;
            this.mainForm.get('primaryPhoneNumber').setValue(res.PhoneNumber);
            this.mainForm.get('primaryEmail').setValue(res.EmailAddress);
          }
        });
    }
  }

  customValidator(controlName: string) {
    return (formGroup: FormGroup) => {
      const matchingControl = formGroup.controls[controlName];
      if (matchingControl.errors && !matchingControl.errors?.customValidator) {
        return;
      }
      const control: string = this.contextService.contextObject.UserName;
      if (control) {
        const am: number = control.indexOf('@');
        const username: string = control.substring(0, am);
        const domain: string = control.substring(am + 1, control.length);
        if (matchingControl?.value?.indexOf(username) > -1 || matchingControl?.value?.indexOf(domain) > -1) {
          matchingControl.setErrors({customValidator: true});
        } else {
          matchingControl.setErrors(null);
        }
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  togglePayInFull(val: string): void {
    if (val) {
      this.payInFull = val === 'full';
      if (this.payInFull) {
        this.totalToBePaid = this.paymentData.CurrentBalance_N;
      } else {
        this.totalToBePaid = this.paymentData.RemainingOverDueAmount_N;
      }
    }
  }

  setAddress(addressChanges: AddressModel): void {
    if (addressChanges) {
      this.mainForm.markAsDirty();
      this.addressData.CountryID = addressChanges.CountryID;
      this.addressData.Address1 = addressChanges.Address1;
      this.addressData.Address2 = addressChanges.Address2;
      this.addressData.City = addressChanges.City;
      this.addressData.StateProvinceID = addressChanges.StateProvinceID;
      this.addressData.Zip = addressChanges.Zip;
    }
  }
}
