@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
<form [formGroup]="mainForm">
  <div class="p-grid p-fluid">
    <div class="col-md-12 col-sm-12 col-xs-12 input-container">
      <p>*Please note - A start date and time and end date and time are required to create a virtual instance of this class. Changes to data on this tab will save all changes to this class.</p>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjOnlineMeetingType" (emitData)="getOnlineMeetingTypeData($event)" ></app-select>
    </div>
    <div class="col-md-6 col-sm-0 col-xs-0 input-container"></div>
    @if (setOnlineMeetingTypeId && !showDetails) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <label>Agenda</label>
        <textarea pInputTextarea formControlName="agenda" rows="5" autoResize="autoResize" tabindex="0"></textarea>
      </div>
    }
    @if (setOnlineMeetingTypeId && !showDetails) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <div class="radio-container">
          <label>Audio</label>
          <p-radioButton value="voip" formControlName="audio" id="onlineAudioVoip" [styleClass]="'radio-input'" label="Computer Audio" class="p-mr-2" tabindex="0"></p-radioButton>
          <p-radioButton value="telephony" formControlName="audio" id="onlineAudioTelephony" [styleClass]="'radio-input'" label="Telephone" class="p-mr-2" tabindex="0"></p-radioButton>
          <p-radioButton value="Computer Audio" formControlName="audio" id="onlineAudioBoth" [styleClass]="'radio-input'" label="Telephone and Computer Audio" tabindex="0"></p-radioButton>
        </div>
      </div>
    }
    @if (showDetails) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
    @if (showDetails) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h4>Virtual Meeting Details</h4>
        <div class="p-grid p-fluid">
          <div class="col-md-3 col-sm-12 col-xs-12">
            <span class="bold">Start Date/Time</span>
          </div>
          <div class="col-md-9 col-sm-12 col-xs-12">
            <span>{{start_time | date:'short'}}</span>
          </div>
          <div class="col-md-3 col-sm-12 col-xs-12 p-mt-2">
            <span class="bold">Topic</span>
          </div>
          <div class="col-md-9 col-sm-12 col-xs-12 p-mt-2">
            <span>{{topic}}</span>
          </div>
          <div class="col-md-3 col-sm-12 col-xs-12 p-mt-2">
            <span class="bold">Audio</span>
          </div>
          <div class="col-md-9 col-sm-12 col-xs-12 p-mt-2">
            <span>{{audio | titlecase}}</span>
          </div>
          <div class="col-md-3 col-sm-12 col-xs-12 p-mt-2">
            <span class="bold">Start Meeting URL</span>
          </div>
          <div class="col-md-9 col-sm-12 col-xs-12 p-mt-2">
            <a href="{{start_url}}" target="_blank">{{start_url}}</a>
          </div>
          <div class="col-md-3 col-sm-12 col-xs-12 p-mt-2">
            <span class="bold">Join Meeting URL</span>
          </div>
          <div class="col-md-9 col-sm-12 col-xs-12 p-mt-2">
            <a href="{{join_url}}" target="_blank">{{join_url}}</a>
          </div>
        </div>
      </div>
    }
    <div class="col-md-6 col-sm-0 col-xs-0 input-container"></div>
    @if (canTabWrite) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12">
            @if (setOnlineMeetingTypeId && !showDetails) {
              <button pButton label="Create Virtual Instance" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-plus" pTooltip="Create Virtual Instance" tooltipPosition="bottom"
              (click)="createOnlineClass()"></button>
            }
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
            @if (isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
              (click)="cancel()" [disabled]="!mainForm.dirty"></button>
            } @else {
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
              (click)="cancel()"></button>
            }
            @if (!isSaving) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                (click)="processData()" [className]="'inline-button'"
              [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
            } @else {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </div>
      </div>
    }
  </div>
</form>
