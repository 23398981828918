import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';

import {WorkflowStepsService} from '../../../../../../services/workflow-steps.service';
import {CreateWorkflowStepModel, WorkflowStepModel} from '../../../../../../models/workflow-steps.model';
import {GenericSelectModel} from '../../../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../../../services/lookups-data.service';

@Component({
  selector: 'app-workflow-steps-form',
  templateUrl: './workflow-steps-form.component.html',
  styleUrls: ['./workflow-steps-form.component.scss']
})
export class WorkflowStepsFormComponent implements OnInit, OnChanges, OnDestroy {
  mainForm: FormGroup;
  step = {} as WorkflowStepModel;
  loading: boolean = false;
  createUpdateStep = {} as CreateWorkflowStepModel;
  isSaving: boolean;
  inputObjStep: GenericSelectModel;
  setStepId: number;
  existingMessage: boolean = false;
  @Input() isEdit: boolean;
  @Input() canEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() organizationId: number;
  @Input() workflowStepData: WorkflowStepModel;
  @Input() workflowID: number;
  @Input() category: string;
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder, private messageService: MessageService,
              private lookupsDataService: LookupsDataService, private confirmationService: ConfirmationService,
              private workflowStepsService: WorkflowStepsService
  ) {
    this.mainForm = this.formBuilder.group({
      stepId: new FormControl(null, Validators.required),
      workflowStepAlias: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.initForm(this.isEdit);

    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  ngOnChanges(): void {
    if (this.canTabWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  valueChanged(): void {
    if (this.mainForm.valid && this.mainForm.dirty && this.isEdit && !this.existingMessage) {
      this.existingMessage = true;
      this.openDialog();
    }
  }

  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Step data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.processData();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  initForm(isEdit: boolean): any {
    return new Promise((resolve, reject) => {
      this.mainForm.markAsPristine();
      this.mainForm.markAsUntouched();
      this.loading = true;
      if (isEdit) {
        this.step = this.workflowStepData;
        this.setStepId = this.step.StepID;
        this.mainForm.get('stepId').setValue(this.step.StepID);
        this.mainForm.get('workflowStepAlias').setValue(this.step.WorkflowStepAlias);
        this.initStepSelect(!this.canTabWrite);
        this.loading = false;
        resolve(null);
      } else {
        this.initStepSelect(false);
        this.loading = false;
        resolve(null);
      }
    });
  }

  initStepSelect(disable: boolean): void {
    this.inputObjStep = {
      labelText: 'Step/Intent',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setStepId,
      disabled: disable
    };
    this.lookupsDataService.getWorkflowStepsByCategoryOrganizationIdLookupData(this.category, this.organizationId, this.isEdit).then((lookupData) => {
      this.inputObjStep.data = lookupData;
      this.inputObjStep = Object.assign({}, this.inputObjStep);
    });
  }

  getStepData(event:any): void {
    if (event && event[0]) {
      this.setStepId = event[0].ID;
    } else {
      this.setStepId = null;
    }
    this.mainForm.markAsDirty();
    this.mainForm.get('stepId').setValue(this.setStepId);
    if (this.setStepId === -1) {
      this.mainForm.get('workflowStepAlias').setValidators(Validators.required);
    } else {
      this.mainForm.get('workflowStepAlias').clearValidators();
    }
    this.mainForm.get('workflowStepAlias').updateValueAndValidity();
  }

  isFormValid(): any {
    return this.mainForm.valid && this.mainForm.dirty;
  }

  processData(): void {
    if (this.mainForm.valid && this.mainForm.dirty) {
      this.existingMessage = false;
      this.isSaving = true;
      if (this.isEdit) {
        this.step.StepID = this.mainForm.get('stepId').value;
        this.step.WorkflowStepAlias = this.mainForm.get('workflowStepAlias').value;
      } else {
        this.createUpdateStep.WorkflowID = this.workflowID;
        this.createUpdateStep.StepID = this.mainForm.get('stepId').value;
        this.createUpdateStep.WorkflowStepAlias = this.mainForm.get('workflowStepAlias').value;
      }
      this.saveForm();
    } else {
      this.messageService.add({severity: 'warn', summary: 'Missing Data', detail: 'No data was saved, the form is currently incomplete.'});
      this.existingMessage = false;
    }
  }

  saveForm(): void {
    if (!this.isEdit) {
      this.workflowStepsService.createWorkflowStepNew(this.createUpdateStep)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'The step has been added.'
            });
            this.isSaving = false;
            this.closeAddEdit.emit(true);
            // location.reload();
          }, error: () => {
            this.isSaving = false;
          }
        });
    } else {
      this.workflowStepsService.updateWorkflowStep(this.workflowStepData.WorkflowStepID, this.step)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.confirmationService.close();
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'The step has been updated.'
            });
            this.mainForm.markAsUntouched();
            this.mainForm.markAsPristine();
            this.isSaving = false;
            this.existingMessage = false;
            this.closeAddEdit.emit(true);
            // location.reload();
            //this.initForm(this.isEdit);
            //this.mainForm.markAsPristine();
          }, error: () => {
            this.isSaving = false;
          }
        });
    }
  }

  cancel(): void {
    this.confirmationService.close();
    if (!this.isEdit) {
      this.mainForm.reset();
      this.closeAddEdit.emit();
    } else {
      this.initForm(this.isEdit);
    }
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
    this.existingMessage = false;
  }
}
