import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {OrganizationService} from '../../../../services/organizations.service';
import {OrganizationModel} from '../../../../models/organizations.model';
import {PageRowCountUtility} from '../../../../shared/utilities/page-row-count.utility';

@Component({
  selector: 'app-employer-assignment-form',
  templateUrl: './employer-assignment-form.component.html',
  styleUrls: ['./employer-assignment-form.component.scss']
})
export class EmployerAssignmentFormComponent implements OnInit, OnDestroy {
  isSaving: boolean = false;
  loading: boolean = false;
  organizationList: OrganizationModel[];
  selorganization: OrganizationModel[] = [];
  dataTotal: number = 0;
  cols: any[];
  @Input() employerID: number;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();
  getListRequest;
  getTotalRequest;


  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private lookupsDataService: LookupsDataService,
              private organizationService: OrganizationService,
              public pageRowCountUtility: PageRowCountUtility
  ) {
  }

  ngOnInit(): void {
    this.cols = [
      {field: 'UTStatusDescription', header: 'Launch Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizationType', header: 'Type', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Name', header: 'Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Number', header: 'Number', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'City', header: 'City/Town', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'StateProvince', header: 'State/Province', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Status', header: 'Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ActiveRosterCount_N', header: 'Active Members', columnType: 'numeric', matchMode: 'gte', displayType: 'numeric', visible: true}
    ];

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  loadTable(event:any) {
    if (event) {
      this.currentFilter = event;
    } else {
      this.currentFilter.filters = {};
      this.currentFilter.sortOrder = 1;
    }
    this.getList(event);
    this.getTotal(event);
  }

  getList(event:any) {
    this.loading = true;
    this.currentFilter = event;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.organizationService.getEmployerAssignmentPickerPrimeNG('DATA', this.employerID, this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.organizationList = res.Data;
          this.loading = false;
        }, error: (e) => {
          this.loading = false;
          console.log(e);
        }
      });
  }

  getTotal(event:any) {
    this.currentFilter = event;
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.organizationService.getEmployerAssignmentPickerPrimeNG('TOTAL', this.employerID, this.currentFilter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }, error: (e) => {
          console.log(e);
        }
      });
  }

  processData() {
    const ids: number[] = [];
    this.isSaving = true;
    this.selorganization.forEach(org => {
      ids.push(org.ID);
    });
    this.saveForm(ids);
  }

  saveForm(ids: number[]) {
    this.organizationService.addEmployerAssignment(this.employerID, ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Employer assignments have been added'
          });
          this.closeAddEdit.emit();
          this.isSaving = false;
        }, error: (e) => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          this.isSaving = false;
          console.debug(e);
        }
      });
  }

  cancel(): void {
    this.closeAddEdit.emit();
  }
}
