import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  AccountAdjustmentListModel,
  InvoiceIssueRefundModel,
  InvoiceRefundablePaymentsGridModel,
  InvoiceRefundDetailModel
} from '../models/invoices.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class AccountAdjustmentsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getRefundPaymentPrimeNG(dataMode: string, personId: number, filterData, orgId?: number): Observable<InvoiceRefundablePaymentsGridModel> {
    const url = orgId ? `?mode=${dataMode}&routeOrganizationId=${orgId}` : `?mode=${dataMode}`;
    return this.http.post<InvoiceRefundablePaymentsGridModel>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/person/${personId}/paymentgrid${url}`, filterData);
  }

  getRefundDetailList(paymentId: number): Observable<InvoiceRefundDetailModel> {
    return this.http.get<InvoiceRefundDetailModel>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/${paymentId}/detaillist`);
  }

  createInvoiceIssueRefund(paymentId: number, modelData: InvoiceIssueRefundModel): Observable<InvoiceIssueRefundModel> {
    return this.http.post<InvoiceIssueRefundModel>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/${paymentId}/issuerefund`, modelData);
  }

  getAccountAdjustmentDetails(orgId: number, personId: number): Observable<any> {
    Userpilot.track('Account Adjustment Detail');
    return this.http.get<any>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${orgId}/person/${personId}/accountAdjustment`);
  }

  makeAccountAdjustment(orgId: number, personId: number, modelData: AccountAdjustmentListModel): Observable<any> {
    return this.http.put<AccountAdjustmentListModel>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/${orgId}/person/${personId}/accountAdjustment`, modelData);
  }

  recreateReceipt(orgId: number, modelData): Observable<AccountAdjustmentListModel> {
    return this.http.put<AccountAdjustmentListModel>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/payment/recreateReceiptPdfs?routeOrganizationId=${orgId}`, modelData);
  }

  recreateInvoice(orgId: number, modelData): Observable<AccountAdjustmentListModel> {
    return this.http.put<AccountAdjustmentListModel>
    (`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/organization/0/memberbilling/${orgId}/recreatepdfs`, modelData);
  }


}
