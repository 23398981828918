import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {Subject} from 'rxjs';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {ContextService} from '../../../services/context.service';
import {takeUntil} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, UntypedFormArray, Validators} from '@angular/forms';
import {OrganizationPaymentSplitModel} from '../../../models/organization-payments.model';
import {DialogUtility} from '../../utilities/dialog.utility';
import {PctPaymentsService} from '../../../services/pct-payment.service';
import {MessageService} from 'primeng/api';
import {TenantConfigDataService} from '../../../services/tenant-config-data.service';

@Component({
  selector: 'app-pct-record-payment',
  templateUrl: './pct-record-payment.component.html',
  styleUrls: ['./pct-record-payment.component.scss']
})

export class PctRecordPaymentComponent implements OnInit, OnDestroy, OnChanges {
  mainForm: FormGroup;
  isSaving: boolean = false;
  inputObjOrganizationFrom: GenericSelectModel;
  setOrganizationIdFrom: number;
  inputObjOrganizationTo: GenericSelectModel;
  setOrganizationIdTo: number;
  organizationName: string;
  inputBillingAdjustments: GenericSelectModel[] = [];
  setBillingAdjustmentsId: number[] = [];
  showBillingAdjustments: boolean[] = [];
  inputObjPaymentMethod: GenericSelectModel;
  createPayment = {} as OrganizationPaymentSplitModel;
  setPaymentMethodId: number[] = [];
  showCancel: boolean = false;
  formTotal: number = 0;
  @Input() InterfaceObjectEnum: string;
  @Input() useType: string;
  @Input() toOrganizationId: number;
  @Input() fromOrganizationId: number;
  @Input() canWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();

  private ngUnsubscribe = new Subject();

  constructor(private lookupsDataService: LookupsDataService,
              private contextService: ContextService,
              private formBuilder: FormBuilder,
              private dialogUtility: DialogUtility,
              private pctPaymentsService: PctPaymentsService,
              private messageService: MessageService,
              private tenantConfigDataService: TenantConfigDataService) {
    this.mainForm = this.formBuilder.group({
      organizationFrom: new FormControl(null, Validators.required),
      organizationTo: new FormControl(null, Validators.required),
      paymentType: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      formFields: this.formBuilder.array([]),
      total: new FormControl({value: 0, disabled: true}),
      memo: new FormControl(null)
    });
  }

  get formFields(): UntypedFormArray {
    return this.mainForm.controls.formFields as UntypedFormArray;
  }

  ngOnInit(): void {
    switch (this.useType) {
      case 'receive-payment':
        this.setOrganizationIdFrom = this.contextService.contextObject.OrganizationID;
        this.setOrganizationIdTo = this.contextService.contextObject.RootOrganizeOrgID;
        break;
      case 'pct-adjustments-add':
        this.setOrganizationIdTo = this.toOrganizationId;
        this.showCancel = true;
        break;
      case 'pct-adjustments-tab':
        this.setOrganizationIdFrom = this.fromOrganizationId;
        this.setOrganizationIdTo = this.toOrganizationId;
        break;
    }
    this.addPayment();
    this.initSelects();
    if (this.canWrite) {
      for (const field in this.mainForm.controls) {
        if (this.mainForm.get(field).disabled) {
          this.mainForm.get(field).disable();
        } else {
          this.mainForm.get(field).enable();
        }
      }
    } else {
      this.mainForm.disable();
    }
  }

  initSelects(): void {
    switch (this.useType) {
      case 'receive-payment':
        this.initOrganizationTo(!this.canWrite);
        this.initOrganizationFrom(!this.setOrganizationIdTo, this.setOrganizationIdTo);
        this.mainForm.get('organizationTo').setValue(this.setOrganizationIdTo);
        break;
      case 'pct-adjustments-add':
        this.setOrganizationIdTo = this.toOrganizationId;
        this.initOrganizationTo(!this.canWrite);
        this.initOrganizationFrom(!this.canWrite, this.setOrganizationIdTo);
        this.mainForm.get('organizationTo').setValue(this.setOrganizationIdTo);
        break;
      case 'pct-adjustments-tab':
        this.setOrganizationIdFrom = this.fromOrganizationId;
        this.setOrganizationIdTo = this.toOrganizationId;
        this.initOrganizationTo(!this.canWrite);
        this.initOrganizationFrom(!this.canWrite, this.setOrganizationIdTo);
        this.mainForm.get('organizationTo').setValue(this.setOrganizationIdTo);
        this.mainForm.get('organizationFrom').setValue(this.setOrganizationIdFrom);
        break;
    }
    this.initPaymentMethod(!this.canWrite);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }

  initOrganizationTo(disable: boolean): void {
    this.inputObjOrganizationTo = {
      labelText: 'Payment To',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: !this.setOrganizationIdTo,
      initSelected: this.setOrganizationIdTo ? this.setOrganizationIdTo : null,
      disabled: disable,
      emitChangeOnLoad: !this.setOrganizationIdTo,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationsPerspectiveMembershipLookupData(true, true).then((lookupData) => {
      this.inputObjOrganizationTo.data = lookupData;
      this.inputObjOrganizationTo = Object.assign({}, this.inputObjOrganizationTo);
    });
  }

  setOrganizationDataTo(event:any): void {
    if (event && event[0]) {
      this.setOrganizationIdTo = (event[0].ID !== null) ? event[0].ID : 0;
      this.initOrganizationFrom(!this.canWrite, this.setOrganizationIdTo);
    } else {
      this.setOrganizationIdTo = 0;
      this.initOrganizationFrom(true, 0);
    }
    this.mainForm.get('organizationTo').setValue(this.setOrganizationIdTo);
    this.createPayment.PayeeOrganizationID = this.setOrganizationIdTo;
    this.mainForm.markAsDirty();
    this.formFields.clear();
    this.addPayment();
  }

  initOrganizationFrom(disable: boolean, id: number): void {
    this.inputObjOrganizationFrom = {
      labelText: 'Payment From',
      optionValue: 'ID',
      optionLabel: this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY'),
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.setOrganizationIdFrom ? this.setOrganizationIdFrom : null,
      disabled: disable,
      customSelect: 'ShowInactiveIcon'
    };
    this.lookupsDataService.getOrganizationByObjectAccessLookupData(this.InterfaceObjectEnum, id, true, 'membership').then((lookupData) => {
      this.inputObjOrganizationFrom.data = lookupData;
      this.inputObjOrganizationFrom = Object.assign({}, this.inputObjOrganizationFrom);
    });
  }

  setOrganizationDataFrom(event:any): void {
    if (event && event[0]) {
      this.organizationName = event[0].Description;
      this.setOrganizationIdFrom = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setOrganizationIdFrom = 0;
    }
    this.mainForm.get('organizationFrom').setValue(this.setOrganizationIdFrom);
    this.createPayment.PayerOrganizationID = this.setOrganizationIdFrom;
    this.mainForm.markAsDirty();
  }

  initBillingAdjustments(disable: boolean, i: number): void {
    this.inputBillingAdjustments[i] = {
      labelText: 'Billing Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: (this.setBillingAdjustmentsId[i]) ? this.setBillingAdjustmentsId[i] : null,
      disabled: disable
    };
    this.lookupsDataService.getOrganizationBillingAdjustmentTypesLookupData(this.setOrganizationIdTo, true).then((lookupData) => {
      this.inputBillingAdjustments[i].data = lookupData;
      this.inputBillingAdjustments[i] = Object.assign({}, this.inputBillingAdjustments[i]);
    });
  }

  setBillingAdjustments(event, i: number): void {
    if (event && event[0]) {
      this.setBillingAdjustmentsId[i] = event[0].ID;
    } else {
      this.setBillingAdjustmentsId[i] = null;
    }
    this.formFields.at(i).get('billingType').setValue(this.setBillingAdjustmentsId[i]);
    this.mainForm.markAsDirty();
  }

  initPaymentMethod(disable: boolean): void {
    this.inputObjPaymentMethod = {
      labelText: 'Payment Type',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable
    };
    this.lookupsDataService.getPaymentTypesLookupData().then((lookupData) => {
      this.inputObjPaymentMethod.data = lookupData;
      this.inputObjPaymentMethod = Object.assign({}, this.inputObjPaymentMethod);
    });
  }

  setPaymentMethodData(event:any): void {
    if (event && event[0] && event[0].ID) {
      this.setPaymentMethodId = event[0].ID;
    } else {
      this.setPaymentMethodId = null;
    }
    this.mainForm.get('paymentType').setValue(this.setPaymentMethodId);
    this.mainForm.markAsDirty();
  }

  addPayment() {
    let counter: number = 0;
    this.formFields.push(
      this.formBuilder.group({
        billingType: new FormControl(null),
        amount: new FormControl(0, Validators.required)
      })
    );
    this.formFields.controls.forEach((x, i) => {
      this.showBillingAdjustments[counter] = true;
      this.initBillingAdjustments(false, counter);
      x.get('billingType').setValue(this.setBillingAdjustmentsId[i]);
      counter++;
    });
  }

  updateTotal() {
    let counter: number = 0;
    this.formFields.controls.forEach((x) => {
      counter = counter + Number(x.get('amount').value);
    });
    this.mainForm.get('total').setValue(counter);
    this.formTotal = counter;
  }

  saveData() {
    this.createPayment.PayerOrganizationID = this.mainForm.get('organizationFrom').value;
    this.createPayment.PayeeOrganizationID = this.mainForm.get('organizationTo').value;
    this.createPayment.PaymentTypeID = this.mainForm.get('paymentType').value;
    this.createPayment.PaymentDate = new Date(this.mainForm.get('date').value).toISOString().split('.')[0];
    this.createPayment.Amount = this.mainForm.get('total').value.toFixed(2);
    this.createPayment.Memo = this.mainForm.get('memo').value;
    this.createPayment.PaymentIntent = [];
    this.formFields.controls.forEach((x, i) => {
      this.createPayment.PaymentIntent.push(
        {
          BillingAdjustmentID: this.setBillingAdjustmentsId[i],
          Amount: x.get('amount').value,
        }
      );
    });
    this.pctPaymentsService.createReceivePayment(this.createPayment)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Payment Recorded', detail: `The payment from ${this.organizationName} has been recorded`});
          this.reset();
          this.closeAddEdit.emit();
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        }
      });
  }

  reset() {
    this.mainForm.reset();
    this.formFields.controls.length = 0;
    this.setBillingAdjustmentsId = [];
    this.mainForm.get('organizationFrom').setValue(null);
    this.setOrganizationIdFrom = null;
    this.mainForm.get('organizationTo').setValue(null);
    this.setOrganizationIdTo = null;
    this.mainForm.get('paymentType').setValue(null);
    this.setPaymentMethodId = null;
    this.mainForm.get('date').setValue(null);
    this.mainForm.get('memo').setValue(null);
    this.mainForm.get('paymentType').setValue(null);
    this.mainForm.get('memo').setValue(0);
    this.formTotal = 0;
    this.initSelects();
    this.addPayment();
  }

  removeFields(i: number) {
    if (i >= 0) {
      this.dialogUtility.promptToDelete(() => {
        this.formFields.removeAt(i);
        let cnt: number = 0;
        this.setBillingAdjustmentsId = [];
        this.formFields.controls.forEach(x => {
          this.setBillingAdjustmentsId[cnt] = x.get('billingType').value;
          this.initBillingAdjustments(false, cnt);
          cnt++;
        });
        this.mainForm.markAsDirty();
        this.updateTotal();
      }, () => {
      });
    }
  }

  processData() {
    this.saveData();
  }

  cancel(): void {
    this.mainForm.markAsPristine();
    this.mainForm.reset();
    this.closeAddEdit.emit();
    this.mainForm.markAsPristine();
    this.mainForm.markAsUntouched();
  }
}
