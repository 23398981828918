<div class="margin-top-10">
  <div class="p-grid p-fluid">
    <div class="col-md-7 col-sm-12 col-xs-12">
      <h2>Primary Trade and Skill</h2>
      <div class="p-grid p-fluid">
        @if (userTrades && primaryReady) {
          <div class="col-md-3 col-sm-3 col-xs-12 input-container">
            <label>Primary Trade</label>
            <p-dropdown [options]="userTrades" [(ngModel)]="selectedPrimaryTrade" optionLabel="Description" optionValue="ID" (onChange)="tradeSelected($event.value, false)" tabindex="0" ></p-dropdown>
          </div>
        }
        @if (selectedPrimaryTrade) {
          <div class="col-md-3 col-sm-3 col-xs-12 input-container">
            <label>Primary Skill</label>
            <p-dropdown [options]="userSkills" [(ngModel)]="selectedPrimarySkill" optionLabel="Description" optionValue="ID" (onChange)="getSkill($event)" tabindex="0" ></p-dropdown>
          </div>
        }
        @if (canTabWrite && this.selectedPrimaryTrade) {
          <div class="col-md-3 col-sm-3 col-xs-12 input-container margin-top-9">
            <button pButton label="Update Primary" class="p-button-info p-mr-2 no-margin" [className]="'list-tab-button'" icon="pi pi-disc" pTooltip="Update" tooltipPosition="bottom"
            (click)="updatePrimary()"></button>
          </div>
        }
      </div>
    </div>
    <div class="col-md-5 col-sm-12 col-xs-12">
      <div class="p-grid p-fluid">
        @if (canTabWrite && canAdd) {
          <div class="col-md-12 col-sm-12 col-xs-12 text-align-right">
            <button pButton label="Add/Edit Trade and Skills" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-plus" pTooltip="Add" tooltipPosition="bottom"
            (click)="addTradesSkills()"></button>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-20">
  </div>
</div>
<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <h2 >Current Trades and Skills</h2>
    @if (tradeSkillsCollection?.length > 0) {
      <p-table
        #dt1
        [rows]="pageRowCountUtility.pageRowCount()"
        [columns]="cols"
        dataKey="TradeID"
        [value]="tradeSkillsCollection"
        [paginator]="true"
        [showCurrentPageReport]="true"
        [metaKeySelection]="false"
        [rowsPerPageOptions]="[25,50,100,250,500]"
        [scrollable]="true"
        styleClass="p-datatable-striped"
        rowGroupMode="subheader"
        groupRowsBy="TradeDescription"
        selectionMode="single"
        responsiveLayout="scroll"
        paginatorDropdownAppendTo="body"
        >
        <ng-template pTemplate="header" let-columns>
          <tr>
            @for (col of columns; track col) {
              <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom" >
                @if (col.visible) {
                  <div class="header-text">
                    {{col.header}}
                  </div>
                  @if (col.header !=='Type') {
                    <div class="header-actions">
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                      <p-columnFilter [field]="col.field" display="menu" [matchMode]="col.matchMode" [showOperator]="true" [showAddButton]="false" [type]="col.columnType"></p-columnFilter>
                    </div>
                  }
                }
              </th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-trade>
          <tr pRowGroupHeader>
            <td [colSpan]="2" [style]="{'background-color' : '#cfcfcf'}">
              <span class="p-text-bold p-ml-2 trade-row">{{trade.TradeDescription}}</span>
              @if (trade.CanDelete) {
                <button pButton icon="pi pi-trash" (click)="removeTrade(trade.PersonTradeID)"  class="p-button-secondary delete-button " pTooltip="Delete" tooltipPosition="bottom"></button>
              }
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body"  let-rowData let-columns="columns" let-trade>
          <tr [pSelectableRow]="rowData">
            @for (col of columns; track col) {
              <td>
                @if (col.header === 'Type') {
                  @if (rowData[col.field] === true) {
                    <span>Primary</span>
                  }
                  @if (rowData[col.field] === false) {
                    <span>Additional</span>
                  }
                }
                @if (col.header !== 'Type') {
                  <div class="skill-row">{{rowData[col.field]}}</div>
                }
              </td>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [colSpan]="cols?.length" style="width: 100%;">No Trades Found.</td>
          </tr>
        </ng-template>
      </p-table>
    }
  </div>
</div>
