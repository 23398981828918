import { Component, OnInit } from '@angular/core';
import {ContextService} from '../services/context.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  constructor(public contextService: ContextService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {

    if(!this.router.url.includes('/two-factor-auth')) {
      console.log('in clear');
      sessionStorage.clear();
    }
  }
}
