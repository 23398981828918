@if (isPage && !isDialog && !isContractEmployer) {
  <div class="p-grid">
    <div class="col-md-3 col-sm-3 col-sm-3">
      <button pButton label="Back" (click)="back()" class="p-button-info" [className]="'back-button'"
      icon="pi pi-angle-left" pTooltip="Back" tooltipPosition="bottom"></button>
    </div>
  </div>
}
<div class="content p-input-filled margin-top-10">
  @if (!createNew) {
    <div class="p-grid p-fluid parent-form">
      <div class="col-md-6 col-sm-12 col-xs-12 input-container margin-top-10">
        <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="setOrganizationData($event)">
        </app-select>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p>Please try to find the employer you are looking for. If not found, create as new.</p>
        <span class="p-input-icon-left full-width">
          <i class="pi pi-search"></i>
          <input class="full-width filter" pInputText type="text" [(ngModel)]="employerFilter" (ngModelChange)="setFilter()"
            [ngModelOptions]="{standalone: true}" placeholder="Search Employers" />
          </span>
        </div>
        @if (employerFilter) {
          <div class="col-md-12 col-sm-12 col-xs-12 input-container p-mt-2">
            <button pButton label="Get Employers" class="p-button-info" [className]="'inline-button'" icon="pi pi-search" pTooltip="Get Employers" tooltipPosition="bottom"
            (click)="showTable=true; filterItems()"></button>
          </div>
        }
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
          @if (showTable && employerFilter) {
            <p-table #dt1
              [rows]="pageRowCountUtility.pageRowCount()"
              [columns]="cols"
              dataKey="ID"
              [value]="organizationList"
              [lazy]="true"
              (onLazyLoad)="loadTable($event)"
              [paginator]="true"
              [showCurrentPageReport]="true"
              [metaKeySelection]="false"
              [totalRecords]="dataTotal"
              [rowsPerPageOptions]="[25,50,100,250,500]"
              [scrollable]="true"
              [(selection)]="selorganization"
              selectionMode="multiple"
              scrollHeight="400px"
              paginatorDropdownAppendTo="body"
              [loading]="loading"
              styleClass="p-datatable-responsive-demo p-datatable-striped inner-table"
              currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
              >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th>
                    <p-tableHeaderCheckbox class="p-pl-2"></p-tableHeaderCheckbox>
                  </th>
                  @for (col of columns; track col) {
                    <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
                      @if (col.field !== 'select') {
                        <div class="header-text">
                          {{col.header}}
                        </div>
                      }
                    </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td>
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  @for (col of columns; track col) {
                    <td>
                      @if (col.visible && col.displayType === 'text') {
                        {{rowData[col.field]}}
                      }
                      @if (col.visible && col.displayType === 'numeric') {
                        {{rowData[col.field] | number:'1.0':'en-US'}}
                      }
                    </td>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [colSpan]="cols?.length + 1" style="width: 100%;">No employers found.</td>
                </tr>
              </ng-template>
            </p-table>
          }
        </div>
<!--        @if (showOtherEmployers) {-->
<!--          <div class="col-md-12 col-sm-12 col-xs-12">-->
<!--            <h3>If you are not seeing the employer you are expecting in the list, it may already be assigned. Please use the Add Existing Employer button instead.</h3>-->
<!--            <button pButton (click)="addExistingContractor()" icon="pi pi-plus" pTooltip="Add" tooltipPosition="bottom"-->
<!--                    class="inline-button p-mr-1" label="Add Existing Employer"></button>-->
<!--          </div>-->
<!--        }-->
        <div class="col-md-12 col-sm-12 col-xs-12 text-align-right">
          <div class="action-buttons float-right margin-top-10">
            @if (isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
              (click)="cancel()" [disabled]="!mainForm.dirty"></button>
            }
            @if (!isEdit) {
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
              (click)="cancel()"></button>
            }
            @if (!isSaving && employerFilter && showTable) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                (click)="processAssignments()" [className]="'inline-button'"
              [disabled]="!setOrganizationId || selorganization.length <= 0"></button>
            }
            @if (isSaving && employerFilter && showTable) {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
            @if (employerFilter && showTable) {
              <button pButton label="Create New" class="p-button-info" icon="pi pi-save" pTooltip="Create New" tooltipPosition="bottom"
                (click)="createNew = true;" [className]="'inline-button'"
              [disabled]="!setOrganizationId || selorganization.length > 0"></button>
            }
          </div>
        </div>
      </div>
    }
    @if (createNew) {
      <app-employers-tab-details [isOrganize]="isOrganize" [orgId]="setOrganizationId" (closeAddEdit)="closeAddEdit.emit($event)" [canTabWrite]="canPageWrite"></app-employers-tab-details>
    }
  </div>
