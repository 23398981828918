import {EventEmitter, Injectable, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {UserAccountService} from '../../services/user-account.service';
import {BlobStorageService} from './blob-storage.service';
import {ContextModel, tenantConfigsModel} from '../../models/context.model';
import {ContextService} from '../../services/context.service';
import {MessageService} from 'primeng/api';
import {OrganizationService} from '../../services/organizations.service';
import {UserAccountMetaDataModel} from '../../models/user-account.model';
import {environment} from '../../../environments/environment';
import {TenantConfigService} from '../../services/tenant-config.service';
import {SystemService} from '../../services/system.service';
import {InactiveStatusUtility} from '../../shared/utilities/inactive-status.utility';
import {InterfaceConfigService} from '../../services/interface-config.service';
import {InterfaceObjectsService} from '../../services/interface-objects.service';
import {InterfaceObjectHelpService} from '../../services/interface-object-help.service';
import {InterfaceConfigListModel} from '../../models/interface-config.model';

@Injectable()
export class AuthService {
  context: ContextModel;
  isAuthenticated: boolean;
  routeTo: string = 'dashboard';
  redirectUrl: string;
  @Output() authChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private loggedIn = new Subject<any>();
  public userEmitter = this.loggedIn.asObservable();
  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private userAccountService: UserAccountService, private interfaceObjectHelpService: InterfaceObjectHelpService,
              private blogStorageService: BlobStorageService, private contextService: ContextService,
              private messageService: MessageService, private organizationsService: OrganizationService,
              private tenantConfigService: TenantConfigService, private systemService: SystemService, private interfaceObjectsService: InterfaceObjectsService,
              private inactiveStatusUtility: InactiveStatusUtility, private interfaceConfigService: InterfaceConfigService) {
  }

  loggedInEmitChange(isLoggedIn) {
    this.loggedIn.next(isLoggedIn);
  }

  loginSuccess(umData: UserAccountMetaDataModel, linkedAcct: boolean, oktaRedirect: string, reRouteUrl?: string) {
    const context: ContextModel = this.contextService.contextObject;
    this.isAuthenticated = linkedAcct;
    if (umData) {
      context.UserId = umData.UserId;
      context.UserName = umData.UserName;
      context.TenantId = umData.TenantId;
      context.PersonId = umData.PersonId;
      context.OrganizationId = umData.OrganizationId;
      context.FacebookAppId = umData.FacebookAppId;
      context.EmailVerified = umData.EmailVerified;
      context.LinkLogExists = umData.LinkLogExists;
      context.UserNameEncoded = umData.UserNameEncoded;
      context.TenantIdEncoded = umData.TenantIdEncoded;
      context.Administrator = umData.Administrator;
      context.Active = umData.Active;
      context.TemporaryPassword = umData.TemporaryPassword;
      context.HasSecurityQuestionResponses = umData.HasSecurityQuestionResponses;
      if (umData.Contexts) {
        // remove duplicates
        context.Contexts = umData.Contexts.filter((a, b) => umData.Contexts.indexOf(a) === b);
      } else {
        context.Contexts = [];
      }
      context.SuperUser = umData.SuperUser;
      context.PersonName = umData.PersonName;
    }
    context.pageContexts = [];
    context.selOrganizationId = null;
    context.selectedMOrgId = null;
    context.selectedTOrgId = null;
    context.selectedOOrgId = null;
    context.selOrganizationDesc = null;
    context.selectedMOrgDesc = null;
    context.selectedTOrgDesc = null;
    context.selectedOOrgDesc = null;
    context.loggedIn = new Date().toString();
    //this.contextService.contextObject = context;
    this.interfaceConfigService.getUserInterfaceConfigList().pipe(take(1)).subscribe({
      next: (result) => {
        let save: boolean;
        context.UserPreferences = JSON.parse(JSON.stringify(result));
        const defaultUserPref: InterfaceConfigListModel[] = [
          {
            Key: 'Global_DymoInstalled',
            Value: 'No'
          },
          {
            Key: 'Global_LoadingIndicator',
            Value: 'Spinner'
          },
          {
            Key: 'Global_PageRowCount',
            Value: '25'
          },
          {
            Key: 'Global_RememberFilters',
            Value: 'Yes'
          },
          {
            Key: 'Global_SaveReminder',
            Value: 'Yes'
          },
          {
            Key: 'Global_ShowFilterDefault',
            Value: 'Show'
          },
          {
            Key: 'Global_ShowHelpDefault',
            Value: 'Hide'
          },
          {
            Key: 'Global_LandingPage',
            Value: 'Dashboard'
          },
          {
            Key: 'Global_ViewType',
            Value: 'List'
          }
        ];
        defaultUserPref.forEach(pref => {
          if (result.length === 0 || result.find(x => x.Key === pref.Key) === undefined) {
            context.UserPreferences.push({
              Key: pref.Key,
              Value: pref.Value
            });
            save = true;
          }
        });
        if (save === true) {
          this.interfaceConfigService.updateUserInterfaceAll(context.UserPreferences).pipe(take(1)).subscribe({
            next: () => {
            }
          });
        }
        this.contextService.contextObject = context;
        // console.log(this.contextService.contextObject);
      }
    });

    this.blogStorageService.getBlobToken().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (bsData) => {
        context.blobToken = bsData;
        this.contextService.contextObject = context;
        this.setTenantConfigAll().then((res: tenantConfigsModel[]) => {
          if (res) {
            const context1: ContextModel = this.contextService.contextObject;
            context1.tenantConfigs = res;
            this.contextService.contextObject = context1;
          }
          this.setRootOrgId('membership').then((res2: number) => {
            if (res2) {
              const context2: ContextModel = this.contextService.contextObject;
              context2.RootMemberOrgID = res2;
              this.contextService.contextObject = context2;
            }
            this.setRootOrgId('training').then((res3: number) => {
              if (res3) {
                const context3: ContextModel = this.contextService.contextObject;
                context3.RootTrainingOrgID = res3;
                this.contextService.contextObject = context3;
              }
              this.setRootOrgId('organize').then((res4: number) => {
                if (res4) {
                  const context4: ContextModel = this.contextService.contextObject;
                  context4.RootOrganizeOrgID = res4;
                  this.contextService.contextObject = context4;
                }
                this.inactiveStatusUtility.getPersonProfileActiveStatuses('membership').then(res5 => {
                  if (res5) {
                    const context5: ContextModel = this.contextService.contextObject;
                    context5.MembershipActiveStatus = res5;
                    this.contextService.contextObject = context5;
                  }
                  this.inactiveStatusUtility.getPersonProfileActiveStatuses('training').then(res6 => {
                    if (res6) {
                      const context6: ContextModel = this.contextService.contextObject;
                      context6.TrainingActiveStatus = res6;
                      this.contextService.contextObject = context6;
                    }
                    this.setRoute(linkedAcct, bsData, umData, oktaRedirect, reRouteUrl);
                  }, () => {
                    this.setRoute(linkedAcct, bsData, umData, oktaRedirect, reRouteUrl);
                  });
                }, () => {
                  this.setRoute(linkedAcct, bsData, umData, oktaRedirect, reRouteUrl);
                });
              }, () => {
                this.setRoute(linkedAcct, bsData, umData, oktaRedirect, reRouteUrl);
              });
            }, () => {
              this.setRoute(linkedAcct, bsData, umData, oktaRedirect, reRouteUrl);
            });
          }, () => {
            this.setRoute(linkedAcct, bsData, umData, oktaRedirect, reRouteUrl);
          });
        }, () => {
          this.setRoute(linkedAcct, bsData, umData, oktaRedirect, reRouteUrl);
        });
      }
    });
  }

  setRoute(linkedAcct, bsData, umData, oktaRedirect, reRouteUrl) {
    // the linkedAcct variable drives whether to display the main header appropriately
    if (linkedAcct) {
      this.loggedInEmitChange(bsData);
      this.interfaceObjectsService.getAppList()
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            sessionStorage.setItem('pagePermissions', JSON.stringify(res));
          }
        });
      this.interfaceObjectHelpService.getInterfaceObjectHelpKGrid()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            sessionStorage.setItem('getHelp', JSON.stringify(res));
          }
        });
      if (localStorage.getItem('redirect') !== null && localStorage.getItem('redirect') !== '') {
        // redirect and clear value if necessary
        if (localStorage.getItem('redirect') === 'page-not-found') {
          console.log('in redirect');
          this.routeTo = 'dashboard';
        } else {

          this.routeTo = localStorage.getItem('redirect');
        }
        localStorage.removeItem('redirect');
      } else if (umData && umData.TemporaryPassword) {
        if (umData.HasSecurityQuestionResponses) {
          this.routeTo = 'reset-password';
        } else {
          // redirect to security-questions if necessary
          this.routeTo = 'security-questions';
        }

      } else if (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_LandingPage')?.Value) {
        switch (this.contextService.contextObject?.UserPreferences?.find(x => x.Key === 'Global_LandingPage')?.Value) {
          case 'Dashboard' : {
            this.routeTo = 'dashboard';
            break;
          }
          case 'StudentRoster' : {
            this.routeTo = 'training/students/list';
            break;
          }
          case 'Roster' : {
            this.routeTo = 'membership/roster/list';
            break;
          }
          case 'WidgetUserDashboard' : {
            this.routeTo = 'widget-dashboard?type=user';
            break;
          }
          case 'WidgetAdminDashboard' : {
            this.routeTo = 'widget-dashboard?type=admin';
            break;
          }
        }
      }
      // console.log(this.routeTo);
      // SSO redirect to Okta
      if (oktaRedirect && oktaRedirect !== '') {
        if (window.location.hostname === 'localhost') {
          window.location.replace(`${oktaRedirect}http://localhost:4200/${this.routeTo}`);
        } else {
          window.location.replace(`${oktaRedirect}https://${window.location.hostname}/${this.routeTo}`);
        }
      } else {
        if (reRouteUrl) {
          this.router.navigateByUrl(reRouteUrl);
        } else {
          this.router.navigateByUrl(this.routeTo);
        }
      }
    }
  }

  setTenantConfigAll() {
    return new Promise((resolve, reject) => {
      this.tenantConfigService.getTenantConfigAll()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: tenantConfigsModel[]) => {
            resolve(res);
          }, error: () => {
            reject(null);
          }
        });
    });
  }

  setRootOrgId(context: string) {
    return new Promise((resolve, reject) => {
      this.organizationsService.getUnauthRootOrganizationId(this.contextService.contextObject.tenantName, context)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: number) => {
            resolve(res);
          }, error: () => {
            reject(null);
          }
        });
    });
  }

  public logout() {
    this.userAccountService.logout().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        this.systemService.clearUserCache().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            localStorage.removeItem('redirect');
            sessionStorage.clear();
            this.loggedInEmitChange(false);
            //this.router.navigateByUrl('/login');
            if (environment.environmentName === 'staging' && environment.local === true) {
              window.location.replace('/login?tenant=IUPAT');
            } else {
              window.location.replace('/login');
            }
            location.reload();
          }
        });
      }
    });
  }

  public authenticationRenew(renewLogin: string) {
    this.userAccountService.refreshLogin(renewLogin)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res) => {
        const contextObject: ContextModel = this.contextService.contextObject;
        contextObject.accessToken = res.accessToken;
        contextObject.refreshToken = res.refresh_token;
        this.contextService.contextObject = contextObject;
      }, error: (e) => {
        if (e.error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Renew Token Failed',
            detail: e.error.error_description
          });
          //Do this instead of logout because logout() causes a 401 loop
          sessionStorage.clear();
          this.loggedInEmitChange(false);
          this.router.navigateByUrl('login');
        }
      }
    });
  }

  // private handleError(e: HttpErrorResponse) {
  //   if (e.error instanceof Error) {
  //     console.log(e);
  //     const errMessage = e.error.message;
  //     return throwError(errMessage);
  //     // return Observable.throw(err.text() || 'backend server error');
  //   }
  //   return throwError('error');
  // }
}
