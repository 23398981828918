import { BaseDataGridModel } from './data-grid.model';

export interface SurveyQuestionsGridModel extends BaseDataGridModel {
  Data: SurveyQuestionModel[];
}

export interface SurveyQuestionModel {
  ID: number;
  Description: string;
  DBEntityID: number;
  DataTypeID: number;
  DataTypeDescription: string;
  DataTypeEnumerator: string;
  FilterDataTypeEnumerator: string;
  Rank: number;
  ParentID: number;
  Current: boolean;
  RequiredCount: number;
  Minimum: number;
  Maximum: number;
  Options: SurveyQuestionOptionsModel[];
  Response: string;
  ResponseStatistics: SurveyQuestionResponseStatisticsModel[];
  Children: any;
  Shared: boolean;
  PartnerIdentifier: string;
  ResponseDBEntityID: number;
  ResponseDBEntityEnumerator: string;
  ResponseDBEntityDescription: string;
}

export interface SurveyQuestionOptionsModel {
  ID: number;
  CustomFieldID?: number;
  Description: string;
  Rank?: number;
}

export interface SurveyQuestionResponseStatisticsModel {
  Description: string;
  Count: number;
}

export interface UpdateSurveyQuestionYesNoModel {
  Description: string;
  DataTypeID: number;
}

export interface UpdateSurveyQuestionRangeModel extends UpdateSurveyQuestionYesNoModel {
  Minimum: number;
  Maximum: number;
}

export interface UpdateSurveyQuestionPickListModel extends UpdateSurveyQuestionYesNoModel {
  Options: UpdateSurveyQuestionPickListOptionsModel[];
}

export interface UpdateSurveyQuestionPickListOptionsModel {
  ID: number;
  Description: string;
  Rank: number;
}

export interface SurveyQuestionResponsesGridModel extends BaseDataGridModel {
  Data: SurveyQuestionResponseModel[];
}

export interface SurveyQuestionResponseModel {
  PersonID: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  MemberNumber: string;
  TypeAlias: string;
  StatusAlias: string;
  OrganizationID: number;
  OrganizationName: string;
  Response: string;
  ResponseDate_D: string;
  PrimaryPhonePhoneType: string;
  PrimaryPhoneNumber: string;
  SecondaryPhonePhoneType: string;
  SecondaryPhoneNumber: string;
  PrimaryEmailAddress: string;
  SecondaryEmailAddress: string;
  PrimaryAddress1: string;
  PrimaryAddress2: string;
  PrimaryCity: string;
  PrimaryStateProvince: string;
  PrimaryZip: string;
  PrimaryCountry: string;
}
