@switch (indicator) {
  @case ('Spinner') {
    <div class="p-mt-5 data-progress-bar">
      <span class="fa fa-spinner list-spinner"></span>
    </div>
  }
  @case ('Progress Bar') {
    <div class="p-mt-5 data-progress-bar">
      <div class="p-mb-2"><em>{{message}}</em></div>
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
  }
}
