import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {LookupModel} from '../../../../models/lookups.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {OrganizationProjectModel} from '../../../../models/organizations.model';
import {OrganizeProjectContractorsModel} from '../../../../models/project-reports.model';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {OrganizationService} from '../../../../services/organizations.service';
import {ProjectReportsService} from '../../../../services/project-reports.service';

@Component({
  selector: 'app-project-report-add-contractor-dialog',
  templateUrl: './project-report-add-existing-contractor-dialog.component.html',
  styleUrls: ['./project-report-add-existing-contractor-dialog.component.scss']
})

export class ProjectReportAddExistingContractorDialogComponent implements OnInit, OnDestroy {
  inputObjEmployer: GenericSelectModel;
  inputObjTrade: GenericSelectModel;
  inputObjYesNo: GenericSelectModel;
  inputObjProjectOrganizationType: GenericSelectModel;
  setOrganizationTypes;
  tempEmployer;
  tempIsUnion: boolean = true;
  setTradeId;
  setTrades;
  showAdd: boolean = false;
  addProjectOrganization = {} as OrganizationProjectModel;
  projectContractors: OrganizeProjectContractorsModel[];
  currentFilter = new PrimeTableFilterModel();
  organizationTypes: LookupModel[];
  trades: LookupModel[];

  private ngUnsubscribe = new Subject();

  constructor(private lookupsDataService: LookupsDataService, private dialogService: DialogService,
              private organizationService: OrganizationService, private messageService: MessageService,
              private confirmationService: ConfirmationService, private projectReportsService: ProjectReportsService,
              private config: DynamicDialogConfig, private ref: DynamicDialogRef) {
  }

  ngOnInit(): void {

    this.initOrganizationTypes();
    this.initTrades();
    if (this.config.data.newId) {
      this.tempEmployer = this.config.data.newId;
      console.log(this.tempEmployer);
    }
    this.initEmployer(false);
  }

  ngOnDestroy(): void {
  }


  initOrganizationTypes(): void {
    this.lookupsDataService.getOrganizeProjectOrganizationType(false).then((lookupData: any[]) => {
      this.organizationTypes = lookupData;
    });
  }


  initEmployer(disable: boolean): void {
    this.inputObjEmployer = {
      labelText: 'Employer',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: true,
      selectFirstValue: false,
      initSelected: this.tempEmployer ? this.tempEmployer : null,
      disabled: disable,
      emitChangeOnLoad: true,
      customSelect: 'ShowStatus'
    };
    this.lookupsDataService.getOrganizationsByParentLookupData('UnionEmployer', this.config.data.orgId, null, true, true).then((lookupData) => {
      this.inputObjEmployer.data = lookupData;
      this.inputObjEmployer = Object.assign({}, this.inputObjEmployer);
    });
  }

  setEmployerData(event:any) {
    if (event && event[0] && event[0].ID) {
      this.tempEmployer = event[0].ID;
    } else {
      this.tempEmployer = null;
    }
  }

  createContractor() {
    this.ref.close('addNew');
  }

  initTrades(): void {
    this.lookupsDataService.getTradesLookupData(false).then((lookupData: any[]) => {
      this.trades = lookupData;
    });
  }


  saveNewContractor() {
    this.addProjectOrganization.OrganizeProjectID = this.config.data.projectData.OrganizeProjectID;
    this.addProjectOrganization.OrganizationID = this.tempEmployer;
    this.addProjectOrganization.OrganizationTypes = [];
    this.addProjectOrganization.Trades = [];
    // this.addProjectOrganization.UnionOrganization = this.tempIsUnion;
    if (this.setOrganizationTypes) {
      this.setOrganizationTypes.forEach(type => {
        this.addProjectOrganization.OrganizationTypes.push({ID: type, Description: null});
      });
    }
    if (this.setTrades) {
      this.setTrades.forEach(trade => {
        this.addProjectOrganization.Trades.push({ID: trade, Description: null});
      });
    }
    this.organizationService.createOrganizeEmployer(this.addProjectOrganization)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'New Contractor has been added to the project.'
          });
          this.setTrades = [];
          this.setOrganizationTypes = [];
          this.ref.close('updates');
        }, error: (e) => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          console.debug(e);
        }
      });
  }

}
