
<div class="page-container">
  <div class="public-card">
    <p-card class="card static-card">
      @if (logo) {
        <div class="align-center">
          <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true" />
        </div>
      }
      <div class="page-container">
        <form [formGroup]="rpForm" class="reset-password-form">
          <h2>Reset Password</h2>
          <div class="p-fieldset-content">
            <label>Security Question</label>
            <p>{{securityQuestion}}</p>
          </div>
          <div class="p-fieldset-content">
            <label>Answer<span class="ut-requireddenotation"> *</span></label>
            <input type="email" id="answer" pInputText formControlName="answer">
            @if (rpForm.controls.answer.invalid && rpForm.controls.answer.touched) {
              <div class="p-error">
                @if (rpForm.controls.answer.errors?.required) {
                  <div>An answer is required</div>
                }
                @if (rpForm.controls.answer.errors?.minlength || rpForm.controls.answer.errors?.maxlength) {
                  <div>Answer must be between 4 and 100 characters</div>
                }
              </div>
            }
          </div>
          <div class="p-fieldset-content">
            <label>New Password/Passphrase<span class="ut-requireddenotation"> *</span></label>
            <input [type]="passwordType" pInputText formControlName="password">
            @if (rpForm.controls.password.invalid && rpForm.controls.password.touched) {
              <div class="p-error">
                @if (rpForm.controls.password.errors?.required) {
                  <div>New Password is required</div>
                }
                <!--                <div *ngIf="changePasswordForm.controls.password.errors?.pattern">Must contain at least one uppercase and lowercase letter, one number, one special character, and be at least 10 characters long</div>-->
                @if (rpForm.controls.password.hasError('length')) {
                  <div>Must be at least 10 characters long</div>
                }
                @if (rpForm.controls.password.hasError('uppercase')) {
                  <div>Must contain at least one uppercase letter (A-Z)</div>
                }
                @if (rpForm.controls.password.hasError('lowercase')) {
                  <div>Must contain at least one lowercase letter (a-z)</div>
                }
                @if (rpForm.controls.password.hasError('number')) {
                  <div>Must contain at least one number (0-9)</div>
                }
                @if (rpForm.controls.password.hasError('special')) {
                  <div [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></div>
                }
                @if (rpForm.controls.password.hasError('repeats')) {
                  <div>Must have no more than 2 identical characters in a row (999 not allowed)</div>
                }
                @if (rpForm.controls.password.hasError('customValidator')) {
                  <div>No parts of your username</div>
                }
              </div>
            }
            <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPassword" label="Show Password"></p-checkbox>
          </div>
          <div class="p-fieldset-content">
            <label>Confirm New Password/Passphrase<span class="ut-requireddenotation"> *</span></label>
            <input [type]="passwordConfirmType" pInputText formControlName="passwordConfirm">
            @if (rpForm.controls.passwordConfirm.invalid && rpForm.controls.passwordConfirm.touched) {
              <div class="p-error">
                @if (rpForm.controls.passwordConfirm.errors?.required) {
                  <div>Confirm New Password is required</div>
                }
                @if (rpForm.controls.passwordConfirm.errors?.confirmedValidator) {
                  <div>New Password & Confirm New Password do not match</div>
                }
              </div>
            }
            <p-checkbox class="checkbox p-mt-2" [binary]="true" formControlName="showPasswordConfirm" label="Show Password"></p-checkbox>
          </div>
          <div class="p-fieldset-content">
            <!--      <span class="align-right"><a href="https://www.useapassphrase.com/" target="_blank" rel="noopener noreferrer" title="Why use a passphrase?">Why use a passphrase?</a></span>-->
            <span class="link" (click)="toggleRequirements()">Requirements</span>
            @if (requirements) {
              <ul>
                <li>Must be at least 10 characters long</li>
                <li>Must contain at least one uppercase letter (A-Z)</li>
                <li>Must contain at least one lowercase letter (a-z)</li>
                <li>Must contain at least one number (0-9)</li>
                <li [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></li>
                <li>Must have no more than 2 identical characters in a row (999 not allowed)</li>
                <li>No parts of your username</li>
              </ul>
            }
          </div>
          <div class="align-center">
            @if (!isLoading) {
              <button pButton label="Reset Password" class="p-button-info p-mr-2" pTooltip="Reset Password" tooltipPosition="bottom"
              (click)="resetPassword()" [className]="'inline-button'" [disabled]="rpForm.invalid || !rpForm.touched || !this.securityQuestionID"></button>
            } @else {
              <button pButton label="Reset Password" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
          <p><span class="link" [routerLink]="['/login']">&lt; Back to Login</span></p>
        </form>
      </div>
    </p-card>
    <div class="align-center">
      <button pButton type="button" class="signup-button" label="New User? - Sign Up" [routerLink]="['/public/user-registration']"></button>
    </div>
  </div>
  <p-toast [baseZIndex]="16000"></p-toast>
</div>
