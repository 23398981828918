import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import {
  MailMergeBatchesGridModel, CreateMailMergeBatchModel, MailMergeBatchDetailsGridModel,
  MailMergeBatchDetailActionModel, MailMergeBatchPrintJobsGridModel, MailMergeBatchModel, MailMergeBatchOfficerCCGridModel, MailMergeBatchDetailModel
} from '../models/mail-merge-batches.model';

@Injectable({
  providedIn: 'root',
})
export class MailMergeBatchesService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  createMailMergeBatch(modelData: CreateMailMergeBatchModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/renderbatch`, modelData);
  }

  createMembershipCardBatch(modelData: CreateMailMergeBatchModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/renderbatch/membershipcard`, modelData);
  }

  createAdhocMailMergeBatch(modelData: CreateMailMergeBatchModel): Observable<MailMergeBatchDetailModel[]> {
    return this.http.post<MailMergeBatchDetailModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/renderbatch/adhoc`, modelData);
  }

  createMailMergeBatchSharables(organizationId: number, mailMergeBatchId: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/batch/${mailMergeBatchId}/createsharables`, null);
  }

  getMailMergeBatchesPrimeNG(dataMode: string, organizationId: number, context: string, filterData, mailMergeTypeId?: number, mailMergeTemplateId?: number): Observable<MailMergeBatchesGridModel> {
    const typeId = (mailMergeTypeId) ? `&mailmergetypeid=${mailMergeTypeId}` : '';
    const templateId = (mailMergeTemplateId) ? `&mailmergetemplateid=${mailMergeTemplateId}` : '';
    return this.http.post<MailMergeBatchesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/batch/grid?context=${context}${typeId}${templateId}&mode=${dataMode}`, filterData);
  }

  getMailMergeBatch(mailMergeBatchID: number): Observable<MailMergeBatchModel> {
    return this.http.get<MailMergeBatchModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/batch/${mailMergeBatchID}`);
  }

  getMailMergeBatchDetailPrimeNG(dataMode: string, organizationId: number, mailMergeBatchId: number, filterData): Observable<MailMergeBatchDetailsGridModel> {
    return this.http.post<MailMergeBatchDetailsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/batch/${mailMergeBatchId}/grid?mode=${dataMode}&context=${this.contextService.contextObject.selContext}`, filterData);
  }

  sendMailMergeBatchEmails(organizationId: number, mailMergeBatchId: number, modelData: MailMergeBatchDetailActionModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/batch/${mailMergeBatchId}/sendemails`, modelData);
  }

  createMailMergeBatchPrintJob(organizationId: number, mailMergeBatchId: number, modelData: MailMergeBatchDetailActionModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/batch/${mailMergeBatchId}/createprintjob`, modelData);
  }

  getMailMergeBatchPrintJobPrimeNG(dataMode: string, organizationId: number, mailMergeBatchId: number, filterData): Observable<MailMergeBatchPrintJobsGridModel> {
    return this.http.post<MailMergeBatchPrintJobsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/batch/${mailMergeBatchId}/printjob/grid?mode=${dataMode}`, filterData);
  }

  updateMailMergeBatchPrintJobPrintDate(organizationId: number, mailMergeBatchId: number, mailMergeBatchPrintJobId: number): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/batch/${mailMergeBatchId}/printjob/${mailMergeBatchPrintJobId}/setprintdate`, null);
  }

  getMailMergeBatchPrintJobPersonIds(organizationId: number, mailMergeBatchId: number, mailMergeBatchPrintJobId: number): Observable<number[]> {
    return this.http.get<number[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/batch/${mailMergeBatchId}/printjob/${mailMergeBatchPrintJobId}/personids`);
  }

  getMailMergeBatchCombinedDocument(organizationId: number, mailMergeBatchId: number, fileType: string): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/batch/${mailMergeBatchId}/rendercombined?filetype=${fileType}`);
  }

  getMailMergeBatchOfficerCCPrimeNG(dataMode: string, organizationId: number, mailMergeTemplateTypeId: number, filterData, rosterQueryID?: number, recipientIDArray?: number[]): Observable<MailMergeBatchOfficerCCGridModel> {
    let url: string = '';
    if (rosterQueryID && rosterQueryID !== 0) {
      url = `&rosterqueryid=${rosterQueryID}`;
    } else if (recipientIDArray && recipientIDArray.length > 0) {
      url = `&recipientIdArray=${encodeURIComponent('[' + recipientIDArray.join() + ']')}`;
    }
    return this.http.post<MailMergeBatchOfficerCCGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/typeid/${mailMergeTemplateTypeId}/officerccgrid?mode=${dataMode}${url}`, filterData);
  }

  getMailMergeBatchEmployerCCPrimeNG(dataMode: string, organizationId: number, mailMergeTemplateTypeId: number, filterData, rosterQueryID?: number, recipientIDArray?: number[]): Observable<MailMergeBatchOfficerCCGridModel> {
    let url: string = '';
    if (rosterQueryID && rosterQueryID !== 0) {
      url = `&rosterqueryid=${rosterQueryID}`;
    } else if (recipientIDArray && recipientIDArray.length > 0) {
      url = `&recipientIdArray=${encodeURIComponent('[' + recipientIDArray.join() + ']')}`;
    }
    return this.http.post<MailMergeBatchOfficerCCGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/mailmerge/organization/${organizationId}/typeid/${mailMergeTemplateTypeId}/employerccgrid?mode=${dataMode}${url}`, filterData);
  }
}
