@if (emergencyContact) {
  <p-card >
    <form [formGroup]="mainForm" >
      <div class="p-fluid p-grid">
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          <div class="p-fluid p-grid">
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <label>First Name <sup class="req">*</sup></label>
              <input class="full-width" type="text" pInputText  formControlName="FirstName" tabindex="0">
              @if ((mainForm.controls.FirstName.dirty || mainForm.controls.FirstName.touched) &&
                mainForm.controls.FirstName.errors?.required) {
                <span class="error">'First Name' must be provided</span>
              }
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <label>Last Name <sup class="req">*</sup></label>
              <input class="full-width" type="text" pInputText  formControlName="LastName" tabindex="0">
              @if ((mainForm.controls.LastName.dirty || mainForm.controls.LastName.touched) &&
                mainForm.controls.LastName.errors?.required) {
                <span class="error">'Last Name' must be provided</span>
              }
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <label>Relationship <sup class="req">*</sup></label>
              <input class="full-width" type="text" pInputText  formControlName="Relationship" tabindex="0">
              @if ((mainForm.controls.Relationship.dirty || mainForm.controls.Relationship.touched) &&
                mainForm.controls.Relationship.errors?.required) {
                <span class="error">'Relationship' must be provided</span>
              }
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <label>Phone <sup class="req">*</sup></label>
              <p-inputMask type="text" pInputText formControlName="PhoneNumber" [className]="'form-input no-border'" mask="(999) 999-9999"
              unmask="true" tabindex="0"></p-inputMask>
              @if ((mainForm.controls.PhoneNumber.dirty || mainForm.controls.PhoneNumber.touched) &&
                mainForm.controls.PhoneNumber.errors?.required) {
                <span class="error">'Phone' must be provided</span>
              }
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 input-container">
              <label>Email</label>
              <input class="full-width" type="text" pInputText  formControlName="EmailAddress" tabindex="0">
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 input-container">
          @if (emergencyContact && addressData.Ready) {
            <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [mapDialogDataTitle]="emergencyContact?.FirstName + ' ' + emergencyContact?.LastName" [mapDialogHeader]="'Location - ' + emergencyContact?.FirstName + ' ' + emergencyContact?.LastName" (outgoingAddress)="setAddress($event)"></app-address-form>
<!--          } @else if (!isEdit && addressData.Ready) {-->
<!--            <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [mapDialogDataTitle]="mainForm?.controls?.name?.value" [mapDialogHeader]="'Location - ' + mainForm?.controls?.name?.value" (outgoingAddress)="setAddress($event)"></app-address-form>-->
          }
        </div>
        @if (canTabWrite) {
          <div class="col-md-12 col-sm-12 col-xs-12 text-align-right">
            <button pButton label="Update Contact" class="p-button-info p-mr-2"  pTooltip="Update Contact" tooltipPosition="bottom"
              (click)="processData()" [className]="'inline-button'"
            [disabled]="mainForm.invalid || !mainForm.dirty"></button>
          </div>
        }
      </div>
    </form>
  </p-card>
}
