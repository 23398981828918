<form class="margin-top-10" [formGroup]="mainForm">
  <p-divider class="dark-divider"></p-divider>
  <div class="p-grid p-fluid">
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjProgram" (emitData)="getProgramData($event)" ></app-select>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjStatus" (emitData)="getStatusData($event)" ></app-select>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjTrainingCenter" (emitData)="getTrainingCenterData($event)" ></app-select>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <label>Start Date</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="startDate" appendTo="body" placeholder="mm/dd/yyyy" [maxDate]="mainForm.controls.endDate.value" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" tabindex="0"></p-calendar>
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <label>End Date @if (closedStatus) {
        <sup class="req">*</sup>
      }</label>
      <p-calendar dateMask  dateFormat="mm/dd/yy" formControlName="endDate" appendTo="body" placeholder="mm/dd/yyyy" [minDate]="mainForm.controls.startDate.value" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" [disabled]="!closedStatus" tabindex="0"></p-calendar>
      @if ((mainForm.controls.endDate.dirty || mainForm.controls.endDate.touched) &&
        mainForm.controls.endDate.errors?.required) {
        <span class="error">'End Date' must be provided</span>
      }
    </div>
    <div class="col-md-4 col-sm-6 col-xs-12 input-container">
      <app-select class="full-width" [inputObj]="inputObjStudentGroup" (emitData)="getStudentGroupData($event)" ></app-select>
    </div>
    @if (!isEdit && hasIncomingCreditFacet) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <button pButton label="Add Incoming Hours" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-plus" pTooltip="Add Hours" tooltipPosition="bottom"
        (click)="openIncomingHours()"></button>
        <button pButton label="Clear Incoming Hours" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Clear Hours" tooltipPosition="bottom"
        (click)="clearIncomingHours()"></button>
      </div>
    }
    @if (!isEdit && studentProgram.FacetTypeList && studentProgram.FacetTypeList.length > 0) {
      <div class="col-md-12 col-sm-12 col-xs-12 input-container">
        <h3>Adding
          @if (studentProgram.FacetTypeList[0].IncomingHours_N) {
            <span> {{studentProgram.FacetTypeList[0].IncomingHours_N | number:'1.0':'en-US'}} </span>
          }
          @if (!studentProgram.FacetTypeList[0].IncomingHours_N) {
            <span> 0 </span>
          }
        incoming hour(s) from {{studentProgram.FacetTypeList[0].FacetTypeDescription}}</h3>
        @if (studentProgram.FacetTypeList.length > 1) {
          <h3>Adding
            @if (studentProgram.FacetTypeList[1].IncomingHours_N) {
              <span> {{studentProgram.FacetTypeList[1].IncomingHours_N | number:'1.0':'en-US'}} </span>
            }
            @if (!studentProgram.FacetTypeList[1].IncomingHours_N) {
              <span> 0 </span>
            }
          incoming hour(s) from {{studentProgram.FacetTypeList[1].FacetTypeDescription}}</h3>
        }
      </div>
    }
    <!--    <div class="col-md-4 col-sm-6 col-xs-12 input-container">-->
    <!--      <label>Identifier</label>-->
    <!--      <input type="text" pInputText  formControlName="identifier">-->
  <!--    </div>-->
  @if (studentProgram && isEdit) {
    @for (facets of studentProgram.FacetTypeList; track facets) {
      <div class="col-md-6 col-sm-6 col-xs-6 margin-top-10">
        <div class="p-grid p-fluid">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <h3 style="margin-block-end:3px;">{{facets.FacetTypeDescription}}</h3>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <b>Required Hours:</b> {{facets.RequiredHours_N | number:'1.0':'en-US'}}
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <b>Recorded Hours:</b> {{facets.RecordedHours_N | number:'1.0':'en-US'}}
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <b>Completed:</b> @if (facets.Completed === true ? 'Yes' : 'No') {
            <span></span>
          }
        </div>
      </div>
    </div>
  }
}
@if (canTabWrite) {
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="p-grid p-fluid">
      <div class="col-md-6 col-sm-6 col-xs-12"></div>
      <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
        @if (isEdit) {
          <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
          (click)="cancel()" [disabled]="!mainForm.dirty"></button>
        } @else {
          <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
          (click)="cancel()"></button>
        }
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
            (click)="processData()" [className]="'inline-button'"
          [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
        } @else {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  </div>
}
</div>
</form>
