import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {AddressesGridModel, AddressModel} from '../../../../models/addresses.model';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AddressesService} from '../../../../services/addresses.service';
import {MessageService} from 'primeng/api';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {DialogService} from 'primeng/dynamicdialog';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {ContextService} from '../../../../services/context.service';
import {AddressesFormComponent} from './add-edit-addresses/addresses-form.component';
import {MapsDialogComponent} from '../../maps-dialog/maps-dialog.component';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-addresses-tab',
  templateUrl: './addresses-tab.component.html',
  styleUrls: ['./addresses-tab.component.scss']
})
export class AddressesTabComponent implements OnInit, OnChanges, OnDestroy {
  addresses: AddressesGridModel;
  addressList: AddressModel[] = [];
  dataLoaded: boolean;
  selSharableId: number;
  isButtonDisabled: boolean = true;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  selectedItem: number;
  showAddEdit: boolean;
  dataTotal: number = 0;
  @Input() DBEntity: string;
  @Input() DBEntityID: number;
  @Input() canTabWrite: boolean;
  @Input() userCall: boolean = false;
  @Input() locationHeader: string = '';
  @Output() resetList = new EventEmitter<boolean>();
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private addressesService: AddressesService, private messageService: MessageService,
              private dialogUtility: DialogUtility, private contextService: ContextService,
              public dialogService: DialogService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.dataLoaded = false;
    if (this.DBEntityID) {
      this.getData();
    }
  }

  deleteAddress(Id: number, addressId: number): void {
    this.dialogUtility.promptToDelete(() => {
      if (this.contextService.contextObject.SuperUser) {
        this.addressesService.deleteAddress(Id, addressId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'The selected address has been removed.'
              });
              this.updateList(true);
            }, error: (e) => {
              if (e?.error?.Message) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: e.error.Message.replace('UM: ', ''),
                  life: 5000
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
              }
            }
          });
      } else {
        this.addressesService.deleteUserAddress(addressId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'The selected address has been removed.'
              });
              this.updateList(true);
            }, error: (e) => {
              if (e?.error?.Message) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: e.error.Message.replace('UM: ', ''),
                  life: 5000
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
                });
              }
            }
          });
      }
    }, () => {
    });
  }

  updateList(dataChanged: boolean): void {
    this.showAddEdit = false;
    if (dataChanged) {
      this.selectedItem = -1;
      // this.resetList.emit(true);
      this.getData();
    }
  }

  getData(): void {
    this.getList();
    this.getTotal();
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.addressList.length = 0;
    this.dataLoaded = false;
    if (!this.userCall) {
      this.getListRequest = this.addressesService.getPersonAddressesPrimeNG('DATA', this.DBEntityID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: AddressesGridModel) => {
            this.addresses = res;
            this.addressList = res.Data;
            if (this.canTabWrite && this.addressList.length === 0) {
              this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
            } else {
              this.showAddEdit = false;
            }
            this.dataLoaded = true;
          }
        });
    } else {
      this.getListRequest = this.addressesService.getUserPersonAddressesPrimeNG('DATA', this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: AddressesGridModel) => {
            this.addresses = res;
            this.addressList = res.Data;
            if (this.canTabWrite && this.addressList.length === 0) {
              this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
            } else {
              this.showAddEdit = false;
            }
            this.dataLoaded = true;
          }
        });
    }
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    if (!this.userCall) {
      this.getTotalRequest = this.addressesService.getPersonAddressesPrimeNG('TOTAL', this.DBEntityID, this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: AddressesGridModel) => {
            this.dataTotal = res.Total;
          }
        });
    } else {
      this.getTotalRequest = this.addressesService.getUserPersonAddressesPrimeNG('TOTAL', this.currentFilter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res: AddressesGridModel) => {
            this.dataTotal = res.Total;
          }
        });
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
    // this.resetList.emit();
  }

  filterItems(): void {
    this.dataLoaded = false;
    this.getData();
    // this.resetList.emit();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.getData();
    // this.resetList.emit();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  goToEdit(event:any): void {
    this.selSharableId = event.SharableId;
  }

  refresh(): void {
    // this.loadTable(this.currentFilter);
    this.getData();
    // this.resetList.emit();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  openAddress(addressID: number): void {
    const selectedAddress = this.addressList.find(x => x.AddressID === addressID);
    const ref = this.dialogService.open(AddressesFormComponent, {
      data: {
        id: addressID,
        DBEntityID: this.DBEntityID,
        DBEntity: this.DBEntity,
        header: this.locationHeader,
        canTabWrite: this.canTabWrite,
        isEdit: true,
        isDialog: true,
        userCall: this.userCall
      },
      header: selectedAddress.ContactCategoryDescription + ' - ' + this.locationHeader,
      width: '90%'
    });
    ref.onClose.subscribe({
      next: (outcome: string) => {
        if (outcome === 'save') {
          this.getData();
        }
      }
    });
  }

  openNewAddress(): void {
    const ref = this.dialogService.open(AddressesFormComponent, {
      data: {
        id: 0,
        DBEntityID: this.DBEntityID,
        DBEntity: this.DBEntity,
        header: this.locationHeader,
        canTabWrite: this.canTabWrite,
        isEdit: false,
        isDialog: true,
        userCall: this.userCall
      },
      header: 'New Address',
      width: '90%'
    });
    ref.onClose.subscribe({
      next: (outcome: string) => {
        if (outcome === 'save') {
          this.getData();
        }
      }
    });
  }

  resetComponent(): void {
    this.addressList = undefined;
    this.addresses = undefined;
  }

  // export(): void {
  //   const arr = JSON.parse(JSON.stringify(this.addressList));
  //   const dataToExport = removeKeysFromArray(arr, ['AddressID','ContactCategoryID','Address3','Address4','Address5','Address6','StateProvinceID','CountryID','CanDelete']);
  //   exportData('addresses', dataToExport);
  // }

  getLocation(data: AddressModel): void {
    // const url = `https://www.google.com/maps/place/${data.Address1},+${data.City},+${data.StateProvince}+${data.Zip}`;
    // this.router.navigate([]).then(() => {  window.open(url, '_blank'); });
    const ref = this.dialogService.open(MapsDialogComponent, {
      data: {
        latitude: data.Latitude,
        longitude: data.Longitude,
        title: this.locationHeader,
        street: data.Address1,
        city: data.City,
        state: data.StateProvinceDescription,
        zip: data.Zip
      },
      header: `Location - ${this.locationHeader}`,
      width: '90%',
      height: '70%'
    });
  }
}
