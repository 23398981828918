@if (invalidRecord) {
  <app-record-not-found></app-record-not-found>
}
@if (!invalidRecord) {
  <div class="content p-input-filled margin-top-10">
    <div class="p-grid p-fluid">
      @if (isPage && !isDialog) {
        <div class="col-md-12 col-sm-12 col-xs-12 add-edit-header">
          @if (!invalidRecord && isPage && !isDialog) {
            <div class="inline-back-button" (click)="back()" pTooltip="Back">
              <i class="pi pi-angle-left"></i>
            </div>
          }
          @if (isStatusInActive(roster.StatusAlias)) {
            <div class="inactive-flag-hard-corner"></div>
          }
          <div class="p-grid p-fluid">
            <div class="col-md-1 col-sm-3 col-xs-12 relative tab-container-profile">
              @if (roster.ThumbnailUrl) {
                <div>
                  <div class="avatar-with-image" [ngClass]="getAvatarClass(true, roster.StatusAlias)"><img
                    ngSrc="{{roster.ThumbnailUrl + blobToken}}" (error)="setDefaultPic()" alt="" width="71" height="71"
                    [priority]="true"/></div>
                </div>
              }
              @if (!roster.ThumbnailUrl) {
                <div>
                  <div class="avatar-without-image"
                       [ngClass]="getAvatarClass(false, roster.StatusAlias)"><img
                    ngSrc="assets/images/profile-generic.png" alt="" width="71" height="71" [priority]="true"/></div>
                  @if (roster.Positions) {
                    <div class="has-position-no-imavge"><i class="fas fa-id-card"></i></div>
                  }
                </div>
              }
            </div>
            @if (roster) {
              <div class="col-md-11 col-sm-9 col-xs-12">
                @if (isEdit && student) {
                  <h2>{{ student.FirstName }} {{ student.MiddleName }} {{ student.LastName }}@if (roster.Suffix) {
                    <span> {{ roster.Suffix }}</span>
                  }
                    @if (roster.OrganizationAbbreviation) {
                      <span> - {{ roster.OrganizationAbbreviation }}</span>
                    }</h2>
                }
                @if (isEdit && student) {
                  <h4>
                    @if (student.StatusAlias) {
                      <span
                        class="p-m-0"><b>{{ roster.StatusAlias }} {{ roster.TypeAlias }} @if (roster.MemberSubclass) {
                        <span> - {{ roster.MemberSubclass | firstLetter }}</span>
                      }</b></span>
                    }
                    @if (student.MemberNumber) {
                      <span class="p-m-0"> | <b>Member #:</b> {{ roster.MemberNumber }}</span>
                    }
                    <!--                <span *ngIf="student.MemberStartDate" class="p-m-0"> | <b>Initiated:</b> {{roster.MemberStartDate | date: 'shortDate'}}</span>-->
                    <!--                <span *ngIf="student.PaidThroughDate" class="p-m-0"> | <b>Paid Through:</b> {{roster.PaidThroughDate | date : 'MM/dd/yyy'}}</span>-->
                  </h4>
                }
                @if (!isEdit) {
                  <h2>Add Student</h2>
                }
              </div>
            }
          </div>
        </div>
      }
      <div class="col-md-12 col-sm-12 col-xs-12">
        @if (!isPage || isDialog) {
          <p-divider></p-divider>
        }
        @if (showTabs) {
          <p-tabView (onChange)="resetWarning()">
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabDemographics')) {
              <p-tabPanel header="Contact">
                <ng-template pTemplate="content">
                  <app-global-student-roster-contact-form [PersonID]="ContentID" [isEdit]="isEdit"
                                                          [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabDemographics')"></app-global-student-roster-contact-form>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabMembership')) {
              <p-tabPanel header="Membership">
                <ng-template pTemplate="content">
                  <app-global-roster-student-membership [PersonID]="ContentID"
                                                        [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabMembership')"
                                                        [isEdit]="isEdit"></app-global-roster-student-membership>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPii')) {
              <p-tabPanel [cache]="false" header="Secure Data">
                <ng-template pTemplate="content">
                  <app-roster-tab-secure-data [PersonID]="ContentID"
                                              [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabPii')"
                                              [isEdit]="isEdit"></app-roster-tab-secure-data>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabPrograms')) {
              <p-tabPanel header="Programs">
                <ng-template pTemplate="content">
                  <app-global-roster-student-programs-tab [PersonID]="ContentID" [isEdit]="isEdit"
                                                          [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabPrograms')"></app-global-roster-student-programs-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabClassHistory')) {
              <p-tabPanel header="Training & Certifications">
                <ng-template pTemplate="content">
                  <app-roster-student-training-certification [DBEntity]="'PERSON'" [DBEntityID]="ContentID"
                                                             [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabClassHistory')"
                                                             [interfaceObjectDesc]="'tabClassHistory'"
                                                             [parentObjectEnum]="InterfaceObjectEnum"></app-roster-student-training-certification>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabCommunications')) {
              <p-tabPanel header="Communications">
                <ng-template pTemplate="content">
                  <app-communications-user-profile-tab [DBEntityID]="ContentID"
                                                       [interfaceObjectDesc]="'tabCommunications'"
                                                       [parentObjectEnum]="InterfaceObjectEnum"></app-communications-user-profile-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEmployment') && _employmentTradeTab()) {
              <p-tabPanel header="Employment">
                <ng-template pTemplate="content">
                  <app-employment-trade-tab [PersonID]="ContentID" [OrganizationID]="roster.OrganizationID"
                                            [isEdit]="isEdit"
                                            [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployment')"
                                            [interfaceObjectDesc]="'tabEmployment'"
                                            [parentObjectEnum]="InterfaceObjectEnum"></app-employment-trade-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabEmployment') && !_employmentTradeTab()) {
              <p-tabPanel header="Employment">
                <ng-template pTemplate="content">
                  <app-employment-default-tab [PersonID]="ContentID" [OrganizationID]="roster.OrganizationID"
                                              [isEdit]="isEdit"
                                              [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabEmployment')"
                                              [interfaceObjectDesc]="'tabEmployment'"
                                              [parentObjectEnum]="InterfaceObjectEnum"></app-employment-default-tab>
                </ng-template>
              </p-tabPanel>
            }
            @if (_tabsPermissionsUtility.checkTabRead(permissions, 'tabTradesSkills')) {
              <p-tabPanel header="Trades/Skills" [disabled]="!isEdit">
                <ng-template pTemplate="content">
                  <app-trade-skills [ContentID]="ContentID" DBEntity="Profile" [isEdit]="isEdit"
                                    [canTabWrite]="canPageWrite && _tabsPermissionsUtility.checkTabWrite(permissions,'tabTradesSkills')"></app-trade-skills>
                </ng-template>
              </p-tabPanel>
            }
          </p-tabView>
        }
      </div>
    </div>
  </div>
}
