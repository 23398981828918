@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
<form [formGroup]="mainForm" class="margin-top-10 padding-bottom-50">
  <div class="p-grid p-fluid">
    <div class="col-md-7 col-sm-12 col-xs-12">
      <div class="p-grid p-fluid">
        @if (!isEdit) {
          <div class="col-md-12 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjOrganization" (emitData)="setOrganizationData($event)">
            </app-select>
          </div>
        }
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>Name <sup class="req">*</sup></label>
          <input class="full-width" type="text" pInputText  formControlName="name">
          @if ((mainForm.controls.name.dirty || mainForm.controls.name.touched) &&
            mainForm.controls.name.errors?.required) {
            <span class="error">'Name' must be provided</span>
          }
        </div>
        @if (_employerContactCustom()) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <label>Doing Business As </label>
            <input class="full-width" type="text" pInputText  formControlName="doingBusinessAs" tabindex="0">
          </div>
        }
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <app-select class="full-width" [inputObj]="inputObjStatus" (emitData)="getStatusData($event)"></app-select>
        </div>
        @if (_employerContactCustom() && !isOrganize) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <label>Status Date</label>
            <p-calendar dateMask  placeholder="mm/dd/yyyy" formControlName="statusDate" appendTo="body" dateFormat="mm/dd/yy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" [styleClass]="'p-mt-2'" tabindex="0"></p-calendar>
          </div>
        }
        @if (_employerContactCustom() && !isOrganize) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <label>Employer ID</label>
            <input class="full-width" type="text" pInputText  formControlName="employerId" tabindex="0">
          </div>
        }
        <div class="col-md-6 col-sm-12 col-xs-12 input-container">
          <label>FEIN</label>
          <input class="full-width" type="text" pInputText  formControlName="FEIN" tabindex="0">
        </div>
        @if (_employerContactCustom()) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <label>Website</label>
            <input class="full-width" type="text" pInputText  formControlName="website" tabindex="0">
          </div>
        }
        @if (_employerContactCustom()) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <label>Primary Contact</label>
            <input class="full-width" type="text" pInputText  formControlName="primaryContact" tabindex="0">
          </div>
        }
        @if (_employerContactCustom() && isEdit) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjHomeOrganization" (emitData)="setHomeOrganizationData($event)">
            </app-select>
          </div>
        }
        @if (_employerContactCustom()) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjEmployerType" (emitData)="setEmployerTypeData($event)">
            </app-select>
          </div>
        }
        @if (!_employerContactCustom()) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjClassification" (emitData)="setEmployerClassificationData($event)">
            </app-select>
          </div>
        }
        @if (_employerContactCustom() && !isOrganize) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjEnterpriseType" (emitData)="setEnterpriseTypeData($event)">
            </app-select>
          </div>
        }
        @if (_employerContactCustom() && !isOrganize) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjMinorityStatus" (emitData)="setMinorityStatusData($event)">
            </app-select>
          </div>
        }
        @if (_employerContactCustom() && !isOrganize) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <app-select class="full-width" [inputObj]="inputObjSubdivision" (emitData)="setSubdivisionData($event)">
            </app-select>
          </div>
        }
        @if (_employerContactCustom() && !isOrganize) {
          <div class="col-md-6 col-sm-3 col-xs-12 input-container">
            <label>9(a) Language</label>
            <app-yes-no-select [isBoolean]="true" (emitData)="setNineA = $event" [selectedValue]="setNineA"></app-yes-no-select>
          </div>
        }
        @if (_employerContactCustom() && !isOrganize) {
          <div class="col-md-6 col-sm-3 col-xs-12 input-container">
            <label>Pension Participating?</label>
            <app-yes-no-select [isBoolean]="true" (emitData)="setPension = $event" [selectedValue]="setPension"></app-yes-no-select>
          </div>
        }
        @if (_employerContactCustom() && !isOrganize) {
          <div class="col-md-6 col-sm-12 col-xs-12 input-container">
            <label>Join Date</label>
            <p-calendar dateMask  placeholder="mm/dd/yyyy" formControlName="joinDate" appendTo="body" dateFormat="mm/dd/yy" [showIcon]="true" [yearNavigator]="true" yearRange="1950:2025" [styleClass]="'p-mt-2'"></p-calendar>
          </div>
        }
      </div>
    </div>
    <div class="col-md-5 col-sm-12 col-xs-12">
      <p-card header="Address" class="parent-p-card">
        <div class="p-grid p-fluid">
          <div class="col-md-12 col-sm-12 col-xs-12 input-container">
            @if (employer && addressData.Ready) {
              <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [mapDialogDataTitle]="employer?.Name" [mapDialogHeader]="'Employer Location - ' + employer?.Name" [showCounty]="true" [showZipPlus4]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
            } @else if (!isEdit && addressData.Ready) {
              <app-address-form [incomingAddress]="addressData" [canTabWrite]="canTabWrite" [mapDialogDataTitle]="mainForm?.controls?.name?.value" [mapDialogHeader]="'Employer Location - ' + mainForm?.controls?.name?.value" [showCounty]="true" [showZipPlus4]="true" (outgoingAddress)="setAddress($event)"></app-address-form>
            }
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 input-container">
            <p-divider class="dark-divider"></p-divider>
          </div>
          <div class="col-md-6 col-sm-3 col-xs-12 input-container">
            <label>Send Journal</label>
            <app-yes-no-select [isBoolean]="true" (emitData)="sendJournal = $event" [selectedValue]="false"></app-yes-no-select>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 input-container mask-container">
            <label>Phone #</label>
            <p-inputMask type="text" pInputText formControlName="primaryPhoneNumber" [className]="'form-input no-border'" mask="(999) 999-9999"
            unmask="true" tabindex="0"></p-inputMask>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 input-container mask-container">
            <label>Fax #</label>
            <p-inputMask type="text" pInputText formControlName="faxNumber" [className]="'form-input no-border'" mask="(999) 999-9999"
            unmask="true" tabindex="0"></p-inputMask>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 input-container">
            <label>E-mail</label>
            <input type="text" pInputText  formControlName="primaryEmail" tabindex="0">
            @if (mainForm.controls.primaryEmail.invalid || mainForm.controls.primaryEmail.touched) {
              <div class="p-error">
                @if (mainForm.controls.primaryEmail?.errors) {
                  <div>E-mail must be a valid e-mail address</div>
                }
              </div>
            }
          </div>
        </div>
      </p-card>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 text-align-right">
      <div class="action-buttons float-right margin-top-10">
        @if (isEdit) {
          <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
          (click)="cancel()" [disabled]="!mainForm.dirty"></button>
        } @else {
          <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
          (click)="cancel()"></button>
        }
        @if (!isSaving) {
          <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
            (click)="processData()" [className]="'inline-button'"
          [disabled]="mainForm.invalid || !mainForm.dirty"></button>
        } @else {
          <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
            <i class="pi pi-spin pi-spinner save-spinner"></i>
          </button>
        }
      </div>
    </div>
  </div>
</form>
