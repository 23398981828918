@if (employer.PhoneNumber) {
  <a [href]="'tel:'+employer.PhoneNumber">
    <div class="icon"><i class="fas fa-phone"></i></div>
  </a>
}
@if (employer.EmailAddress) {
  <a [href]="'mailTo:' +employer.EmailAddress" target="_top">
    <div class="icon"><i class="fas fa-envelope"></i></div>
  </a>
}
@if (employer.Latitude && employer.Longitude && employer.Name) {
  <div class="icon hide-on-mobile" pTooltip="Map" tooltipPosition="bottom"
       (click)="getLocation(employer)"><i class="fas fa-map-marker-alt"></i></div>
}
<app-action-buttons [AlertMessage]="'Employer Alert: '" [AlertNavigateTo]="'/' + routeContext + '/employers/edit/' + employer.ID" [AlertTitle]="employer.Name" [InterfaceObjectEnum]="InterfaceObjectEnum"
                    [OrganizatioinID]="employer.ID"></app-action-buttons>
