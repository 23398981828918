<div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding">
  <!--  <p-divider class="dark-divider"></p-divider>-->
  <div class="row no-padding">
    <h3>Filter</h3>
  </div>
</div>
@for (filter of activeFilters; track filter; let i = $index; let last = $last) {
  <div class="col-md-12 col-sm-12 col-xs-12 filter-item-padding margin-top-5">
    @switch (filter.type) {
      @case ('text') {
        <div>
          <label [hidden]="!filter.visible">{{ filter.label }}</label>
          <input [hidden]="!filter.visible" class="full-width filter" pInputText type="text"
                 [value]="getCurrentValue(i, 'text')" (keyup.enter)="updateTextFilter(i, true, isLast, $event, false)"
                 (blur)="updateTextFilter(i, false, isLast, $event, false)">
        </div>
      }
      @case ('multi') {
        <div>
          <label [hidden]="!filter.visible" [ngClass]="{'loading-total':!getIndexData(i)}">{{ filter.label }}</label>
          <app-multi-select-filter-list [hidden]="!filter.visible" class="full-width" [inputObj]="getIndexData(i)"
                                        (emitData)="getGenericMultiData($event, i)"
                                        (loadData)="initGenericMulti(false, i)" [labelOption]="filter.labelOption"
                                        [valueOption]="filter.valueOption">
          </app-multi-select-filter-list>
        </div>
      }
      @case ('boolean') {
        <div>
          <label [hidden]="!filter.visible">{{ filter.label }}</label>
          <app-yes-no-select [hidden]="!filter.visible" [isBoolean]="true"
                             (emitData)="updateBooleanFilter(i, false, false,  $event)" [nullable]="true"
                             [selectedValue]="getCurrentValue(i, 'boolean', null, filter)"></app-yes-no-select>
        </div>
      }
      @case ('hasAmount') {
        <div>
          <label [hidden]="!filter.visible">{{ filter.label }}</label>
          <app-yes-no-select [hidden]="!filter.visible" [isAmount]="true"
                             (emitData)="updateHasAmountFilter(i, false, false,  $event)" [nullable]="true"
                             [selectedValue]="getCurrentValue(i, 'hasAmount')"></app-yes-no-select>
        </div>
      }
      @case ('filterMyData') {
        <div>
          <label [hidden]="!filter.visible">{{ filter.label }}</label>
          <app-filter-my-data-select [hidden]="!filter.visible"
                                     (emitData)="updateMyDataFilter(i, false, false,  $event)" [nullable]="true"
                                     [selectedValue]="getCurrentValue(i, 'filterMyData')"
                                     optionName="Reports"></app-filter-my-data-select>
        </div>
      }
      @case ('dateRange') {
        <div>
          <div class="p-grid p-fluid" [hidden]="!filter.visible">
            <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
              <label>Start Date</label>
              <p-calendar dateMask appendTo="body" dateFormat="mm/dd/yy"
                          (onSelect)="updateDateFilter(i, false, true, $event)" [showIcon]="true"
                          placeholder="mm/dd/yyyy" [yearNavigator]="true" yearRange="1950:2035"
                          (onBlur)="updateDateFilter(i, false, true, $event)" dataType="string"></p-calendar>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10">
              <label>End Date</label>
              <p-calendar dateMask appendTo="body" dateFormat="mm/dd/yy"
                          (onSelect)="updateDateFilter(i, false, false, $event)" [showIcon]="true"
                          placeholder="mm/dd/yyyy" [yearNavigator]="true" yearRange="1950:2035"
                          (onBlur)="updateDateFilter(i, false, false, $event)" dataType="string"></p-calendar>
            </div>
          </div>
        </div>
      }
      @case ('last') {
        <!-- always needs to be last field -->
        <div>
          <app-last-in-loop (emitData)="filterItems(false)" [dataSent]="dataIsReady"></app-last-in-loop>
        </div>
        <!-- always needs to be last field -->
      }
    }
  </div>
}
<div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 filter-item-padding">
  <div class="list-filter-buttons text-align-right">
    <button (click)="resetFilter()" [ngClass]="{'filter-active': (currentFilter.filters | json) !== '{}'}" class="p-button-download export-menu" icon="pi pi-times"
            label="Clear" pButton>
    </button>
    <button (click)="filterItems(true)" class="p-button-download export-menu" icon="pi pi-search" label="Search"
            pButton>
    </button>
  </div>
</div>
