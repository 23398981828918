<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 colxs-12">
    <p>Please be aware that exporting times may vary (even up to several minutes). Thank you for your patience.</p>
    @if(canExportPii && config.data.isRoster) {
      <p-checkbox class="checkbox p-mb-2" [binary]="true" [(ngModel)]="includePii"
                  label="Include Secure Data in Export"></p-checkbox>
    }
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-5 p-button-auto-width text-align-right">
    <button pButton class="p-button-download contact-button p-mr-1" icon="pi pi-times" label="Cancel"
            pTooltip="Cancel" tooltipPosition="bottom" (click)="closeDialog()">
    </button>
    <button pButton class="p-button-download contact-button" icon="pi pi-download" label="Continue"
            pTooltip="Continue" tooltipPosition="bottom" (click)="export()">
    </button>
  </div>
</div>
