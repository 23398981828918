import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {AttributesValueByEntityGridModel, AttributeValueByEntityModel} from '../../../../models/attributes.model';
import {AttributesService} from '../../../../services/attributes.service';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-custom-fields-tab',
  templateUrl: './custom-fields-tab.component.html',
  styleUrls: ['./custom-fields-tab.component.scss']
})

export class CustomFieldsTabComponent implements OnInit, OnChanges, OnDestroy {
  isDirty: boolean;
  isSaving: boolean = false;
  isExporting: boolean;
  customFields: AttributesValueByEntityGridModel;
  customFieldsList: AttributeValueByEntityModel[] = [];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  descriptionFilter: string = '';
  showAddEdit: boolean;
  dataLoaded: boolean;
  totalLoaded: boolean;
  showData: boolean = false;
  selectedItem: number;
  dataTotal: number = 0;
  usedCustomFields: number[] = [];
  @Input() category: string;
  @Input() responseentity: string;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() PersonID: number;
  @Input() OrgRefID: number;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() canAdd: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private ref: DynamicDialogRef,
              private messageService: MessageService, private attributesService: AttributesService,
              private dialogUtility: DialogUtility, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges() {
    if (this.PersonID || this.OrgRefID) {
      this.getCustomFields();
    }
  }

  getCustomFields() {
    switch (true) {
      case this.PersonID !== undefined:
        if (this.getListRequest) {
          this.getListRequest.unsubscribe();
        }
        this.selectedItem = -1;
        this.customFieldsList.length = 0;
        this.dataLoaded = false;
        this.getListRequest = this.attributesService.getPersonValuePrimeNG('DATA', this.PersonID, this.category, this.currentFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.customFields = res;
              this.customFieldsList = res.Data;
              this.usedCustomFields = [];
              this.customFieldsList.forEach(field => {
                this.usedCustomFields.push(field.CustomFieldID);
              });
              this.dataLoaded = true;
            }, error: (e) => {
              console.debug(e);
              this.dataLoaded = true;
            }
          });
        if (this.getTotalRequest) {
          this.getTotalRequest.unsubscribe();
        }
        this.dataTotal = 0;
        this.totalLoaded = false;
        this.getTotalRequest = this.attributesService.getPersonValuePrimeNG('TOTAL', this.PersonID, this.category, this.currentFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.dataTotal = res.Total;
              if (this.canAdd && this.dataTotal <= 0) {
                this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
              } else {
                this.showAddEdit = false;
              }
              this.totalLoaded = true;
            }, error: (e) => {
              console.debug(e);
              this.totalLoaded = true;
            }
          });
        break;
      case this.OrgRefID !== undefined:
        this.getData();
        break;
    }
  }

  getData(): void {
    this.getList();
    this.getTotal();
  }

  getList(): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.customFieldsList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.attributesService.getOrganizationReferenceValuePrimeNG('DATA', this.OrgRefID, this.category, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.customFields = res;
          this.customFieldsList = res.Data;
          this.customFieldsList.forEach(field => {
            this.usedCustomFields.push(field.CustomFieldID);
          });
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
  }

  getTotal(): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.attributesService.getOrganizationReferenceValuePrimeNG('TOTAL', this.OrgRefID, this.category, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          if (this.canAdd && this.dataTotal <= 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  paginate(event:any) {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.getData();
  }

  filterItems() {
    if (this.descriptionFilter && this.descriptionFilter !== '') {
      this.initFilters('Attribute', this.descriptionFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.Attribute;
    }
    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('Value', this.activeGlobalFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.Value;
    }
    this.getCustomFields();
  }

  clearFilters(): void {
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.descriptionFilter = null;
    this.getCustomFields();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  refresh() {
    this.getCustomFields();
  }

  goToEdit(event:any) {
    //sessionStorage.setItem('sessionViewType', 'Grid');

  }

  updateList() {
    this.showAddEdit = false;
    this.getCustomFields();
    this.selectedItem = -1;
  }

  deleteRecord(id) {
    this.dialogUtility.promptToDelete(() => {
      this.attributesService.deleteAttributeValue(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success', summary: 'Success',
              detail: 'Custom Field has been deleted'
            });
            this.getCustomFields();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  expandData(index, id) {
    this.showData = !this.showData;
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  cancel() {
    this.ref.destroy();
    this.showAddEdit = false;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.customFieldsList));
        const dataToExport = removeKeysFromArray(arr, []);
        exportData('custom-fields', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.attributesService.getOrganizationReferenceValuePrimeNG('DATA', this.OrgRefID, this.category, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, []);
              exportData('custom-fields', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
