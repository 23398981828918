<div class="p-grid p-fluid">
  <div class="col-md-6 col-sm-12 col-xs-12 margin-top-10 ">
    <div class="margin-left-10"><b>Total Hours
      Reported: {{ reportedHours.TotalReportedHours | number:'1.0':'en-US' }}</b></div>
  </div>
  <div class="col-md-6 col-sm-12 col-xs-12 action-buttons float-right">
    <button pButton (click)="refresh()" icon="pi pi-refresh"
            pTooltip="Refresh" tooltipPosition="bottom"></button>
    @if (!isExporting) {
      <button pButton class="p-button-download export-menu export-excel" [disabled]="dataTotal === 0"
              icon="fa fa-file-excel"
              pTooltip="Export" tooltipPosition="bottom" (click)="export()">
      </button>
    } @else {
      <button pButton class="p-button-download export-menu export-excel p-pl-2" [disabled]="true"
              icon="pi pi-spin pi-spinner save-spinner">
      </button>
    }
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 table-padding">
    <p-table [rows]="pageRowCountUtility.pageRowCount()"
             [value]="reportedList"
             [columns]="cols"
             [totalRecords]="dataTotal"
             [paginator]="true"
             [showCurrentPageReport]="true"
             [metaKeySelection]="false"
             [rowsPerPageOptions]="[25,50,100,250,500]"
             [scrollable]="true"
             paginatorDropdownAppendTo="body"
             styleClass="p-datatable-gridlines p-datatable-striped"
             currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries">
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (col of columns; track col) {
            <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
              @if (col.visible) {
                <div class="header-text">
                  {{ col.header }}
                </div>
                <div class="header-actions">
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                  <p-columnFilter [field]="col.field" display="menu" [matchMode]="col.matchMode" [showOperator]="true"
                                  [showAddButton]="false" [type]="col.columnType"></p-columnFilter>
                </div>
              }
            </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
        <tr [pSelectableRow]="rowData">
          @for (col of columns; track col) {
            <td>
              @if (col.visible && col.displayType === 'date') {
                {{ rowData[col.field] | date: 'shortDate' }}
              }
              @if (col.visible && col.displayType === 'text') {
                {{ rowData[col.field] }}
              }
              @if (col.visible && col.displayType === 'numeric') {
                {{ rowData[col.field] }}
              }
            </td>
          }
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
