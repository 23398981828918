<div class="p-grid">
  <div class="col-md-12 col-sm-12 col-xs-12 margin-top-15">
    <p-tabView (onChange)="resetWarning()">
      <p-tabPanel header="Docs">
        <ng-template pTemplate="content">
          <app-docs-tab [DBEntity]="'ACTIVITYHISTORY'" [DBEntityID]="ContentID" [interfaceObjectDesc]="'tabDocs'" [canTabWrite]="canTabWrite" [InterfaceObjectEnum]="InterfaceObjectEnum"></app-docs-tab>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Notes" [disabled]="!isEdit">
        <ng-template pTemplate="content">
          <app-notes-tab [DBEntity]="'ACTIVITYHISTORY'" [DBEntityID]="ContentID" [interfaceObjectDesc]="'tabNotes'" [canTabWrite]="canTabWrite" [canEdit]="true" [parentObjectEnum]="InterfaceObjectEnum"></app-notes-tab>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

