<div class="margin-top-10">
  <p-divider class="dark-divider"></p-divider>
  <div class="p-grid p-fluid parent-form">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
      @if (employerID) {
        <p-table
          #dt1
          [rows]="pageRowCountUtility.pageRowCount()"
          [columns]="cols"
          dataKey="ID"
          [value]="organizationList"
          [lazy]="true"
          (onLazyLoad)="loadTable($event)"
          [paginator]="true"
          [showCurrentPageReport]="true"
          [metaKeySelection]="false"
          [totalRecords]="dataTotal"
          [rowsPerPageOptions]="[25,50,100,250,500]"
          [scrollable]="true"
          [(selection)]="selorganization"
          selectionMode="multiple"
          scrollHeight="400px"
          paginatorDropdownAppendTo="body"
          [loading]="loading"
          styleClass="p-datatable-responsive-demo p-datatable-striped inner-table"
          currentPageReportTemplate="Showing {first} to {last} of {{dataTotal | number}} entries"
          >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th>
                <p-tableHeaderCheckbox class="p-pl-2"></p-tableHeaderCheckbox>
              </th>
              @for (col of columns; track col) {
                <th [pSortableColumn]="col.field" pTooltip="{{col.fullHeader}}" tooltipPosition="bottom">
                  @if (col.field !== 'select') {
                    <div class="header-text">
                      {{col.header}}
                    </div>
                  }
                  @if (col.field !== 'select') {
                    <div class="header-actions">
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                      @if (col.columnType !== 'picker') {
                        <p-columnFilter type="{{col.columnType}}" matchMode="{{col.matchMode}}" [field]="col.field" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                      }
                    </div>
                  }
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </td>
              @for (col of columns; track col) {
                <td>
                  @if (col.visible && col.displayType === 'text') {
                    {{rowData[col.field]}}
                  }
                  @if (col.visible && col.displayType === 'numeric') {
                    {{rowData[col.field] | number:'1.0':'en-US'}}
                  }
                </td>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [colSpan]="cols?.length" style="width: 100%;">No organizations found.</td>
            </tr>
          </ng-template>
        </p-table>
      }
    </div>
  </div>
  <div class="p-grid p-fluid margin-top-10">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="p-grid p-fluid">
        <div class="col-md-6 col-sm-6 col-xs-12"></div>
        <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
          <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'" icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
          (click)="cancel()"></button>
          @if (!isSaving) {
            <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
              (click)="processData()" [className]="'inline-button'"
            [disabled]="selorganization.length<=0"></button>
          } @else {
            <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
              <i class="pi pi-spin pi-spinner save-spinner"></i>
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</div>
