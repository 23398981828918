import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ContextService} from '../../../../services/context.service';
import {RosterQueryService} from '../../../../services/roster-queries.service';
import {MessageService} from 'primeng/api';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {RosterQueryExistingModel} from '../../../../models/roster-query-primeng.model';
import {ProgramsService} from '../../../../services/programs.service';
import {ProgramStudentHoursByFacetTypeModel} from '../../../../models/programs.model';
import {PageRowCountUtility} from '../../../../shared/utilities/page-row-count.utility';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';

@Component({
  selector: 'app-program-student-hours',
  templateUrl: './program-student-hours-dialog.component.html',
  styleUrls: ['./program-student-hours-dialog.component.scss']
})

export class ProgramStudentHoursDialogComponent implements OnInit, OnDestroy {
  isSaving: boolean;
  mainForm: FormGroup;
  setOrganizationId: number;
  inputObjOrganization: GenericSelectModel;
  saveRosterQuery = {} as RosterQueryExistingModel;
  selectedType: string = 'ojl';
  programHours: ProgramStudentHoursByFacetTypeModel;
  dataTotal: number;
  loading: boolean;
  cols: any[];
  totalHours: number = 0;
  currentFilter = new PrimeTableFilterModel();
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, private contextService: ContextService, public ref: DynamicDialogRef,
              private rosterQueryService: RosterQueryService, private programsService: ProgramsService,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private lookupsDataService: LookupsDataService, public pageRowCountUtility: PageRowCountUtility) {
    this.mainForm = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.config.data.type.toLowerCase() === 'ojl') {
      this.cols = [
        {field: 'FacetDescription', header: 'Facet', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
        //  { field: 'Hours', header: 'Hours',  columnType: 'numeric', matchMode: 'contains', displayType: 'numeric', visible: true },
        //{ field: 'RequiredHours', header: 'Hours',  columnType: 'numeric', matchMode: 'contains', displayType: 'numeric', visible: true },
        {field: 'ApprovedHours', header: 'Approved Hours', columnType: 'numeric', matchMode: 'contains', displayType: 'numeric', visible: true},
        {field: 'WaitingForApprovalHours', header: 'Waiting for Approval', columnType: 'numeric', matchMode: 'contains', displayType: 'numeric', visible: true},
        {field: 'RejectedHours', header: 'Rejected Hours', columnType: 'numeric', matchMode: 'contains', displayType: 'numeric', visible: true}
      ];
    } else {
      this.cols = [
        {field: 'FacetDescription', header: 'Facet', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
        {field: 'Hours', header: 'Hours', columnType: 'numeric', matchMode: 'contains', displayType: 'numeric', visible: true},
      ];
    }


    this.currentFilter.filters = {};
    this.currentFilter.sortOrder = 1;
    this.currentFilter.rows = 25;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  loadTable(event:any) {
    if (event) {
      this.currentFilter = event;
    } else {
      this.currentFilter.filters = {};
      this.currentFilter.sortOrder = 1;
    }
    this.getData();
  }

  getData() {
    this.programsService.getProgramStudentDetailsByFacetType(this.config.data.id, this.config.data.type.toLowerCase())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.programHours = res;
          this.dataTotal = res.Facets.length;
          this.programHours.Facets.forEach(facet => {
            this.totalHours = this.totalHours + facet.Hours;
          });
        }
      });
  }

  closeDialog() {
    this.ref.destroy();
  }

  cancel() {
    this.closeDialog();
  }


}
