<div class="p-grid p-fluid">
  <div class="col-md-12 col-sm-12 col-sx-12 margin-top-10">
    <p-tabView>
      <p-tabPanel header="Upload New Image">
        <div class="p-grid p-fluid">
          <div class="col-md-12 col-sm-12 col-sx-12 margin-top-10 p-button-auto-width">
            <p-fileUpload accept="image/*" class="p-button-download export-menu image-button" mode="basic" name="myFile[]" customUpload="true"
            (onSelect)="uploadImage($event)" chooseIcon="pi pi-upload" maxFileSize="4e+6" chooseLabel ="Select Image"></p-fileUpload>
          </div>
          <div class="col-md-12 col-sm-12 col-sx-12 text-align-right">
            @if (!isSaving) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save" tooltipPosition="bottom"
                (click)="returnUrl()" [className]="'inline-button'"
              [disabled]="!imageUrl"></button>
            } @else {
              <button pButton label="Add" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </div>
      </p-tabPanel>
      <!--      <p-tabPanel header="Select Existing Image">-->
      <!--        Media Content Here-->
    <!--      </p-tabPanel>-->
  </p-tabView>
</div>
</div>
