@if (memberData) {
  <div class="member-verification-container">
    <div class="header">
      <div class="logo"></div>
      <div class="line"></div>
      <div class="member-name">Member Verification</div>
    </div>
    <div class="member-card" >
      <div class="member-card-header">Member:</div>
      <div class="member-card-body">
        <div class="member-image"><img [src]="memberData.ThumbnailUrl" alt="" (error)="setDefaultPic()"></div>
        <div class="details-container">
          <div class="line-item"><b>Name:</b> {{memberData.FullName}}</div>
          <div class="line-item"><b>Member #:</b> {{memberData.MemberNumber}}</div>
          <div class="line-item"><b>{{memberData.GroupOrganizationAbbreviation}} @if (memberData.OrganizationAbbreviation
            && memberData.GroupOrganizationAbbreviation) {
            <span> | </span>
          }{{memberData.OrganizationAbbreviation}}</b></div>
          <div class="line-item"><b>Membership Class:</b> {{memberData.TypeDescription}}</div>
          <div class="line-item"><b>Paid Through Date:</b> {{memberData.PaidThroughDate | date: 'shortDate'}}</div>
        </div>
      </div>
    </div>
    <div class="member-card">
      <div class="member-card-header">Emergency Contact:</div>
      <div class="details-container-full">
        <div class="line-item"><b>Name:</b> {{memberData.EmergencyContactName}}</div>
        <div class="line-item"><b>Phone:</b> @if (memberData.EmergencyContactPhone) {
        <span> {{memberData.EmergencyContactPhone | phone}}</span>
      }</div>
      <div class="line-item"><b>Relationship:</b> {{memberData.EmergencyContactRelationship}}</div>
    </div>
  </div>
  <div class="member-card">
    <div class="member-card-header">Certifications:</div>
    <div class="details-container-full">
      @for (cert of memberData.Certifications; track cert) {
        <div class="margin-left-10">
          <div class="left-column">
            <div class="line-item"><b>{{cert.Description}}</b></div>
            <div class="line-item"><b>Issue Date:</b> {{cert.IssueDate | date: 'shortDate'}}</div>
            <div class="line-item"><b>Expiration Date:</b> @if (cert.ExpirationDate) {
            <span>{{cert.ExpirationDate | date: 'shortDate'}}</span>
          }
          @if (!cert.ExpirationDate) {
            <span>Permanent</span>
          }
        </div>
      </div>
      <div class="right-column text-align-right">
        <div class="active-icon" [ngClass]="{'green' : cert.Active, 'red' : !cert.Active}">
          @if (cert.Active) {
            <span>Active</span>
          }
          @if (!cert.Active) {
            <span>Expired</span>
          }
        </div>
        @if (cert.CertificationUrlLink) {
          <i class="pi pi-download float-right" (click)="getCert(cert.CertificationUrlLink)"></i>
        }
      </div>
      <p-divider class="dark-divider"></p-divider>
    </div>
  }
</div>
</div>
<div class="member-card">
  <div class="member-card-header">Training:</div>
  <div class="details-container-full">
    @for (training of memberData.Trainings; track training) {
      <div class="margin-top-15">
        <div class="line-item"><b>{{training.CourseDescription}}</b></div>
        <div class="line-item"><b>Completion Date:</b> {{training.CompletionDate | date: 'shortDate'}}</div>
        <p-divider class="dark-divider"></p-divider>
      </div>
    }
  </div>
</div>
<div class="member-verification-footer">
  If you have any questions please contact us at support&#64;iupat.org@if (memberData.OrganizationPhone) {
  <span> or {{memberData.OrganizationPhone}}
  </span>
  }.
</div>
</div>
}
