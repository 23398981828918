import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ContextService} from '../../../../services/context.service';
import {UserRosterPiiAttestationService} from '../../../../services/user-roster-pii-attestation.service';
import {UserRosterPiiAttestationModel} from '../../../../models/user-roster-pii-attestation.model';
import {RosterProfileService} from '../../../../services/roster-profile.service';
import {DOBValid} from '../../../utilities/form.utility';
import moment from 'moment/moment';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {PiiValidateModel} from '../../../../models/pii-validate.model';
import {PiiDuplicateDialogComponent} from '../../pii-duplicate-dialog/pii-duplicate-dialog.component';
import {PiiValidateService} from '../../../../services/pii-validate.service';

@Component({
  selector: 'app-roster-tab-secure-data',
  templateUrl: './roster-tab-secure-data.component.html',
  styleUrls: ['./roster-tab-secure-data.component.scss']
})

export class RosterTabSecureDataComponent implements OnInit, OnDestroy {
  tenantName: string;
  supportEmail: string;
  membershipType: string;
  membershipStatus: string;
  isSaving: boolean = false;
  isViewSSN: boolean = false;
  isViewSIN: boolean = false;
  newSSN: string;
  showSSN: string;
  hiddenSSN: string;
  newSIN: string;
  showSIN: string;
  hiddenSIN: string;
  newDOB: string;
  showDOB;
  minDate: Date;
  maxDate: Date;
  isSearching: boolean;
  isEditing: boolean;
  isSecuredSSNMessage: boolean = false;
  isSecuredSINMessage: boolean = false;
  securedSSNMessage: string = '';
  securedSINMessage: string = '';
  agreementInfo: UserRosterPiiAttestationModel;
  isPrivacyChecked: boolean = false;
  existingMessage: boolean = false;
  memberAge: number;
  mainForm: FormGroup;
  @Input() isPage: boolean;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() PersonID: number;
  private ngUnsubscribe = new Subject();

  constructor(private ref: DynamicDialogRef,
              private config: DynamicDialogConfig, private lookupsDataService: LookupsDataService, private formBuilder: FormBuilder,
              private messageService: MessageService, private contextService: ContextService, private piiValidateService: PiiValidateService,
              private userRosterPiiAttestationService: UserRosterPiiAttestationService, private rosterProfileService: RosterProfileService,
              private confirmationService: ConfirmationService, private changeDetection: ChangeDetectorRef, private dialogService: DialogService) {
    this.mainForm = this.formBuilder.group({
      dob: new FormControl(null,),
      dobValidate: new FormControl(null, [DOBValid()]),
    });
  }

  ngOnInit(): void {
    this.tenantName = this.contextService.contextObject.tenantName;
    this.supportEmail = this.contextService.contextObject.supportEmail;
    if (this.PersonID) {
      this.getRosterProfile();
    }
    this.minDate = moment(this.minDate).subtract(130, 'years').toDate();
    this.maxDate = moment(this.maxDate).toDate();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    if (this.PersonID) {
      this.getRosterProfile();
    }
  }

  dataChange(): void {
    if (this.isEdit && !this.existingMessage) {
      this.existingMessage = true;
      this.openDialog();
    }
  }


  openDialog(): void {
    this.confirmationService.confirm({
      key: 'formChanged',
      defaultFocus: 'none',
      header: 'Reminder to Save',
      acceptLabel: 'Save',
      rejectLabel: 'Ignore',
      message: 'Secure data has been modified. Please save, or all changes will be lost.',
      accept: () => {
        this.updateRosterProfilePii();
      },
      reject: () => {
        this.existingMessage = false;
      }
    });
  }

  getRosterProfile(): void {
    const userProfileData = JSON.parse(sessionStorage.getItem('contextObject'));
    this.isViewSIN = false;
    this.isViewSSN = false;
    this.isSecuredSSNMessage = false;
    this.securedSSNMessage = '';
    this.isSecuredSINMessage = false;
    this.securedSINMessage = '';
    this.userRosterPiiAttestationService.getUserRosterPiiAttestation(userProfileData.PersonId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.agreementInfo = res;
          this.isPrivacyChecked = res.PiiAttestation;
          if (this.isPrivacyChecked) {
            this.newSSN = '';
            this.hiddenSSN = '';
            this.newSIN = '';
            this.hiddenSIN = '';
            this.showDOB = null;
            this.getRosterProfilePii();
          }
        }
      });
  }

  updateRosterProfile(): void {
    this.existingMessage = false;
    const userProfileData = JSON.parse(sessionStorage.getItem('contextObject'));
    const date = new Date().toISOString().split('.')[0];
    const attestationObject = {
      PersonID: userProfileData.PersonId,
      PiiAttestation: true,
      PiiAttestationDate: date
    };
    this.userRosterPiiAttestationService.updateUserRosterPiiAttestation(userProfileData.PersonId, attestationObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Your profile has been successfully updated'
          });
          this.getRosterProfile();
        }, error: () => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
        }
      });
  }

  getRosterProfilePii(): void {
    this.rosterProfileService.getRosterProfilePii(this.PersonID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          if (res.SSN) {
            if (res.SSN !== null || res.SSN !== undefined) {
              this.newSSN = res.SSN;
            } else {
              this.newSSN = '';
            }
            this.hiddenSSN = this.newSSN.substring(this.newSSN.length - 4);
            this.hiddenSSN = '***-**-' + this.hiddenSSN;
            this.showSSN = this.hiddenSSN;
            // console.log(this.showSSN);
            this.changeDetection.detectChanges();
          }
          if (res.SIN) {
            if (res.SIN !== null || res.SIN !== undefined) {
              this.newSIN = res.SIN;
            } else {
              this.newSIN = '';
            }
            this.hiddenSIN = this.newSIN.substring(this.newSIN.length - 3);
            this.hiddenSIN = '***-***-' + this.hiddenSIN;
            this.showSIN = this.hiddenSIN;
          }
          if (res.DOBSecure_D) {
            if (res.DOBSecure_D !== null || res.DOBSecure_D !== undefined) {
              this.mainForm.get('dobValidate').setValue(res.DOBSecure_D);
              this.mainForm.get('dob').setValue(res.DOBSecure_D);
              this.getAge(new Date(this.mainForm.get('dob').value));
            } else {
              this.showDOB = null;
            }
          }
        }
      });
  }

  getAge(dob: Date) {
    if (dob) {
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      this.memberAge = age;
    } else {
      this.memberAge = null;
    }
  }

  checkSSN(event:any) {
    if (event.length !== 9 && event.length !== 0) {
      this.isSecuredSSNMessage = true;
      this.securedSSNMessage = 'Please, provide 9 digits for this field';
    } else {
      this.isSecuredSSNMessage = false;
      this.securedSSNMessage = '';
    }
  }

  checkSIN(event:any) {
    if (event.length !== 9 && event.length !== 0) {
      this.isSecuredSINMessage = true;
      this.securedSINMessage = 'Please, provide 9 digits for this field';
    } else {
      this.isSecuredSINMessage = false;
      this.securedSINMessage = '';
    }
  }

  updateRosterProfilePii(): void {
    this.existingMessage = false;
    if (this.showSSN === undefined || this.showSSN.indexOf('*') > -1) {
      this.showSSN = this.newSSN;
    }
    if (this.showSIN === undefined || this.showSIN.indexOf('*') > -1) {
      this.showSIN = this.newSIN;
    }
    if (this.mainForm.get('dobValidate').value === null || this.mainForm.get('dobValidate').value === '') {
      this.showDOB = null;
    }
    const dob = (this.mainForm.get('dobValidate').value === null) ? '' : this.mainForm.get('dobValidate').value;
    const obj = {
      PersonID: this.PersonID,
      SSN: this.showSSN,
      SIN: this.showSIN,
      DOBSecure_D: this.mainForm.get('dobValidate').value,
      Age: this.memberAge
    };
    this.rosterProfileService.updateRosterProfilePii(this.PersonID, obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Secure data has been successfully updated'
          });
          this.confirmationService.close();
          // this.getRosterProfile();
          this.existingMessage = false;
        }, error: () => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
        }
      });
  }

  validatePii(key: string, value: string) {
    this.isSearching = true;
    this.isEditing = false;
    const piiData: PiiValidateModel = {
      SSN: null,
      SIN: null,
      PrimaryEmailAddress: null,
      PersonID: this.PersonID,
      MatchType: key
    };
    piiData[key] = value;
    this.piiValidateService.validatePii(piiData)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.isSearching = false;
          if (res.MatchType) {
            const ref = this.dialogService.open(PiiDuplicateDialogComponent, {
              data: {
                piiData: res,
                value
              },
              header: 'Duplicate User(s) Found',
              width: '60%',
            });
            ref.onClose.subscribe({
              next: () => {
                // console.log(key);
                switch (key) {
                  case 'SSN' : {
                    this.showSSN = null;
                    this.hiddenSSN = null;
                    break;
                  }
                  case 'SIN' : {
                    this.showSIN = null;
                    this.hiddenSIN = null;
                    break;
                  }
                  case 'PrimaryEmailAddress' : {
                    this.mainForm.get('primaryEmail').setValue(null);
                    break;
                  }
                }
              }
            });
          }
        }
      });
  }

  toggleSSN(): void {
    if (this.isViewSSN && this.canTabWrite) {
      this.showSSN = this.newSSN;
    } else {
      this.showSSN = this.hiddenSSN;
    }
  }

  toggleSIN(): void {
    if (this.isViewSIN && this.canTabWrite) {
      this.showSIN = this.newSIN;
    } else {
      this.showSIN = this.hiddenSIN;
    }
  }
}
