import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TenantConfigDataService} from '../../../../services/tenant-config-data.service';

@Component({
  selector: 'app-account-transactions-tab',
  templateUrl: './account-transactions-tab.component.html',
  styleUrls: ['./account-transactions-tab.component.scss']
})

export class AccountTransactionsTabComponent implements OnInit {
  STATEMENTBILLING: boolean;
  @Input() PersonID: number;
  @Input() organizationId: number;
  @Input() isEdit: boolean;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Output() closeAddEdit = new EventEmitter<any>();
  @Input() TypeFilter: string;
  @Input() canTabWrite: boolean;
  @Input() pdfType: string;
  @Output() updateData = new EventEmitter<any>();
  @Output() updateProfileAccount: EventEmitter<number> = new EventEmitter<number>();
  @Output() updateGlobalOrgId = new EventEmitter<any>();

  constructor(private tenantConfigDataService: TenantConfigDataService) {
  }

  ngOnInit(): void {
    this.STATEMENTBILLING = this.tenantConfigDataService.getBooleanValue('STATEMENTBILLING');
  }
}
