<div class="parent-p-card">
  <div class="alert-container">
    @if (!dataLoaded) {
      <span class="fa fa-spinner list-spinner"></span>
    }
    <div class="p-grid p-fluid">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h2>Alerts</h2>
        <!--        <div class="close-alerts"><i class="pi pi-times-circle" (click)="toggleAlertMenu()"></i></div>-->
      </div>
    </div>
    @if (alertsList && alertsList?.length >= 8) {
      <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 p-mb-4 text-align-middle">
        <button pButton icon="fas fa-bell gly-rotate-22-5" (click)="closeAlerts()" label="View All"
                pTooltip="View All" tooltipPosition="bottom" class="clear-all-button p-mr-2"></button>
        @if (alertsList && alertsList.length > 0) {
          <button pButton icon="pi pi-times" (click)="clearAll()" label="Clear All"
                  pTooltip="Clear All" tooltipPosition="bottom" class="clear-all-button"></button>
        }
      </div>
    }
    @for (alert of alertsList; track alert; let i = $index) {
      <div class="col-md-12">
        <div class="list-card">
          <div class="p-grid p-fluid">
            <div class="col-md-12 col-sm-12 col-xs-8 p-ml-2">
              <h3>{{ alert.Title }}</h3>
              <div class="list-subheader" [innerHTML]="alert.Body"></div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2 p-ml-2 p-mb-2 text-align-right">
              @if (alert.GoURL) {
                <button pButton icon="fas fa-bullseye-arrow" (click)="jumpTo(alert.SharableId, alert.GoURL)"
                        pTooltip="Go to Target" tooltipPosition="bottom" class="p-mr-2"></button>
              }
              <button pButton icon="fas fa-times" (click)="clearAlert(alert.SharableId)" pTooltip="Clear Alert"
                      tooltipPosition="bottom" class="p-mr-3"></button>
            </div>
          </div>
        </div>
      </div>
    }
    @if (!alertsList || alertsList.length <= 0) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <p class="p-ml-2 p-mr-2">Thanks for checking, but there are no active alerts available at this time.</p>
      </div>
    }
    <div class="col-md-12 col-sm-12 col-xs-12 margin-top-10 p-mb-4 text-align-middle">
      <button pButton icon="fas fa-bell gly-rotate-22-5" (click)="closeAlerts()" label="View All"
              pTooltip="View All" tooltipPosition="bottom" class="clear-all-button p-mr-2"></button>
      @if (alertsList && alertsList.length > 0) {
        <button pButton icon="pi pi-times" (click)="clearAll()" label="Clear All"
                pTooltip="Clear All" tooltipPosition="bottom" class="clear-all-button"></button>
      }
    </div>
  </div>
</div>
