
@if (hideLabel) {
  <label>{{labelText}} @if (requiredField) {
    <sup class="req">*</sup>
  }
</label>
}
<div class="p-grid p-fluid no-margin position-relative">
  <div class="col-md-12 col-sm-12 col-xs-12 no-padding no-margin">
    <!--    <div class="currency-symbol">$</div>-->
    <!--    <input id="number" name="number" #number="ngModel" type="number" [style]="" class="text-align-right full-width"-->
    <!--           [required]="requiredField" [(ngModel)]="value" [disabled]="disabled" (change)="emitDataToParent()"/>-->
    <input id="number" name="number" #number="ngModel" currencyMask [style]="" class="text-align-right full-width" #myInput (click)="myInput.select()"
      [required]="requiredField" [(ngModel)]="value" [disabled]="disabled" (blur)="emitDataToParent()" [options]="options" tabindex="0"/>
      <div [hidden]="number.valid || number.pristine" class="">{{labelText}} cannot be empty</div>
    </div>
  </div>
