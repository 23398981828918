import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ContextService} from '../../../services/context.service';
import {MessageService} from 'primeng/api';
import {FormBuilder} from '@angular/forms';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {ClassesModulesService} from '../../../services/classes.service';
import {ClassModel} from '../../../models/classes.model';

@Component({
  selector: 'app-class-confirm-multiple',
  templateUrl: './class-confirm-multiple-dialog.component.html',
  styleUrls: ['./class-confirm-multiple-dialog.component.scss']
})

export class ClassConfirmMultipleDialogComponent implements OnInit, OnDestroy {
  isSaving: boolean;
  class = {} as ClassModel;
  previewClass: any[] = [];
  recurrenceSummary: string;
  loading: boolean;
  cols: any[];
  totalHours: number = 0;
  private ngUnsubscribe = new Subject();

  constructor(public config: DynamicDialogConfig, private contextService: ContextService, public ref: DynamicDialogRef,
              private messageService: MessageService, private formBuilder: FormBuilder,
              private lookupsDataService: LookupsDataService, private classesService: ClassesModulesService) {
  }

  ngOnInit(): void {
    console.log(this.config.data.class);
    this.previewClassData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  previewClassData() {
    this.classesService.createMultipleClass(this.config.data.class, true)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.previewClass = res[0].ClassDateTimes;
          this.recurrenceSummary = res[0].RecurrenceSummary;
          this.isSaving = false;
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
          }
        }
      });
  }

  saveData() {
    this.classesService.createMultipleClass(this.config.data.class, false).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res) => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your classes have been created'});
        this.isSaving = false;
        const newClassId: number[] = [];
        res.forEach(x => {
          newClassId.push(x.ClassID);
        });
        this.ref.close(newClassId);
      }, error: (e) => {
        if (e?.error?.Message) {
          this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.Message.replace('UM: ', ''), life: 5000});
        } else {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
        }
        this.isSaving = false;
        console.debug(e);
      }
    });
  }

  closeDialog() {
    this.ref.close('cancel');
  }

  cancel() {
    this.closeDialog();
  }

}
