import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {RosterProfileService} from '../../../services/roster-profile.service';
import {RosterProfileModel} from '../../../models/roster-profile.model';

@Component({
  selector: 'app-roster-tab-user-account',
  templateUrl: './roster-tab-user-account.component.html'
})

export class RosterTabUserAccountComponent implements OnInit, OnDestroy {
  personInfo: RosterProfileModel;
  @Input() PersonID: number;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Output() updateImage = new EventEmitter<any>();
  private ngUnsubscribe = new Subject();

  constructor(private rosterProfileService: RosterProfileService) {
  }

  ngOnInit(): void {
    this.getRosterDetails(this.PersonID);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getRosterDetails(personId: number): void {
    if (personId) {
      this.rosterProfileService.getRosterProfile(personId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            this.personInfo = res;
            this.updateImage.emit(this.personInfo);
          }
        });
    }
  }
}
