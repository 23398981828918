import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

import {RosterProfileChangeLogsGridModel} from '../models/roster-profile-change-logs.model';

@Injectable({
  providedIn: 'root',
})
export class RosterProfileChangeLogsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getRosterChangeLogsPrimeNG(dataMode: string, personId: number, context: string, start?: string, end?: string, filterData?: any): Observable<RosterProfileChangeLogsGridModel> {
    const startDate: string = (start) ? `&start=${start}` : '';
    const endDate: string = (end) ? `&end=${end}` : '';
    return this.http.post<RosterProfileChangeLogsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/changeloggrid/${personId}?mode=${dataMode}&hidecontactinfo=false&context=${context}${startDate}${endDate}`, filterData);
  }

  syncChangeLog(syncType: string, syncId: number): Observable<any> {
    switch (syncType) {
      case 'person':
        return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/changelogsync/byPerson/${syncId}`, null);
      case 'organization':
        return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/changelogsync/byOrganization/${syncId}`, null);
    }
  }
}
