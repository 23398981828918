import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { StudentProgramsGridModel, StudentProgramModel, UpdateStudentProgramModel } from '../models/student-programs.model';

@Injectable({
  providedIn: 'root',
})
export class StudentProgramsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getStudentProgramsPrimeNG(dataMode: string, personId: number, filterData): Observable<StudentProgramsGridModel> {
    return this.http.post<StudentProgramsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/${personId}/programstudent/grid?mode=${dataMode}`, filterData);
  }

  getUserStudentProgramsPrimeNG(dataMode: string, filterData): Observable<StudentProgramsGridModel> {
    return this.http.post<StudentProgramsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/programstudent/grid?mode=${dataMode}`, filterData);
  }

  getStudentProgram(programStudentId: number): Observable<StudentProgramModel> {
    return this.http.get<StudentProgramModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/programstudent/${programStudentId}`);
  }

  getUserStudentProgram(programStudentId: number): Observable<StudentProgramModel> {
    return this.http.get<StudentProgramModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/programstudent/${programStudentId}`);
  }

  createStudentProgram(modelData: StudentProgramModel): Observable<StudentProgramModel> {
    return this.http.post<StudentProgramModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/programstudent/create`, modelData);
  }

  updateStudentProgram(programStudentId: number, modelData: UpdateStudentProgramModel): Observable<UpdateStudentProgramModel> {
    return this.http.put<UpdateStudentProgramModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/programstudent/${programStudentId}`, modelData);
  }

  deleteStudentProgram(programStudentId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/programstudent/remove/${programStudentId}`);
  }

  addStudentProgramGroup(programStudentId: number, studentGroupId: number): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/programstudent/${programStudentId}/addgroup/${studentGroupId}`, null);
  }

  removeStudentProgramGroup(programStudentId: number, studentGroupId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/programstudent/${programStudentId}/removegroup/${studentGroupId}`);
  }
}
