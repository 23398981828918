<div class="p-grid p-fluid margin-top-10">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="p-grid p-fluid">
      <h2 class="col-md-6 col-sm-6 col-xs-12 results">@if (addresses) {
        <span>{{ dataTotal }} Address @if (dataTotal === 1) {
          Result
        } @else {
          Results
        }</span>
      }</h2>
      <!--      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator" >-->
      <!--        <p-paginator *ngIf="addresses" [rows]="currentFilter.rows" [totalRecords]="addresses?.Total" (onPageChange)="paginate($event)"></p-paginator>-->
      <!--      </div>-->
      <div class="col-md-6 col-sm-6 col-xs-12 action-buttons text-align-right">
        <button pButton (click)="refresh()" icon="pi pi-refresh"
                pTooltip="Refresh" tooltipPosition="bottom" class="override-margins"></button>
        @if (canTabWrite) {
          <button pButton (click)="openNewAddress()" class="override-margins"
                  icon="pi pi-plus" pTooltip="Add"
                  tooltipPosition="bottom"></button>
        }
        <!--        <button *ngIf="showExport" pButton class="p-button-download export-menu export-excel override-margins" icon="fa fa-file-excel"-->
        <!--                pTooltip="Export" tooltipPosition="bottom" (click)="export()">-->
        <!--        </button>-->
      </div>
      <!--      <div class="col-md-6 col-sm-4 col-xs-12 align-center list-paginator-mobile" *ngIf="addressList">-->
      <!--        <p-paginator [rows]="currentFilter.rows" [totalRecords]="addresses?.Total" (onPageChange)="paginate($event)"></p-paginator>-->
      <!--      </div>-->
    </div>
    <!--    <div *ngIf="showAddEdit">-->
    <!--      <p-card header="Add Address" [styleClass]="'list-card'">-->
    <!--        <app-addresses-form [DBEntity]="DBEntity" [DBEntityID]="DBEntityID" [isEdit]="false" [canTabWrite]="canTabWrite" (closeAddEdit)="updateList($event)"></app-addresses-form>-->
    <!--      </p-card>-->
    <!--    </div>-->
    @if (dataLoaded && dataTotal <= 0) {
      <div class="col-md-12 col-sm-12 col-xs-12" style="padding: 25px 25px 0 25px;">
        @if ((currentFilter.filters | json) === '{}') {
          <span><b>No results were found.</b></span>
        } @else {
          <span><i
            class="fas fa-search p-mr-2"></i><b>No results were found. Please review the filter and try again.</b></span>
        }
      </div>
    }
    @if (!dataLoaded) {
      <div class="col-md-12 col-sm-12 col-xs-12">
        <app-data-indicator></app-data-indicator>
      </div>
    } @else {
      <div class="col-md-12  address-container">
        <ng-scrollbar #scrollable track="all" pointerEventsMethod="scrollbar" visibility="hover">
          @for (address of addressList; track address; let i = $index) {
            <div>
              <p-card [styleClass]="'list-card address-card'">
                <div class="p-grid p-fluid">
                  <div class="left-column col-md-10 col-sm-10 col-xs-10" (click)="openAddress(address.AddressID)">
                    <div class="list-header">
                      {{ address.ContactCategoryDescription | titlecase }}
                    </div>
                    <!--                <div *ngIf="address.Primary || address.FailedCommunication" class="list-subheader">-->
                    <!--                  <span *ngIf="address.Primary"> *Primary</span><span *ngIf="address.FailedCommunication" style="color: red;"> *Undeliverable</span>-->
                    <!--                </div>-->
                    @if (!address.Address1 || !address.City || !address.StateProvinceID || !address.Zip) {
                      <div style="color: red;" class="list-subheader">
                        *Incomplete
                      </div>
                    }
                    <div class="list-subheader">
                      <div class="p-grid p-fluid">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                          @if (address.Address1) {
                            <span>{{ address.Address1 }}<br></span>
                          }
                          @if (address.Address2) {
                            <span>{{ address.Address2 }}<br></span>
                          }
                          @if (address.City && address.StateProvinceDescription && address.Zip) {
                            <span>{{ address.City }}, {{ address.StateProvinceDescription }} {{ address.Zip }}</span>
                          }
                          @if (address.County) {
                            <p><b>County:</b> {{ address.County }}</p>
                          }
                        </div>
                        <div class="col-md-6 col-sm-12 col-xs-12">
                          @if (address.Primary) {
                            <span><b>*Primary</b></span>
                          }
                          @if (address.FailedCommunication) {
                            <span style="color: red;" [ngClass]="{'p-ml-2' : address.Primary}"><b>*Undeliverable</b><br></span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="right-column col-md-2 col-sm-2 col-xs-2 list-container">
                    <div class="list-action-buttons">
                      @if (address.Latitude && address.Longitude && locationHeader) {
                        <div class="icon p-mt-1 hide-on-mobile" pTooltip="Map" tooltipPosition="bottom"
                             (click)="getLocation(address)"><i class="fas fa-map-marker-alt"></i></div>
                      }
                      <!--                <div class="icon" pTooltip="Collapse / Expand" tooltipPosition="bottom" (click)="expandData(i)" >-->
                      <!--                  <i *ngIf="this.selectedItem === i" class="fas fa-arrow-circle-up"></i>-->
                      <!--                  <i *ngIf="this.selectedItem !== i" class="fas fa-arrow-circle-down"></i>-->
                      <!--                </div>-->
                      @if (canTabWrite && address.CanDelete) {
                        <div class="icon p-mt-1" pTooltip="Delete" tooltipPosition="bottom"
                             (click)="deleteAddress(DBEntityID, address.AddressID)"><i class="fas fa-trash-alt"></i>
                        </div>
                      }
                    </div>
                  </div>
                  @if (selectedItem === i) {
                    <div class="full-width margin-top-10">
                      <app-addresses-form [DBEntityID]="DBEntityID" [addressID]="address.AddressID"
                                          [locationHeader]="locationHeader" [isEdit]="true" [canTabWrite]="canTabWrite"
                                          [userCall]="userCall"
                                          (closeAddEdit)="updateList($event)"></app-addresses-form>
                    </div>
                  }
                </div>
              </p-card>
            </div>
          }
        </ng-scrollbar>
      </div>
    }
  </div>
</div>
