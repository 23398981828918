import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {PrimeTableFilterModel} from '../../../../../../models/table-filter.model';
import {LookupsDataService} from '../../../../../../services/lookups-data.service';
import {GenericSelectModel} from '../../../../../../models/generic-select.model';
import {ClassesModulesService} from '../../../../../../services/classes.service';
import {ClassModel} from '../../../../../../models/classes.model';
import {CreateClassStudentModel} from '../../../../../../models/class-students.model';
import {ClassStudentsService} from '../../../../../../services/class-students.service';
import {PageRowCountUtility} from '../../../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-student-class-form',
  templateUrl: './student-class-form.component.html',
  styleUrls: ['./student-class-form.component.scss']
})
export class StudentClassFormComponent implements OnInit, AfterViewInit, OnDestroy {
  pageTitle = 'Add Student Class';
  setCourseId: number = 0;
  isSaving: boolean = false;
  classesList: ClassModel[];
  selectedClasses: any[] = [];
  selClass: ClassModel;
  loading: boolean = false;
  dataTotal: number = 0;
  showTable: boolean = false;
  inputObjCourse: GenericSelectModel;
  @Input() isEdit: boolean;
  @Input() canTabWrite: boolean;
  @Input() PersonID: number;
  currentFilter = new PrimeTableFilterModel();
  @Output() closeAddEdit = new EventEmitter<boolean>();
  cols: any[];
  unSaved: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private messageService: MessageService,
              private lookupsDataService: LookupsDataService, private classesService: ClassesModulesService,
              private changeDetection: ChangeDetectorRef, private classStudentsService: ClassStudentsService,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.cols = [
      {field: 'select', header: '', columnType: 'none', visible: true, width: '50px'},
      {
        field: 'ClassIdentifier',
        header: 'Class #',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        width: '150px',
        displayType: 'text'
      },
      {
        field: 'ClassStatusDescription',
        header: 'Status',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        width: '150px',
        displayType: 'text'
      },
      {
        field: 'OrganizationName',
        header: 'Organization',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        width: '150px',
        displayType: 'text'
      },
      {
        field: 'ClassStaff',
        header: 'Staff',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        width: '150px',
        displayType: 'text'
      },
      {
        field: 'StartDate_D',
        header: 'Start Date',
        columnType: 'date',
        matchMode: 'contains',
        visible: true,
        width: '150px',
        displayType: 'date'
      },
      {
        field: 'StartTime_T',
        header: 'Start Time',
        columnType: 'date',
        matchMode: 'contains',
        visible: true,
        width: '150px',
        displayType: 'time'
      },
      {
        field: 'EndDate_D',
        header: 'End Date',
        columnType: 'date',
        matchMode: 'contains',
        visible: true,
        width: '150px',
        displayType: 'date'
      },
      {
        field: 'EndTime_T',
        header: 'End Time',
        columnType: 'date',
        matchMode: 'contains',
        visible: true,
        width: '150px',
        displayType: 'time'
      },
      {
        field: 'TimezoneDescription',
        header: 'Timezone',
        columnType: 'text',
        matchMode: 'contains',
        visible: true,
        width: '150px',
        displayType: 'text'
      },
      {
        field: 'Students_N',
        header: '# Enrolled',
        columnType: 'numeric',
        matchMode: 'gte',
        visible: true,
        width: '150px',
        displayType: 'numeric'
      },
      {
        field: 'MaxSeats_N',
        header: 'Max # of Seats',
        columnType: 'numeric',
        matchMode: 'gte',
        visible: true,
        width: '150px',
        displayType: 'numeric'
      }
    ];

    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.initCourses(false);
  }

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  cancel(): void {
    if (!this.isEdit) {
      //this.mainForm.reset();
      this.closeAddEdit.emit();
    }
    //this.mainForm.markAsPristine();
    //this.mainForm.markAsUntouched();
  }

  getData(): void {
    this.getTotal();
    this.getList();
  }

  getTotal(): void {
    const dt = new Date().toLocaleDateString().split('T')[0];
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.getTotalRequest = this.classesService
      .getClassesPrimeNG('TOTAL', this.currentFilter, dt, null, null, this.setCourseId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
        }
      });
  }

  getList(): void {
    this.loading = true;
    const dt = new Date().toLocaleDateString().split('T')[0];
    this.classesList = [];
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.getListRequest = this.classesService
      .getClassesPrimeNG('DATA', this.currentFilter, dt, null, null, this.setCourseId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.classesList = res.Data;
          this.loading = false;
        }, error:
          (e) => {
            console.warn(e);
          }
      });
  }

  loadTable(event:any): void {
    if (event) {
      this.currentFilter = event;
    } else {
      this.currentFilter.filters = {};
      this.currentFilter.sortOrder = 1;
    }
    this.getData();
  }

  onRowSelect(event, data): void {
    let i: number;
    let ilen: number;
    if (event.checked) {
      this.selectedClasses.push(data);
    } else {
      const workArr: any[] = JSON.parse(JSON.stringify(this.selectedClasses));
      for (i = 0, ilen = workArr.length; i < ilen; i++) {
        if (workArr[i] && workArr[i].ClassID === data.ClassID) {
          workArr.splice(i, 1);
        }
      }
      this.selectedClasses = JSON.parse(JSON.stringify(workArr));
    }
  }

  processData(): void {
    this.isSaving = true;
    this.saveForm();
  }

  saveForm(): void {
    if (!this.isEdit) {
      const dataModel: CreateClassStudentModel = {
        PersonIDList: [this.PersonID],
        TrainingStatusID: null,
        SendRegistrationEmail: false
      };
      this.selectedClasses.forEach(x => {
        this.classStudentsService.createClassStudent(x.ClassID, dataModel)
          .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected class has been added, but must be further completed in the class record itself.',
              life: 5000
            });
            this.isSaving = false;
            this.closeAddEdit.emit(true);
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
            this.isSaving = false;
            console.debug(e);
          }
        });
      });
    }
  }

  initCourses(disable: boolean) {
    this.inputObjCourse = {
      labelText: 'Courses',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable
    };
    this.lookupsDataService.getCoursesLookupData().then((lookupData) => {
      this.inputObjCourse.data = lookupData;
      this.inputObjCourse = Object.assign({}, this.inputObjCourse);
    });
  }

  getCourseData(event:any) {
    if (event && event[0]) {
      this.setCourseId = (event[0].ID !== null) ? event[0].ID : 0;
    } else {
      this.setCourseId = 0;
    }
  }
}
