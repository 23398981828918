import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ClassModel} from '../../../models/classes.model';
import {PrimeTableFilterModel} from '../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {MessageService} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../shared/utilities/data.utility';
import {exportData} from '../../../shared/utilities/list-table.utility';
import {GenericSelectModel} from '../../../models/generic-select.model';
import {LookupsDataService} from '../../../services/lookups-data.service';
import {listFilterUtility} from '../../../shared/utilities/list-filter.utility';
import {ClassStudentsService} from '../../../services/class-students.service';
import {StudentGradesByCourseModel} from '../../../models/class-students.model';
import {AddCertificateDialogComponent} from './add-certificate-dialog/add-certificate-dialog.component';
import {PageRowCountUtility} from '../../../shared/utilities/page-row-count.utility';
import {SetAllToPassDialogComponent} from './set-all-to-pass-dialog/set-all-to-pass-dialog.component';
import {transformDateTimeToDateOnly} from '../../../shared/utilities/form.utility';
import {DialogUtility} from '../../../shared/utilities/dialog.utility';
import {SharablesService} from '../../../services/sharables.service';

@Component({
  selector: 'app-class-tab-grades',
  templateUrl: './class-tab-grades.component.html',
  styleUrls: ['./class-tab-grades.component.scss']
})

export class ClassTabGradesComponent implements OnInit, AfterViewInit, OnDestroy {
  showTable: boolean = false;
  cols: any[];
  exportColumns: any[];
  loading: boolean = false;
  isExporting: boolean;
  grades: StudentGradesByCourseModel[];
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  selectedItem: number;
  showAddEdit: boolean;
  inputObjCourse: GenericSelectModel;
  inputObjStudents: GenericSelectModel;
  setCourseId: number;
  setStudentId: number;
  dataTotal: number = 0;
  selectedActiveName: string;
  CognitiveLevels;
  isSaving: boolean = false;
  editedStudents: StudentGradesByCourseModel[] = [];
  passFail;
  byCourse: boolean;
  inBatchEdit: boolean;


  @Input() ClassID: number;
  @Input() canTabWrite: boolean;
  @Input() ClassInfo: ClassModel;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;

  private ngUnsubscribe = new Subject();

  constructor(private changeDetectorRef: ChangeDetectorRef, private messageService: MessageService,
              private changeDetection: ChangeDetectorRef, private sharablesService: SharablesService,
              public dialogService: DialogService, private lookupsDataService: LookupsDataService,
              private dialogUtility: DialogUtility, private classStudentsService: ClassStudentsService,
              public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.initCourses(false);
    this.initStudents(false);
    this.getCognitiveLevels();
    this.getPassFail();
  }

  ngAfterViewInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  initCourses(disable: boolean) {
    this.inputObjCourse = {
      labelText: 'Grade by Course/Topic',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable
    };
    this.lookupsDataService.getCoursesByClassIdLookupData(this.ClassID).then((lookupData) => {
      this.inputObjCourse.data = lookupData;
      this.inputObjCourse = Object.assign({}, this.inputObjCourse);
    });
  }

  getCourseData(event:any) {
    if (event && event[0]) {
      this.byCourse = true;
      this.setCourseId = (event[0].ID !== null) ? event[0].ID : 0;
      this.initStudents(false);
      this.editedStudents = [];
      if (this.ClassID) {
        this.getListByCourse();
      }
      this.selectedActiveName = event[0].Description;
    } else {
      this.setCourseId = 0;
    }
  }

  initStudents(disable: boolean) {
    this.inputObjStudents = {
      labelText: 'Grade by Student',
      optionValue: 'ID',
      optionLabel: 'Description',
      filter: true,
      requiredField: false,
      selectFirstValue: false,
      initSelected: null,
      disabled: disable
    };
    this.lookupsDataService.getStudentsByClassId(this.ClassID).then((lookupData) => {
      this.inputObjStudents.data = lookupData;
      this.inputObjStudents = Object.assign({}, this.inputObjStudents);
    });
  }

  getCognitiveLevels() {
    this.lookupsDataService.getCognitiveLevelsLookupData().then((lookupData) => {
      this.CognitiveLevels = lookupData;
    });
  }

  getPassFail() {
    this.lookupsDataService.getPassFailLookupData().then((lookupData) => {
      this.passFail = lookupData;
    });
  }

  getStudentData(event:any) {
    if (event && event[0]) {
      this.byCourse = false;
      this.setStudentId = (event[0].ID !== null) ? event[0].ID : 0;
      this.initCourses(false);
      this.selectedActiveName = event[0].Description;
      this.getListByStudent();
      this.editedStudents = [];
    } else {
      this.setStudentId = 0;
    }
  }

  getListByCourse() {
    this.loading = true;
    this.inBatchEdit = false;
    this.classStudentsService.getStudentGradesByCourseId(this.ClassID, this.setCourseId, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.grades = res.Data;
          this.grades.forEach(grade => {
            if (grade.ResultDate_D) {
              grade.DateConverted = new Date(grade.ResultDate_D);
            }
          });
          this.dataTotal = res.Total;
          this.loading = false;
        }
      });
  }

  getListByStudent() {
    this.loading = true;
    this.inBatchEdit = false;
    this.classStudentsService.getStudentGradesByStudentId(this.ClassID, this.setStudentId, this.currentFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.grades = res.Data;
          this.grades.forEach(grade => {
            if (grade.ResultDate_D) {
              grade.DateConverted = new Date(grade.ResultDate_D);
            }
          });
          this.dataTotal = res.Total;
          this.loading = false;
        }
      });
  }

  filterItems() {
    // this.loading = true;
    // this.initFilters('FullName', this.activeGlobalFilter, 'contains', 'and');
    // if(this.setStatus && this.setStatus !== 0) {
    //   this.initFilters('AttendanceStatusID', this.setStatus, 'equals', 'and');
    // } else {
    //   this.initFilters('AttendanceStatusID', null, 'equals', 'and');
    // }
    // this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
  }

  clearFilters() {
    this.loading = true;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = '';
    this.initCourses(false);
  }

  updateList(): void {
    this.showAddEdit = false;
    // this.getClassAttendance(this.ClassID, this.setClassPeriodDesc, this.currentFilter);
    this.selectedItem = -1;
  }


  refresh(): void {
    if (this.byCourse) {
      this.getListByCourse();
    } else {
      this.getListByStudent();
    }
  }

  openCertificate(index) {
    //this.saveData();
    if (!this.inBatchEdit) {
      const selectedStudent: StudentGradesByCourseModel = this.grades[index];
      const ref = this.dialogService.open(AddCertificateDialogComponent, {
        data: {
          courseID: this.setCourseId,
          classID: this.ClassID,
          selectedStudent
        },
        header: `Add Certificate for ${selectedStudent.FullName}`,
        width: '600px',
        styleClass: 'dialog-form'
      });
      ref.onClose.subscribe({
        next: () => {
          this.getListByCourse();
        }
      });
    }


  }

  removeCert(sharableId) {
    this.dialogUtility.promptToDelete(() => {
      this.sharablesService.deleteSharable(sharableId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected training/certification has been removed.'
            });
            this.refresh();
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  // updateSaveArray(index, studentClassId, courseId) {
  //   if(this.byCourse) {
  //     if(this.editedStudents.find(student => student.ClassStudentID === studentClassId)) {
  //       const removeIndex = this.editedStudents.findIndex(student => student.ClassStudentID === studentClassId);
  //       this.editedStudents.splice(removeIndex, 1);
  //     }
  //   } else {
  //     if(this.editedStudents.find(student => student.CourseID === courseId)) {
  //       const removeIndex = this.editedStudents.findIndex(student => student.CourseID === courseId);
  //       this.editedStudents.splice(removeIndex, 1);
  //     }
  //   }
  //
  //   this.editedStudents.push(this.grades[index]);
  //   this.editedStudents.forEach(student => {
  //     student.ResultDate_D = this.datePipe.transform(student.DateConverted,'yyyy-MM-dd').toString();
  //   });
  //   this.grades[index].Passed = this.grades[index].ResultID === 1 ? true : false;
  //   console.log(this.editedStudents);
  // }

  setAllToPass() {
    const ref = this.dialogService.open(SetAllToPassDialogComponent, {
      data: {
        grade: this.grades[0]
      },
      header: 'Set All',
      width: '60%',
      height: '200px'
    });

    ref.onClose.subscribe({
      next: (res) => {
        this.grades.forEach(grade => {
          grade.DateConverted = res.DateConverted;
          grade.Grade = res.Grade;
          grade.Passed = res.Passed;
          grade.ResultID = res.ResultID;
          if (grade.HasCertification) {
            grade.CertificationIssueDate = res.CertificationIssueDate;
            grade.CertificationExpirationDate = res.CertificationExpirationDate;
          }
          this.changeDetectorRef.detectChanges();
          this.inBatchEdit = true;
          //  this.editedStudents = this.grades;
        });
      }
    });

  }


  saveData() {
    this.grades.forEach(grade => {
      if (grade.CertificationIssueDate) {
        grade.CertificationIssueDate = transformDateTimeToDateOnly(grade.CertificationIssueDate);
      }
      if (grade.CertificationExpirationDate) {
        grade.CertificationExpirationDate = transformDateTimeToDateOnly(grade.CertificationExpirationDate);
      }
      if (grade.DateConverted) {
        grade.ResultDate_D = transformDateTimeToDateOnly(grade.DateConverted.toString());
      }
    });
    this.classStudentsService.updateStudentGrades(this.grades)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Grades have been updated.'
          });
          if (this.byCourse) {
            this.getListByCourse();
          } else {
            this.getListByStudent();
          }
          this.inBatchEdit = false;
        }, error: () => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
        }
      });
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.dataTotal <= this.pageRowCountUtility.pageRowCount()) {
        const arr = JSON.parse(JSON.stringify(this.grades));
        const dataToExport = removeKeysFromArray(arr, ['ClassID', 'ClassTypeID', 'CourseID', 'CourseTypeID', 'SubjectAreaID', 'ClassStatusID', 'OrganizationID', 'TimezoneID', 'OnlineMeetingTypeID', 'CanEdit', 'CanDelete',
          'ClassStudentID', 'PersonID', 'ResultID', 'DateConverted']);
        exportData('grades', dataToExport);
        this.isExporting = false;
      }
    }, () => {
    });
  }
}
