@if (loading) {
  <span class="fa fa-spinner list-spinner"></span>
}
<form [formGroup]="mainForm" class="margin-top-10 ">
  <p-divider class="dark-divider"></p-divider>
  <div class="p-grid p-fluid parent-form">
    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6">
      <app-select class="full-width" [inputObj]="inputObjTaxForm" (emitData)="getTaxFormData($event)"></app-select>
      @if ((mainForm.controls.TaxFormTypeDescription.dirty || mainForm.controls.TaxFormTypeDescription.touched) &&
      mainForm.controls.TaxFormTypeDescription.errors?.required) {
        <span class="error">'Form' must be provided</span>
      }
    </div>
    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6">
      <label>Year <sup class="req">*</sup></label>
      <p-inputNumber formControlName="TaxFormYear" mode="decimal" [showButtons]="true" inputId="minmax-buttons"
                     [useGrouping]="false" [min]="1950" [max]="2050" tabindex="0"></p-inputNumber>
      @if ((mainForm.controls.TaxFormYear.dirty || mainForm.controls.TaxFormYear.touched) &&
      mainForm.controls.TaxFormYear.errors?.required) {
        <span class="error">'Year' must be provided</span>
      }
    </div>
    @if (taxformData == null) {
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 input-container p-button-auto-width">
        <p>*Files must be 30 MB or less in size. If necessary, change the name of your files appropriately prior to
          uploading them. Upload one file at a time.</p>
        @if (canTabWrite) {
          <p-fileUpload accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.ppt,.pptx" #fileUploader
                        name="uploadedFiles[]" (onSelect)="onUpload($event)" (onClear)="clearUpload()"
                        [showUploadButton]="false" maxFileSize="31457280">
            <ng-template let-file pTemplate='file'>
              <div>
                {{ file.name }} - ({{ file.size }} bytes)
              </div>
            </ng-template>
          </p-fileUpload>
        }
      </div>
    }
    @if (taxformData != null) {
      <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 tab-form-buttons">
        <ng-container>
          <div class="upload-file-container">
            <button pButton (click)="openResource(taxformData.ResourceReferenceID)" icon="fa fa-file-excel" label="Form"
                    pTooltip="Form" tooltipPosition="bottom" class="p-button-info p-mr-2 export-excel"></button>
          </div>
        </ng-container>
      </div>
    }
    @if (showProgress) {
      <div class="col-md-12">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
    }
  </div>
  @if (canTabWrite) {
    <div class="p-grid p-fluid margin-top-10">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="p-grid p-fluid">
          <div class="col-md-6 col-sm-6 col-xs-12"></div>
          <div class="col-md-6 col-sm-6 col-xs-12 tab-form-buttons">
            @if (isEdit) {
              <button pButton label="Reset" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-refresh" pTooltip="Reset" tooltipPosition="bottom"
                      (click)="cancel()" [disabled]="!mainForm.dirty"></button>
            } @else {
              <button pButton label="Cancel" class="p-button-info p-mr-2" [className]="'inline-button'"
                      icon="pi pi-times" pTooltip="Cancel" tooltipPosition="bottom"
                      (click)="cancel()"></button>
            }
            @if (!isSaving) {
              <button pButton label="Save" class="p-button-info p-mr-2" icon="pi pi-save" pTooltip="Save"
                      tooltipPosition="bottom"
                      (click)="processData()" [className]="'inline-button'"
                      [disabled]="this.mainForm.invalid || !mainForm.dirty"></button>
            } @else {
              <button pButton label="Save" class="p-button-info p-mr-2" [className]="'inline-button'" [disabled]="true">
                <i class="pi pi-spin pi-spinner save-spinner"></i>
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  }
</form>
