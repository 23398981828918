import {Component, OnInit} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {SMSOptStatusModel} from '../../../models/sms.model';

@Component({
  selector: 'app-maps',
  templateUrl: './sms-dialog.component.html',
  styleUrls: ['./sms-dialog.component.scss']
})

export class SmsDialogComponent implements OnInit {
  data: SMSOptStatusModel[];

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef) {
  }

  ngOnInit(): void {
    this.data = this.config.data;
  }

  closeDialog() {
    this.ref.destroy();
    this.ref.destroy();
  }

  cancel() {
    this.closeDialog();
  }
}
