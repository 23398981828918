import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {AttributeModel, AttributesGridModel} from '../../../../models/attributes.model';
import {AttributesService} from '../../../../services/attributes.service';
import {PrimeTableFilterModel} from '../../../../models/table-filter.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {DialogUtility} from '../../../utilities/dialog.utility';
import {DialogService} from 'primeng/dynamicdialog';
import {removeKeysFromArray} from '../../../utilities/data.utility';
import {exportData} from '../../../utilities/list-table.utility';
import {listFilterUtility} from '../../../utilities/list-filter.utility';
import {GenericSelectModel} from '../../../../models/generic-select.model';
import {LookupsDataService} from '../../../../services/lookups-data.service';
import {PageRowCountUtility} from '../../../utilities/page-row-count.utility';

@Component({
  selector: 'app-organization-attributes-tab',
  templateUrl: './organization-attributes-tab.component.html',
  styleUrls: ['./organization-attributes-tab.component.scss']
})
export class OrganizationAttributesTabComponent implements OnInit, OnChanges, OnDestroy {
  attributes: AttributesGridModel;
  attributeList: AttributeModel[] = [];
  dataLoaded: boolean;
  isExporting: boolean;
  totalLoaded: boolean;
  selSharableId: number;
  isButtonDisabled: boolean = true;
  filter = new PrimeTableFilterModel();
  currentFilter = new PrimeTableFilterModel();
  activeGlobalFilter: string = '';
  canDelete: boolean = true;
  selectedItem: number;
  showAddEdit: boolean;
  dataTotal: number = 0;
  inputObjAttributeDataTypeByEntity: GenericSelectModel;
  setAttributeDataTypeByEntity: string = '';
  @Input() OrgRefID: number;
  @Input() canTabWrite: boolean;
  @Input() ResponseDBEntityEnumeratorOptions: boolean;
  @Output() resetList = new EventEmitter<boolean>();
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() canAdd: boolean;
  getListRequest;
  getTotalRequest;

  private ngUnsubscribe = new Subject();

  constructor(private attributesService: AttributesService,
              private messageService: MessageService, private router: Router,
              private dialogUtility: DialogUtility,
              private lookupsDataService: LookupsDataService,
              public dialogService: DialogService, public pageRowCountUtility: PageRowCountUtility) {
  }

  ngOnInit(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.initAttributeDataTypeByEntity(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(): void {
    this.currentFilter.rows = this.pageRowCountUtility.pageRowCount();
    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    this.getData();
  }

  getData() {
    this.getList(this.OrgRefID, this.currentFilter);
    this.getTotal(this.OrgRefID, this.currentFilter);
  }

  getList(orgRefId: number, filterData): void {
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.selectedItem = -1;
    this.attributeList.length = 0;
    this.dataLoaded = false;
    this.getListRequest = this.attributesService.getOrganizationReferenceAttributesPrimeNG('DATA', orgRefId, filterData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.attributes = res;
          this.attributeList = res.Data;
          this.dataLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.dataLoaded = true;
        }
      });
  }

  getTotal(orgRefId: number, filterData): void {
    if (this.getTotalRequest) {
      this.getTotalRequest.unsubscribe();
    }
    this.dataTotal = 0;
    this.totalLoaded = false;
    this.getTotalRequest = this.attributesService.getOrganizationReferenceAttributesPrimeNG('TOTAL', orgRefId, filterData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.dataTotal = res.Total;
          if (this.canAdd && this.dataTotal <= 0) {
            this.showAddEdit = JSON.stringify(this.currentFilter.filters) === JSON.stringify({});
          } else {
            this.showAddEdit = false;
          }
          this.totalLoaded = true;
        }, error: (e) => {
          console.debug(e);
          this.totalLoaded = true;
        }
      });
  }

  reorderAttribute(iD1: number, iD2: number): void {
    this.attributesService.reorderAttribute(iD1, iD2)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'The attribute has been reordered.'});
          this.updateList(true);
        }
      });
  }

  deleteAttribute(orgRefId: number, attributeId: number): void {
    this.dialogUtility.promptToDelete(() => {
      this.attributesService.deleteAttribute(orgRefId, attributeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'The selected attribute has been removed.'
            });
            this.updateList(true);
          }, error: (e) => {
            if (e?.error?.Message) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.Message.replace('UM: ', ''),
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
              });
            }
          }
        });
    }, () => {
    });
  }

  updateList(dataChanged: boolean): void {
    this.showAddEdit = false;
    if (dataChanged) {
      this.selectedItem = -1;
      //this.resetList.emit(true);
      this.getData();
    }
  }

  paginate(event:any): void {
    this.currentFilter.rows = event.rows;
    this.currentFilter.sortOrder = event.sortOrder;
    this.currentFilter.first = event.first;
    this.resetList.emit();
    this.getData();
  }

  filterItems(): void {
    if (this.setAttributeDataTypeByEntity) {
      this.initFilters('DataTypeDescription', this.setAttributeDataTypeByEntity, 'equals', 'and');
    } else {
      this.currentFilter.filters.DataTypeDescription = [];
    }

    if (this.activeGlobalFilter && this.activeGlobalFilter !== '') {
      this.initFilters('Description', this.activeGlobalFilter, 'contains', 'and');
    } else {
      delete this.currentFilter.filters.Description;
    }

    this.resetList.emit();
    this.getData();
  }

  clearFilters(): void {
    this.dataLoaded = false;
    this.currentFilter.filters = {};
    this.activeGlobalFilter = null;
    this.setAttributeDataTypeByEntity = null;
    this.initAttributeDataTypeByEntity(false);
    this.resetList.emit();
    this.getData();
  }

  initFilters(fieldValue: string, value, matchMode: string, operator: string) {
    this.currentFilter.filters[fieldValue] = listFilterUtility(value, matchMode, operator);
  }

  goToEdit(event:any): void {
    this.selSharableId = event.SharableId;
  }

  refresh(): void {
    // this.loadTable(this.currentFilter);
    this.resetList.emit();
    this.getData();
  }

  expandData(index: number): void {
    if (this.selectedItem === index) {
      this.selectedItem = -1;
    } else {
      this.selectedItem = index;
    }
  }

  initAttributeDataTypeByEntity(disable: boolean): void {
    this.inputObjAttributeDataTypeByEntity = {
      labelText: 'Type',
      optionValue: 'Description',
      optionLabel: 'Description',
      filter: false,
      requiredField: false,
      selectFirstValue: false,
      initSelected: this.setAttributeDataTypeByEntity,
      disabled: disable,
      customSelect: 'AttributeDataType'
    };
    this.lookupsDataService.getAttributeDataTypeByEntityLookupData('orgreference').then((lookupData) => {
      this.inputObjAttributeDataTypeByEntity.data = lookupData;
      this.inputObjAttributeDataTypeByEntity = Object.assign({}, this.inputObjAttributeDataTypeByEntity);
    });
  }

  getAttributeDataTypeByEntityData(event:any) {
    if (event && event[0] && event[0].Description) {
      this.setAttributeDataTypeByEntity = event[0].Description;
    } else {
      this.setAttributeDataTypeByEntity = null;
    }
  }

  resetComponent(): void {
    this.attributeList = undefined;
    this.attributes = undefined;
  }

  export(): void {
    this.dialogUtility.promptToExportData(() => {
      this.isExporting = true;
      if (this.attributeList.length <= this.currentFilter.rows) {
        const arr = JSON.parse(JSON.stringify(this.attributeList));
        const dataToExport = removeKeysFromArray(arr, ['ID', 'DBEntityID', 'DataTypeID', 'ParentID', 'ResponseDBEntityID', 'ResponseDBEntityEnumerator', 'ResponseDBEntityDescription']);
        exportData('attributes', dataToExport);
        this.isExporting = false;
      } else {
        const exportFilter: PrimeTableFilterModel = JSON.parse(JSON.stringify(this.currentFilter));
        exportFilter.first = 0;
        exportFilter.rows = null;
        this.attributesService.getOrganizationReferenceAttributesPrimeNG('DATA', this.OrgRefID, exportFilter)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              const arr = JSON.parse(JSON.stringify(res.Data));
              const dataToExport = removeKeysFromArray(arr, ['ID', 'DBEntityID', 'DataTypeID', 'ParentID', 'ResponseDBEntityID', 'ResponseDBEntityEnumerator', 'ResponseDBEntityDescription']);
              exportData('attributes', dataToExport);
              this.isExporting = false;
            }
          });
      }
    }, () => {
    });
  }
}
